import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { HotKeys } from 'react-hotkeys';

import TCClient from '../../client';
import { ReactComponent as KeyboardSvg } from '../images/keyboard.svg';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import BEM from '../bem';
import { Switch } from './WebComponents';
import Modal from './Modal';

const classes = BEM.with('GoModal');

const Actions = {
  NEW_MESSAGE: 'newMessage',
  PIN: 'pin',
  SEARCH: 'search',
  RTF: 'rtf',
};
const KEYMAP = {
  [Actions.NEW_MESSAGE]: ['n'],
  [Actions.PIN]: ['p'],
  [Actions.SEARCH]: ['s'],
  [Actions.RTF]: ['r'],
};

class GoModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    composeNewMessage: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    startSearching: PropTypes.func.isRequired,
    togglePinned: PropTypes.func.isRequired,
    isProviderNetwork: PropTypes.bool.isRequired,
    richTextFormat: PropTypes.bool.isRequired,
    setRichTextFormat: PropTypes.func.isRequired,
    expertMode: PropTypes.bool.isRequired,
  };

  _lastIsOpen = false;

  constructor(...args) {
    super(...args);

    this.handlers = {
      [Actions.NEW_MESSAGE]: this._handleKeypress(this.props.composeNewMessage),
      [Actions.PIN]: this._handleKeypress(this.props.togglePinned, {
        returnFocus: true,
      }),
      [Actions.SEARCH]: this._handleKeypress(this.props.startSearching),
      [Actions.RTF]: this._handleRTF,
    };

    const rtf = this.props.richTextFormat || false;
    this.state = { rtf };
  }

  render() {
    const { isOpen, isProviderNetwork, expertMode } = this.props;
    if (isOpen && !this._lastIsOpen) {
      this._lastFocusedElement = document.activeElement;
    }
    this._lastIsOpen = isOpen;

    return (
      <Modal
        isOpen={isOpen}
        size={'medium-large'}
        header={'Press a key...'}
        headerClass={classes('header')}
        className={classes()}
        onRequestClose={this._closeModalReturningFocus}
        hasCloseButton={false}
        shouldCloseOnOverlayClick={true}
        shouldReturnFocusAfterClose={false}
      >
        <HotKeys className={classes('content')} keyMap={KEYMAP} handlers={this.handlers}>
          <div className={classes('hero-image')} ref={this._setFocusElement} tabIndex={1}>
            <KeyboardSvg />
          </div>
          <div className={classes('command-container')}>
            <div className={classes('command-item', { firstCommandItem: true })}>
              <div className={classes('command-key')}>N</div>
              <div className={classes('command-option')}>New Message</div>
            </div>
            {isProviderNetwork && (
              <div className={classes('command-item')}>
                <div className={classes('command-key')}>P</div>
                <div className={classes('command-option')}>Pin</div>
              </div>
            )}
            <div className={classes('command-item')}>
              <div className={classes('command-key')}>S</div>
              <div className={classes('command-option')}>Search</div>
            </div>
            {expertMode && (
              <div className={classes('command-item')}>
                <div className={classes('command-key')}>R</div>
                <div className={classNames(classes('command-option'), classes('rich-text'))}>
                  Rich Text
                  <div className={classes('switch')}>
                    <Switch
                      checked={this.state.rtf}
                      onSwitch={() => this._handleRTF()}
                      dataTestId="richText"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className={classes('command-item')}>
              <div className={classes('command-key')}>Esc</div>
              <div className={classes('command-option')}>Cancel</div>
            </div>
          </div>
        </HotKeys>
      </Modal>
    );
  }

  _handleKeypress = (fn, { returnFocus = false } = {}) => {
    return (e) => {
      const { closeModal, isProviderNetwork } = this.props;
      e.preventDefault();

      if (returnFocus) {
        this._closeModalReturningFocus();
      } else {
        closeModal();
      }

      this._lastFocusedElement = null;
      if (e.key === 'p' && !isProviderNetwork) return;
      fn();
    };
  };

  _closeModalReturningFocus = () => {
    const elementPresent = this._lastFocusedElement && this._lastFocusedElement.focus;
    elementPresent && this._lastFocusedElement.focus();
    this.props.closeModal();
  };

  _setFocusElement = (ref) => {
    this.focusElement = ref;
    this.focus();
  };

  focus() {
    this.focusElement && this.focusElement.focus();
  }

  blur() {
    this.focusElement && this.focusElement.blur();
  }

  _handleRTF = () => {
    const { rtf } = this.state;
    this.setState({ rtf: !rtf });
    this.props.setRichTextFormat(!rtf);
    TCClient.configure({ richTextFormat: !rtf });
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['composeNewMessage'],
  conversationStore: ['togglePinned'],
  messengerStore: ['startSearching'],
  networkStore: ['isProviderNetwork'],
  localStore: ['richTextFormat', 'setRichTextFormat', 'expertMode'],
})(GoModal);
