import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BEM from '../bem';
import { getGroupTag } from '../utils/getGroupTag';
import { AvatarImage } from './';

const classes = BEM.with('AvatarImage');

const MODIFIERS_WITH_INITIALS = { initials: true };

function getInitials(displayName) {
  const initialsParts = displayName.split(' ').slice(0, 2);
  const initials = initialsParts
    .filter(Boolean)
    .map((s) => s[0])
    .join('')
    .toUpperCase();
  if (!initials) return null;

  return initials;
}

class TeamAvatar extends Component {
  static propTypes = {
    className: PropTypes.string,
    displayName: PropTypes.string,
  };

  static defaultProps = {
    displayName: '',
  };

  render() {
    const { className, displayName, fontSize, group, team, ...restProps } = this.props;
    const tag = team?.tag || (group && getGroupTag(group));

    const entityType = 'team';
    const initials = getInitials(displayName);
    const initialsFontSizeStyles = fontSize ? { fontSize } : {};

    return (
      <AvatarImage
        className={className}
        displayName={displayName}
        entityType={entityType}
        modifiers={MODIFIERS_WITH_INITIALS}
        tagColor={tag?.color}
        {...restProps}
      >
        <span
          style={initialsFontSizeStyles}
          className={classes('initials', { entityType, teamNoTag: !tag })}
        >
          {initials}
        </span>
      </AvatarImage>
    );
  }
}

export default observer(TeamAvatar);
