import React from 'react';
import { useDispatch } from 'react-redux';
import { pick } from 'lodash';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { MapStateToProps, reduxInjectSelect } from '../../../utils/reduxInjectSelect';
import { BasicModal } from '../..';
import { entityTagId } from '../../../../redux-stores/Collaboration/selectors';
const { setModal } = actions;
const { batchEntityUpdate } = thunk;

const reduxSelectors = {
  collab: ['selectedOrgId'],
  entities: ['activeTab', 'checkedEntityIds', 'entitiesById', 'selectedEntity'],
  tags: ['checkedTagsById', 'selectedCategory', 'selectedTag', 'tagsById'],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type RemoveTagModalProps = {
  isOpen: boolean;
};

function RemoveTagModal({
  activeTab,
  checkedEntityIds,
  checkedTagsById,
  entitiesById,
  isOpen,
  selectedCategory,
  selectedOrgId,
  selectedEntity,
  selectedTag,
  tagsById,
}: ReduxProps & RemoveTagModalProps) {
  const dispatch = useDispatch();

  const itemCount = Object.keys(checkedEntityIds).length;
  const hasTags = Object.values(pick(entitiesById, checkedEntityIds)).find(entityTagId);

  const closeModal = () => {
    dispatch(setModal(undefined));
  };

  const removeTags = async () => {
    await batchEntityUpdate(dispatch, {
      ids: checkedEntityIds,
      activeTab,
      checkedTagsById,
      organizationId: selectedOrgId,
      entitiesById,
      entityUpdateProps: {
        tagColor: '',
        tagName: '',
        tagId: '',
      },
      selectedCategory,
      selectedEntity,
      selectedTag,
      tagsById,
    });
    closeModal();
  };

  return (
    <BasicModal
      ariaLabelBody={'Remove Tag Info'}
      ariaLabelCancelButton={'Remove Tag Cancel'}
      ariaLabelCloseButton={'Remove Tag Close'}
      ariaLabelHeader={'Remove Tag Header'}
      ariaLabelSubmitButton={'Confirm Remove Tag'}
      bodyText={
        hasTags
          ? 'Removing items from a tag makes them harder to find in the future.'
          : `There are no tags to remove from ${itemCount === 1 ? 'this item' : 'these items'}.`
      }
      headerText={
        hasTags
          ? `Remove tags from ${itemCount} ${itemCount === 1 ? 'item' : 'items'}?`
          : 'No tags available to remove'
      }
      hideCancel={!hasTags}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={() => (hasTags ? removeTags() : closeModal())}
      size={'medium'}
      theme={hasTags ? 'danger' : 'normal'}
      submitText={hasTags ? 'REMOVE' : 'OK'}
      useWCL
    />
  );
}

export default reduxInjectSelect<RemoveTagModalProps, ReduxProps, ReduxState>(reduxSelectors)(
  RemoveTagModal
);
