import { action } from 'mobx';
import TagManager from 'react-gtm-module';
import { isEmpty, isEqual, last } from 'lodash';
import * as Sentry from '@sentry/react';

import { DesktopClients, PerformanceKPITypes } from '../models/enums';
import { getTimeDiffInSecondsRounded } from '../common/utils';
import {
  PendoParentSettingsPathNameLabels,
  PendoPathNameLabels,
} from '../models/enums/PendoPathNameLabels';
import { parseUserAgent } from '../common/utils/parseUserAgent';
import {
  SSE_CONNECTION_DURATION,
  EVENTS_QUEUE_ERROR,
  WS_CONNECTIONS_CLOSE_COUNT,
  WS_CONNECTIONS_OVER_LIMIT,
} from '../js-sdk/src/services/EventsService';

const COUNTER_PARTY_TYPE_TO_PROP = {
  user: 'P2P',
  distributionList: 'DL',
  group: 'Group',
};

const ADVISORY_CONTEXT = {
  WRUT: 'Wrut',
  SDK: 'Sdk',
};

const TRACKED_EVENTS = [
  'click',
  'dbclick',

  'keydown',
  'keypress',
  'keyup',

  'beforeinput',
  'input',

  'mouseover',
  'mouseout',
  'mouseenter',
  'mousedown',
  'mouseup',
  'mouseleave',

  'pointerover',
  'pointerenter',
  'pointerdown',
  'pointerup',
  'pointercancel',
  'pointerout',
  'pointerleave',
  'gotpointercapture',
  'lostpointercapture',
];

const ACCEPTED_CONSOLE_ERRORS = ['users.find', '[LaunchDarkly] network error'];

export default class TrackerStore {
  allEntitiesProcessed = null;
  beforeMessageDownloadTime = null;
  conversationsReloadingEndTime = null;
  conversationsReloadingStartTime = null;
  hasSetUsersInternetSpeed = false;
  messageReplay = {
    startTimeSdk: null,
    stopTimeSdk: null,
    startTimeWrut: null,
    stopTimeWrut: null,
  };
  networkOfflineStart = null;
  offlineMessagesDownloadTime = null;
  performanceKpiTracking = {};
  roleOptInMetadata = {};
  roleOptInStart = {};
  roleOptOutStart = {};
  roleOptOutMetadata = {};
  rosterDownload = {
    firstConversation: null,
    lastConversation: null,
    startTimeSdk: null,
    stopTimeSdk: null,
    processConversationsStartTimeWrut: null,
    processConversationsEndTimeWrut: null,
    processFirstCounterPartyTimeWrut: null,
    processLastCounterPartyTimeWrut: null,
    requestsStartTimeSdk: null,
    requestsStopTimeSdk: null,
  };
  signInTime = null;
  usersInternetSpeedInMbps = null;
  usersLoadingSpinnerCacheRand = Math.random();

  constructor({ client, params, stores }) {
    this.client = client;
    this.params = params;
    this.stores = stores;
  }

  mounted() {
    if (this.client.config.reportErrors) {
      this.overrideConsoleError();
    }

    const referrer = String(document.referrer);
    const isTigerConnectReferral =
      referrer &&
      (referrer.startsWith('https://www.tigerconnect.com') ||
        referrer.startsWith('https://tigerconnect.com'));

    if (isTigerConnectReferral && process.env.GOOGLE_TAG_MANAGER_ID) {
      TagManager.initialize({ gtmId: process.env.GOOGLE_TAG_MANAGER_ID });
    }

    this.client.once('roster:download:start', () => {
      this.rosterDownload.requestsStartTimeSdk = performance.now();
    });

    this.client.once('roster:download:stop', () => {
      this.rosterDownload.requestsStopTimeSdk = performance.now();
    });

    this.client.on('change:account:data', (data) => {
      this.logPendoAnalytics(data);
    });

    this.client.on('event:websockets:error', (error) => {
      this.logWebsocketsEventError(error);
    });

    this.client.on(SSE_CONNECTION_DURATION, (data) => {
      this.send({
        level: 'info',
        message: 'SSEConnectionDuration',
        payload: data,
      });
    });

    this.client.on(EVENTS_QUEUE_ERROR.PARSE, (data) => {
      this.send({
        flushImmediately: true,
        level: 'info',
        message: 'EventsQueueErrorParse',
        payload: { eventsQueueErrorParse: data },
      });
    });

    this.client.on(EVENTS_QUEUE_ERROR.UNKNOWN, (data) => {
      this.send({
        flushImmediately: true,
        level: 'info',
        message: 'EventsQueueErrorUnknown',
        payload: { eventsQueueErrorUnknown: data },
      });
    });

    this.client.on(WS_CONNECTIONS_CLOSE_COUNT, (data) => {
      const tx = this.startSentryTransaction('WebSocket.PreviousConnections');
      if (tx) {
        Sentry.setMeasurement('CloseCount', data, 'none', tx);
        tx.end();
      }
    });

    this.client.on(WS_CONNECTIONS_OVER_LIMIT, (data) => {
      const tx = this.startSentryTransaction('WebSocket.ConnectionLimit');
      if (tx) {
        Sentry.setMeasurement('Count', data, 'none', tx);
        tx.end();
      }
    });

    this.client.on('networkStatus:change', async (options) => {
      const { networkStatus } = options;
      const timeNumber = performance.now();

      if (networkStatus === 'OFFLINE') {
        this.networkOfflineStart = timeNumber;
      } else if (networkStatus === 'ONLINE') {
        const timeOffline = timeNumber - this.networkOfflineStart;
        Object.keys(this.roleOptInMetadata).forEach((roleId) => {
          const currentValue = this.getRoleMetadata('in', roleId, 'offlineTimeCounter');
          this.setRoleMetadata('in', roleId, 'offlineTimeCounter', currentValue + timeOffline);
        });

        Object.keys(this.roleOptOutMetadata).forEach((roleId) => {
          const currentValue = this.getRoleMetadata('out', roleId, 'offlineTimeCounter');
          this.setRoleMetadata('out', roleId, 'offlineTimeCounter', currentValue + timeOffline);
        });
      }
    });

    this.client.on('roles:processing', async (event) => {
      const name = event['event_name'];
      const inOut = event['action_type'] === 'role_opt_out' ? 'out' : 'in';
      if (name === 'start') {
        this.setRoleMetadata(inOut, event.role_id, 'offlineTimeCounter', 0);
      } else if (!this.getRoleMetadata(inOut, event.role_id)) {
        return;
      }
      this.setRoleMetadata(inOut, event.role_id, `${name}EventReceived`, performance.now());
    });

    this.client.on('message:alertMetadataSSEReceived', ({ messageId }) => {
      this.send({
        level: 'info',
        message: 'groupAlertMetadataSSEReceipt',
        payload: {
          messageId,
          currentUserId: this.client.currentUser.id,
        },
      });
    });

    this.client.on('message:statusSync', async (event) => {
      const enabled = this.stores.featureStore.featureFlags?.ENABLE_MESSAGE_STATUS_SYNC || false;
      if (!enabled) return;

      const tx = this.startSentryTransaction('CompareUnreadMessages');
      tx?.setAttribute('validBeforeUpdate', event);
      tx?.end();
    });

    this.stores.messengerStore.events.once('startRosterDownload', () => {
      this.rosterDownload.startTimeSdk = performance.now();
    });

    this.stores.entityStore.events.on('optin:start', (event) => {
      this.roleOptInStart[event.role_id] = performance.now();
      const startMessagesCount = this.stores.entityStore.message
        .getAll()
        .filter((message) => !message.id.includes('bang')).length;
      const startConversationsCount = this.stores.entityStore.conversation.getAll().length;
      this.setRoleMetadata('in', event.role_id, 'startMessagesCount', startMessagesCount);
      this.setRoleMetadata('in', event.role_id, 'startConversationsCount', startConversationsCount);
    });

    this.stores.entityStore.events.on('optin:stop', (event) => {
      const roleId = event.role_id;
      if (!this.getRoleMetadata('in', roleId)) {
        return;
      }
      const metadata = this.getRoleMetadata('in', roleId);
      const totalOptInTimeInMs = performance.now() - this.roleOptInStart[roleId];
      const totalOptInTimeConnectionAdjustedInMs = totalOptInTimeInMs - metadata.offlineTimeCounter;
      const endMessagesCount = this.stores.entityStore.message
        .getAll()
        .filter((message) => !message.id.includes('bang')).length;
      const endConversationsCount = this.stores.entityStore.conversation.getAll().length;
      const totalMessages = endMessagesCount - metadata?.startMessagesCount;
      const totalConversations = endConversationsCount - metadata?.startConversationsCount;
      const totalOptinTimeNoProcessingInMs =
        metadata.stopEventReceived - metadata.startEventReceived;

      this.send({
        level: 'info',
        message: 'totalOptInTimeInMS',
        payload: {
          totalOptInTimeInMs,
          totalOptInTimeConnectionAdjustedInMs,
          totalOptinTimeNoProcessingInMs,
          roleId,
          totalMessages,
          totalConversations,
        },
      });
      this.clearRoleMetadata('in', roleId);
    });

    this.stores.entityStore.events.on('optout:start', (event) => {
      this.roleOptOutStart[event.role_id] = performance.now();
    });

    this.stores.entityStore.events.on('optout:stop', (event) => {
      const roleId = event.role_id;
      if (!this.getRoleMetadata('out', roleId)) {
        return;
      }
      const metadata = this.getRoleMetadata('out', roleId);
      const totalOptOutTime = performance.now() - this.roleOptOutStart[roleId];
      const totalOptOutTimeConnectionAdjustedInMs = totalOptOutTime - metadata.offlineTimeCounter;
      const totalOptOutTimeNoProcessing = metadata.stopEventReceived - metadata.startEventReceived;
      this.send({
        level: 'info',
        message: 'totalOptOutTimeInMS',
        payload: {
          totalOptOutTime,
          totalOptOutTimeConnectionAdjustedInMs,
          totalOptOutTimeNoProcessing,
          roleId,
        },
      });
      this.clearRoleMetadata('out', roleId);
    });

    this.stores.messengerStore.events.once('finishRosterDownload', (sdkConversations) => {
      this.rosterDownload.stopTimeSdk = performance.now();

      if (sdkConversations?.length > 0) {
        this.rosterDownload.firstConversation = sdkConversations[0];
        this.rosterDownload.lastConversation = sdkConversations[sdkConversations.length - 1];

        this.stores.entityStore.events.on('afterInject', this.rosterDownloadHandler);
        this.stores.entityStore.events.once('showMessenger', () => {
          this.stores.entityStore.events.removeListener('afterInject', this.rosterDownloadHandler);
        });
      }
    });

    this.stores.sessionStore.events.on('signedIn', () => {
      this.signInTime = performance.now();

      this.installFirstReplayAdvisoryHandler(ADVISORY_CONTEXT.SDK, this.client);
      this.installFirstReplayAdvisoryHandler(ADVISORY_CONTEXT.WRUT, this.stores.entityStore.events);

      this.client.once('messages:offline:start', () => {
        this.beforeMessageDownloadTime = performance.now();
      });

      this.client.once('conversations:loading:start', () => {
        this.conversationsReloadingStartTime = performance.now();
      });

      this.stores.entityStore.events.once('showMessenger', () => {
        this.allEntitiesProcessed = performance.now();

        if (this.client.config.reportPerformance) {
          this.sendLoginAnalytics();
        }
      });
    });

    this.stores.downloadedMessagesProgressStore.events.on('downloadFinished', () => {
      this.initializeResponsivenessObserver();
    });

    this.client.on('message:sending', (message) => {
      const trackingData = this.performanceKpiTracking[PerformanceKPITypes.MESSAGE_SEND];
      if (!trackingData) return;
      const key = this.performanceKpiKey(PerformanceKPITypes.MESSAGE_SEND, message.id);
      delete this.performanceKpiTracking[PerformanceKPITypes.MESSAGE_SEND];
      this.performanceKpiTracking[key] = trackingData;
    });

    this.client.on('message:sent', (message) => {
      this.performanceKpiEnd(PerformanceKPITypes.MESSAGE_SEND, message.id);
    });
  }

  rosterDownloadHandler = (entity) => {
    if (!entity) return;
    if (
      entity.$entityType !== 'conversation' &&
      entity.$entityType !== 'user' &&
      entity.$entityType !== 'group'
    ) {
      return;
    }

    if (
      this.rosterDownload.processConversationsStartTimeWrut === null &&
      entity.id === this.rosterDownload.firstConversation.id
    ) {
      this.rosterDownload.processConversationsStartTimeWrut = performance.now();
    }

    if (
      this.rosterDownload.processConversationsEndTimeWrut === null &&
      entity.id === this.rosterDownload.lastConversation.id
    ) {
      this.rosterDownload.processConversationsEndTimeWrut = performance.now();
    }

    if (
      this.rosterDownload.processFirstCounterPartyTimeWrut === null &&
      entity.id === this.rosterDownload.firstConversation.counterPartyId
    ) {
      this.rosterDownload.processFirstCounterPartyTimeWrut = performance.now();
    }

    if (
      this.rosterDownload.processLastCounterPartyTimeWrut === null &&
      entity.id === this.rosterDownload.lastConversation.counterPartyId
    ) {
      this.rosterDownload.processLastCounterPartyTimeWrut = performance.now();
    }

    if (
      this.rosterDownload.processConversationsStartTimeWrut !== null &&
      this.rosterDownload.processConversationsEndTimeWrut !== null &&
      this.rosterDownload.processConversationsStartTimeWrut >
        this.rosterDownload.processConversationsEndTimeWrut
    ) {
      [
        this.rosterDownload.processConversationsStartTimeWrut,
        this.rosterDownload.processConversationsEndTimeWrut,
      ] = [
        this.rosterDownload.processConversationsEndTimeWrut,
        this.rosterDownload.processConversationsStartTimeWrut,
      ];
    }

    if (
      this.rosterDownload.processFirstCounterPartyTimeWrut !== null &&
      this.rosterDownload.processLastCounterPartyTimeWrut !== null &&
      this.rosterDownload.processFirstCounterPartyTimeWrut >
        this.rosterDownload.processLastCounterPartyTimeWrut
    ) {
      [
        this.rosterDownload.processFirstCounterPartyTimeWrut,
        this.rosterDownload.processLastCounterPartyTimeWrut,
      ] = [
        this.rosterDownload.processLastCounterPartyTimeWrut,
        this.rosterDownload.processFirstCounterPartyTimeWrut,
      ];
    }
  };

  installFirstReplayAdvisoryHandler = (context, emitter) => {
    const handler = ({ type } = {}) => {
      if (type === 'replayStart') {
        this.messageReplay[`startTime${context}`] = performance.now();
      } else if (type === 'replayStop') {
        this.messageReplay[`stopTime${context}`] = performance.now();
        emitter.removeListener('advisory', handler);
      }
    };

    emitter.on('advisory', handler);
  };

  @action('TrackerStore.compareUnreadMessages') compareUnreadMessages = async () => {
    const enabled = this.stores.featureStore.featureFlags?.ENABLE_MESSAGE_STATUS_SYNC || false;
    if (!enabled) return;

    const sampleRate = this.stores.featureStore.featureFlags?.MESSAGE_STATUS_SYNC_SAMPLE_RATE || 0;
    const shouldSample = Math.random() >= 1 - sampleRate;
    if (!shouldSample) return;

    const conversations = await this.stores.conversationStore.findAll();

    if (conversations === undefined || conversations === null) return;

    const localUnreadIds = conversations
      .map((conversation) => {
        return conversation.unreadIds;
      })
      .reduce((a, b) => a.concat(...b), [])
      .sort();

    const localUnreadCount = this.stores.messengerStore.totalUnreadCount;

    if (localUnreadCount === undefined || localUnreadCount === null) return;

    const remoteData = await this.client.messages.getUnreadMessages(
      this.stores.sessionStore.currentUserId
    );

    if (remoteData.count === undefined || remoteData.message_token_list === undefined) {
      Sentry.captureException(new Error('Response from getUnreadMessages is missing data'));
      return;
    }

    const remoteUnreadCount = remoteData.count;

    const remoteUnreadIds = remoteData.message_token_list
      .map((message) => message.message_id)
      .sort();

    const countsMatch = localUnreadCount === remoteUnreadCount;
    const idsMatch = isEqual(localUnreadIds, remoteUnreadIds);

    const span = Sentry.startInactiveSpan({ name: 'CompareUnreadMessages' });
    span?.setAttribute('countsMatch', countsMatch);
    span?.setAttribute('idsMatch', idsMatch);
    span?.setAttribute('localUnreadCount', localUnreadCount);
    span?.setAttribute('remoteUnreadCount', remoteUnreadCount);
    span?.end();
  };

  @action('TrackerStore.clearPendoSession')
  clearPendoSession = () => {
    return window?.pendo?.clearSession?.();
  };

  @action('TrackStore.logPendoAnalytics')
  logPendoAnalytics = async ({
    account,
    initalizePendo,
    pathName,
    parentPathName,
    tracker = { name: '', props: {} },
    visitor,
  }) => {
    const { browserName, browserVersion, osName } = parseUserAgent(window.navigator.userAgent);
    const currentUserOsInfo = osName === 'Mac OS' ? 'macOs' : osName;

    if (initalizePendo) {
      window.pendo.initialize({
        location: {
          transforms: [
            { attr: 'pathname', action: 'Replace', data: PendoPathNameLabels[pathName] },
          ],
        },
      });
      this.clearPendoSession();
      return;
    }

    if (['Login Password', 'Login Username'].includes(pathName) && !initalizePendo) {
      window.pendo?.location?.addTransforms([
        { attr: 'pathname', action: 'Replace', data: PendoPathNameLabels[pathName] },
      ]);
      return;
    }

    if (parentPathName === 'Analytics' && pathName === 'Patient Video') {
      pathName = 'Analytics Patient Video';
    }

    if (!this.client.currentUser) return;

    const { version } = this.stores.messengerStore.buildInfo;
    const desktopAppProps = await this.getDesktopAppProps();
    let userDepartment = this.client.currentUser?.department || '';
    let userTitle = this.client.currentUser?.title || '';
    let currentUser;

    if (this.stores.messengerStore.currentOrganization) {
      currentUser = await this.client.users.findMeByOrganizationId({
        organizationId: this.stores.messengerStore.currentOrganization.id,
      });
    }

    if (currentUser) {
      if (currentUser.entity) {
        userDepartment = currentUser.entity?.department || '';
        userTitle = currentUser.entity?.title || '';
      } else {
        userDepartment = currentUser?.department || '';
        userTitle = currentUser?.title || '';
      }
    }

    const visitorId =
      this.client.config.apiEnv === 'prod' ||
      this.client.config.apiEnv === 'production' ||
      this.client.config.apiEnv === 'ihis'
        ? this.client.currentUser.id
        : `${this.client.config.apiEnv}_${this.client.currentUser.id}`;

    const metadata = {
      visitor: {
        id: visitorId,
        numberOfOrgs: this.stores.messengerStore.organizationIds.length,
        displayName: this.client.currentUser.displayName,
        userdepartment: userDepartment,
        usertitle: userTitle,
        usertype: this.client.currentUser.isPatient ? 'patient' : 'staff',
        ...(!desktopAppProps['DA Version'] && {
          tcwebappversion: version,
          webos: currentUserOsInfo,
          webbrowser: browserName,
          webbrowserversion: browserVersion,
        }),
        ...(desktopAppProps['DA Version'] && {
          tcdaversion: desktopAppProps['DA Version'],
          daos: currentUserOsInfo,
        }),
      },
      account: {
        'Organization Name': this.stores.messengerStore.currentOrganization?.name,
        id: this.stores.messengerStore.currentOrganization?.id,
      },
    };

    if (!this.client.currentUser.emails || !this.client.currentUser.emails.length) {
      metadata['visitor']['emails'] = '';
    } else if (this.client.currentUser.emails.length === 1) {
      metadata['visitor']['emails'] = this.client.currentUser.emails[0].address;
    } else if (this.client.currentUser.emails.length > 1) {
      metadata['visitor']['emails'] = this.client.currentUser.emails
        .map((email) => email.address)
        .join(', ');
    }

    const mergeVisitorData = { ...metadata, ...visitor };
    const mergeAccountData = { ...metadata, ...account };

    const mergeMetadata = { ...mergeVisitorData, ...mergeAccountData };

    if (parentPathName || pathName) {
      let path = '';
      if (parentPathName && pathName) {
        path = `${PendoParentSettingsPathNameLabels[parentPathName]}/${PendoPathNameLabels[pathName]}`;
      } else if (parentPathName) {
        path = PendoParentSettingsPathNameLabels[parentPathName];
      } else if (pathName) {
        path = PendoPathNameLabels[pathName];
      }

      if (path)
        window.pendo.location?.addTransforms([{ attr: 'pathname', action: 'Replace', data: path }]);
    }

    if (tracker.name) {
      const trackerClientType = desktopAppProps['DA Version'] ? 'CCP DA' : 'CCP Web';
      const trackerName = `${trackerClientType} | ${tracker.name}`;
      const trackerProps = isEmpty(tracker.props) ? null : tracker.props;

      window.pendo.track(trackerName, trackerProps);
    } else {
      window.pendo.identify(mergeMetadata);
    }
  };

  offsetToDays = ({ appointmentTimeOffset, appointmentTimeOffsetUnit }) => {
    let absoluteOffsetValue;
    if (appointmentTimeOffsetUnit === 'minutes') {
      absoluteOffsetValue = (Math.abs(appointmentTimeOffset) / (60 * 24)).toFixed(2);
    } else if (appointmentTimeOffsetUnit === 'hours') {
      absoluteOffsetValue = (Math.abs(appointmentTimeOffset) / 24).toFixed(2);
    } else if (appointmentTimeOffsetUnit === 'days') {
      absoluteOffsetValue = Math.abs(appointmentTimeOffset);
    } else if (appointmentTimeOffsetUnit === 'weeks') {
      absoluteOffsetValue = Math.abs(appointmentTimeOffset) * 7;
    }
    return absoluteOffsetValue;
  };

  getMessageDeliveryMethod = (deliveryMethod) => {
    let messageDeliveryMethod;
    if (deliveryMethod === 'sms') {
      messageDeliveryMethod = 'SMS Text';
    } else if (deliveryMethod === 'link') {
      messageDeliveryMethod = 'Secure Two-Way';
    } else {
      messageDeliveryMethod = 'Secure One-Way';
    }
    return messageDeliveryMethod;
  };

  isPatientDL(conversation, counterParty) {
    return this.getRecipientType(counterParty) === 'DL' && conversation.network === 'PATIENT';
  }

  setRoleMetadata = (inOut, roleId, field, value) => {
    const metdataObject = inOut === 'in' ? this.roleOptInMetadata : this.roleOptOutMetadata;
    if (!metdataObject[roleId]) metdataObject[roleId] = {};
    metdataObject[roleId][field] = value;
  };

  getRoleMetadata = (inOut, roleId, field) => {
    const metdataObject = inOut === 'in' ? this.roleOptInMetadata : this.roleOptOutMetadata;
    if (!field) return metdataObject[roleId];
    return metdataObject[roleId] && metdataObject[roleId][field];
  };

  clearRoleMetadata = (inOut, roleId) => {
    const metdataObject = inOut === 'in' ? this.roleOptInMetadata : this.roleOptOutMetadata;
    delete metdataObject[roleId];
  };

  getMsgViewedProps = (message) => {
    const {
      conversation,
      counterParty,
      patientCareCard,
      sender,
      senderOrganization: senderOrg,
    } = message;
    const conditionalProps = {};
    const forwarded = !!message.isForwarded;
    const groupType = this.getGroupType(message);
    const distListName = groupType === 'DL' ? counterParty.name : 'Not DL';
    const senderType = sender.isPatient
      ? 'patient'
      : sender.isPatientContact
      ? 'patient contact'
      : 'provider';

    if (patientCareCard && patientCareCard.length > 0) {
      const EHRCardType = patientCareCard[0].value;
      conditionalProps['EHR Card Type'] = EHRCardType;
    }

    return {
      'As Role': !!message.senderRole,
      'Content Type': this.getContentTypes(message.attachments),
      'Distribution List Name': distListName,
      Forwarded: forwarded,
      'Forwarded # of Recipients': forwarded ? this.getNumOfRecipients(message) : 0,
      'Group Type': groupType,
      'Has Attachment': message.attachments.length > 0,
      'Is a TigerTouch message': conversation.network === 'PATIENT',
      'Is Auto Forwarded': message.isAutoForwarded,
      'Is Escalated': !!message.escalationExecution,
      'Is Priority Message': message.priority === 'HIGH',
      'Lifespan of Message': message.ttl,
      'Message ID': message.serverId || message.id,
      'Organization ID': senderOrg.serverId || senderOrg.id,
      'Organization Name': senderOrg.displayName,
      'Personal Org': senderOrg.isContacts,
      'Recipient ID': counterParty.serverId || counterParty.id,
      'Sender Type': senderType,
      'Type of Recipient': this.isPatientDL(conversation, counterParty)
        ? 'PatientDL'
        : this.getRecipientType(counterParty),
      ...conditionalProps,
    };
  };

  getGroupTypeFromConversation = (conversation) => {
    if (conversation.counterPartyType === 'distributionList') {
      return 'DL';
    }

    if (conversation.counterPartyType === 'group') {
      if (conversation.counterParty.groupType === 'INTRA_TEAM') {
        return 'Intra-Team';
      } else if (conversation.counterParty.groupType === 'ACTIVATED_TEAM') {
        return 'Activated Team';
      } else if (conversation.counterParty.groupType === 'FORUM') {
        return 'Forum';
      } else if (conversation.counterParty.groupType === 'PATIENT_CARE') {
        return 'Patient Care';
      } else if (conversation.counterParty.groupType === 'GROUP') {
        return 'Group';
      } else if (conversation.counterParty.groupType === 'ESCALATION') {
        return 'Escalation';
      } else if (conversation.counterParty.groupType === 'ROLE_P2P') {
        return 'Role P2P';
      }

      return conversation.counterParty.isPublic ? 'Public' : 'Private';
    }

    return 'Not Group';
  };

  getMsgRecalledProps = (message) => {
    return {
      'Group Type': this.getGroupType(message),
      'Is Priority Message': message.priority === 'HIGH',
      'Message ID': message.serverId || message.id,
    };
  };

  getMsgSentProps = (message, { origin = 'Roster', resent = false } = {}) => {
    const baseProps = this.getMsgViewedProps(message);

    return {
      ...baseProps,
      'Conversation Origin': origin,
      'Delete on Read': message.deleteOnRead,
      'Number of Recipients': this.getNumOfRecipients(message),
      'Photo Type': 'N/A',
      'Resend Message': resent,
      'Sender Resource': this.getPlatform(),
    };
  };

  getContentTypes = (attachments) => {
    if (attachments.length === 0) {
      return 'Text';
    }

    return attachments.map((attachment) => attachment.contentType).join(', ');
  };

  getRecipientType = (counterParty) => {
    if (counterParty.groupType === 'ROLE_P2P') {
      return 'Role';
    } else if (counterParty.groupType === 'PATIENT_MESSAGING') {
      if (counterParty.memberCount === 2) {
        if (counterParty.patientDetails.isPatientContact) {
          return 'Patient Contact';
        } else {
          return 'Patient';
        }
      }
    }

    if (counterParty.conversation?.featureService === 'vwr') {
      return 'Virtual Waiting Room Visitor';
    }

    return COUNTER_PARTY_TYPE_TO_PROP[counterParty.$entityType];
  };

  getNumOfRecipients = (message) => {
    if (!message.isGroup && !message.group) {
      return 1;
    }

    return message.counterParty.memberCount - 1;
  };

  getGroupType = (message) => {
    if (message.counterPartyType === 'distributionList') {
      return 'DL';
    }

    if (message.isGroup || message.group) {
      if (message.group) {
        if (message.group.groupType === 'INTRA_TEAM') {
          return 'Intra-Team';
        } else if (message.group.groupType === 'ACTIVATED_TEAM') {
          return 'Activated Team';
        }
      }
      return message.counterParty.isPublic ? 'Public' : 'Private';
    }

    return 'Not Group';
  };

  getSuperProps = async (user) => {
    const orgs = await this.client.organizations.findAll();
    let contacts = 0;
    const orgNames = [];
    let paidOrg = false;
    orgs.forEach((org) => {
      contacts += org.memberCount - 1;
      orgNames.push(org.name);

      if (!paidOrg && org.paid) {
        paidOrg = true;
      }
    });

    const convos = await this.stores.conversationStore.findAll();

    const { version } = this.stores.messengerStore.buildInfo;
    const desktopAppProps = await this.getDesktopAppProps();
    const superProps = {
      '# of Contacts': contacts > 0 ? contacts : 'N/A',
      '# of Roster': convos.length > 0 ? convos.length : 'N/A',
      $app_version: version,
      'Build Version': this.stores.staticStore.isDevelopment ? 'Dev' : version,
      'Display Name': user.displayName,
      $email: user.emails.length > 0 ? user.emails[0].address : '',
      'Number of Orgs': orgs.length,
      'Organization Names': orgNames.join(', '),
      'Org Type': paidOrg ? 'Paid' : 'Free',
      'Paid Org': paidOrg ? 'Yes' : 'No',
      Platform: this.getPlatform(),
      Token: user.serverId,
      $username: user.username,
      ...desktopAppProps,
    };

    return superProps;
  };

  getDesktopAppProps = async () => {
    if (!this.stores.desktopAppStore.isDesktopApp) {
      return {};
    }

    if (window.parent.isElectron) {
      const { DAOS, DAOSArch, DAOSVersion, DAVersion } = window.parent;

      return {
        'DA OS': DAOS,
        'DA OS Architecture': DAOSArch,
        'DA OS Version': DAOSVersion,
        'DA Version': DAVersion,
      };
    }

    const dAInfoProps = [
      'dAOS',
      'dAOSArch',
      'dAOSServicePack',
      'dAOSVersion',
      'dAPackageType',
      'dAVersion',
    ];
    const { notificationHandler } = window;
    const dAInfo = dAInfoProps.map((key) => {
      const value = notificationHandler[key];

      return typeof value === 'function' ? value() : value;
    });

    const [dAOS, dAOSArch, dAOSServicePack, dAOSVersion, dAPackageType, dAVersion] =
      await Promise.all(dAInfo);

    return {
      'DA OS': dAOS,
      'DA OS Architecture': dAOSArch,
      'DA OS Service Pack': dAOSServicePack,
      'DA OS Version': dAOSVersion,
      'DA Package Type': dAPackageType,
      'DA Version': dAVersion,
    };
  };

  getPlatform = () => {
    const { clientType, isDesktopApp } = this.stores.desktopAppStore;

    let platform = 'New Web';
    if (isDesktopApp) {
      if (clientType === DesktopClients.DOTNET) {
        platform = 'Windows Desktop';
      } else {
        platform = 'OSX Desktop';
      }
    }

    return platform;
  };

  overrideConsoleError = () => {
    const { trackerStore } = this.stores;
    const consoleErrorFn = console.error;
    const consoleOnErrorFn = window.onerror;

    window.onerror = (messageText, source, lineno, colno, error) => {
      consoleOnErrorFn && consoleOnErrorFn(messageText, source, lineno, colno, error);

      if (!messageText.length) {
        return;
      }

      // Skip sending Sentry Replay errors
      // https://github.com/getsentry/sentry-javascript/blob/f8cebde5884e576fab59b4eb0c17703aba4fd14a/packages/replay/src/integration.ts#L151
      if (error?.__rrweb__) return;

      trackerStore.send({
        level: 'error',
        message: 'onError',
        flushImmediately: true,
        payload: {
          messageText,
          source,
          lineno,
          colno,
          error,
          ...(error?.stack && { stack: error.stack }),
        },
      });
    };

    console.error = (...messageParts) => {
      consoleErrorFn && consoleErrorFn(...messageParts);
      const joinedMessage = this.__joinConsoleErrorMessages(messageParts);
      const errorStack = new Error(joinedMessage).stack;

      if (
        !joinedMessage.length ||
        ACCEPTED_CONSOLE_ERRORS.some((str) => joinedMessage.startsWith(str))
      ) {
        return;
      }

      trackerStore.send({
        level: 'error',
        message: 'consoleError',
        flushImmediately: true,
        payload: {
          messageText: joinedMessage,
          stack: errorStack,
        },
      });
    };
  };

  __joinConsoleErrorMessages = (messageParts = []) => {
    return messageParts
      .filter(
        (messagePart) =>
          typeof messagePart === 'string' ||
          (messagePart?.message && typeof messagePart.message === 'string')
      )
      .map((messagePart) => (typeof messagePart === 'string' ? messagePart : messagePart?.message))
      .join(' ');
  };

  calculateRosterDistribution = () => {
    let p2p = 0;
    let rolep2p = 0;
    let group = 0;
    let forum = 0;
    let broadcastList = 0;
    let patientCare = 0;
    let intraTeam = 0;
    let activatedTeam = 0;
    let escalation = 0;
    let unknown = 0;
    let unknownGroup = 0;

    for (const conv of this.stores.conversationStore.conversations) {
      if (conv.counterPartyType === 'group') {
        switch (this.getGroupTypeFromConversation(conv)) {
          case 'Group':
            group++;
            break;
          case 'Patient Care':
            patientCare++;
            break;
          case 'Intra-Team':
            intraTeam++;
            break;
          case 'Activated Team':
            activatedTeam++;
            break;
          case 'Forum':
            forum++;
            break;
          case 'Escalation':
            escalation++;
            break;
          case 'Role P2P':
            rolep2p++;
            break;
          default:
            unknownGroup++;
            break;
        }
      } else if (conv.counterPartyType === 'distributionList') {
        broadcastList++;
      } else if (conv.counterPartyType === 'user') {
        p2p++;
      } else {
        unknown++;
      }
    }

    return {
      p2p,
      rolep2p,
      group,
      patientCare,
      intraTeam,
      activatedTeam,
      forum,
      broadcastList,
      escalation,
      unknown,
      unknownGroup,
    };
  };

  @action('TrackerStore.setUsersInternetSpeed') setUsersInternetSpeed = (speed) => {
    this.usersInternetSpeedInMbps = speed;
    this.hasSetUsersInternetSpeed = true;
  };

  @action('TrackerStore.sendWebLoggingMessage') sendWebLoggingMessage = (
    level,
    message,
    payload
  ) => {
    this.send({
      level,
      message,
      payload,
    });
  };

  sendLoginAnalytics = () => {
    const rosterDistribution = this.calculateRosterDistribution();
    const { messageDownloadTime, offlineMessageCount } =
      this.stores.downloadedMessagesProgressStore;

    const payload = {
      times: {
        beforeAdvisory: getTimeDiffInSecondsRounded(
          this.signInTime,
          this.beforeMessageDownloadTime
        ),
        receiveRosterFromServer: getTimeDiffInSecondsRounded(
          this.rosterDownload.requestsStartTimeSdk,
          this.rosterDownload.requestsStopTimeSdk
        ),
        processRosterSdk: getTimeDiffInSecondsRounded(
          this.rosterDownload.startTimeSdk,
          this.rosterDownload.stopTimeSdk
        ),
        processEntitiesBeforeRosterWrut: getTimeDiffInSecondsRounded(
          this.rosterDownload.stopTimeSdk,
          this.rosterDownload.processFirstCounterPartyTimeWrut
        ),
        processRosterCounterPartiesWrut: getTimeDiffInSecondsRounded(
          this.rosterDownload.processFirstCounterPartyTimeWrut,
          this.rosterDownload.processLastCounterPartyTimeWrut
        ),
        processRosterConversationsWrut: getTimeDiffInSecondsRounded(
          this.rosterDownload.processConversationsStartTimeWrut,
          this.rosterDownload.processConversationsEndTimeWrut
        ),
        receiveAndProcessMessageReplay: getTimeDiffInSecondsRounded(0, messageDownloadTime),
        processMessageReplaySdk: getTimeDiffInSecondsRounded(
          this.messageReplay.startTimeSdk,
          this.messageReplay.stopTimeSdk
        ),
        processMessageReplayWrut: getTimeDiffInSecondsRounded(
          this.messageReplay.startTimeWrut,
          this.messageReplay.stopTimeWrut
        ),
        reloadConversation: getTimeDiffInSecondsRounded(
          this.conversationsReloadingStartTime,
          this.conversationsReloadingEndTime
        ),
        processRemainingEntitiesWrut: getTimeDiffInSecondsRounded(
          this.rosterDownload.processLastCounterPartyTimeWrut,
          this.allEntitiesProcessed
        ),
        pageRendered: getTimeDiffInSecondsRounded(this.signInTime, this.allEntitiesProcessed),
        totalEventsProcessedWrut: this.stores.entityStore._processedEventsCount,
        totalRelationsUpdatedWrut: this.stores.entityStore._relationsUpdatedCount,
        usersInternetSpeedInMbps: this.usersInternetSpeedInMbps,
      },
      useWebSockets: this.client.events.eventSourceIsWebSocket,
    };

    if (!this.client.config.condensedReplays) {
      const totalMessages = this.stores.entityStore.message
        .getAll()
        .filter((message) => !message.id.includes('bang')).length;
      const unreadMessages = this.stores.messengerStore.product?.unreadCount;
      const onlyUnreadPercentage = unreadMessages / totalMessages;
      const potentialMessageReplayNewTime =
        getTimeDiffInSecondsRounded(
          this.messageReplay.startTimeWrut,
          this.messageReplay.stopTimeWrut
        ) * onlyUnreadPercentage;
      const messageReplaySavings =
        getTimeDiffInSecondsRounded(
          this.messageReplay.startTimeWrut,
          this.messageReplay.stopTimeWrut
        ) - potentialMessageReplayNewTime;

      payload.counts = {
        totalMessages,
        unreadMessages,
        potentialMessageReplayNewTime,
        potentialPageRenderedNewTime:
          getTimeDiffInSecondsRounded(this.signInTime, this.allEntitiesProcessed) -
          messageReplaySavings,
        conversationsCount: this.stores.entityStore.conversation.getAll().length,
      };
    }

    const sentryTransaction = this.startSentryTransaction(PerformanceKPITypes.LOGIN);
    const loginTimingEvents = [
      'beforeAdvisory',
      'receiveRosterFromServer',
      'processRosterSdk',
      'processEntitiesBeforeRosterWrut',
    ];
    loginTimingEvents.forEach((timingKey) => {
      Sentry.setMeasurement(timingKey, payload.times[timingKey], 'second', sentryTransaction);
    });
    sentryTransaction?.end();

    this.send({
      level: 'info',
      message: 'loginTimings',
      payload,
    });

    this.send({
      level: 'info',
      message: 'rosterDistribution',
      payload: {
        messageCount: offlineMessageCount,
        conversationsCount: this.stores.conversationStore.conversations.length,
        distribution: rosterDistribution,
      },
    });
  };

  @action('TrackerStore.sendPrintLogs') sendPrintLogs = (currentConversation) => {
    this.send({
      level: 'info',
      message: 'printConversation',
      payload: {
        conversationId: currentConversation.id,
        conversationType: currentConversation.counterPartyType,
        currentUserId: this.client.currentUser.id,
        network: currentConversation.network,
      },
    });
  };

  @action('TrackerStore.logWebsocketsEventError') logWebsocketsEventError = (error) => {
    this.send({
      level: 'info',
      message: 'websocketsEventError',
      payload: error,
    });
  };

  @action('TrackerStore.logGroupAlertAction') logGroupAlertAction = async (
    messageId,
    buttonComponent
  ) => {
    try {
      await this.send({
        level: 'info',
        message: 'groupAlertAction',
        payload: {
          messageId,
          currentUserId: this.client.currentUser.id,
          value: buttonComponent.value,
          actionUrl: buttonComponent.action_url,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  send = async ({
    correlationId,
    flushImmediately,
    level = 'debug',
    message,
    organizationId,
    payload,
  }) => {
    const { desktopAppStore, launchStore, messengerStore } = this.stores;

    this.client.report.send({
      correlationId,
      desktopAppVersion: desktopAppStore.desktopAppVersion,
      desktopClientType: desktopAppStore.clientType,
      flushImmediately,
      level,
      message,
      organizationId: organizationId || messengerStore.currentOrganizationId,
      isFHIRLaunchSession: launchStore.isFHIRLaunchSession,
      payload,
    });
  };

  sentryTransactionEnabled = (name) => {
    if (name.includes('ConversationStore.') || name.includes('ConversationMessages.')) {
      return this.stores.featureStore.featureFlags?.SENTRY_MONITOR_CONVERSATIONS;
    } else if (name.includes('WebSocket')) {
      return this.stores.featureStore.featureFlags?.SENTRY_MONITOR_WEBSOCKETS;
    } else {
      return true;
    }
  };

  startSentryTransaction = (name) => {
    if (this.stores.featureStore.featureFlags?.SENTRY && this.sentryTransactionEnabled(name)) {
      return Sentry.startInactiveSpan({ name, forceTransaction: true });
    }
  };

  coalescePerformanceEntries = (entries) => {
    if (!entries || !entries.length) return {};

    const { groups, currentGroup } = entries
      .sort((a, b) => a.startTime - b.startTime)
      .reduce(
        (memo, entry) => {
          const { currentGroup, groups, lastEntry } = memo;
          if (lastEntry && lastEntry.processingStart > entry.startTime) {
            currentGroup.push(entry);
            return { ...memo, lastEntry: entry };
          } else {
            if (currentGroup) groups.push(currentGroup);
            return { ...memo, currentGroup: [entry], lastEntry: entry };
          }
        },
        { groups: [] }
      );

    groups.push(currentGroup);

    return {
      duration: groups.reduce(
        (sum, items) => sum + (last(items).processingStart - items[0].startTime),
        0
      ),
      samplePeriod: last(last(groups)).processingStart - groups[0][0].startTime,
    };
  };

  observeGeneralPerformance = (entries, { observerThreshold }) => {
    if (!this.stores.featureStore.featureFlags?.SENTRY_MONITOR_RESPONSIVENESS) return;
    const filteredAndSortedEntries = entries
      .filter((entry) => {
        const delay = entry.processingStart - entry.startTime;
        return delay >= observerThreshold && TRACKED_EVENTS.includes(entry.name);
      })
      .sort((a, b) => a.processingStart - a.startTime - (b.processingStart - b.startTime));

    if (filteredAndSortedEntries.length === 0) return;

    const min = filteredAndSortedEntries[0];
    const minDelay = min.processingStart - min.startTime;

    const max = filteredAndSortedEntries[filteredAndSortedEntries.length - 1];
    const maxDelay = max.processingStart - max.startTime;

    const occurrences = filteredAndSortedEntries.length;

    const { duration = 0, samplePeriod = 0 } =
      this.coalescePerformanceEntries(filteredAndSortedEntries);

    const tx = this.startSentryTransaction('PoorResponsiveness');
    if (tx) {
      Sentry.setMeasurement('min', minDelay, 'millisecond', tx);
      Sentry.setMeasurement('max', maxDelay, 'millisecond', tx);
      Sentry.setMeasurement('occurrences', occurrences, 'none', tx);
      Sentry.setMeasurement('samplePeriod', samplePeriod, 'millisecond', tx);
      samplePeriod &&
        Sentry.setMeasurement('unresponsivePercentage', duration / samplePeriod, 'percent', tx);

      const startSeconds = tx?.startTimestamp || 0;
      const durationSeconds = duration / 1000;
      const finishSeconds = startSeconds + durationSeconds;

      tx.end(finishSeconds);
    }
  };

  observeTypingPerformance = (entries, { observerThreshold }) => {
    if (!this.stores.featureStore.featureFlags?.SENTRY_PERFORMANCE_KPI) return;
    const typingEntries = entries
      .filter((entry) => {
        const delay = entry.processingStart - entry.startTime;
        return delay >= observerThreshold && ['keydown'].includes(entry.name);
      })
      .sort((a, b) => a.processingEnd - a.startTime - (b.processingEnd - b.startTime));
    if (typingEntries.length === 0) return;

    const worstCase = typingEntries[typingEntries.length - 1];
    const delay = worstCase.processingEnd - worstCase.startTime;
    const occurrences = typingEntries.length;

    const tx = this.startSentryTransaction({ name: PerformanceKPITypes.TYPING });
    if (tx) {
      Sentry.setMeasurement('worst', delay, 'millisecond', tx);
      Sentry.setMeasurement('occurrences', occurrences, 'none', tx);
      tx.end();
    }
  };

  initializeResponsivenessObserver = () => {
    const DEFAULT_OBSERVER_THRESHOLD_MS =
      this.stores.featureStore.featureFlags?.SENTRY_RESPONSIVENESS_THRESHOLD_MS ?? 120;
    const responsivenessObserver = new PerformanceObserver((list) => {
      const entries = list.getEntries();
      this.observeTypingPerformance(entries, { observerThreshold: DEFAULT_OBSERVER_THRESHOLD_MS });
      this.observeGeneralPerformance(entries, { observerThreshold: DEFAULT_OBSERVER_THRESHOLD_MS });
    });

    try {
      responsivenessObserver.observe({
        type: 'event',
        buffered: true,
        durationThreshold: DEFAULT_OBSERVER_THRESHOLD_MS,
      });
    } catch (e) {
      console.error(`TrackerStore: Could not instantiate PerformanceObserver: ${e}`);
    }
  };

  performanceKpiKey = (type, id = null) => `${type}${id ? `_${id}` : ''}`;

  performanceKpiStart = (type, options = {}) => {
    if (!this.stores.featureStore.featureFlags?.SENTRY_PERFORMANCE_KPI) return;
    const { id, attrs = {} } = options;
    const key = this.performanceKpiKey(type, id);
    const span = Sentry.startInactiveSpan({ name: type });
    for (const [key, value] of Object.entries(attrs)) {
      span?.setAttribute(key, value);
    }
    this.performanceKpiTracking[key] = { span, start: Date.now() };
  };

  performanceKpiEnd = (type, id = null) => {
    if (!this.stores.featureStore.featureFlags?.SENTRY_PERFORMANCE_KPI) return;
    const key = this.performanceKpiKey(type, id);
    if (!this.performanceKpiTracking[key]) return;
    const { span, start = Date.now() } = this.performanceKpiTracking[key];
    const duration = Date.now() - start;
    const startSeconds = span?.startTimestamp || 0;
    const durationSeconds = duration / 1000;
    const finishSeconds = startSeconds + durationSeconds;
    span?.end(finishSeconds);
    delete this.performanceKpiTracking[key];
  };

  sentryBeforeSendError = (event) => {
    const { SENTRY_HANDLED_ERROR_SAMPLE_RATE = 0.5, SENTRY_UNHANDLED_ERROR_SAMPLE_RATE = 0.5 } =
      this.stores.featureStore?.featureFlags || {};

    const isHandled = event.exception?.values?.every((v) => v.mechanism?.handled);
    const sampleRate = isHandled
      ? SENTRY_HANDLED_ERROR_SAMPLE_RATE
      : SENTRY_UNHANDLED_ERROR_SAMPLE_RATE;
    return Math.random() >= 1 - sampleRate ? event : null;
  };

  sentryTracesSampler = ({ name, attributes = {} }) => {
    const { 'sentry.origin': origin = '' } = attributes;
    const {
      SENTRY_PERFORMANCE_COMPOSE_SAMPLE_RATE = 0.05,
      SENTRY_PERFORMANCE_MESSAGE_SEND_SAMPLE_RATE = 0.05,
      SENTRY_PERFORMANCE_TYPING_DELAY_SAMPLE_RATE = 0.05,
      SENTRY_LOGIN_TIMING_SAMPLE_RATE = 0.05,
      SENTRY_PROFILING_SAMPLE_RATE = 0,
    } = this.stores.featureStore?.featureFlags || {};
    let sampleRate = 1;

    if (origin.includes('pageload')) {
      sampleRate = 0;
    } else if (origin.includes('.inp')) {
      sampleRate = SENTRY_PROFILING_SAMPLE_RATE;
    } else if (name === PerformanceKPITypes.COMPOSE) {
      sampleRate = SENTRY_PERFORMANCE_COMPOSE_SAMPLE_RATE;
    } else if (name === PerformanceKPITypes.MESSAGE_SEND) {
      sampleRate = SENTRY_PERFORMANCE_MESSAGE_SEND_SAMPLE_RATE;
    } else if (name === PerformanceKPITypes.LOGIN) {
      sampleRate = SENTRY_LOGIN_TIMING_SAMPLE_RATE;
    } else if (name === PerformanceKPITypes.TYPING) {
      sampleRate = SENTRY_PERFORMANCE_TYPING_DELAY_SAMPLE_RATE;
    }

    return sampleRate;
  };
}
