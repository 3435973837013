import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Switch } from '../WebComponents';
import Bounce from '../../../models/enums/Bounce';
import BEM from '../../bem';
import { ReactComponent as DownArrow } from '../../images/down-arrow.svg';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { ContextMenu, MenuItemList, MenuItem } from '../ContextMenu';

const classes = BEM.with('DesktopAppSettings');

class DesktopAppSettings extends Component {
  static propTypes = {
    bounceDockIcon: PropTypes.string.isRequired,
    customSound: PropTypes.number.isRequired,
    customSoundOptions: PropTypes.array.isRequired,
    playSounds: PropTypes.bool.isRequired,
    setBounceDockIcon: PropTypes.func.isRequired,
    setCustomSound: PropTypes.func.isRequired,
    setPlaySounds: PropTypes.func.isRequired,
    setScrollbarsElement: PropTypes.func.isRequired,
    setShowNotifications: PropTypes.func.isRequired,
    showNotifications: PropTypes.bool.isRequired,
    supportsCustomSounds: PropTypes.bool.isRequired,
  };

  state = {
    soundSelectActive: false,
  };

  render() {
    const { bounceDockIcon, customSound, playSounds, showNotifications, supportsCustomSounds } =
      this.props;
    let { customSoundOptions } = this.props;

    customSoundOptions = customSoundOptions.map(
      (customSoundOption) =>
        customSoundOption[0].toUpperCase() + customSoundOption.slice(1).replace('.mp3', '')
    );

    return (
      <div className={classes()} ref={this._setScrollbarsElement}>
        <Card
          header="DESKTOP APP SETTINGS"
          headerRight={
            <span className={classes('da-lead')}>Will only work on the desktop app</span>
          }
        >
          <div className={classes('info')}>
            <div className={classes('info-header')}>NOTIFICATIONS</div>
            <div className={classes('switch')}>
              <Switch
                checked={showNotifications}
                onSwitch={this._toggleShowNotifications}
                dataTestId="showNotifications"
              />
            </div>
          </div>
          <div className={classes('info')}>
            <div className={classes('info-header')}>BOUNCE ICON</div>
            <div className={classes('switch')}>
              <Switch
                checked={bounceDockIcon !== Bounce.NEVER}
                onSwitch={this._toggleBounceIcon}
                dataTestId="bounceDockIcon"
              />
            </div>
          </div>
          <div className={classes('info')}>
            <div className={classes('info-header')}>SOUNDS</div>
            <div className={classes('switch')}>
              <Switch
                checked={playSounds}
                onSwitch={this._togglePlaySounds}
                dataTestId="playSounds"
              />
            </div>
          </div>
          {playSounds && supportsCustomSounds && (
            <div className={classes('info')}>
              <div className={classes('info-header')}>CHANGE SOUND</div>
              <div className={classes('sound-select')}>
                <ContextMenu
                  event="click"
                  theme="vertical"
                  position="bottominnerleft"
                  offsetY={1}
                  onShow={() => this.setState({ soundSelectActive: true })}
                  onHide={() => this.setState({ soundSelectActive: false })}
                  menu={
                    <MenuItemList>
                      {customSoundOptions.map((value, index) => (
                        <MenuItem
                          key={value}
                          onClick={() => this._handleSoundSelect(index)}
                          ariaLabel={value}
                        >
                          {value}
                        </MenuItem>
                      ))}
                    </MenuItemList>
                  }
                >
                  <div
                    className={classes('svg-box', {
                      isActive: this.state.soundSelectActive,
                    })}
                  >
                    {customSoundOptions[customSound]}
                    <div className={classes('svg')}>
                      <DownArrow />
                    </div>
                  </div>
                </ContextMenu>
              </div>
            </div>
          )}
        </Card>
      </div>
    );
  }

  _handleSoundSelect = (value) => {
    const { setCustomSound } = this.props;
    setCustomSound(value);
  };

  _setScrollbarsElement = (ref) => {
    this.props.setScrollbarsElement(ref, 'DASettings');
  };

  _toggleShowNotifications = () => {
    const { setShowNotifications, showNotifications } = this.props;
    setShowNotifications(!showNotifications);
  };

  _toggleBounceIcon = () => {
    const { bounceDockIcon, setBounceDockIcon } = this.props;
    setBounceDockIcon(bounceDockIcon === Bounce.NEVER ? Bounce.UNREAD : Bounce.NEVER);
  };

  _togglePlaySounds = () => {
    const { playSounds, setPlaySounds } = this.props;
    setPlaySounds(!playSounds);
  };
}

export default mobxInjectSelect({
  desktopAppStore: [
    'bounceDockIcon',
    'customSound',
    'customSoundOptions',
    'playSounds',
    'setBounceDockIcon',
    'setCustomSound',
    'setPlaySounds',
    'setShowNotifications',
    'showNotifications',
    'supportsCustomSounds',
  ],
  profileStore: ['setScrollbarsElement'],
})(DesktopAppSettings);
