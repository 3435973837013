import React, { useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { Switch } from '../../WebComponents';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import BEM from '../../../bem';
import propTypes from '../../../propTypes';
import { mobxInjectSelect } from '../../../utils';
import { AppTypes, BroadcastListOrigins, PatientCSVUploadTypes } from '../../../../models/enums';
import { ReactComponent as UploadButtonSvg } from '../../../images/upload.svg';
import { ReactComponent as BroadcastIconSvg } from '../../../images/broadcast-big.svg';
import { ReactComponent as BroadcastPregenSvg } from '../../../images/broadcast-pregen-big.svg';
import { ReactComponent as BroadcastAdSecurityGroupSvg } from '../../../images/broadcast-adSecurityGroup-big.svg';
import { ReactComponent as BroadcastListUploadIndicatorSvg } from '../../../images/icon_active_indicator_blue.svg';
import { Dropdown, InlineEditor } from '../../';
import {
  createProviderBroadcastList,
  updateProviderBroadcastList,
} from '../../../../contexts/BroadcastListSettings';
import ShareBroadcastListSearchBox from '../../BroadcastListSettings/ShareBroadcastListSearchBox';

const DATE_FORMAT = 'MMM d, h:mm a';
const DATE_FORMAT_WITH_DAY_AND_TIME = 'EEEE, MMMM do, yyyy hh:mm a';

const classes = BEM.with('PatientBroadcastFormHeader');
const PatientBroadcastFormHeader = ({
  broadcastListDetails,
  createBroadcastList,
  currentAppSelected,
  currentOrganizationId,
  currentUserId,
  isAdmin,
  isAsyncCsvUpload,
  isADSync,
  isPregen,
  isSaving,
  listId,
  listError,
  openModal,
  openUploadPatientsCsvModal,
  origin,
  peopleCount,
  resetForm,
  saveADSyncListName,
  setBroadcastListDetails,
  setIsADSync,
  setIsSaving,
  setListError,
  updateBroadcastList,
}) => {
  const {
    adminOnly = false,
    createdBy,
    createdOn,
    displayName: listName,
    isAdSync,
    securityGroupName,
    syncStatus,
    updatedOn,
  } = broadcastListDetails;

  const shareButtonRef = useRef(null);
  const [isShareDropdownOpen, setIsShareDropdownOpen] = useState(false);
  const [currentListId, setCurrentListId] = useState(listId);
  let syncStatusMessageFragment;

  const listCreatedBy = createdBy
    ? currentUserId === createdBy.id
      ? 'you'
      : createdBy.displayName
    : currentAppSelected === AppTypes.BROADCAST_LISTS
    ? ''
    : 'you';

  if (syncStatus === 'in_progress') {
    syncStatusMessageFragment = <span> Directory Sync In Progress </span>;
  } else if (syncStatus === 'success') {
    syncStatusMessageFragment = (
      <span> Last updated on {format(parseISO(updatedOn), DATE_FORMAT_WITH_DAY_AND_TIME)} </span>
    );
  } else {
    syncStatusMessageFragment = <span> Awaiting Directory Sync </span>;
  }

  const saveBroadcastListName = async (name) => {
    if (name.trim() === '') {
      setListError('Broadcast list must have a unique label.');
      return;
    }
    try {
      setIsSaving(true);
      if (currentListId) {
        currentAppSelected === AppTypes.BROADCAST_LISTS
          ? await updateProviderBroadcastList({
              adminOnly,
              id: currentListId,
              name,
              currentOrganizationId,
            })
          : await updateBroadcastList({
              adminOnly,
              id: currentListId,
              name,
            });
        setBroadcastListDetails({
          ...broadcastListDetails,
          displayName: name,
        });
      } else {
        const list =
          currentAppSelected === AppTypes.BROADCAST_LISTS
            ? await createProviderBroadcastList({
                adminOnly,
                name,
                currentOrganizationId,
                isAdSync: isADSync,
              })
            : await createBroadcastList({
                adminOnly,
                name,
              });
        setBroadcastListDetails({
          ...broadcastListDetails,
          displayName: name,
        });
        setCurrentListId(list.id);
        resetForm(list.id);
        if (isADSync) saveADSyncListName(name, list.id);
      }
      setListError('');
      if (isADSync) setIsADSync(false);
      return true;
    } catch (err) {
      if (err.response) {
        const { error, status } = err.response.body;
        const { message } = error;
        if (status === 'fail' && (message === 'name_duplicated' || message === 'invalid_dl_name')) {
          setListError('Broadcast list must have a unique label.');
          return false;
        }
      } else if (err.message === 'name must be less than 60 alphanumeric characters') {
        setListError('Broadcast list be use between 1 and 60 alphanumeric and special characters.');
        return false;
      }
      console.error(err);
      openModal('patientAdminFailure', {
        body: 'Unable to create broadcast list. Please try again.',
      });
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  const togglePermission = async () => {
    try {
      if (currentListId && !isSaving) {
        setIsSaving(true);
        const newAdminOnly = !adminOnly;
        await updateBroadcastList({
          adminOnly: newAdminOnly,
          id: currentListId,
        });
        setBroadcastListDetails({
          ...broadcastListDetails,
          adminOnly: newAdminOnly,
        });
      }
    } catch (err) {
      console.error(err);
      openModal('failure');
    } finally {
      setIsSaving(false);
    }
  };

  const toggleShare = () => {
    setIsShareDropdownOpen(!isShareDropdownOpen);
  };

  const uploadPatientsCsv = () => {
    openUploadPatientsCsvModal(PatientCSVUploadTypes.BROADCAST, listId);
  };

  return (
    <div className={classes()}>
      {listError && <div className={classes('list-error')}>{listError}</div>}
      <div className={classes('header-wrapper')}>
        <div className={classes('left-wrapper')}>
          <div className={classes('header-content')}>
            {securityGroupName && (
              <div className={classes('sync-status', { syncFailed: syncStatus === 'failure' })}>
                <BroadcastListUploadIndicatorSvg className={classes('sync-status-icon')} />
                {syncStatusMessageFragment}
              </div>
            )}
          </div>
          <div className={classes('header-content-list-information')}>
            <div className={classes('header')}>
              <div>
                {isPregen ? (
                  <BroadcastPregenSvg />
                ) : isAdSync || isADSync ? (
                  <BroadcastAdSecurityGroupSvg />
                ) : (
                  <BroadcastIconSvg />
                )}
              </div>
              <div className={classes('group-main-details')}>
                {isPregen || !isAdmin ? (
                  <span className={classes('broadcast-list-name')}>{listName}</span>
                ) : (
                  <InlineEditor
                    classesName="PatientBroadcastFormHeader"
                    listError={listError}
                    openedEditor={!currentListId}
                    placeholder="Broadcast Name"
                    save={saveBroadcastListName}
                    saveOnBlur
                    value={listName.trim()}
                    isADSync={isADSync || !!securityGroupName}
                  />
                )}
                <div className={classes('group-people-count')}>
                  {peopleCount} {peopleCount === 1 ? 'person' : 'people'}
                </div>
              </div>
            </div>
            <div className={classes('group-creator')}>
              {listCreatedBy !== '' ? (
                <>
                  Created by {listCreatedBy}{' '}
                  {createdOn ? `on ${format(parseISO(createdOn), DATE_FORMAT)}` : ''}
                </>
              ) : (
                ''
              )}
            </div>
            {isAdmin && (
              <div className={classes('permission-details')}>
                <div className={classes('admin-only')}>
                  {currentAppSelected === AppTypes.BROADCAST_LISTS ? 'Limited' : 'Admin Only'}
                </div>
                <div className={classes('admin-only-button')} data-test-id={'admin-only-button'}>
                  <Switch
                    ariaLabel="Toggle switch Admin Only"
                    disabled={!listId && !isSaving}
                    checked={adminOnly}
                    onSwitch={togglePermission}
                    dataTestId="blAdminOnly"
                  />
                </div>
                {currentAppSelected === AppTypes.BROADCAST_LISTS && adminOnly && (
                  <>
                    <button
                      className={classes('share-button')}
                      ref={shareButtonRef}
                      onClick={toggleShare}
                    >
                      SHARE <span className={classes('share-button-arrow')}></span>
                    </button>
                    {isShareDropdownOpen && (
                      <div className={classes('share-dropdown-container')}>
                        <Dropdown
                          ariaLabel={'share-broadcast-list-share-box'}
                          triggerHandler={toggleShare}
                          triggerRef={shareButtonRef}
                        >
                          <ShareBroadcastListSearchBox listId={listId} />
                        </Dropdown>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={classes('right-wrapper')}>
          {isAsyncCsvUpload && origin === BroadcastListOrigins.PATIENT && (
            <ToolTip text="Upload Patients" isPatientFacing>
              <button aria-label="Upload patients" onClick={uploadPatientsCsv}>
                <UploadButtonSvg />
              </button>
            </ToolTip>
          )}
        </div>
      </div>
    </div>
  );
};

PatientBroadcastFormHeader.propTypes = {
  broadcastListDetails: PropTypes.shape({
    adminOnly: PropTypes.bool,
    createdBy: propTypes.user,
    createdOn: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  createBroadcastList: PropTypes.func.isRequired,
  currentAppSelected: PropTypes.string,
  currentOrganizationId: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isAsyncCsvUpload: PropTypes.bool.isRequired,
  isPregen: PropTypes.bool,
  isADSync: PropTypes.bool,
  setIsADSync: PropTypes.func,
  listId: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  origin: PropTypes.string,
  peopleCount: PropTypes.number,
  resetForm: PropTypes.func.isRequired,
  setBroadcastListDetails: PropTypes.func.isRequired,
  updateBroadcastList: PropTypes.func.isRequired,
};

PatientBroadcastFormHeader.defaultProps = {
  isADSync: false,
};

export default mobxInjectSelect({
  messengerStore: ['currentOrganizationId'],
  modalStore: ['openModal'],
  patientAdminStore: ['createBroadcastList', 'isAsyncCsvUpload', 'updateBroadcastList'],
  patientStore: ['openUploadPatientsCsvModal'],
  sessionStore: ['currentUserId'],
})(PatientBroadcastFormHeader);
