import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import BEM from '../../bem';
import { AvatarPreview, AvatarEditMenu, AvatarUploadButton } from './';

const classes = BEM.with('GroupAvatarDropzone');

export default class GroupAvatarDropzone extends Component {
  static propTypes = {
    onAvatarSelected: PropTypes.func.isRequired,
    setAvatarHovered: PropTypes.func.isRequired,
    avatarPreviewUrl: PropTypes.string,
    removeAvatar: PropTypes.func.isRequired,
    isAvatarHovered: PropTypes.bool.isRequired,
  };

  state = {
    isAvatarMenuOpen: false,
  };

  render() {
    const { onAvatarSelected, setAvatarHovered, avatarPreviewUrl, removeAvatar, isAvatarHovered } =
      this.props;
    const { isAvatarMenuOpen } = this.state;

    return (
      <Dropzone
        className={classes()}
        disableClick
        multiple={false}
        onDropAccepted={onAvatarSelected}
        accept="image/*"
        ref={this._setDropzone}
      >
        {() =>
          avatarPreviewUrl ? (
            <div
              onMouseEnter={() => setAvatarHovered(true)}
              onMouseLeave={() => setAvatarHovered(false)}
              ref={this._setAvatarHoverTarget}
            >
              {!isAvatarHovered && !isAvatarMenuOpen ? (
                <AvatarPreview avatarPreviewUrl={avatarPreviewUrl} />
              ) : (
                <AvatarEditMenu
                  changeAvatar={this._changeAvatar}
                  removeAvatar={removeAvatar}
                  setAvatarMenuState={this._setAvatarMenuState}
                  isAvatarMenuOpen={isAvatarMenuOpen}
                  avatarHoverTarget={this.avatarHoverTarget}
                />
              )}
            </div>
          ) : (
            <div onClick={this._changeAvatar}>
              <AvatarUploadButton />
            </div>
          )
        }
      </Dropzone>
    );
  }

  _setAvatarHoverTarget = (ref) => {
    this.avatarHoverTarget = ref;
  };

  _setDropzone = (ref) => {
    this.dropzone = ref;
  };

  _changeAvatar = () => {
    this.dropzone.open();
  };

  _setAvatarMenuState = (isAvatarMenuOpen) => {
    this.setState({ isAvatarMenuOpen });
  };
}
