import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HotKeys } from 'react-hotkeys';
import { Button } from '../../WebComponents';
import HotKeyActions from '../../../../models/enums/HotKeyActions';
import BEM from '../../../bem';
import { passwordValidation, mobxInjectSelect } from '../../../utils';
import { RemoveChangesButton } from '../';
import { ValidateNewPassword, ValidateReEnterNewPassword } from './';

const classes = BEM.with('ChangePassword');

class ChangePassword extends Component {
  static propTypes = {
    onCloseEditMode: PropTypes.func.isRequired,
    scrollToElement: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
  };

  state = {
    draftPassword: {
      current: '',
      new: '',
      reEnterNew: '',
    },
    draftValidate: {
      current: false,
      new: false,
      reEnterNew: false,
    },
    focus: {
      current: false,
      new: false,
      reEnterNew: false,
    },
  };

  constructor(...args) {
    super(...args);

    this.handlers = {
      [HotKeyActions.ESCAPE]: this._removeChanges,
    };
  }

  componentDidMount() {
    this.focus();
  }

  render() {
    const { draftPassword, draftValidate, focus } = this.state;

    return (
      <HotKeys className={classes()} handlers={this.handlers}>
        <div className={classes('container')}>
          <div className={classes('header')}>CURRENT PASSWORD</div>
          <form className={classes('form-current')}>
            <input
              className={classes('input')}
              onBlur={this._onBlur('current')}
              onChange={this._onChange('current')}
              onFocus={this._onFocus('current')}
              placeholder="Enter your current password"
              ref={this._setInput}
              tabIndex={4}
              type="password"
              value={draftPassword.current}
            />
          </form>
        </div>
        <div className={classes('container')}>
          <div className={classes('header')}>NEW PASSWORD</div>
          <form className={classes('form', { new: draftValidate.new })}>
            <input
              className={classes('input')}
              onBlur={this._onBlur('new')}
              onChange={this._onChange('new')}
              onFocus={this._onFocus('new')}
              placeholder="Enter your new password"
              tabIndex={5}
              type="password"
              value={draftPassword.new}
            />
            <ValidateNewPassword focus={focus} value={draftPassword.new} />
          </form>
        </div>
        <div className={classes('container')}>
          <div className={classes('header')}>RE-ENTER PASSWORD</div>
          <form
            className={classes('form', {
              reEnterNew: draftValidate.reEnterNew,
            })}
          >
            <input
              className={classes('input')}
              onBlur={this._onBlur('reEnterNew')}
              onChange={this._onChange('reEnterNew')}
              onFocus={this._onFocus('reEnterNew')}
              placeholder="Re-enter your new password"
              tabIndex={6}
              type="password"
              value={draftPassword.reEnterNew}
            />
            {!draftValidate.reEnterNew && <ValidateReEnterNewPassword focus={focus} />}
          </form>
        </div>
        <div className={classes('buttons-container')}>
          <RemoveChangesButton removeChanges={this._removeChanges} />
          <Button
            label="SAVE"
            outline
            onClick={this._submit}
            disabled={
              draftPassword.current.length === 0 || !draftValidate.new || !draftValidate.reEnterNew
            }
          />
        </div>
      </HotKeys>
    );
  }

  _setInput = (ref) => {
    this.focusTarget = ref;
  };

  blur() {
    this.focusTarget && this.focusTarget.blur();
  }

  focus() {
    this.focusTarget && this.focusTarget.focus();
  }

  _onBlur = (field) => {
    return () => {
      this.setState({ focus: { ...this.state.focus, [field]: false } });
    };
  };

  _onChange = (field) => {
    return (e) => this._updateDraftField(field, e.target.value);
  };

  _onFocus = (field) => {
    const { scrollToElement } = this.props;
    return () => {
      this.setState({ focus: { ...this.state.focus, [field]: true } });
      scrollToElement('AccountSettings', { offset: 25, shouldSpring: false });
    };
  };

  _updateDraftField = (field, value) => {
    this.setState({
      draftPassword: { ...this.state.draftPassword, [field]: value },
    });

    const newValue = field === 'new' ? value : this.state.draftPassword.new;
    const reEnterNewValue = field === 'reEnterNew' ? value : this.state.draftPassword.reEnterNew;

    this.setState({
      draftValidate: {
        new: this._testValue('new', newValue, reEnterNewValue),
        reEnterNew: this._testValue('reEnterNew', newValue, reEnterNewValue),
      },
    });
  };

  _testValue = (field, newValue, reEnterNewValue) => {
    if (field === 'reEnterNew') {
      return newValue === reEnterNewValue && passwordValidation(reEnterNewValue, false);
    }

    return passwordValidation(newValue, false);
  };

  _removeChanges = () => {
    const { onCloseEditMode } = this.props;

    this.setState({
      draftPassword: {
        current: '',
        new: '',
        reEnterNew: '',
      },
    });

    onCloseEditMode();
  };

  _submit = async () => {
    const { updatePassword } = this.props;
    const { draftPassword } = this.state;
    const isSuccess = await updatePassword(draftPassword.current, draftPassword.new);
    if (isSuccess) this._removeChanges();
  };
}

export default mobxInjectSelect({
  profileStore: ['scrollToElement'],
  userStore: ['updatePassword'],
})(ChangePassword);
