import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Card } from '../../../components/WebComponents';
import { mobxInjectSelect } from '../../../utils';
import { Organization, TeamUpdateProps, User, Team } from '../../../../types';
import { TEAMS_SEARCH_TYPES } from '../../../../models/enums/SearchTypes';

import BEM from '../../../bem';
import RecipientSearchPicker from '../../RecipientSearchPicker';
import EntityEditorCreateButtons from '../Entities/EntityEditorCreateButtons';
import useClickOutside from '../../../hooks/useClickOutside';
import { ReduxState } from '../../../../redux-stores';
import TeamEditorMemberLineItem from './TeamEditorMemberLineItem';

const editorClasses = BEM.with('CollaborationEntityEditor');
const classes = BEM.with('CollaborationTeamEditorMembers');

type MobxProps = {
  currentOrganization: Organization;
};

type TeamEditorMembersProps = {
  canCreateTeam: string | boolean | undefined;
  isNewTeam: boolean;
  isTeamAdmin: boolean;
  maxSpaces: number;
  memberSpaces: number;
  memberSpacesExceeded: boolean;
  persistTeam: (props: TeamUpdateProps) => void;
  setMemberSpaces: (length: number) => void;
  team: Team | undefined;
  teamMembers: User[];
};

type EditorType = '' | 'membersEditor' | 'viewAllMembers';

function NewTeamEditorList({
  children,
  header,
  headerRight,
}: {
  children?: React.ReactNode;
  header: string;
  headerRight: React.ReactNode;
}) {
  return (
    <div className={editorClasses('sub-container', { isNewTeam: true })}>
      <div className={classes('header', { isNewTeam: true })}>
        <div className={classes('team-description-text')}>
          <div className={classes('text')}>{header}</div>
          {headerRight}
        </div>
      </div>
      {children}
    </div>
  );
}

function TeamEditorMembers({
  canCreateTeam,
  currentOrganization,
  isNewTeam,
  isTeamAdmin,
  maxSpaces,
  memberSpaces,
  memberSpacesExceeded,
  persistTeam,
  setMemberSpaces,
  team,
  teamMembers,
}: TeamEditorMembersProps & MobxProps) {
  const [members, setMembers] = useState<User[]>([]);
  const [editor, setEditor] = useState<EditorType>('');
  const isRolesEnabled = useSelector((state: ReduxState) => state.collab.isRolesEnabled);
  const [showMemberSpacesInfo, setShowMemberSpacesInfo] = useState(false);
  const memberSpacesRef = useRef(null);
  const createTeamsRequirements = team?.id
    ? 'Team must use between 1 and 98 spaces.'
    : 'Team must have a name and use between 1 and 98 spaces.';
  const limitNumberOfMembers = editor === '' && !isNewTeam ? members.slice(0, 4) : members;
  const teamMembersLength = members.length > 0;
  const createTeam = isNewTeam ? canCreateTeam : teamMembersLength;
  const showViewAll = !editor && !isNewTeam && members.length > 4;
  const memberLength = members?.length || 0;
  const isEditingOrCreating = editor === 'membersEditor' || isNewTeam;

  useEffect(() => {
    setEditor('');
  }, [isNewTeam, team]);

  useEffect(() => {
    setMembers(teamMembers || []);
  }, [teamMembers]);

  useEffect(() => {
    setMemberSpaces(
      members.reduce((m, u) => m + (u.isRoleBot || u.$entityType === 'role' ? 2 : 1), 0)
    );
  }, [members, setMemberSpaces]);

  useClickOutside([memberSpacesRef], () => {
    if (!showMemberSpacesInfo) return;
    setShowMemberSpacesInfo(false);
  });

  const updateMembers = (selectedMembers: User[], isDelete = false) => {
    let newMembers: User[];
    if (isDelete) {
      newMembers = members.filter((m: User) => m.id !== selectedMembers[0].id);
    } else {
      newMembers = [...members, ...selectedMembers];
    }
    const memberIds = newMembers?.map(({ id }) => id);

    if (isNewTeam) {
      persistTeam({
        members: newMembers,
        memberIds,
      });
    }

    setMembers(newMembers);
  };

  const resetMembers = () => {
    setEditor('');
    setMembers(teamMembers || []);
  };

  const requestSaveTeam = () => {
    const newMembers = members || teamMembers;
    const memberIds = newMembers?.map(({ id }) => id);

    persistTeam({ members: newMembers, memberIds });

    setEditor('');
  };

  const editorTitleAndPicker = (
    <>
      <div className={classes('form-row')}>
        <div className={classes('members-row')}>
          <div className={classes('members')}>
            <RecipientSearchPicker
              className={'add-team-members'}
              excludeRoles={false}
              isOpenOnFocus={false}
              multi={true}
              onChange={updateMembers}
              organization={currentOrganization}
              placeholder={`Search for members`}
              excludeIds={members?.map((user: User) => user.id)}
              excludeSelf={false}
              searchTypes={TEAMS_SEARCH_TYPES}
              centeredOuterMenu={true}
              tabIndex={2}
              excludeIntraTeams={true}
              excludeTeams={true}
            />
          </div>
        </div>
      </div>
    </>
  );

  const showEditorTitleAndPicker =
    (editor === 'membersEditor' || isNewTeam) && editorTitleAndPicker;
  const showMembersEditor = ((!editor && !isNewTeam) || editor === 'viewAllMembers') && isTeamAdmin;
  const ContentContainer = isNewTeam ? NewTeamEditorList : Card;
  const contentContainerProps = {
    header: `TEAM MEMBERS${!isEditingOrCreating ? ` (${memberLength})` : ''}`,
    headerRight: isEditingOrCreating && (
      <div
        className={classes('text', {
          count: true,
          overLimit: memberSpacesExceeded,
        })}
      >
        {memberSpaces}/{maxSpaces} Spaces
        {isRolesEnabled && (
          <div
            onClick={() => {
              setShowMemberSpacesInfo(!showMemberSpacesInfo);
            }}
            className={classes('info-icon', { active: showMemberSpacesInfo })}
          >
            ?
          </div>
        )}
        {showMemberSpacesInfo && (
          <div className={classes('member-spaces-outer-container')}>
            <div ref={memberSpacesRef} className={classes('member-spaces-inner-container')}>
              <div className={classes('header')}>Member Spaces</div>
              <div className={classes('content')}>
                <div> Users will fill 1 space </div>
                <div> Roles will fill 2 spaces </div>
              </div>
            </div>
          </div>
        )}
      </div>
    ),
  };

  return (
    <div className={classnames([editorClasses(''), classes('')])}>
      <div className={editorClasses('card-container')}>
        <ContentContainer {...contentContainerProps}>
          <div
            className={classnames([
              classes('container', { editing: editor === 'membersEditor' || isNewTeam }),
            ])}
          >
            {(showEditorTitleAndPicker || showMembersEditor) && (
              <div className={classes('header')}>
                {showEditorTitleAndPicker && editorTitleAndPicker}
                {showMembersEditor && (
                  <div
                    className={classes('team-editor-button')}
                    onClick={() => {
                      setEditor('membersEditor');
                    }}
                  >
                    Edit Members
                  </div>
                )}
              </div>
            )}
            <div
              className={classes('members-wrapper', {
                isNewTeamOrEditorSection: editor === 'membersEditor' || isNewTeam,
                viewAllMembers: editor === 'viewAllMembers',
              })}
            >
              <div className={classes('member-list-row')}>
                <div className={classes('selected-members-row', { showViewAll })}>
                  {teamMembersLength &&
                    limitNumberOfMembers?.map((user) => (
                      <TeamEditorMemberLineItem
                        key={user.id}
                        editor={editor}
                        isNewTeam={isNewTeam}
                        member={user}
                        removeMember={(u: User) => updateMembers([u], true)}
                      />
                    ))}
                </div>
              </div>
              {showViewAll && (
                <button
                  className={classes('view-all-members')}
                  onClick={() => {
                    setEditor('viewAllMembers');
                  }}
                >
                  View all
                </button>
              )}
              {!createTeam && (
                <div className={classes('require-members-text-row')}>{createTeamsRequirements}</div>
              )}
              {editor === 'membersEditor' && (
                <EntityEditorCreateButtons
                  type="team"
                  createEnabled={!!createTeam && !memberSpacesExceeded}
                  onCreate={requestSaveTeam}
                  createButtonText={'Save'}
                  onCancel={resetMembers}
                />
              )}
            </div>
          </div>
        </ContentContainer>
      </div>
    </div>
  );
}

export default mobxInjectSelect<TeamEditorMembersProps, MobxProps>({
  messengerStore: ['currentOrganization'],
})(TeamEditorMembers);
