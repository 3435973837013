import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../utils';
import BEM from '../bem';
import VideoCallButton from './Call/VideoCallButton';
import PfCallOrigins from './../../models/enums/PfCallOrigins';
import { EntityAvatar, SmsOptedOut } from './';

const classes = BEM.with('PatientContactSearchResultDetails');
const AVATAR_SIZE = 25;

class PatientContactSearchResultDetails extends Component {
  static propTypes = {
    currentOrganizationId: PropTypes.string.isRequired,
    entity: PropTypes.object.isRequired,
    isAddFamilyAndContacts: PropTypes.bool.isRequired,
    isComposing: PropTypes.bool.isRequired,
    isSearching: PropTypes.bool.isRequired,
    isPFVideoCallEnabled: PropTypes.bool.isRequired,
  };

  render() {
    const {
      currentOrganizationId,
      entity,
      isAddFamilyAndContacts,
      isComposing,
      isSearching,
      isPFVideoCallEnabled,
    } = this.props;
    const contactDisplayName = entity.displayName;
    const { patientContact } = entity;

    if (patientContact.organizationId !== currentOrganizationId) return null;

    const { smsOptedOut } = patientContact;
    const disableSelection =
      smsOptedOut && (isComposing || !patientContact.sharedConversations.length);
    let displayNameFragment;

    if (!isAddFamilyAndContacts) {
      displayNameFragment = (
        <div>
          <div className={classes('patient-relation', { smsOptedOut })}>
            {entity.patientContact.relation}
          </div>
          <div className={classes('contact-name', { smsOptedOut })}>{contactDisplayName}</div>
        </div>
      );
    } else {
      displayNameFragment = (
        <div className={classes('contact-information')}>
          <div className={classes('avatar')}>
            <EntityAvatar
              className={classes('avatar-class-information', { smsOptedOut })}
              entity={entity}
              size={AVATAR_SIZE}
            />
          </div>
          <div className={classes('contact-details', { isAddFamilyAndContacts, smsOptedOut })}>
            <div className={classes('contact-name-information', { smsOptedOut })}>
              {contactDisplayName}
            </div>
            <div className={classes('patient-relation-information', { smsOptedOut })}>
              {entity.patientContact.relation}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={classes()}>
        <div className={classes('divider', { isAddFamilyAndContacts })} />
        <div
          className={classes('wrapper', {
            smsOptedOut,
            disableSelection,
            isAddFamilyAndContacts,
          })}
        >
          <div className={classes('patient-space-box', { isAddFamilyAndContacts })} />
          <div className={classes('patient-contact-box', { disableSelection })}>
            <div
              className={classes('patient-contact-box-hover', {
                isAddFamilyAndContacts,
                smsOptedOut,
              })}
            >
              {displayNameFragment}
              {smsOptedOut && <SmsOptedOut isContact tooltip={!isAddFamilyAndContacts} />}
            </div>
            {isPFVideoCallEnabled && !smsOptedOut && isSearching && (
              <div className={classes('call')}>
                <VideoCallButton
                  entity={{
                    recipientId: entity.id,
                    recipientName: contactDisplayName,
                  }}
                  isSmall={true}
                  origin={PfCallOrigins.SEARCH}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  callStore: ['isPFVideoCallEnabled'],
  composeMessageStore: ['isAddFamilyAndContacts', 'isComposing'],
  messengerStore: ['currentOrganizationId', 'isSearching'],
})(PatientContactSearchResultDetails);
