import EventEmitter from 'events';
import { action } from 'mobx';
import _ from 'lodash';
import * as models from '../models';
import Timings from '../models/enums/Timings';
import EntityListStore from './EntityListStore';

const ACTIONS = ['afterInject', 'beforeEject', 'afterEject'];
const ALL_MODELS = [];

export const MAX_QUEUES_PROCESS_TIME = 500;
export const MAX_QUEUES_PROCESS_TIME_LOGIN = 1000;

export default class EntityStore {
  constructor({ client, stores }) {
    const modelClasses = Object.values(models);

    for (const modelClass of modelClasses) {
      const model = new EntityListStore(modelClass, { entityStore: this });
      this[modelClass.entityType] = model;
      ALL_MODELS.push(model);
    }

    this._actionHandlers = [];
    this._batchAvailable = false;
    this._drainResolvers = [];
    this._processedEventsCount = 0;
    this._relationsUpdatedCount = 0;
    this.client = client;
    this.events = new EventEmitter();
    this.idleCallbackId = null;
    this.isRequestIdleCallbackSupported = 'requestIdleCallback' in window;
    this.queue = new Map();
    this.otherEventCounter = {};
    this.replayStartQueue = [];
    this.stores = stores;
  }

  addToQueue(eventName, data, eventType) {
    let key = eventName;
    if (eventType === 'entity') {
      key += data?.id;
    } else {
      if (!this.otherEventCounter[eventName]) this.otherEventCounter[eventName] = 1;
      key += this.otherEventCounter[eventName]++;
    }
    this.queue.set(key, { eventName, entity: data });
  }

  getNextEvent(queueIterator) {
    if (this.replayStartQueue.length) {
      return { eventName: 'advisory', entity: this.replayStartQueue.pop() };
    } else {
      const { value, done } = queueIterator.next();
      if (done) return {};
      const [key, { eventName, entity }] = value;
      this.queue.delete(key);
      return { eventName, entity };
    }
  }

  mounted() {
    for (const model of Object.values(this.client.models)) {
      if (!this[model.name]) continue;
      if (!model.on) continue;

      for (const action of ACTIONS) {
        this._actionHandlers.push([model, action, (m, e) => this._queueEntityEvent(action, e)]);
      }
    }

    this._actionHandlers.push([
      this.client,
      'advisory',
      (e) => this._queueAdvisoryEvent('advisory', e),
    ]);
    this._actionHandlers.push([
      this.client,
      'conversations:loading:start',
      () => this._queueOtherEvent('conversations:loading:start'),
    ]);
    this._actionHandlers.push([
      this.client,
      'conversations:loading:stop',
      () => this._queueOtherEvent('conversations:loading:stop'),
    ]);
    this._actionHandlers.push([
      this.client.roles,
      'optin:start',
      (e) => this._queueOtherEvent('optin:start', e),
    ]);
    this._actionHandlers.push([
      this.client.roles,
      'optin:stop',
      (e) => this._queueOtherEvent('optin:stop', e),
    ]);
    this._actionHandlers.push([
      this.client.roles,
      'optout:start',
      (e) => this._queueOtherEvent('optout:start', e),
    ]);
    this._actionHandlers.push([
      this.client.roles,
      'optout:stop',
      (e) => this._queueOtherEvent('optout:stop', e),
    ]);
    this._actionHandlers.push([this.client, 'events:batch:available', this._markBatchAvailable]);

    for (const [emitter, action, handler] of this._actionHandlers) {
      emitter.on(action, handler);
    }

    this._scheduleQueueProcessing();
  }

  dispose() {
    for (const [emitter, action, handler] of this._actionHandlers) {
      emitter.removeListener(action, handler);
    }

    clearTimeout(this.queueTimer);
    this.isRequestIdleCallbackSupported && window.cancelIdleCallback(this.idleCallbackId);
    this.queueTimer = null;
    this.idleCallbackId = null;
  }

  _queueEntityEvent = (eventName, entity) => {
    if (!entity) {
      console.warn(
        `EntityStore (${eventName} event): got undefined entity from SDK`,
        new Error().stack
      );
    } else if (!(entity.$entityType in this)) {
      console.warn(
        `EntityStore (${eventName} event): entityType ${entity.$entityType} not found`,
        new Error().stack
      );
    } else {
      this.addToQueue(eventName, entity, 'entity');
    }
  };

  _queueAdvisoryEvent = (eventName, event) => {
    if (event.type === 'replayStart') {
      this.replayStartQueue.unshift(event);
    } else {
      this.addToQueue(eventName, event, 'advisory');
    }
  };

  _queueOtherEvent = (eventName, arg) => {
    this.addToQueue(eventName, arg, 'other');
  };

  _markBatchAvailable = ({ immediate = false } = {}) => {
    this._batchAvailable = true;

    if (immediate) {
      this.processEventAndEntityQueues();
    }
  };

  _scheduleQueueProcessing = () => {
    const { REQUEST_IDLE_CALLBACK_ENABLED = true } = this.stores.featureStore?.featureFlags || {};
    const ricEnabled = this.isRequestIdleCallbackSupported && REQUEST_IDLE_CALLBACK_ENABLED;

    clearTimeout(this.queueTimer);
    this.isRequestIdleCallbackSupported && window.cancelIdleCallback(this.idleCallbackId);

    this.queueTimer = setTimeout(
      () => {
        if (ricEnabled) {
          this.idleCallbackId = window.requestIdleCallback(this.processEventAndEntityQueues, {
            timeout: Timings.IDLE_CALLBACK_TIMEOUT,
          });
        } else {
          this.processEventAndEntityQueues();
        }
      },
      ricEnabled ? 0 : Timings.EVENT_PROCESSING_DELAY
    );
  };

  @action('EntityStore.drainQueue') drainQueue = () => {
    return new Promise((resolve) => {
      this._drainResolvers.push(resolve);
      this.processEventAndEntityQueues();
    });
  };

  @action('EntityStore.processEventAndEntityQueues') processEventAndEntityQueues = () => {
    const {
      downloadedMessagesProgressStore: { downloadFinished },
    } = this.stores;

    const startTime = Date.now();
    const maxTime = downloadFinished ? MAX_QUEUES_PROCESS_TIME : MAX_QUEUES_PROCESS_TIME_LOGIN;

    if (this._batchAvailable) {
      this._batchAvailable = false;
      this.client.events.processEventQueue(!downloadFinished ? 500 : undefined);
    }

    if (this.queue.size > 0) {
      for (const model of ALL_MODELS) {
        model.resetCycles();
      }

      const iterator = this.queue.entries();
      do {
        const { eventName, entity } = this.getNextEvent(iterator);
        if (!eventName) break;
        try {
          if (eventName === 'afterInject') {
            this[entity.$entityType]._sync(entity);
          } else if (eventName === 'afterEject') {
            this[entity.$entityType]._remove(entity);
          }
        } catch (err) {
          console.warn(
            `EntityStore.processEventAndEntityQueues (${eventName} event model update): error`,
            err
          );
        }

        try {
          this.events.emit(eventName, entity);
          this._processedEventsCount++;
        } catch (err) {
          console.warn(
            `EntityStore.processEventAndEntityQueues (${eventName} event reaction): error`,
            err
          );
        }
      } while (Date.now() - startTime < maxTime);
    }

    this._scheduleQueueProcessing();

    if (this.queue.size === 0 && this._drainResolvers.length > 0) {
      const resolvers = this._drainResolvers;
      this._drainResolvers = [];
      for (const resolve of resolvers) resolve();
    }
  };

  @action('EntityStore.sync') sync = (entities) => {
    if (!entities) return;
    entities = _.castArray(entities);
    if (entities.some((e) => !e)) {
      console.warn('[EntityStore.sync] entities contain undefined', new Error().stack);
      entities = entities.filter(Boolean);
    }

    for (const model of ALL_MODELS) {
      model.resetCycles();
    }

    return entities.map((entity) => {
      if (!(entity.$entityType in this)) {
        throw new Error(`EntityStore: entityType ${entity.$entityType} not found`);
      }
      return this[entity.$entityType]._sync(entity, true);
    });
  };

  @action('EntityStore.syncOne') syncOne = (entity) => {
    if (!entity) {
      console.warn('[EntityStore.syncOne] entity is undefined', new Error().stack);
      return;
    }

    const { $entityType } = entity;
    if (!($entityType in this)) {
      throw new Error(`EntityStore: entityType ${$entityType} not found`);
    }

    for (const model of ALL_MODELS) {
      model.resetCycles();
    }

    return this[$entityType]._sync(entity, true);
  };

  getAll(entityType) {
    if (!(entityType in this)) {
      throw new Error(`EntityStore: entityType ${entityType} not found`);
    }
    return this[entityType].getAll();
  }

  getById(entityType, id) {
    if (!(entityType in this)) {
      throw new Error(`EntityStore: entityType ${entityType} not found (id is ${id})`);
    }
    return this[entityType].getById(id);
  }

  @action('EntityStore.getOrSyncOne') getOrSyncOne = (entity) => {
    const { id, $entityType: entityType } = entity;
    if (!(entityType in this)) {
      console.error(`EntityStore: entityType ${entityType} not found (id is ${id})`);
      return undefined;
    }

    return this[entityType].getById(id) || this[entityType]._sync(entity, true);
  };

  @action('EntityStore.getOrSync') getOrSync = (entities) => {
    if (!entities) return;
    entities = _.castArray(entities);
    if (entities.some((e) => !e)) {
      console.warn('[EntityStore.sync] entities contain undefined', new Error().stack);
      entities = entities.filter(Boolean);
    }

    return entities.map((entity) => {
      if (!(entity.$entityType in this)) {
        throw new Error(`EntityStore: entityType ${entity.$entityType} not found`);
      }
      return this.getOrSyncOne(entity);
    });
  };

  @action('EntityStore.getEntityData') getEntityData = (entityType, id) => {
    const { roleStore } = this.stores;
    if (entityType === 'role') {
      return roleStore.getRoleById(id);
    } else {
      return this.getById(entityType === 'account' ? 'user' : entityType, id);
    }
  };
}
