import { User } from '../../types';

const MAX_NAME_LENGTH = 32;
const MAX_MEMBER_LENGTH = 215;

export default function buildMembersList(recipients: User[] = [], currentUser?: User) {
  if (!currentUser || !currentUser.id) {
    throw new Error('buildMembersList requires a currentUser object');
  }
  let members = recipients
    .filter(({ id }) => id !== currentUser.id)
    .map(({ displayName, isPatient, isPatientContact }) => {
      if (!displayName) {
        throw new Error('buildMembersList requires a displayName for each entry');
      }
      let renderedName = displayName;
      if (renderedName.length >= MAX_NAME_LENGTH) {
        renderedName = renderedName.slice(0, MAX_NAME_LENGTH - 3).concat('...');
      }
      if (isPatient) {
        return `${renderedName} (Patient)`;
      } else if (isPatientContact) {
        return `${renderedName} (Patient Contact)`;
      } else {
        return renderedName;
      }
    })
    .join(', ');
  if (members.length >= MAX_MEMBER_LENGTH) {
    members = members.slice(0, MAX_MEMBER_LENGTH - 3).concat('...');
  }
  return members;
}
