import React from 'react';
import Modal from '../Modal';

import BEM from '../../bem';

const classes = BEM.with('CalleeCapabilitiesModal');

function CalleeCapabilitiesModal({ closeModal, isOpen }) {
  return (
    <Modal
      bodyClass={classes('body')}
      closeClass={classes('close-button')}
      headerClass={classes('header')}
      className={classes()}
      header="Error"
      isOpen={isOpen}
      onRequestClose={closeModal}
      size={'medium'}
      footerPrimaryActions={
        <button type="button" className={classes('ok-btn')} onClick={closeModal}>
          OK
        </button>
      }
    >
      <div className={classes('body-container')}>
        <div className={classes('description-container')}>
          <span>The participants called do not have calling capabilities.</span>
        </div>
      </div>
    </Modal>
  );
}

export default CalleeCapabilitiesModal;
