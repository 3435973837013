import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../common/bem';
import propTypes from '../../common/propTypes';
import { attachmentType, mobxInjectSelect } from '../utils';
import { ReactComponent as AttachmentSvg } from '../images/attachment-sample.svg';
import { ReactComponent as CloseIconSvg } from '../images/close-icon.svg';

const classes = BEM.with('AttachmentPreviews');

class AttachmentPreview extends Component {
  static propTypes = {
    attachmentDescriptor: propTypes.messageAttachment.isRequired,
    removeAttachment: PropTypes.func.isRequired,
    shouldShowImages: PropTypes.bool.isRequired,
  };

  render() {
    const { attachmentDescriptor, shouldShowImages } = this.props;
    const { file, localPath } = attachmentDescriptor;
    let backgroundStyle = null;
    let imageFragment;
    let previewNameStyle;
    const removeButtonRef = useRef < HTMLDivElement > null;

    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        this._removeAttachment();
      }
    };

    if (shouldShowImages && attachmentType(file.type) === 'Image') {
      backgroundStyle = { backgroundImage: `url(${localPath})` };
      previewNameStyle = { image: true };
    } else {
      imageFragment = (
        <AttachmentSvg
          className={classes('attachment-preview-icon')}
          fill={'#dadada'}
          aria-hidden
        />
      );
    }

    return (
      <div className={classes('attachment-preview')}>
        <div className={classes('attachment-preview-image')} style={backgroundStyle}>
          <button
            ref={removeButtonRef}
            className={classes('attachment-remove-button-container')}
            onClick={this._removeAttachment}
            onKeyDown={handleKeyDown}
            aria-label="Remove Attachment"
          >
            <CloseIconSvg
              className={classes('attachment-remove-button')}
              fill={'#fff'}
              aria-hidden
            />
          </button>
          {imageFragment}
          <span className={classes('attachment-preview-name', previewNameStyle)}>{file.name}</span>
        </div>
      </div>
    );
  }

  _removeAttachment = () => {
    const { attachmentDescriptor, removeAttachment } = this.props;
    removeAttachment(attachmentDescriptor);
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['removeAttachment'],
})(AttachmentPreview);
