import React from 'react';
import { useDispatch } from 'react-redux';

import { actions } from '../../../../redux-stores';

const { selectEntity, setNewEntityType } = actions;

type EntityErrorBoundaryProps = {
  children: React.ReactElement;
};

export default function EntityErrorBoundary({ children }: EntityErrorBoundaryProps) {
  const dispatch = useDispatch();

  let output = <></>;
  try {
    output = children;
  } catch (error) {
    console.error(error);
    dispatch(selectEntity(undefined));
    dispatch(setNewEntityType(undefined));
  }
  return output;
}
