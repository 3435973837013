import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import TCClient from '../../../../client';

import { DotsIndicator } from '../../../../common/components';
import ActionBarMenuOption from '../../../shared-components/ActionBarMenuOption/ActionBarMenuOption';

import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import { ReactComponent as MoreButton } from '../../../../common/images/more-button.svg';
import sideArrow from '../../../images/side_arrow.svg';
import downArrow from '../../../images/down_arrow.svg';
import plusIcon from '../../../images/plus.svg';
import lineIcon from '../../../images/single_line.svg';

import { ChangeUnitModal } from '../DetailsModals/ChangeUnitModal';
import { RemoveFromUnitModal } from '../DetailsModals/RemoveFromUnitModal';
import { UnitModal } from '../DetailsModals/UnitModal';

import AssignRoomsModal from '../DetailsModals/AssignRoomsModal';
import styles from './Units.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

export function UnitsTab() {
  const dispatch = useAppDispatch();
  const [openItems, setOpenItems] = useState<{ [k: string]: boolean }>({});
  const [showOptions, setShowOptions] = useState('');
  const [facilities, setFacilities] = useState<
    Awaited<ReturnType<typeof TCClient.multiOrg.getOrganizationLocations>>
  >([]);
  const [areaNamesByRoomId, setAreaNamesByRoomId] = useState<{ [k: string]: string }>({});
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [addUnit, setAddUnit] = useState<{
    facilityId: string;
    unitName: string;
    unitId: string;
  } | null>(null);
  const [editUnit, setEditUnit] = useState<{
    facilityDisplayName: string;
    facilityId: string;
    unitName: string;
    unitId: string;
  } | null>(null);
  const [createUnit, setCreateUnit] = useState<{
    facilityDisplayName: string;
    facilityId: string;
  } | null>(null);
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);
  const isUnitsDataDirty = useAppSelector((state) => state.multiOrg.isUnitsDataDirty);
  const [changeUnitModal, setChangeUnitModal] = useState<{
    parentAreaName: string;
    roomName: string;
    roomId: string;
    unitId: string;
    unitName: string;
  } | null>(null);
  const [removeFromUnitModal, setRemoveFromUnitModal] = useState<{
    parentAreaName: string;
    roomName: string;
    roomId: string;
    unitId: string;
    unitName: string;
  } | null>(null);

  const getFacilities = useCallback(async () => {
    setLoadingUnits(true);
    const summary = await TCClient.multiOrg.getOrganizationLocations(selectedOrganizationId);
    const areaNamesByRoomId: { [k: string]: string } = {};
    summary.forEach((facility) =>
      facility.areas?.forEach((area) =>
        area.rooms?.forEach((room) => {
          if (room.roomId) areaNamesByRoomId[room.roomId] = area.areaDisplayName;
        })
      )
    );
    setAreaNamesByRoomId(areaNamesByRoomId);
    setFacilities(summary);
    dispatch(actions.setIsUnitsDataDirty({ isUnitsDataDirty: false }));
    setLoadingUnits(false);
  }, [dispatch, selectedOrganizationId]);

  useEffect(() => {
    if (isUnitsDataDirty) getFacilities();
  }, [isUnitsDataDirty, getFacilities]);

  const toggleShowOptions = (id: string) => {
    if (showOptions === id) setShowOptions('');
    else setShowOptions(id);
  };

  if (loadingUnits)
    return (
      <div className={classNames(styles.loading)}>
        <DotsIndicator />
      </div>
    );

  return (
    <div className={styles.table}>
      {changeUnitModal !== null && (
        <ChangeUnitModal
          isOpen={changeUnitModal !== null}
          onClose={() => setChangeUnitModal(null)}
          onSave={async () => {
            await getFacilities();
            setChangeUnitModal(null);
          }}
          room={changeUnitModal}
          unit={changeUnitModal}
        />
      )}
      {removeFromUnitModal !== null && (
        <RemoveFromUnitModal
          isOpen={removeFromUnitModal !== null}
          onClose={() => setRemoveFromUnitModal(null)}
          onRemove={async () => {
            await getFacilities();
            setRemoveFromUnitModal(null);
          }}
          room={removeFromUnitModal}
          unit={removeFromUnitModal}
        />
      )}
      {facilities.map((facility, facilityIndex) => {
        const isFacilityOpen = openItems[facilityIndex];
        const facilityArrow = isFacilityOpen ? downArrow : sideArrow;
        const facilityArrowStyle = isFacilityOpen ? styles.downArrow : styles.sideArrow;

        return (
          <div key={facility.facilityId} className={styles.facilityGroup}>
            <div className={styles.facilityRow}>
              <ToolTip text={`New Unit`} textAlign={'center'}>
                <div
                  className={styles.plus}
                  onClick={() => {
                    const { facilityId, facilityDisplayName } = facility;
                    setCreateUnit({ facilityId, facilityDisplayName });
                  }}
                >
                  <img src={plusIcon} alt="" />
                </div>
              </ToolTip>
              <div
                className={classNames(
                  styles.facilityDetails,
                  showOptions === facility.facilityId && styles.showOptionsBackground
                )}
              >
                {facility.units && facility.units.length > 0 && (
                  <div
                    className={styles.facilityCollapse}
                    onClick={() => {
                      if (facility.units && facility.units.length > 0) {
                        const facilityUnits = facility.units
                          ?.map((u) => u.unitId)
                          .reduce((memo: Record<string, false>, unitId) => {
                            memo[unitId] = false;
                            return memo;
                          }, {});
                        setOpenItems({
                          ...openItems,
                          [facilityIndex]: !isFacilityOpen,
                          ...(isFacilityOpen && { ...facilityUnits }),
                        });
                      }
                    }}
                  >
                    <img className={facilityArrowStyle} src={facilityArrow} alt="" />
                  </div>
                )}
                <div className={styles.facilityInfo}>
                  <div className={styles.nameContainer}>
                    <div className={styles.facilityName}>{facility.facilityDisplayName}</div>
                    <div className={classNames(styles.subtitle)}>Facility</div>
                  </div>
                  <div className={styles.facilityLabel}>
                    {facility.units?.length || '0'}{' '}
                    {facility.units?.length === 1 ? 'Unit' : 'Units'}
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.options,
                    showOptions === facility.facilityId && styles.showOptionsMenu
                  )}
                  onClick={() => toggleShowOptions(facility.facilityId)}
                ></div>
              </div>
            </div>
            {isFacilityOpen && (
              <div className={styles.facilityChildren}>
                {facility.units?.map((u) => {
                  const isUnitOpen = openItems[u.unitId];
                  const unitArrow = isUnitOpen ? downArrow : sideArrow;
                  const unitArrowStyle = isUnitOpen ? styles.downArrow : styles.sideArrow;
                  const unitsHasRooms =
                    u.rooms.length > 0 && !!areaNamesByRoomId[u.rooms[0].roomId];
                  return (
                    <div key={u.unitId} className={styles.unitGroup}>
                      <div className={styles.unitRow}>
                        <img src={lineIcon} alt="" className={styles.line} />
                        <div
                          className={classNames(
                            styles.unitDetails,
                            showOptions === u.unitId && styles.showOptionsBackground
                          )}
                        >
                          {unitsHasRooms && (
                            <div
                              className={styles.unitCollapse}
                              onClick={() =>
                                setOpenItems({ ...openItems, [u.unitId]: !isUnitOpen })
                              }
                            >
                              <img className={unitArrowStyle} src={unitArrow} alt="" />
                            </div>
                          )}
                          <div className={styles.unitInfo}>
                            <span className={styles.unitName}>{u.unitDisplayName}</span>
                            <span className={styles.unitLabel}>Unit</span>
                          </div>
                          <div
                            className={classNames(
                              styles.options,
                              showOptions === u.unitId && styles.showOptionsMenu
                            )}
                            onClick={() => toggleShowOptions(u.unitId)}
                            ref={null}
                          >
                            <ActionBarMenuOption
                              onClose={() => toggleShowOptions(u.unitId)}
                              customStyles={{ parent: styles.unitOptions, width: '95px' }}
                              showDropDown={showOptions === u.unitId}
                              menuOptionButtons={[
                                {
                                  buttonName: 'Edit Unit',
                                  onClick: () => {
                                    setEditUnit({
                                      facilityDisplayName: facility.facilityDisplayName,
                                      facilityId: facility.facilityId,
                                      unitId: u.unitId,
                                      unitName: u.unitDisplayName,
                                    });
                                  },
                                },
                                {
                                  buttonName: 'Add Rooms',
                                  onClick: () => {
                                    setAddUnit({
                                      facilityId: facility.facilityId,
                                      unitId: u.unitId,
                                      unitName: u.unitDisplayName,
                                    });
                                  },
                                },
                              ]}
                            />
                            {!showOptions ? (
                              <ToolTip text={'Details'} textAlign={'center'}>
                                <MoreButton />
                              </ToolTip>
                            ) : (
                              <MoreButton />
                            )}
                          </div>
                        </div>
                      </div>
                      {isUnitOpen &&
                        u.rooms.map(
                          (room) =>
                            room.roomId &&
                            room.roomDisplayName && (
                              <div key={room.roomId} className={styles.roomRow}>
                                <img src={lineIcon} alt="" className={styles.line} />
                                <div
                                  className={classNames(
                                    styles.roomDetails,
                                    showOptions === room.roomId && styles.showOptionsBackground
                                  )}
                                >
                                  <div className={styles.roomInfo}>
                                    <span className={styles.roomLabel}>
                                      {areaNamesByRoomId[room.roomId] &&
                                        areaNamesByRoomId[room.roomId]}
                                      :
                                    </span>{' '}
                                    <span className={styles.roomName}>{room.roomDisplayName}</span>
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.options,
                                      showOptions === room.roomId && styles.showOptionsMenu
                                    )}
                                    onClick={() => toggleShowOptions(room.roomId)}
                                    ref={null}
                                  >
                                    <ActionBarMenuOption
                                      onClose={() => toggleShowOptions(room.roomId)}
                                      customStyles={{ parent: styles.roomOptions, width: '130px' }}
                                      showDropDown={showOptions === room.roomId}
                                      menuOptionButtons={[
                                        {
                                          buttonName: 'Change Unit',
                                          toolTip: 'Change Unit',
                                          onClick: () => {
                                            setChangeUnitModal({
                                              parentAreaName: areaNamesByRoomId[room.roomId],
                                              roomName: room.roomDisplayName,
                                              roomId: room.roomId,
                                              unitId: u.unitId,
                                              unitName: u.unitDisplayName,
                                            });
                                          },
                                        },
                                        {
                                          buttonName: 'Remove from Unit',
                                          toolTip: 'Remove from Unit',
                                          onClick: async () => {
                                            setRemoveFromUnitModal({
                                              parentAreaName: areaNamesByRoomId[room.roomId],
                                              roomName: room.roomDisplayName,
                                              roomId: room.roomId,
                                              unitId: u.unitId,
                                              unitName: u.unitDisplayName,
                                            });
                                          },
                                        },
                                      ]}
                                    />
                                    <ToolTip text={'Details'} textAlign={'center'}>
                                      <MoreButton className={styles.moreButton} />
                                    </ToolTip>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                    </div>
                  );
                })}
                <div
                  className={styles.addUnitRow}
                  onClick={() => {
                    const { facilityId, facilityDisplayName } = facility;
                    setCreateUnit({ facilityId, facilityDisplayName });
                  }}
                >
                  <div className={styles.addUnit}>
                    <img src={plusIcon} alt="add unit" />
                  </div>
                  <span className={styles.addUnitLabel}>New Unit</span>
                </div>
              </div>
            )}
          </div>
        );
      })}
      {editUnit && (
        <UnitModal
          isOpen={!!editUnit}
          onClose={() => setEditUnit(null)}
          mode={{
            type: 'edit',
            data: {
              facilityDisplayName: editUnit.facilityDisplayName,
              facilityId: editUnit.facilityId,
              unitName: editUnit.unitName,
              unitId: editUnit.unitId,
            },
          }}
        />
      )}
      {createUnit && (
        <UnitModal
          isOpen={!!createUnit}
          onClose={() => setCreateUnit(null)}
          mode={{
            type: 'create',
            data: createUnit,
          }}
        />
      )}
      {addUnit && (
        <AssignRoomsModal
          facilityId={addUnit.facilityId}
          isOpen={!!addUnit}
          onClose={() => setAddUnit(null)}
          unitId={addUnit.unitId}
          unitName={addUnit.unitName}
        />
      )}
    </div>
  );
}
