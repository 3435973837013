import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import BEM from '../../bem';

const classes = BEM.with('PatientCareCard');

class PatientCareItem extends Component {
  static propTypes = {
    component: PropTypes.shape({
      title: PropTypes.string.isRequired,
      title_color: PropTypes.string,
      value: PropTypes.string.isRequired,
      value_color: PropTypes.string,
      value_unit: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { title, value_unit: valueUnit } = this.props.component;
    let { title_color: titleColor, value, value_color: valueColor } = this.props.component;

    if (titleColor) titleColor = titleColor.replace('0x', '#');
    if (valueColor) valueColor = valueColor.replace('0x', '#');

    if (valueUnit) {
      value += ` ${valueUnit}`;
    }

    return (
      <div className={classes('item')}>
        <span className={classes('title')} style={{ color: titleColor }}>
          {title}
        </span>
        <span className={classes('value')} style={{ color: valueColor }}>
          {value}
        </span>
      </div>
    );
  }
}

export default observer(PatientCareItem);
