const DATitles = {
  DEFAULT: 'TigerConnect - Messenger',
  ERROR: 'TigerConnect - Error - Unknown Error',
  EXTERNAL_LOGIN: 'TigerConnect - External Login',
  EXTERNAL_REDIRECT: 'TigerConnect - External Redirect',
  LOGGING_IN: 'TigerConnect - Logging In',
  LOGIN: 'TigerConnect - Login',
  PASSWORD: 'TigerConnect - Password',
  SESSION_EXPIRED: 'TigerConnect - Session Expired',
} as const;

export type DATitle =
  | typeof DATitles.DEFAULT
  | typeof DATitles.ERROR
  | typeof DATitles.EXTERNAL_LOGIN
  | typeof DATitles.EXTERNAL_REDIRECT
  | typeof DATitles.LOGGING_IN
  | typeof DATitles.LOGIN
  | typeof DATitles.PASSWORD
  | typeof DATitles.SESSION_EXPIRED;

export default DATitles;
