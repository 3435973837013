import createMobxModel from './createMobxModel';

const Appointment = createMobxModel({
  name: 'appointment',

  fields: [
    'id',
    'appointmentDate',
    'appointmentTime',
    'appointmentTimezone',
    'patientId',
    'location',
    'organizationId',
    'provider',
    'source',
    'visitNumber',
    'workflowId',
  ],

  objectFields: {},

  relations: {
    one: {
      organization: { type: 'organization' },
    },
  },
});

export default Appointment;
