import React, { useEffect, useState, ReactElement, PropsWithChildren } from 'react';
import styles from './Table.module.css';
import { Checkbox } from './../Checkbox/Checkbox';
import { DeleteButton } from './../DeleteButton/DeleteButton';
import { DotsIndicator } from 'common/components';

export type TableColumn = {
  field: string;
  headerName: string;
  customCellStyles?: object;
  renderer?: (colIndex: number, rowIndex: number, value: CellValue) => ReactElement;
};

export type actionType = {
  name: string;
  type: string;
};

type CellValue = string | number | undefined | boolean | ReactElement | Array<unknown> | unknown;

export type TableRow<T = unknown> = {
  id: string;
  [key: string]: CellValue;
} & T;

export type RowsSelected = {
  [key: string]: TableRow;
};

export type TableProps<T = unknown> = {
  // The data of the columns that will be used to create this table
  columns: Array<TableColumn>;
  // Optional, message to display if table is empty
  emptyMessage?: string;
  // The data of the rows that will be used to create this table
  rows: Array<TableRow<T>>;

  // Optional, determines if the table uses pagination
  paginated?: boolean;
  // Optional async pagination function
  onPaginate?: () => Promise<void> | void;

  // Optional, determines if the table has checkbox selection
  hasCheckbox?: boolean;
  // Optional, a parent owned state of rows selected
  rowsSelected?: RowsSelected;
  // Optional row select handler, works in tandem with rowsSelected
  onRowSelect?: (selected: RowsSelected) => void;

  // Optional row click handler, exposes the selected row's data and its index
  onRowClick?: (row: TableRow<T>, index: number) => void;

  // Optional delete click handler, exposes the selected row's data and its index
  onRowDelete?: (row: TableRow<T>, index: number) => Promise<void> | void;

  // Optional edit click handler, exposes the selected row's data and its index
  onRowEdit?: (row: TableRow<T>, index: number) => void;

  onRowAdd?: (row: TableRow<T>, index: number) => void;

  // Optional, determines if the table has an actions columns | delete | edit | add
  actionsOrder?: Array<actionType>;
  isLoading?: boolean;
};

export const Table = <T,>({
  actionsOrder = [],
  columns = [],
  emptyMessage,
  hasCheckbox = false,
  onPaginate,
  onRowAdd,
  onRowClick,
  onRowDelete,
  onRowEdit,
  onRowSelect,
  paginated = false,
  rows = [],
  rowsSelected = {},
  isLoading = false,
}: PropsWithChildren<TableProps<T>>): ReactElement | null => {
  const [selectedCount, setSelectedCount] = useState(0);
  const [paginationLoading, setPaginationLoading] = useState(false);

  useEffect(() => {
    setSelectedCount(Object.keys(rowsSelected).length);
  }, [rowsSelected]);

  function selectAll() {
    if (!onRowSelect) return;

    if (selectedCount < rows.length) {
      const rowsObj = {} as RowsSelected;
      rows.forEach((row: TableRow) => (rowsObj[row.id] = row));
      onRowSelect(rowsObj);
    } else if (selectedCount === rows.length) {
      onRowSelect({});
    }
  }

  async function handlePagination() {
    if (!onPaginate) return;
    setPaginationLoading(true);
    await onPaginate();
    setPaginationLoading(false);
  }

  function renderCells(
    row: TableRow,
    { customCellStyles, field, renderer }: TableColumn,
    colIndex: number,
    rowIndex: number
  ) {
    const value = row[field];
    if (renderer) {
      return (
        <td style={{ paddingLeft: field === 'priority' ? 25 : 0 }} key={colIndex}>
          {renderer(colIndex, rowIndex, value)}
        </td>
      );
    }
    if (
      field.includes('avatar') &&
      typeof value === 'string' &&
      (value.includes('http') || value.includes('png'))
    ) {
      return (
        <td className={styles.imageCell} key={colIndex}>
          <img src={value} alt={'avatar'} />
        </td>
      );
    }
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return <td style={customCellStyles} key={colIndex}></td>;
      }
      return (
        <td style={customCellStyles} key={colIndex}>
          {JSON.stringify(value)}
        </td>
      );
    }
    if (React.isValidElement(value)) {
      return (
        <td style={customCellStyles} key={colIndex}>
          {value}
        </td>
      );
    }
    if (typeof value === 'object') {
      return <td style={customCellStyles} key={colIndex}></td>;
    }
    return (
      <td style={customCellStyles} key={colIndex}>
        {value}
      </td>
    );
  }

  function renderRows(row: TableRow<T>, rowIndex: number) {
    let rowIdentifier = row.name;
    if (columns[3]?.headerName === 'Username') {
      rowIdentifier = `${row.firstName} ${row.lastName}`;
    } else if (columns[0]?.headerName === 'Org Name') {
      rowIdentifier = `${row.orgName}`;
    }

    const deleteAction = (
      <td
        key={'delete'}
        onClick={async (e) => {
          e.stopPropagation();
          if (onRowDelete) {
            await onRowDelete(row, rowIndex);
          }
        }}
        className={styles.iconCell}
        data-test-id={`${rowIdentifier} delete`}
      >
        <DeleteButton id={row.id} />
      </td>
    );

    const editAction = (
      <td
        key={'edit'}
        onClick={(e) => {
          e.stopPropagation();
          if (onRowEdit) {
            onRowEdit(row, rowIndex);
          }
        }}
        className={styles.iconCell}
        data-test-id={`${rowIdentifier} edit`}
      >
        <span className={styles.editIcon} />
      </td>
    );

    const addAction = (
      <td
        key={'add'}
        onClick={(e) => {
          e.stopPropagation();
          if (onRowAdd) {
            onRowAdd(row, rowIndex);
          }
        }}
        className={styles.iconCell}
        data-test-id={`${rowIdentifier} add`}
      >
        <div className={styles.addIcon} />
      </td>
    );

    return (
      <tr
        key={row.id}
        style={{ cursor: onRowClick ? 'pointer' : 'default' }}
        onClick={(e) => {
          e.stopPropagation();
          if (onRowClick) {
            onRowClick(row, rowIndex);
          }
        }}
      >
        {hasCheckbox && (
          <td
            className={styles.checkbox}
            onClick={(e) => {
              e.stopPropagation();
              if (!onRowSelect) return;
              if (!rowsSelected[row.id]) {
                onRowSelect({ ...rowsSelected, [row.id]: row });
              } else {
                delete rowsSelected[row.id];
                onRowSelect({ ...rowsSelected });
              }
            }}
          >
            <Checkbox checked={!!rowsSelected[row.id]} dataTestId={`${rowIdentifier} checkbox`} />
          </td>
        )}
        {columns.map((col, colIndex) => (
          <React.Fragment key={colIndex}>
            {renderCells(row, col, colIndex, rowIndex)}
          </React.Fragment>
        ))}
        {actionsOrder.map((action) => {
          switch (action.type) {
            case 'edit':
              return editAction;
            case 'delete':
              return deleteAction;
            case 'add':
              return addAction;
            default:
              return null;
          }
        })}
      </tr>
    );
  }

  if (emptyMessage && !isLoading && !rows.length) {
    return (
      <div className={styles.tableContainer}>
        <div className={styles.emptyMessage}>{emptyMessage}</div>
      </div>
    );
  }

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            {hasCheckbox && (
              <th className={styles.checkbox}>
                <Checkbox
                  checked={selectedCount === rows.length}
                  onClick={selectAll}
                  dataTestId="tableCheckbox"
                />
              </th>
            )}
            {columns.map((label) => (
              <th key={label.field}>{label.headerName}</th>
            ))}
            {actionsOrder.map((action) => (
              <th key={action.name}>{action.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>{rows.map((row, index) => renderRows(row, index))}</tbody>
      </table>
      {paginated && (
        <div className={styles.paginationContainer}>
          <div className={styles.paginationButton} onClick={handlePagination}>
            {paginationLoading ? 'Loading...' : 'Load More'}
          </div>
        </div>
      )}
      {isLoading && (
        <div className={styles.paginationContainer}>
          <DotsIndicator size={13} />
        </div>
      )}
    </div>
  );
};
