import TCClient from '../../client';
import { ActionStatus, Visitor, VisitorResponse, VisitStatus, VWR } from '../../types';
import { MobxProps, VWRDispatch, VWRState } from './context';

type MobxPropsSlice = Pick<
  MobxProps,
  'conversations' | 'findConversation' | 'selectConversation' | 'currentOrganizationId'
>;

type VisitorsPayload = VWRState['visitors'];

type VisitorMeta = {
  totalElements: number;
  content: VisitorResponse[];
  pageNumber: number;
  totalPages: number;
};

const parseVisitors = async (
  visitors: VisitorResponse[],
  { conversations, findConversation, selectConversation, currentOrganizationId }: MobxPropsSlice,
  selectDefaultVisitor = true
): Promise<{ visitors: Visitor[]; selectedVisitor: Visitor | null }> => {
  const _visitors: Visitor[] = visitors.map((visitor) => {
    const visitorModelInstance = TCClient.models.VirtualWaitingRoomVisit.get(visitor.id);

    let foundConversation;

    for (const _conversation of conversations) {
      if (_conversation?.id === visitorModelInstance?.conversation?.id) {
        foundConversation = _conversation;
      }
    }

    if (!foundConversation) {
      const group = TCClient.models.Group.get(visitor.groupId);
      foundConversation = group?.conversation;
    }

    return {
      ...visitorModelInstance,
      conversation: foundConversation,
      isSelected: false,
    };
  });

  let selectedVisitor: Visitor | null = null;
  if (selectDefaultVisitor) {
    const visitor = _visitors.length > 0 ? _visitors[0] : null;
    if (visitor) {
      const { id: visitId, roomId } = visitor;
      const visitorResponse = (await TCClient.virtualWaitingRoom.getVisit(currentOrganizationId, {
        roomId,
        visitId,
      })) as VisitorResponse;
      const { isSelected, conversation } = visitor;
      selectedVisitor = {
        ...visitorResponse,
        isSelected,
        conversation,
        roomId,
      };
    }
  }

  if (selectedVisitor) {
    const conversation = await findConversation(selectedVisitor.conversationId, {
      groupId: selectedVisitor.groupId,
      organizationId: currentOrganizationId,
    });
    selectConversation(conversation);
  } else if (selectDefaultVisitor) {
    selectConversation(null);
  }

  return { visitors: _visitors, selectedVisitor };
};

export const getVisitors = async (
  roomId: string,
  mobxProps: MobxPropsSlice,
  status: VisitStatus = 'INCOMPLETE',
  page = 0,
  selectDefaultVisitor = true
): Promise<VisitorsPayload> => {
  const { currentOrganizationId } = mobxProps;

  const {
    content,
    totalElements: count,
    pageNumber,
    totalPages,
  } = (await TCClient.virtualWaitingRoom.findAllVisits({
    page,
    status,
    roomId,
    organizationId: currentOrganizationId,
  })) as VisitorMeta;

  const hasAllVisitors = pageNumber === totalPages - 1;

  const { visitors, selectedVisitor } = await parseVisitors(
    content,
    mobxProps,
    selectDefaultVisitor
  );

  return {
    count,
    hasAllVisitors,
    page: pageNumber,
    visitors,
    selectedVisitor,
  };
};

export const hydrateVWR = async (dispatch: VWRDispatch, mobxProps: MobxProps) => {
  dispatch({ type: 'SET_LOADING_STATUS', payload: 'PENDING' });
  const { currentOrganizationId } = mobxProps;

  let loadedStatus: ActionStatus = 'PENDING';
  let roomList: VWR[] = [];
  let selectedRoom: VWR | null = null;
  let visitors: VisitorsPayload = {
    page: 0,
    hasAllVisitors: false,
    count: 0,
    visitors: [],
    selectedVisitor: null,
  };

  try {
    roomList = (await TCClient.virtualWaitingRoom.findAll(currentOrganizationId, {
      renderUnreadMessageCount: true,
    })) as VWR[];

    if (roomList.length > 0) {
      selectedRoom = (await TCClient.virtualWaitingRoom.find(
        roomList[0].id,
        currentOrganizationId
      )) as VWR;

      visitors = await getVisitors(selectedRoom.id, mobxProps);
    }

    loadedStatus = 'SUCCESS';
  } catch {
    loadedStatus = 'ERROR';
  }

  dispatch({
    type: 'HYDRATE_VWR',
    payload: { loadedStatus, roomList, selectedRoom, visitors },
  });
};

export const getRoomList = async (dispatch: VWRDispatch, currentOrganizationId: string) => {
  const roomList = (await TCClient.virtualWaitingRoom.findAll(currentOrganizationId, {
    renderUnreadMessageCount: true,
  })) as VWR[];

  dispatch({
    type: 'UPDATE_ROOM_LIST',
    payload: {
      roomList,
    },
  });

  return roomList;
};

export const changeSelectedRoom = async (
  dispatch: VWRDispatch,
  roomId: string,
  mobxProps: MobxPropsSlice
) => {
  dispatch({ type: 'SET_LOADING_STATUS', payload: 'PENDING' });
  const { currentOrganizationId, selectConversation } = mobxProps;

  selectConversation(null);

  const selectedRoom = (await TCClient.virtualWaitingRoom.find(
    roomId,
    currentOrganizationId
  )) as VWR;

  const visitors = await getVisitors(selectedRoom.id, mobxProps);

  dispatch({
    type: 'CHANGE_SELECTED_ROOM',
    payload: { selectedRoom, visitors },
  });

  dispatch({ type: 'SET_LOADING_STATUS', payload: 'SUCCESS' });
};

export const changeSelectedStatus = async (
  dispatch: VWRDispatch,
  roomId: string,
  selectedStatus: VisitStatus,
  mobxProps: MobxProps
) => {
  dispatch({ type: 'SET_LOADING_STATUS', payload: 'PENDING' });
  const visitors = await getVisitors(roomId, mobxProps, selectedStatus);

  dispatch({
    type: 'CHANGE_SELECTED_STATUS',
    payload: { selectedStatus, visitors },
  });
  dispatch({ type: 'SET_LOADING_STATUS', payload: 'SUCCESS' });
};

export const changeSelectedVisitor = async (
  dispatch: VWRDispatch,
  selectedVisitor: Visitor,
  { currentOrganizationId, findConversation, selectConversation }: MobxProps
) => {
  const { id: visitId, conversation, roomId } = selectedVisitor;
  let selectedConversation;

  if (!conversation) {
    selectedConversation = await findConversation(selectedVisitor.conversationId, {
      groupId: selectedVisitor.groupId,
      organizationId: currentOrganizationId,
    });
  }

  dispatch({ type: 'CHANGE_SELECTED_VISITOR', payload: selectedVisitor });

  const { activeCall } = (await TCClient.virtualWaitingRoom.getVisit(currentOrganizationId, {
    roomId,
    visitId,
  })) as Visitor;

  const updatedVisitor = { ...selectedVisitor, activeCall };
  dispatch({ type: 'UPDATE_SELECTED_VISITOR', payload: updatedVisitor });
  await selectConversation(conversation || selectedConversation);
};
