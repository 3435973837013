import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as PatientCareAdmitIcon } from '../../images/patient-care-admit-icon.svg';
import { ReactComponent as PatientCareDischargeIcon } from '../../images/patient-care-discharge-icon.svg';
import { ReactComponent as PatientCareLabIcon } from '../../images/patient-care-lab-icon.svg';
import { ReactComponent as PatientCareTransferIcon } from '../../images/patient-care-transfer-icon.svg';
import { ReactComponent as PatientCareGenericIcon } from '../../images/patient-care-generic-icon.svg';
import BEM from '../../bem';
import { Timestamp } from '../';

const classes = BEM.with('PatientCareCard');

const PATIENT_CARE_ICON = {
  ADMIT: PatientCareAdmitIcon,
  DISCHARGE: PatientCareDischargeIcon,
  LAB: PatientCareLabIcon,
  TRANSFER: PatientCareTransferIcon,
  NA: PatientCareGenericIcon,
};

class PatientCareHeader extends Component {
  static propTypes = {
    component: PropTypes.shape({
      createdAt: PropTypes.instanceOf(Date).isRequired,
      sub_type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      value_color: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { createdAt, sub_type: subType, value } = this.props.component;
    let { value_color: color } = this.props.component;

    const PatientCareIcon = PATIENT_CARE_ICON[subType];

    if (color) color = color.replace('0x', '#');

    return (
      <div className={classes('header')}>
        <PatientCareIcon className={classNames(classes('icon'), classes({ subType }))} />
        <span className={classes('title')} style={{ color }}>
          {value}
        </span>
        <Timestamp className={classes('timestamp')} value={createdAt} />
      </div>
    );
  }
}

export default observer(PatientCareHeader);
