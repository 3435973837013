import React, { useEffect } from 'react';
import { orderBy } from 'lodash';
import ToolTip from 'rc-tooltip';
import BEM from '../../../bem';

import { BasicModal, DotsIndicator } from '../../';
import { useAMContext } from '../../../../contexts/AutomatedMessages';
import { ReactComponent as PlusButtonSvg } from '../../../images/plus-lean.svg';
import { WorkflowItem, WorkflowSidebar } from './';
import { useAppSelector } from 'redux-stores';
import { UISliceState } from 'redux-stores/ui';
import { AccessibleList } from 'common/components';
import { KEYMAP } from 'common/constants';

const MAX_WORKFLOWS = 50;

const classes = BEM.with('AutomatedMessages');

export default function AutomatedMessages() {
  const {
    isLoading,
    loadWorkflows,
    selectedWorkflow,
    selectWorkflow,
    setIsEditingWorkflowName,
    setWorkflowError,
    startNewWorkflow,
    workflowError,
    workflowEvents,
    workflows,
  } = useAMContext();
  const { accessibilityMode } = useAppSelector(({ ui }: { ui: UISliceState }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));
  const canAddMore = workflows.length < MAX_WORKFLOWS;
  const uniqueNameError = workflowError === 'Workflows must have a unique label.';
  let addWorkflowsFragment;

  const addWorkflowsSvgFragment = (
    <button
      onClick={() => canAddMore && startNewWorkflow()}
      className={classes('add-button', { disabled: !canAddMore })}
      tabIndex={0}
      aria-label="Add New Workflow"
    >
      <PlusButtonSvg className={classes('add-button-svg')} aria-hidden={true} />
    </button>
  );

  if (!canAddMore) {
    addWorkflowsFragment = (
      <ToolTip
        placement="bottom"
        overlay={<span>{`${MAX_WORKFLOWS} Workflows Max`}</span>}
        trigger={['hover']}
        overlayInnerStyle={{
          padding: '6px',
          width: '93px',
          minHeight: '10px',
          textAlign: 'center',
        }}
        overlayStyle={{
          marginRight: '5px',
        }}
      >
        {addWorkflowsSvgFragment}
      </ToolTip>
    );
  } else {
    addWorkflowsFragment = addWorkflowsSvgFragment;
  }

  const closeErrorModal = () => {
    setWorkflowError('');
    setIsEditingWorkflowName(true);
  };

  useEffect(() => {
    loadWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes()}>
      <div className={classes('wrapper')}>
        <div className={classes('header')}>
          <h3 className={classes('title')} id="patient-settings-heading">
            Automated Messages ({workflows.length})
          </h3>
          {addWorkflowsFragment}
        </div>
        <div className={classes('workflows-list')}>
          <AccessibleList
            focusableClasses=".tc-AutomatedMessages__selected-workflow"
            loop={true}
            accessibilityMode={accessibilityMode}
            setStartElementOnChange={true}
          >
            {orderBy(workflows, ['isPlaceholder', 'label'], ['asc', 'asc']).map(
              ({ id, isActive, label, eventsCount, organizationId }) => (
                <div
                  aria-label={label}
                  role="button"
                  onClick={() => selectWorkflow(id)}
                  key={id}
                  className={classes('selected-workflow', {
                    isSelected: selectedWorkflow?.id === id,
                  })}
                  onKeyDown={(e) => {
                    if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
                      selectWorkflow(id);
                    }
                  }}
                >
                  <WorkflowItem
                    id={id}
                    isActive={isActive}
                    key={id}
                    label={label}
                    eventsCount={eventsCount}
                    organizationId={organizationId}
                  />
                </div>
              )
            )}
          </AccessibleList>
        </div>
        <div className={classes('loading', { isLoading })}>
          <DotsIndicator color="#969696" size={18} />
        </div>
      </div>
      {selectedWorkflow && (
        <div className={classes('sidebar-wrapper')}>
          <WorkflowSidebar
            workflow={selectedWorkflow}
            workflowEvents={workflowEvents}
            disableDeleteButton={workflows.length < 2}
          />
        </div>
      )}
      <BasicModal
        ariaLabelBody={'Workflow Delete Error'}
        ariaLabelCancelButton={'Workflow Delete Error Cancel'}
        ariaLabelCloseButton={'Workflow Delete Error Close'}
        ariaLabelHeader={'Workflow Delete Error Header'}
        ariaLabelSubmitButton={'Workflow Delete Error Delete'}
        bodyText={workflowError}
        headerText={'Workflow Error'}
        hideCancel={true}
        isOpen={!!workflowError}
        onClose={() => {
          if (uniqueNameError) closeErrorModal();
          else setWorkflowError('');
        }}
        onSubmit={() => {
          if (uniqueNameError) closeErrorModal();
          else setWorkflowError('');
        }}
        submitText={'OK'}
        size={'medium-large'}
        theme={'danger'}
      />
    </div>
  );
}
