import React from 'react';
import classNames from 'classnames';
import { mobxInjectSelect } from 'common/utils';

import { Buttons, SubmitButton } from 'common/components/Form';
import Form from 'common/components/Form/Form';
import styles from 'common/styles/components/SignInForm/WarnIEUsersUnsupportedBrowser.module.css';

type WarnIEUsersUnsupportedBrowserFormProps = {
  onSubmit: () => void;
};

type MobxProps = {
  isIeEOLFeatureFlagEnabled: boolean;
  isStaticRender: boolean;
};

function WarnIEUsersUnsupportedBrowserForm({
  onSubmit,
  isIeEOLFeatureFlagEnabled,
  isStaticRender,
}: WarnIEUsersUnsupportedBrowserFormProps & MobxProps) {
  const link = (
    <a
      className={classNames(styles.hyperText)}
      href={'https://tigerconnect.com/support/system-requirements/'}
    >
      system requirements
    </a>
  );

  return (
    <div>
      <Form
        className={classNames(styles.form)}
        columns={2}
        isWorking={isStaticRender}
        onSubmit={onSubmit}
        theme="rows"
      >
        {isIeEOLFeatureFlagEnabled ? (
          <div className={classNames(styles.bodyText)}>
            Internet Explorer is not a supported browser for TigerConnect. Please contact your IT
            administrator for help installing a supported browser referenced on the {link} page
          </div>
        ) : (
          <div className={classNames(styles.bodyText)}>
            Internet Explorer browser is no longer supported and starting December 1st, 2022 will
            stop working with TigerConnect. Contact your IT administrator for help installing a
            supported browser referenced on the TigerConnect {link} page.
          </div>
        )}

        {!isIeEOLFeatureFlagEnabled && (
          <div className={classNames(styles.buttonContainer)}>
            <Buttons>
              <SubmitButton
                ariaLabel="continue to login"
                className={classNames(styles.continue)}
                disabled={isStaticRender}
                data-test-id={'continue-to-login'}
                isWorking={isStaticRender}
              >
                CONTINUE TO LOGIN
              </SubmitButton>
            </Buttons>
          </div>
        )}
      </Form>
    </div>
  );
}

export default mobxInjectSelect<WarnIEUsersUnsupportedBrowserFormProps, MobxProps>({
  messengerStore: ['isIeEOLFeatureFlagEnabled', 'isStaticRender'],
})(WarnIEUsersUnsupportedBrowserForm);
