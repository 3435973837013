import React, { useEffect, useState } from 'react';

import TCClient from '../../../../client';

import { Table, TableRow } from '../../../shared-components/Table/Table';
import { ViewContainer } from '../../../shared-components/View/ViewContainer/ViewContainer';

import { TabProps } from './';
import { useAppSelector } from 'redux-stores';

const userPermissionsNameMappings = {
  virtual_waiting_room_admin: 'virtual waiting room admin',
  tigertouchplus_reporting: 'patient video reporting',
  patient_data_audit_admin: 'patient audit admin',
  patient_context_reporting: 'patient context reporting',
  team_admin: 'team admin',
  reporting: 'reporting',
  provider_broadcast_list_admin: 'provider broadcast list admin',
  patient_messaging_admin: 'patient messaging admin',
  help_desk: 'help desk',
  feature_role_admin: 'feature role admin',
  customer: 'customer',
  admin: 'admin',
  patient_data_report_analytics: 'patient data report analytics',
  scim_integration_admin: 'scim integration admin',
} as { [key: string]: string };

export default function UserPermissions({ onClose }: TabProps) {
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);

  const [rows, setRows] = useState<Array<TableRow>>([]);

  useEffect(() => {
    async function getUserPermissions() {
      try {
        const { results } = await TCClient.api.adminOrganizations.usersInRoles({
          organizationId: selectedOrganizationId,
        });
        const rows = results
          .filter((user: { rolesInOrg: Array<string> }) => user.rolesInOrg.length > 0)
          .map(
            (user: {
              token: string;
              firstName: string;
              lastName: string;
              rolesInOrg: Array<string>;
              displayName: string;
            }) =>
              ({
                id: user.token,
                user: `${user.firstName} ${user.lastName}`,
                permissions: user.rolesInOrg
                  .map((role) => userPermissionsNameMappings[role] || role)
                  .join(', '),
              } as TableRow)
          );

        setRows(rows);
      } catch (e) {
        console.error(e);
      }
    }

    getUserPermissions();
  }, [selectedOrganizationId]);

  const columns = [
    { field: 'user', headerName: 'User' },
    { field: 'permissions', headerName: 'Permissions' },
  ];

  return (
    <ViewContainer
      {...{
        header: 'User Permissions',
        subHeader: 'View users within the organization who have admin permissions.',
        onClose,
      }}
    >
      <div>
        <Table rows={rows} columns={columns} />
      </div>
    </ViewContainer>
  );
}
