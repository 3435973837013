import React, { useCallback, useEffect, useState } from 'react';
import { trim } from 'lodash';
import { useDispatch } from 'react-redux';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { Color } from '../../../../types';
import BEM from '../../../bem';
import { MapStateToProps, reduxInjectSelect } from '../../../utils/reduxInjectSelect';
import { BasicModal } from '../..';
import TagItem from '../Tags/TagItem';
import TagColorSelector from '../Tags/TagColorSelector';

const { setModal, setTagBeingEdited } = actions;
const { saveTag } = thunk;
const classes = BEM.with('CollaborationModal');

const DEFAULT_COLOR = {
  colorId: '',
  colorValue: '0xFFF',
  colorName: 'No tag color selected',
};

const reduxSelectors = {
  session: ['openModal'],
  collab: ['selectedOrgId'],
  tags: ['checkedTagsById', 'colors', 'selectedTag', 'tagBeingEdited', 'tagModalError', 'tagsById'],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type TagModalProps = { isOpen: boolean };

function TagModal({
  checkedTagsById,
  colors,
  isOpen,
  selectedOrgId,
  selectedTag,
  tagBeingEdited,
  tagModalError,
  tagsById,
}: ReduxProps & TagModalProps) {
  const dispatch = useDispatch();

  const [colorsOpen, setColorsOpen] = useState(false);
  const [color, setColor] = useState<Color>(DEFAULT_COLOR);
  const [tagName, setTagName] = useState('');

  useEffect(() => {
    setColor(colors.find((c) => c.colorId === tagBeingEdited?.colorId) || DEFAULT_COLOR);
    setTagName(tagBeingEdited?.name || '');
  }, [colors, tagBeingEdited]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };

  const clear = useCallback(() => {
    setTagName('');
    setColor(DEFAULT_COLOR);
    setColorsOpen(false);
    dispatch(setTagBeingEdited({ tag: undefined }));
  }, [dispatch]);

  const save = async () => {
    const success = await saveTag(dispatch, {
      id: tagBeingEdited?.id,
      name: trim(tagName),
      color,
      organizationId: selectedOrgId,
      tagsById: {
        ...(selectedTag ? { [selectedTag.id]: selectedTag } : null),
        ...checkedTagsById,
        ...tagsById,
      },
    });
    success && clear();
  };

  const openDeleteTagModal = () => {
    dispatch(setModal({ name: 'deleteTag' }));
  };

  const selectColor = (color?: Color) => {
    setColor(color || DEFAULT_COLOR);
    setColorsOpen(false);
  };

  const toggleColorsMenu = () => {
    setColorsOpen(!colorsOpen);
  };

  const closeModal = () => {
    dispatch(setModal(undefined));
    clear();
  };

  return (
    <BasicModal
      ariaLabelBody={'Manage Tag Info'}
      ariaLabelCancelButton={'Manage Tag Cancel'}
      ariaLabelCloseButton={'Manage Tag Close'}
      ariaLabelDangerButton={'Manage Tag Delete'}
      ariaLabelHeader={'Manage Tag Header'}
      ariaLabelSubmitButton={'Manage Tag Submit'}
      headerText={tagBeingEdited ? 'Edit tag' : 'Create a new tag'}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={save}
      onDangerSubmit={tagBeingEdited && openDeleteTagModal}
      dangerText={'DELETE'}
      size={'medium'}
      submitDisabled={!color.colorId || !trim(tagName)}
      submitText={'SAVE'}
      useWCL
    >
      <div className={classes('')}>
        <div className={classes('error-message-container')}>
          {tagModalError && (
            <div className={classes('error-message')}>
              Tag name and color combination already taken
            </div>
          )}
        </div>
        <div className={classes('input-group')}>
          <label className={classes('label')} htmlFor="tagName">
            TAG NAME
          </label>
          <input
            data-test-id="tag name"
            className={classes('input')}
            id="tagName"
            maxLength={256}
            name="tagName"
            onChange={handleNameChange}
            placeholder="Write your tag's name"
            type="text"
            value={tagName}
          />
        </div>
        <div className={classes('input-group')}>
          <label htmlFor="colorsMenu" className={classes('label')}>
            SELECT COLOR
          </label>
          <div className={classes('selector')}>
            {colorsOpen ? (
              <TagColorSelector selectColor={selectColor} toggleHandler={toggleColorsMenu} />
            ) : (
              <div
                className={classes('tag-color-button')}
                id="colorsMenu"
                onClick={toggleColorsMenu}
              >
                <TagItem
                  colorHex={color.colorValue.replace('0x', '#')}
                  hasBorder={color.colorId === ''}
                  isAdmin={true}
                  name={color.colorName}
                  noIcon
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </BasicModal>
  );
}

export default reduxInjectSelect<TagModalProps, ReduxProps, ReduxState>(reduxSelectors)(TagModal);
