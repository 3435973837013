import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import {
  addSharedMembers,
  loadProviderBroadcastListSharedMembers,
  removeSharedMembers,
} from '../../../contexts/BroadcastListSettings/api';
import { BROADCAST_LIST_SEARCH_TYPES } from '../../../models/enums/SearchTypes';

import { Dropdown, UserAvatar } from '../';
import { ReactComponent as AddMembersIcon } from '../../images/add-shared-members.svg';
import { ReactComponent as AddMembersIconActive } from '../../images/add-shared-members-active.svg';
import { ReactComponent as RemovePendingMemberIcon } from '../../images/x-white-icon.svg';
import { ReactComponent as RemoveMemberIcon } from '../../images/x-gray-icon.svg';

const classes = BEM.with('ShareBroadcastListSearchBox');

const DEBOUNCE_TIMEOUT = 500;
const SHARED_MEMBERS_SIZE_LIMIT = 30;

type SearchResult = {
  entity: {
    displayName: string;
    id: string;
  };
};

type SearchProps = {
  text: string;
  searchTypes: string[];
  organization: { id: string };
  excludeRoles: boolean;
  isShareBLSearch: boolean;
};

type ShareBroadcastListSearchBoxProps = {};

type MobxProps = {
  currentOrganization: {
    id: string;
  };
  listId: string;
  search: (props: SearchProps) => void;
  searchResults: SearchResult[];
};

function ShareBroadcastListSearchBox({
  currentOrganization,
  listId,
  search,
  searchResults,
}: ShareBroadcastListSearchBoxProps & MobxProps) {
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);
  const [pendingMembers, setPendingMembers] = useState<SearchResult['entity'][]>([]);
  const [pendingMemberIds, setPendingMemberIds] = useState<string[]>([]);
  const [pendingRemoveMemberIds, setPendingRemoveMemberIds] = useState<string[]>([]);
  const [members, setMembers] = useState<SearchResult['entity'][]>([]);
  const [memberIds, setMemberIds] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSearchResults, setFilteredSearchResults] = useState<SearchResult[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);
  const [isAddMembersButtonHovered, setIsAddMembersButtonHovered] = useState(false);
  const searchDebounced = useRef(debounce(search, DEBOUNCE_TIMEOUT));

  useEffect(() => {
    async function getMembers() {
      setIsLoadingMembers(true);
      try {
        const { results } = await loadProviderBroadcastListSharedMembers({
          id: listId,
          continuation: '',
          currentOrganizationId: currentOrganization.id,
          query: '',
          sortBy: ['name'],
          sortOrder: ['asc'],
        });

        setMembers(results);
      } catch (error) {
        console.error(error);
      }
      setIsLoadingMembers(false);
    }
    getMembers();
  }, [currentOrganization.id, listId]);

  useEffect(() => {
    setMemberIds(members.map((member) => member.id));
  }, [members]);

  useEffect(() => {
    let filteredResults = searchResults;
    if (
      searchResults.length > 0 &&
      (pendingRemoveMemberIds.length > 0 || pendingMemberIds.length > 0 || memberIds.length > 0)
    ) {
      filteredResults = searchResults.filter(
        ({ entity: { id } }) =>
          !memberIds.includes(id) &&
          !pendingMemberIds.includes(id) &&
          !pendingRemoveMemberIds.includes(id)
      );
    }
    setFilteredSearchResults(filteredResults);
  }, [memberIds, searchResults, pendingMemberIds, pendingRemoveMemberIds]);

  useEffect(() => {
    if (isSearchInputOpen) {
      try {
        searchDebounced.current({
          text: searchQuery,
          searchTypes: BROADCAST_LIST_SEARCH_TYPES,
          organization: currentOrganization,
          excludeRoles: true,
          isShareBLSearch: true,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [currentOrganization, isSearchInputOpen, search, searchQuery]);

  async function reloadState() {
    try {
      const { results } = await loadProviderBroadcastListSharedMembers({
        id: listId,
        continuation: '',
        currentOrganizationId: currentOrganization.id,
        query: '',
        sortBy: ['name'],
        sortOrder: ['desc'],
      });

      setMembers(results);
      setPendingMemberIds([]);
      setPendingMembers([]);
      setPendingRemoveMemberIds([]);
      setIsSaving(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function saveMembers() {
    setIsSaving(true);
    try {
      if (pendingRemoveMemberIds.length > 0) {
        await removeSharedMembers({
          id: listId,
          organizationId: currentOrganization.id,
          members: pendingRemoveMemberIds,
        });
      }

      if (pendingMemberIds.length > 0) {
        await addSharedMembers({
          id: listId,
          organizationId: currentOrganization.id,
          members: pendingMemberIds,
        });
      }
    } catch (error) {
      console.error(error);
    }

    setTimeout(reloadState, 1500);
  }

  function removeExistingMember(memberId: string) {
    setPendingRemoveMemberIds(pendingRemoveMemberIds.concat([memberId]));
    setMembers(members.filter((member) => member.id !== memberId));
  }

  function addPendingMember(entity: SearchResult['entity']) {
    if (!isMaxLimit() && !pendingMemberIds.includes(entity.id)) {
      setIsSearchInputOpen(false);
      setPendingMemberIds(pendingMemberIds.concat([entity.id]));
      setPendingMembers(pendingMembers.concat([entity]));
    }
  }

  function removePendingMember(memberId: string) {
    setPendingMembers(pendingMembers.filter((member) => member.id !== memberId));
    setPendingMemberIds(pendingMemberIds.filter((id) => id !== memberId));
  }

  function toggleSearchInput() {
    setIsSearchInputOpen(!isSearchInputOpen);
  }

  function handleSearchInput(e: React.ChangeEvent) {
    const value = (e.target as HTMLInputElement).value;
    setSearchQuery(value);
  }

  function isMaxLimit() {
    return members.length + pendingMembers.length >= SHARED_MEMBERS_SIZE_LIMIT;
  }

  return (
    <div className={classes()}>
      <div className={classes('sbl-header')}>
        <div className={classes('sbl-share-count')}>
          Shared with {members.length + pendingMembers.length}/30 people
        </div>
        <div
          className={classes('sbl-add-button')}
          onClick={toggleSearchInput}
          onMouseEnter={() => setIsAddMembersButtonHovered(true)}
          onMouseLeave={() => setIsAddMembersButtonHovered(false)}
        >
          {isSearchInputOpen || isAddMembersButtonHovered ? (
            <AddMembersIconActive />
          ) : (
            <AddMembersIcon />
          )}
        </div>
      </div>
      {isSearchInputOpen && (
        <div className={classes('sbl-results-dropdown')}>
          <Dropdown ariaLabel={'sbl-results-dropdown'} triggerHandler={toggleSearchInput}>
            <>
              {isMaxLimit() && (
                <div className={classes('sbl-max-limit-text')}>
                  Max limit for shared people reached.
                </div>
              )}
              <div className={classes('sbl-search')}>
                <input
                  data-test-id="sbl-search-input"
                  type="text"
                  placeholder="Search for members"
                  onChange={handleSearchInput}
                  value={searchQuery}
                  autoFocus
                />
              </div>
              {filteredSearchResults.length > 0 && (
                <div className={classes('sbl-results')}>
                  {filteredSearchResults.map(({ entity }) => (
                    <div
                      key={entity.id}
                      className={classes('sbl-result-item')}
                      onClick={() => {
                        addPendingMember(entity);
                      }}
                    >
                      <div className={classes('sbl-result-avatar')}>
                        <UserAvatar className={classes('avatar')} size={24} user={entity} />
                      </div>
                      <div className={classes('sbl-result-name')}>{entity.displayName}</div>
                    </div>
                  ))}
                </div>
              )}
            </>
          </Dropdown>
        </div>
      )}
      {!isLoadingMembers && members.length === 0 && pendingMembers.length === 0 && (
        <div className={classes('sbl-empty-list-text')}>No members are shared.</div>
      )}
      <div className={classes('sbl-shared-members-list')}>
        {pendingMembers.length > 0 && (
          <>
            <div className={classes('sbl-pending-results')}>
              {pendingMembers.map((member) => (
                <div key={member.id} className={classes('sbl-result-item')}>
                  <div className={classes('sbl-result-avatar')}>
                    <UserAvatar className={classes('avatar')} size={24} user={member} />
                  </div>
                  <div className={classes('sbl-result-name')}>{member.displayName}</div>
                  <div className={classes('sbl-result-remove-button')}>
                    <RemovePendingMemberIcon onClick={() => removePendingMember(member.id)} />
                  </div>
                </div>
              ))}
            </div>
            <hr className={classes('sbl-pending-results-divider')} />
          </>
        )}
        {members.length > 0 && (
          <div className={classes('sbl-current-results')}>
            {members.map((member) => (
              <div key={member.id} className={classes('sbl-result-item')}>
                <div className={classes('sbl-result-avatar')}>
                  <UserAvatar className={classes('avatar')} size={24} user={member} />
                </div>
                <div className={classes('sbl-result-name')}>{member.displayName}</div>
                <div className={classes('sbl-result-remove-button')}>
                  <RemoveMemberIcon onClick={() => removeExistingMember(member.id)} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {(pendingMembers.length > 0 || pendingRemoveMemberIds.length > 0) && (
        <div className={classes('sbl-actions')}>
          <div
            data-test-id="sbl-cancel-button"
            onClick={reloadState}
            className={classes('sbl-cancel-button')}
          >
            Cancel
          </div>
          <div
            data-test-id="sbl-save-button"
            onClick={saveMembers}
            className={classes('sbl-save-button')}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </div>
        </div>
      )}
    </div>
  );
}

export default mobxInjectSelect<ShareBroadcastListSearchBoxProps, MobxProps>({
  messengerStore: ['currentOrganization'],
  recipientPickerStore: ['search', 'searchResults'],
})(ShareBroadcastListSearchBox);
