import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '../../../../common/components/WebComponents';
import mobxInjectSelect from '../../../../common/utils/mobxInjectSelect';
import propTypes from '../../../../common/propTypes';
import BEM from '../../../../common/bem';
import { ReactComponent as DndSvg } from '../../../../common/images/dnd-icon.svg';
import DndRosterAutoForwardRecipientText from './DndRosterAutoForwardRecipientText';

const classes = BEM.with('DndRoster');

class DndRoster extends Component {
  static propTypes = {
    currentOrganization: propTypes.organization,
    currentUser: propTypes.user.isRequired,
    hideDnd: PropTypes.bool,
    isExtendedAutoForwardOptionsEnabled: PropTypes.bool,
    isProviderNetwork: PropTypes.bool.isRequired,
    removeAutoForward: PropTypes.func.isRequired,
    setAwayMessage: PropTypes.func.isRequired,
    showDndUndoButton: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    turnOffDndFromRoster: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
  };

  state = {
    draftDnd: true,
    draftAutoForward: true,
  };

  UNSAFE_componentWillUpdate() {
    const { draftAutoForward, draftDnd } = this.state;

    if (draftDnd !== true) {
      this.setState({ draftDnd: true });
    }
    if (draftAutoForward !== true) {
      this.setState({ draftAutoForward: true });
    }
  }

  render() {
    const { draftDnd, draftAutoForward } = this.state;
    const {
      currentUser,
      currentOrganization,
      hideDnd,
      isExtendedAutoForwardOptionsEnabled,
      isProviderNetwork,
      showDndUndoButton,
      title,
    } = this.props;
    const { autoForwardEntitiesIds, autoForwardReceiver } = currentOrganization || {};

    let showDnd = false;
    if (isProviderNetwork) {
      showDnd = currentUser.dnd && !hideDnd;
    } else if (
      currentOrganization &&
      currentOrganization.networks &&
      currentOrganization.networks.patient
    ) {
      showDnd = currentOrganization.networks.patient.dnd;
    }
    const showAutoForward = isExtendedAutoForwardOptionsEnabled
      ? !!autoForwardEntitiesIds
      : !!autoForwardReceiver;

    return (
      <div className={classes()}>
        <div
          className={classes('details', {
            enable: showDnd && !showDndUndoButton,
          })}
        >
          <div className={classes('dnd')}>
            <DndSvg className={classes('icon')} />
            <div className={classes('text')}>{title}</div>
            <div className={classes('switch')}>
              <Switch
                checked={draftDnd}
                disabled={!currentOrganization}
                onSwitch={this._onDndButtonClick}
                dataTestId="rosterDnd"
              />
            </div>
          </div>
          <div className={classes('auto-forward', { enable: showAutoForward })}>
            <div className={classes('text')}>Auto-Forwarding:</div>
            {showAutoForward && (
              <div className={classes('users')}>
                <div className={classes('switch')}>
                  <Switch
                    checked={draftAutoForward}
                    disabled={!currentOrganization}
                    onSwitch={this._onAutoForwardUserButtonClick}
                    dataTestId="autoForwardUser"
                  />
                </div>
                <span className={classes('user')}>
                  <DndRosterAutoForwardRecipientText
                    autoForwardEntitiesIds={autoForwardEntitiesIds}
                    autoForwardReceiver={autoForwardReceiver}
                    isExtendedAutoForwardOptionsEnabled={isExtendedAutoForwardOptionsEnabled}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  _onAutoForwardUserButtonClick = () => {
    const { currentOrganization, removeAutoForward } = this.props;
    const { draftAutoForward } = this.state;

    this.setState({ draftAutoForward: !draftAutoForward });

    removeAutoForward(currentOrganization);
  };

  _onDndButtonClick = async () => {
    const {
      currentOrganization,
      currentUser,
      isAvailabilityFeatureFlagEnabled,
      isProviderNetwork,
      setAwayMessage,
      turnOffDndFromRoster,
      update,
    } = this.props;
    const { draftDnd } = this.state;

    if (this.props.isAvailabilityFeatureFlagEnabled && draftDnd) {
      this.props.logPendoAnalytics({
        tracker: {
          name: 'Admin + Settings | Settings - Unavailable Cancel',
          props: { UnavailableCancelLocation: 'Roster' },
        },
      });
    }

    this.setState({ draftDnd: !draftDnd });
    if (!isProviderNetwork) {
      setAwayMessage(currentOrganization.id, {
        dnd: !draftDnd,
        dndText: currentOrganization.networks.patient.dndText,
      });
    } else if (currentUser.dnd) {
      if (isAvailabilityFeatureFlagEnabled) {
        update({ dnd: false });
      } else {
        turnOffDndFromRoster(currentOrganization);
      }
    }
  };
}

export default mobxInjectSelect({
  messengerStore: [
    'currentOrganization',
    'isAvailabilityFeatureFlagEnabled',
    'isExtendedAutoForwardOptionsEnabled',
  ],
  networkStore: ['isProviderNetwork'],
  sessionStore: ['currentUser'],
  trackerStore: ['logPendoAnalytics'],
  userStore: [
    'hideDnd',
    'removeAutoForward',
    'setAwayMessage',
    'showDndUndoButton',
    'turnOffDndFromRoster',
    'update',
  ],
})(DndRoster);
