import createMobxModel from './createMobxModel';

export const DistributionList = createMobxModel({
  name: 'distributionList',

  fields: [
    'adminOnly',
    'conversationId',
    'createdByDisplayName',
    'createdOn',
    'displayName',
    'id',
    'isAdSync',
    'memberCount',
    'name',
    'network',
    'organizationId',
    'securityGroupName',
    'syncStatus',
    'updatedByDisplayName',
    'updatedOn',
  ],

  relations: {
    one: {
      createdBy: { type: 'user' },
      organization: { type: 'organization' },
      updatedBy: { type: 'user' },
    },
  },
});

export default DistributionList;
