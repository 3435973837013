import React from 'react';
import { observer } from 'mobx-react';
import BEM from '../../../common/bem';

const classes = BEM.with('AutomatedMessageHeader');

type AutomatedMessageHeaderProps = {
  message: {
    isOutgoing: boolean;
  };
  headerText?: string;
};

function AutomatedMessageHeader({
  message,
  headerText = 'AUTOMATED MESSAGE',
}: AutomatedMessageHeaderProps) {
  const { isOutgoing } = message;
  const direction = isOutgoing ? 'OUTGOING' : 'INCOMING';

  return (
    <div className={classes({ direction })}>
      <div className={classes('automated-text')}>{headerText}</div>
    </div>
  );
}

export default observer(AutomatedMessageHeader);
