import React from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import { ReactComponent as DropDownSvg } from '../../../images/pa-dropdown.svg';
import { AppTypes } from '../../../../models/enums';
import { PatientBroadcastRow } from './';
import AccessibleList from 'common/components/AccessibleList';
import { useAppSelector } from 'redux-stores';

const classes = BEM.with('PatientBroadcastTable');

const PatientBroadcastTable = ({
  currentAppSelected,
  editBroadcastList,
  isAdmin,
  isLoadingMore,
  queryValue,
  results,
  scrollContainerRef,
  selected,
  sortBy,
  sortOrder,
  toggleSelected,
  toggleSort,
}) => {
  const accessibilityMode = useAppSelector((state) => state.ui.accessibilityMode);
  let resultsFragment;

  const ariaSortString = (colName) => {
    return sortBy === colName ? (sortOrder === 'desc' ? 'descending' : 'ascending') : 'none';
  };

  if (results.length === 0) {
    if (isLoadingMore) {
      resultsFragment = <div />;
    } else if (queryValue) {
      resultsFragment = (
        <div className={classes('empty')}>No results found for &quot;{queryValue}&quot;</div>
      );
    } else {
      resultsFragment = (
        <div className={classes('empty')}>
          No {currentAppSelected === AppTypes.BROADCAST_LISTS ? 'Provider' : 'Patient'} Broadcast
          Lists available
          {isAdmin && <span>, click &quot;Create List&quot; to get started</span>}
        </div>
      );
    }
  } else {
    resultsFragment = results.map((broadcastList) => {
      return (
        <PatientBroadcastRow
          {...broadcastList}
          createdBy={broadcastList.createdBy}
          currentAppSelected={currentAppSelected}
          editBroadcastList={editBroadcastList}
          isAdmin={isAdmin}
          isPregen={broadcastList.id.includes('@')}
          isSelected={selected.includes(broadcastList.id)}
          key={broadcastList.id}
          toggleSelected={toggleSelected}
          updatedBy={broadcastList.updatedBy}
          adminOnly={broadcastList.adminOnly}
          memberCount={broadcastList.memberCount}
        />
      );
    });
  }

  return (
    <div className={classes()} role="table" aria-describedby="Patient Broadcast List table">
      <div className={classes('list')}>
        <div
          className={classes('list-header', { currentAppSelected, noCheckbox: !isAdmin })}
          role="rowgroup"
        >
          {isAdmin && (
            <div
              className={classes('list-item', { checkbox: true })}
              role="columnheader"
              aria-label="Select all items"
            />
          )}
          <button
            className={classes('list-item', { three: true })}
            onClick={() => toggleSort('name')}
            aria-sort={ariaSortString('name')}
            role="columnheader"
          >
            Broadcast List Label
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'name',
              })}
              aria-hidden
            />
          </button>
          <button
            className={classes('list-item')}
            onClick={() => toggleSort('total_members')}
            aria-sort={ariaSortString('total_members')}
            role="columnheader"
          >
            People
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'total_members',
              })}
              aria-hidden
            />
          </button>
          <button
            className={classes('list-item')}
            onClick={() => toggleSort('created_by_display_name')}
            aria-sort={ariaSortString('created_by_display_name')}
            role="columnheader"
          >
            Created by
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'created_by_display_name',
              })}
              aria-hidden
            />
          </button>
          <button
            className={classes('list-item')}
            onClick={() => toggleSort('created_on')}
            aria-sort={ariaSortString('created_on')}
            role="columnheader"
          >
            Created on
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'created_on',
              })}
              aria-hidden
            />
          </button>
          <button
            className={classes('list-item')}
            onClick={() => toggleSort('updated_on')}
            aria-sort={ariaSortString('updated_on')}
            role="columnheader"
          >
            Edited
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'updated_on',
              })}
              aria-hidden
            />
          </button>
          {(currentAppSelected === AppTypes.BROADCAST_LISTS ||
            (currentAppSelected === AppTypes.PATIENT_SETTINGS && isAdmin)) && (
            <button
              className={classes('list-item')}
              onClick={() => toggleSort('admin_only')}
              aria-sort={ariaSortString('admin_only')}
              role="columnheader"
            >
              Access
              <DropDownSvg
                className={classes('sort-arrow', {
                  isDesc: sortOrder === 'desc',
                  sortBy: sortBy === 'admin_only',
                })}
                aria-hidden
              />
            </button>
          )}
        </div>
        <div
          className={classes('list-body')}
          ref={scrollContainerRef}
          id="Patient Broadcast List table"
        >
          <AccessibleList
            focusableClasses={'.tc-PatientBroadcastRow'}
            accessibilityMode={accessibilityMode}
            focusableChildrenClasses={'.tc-PatientBroadcastRow__list-item-checkbox-default'}
            setStartElementOnChange={true}
            role="rowgroup"
          >
            {resultsFragment}
          </AccessibleList>
        </div>
      </div>
    </div>
  );
};

PatientBroadcastTable.propTypes = {
  currentAppSelected: PropTypes.string.isRequired,
  editBroadcastList: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  queryValue: PropTypes.string.isRequired,
  results: PropTypes.array.isRequired,
  scrollContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  selected: PropTypes.array.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  toggleSort: PropTypes.func.isRequired,
};

export default PatientBroadcastTable;
