import React, { Component } from 'react';
import classNames from 'classnames';
import BEM from '../../bem';

const classes = BEM.with('FormInput');

const withInput = ({
  classTypeModifier,
  defaultValue = '',
  forceType,
  valueAttribute = 'value',
}) => {
  class InputElement extends Component {
    render() {
      const {
        ariaLabel,
        autocomplete,
        className,
        disabled,
        'data-test-id': dataTestId = '',
        value,
        inputRef,
        isFormSubmitted,
        isPristine,
        isValid,
        maxLength,
        name,
        placeholder,
        showRequired,
        type,
      } = this.props;

      return (
        <input
          className={classNames(
            className,
            classes({
              type: classTypeModifier,
              required: showRequired,
              [isValid ? 'valid' : 'invalid']: true,
              'form-submitted': isFormSubmitted,
              [isPristine ? 'pristine' : 'dirty']: true,
            })
          )}
          aria-label={ariaLabel}
          autoComplete={autocomplete}
          data-test-id={dataTestId}
          disabled={disabled ? 'disabled' : false}
          maxLength={maxLength}
          name={name}
          onChange={this._onChange}
          onFocus={this._onFocus}
          placeholder={placeholder}
          ref={inputRef}
          type={forceType || type}
          {...{ [valueAttribute]: value || defaultValue }}
        />
      );
    }

    _onChange = (e) => {
      const { onChange, setValue } = this.props;

      setValue(e.target[valueAttribute]);
      onChange && onChange(e.target[valueAttribute]);
    };

    _onFocus = () => {
      const { onFocus } = this.props;

      onFocus && onFocus();
    };
  }

  return InputElement;
};

export default withInput;
