import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import BEM from '../../bem';
import CheckboxInput from './CheckboxInput';
import FormFieldWrapper from './FormFieldWrapper';

const classes = BEM.with('FormField');

class TextField extends Component {
  render() {
    const { caption, ...restProps } = this.props;

    return (
      <FormFieldWrapper {...restProps} type="checkbox">
        <CheckboxInput {...restProps} />
        {caption && <span className={classes('caption')}>{caption}</span>}
      </FormFieldWrapper>
    );
  }
}

export default withFormsy(TextField);
