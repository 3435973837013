import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Appointment } from '../../../../types';
import BEM from '../../../bem';
import { ReactComponent as CheckMarkSvg } from '../../../images/green-checkmark.svg';
import { mobxInjectSelect } from '../../../utils';

const classes = BEM.with('AppointmentList');

type AppointmentListProps = {
  appointment: Appointment;
  isPastAppointments: boolean;
  itemsToDelete: string[];
  patientId: string;
  patientName: string;
  setItemsToDelete: (items: string[]) => void;
};

type MobxProps = {
  openAppointmentModal: (appointment: Appointment, isPastAppointments: boolean) => void;
};

function AppointmentList({
  appointment,
  isPastAppointments,
  itemsToDelete,
  openAppointmentModal,
  patientId,
  patientName,
  setItemsToDelete,
}: AppointmentListProps & MobxProps) {
  const [isChecked, setIsChecked] = useState(false);
  const appointmentDateGiven = `${appointment.appointmentDate}T${appointment.appointmentTime}`;
  const formattedDate = moment
    .tz(appointmentDateGiven, appointment.appointmentTimezone)
    .format('LLLL');
  const timeZone = moment.tz(`${appointment.appointmentTimezone}`).zoneAbbr();

  const selectAppointment = (id: string) => {
    const newCheckedValue = !isChecked;
    setIsChecked(newCheckedValue);
    if (newCheckedValue) setItemsToDelete([id, ...itemsToDelete]);
    else setItemsToDelete(itemsToDelete.filter((selectedId) => selectedId !== id));
  };

  useEffect(() => {
    setIsChecked(itemsToDelete.includes(appointment.id));
  }, [appointment.id, itemsToDelete]);

  return (
    <div className={classes()}>
      <div className={classes('appointment-container', { isChecked })}>
        <button
          onClick={() => {
            selectAppointment(appointment.id);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              selectAppointment(appointment.id);
            }
          }}
          className={classes('check-mark', { isChecked })}
          aria-label="Select appointment"
        >
          <CheckMarkSvg className={classes('check-mark-svg')} aria-hidden />
        </button>
        <button
          className={classes('appointment-details')}
          onClick={() =>
            openAppointmentModal({ ...appointment, patientId, patientName }, isPastAppointments)
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              openAppointmentModal({ ...appointment, patientId, patientName }, isPastAppointments);
            }
          }}
          aria-label="View Appointment Information"
        >
          <div className={classes('appointment-primary')}>
            <div className={classes('appointment-date-time')}>
              {formattedDate}
              <span> {timeZone} </span>
            </div>
            <div className={classes('appointment-provider-location')}>
              <div className={classes('appointment-provider')} title={appointment.provider}>
                {appointment.provider}
              </div>
              <div className={classes('appointment-location')} title={appointment.location}>
                , {appointment.location}
              </div>
            </div>
          </div>
          {appointment.visitNumber && (
            <div className={classes('appointment-visit-number')}>
              Visit Number: {appointment.visitNumber}
            </div>
          )}
        </button>
      </div>
    </div>
  );
}

export default mobxInjectSelect<AppointmentListProps, MobxProps>({
  appointmentsStore: ['openAppointmentModal'],
})(AppointmentList);
