import React from 'react';
import classNames from 'classnames';

import styles from './ActionBarTabButton.module.css';

export interface ActionBarTabButtonProps {
  /**
   * Optional data test id
   */
  dataTestId?: string;
  /**
   * Button selected mode
   */
  isSelected: boolean;
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  customStyles?: Record<string, string>;
}

export const ActionBarTabButton: React.FC<ActionBarTabButtonProps> = ({
  dataTestId,
  isSelected,
  label,
  customStyles = {},
  ...props
}) => {
  const currentStyles = { ...styles, ...customStyles };
  return (
    <button
      className={classNames(currentStyles.tabButton)}
      data-test-id={`actionBar${dataTestId || label}`}
      type="button"
    >
      <div
        className={classNames(currentStyles.button, isSelected ? currentStyles.isSelected : '')}
        {...props}
      >
        {label}
      </div>
    </button>
  );
};
