import React from 'react';
import { ActionButton } from '../../../shared-components/ActionButton/ActionButton';
import styles from './CreateNewFacility.module.css';
import { actions, useAppDispatch } from 'redux-stores';

export function CreateNewFacility() {
  const dispatch = useAppDispatch();

  return (
    <div>
      <ActionButton
        customStyles={styles}
        onClick={() => {
          dispatch(
            actions.setFacilityModalOpen({
              isFacilityModalOpen: true,
            })
          );
        }}
        textAlign={'center'}
        toolTipText={'New Facility'}
      />
    </div>
  );
}
