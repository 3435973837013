import React from 'react';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react';

import { AlertComponent, Message } from '../../../../../src/types/';
import BEM from '../../../../common/bem';

const classes = BEM.with('AlertHeader');

const DATE_TIME_FORMAT = 'MM/dd/yy p';

export type AlertHeaderProps = {
  component: AlertComponent;
  createdAt: string;
  message: Message;
  onStatusClick?: () => void;
};

const AlertHeader = ({ component, createdAt, message, onStatusClick }: AlertHeaderProps) => {
  const { value: text } = component;
  const { alertDetails, alertRecipients, statusesPerRecipient } = message;
  let numPrimaryReactions = 0;
  let { bg_color: backgroundColor } = component;

  if (backgroundColor) {
    backgroundColor = backgroundColor.replace('0x', '#');
  }

  const shouldShowRecipientSummary =
    message.featureService === 'group_alerts' && alertRecipients && alertDetails?.primaryAction;
  if (shouldShowRecipientSummary) {
    for (const statusPerRecipient of statusesPerRecipient) {
      if (statusPerRecipient.reaction === alertDetails?.primaryAction) {
        numPrimaryReactions++;
      }
    }
  }

  return (
    <div className={classes()}>
      <div className={classes('info-container')}>
        <div
          className={classes('alert-severity')}
          style={{ backgroundColor }}
          data-test-id={'alertSeverity'}
          aria-label="alert-severity"
        >
          {text}
        </div>
        <div className={classes('alert-time')}>{format(parseISO(createdAt), DATE_TIME_FORMAT)}</div>
      </div>
      {shouldShowRecipientSummary && (
        <div className={classes('status-container')}>
          <div
            className={classes('status-info', { clickable: !!onStatusClick })}
            onClick={onStatusClick}
            data-test-id={'statusInfo'}
            aria-label="status-info"
          >
            {numPrimaryReactions}/{alertRecipients.length} Chose "{alertDetails?.primaryAction}"
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(AlertHeader);
