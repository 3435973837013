import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as PatientAvatar } from '../../../common/images/default-avatar--singleProvider.svg';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#d9e5ee',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatarContainer: {
    backgroundColor: '#4a657b',
    borderRadius: '50%',
    marginBottom: '15px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.23)',
  },
  avatar: {
    width: '28px',
    height: '28px',
    paddingBottom: '3px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#4a657b',
    fontSize: '14px',
    fontWeight: '700',
  },
});

export default function AudioPreview() {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container)}>
      <div className={classes.avatarContainer}>
        <PatientAvatar className={classes.avatar} />
      </div>
      <div className={classes.text}>Connecting...</div>
    </div>
  );
}
