const LoginErrors = {
  ACCOUNTS_CONNECTED_WITH_SAME_PHONE_NUMBER: 'accounts-connected-with-same-phone-number',
  MAGIC_LOGIN_FAILED: 'magic-login-failed',
  PASSWORD_MISSING: 'password-missing',
  UNKNOWN_ERROR: 'unknown-error',
  USER_NOT_FOUND: 'user-not-found',
  USERNAME_MISSING: 'username-missing',
  WRONG_CREDENTIALS: 'wrong-credentials',
};

export const LOGIN_ERROR_TO_MESSAGE = {
  [LoginErrors.ACCOUNTS_CONNECTED_WITH_SAME_PHONE_NUMBER]:
    'Sorry, that phone number is associated with multiple accounts. Please use an email address and try to log in again.',
  [LoginErrors.MAGIC_LOGIN_FAILED]: 'Magic Login Failed',
  [LoginErrors.PASSWORD_MISSING]: 'Invalid Password',
  [LoginErrors.UNKNOWN_ERROR]: 'Unknown Login Error',
  [LoginErrors.USER_NOT_FOUND]: 'Invalid Username',
  [LoginErrors.USERNAME_MISSING]: 'Invalid Username',
  [LoginErrors.WRONG_CREDENTIALS]: 'Invalid Password',
};

export default LoginErrors;
