import LoadingSpinner from '../LoadingSpinner';
import { useLaunchCall } from './useLaunchCall';
import { useLaunchPCM } from './useLaunchPCM';
import mobxInjectSelect from 'common/utils/mobxInjectSelect';
import type { Entity } from 'types';
import type { LaunchData } from 'stores/LaunchStore';

type MobxProps = {
  composeNewMessage: () => void;
  launchData: LaunchData;
  videoCallSetUp: (entity: Entity, origin: string) => unknown;
};

type LaunchServiceFlowProps = {};

function LaunchServiceFlow({
  composeNewMessage,
  launchData,
  videoCallSetUp,
}: LaunchServiceFlowProps & MobxProps) {
  const { loading: loadingCall } = useLaunchCall(launchData, videoCallSetUp);
  const { loading: loadingPCM } = useLaunchPCM(launchData, composeNewMessage);

  if (!launchData) return null;

  if (launchData.type === 'call' && loadingCall)
    return <LoadingSpinner loadingText={'Opening Call to Provider'} />;

  if (launchData.type === 'pcm' && loadingPCM)
    return <LoadingSpinner loadingText={'Opening Patient Reference Conversation'} />;

  return null;
}

export default mobxInjectSelect<LaunchServiceFlowProps, MobxProps>({
  callStore: ['videoCallSetUp'],
  composeMessageStore: ['composeNewMessage'],
  launchStore: ['launchData'],
})(LaunchServiceFlow);
