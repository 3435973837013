import React from 'react';
import { observer } from 'mobx-react';
import { Message } from '../../types';
import BEM from '../bem';

const classes = BEM.with('AlertReactionBang');

export type AlertReactionBangProps = {
  message: Message;
  precededByDateSeparator: boolean;
};

const AlertReactionBang = ({ message, precededByDateSeparator }: AlertReactionBangProps) => {
  const { alertDetails = {}, body } = message;
  const { isPrimaryAction = false } = alertDetails || {};

  return <div className={classes({ isPrimaryAction, precededByDateSeparator })}>{body}</div>;
};

export default observer(AlertReactionBang);
