import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../utils';
import { getGroupTag } from '../utils/getGroupTag';
import BEM from '../bem';
import propTypes from '../propTypes';
import AvatarImage from './AvatarImage';

const classes = BEM.with('GroupAvatar');

const SUPPORTED_GROUP_ENTITY_TYPES = {
  ESCALATION: 'escalation',
  FORUM: 'forum',
  PATIENT_CARE: 'patientCare',
  PATIENT_MESSAGING: 'patientMessaging',
  ACTIVATED_TEAM: 'activatedTeam',
};

const ROLES_TRANSITION_AVATAR_URL_SUBSTRING = 'roles_transition';

class GroupAvatar extends Component {
  static propTypes = {
    ariaLabel: PropTypes.string,
    avatarUrl: PropTypes.string,
    group: propTypes.group.isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  render() {
    const {
      ariaLabel,
      avatarUrl,
      displayName,
      group,
      isMuted = false,
      isRolesTransitionFeatureFlagEnabled,
      size,
    } = this.props;
    const { escalationExecution, memberCount } = group;
    const tag = getGroupTag(group);
    const isEscalated = !!escalationExecution;
    const isPatientCare = group.groupType === 'PATIENT_CARE';
    const isPatientMessaging = group.groupType === 'PATIENT_MESSAGING';
    const isActivatedTeam = group.groupType === 'ACTIVATED_TEAM';
    const isSingleProviderGroup = isPatientMessaging && memberCount <= 2;

    let groupType;
    if (isPatientMessaging) {
      groupType = isSingleProviderGroup ? 'singleProvider' : 'multipleProviders';

      if (group.patientDetails.smsOptedOut) {
        groupType += 'SmsOptedOut';
      }
    } else {
      groupType = SUPPORTED_GROUP_ENTITY_TYPES[group.groupType] || 'group';
    }
    const isRolesTransitionedGroup =
      isRolesTransitionFeatureFlagEnabled &&
      group?.avatarUrl?.includes(ROLES_TRANSITION_AVATAR_URL_SUBSTRING);

    const squareAvatar = isPatientMessaging && !isSingleProviderGroup;
    const forceDefault =
      (isActivatedTeam && tag) || isEscalated || isPatientCare || isPatientMessaging;

    return (
      <AvatarImage
        ariaLabel={ariaLabel}
        avatarUrl={avatarUrl || group.avatarUrl}
        basicImageClassModifier={isRolesTransitionedGroup ? 'roles-transition' : ''}
        className={classes()}
        displayName={displayName || group.displayName}
        entityType={groupType}
        forceDefault={forceDefault}
        isMuted={isMuted}
        isRolesTransitionFeatureFlagEnabled={isRolesTransitionFeatureFlagEnabled}
        size={size}
        squareAvatar={squareAvatar}
        tagColor={tag?.color}
      />
    );
  }
}

export default mobxInjectSelect({
  messengerStore: ['isRolesTransitionFeatureFlagEnabled'],
})(GroupAvatar);
