import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import propTypes from '../propTypes';
import BEM from '../../common/bem';
import { DotsIndicator, TypersNames } from './';

const classes = BEM.with('TypingStatusIndicator');

class TypingStatusIndicator extends Component {
  static propTypes = {
    color: PropTypes.string,
    maxTypers: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    typers: propTypes.userArray.isRequired,
  };

  static defaultProps = {
    color: '#db524b',
  };

  render() {
    const { color, maxTypers, size, typers } = this.props;
    let innerFragment;

    if (typers && typers.length > 0) {
      innerFragment = (
        <div className={classes('name-indicator')}>
          <TypersNames typers={typers} maxTypers={maxTypers} />
          <DotsIndicator className={classes('dots')} size={size} speed={0.5} color={color} />
        </div>
      );
    }

    return <div className={classes()}>{innerFragment}</div>;
  }
}

export default observer(TypingStatusIndicator);
