import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BEM from '../bem';
import { ReactComponent as SuccessCheckMark } from '../images/checkmark.svg';
import { Modal } from '.';

const classes = BEM.with('CreateSuccessModal');
export const CREATION_SUCCESS_TIMEOUT = 3000;

class CreateSuccessModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, options = {} } = this.props;
    const { content } = options;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        hasCloseButton={false}
        headerClass={classes('createSuccessHeader')}
        isOpen={isOpen}
        onAfterOpen={this._autoCloseModal}
        size={'variable'}
        role="alert"
      >
        <div className={classes('check-mark')}>
          <SuccessCheckMark aria-hidden />
        </div>
        <div className={classes('message-container')}>{content}</div>
      </Modal>
    );
  }

  _autoCloseModal = () => {
    const { closeModal, options = {} } = this.props;
    const { onClose } = options;
    setTimeout(() => {
      closeModal();
      if (onClose) {
        onClose();
      }
    }, CREATION_SUCCESS_TIMEOUT);
  };
}

export default CreateSuccessModal;
