import React, { useCallback, useEffect, useState } from 'react';

import TCClient from '../../../../../client';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { Table } from '../../../../shared-components/Table/Table';
import { AdminStateSlice, Device } from '../../../../types';
import { reduxInjectSelect } from '../../../../../common/utils/reduxInjectSelect';
import { ReduxState } from 'redux-stores';

type DevicesProps = {
  onClose: () => void;
};

const columns = [
  { field: 'deviceType', headerName: 'Type' },
  { field: 'id', headerName: 'Id' },
  { field: 'lastLoginTime', headerName: 'Last Login GMT' },
  { field: 'productVersion', headerName: 'Version' },
];

const Devices: React.FC<DevicesProps & ReduxProps> = ({
  onClose,
  selectedUser,
  selectedOrganization,
}) => {
  const [devices, setDevices] = useState<Device[]>([]);

  const getDevices = useCallback(async () => {
    const _devices = await TCClient.adminUsers.getDevices({
      orgId: selectedOrganization.token,
      userId: selectedUser.id,
    });
    setDevices(_devices);
  }, [selectedOrganization.token, selectedUser.id]);

  useEffect(() => {
    getDevices();
  }, [getDevices, selectedOrganization.token, selectedUser.id]);

  const onRowDelete = async ({ id: resource }: Device) => {
    const confirm = await window.confirm('Are you sure you want to permanently wipe this device?');
    if (confirm) {
      await TCClient.adminUsers.remoteWipe({
        orgId: selectedOrganization.token,
        userId: selectedUser.id,
        resource,
      });
      getDevices();
    }
  };

  return (
    <ViewContainer
      header={'Devices'}
      subHeader={'View a list of devices this user has used to log into TigerConnect.'}
      onClose={onClose}
    >
      <div>
        {devices.length > 0 && (
          <Table
            actionsOrder={[{ name: 'Delete', type: 'delete' }]}
            columns={columns}
            rows={devices}
            onRowDelete={async (row) => {
              await onRowDelete(row);
            }}
          />
        )}
      </div>
    </ViewContainer>
  );
};

type ReduxProps = {
  selectedUser: AdminStateSlice['selectedUser'];
  selectedOrganization: AdminStateSlice['selectedOrganization'];
};

export default reduxInjectSelect<DevicesProps, ReduxProps, ReduxState>({
  admin: ['selectedUser', 'selectedOrganization'],
})(Devices);
