import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

const PatientPill = ({ className }) => {
  return <span className={className}>Patient</span>;
};

PatientPill.propTypes = {
  className: PropTypes.string,
};

export default observer(PatientPill);
