import React, { useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import BasicModal from '../BasicModal';
import styles from './AddMemberToConversationModal.module.css';
import { Conversation, Entity } from 'types';
import mobxInjectSelect from 'common/utils/mobxInjectSelect';
import { actions, useAppDispatch } from 'redux-stores';
import { Checkbox } from 'admin/shared-components/Checkbox/Checkbox';

const { setModal } = actions;

type AddMemberToConversationModalProps = {
  isOpen: boolean;
  options: {
    membersToAdd: Entity[];
  };
};

type MobxProps = {
  currentConversation: Conversation;
  addMember: (groupId: string, memberId: string) => Promise<void>;
  selectRecipients: (recipients: Entity[]) => void;
  selectedRecipients: Entity[];
  isComposing: boolean;
};

const AddMemberToConversationModal = ({
  isOpen,
  options,
  currentConversation,
  addMember,
  selectRecipients,
  selectedRecipients,
  isComposing,
}: AddMemberToConversationModalProps & MobxProps) => {
  const { membersToAdd } = options;
  const dispatch = useAppDispatch();

  const [selectedMembersToAdd, setSelectedMembersToAdd] = useState<Entity[]>([]);

  const onSelectMember = (member: Entity) => {
    setSelectedMembersToAdd((prevMembers) => {
      if (prevMembers.some((item) => item.id === member.id)) {
        return prevMembers.filter((prevMember) => prevMember.id !== member.id);
      } else {
        return [...prevMembers, member];
      }
    });
  };

  const closeModal = () => {
    setSelectedMembersToAdd([]);
    dispatch(setModal(undefined));
  };

  const onSubmitFromComposing = () => {
    const selectedMembers = membersToAdd.length > 1 ? selectedMembersToAdd : membersToAdd;
    const filteredMembers = uniqBy([...selectedMembers, ...selectedRecipients], 'id');

    selectRecipients(filteredMembers);
    closeModal();
  };

  const selectedMembers = useMemo(
    () => (membersToAdd.length > 1 ? selectedMembersToAdd : membersToAdd),
    [membersToAdd, selectedMembersToAdd]
  );

  const onSubmit = async () => {
    if (isComposing) {
      onSubmitFromComposing();
      return;
    }

    if (!selectedMembers.length) return;

    await Promise.all(
      selectedMembers.map(
        async (selectedMember) =>
          await addMember(currentConversation.counterParty.id, selectedMember.id)
      )
    );

    closeModal();
  };

  return (
    <BasicModal
      ariaLabelCancelButton="Add Member Cancel"
      ariaLabelCloseButton="Add Member Modal Close"
      ariaLabelHeader="Add Member Header"
      ariaLabelSubmitButton="Add Member Submitted"
      dangerButtonTheme="danger"
      hasCloseButton={false}
      headerText="Add member"
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={onSubmit}
      submitDisabled={selectedMembers?.length === 0}
      size="medium"
      cancelText="SKIP"
      submitText="ADD"
      theme="normal"
      useWCL
    >
      {membersToAdd.length > 1 ? (
        <div className={styles.contentContainer}>
          <p>These people are not in this conversation.</p>
          <p>Select who you would like to add.</p>
          <div className={styles.membersWrapper}>
            <ul className={styles.membersToAddContainer}>
              {membersToAdd.map((memberToAdd) => (
                <li className={styles.membersToAddItem} key={memberToAdd.id}>
                  <Checkbox
                    label={memberToAdd.displayName}
                    checked={selectedMembersToAdd.some(
                      (selectedMember) => selectedMember.id === memberToAdd.id
                    )}
                    onClick={() => onSelectMember(memberToAdd)}
                    isLabelClickable
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <p>
            <span className={styles.memberToAddDisplayName}>{membersToAdd?.[0]?.displayName} </span>
            is not in this conversation. Would you like to add them?
          </p>
        </div>
      )}
    </BasicModal>
  );
};

export default mobxInjectSelect<AddMemberToConversationModalProps, MobxProps>({
  conversationStore: ['currentConversation'],
  groupStore: ['addMember'],
  composeMessageStore: ['selectRecipients', 'selectedRecipients', 'isComposing'],
})(AddMemberToConversationModal);
