import React from 'react';

import BEM from '../../bem';

import { useVWRContext } from '../../../contexts/VirtualWaitingRoom';
import { ActionStatuses } from '../../../types';
import Room from './Room';
import EmptyRoom from './EmptyRoom';
import SideBar from './SideBar';

const classes = BEM.with('VirtualWaitingRoomPage');

export default function VirtualWaitingRoom() {
  const {
    state: {
      loadedStatus,
      visitors: { selectedVisitor },
      userHasRoomsAvailable,
    },
  } = useVWRContext();

  return (
    <div className={classes()}>
      <div className={classes()}>
        <SideBar />
        {loadedStatus === ActionStatuses.SUCCESS &&
        userHasRoomsAvailable &&
        selectedVisitor?.id !== undefined ? (
          <Room />
        ) : loadedStatus === ActionStatuses.SUCCESS && !userHasRoomsAvailable ? (
          <EmptyRoom />
        ) : (
          <div className={classes('empty-room')}></div>
        )}
      </div>
    </div>
  );
}
