import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';

const classes = BEM.with('PatientCareCard');

class PatientCareAction extends Component {
  static propTypes = {
    closeInfoPane: PropTypes.func.isRequired,
    component: PropTypes.shape({
      action_type: PropTypes.string.isRequired,
      bg_color: PropTypes.string,
      value: PropTypes.string.isRequired,
      value_color: PropTypes.string,
    }).isRequired,
    openModal: PropTypes.func.isRequired,
  };

  render() {
    const { action_type: actionType, value } = this.props.component;
    let { bg_color: backgroundColor, value_color: color } = this.props.component;

    if (backgroundColor) backgroundColor = backgroundColor.replace('0x', '#');
    if (color) color = color.replace('0x', '#');

    return (
      <div className={classes('action-container')}>
        <button
          className={classes('action-button')}
          onClick={this._onActionClick(actionType)}
          style={{ backgroundColor, color }}
        >
          {value}
        </button>
      </div>
    );
  }

  _onActionClick = (actionType) => {
    return () => {
      if (actionType === 'ADD_MEMBERS') this._addMembers();
    };
  };

  _addMembers = () => {
    const { closeInfoPane, openModal } = this.props;
    closeInfoPane();
    openModal('addCareTeamModal');
  };
}

export default mobxInjectSelect({
  messengerStore: ['closeInfoPane'],
  modalStore: ['openModal'],
})(PatientCareAction);
