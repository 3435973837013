import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SignInStep } from '../../../models/enums';
import BEM from '../../bem';
import { ReactComponent as ArrowLeftSvg } from '../../images/arrow-left.svg';
import { mobxInjectSelect } from '../../utils';

const classes = BEM.with('SignInForm');

class PasswordTitle extends Component {
  static propTypes = {
    isStaticRender: PropTypes.bool,
    passwordFormClassName: PropTypes.string,
    setSignInStep: PropTypes.func.isRequired,
    signInUsername: PropTypes.string,
  };

  render() {
    const { isStaticRender, passwordFormClassName, signInUsername } = this.props;
    const className = (isStaticRender && passwordFormClassName) || classes('password-title');
    const usernameClasses = [classes('username')];
    if (!isStaticRender) usernameClasses.push(classes('username--clickable'));

    return (
      <div id="tc-SignInForm__PasswordTitle" className={className}>
        <span className={classes('title-text')} data-test-id={'password-title-text'}>
          Enter your Password for
        </span>
        <div
          className={classNames(usernameClasses)}
          onClick={this._goBackToUsername}
          aria-label="back to username"
        >
          {!isStaticRender && (
            <button className={classes('back-arrow')}>
              <ArrowLeftSvg aria-hidden />
            </button>
          )}
          <div
            id="tc-SignInForm__PasswordTitle__username"
            className={classes('username-text')}
            data-test-id={'username-text'}
          >
            {signInUsername}
          </div>
        </div>
      </div>
    );
  }

  _goBackToUsername = () => {
    const { setSignInStep } = this.props;
    setSignInStep(SignInStep.USERNAME);
  };
}

export default mobxInjectSelect({
  messengerStore: ['isStaticRender'],
  sessionStore: ['setSignInStep', 'signInUsername'],
  staticStore: ['passwordFormClassName'],
})(PasswordTitle);
