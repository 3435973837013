export default class NotFoundError extends Error {
  code: string;
  resource: string;
  id: string;
  static CODE = 'not-found';

  constructor(resource: string, id: string, message?: string) {
    if (!message) {
      message = `${resource} '${id}' not found`;
    }
    super(message);
    this.code = NotFoundError.CODE;
    this.resource = resource;
    this.id = id;
  }
}
