import React from 'react';
import { Avatar } from '@tigerconnect/web-component-library';

import BEM from '../../../bem';
import { CollaborationCategories } from 'models/enums';

const { ALL, MYROLES, MYTEAMS, NOTTAGGED } = CollaborationCategories;
const classes = BEM.with('CollaborationTagItem');

type TagItemProps = {
  colorHex?: string;
  customStyles?: string;
  dotColor?: boolean;
  hasBorder?: boolean;
  isOverflow?: boolean;
  isAdmin?: boolean;
  name: string;
  noColor?: boolean;
  noIcon?: boolean;
  useType?: 'action' | 'adminAction' | 'display';
};

export default function TagItem({
  colorHex = undefined,
  customStyles,
  dotColor = false,
  hasBorder = false,
  isOverflow = false,
  isAdmin = false,
  name,
  noColor = false,
  useType = 'adminAction',
}: TagItemProps) {
  const isClickable = useType === 'action' || (isAdmin && useType === 'adminAction');
  const styleOptions: { [k: string]: boolean } = { isClickable };

  if (customStyles) {
    styleOptions[customStyles] = true;
  }

  const nameToType = () => {
    switch (name) {
      case MYROLES:
        return 'myroles';
      case MYTEAMS:
        return 'team';
      case ALL:
      case NOTTAGGED:
      default:
        return 'tag';
    }
  };

  const showEmptyIcon = hasBorder || name === NOTTAGGED;

  return (
    <div className={classes('', styleOptions)} data-test-id={`Tag color ${name}`}>
      <div className={classes('tag-item', styleOptions)}>
        {!noColor && (
          <>
            {dotColor ? (
              <div
                className={classes('dot-color')}
                style={{
                  backgroundColor: colorHex,
                }}
              />
            ) : (
              <div className={classes('tag-icon')}>
                <Avatar
                  type={nameToType()}
                  bgColor={showEmptyIcon ? '#fff' : colorHex || undefined}
                  size="small"
                  theme={showEmptyIcon ? 'empty' : undefined}
                />
              </div>
            )}
          </>
        )}
        <span
          className={classes('tag-name', {
            overflow: isOverflow,
          })}
        >
          {name}
        </span>
      </div>
    </div>
  );
}
