import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '../WebComponents';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import mobxInjectSelect from '../../utils/mobxInjectSelect';

const classes = BEM.with('AutoForwardItem');

class AutoForwardItem extends Component {
  static propTypes = {
    autoForwardEntitiesDisplayName: PropTypes.string,
    openModal: PropTypes.func.isRequired,
    organization: propTypes.organization.isRequired,
    removeAutoForward: PropTypes.func.isRequired,
  };

  render() {
    const { autoForwardEntitiesDisplayName, organization } = this.props;
    const { autoForwardReceiver, dndAutoForwardEntities } = organization;
    const isExtendedAutoForwardOptionsEnabled = dndAutoForwardEntities;

    const showAutoForward = isExtendedAutoForwardOptionsEnabled
      ? !!autoForwardEntitiesDisplayName
      : !!autoForwardReceiver;
    const autoForwardDisplayName = isExtendedAutoForwardOptionsEnabled
      ? autoForwardEntitiesDisplayName
      : autoForwardReceiver?.displayName;

    return (
      <div className={classes()}>
        <div className={classes('switch-button')}>
          <Switch
            checked={showAutoForward}
            onSwitch={this._onAutoForwardButtonClick}
            dataTestId="autoForwardItem"
          />
        </div>
        <div className={classes('organization-name')}>{organization.displayName}</div>
        {showAutoForward && (
          <div className={classes('user-name')} onClick={this._editForwardUser}>
            {autoForwardDisplayName}
          </div>
        )}
      </div>
    );
  }

  _onAutoForwardButtonClick = async () => {
    const { autoForwardEntitiesDisplayName, openModal, organization, removeAutoForward } =
      this.props;
    const { autoForwardReceiverId, dndAutoForwardEntities } = organization;
    const isExtendedAutoForwardOptionsEnabled = dndAutoForwardEntities;

    const shouldOpenAutoForwardModal = isExtendedAutoForwardOptionsEnabled
      ? !autoForwardEntitiesDisplayName
      : !autoForwardReceiverId;

    if (shouldOpenAutoForwardModal) {
      openModal('autoForwardModal', { organization });
    } else {
      await removeAutoForward(organization);
    }
  };

  _editForwardUser = () => {
    const { openModal, organization } = this.props;

    openModal('autoForwardModal', { organization });
  };
}

export default mobxInjectSelect({
  modalStore: ['openModal'],
  userStore: ['removeAutoForward'],
})(AutoForwardItem);
