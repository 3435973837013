import React from 'react';
import { omit } from 'lodash';
import { Table } from '../../../../shared-components/Table/Table';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

export default function AuthSubstitutionsTable() {
  const dispatch = useAppDispatch();
  const substitutions = useAppSelector((state) => state.orgSettings.settings.authSubstitutions);

  if (!substitutions) return <></>;

  return (
    <Table
      actionsOrder={[{ name: 'Delete', type: 'delete' }]}
      columns={[
        { field: 'key', headerName: 'Text to look for:' },
        { field: 'value', headerName: 'Text to replace:' },
      ]}
      rows={Object.entries(substitutions).map(([key, value]) => ({
        id: key,
        key,
        value,
      }))}
      onRowDelete={({ key }) => {
        dispatch(
          actions.updateSettings({
            authSubstitutions: omit(substitutions, key),
          })
        );
      }}
    />
  );
}
