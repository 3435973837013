import withInput from './withInput';

const CheckBoxInput = withInput({
  classTypeModifier: 'checkbox-input',
  defaultValue: false,
  forceType: 'checkbox',
  valueAttribute: 'checked',
});

export default CheckBoxInput;
