import React from 'react';

import { StandardOrgSettingInput } from '../../../../shared-components/StandardSettingInput';

import styles from '../../OrganizationSettings.module.css';
import AuthSubstitutionsTable from './AuthSubstitutionsTable';
import AuthSubstituionsForm from './AuthSubstitutionsForm';
import LoginWorkflowComponent from './LoginWorkflowComponent';

export const HttpsComponent = () => {
  return (
    <div>
      <LoginWorkflowComponent />
      <div className={styles.flexListStyleWithPadding}>
        <div className={styles.mainLabelStyle}>Add Prefix</div>
        <div className={styles.inputBoxStyle}>
          <StandardOrgSettingInput settingName="authPrefix" type="input" />
        </div>
      </div>
      <div className={styles.flexListStyleWithPadding}>
        <div className={styles.mainLabelStyle}>Add Suffix </div>
        <div className={styles.inputBoxStyle}>
          <StandardOrgSettingInput settingName="authSuffix" type="input" />
        </div>
      </div>
      <div className={styles.flexListStyleWithPadding}>
        <div className={styles.mainLabelStyle}>HTTP Auth URL</div>
        <div className={styles.inputBoxStyle}>
          <StandardOrgSettingInput settingName="authUrl" type="input" />
        </div>
      </div>
      <div className={styles.flexListStyleWithPadding}>
        <div className={styles.mainLabelStyle}>Accepted HTTP Status Codes </div>
        <div className={styles.inputBoxStyle}>
          <StandardOrgSettingInput settingName="authAcceptedStatusCodes" type="input" />
        </div>
      </div>
      <div className={styles.flexListStyleWithPadding}>
        <div className={styles.mainLabelStyle}>Allow Login with Username</div>
        <StandardOrgSettingInput
          settingName="authUsernameLogin"
          type="radio"
          options={[
            { label: 'True', value: true },
            { label: 'False', value: false },
          ]}
        />
      </div>
      <div className={styles.flexListStyleWithPadding}>
        <div className={styles.mainLabelStyle}>Force 3rd Party Certificate Verification</div>
        <StandardOrgSettingInput
          settingName="authCertVerify"
          type="radio"
          options={[
            { label: 'True', value: true },
            { label: 'False', value: false },
          ]}
        />
      </div>
      <AuthSubstituionsForm />
      <AuthSubstitutionsTable />
    </div>
  );
};
