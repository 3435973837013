import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../../bem';
import mobxInjectSelect from '../../../../utils/mobxInjectSelect';
import DotsIndicator from '../../../DotsIndicator';
import { Modal } from '../../..';

const classes = BEM.with('ContactDeleteModal');

class ContactDeleteModal extends Component {
  static propTypes = {
    actionInProgress: PropTypes.bool.isRequired,
    deleteContact: PropTypes.func.isRequired,
    hidePatientDeleteModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
  };

  render() {
    const { actionInProgress, isOpen, options } = this.props;
    const { contact } = options;
    const { displayName } = contact.user;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('closeButton')}
        headerClass={classes('deleteHeader')}
        header="Are you sure you want to delete this patient's contact?"
        isOpen={isOpen}
        onRequestClose={this._onRequestClose}
        size={'variable'}
        footerClass={classes('forwardFooter')}
        footerPrimaryActions={
          <button
            className={classes('ok-btn', { progress: actionInProgress })}
            onClick={this._onDeleteContact}
            type="button"
          >
            {actionInProgress ? (
              <div className={classes('dots-container')}>
                <DotsIndicator
                  className={classes('dots')}
                  size={10}
                  speed={0.5}
                  color={'#db524b'}
                />
              </div>
            ) : (
              'OK'
            )}
          </button>
        }
        footerSecondaryActions={
          <button
            className={classes('cancel-btn', { progress: actionInProgress })}
            type="button"
            onClick={this._onRequestClose}
          >
            CANCEL
          </button>
        }
      >
        <div className={classes('message-container')}>
          <div className={classes('message')}>
            {"Deleting an active patient's contact like"}
            <span className={classes('patient-name')}> {`${displayName}`} </span>
            would erase any conversations with them.
          </div>
        </div>
      </Modal>
    );
  }

  _onRequestClose = () => {
    const { hidePatientDeleteModal } = this.props;

    hidePatientDeleteModal();
  };

  _onDeleteContact = () => {
    const { deleteContact, options } = this.props;
    const { contact, index } = options;
    const { patient } = contact;

    deleteContact(contact.userId, patient.userId, index);
  };
}

export default mobxInjectSelect({
  patientStore: ['actionInProgress', 'deleteContact', 'hidePatientDeleteModal'],
})(ContactDeleteModal);
