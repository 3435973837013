import createMobxModel from './createMobxModel';

const Conversation = createMobxModel({
  name: 'conversation',

  fields: [
    'allowedSendersReason',
    'entitiesEnsured',
    'featureService',
    'higherContinuation',
    'inProgressEscalationCount',
    'isDeletable',
    'isLive',
    'isMuteable',
    'isMuted',
    'lowerContinuation',
    'muted',
    'network',
    'shouldDisplay',
    'unreadCount',
    'unreadIds',
    'unreadMentionMessages',
    'unreadPriorityCount',
  ],

  relations: {
    one: {
      counterParty: { type: ['group', 'user'] },
      firstUnreadMessage: { type: 'message' },
      lastMessage: { type: 'message' },
      organization: { type: 'organization' },
    },
    many: {
      allowedSenders: { type: ['user', 'role'] },
      markableAsReadMessages: { type: 'message' },
      timeline: { type: 'message' },
    },
  },
});

export default Conversation;
