import React from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import { ReactComponent as DropDownSvg } from '../../../images/pa-dropdown.svg';
import { ScheduledMessagesRow } from './';
import { AccessibleList } from 'common/components';
import { useAppSelector } from 'redux-stores';

const classes = BEM.with('ScheduledMessagesTable');

const ScheduledMessagesTable = ({
  category,
  feed,
  isLoading,
  queryValue,
  reloadSearch,
  results,
  scrollContainerRef,
  selected,
  sortBy,
  sortOrder,
  toggleSelected,
  toggleSort,
}) => {
  const isQueue = category === 'queue';
  const isHistory = category === 'history';
  const accessibilityMode = useAppSelector((state) => state.ui.accessibilityMode);
  let resultsFragment;

  const ariaSortString = (colName) => {
    return sortBy === colName ? (sortOrder === 'desc' ? 'descending' : 'ascending') : 'none';
  };

  if (results.length === 0) {
    if (isLoading) {
      resultsFragment = <div />;
    } else if (queryValue) {
      resultsFragment = (
        <div className={classes('empty')}>No results found for &quot;{queryValue}&quot;</div>
      );
    } else {
      resultsFragment = (
        <div className={classes('empty')}>
          No Schedule Messages available, click &quot;Create Schedule Message&quot; to get started
        </div>
      );
    }
  } else {
    resultsFragment = results.map((message, index) => (
      <ScheduledMessagesRow
        index={index}
        category={category}
        feed={feed}
        isSelected={selected.includes(message.id)}
        key={message.id}
        message={message}
        recipient={message.recipient}
        reloadSearch={reloadSearch}
        sender={message.sender}
        toggleSelected={toggleSelected}
        updatedBy={message.updatedBy}
      />
    ));
  }

  return (
    <div
      role="tabpanel"
      className={classes()}
      aria-labelledby={
        isQueue ? 'scheduled-messages__queue-button' : 'scheduled-messages__history-button'
      }
    >
      <div
        className={classes()}
        role="table"
        aria-describedby={
          isQueue
            ? 'scheduled-messages-table__queue-list'
            : 'scheduled-messages-table__history-list'
        }
      >
        <div className={classes('list')}>
          <div
            className={classes('list-header', {
              noCheckbox: category !== 'queue',
            })}
            role="rowgroup"
          >
            {isQueue && <div className={classes('list-item', { checkbox: true })} />}
            <button
              className={classes('list-item')}
              onClick={() => toggleSort('schedule_status')}
              aria-sort={ariaSortString('schedule_status')}
              role="columnheader"
            >
              Status
              <DropDownSvg
                className={classes('sort-arrow', {
                  isDesc: sortOrder === 'desc',
                  sortBy: sortBy === 'schedule_status',
                })}
                aria-hidden
              />
            </button>
            {feed !== 'personal' && (
              <button
                className={classes('list-item', { three: true })}
                onClick={() => toggleSort('sender_display_name')}
                aria-sort={ariaSortString('sender_display_name')}
                role="columnheader"
              >
                Sender
                <DropDownSvg
                  className={classes('sort-arrow', {
                    isDesc: sortOrder === 'desc',
                    sortBy: sortBy === 'sender_display_name',
                  })}
                  aria-hidden
                />
              </button>
            )}
            <button
              className={classes('list-item', { four: true })}
              onClick={() => toggleSort('recipient_display_name')}
              aria-sort={ariaSortString('recipient_display_name')}
              role="columnheader"
            >
              Recipient
              <DropDownSvg
                className={classes('sort-arrow', {
                  isDesc: sortOrder === 'desc',
                  sortBy: sortBy === 'recipient_display_name',
                })}
                aria-hidden
              />
            </button>
            <button
              className={classes('list-item', { four: true })}
              onClick={() => toggleSort('template_label')}
              aria-sort={ariaSortString('template_label')}
              role="columnheader"
            >
              Template Label
              <DropDownSvg
                className={classes('sort-arrow', {
                  isDesc: sortOrder === 'desc',
                  sortBy: sortBy === 'template_label',
                })}
                aria-hidden
              />
            </button>
            <button
              className={classes('list-item')}
              onClick={() => toggleSort('scheduled_time')}
              aria-sort={ariaSortString('scheduled_time')}
              role="columnheader"
            >
              Scheduled
              <DropDownSvg
                className={classes('sort-arrow', {
                  isDesc: sortOrder === 'desc',
                  sortBy: sortBy === 'scheduled_time',
                })}
                aria-hidden
              />
            </button>
            {isHistory && (
              <button
                className={classes('list-item')}
                onClick={() => toggleSort('created_on')}
                aria-sort={ariaSortString('created_on')}
                role="columnheader"
              >
                Created On
                <DropDownSvg
                  className={classes('sort-arrow', {
                    isDesc: sortOrder === 'desc',
                    sortBy: sortBy === 'created_on',
                  })}
                  aria-hidden
                />
              </button>
            )}
            <button
              className={classes('list-item')}
              onClick={() => toggleSort('updated_on')}
              aria-sort={ariaSortString('updated_on')}
              role="columnheader"
            >
              Edited
              <DropDownSvg
                className={classes('sort-arrow', {
                  isDesc: sortOrder === 'desc',
                  sortBy: sortBy === 'updated_on',
                })}
                aria-hidden
              />
            </button>
          </div>
          <div
            className={classes('list-body')}
            ref={scrollContainerRef}
            id={
              isQueue
                ? 'scheduled-messages-table__queue-list'
                : 'scheduled-messages-table__history-list'
            }
          >
            <AccessibleList
              focusableClasses={'.tc-ScheduledMessagesRow'}
              accessibilityMode={accessibilityMode}
              focusableChildrenClasses={'.tc-ScheduledMessagesRow__list-item-checkbox-default'}
              setStartElementOnChange={true}
              role="rowgroup"
            >
              {resultsFragment}
            </AccessibleList>
          </div>
        </div>
      </div>
    </div>
  );
};

ScheduledMessagesTable.propTypes = {
  category: PropTypes.string.isRequired,
  feed: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  queryValue: PropTypes.string.isRequired,
  results: PropTypes.array.isRequired,
  scrollContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  selected: PropTypes.array.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  toggleSort: PropTypes.func.isRequired,
};

export default ScheduledMessagesTable;
