import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import { ScheduleOption } from '../../../models/enums';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { mobxInjectSelect, isToday, scheduledMessageFormats } from '../../utils';
import { ReactComponent as CloseButtonSvg } from '../../images/close-icon.svg';
import { ReactComponent as DropdownChevronSvg } from '../../images/dropdown-chevron.svg';
import { ContextMenu } from '../ContextMenu';
import { RepeatScheduleContextMenu, TimeInputSelect, TimezoneContextMenu } from './';
import { KEYMAP } from 'common/constants';

const { DATE_FORMAT_WITH_DAY, MAX_DAYS } = scheduledMessageFormats;

const classes = BEM.with('ScheduleInputs');

class ScheduleInputs extends Component {
  static propTypes = {
    bulkUpdateScheduledMessage: PropTypes.func.isRequired,
    isIhis: PropTypes.bool.isRequired,
    scheduledMessage: propTypes.scheduledMessageState.isRequired,
    updateCoarseTimezone: PropTypes.func.isRequired,
    updateScheduledMessage: PropTypes.func.isRequired,
    setNextButtonDisabled: PropTypes.func,
    accessibilityMode: PropTypes.bool,
  };

  state = {
    calendarDateRef: null,
    endDatePickerFocused: false,
    repeatMenuRef: null,
    startDatePickerFocused: false,
    timezoneMenuButtonRef: null,
    selectDateTimeButtonRef: null,
  };

  componentDidUpdate() {
    this._updateAccessibilityMode(!!this.props.accessibilityMode);
  }

  componentWillUnmount() {
    if (this.props.accessibilityMode) {
      this._updateAccessibilityMode(false);
    }
  }

  _updateAccessibilityMode(enable) {
    const calendarIconButtons = document.querySelectorAll(
      'button[class*="SingleDatePickerInput_calendarIcon"]'
    );
    const startCalendarInput = document.querySelector('input[id*="start-date-picker"]');
    const endCalendarInput = document.querySelector('input[id*="end-date-picker"]');

    calendarIconButtons.forEach((button) => {
      if (button) button.tabIndex = enable ? -1 : 0;
    });

    if (startCalendarInput) {
      if (enable) {
        startCalendarInput.addEventListener('keydown', this._handleStartDateKeyDown);
      } else {
        startCalendarInput.removeEventListener('keydown', this._handleStartDateKeyDown);
      }
    }

    if (endCalendarInput) {
      if (enable) {
        endCalendarInput.addEventListener('keydown', this._handleEndDateKeyDown);
      } else {
        endCalendarInput.removeEventListener('keydown', this._handleEndDateKeyDown);
      }
    }
  }

  render() {
    const {
      endDatePickerFocused,
      repeatMenuRef,
      startDatePickerFocused,
      timezoneMenuButtonRef,
      calendarDateRef,
    } = this.state;
    const { isEditing, isIhis, scheduledMessage, updateScheduledMessage, accessibilityMode } =
      this.props;
    const {
      repeatEnabled,
      repeatSchedule,
      scheduleOption,
      selectedEndDate,
      selectedStartDate,
      selectedTime,
      timezone,
    } = scheduledMessage;

    const executeAutoScroll = () => {
      calendarDateRef.scrollIntoView();
      if (!startDatePickerFocused) {
        timezoneMenuButtonRef.scrollIntoView();
      }
    };

    let scheduleFragment;
    switch (scheduleOption) {
      case ScheduleOption.NOT_SELECTED:
        scheduleFragment = (
          <React.Fragment>
            <button
              className={classes('schedule-option-btn')}
              data-test-id={'select-date-time-button'}
              ref={this._setSelectDateTimeButton}
              onClick={() => {
                this._setScheduleOption(ScheduleOption.SET_DATE_TIME);
                if (accessibilityMode) {
                  setTimeout(() => {
                    const repeatSwitch = document.querySelector('[role="switch"]');
                    if (repeatSwitch) repeatSwitch.focus();
                  }, 0);
                }
              }}
            >
              Select Date / Time
            </button>
            <p className={classes('or-text')}>Or</p>
            <button
              className={classes('schedule-option-btn')}
              data-test-id={'send-now-button'}
              onClick={() => {
                this._setScheduleOption(ScheduleOption.SEND_NOW);
                setTimeout(() => {
                  if (this.props.accessibilityMode) {
                    const clearButton = document.querySelector(
                      '.tc-ScheduleInputs__reset-schedule-btn'
                    );
                    clearButton?.focus();
                  }
                }, 0);
              }}
            >
              Send Now
            </button>
          </React.Fragment>
        );
        break;
      case ScheduleOption.SEND_NOW:
        scheduleFragment = (
          <React.Fragment>
            <p className={classes('send-now-text')}>Send Now</p>
            <button
              className={classes('reset-schedule-btn')}
              data-test-id={'cancel-send-now-button'}
              onClick={() => {
                this._resetScheduleOption();
                setTimeout(() => {
                  if (accessibilityMode && this.state.selectDateTimeButtonRef) {
                    this.state.selectDateTimeButtonRef.focus();
                  }
                }, 0);
              }}
              aria-label="Close"
            >
              <CloseButtonSvg aria-hidden />
            </button>
          </React.Fragment>
        );
        break;
      case ScheduleOption.SET_DATE_TIME:
        scheduleFragment = (
          <div className={classes('schedule-row')} ref={this._setCalendarDateRef}>
            <SingleDatePicker
              customInputIcon={
                <DropdownChevronSvg
                  className={classes('chevron-icon', { active: startDatePickerFocused })}
                  onClick={(event) => {
                    executeAutoScroll();
                    if (startDatePickerFocused) {
                      event.stopPropagation();
                      this.setState({ startDatePickerFocused: false });
                    }
                  }}
                />
              }
              date={selectedStartDate}
              displayFormat={DATE_FORMAT_WITH_DAY}
              focused={startDatePickerFocused}
              hideKeyboardShortcutsPanel
              id="start-date-picker"
              inputIconPosition="after"
              isOutsideRange={this._isOutOfStartDateSelectorRange}
              noBorder
              numberOfMonths={1}
              onDateChange={(date) => {
                this._setSelectedStartDate(date);
                if (accessibilityMode) {
                  const calendarInput = document.querySelector('input[id*="start-date-picker"]');
                  if (calendarInput) calendarInput.focus();
                }
              }}
              onFocusChange={({ focused: startDatePickerFocused }) => {
                this.setState({ startDatePickerFocused });
              }}
              placeholder="Select Date"
              readOnly
              verticalSpacing={0}
            />
            <TimeInputSelect
              selectedStartDate={selectedStartDate}
              selectedTime={selectedTime}
              setNextButtonDisabled={this._updateActiveNextButton}
              setSelectedTime={this._setSelectedTime}
              timezone={timezone}
              accessibilityMode={accessibilityMode}
            />
            <ContextMenu
              event="click"
              offsetY={6}
              position="bottominnerleft"
              relativeTo={timezoneMenuButtonRef}
              theme="vertical"
              accessibilityMode={accessibilityMode}
              menu={
                <TimezoneContextMenu
                  onTimezoneSelected={(timezone) => {
                    this._setTimezone(timezone);
                    if (accessibilityMode && this.state.timezoneMenuButtonRef) {
                      this.state.timezoneMenuButtonRef?.focus();
                    }
                  }}
                  buttonRef={this.state.timezoneMenuButtonRef}
                  selectedTime={selectedTime || selectedStartDate}
                  selectedTimezone={timezone}
                  accessibilityMode={accessibilityMode}
                />
              }
            >
              <button
                className={classes('timezone-menu-btn', {
                  placeholder: !timezone,
                })}
                disabled={isIhis}
                data-test-id={'timezone-menu-button'}
                ref={this._setTimezoneMenuButtonRef}
              >
                {timezone &&
                  moment(selectedTime || selectedStartDate || {})
                    .tz(timezone)
                    .zoneAbbr()}
                <DropdownChevronSvg aria-hidden />
              </button>
            </ContextMenu>
            {!isEditing && (
              <button
                className={classes('reset-schedule-btn')}
                data-test-id={'cancel-set-date-time-button'}
                onClick={() => {
                  this._resetScheduleOption();
                  setTimeout(() => {
                    if (accessibilityMode && this.state.selectDateTimeButtonRef) {
                      this.state.selectDateTimeButtonRef.focus();
                    }
                  }, 0);
                }}
                aria-label="Close"
              >
                <CloseButtonSvg aria-hidden />
              </button>
            )}
          </div>
        );
        break;
      default:
    }

    return (
      <div className={classes()}>
        {scheduleFragment}
        {repeatEnabled && (
          <div className={classes('schedule-row')}>
            <ContextMenu
              event="click"
              offsetY={6}
              position="bottominnerleft"
              relativeTo={repeatMenuRef}
              theme="vertical"
              accessibilityMode={accessibilityMode}
              menu={
                <RepeatScheduleContextMenu
                  repeatSchedule={repeatSchedule}
                  onRepeatScheduleSelected={(schedule) => {
                    this._setRepeatSchedule(schedule);
                    if (accessibilityMode && this.state.repeatMenuRef) {
                      this.state.repeatMenuRef.focus();
                    }
                  }}
                  accessibilityMode={accessibilityMode}
                  buttonRef={this.state.repeatMenuRef}
                />
              }
            >
              <button
                className={classes('repeat-menu-btn', {
                  placeholder: !repeatSchedule,
                })}
                data-test-id={'select-repeat-schedule-button'}
                ref={this._setRepeatMenuButtonRef}
              >
                {repeatSchedule}
                <DropdownChevronSvg />
              </button>
            </ContextMenu>
            <span className={classes('until-text')}>Until</span>
            <SingleDatePicker
              customInputIcon={
                <DropdownChevronSvg
                  className={classes('chevron-icon', { active: endDatePickerFocused })}
                  onClick={(event) => {
                    if (endDatePickerFocused) {
                      event.stopPropagation();
                      this.setState({ endDatePickerFocused: false });
                    }
                  }}
                />
              }
              date={selectedEndDate}
              displayFormat={DATE_FORMAT_WITH_DAY}
              focused={endDatePickerFocused}
              hideKeyboardShortcutsPanel
              id="end-date-picker"
              initialVisibleMonth={() => this._calculateStartDateLimit(repeatSchedule)}
              inputIconPosition="after"
              isOutsideRange={(date) =>
                !!(selectedStartDate && date <= selectedStartDate) ||
                this._isOutOfEndDateSelectorRange(date)
              }
              noBorder
              numberOfMonths={1}
              onDateChange={(selectedEndDate) => {
                this._updateActiveNextButton();
                updateScheduledMessage('selectedEndDate', selectedEndDate);
                if (accessibilityMode) {
                  const calendarInput = document.querySelector('input[id*="end-date-picker"]');
                  if (calendarInput) calendarInput.focus();
                }
              }}
              onFocusChange={({ focused: endDatePickerFocused }) =>
                this.setState({ endDatePickerFocused })
              }
              placeholder="Select Date"
              readOnly
              verticalSpacing={0}
            />
          </div>
        )}
      </div>
    );
  }

  _handleStartDateKeyDown = (e) => {
    if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
      this.setState((prevState) => ({
        startDatePickerFocused: !prevState.startDatePickerFocused,
      }));
    }
    if (e.key === KEYMAP.ESCAPE) {
      this.setState({ startDatePickerFocused: false });
    }
  };

  _handleEndDateKeyDown = (e) => {
    if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
      this.setState((prevState) => ({
        endDatePickerFocused: !prevState.endDatePickerFocused,
      }));
    }
    if (e.key === KEYMAP.ESCAPE) {
      this.setState({ endDatePickerFocused: false });
    }
  };

  _isOutOfStartDateSelectorRange = (day) => {
    const startDateLimit = moment().startOf('day');
    const endDateLimit = moment().add(MAX_DAYS, 'days').endOf('day');

    return day.isBefore(startDateLimit) || day.isAfter(endDateLimit);
  };

  _isOutOfEndDateSelectorRange = (day) => {
    const { scheduledMessage } = this.props;
    const { repeatSchedule } = scheduledMessage;
    const startDateLimit = this._calculateStartDateLimit(repeatSchedule);
    const endDateLimit = moment().add(MAX_DAYS, 'days').startOf('day');

    return day.isBefore(startDateLimit) || day.isAfter(endDateLimit);
  };

  _calculateStartDateLimit = (repeatSchedule) => {
    const { scheduledMessage } = this.props;
    const { selectedStartDate } = scheduledMessage;
    let startDateLimit = selectedStartDate || moment().startOf('day');

    switch (repeatSchedule) {
      case 'Daily':
        startDateLimit = moment(startDateLimit).add(2, 'days').startOf('day');
        break;
      case 'Weekly':
        startDateLimit = moment(startDateLimit).add(2, 'weeks').startOf('day');
        break;
      case 'Bi-weekly':
        startDateLimit = moment(startDateLimit).add(4, 'weeks').startOf('day');
        break;
      case 'Monthly':
        startDateLimit = moment(startDateLimit).add(2, 'months').startOf('day');
        break;
      default:
    }

    return startDateLimit;
  };

  _resetScheduleOption = () => {
    const { bulkUpdateScheduledMessage } = this.props;

    bulkUpdateScheduledMessage({
      scheduleOption: ScheduleOption.NOT_SELECTED,
      repeatEnabled: false,
    });
  };

  _setRepeatMenuButtonRef = (ref) => {
    this.setState({ repeatMenuRef: ref });
  };

  _setRepeatSchedule = (schedule) => {
    const { scheduledMessage, bulkUpdateScheduledMessage } = this.props;
    const { selectedEndDate } = scheduledMessage;
    const updates = {
      repeatSchedule: schedule,
    };

    if (selectedEndDate && selectedEndDate.isBefore(this._calculateStartDateLimit(schedule))) {
      updates.selectedEndDate = null;
    }

    this._updateActiveNextButton();
    bulkUpdateScheduledMessage(updates);
  };

  _setScheduleOption = (scheduleOption) => {
    const { updateScheduledMessage } = this.props;

    updateScheduledMessage('scheduleOption', scheduleOption);
  };

  _setSelectedStartDate = (selectedStartDate) => {
    const { scheduledMessage, updateScheduledMessage } = this.props;
    const { selectedEndDate, selectedTime } = scheduledMessage;

    this._updateActiveNextButton();
    updateScheduledMessage('selectedStartDate', selectedStartDate);

    if (selectedTime) {
      if (isToday(selectedStartDate) && moment.now() > selectedTime) {
        updateScheduledMessage('selectedTime', null);
      } else {
        updateScheduledMessage(
          'selectedTime',
          moment(selectedTime)
            .year(selectedStartDate.year())
            .month(selectedStartDate.month())
            .date(selectedStartDate.date())
        );
      }
    }

    if (selectedEndDate && selectedStartDate >= selectedEndDate) {
      updateScheduledMessage('selectedEndDate', null);
    }
  };

  _setSelectedTime = (time) => {
    const { updateScheduledMessage } = this.props;

    this._updateActiveNextButton();
    updateScheduledMessage('selectedTime', time);
  };

  _setTimezone = (timezone) => {
    const { bulkUpdateScheduledMessage, scheduledMessage, updateCoarseTimezone } = this.props;
    const { selectedStartDate, selectedTime } = scheduledMessage;
    const newStart = moment(selectedTime || selectedStartDate).utcOffset(
      moment(selectedTime || selectedStartDate)
        .tz(timezone)
        .utcOffset(),
      true
    );

    this._updateActiveNextButton();
    updateCoarseTimezone({ name: timezone });

    const update = {
      timezone,
    };
    if (newStart.isValid()) {
      if (selectedStartDate) {
        update.selectedStartDate = newStart;
      }
      if (selectedTime) {
        if (moment.now() > newStart) {
          update.selectedTime = null;
        } else {
          update.selectedTime = newStart;
        }
      }
    }
    bulkUpdateScheduledMessage(update);
  };

  _setTimezoneMenuButtonRef = (ref) => {
    this.setState({ timezoneMenuButtonRef: ref });
  };

  _setCalendarDateRef = (ref) => {
    this.setState({ calendarDateRef: ref });
  };

  _setSelectDateTimeButton = (ref) => {
    this.setState({ selectDateTimeButtonRef: ref });
  };

  _updateActiveNextButton = (shouldDisable = false) => {
    const { isEditing, setNextButtonDisabled } = this.props;

    if (isEditing) {
      setNextButtonDisabled(shouldDisable);
    }
  };
}

export default mobxInjectSelect({
  patientAdminStore: ['isIhis', 'updateCoarseTimezone'],
  scheduleMessageStore: [
    'bulkUpdateScheduledMessage',
    'scheduledMessage',
    'updateScheduledMessage',
  ],
})(ScheduleInputs);
