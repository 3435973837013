import React, { useMemo } from 'react';
import { compiler } from 'markdown-to-jsx';

export default function MarkdownWrapper({ text }: { text: string }) {
  const body = useMemo(() => {
    let compiled;
    try {
      compiled = compiler(text);
    } catch (_e) {}

    if (
      !compiled ||
      compiled.props.children === null ||
      (Array.isArray(compiled.props.children) && compiled.props.children.includes(null))
    ) {
      return <>{text}</>;
    }

    return compiled;
  }, [text]);

  return body;
}
