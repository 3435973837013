import React, { useCallback } from 'react';
import BEM from '../bem';
import { mobxInjectSelect } from '../utils';
import { ReactComponent as Avatar } from '../images/patient-avatar-selected.svg';
import { ReactComponent as VideoIcon } from '../images/icon-video.svg';
import { Visitor } from '../../types';
import Modal from './Modal';

const classes = BEM.with('VisitorCallModal');
type optionsType = {
  entity: Visitor;
};

type VisitorCallModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  startCall: (entity: startCallType) => void;
  options: optionsType;
};

type startCallType = {
  recipientId: string;
};

type MobxProps = {
  startCall: (entity: startCallType) => void;
};

function VisitorCallModal({
  isOpen,
  closeModal,
  startCall,
  options,
}: VisitorCallModalProps & MobxProps) {
  const { entity } = options;
  const handleVideoCall = useCallback(() => {
    startCall({ recipientId: entity.visitorAccountToken });
  }, [startCall, entity.visitorAccountToken]);

  return (
    <Modal
      ariaLabelCloseButton={'Cancel Invite'}
      ariaLabelHeader={'Inform Provider Header'}
      bodyClass={classes('body')}
      className={classes()}
      closeClass={classes('close-button')}
      footer={<></>}
      footerClass={classes('footer')}
      headerClass={classes('header')}
      isOpen={isOpen}
      onRequestClose={closeModal}
      size={'medium-large'}
    >
      <>
        <div className={classes('patient-container')}>
          <div className={classes('avatar-container')}>
            <Avatar className={classes('avatar')} />
          </div>
          <div className={classes('call-text')}>Call {entity.visitorFullName}?</div>
        </div>
        <div className={classes('call-container')}>
          <div className={classes('call-button-container')}>
            <button className={classes('call-button')} onClick={handleVideoCall}>
              <div className={classes('video-icon')}>
                <VideoIcon />
              </div>
              <span className={classes('call-button-text')}>Start Video Call</span>
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
}

export default mobxInjectSelect<VisitorCallModalProps, MobxProps>({
  callStore: ['startCall'],
})(VisitorCallModal);
