import { useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';

export default function useIsUserActive(callContainerRef) {
  const [isUserActive, setIsUserActive] = useState(true);
  const timeoutIDRef = useRef(0);

  useEffect(() => {
    let mounted = true;
    const handleUserActivity = throttle(() => {
      if (!mounted) return;
      setIsUserActive(true);
      clearTimeout(timeoutIDRef.current);
      const timeoutID = window.setTimeout(() => setIsUserActive(false), 5000);
      timeoutIDRef.current = timeoutID;
    }, 500);

    handleUserActivity();

    const refCleanup = callContainerRef;
    callContainerRef.current &&
      callContainerRef.current.addEventListener('mouseover', handleUserActivity);

    return () => {
      refCleanup.current && refCleanup.current.removeEventListener('mouseover', handleUserActivity);
      mounted = false;
      clearTimeout(timeoutIDRef.current);
    };
  }, [callContainerRef]);

  return isUserActive;
}
