import React, { Component } from 'react';
import PropTypes from 'prop-types';
import propTypes from '../propTypes';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { EntityAvatar } from './';

const classes = BEM.with('ExistingPatientConversationCard');

const KEYMAP = {
  ENTER: 'Enter',
  SPACE: ' ',
};

class ExistingPatientConversationCard extends Component {
  static propTypes = {
    conversation: propTypes.conversation.isRequired,
    selectConversation: PropTypes.func.isRequired,
  };

  render() {
    const { conversation } = this.props;
    const { counterParty, counterPartyType } = conversation;
    const { createdByUser } = counterParty;

    const membersList = this.formatMemberNames(counterParty);
    const createdBy = createdByUser && createdByUser.displayName;
    return (
      <div
        role="button"
        className={classes()}
        onClick={this._onClick}
        tabIndex={-1}
        onKeyDown={this._handleKeyDown}
        aria-label={`Open conversation with ${membersList}`}
      >
        <div className={classes('avatar')}>
          <EntityAvatar entity={counterParty} entityType={counterPartyType} size="fit" />
        </div>
        <div className={classes('conversation-information')}>
          <div className={classes('display-name')}>{counterParty.displayName}</div>
          <div className={classes('sub-text')}>
            <div className={classes('members-text')}>Members:</div>
            <div className={classes('members-list')}>{membersList}</div>
          </div>
          <div className={classes('sub-text')}>
            <div className={classes('created-text')}>Created by:</div>
            <div className={classes('created-list')}>{createdBy}</div>
          </div>
        </div>
      </div>
    );
  }

  formatMemberNames = (group) => {
    return group.members
      .filter((u) => u.firstName)
      .map((u) => `${u.firstName}`)
      .join(', ');
  };

  _handleKeyDown = (e) => {
    if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
      this._onClick();
    }
  };

  _onClick = () => {
    const { conversation, selectConversation } = this.props;
    selectConversation(conversation);
  };
}

export default mobxInjectSelect({
  conversationStore: ['selectConversation'],
})(ExistingPatientConversationCard);
