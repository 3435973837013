import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { Switch } from '@tigerconnect/web-component-library';
import TCClient from '../../../client';
import { VWR } from '../../../types';
import { mobxInjectSelect } from '../../utils';
import { useVWRContext, hydrateVWR } from '../../../contexts/VirtualWaitingRoom';

import { ReactComponent as PowerOffSVG } from '../../images/icon-power-off.svg';
import { ReactComponent as PowerOnSVG } from '../../images/icon-power-on.svg';
import AccessPicker from '../PatientSettings/VirtualWaitingRoom/AccessPicker';
import DotsIndicator from '../DotsIndicator';

import BEM from '../../bem';

import SourceIdVwr from '../../../models/enums/SourceIdVwr';

const classes = BEM.with('VirtualWaitingRoomOptionsMenu');
const successModalClasses = BEM.with('VirtualWaitingRoomOptionsMenu');
const sourceId = SourceIdVwr.LOCAL;

export interface OptionsProps {
  closeOptions: () => void;
  vwr: VWR;
}

type MobxProps = {
  currentUserId: string;
  openModal: (modalName: string, options?: { content: JSX.Element }) => void;
};

const saveSuccessModalContent = (
  <div className={successModalClasses()}>
    <div>Success!</div>
    <span className={successModalClasses('modal-text')}>
      Settings has been successfully updated.
    </span>
  </div>
);

const FORM_ERROR_TEXT = 'There was a problem saving the settings updates. Please try again.';

function OptionsMenu({ currentUserId, closeOptions, vwr, openModal }: OptionsProps & MobxProps) {
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState<boolean>(false);
  const [saveIndicator, setSaveIndicator] = useState<boolean>(false);
  const [hasFormSubmissionError, setHasFormSubmissionError] = useState<boolean>(false);
  const [hasAccessPickerError, setHasAccessPickerError] = useState<boolean>(false);
  const [vwrDraft, setVWRDraft] = useState<VWR>(vwr);
  const {
    dispatch,
    state: { mobxProps },
  } = useVWRContext();
  const { currentOrganizationId } = mobxProps;

  const isEditClosingVWR = vwr.status === 'OPEN' && vwrDraft.status === 'CLOSED';

  useEffect(() => {
    setVWRDraft(vwr);
  }, [vwr]);

  useEffect(() => {
    const draftHasValidStaff = vwrDraft.staff.length > 0;

    setHasAccessPickerError(!draftHasValidStaff);
    setIsSaveButtonEnabled(!isEqual(vwr, vwrDraft) && draftHasValidStaff);
  }, [vwr, vwrDraft]);

  function toggleIsOpen() {
    setVWRDraft({ ...vwrDraft, status: vwrDraft.status === 'OPEN' ? 'CLOSED' : 'OPEN' });
  }

  async function save() {
    if (isSaveButtonEnabled) {
      setSaveIndicator(true);
      try {
        await TCClient.virtualWaitingRoom.update(vwr.id, currentOrganizationId, {
          staff: vwrDraft.staff,
          status: vwrDraft.status,
          version: vwrDraft.version,
          sourceId,
        });
        setSaveIndicator(false);
        const isCurrentUserIncluded =
          currentUserId && vwrDraft.staff.some((s) => s.accountToken === currentUserId);

        if (openModal) openModal('createSuccessModal', { content: saveSuccessModalContent });
        if (!isCurrentUserIncluded) {
          hydrateVWR(dispatch, mobxProps);
        }
        closeOptions();
      } catch (error) {
        setSaveIndicator(false);
        setHasFormSubmissionError(true);
      }
    }
  }

  return (
    <div className={classes()}>
      {hasFormSubmissionError && (
        <div className={classes('form-submission-error-notice')}>{FORM_ERROR_TEXT}</div>
      )}
      <div className={classes('form-items')}>
        <div className={classes('status-section')}>
          <div className={classes('status-text')}>
            <div className={classes('status-text-main')}>
              {vwrDraft.status === 'OPEN' ? <PowerOnSVG /> : <PowerOffSVG />}
              <p className={classes('status-text-main-text')}>
                Virtual Waiting Room is {vwrDraft.status === 'OPEN' ? 'Open' : 'Closed'}
              </p>
              <Switch
                checked={vwrDraft.status === 'OPEN'}
                onSwitch={toggleIsOpen}
                theme={'vwr-status'}
                height={19}
                width={57}
                offText="CLOSED"
                onText="OPEN"
                onColorTheme={'#007aff'}
                offColorTheme={'#c7c7c7'}
                dataTestId="vwrStatus"
              />
            </div>
            {isEditClosingVWR ? (
              <p className={classes('status-text-secondary-closing')}>
                You have selected to close the Virtual Waiting Room.
              </p>
            ) : (
              <p className={classes('status-text-secondary')}>
                Use this toggle to open or close this virtual waiting room.
              </p>
            )}
          </div>
        </div>
        <p className={classes('access-text')}>Virtual Waiting Room Access</p>
        <p className={classes('access-description')}>
          Please select the users who will have access to this Virtual Waiting Room. Users with
          access will not only be able to navigate to this virtual waiting room, but will also
          receive notifications for all virtual waiting room activity.
        </p>
        <div className={classes('access-picker')}>
          <AccessPicker
            draft={vwrDraft}
            setDraft={setVWRDraft as React.Dispatch<React.SetStateAction<VWR | undefined>>}
          />
          {hasAccessPickerError && (
            <span className={classes('staff-error-message')}>
              A virtual waiting room requires at least one staff member
            </span>
          )}
        </div>
      </div>
      <div className={classes('buttons-container')}>
        <button className={classes('cancel-button')} onClick={closeOptions}>
          Cancel
        </button>
        <button disabled={!isSaveButtonEnabled} className={classes('save-button')} onClick={save}>
          {saveIndicator ? <DotsIndicator color={'white'} size={13} /> : 'Save'}
        </button>
      </div>
    </div>
  );
}

export default mobxInjectSelect<OptionsProps, MobxProps>({
  modalStore: ['openModal'],
  sessionStore: ['currentUserId'],
})(OptionsMenu);
