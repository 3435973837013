import React from 'react';
import ToolTip from 'rc-tooltip';
import BEM from '../bem';
import { ReactComponent as InfoTooltipIcon } from '../images/info-tooltip.svg';

const classes = BEM.with('SmsOptedOut');

const SmsOptedOut = ({ isContact = false, tooltip = false }) => (
  <div className={classes('')}>
    <span className={classes('sms-opted-out-text')}>Opted out via SMS</span>
    {tooltip && (
      <ToolTip
        placement="right"
        overlay={
          <span>
            This {isContact ? 'contact' : 'patient'} has opted out of the TigerConnect Patient
            Engagement service via SMS. They will no longer be able to receive or reply to messages.
            Please contact your admin for further information.
          </span>
        }
        trigger={['click']}
      >
        <InfoTooltipIcon className={classes('tooltip-icon')} />
      </ToolTip>
    )}
  </div>
);

export default SmsOptedOut;
