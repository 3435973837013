import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import { attachments } from '../utils';
import Modal from './Modal';

const classes = BEM.with('SupportedFileTypesModal');

export default class SupportedFileTypesModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  render() {
    const { closeModal, isOpen } = this.props;

    return (
      <Modal
        bodyClass={classes('body')}
        closeClass={classes('close-button')}
        footerClass={classes('footer')}
        header={'File formats accepted'}
        headerClass={classes('header')}
        isOpen={isOpen}
        size={'medium-small'}
        footerSecondaryActions={
          <button type="button" onClick={closeModal} className={classes('cancel-btn')}>
            Got it
          </button>
        }
        className={classes()}
        onRequestClose={closeModal}
      >
        <div className={classes('formats-box')}>
          <ul className={classes('formats-list')}>
            {attachments.allExtensions.map((ext) => (
              <li key={ext}>.{ext}</li>
            ))}
          </ul>
        </div>
      </Modal>
    );
  }
}
