import {
  cloneDeep,
  compact,
  countBy,
  difference,
  groupBy,
  isUndefined,
  keyBy,
  omit,
  sum,
} from 'lodash';
import type { Dispatch } from '@reduxjs/toolkit';
import {
  CollabEntity,
  Role,
  RoleMetadata,
  SearchQueryOptions,
  SearchResult,
  Tag,
  Team,
  User,
} from '../../../types';
import {
  BatchFn,
  BatchEntityUpdateProps,
  DeleteEntityProps,
  FetchEntitiesOptions,
  FormatEntityProps,
  ToggleSaveEntityProps,
  TeamUpdateProps,
  RoleUpdateProps,
} from '../../../types/Collaboration';
import { actions } from '../../index';
import { entityServerId, entityTagId, standardTagId } from '../selectors';
import { batchRoleUpdate } from '../roles/thunk';
import { batchTeamUpdate } from '../teams/thunk';
import { CollaborationCategories, CollaborationTabs } from '../../../models/enums';
import { CollaborationCategory } from '../../../models/enums/CollaborationCategories';
import TCClient from 'client';

const { ALL, MYROLES, MYTEAMS, NOTTAGGED } = CollaborationCategories;
const { ACTIVETEAMS, ACTIVEROLES, ROLES, SAVED, TEAMS } = CollaborationTabs;
let latestQuery = 0;

export const createEventListeners = (eventHandlers: {
  [event: string]: (...args: unknown[]) => void;
}) => {
  Object.keys(eventHandlers).forEach((e: string) => TCClient.on(e, eventHandlers[e]));
};

export const destroyEventListeners = (eventHandlers: {
  [event: string]: (...args: unknown[]) => void;
}) => {
  Object.keys(eventHandlers).forEach((e: string) => TCClient.off(e, eventHandlers[e]));
};

const getSearchTypes = (options?: FetchEntitiesOptions) => {
  const { activeTab = '', category = '', isRolesEnabled, isTeamsEnabled } = options || {};
  let type;
  switch (activeTab) {
    case TEAMS:
    case ACTIVETEAMS:
      type = ['team'];
      break;
    case ROLES:
    case ACTIVEROLES:
      type = ['user'];
      break;
    case SAVED:
      type = category === MYROLES ? ['user'] : category === MYTEAMS ? ['team'] : [];
      break;
    default:
      type = compact([isRolesEnabled && 'user', isTeamsEnabled && 'team']);
      break;
  }
  return type;
};

const getEntitySearchOptions = (
  organizationId: string,
  options?: FetchEntitiesOptions
): SearchQueryOptions & { types: string[] } => {
  const { activeTab = '', category = '', continuation, name, tagIds } = options || {};
  const searchTypes = getSearchTypes(options);
  return {
    ...(tagIds?.length ? { tags: tagIds.map((id) => id.split(':')[1]) } : null),
    ...(category === ALL || category === NOTTAGGED
      ? { isTagged: category === ALL ? 'all' : false }
      : null),
    ...(activeTab === ACTIVEROLES || activeTab === ACTIVETEAMS
      ? { filterType: 'active' }
      : activeTab === SAVED
      ? { filterType: 'saved' }
      : null),
    name: name || '',
    continuation,
    organizationId,
    excludeReturnFields: true,
    types: searchTypes,
    sort: ['display_name'],
    version: 'LEGACY',
  };
};

const formatTeam = ({ entity, rawEntity }: FormatEntityProps): Team => {
  const aTeam = entity as Team;
  const rawTeam = rawEntity as Team;
  return {
    ...omit(aTeam, ['members']),
    tag: cloneDeep(rawTeam.tag),
  };
};

export const formatRole = ({ entity, metadata }: FormatEntityProps): Role => {
  const aRole = entity as Role;
  if (metadata) {
    const {
      exclude_private_group,
      open_assignment,
      owner_required,
      replay_history,
      role_transition,
    } = metadata;
    const aRoleMetadata = {
      ...metadata,
      exclude_private_group: exclude_private_group === '1',
      open_assignment: open_assignment === '1',
      owner_required: owner_required === '1',
      replay_history: replay_history === '1',
      role_transition: role_transition === '1',
    } as RoleMetadata;
    aRole.metadata = aRoleMetadata;
  }
  return {
    ...aRole,
    members: aRole.members?.map(
      (user) => omit(user, ['roles', 'botRole', 'profileByOrganizationId']) as User
    ),
  };
};

export const updateTagEntityCounts = (
  tagId: string | undefined,
  organizationId: string,
  entities: CollabEntity[],
  tagsById: { [id: string]: Tag }
) => {
  if (isUndefined(tagId)) return {};
  const tagIdKey = standardTagId(tagId, organizationId);
  const tagCounts = countBy(entities, (e: CollabEntity) =>
    standardTagId(entityTagId(e), organizationId)
  );
  const totalCurrentTagged = sum(Object.values(omit(tagCounts, ['undefined'])));
  const totalCurrentUntagged = tagCounts['undefined'] || 0;
  const untaggedEntityCountDiff = tagIdKey ? -totalCurrentUntagged : totalCurrentTagged;
  const taggedEntityCountDiff = tagIdKey ? totalCurrentUntagged : -totalCurrentTagged;
  const getTagKey = (tagId: string) => standardTagId(tagId, organizationId) as string;

  const updatedTags: { [id: string]: Tag } = Object.keys(omit(tagCounts, [tagIdKey || 'undefined']))
    .filter((x) => x && x !== 'undefined')
    .reduce((memo, id) => {
      const tag = tagsById[getTagKey(id)];
      return { ...memo, [getTagKey(id)]: { ...tag, entityCount: tag.entityCount - tagCounts[id] } };
    }, tagsById);

  if (tagIdKey) {
    const tag = tagsById[tagIdKey];
    if (tag) {
      updatedTags[tagIdKey] = {
        ...tag,
        entityCount: tag.entityCount + entities.length - (tagCounts[tagIdKey] || 0),
      };
    }
  }

  return {
    taggedEntityCountDiff,
    untaggedEntityCountDiff,
    updatedTags,
  };
};

export const batchUpdateOutOfScope = (
  activeTab: string,
  entityTypes: string[],
  selectedTag: Tag | undefined,
  selectedCategory: CollaborationCategory,
  entityUpdateProps: TeamUpdateProps | RoleUpdateProps,
  checkedTagsById: { [id: string]: Tag },
  organizationId: string,
  isCreate = false
): boolean | string => {
  const { tagId } = entityUpdateProps;

  if (selectedCategory === MYROLES || selectedCategory === MYTEAMS)
    return isCreate ? true : 'update';
  if (
    (activeTab === ROLES && entityTypes[0] === 'team') ||
    (activeTab === TEAMS && entityTypes[0] === 'role')
  )
    return true;

  if (selectedCategory === ALL) return false;
  if (selectedCategory === NOTTAGGED) return isCreate ? !!tagId : !isUndefined(tagId) && !!tagId;

  if (isUndefined(tagId)) return 'update';

  const currentTags = Object.keys(checkedTagsById).length
    ? Object.keys(checkedTagsById)
    : selectedTag
    ? [selectedTag.id]
    : [];

  const storeTagId = tagId && standardTagId(tagId, organizationId);
  return !storeTagId || !currentTags.includes(storeTagId);
};

export const handleCreateUpdateError = (
  dispatch: Dispatch,
  e: { response?: { text?: string; body?: { error: { message: string }; status: string } } }
) => {
  if (
    (e.response?.text &&
      e.response?.text?.indexOf('A role with that name already exists') !== -1) ||
    (e.response?.body?.status === 'fail' &&
      e.response?.body?.error?.message === 'This team name has been taken')
  ) {
    dispatch(
      actions.setEntityNameErrorMessage('This name already exists. Please choose a different name.')
    );
    dispatch(actions.setEntityNameError(true));
  } else {
    dispatch(actions.setModal({ name: 'error' }));
  }
};

export const batchEntityUpdate = async (
  dispatch: Dispatch,
  {
    ids,
    activeTab,
    checkedTagsById,
    organizationId,
    entitiesById,
    entityUpdateProps,
    selectedCategory,
    selectedEntity,
    selectedTag,
    tagsById,
    syncOne,
  }: BatchEntityUpdateProps
) => {
  const { tagId } = entityUpdateProps;
  const entities = ids
    .map((id) => entitiesById[id] || (selectedEntity?.id === id && selectedEntity))
    .filter((x) => x);
  const entitiesByType = groupBy(entities, '$entityType');
  const entitiesKeyedById = keyBy(entities, ({ id }) => id);
  let idsToOmit: string[] = [];

  try {
    dispatch(actions.setIsSavingEntity(true));

    const {
      taggedEntityCountDiff = 0,
      untaggedEntityCountDiff = 0,
      updatedTags = tagsById,
    } = updateTagEntityCounts(tagId, organizationId, entities, { ...cloneDeep(tagsById) });

    const batchFnByType: { [k: string]: BatchFn } = {
      team: batchTeamUpdate,
      role: batchRoleUpdate,
    };

    const updatedEntityMaps: { [id: string]: CollabEntity }[] = await Promise.all(
      Object.keys(entitiesByType).map(async (entityType) => {
        const ids = entitiesByType[entityType].map((x) => x.id);
        const batchFn = batchFnByType[entityType];
        const updates = await batchFn({
          dispatch,
          ids,
          entitiesById: entitiesKeyedById,
          organizationId,
          entityUpdateProps,
          syncOne,
        });

        return updates;
      })
    );

    const updatedEntities = updatedEntityMaps.reduce(
      (memo, updates) => ({ ...memo, ...updates }),
      {}
    );

    if (selectedEntity?.id && updatedEntities[selectedEntity?.id]) {
      dispatch(actions.selectEntity(updatedEntities[selectedEntity?.id]));
    }

    dispatch(actions.saveTags({ dontPropagate: true, tagsById: updatedTags }));

    const outOfScope = batchUpdateOutOfScope(
      activeTab,
      Object.keys(entitiesByType),
      selectedTag,
      selectedCategory,
      entityUpdateProps,
      checkedTagsById,
      organizationId
    );

    if (outOfScope === true) {
      idsToOmit = entities.map(({ id }) => id);
    } else if (outOfScope === 'update') {
      idsToOmit = difference(Object.keys(updatedEntities), Object.keys(entitiesById));
    }

    dispatch(
      actions.updateEntities({
        taggedEntityCountDiff,
        untaggedEntityCountDiff,
        entitiesById: omit({ ...entitiesById, ...updatedEntities }, idsToOmit),
      })
    );
    dispatch(actions.setModal(undefined));
  } catch (e) {
    console.error(e);
    handleCreateUpdateError(dispatch, e);
  } finally {
    dispatch(actions.setIsSavingEntity(false));
  }
};

export const fetchTotals = async (dispatch: Dispatch, organizationId: string) => {
  const data = await TCClient.tags.fetchTotals(organizationId);
  dispatch(actions.setTotals(data));
};

export const formatEntity = ({ entity, metadata }: FormatEntityProps): CollabEntity => {
  const clone = cloneDeep(entity);
  const metaDataClone = cloneDeep(metadata);
  const { $entityType } = clone;
  const props = { entity: clone, metadata: metaDataClone, rawEntity: entity };
  if ($entityType === 'role') return formatRole(props);
  return formatTeam(props);
};

const formatEntitySearchResults = (
  results: SearchResult[],
  metadata: { [k: string]: string | number | string[] }
) => {
  return {
    isResultsContinuation: (metadata.firstHit && metadata.firstHit !== 1) || false,
    entityResultsCount: (metadata.totalHits || 0) as number,
    entities: results
      .filter(({ entityType }: SearchResult) => ['team', 'role'].includes(entityType))
      .map((result: SearchResult) => {
        const { entity, metadata } = result;
        return formatEntity({ entity: entity as CollabEntity, metadata });
      }),
  };
};

export const addEntity = (
  dispatch: Dispatch,
  entityType: string,
  activeTab: string,
  checkedTagsById: { [id: string]: Tag },
  entity: CollabEntity,
  organizationId: string,
  entitiesById: { [k: string]: CollabEntity },
  selectedCategory: CollaborationCategory,
  selectedTag: Tag | undefined,
  tagsById: { [k: string]: Tag }
) => {
  const formattedEntity = formatEntity({ entity });
  let untaggedEntityCountDiff = 0;
  let taggedEntityCountDiff = 0;
  const tagId = entityTagId(entity) && standardTagId(entityTagId(entity) as string, organizationId);

  if (tagId) {
    if (tagsById[tagId]) {
      const oldTag = tagsById[tagId];
      const newTag = {
        ...oldTag,
        entityCount: (oldTag.entityCount || 0) + 1,
      };
      dispatch(
        actions.saveTags({ dontPropagate: true, tagsById: { ...tagsById, [tagId]: newTag } })
      );
    }
    taggedEntityCountDiff++;
  } else {
    untaggedEntityCountDiff++;
  }

  const isOutOfScope = batchUpdateOutOfScope(
    activeTab,
    [entityType],
    selectedTag,
    selectedCategory,
    formattedEntity,
    checkedTagsById,
    organizationId,
    true
  );

  dispatch(
    actions.updateEntities({
      entityResultsCountDiff: isOutOfScope ? 0 : 1,
      taggedEntityCountDiff,
      untaggedEntityCountDiff,
      entitiesById: {
        ...entitiesById,
        ...(isOutOfScope ? null : { [formattedEntity.id]: formattedEntity }),
      },
    })
  );

  dispatch(actions.setNewEntityType(undefined));
  dispatch(actions.selectEntity(formattedEntity));
};

export const deleteEntity = async (
  dispatch: Dispatch,
  { entityType, organizationId, entitiesById, selectedEntity, tagsById }: DeleteEntityProps
) => {
  const id = selectedEntity.id;
  if (entityType === 'role') {
    await TCClient.roles.delete(entityServerId(id), organizationId);
  } else {
    await TCClient.teams.delete(entityServerId(id), { organizationId });
  }

  const allEntities = { [id]: selectedEntity, ...entitiesById };
  const oldEntity = allEntities[id] as CollabEntity;
  const oldEntityTagId =
    entityTagId(oldEntity) && standardTagId(entityTagId(oldEntity) as string, organizationId);

  let untaggedEntityCountDiff = 0;
  let taggedEntityCountDiff = 0;

  if (oldEntityTagId) {
    if (tagsById[oldEntityTagId]) {
      taggedEntityCountDiff--;
      const oldTag = tagsById[oldEntityTagId];
      const newTag = { ...oldTag, entityCount: (oldTag.entityCount || 1) - 1 };
      dispatch(
        actions.saveTags({
          dontPropagate: true,
          tagsById: { ...tagsById, [oldEntityTagId]: newTag },
        })
      );
    }
  } else {
    untaggedEntityCountDiff--;
  }

  dispatch(
    actions.updateEntities({
      entityResultsCountDiff: -1,
      taggedEntityCountDiff,
      untaggedEntityCountDiff,
      entitiesById: omit(entitiesById, [id]),
    })
  );

  dispatch(actions.selectEntity(undefined));
};

export const fetchEntities = async (
  dispatch: Dispatch,
  organizationId: string,
  options?: FetchEntitiesOptions
) => {
  latestQuery++;
  const { continuation } = options || {};

  !continuation && dispatch(actions.setEntityLoadingStatus(true));

  const searchOptions = getEntitySearchOptions(organizationId, options);

  if (searchOptions.types.length === 0) {
    dispatch(
      actions.updateEntities({
        entityResultsCount: 0,
        entitiesById: {},
        shouldAppend: false,
        continuation: undefined,
      })
    );
    dispatch(actions.setEntityLoadingStatus(false));
    return;
  }

  const currentQuery = latestQuery;
  const { results, metadata } = await TCClient.search.query<SearchResult>(searchOptions);
  if (currentQuery < latestQuery) return;

  const { entities, entityResultsCount, isResultsContinuation } = formatEntitySearchResults(
    results,
    metadata
  );

  dispatch(
    actions.updateEntities({
      entityResultsCount,
      entitiesById: keyBy(entities, (entity) => entity.id),
      shouldAppend: isResultsContinuation,
      continuation: metadata?.continuation,
    })
  );

  !continuation && dispatch(actions.setEntityLoadingStatus(false));
};

export const toggleSaveEntity = async (
  dispatch: Dispatch,
  {
    activeTab,
    savedIds,
    selectedCategory,
    entity,
    organizationId,
    entityType,
  }: ToggleSaveEntityProps
) => {
  let newSavedIds = [];
  const id = entity.id;

  if (!savedIds.includes(id)) {
    if (entityType === 'role') {
      await TCClient.roles.saveRole(entityServerId(id), organizationId);
    } else {
      await TCClient.teams.saveTeam(entityServerId(id), organizationId);
    }
    newSavedIds = [...savedIds, id];
  } else {
    if (entityType === 'role') {
      await TCClient.roles.removeSavedRole(entityServerId(id), organizationId);
    } else {
      await TCClient.teams.removeSavedTeam(entityServerId(id), organizationId);
    }
    newSavedIds = savedIds.filter((savedId) => savedId !== id);
  }

  if (
    activeTab === SAVED &&
    ((entityType === 'role' && selectedCategory === MYROLES) ||
      (entityType === 'team' && selectedCategory === MYTEAMS))
  ) {
    savedIds.includes(id)
      ? dispatch(actions.removeEntity(entity.id))
      : dispatch(actions.addEntity(entity));
  }

  if (entityType === 'role') {
    dispatch(actions.setMyRoles({ savedRoleIds: newSavedIds }));
  } else {
    dispatch(actions.setMyTeams({ savedTeamIds: newSavedIds }));
  }
};
