import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SpringScrollbars } from '../';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import CallingPreferencesSettings from './CallingPreferencesSettings';
import {
  About,
  AccountSettings,
  DesktopAppSettings,
  DownloadApp,
  ExpertSettings,
  ProfileMenu,
  ProfileHeader,
  ProfileInfo,
  ProfileStatus,
  NewProfileStatus,
  NewProviderStatus,
} from './';

const classes = BEM.with('Profile');

class Profile extends Component {
  static propTypes = {
    expertMode: PropTypes.bool.isRequired,
    getAutoForwardEntityDisplayNameByOrg: PropTypes.func,
    isCallingAvailable: PropTypes.func.isRequired,
    isExtendedAutoForwardOptionsEnabled: PropTypes.bool.isRequired,
    setScrollbars: PropTypes.func.isRequired,
    setScrollbarsElement: PropTypes.func.isRequired,
  };

  render() {
    const {
      currentUser,
      expertMode,
      getAutoForwardEntityDisplayNameByOrg,
      isAvailabilityFeatureFlagEnabled,
      isCallingAvailable,
      isExtendedAutoForwardOptionsEnabled,
      stopCurrentAction,
    } = this.props;

    const autoForwardEntitiesDisplayName = (organization) => {
      const currentAutoForwardDisplayText = isExtendedAutoForwardOptionsEnabled
        ? getAutoForwardEntityDisplayNameByOrg(organization)
        : organization?.autoForwardReceiver?.displayName;
      return currentAutoForwardDisplayText;
    };

    return (
      <div
        className={classes()}
        tabIndex={-1}
        onKeyDown={(e) => e.key === 'Escape' && stopCurrentAction()}
      >
        <ProfileHeader />
        <div className={classes('content')}>
          <ProfileMenu />
          <div className={classes('scroll')}>
            <SpringScrollbars ref={this._setScrollbars} shouldScrollOverflow={true}>
              <div className={classes('body')}>
                <div className={classes('items')} ref={this._setScrollbarsElement}>
                  <ProfileInfo />
                  {isAvailabilityFeatureFlagEnabled ? (
                    <>
                      <NewProviderStatus />
                      <NewProfileStatus
                        autoForwardEntitiesDisplayName={autoForwardEntitiesDisplayName}
                        dndText={currentUser.dndText}
                        dnd={currentUser.dnd}
                        dndExpireAt={currentUser.dndExpireAt}
                        inRole={currentUser.roles?.length > 0}
                      />
                    </>
                  ) : (
                    <ProfileStatus
                      autoForwardEntitiesDisplayName={autoForwardEntitiesDisplayName}
                    />
                  )}
                  <AccountSettings />
                  <DesktopAppSettings />
                  {isCallingAvailable && <CallingPreferencesSettings />}
                  {expertMode && <ExpertSettings />}
                  <About />
                  <DownloadApp />
                </div>
              </div>
            </SpringScrollbars>
          </div>
        </div>
      </div>
    );
  }

  _setScrollbars = (ref) => {
    this.props.setScrollbars(ref);
  };

  _setScrollbarsElement = (ref) => {
    const { setScrollbarsElement } = this.props;
    setScrollbarsElement(ref, 'ProfileInfo');
  };
}

export default mobxInjectSelect({
  callStore: ['isCallingAvailable'],
  localStore: ['expertMode'],
  messengerStore: [
    'isAvailabilityFeatureFlagEnabled',
    'isExtendedAutoForwardOptionsEnabled',
    'stopCurrentAction',
  ],
  profileStore: ['setScrollbars', 'setScrollbarsElement'],
  sessionStore: ['currentUser'],
  userStore: ['getAutoForwardEntityDisplayNameByOrg'],
})(Profile);
