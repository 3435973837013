import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppTypes } from '../../../models/enums';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import Admin from './Admin';
import ReactAdmin from './ReactAdmin';

const classes = BEM.with('AdminIframe');

class AdminIframe extends Component {
  static propTypes = {
    currentAppSelected: PropTypes.string.isRequired,
  };

  render() {
    const { currentAppSelected } = this.props;
    let adminFragment;

    if (currentAppSelected === AppTypes.ROLES || currentAppSelected === AppTypes.ANALYTICS) {
      adminFragment = <ReactAdmin view={currentAppSelected} />;
    } else {
      adminFragment = <Admin type={currentAppSelected} />;
    }

    return <div className={classes()}>{adminFragment}</div>;
  }
}

export default mobxInjectSelect({
  messengerStore: ['currentAppSelected'],
})(AdminIframe);
