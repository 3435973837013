import React from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import propTypes from '../../propTypes';

import { ReactComponent as DropDownSvg } from '../../images/pa-dropdown.svg';
import ProviderBroadcastListRow from './ProviderBroadcastListRow';

const classes = BEM.with('ProviderBroadcastListTable');

const ProviderBroadcastListTable = ({
  groupedMembers,
  groupedPendingMembers,
  id,
  isAdmin,
  isLoading,
  isPregen,
  isSecurityGroupPresent,
  queryValue,
  removePendingMember,
  resetPendingMembers,
  saveInProgress,
  savePendingMembers,
  scrollContainerRef,
  selectedMemberIds,
  sortBy,
  sortOrder,
  toggleSelectedMember,
  toggleSort,
}) => {
  let resultsFragment;

  if (id.includes('admins@')) {
    resultsFragment = (
      <div className={classes('pregen-broadcastList')}>
        <div className={classes('pregen-broadcastList-text')}>
          This broadcast list is automatically generated and contains every administrative user
          within the organization. This list cannot be edited or deleted.
        </div>
      </div>
    );
  } else if (id.includes('everyone@')) {
    resultsFragment = (
      <div className={classes('pregen-broadcastList')}>
        <div className={classes('pregen-broadcastList-text')}>
          This broadcast list is automatically generated and contains every member within the
          organization. This list cannot be edited or deleted.
        </div>
      </div>
    );
  } else if (groupedPendingMembers.length === 0 && groupedMembers.length === 0 && !isLoading) {
    if (queryValue) {
      resultsFragment = (
        <div className={classes('empty-broadcastList')}>
          No results found for &quot;{queryValue}&quot;
        </div>
      );
    } else if (isSecurityGroupPresent) {
      resultsFragment = (
        <div className={classes('empty-broadcastList', { isSecurityGroupPresent })}>
          Members within this list cannot be manually edited or deleted within TigerConnect.
        </div>
      );
    } else {
      resultsFragment = <div className={classes('empty-broadcastList')}>No members available.</div>;
    }
  } else {
    resultsFragment = (
      <React.Fragment>
        {groupedPendingMembers.length > 0 && (
          <div className={classes('pending-members')}>
            {groupedPendingMembers.map((members) => (
              <div className={classes('members-group')} key={members[0].id}>
                {members.map((member) => (
                  <ProviderBroadcastListRow
                    isPending
                    isSingle={members.length === 1}
                    isAdmin={isAdmin}
                    isSecurityGroupPresent={isSecurityGroupPresent}
                    key={member.id}
                    member={member.$entityType === 'role' ? member.botUser : member}
                    removePendingMember={removePendingMember}
                  />
                ))}
              </div>
            ))}
            <div className={classes('save-cancel-buttons')}>
              <button className={classes('cancel-button')} onClick={resetPendingMembers}>
                Cancel
              </button>
              <button
                className={classes('save-button')}
                disabled={saveInProgress}
                onClick={savePendingMembers}
              >
                {saveInProgress ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        )}
        {groupedMembers.length > 0 && (
          <div className={classes('members')}>
            {groupedMembers.map((members) => (
              <div className={classes('members-group')} key={members[0].id}>
                {members.map((member, idx) => (
                  <ProviderBroadcastListRow
                    isFirstEntry={idx === 0}
                    isSelected={selectedMemberIds.includes(
                      member.$entityType === 'role' ? member.botUserId : member.id
                    )}
                    isSecurityGroupPresent={isSecurityGroupPresent}
                    key={member.id}
                    isAdmin={isAdmin}
                    member={member.$entityType === 'role' ? member.botUser : member}
                    isSingle={members.length === 1}
                    toggleSelectedMember={toggleSelectedMember}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }

  return (
    <div className={classes()}>
      <div className={classes('list')}>
        <div className={classes('list-header', { isPregen })}>
          <div className={classes('list-item-checkbox')} />
          <div className={classes('list-item-label')} onClick={() => toggleSort('name')}>
            NAME
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'name',
              })}
            />
          </div>
          <div className={classes('list-item-title')} onClick={() => toggleSort('title')}>
            TITLE
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'title',
              })}
            />
          </div>
          <div className={classes('list-item-department')} onClick={() => toggleSort('department')}>
            DEPARTMENT
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'department',
              })}
            />
          </div>
          <div
            className={classes('list-item-added-by')}
            onClick={() => toggleSort('added_by_display_name')}
          >
            ADDED BY
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'added_by_display_name',
              })}
            />
          </div>
          <div className={classes('list-item-date-added')} onClick={() => toggleSort('added_on')}>
            DATE ADDED
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'added_on',
              })}
            />
          </div>
        </div>
        <div className={classes('lists-container')} ref={scrollContainerRef}>
          {resultsFragment}
        </div>
      </div>
    </div>
  );
};

ProviderBroadcastListTable.propTypes = {
  groupedMembers: PropTypes.arrayOf(propTypes.userArray).isRequired,
  groupedPendingMembers: PropTypes.arrayOf(propTypes.userArray).isRequired,
  id: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isPregen: PropTypes.bool.isRequired,
  isSecurityGroupPresent: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  queryValue: PropTypes.string.isRequired,
  removePendingMember: PropTypes.func.isRequired,
  resetPendingMembers: PropTypes.func.isRequired,
  saveInProgress: PropTypes.bool.isRequired,
  savePendingMembers: PropTypes.func.isRequired,
  scrollContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  selectedMemberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  toggleSelectedMember: PropTypes.func.isRequired,
  toggleSort: PropTypes.func.isRequired,
};

export default ProviderBroadcastListTable;
