import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import LoadingSpinner from './LoadingSpinner';

class NetworkLoading extends Component {
  static propTypes = {
    isProviderNetwork: PropTypes.bool.isRequired,
  };

  render() {
    const { isProviderNetwork } = this.props;
    const network = isProviderNetwork ? 'Provider' : 'Patient';

    return <LoadingSpinner loadingText={`Switching to ${network} Network`} />;
  }
}

export default mobxInjectSelect({
  networkStore: ['isProviderNetwork'],
})(NetworkLoading);
