import React from 'react';
import { BasicModal } from '../..';

export default function ErrorModal({
  closeModal,
  isOpen,
  options = {},
}: {
  closeModal: () => void;
  isOpen: boolean;
  options?: {
    customHeaderText?: string;
    customBodyText?: string;
    customSubmitText?: string;
  };
}) {
  const { customHeaderText, customBodyText, customSubmitText } = options;

  return (
    <BasicModal
      ariaLabelBody={'Error Info'}
      ariaLabelCancelButton={'Error Cancel'}
      ariaLabelCloseButton={'Error Close'}
      ariaLabelHeader={'Error Header'}
      ariaLabelSubmitButton={'Confirm Error'}
      bodyText={customBodyText || 'Something went wrong, please try again.'}
      headerText={customHeaderText || 'Oh no.'}
      hideCancel={true}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={closeModal}
      size={'medium'}
      theme={'danger'}
      submitText={customSubmitText || 'OK'}
      hasCloseButton={true}
      useWCL
    />
  );
}
