import React from 'react';

import styles from './Link.module.css';
export type LinkProps = {
  children: string | JSX.Element;
  download?: string;
  href?: string;
  onClick?: () => void;
  target?: string;
};
export function Link({ children, download, href, onClick, target }: LinkProps) {
  const props = {
    ...(download ? { download } : null),
    ...(href ? { href } : null),
    ...(onClick ? { onClick } : null),
    ...(target ? { target } : null),
  };
  return (
    <a className={styles.root} {...props}>
      {children}
    </a>
  );
}
