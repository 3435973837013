import React from 'react';
import BEM from '../bem';

const classes = BEM.with('InputCounter');

type InputCounterProps = {
  placeholderValue: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputValue?: string;
  maxCharacterInputValue: number;
  type: string;
};

export default function InputCounter({
  placeholderValue,
  handleChange,
  inputValue,
  maxCharacterInputValue,
  type,
}: InputCounterProps) {
  return (
    <div className={classes()}>
      <input
        className={classes('input-container', { type })}
        type="text"
        placeholder={placeholderValue}
        onChange={handleChange}
        value={inputValue}
        maxLength={maxCharacterInputValue}
      ></input>
      <div className={classes('character-limit-count')}>
        {inputValue ? inputValue.length : 0}/{maxCharacterInputValue}
      </div>
    </div>
  );
}
