import React from 'react';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import Modal from './Modal';

const classes = BEM.with('DeleteAppointmentModal');

type DeleteAppointmentModalProps = {
  isOpen: boolean;
  options: {
    itemsToDelete: string[];
    patientId: string;
    patientAppointmentsList: string[];
    setItemsToDelete: (items: string[]) => void;
  };
};

type MobxProps = {
  closeModal: () => void;
  deleteAppointments: (id: string[]) => void;
};

function DeleteAppointmentModal({
  closeModal,
  deleteAppointments,
  isOpen,
  options,
}: MobxProps & DeleteAppointmentModalProps) {
  const { itemsToDelete, setItemsToDelete } = options;

  const deleteAppointmentsFromList = () => {
    deleteAppointments(itemsToDelete);
    setItemsToDelete([]);
    closeModal();
  };

  return (
    <Modal
      ariaLabelCloseButton="Delete Appointment Close"
      ariaLabelHeader="Delete Appointment"
      bodyClass={classes('body')}
      closeClass={classes('close-button')}
      header="Confirm Delete"
      headerClass={classes('header')}
      size={'medium'}
      isOpen={isOpen}
      footerPrimaryActions={
        <button
          aria-label="Delete Appointment Delete"
          className={classes('delete-btn')}
          type="button"
          onClick={() => deleteAppointmentsFromList()}
        >
          DELETE ({itemsToDelete.length > 0 && itemsToDelete.length})
        </button>
      }
      footerSecondaryActions={
        <button
          aria-label="Delete Appointment Cancel"
          className={classes('cancel-btn')}
          onClick={() => closeModal()}
          type="button"
        >
          CANCEL
        </button>
      }
      className={classes()}
      onRequestClose={() => closeModal()}
    >
      <div aria-label="Delete Appointment Info" className={classes('info')}>
        Deleting this appointment will also delete all related automated messages connected to this
        appointment record.
      </div>
    </Modal>
  );
}

export default mobxInjectSelect<DeleteAppointmentModalProps, MobxProps>({
  appointmentsStore: ['deleteAppointments'],
})(DeleteAppointmentModal);
