import React, { useState, useRef, useEffect, useCallback } from 'react';
import BEM from '../../../../bem';
import { Modal } from '../../..';
import { ReactComponent as UploadButtonSvg } from '../../../../images/upload.svg';
import mobxInjectSelect from '../../../../utils/mobxInjectSelect';
import TCClient from '../../../../../client';
import { useAppSelector } from 'redux-stores';

const classes = BEM.with('UploadPBXLinesModal');
const type = 'PBX_LINES';

type PBXSystem = {
  domain: string;
  id: number;
  organizationId: number;
  targetType: string;
};

type UploadPBXLinesModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

type MobxProps = {
  uploadCSV: (
    newFile: File,
    type: string,
    { systemConfigurationId }: { systemConfigurationId: string }
  ) => void;
};

function UploadPBXLinesModal({
  isOpen,
  closeModal,
  uploadCSV,
}: UploadPBXLinesModalProps & MobxProps) {
  const [error, setError] = useState<string | null>(null);
  const file = useRef<File | ''>('');
  const fileHandlerRef = useRef<HTMLInputElement | null>(null);
  const [pbxSystems, setPbxSystems] = useState<number[] | null>([]);
  const [selectedSystemIdValue, setSelectedSystemIdValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSystemIdValue(event.target.value);
    setError(null);
  };

  const handleRequestClose = () => {
    setError(null);
    file.current = '';
    closeModal();
  };

  const currentOrganizationId = useAppSelector((state) => state.orgSettings.settings.id);

  const getPBXSystems = useCallback(async () => {
    const containNurseCall = false;
    try {
      const pbxSystems = (await TCClient.pbx.getPbxSystems({
        currentOrganizationId,
        containNurseCall,
      })) as PBXSystem[];
      const pbxSystemIds: number[] = pbxSystems.map((system) => system.id);
      setPbxSystems(pbxSystemIds);
    } catch (error) {
      console.error('Error fetching PBX systems:', error);
    }
  }, [currentOrganizationId]);

  useEffect(() => {
    if (isOpen) {
      getPBXSystems();
    }
  }, [isOpen, getPBXSystems]);

  const handleSelectFile = () => {
    if (fileHandlerRef.current) {
      fileHandlerRef.current.click();
    }
  };

  const setFileHandler = (element: HTMLInputElement | null) => {
    fileHandlerRef.current = element;
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files && event.target.files.length ? event.target.files[0] : null;

    if (newFile) {
      file.current = newFile;
      await uploadCSV(newFile, type, { systemConfigurationId: selectedSystemIdValue });
    }
  };

  return (
    <Modal
      bodyClass={classes('body')}
      className={classes()}
      header={'Upload PBX lines'}
      closeClass={classes('close-button')}
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      size={'medium-large'}
    >
      <div className={classes('main-container')}>
        {error && (
          <div className={classes('error')}>
            <div className={classes('error-message')}>{error}</div>
          </div>
        )}
        <div className={classes('pbx-system-id-container')}>
          <div className={classes('pbx-system-id-label')}>Select PBX System ID:</div>
          <div>
            <select id="dropdown" value={selectedSystemIdValue} onChange={handleChange}>
              <option value="" disabled>
                Select an id
              </option>
              {pbxSystems &&
                pbxSystems.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div
          className={classes('upload-button-container')}
          onClick={() => {
            if (selectedSystemIdValue === '') {
              setError('Please select a PBX System ID and retry upload');
              return null;
            } else {
              handleSelectFile();
            }
          }}
        >
          <input
            className={classes('upload-button-input')}
            type="file"
            accept="text/csv"
            ref={setFileHandler}
            onChange={handleFileChange}
          />
          <div className={classes('column-one')}>
            <div className={classes('first-row')}>Upload PBX lines</div>
            <div className={classes('second-row')}>Upload PBX lines from a .csv file</div>
          </div>
          <div className={classes('column-two')}>
            <UploadButtonSvg className={classes('button-icon-path')} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default mobxInjectSelect<UploadPBXLinesModalProps, MobxProps>({
  patientStore: ['uploadCSV'],
})(UploadPBXLinesModal);
