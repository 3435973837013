import React from 'react';
import classNames from 'classnames';

import { ViewHeader } from '../ViewHeader/ViewHeader';
import { ViewSubheader } from '../ViewSubheader/ViewSubheader';

import styles from './ViewContainer.module.css';

export interface ViewContainerProps {
  children: React.ReactElement;
  /**
   * Customize styles for ViewContainer
   */
  customStyles?: Record<string, string>;
  /**
   * View Header
   */
  header: string;
  /**
   * View Sub header
   */
  subHeader?: string;
  /**
   * callback for view close button
   */
  onClose?: () => void;
}

export const ViewContainer: React.FC<ViewContainerProps> = ({
  children,
  customStyles = {},
  header,
  subHeader,
  onClose,
}) => {
  const currentStyles = { ...styles, ...customStyles };

  return (
    <div className={classNames(currentStyles.viewContainer)}>
      <div className={currentStyles.headerContainer}>
        <div className={currentStyles.header}>
          <ViewHeader label={header} customStyles={currentStyles} />
        </div>
        {onClose && (
          <div
            className={classNames(currentStyles.closeButton)}
            onClick={() => {
              onClose?.();
            }}
          />
        )}
      </div>
      {subHeader && <ViewSubheader label={subHeader} customStyles={currentStyles} />}
      {children}
    </div>
  );
};
