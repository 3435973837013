import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '../../WebComponents';
import { Role } from '../../../../types';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import BEM from '../../../bem';

const { setAwayMessages } = actions;

const { getAutoResponse, removeAwayResponse, saveAwayResponse } = thunk;
const classes = BEM.with('CollaborationEntityEditor');

type RoleEditorAutoResponseProps = {
  role?: Role;
};

export default function RoleEditorAutoResponse({ role }: RoleEditorAutoResponseProps) {
  const dispatch = useDispatch();
  const selectedOrgId = useSelector((state: ReduxState) => state.collab.selectedOrgId);
  const awayMessages = useSelector((state: ReduxState) => state.roles.awayMessages);
  const [draft, setDraft] = useState<string>();
  const [hasAutoResponse, setHasAutoResponse] = useState<boolean>();
  const [savedDraft, setSavedDraft] = useState<string>();
  const [focus, setFocus] = useState<boolean>(false);
  const descriptionSetInput = useRef<HTMLTextAreaElement>(null);

  const fetchAutoResponse = useCallback(
    async (id: string) => {
      const response = await getAutoResponse(id);
      const metadata = response.entity ? response.entity.metadata : response.metadata;
      if (metadata && metadata.away_status) {
        setHasAutoResponse(true);
        setDraft(metadata.away_status);
        setSavedDraft(metadata.away_status);
        dispatch(setAwayMessages({ id: metadata.id, awayMessage: metadata.away_status }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    setDraft('');
    setSavedDraft('');
    setHasAutoResponse(false);
    if (role?.metadata?.room_assignment_id) {
      fetchAutoResponse(role.metadata.room_assignment_id);
    }
  }, [fetchAutoResponse, role]);

  useEffect(() => {
    if (!savedDraft?.length && hasAutoResponse && descriptionSetInput.current) {
      descriptionSetInput.current.focus();
    }
  }, [hasAutoResponse, savedDraft]);

  const descriptionOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 27) {
      resetDescription();
    }

    if (focus && e.keyCode === 13 && descriptionSetInput.current) {
      e.preventDefault();
      descriptionSetInput.current.blur();
    }
  };

  const descriptionOnFocus = () => {
    setFocus(true);
    if (descriptionSetInput.current) {
      descriptionSetInput.current.focus();
    }
  };

  const descriptionOnBlur = () => {
    setFocus(false);
    if (draft?.length) {
      if (draft !== savedDraft) {
        saveResponse(draft.trim());
      }
    } else {
      delResponse();
    }
  };

  const updateDraft = (value: string) => {
    setDraft(value);
  };

  const saveResponse = (awayMessage: string) => {
    if (!role) return;

    saveAwayResponse(dispatch, role.id, selectedOrgId, awayMessage || '');
    setSavedDraft(awayMessage);

    if (role.botUserId && awayMessage) {
      dispatch(setAwayMessages({ id: role.botUserId, awayMessage: awayMessage }));
    }
  };

  const delResponse = () => {
    if (!role) return;
    removeAwayResponse(dispatch, role.id, selectedOrgId);
    setHasAutoResponse(false);
    setDraft('');
    setSavedDraft('');
  };

  const resetDescription = () => {
    setDraft(savedDraft);
  };

  const handleAutoResponseSwitch = () => {
    if (!!hasAutoResponse) {
      delResponse();
    } else if (role && role.botUserId && awayMessages[role.botUserId]) {
      setHasAutoResponse(true);
      setDraft(awayMessages[role.botUserId]);
      saveResponse(awayMessages[role.botUserId]);
    } else {
      setHasAutoResponse(!hasAutoResponse);
      setDraft('');
    }
  };

  const form = (
    <form id="autoResponseForm">
      <textarea
        className={classes('role-response-text')}
        maxLength={256}
        onBlur={descriptionOnBlur}
        onChange={(e) => updateDraft(e.target.value)}
        onFocus={descriptionOnFocus}
        onKeyDown={descriptionOnKeyDown}
        ref={descriptionSetInput}
        placeholder="Enter Custom Message (Required)"
        value={draft}
      />
    </form>
  );
  return (
    <div className={classes('role-name-attached-container')}>
      <div className={classes('title-text-area-container')}>
        <div className={classes('title-text-area')}>Automated Response</div>
        <div className={classes('text-area-button-container')}>
          <Switch
            checked={!!hasAutoResponse}
            dataTestId="autoResponse"
            onSwitch={handleAutoResponseSwitch}
          />
        </div>
      </div>
      {hasAutoResponse && (
        <ToolTip disable={focus} text="Edit" textAlign={'center'} getManualOffset={() => {}}>
          <div className={classes('auto-response-editor-container')}>{form}</div>
        </ToolTip>
      )}
    </div>
  );
}
