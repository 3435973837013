import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './Dropdown.module.css';

export type DropdownOption<Value extends string = string> = {
  label: string;
  value: Value;
  disabled?: boolean;
  dataTestId?: string;
};

export type DropdownProps<Value extends string = string> = {
  options?: DropdownOption<Value>[];
  defaultValue?: string | number;
  parentValue?: string;
  onChange?: (selectedOption: Value, index: number) => void;
  dataTestId?: string;
  disabled?: boolean;
};

export const Dropdown = <Value extends string = string>({
  dataTestId,
  defaultValue,
  options = [
    { label: 'Off', value: 'off' },
    { label: 'Organization', value: 'all' },
    { label: 'Individual', value: 'individual' },
  ] as unknown as DropdownOption<Value>[],
  onChange,
  parentValue,
  disabled,
}: DropdownProps<Value>) => {
  const [selectedOption, setSelectedOption] = useState(
    defaultValue !== null && defaultValue !== undefined ? defaultValue : options[0]?.value
  );
  useEffect(() => {
    if (parentValue) {
      setSelectedOption(parentValue);
    }
  }, [parentValue]);

  useEffect(() => {
    if (defaultValue !== null && defaultValue !== undefined) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue]);

  return (
    <select
      className={classNames(styles.dropdownRoot)}
      onChange={(e) => {
        setSelectedOption(e.target.value);
        onChange && onChange(e.target.value as Value, e.target.options.selectedIndex);
      }}
      value={selectedOption}
      data-test-id={dataTestId}
      disabled={disabled}
    >
      {parentValue && <option key={parentValue}>{parentValue}</option>}
      {options.map((option, index) => (
        <option
          key={index}
          value={option.value}
          disabled={option.disabled}
          data-test-id={option.dataTestId}
        >
          {option.label}
        </option>
      ))}
    </select>
  );
};
