import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import BEM from '../../../bem';
import { BasicModal } from '../..';
import EntitiesItem from '../Entities/EntitiesItem';
import { mobxInjectSelect } from '../../../utils';

const { setModal } = actions;
const { deleteEntity } = thunk;
const classes = BEM.with('CollaborationModal');

type MobxProps = {
  removeSavedRole: (id: string, org: string) => Promise<void>;
  toggleSaveTeam: (id: string) => Promise<void>;
};

type DeleteEntityModalProps = {
  isOpen: boolean;
};

function DeleteEntityModal({
  removeSavedRole,
  toggleSaveTeam,
  isOpen,
}: MobxProps & DeleteEntityModalProps) {
  const dispatch = useDispatch();
  const entitiesById = useSelector((state: ReduxState) => state.entities.entitiesById);
  const savedRoleIds = useSelector((state: ReduxState) => state.roles.savedRoleIds);
  const savedTeamIds = useSelector((state: ReduxState) => state.teams.savedTeamIds);
  const selectedOrgId = useSelector((state: ReduxState) => state.collab.selectedOrgId);
  const selectedEntity = useSelector((state: ReduxState) => state.entities.selectedEntity);
  const tagsById = useSelector((state: ReduxState) => state.tags.tagsById);
  const entityType = selectedEntity?.$entityType === 'role' ? 'Role' : 'Team';

  const closeModal = () => {
    dispatch(setModal(undefined));
  };

  const performDelete = async () => {
    if (!selectedEntity?.id) return;
    const id = selectedEntity?.id;
    const savedIds = entityType === 'Role' ? savedRoleIds : savedTeamIds;

    if (savedIds.includes(id)) {
      entityType === 'Role' ? await removeSavedRole(id, selectedOrgId) : await toggleSaveTeam(id);
    }

    await deleteEntity(dispatch, {
      entityType: selectedEntity.$entityType || '',
      organizationId: selectedOrgId,
      entitiesById,
      selectedEntity,
      tagsById,
    });

    closeModal();
  };

  return (
    <BasicModal
      ariaLabelBody={`Delete ${entityType} Info`}
      ariaLabelCancelButton={`Delete ${entityType} Cancel`}
      ariaLabelCloseButton={`Delete ${entityType} Close`}
      ariaLabelHeader={`Delete ${entityType} Header`}
      ariaLabelSubmitButton={`Confirm Delete ${entityType}`}
      hasCloseButton={true}
      headerText={`Are you sure you want to delete this ${entityType.toLocaleLowerCase()}?`}
      headerTextStyle={'small-text'}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={performDelete}
      size={'medium'}
      theme={'danger'}
      submitText={'DELETE'}
      useWCL
    >
      <div className={classes('')}>
        <div className={classes('modal-role-body')}>
          {selectedEntity && (
            <EntitiesItem entity={selectedEntity} id={selectedEntity.id} showTag />
          )}
        </div>
      </div>
    </BasicModal>
  );
}

export default mobxInjectSelect<DeleteEntityModalProps, MobxProps>({
  roleStore: ['removeSavedRole'],
  teamStore: ['toggleSaveTeam'],
})(DeleteEntityModal);
