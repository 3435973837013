import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Select from 'react-select-plus';
import styles from './MultiSelect.module.css';

export type MultiSelectOption<Value extends string = string> = {
  label: string;
  value: Value;
};

export type MultiSelectProps<Value extends string = string> = {
  dataTestId?: string;
  disabled?: boolean;
  onChange?: (selectedOption: Value[]) => void;
  options: MultiSelectOption[];
  placeholder?: string;
  value?: Value[];
};

export const MultiSelect = <Value extends string = string>({
  dataTestId,
  disabled,
  onChange,
  options = [] as MultiSelectOption<Value>[],
  placeholder = 'Select your options...',
  value,
}: MultiSelectProps<Value>) => {
  const selectRef = useRef<{ input?: { input?: HTMLInputElement } }>();
  const [selectValue, setSelectValue] = useState<Value[]>(value || []);

  useEffect(() => {
    if (!dataTestId) return;
    selectRef.current?.input?.input?.setAttribute('data-test-id', dataTestId);
  }, [dataTestId]);

  const onValueChange = (options: MultiSelectOption[]) => {
    setSelectValue(options.map((o) => o.value as Value));
    onChange && onChange(options.map((o) => o.value as Value));
  };

  return (
    <Select
      className={clsx(styles.multiSelect, { [styles.disabled]: disabled })}
      disabled={disabled}
      escapeClearsValue={false}
      menuStyle={{
        overflow: 'scroll',
        height: 200,
      }}
      multi={true}
      onChange={onValueChange}
      openOnClick={true}
      options={options.map((o) => ({ ...o, className: styles.multiSelectOption }))}
      placeholder={placeholder}
      ref={selectRef}
      searchable={true}
      value={selectValue}
    />
  );
};
