import React, { useEffect, useRef, useState } from 'react';

import TCClient from '../../../../client';
import { DotsIndicator } from '../../../../common/components';
import Modal from '../../../../common/components/Modal';

import styles from './DeleteLocationModal.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => Promise<void>;
  unit: { unitId: string; unitName: string };
  room: { parentAreaName: string; roomName: string; roomId: string };
};

export const RemoveFromUnitModal = ({ isOpen = true, onClose, onRemove, unit, room }: Props) => {
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);
  const dispatch = useAppDispatch();
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const [buttonHasFocus, setButtonHasFocus] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleMouseEnter = () => setButtonHasFocus(true);
  const handleMouseLeave = () => setButtonHasFocus(false);

  useEffect(() => {
    let ref: HTMLButtonElement | null = null;

    buttonRef.current?.addEventListener('mouseenter', handleMouseEnter);
    buttonRef.current?.addEventListener('mouseleave', handleMouseLeave);

    ref = buttonRef.current;

    return () => {
      if (ref) {
        ref.removeEventListener('mouseenter', handleMouseEnter);
        ref.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  });

  const close = () => {
    setError('');
    onClose();
  };

  return (
    <Modal
      bodyClass={styles.body}
      headerClass={styles.header}
      header={`Remove from Unit`}
      isOpen={isOpen}
      size={'medium'}
      onRequestClose={close}
      closeClass={styles.close}
    >
      <>
        <div className={styles.errorContainer}>
          {error.length > 0 && <div className={styles.error}>{error}</div>}
        </div>
        <div className={styles.bodyText}>
          <p>
            Are you sure you want to remove{' '}
            <span className={styles.bold}>
              {room.parentAreaName}: {room.roomName}{' '}
            </span>{' '}
            from the <span className={styles.bold}>{unit.unitName}</span> unit?
          </p>
        </div>
        <div className={styles.footer}>
          <div className={styles.footerLeft}></div>
          <div className={styles.footerRight}>
            <button
              aria-label="Cancel"
              className={styles.cancelButton}
              onClick={close}
              type="button"
            >
              Cancel
            </button>
            <button
              ref={buttonRef}
              aria-label="Delete"
              className={styles.saveButton}
              onClick={async () => {
                setIsSaving(true);

                const { rooms } = await TCClient.multiOrg.getUnit({
                  orgId: selectedOrganizationId,
                  unitId: unit.unitId,
                });
                const roomIds = rooms.filter((r) => r.roomId !== room.roomId).map((r) => r.roomId);
                await TCClient.multiOrg.updateUnit({
                  orgId: selectedOrganizationId,
                  roomIds,
                  unitId: unit.unitId,
                  unitName: unit.unitName,
                });

                onRemove();
                dispatch(actions.setIsUnitsDataDirty({ isUnitsDataDirty: true }));
                setIsSaving(false);
              }}
              type="button"
            >
              {isSaving && (
                <DotsIndicator color={buttonHasFocus ? '#fff' : '#db524b'} size={10} speed={0.5} />
              )}
              {!isSaving && 'Remove'}
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};
