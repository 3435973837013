import { useState, useEffect, useRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import mobxInjectSelect from '../../../../common/utils/mobxInjectSelect';
import { User, PBXLinesList, PBXTab, Role } from '../../../../types';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';

import { Checkbox } from '../../../shared-components/Checkbox/Checkbox';
import AssigneeSearchBox from '../../../../common/components/AssigneeSearchBox';
import { EntityAvatar } from '../../../../common/components';
import styles from '../OrganizationSettings.module.css';

type MobxProps = {
  findUser: (targetId: string, organizationId: string) => User;
  findRole: (targetId: string, organizationId: string) => Role;
  openModal: (name: string, options: unknown) => void;
};

type UserRoleAssigneeRowProps = {
  category: PBXTab;
  organizationId: string;
  pbxLinesList: PBXLinesList[];
  result: PBXLinesList;
  selected: string[];
  setSelected: (selected: string[]) => void;
  setPbxLinesList: (pbxLinesList: PBXLinesList[]) => void;
  targetId: string | null;
  toggleSelected: (id: string) => void;
};

function UserRoleAssigneeRow({
  category,
  findRole,
  findUser,
  openModal,
  organizationId,
  pbxLinesList,
  result,
  selected,
  setPbxLinesList,
  setSelected,
  targetId,
  toggleSelected,
}: UserRoleAssigneeRowProps & MobxProps) {
  const { id, targetType, customSettings } = result;
  const { extensionNumber } = customSettings;
  const [pbxAssignee, setPbxAssignee] = useState(targetId);
  const [isAssigneeSearchBoxOpen, setIsAssigneeSearchBoxOpen] = useState(false);
  const [userAssigned, setUserAssigned] = useState<User | Role>();
  const [containerHasFocus, setContainerHasFocus] = useState(false);
  const isUserAssignee = category === 'User Lines' || 'Nurse Call Users';
  const isCategoryNurseCall = category !== 'User Lines' && category !== 'Roles Lines';

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseEnter = () => setContainerHasFocus(true);

    const handleMouseLeave = () => setContainerHasFocus(false);

    let ref: HTMLDivElement | null = null;

    containerRef.current?.addEventListener('mouseenter', handleMouseEnter);
    containerRef.current?.addEventListener('mouseleave', handleMouseLeave);

    ref = containerRef.current;

    return () => {
      if (ref) {
        ref.removeEventListener('mouseenter', handleMouseEnter);
        ref.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    setPbxAssignee(targetId);
    setContainerHasFocus(false);
  }, [targetId]);

  useEffect(() => {
    if (targetId) {
      const fetchUser = async () => {
        const assignedUser = isUserAssignee
          ? await findUser(targetId, organizationId)
          : await findRole(targetId, organizationId);
        if (!assignedUser) {
          setPbxAssignee(null);
        } else {
          setUserAssigned(assignedUser);
        }
      };
      fetchUser();
    } else {
      setUserAssigned(undefined);
    }
  }, [organizationId, findUser, targetId, findRole, isUserAssignee]);

  const unassignSelected = (id: string) => {
    openModal('removeRoleOrUserLine', {
      currentOrganizationId: organizationId,
      lineType: category,
      pbxLinesList,
      selectedLines: [id],
      setPbxLinesList,
      setSelected,
    });
  };

  return (
    <div ref={containerRef}>
      <div className={classNames(selected.includes(id) ? styles.selectedRow : styles.row)}>
        <div className={styles.inputBoxStyle}>
          <div className={styles.checkbox}>
            {((isCategoryNurseCall && targetId) || pbxAssignee) && (
              <Checkbox onClick={() => toggleSelected(id)} checked={selected.includes(id)} />
            )}
          </div>
          {!isCategoryNurseCall && extensionNumber}
          {isCategoryNurseCall && userAssigned && (
            <div className={styles.flexListStyle}>
              <EntityAvatar
                entity={userAssigned}
                indicatorSize={'SMALL'}
                showPresenceIndicator={true}
                size={25}
              />
              <div className={styles.ncAssigneeDetails}>{userAssigned?.displayName}</div>
            </div>
          )}
        </div>
        {!isCategoryNurseCall && (
          <div>
            <div>
              {!isAssigneeSearchBoxOpen && (
                <div
                  className={
                    pbxAssignee === null ? styles.usersOrRolesUnassigned : styles.assignedUserOrRole
                  }
                  onClick={() => {
                    if (pbxAssignee === null) setIsAssigneeSearchBoxOpen(true);
                    if (userAssigned && containerHasFocus) unassignSelected(id);
                  }}
                >
                  {pbxAssignee === null ? (
                    <ToolTip text={`Assign a ${targetType}`} textAlign={'center'}>
                      <div>Unassigned</div>
                    </ToolTip>
                  ) : (
                    userAssigned && (
                      <div>
                        {!containerHasFocus && (
                          <div className={styles.assigneeDetails}>
                            <EntityAvatar
                              entity={userAssigned}
                              indicatorSize={'TINY'}
                              showPresenceIndicator={true}
                              size={25}
                              className={styles.assigneeImg}
                            />
                            <div className={styles.assigneeDisplayName}>
                              {userAssigned && userAssigned.displayName}
                            </div>
                          </div>
                        )}
                        {containerHasFocus && (
                          <div className={styles.removeUserOrRole}>Remove {targetType}</div>
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
              {isAssigneeSearchBoxOpen && (
                <div>
                  <OutsideClickHandler onOutsideClick={() => setIsAssigneeSearchBoxOpen(false)}>
                    <AssigneeSearchBox
                      category={category}
                      setIsAssigneeSearchBoxOpen={setIsAssigneeSearchBoxOpen}
                      id={id}
                      organizationId={organizationId}
                      targetType={targetType}
                      pbxLinesList={pbxLinesList}
                      setPbxLinesList={setPbxLinesList}
                      setPbxAssignee={setPbxAssignee}
                    />
                  </OutsideClickHandler>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default mobxInjectSelect<UserRoleAssigneeRowProps, MobxProps>({
  userStore: ['findUser'],
  roleStore: ['findRole'],
  modalStore: ['openModal'],
})(UserRoleAssigneeRow);
