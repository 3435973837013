import React, {
  useEffect,
  useState,
  PropsWithChildren,
  RefObject,
  ReactElement,
  useRef,
} from 'react';
import classNames from 'classnames';

import { ActionBarTabButton } from '../ActionBarTabButton/ActionBarTabButton';
import { Input } from '../Input/Input';

import styles from './ActionBar.module.css';
import { useAppSelector } from 'redux-stores';

export type TabButton<T extends string = string> = {
  label: T;
  onClick?: () => void;
};

export type ActionBarProps<T extends string = string> = {
  /**
   * Search input function
   */
  hasSearch?: boolean;

  /**
   * Array of tab buttons
   */
  tabButtons?: TabButton<T>[];

  /**
   * Index of currently selected tab
   */
  selectedTabIndex?: number;

  /**
   * Function that control which button is selected
   */
  setSelectedTabIndex?: (tabButton: TabButton<T>, index: number) => void;

  /**
   * Optional callback for when user types into text field
   */
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Value for Input
   */
  searchValue?: string;
  handleClearClick?: () => void;
  dataTestId?: string;
  customStyles?: Record<string, string>;
};

export const ActionBar = <
  T extends string = string,
  R extends RefObject<HTMLDivElement> = RefObject<HTMLDivElement>
>({
  hasSearch = false,
  tabButtons = [],
  selectedTabIndex,
  setSelectedTabIndex,
  onInputChange = () => {},
  searchValue = '',
  handleClearClick = () => {},
  dataTestId,
  forwardRef,
  customStyles = {},
}: PropsWithChildren<ActionBarProps<T>> & { forwardRef?: R }): ReactElement | null => {
  const viewToShow = useAppSelector((state) => state.admin.viewToShow);
  const [selectedTab, setSelectedTab] = useState<number>();
  const searchInput = useRef<HTMLInputElement>(null);
  const currentStyles = { ...styles, ...customStyles };

  useEffect(() => {
    if (setSelectedTabIndex && selectedTab !== selectedTabIndex) {
      setSelectedTab(selectedTabIndex);
    }
  }, [selectedTab, selectedTabIndex, setSelectedTab, setSelectedTabIndex]);

  return (
    <div className={classNames(currentStyles.actionBar)} ref={forwardRef}>
      <div className={classNames(currentStyles.barBG)}>
        {tabButtons.length > 0 && (
          <div className={classNames(currentStyles.tabButtonBar)}>
            {tabButtons.map((tabButton, index) => {
              return (
                <ActionBarTabButton
                  key={index}
                  label={tabButton.label}
                  isSelected={viewToShow !== '' && selectedTab === index}
                  onClick={() => {
                    if (index === selectedTab) {
                      setSelectedTab(undefined);
                    } else {
                      setSelectedTab(index);
                    }
                    setSelectedTabIndex?.(tabButton, index);
                  }}
                  customStyles={currentStyles}
                />
              );
            })}
          </div>
        )}
      </div>
      {hasSearch && (
        <div className={classNames(currentStyles.barInput)}>
          <Input
            shouldFocus={true}
            inputRef={searchInput}
            onInputChange={onInputChange}
            value={searchValue}
            hasClearButton={true}
            onClearClick={handleClearClick}
            dataTestId={dataTestId}
          />
        </div>
      )}
    </div>
  );
};
