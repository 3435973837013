import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import propTypes from '../../propTypes';
import BEM from '../../bem';

import AttachmentFileHeader from './AttachmentFileHeader';

const classes = BEM.with('DocumentAttachment');

class DocumentAttachment extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    attachment: propTypes.messageAttachment.isRequired,
    isMultiSelectMode: PropTypes.bool.isRequired,
    messageId: PropTypes.string.isRequired,
    multiSelectable: PropTypes.bool.isRequired,
  };

  render() {
    const { action, attachment, isMultiSelectMode, messageId, multiSelectable } = this.props;

    return (
      <div
        onClick={isMultiSelectMode ? () => {} : action}
        data-test-id={attachment.name}
        aria-label={`document-download-${attachment.name}`}
        className={classes({
          type: 'document',
          unselectable: isMultiSelectMode && !multiSelectable,
        })}
      >
        <AttachmentFileHeader messageId={messageId} attachment={attachment} />
      </div>
    );
  }
}

export default observer(DocumentAttachment);
