export default class NotSupportedError extends Error {
  static CODE = 'not-supported';

  code: string;

  constructor(message: string) {
    super(message);
    this.code = NotSupportedError.CODE;
  }
}
