export const insertTextAtSelection = (element: HTMLTextAreaElement, textToInsert: string) => {
  const { scrollTop, selectionEnd, selectionStart, value } = element;

  const scrollTopCopy = scrollTop;
  const beginning = value.substring(0, selectionStart);
  const end = value.substring(selectionEnd, value.length);

  if (beginning.length && !/\s$/.test(beginning)) {
    textToInsert = ' ' + textToInsert;
  }
  if (!/^\s/.test(end)) {
    textToInsert += ' ';
  }

  element.value = beginning + textToInsert + end;
  element.selectionStart = element.selectionEnd = selectionStart + textToInsert.length;
  element.focus();
  element.scrollTop = scrollTopCopy;
};
