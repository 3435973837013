import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { once } from 'ramda';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { Modal } from './';

const classes = BEM.with('DeleteConversationModal');

class MessageRecallModal extends Component {
  static propTypes = {
    hideRecallModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    options: PropTypes.shape({
      messages: PropTypes.object.isRequired,
    }),
    recallMessages: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        ariaLabelCloseButton="Recall Message Close"
        ariaLabelHeader="Recall Message Header"
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('close-button')}
        headerClass={classes('header')}
        header="Recall Messages"
        isOpen={isOpen}
        onRequestClose={this._onRequestClose}
        size={'medium-small'}
        footerPrimaryActions={
          <button
            aria-label="Recall Message Recall"
            className={classes('delete-btn')}
            onClick={once(this._recallMessage)}
            type="button"
          >
            RECALL
          </button>
        }
        footerSecondaryActions={
          <button
            aria-label="Delete Message Cancel"
            className={classes('cancel-btn')}
            onClick={this._onRequestClose}
            type="button"
          >
            CANCEL
          </button>
        }
      >
        <div className={classes('info')}>
          Messages will be permanently removed from all devices and browsers.
        </div>
      </Modal>
    );
  }

  _recallMessage = async () => {
    const {
      recallMessages,
      options: { messages },
    } = this.props;
    recallMessages(Object.values(messages));
    this._onRequestFinish();
  };

  _onRequestFinish = () => {
    const { hideMessageMultiSelect } = this.props;
    this._onRequestClose();
    hideMessageMultiSelect();
  };

  _onRequestClose = () => {
    const { closeModal } = this.props;
    closeModal();
  };
}

export default mobxInjectSelect({
  messageStore: ['recallMessages'],
  messengerStore: ['hideMessageMultiSelect'],
})(MessageRecallModal);
