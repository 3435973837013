import React, { useState } from 'react';
import { Card, Switch } from '../WebComponents';
import BEM from '../../bem';
import mobxInjectSelect from '../../utils/mobxInjectSelect';

const classes = BEM.with('DesktopAppSettings');

type CallingPreferencesSettingsProps = {};

type MobxProps = {
  currentUser: {
    incomingCallSound: boolean;
    incomingCallNotification: boolean;
  };
  isCallingNotificationsAllowed: boolean;
  setScrollbarsElement: (ref: HTMLDivElement | null, element: string) => void;
  update: (opts: unknown) => Promise<{
    incomingCallSound: boolean;
    incomingCallNotification: boolean;
  }>;
};

function CallingPreferencesSettings({
  currentUser,
  isCallingNotificationsAllowed,
  setScrollbarsElement,
  update,
}: MobxProps) {
  const [user, setUser] = useState(currentUser);
  const { incomingCallSound, incomingCallNotification } = user || {};
  const _setScrollbarsElement = (ref: HTMLDivElement | null) => {
    setScrollbarsElement(ref, 'CallingPreferences');
  };

  const toggleCallAlert = async () => {
    const tempUser = await update({
      incomingCallSound: !incomingCallSound,
    });
    if (tempUser) setUser({ ...tempUser });
  };

  const toggleNotification = async () => {
    const tempUser = await update({
      incomingCallNotification: !incomingCallNotification,
    });
    if (tempUser) setUser({ ...tempUser });
  };

  return (
    <div className={classes()} ref={_setScrollbarsElement}>
      <Card header="CALLING PREFERENCES">
        <div className={classes('info')}>
          <div className={classes('info-header')}>INCOMING CALL ALERT SOUND</div>
          <div className={classes('switch')} onClick={toggleCallAlert}>
            <Switch disabled={false} checked={!!incomingCallSound} dataTestId="callAlert" />
          </div>
        </div>
        {isCallingNotificationsAllowed && (
          <div className={classes('info')}>
            <div className={classes('info-header')}>INCOMING CALL NOTIFICATION</div>
            <div className={classes('switch')} onClick={toggleNotification}>
              <Switch
                disabled={false}
                checked={!!incomingCallNotification}
                dataTestId="callNotification"
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}

export default mobxInjectSelect<CallingPreferencesSettingsProps, MobxProps>({
  callStore: ['isCallingNotificationsAllowed'],
  profileStore: ['setScrollbarsElement'],
  sessionStore: ['currentUser'],
  userStore: ['update'],
})(CallingPreferencesSettings);
