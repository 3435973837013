import React from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import { ReactComponent as MemberAdded } from '../../../images/green-checkmark.svg';
import { ReactComponent as MembersToBeAdded } from '../../../images/check-add-members.svg';
import { AvatarImage, SmsOptedOut } from '../../';
import { AddContactsFromList } from './';

const classes = BEM.with('AddPatientsFromList');

const AddPatientsFromList = ({
  currentMembersMap,
  isFirstEntry,
  patientDetails,
  pendingMemberIds,
  toggle,
}) => {
  if (!patientDetails.patient) return <></>;
  const smsOptedOut = patientDetails.patient.smsOptedOut;
  const disabled = smsOptedOut || currentMembersMap[patientDetails.id];
  const contacts = patientDetails.patient.contacts;
  const hasContacts = contacts.length > 0;
  const isSelected =
    !smsOptedOut &&
    (disabled || pendingMemberIds.some((pendingMemberId) => pendingMemberId === patientDetails.id));
  const patientDob = patientDetails.patient.dob;
  const patientGender = patientDetails.patient.gender;
  const patientMrn = patientDetails.patient.mrn;
  const patientName = patientDetails.displayName;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      !disabled && toggle(patientDetails);
    }
  };

  return (
    <div>
      <div
        className={classes({ disabled, smsOptedOut })}
        onClick={() => !disabled && toggle(patientDetails)}
        onKeyDown={handleKeyDown}
      >
        <div className={classes('wrapper', { isFirstEntry })} onKeyDown={handleKeyDown}>
          <div className={classes('patient-svg')}>
            <AvatarImage
              className={classes('patient-svg', { smsOptedOut })}
              displayName={patientName}
              entityType={smsOptedOut ? 'singleProviderSmsOptedOut' : 'singleProvider'}
              forceDefault
              size={24}
            />
          </div>
          <div className={classes('patient-info')}>
            <div className={classes('patient-name')}>{patientName}</div>
            <div className={classes('patient-details')}>
              <div className={classes('patient-mrn')} title={patientMrn}>
                MRN {patientMrn}
              </div>
              <div className={classes('patient-dob')}> | DOB {patientDob}</div>
              <div className={classes('patient-gender')}> | {patientGender}</div>
            </div>
            {smsOptedOut && <SmsOptedOut tooltip />}
          </div>
          <div className={classes('check-mark', { isSelected })}>
            {isSelected ? <MemberAdded /> : <MembersToBeAdded />}
          </div>
        </div>
      </div>
      <div>
        {hasContacts &&
          contacts.map((contactDetail) => (
            <AddContactsFromList
              disabled={contactDetail.smsOptedOut || !!currentMembersMap[contactDetail.user.id]}
              contactDetails={contactDetail}
              key={contactDetail.id}
              toggle={() => toggle(contactDetail.user)}
              pendingMemberIds={pendingMemberIds}
            />
          ))}
      </div>
    </div>
  );
};

AddPatientsFromList.propTypes = {
  currentMembersMap: PropTypes.object.isRequired,
  isFirstEntry: PropTypes.bool,
  patientDetails: PropTypes.object.isRequired,
  pendingMemberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggle: PropTypes.func.isRequired,
};

AddPatientsFromList.defaultProps = {
  isFirstEntry: false,
};

export default AddPatientsFromList;
