import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckmarkSvg } from '../images/checkmark.svg';

import BEM from '../bem';
import { Modal } from './';

const classes = BEM.with('SuccessModal');

class SuccessModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Modal
        className={classes('success-modal')}
        hasCloseButton={false}
        headerClass={classes('header')}
        isOpen={this.props.isOpen}
        onAfterOpen={this._autoCloseModal}
        overlayClassName={classes()}
      >
        <CheckmarkSvg className={classes('image')} data-test-id={'success-modal-checkmark'} />
      </Modal>
    );
  }

  _autoCloseModal = () => {
    setTimeout(() => {
      this.props.closeModal('success');
    }, 1000);
  };
}

export default SuccessModal;
