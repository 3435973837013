import React from 'react';
import BEM from '../bem';
import { ReactComponent as PhoneIcon } from '../../video-call/images/provider-call-outline.svg';
import { ReactComponent as VideoIcon } from '../../video-call/images/provider-video-outline.svg';

const classes = BEM.with('CallChangeLog');

export default function CallChangeBang({
  body,
  callAgain,
  isCaller,
  isGroup,
  isGroupVideoCallEnabled,
  isGroupVoiceCallEnabled,
  isPFGroupVideoCallEnabled,
  isPFGroupVoiceCallEnabled,
  isProviderNetwork,
  isVideoCall,
}) {
  let parsedBody = body.split(' ');
  let index = isGroup && !isCaller ? parsedBody.indexOf('from') : parsedBody.indexOf('at');
  const headerText = parsedBody.slice(0, index).join(' ');
  parsedBody = parsedBody.slice(index);

  let callAgainFragment, groupText;
  if (isGroup) {
    index = parsedBody.indexOf('at');
    groupText = parsedBody.slice(0, index).join(' ');
    parsedBody = parsedBody.slice(index);
  }
  if (
    isGroup &&
    ((isProviderNetwork &&
      ((isVideoCall && !isGroupVideoCallEnabled) || (!isVideoCall && !isGroupVoiceCallEnabled))) ||
      (!isProviderNetwork &&
        ((isVideoCall && !isPFGroupVideoCallEnabled) ||
          (!isVideoCall && !isPFGroupVoiceCallEnabled))))
  ) {
    callAgainFragment = (
      <div className={classes('call-again-container')}>
        <div className={classes('permissions-text')} aria-label="call-again-button">
          Group calling permissions required
        </div>
      </div>
    );
  } else {
    callAgainFragment = (
      <div className={classes('call-again-container')}>
        <button className={classes('call-again')} onClick={callAgain}>
          Call Again
        </button>
      </div>
    );
  }
  const timeText = parsedBody.join(' ');

  return (
    <div className={classes()}>
      <div className={classes('missed-call')}>
        <div className={classes('header')}>
          {isVideoCall ? (
            <VideoIcon className={classes('icon')} />
          ) : (
            <PhoneIcon className={classes('icon')} />
          )}
          <div className={classes('bang-text')}>{headerText}</div>
        </div>
        <div className={classes('supporting-text')}>
          <div className={classes('bang-text')}>{groupText}</div>
          <div className={classes('bang-text')}>{timeText}</div>
        </div>
        {callAgainFragment}
      </div>
    </div>
  );
}
