import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import iconFileAudio from '../../widgets/messenger/images/iconFileAudio.png';
import iconFileDocs from '../../widgets/messenger/images/iconFileDocs.png';
import iconFilePdf from '../../widgets/messenger/images/iconFilePdf.png';
import iconFileSheets from '../../widgets/messenger/images/iconFileSheets.png';
import iconFileSlides from '../../widgets/messenger/images/iconFileSlides.png';
import iconGeneral from '../../widgets/messenger/images/iconGeneral.png';

class AttachmentIcon extends Component {
  static propTypes = {
    fileType: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  render() {
    const { fileType, className } = this.props;
    let icon;

    if (
      fileType.includes('text/plain') ||
      fileType.includes('application/msword') ||
      fileType.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')
    ) {
      icon = iconFileDocs;
    } else if (
      fileType.includes('text/csv') ||
      fileType.includes('application/vnd.ms-excel') ||
      fileType.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    ) {
      icon = iconFileSheets;
    } else if (
      fileType.includes('application/vnd.ms-powerpoint') ||
      fileType.includes('application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
      fileType.includes('vnd.openxmlformats-officedocument.presentationml.presentation')
    ) {
      icon = iconFileSlides;
    } else if (fileType.includes('application/pdf')) {
      icon = iconFilePdf;
    } else if (fileType.includes('audio/')) {
      icon = iconFileAudio;
    } else {
      icon = iconGeneral;
    }

    return <img src={icon} className={className} alt={fileType} />;
  }
}

export default observer(AttachmentIcon);
