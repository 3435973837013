import React from 'react';
import { BasicModal } from '../../components/index';

type CalleeDndEnabledModalType = {
  closeModal: () => void | Promise<void>;
  isOpen: boolean;
};

export default function CalleeDndEnabledModal({ closeModal, isOpen }: CalleeDndEnabledModalType) {
  return (
    <BasicModal
      ariaLabelBody={'Callee Dnd Enabled Error Info'}
      ariaLabelCancelButton={'Callee Dnd Enabled Error Cancel'}
      ariaLabelCloseButton={'Callee Dnd Enabled Error Close'}
      ariaLabelHeader={'Callee Dnd Enabled Error Header'}
      ariaLabelSubmitButton={'Callee Dnd Enabled Confirm Error'}
      bodyText={'The user called is currently set to DND.'}
      hasCloseButton={true}
      headerText="Error"
      hideCancel={true}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={closeModal}
      size={'medium'}
      submitText={'OK'}
      theme={'danger'}
      useWCL={true}
    ></BasicModal>
  );
}
