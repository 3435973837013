import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import BEM from '../../bem';
import { ReactComponent as CheckSvg } from '../../images/selected-check.svg';
import { generateValidTimes, scheduledMessageFormats } from '../../utils';
import { MenuItemList, MenuItem } from '../ContextMenu';

const { TIME_FORMAT } = scheduledMessageFormats;
const TIME_INTERVAL = 15;

const classes = BEM.with('TimeContextMenu');

class TimeContextMenu extends Component {
  static propTypes = {
    onTimeSelected: PropTypes.func.isRequired,
    selectedStartDate: momentPropTypes.momentObj,
    selectedTime: momentPropTypes.momentObj,
  };

  render() {
    const { onTimeSelected, selectedStartDate, selectedTime, timezone } = this.props;

    return (
      <MenuItemList className={classes()}>
        {generateValidTimes(selectedStartDate, TIME_INTERVAL, timezone).map((time) => {
          const formattedTime = time.format(TIME_FORMAT);
          const isSelected = !!(selectedTime && formattedTime === selectedTime.format(TIME_FORMAT));

          return (
            <MenuItem
              className={classes('menu-item', {
                selected: isSelected,
              })}
              onClick={() => onTimeSelected(time)}
              key={formattedTime}
            >
              <p>{formattedTime}</p>
              {isSelected && <CheckSvg className={classes('check')} />}
            </MenuItem>
          );
        })}
      </MenuItemList>
    );
  }
}

export default observer(TimeContextMenu);
