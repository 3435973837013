import React from 'react';
import { PageHeader } from '../../../shared-components/PageHeader/PageHeader';
import { ReactComponent as RefreshIcon } from '../../../../common/images/refresh.svg';
import { LocationsTabs } from './LocationsTabs';
import styles from './Locations.module.css';
import { CreateNewFacility } from './CreateNewFacility';
import { DirectoryContainer } from './DirectoryContainer';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

export default function Locations() {
  const selectedLocationButton = useAppSelector((state) => state.multiOrg.selectedLocationButton);

  const dispatch = useAppDispatch();

  const onRefresh = (tabLabel: string) => {
    if (tabLabel === 'Locations') {
      dispatch(actions.setLocationsDataDirty({ isLocationsDataDirty: true }));
    }
    if (tabLabel === 'Units') {
      dispatch(actions.setIsUnitsDataDirty({ isUnitsDataDirty: true }));
    }
  };

  return (
    <div className={styles.location}>
      <div className={styles.locationHeader}>
        <div className={styles.locationHeaderTextAndRefresh}>
          <PageHeader label={'Location Directory'} />
          <RefreshIcon
            className={styles.refreshIcon}
            onClick={() => onRefresh(selectedLocationButton)}
          />
        </div>
        {selectedLocationButton === 'Locations' && <CreateNewFacility />}
      </div>
      <LocationsTabs />
      <DirectoryContainer />
    </div>
  );
}
