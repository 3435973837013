import { Action } from './actions';
import { AppState } from './context';

const reducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'VWR_SET_NEW_VISITS_COUNT':
      return {
        ...state,
        virtualWaitingRoom: { ...state.virtualWaitingRoom, newVisitorCounts: action.payload },
      };
    case 'VWR_INCREASE_NEW_VISITS_COUNT': {
      const roomId = action.payload;
      const { virtualWaitingRoom } = state;
      const { newVisitorCounts } = virtualWaitingRoom;

      const previousTotalCount = newVisitorCounts.total;
      const previousRoomCount = newVisitorCounts[roomId] || 0;

      return {
        ...state,
        virtualWaitingRoom: {
          ...virtualWaitingRoom,
          newVisitorCounts: {
            ...newVisitorCounts,
            [roomId]: previousRoomCount + 1,
            total: previousTotalCount + 1,
          },
        },
      };
    }
    case 'VWR_DECREASE_NEW_VISITS_COUNT': {
      const roomId = action.payload;
      const { virtualWaitingRoom } = state;
      const { newVisitorCounts } = virtualWaitingRoom;

      const previousTotalCount = newVisitorCounts.total;
      const previousRoomCount = newVisitorCounts[roomId] || 0;

      return {
        ...state,
        virtualWaitingRoom: {
          ...virtualWaitingRoom,
          newVisitorCounts: {
            ...newVisitorCounts,
            [roomId]: previousRoomCount - 1,
            total: previousTotalCount - 1,
          },
        },
      };
    }
    default:
      return ((_: never): never => {
        throw new Error('Reducer is not exhaustive');
      })(action);
  }
};

export default reducer;
