import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import BEM from '../../../bem';
import { ReactComponent as CheckMarkSvg } from '../../../images/green-checkmark-full.svg';
import { ReactComponent as CloseButtonSvg } from '../../../images/close-icon.svg';
import propTypes from '../../../propTypes';
import scheduledMessageFormats from '../../../utils/scheduledMessageFormats';
import { AvatarImage, SmsOptedOut } from '../../';
import { triggerButtonActionOnKeyPress } from 'common/utils/accessibility';

const classes = BEM.with('PatientBroadcastListRowDetails');
const { DATE_FORMAT } = scheduledMessageFormats;

const PatientBroadcastListRowDetails = ({
  isFirstEntry,
  isPending,
  isSelected,
  isSingle,
  member,
  removePendingMember,
  toggleSelectedMember,
}) => {
  const {
    displayName,
    isPatient,
    patient,
    patientBroadcastListDetails = {},
    patientContact,
  } = member;
  const { addedByDisplayName, addedOn } = patientBroadcastListDetails;
  const hideBorder = isFirstEntry || isSingle;
  const dob = isPatient ? patient.dob : '';
  const gender = isPatient ? patient.gender : '';
  const mrn = isPatient ? patient.mrn : '';
  const smsOptedOut = isPatient ? patient.smsOptedOut : patientContact.smsOptedOut;
  let displayNameText = displayName;
  let memberDetailsFragment;

  if (isPatient) {
    memberDetailsFragment = (
      <div className={classes('member-name', { hideBorder })} title={displayName}>
        {displayName}
      </div>
    );
  } else {
    const relationText = ` (${patientContact.relation})`;

    if (!isSingle) {
      displayNameText += relationText;
      memberDetailsFragment = (
        <div className={classes('member-name', { hideBorder })} title={displayNameText}>
          {displayName}
          <span className={classes('grouped-contact-relation')}>{relationText}</span>
        </div>
      );
    } else {
      const patientDetailsText = `MRN ${patient.mrn} | DOB ${patient.dob} | ${patient.gender}`;

      memberDetailsFragment = (
        <React.Fragment>
          <div className={classes('member-name', { hideBorder })} title={displayNameText}>
            {displayName}
            <span className={classes('grouped-contact-relation')}>{relationText}</span>
          </div>
          <div className={classes('patient-name')}>{patient.user.displayName}</div>
          <div className={classes('patient-details')} title={patientDetailsText}>
            {patientDetailsText}
          </div>
        </React.Fragment>
      );
    }
  }

  const dateAdded = moment(addedOn).format(DATE_FORMAT);
  const toggleMemberSelection = () => {
    toggleSelectedMember && toggleSelectedMember(member.id);
  };
  return (
    <div
      className={classes({ isSelected, selectable: !isPending, smsOptedOut })}
      onClick={toggleMemberSelection}
      onKeyDown={(event) => triggerButtonActionOnKeyPress({ event, action: toggleMemberSelection })}
    >
      <div className={classes('list-item-checkbox')}>
        {!isPending && (
          <div className={classes('list-item-checkbox-default', { isSelected })}>
            {isSelected && <CheckMarkSvg />}
          </div>
        )}
      </div>

      <div className={classes('svg-container')}>
        {isPatient && (
          <AvatarImage
            className={classes('patient-svg', { smsOptedOut })}
            displayName={displayNameText}
            entityType={smsOptedOut ? 'singleProviderSmsOptedOut' : 'singleProvider'}
            forceDefault
            size={24}
          />
        )}
      </div>
      <div className={classes('member-details', { isSingle })}>
        {memberDetailsFragment}
        {smsOptedOut && <SmsOptedOut />}
      </div>
      <div className={classes('patient-mrn', { hideBorder })} title={mrn}>
        {mrn}
      </div>
      <div className={classes('patient-dob', { hideBorder })} title={dob}>
        {dob}
      </div>
      <div className={classes('patient-gender', { hideBorder })}>{gender}</div>
      <div className={classes('added-by', { hideBorder })} title={addedByDisplayName}>
        {addedByDisplayName}
      </div>
      <div className={classes('added-on', { hideBorder })}>
        <span className={classes('added-on-text')} title={dateAdded}>
          {dateAdded}
        </span>
        {isPending && (
          <button
            className={classes('close-button-container')}
            onClick={() => removePendingMember && removePendingMember(member)}
          >
            <CloseButtonSvg className={classes('close-icon-white')} />
          </button>
        )}
      </div>
    </div>
  );
};

PatientBroadcastListRowDetails.propTypes = {
  isFirstEntry: PropTypes.bool,
  isPending: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSingle: PropTypes.bool,
  member: propTypes.user.isRequired,
  removePendingMember: PropTypes.func,
  toggleSelectedMember: PropTypes.func,
};

PatientBroadcastListRowDetails.defaultProps = {
  isFirstEntry: false,
  isPending: false,
  isSelected: false,
  isSingle: false,
};

export default PatientBroadcastListRowDetails;
