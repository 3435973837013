const MessageSendEntityTypes = {
  CONVERSATION: 'conversation',
  DISTRIBUTION_LIST: 'distribution_list',
  GROUP: 'group',
  GROUP_OF_USERS: 'group_of_users',
  PATIENT: 'patient_account',
  ROLE: 'role',
  TEAM: 'team',
  USER: 'user',
};

export default MessageSendEntityTypes;
