import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { once } from 'ramda';
import propTypes from '../../propTypes';
import BEM from '../../bem';
import Modal from '../Modal';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { groupTypeReadableLabel } from '../../utils/readableLabels';

const classes = BEM.with('LeaveGroupModal');

class LeaveGroupModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    group: propTypes.group,
    groupId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    leaveGroup: PropTypes.func.isRequired,
  };

  render() {
    const { closeModal, group, isOpen, ...restProps } = this.props;
    if (!group) return null;
    const { groupType } = group;
    const label = groupTypeReadableLabel(groupType).toLowerCase();
    const verb = groupType === 'ROLE_P2P' ? 'delete' : 'leave';
    let header, notice;

    if (groupType === 'ROLE_P2P') {
      header = 'Delete Conversation';
    } else {
      header = `Are you sure you want to ${verb} this ${label}?`;
    }

    if (groupType === 'GROUP') {
      notice = 'Private groups can only be joined when another member in the group invites you.';
    } else if (groupType === 'FORUM') {
      notice = 'You can always add yourself back in to any forum.';
    } else if (groupType === 'ROLE_P2P') {
      notice = `Are you sure you want to ${verb} this ${label}?`;
    }

    return (
      <Modal
        isOpen={isOpen}
        bodyClass={classes('body')}
        size={'medium'}
        header={header}
        footerPrimaryActions={
          <button type="button" onClick={once(this._leaveGroup)} className={classes('leave-btn')}>
            {verb.toUpperCase()}
          </button>
        }
        footerSecondaryActions={
          <button type="button" onClick={closeModal} className={classes('cancel-btn')}>
            CANCEL
          </button>
        }
        className={classes()}
        onRequestClose={closeModal}
        {...restProps}
      >
        <div className={classes('info')}>{notice}</div>
      </Modal>
    );
  }

  _leaveGroup = () => {
    const { closeModal, group, leaveGroup } = this.props;
    leaveGroup(group);
    closeModal();
  };
}

export default mobxInjectSelect({
  groupStore: ['leaveGroup'],
  groupEditorStore: ['group', 'groupId'],
})(LeaveGroupModal);
