import createMobxModel from './createMobxModel';

const Role = createMobxModel({
  name: 'role',

  fields: ['description', 'displayName'],

  relations: {
    one: {
      botUser: { type: 'user' },
      escalationPolicy: { type: 'escalationPolicy' },
      organization: { type: 'organization' },
      tag: { type: 'tag' },
    },
    many: {
      members: { type: 'user' },
    },
  },
});

export default Role;
