import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchTypes from '../../models/enums/SearchTypes';
import BEM from '../bem';
import propTypes from '../propTypes';
import { FROZEN_EMPTY_ARRAY, mobxInjectSelect } from '../utils';
import { ReactComponent as SearchCancelSvg } from '../images/search-cancel.svg';
import { ReactComponent as SearchSvg } from '../images/search.svg';

const classes = BEM.with('SearchBar');
const excludeIds = FROZEN_EMPTY_ARRAY;
const PLACEHOLDER_LENGTH = 23;

class SearchBar extends Component {
  static propTypes = {
    allowPatientBroadcastLists: PropTypes.bool.isRequired,
    clearSearchResults: PropTypes.func.isRequired,
    currentOrganization: propTypes.organization,
    search: PropTypes.func.isRequired,
  };

  state = {
    cancelHovered: false,
  };

  componentWillUnmount() {
    const { clearSearchResults } = this.props;

    clearSearchResults();
  }

  render() {
    const { clearSearchResults, currentOrganization, input } = this.props;
    const { cancelHovered } = this.state;
    const { displayName = '' } = currentOrganization || {};

    let placeholder = `Search ${displayName}`;
    if (placeholder.length > PLACEHOLDER_LENGTH) {
      placeholder = placeholder.substr(0, PLACEHOLDER_LENGTH) + '...';
    }

    return (
      <div className={classes()}>
        <div className={classes('search-container')}>
          <div className={classes('search-icon-container')}>
            <SearchSvg className={classes('icon', { search: true })} />
          </div>
          <input
            className={classes('input')}
            ref={this._setInput}
            onChange={this._search}
            onKeyDown={(e) => e.key !== 'Escape' && e.stopPropagation()}
            placeholder={placeholder}
            type="text"
            value={input}
            aria-label="search bar"
            autoFocus
          />
          {input.length > 0 && (
            <button
              className={classes('cancel-icon-container', {
                'cancel-hovered': cancelHovered,
              })}
              onClick={() => {
                clearSearchResults();
                this.input.focus();
              }}
              onMouseEnter={() => this.setState({ cancelHovered: true })}
              onMouseLeave={() => this.setState({ cancelHovered: false })}
              aria-label="search bar cancel"
            >
              <SearchCancelSvg className={classes('icon', { cancel: true })} aria-hidden />
            </button>
          )}
        </div>
      </div>
    );
  }

  _setInput = (ref) => {
    this.input = ref;
  };

  _search = (e) => {
    const { allowPatientBroadcastLists, currentOrganization, isProviderNetwork, search } =
      this.props;
    if (!currentOrganization) return;

    let searchTypes;
    if (!isProviderNetwork) {
      searchTypes = [SearchTypes.PATIENT];
      if (allowPatientBroadcastLists) {
        searchTypes.push(SearchTypes.PATIENT_DISTRIBUTION_LIST);
      }
    } else {
      searchTypes = [
        SearchTypes.USER,
        SearchTypes.GROUP,
        SearchTypes.DISTRIBUTION_LIST,
        SearchTypes.CARE_TEAM,
      ];
      if (currentOrganization.forumsEnabled) {
        searchTypes.push(SearchTypes.FORUM);
      }
      if (currentOrganization.showTeams) {
        searchTypes.push(SearchTypes.TEAM);
      }
    }

    search({
      excludeIds: excludeIds,
      organization: currentOrganization,
      search,
      searchTypes,
      text: e.target.value,
    });
  };
}

export default mobxInjectSelect({
  messengerStore: ['currentOrganization'],
  networkStore: ['isProviderNetwork'],
  patientAdminStore: ['allowPatientBroadcastLists'],
  searchSidebarStore: ['clearSearchResults', 'input', 'search'],
})(SearchBar);
