import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../../utils';
import { ReactComponent as TCIcon } from '../../../widgets/messenger/images/side-icon-Messenger.svg';
import { ReactComponent as RefreshArrow } from '../../../widgets/messenger/images/refresh-roles-icon.svg';
import BEM from '../../bem';

const classes = BEM.with('MobileQRLogin');

class MobileQRLogin extends Component {
  static propTypes = {
    closeMobileQRLogin: PropTypes.func.isRequired,
    generateQRImage: PropTypes.func.isRequired,
    qrImage: PropTypes.string,
  };

  componentDidMount() {
    document.addEventListener('click', this._closePopup, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._closePopup, false);
  }

  render() {
    const { qrImage } = this.props;

    let qrImageDiv;
    if (qrImage) {
      qrImageDiv = <img alt="qr code" className={classes('qr-image')} src={qrImage} />;
    } else {
      qrImageDiv = (
        <div className={classes('new-qr-code')}>
          <div className={classes('expired-text')}>QR code expired</div>
          <div className={classes('new-code-button')} onClick={this._generateQRImage}>
            <div className={classes('new-code-text')}>New Code</div>
            <div className={classes('arrow-circle')}>
              <RefreshArrow className={classes('refresh-arrow')} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={classes()} onBlur={this._closePopup} tabIndex={1} ref={this._setDiv}>
        <div className={classes('qr-container')}>{qrImageDiv}</div>
        <div className={classes('mobile-text')}>
          <div className={classes('mobile-header')}>
            <div className={classes('circle')}>
              <TCIcon className={classes('tc-icon')} />
            </div>
            Mobile Login
          </div>
          Open the TigerConnect app and click on
          <div className={classes('login-text')}>Login with QR code</div>
        </div>
      </div>
    );
  }

  _generateQRImage = async () => {
    const { generateQRImage } = this.props;
    const qrImage = await generateQRImage();
    return qrImage;
  };

  _setDiv = (ref) => {
    this.qrCodeDiv = ref;
  };

  _closePopup = (e) => {
    const { closeMobileQRLogin } = this.props;
    if (!(this.qrCodeDiv && this.qrCodeDiv.contains(e.target))) {
      closeMobileQRLogin();
    }
  };
}

export default mobxInjectSelect({
  userStore: ['closeMobileQRLogin', 'generateQRImage', 'qrImage'],
})(MobileQRLogin);
