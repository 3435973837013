import React, { useRef } from 'react';
import {
  MessageTemplateRepository,
  MessageTemplateRepositoryLabels,
} from '../../../../../models/enums/MessageTemplateRepositories';
import BEM from '../../../../bem';
import { ContextMenu } from '../../../ContextMenu';
import { RepositoryTypeContextMenu } from '../../../ScheduleMessageModal';

import { ReactComponent as DropDownSvg } from '../../../../images/pa-dropdown.svg';
import { useAppSelector } from 'redux-stores';

const classes = BEM.with('TemplateForm');

type RepositoriesMenuProps = {
  disabled: boolean;
  newRepository: MessageTemplateRepository;
  setNewRepository: (repository: MessageTemplateRepository) => void;
};

const RepositoriesMenu: React.FC<RepositoriesMenuProps> = ({
  disabled,
  newRepository,
  setNewRepository,
}) => {
  const templateSelectRef = useRef<HTMLButtonElement>(null);
  const accessibilityMode = useAppSelector((state) => state.ui.accessibilityMode);

  const selectedFragment = (
    <button
      className={classes('templateTypeDropdown', { disabled })}
      ref={templateSelectRef}
      disabled={disabled}
      onClick={(event) => {
        event.preventDefault();
      }}
    >
      <div className={classes('repositoryTypeDropdown')}>
        <div className={classes('repositoryTypeDropdown-repo')}>
          {MessageTemplateRepositoryLabels[newRepository]}
        </div>
        {!disabled && (
          <div>
            <DropDownSvg className={classes('nav-item-dropdown')} aria-hidden />
          </div>
        )}
      </div>
    </button>
  );

  let menuFragment = selectedFragment;
  if (!disabled) {
    menuFragment = (
      <ContextMenu
        event="click"
        offsetY={6}
        position="bottominnerleft"
        relativeTo={templateSelectRef.current}
        theme="vertical"
        value={newRepository}
        data-test-id={`template-save-to-${newRepository}`}
        accessibilityMode={accessibilityMode}
        menu={
          <RepositoryTypeContextMenu
            newRepository={newRepository}
            setNewRepository={setNewRepository}
            accessibilityMode={accessibilityMode}
            buttonRef={templateSelectRef}
          />
        }
      >
        {selectedFragment}
      </ContextMenu>
    );
  }

  return (
    <div className={classes('formGroup')}>
      <label>Save Template To</label>
      {menuFragment}
    </div>
  );
};

export default RepositoriesMenu;
