import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { once } from 'ramda';
import { AutoSizer } from 'react-virtualized';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { ReactComponent as PriorityIconSvg } from '../images/priority-icon.svg';
import RoleSelector from './Roles/RoleSelector';
import PreviewMessages from './PreviewMessages';
import { Modal, NewMessageRecipientPicker } from './';

const classes = BEM.with('MessageForwardModal');

class MessageForwardModal extends Component {
  static propTypes = {
    currentOrganization: propTypes.organization.isRequired,
    getDefaultUser: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    selectorDefaultsToUser: PropTypes.bool,
  };

  state = {
    asPriority: false,
    recipients: null,
    senderId: null,
    sortedSelectedMessages: [],
  };

  render() {
    const { isOpen, isProviderNetwork } = this.props;
    const { recipients, sortedSelectedMessages } = this.state;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        headerClass={classes('forwardHeader')}
        header={isProviderNetwork ? 'Forward Message' : 'Forward to Provider'}
        isOpen={isOpen}
        onAfterOpen={this._onAfterOpen}
        onRequestClose={this._onRequestCancel}
        size={'variable'}
        footerClass={classes('forwardFooter', {
          patientFooter: !isProviderNetwork,
        })}
        footerPrimaryActions={
          <button
            className={classes('forward-btn')}
            disabled={isEmpty(recipients)}
            onClick={once(this._forward)}
            type="button"
          >
            FORWARD
          </button>
        }
        footerSecondaryActions={
          <button className={classes('cancel-btn')} onClick={this._onRequestCancel} type="button">
            CANCEL
          </button>
        }
      >
        <div className={classes('preview-messages-container')}>
          {sortedSelectedMessages.length > 0 && (
            <PreviewMessages messages={sortedSelectedMessages} />
          )}
        </div>
        {this._renderInformationFragment()}
      </Modal>
    );
  }

  _renderInformationFragment = () => {
    const { currentOrganization, isProviderNetwork, getUserById } = this.props;
    const { asPriority, senderId } = this.state;
    const { allowedSenders, allowPriorityMessage, displayName } = currentOrganization;
    const sender = senderId && getUserById(senderId);

    if (!isProviderNetwork) {
      return (
        <div className={classes('patient-information-container')}>
          <div className={classes('patient-recipients')}>
            <label className={classes('patient-recipients-label')}>Providers</label>
            <div className={classes('patient-autosizer')}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <div className={classes('patient-autosizer-contents')} style={{ width }}>
                    <NewMessageRecipientPicker
                      className={'Patient-Message-forward-modal'}
                      excludeBroadcastsWhenSenderIsRole={true}
                      onChange={this._recipientPickerDidChange}
                      placeholder={`Select from Provider Network in ${displayName}`}
                      sender={sender}
                      autoFocus={false}
                      openOnType
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={classes('information-container')}>
        {asPriority && (
          <div className={classes('priority-indicator-container')}>
            <div
              aria-label="Message Priority Indicator"
              className={classes('priority-on-indicator')}
            >
              Priority Message: On
            </div>
          </div>
        )}
        {senderId && allowedSenders.length > 1 && (
          <div className={classes('sending')}>
            <div className={classes('role-selector-row')}>
              <RoleSelector
                allowedSenders={allowedSenders}
                ref={this._setRoleSelector}
                sender={sender}
                setSenderId={this._setSenderId}
                showSendAs={true}
              />
            </div>
          </div>
        )}
        <div className={classes('recipients-container')}>
          {allowPriorityMessage && (
            <button className={classes('priority-button-container')} onClick={this._handlePriority}>
              <PriorityIconSvg
                aria-label="Message Priority Button"
                className={classes('image', {
                  alwaysEscalate: false,
                  isPriority: asPriority,
                })}
                aria-hidden
              />
            </button>
          )}
          <div className={classes('recipients')}>
            <label className={classes('recipients-label')}>To:</label>
            <div className={classes('autosizer')}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <div className={classes('autosizer-contents')} style={{ width }}>
                    <NewMessageRecipientPicker
                      className={'Message-forward-modal'}
                      excludeBroadcastsWhenSenderIsRole={true}
                      onChange={this._recipientPickerDidChange}
                      placeholder={'Choose a contact to forward'}
                      sender={sender}
                      autoFocus={false}
                      openOnType
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
          </div>
        </div>
      </div>
    );
  };

  _setRoleSelector = (ref) => {
    this.roleSelector = ref;
  };

  _setSenderId = (senderId) => {
    this.setState({ senderId });
  };

  _recipientPickerDidChange = (values) => {
    this.setState({ recipients: values });
  };

  _handlePriority = () => {
    const { asPriority } = this.state;
    this.setState({ asPriority: !asPriority });
  };

  _forward = async () => {
    const { forwardMultiple } = this.props;
    const { asPriority, recipients, senderId, sortedSelectedMessages } = this.state;

    forwardMultiple(senderId, recipients, sortedSelectedMessages, {
      asPriority: asPriority ? 'HIGH' : null,
    });

    this._onRequestClose();
  };

  _onAfterOpen = () => {
    const { currentUserId } = this.props;
    this._setSenderId(currentUserId);
    this._setSortedSelectedMessages();
  };

  _onRequestCancel = () => {
    const { closeModal } = this.props;

    closeModal();
    this.setState({ asPriority: false, recipients: null });
  };

  _onRequestClose = () => {
    const { closeModal, hideMessageMultiSelect, isProviderNetwork, openModal } = this.props;
    const { recipients } = this.state;

    if (!isProviderNetwork) {
      openModal('patientForwardSuccess', { recipients });
      this.setState({
        asPriority: false,
        recipients: null,
        sortedSelectedMessages: [],
      });
    } else {
      closeModal();
      this.setState({
        asPriority: false,
        recipients: null,
        sortedSelectedMessages: [],
      });
    }
    hideMessageMultiSelect();
  };

  _setDefaultSender = () => {
    const { currentOrganization, selectorDefaultsToUser, getDefaultUser } = this.props;
    const { allowedSenders } = currentOrganization;
    const { senderId: lastSenderId } = this.state;

    if (allowedSenders.length === 0) {
      if (lastSenderId) this.setState({ senderId: null });
      return;
    }

    if (selectorDefaultsToUser) {
      const senderId = getDefaultUser({ allowedSenders, setSenderId: false });
      return this.setState({ senderId });
    }

    const idOfSender = (sender) => (sender.$entityType === 'role' ? sender.botUser.id : sender.id);
    const senderIdAllowed = (id) =>
      id && allowedSenders.find((sender) => idOfSender(sender) === id);
    if (senderIdAllowed(lastSenderId)) return;
    this.setState({ senderId: idOfSender(allowedSenders[0]) });
  };

  _setSortedSelectedMessages = () => {
    const {
      options: { messages },
    } = this.props;
    const sortedMessages = Object.values(messages).sort((a, b) => {
      if (a.sortNumber < b.sortNumber) {
        return -1;
      }
      if (a.sortNumber > b.sortNumber) {
        return 1;
      }
      return 0;
    });
    this.setState({ sortedSelectedMessages: sortedMessages });
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['getDefaultUser', 'selectorDefaultsToUser'],
  messageStore: ['forwardMultiple'],
  messengerStore: ['currentOrganization', 'hideMessageMultiSelect'],
  modalStore: ['openModal'],
  networkStore: ['isProviderNetwork'],
  sessionStore: ['currentUserId'],
  userStore: ['getUserById'],
})(MessageForwardModal);
