import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';

const classes = BEM.with('SignInForm');

class UsernameTitle extends Component {
  static propTypes = {
    isStaticRender: PropTypes.bool,
    usernameFormClassName: PropTypes.string,
    bodyText: PropTypes.string,
  };

  render() {
    const { isStaticRender, usernameFormClassName, bodyText } = this.props;
    const className = isStaticRender && usernameFormClassName;
    const classNameValue = className === false ? className.toString() : className;

    return (
      <div id="tc-SignInForm__UsernameTitle" className={classNameValue}>
        <div className={classes('title-text')} data-test-id={'sign-in-title-text'}>
          {bodyText}
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  messengerStore: ['isStaticRender'],
  staticStore: ['usernameFormClassName'],
})(UsernameTitle);
