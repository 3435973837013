import React from 'react';
import { Team, User, ActionButton } from '../../../../types';
import { mobxInjectSelect } from '../../../utils';
import { ActionTexts } from '../../../../models/enums';
import EntityEditorActionButtons from '../Entities/EntityEditorActionButtons';

type MobxProps = {
  actionButtonOnClick: () => Promise<void>;
  getAdminRolesInOrganization: () => { isTeamAdmin?: boolean };
  requestToJoin: (teamId: string | undefined, options: unknown) => Promise<void>;
  currentOrganizationId: string;
  currentTeam: Team;
  currentUser: User;
};

type TeamEditorProps = {
  isTeamAdmin: boolean;
  isLoading: boolean;
  maxTeamMembers: number;
  memberSpaces: number;
};

function TeamEditorActions({
  actionButtonOnClick,
  requestToJoin,
  getAdminRolesInOrganization,
  isLoading,
  currentOrganizationId,
  currentTeam,
  currentUser,
  maxTeamMembers,
  memberSpaces,
}: TeamEditorProps & MobxProps) {
  let actionButtons: Array<ActionButton> = [];
  const { isTeamAdmin = false } = getAdminRolesInOrganization();
  const {
    canRequestToJoin,
    hasCurrentUserBeenDeclined,
    hasCurrentUserOrRole,
    hasCurrentUserPending,
  } = currentTeam || {};

  const makeJoinRequest = async () => {
    if (!hasCurrentUserOrRole && currentTeam?.id) {
      const requestedById = currentUser.id;
      await requestToJoin(currentTeam.id, {
        organizationId: currentOrganizationId,
        requestedById,
      });
    }
  };

  actionButtons = [
    {
      title: ActionTexts.activate,
      id: 'activate',
      isVisible: () => !hasCurrentUserOrRole,
      action: actionButtonOnClick,
    },
    {
      title: 'Request pending...',
      isVisible: () =>
        (!hasCurrentUserOrRole && hasCurrentUserPending && !hasCurrentUserBeenDeclined) || false,
      action: () => undefined,
      isDisabled: () => true,
    },
    {
      title: `Request to join as ${currentUser.displayName}`,
      id: 'request-to-join-team',
      isVisible: () =>
        (!hasCurrentUserOrRole &&
          !isTeamAdmin &&
          canRequestToJoin &&
          (!hasCurrentUserPending || hasCurrentUserBeenDeclined) &&
          memberSpaces &&
          memberSpaces < maxTeamMembers) ||
        false,
      action: makeJoinRequest,
    },
    {
      title: ActionTexts.message,
      id: 'message-team',
      isVisible: () => hasCurrentUserOrRole || false,
      action: () => actionButtonOnClick(),
    },
  ];

  return <EntityEditorActionButtons isLoading={isLoading} buttons={actionButtons} />;
}

export default mobxInjectSelect<TeamEditorProps, MobxProps>({
  teamStore: ['actionButtonOnClick', 'currentTeam', 'requestToJoin'],
  messengerStore: ['currentOrganizationId', 'getAdminRolesInOrganization'],
  sessionStore: ['currentUser'],
})(TeamEditorActions);
