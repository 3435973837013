import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import uuid from 'uuid';
import { ReactComponent as IconTrashSvg } from '../../images/icon-trash.svg';
import { ReactComponent as OrganizeSvg } from '../../images/icon-organize.svg';
import BEM from '../../bem';
import DotsIndicator from '../DotsIndicator';
import { AccessibleList } from 'common/components';
import { KEYMAP } from 'common/constants';
import { useAppSelector } from 'redux-stores';
import { UISliceState } from 'redux-stores/ui';

const classes = BEM.with('CustomResponseList');

const DEFAULT_CHARACTER_LIMIT = 1024;
const DEFAULT_CUSTOM_RESPONSE_LIMIT = 10;

export type Response = { id: string; text: string };

type CustomResponseListProps = {
  responses: Response[] | undefined;
  setResponses?: (replies: Response[]) => void;
  onEditButtonClick?: () => void;
  onResponseClick?: (text: string) => void;
  responseLimit?: number;
  characterLimit?: number;
  maxHeight?: string;
  isLoading?: boolean;
  setBtnSaveDisabled?: (isDisabled: boolean) => void;
};

function CustomResponseList({
  responses,
  responseLimit = DEFAULT_CUSTOM_RESPONSE_LIMIT,
  characterLimit = DEFAULT_CHARACTER_LIMIT,
  onEditButtonClick,
  onResponseClick,
  setResponses,
  maxHeight = '',
  isLoading,
  setBtnSaveDisabled,
}: CustomResponseListProps) {
  const [activeReplyId, setActiveReplyId] = useState('');
  const [dragHover, setDragHover] = useState<number>();
  const [responseSubmitting, setResponseSubmitting] = useState<string>();
  const [firstTimeEnter, setFirstTimeEnter] = useState<boolean>(true);
  const [focusLastElement, setFocusLastElement] = useState<boolean>(false);
  const dragItem = useRef<number>();
  const addCustomResponseButtonRef = useRef<HTMLButtonElement>(null);
  const messageContainerRef = useRef<HTMLUListElement>(null);
  const responseListContainerRef = useRef<HTMLUListElement>(null);
  const { accessibilityMode } = useAppSelector(({ ui }: { ui: UISliceState }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));
  const emptyReply = responses?.length && responses.some((qr) => qr?.text.trim().length === 0);
  const canAdd = !emptyReply && responses && responses.length < responseLimit;

  const showCharacterCount = activeReplyId !== '';
  const characterCount = responses?.find((qr) => qr.id === activeReplyId)?.text?.length || 0;

  useEffect(() => {
    if (activeReplyId) {
      const el = document.getElementById('quick-reply-input') as HTMLTextAreaElement;

      if (!el) return;

      const text = el.textContent || '';
      const length = text.length;

      el.focus();

      el.selectionStart = length;
      el.selectionEnd = length;
    }
  }, [activeReplyId]);

  useEffect(() => {
    if (!responses) return;
    const hasEmptyResponses = responses?.some((qr) => qr.text.trim().length === 0);
    setBtnSaveDisabled?.(hasEmptyResponses);
  }, [responses, setBtnSaveDisabled]);

  const focusFirstChildElement = useCallback(
    (container: HTMLUListElement | null) => {
      if (accessibilityMode && container) {
        const firstChild = container?.childNodes[0] as HTMLButtonElement;
        firstChild && firstChild.focus();
        setFirstTimeEnter(false);
      }
    },
    [accessibilityMode]
  );

  const focusLastChildElement = useCallback(
    (container: HTMLUListElement | null) => {
      if (accessibilityMode && container) {
        const childNodes = container?.childNodes;
        const lastChild = childNodes[childNodes.length - 1] as HTMLButtonElement;
        lastChild && lastChild.focus();
      }
    },
    [accessibilityMode]
  );

  useEffect(() => {
    setFirstTimeEnter(true);
  }, [setResponses]);

  useEffect(() => {
    if (accessibilityMode) {
      if (!setResponses) {
        if (responses && !responses.length) {
          addCustomResponseButtonRef.current?.focus();
        }
        if (responses && responses.length > 0 && firstTimeEnter) {
          focusFirstChildElement(messageContainerRef.current);
        }
      } else {
        if (responses) {
          if (firstTimeEnter) setFirstTimeEnter(false);
          if (responses.some((qr) => qr.text === '')) {
            focusLastChildElement(responseListContainerRef.current);
            setFocusLastElement(true);
          }
        }
      }
    }
  }, [
    accessibilityMode,
    firstTimeEnter,
    focusFirstChildElement,
    focusLastChildElement,
    focusLastElement,
    responses,
    setResponses,
  ]);

  function addReply() {
    if (!responses) return;
    setResponses?.([...responses.filter((qr) => qr.text !== ''), { id: uuid(), text: '' }]);
  }

  function delReply(replyId: string) {
    if (!responses) return;

    setResponses?.(responses.filter((qr) => qr.id !== replyId));
  }

  function updateReply(event: React.ChangeEvent<HTMLTextAreaElement>) {
    if (!responses) return;
    event.stopPropagation();

    setResponses?.(
      responses.map((qr) => {
        return qr.id === activeReplyId ? Object.assign(qr, { text: event.target.value }) : qr;
      })
    );
  }

  function filterEmptyReplies() {
    if (!responses) return;
    setResponses?.(responses.filter((qr) => qr.text.trim().length !== 0));
    setActiveReplyId('');
    if (focusLastElement) setFocusLastElement(false);
  }

  function handleEditClick() {
    onEditButtonClick?.();
  }

  function onEnterSpaceKeyPress(responseId: string) {
    responseId !== activeReplyId && setActiveReplyId(responseId);
  }

  return (
    <div className={classes()}>
      {!setResponses ? (
        <div className={classes('view-container')}>
          {(!responses || isLoading) && (
            <div className={classes('dots-container')}>
              <DotsIndicator color={'var(--neutral-700)'} size={13} />
            </div>
          )}
          {responses && !responses.length && (
            <div className={classes('empty-container')}>
              <div className={classes('empty-message')}>You have no custom responses yet.</div>
              <button
                className={classes('empty-action')}
                onClick={handleEditClick}
                ref={addCustomResponseButtonRef}
              >
                Click here to add some!
              </button>
            </div>
          )}
          {responses && responses.length > 0 && (
            <>
              <div
                className={classes('message-container')}
                style={{ maxHeight }}
                id="custom-response-list__message-container"
              >
                <AccessibleList
                  focusableClasses=".tc-CustomResponseList__message"
                  loop={true}
                  ref={messageContainerRef}
                  accessibilityMode={!firstTimeEnter && accessibilityMode}
                >
                  {responses.map((response) => (
                    <button
                      key={response.id}
                      title={response.text}
                      className={classes('message')}
                      onClick={async () => {
                        setResponseSubmitting(response.id);
                        await onResponseClick?.(response.text);
                        setResponseSubmitting('');
                      }}
                      onKeyDown={async (e) => {
                        if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
                          setResponseSubmitting(response.id);
                          await onResponseClick?.(response.text);
                          setResponseSubmitting('');
                        }
                      }}
                    >
                      {response.id === responseSubmitting ? (
                        <div className={classes('response-dots-container')}>
                          <DotsIndicator color={'var(--neutral-700)'} size={13} />
                        </div>
                      ) : (
                        <div className={classes('clamp')}>{response.text}</div>
                      )}
                    </button>
                  ))}
                </AccessibleList>
              </div>
              <button
                className={classes('message-edit')}
                onClick={!!responseSubmitting ? () => {} : handleEditClick}
              >
                Edit
              </button>
            </>
          )}
        </div>
      ) : (
        <div className={classes('edit-container')}>
          <div className={classes('title')}>
            <div>{`${responses?.length || 0}/${responseLimit} Items`}</div>
            {showCharacterCount && (
              <div>
                {characterCount}/{characterLimit}
              </div>
            )}
          </div>
          <div
            className={classes('response-list-container')}
            style={{ maxHeight }}
            id="custom-response-list__response-list-container"
          >
            <>
              {(!responses || isLoading) && (
                <div className={classes('dots-container')}>
                  <DotsIndicator color={'var(--neutral-700)'} size={13} />
                </div>
              )}
              {responses && (
                <AccessibleList
                  focusableClasses=".tc-CustomResponseList__message"
                  loop={true}
                  ref={responseListContainerRef}
                  focusableChildrenClasses={[
                    '.tc-CustomResponseList__delete-icon-holder',
                    '.tc-CustomResponseList__clamp',
                  ]}
                  focusStart={focusLastElement ? 'last' : 'first'}
                  setStartElementOnChange={true}
                  accessibilityMode={!firstTimeEnter && accessibilityMode}
                >
                  {responses.map((response, idx) => (
                    <button
                      className={classes('message', { dragHover: dragHover === idx })}
                      key={response.id}
                      onDragEnd={() => {
                        setDragHover(undefined);
                        if (typeof dragItem.current !== 'number') return;
                        if (dragItem.current === dragHover) return;
                        const cleanArr = ([] as Response[]).concat(
                          responses.slice(0, dragItem.current),
                          responses.slice(dragItem.current + 1)
                        );

                        setResponses(
                          ([] as Response[]).concat(
                            cleanArr.slice(0, dragHover),
                            responses[dragItem.current],
                            cleanArr.slice(dragHover)
                          )
                        );
                      }}
                      onDragEnter={(e) => {
                        e.preventDefault();
                        setDragHover(idx);
                      }}
                      onDragStart={() => {
                        dragItem.current = idx;
                      }}
                      draggable={true}
                      onClick={() => onEnterSpaceKeyPress(response.id)}
                      onKeyDown={(e) => {
                        if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') e.stopPropagation();
                      }}
                    >
                      <div className={classes('action')}>
                        <OrganizeSvg className={classes('organize-icon')} />
                      </div>
                      <div className={classes('text', { blank: !response.text })}>
                        {response.id === activeReplyId ? (
                          <textarea
                            id="quick-reply-input"
                            maxLength={characterLimit}
                            onBlur={filterEmptyReplies}
                            onChange={updateReply}
                            value={response.text}
                            onKeyDown={(e) => {
                              e.stopPropagation();
                              if (e.key === 'Escape') {
                                const activeElement = document.activeElement as HTMLElement;
                                activeElement.blur();
                              }
                            }}
                          />
                        ) : (
                          <div
                            style={{ cursor: 'pointer', width: '100%' }}
                            onClick={() => {
                              setActiveReplyId(response.id);
                            }}
                          >
                            <div className={classes('clamp')}>
                              {response.text || 'Click here to type reply...'}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={classes('action')}>
                        <div
                          className={classes('delete-icon-holder')}
                          onClick={(e) => {
                            delReply(response.id);
                            e.stopPropagation();
                          }}
                          id={'delete-icon-holder'}
                          role="button"
                          aria-label="delete-icon-holder"
                          onKeyDown={(e) => {
                            if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
                              delReply(response.id);
                              e.stopPropagation();
                            }
                          }}
                        >
                          <IconTrashSvg
                            viewBox={'0.5 0 12 13'}
                            className={classes('delete-icon')}
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </button>
                  ))}
                </AccessibleList>
              )}
            </>
          </div>
          {canAdd && (
            <button
              onClick={addReply}
              className={classNames(classes('message'), classes('add-message'))}
            >
              New Reply
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default CustomResponseList;
