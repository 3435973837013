import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { downloadFile, mobxInjectSelect, scheduleTimezones } from '../../../utils';
import BEM from '../../../bem';
import { BasicModal } from '../..';

import { ReactComponent as FailedIcon } from '../../../images/schedule-import-tool-upload-failed.svg';
import { ReactComponent as SuccessfulIcon } from '../../../images/schedule-import-tool-upload-successful.svg';
import { FailedRecord } from '../../../../types/Collaboration';

const { clearRoleScheduleUploadResponse, setModal } = actions;
const { fetchIntegrationIds, uploadScheduleUpload } = thunk;
const classes = BEM.with('CollaborationRolesSchedulerModal');

const pathname = window.location.pathname.replace('index.html', '');
const TEMPLATE_URL = window.location.origin + pathname + 'media/roles_schedule_template.csv';
const getCurrentTimestamp = () =>
  new Date().toLocaleString('en-US', { hour12: false, timeZone: 'UTC', timeZoneName: 'short' });

const ERROR_DESC: { [err: string]: string } = {
  etime_lte_stime: 'START time is after END time',
  etime_missing: 'END time has not been provided',
  invalid_data_format: 'CSV rows do not match the required header format',
  invalid_header_format: 'CSV header does not match required format',
  not_iso8601: 'Unknown Date',
  overlapping_shift: 'Shifts overlap',
  reached_file_size_limit: 'Your CSV file is larger than 10MB please split into chunks < 10MB',
  role_not_matched: 'Unknown Role token',
  server_unavailable: 'Roles service not available',
  stime_etime_equals: 'START time equals END time',
  stime_missing: 'START time has not been provided',
  unparseable: 'Does not recognize data format',
  user_not_matched: 'Unknown User',
  user_role_not_matched: 'Missing required value(s)',
};

type RolesSchedulerModalProps = {
  isOpen: boolean;
};
type SchedulerStep = 'selectFile' | 'showDetails' | 'submitFile' | 'uploadedFile';
type ParsedResponse = {
  successful?: number;
  failed?: number;
  total?: number;
  failures?: FailedRecord[];
};

type MobxProps = {
  determineCoarseTimezone: () => Promise<{ name: string }>;
};

function RolesSchedulerModal({
  determineCoarseTimezone,
  isOpen,
}: RolesSchedulerModalProps & MobxProps) {
  const dispatch = useDispatch();
  const fileHandler = useRef<HTMLInputElement>(null);
  const selectHandler = useRef<HTMLSelectElement>(null);
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [step, setStep] = useState<SchedulerStep>('selectFile');
  const [file, setFile] = useState<File | undefined>(undefined);
  const [error, setError] = useState('');
  const [parsedResponse, setParsedResponse] = useState<ParsedResponse>({});
  const integrationIds = useSelector((state: ReduxState) => state.roles.integrationIds);
  const openModal = useSelector((state: ReduxState) => state.session.openModal);
  const selectedOrgId = useSelector((state: ReduxState) => state.collab.selectedOrgId);
  const uploadResponse = useSelector((state: ReduxState) => state.roles.scheduleUploadResponse);
  const { status } = uploadResponse || {};

  useEffect(() => {
    if (openModal?.name !== 'roleScheduler') return;
    fetchIntegrationIds(dispatch, selectedOrgId);
  }, [dispatch, openModal, selectedOrgId]);

  useEffect(() => {
    if (openModal?.name !== 'roleScheduler') return;
    const getTimezone = async () => {
      const { name } = await determineCoarseTimezone();
      setSelectedTimezone(name);
    };

    getTimezone();
  }, [determineCoarseTimezone, openModal]);

  const closeModal = useCallback(() => {
    dispatch(setModal(undefined));
    dispatch(clearRoleScheduleUploadResponse());
    setError('');
    setFile(undefined);
    setStep('selectFile');
  }, [dispatch]);

  const clickSelectFile = () => {
    fileHandler.current && fileHandler.current.click();
  };

  const submitFile = useCallback(() => {
    if (!file) return;
    uploadScheduleUpload(
      dispatch,
      file,
      selectHandler.current?.value || '',
      selectedTimezone,
      selectedOrgId
    );
    setStep('uploadedFile');
  }, [dispatch, file, selectedOrgId, selectedTimezone]);

  const resetModal = () => {
    dispatch(clearRoleScheduleUploadResponse());
    setError('');
    setFile(undefined);
    if (fileHandler.current) {
      fileHandler.current.value = '';
    }
    setParsedResponse({});
    setStep('selectFile');
  };

  const onFileChange = ({ target: { files } }: { target: { files: FileList | null } }) => {
    let nextStep = step;
    let newError = '';
    let newFile: File | undefined = files && files.length ? files[0] : undefined;
    const ext: string | undefined = newFile?.name.split('.').pop()?.toLowerCase();

    if (newFile) {
      nextStep = 'submitFile';
      if (!(newFile.type === 'text/csv' || ext === 'csv')) {
        newError = newFile && 'Invalid file type, please select a .csv file';
        newFile = undefined;
        nextStep = 'selectFile';
      }
    }

    if (step === 'submitFile' && file && !newFile) {
      newFile = file;
    }

    setFile(newFile);
    setError(newError);
    setStep(nextStep);
  };

  const parseResponse = () => {
    const newParsedResponse: ParsedResponse = {};
    if (uploadResponse?.json.reason !== 'invalid_data_format') {
      newParsedResponse.successful = uploadResponse?.json.matched_count;
      newParsedResponse.failed = uploadResponse?.json.unmatched_count;
      newParsedResponse.total = uploadResponse?.json.total_count;
      newParsedResponse.failures = uploadResponse?.json.unmatched;
    }
    setParsedResponse(newParsedResponse);
  };

  const showDetails = () => {
    parseResponse();
    setStep('showDetails');
  };

  const downloadDetails = () => {
    let details = 'Line,Reason';
    parsedResponse?.failures?.forEach((failure) =>
      failure.reasons.forEach((reason) => {
        details += `\r\n${failure.line},${ERROR_DESC[reason]}`;
      })
    );

    downloadFile('CSV-Upload-Failure-Details.csv', 'text/csv', details);
  };

  const selectFileStep = (
    <>
      This utility allows you to import a schedule for Roles, so you can pre-determine who should be
      on duty for each role and at what time. Once a schedule is set, our Roles platform will opt
      in/out the owner accordingly.
      <br />
      <br />
      Please download the{' '}
      <a
        className={classes('upload-text-link')}
        download
        href={TEMPLATE_URL}
        rel="noopener noreferrer"
        target="_blank"
      >
        CSV Template
      </a>
      , which shows the required format for processing your schedule.
    </>
  );

  const submitFileStep = (
    <>
      <label htmlFor={classes('schedule-file-change')} className={classes('schedule-file-label')}>
        Roles Schedule File:
      </label>
      <div className={classes('schedule-file')}>
        <div className={classes('schedule-file-name')}>{file?.name}</div>
        <button
          className={classes('schedule-file-change')}
          onClick={clickSelectFile}
          data-test-id="change file"
        >
          Change
        </button>
      </div>
      <div className={classes('schedule-integration')}>
        <label htmlFor={classes('schedule-integration-select')}>
          This roster will be saved under integration ID:
        </label>
        <select className={classes('schedule-integration-select')} ref={selectHandler}>
          {integrationIds.map((id: string) => (
            <option key={id}>{id}</option>
          ))}
        </select>
      </div>
      <div className={classes('schedule-timezone')}>
        <label htmlFor={classes('schedule-timezone-select')} className={classes('timezone-label')}>
          Select this integration's timezone:
        </label>
        <select
          className={classes('schedule-timezone-select')}
          defaultValue={selectedTimezone}
          onChange={({ target: { value } }) => setSelectedTimezone(value)}
        >
          {scheduleTimezones.map((tz) => (
            <option key={tz} value={tz}>
              {tz.replace(/_/g, ' ')}
            </option>
          ))}
        </select>
      </div>
    </>
  );

  const uploadFileStep = (
    <div className={classes('schedule-response')}>
      {!status && <>Uploading CSV file...</>}
      {status && status !== 200 && <FailedIcon className={classes('fail-icon')} />}
      {status === 200 && <SuccessfulIcon />}
      <div className={classes('schedule-response-filename')}>
        File:
        {file?.name}
      </div>
      {status && status !== 200 && (
        <>
          <div className={classes('schedule-response-timestamp-failed')}>
            Import Failed - ({getCurrentTimestamp()})
          </div>
          <button className={classes('schedule-response-view-details')} onClick={showDetails}>
            View Details
          </button>
        </>
      )}
      {status === 200 && (
        <div className={classes('schedule-response-timestamp-successful')}>
          Import Successful - Active ({getCurrentTimestamp()})
        </div>
      )}
    </div>
  );

  const showDetailsStep = (
    <div className={classes('schedule-response-show-details')}>
      <div className={classes('schedule-response-filename')}>
        <b>File: </b>
        {file?.name}
      </div>
      <div className={classes('schedule-response-timestamp-failed')}>
        <b>Status: </b>
        Import Failed - ({getCurrentTimestamp()})
      </div>
      {parsedResponse && parsedResponse.failures && (
        <>
          <div className={classes('schedule-response-stats')}>
            <b>Stats: </b>
            <div className={classes('schedule-response-stats-total')}>
              {parsedResponse.total} Total Records
            </div>
            <div className={classes('schedule-response-stats-success')}>
              {parsedResponse.successful} Processed Successfully
            </div>
            <div className={classes('schedule-response-stats-failed')}>
              {parsedResponse.failed} Failed
            </div>
          </div>
          <div className={classes('schedule-response-details')}>
            <b>Details: </b>
            <button
              className={classes('schedule-response-download-details')}
              onClick={downloadDetails}
            >
              Download Details
            </button>
            <code>
              {parsedResponse.failures.map((failure) =>
                failure.reasons.map((reason) => (
                  <div key={`${failure.line}.${reason}`}>
                    <b>Line {failure.line}:</b>
                    {ERROR_DESC[reason]}
                  </div>
                ))
              )}
            </code>
          </div>
        </>
      )}
      {(!parsedResponse || !parsedResponse.failures) && (
        <div className={classes('schedule-response-reason')}>
          <b>Reason: </b>
          {uploadResponse?.json?.reason && ERROR_DESC[uploadResponse.json.reason]}
        </div>
      )}
    </div>
  );

  let submitText, submitFn;

  if (step === 'selectFile') {
    submitText = 'SELECT FILE';
    submitFn = clickSelectFile;
  } else if (step === 'submitFile') {
    submitText = 'SUBMIT';
    submitFn = submitFile;
  } else if (['uploadedFile', 'showDetails'].includes(step) && status !== 200) {
    submitText = 'RETRY';
    submitFn = resetModal;
  } else {
    submitText = 'DONE';
    submitFn = closeModal;
  }

  return (
    <BasicModal
      ariaLabelBody={'Roles Scheduler Info'}
      ariaLabelCancelButton={'Roles Scheduler Cancel'}
      ariaLabelCloseButton={'Roles Scheduler Close'}
      ariaLabelHeader={'Roles Scheduler Header'}
      ariaLabelSubmitButton={'Select File'}
      headerText={'Roles Schedule Import Tool'}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={submitFn}
      size={'medium'}
      theme={'normal'}
      submitText={submitText}
      hasCloseButton={true}
      useWCL
    >
      <div className={classes('')}>
        <input
          className={classes('upload-button-input')}
          type="file"
          ref={fileHandler}
          onChange={onFileChange}
        />
        {step === 'selectFile' && selectFileStep}
        {step === 'submitFile' && submitFileStep}
        {step === 'uploadedFile' && uploadFileStep}
        {step === 'showDetails' && showDetailsStep}
        {error && <div className={classes('error')}>{error}</div>}
      </div>
    </BasicModal>
  );
}

export default mobxInjectSelect<RolesSchedulerModalProps, MobxProps>({
  patientAdminStore: ['determineCoarseTimezone'],
})(RolesSchedulerModal);
