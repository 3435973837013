import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { MessageContextMenu } from '../';
import propTypes from '../../propTypes';
import BEM from '../../bem';
import { MessageSubTypes } from '../../../models/enums/MessageSubTypes';
import { ReactComponent as MoreButtonSvg } from '../../images/more-button.svg';
import { ReactComponent as MoreButtonWhiteSvg } from '../../images/more-button-white.svg';
import ReactionOptionBar from '../ReactionOptionBar';
import { ContextMenu } from '../ContextMenu';
import ReduxEscapeHatch from '../ReduxEscapeHatch';

const classes = BEM.with('MessageDropdown');

class MessageDropdown extends Component {
  static propTypes = {
    closeActionsMenu: PropTypes.func.isRequired,
    currentConversation: propTypes.conversation,
    isReactionsEnabled: PropTypes.bool.isRequired,
    isProviderNetwork: PropTypes.bool.isRequired,
    message: propTypes.message.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    openActionsMenu: PropTypes.func.isRequired,
    currentActionMenuMessageId: PropTypes.string,
  };

  state = {
    showClass: 'show-onhover-actions-button',
  };

  render() {
    const {
      currentConversation,
      isReactionsEnabled,
      isProviderNetwork,
      message,
      onMouseEnter,
      onMouseLeave,
      relativeTo,
      accessibilityMode,
      selectMessageRef,
      currentActionMenuMessageId,
    } = this.props;
    const { showClass } = this.state;
    const {
      id,
      escalationExecution,
      isForwarded,
      isForwardedFromPatientNetwork,
      priority,
      sender,
      senderStatus,
      shouldEscalate,
      subType,
    } = message;
    let { isOutgoing } = message;
    if (subType === MessageSubTypes.SYSTEM) {
      isOutgoing = false;
    }
    const isEscalated = !!escalationExecution || !!shouldEscalate;
    const position = isOutgoing ? 'bottomleft' : 'bottomright';
    const offsetX = isOutgoing ? -15 : 15;
    const offsetY = -10;
    const isRoleAutomatedMessage = subType === MessageSubTypes.ROLE_AWAY_NOTIFICATION;
    const direction = isOutgoing ? 'OUTGOING' : 'INCOMING';
    const isPatientOrContactMessage = sender && (sender.isPatient || sender.isPatientContact);
    const isVWRConversation = currentConversation?.featureService === 'vwr';
    const isAccessibleActive = accessibilityMode && currentActionMenuMessageId === id;

    let ButtonIcon = MoreButtonSvg;
    if (
      isEscalated ||
      priority === 'HIGH' ||
      isRoleAutomatedMessage ||
      isForwardedFromPatientNetwork
    ) {
      ButtonIcon = MoreButtonWhiteSvg;
    }

    const optionButton = <ButtonIcon className={classes('option-button')} />;

    let optionBar = optionButton;
    if (isReactionsEnabled && isProviderNetwork && !isVWRConversation) {
      optionBar = (
        <ReactionOptionBar direction={direction} messageId={message.id}>
          {optionButton}
        </ReactionOptionBar>
      );
    }

    return (
      <div className={classNames(classes())}>
        <ContextMenu
          event={'click'}
          menu={
            <MessageContextMenu
              accessibilityMode={accessibilityMode}
              message={message}
              messageRef={relativeTo}
              selectMessageRef={selectMessageRef}
            />
          }
          onShow={this._onShow}
          onHide={this._onHide}
          sideEffect={this._sideEffect}
          position={position}
          hideOnScroll={true}
          relativeTo={relativeTo}
          offsetX={offsetX}
          offsetY={offsetY}
          accessibilityMode={accessibilityMode}
          isAccessibleActive={isAccessibleActive}
        >
          <div
            className={classNames(
              classes(showClass),
              classes({
                direction,
                isEscalated,
                priority,
                isRoleAutomatedMessage: isRoleAutomatedMessage && !isForwarded,
                isPatientOrContactMessage: isPatientOrContactMessage && !isForwarded,
                isForwardedFromPatientNetwork,
                senderStatus,
              })
            )}
            aria-label={'Message Dropdown'}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            role="button"
            tabIndex={-1}
          >
            {optionBar}
          </div>
        </ContextMenu>
        <ReduxEscapeHatch
          ref={(ref) => {
            this.reduxEscapeHatch = ref;
          }}
        />
      </div>
    );
  }

  _sideEffect = (menuItem) => {
    if (['resend'].includes(menuItem)) {
      this.reduxEscapeHatch.focusMessageBodyInput();
    }
  };

  _onShow = () => {
    this.setState({ showClass: 'hide-actions-button' });
    const { openActionsMenu } = this.props;
    openActionsMenu();
  };

  _onHide = () => {
    this.setState({ showClass: 'show-onhover-actions-button' });
    const { closeActionsMenu } = this.props;
    closeActionsMenu();
  };
}

export default mobxInjectSelect({
  conversationStore: ['currentConversation'],
  messengerStore: ['isReactionsEnabled'],
  messageStore: ['currentActionMenuMessageId'],
  networkStore: ['isProviderNetwork'],
})(MessageDropdown);
