import React, { useState } from 'react';

import TCClient from '../../../../../client';

import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';
import { Input } from '../../../../shared-components/Input/Input';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { UserData } from '../../../../types';

import styles from '../../CustomerSupport.module.css';
import { SET_TOAST_DURATION_IN_MILLISECONDS } from '../../../../utils/setToastDuration';
import { thunk } from 'redux-stores';

export default function SetPassword({
  accountData,
  onClose,
}: {
  accountData: UserData;
  onClose: () => void;
}) {
  const [passwordInput, setPasswordInput] = useState('');
  const [savingPassword, setSavingPassword] = useState(false);

  async function handlePasswordClick() {
    try {
      setSavingPassword(true);
      await TCClient.adminUsers.savePassword({
        orgId: accountData.organizationSettings[0].organizationId,
        password: passwordInput,
        userId: accountData.token,
      });
      thunk.setToastSettings({
        duration: SET_TOAST_DURATION_IN_MILLISECONDS(1),
        open: true,
        message: 'Password Set',
        type: 'SUCCESS',
      });
      setSavingPassword(false);
    } catch (_e) {
      thunk.setToastSettings({
        duration: SET_TOAST_DURATION_IN_MILLISECONDS(2),
        open: true,
        message: 'Error setting temporary password' + _e,
        type: 'FAILURE',
      });
    }
  }

  const passwordLength = passwordInput.length >= 8;
  const passwordSpecial = passwordInput.match(/\W/);
  const passwordNumber = passwordInput.match(/\d/);
  const passwordCapital = passwordInput.match(/[A-Z]/);

  return (
    <ViewContainer
      {...{
        header: 'Set Password',
        subHeader:
          'Set a new password for this user. Changes will take effect immediately. Temporary password must be a strong password.',
        onClose,
      }}
    >
      <div className={styles.viewContainer}>
        <div className={styles.containerText}>New Password: </div>
        <div className={styles.passwordInput}>
          <Input
            customCSS={styles.input}
            onInputChange={(e) => {
              setPasswordInput(e.target.value);
            }}
            value={passwordInput}
          />
        </div>
        <div className={styles.passwordText}>
          <div className={passwordLength ? styles.strikeThrough : styles.redText}>
            Minimum of 8 characters,
          </div>
          <div className={passwordCapital ? styles.strikeThrough : styles.redText}>
            1 capital letter,
          </div>
          <div className={passwordNumber ? styles.strikeThrough : styles.redText}>1 number,</div>
          <div className={passwordSpecial ? styles.strikeThrough : styles.redText}>
            and 1 special character
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <CtaButton
            label={'Set Password'}
            onClick={handlePasswordClick}
            primary={
              passwordLength && passwordCapital && passwordNumber && passwordSpecial ? true : false
            }
            disabled={
              !passwordLength ||
              !passwordCapital ||
              !passwordNumber ||
              !passwordSpecial ||
              savingPassword
            }
          />
        </div>
      </div>
    </ViewContainer>
  );
}
