import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import BEM from '../../bem';
import { ReactComponent as CheckMarkSvg } from '../../images/green-checkmark-full.svg';
import { ReactComponent as CloseButtonSvg } from '../../images/close-icon.svg';
import propTypes from '../../propTypes';
import scheduledMessageFormats from '../../utils/scheduledMessageFormats';
import EntityAvatar from '../EntityAvatar';

const classes = BEM.with('ProviderBroadcastListRow');
const { DATE_FORMAT } = scheduledMessageFormats;

const ProviderBroadcastListRow = ({
  isAdmin,
  isFirstEntry,
  isPending,
  isSecurityGroupPresent,
  isSelected,
  isSingle,
  member,
  removePendingMember,
  toggleSelectedMember,
}) => {
  const { addedByDisplayName, addedOn, department, title, name, displayName } = member;
  const hideBorder = isFirstEntry || isSingle;
  const dateAdded = addedOn ? moment(addedOn).format(DATE_FORMAT) : '';

  const memberType = member.botRole ? member.botRole : member;

  return (
    <div
      className={classes({ isSelected, selectable: !isPending && isAdmin })}
      onClick={
        isAdmin && !isSecurityGroupPresent
          ? () =>
              toggleSelectedMember &&
              toggleSelectedMember(member.$entityType === 'role' ? member.botUserId : member.id)
          : () => {}
      }
    >
      <div className={classes('list-item-checkbox')}>
        {!isPending && isAdmin && !isSecurityGroupPresent && (
          <div className={classes('list-item-checkbox-default', { isSelected })}>
            {isSelected && <CheckMarkSvg />}
          </div>
        )}
      </div>
      <div className={classes('member-details')}>
        <div className={classes('member-name', { hideBorder })} title={name || displayName}>
          <div className={classes('entity-avatar')}>
            <EntityAvatar
              entity={memberType}
              indicatorSize={'SMALL'}
              showPresenceIndicator={true}
              size={25}
            />
          </div>
          <span className={classes('name')}>{name || displayName}</span>
        </div>
      </div>
      <div className={classes('title', { hideBorder })} title={title}>
        {title}
      </div>
      <div className={classes('department', { hideBorder })} title={department}>
        {department}
      </div>
      <div className={classes('added-by', { hideBorder })} title={addedByDisplayName}>
        {addedByDisplayName}
      </div>
      <div className={classes('added-on', { hideBorder })}>
        <span className={classes('added-on-text')} title={dateAdded}>
          {dateAdded}
        </span>
        {isPending && (
          <button
            className={classes('close-button-container')}
            onClick={() => removePendingMember && removePendingMember(member)}
          >
            <CloseButtonSvg className={classes('close-icon-white')} />
          </button>
        )}
      </div>
    </div>
  );
};

ProviderBroadcastListRow.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isFirstEntry: PropTypes.bool,
  isPending: PropTypes.bool,
  isSecurityGroupPresent: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSingle: PropTypes.bool,
  member: propTypes.user.isRequired,
  removePendingMember: PropTypes.func,
  toggleSelectedMember: PropTypes.func,
};

ProviderBroadcastListRow.defaultProps = {
  isFirstEntry: false,
  isPending: false,
  isSecurityGroupPresent: false,
  isSelected: false,
  isSingle: false,
};

export default ProviderBroadcastListRow;
