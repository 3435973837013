import React from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import Modal from './Modal';

const classes = BEM.with('DeleteScheduledMessageModal');

const DeleteScheduledMessageModal = ({
  closeModal,
  deleteScheduledMessage,
  deleteScheduledMessages,
  isOpen,
  options,
}) => {
  const { selected, onClose, onCancel } = options;

  const deleteSelected = async () => {
    if (selected.length === 1) {
      await deleteScheduledMessage(selected[0]);
    } else {
      await deleteScheduledMessages(selected);
    }
    if (onClose) {
      onClose();
    }
    closeModal();
  };

  const cancelDeletion = () => {
    if (onCancel) {
      onCancel();
    } else {
      closeModal();
    }
  };

  return (
    <Modal
      bodyClass={classes('body')}
      closeClass={classes('close-button')}
      footerClass={classes('footer')}
      header={'Confirm Delete'}
      headerClass={classes('header')}
      isOpen={isOpen}
      size={'medium'}
      footerPrimaryActions={
        <button type="button" className={classes('delete-btn')} onClick={deleteSelected}>
          DELETE {selected.length > 1 && `(${selected.length})`}
        </button>
      }
      footerSecondaryActions={
        <button type="button" onClick={cancelDeletion} className={classes('cancel-btn')}>
          CANCEL
        </button>
      }
      className={classes()}
      onRequestClose={closeModal}
    >
      <div className={classes('info')}>
        Are you sure you want to delete the Scheduled Message
        {selected.length > 1 && 's'}?
      </div>
    </Modal>
  );
};

DeleteScheduledMessageModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteScheduledMessage: PropTypes.func.isRequired,
  deleteScheduledMessages: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
};

export default mobxInjectSelect({
  patientAdminStore: ['deleteScheduledMessage', 'deleteScheduledMessages'],
})(DeleteScheduledMessageModal);
