import { observable } from 'mobx';
import createMobxModel from './createMobxModel';

const Product = createMobxModel({
  name: 'product',

  fields: [
    'conversationCount',
    'unreadAlertsCount',
    'unreadCount',
    'unreadPriorityCount',
    'unreadVWRCount',
  ],

  objectFields: {
    activeProcessingEvents: {
      clone(entityParam, { entityStore }) {
        const results = [];
        for (const { actionType, entity, itemsEstimate } of entityParam) {
          const entry = { actionType, itemsEstimate };
          if (entity) {
            const { $entityType: entityType, id } = entity;
            entry.entityType = entityType;
            Object.defineProperty(entry, 'entity', {
              enumerable: true,
              get() {
                return entityStore.getById(entityType, id);
              },
            });
          }
          results.push(observable.object(entry));
        }
        return observable.array(results);
      },
    },
  },

  relations: {
    one: {
      productRoles: { type: 'productRoles' },
    },
  },
});

export default Product;
