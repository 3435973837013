import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import Modal from '../Modal';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { ReactComponent as FailIcon } from '../../images/icon-failed.svg';

const classes = BEM.with('CalleeBusyModal');
const CALL_FAILURE_TIMEOUT = 3000;

class CalleeBusyModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen } = this.props;
    const imgHeader = {
      image: FailIcon,
      imgClassHeader: classes('img'),
    };

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        imgHeader={imgHeader}
        hasCloseButton={false}
        headerClass={classes('header')}
        isOpen={isOpen}
        onAfterOpen={this._autoCloseModal}
        size={'small'}
      >
        <div className={classes('oops-text')}> Oops </div>
        <div className={classes('busy-user-text')}> User is currently busy</div>
      </Modal>
    );
  }

  _autoCloseModal = () => {
    const { closeModal } = this.props;
    setTimeout(() => {
      closeModal();
    }, CALL_FAILURE_TIMEOUT);
  };
}

export default mobxInjectSelect({
  callStore: ['closeModal'],
})(CalleeBusyModal);
