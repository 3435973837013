import React, { useCallback, useEffect, useState } from 'react';
import { keyBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import { MapStateToProps, reduxInjectSelect } from '../../../utils/reduxInjectSelect';
import BEM from '../../../bem';
import Dropdown from '../../Dropdown';
import SelectedTags from '../Tags/SelectedTags';
import TagItem from '../Tags/TagItem';
import CollaborationSearch from '../CollaborationSearch';
import TagFilter from '../TagFilter';
import { Tag } from '../../../../types';
import DotsIndicator from '../../DotsIndicator';
import { CollaborationCategories } from '../../../../models/enums';
import { notTaggedTag, selectCheckedTagsIds } from '../../../../redux-stores/Collaboration/tags';
import { actions, ReduxState, thunk } from '../../../../redux-stores';

import { ReactComponent as PlusIcon } from '../../../images/tag-plus.svg';
import { ReactComponent as SearchIcon } from '../../../images/tag-search.svg';
import { ReactComponent as BookmarkIcon } from '../../../images/bookmark.svg';
import { ReactComponent as TagIcon } from '../../../images/tag.svg';
import { ReactComponent as RoleIcon } from '../../../images/role.svg';
import { ReactComponent as FilterIcon } from '../../../images/filter.svg';
import { ReactComponent as TeamIcon } from '../../../images/team.svg';

const { ALL, MYROLES, MYTEAMS, NOTTAGGED } = CollaborationCategories;

const {
  setEntityLoadingStatus,
  setEntitiesSearchQuery,
  setFilteredTags,
  setIsEntitySearchOpen,
  setModal,
  setNewEntityType,
  setSelectedOrganization,
} = actions;
const { toggleSaveTag } = thunk;

const classes = BEM.with('CollaborationRoles');

const reduxSelectors = {
  collab: [
    'currentOrganization',
    'isRolesAdmin',
    'isRolesEnabled',
    'isTeamAdmin',
    'isTeamsEnabled',
    'selectedOrgId',
    'selectedOrgName',
  ],
  entities: [
    'activeTab',
    'checkedEntityIds',
    'entityResultsCount',
    'entitiesSearchQuery',
    'isEntitySearchOpen',
  ],
  tags: [
    'checkedTagsById',
    'filteredTags',
    'savedTags',
    'selectedCategory',
    'selectedTag',
    'tagsById',
  ],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type EntitiesHeaderProps = {};

function EntitiesHeader({
  activeTab,
  checkedEntityIds,
  checkedTagsById,
  currentOrganization,
  filteredTags,
  entityResultsCount,
  entitiesSearchQuery,
  isEntitySearchOpen,
  isRolesAdmin,
  isRolesEnabled,
  isTeamAdmin,
  isTeamsEnabled,
  savedTags,
  selectedCategory,
  selectedOrgId,
  selectedOrgName,
  selectedTag,
  tagsById,
}: EntitiesHeaderProps & ReduxProps) {
  const dispatch = useDispatch();

  const [batchDropdownOpen, setBatchDropdownOpen] = useState(false);
  const [isTogglingTag, setIsTogglingTag] = useState(false);
  const [showTagFilter, setShowTagFilter] = useState(false);

  const clearSearch = useCallback(() => {
    dispatch(setEntitiesSearchQuery(''));
    dispatch(setEntityLoadingStatus(false));
  }, [dispatch]);

  useEffect(() => {
    setIsTogglingTag(false);
  }, [savedTags]);

  const toggleSearchOpen = () => {
    isEntitySearchOpen && clearSearch();
    dispatch(setIsEntitySearchOpen(!isEntitySearchOpen));
  };

  const initiateRole = () => {
    dispatch(setNewEntityType('role'));
  };

  const updateSavedTags = (tagId: string) => {
    const allTags = {
      ...tagsById,
      ...keyBy(savedTags, 'id'),
    };
    setIsTogglingTag(true);
    toggleSaveTag(dispatch, {
      organizationId: selectedOrgId,
      savedTags,
      tag: allTags[tagId],
    });
  };

  const initiateTeam = () => {
    dispatch(setNewEntityType('team'));
  };

  const myRolesOpen = selectedCategory === MYROLES;
  const myTeamsOpen = selectedCategory === MYTEAMS;
  const allTagsOpen = selectedCategory === ALL;
  const notTaggedOpen = selectedCategory === NOTTAGGED;
  const checkedTagsIds = useSelector(selectCheckedTagsIds);
  const activeTags = checkedTagsIds.length ? checkedTagsIds : selectedTag ? [selectedTag.id] : [];
  const savedTagIds = savedTags.map((t) => t.id);
  const tagIsSaved = activeTags.length && savedTagIds.includes(activeTags[0]);
  const shouldShowSaveTagButton =
    !myRolesOpen &&
    !myTeamsOpen &&
    activeTags.length === 1 &&
    checkedTagsIds[0] !== notTaggedTag.id;
  const shouldShowTagFilterButton =
    (myRolesOpen || myTeamsOpen) && Object.keys(tagsById).length > 2;
  const shouldShowAddRoleButton =
    isRolesAdmin && isRolesEnabled && !myRolesOpen && !myTeamsOpen && checkedTagsIds.length < 2;
  const shouldShowAddTeamButton =
    isTeamAdmin && isTeamsEnabled && !myRolesOpen && !myTeamsOpen && checkedTagsIds.length < 2;
  const shouldShowBatchActionButtons =
    (isRolesAdmin || isTeamAdmin) && !myRolesOpen && !myTeamsOpen && checkedEntityIds.length > 0;

  return (
    <div
      className={classes('roles-header', {
        withFilterBar: (isTeamsEnabled && isRolesEnabled) || myRolesOpen || myTeamsOpen,
      })}
      data-test-id="entities header"
    >
      <div
        className={classes('filters-and-action')}
        data-test-id="entities header filters and actions"
      >
        <div className={classes('selected-or-checked-tag-container')}>
          {allTagsOpen && (
            <TagItem customStyles={'tag-selected-name'} name={ALL} useType="display" />
          )}
          {myRolesOpen && <TagItem name={MYROLES} customStyles={'tag-selected-name'} />}
          {myTeamsOpen && <TagItem name={MYTEAMS} customStyles={'tag-selected-name'} />}
          {notTaggedOpen && (
            <TagItem name={NOTTAGGED} customStyles={'tag-selected-name'} useType="display" />
          )}
          <SelectedTags
            checkedTagsById={selectedTag ? { [selectedTag.id]: selectedTag } : checkedTagsById}
          />
        </div>
        {currentOrganization && currentOrganization.id !== selectedOrgId && (
          <div className={classes('org-peek-container')}>
            <div
              className={classes('org-peek')}
              onClick={() => {
                dispatch(
                  setSelectedOrganization({
                    id: currentOrganization.id,
                    name: currentOrganization.name,
                  })
                );
              }}
            >
              <span>Currently viewing:</span> {selectedOrgName}
            </div>
          </div>
        )}
        <div className={classes('roles-actions')}>
          {shouldShowBatchActionButtons && (
            <div className={classes('action')}>
              <ToolTip
                text="Batch Actions"
                textAlign={'center'}
                getManualOffset={() => {
                  '0px';
                }}
              >
                <button
                  className={classes('batch-tag-icon')}
                  onClick={() => setBatchDropdownOpen(!batchDropdownOpen)}
                  data-test-id="open batch actions"
                >
                  <TagIcon />
                  <div
                    className={classnames(classes('caret-down'), classes('batch-dropdown-caret'))}
                  />
                </button>
              </ToolTip>
              {batchDropdownOpen && (
                <div className={classes('batch-actions-dropdown')}>
                  <Dropdown
                    ariaLabel={'batch action dropdown'}
                    triggerHandler={() => setBatchDropdownOpen(!batchDropdownOpen)}
                  >
                    <div>
                      <div
                        className={classes('batch-dropdown-item')}
                        onClick={() => {
                          setBatchDropdownOpen(!batchDropdownOpen);
                          dispatch(setModal({ name: 'removeTag' }));
                        }}
                      >
                        Remove tag
                      </div>
                      <div
                        className={classes('batch-dropdown-item')}
                        onClick={() => {
                          setBatchDropdownOpen(!batchDropdownOpen);
                          dispatch(setModal({ name: 'tagSelector' }));
                        }}
                      >
                        Switch tag
                      </div>
                    </div>
                  </Dropdown>
                </div>
              )}
            </div>
          )}
          {(entityResultsCount > 0 || entitiesSearchQuery) && (
            <div className={classes('action')}>
              <ToolTip
                text={`Search ${activeTab}`}
                textAlign={'center'}
                getManualOffset={() => ({})}
              >
                <button
                  className={classes('action-icon', {
                    open: isEntitySearchOpen,
                  })}
                  onClick={() => toggleSearchOpen()}
                  data-test-id="search"
                >
                  <SearchIcon />
                </button>
              </ToolTip>
            </div>
          )}
          {shouldShowTagFilterButton && (
            <div className={classes('action')}>
              <ToolTip
                text={filteredTags.length > 0 ? 'Edit Filter' : 'Filter By Tags'}
                textAlign={'center'}
                hideArrow={true}
                getManualOffset={() => ({ left: '-10px' })}
              >
                <button
                  className={classes('action-icon', { open: showTagFilter })}
                  onClick={() => setShowTagFilter(!showTagFilter)}
                  data-test-id="Tag Filter"
                >
                  <FilterIcon />
                </button>
              </ToolTip>
              {showTagFilter && (
                <TagFilter
                  onClear={() => dispatch(setFilteredTags([]))}
                  onChange={(tags: Tag[]) => dispatch(setFilteredTags(tags))}
                  onOutsideClick={() => setShowTagFilter(false)}
                  savedTags={savedTags}
                  selectedTags={filteredTags}
                />
              )}
            </div>
          )}
          {shouldShowSaveTagButton && (
            <div className={classes('action')}>
              <ToolTip
                text={`${tagIsSaved ? 'Unsave' : 'Save'} Tag`}
                textAlign={'center'}
                getManualOffset={() => {
                  '0px';
                }}
              >
                <button
                  className={classes('action-icon', {
                    gold: true,
                    goldOpen: tagIsSaved,
                    disabled: isTogglingTag,
                  })}
                  onClick={() => !isTogglingTag && updateSavedTags(activeTags[0])}
                  data-test-id="save tag"
                >
                  {isTogglingTag ? (
                    <DotsIndicator size={6} color={tagIsSaved ? '#fff' : '#9eb1bd'} />
                  ) : (
                    <BookmarkIcon />
                  )}
                </button>
              </ToolTip>
            </div>
          )}
          {shouldShowAddTeamButton && (
            <div className={classes('action')}>
              <ToolTip
                text="New Team"
                textAlign={'center'}
                getManualOffset={() => {
                  '0px';
                }}
              >
                <button
                  className={classes('plus-icon')}
                  onClick={initiateTeam}
                  data-test-id="addNewTeam"
                >
                  <TeamIcon className={classes('entity-icon')} />
                  <PlusIcon />
                </button>
              </ToolTip>
            </div>
          )}
          {shouldShowAddRoleButton && (
            <div className={classes('action')}>
              <ToolTip
                text="New Role"
                textAlign={'center'}
                getManualOffset={() => {
                  '0px';
                }}
              >
                <button
                  className={classes('plus-icon')}
                  onClick={initiateRole}
                  data-test-id="addNewRole"
                >
                  <RoleIcon className={classes('entity-icon')} />
                  <PlusIcon />
                </button>
              </ToolTip>
            </div>
          )}
        </div>
      </div>
      {filteredTags.length > 0 && (myRolesOpen || myTeamsOpen) && (
        <div className={classes('tag-filter-message')}>
          Items filtered by {filteredTags.length} tag{filteredTags.length !== 1 ? 's' : ''}:
        </div>
      )}
      <div className={classes('search-container')}>
        {isEntitySearchOpen && (
          <CollaborationSearch
            actionFn={setEntitiesSearchQuery}
            query={entitiesSearchQuery || ''}
            placeholder={`Search ${activeTab}`}
          />
        )}
      </div>
    </div>
  );
}

export default reduxInjectSelect<EntitiesHeaderProps, ReduxProps, ReduxState>(reduxSelectors)(
  EntitiesHeader
);
