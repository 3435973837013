import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import propTypes from '../propTypes';
import BEM from '../bem';
const classes = BEM.with('GroupName');

const GroupName = ({ group, className, ariaLabel, ...restProps }) => {
  className = classNames(classes(), className);
  return (
    <span className={className} aria-label={ariaLabel} {...restProps} title={group.displayName}>
      {group.displayName}
    </span>
  );
};

GroupName.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  group: propTypes.group.isRequired,
};

export default observer(GroupName);
