import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { BasicModal } from '../..';

const { setModal } = actions;
const { clearRolesSchedule } = thunk;

export default function ClearRolesSchedulerModal({ isOpen }: { isOpen: boolean }) {
  const dispatch = useDispatch();
  const openModal = useSelector((state: ReduxState) => state.session.openModal);
  const organizationId = useSelector((state: ReduxState) => state.collab.selectedOrgId);
  const [successful, setSuccessful] = useState<boolean | undefined>(undefined);

  const closeModal = () => {
    dispatch(setModal(undefined));
  };

  const submit = async () => {
    if (successful) return closeModal();
    const wasSuccessful = await clearRolesSchedule(dispatch, organizationId);
    setSuccessful(wasSuccessful);
  };

  useEffect(() => {
    if (openModal?.name === 'clearScheduler') setSuccessful(undefined);
  }, [openModal]);

  return (
    <BasicModal
      ariaLabelBody={'Clear Roles Scheduler Info'}
      ariaLabelCancelButton={'Clear Roles Scheduler Cancel'}
      ariaLabelCloseButton={'Clear Roles Scheduler Close'}
      ariaLabelHeader={'Clear Roles Scheduler Header'}
      ariaLabelSubmitButton={'Confirm Clear Roles Scheduler'}
      bodyText={
        successful
          ? 'Schedule cleared successfully'
          : 'Are you sure you want to clear the schedule?'
      }
      hideCancel={successful}
      headerText={'Clear Existing Schedule'}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={submit}
      size={'medium'}
      theme={successful ? 'normal' : 'danger'}
      submitText={successful ? 'OK' : 'CLEAR SCHEDULE'}
      hasCloseButton={true}
      useWCL
    />
  );
}
