import React, { useState, useEffect, useCallback, useRef } from 'react';
import { batch } from 'react-redux';
import classNames from 'classnames';
import { debounce } from 'lodash';
import TCClient from '../../../client';

import { ActionBar } from '../../shared-components/ActionBar/ActionBar';
import { CtaButton } from '../../shared-components/CtaButton/CtaButton';
import { Table } from '../../shared-components/Table/Table';
import { Toast } from '../../shared-components/Toast/Toast';

import styles from './Organization/OrganizationForm.module.css';
import { actions, useAppDispatch } from 'redux-stores';

const { appendOrganization, setSelectedPage, setSelectedOrganizationId } = actions;

type OrganizationsTableProps = {
  isCreateMode: boolean;
  isSuccessToastOpen: boolean;
  setIsCreateMode: (isCreateMode: boolean) => void;
  setIsSuccessToastOpen: (isSuccessToastOpen: boolean) => void;
};

type EntityData = {
  billing: string;
  createdDate: string;
  emailDomains: string;
  name: string;
  orgType: string;
  token: string;
  totalMembers: number;
};

type OrgRow = {
  id: string;
  orgName: string;
  orgType: string;
  orgId: string;
  memberCount: number;
  status: string;
  domainName?: string;
};

type ListResults = {
  entity: EntityData;
  organizationId: string;
  type: string;
};

type OrganizationsList = {
  metadata: {
    continuation: string;
    first_hit: number;
    total_hits: number;
  };
  results: Array<ListResults>;
};

export default function OrganizationsTable({
  isSuccessToastOpen,
  setIsCreateMode,
  setIsSuccessToastOpen,
}: OrganizationsTableProps) {
  const [rows, setRows] = useState<OrgRow[]>([]);
  const [isPaginated, setIsPaginated] = useState(false);
  const [continuation, setContinuation] = useState('');
  const [queryValue, setQueryValue] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const debounceQuery = useRef(debounce(setQueryValue, 500));
  const dispatch = useAppDispatch();

  const updateSearchInputValue = (text: string) => {
    setSearchInputValue(text);
    debounceQuery.current(text);
  };

  const setRowData = useCallback(
    ({ organizationsList, rows }: { organizationsList: OrganizationsList; rows: OrgRow[] }) => {
      const rowsSnapshot = [...rows];
      if (organizationsList.metadata.continuation) {
        setIsPaginated(true);
        setContinuation(organizationsList.metadata.continuation);
      } else {
        setIsPaginated(false);
      }
      organizationsList.results.forEach((item) => {
        const domainName = Array.isArray(item.entity.emailDomains)
          ? item.entity.emailDomains.join(`\n`)
          : item.entity.emailDomains;
        const memberCount = item.entity.totalMembers;
        const orgId = item.entity.token;
        const orgName = item.entity.name;
        const orgType = item.entity.orgType;
        const status = item.entity.billing;
        const id = item.entity.token;
        rowsSnapshot.push({ id, orgName, orgType, orgId, memberCount, status, domainName });
      });
      setRows(rowsSnapshot);
    },
    [setContinuation, setRows]
  );

  const getTableData = useCallback(
    async ({
      rows,
      continuation,
      event,
      currentQueryValue,
    }: {
      rows: OrgRow[];
      continuation?: string;
      event?: React.ChangeEvent<HTMLInputElement>;
      currentQueryValue?: string;
    }) => {
      if (!currentQueryValue) {
        const query = event?.target?.value;
        setQueryValue(query ?? '');
      } else {
        setQueryValue(currentQueryValue);
      }
      await TCClient.search
        .queryOrgs({
          ...(continuation ? { continuation } : null),
          query: queryValue,
        })
        .then((response: OrganizationsList) => {
          setRowData({ organizationsList: response, rows });
        });
    },
    [queryValue, setRowData]
  );

  useEffect(() => {
    getTableData({ continuation: '', rows: [], currentQueryValue: queryValue });
  }, [queryValue, getTableData]);

  const columns = [
    { field: 'orgName', headerName: 'Org Name' },
    { field: 'orgType', headerName: 'Org Type' },
    { field: 'orgId', headerName: 'Org Id' },
    { field: 'memberCount', headerName: '# of Members' },
    { field: 'status', headerName: 'Status' },
    {
      field: 'domainName',
      headerName: 'Domain Name',
      customCellStyles: { whiteSpace: 'pre-line' },
    },
  ];

  const onRowEdit = (row: { orgName: string; orgId: string; memberCount: number }) => {
    const organization = {
      admin: true,
      name: row.orgName,
      token: row.orgId,
      totalMembers: row.memberCount,
    };
    batch(() => {
      dispatch(appendOrganization({ organization }));
      dispatch(setSelectedOrganizationId({ selectedOrganizationId: organization.token }));
      dispatch(setSelectedPage({ selectedPage: 'Org Settings' }));
    });
  };

  const actions = [{ name: 'Edit', type: 'edit' }];

  return (
    <div className={classNames(styles.orgTable)}>
      <ActionBar
        hasSearch
        onInputChange={(e) => updateSearchInputValue(e.target.value)}
        searchValue={searchInputValue}
        dataTestId={'orgSearch'}
        handleClearClick={() => {
          getTableData({ continuation: '', rows: [] });
        }}
      />
      <div className={classNames(styles.createButton)}>
        <CtaButton primary label={'Create New Org'} onClick={() => setIsCreateMode(true)} />
      </div>
      {isSuccessToastOpen && (
        <Toast
          message={'Org Successfully Created'}
          type={'SUCCESS'}
          open={isSuccessToastOpen}
          onClose={() => {
            setIsSuccessToastOpen(false);
          }}
          autoHideDuration={2000}
        />
      )}
      <Table
        actionsOrder={actions}
        columns={columns}
        onPaginate={() => getTableData({ continuation, rows, currentQueryValue: queryValue })}
        onRowClick={onRowEdit}
        onRowEdit={onRowEdit}
        paginated={isPaginated}
        rows={rows}
      />
    </div>
  );
}
