import React, { Component } from 'react';
import BEM from '../bem';

const classes = BEM.with('Spinner');

class Spinner extends Component {
  render() {
    const props = this.props;
    const bars = [];

    for (let i = 0; i < 12; i++) {
      bars.push(<div className={classes(`bar-${i}`)} key={i} />);
    }

    return (
      <div {...props} className={classes()}>
        {bars}
      </div>
    );
  }
}

export default Spinner;
