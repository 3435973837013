import React from 'react';

import mobxInjectSelect from '../../../../common/utils/mobxInjectSelect';
import { CtaButton } from '../../../shared-components/CtaButton/CtaButton';
import { StandardOrgSettingInput } from '../../../shared-components/StandardSettingInput';
import { ViewContainer } from '../../../shared-components/View/ViewContainer/ViewContainer';
import { OrganizationSetting } from '../../../types';
import styles from '../OrganizationSettings.module.css';

import { TabProps } from './';
import { useAppSelector } from 'redux-stores';

type MobxProps = {
  featureFlags: Record<string, unknown>;
  getURLParams: () => Record<string, string>;
};

type featureFlagProps = {
  featureFlagDependency: {
    featureFlags: Record<string, unknown>;
    urlParams: Record<string, string>;
  };
};

const orgFeatureMap = [
  {
    name: 'Reporting - Transactions',
    key: 'featureTransaction',
  },
  {
    name: 'Reporting - Insights',
    key: 'featureInsights',
  },
  {
    name: 'Delivery Escalations',
    key: 'featureDeliveryEscalation',
  },
  {
    name: 'Restrict Contacts Org Messaging',
    key: 'featureRestrictContactsOrg',
  },
  {
    name: 'Hide mobile # for outbound calls',
    key: 'featureHidePersonalMobile',
  },
  {
    name: 'Customized Fast Deploy Interface',
    key: 'featureCustomFastDeploy',
  },
  {
    name: 'DND Auto Forwarding',
    key: 'featureDndAutoForward',
  },
  {
    name: 'Priority Messaging',
    key: 'featurePriorityMessaging',
  },
  {
    name: 'Roles',
    key: 'featureRoles',
  },
  {
    name: 'Roles Escalation',
    key: 'featureRolesEscalation',
    isDisabled: (allOrgSettings: OrganizationSetting) => {
      return !allOrgSettings.featureRoles;
    },
  },
  {
    name: 'Roles Schedule Upload Tool',
    key: 'featureRolesScheduleUpload',
    isDisabled: (allOrgSettings: OrganizationSetting) => {
      return !allOrgSettings.featureRoles;
    },
  },
  {
    name: 'Click To Call',
    key: 'featureClickToCall',
  },
  {
    name: 'VoIP Audio Calling',
    key: 'featureVoipCalling',
  },
  {
    name: 'Group Audio Calling',
    key: 'featureGroupAudioCalling',
    isDisabled: (allOrgSettings: OrganizationSetting) => {
      return !allOrgSettings.featureVoipCalling;
    },
  },
  {
    name: 'Video Calling',
    key: 'featureVideoCalling',
  },
  {
    name: 'Group Video Calling',
    key: 'featureGroupVideoCalling',
    isDisabled: (allOrgSettings: OrganizationSetting) => {
      return !allOrgSettings.featureVideoCalling;
    },
  },
  {
    name: 'Roles Calendar Views',
    key: 'featureRolesCalendarView',
  },
  {
    name: 'Hide Fast Deploy URL',
    key: 'featureHideFastDeployUrl',
  },
  {
    name: 'Hide Delivery Escalations URL',
    key: 'featureHideDeliveryEscalationUrl',
  },
  {
    name: 'Disable sharing photos/videos from device',
    key: 'featurePhotoVideoSharingDisabled',
  },
  {
    name: 'Disable sharing attachments from device',
    key: 'featureAttachmentSharingDisabled',
  },
  {
    name: 'TigerConnect Physician Scheduling',
    key: 'featureTigerSchedule',
  },
  {
    name: 'Teams',
    key: 'featureTeams',
  },
  {
    name: 'Enterprise PBX Integration',
    key: 'featureEnterprisePbx',
  },
  {
    name: 'TigerConnect Patient Engagement',
    key: 'featurePatientEngagement',
  },
  {
    name: 'TigerConnect Patient Engagement - Disable sharing photos/videos from device',
    key: 'featurePatientPhotoVideoSharingDisabled',
  },
  {
    name: 'TigerConnect Patient Engagement - Disable sharing attachments from device',
    key: 'featurePatientAttachmentSharingDisabled',
  },
  {
    name: 'TigerConnect Patient Engagement Patient Quick Add',
    key: 'featurePatientQuickAdd',
  },
  {
    name: 'TigerConnect Patient Engagement Patient Quick Call',
    key: 'featurePatientQuickCall',
    isDisabled: (allOrgSettings: OrganizationSetting) => {
      return !allOrgSettings.featureClickToCall && !!allOrgSettings.click2CallNumberMaskProxyNumber;
    },
  },
  {
    name: 'TigerConnect Patient Engagement Patient Quick Call Video',
    key: 'featurePatientQuickCallVideo',
  },
  {
    name: 'TigerConnect Patient Engagement Voice Calling',
    key: 'featurePatientVoiceCalling',
    isVisible: ({ featureFlagDependency }: featureFlagProps) => {
      const { urlParams } = featureFlagDependency;
      return urlParams.pfVoiceCall === 'true';
    },
  },
  {
    name: 'TigerConnect Patient Engagement Group Voice Calling',
    key: 'featurePatientGroupVoiceCalling',
    isVisible: ({ featureFlagDependency }: featureFlagProps) => {
      const { urlParams } = featureFlagDependency;
      return urlParams.pfGroupVoiceCall === 'true';
    },
  },
  {
    name: 'TigerConnect Patient Engagement Patient List Access',
    key: 'featurePatientListAccess',
  },
  {
    name: 'TigerConnect Patient Engagement Video Calling',
    key: 'featurePatientVideoCalling',
  },
  {
    name: 'TigerConnect Patient Engagement Group Video Calling',
    key: 'featurePatientGroupVideoCalling',
  },
  {
    name: 'TigerConnect Patient Engagement Schedule Messages',
    key: 'featurePatientScheduledMessages',
    isDisabled: (allOrgSettings: OrganizationSetting) => {
      return !allOrgSettings.featurePatientEngagement;
    },
  },
  {
    name: 'TigerConnect Patient Engagement Automated Appointment Reminders',
    key: 'featurePatientAppointmentReminders',
    isDisabled: (allOrgSettings: OrganizationSetting) => {
      return !allOrgSettings.featurePatientScheduledMessages;
    },
  },
  {
    name: 'TigerConnect Patient Engagement Virtual Waiting Room',
    key: 'featurePatientVirtualWaitingRoom',
    isDisabled: (allOrgSettings: OrganizationSetting) => {
      return !allOrgSettings.featurePatientEngagement;
    },
  },
  {
    name: 'TigerConnect Patient Engagement Patient Broadcasting',
    key: 'featurePatientBroadcasting',
    isDisabled: (allOrgSettings: OrganizationSetting) => {
      return !allOrgSettings.featurePatientEngagement;
    },
  },
  {
    name: 'TigerConnect Patient Context Messaging',
    key: 'featurePatientContext',
  },
];

function Premium({ onClose, onSave, featureFlags, getURLParams }: TabProps & MobxProps) {
  const allOrgSettings = useAppSelector((state) => state.orgSettings.settings);
  const urlParams = getURLParams();
  const featureFlagDependency = { urlParams, featureFlags };

  return (
    <ViewContainer
      {...{
        header: 'Premium Features',
        subHeader: "Select the organization's premium features.",
        onClose,
      }}
    >
      <div>
        {orgFeatureMap
          .filter((feature) =>
            feature.isVisible ? feature.isVisible({ featureFlagDependency }) : true
          )
          .map((feature) => {
            return (
              <div key={feature.name}>
                <StandardOrgSettingInput
                  settingName={feature.key as keyof OrganizationSetting}
                  type="checkbox"
                  disabled={feature.isDisabled ? feature.isDisabled(allOrgSettings) : false}
                  multiple
                />
                <span className={styles.premiumOrgFeatureEntry}>{feature.name}</span>
              </div>
            );
          })}
        <div className={styles.premiumSaveButton}>
          <CtaButton primary label="Save" onClick={onSave} />
        </div>
      </div>
    </ViewContainer>
  );
}

export default mobxInjectSelect<TabProps, MobxProps>({
  featureStore: ['featureFlags', 'getURLParams'],
})(Premium);
