import React, { useEffect, useState } from 'react';
import { actions, useAppDispatch } from 'redux-stores';
import BEM from 'common/bem';
import { Button, Modal } from 'common/components/WebComponents';
import CustomResponseList, {
  Response,
} from 'common/components/CustomResponseList/CustomResponseList';
import {
  updateProfileQueryData,
  useGetPreferencesQuery,
  useUpdatePreferencesMutation,
} from 'redux-stores/profile/profileApi';
import { mobxInjectSelect } from 'common/utils';
const CHARACTER_LIMIT = 512;

const classes = BEM.with('CustomDndMessageModal');

type CustomDndMessageModalProps = {
  isOpen: boolean;
  options: { justEdit: boolean };
};

type MobxProps = {
  update: ({ dndText }: { dndText: string }) => void;
  logPendoAnalytics: (data: { tracker: { name: string; props?: Record<string, unknown> } }) => void;
  currentUser: { dndText: string };
};

function CustomDndMessageModal({
  currentUser,
  isOpen,
  update,
  options: { justEdit },
  logPendoAnalytics,
}: CustomDndMessageModalProps & MobxProps) {
  const dispatch = useAppDispatch();
  const [quickCustomMessage, setQuickCustomMessage] = useState(currentUser?.dndText || '');
  const [editMode, setEditMode] = useState(false);
  const [isBtnSaveDisabled, setBtnSaveDisabled] = useState(false);
  const { data, isLoading, refetch } = useGetPreferencesQuery('');
  const dndText = data?.dnd_text_templates.map((text: string, idx: number) => ({
    id: String(idx),
    text,
  }));
  const [updatePreferences] = useUpdatePreferencesMutation();

  useEffect(() => {
    if (isOpen) {
      refetch();
      setEditMode(justEdit);
    }
  }, [isOpen, justEdit, refetch]);

  function closeModal() {
    setQuickCustomMessage(currentUser?.dndText || '');
    dispatch(actions.setModal(undefined));
  }

  function handleEdits(replies: Response[]) {
    const dndTextTemplates = replies?.map(({ text }) => text);
    dispatch(
      updateProfileQueryData('getPreferences', '', (draft) => {
        draft.dnd_text_templates = dndTextTemplates;
      })
    );
  }

  async function handleCancel() {
    if (justEdit) {
      closeModal();
      return;
    }
    await refetch();
    setEditMode(false);
  }

  async function submitCustomDndTemplates() {
    const dndTextTemplates = data?.dnd_text_templates.filter((text) => text.trim() !== '');
    await updatePreferences({ dndTextTemplates });
    logPendoAnalytics({
      tracker: {
        name: 'Admin + Settings | Settings - Saved Unavailable Messages Edit',
        props: {
          SavedUnavailableMessageCount: dndTextTemplates?.length || 0,
        },
      },
    });
    if (justEdit) {
      closeModal();
      return;
    }
    setEditMode(false);
  }

  async function submitCustomMessage(dndText: string) {
    await update({ dndText });
    logPendoAnalytics({
      tracker: {
        name: 'Admin + Settings | Settings - Unavailable Message Set',
        props: {
          UnavailableMessageType: dndText === quickCustomMessage ? 'Custom' : 'Saved',
        },
      },
    });
    closeModal();
  }

  return (
    <Modal
      closeButtonDataTestId="CustomDndMessage Close"
      headerDataTestId="CustomDndMessage Header"
      submitDataTestId="CustomDndMessage Submit"
      headerText={`${editMode ? 'Manage' : 'Select'} Unavailable Message${editMode ? 's' : ''}`}
      isOpen={isOpen}
      onClose={closeModal}
      submitText="APPLY"
      hasCloseButton
      size={'medium'}
    >
      <div className={classes()}>
        <div className={classes('custom-message-list-container')}>
          <CustomResponseList
            characterLimit={CHARACTER_LIMIT}
            responses={dndText}
            isLoading={isLoading}
            onResponseClick={submitCustomMessage}
            setResponses={editMode ? handleEdits : undefined}
            onEditButtonClick={() => setEditMode(true)}
            maxHeight={'300px'}
            setBtnSaveDisabled={setBtnSaveDisabled}
          />
        </div>
        {editMode ? (
          <div className={classes('edit-mode-buttons')}>
            <Button
              data-test-id={'cancel-customdndtext-edit-mode'}
              onClick={handleCancel}
              color="neutral"
              label={'CANCEL'}
              outline
              alignment="right"
            />
            <Button
              color={'secondary'}
              data-test-id={'submit-customdndtext-changes'}
              disabled={isBtnSaveDisabled}
              onClick={submitCustomDndTemplates}
              label={'SAVE'}
              outline
            />
          </div>
        ) : (
          <div className={classes('quick-custom-message-container')}>
            <h4>Custom Message</h4>
            <textarea
              rows={2}
              placeholder="Write your custom message here..."
              value={quickCustomMessage}
              maxLength={CHARACTER_LIMIT}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setQuickCustomMessage(e.target.value)
              }
            />
            <div className={classes('character-counter')}>
              {quickCustomMessage.length}/{CHARACTER_LIMIT}
            </div>
            <Button
              label="APPLY"
              onClick={() => submitCustomMessage(quickCustomMessage)}
              dataTestId="apply-quick-custom-message"
              disabled={!quickCustomMessage || quickCustomMessage.trim().length === 0}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default mobxInjectSelect<CustomDndMessageModalProps, MobxProps>({
  userStore: ['update'],
  sessionStore: ['currentUser'],
  trackerStore: ['logPendoAnalytics'],
})(CustomDndMessageModal);
