import React, { useMemo, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import { debounce, last } from 'lodash';
import { useSelector } from 'react-redux';
import { Card } from '@tigerconnect/web-component-library';
import TCClient from '../../../../client';
import { ReduxState } from '../../../../redux-stores';
import {
  Role as RoleEntity,
  Individual as IndividualEntity,
} from '../../../../widgets/messenger/components/SearchSidebar/SearchParityResults';
import { Role, SearchQueryOptions, SearchResult, User } from '../../../../types';
import BEM from '../../../bem';
import { DotsIndicator, Dropdown } from '../../';
import CollaborationSearch from '../CollaborationSearch';
import { mobxInjectSelect } from '../../../utils';
import { groupByUserAndRoleEntities } from '../../../utils/groupByUserAndRoleEntities';

const classes = BEM.with('CollaborationEscalationModal');
const selectorClasses = BEM.with('CollaborationSelector');

type MobxProps = {
  currentUserId: string;
  hideDnd: boolean;
  isAvailabilityFeatureFlagEnabled: boolean;
  isPresenceFeatureFlagEnabled: boolean;
};

type BackupSelectorProps = {
  backupOneToken: string;
  backupTwoToken: string;
  ogToken: string;
  saveHandler: (selector: number, name: string, id: string) => void;
  selector: number;
  toggleHandler: () => void;
};

function BackupSelector({
  backupOneToken,
  backupTwoToken,
  currentUserId,
  hideDnd,
  isAvailabilityFeatureFlagEnabled,
  isPresenceFeatureFlagEnabled,
  ogToken,
  saveHandler,
  selector,
  toggleHandler,
}: BackupSelectorProps & MobxProps) {
  const [searchResults, setSearchResults] = useState<(User | Role)[]>([]);
  const [activeTargetSearch, setActiveTargetSearch] = useState(0);
  const [query, setQuery] = useState('');
  const selectedOrgId = useSelector((state: ReduxState) => state.collab.selectedOrgId);
  const [isLoading, setIsLoading] = useState(false);

  const sortOrder = useRef<Map<string, number>>(new Map([]));
  const continuation = useRef<string | undefined>('');
  const fetchEnd = useRef(false);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveTargetSearch(selector);
    setQuery(e.target.value);
    setIsLoading(false);
    continuation.current = '';
    fetchEnd.current = false;
    if (e.target.value) handleSearchQuery(e.target.value);
    else setSearchResults([]);
  };

  const handleSearchQuery = useMemo(
    () =>
      debounce((val: string, scrolling?: boolean) => {
        const searchTags = async (val: string) => {
          const searchOptions: SearchQueryOptions = {
            version: 'SEARCH_PARITY',
            query: { any: val, display_name: val },
            organizationId: selectedOrgId,
            types: ['user'],
            sort: isPresenceFeatureFlagEnabled
              ? ['presenceStatus', 'displayName']
              : ['displayName'],
            ...(scrolling && continuation.current && { continuation: continuation.current }),
          };

          const {
            results,
            metadata: { continuation: nextContinuation, entityOrder },
          } = await TCClient.search.query<SearchResult>(searchOptions);

          continuation.current = nextContinuation;
          if (!nextContinuation) fetchEnd.current = true;

          if (entityOrder) {
            const lastIndex = sortOrder.current.size;
            const mergedOrder = entityOrder
              .filter((k) => !sortOrder.current.has(k))
              .map((k, i) => [k, i + lastIndex] as readonly [string, number]);
            sortOrder.current = new Map(mergedOrder);
          }

          const data = results
            .filter((r: SearchResult) => r.entity.accountStatus === 'active')
            .map((r: SearchResult) => r.entity as User)
            .filter(
              (u: User | Role) =>
                ![
                  'ccd0b6da-e5c8-40fe-9708-828fa8fef035',
                  'e5ef47bd-6ab9-4ece-9b3e-aa477056d086',
                ].includes(u.id)
            )
            .filter((e: User | Role) => {
              const entityId = last(e.id.split(':'));
              return e.id !== ogToken && entityId !== backupTwoToken && entityId !== backupOneToken;
            });

          if (scrolling) {
            setSearchResults((_results) => _results.concat(data));
            setIsLoading(false);
          } else setSearchResults(data);
        };
        searchTags(val);
      }, 500),
    [backupOneToken, backupTwoToken, isPresenceFeatureFlagEnabled, ogToken, selectedOrgId]
  );

  const scrollTimeoutMS = 500;
  const handleScroll = throttle(({ target }: React.ChangeEvent<HTMLElement>) => {
    const { clientHeight, scrollHeight, scrollTop } = target;
    const scrollRatio = 0.8;
    if (
      clientHeight / (scrollHeight - scrollTop) > scrollRatio &&
      !fetchEnd.current &&
      !isLoading
    ) {
      setIsLoading(true);
      handleSearchQuery(query, true);
    }
  }, scrollTimeoutMS);

  const groupedEntities = groupByUserAndRoleEntities(searchResults, sortOrder);

  return (
    <div className={selectorClasses('', { coverInput: true, large: true })}>
      <Dropdown
        ariaLabel="select-backup"
        triggerHandler={toggleHandler}
        handleScroll={handleScroll}
      >
        <div>
          <div className={classes('search-container')}>
            <CollaborationSearch
              clearInputHandler={() => {
                setQuery('');
                setSearchResults([]);
              }}
              onChangeHandler={onChangeHandler}
              placeholder={'Search Members'}
              query={query}
            />
          </div>
          {activeTargetSearch === selector && (
            <div>
              {groupedEntities.map((entities) => {
                if (entities.entity_type === 'user' && entities.values.length > 0) {
                  return (
                    <Card header="Individuals" theme="provider" type="category">
                      {entities.values.map((individual, index) => {
                        return (
                          <div
                            key={individual.id}
                            onClick={() => {
                              saveHandler(selector, individual.displayName, individual.id);
                            }}
                            className={classes('target-item', {
                              divider: index < entities.values.length - 1,
                            })}
                          >
                            <IndividualEntity
                              currentUserId={currentUserId}
                              hideDnd={hideDnd}
                              individual={
                                individual as unknown as React.ComponentProps<
                                  typeof IndividualEntity
                                >['individual']
                              }
                              isPresenceFeatureFlagEnabled={isPresenceFeatureFlagEnabled}
                              isAvailabilityFeatureFlagEnabled={isAvailabilityFeatureFlagEnabled}
                              showPresenceIndicator={true}
                              presenceSize={'small'}
                            />
                          </div>
                        );
                      })}
                    </Card>
                  );
                } else if (entities.entity_type === 'role' && entities.values.length > 0) {
                  return (
                    <Card header="Roles" theme="provider" type="category">
                      {entities.values.map((role) => {
                        return (
                          <div
                            key={role.id}
                            onClick={() => {
                              saveHandler(selector, role.displayName, role.id);
                            }}
                            className={classes('target-item')}
                          >
                            <RoleEntity
                              role={
                                role as unknown as React.ComponentProps<typeof RoleEntity>['role']
                              }
                            />
                          </div>
                        );
                      })}
                    </Card>
                  );
                } else {
                  return null;
                }
              })}
              {isLoading && (
                <div className={selectorClasses('loading')}>
                  <DotsIndicator size={13} speed={0.5} />
                </div>
              )}
            </div>
          )}
        </div>
      </Dropdown>
    </div>
  );
}

export default mobxInjectSelect<BackupSelectorProps, MobxProps>({
  messengerStore: ['isPresenceFeatureFlagEnabled', 'isAvailabilityFeatureFlagEnabled'],
  sessionStore: ['currentUserId'],
  userStore: ['hideDnd'],
})(BackupSelector);
