import React from 'react';
import { observer } from 'mobx-react';
import { Message } from '../../../types';
import BEM from '../../bem';

const classes = BEM.with('AlertReactionBang');

export type AutoForwardBangProps = {
  message: Message;
  precededByDateSeparator: boolean;
};

const AutoForwardBang = ({ message, precededByDateSeparator }: AutoForwardBangProps) => {
  const { body } = message;

  return <div className={classes({ precededByDateSeparator })}>{body}</div>;
};

export default observer(AutoForwardBang);
