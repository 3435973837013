import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pick } from 'lodash';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { Tag } from '../../../../types';
import BEM from '../../../bem';
import { MapStateToProps, reduxInjectSelect } from '../../../utils/reduxInjectSelect';
import { BasicModal } from '../..';
import TagItem from '../Tags/TagItem';

const { setModal, setTagBeingEdited } = actions;
const { deleteTags } = thunk;
const classes = BEM.with('CollaborationModal');

const reduxSelectors = {
  session: ['openModal'],
  collab: ['selectedOrgId'],
  tags: ['checkedTagsById', 'selectedTag', 'tagBeingEdited', 'tagsById'],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type DeleteTagModalProps = {
  isOpen: boolean;
};

function DeleteTagModal({
  checkedTagsById,
  selectedOrgId,
  selectedTag,
  tagBeingEdited,
  tagsById,
  isOpen,
}: ReduxProps & DeleteTagModalProps) {
  const dispatch = useDispatch();
  const [entityCount, setEntityCount] = useState<number>(0);
  const [tagsToDelete, setTagsToDelete] = useState<Tag[]>([]);
  const styleType = entityCount > 0 ? 'danger' : 'normal';
  const closeModal = () => {
    dispatch(setModal(undefined));
    dispatch(setTagBeingEdited({ tag: undefined }));
  };

  const performDelete = async () => {
    await deleteTags(
      dispatch,
      tagsToDelete.map((t) => t.id.split(':')[1]),
      tagsById,
      selectedOrgId
    );
    closeModal();
  };

  useEffect(() => {
    if (tagBeingEdited) {
      setTagsToDelete([tagBeingEdited]);
      setEntityCount(tagBeingEdited.entityCount);
    } else {
      const ids = selectedTag ? [selectedTag.id] : Object.keys(checkedTagsById);
      const filteredTags = Object.values(pick(tagsById, ids));
      setTagsToDelete(filteredTags);
      setEntityCount(filteredTags.reduce((memo, tag) => memo + (tag?.entityCount || 0), 0));
    }
  }, [checkedTagsById, selectedTag, tagBeingEdited, tagsById]);

  return (
    <BasicModal
      ariaLabelBody={'Delete Tag Info'}
      ariaLabelCancelButton={'Delete Tag Cancel'}
      ariaLabelCloseButton={'Delete Tag Close'}
      ariaLabelHeader={'Delete Tag Header'}
      ariaLabelSubmitButton={'Confirm Delete Tag'}
      headerText={`Are you sure you want to delete ${
        tagsToDelete.length === 1 ? 'this tag' : 'these tags'
      }?`}
      modalType={'delete'}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={performDelete}
      size={'medium'}
      theme={styleType === 'danger' ? 'danger' : 'normal'}
      submitText={styleType === 'danger' ? 'DELETE' : 'OK'}
      useWCL
    >
      <div className={classes('')}>
        {
          <>
            {tagsToDelete.length >= 1 && (
              <>
                <div data-test-id={`tag delete name ${tagsToDelete[0].name}`}>
                  {`You are about to remove ${
                    tagsToDelete.length === 1
                      ? `${tagsToDelete.length} tag`
                      : `${tagsToDelete.length} tags`
                  } containing ${
                    entityCount > 1
                      ? `${entityCount} items. These items will no longer be tagged.`
                      : entityCount === 1
                      ? `${entityCount} item. This item will no longer be tagged.`
                      : `${entityCount} items`
                  }`}
                </div>
                {
                  <div className={classes('selected-preview-container')}>
                    <div className={classes('selected-preview-label')}>
                      Tags Selected ({tagsToDelete.length})
                    </div>
                    <div className={classes('selected-preview')}>
                      {tagsToDelete.map((tag, idx) => (
                        <div
                          key={tag.id}
                          className={classes('selected-preview-item', {
                            altBG: idx % 2,
                          })}
                        >
                          <div className={classes('selected-preview-tag')}>
                            <TagItem
                              name={tag.name}
                              colorHex={tag.color?.replace('0x', '#')}
                              isAdmin={true}
                              useType={'display'}
                            />
                          </div>
                          <div className={classes('selected-preview-role-count')}>
                            ({tag.entityCount || 0})
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                }
              </>
            )}
          </>
        }
      </div>
    </BasicModal>
  );
}

export default reduxInjectSelect<DeleteTagModalProps, ReduxProps, ReduxState>(reduxSelectors)(
  DeleteTagModal
);
