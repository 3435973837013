import React from 'react';
import { useDispatch } from 'react-redux';
import { Role } from '../../../../types';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { MapStateToProps, reduxInjectSelect } from '../../../utils/reduxInjectSelect';
import { BasicModal } from '../..';

const { setModal } = actions;
const { removePagerNumber } = thunk;

const reduxSelectors = {
  session: ['openModal'],
  collab: ['selectedOrgId'],
  entities: ['entitiesById', 'selectedEntity'],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type RemovePagerModalProps = {
  isOpen: boolean;
};

function RemovePagerModal({
  entitiesById,
  isOpen,
  selectedOrgId,
  selectedEntity,
}: ReduxProps & RemovePagerModalProps) {
  const dispatch = useDispatch();
  const selectedRole = selectedEntity as Role;

  const closeModal = () => {
    dispatch(setModal(undefined));
  };

  const removePager = async () => {
    if (selectedRole.orgPagers?.[0]) {
      await removePagerNumber(dispatch, {
        pagerNumber: selectedRole.orgPagers?.[0],
        roleId: selectedRole.id,
        organizationId: selectedOrgId,
        selectedRole: selectedRole,
        entitiesById,
      });
      closeModal();
    }
  };

  return (
    <BasicModal
      ariaLabelBody={'Remove Pager Info'}
      ariaLabelCancelButton={'Remove Pager Cancel'}
      ariaLabelCloseButton={'Remove Pager Close'}
      ariaLabelHeader={'Remove Pager Header'}
      ariaLabelSubmitButton={'Confirm Remove Pager'}
      bodyText={'Are you sure you want to remove this TigerPage number?'}
      headerText={'Delete TigerPage Number'}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={removePager}
      size={'medium'}
      theme={'danger'}
      submitText={'DELETE'}
      hasCloseButton={true}
      useWCL
    />
  );
}

export default reduxInjectSelect<RemovePagerModalProps, ReduxProps, ReduxState>(reduxSelectors)(
  RemovePagerModal
);
