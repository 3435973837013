import React, { useState, useRef, useCallback } from 'react';
import { Setting } from '@tigerconnect/web-component-library';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import BEM from '../../bem';
import { User } from '../../../types';
import { Button, Card, Switch } from '../WebComponents';
import { ReactComponent as RightChevron } from '../../images/status-message-selector.svg';
import { ChangePassword } from './ChangePassword';
import { actions, useAppDispatch } from 'redux-stores';

const classes = BEM.with('AccountSettings');

type MobxProps = {
  currentUser: User;
  isAvailabilityFeatureFlagEnabled: boolean;
  isDarkModeFeatureFlagEnabled: boolean;
  isDarkModeSwitchEnabled: boolean;
  isDesktopApp: boolean;
  isIdpUser: boolean;
  isSamlUser: boolean;
  logPendoAnalytics: (p: { tracker: { name: string; props: {} } }) => void;
  openIdIssuerUrl: string;
  setDarkMode: (isDarkModeSwitchEnabled: boolean) => void;
  setScrollbarsElement: (ref: HTMLElement | null, elementName?: string) => void;
  showOtpSetup: boolean;
  supportsIdp: boolean;
};

type AccountSettingsProps = {};

const AccountSettings = ({
  currentUser,
  isAvailabilityFeatureFlagEnabled,
  isDarkModeFeatureFlagEnabled,
  isDarkModeSwitchEnabled,
  isDesktopApp,
  isIdpUser,
  isSamlUser,
  logPendoAnalytics,
  openIdIssuerUrl,
  setDarkMode,
  setScrollbarsElement,
  showOtpSetup,
  supportsIdp,
}: AccountSettingsProps & MobxProps) => {
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const _focusEntity = useRef<HTMLElement>();
  const { mfaEnabled = false } = currentUser;

  const _onChangePassword = () => {
    if ((!isDesktopApp || supportsIdp) && isIdpUser && !isSamlUser) {
      window.open(`${openIdIssuerUrl}/account/changepassword`);
    } else {
      setEditMode(true);
    }
  };

  const _onChangeOTP = () => {
    window.open(`${openIdIssuerUrl}/OTP/PhoneSetup`);
  };

  const _onChangeMFA = () => {
    window.open(`${openIdIssuerUrl}/mfa/selectdestinationedit?IsEditMfa=true`);
  };

  const _onCloseEditMode = () => {
    _focusEntity?.current?.focus();
    setEditMode(false);
  };

  const _setScrollbarsElement = useCallback(
    (ref: HTMLElement | null) => {
      _focusEntity.current = ref || undefined;
      setScrollbarsElement(ref, 'AccountSettings');
    },
    [setScrollbarsElement]
  );

  return (
    <div className={classes()} ref={_setScrollbarsElement} tabIndex={-1}>
      <Card header="ACCOUNT SETTINGS">
        <div className={classes('info')}>
          {editMode ? (
            <div className={classes('info-header-edit')}>
              <div className={classes('info-header')}>PASSWORD</div>
              <ChangePassword onCloseEditMode={_onCloseEditMode} />
            </div>
          ) : (
            <div className={classes('info-header-no-edit')}>
              <div className={classes('info-header')}>PASSWORD</div>
              <Button label={'CHANGE'} onClick={_onChangePassword} outline />
            </div>
          )}
          {isAvailabilityFeatureFlagEnabled && (
            <div className={classes('settings-container')}>
              <div
                className={classes('settings-content')}
                onClick={() =>
                  dispatch(actions.setModal({ name: 'customDndMessage', data: { justEdit: true } }))
                }
                data-test-id={'open-customDndMessageModal'}
              >
                <Setting label="MANAGE UNAVAILABLE MESSAGES" action={<RightChevron />} />
              </div>
            </div>
          )}
          {isDarkModeFeatureFlagEnabled && (
            <div className={classes('info-header-no-edit')}>
              <div className={classes('info-header')}>DARK MODE</div>
              <div className={classes('switch')}>
                <Switch
                  checked={isDarkModeSwitchEnabled}
                  onSwitch={() => {
                    const newValue = !isDarkModeSwitchEnabled;
                    setDarkMode(newValue);
                    logPendoAnalytics({
                      tracker: {
                        name: `Admin + Settings | Profile Settings - Dark Mode`,
                        props: {
                          SwitchAction: newValue ? 'enable' : 'disable',
                        },
                      },
                    });
                  }}
                  dataTestId="darkModeSwitch"
                />
              </div>
            </div>
          )}
        </div>
        {(!isDesktopApp || supportsIdp) && isIdpUser && mfaEnabled && !isSamlUser && (
          <div className={classes('info-static')}>
            <div className={classes('info-body')}>
              <div className={classes('info-header-static')}>TWO FACTOR AUTHENTIFICATION</div>
              <div className={classes('body-text')}>
                To verify your identity, we need to send a authorization code.
              </div>
            </div>
            <Button label={'CHANGE'} onClick={_onChangeMFA} outline />
          </div>
        )}
        {showOtpSetup && (
          <div className={classes('info-static')}>
            <div className={classes('info-body')}>
              <div className={classes('info-header-otp')}>(One-Time Password) OTP Login</div>
              <div className={classes('body-text')}>
                Used as an alternate way to login to Tigerconnect when your organization's
                authentication solution goes down
              </div>
            </div>
            <Button label={'SETUP'} onClick={_onChangeOTP} outline />
          </div>
        )}
      </Card>
    </div>
  );
};

export default mobxInjectSelect<AccountSettingsProps, MobxProps>({
  desktopAppStore: ['isDesktopApp', 'supportsIdp'],
  messengerStore: [
    'isAvailabilityFeatureFlagEnabled',
    'isDarkModeFeatureFlagEnabled',
    'showOtpSetup',
  ],
  profileStore: ['setScrollbarsElement'],
  trackerStore: ['logPendoAnalytics'],
  sessionStore: [
    'setDarkMode',
    'isDarkModeSwitchEnabled',
    'currentUser',
    'openIdIssuerUrl',
    'isIdpUser',
    'isSamlUser',
  ],
})(AccountSettings);
