import React from 'react';
import classNames from 'classnames';
import styles from './Modal.module.css';
import { CtaButton } from './../CtaButton/CtaButton';

export interface ModalProps {
  body: string;
  closeButton: string;
  canClose: boolean;
  doneButton: string;
  header: string;
  isWarningButtonPresent: boolean;
  modalSize: 'small' | 'medium' | 'large';
  onClose?: () => void;
  onSuccess?: () => void;
  isOpen: boolean;
}

export default function Modal({
  body,
  closeButton,
  canClose,
  doneButton,
  header,
  isWarningButtonPresent,
  modalSize = 'medium',
  onClose,
  onSuccess,
  isOpen = false,
}: ModalProps) {
  if (!isOpen) return null;
  return (
    <div className={styles.modalContainer}>
      <div
        className={styles.modalContainerBG}
        onClick={() => {
          onClose?.();
        }}
      />
      <div className={styles.modal}>
        <div className={classNames(styles.root, styles[modalSize])}>
          <div className={styles.headerContainer}>
            <div className={styles.header}>{header}</div>
            {canClose && (
              <div
                className={styles.close}
                onClick={() => {
                  onClose?.();
                }}
              >
                x
              </div>
            )}
          </div>
          <div className={styles.body}>{body}</div>
          <div className={styles.footer}>
            <CtaButton
              label={closeButton}
              onClick={() => {
                onClose?.();
              }}
              size={'large'}
            />
            <CtaButton
              label={doneButton}
              onClick={async () => {
                await onSuccess?.();
              }}
              primary={isWarningButtonPresent}
              size={'large'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
