import React, { RefObject } from 'react';
import {
  MessageTemplateRepository,
  MessageTemplateRepositories,
} from '../../../models/enums/MessageTemplateRepositories';
import BEM from '../../bem';
import { MenuItemList, MenuItem } from '../ContextMenu';
import { KEYMAP } from 'common/constants';

const { GENERAL, PERSONAL } = MessageTemplateRepositories;
const classes = BEM.with('RepositoryTypeContextMenu');

type RepositoryTypeContextMenuProps = {
  newRepository: MessageTemplateRepository;
  setNewRepository: (newRepository: MessageTemplateRepository) => void;
  accessibilityMode: boolean;
  buttonRef: RefObject<HTMLButtonElement>;
};

const RepositoryTypeContextMenu = ({
  newRepository,
  setNewRepository,
  accessibilityMode,
  buttonRef,
}: RepositoryTypeContextMenuProps) => {
  return (
    <div className={classes()}>
      <MenuItemList className={classes('menu-list')} accessibilityMode={accessibilityMode}>
        <MenuItem
          ariaLabel="generalRepository"
          className={classes('menu-list-item')}
          isSelected
          onClick={() => {
            newRepository !== GENERAL && setNewRepository(GENERAL);
            if (accessibilityMode) buttonRef?.current?.focus();
          }}
          onKeyDown={(e) => {
            if (e.key === KEYMAP.ESCAPE || e.key === KEYMAP.TAB) {
              if (accessibilityMode) buttonRef?.current?.focus();
            }
          }}
        >
          General
        </MenuItem>
        <MenuItem
          ariaLabel="personalRepository"
          className={classes('menu-list-item')}
          isSelected
          onClick={() => {
            newRepository !== PERSONAL && setNewRepository(PERSONAL);
            if (accessibilityMode) buttonRef?.current?.focus();
          }}
          onKeyDown={(e) => {
            if (e.key === KEYMAP.ESCAPE || e.key === KEYMAP.TAB) {
              if (accessibilityMode) buttonRef?.current?.focus();
            }
          }}
        >
          Personal
        </MenuItem>
      </MenuItemList>
    </div>
  );
};

export default RepositoryTypeContextMenu;
