import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import AddMemberButton from './AddMemberButton/AddMemberButton';
import EndCallButton from './EndCallButton/EndCallButton';
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton';
import { useAppSelector } from 'redux-stores';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      position: 'absolute',
      right: '50%',
      transform: 'translate(50%, 30px)',
      bottom: '20px',
      zIndex: 1,
      transition: 'opacity 1.2s, transform 1.2s, visibility 0s 1.2s',
      opacity: 0,
      visibility: 'hidden',
      '&.isGrid': { bottom: '30px' },
      '&.showControls, &:hover': {
        transition: 'opacity 0.6s, transform 0.6s, visibility 0s',
        opacity: 1,
        visibility: 'visible',
        transform: 'translate(50%, 0px)',
      },
    },
  })
);

export default function Controls({ canAddMember, isUserActive }) {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const accessibilityMode = useAppSelector((state) => state.ui.accessibilityMode);
  const showControls = isUserActive || roomState === 'disconnected' || accessibilityMode;
  const { canUseVideo, room } = useVideoContext();
  const isGrid = room.participants ? room.participants.size > 1 : false;

  return (
    <div className={clsx(classes.container, { showControls, isGrid })}>
      <ToggleAudioButton disabled={isReconnecting} />
      {canUseVideo && <ToggleVideoButton disabled={isReconnecting} />}
      {canAddMember && <AddMemberButton />}
      <EndCallButton />
    </div>
  );
}
