import React from 'react';
import BEM from '../../bem';

const classes = BEM.with('RetryAttachment');

export default function RetryAttachment({ retry, isSmall = false }) {
  return (
    <div className={classes({ small: isSmall })}>
      <div className={classes('cannot', { noPadding: isSmall })}>Cannot Retrieve Attachment</div>
      <a onClick={retry} className={classes('retry')}>
        Retry
      </a>
    </div>
  );
}
