import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import propTypes from '../../../propTypes';
import { mobxInjectSelect } from '../../../utils';
import { UserName } from '../..';
import { MessageSubTypes } from '../../../../models/enums/MessageSubTypes';

const classes = BEM.with('MessageContent');

class MessageModalHeader extends Component {
  static propTypes = {
    currentModalType: PropTypes.string,
    isModalOpen: PropTypes.bool.isRequired,
    message: propTypes.message.isRequired,
  };

  render() {
    const { currentModalType, isModalOpen, message } = this.props;
    const {
      counterParty,
      isForwarded,
      isOutgoing,
      originalSender,
      originalSenderRole,
      sender,
      subType,
    } = message;

    let nameFragment, pillFragment;
    const { groupType, metadata } = counterParty || {};
    const isPatientMessaging = groupType === 'PATIENT_MESSAGING';
    const isPatientMessageForward = isModalOpen && currentModalType === 'patientMessageForward';
    if (isPatientMessaging && isPatientMessageForward) {
      if (sender.isPatient) {
        pillFragment = <span className={classes('patient-name')}>(Patient)</span>;
      } else if (sender.isPatientContact) {
        const relation = metadata.relation_name;
        pillFragment = (
          <span title={relation} className={classes('patient-contact-relation')}>
            &nbsp;({relation})
          </span>
        );
      }
    }

    if (isOutgoing && !isForwarded) return null;

    if (subType === MessageSubTypes.ROLE_AWAY_NOTIFICATION) {
      nameFragment = <UserName role={originalSenderRole} user={originalSender} />;
    } else if (
      subType === MessageSubTypes.EMPTY_ROLE_NOTIFICATION ||
      subType === MessageSubTypes.WORKFLOW_AUTOMATION_NOTIFICATION
    ) {
      nameFragment = 'Automated Message';
    } else if (originalSenderRole && originalSenderRole.displayName) {
      nameFragment = originalSenderRole.displayName;
    } else if (originalSender && originalSender.displayName) {
      nameFragment = originalSender.displayName;
    } else {
      nameFragment = sender.displayName;
    }

    return (
      <div className={classes('user-name', { isPatientMessageForward })}>
        {nameFragment}
        {pillFragment}
      </div>
    );
  }
}

export default mobxInjectSelect({
  modalStore: ['currentModalType', 'isModalOpen'],
})(MessageModalHeader);
