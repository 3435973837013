import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment-timezone';
import scheduledMessageFormats from '../common/utils/scheduledMessageFormats';

const { SERVER_DATE_FORMAT, SERVER_TIME_FORMAT } = scheduledMessageFormats;

const DEFAULT_APPOINTMENT_STATE = {
  id: '',
  time: '',
  date: '',
  timezone: '',
  location: '',
  patientId: '',
  provider: '',
  source: '',
  visitNumber: '',
};

export default class AppointmentsStore {
  @observable appointment = null;
  @observable appointments = [];

  constructor({ client, entityStore, stores }) {
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  mounted() {
    this._resetAppointment();
  }

  @computed get workflows() {
    return this.client.models.Workflow.getAll();
  }

  _resetAppointment = () => {
    this.appointment = { ...DEFAULT_APPOINTMENT_STATE };
  };

  _getTimeZone = async () => {
    const { patientAdminStore } = this.stores;
    const { name } = await patientAdminStore.determineCoarseTimezone();
    return name;
  };

  _getDateFields = async (appointment) => {
    const { appointmentDate, appointmentTime, appointmentTimezone } = appointment;
    let selectedTimezone = appointmentTimezone;
    if (!selectedTimezone) selectedTimezone = await this._getTimeZone();
    const time = appointmentTime || `${moment().add(1, 'hours').format('HH')}:30`;
    const date = appointmentDate || moment().format('YYYY-MM-DD');
    const momentObj = moment.tz(`${date}T${time}`, selectedTimezone);

    return { selectedStartDate: momentObj, selectedTime: momentObj, selectedTimezone };
  };

  @action('AppointmentsStore.openAppointmentModal')
  openAppointmentModal = async (appointment, isPastAppointments) => {
    const { messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;
    const dateFields = await this._getDateFields(appointment);

    runInAction(() => {
      this.appointment = {
        ...appointment,
        ...dateFields,
      };
    });
    const workflowCount = this.client.models.Workflow.getAll().filter(
      (w) => w.workflowId === currentOrganizationId
    ).length;
    if (!workflowCount) await this.client.workflows.findAll(currentOrganizationId);
    this.stores.modalStore.openModal('createAppointment', {
      isAppointmentInPast: isPastAppointments,
    });
  };

  @action('AppointmentsStore.saveAppointment')
  saveAppointment = async () => {
    const {
      location,
      id,
      patientId,
      provider,
      selectedStartDate,
      selectedTime,
      selectedTimezone,
      workflowId,
    } = this.appointment;
    const { messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    const appointment = await this.client.appointments[id ? 'update' : 'create']({
      appointmentDate: selectedStartDate.format(SERVER_DATE_FORMAT),
      appointmentTime: selectedTime.format(SERVER_TIME_FORMAT),
      appointmentTimezone: selectedTimezone,
      id,
      location,
      organizationId: currentOrganizationId,
      patientId,
      provider,
      workflowId,
    });

    const [appointmentData] = this.entityStore.sync(appointment);

    runInAction(() => {
      this.appointments = this.appointments
        .filter((a) => a.id !== appointmentData.id)
        .concat({ ...appointmentData });
    });

    return appointmentData;
  };

  @action('AppointmentsStore.showAllAppointments')
  showAllAppointments = async (patientId) => {
    const { entityStore, messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    const appointmentsSdk = await this.client.appointments.findAll({
      patientId,
      organizationId: currentOrganizationId,
    });

    runInAction(() => {
      this.appointments = entityStore.sync(appointmentsSdk);
    });
  };

  @action('AppointmentsStore.updateAppointment')
  updateAppointment = (field, value) => {
    this.appointment = { ...this.appointment, [field]: value };
  };

  @action('AppointmentsStore.bulkUpdateAppointment')
  bulkUpdateAppointment = (fields) => {
    this.appointment = { ...this.appointment, ...fields };
  };

  @action('AppointmentsStore.clearAppointmentData')
  clearAppointmentData = () => {
    this._resetAppointment();
    this.appointments = [];
  };

  @action('AppointmentsStore.deleteAppointments') deleteAppointments = async (ids) => {
    const { messengerStore } = this.stores;
    if (ids.length > 1) {
      await this.client.appointments.batchDelete(ids, messengerStore.currentOrganizationId);
    } else {
      await this.client.appointments.delete(ids[0], messengerStore.currentOrganizationId);
    }

    runInAction(() => {
      this.appointments = this.appointments.filter((a) => !ids.includes(a.id));
    });

    return true;
  };
}
