import React from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('RoundButton');

export default function RoundButton(props) {
  const {
    color = 'currentColor',
    dataTestId,
    disabled = false,
    img: Img,
    isInverseHover = false,
    isSmall = false,
    isWhite = false,
    onClick,
    title,
  } = props;
  const size = isSmall ? 12 : 17;

  return (
    <div className={classes()}>
      <button
        className={classes('button', { isSmall, isInverseHover, isWhite })}
        data-test-id={dataTestId}
        disabled={disabled}
        onClick={onClick}
        title={title}
        onKeyDown={(e) => {
          if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
            onClick(e);
          }
        }}
      >
        <div className={classes('overlay', { isSmall, disabled })} />
        <Img fill={color} height={size} width={size} aria-hidden />
      </button>
    </div>
  );
}

RoundButton.propTypes = {
  color: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  img: PropTypes.object.isRequired,
  isInverseHover: PropTypes.bool,
  isSmall: PropTypes.bool,
  isWhite: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  isTabbable: PropTypes.bool,
};
