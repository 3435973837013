import './init';
import './polyfills';
import 'react-app-polyfill/stable';
import 'classlist-polyfill';
import 'webcrypto-shim';

import React from 'react';
import ReactDOM from 'react-dom';
import { configure as configureMobx } from 'mobx';
import * as Sentry from '@sentry/react';

import client, { featureMatch, localHostMatch, xyzMatch } from './client';
import stores from './root-store';

import Root from './containers/Root';
import { refreshParams } from './params';
import './styles/main.scss';
import './styles/colors.css';

const initSentry = () => {
  const { apiEnv = 'uat' } = client.config;

  const isDev = !apiEnv.includes('prod') || featureMatch || localHostMatch || xyzMatch;

  const devProjectDSN =
    'https://b7adb2f177f86649129526718e8432e4@o4505999279980544.ingest.sentry.io/4506034055282688';
  const prodProjectDSN =
    'https://f771e0a54a77e2e201817d4aefe83f5b@o4505999279980544.ingest.sentry.io/4506185707552768';

  const devRelease = buildInfo.version;
  const prodRelease = client.version;

  const dsn = isDev ? devProjectDSN : prodProjectDSN;
  const release = isDev ? devRelease : prodRelease;
  const breadcrumbOptions = isDev ? { console: false } : {};

  Sentry.init({
    ...(apiEnv && { environment: apiEnv }),
    beforeSend: stores.trackerStore.sentryBeforeSendError,
    dsn,
    integrations: [
      Sentry.breadcrumbsIntegration(breadcrumbOptions),
      Sentry.browserProfilingIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    release,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    sampleRate: 1.0,
    tracesSampler: stores.trackerStore.sentryTracesSampler,
    tracesSampleRate: 1.0,
  });
};

configureMobx({
  enforceActions: 'observed',
});

client.on('browserUnload', (performAtPriority) => {
  const { sessionStore } = stores;
  sessionStore.setSignOutLoading(true);
  performAtPriority(
    () => Object.values(stores).forEach((store) => store.dispose && store.dispose()),
    400,
    'store dispose'
  );
});

function setGlobals() {
  const { desktopAppStore } = stores;
  const context = desktopAppStore.isDesktopApp && window.parent ? window.parent : global;

  context.TCClient = client;
  context.React = React;
  context.ReactDOM = ReactDOM;
  context.mobxStores = stores;
  context.Sentry = Sentry;

  if (global.finishDevInit) global.finishDevInit();
}
setGlobals();

document.addEventListener('DOMContentLoaded', () => {
  const rootNode = document.getElementById('app');

  refreshParams();
  initSentry();

  ReactDOM.render(<Root stores={stores} />, rootNode);
});
