import React, { useState } from 'react';
import { PageHeader } from '../../shared-components/PageHeader/PageHeader';
import OrganizationForm from './Organization/OrganizationForm';
import OrganizationsTable from './OrganizationsTable';

export default function Organizations() {
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);

  return (
    <div>
      <PageHeader label={'Organizations Directory'} />
      {!isCreateMode && (
        <OrganizationsTable
          isCreateMode={isCreateMode}
          setIsCreateMode={setIsCreateMode}
          isSuccessToastOpen={isSuccessToastOpen}
          setIsSuccessToastOpen={setIsSuccessToastOpen}
        />
      )}
      {isCreateMode && (
        <OrganizationForm
          isCreateMode={isCreateMode}
          setIsCreateMode={setIsCreateMode}
          isSuccessToastOpen={isSuccessToastOpen}
          setIsSuccessToastOpen={setIsSuccessToastOpen}
        />
      )}
    </div>
  );
}
