import React, { Component } from 'react';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import { MenuItemList, MenuItem } from '../ContextMenu';

const classes = BEM.with('LogoutButtonMenu');

class LogoutButtonMenu extends Component {
  render() {
    return (
      <MenuItemList className={classes()}>
        <MenuItem onClick={this._openLogoutModal}>
          <span>Logout</span>
        </MenuItem>
        <MenuItem onClick={this._openForceLogoutModal} className={classes('list-item-red')}>
          <span>Force logout from all devices</span>
        </MenuItem>
      </MenuItemList>
    );
  }

  _openLogoutModal = () => {
    this.props.openModal('logoutModal');
  };

  _openForceLogoutModal = () => {
    this.props.openModal('forceLogoutModal');
  };
}

export default mobxInjectSelect({
  modalStore: ['openModal'],
})(LogoutButtonMenu);
