import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { ReactComponent as CheckmarkSvg } from '../../images/icon-checkmark-gray.svg';
import mobxInjectSelect from '../../utils/mobxInjectSelect';

const classes = BEM.with('AcknowledgeButton');

class AcknowledgeButton extends Component {
  static propTypes = {
    acknowledgeEscalation: PropTypes.func.isRequired,
    markConversationAsRead: PropTypes.func.isRequired,
    message: propTypes.message,
  };

  _acknowledgeInProgress = false;

  render() {
    const { message } = this.props;
    const { escalationExecution } = message;
    const { status } = escalationExecution;

    let statusText;
    if (status === 'IN_PROGRESS') statusText = 'Acknowledge';
    if (status === 'CANCELLED' || status === 'NO_RESPONSE') statusText = 'NA';

    let button;
    if (status === 'ACKNOWLEDGED') {
      button = (
        <div className={classes({ status })}>
          <CheckmarkSvg />
        </div>
      );
    } else if (status === 'IN_PROGRESS') {
      button = (
        <div className={classes({ status })} onClick={this._acknowledge}>
          {statusText}
        </div>
      );
    } else {
      button = <div className={classes({ status })}>{statusText}</div>;
    }

    return (
      <div className={classes({ type: status })}>
        <div className={classes('container')}>
          <div className={classes('button')}>{button}</div>
        </div>
      </div>
    );
  }

  _acknowledge = async () => {
    const { acknowledgeEscalation, markConversationAsRead, message } = this.props;
    const { conversation, escalationExecution } = message || {};

    if (conversation) markConversationAsRead(conversation);

    if (escalationExecution && !this._acknowledgeInProgress) {
      this._acknowledgeInProgress = true;
      await acknowledgeEscalation(escalationExecution);
      this._acknowledgeInProgress = false;
    }
  };
}

export default mobxInjectSelect({
  conversationStore: ['markConversationAsRead'],
  escalationStore: ['acknowledgeEscalation'],
})(AcknowledgeButton);
