import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from '../WebComponents';
import propTypes from '../../propTypes';
import { mobxInjectSelect } from '../../utils';
import BEM from '../../bem';
import { DotsIndicator } from '../';
import { RemoveChangesButton } from './';

const classes = BEM.with('ProfileInfo');

class ProfileInfo extends Component {
  static propTypes = {
    currentUser: propTypes.user,
    loadingUserDisplayName: PropTypes.bool.isRequired,
    scrollToElement: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
  };

  state = {
    draftName: this.props.currentUser.displayName,
    isDirty: false,
  };

  render() {
    const { draftName, isDirty } = this.state;
    const { loadingUserDisplayName } = this.props;

    return (
      <div className={classes()}>
        <Card header="PROFILE INFO">
          {loadingUserDisplayName ? (
            <div className={classes('dots-container')}>
              <DotsIndicator className={classes('dots')} size={16} color={'#00a945'} />
            </div>
          ) : (
            <div className={classes('info')}>
              <div className={classes('info-header')}>NAME</div>
              <form className={classes('info-name')} onSubmit={this._submit}>
                <input
                  className={classes('name-input', { isDirty })}
                  onChange={this._onNameChange}
                  onFocus={this._onFocus}
                  placeholder="Name"
                  tabIndex={1}
                  type="text"
                  value={draftName}
                  onKeyDown={this._onEscape}
                />
              </form>
              {isDirty && (
                <div className={classes('buttons')}>
                  <RemoveChangesButton removeChanges={this._removeChanges} />
                  <Button label="SAVE" outline onClick={this._submitName} />
                </div>
              )}
            </div>
          )}
        </Card>
      </div>
    );
  }

  _submit = (e) => {
    e.preventDefault();
    this._submitName();
  };

  _onFocus = () => {
    const { scrollToElement } = this.props;
    scrollToElement('ProfileInfo', { shouldSpring: false });
  };

  _onNameChange = (e) => {
    this._updateDraftName(e.target.value);
  };

  _onEscape = (e) => {
    if (e.key !== 'Escape') return;
    e.stopPropagation();
    const { isDirty } = this.state;
    if (!isDirty) return;
    this._removeChanges();
  };

  _updateDraftName(newDisplayName) {
    this.setState({ draftName: newDisplayName });
    if (this.props.currentUser.displayName !== newDisplayName) {
      this.setState({ isDirty: true });
    } else {
      this.setState({ isDirty: false });
    }
  }

  _submitName = async () => {
    const { draftName } = this.state;

    await this.props.update({
      displayName: draftName,
      loadingComponent: 'loadingUserDisplayName',
    });
    this.setState({ isDirty: false });
  };

  _removeChanges = () => {
    this._updateDraftName(this.props.currentUser.displayName);
    this.setState({ isDirty: false });
  };
}

export default mobxInjectSelect({
  profileStore: ['scrollToElement'],
  sessionStore: ['currentUser'],
  userStore: ['loadingUserDisplayName', 'update'],
})(ProfileInfo);
