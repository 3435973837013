import React from 'react';
import BEM from '../bem';
import AvatarImage from './AvatarImage';

const classes = BEM.with('AvatarImage');

const MODIFIERS_WITH_INITIALS = { initials: true };

type DisplayNameProps = {
  firstName: string;
  lastName: string;
};

type CareTeamAvatarProps = {
  className: string;
  careTeam: {
    firstName: string;
    lastName: string;
    displayName: string;
  };
  size: number;
  entityType: string;
};

function getInitials({ firstName, lastName }: DisplayNameProps) {
  return `${firstName[0]}${lastName[0]}`;
}

function CareTeamAvatar({ className, careTeam, size, entityType }: CareTeamAvatarProps) {
  const { firstName, lastName, displayName } = careTeam;
  const fontSize = size < 50 ? Math.round(size * 0.47) : 24;

  const initials = getInitials({ firstName, lastName });
  const initialsFontSizeStyles = fontSize ? { fontSize } : {};

  return (
    <AvatarImage
      className={className}
      displayName={displayName}
      entityType={entityType}
      modifiers={MODIFIERS_WITH_INITIALS}
      size={30}
    >
      <span style={initialsFontSizeStyles} className={classes('initials', { entityType })}>
        {initials}
      </span>
    </AvatarImage>
  );
}

export default CareTeamAvatar;
