import { observable } from 'mobx';
import createMobxModel from './createMobxModel';

export const Message = createMobxModel({
  name: 'message',

  fields: [
    '$placeholder',
    'availabilityDndAutoForwardedTo',
    'body',
    'canEscalate',
    'canRecall',
    'createdAt',
    'deleteOnRead',
    'dndAutoForwardedTo',
    'expiresAt',
    'featureService',
    'isAutoForwarded',
    'isAvailabilityDndAutoForwardedTo',
    'isForwarded',
    'isForwardedFromPatientNetwork',
    'isGroupReplay',
    'isGroupReplayStatusesFetched',
    'isHistoricalAlert',
    'isInjected',
    'isMentioned',
    'isOutgoing',
    'isUnread',
    'messageType',
    'originalGroupId',
    'originalGroupName',
    'originalMessageId',
    'originalMetadata',
    'priority',
    'reactions',
    'senderError',
    'senderStatus',
    'serverId',
    'shouldEnsureRecipientStatus',
    'shouldEscalate',
    'sortNumber',
    'subType',
    'ttl',
  ],

  objectFields: {
    alertCard: {
      clone(card) {
        return observable.array(card, { deep: false });
      },
    },
    alertDetails: {},
    alertNotification: {},
    attachments: {},
    metadata: {},
    originalSender: {},
    originalSenderRole: {},
    vwrCallInvite: {},
    patientCareCard: {
      clone(card) {
        return observable.array(card, { deep: false });
      },
    },
    patientDetails: {},
    senderRole: {},
  },

  relations: {
    one: {
      alertConversationGroup: { type: 'group' },
      autoForwardedFrom: { type: 'user' },
      autoForwardedTo: { type: 'user' },
      conversation: { type: 'conversation' },
      counterParty: { type: ['distributionList', 'group', 'user'] },
      currentRecipientAlertAction: { type: 'messageStatusPerRecipient' },
      currentSenderRole: { type: 'role' },
      distributionList: { type: 'distributionList' },
      escalationExecution: { type: 'escalationExecution' },
      escalationExecutionChange: { type: 'escalationExecutionChange' },
      group: { type: 'group' },
      groupMembersChange: { type: 'groupMembersChange' },
      originalGroup: { type: 'group' },
      recipient: { type: 'user' },
      recipientOrganization: { type: 'organization' },
      sender: { type: 'user' },
      senderOrganization: { type: 'organization' },
      teamRequest: { type: 'teamRequest' },
    },
    many: {
      alertRecipients: { type: ['user'] },
      statusesPerRecipient: { type: 'messageStatusPerRecipient' },
    },
  },
});

export default Message;
