const AppTypes = {
  ANALYTICS: 'Analytics',
  BROADCAST_LISTS: 'BroadcastLists',
  COLLABORATION: 'Roles&Teams',
  MESSAGES: 'Messages',
  PATIENT_SETTINGS: 'PatientSettings',
  ROLES: 'Roles',
  SETTINGS: 'Settings',
  TEAMS: 'Teams',
  VIRTUAL_WAITING: 'VirtualWaiting',
  PATIENT_DIRECTORY: 'PatientDirectory',
} as const;

export type AppType = keyof typeof AppTypes;

export default AppTypes;
