import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { ReactComponent as DragRejectedSvg } from '../../widgets/messenger/images/drag-rejected.svg';
import Modal from './Modal';

const classes = BEM.with('RejectedFileFormatModal');

class RejectedFileFormatModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  render() {
    const { closeModal, isOpen } = this.props;
    const imgHeader = {
      image: DragRejectedSvg,
      imgClassHeader: classes('img'),
    };

    return (
      <Modal
        bodyClass={classes('body')}
        closeClass={classes('close-button')}
        footerClass={classes('footer')}
        header={'File format not accepted'}
        headerClass={classes('header')}
        imgHeader={imgHeader}
        isOpen={isOpen}
        size={'medium-small'}
        footerSecondaryActions={
          <button type="button" onClick={closeModal} className={classes('cancel-btn')}>
            Got it
          </button>
        }
        className={classes()}
        onRequestClose={closeModal}
      >
        <div className={classes('info')}>
          Some file formats are not supported by TigerConnect,{' '}
          <a onClick={this._onLinkClick}>view a list of supported files.</a>
        </div>
      </Modal>
    );
  }

  _onLinkClick = () => {
    const { closeModal, openModal } = this.props;
    closeModal();
    openModal('supportedFileTypes');
  };
}

export default mobxInjectSelect({
  modalStore: ['openModal'],
})(RejectedFileFormatModal);
