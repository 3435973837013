import { ServerValueEnum } from '../../utils/enum';

const MessageRecipientStatus = new ServerValueEnum(
  {
    NA: { key: 'NA' },
    NEW: { serverValue: 'New' },
    DELIVERED: { serverValue: 'Delivered' },
    READ: { serverValue: 'Read' },
    ACKNOWLEDGED: { serverValue: 'Confirmed' },
  } as const,
  { defaultValue: 'NA' }
);

const MESSAGE_STATUS_ORDER = {
  [MessageRecipientStatus.NA]: 0,
  [MessageRecipientStatus.NEW]: 1,
  [MessageRecipientStatus.DELIVERED]: 2,
  [MessageRecipientStatus.READ]: 3,
  [MessageRecipientStatus.ACKNOWLEDGED]: 4,
};

export { MessageRecipientStatus, MESSAGE_STATUS_ORDER };
export default MessageRecipientStatus;
