import React from 'react';

import { CtaButton } from '../../../shared-components/CtaButton/CtaButton';
import { ViewContainer } from '../../../shared-components/View/ViewContainer/ViewContainer';

import { TabProps } from './';
import { thunk, useAppDispatch } from 'redux-stores';

export default function Delete({ onClose }: TabProps) {
  const dispatch = useAppDispatch();

  return (
    <ViewContainer
      {...{
        header: 'Delete',
        subHeader:
          'Permanently delete this organization. Note: Users within this organization will not be deleted.',
        onClose,
      }}
    >
      <div>
        <CtaButton
          dataTestId="Delete Organization"
          primary
          label="Delete Organization"
          onClick={async () => {
            try {
              await thunk.deleteOrganization(dispatch);
            } catch (error) {
              console.error('Error deleting organization.', error);
            }
          }}
        />
      </div>
    </ViewContainer>
  );
}
