import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@tigerconnect/web-component-library';
import propTypes from '../../../../common/propTypes';
import BEM from '../../../../common/bem';
import { mobxInjectSelect } from '../../../../common/utils';

import { SearchBar } from '../../../../common/components';
import { ContextMenu, MenuItemList, MenuItem } from '../../../../common/components/ContextMenu';
import { AppTypes } from '../../../../models/enums';
import { ReactComponent as RefreshRolesSvg } from '../../images/refresh-roles-icon.svg';
import { ReactComponent as ScheduleSvg } from '../../images/schedule-icon.svg';
import { SidebarOrganizationSelector } from './';
import { actions, useAppDispatch } from 'redux-stores';

const { setModal, setMessageBodyInputFocus, setKeywordSearchMode } = actions;

const classes = BEM.with('SidebarHeader');

const MENU_OFFSET_Y = 16;
const MENU_OFFSET_X = -27;

function SidebarHeader({
  clearExistingSchedule,
  composeNewMessage,
  currentAppSelected,
  currentOrganization,
  currentUser,
  exportRolesDetails,
  exportRoleDetailsToScheduleTool,
  exportRolesScheduleUpload,
  getAdminRolesInOrganization,
  isKeywordInboxSearchFeatureFlagEnabled,
  isProviderNetwork,
  isSearching,
  isSukiEnabled,
  isUniversalTaggingAllowed,
  openScheduleTool,
  refreshAdminIframe,
  reloadCollaboration,
  retrieveLastUploadToScheduleTool,
  startSearching,
  stopSearching,
}) {
  const dispatch = useAppDispatch();
  const { isRolesAdmin } = getAdminRolesInOrganization();
  const { contactsMessagingBlocked } = currentUser || {};
  const { canUploadRolesSchedule = false, isContacts = false } = currentOrganization || {};
  const searchIconType = isKeywordInboxSearchFeatureFlagEnabled ? 'directory' : 'search';

  function renderComposeFragment() {
    if (isContacts && contactsMessagingBlocked) {
      return;
    }
    return (
      <div
        className={classes('icon-container')}
        onClick={() => {
          if (isKeywordInboxSearchFeatureFlagEnabled) {
            dispatch(setKeywordSearchMode(false));
          }
          return composeNewMessage();
        }}
        id={'compose'}
      >
        <Button
          shape="circle"
          color={isProviderNetwork ? 'primary' : 'patient'}
          icon="compose"
          aria-label="Compose new message"
        />
      </div>
    );
  }

  function renderSearchFragment() {
    return (
      <div
        className={classes('icon-container')}
        onClick={
          isSearching
            ? () => {
                stopSearching();
                dispatch(setMessageBodyInputFocus(true));
              }
            : startSearching
        }
      >
        <Button
          shape="circle"
          color={isProviderNetwork ? 'primary' : 'patient'}
          icon={isSearching ? 'cancel' : searchIconType}
          aria-label="org search"
        />
      </div>
    );
  }

  function renderTabRefreshFragment() {
    return (
      <div
        aria-label="roles refresh"
        className={classes('icon-container-roles')}
        onClick={isUniversalTaggingAllowed ? reloadCollaboration : refreshAdminIframe}
      >
        <RefreshRolesSvg className={classes('icon-roles')} />
      </div>
    );
  }

  function renderSchedulerFragment() {
    if (!(isRolesAdmin && canUploadRolesSchedule)) {
      return;
    }
    return (
      <ContextMenu
        event="click"
        position="bottomright"
        softClick={false}
        offsetY={MENU_OFFSET_Y}
        offsetX={MENU_OFFSET_X}
        menu={
          <MenuItemList>
            <MenuItem
              onClick={
                isUniversalTaggingAllowed
                  ? () => dispatch(setModal({ name: 'roleScheduler' }))
                  : openScheduleTool
              }
            >
              Upload Schedule Tool
            </MenuItem>
            <MenuItem
              onClick={
                isUniversalTaggingAllowed ? exportRolesDetails : exportRoleDetailsToScheduleTool
              }
            >
              Export Role Details
            </MenuItem>
            <MenuItem
              onClick={
                isUniversalTaggingAllowed
                  ? exportRolesScheduleUpload
                  : retrieveLastUploadToScheduleTool
              }
            >
              Retrieve Last Upload
            </MenuItem>
            <MenuItem
              onClick={
                isUniversalTaggingAllowed
                  ? () => dispatch(setModal({ name: 'clearScheduler' }))
                  : clearExistingSchedule
              }
            >
              Clear Existing Schedule
            </MenuItem>
          </MenuItemList>
        }
      >
        <div aria-label="roles schedule tool" className={classes('icon-container-roles')}>
          <ScheduleSvg className={classes('icon-roles')} />
        </div>
      </ContextMenu>
    );
  }

  function renderOpenSukiFragment() {
    if (!isSukiEnabled) return null;
    return (
      <div
        className={classes('icon-container')}
        onClick={() => {
          dispatch(setModal({ name: 'sukiModal' }));
        }}
      >
        <Button
          shape="circle"
          color={isProviderNetwork ? 'primary' : 'patient'}
          icon={'add'}
          aria-label="open suki"
        />
      </div>
    );
  }

  return (
    <div className={classes()}>
      <div className={classes('sidebar-content')}>
        {isSearching ? <SearchBar /> : <SidebarOrganizationSelector />}
        <div className={classes('sidebar-btn')}>
          {currentAppSelected === AppTypes.MESSAGES && (
            <>
              {renderSearchFragment()}
              {renderComposeFragment()}
              {renderOpenSukiFragment()}
            </>
          )}
          {(currentAppSelected === AppTypes.ROLES ||
            currentAppSelected === AppTypes.COLLABORATION) && (
            <>
              {renderTabRefreshFragment()}
              {renderSchedulerFragment()}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

SidebarHeader.propTypes = {
  clearExistingSchedule: PropTypes.func.isRequired,
  composeNewMessage: PropTypes.func.isRequired,
  currentAppSelected: PropTypes.string.isRequired,
  currentOrganization: propTypes.organization,
  currentUser: propTypes.user.isRequired,
  exportRolesDetails: PropTypes.func.isRequired,
  exportRoleDetailsToScheduleTool: PropTypes.func.isRequired,
  exportRolesScheduleUpload: PropTypes.func.isRequired,
  getAdminRolesInOrganization: PropTypes.func.isRequired,
  isKeywordInboxSearchFeatureFlagEnabled: PropTypes.bool,
  isProviderNetwork: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
  isUniversalTaggingAllowed: PropTypes.bool.isRequired,
  openScheduleTool: PropTypes.func.isRequired,
  refreshAdminIframe: PropTypes.func.isRequired,
  reloadCollaboration: PropTypes.func.isRequired,
  retrieveLastUploadToScheduleTool: PropTypes.func.isRequired,
  startSearching: PropTypes.func.isRequired,
  stopSearching: PropTypes.func.isRequired,
};

export default mobxInjectSelect({
  collaborationStore: ['exportRolesScheduleUpload', 'exportRolesDetails', 'reloadCollaboration'],
  composeMessageStore: ['composeNewMessage'],
  messengerStore: [
    'currentAppSelected',
    'currentOrganization',
    'getAdminRolesInOrganization',
    'isKeywordInboxSearchFeatureFlagEnabled',
    'isSearching',
    'isSukiEnabled',
    'isUniversalTaggingAllowed',
    'startSearching',
    'stopSearching',
  ],
  networkStore: ['isProviderNetwork'],
  reactAdminStore: [
    'clearExistingSchedule',
    'exportRoleDetailsToScheduleTool',
    'openScheduleTool',
    'refreshAdminIframe',
    'retrieveLastUploadToScheduleTool',
  ],
  sessionStore: ['currentUser'],
})(SidebarHeader);
