import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import propTypes from '../propTypes';
import { About, ProfileStatus } from './Profile';
import { EscButton, UserName } from './';

const classes = BEM.with('PatientUserSettings');

class PatientUserSettings extends Component {
  static propTypes = {
    closePatientUserSettings: PropTypes.func.isRequired,
    currentUser: propTypes.user.isRequired,
  };

  render() {
    const { closePatientUserSettings, currentUser } = this.props;

    return (
      <div className={classes()}>
        <div className={classes('header')}>
          <div className={classes('name-container')}>
            <div className={classes('title')}>User Settings</div>
            <div className={classes('name')}>
              <UserName user={currentUser} />
            </div>
          </div>
          <div className={classes('close-container')}>
            <EscButton onClose={closePatientUserSettings} />
          </div>
        </div>
        <div className={classes('content')}>
          <div className={classes('body')}>
            <div className={classes('items')}>
              <ProfileStatus isPatientNetwork={true} />
              <About />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  messengerStore: ['closePatientUserSettings'],
  sessionStore: ['currentUser'],
})(PatientUserSettings);
