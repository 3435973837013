import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { allowedSendersReasons, mobxInjectSelect } from 'common/utils';
import propTypes from 'common/propTypes';
import { MessageForm } from 'common/components/MessageForm';

class ExistingConvMessageForm extends Component {
  static propTypes = {
    clearNewMessagesIndicator: PropTypes.func.isRequired,
    currentConversation: propTypes.conversation,
    isProviderNetwork: PropTypes.bool.isRequired,
    markConversationAsRead: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    senderId: PropTypes.string,
    sendMessageToConversation: PropTypes.func,
    startTyping: PropTypes.func.isRequired,
  };

  _lastAllowedSendersReason = null;
  _lastConversation = null;

  componentDidUpdate() {
    this._displayAllowedSenderModalMaybe();
  }

  render() {
    const { currentConversation, isProviderNetwork } = this.props;
    const { allowedSenders, counterParty, organization } = currentConversation;
    if (!counterParty) return null;

    const { p2pRecipient, patientDetails } = counterParty;

    const allowAttachments = isProviderNetwork ? true : organization.isPFAttachmentAllowed;

    let alwaysEscalate = false;
    if (p2pRecipient && p2pRecipient.escalationPolicy) {
      const { escalationPolicy } = p2pRecipient;
      alwaysEscalate = escalationPolicy.alwaysEscalate;
    }

    let disableMessageForm = false;
    if (patientDetails && patientDetails.smsOptedOut) {
      disableMessageForm = true;
    }

    return (
      <MessageForm
        allowAttachments={allowAttachments}
        allowedSenders={disableMessageForm ? [] : allowedSenders}
        alwaysEscalate={alwaysEscalate}
        onInputClick={this._onInputClick}
        onTyping={this._startTyping}
        organization={organization}
        sendMessage={this._sendMessage}
      />
    );
  }

  _displayAllowedSenderModalMaybe = () => {
    const { currentConversation, openModal } = this.props;
    const { allowedSendersReason } = currentConversation;
    const updateAllowed =
      this._lastAllowedSendersReason !== allowedSendersReason ||
      currentConversation !== this._lastConversation;

    if (updateAllowed && allowedSendersReasons[allowedSendersReason]) {
      openModal('allowedSenderModal', { reason: allowedSendersReason });
    }

    this._lastAllowedSendersReason = allowedSendersReason;
    this._lastConversation = currentConversation;
  };

  _onInputClick = () => {
    const { currentConversation, markConversationAsRead } = this.props;
    markConversationAsRead(currentConversation);
  };

  _startTyping = () => {
    const { currentConversation, senderId, startTyping } = this.props;
    startTyping(currentConversation.counterPartyId, { senderId });
  };

  _sendMessage = async (
    body,
    { attachmentFiles, escalate, membersMentionInConversation, priority } = {}
  ) => {
    const {
      clearNewMessagesIndicator,
      currentConversation,
      markConversationAsRead,
      senderId,
      sendMessageToConversation,
    } = this.props;
    const { organizationId } = currentConversation;

    await sendMessageToConversation(currentConversation.id, body, {
      attachmentFiles,
      escalate,
      organizationId,
      priority,
      membersMentionInConversation,
      senderId,
    });

    clearNewMessagesIndicator(currentConversation);
    await markConversationAsRead(currentConversation);
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['senderId'],
  conversationStore: ['clearNewMessagesIndicator', 'currentConversation', 'markConversationAsRead'],
  messageStore: ['sendMessageToConversation'],
  modalStore: ['openModal'],
  networkStore: ['isProviderNetwork'],
  typingStatusStore: ['startTyping'],
})(ExistingConvMessageForm);
