import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BEM from '../../../common/bem';
import { UserInfoHeader } from '../../../common/components';
import { RoleBanner } from '../../../common/components/Roles';
import mobxInjectSelect from '../../../common/utils/mobxInjectSelect';
import propTypes from '../../../common/propTypes';
import { OrganizationSelector, SidebarHeader } from './Sidebar';

const classes = BEM.with('MessengerFrame');

class MessengerFrame extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isOpenSelector: PropTypes.bool.isRequired,
    isPrintModeOpen: PropTypes.bool.isRequired,
    showRoleBanner: PropTypes.bool.isRequired,
    isPatientUserSettingsOpen: PropTypes.bool.isRequired,
    currentOrganization: propTypes.organization,
  };

  render() {
    const {
      children,
      className,
      isOpenSelector,
      isPrintModeOpen,
      showRoleBanner,
      isPatientUserSettingsOpen,
      currentOrganization,
    } = this.props;

    return (
      <div className={classNames(className, classes())}>
        {!isPatientUserSettingsOpen && (
          <header
            role="banner"
            aria-labelledby="organization-heading"
            className={classes('header-row', { isPrintModeOpen })}
          >
            <h1 id="organization-heading" className="screen-reader-only">
              {currentOrganization && currentOrganization.name} Organization
            </h1>
            <div className={classes('sidebar-header')}>
              <SidebarHeader />
            </div>
            <div className={classes('middle-header')}>
              <UserInfoHeader />
            </div>
          </header>
        )}

        <div className={classes('role-banner', { enable: showRoleBanner })}>
          {showRoleBanner && <RoleBanner />}
        </div>

        {children}
        {isOpenSelector && <OrganizationSelector />}
      </div>
    );
  }
}

export default mobxInjectSelect({
  messengerStore: [
    'isPatientUserSettingsOpen',
    'isPrintModeOpen',
    'showRoleBanner',
    'currentOrganization',
  ],
  organizationSelectorStore: ['isOpenSelector'],
})(MessengerFrame);
