import React, { Component } from 'react';
import classNames from 'classnames';
import BEM from '../bem';
import propTypes from '../propTypes';
import { FROZEN_EMPTY_ARRAY, mobxInjectSelect } from '../utils';
import { AttachmentPreviews, ConversationMessages } from './';

const classes = BEM.with('NewConversation');
const existingClasses = BEM.with('ExistingConversation');

class NewConversation extends Component {
  static propTypes = {
    currentPreviewConversation: propTypes.conversation,
  };

  render() {
    const { currentPreviewConversation } = this.props;
    const {
      counterParty = null,
      firstUnreadMessage = null,
      lastMessage = null,
      timeline = FROZEN_EMPTY_ARRAY,
    } = currentPreviewConversation || {};

    return (
      <div className={classNames(classes(), existingClasses())}>
        <ConversationMessages
          counterParty={counterParty}
          firstUnreadMessage={firstUnreadMessage}
          key={counterParty ? counterParty.id : 'new-message'}
          lastMessage={lastMessage}
          messages={timeline}
        />
        <AttachmentPreviews />
      </div>
    );
  }
}

export default mobxInjectSelect({
  composeMessageStore: ['currentPreviewConversation', 'hasAttachments'],
})(NewConversation);
