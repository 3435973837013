import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../utils';
import { UndoAction } from './';

class UndoDnd extends Component {
  static propTypes = {
    setDndUndoButton: PropTypes.func.isRequired,
    undoRemoveDnd: PropTypes.func.isRequired,
  };

  render() {
    return (
      <UndoAction
        removeButton={this._removeUndoButton}
        text={'You are Available'}
        undoAction={this._undoRemoveDnd}
      />
    );
  }

  _undoRemoveDnd = async () => {
    const { undoRemoveDnd } = this.props;
    await undoRemoveDnd();
  };

  _removeUndoButton = () => {
    this.props.setDndUndoButton(false);
  };
}

export default mobxInjectSelect({
  userStore: ['setDndUndoButton', 'undoRemoveDnd'],
})(UndoDnd);
