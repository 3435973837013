import { Visitor } from '../../types';
import { VWRState } from './context';
import { VWRAction } from './actions';

export const vwrReducer = (state: VWRState, action: VWRAction): VWRState => {
  switch (action.type) {
    case 'HYDRATE_VWR': {
      const { loadedStatus, selectedRoom, roomList, visitors } = action.payload;
      const selectedStatus = 'INCOMPLETE';
      const staff = selectedRoom?.staff || [];
      const userHasRoomsAvailable = roomList.length > 0;

      return {
        ...state,
        loadedStatus,
        staff,
        roomList,
        selectedRoom,
        selectedStatus,
        userHasRoomsAvailable,
        visitors,
      };
    }
    case 'UPDATE_ROOM_LIST': {
      const { roomList } = action.payload;

      return {
        ...state,
        userHasRoomsAvailable: roomList?.length > 0,
        roomList,
      };
    }
    case 'CHANGE_SELECTED_ROOM': {
      const { selectedRoom, visitors } = action.payload;
      const selectedStatus = 'INCOMPLETE';
      return {
        ...state,
        selectedRoom,
        selectedStatus,
        staff: selectedRoom.staff,
        userHasRoomsAvailable: true,
        visitors,
      };
    }
    case 'CHANGE_SELECTED_STATUS': {
      const { selectedStatus, visitors } = action.payload;
      return {
        ...state,
        selectedStatus,
        visitors,
      };
    }
    case 'CHANGE_SELECTED_VISITOR': {
      const selectedVisitor = action.payload;

      return {
        ...state,
        visitors: {
          ...state.visitors,
          selectedVisitor,
        },
      };
    }
    case 'UPDATE_SELECTED_VISITOR': {
      const selectedVisitor = action.payload;

      const visitors = state.visitors.visitors.map((visitor) => {
        if (visitor.id === selectedVisitor.id) {
          return selectedVisitor;
        } else {
          return visitor;
        }
      });

      return {
        ...state,
        visitors: {
          ...state.visitors,
          visitors,
          selectedVisitor,
        },
      };
    }
    case 'SET_LOADING_STATUS': {
      const loadedStatus = action.payload;
      return {
        ...state,
        loadedStatus,
      };
    }
    case 'APPEND_VISITORS': {
      const { count, hasAllVisitors, page, visitors } = action.payload;
      const selectedVisitor = action.payload.selectedVisitor || state.visitors.selectedVisitor;
      return {
        ...state,
        visitors: {
          count,
          hasAllVisitors,
          page,
          selectedVisitor,
          visitors: [...state.visitors.visitors, ...visitors],
        },
      };
    }
    case 'CLEAR_COMPLETED_VISITS': {
      return {
        ...state,
        visitors: {
          count: 0,
          hasAllVisitors: true,
          page: 0,
          selectedVisitor: null,
          visitors: [],
        },
      };
    }
    case 'PREPEND_VISITOR': {
      const { selectedStatus } = state;
      const { count, hasAllVisitors, page, visitors, selectedVisitor } = state.visitors;
      const newVisitor: Visitor = action.payload;

      if (
        newVisitor.roomId === state.selectedRoom?.id &&
        (selectedStatus === 'NEW' || selectedStatus === 'INCOMPLETE')
      ) {
        const _newVisitor = {
          ...newVisitor,
          conversation: newVisitor.conversation,
        };

        return {
          ...state,
          visitors: {
            count: count + 1,
            hasAllVisitors,
            page,
            selectedVisitor,
            visitors: [_newVisitor, ...visitors],
          },
        };
      } else {
        return state;
      }
    }
    case 'UPDATE_STATUS': {
      const {
        selectedRoom,
        visitors: { selectedVisitor },
      } = state;
      const { visitId, roomId, status, version } = action.payload;

      let updatedVisitor: Visitor | null = null;
      const visitors = state.visitors.visitors.map((visitor) => {
        if (visitor.id === visitId) {
          updatedVisitor = {
            ...visitor,
            status,
            version,
          };
          return updatedVisitor;
        } else {
          return visitor;
        }
      });

      if (roomId === selectedRoom?.id && visitId === selectedVisitor?.id) {
        return {
          ...state,
          visitors: {
            ...state.visitors,
            visitors,
            selectedVisitor: updatedVisitor || selectedVisitor,
          },
        };
      } else {
        return state;
      }
    }
    case 'UPDATE_ASSIGNMENT': {
      const {
        selectedRoom,
        visitors: { selectedVisitor },
      } = state;
      const { visitId, roomId, assignedStaffAccountId, version } = action.payload;

      let updatedVisitor: Visitor | null = null;
      const visitors = state.visitors.visitors.map((visitor) => {
        if (visitor.id === visitId) {
          updatedVisitor = {
            ...visitor,
            assignedStaffAccountId,
            version,
          };
          return updatedVisitor;
        } else {
          return visitor;
        }
      });

      if (roomId === selectedRoom?.id && visitId === selectedVisitor?.id) {
        return {
          ...state,
          visitors: {
            ...state.visitors,
            visitors,
            selectedVisitor: updatedVisitor || selectedVisitor,
          },
        };
      } else {
        return state;
      }
    }
    case 'UPDATE_CALL': {
      const {
        selectedRoom,
        visitors: { selectedVisitor },
      } = state;
      const { visitId, roomId, activeCall, version } = action.payload;

      let updatedVisitor: Visitor | null = null;
      const visitors = state.visitors.visitors.map((visitor) => {
        if (visitor.id === visitId) {
          updatedVisitor = {
            ...visitor,
            activeCall,
            version,
          };
          return updatedVisitor;
        } else {
          return visitor;
        }
      });

      if (roomId === selectedRoom?.id && visitId === selectedVisitor?.id) {
        return {
          ...state,
          visitors: {
            ...state.visitors,
            visitors,
            selectedVisitor: updatedVisitor || selectedVisitor,
          },
        };
      } else {
        return state;
      }
    }
    case 'UPDATE_ROOM': {
      const { roomId, status, version } = action.payload;

      const { selectedRoom: existingSelectedRoom } = state;

      const newSelectedRoom =
        existingSelectedRoom?.id === roomId
          ? {
              ...existingSelectedRoom,
              status,
              version,
            }
          : existingSelectedRoom;

      return {
        ...state,
        selectedRoom: newSelectedRoom,
      };
    }
    case 'UPDATE_STAFF': {
      const { roomId, staff, version } = action.payload;

      const { selectedRoom: existingSelectedRoom } = state;

      const newSelectedRoom =
        existingSelectedRoom?.id === roomId
          ? {
              ...existingSelectedRoom,
              staff,
              version,
            }
          : existingSelectedRoom;

      return {
        ...state,
        selectedRoom: newSelectedRoom,
      };
    }
    default:
      return ((_: never): never => {
        throw new Error('Reducer is not exhaustive');
      })(action);
  }
};
