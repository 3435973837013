import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Dropdown } from '../WebComponents';
import PatientReferenceInfo from './PatientReferenceInfo';
import PatientReferenceSearch from './PatientReferenceSearch';
import BasicModal from 'common/components/BasicModal';
import BEM from 'common/bem';
import { ReactComponent as CancelIcon } from 'common/images/patient-reference-remove-icon.svg';
import { PatientContextModel } from 'redux-stores/patientContext';

import { actions, useAppDispatch } from 'redux-stores';

const classes = BEM.with('PatientReferenceSelectModal');

const { setModal, setSelectedPatientReference, setPatientSearchView } = actions;

type PatientReferenceSelectModalProps = {
  isOpen: boolean;
};

function PatientReferenceSelectModal({ isOpen }: PatientReferenceSelectModalProps) {
  const dispatch = useAppDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [firstEnter, setFirstEnter] = useState<boolean>(true);
  const [selectedPatientReferenceBuffer, setSelectedPatientReferenceBuffer] = useState<
    PatientContextModel | undefined
  >(undefined);

  useEffect(() => {
    if (firstEnter) {
      setIsDropdownOpen(true);
      setFirstEnter(false);
    }
  }, [firstEnter]);

  useEffect(
    function modalCleanup() {
      if (!isOpen) {
        setSelectedPatientReferenceBuffer(undefined);
        setIsDropdownOpen(false);
        setFirstEnter(true);
      }
    },
    [dispatch, isOpen]
  );

  function closeModal() {
    dispatch(setModal());
    dispatch(setPatientSearchView('All'));
  }

  function onSubmit() {
    dispatch(setSelectedPatientReference(selectedPatientReferenceBuffer));
    closeModal();
  }

  function selectPatientReference(patientContext?: PatientContextModel) {
    setSelectedPatientReferenceBuffer(patientContext);
    setIsDropdownOpen(false);
  }

  function cancelSelectedReference(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setSelectedPatientReferenceBuffer(undefined);
  }

  return (
    <BasicModal
      ariaLabelBody={'patientReferenceModalBody'}
      ariaLabelCancelButton={'cancelPatientReferenceModal'}
      ariaLabelCloseButton={'closePatientReferenceModal'}
      ariaLabelHeader={'patientReferenceModalHeader'}
      ariaLabelSubmitButton={'submitPatientReferenceModal'}
      headerText={'Add a Patient Reference'}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={onSubmit}
      size={'medium'}
      submitDisabled={false}
      submitText={'OK'}
      useWCL
      hasCloseButton
    >
      <div className={classes('')}>
        <div className={classes('body-wrapper')}>
          <div className={classes('title')}>Select a patient reference</div>
          <div className={classes('selector-container')}>
            <div
              className={classNames(
                classes('selector'),
                selectedPatientReferenceBuffer ? classes('selected') : ''
              )}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {selectedPatientReferenceBuffer ? (
                <>
                  {renderPatientContextDetail(selectedPatientReferenceBuffer)}
                  <div
                    className={classes('cancel-icon')}
                    onClick={cancelSelectedReference}
                    data-test-id={'removeSelectedPatientReference'}
                  >
                    <CancelIcon />
                  </div>
                </>
              ) : (
                <>Enter Patient's Name or MRN</>
              )}
            </div>
            {isDropdownOpen && (
              <div className={classes('dropdown-container')}>
                <Dropdown triggerHandler={() => setIsDropdownOpen(!isDropdownOpen)}>
                  <PatientReferenceSearch selectResultFn={selectPatientReference} />
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </div>
    </BasicModal>
  );

  function renderPatientContextDetail(patientContext: PatientContextModel) {
    return <PatientReferenceInfo patientReference={patientContext} type={'card'} showLocation />;
  }
}

export default PatientReferenceSelectModal;
