import React, { useState } from 'react';
import BEM from '../../bem';
import { ReactComponent as DropdownChevronSvg } from '../../images/dropdown-chevron.svg';
import { ReactComponent as EllipsesSvg } from '../../images/icon-ellipsis.svg';
import DotsIndicator from '../DotsIndicator';
import {
  useVWRContext,
  changeSelectedRoom,
  getRoomList,
} from '../../../contexts/VirtualWaitingRoom';
import { useAppContext } from '../../../contexts/App';
import { ActionStatuses } from '../../../types';
import DropdownList from './DropdownList';

const classes = BEM.with('VirtualWaitingRoomSideBarHeader');

export interface SideBarHeaderProps {
  onOptionsButtonPress: () => void;
}

export default function SideBarHeader({ onOptionsButtonPress }: SideBarHeaderProps) {
  const {
    dispatch,
    state: { roomList, selectedRoom, userHasRoomsAvailable, loadedStatus, mobxProps },
  } = useVWRContext();
  const {
    state: {
      virtualWaitingRoom: { newVisitorCounts },
    },
  } = useAppContext();
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

  const currentRoomStatus = selectedRoom?.status;
  const statusClassName =
    currentRoomStatus === 'OPEN' ? 'room-status-text-open' : 'room-status-text-closed';

  const manyRoomsExist = roomList.length > 1;
  const dropdownValues = roomList.map((room) => ({
    ...room,
    isMiniBadgeShown: newVisitorCounts[room.id] > 0,
  }));

  const handleDropdownClick = async () => {
    try {
      setIsDropdownDisabled(true);
      await getRoomList(dispatch, mobxProps.currentOrganizationId);
    } catch (e) {
      console.error(e);
    } finally {
      setIsDropdownDisabled(false);
    }
  };

  return (
    <div className={classes()}>
      {loadedStatus === ActionStatuses.ERROR ? (
        <div className={classes('dropdown-button')}>
          <p>
            There was a problem retrieving the available Virtual Waiting Rooms. Reload the page to
            try again.
          </p>
        </div>
      ) : loadedStatus === ActionStatuses.PENDING ? (
        <div className={classes('loading-container')}>
          <DotsIndicator />
        </div>
      ) : !userHasRoomsAvailable ? (
        <>
          <div className={classes('room-info')}>
            <p className={classes('no-rooms-title')}>Virtual Waiting Room</p>
            <p className={classes('no-rooms-subtext')}>ACCESS PERMISSION REQUIRED</p>
          </div>
          <button disabled>
            <EllipsesSvg />
          </button>
        </>
      ) : (
        <>
          <div className={classes('room-info')}>
            <DropdownList
              {...{
                shouldShowCheckMark: false,
                disabled: isDropdownDisabled,
                handleDropdownClick,
                values: dropdownValues,
                setSelectedValue: ({ index }: { index: number }) => {
                  changeSelectedRoom(dispatch, roomList[index].id, mobxProps);
                },
                selectedValue: { id: selectedRoom?.id || '' },
                shouldScroll: true,
                scrollHeight: 280,
                primaryHeader: (
                  <div className={classes('room-info')}>
                    <div className={classes('selected-room')}>
                      <p className={classes('selected-room-text')}>
                        {selectedRoom?.name || 'No Lobbies Exist'}
                      </p>
                      {manyRoomsExist && <DropdownChevronSvg />}
                    </div>
                  </div>
                ),
                width: 228,
              }}
              centeredItemSubText={<p className={classes(statusClassName)}>{currentRoomStatus}</p>}
            />
          </div>
          <button onClick={onOptionsButtonPress} className={classes('ellipse-button')}>
            <EllipsesSvg />
          </button>
        </>
      )}
    </div>
  );
}
