import React from 'react';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import BEM from '../../bem';
import { ReactComponent as PresenceIndicatorDndAFSvg } from '../../images/dnd-auto-forward-indicator.svg';
import { PresenceIndicatorAvailable, PresenceIndicatorUnavailable, PresenceIndicatorDnd } from './';

const classes = BEM.with('PresenceIndicatorAutoForward');

type MobxProps = {
  currentOrganization: { autoForwardEntitiesIds: string[]; autoForwardReceiver: string[] };
  currentUserId: string;
  hideDnd: boolean;
  isAvailabilityFeatureFlagEnabled: boolean;
  isPresenceFeatureFlagEnabled: boolean;
};

type PresenceIndicatorProps = {
  showDndAfStatus?: boolean;
  dnd?: boolean;
  presence?: string | undefined;
  size?: string;
  userId?: string;
};

const PresenceIndicator = ({
  currentOrganization,
  currentUserId,
  dnd,
  hideDnd,
  showDndAfStatus,
  isAvailabilityFeatureFlagEnabled,
  isPresenceFeatureFlagEnabled,
  presence,
  size = 'MEDIUM',
  userId,
}: PresenceIndicatorProps & MobxProps) => {
  if (
    showDndAfStatus ||
    ((!!currentOrganization?.autoForwardReceiver ||
      !!currentOrganization?.autoForwardEntitiesIds) &&
      currentUserId === userId &&
      !hideDnd)
  ) {
    return <PresenceIndicatorDndAFSvg className={classes({ size })} />;
  } else if (dnd && (currentUserId !== userId || !hideDnd)) {
    return <PresenceIndicatorDnd size={size} />;
  } else if (
    (isPresenceFeatureFlagEnabled || isAvailabilityFeatureFlagEnabled) &&
    (currentUserId === userId || presence === 'AVAILABLE')
  ) {
    return <PresenceIndicatorAvailable size={size} />;
  } else if (
    (isPresenceFeatureFlagEnabled || isAvailabilityFeatureFlagEnabled) &&
    presence === 'UNAVAILABLE'
  ) {
    return isAvailabilityFeatureFlagEnabled ? null : <PresenceIndicatorUnavailable size={size} />;
  } else {
    return null;
  }
};

export default mobxInjectSelect<PresenceIndicatorProps, MobxProps>({
  messengerStore: [
    'currentOrganization',
    'isAvailabilityFeatureFlagEnabled',
    'isPresenceFeatureFlagEnabled',
  ],
  sessionStore: ['currentUserId'],
  userStore: ['hideDnd'],
})(PresenceIndicator);
