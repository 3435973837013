import React, { useCallback, useEffect, useRef, useState } from 'react';
import BEM from '../../../../bem';
import { mobxInjectSelect } from '../../../../utils';
import { CREATION_SUCCESS_TIMEOUT } from '../../../CreateSuccessModal';
import DotsIndicator from '../../../DotsIndicator';
import TextAreaStyled from '../../../TextAreaStyled';
import TemplateVariables from '../TemplateVariables';
import RepositoriesMenu from './RepositoriesMenu';
import TemplateAttachment from './TemplateAttachment';
import TemplateDeliveryMethod from './TemplateDeliveryMethod';
import { TemplateFull } from 'types/smb';
import { templateVariables } from 'models/enums/MessageTemplateVariables';
import { KEYMAP } from 'common/constants';
import {
  MessageTemplateRepositories,
  MessageTemplateRepository,
} from 'models/enums/MessageTemplateRepositories';
import { DeliveryMethod, DeliveryMethods } from 'models/enums/DeliveryMethods';

const classes = BEM.with('TemplateForm');

const { LINK, SMS } = DeliveryMethods;
const { DYNAMIC } = MessageTemplateRepositories;
const TEMPLATE_DUPLICATE_LABEL_ERROR_MESSAGE = 'Template Label already exists';
const TEMPLATE_TITLE_LENGTH_LIMIT = 60;

const variablesToReplace = templateVariables.map((variable) => variable.display);
const lowercaseLookup = variablesToReplace.map((s) => s.toLowerCase());
const detectHashWord = /#[a-zA-Z]+/gi;
export const detectFormattedVariables = new RegExp(`{{(${variablesToReplace.join('|')})}}`, 'g');

export type Attachment = {
  name: string;
  type: string;
};

type AttachmentBackup = {
  isAttachmentFromTemplate: boolean;
  isBackupValid: boolean;
  attachment: Attachment | null;
};

type TemplateForm = {
  attachment?: File | null;
  body: string;
  id?: string;
  isSmsCompatible: boolean;
  repository: MessageTemplateRepository;
  title: string;
};

type MessageTemplateFormProps = {
  id?: string;
  isAdmin: boolean;
  repository: MessageTemplateRepository;
  searchTemplates: () => void;
};

type MobxProps = {
  allowPatientDeliveryMethod: boolean;
  createTemplate: (template: TemplateForm) => Promise<void>;
  downloadTemplateFile: (options: { templateId: string; fileName: string }) => void;
  dynamicSmsMessageLengthLimit: number;
  loadTemplate: (id: string) => Promise<TemplateFull>;
  openModal: (text: string, options?: Record<string, unknown>) => void;
  smsMessageLengthLimit: number;
  updateTemplate: (template: TemplateForm) => Promise<void>;
};

const MessageTemplateForm: React.FC<MessageTemplateFormProps & MobxProps> = ({
  allowPatientDeliveryMethod,
  createTemplate,
  downloadTemplateFile,
  dynamicSmsMessageLengthLimit,
  id,
  isAdmin,
  loadTemplate,
  openModal,
  repository,
  searchTemplates,
  smsMessageLengthLimit,
  updateTemplate,
}) => {
  const [attachment, setAttachment] = useState<Attachment | null>(null);
  const [body, setBody] = useState('');
  const [bodyEdited, setBodyEdited] = useState(false);
  const [fetchingTemplate, setFetchingTemplate] = useState(false);
  const [isAttachmentFromTemplate, setIsAttachmentFromTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUnChecked, setIsUnChecked] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [newRepository, _setNewRepository] = useState(repository);
  const [templateError, setTemplateError] = useState('');
  const [messageDeliveryMethod, _setMessageDeliveryMethod] = useState<DeliveryMethod>(LINK);
  const [title, setTitle] = useState('');
  const validVariableTextLength = useRef(0);
  const messageBodyRef = useRef<HTMLTextAreaElement>(null);
  const attachmentBackup = useRef<AttachmentBackup>({} as AttachmentBackup);
  const isSecureText = messageDeliveryMethod === LINK;
  let characterLimitExceeded: boolean;

  if (newRepository === DYNAMIC) {
    characterLimitExceeded = body.length > dynamicSmsMessageLengthLimit;
  } else {
    characterLimitExceeded = body.length > smsMessageLengthLimit;
  }

  const setMessageDeliveryMethod = (newDeliveryMethod: DeliveryMethod) => {
    if (messageDeliveryMethod === LINK && newDeliveryMethod === SMS) {
      attachmentBackup.current.isAttachmentFromTemplate = isAttachmentFromTemplate;
      attachmentBackup.current.isBackupValid = true;
      attachmentBackup.current.attachment = attachment;
      setIsAttachmentFromTemplate(false);
      setAttachment(null);
    } else if (
      messageDeliveryMethod === SMS &&
      newDeliveryMethod === LINK &&
      attachmentBackup.current.isBackupValid
    ) {
      setIsAttachmentFromTemplate(attachmentBackup.current.isAttachmentFromTemplate);
      setAttachment(attachmentBackup.current.attachment);
    }

    _setMessageDeliveryMethod(newDeliveryMethod);
  };

  const setNewRepository = (repository: MessageTemplateRepository, text?: string) => {
    setBody(processBody(text || body, repository));
    _setNewRepository(repository);
  };

  const downloadFile = useCallback(
    (fileName: string) => {
      if (!isAttachmentFromTemplate || !id) return;
      downloadTemplateFile({ templateId: id, fileName });
    },
    [downloadTemplateFile, id, isAttachmentFromTemplate]
  );

  useEffect(() => {
    const fetchTemplate = async () => {
      if (!id) return;

      setFetchingTemplate(true);
      const template = await loadTemplate(id);

      if (template.hasAttachment) {
        setIsAttachmentFromTemplate(true);
        setAttachment({
          name: template.attachmentName,
          type: template.attachmentType,
        });
      } else {
        setAttachment(null);
      }

      setBody(processBody(template.body, template.repository));
      _setNewRepository(template.repository);
      setTitle(template.title);
      _setMessageDeliveryMethod(template.isSmsCompatible ? SMS : LINK);
      setFetchingTemplate(false);
      attachmentBackup.current.isBackupValid = false;
    };

    fetchTemplate();
  }, [id, loadTemplate]);

  const onDelete = () => {
    openModal('deleteTemplate', {
      selected: [id],
      reloadTemplates: searchTemplates,
    });
  };

  const isPiiVariableWithUnsecureDelivery = (variableDisplay: string) => {
    if (messageDeliveryMethod !== SMS) return false;
    const tempVariable = templateVariables.find((v) => v.display === variableDisplay);
    return !!tempVariable?.pii;
  };

  const formatVariables = (str: string) => {
    if (newRepository !== DYNAMIC) return str;

    const formattedString = str.replace(detectHashWord, (match) => {
      const keyword = match.replace('#', '');
      const i = lowercaseLookup.indexOf(keyword.toLowerCase());
      if (i === -1 || isPiiVariableWithUnsecureDelivery(keyword)) {
        return match;
      }

      return `{{${keyword}}}`;
    });

    return formattedString;
  };

  const onSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const form: TemplateForm = {
      body: formatVariables(body),
      isSmsCompatible: !isSecureText,
      repository: newRepository,
      title,
    };

    if (event.target.attachment?.files?.length) {
      form.attachment = event.target.attachment.files[0];
    } else if (attachment === null) {
      form.attachment = null;
    }

    try {
      let verb;
      if (id) {
        form.id = id;
        await updateTemplate(form);
        verb = 'updated';
      } else {
        await createTemplate(form);
        verb = 'created';
      }
      setTemplateError('');
      openModal('createSuccessModal', {
        content: `Message Template was successfully ${verb}`,
      });
      window.setTimeout(() => {
        searchTemplates();
        setIsLoading(false);
      }, CREATION_SUCCESS_TIMEOUT);
    } catch (error) {
      const { response = {} } = error;
      if (response.body) {
        const { error, status } = response.body;
        const { message } = error;
        if (status === 'fail' && message === TEMPLATE_DUPLICATE_LABEL_ERROR_MESSAGE) {
          setTemplateError('Template must have a unique label.');
          setIsLoading(false);
          return false;
        }
      }

      console.error(error);
      openModal('patientAdminFailure', {
        body: 'Unable to save template. Please try again.',
      });
      setIsLoading(false);
    }
  };

  const processBody = (text = '', repository: MessageTemplateRepository) => {
    if (repository !== DYNAMIC) return text;

    return text.replace(detectFormattedVariables, '#$1');
  };

  const renderStylizedText = (textAreaValue: string) => {
    setBody(textAreaValue);
    if (newRepository !== DYNAMIC) {
      return textAreaValue;
    }
    validVariableTextLength.current = 0;
    return textAreaValue.replace(detectHashWord, (match, offset, str) => {
      const prefix = str.charAt(offset - 1);
      const suffix = str.charAt(offset + match.length);
      if (!(offset === 0 || /\s/.test(prefix))) {
        return match;
      }

      const keyword = match.replace('#', '');
      let className = 'highlighted';
      const i = lowercaseLookup.indexOf(keyword.toLowerCase());
      if (i === -1 || isPiiVariableWithUnsecureDelivery(keyword)) {
        className = 'highlightedError';
      } else if (keyword !== variablesToReplace[i]) {
        let endingSpace = '';
        if (suffix !== ' ') {
          endingSpace = ' ';
        }
        match = match.replace(keyword, variablesToReplace[i]) + endingSpace;

        if (messageBodyRef.current) {
          const caretPos = messageBodyRef.current.selectionEnd + endingSpace.length;
          messageBodyRef.current.value = messageBodyRef.current.value.replace(
            `#${keyword}`,
            `#${variablesToReplace[i]}${endingSpace}`
          );
          messageBodyRef.current.selectionStart = messageBodyRef.current.selectionEnd = caretPos;
        }
      }

      if (className === 'highlighted') {
        validVariableTextLength.current += match.length;
      }

      return `<span class="${className}">${match}</span>`;
    });
  };

  const validateForm = useCallback(
    () =>
      title.length > 0 &&
      body.length > 0 &&
      !(!isSecureText && (isUnChecked || characterLimitExceeded)),
    [body.length, characterLimitExceeded, isSecureText, isUnChecked, title.length]
  );

  useEffect(() => {
    setIsValid(validateForm());
  }, [validateForm]);

  return (
    <div className={classes()}>
      <form className={classes('form')} onSubmit={onSubmit}>
        <div className={classes('wrapper')}>
          <div className={classes('header')}>{id ? 'Edit' : 'Create'} Message Template</div>
          {fetchingTemplate && <DotsIndicator color={'#969696'} size={13} />}
          {!fetchingTemplate && (
            <>
              {templateError && <div className={classes('template-error')}>{templateError}</div>}
              <div className={classes('formGroup')}>
                <label>
                  Template Label
                  <input
                    className={classes('formInput')}
                    data-test-id={'template-label-input'}
                    type="text"
                    name="title"
                    onChange={({ target }) =>
                      setTitle(target.value.trimStart().slice(0, TEMPLATE_TITLE_LENGTH_LIMIT))
                    }
                    value={title}
                    placeholder="Enter title here..."
                  />
                  <div className={classes('titleSubtext')}>
                    {TEMPLATE_TITLE_LENGTH_LIMIT - title.length} Characters Left
                  </div>
                </label>
              </div>
              {allowPatientDeliveryMethod && (
                <TemplateDeliveryMethod
                  messageDeliveryMethod={messageDeliveryMethod}
                  setMessageDeliveryMethod={setMessageDeliveryMethod}
                />
              )}
              <div className={classes('formGroup')}>
                <label htmlFor="messageBody" onClick={() => messageBodyRef.current?.focus()}>
                  Message
                </label>
                <div className={classes('textArea')}>
                  <div className={classes('inputGroup')}>
                    <TextAreaStyled
                      body={body}
                      placeholder="Enter message here..."
                      renderStylizedText={renderStylizedText}
                      textAreaRef={messageBodyRef}
                      bodyEdited={bodyEdited}
                      setBodyEdited={setBodyEdited}
                      isSecureText={isSecureText}
                    />
                    {!isSecureText && (
                      <div
                        className={classes('messageLengthSubtext', {
                          lengthExceeded: characterLimitExceeded,
                          emptyMessageBody: body.length < 1,
                        })}
                      >
                        {body.length - validVariableTextLength.current} /{' '}
                        {newRepository === DYNAMIC
                          ? dynamicSmsMessageLengthLimit
                          : smsMessageLengthLimit}
                      </div>
                    )}
                  </div>
                  {newRepository === DYNAMIC && (
                    <TemplateVariables
                      bodyEdited={bodyEdited}
                      bodyRef={messageBodyRef}
                      isDeliveryMethodSms={messageDeliveryMethod === SMS}
                      setBodyEdited={setBodyEdited}
                    />
                  )}
                </div>
              </div>
              <TemplateAttachment
                attachment={attachment}
                downloadFile={downloadFile}
                isAttachmentFromTemplate={isAttachmentFromTemplate}
                isSecureText={isSecureText}
                setAttachment={setAttachment}
                setIsAttachmentFromTemplate={setIsAttachmentFromTemplate}
              />

              {!isSecureText && (
                <div className={classes('formGroup')}>
                  <label>
                    Consent
                    <div
                      className={classes('messageUnsecuredNote', {
                        isDisabled: id && !bodyEdited,
                      })}
                    >
                      <input
                        type="checkbox"
                        tabIndex={0}
                        className={classes('messageUnsecuredNoteCheckbox')}
                        id="messageUnsecuredNoteCheckbox"
                        checked={(id && !bodyEdited) || !isUnChecked}
                        onChange={() => setIsUnChecked(!isUnChecked)}
                        onKeyDown={(e) => {
                          if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
                            e.preventDefault();
                            setIsUnChecked(!isUnChecked);
                          }
                        }}
                      />
                      SMS messages are unencrypted and unsecured. I confirm that the content above
                      does not contain any PHI.
                    </div>
                  </label>
                </div>
              )}
              {isAdmin && (
                <RepositoriesMenu
                  disabled={newRepository === DYNAMIC}
                  newRepository={newRepository}
                  setNewRepository={setNewRepository}
                />
              )}
            </>
          )}
        </div>
        <div className={classes('footer')}>
          {id && (
            <button
              className={classes('delete')}
              data-test-id={'template-delete'}
              onClick={onDelete}
              type="button"
            >
              Delete
            </button>
          )}
          <button
            className={classes('cancel')}
            data-test-id={'template-cancel'}
            onClick={searchTemplates}
            type="button"
          >
            Cancel
          </button>
          <button
            className={classes('submit')}
            data-test-id={`template-${id ? 'save' : 'create'}`}
            disabled={!isValid || isLoading}
            type="submit"
          >
            {id ? 'Sav' : 'Creat'}
            {isLoading ? 'ing' : 'e'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default mobxInjectSelect<MessageTemplateFormProps, MobxProps>({
  patientAdminStore: [
    'allowPatientDeliveryMethod',
    'createTemplate',
    'loadTemplate',
    'updateTemplate',
  ],
  modalStore: ['openModal'],
  scheduleMessageStore: [
    'downloadTemplateFile',
    'smsMessageLengthLimit',
    'dynamicSmsMessageLengthLimit',
  ],
})(MessageTemplateForm);
