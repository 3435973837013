import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import * as sessionThunk from './thunk';

export type SessionSliceState = {
  isModalOpen?: boolean;
  openModal?: ModalPayload;
  currentOrganizationId?: string;
};

export type ModalPayload = {
  name: ModalName;
  data?: Record<string, unknown>;
};

type CollaborationModal =
  | 'clearScheduler'
  | 'deleteEntity'
  | 'deleteTag'
  | 'error'
  | 'escalation'
  | 'leaveTeam'
  | 'mentionMember'
  | 'addMemberToConversation'
  | 'owner'
  | 'ownerRequired'
  | 'pager'
  | 'removePager'
  | 'removeTag'
  | 'rtuWarningScreen'
  | 'roleScheduler'
  | 'tag'
  | 'tagSelector'
  | 'tagSelectorEditor'
  | 'onDuty'
  | 'optOut'
  | 'optIn';

type PatientReferenceModal = 'patientReferenceSelect' | 'patientReferenceDetails';

type AdminModal = 'patientAccessCode' | 'patientDOB' | 'orgSettingEnabled';

type ProfileModal = 'customDndMessage';

export type ModalName = CollaborationModal | PatientReferenceModal | AdminModal | ProfileModal;

const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    isModalOpen: undefined,
    openModal: undefined,
    currentOrganizationId: undefined,
  } as SessionSliceState,
  reducers: {
    setCurrentOrganizationId: (state, action: PayloadAction<string>) => {
      state.currentOrganizationId = action.payload;
    },
    setModal: (state, action: PayloadAction<ModalPayload | undefined>) => {
      if (action.payload === undefined) {
        state.isModalOpen = false;
      } else {
        state.isModalOpen = true;
        state.openModal = { ...action.payload };
      }
    },
  },
});

export { sessionSlice, sessionThunk };
