import styles from '../index.module.css';
import { RenderSettingsParams } from './RenderSettings';
import { UserSettings, UserOrganizationSettings } from 'admin/types';

import { TTL_CONTROL_OPTIONS, PINLOCK_TTL_CONTROL_OPTIONS } from 'admin/utils/commonControlOptions';

import { Slider } from 'admin/shared-components/Slider/Slider';
import { Dropdown } from 'admin/shared-components/Dropdown/Dropdown';
import { Checkbox, CheckboxProps } from 'admin/shared-components/Checkbox/Checkbox';
import {
  isIndividual,
  hasOrgFeatureOrSettings as hasFeature,
} from 'admin/utils/renderSettingConditions';

type RenderCheckBoxSettings = {
  userSettingKey: keyof UserSettings;
} & CheckboxProps;

export const userSettingsLabels = (
  userSettings: UserSettings,
  orgSettings: UserOrganizationSettings,
  setUserSettings: (us: UserSettings) => void,
  featureFlags: Record<string, unknown>,
  urlParams: Record<string, string>
) => {
  const getIsIndividual = (setting: keyof UserOrganizationSettings) =>
    isIndividual(orgSettings, setting);
  const hasOrgFeatureOrSettings = (settings: Array<keyof UserOrganizationSettings>) =>
    hasFeature(orgSettings, settings);
  const initialTTLIndex = TTL_CONTROL_OPTIONS.findIndex(
    ({ value }) => value === userSettings?.messageLifespan
  );

  const currentInitialTTLIndex =
    initialTTLIndex === -1 ? TTL_CONTROL_OPTIONS.length - 1 : initialTTLIndex;

  const renderCheckbox = (props: RenderCheckBoxSettings): JSX.Element => {
    const { userSettingKey, ...rest } = props;
    return (
      <Checkbox
        checked={!!userSettings[userSettingKey]}
        onClick={(v) => setUserSettings({ ...userSettings, [userSettingKey]: v })}
        {...rest}
      />
    );
  };

  const settings: RenderSettingsParams[] = [
    {
      label: 'Message Lifespan',
      subLabel: 'Sent messages will be deleted after this time limit',
      settingControl: (
        <Slider
          onChange={(s) => setUserSettings({ ...userSettings, messageLifespan: s })}
          initialIndex={currentInitialTTLIndex}
          initialLabel={TTL_CONTROL_OPTIONS[currentInitialTTLIndex]?.label}
          options={TTL_CONTROL_OPTIONS}
        />
      ),
    },
    {
      label: 'Delete on Read',
      subLabel: 'All messages will be deleted one minute after opening',
      settingControl: renderCheckbox({
        userSettingKey: 'isDeleteOnReadEnabled',
      }),
    },
    {
      label: 'PIN Lock (mobile)',
      subLabel:
        'Users are required to enter their PIN code on the mobile application after this time limit.',
      settingControl: (
        <Dropdown
          defaultValue={userSettings.pinLock}
          options={PINLOCK_TTL_CONTROL_OPTIONS}
          onChange={(_, i) => {
            setUserSettings({
              ...userSettings,
              pinLock: parseInt(PINLOCK_TTL_CONTROL_OPTIONS[i].value, 10),
            });
          }}
        />
      ),
    },
    {
      label: 'Repeat Notifications',
      subLabel:
        'Users will receive a new alert sound every 2 minutes, up to 20 minutes, until the message is viewed.',
      settingControl: renderCheckbox({
        userSettingKey: 'areRepeatNotificationsEnabled',
      }),
    },
    {
      label: 'Fast Deploy',
      subLabel: 'Users not logged in will receive TigerConnect messages via email and SMS.',
      settingControl: renderCheckbox({
        userSettingKey: 'isFastDeployEnabled',
      }),
    },
    {
      label: 'Message Forwarding',
      subLabel: 'Enable users to forward TigerConnect messages to others within the organization.',
      settingControl: renderCheckbox({
        userSettingKey: 'isMessageForwardingEnabled',
      }),
    },
    {
      requiredOrgSettings: ['featureClickToCall', 'clickToCallSetting'],
      label: 'Click to Call',
      subLabel:
        'Enable this feature to allow your users to one-tap call each other within TigerConnect. Only works for users with verified mobile numbers.',
      settingControl: (
        <div>
          {renderCheckbox({
            userSettingKey: 'isClickToCallEnabled',
            disabled: orgSettings.clickToCallSetting.clickToCallStatusSetting !== 'individual',
          })}
          {orgSettings.clickToCallSetting.clickToCallStatusSetting !== 'individual' && (
            <span className={styles.clickToCallAdmin}>Controlled by Admin</span>
          )}
        </div>
      ),
    },
    {
      requiredOrgSettings: ['isVoipCallingEnabled'],
      label: 'VoIP Audio Calling',
      subLabel: 'Allow users to make and receive VoIP audio calls.',
      settingControl: renderCheckbox({
        userSettingKey: 'isVoipCallingEnabled',
      }),
    },
    {
      forbiddenOffCondition: 'groupAudioCallingSetting',
      label: 'Group Audio Calling',
      subLabel: 'Allow users to make and receive group audio calls.',
      settingControl: renderCheckbox({
        userSettingKey: 'groupAudioCallingSetting',
        disabled: !(
          hasOrgFeatureOrSettings(['isVoipCallingEnabled']) &&
          getIsIndividual('groupAudioCallingSetting')
        ),
      }),
    },
    {
      requiredOrgSettings: ['isVideoCallEnabled'],
      label: 'Video Calling',
      subLabel: 'Allow users to make and receive video calls.',
      settingControl: renderCheckbox({ userSettingKey: 'isVideoCallingEnabled' }),
    },
    {
      forbiddenOffCondition: 'groupVideoCallingSetting',
      label: 'Group Video Calling',
      subLabel: 'Allow users to make and receive group video calls.',
      settingControl: renderCheckbox({
        userSettingKey: 'groupVideoCallingSetting',
        disabled: !(
          userSettings.isVideoCallingEnabled && getIsIndividual('groupVideoCallingSetting')
        ),
      }),
    },
    {
      requiredOrgSettings: ['featureDeliveryEscalation'],
      label: 'Delivery Escalations',
      subLabel: 'Enable SMS delivery if TigerConnect messages are undeliverable after 5 minutes.',
      settingControl: renderCheckbox({ userSettingKey: 'areDeliveryEscalationsEnabled' }),
    },
    {
      requiredOrgSettings: ['featurePriorityMessaging'],
      requiredIndividualCondition: 'priorityMessagingSetting',
      label: 'Priority Messaging',
      subLabel: 'Enable users to send high priority messages.',
      settingControl: renderCheckbox({ userSettingKey: 'priorityMessagingSetting' }),
    },
    {
      requiredOrgSettings: ['featureEnterprisePbx'],
      forbiddenOffCondition: 'pbxIntegrationSetting',
      show: !!(urlParams.allowPbxIntegration || featureFlags?.pbx),
      label: 'Enterprise PBX Integration',
      subLabel:
        'Enable users to access PBX calling capabilities within TigerConnect. (Requires PBX enterprise network integration)',
      settingControl: renderCheckbox({
        userSettingKey: 'enterprisePbxSetting',
        disabled: !getIsIndividual('pbxIntegrationSetting'),
      }),
    },
    {
      requiredOrgSettings: ['featureTeams'],
      requiredIndividualCondition: 'teamsSetting',
      label: 'Teams',
      subLabel: 'Allow users to create and activate teams',
      settingControl: renderCheckbox({ userSettingKey: 'teamsSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientContext'],
      requiredIndividualCondition: 'patientContextSetting',
      label: 'Patient Reference',
      subLabel: 'Allow users to start a group message about a specific patient.',
      settingControl: renderCheckbox({ userSettingKey: 'patientContextSetting' }),
    },
    {
      requiredOrgSettings: ['featureTigerSchedule'],
      requiredIndividualCondition: 'tigerScheduleSetting',
      label: 'TigerConnect Physician Scheduling',
      subLabel: 'Allow users to access TigerConnect Physician Scheduling applications.',
      settingControl: renderCheckbox({ userSettingKey: 'tigerScheduleSetting' }),
    },
    {
      requiredIndividualCondition: 'multiFactorAuthSetting',
      show: !!(featureFlags?.MFA || urlParams.allowMfa),
      label: 'MFA Authentication',
      subLabel: 'Will enable the MFA Authentication process during login for this user.',
      settingControl: renderCheckbox({
        userSettingKey: 'multiFactorAuthSetting',
        disabled: !getIsIndividual('multiFactorAuthSetting'),
      }),
    },
  ];

  const patientEngagementSettings: RenderSettingsParams[] = [
    {
      requiredOrgSettings: ['featurePatientEngagement'],
      requiredIndividualCondition: 'patientEngagementSetting',
      label: 'TigerConnect Patient Engagement',
      subLabel:
        'Enable this feature to allow your users to communicate with patients. You can choose to enable this feature for your entire organization or enable this feature to individual users.',
      settingControl: renderCheckbox({ userSettingKey: 'patientEngagementSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientQuickAdd'],
      requiredIndividualCondition: 'patientQuickAddSetting',
      label: 'Patient Quick Add',
      subLabel: 'Allow users to quickly add a patient from their mobile application.',
      settingControl: renderCheckbox({
        userSettingKey: 'patientQuickAddSetting',
        disabled: !userSettings.patientEngagementSetting,
      }),
    },
    {
      requiredOrgSettings: ['featurePatientQuickCall'],
      requiredIndividualCondition: 'patientQuickCallSetting',
      label: 'Patient Quick Call',
      subLabel:
        'Allow users to quickly dial any telephone number using masked cellular calling from their mobile application.',
      settingControl: renderCheckbox({
        userSettingKey: 'patientQuickCallSetting',
        disabled: !userSettings.patientEngagementSetting,
      }),
    },
    {
      requiredOrgSettings: ['featurePatientQuickCallVideo'],
      requiredIndividualCondition: 'patientQuickCallVideoSetting',
      label: 'Patient Quick Call Video',
      subLabel:
        'Allow users to quickly make video calls to patients from their mobile application.',
      settingControl: renderCheckbox({ userSettingKey: 'patientQuickCallVideoSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientVoip'],
      requiredIndividualCondition: 'patientVoiceCallingSetting',
      show: !!urlParams.pfVoiceCall,
      label: 'Voice Calling',
      subLabel: 'Enable users to voice call with patients.',
      settingControl: renderCheckbox({ userSettingKey: 'patientVoiceCallingSetting' }),
    },
    {
      requiredOrgSettings: ['featureGroupAudioCalling'],
      forbiddenOffCondition: 'groupAudioCallingSetting',
      show: !!urlParams.pfGroupVoiceCall,
      label: 'Group Voice Calling',
      subLabel: 'Enable users to group voice call with patients.',
      settingControl: renderCheckbox({ userSettingKey: 'patientGroupVoiceCallingSetting' }),
    },
    {
      requiredOrgSettings: ['featureVideoCall'],
      requiredIndividualCondition: 'patientVideoCallingSetting',
      label: 'Video Calling',
      subLabel: 'Enable users to video call with patients.',
      settingControl: renderCheckbox({ userSettingKey: 'patientVideoCallingSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientGroupVideoCalling'],
      requiredIndividualCondition: 'patientGroupVideoCallingSetting',
      label: 'Group Video Calling',
      subLabel: 'Enable users to group video call with patients.',
      settingControl: renderCheckbox({ userSettingKey: 'patientGroupVideoCallingSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientEngagement'],
      requiredIndividualCondition: 'patientListAccessSetting',
      label: 'Patient List Access',
      subLabel: 'Allow providers to access and update patient information in the Patient List.',
      settingControl: renderCheckbox({ userSettingKey: 'patientListAccessSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientScheduledMessages'],
      requiredIndividualCondition: 'patientScheduledMessagesSetting',
      label: 'Schedule Messages',
      subLabel: 'Allow providers to schedule messages to patients.',
      settingControl: renderCheckbox({ userSettingKey: 'patientScheduledMessagesSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientVirtualWaitingRoom'],
      forbiddenOffCondition: 'patientVirtualWaitingRoomSetting',
      label: 'Virtual Waiting Room',
      subLabel:
        'Will enable the ability for this user to be given access to a virtual waiting room.',
      settingControl: renderCheckbox({
        userSettingKey: 'virtualWaitingRoomSetting',
        disabled: !getIsIndividual('patientVirtualWaitingRoomSetting'),
      }),
    },
    {
      requiredOrgSettings: ['featurePatientBroadcasting'],
      requiredIndividualCondition: 'patientBroadcastingSetting',
      label: 'Patient Broadcasting',
      subLabel: 'Allow providers to broadcast messages to patients.',
      settingControl: renderCheckbox({ userSettingKey: 'patientBroadcastingSetting' }),
    },
  ];

  return { settings, patientEngagementSettings };
};
