import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import propTypes from '../propTypes';
import { mobxInjectSelect } from '../utils';
import AvatarImage from './AvatarImage';

const classes = BEM.with('DistributionListAvatar');

class DistributionListAvatar extends Component {
  static propTypes = {
    distributionList: propTypes.distributionList.isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  render() {
    const { avatarUrl, displayName, distributionList, isBroadcastListSettingsAllowed, size } =
      this.props;

    return (
      <AvatarImage
        className={classes()}
        avatarUrl={avatarUrl || distributionList.avatarUrl}
        displayName={displayName || distributionList.displayName}
        entityType={
          isBroadcastListSettingsAllowed && distributionList.id.includes('@')
            ? 'distributionListPregen'
            : 'distributionList'
        }
        size={size}
      />
    );
  }
}

export default mobxInjectSelect({
  messengerStore: ['isBroadcastListSettingsAllowed'],
})(DistributionListAvatar);
