import React from 'react';
import { mobxInjectSelect } from '../../utils';
import Modal from '../Modal';

import BEM from '../../bem';

const classes = BEM.with('CalleePermissionsModal');

function CalleePermissionsModal({ closeModal, isOpen, options, startCall }) {
  const { entity, filteredIds, isVideo } = options;
  const entityType = entity.$entityType;
  const isGroup = entityType === 'group';

  const initiateCall = async () => {
    const members = isGroup ? entity.members : [];
    startCall({
      recipientId: entity.id,
      members,
      isVideo,
      context: { type: entityType === 'user' ? 'account' : entityType, id: entity.id },
    });
  };

  return (
    <Modal
      bodyClass={classes('body')}
      closeClass={classes('close-button')}
      headerClass={classes('header')}
      className={classes()}
      header="Permissions Error"
      isOpen={isOpen}
      onRequestClose={closeModal}
      size={'medium'}
      footerPrimaryActions={
        <button type="button" className={classes('ok-btn')} onClick={initiateCall}>
          OK
        </button>
      }
      footerSecondaryActions={
        <button type="button" onClick={closeModal} className={classes('cancel-btn')}>
          CANCEL
        </button>
      }
    >
      <div className={classes('body-container')}>
        <div className={classes('description-container')}>
          {entity.members
            .filter((member) => filteredIds.includes(member.id))
            .map(
              (member, index) =>
                `${member.displayName}${index + 1 !== filteredIds.length ? ', ' : ' '}`
            )}
          {`${
            filteredIds.length === 1 ? 'does' : 'do'
          } not have calling permissions, press OK to continue.`}
        </div>
      </div>
    </Modal>
  );
}

export default mobxInjectSelect({
  callStore: ['closeModal', 'startCall'],
})(CalleePermissionsModal);
