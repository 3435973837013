import createMobxModel from './createMobxModel';

export const CareTeam = createMobxModel({
  name: 'careTeam',

  fields: [
    'activationTime',
    'avatarUrl',
    'department',
    'displayName',
    'dnd',
    'firstName',
    'isExternal',
    'lastLoginTime',
    'lastName',
    'organizationKey',
    'pagers',
    'phones',
    'presenceStatus',
    'status',
    'tags',
    'token',
    'timeToLive',
    'title',
    'username',
  ],

  relations: {
    one: {
      organization: { type: 'organization' },
    },
  },
});

export default CareTeam;
