const VideoCallMemberStatus = {
  CONNECTED: 'Connected',
  NOTCONNECTED: 'Not Connected',
  LEFTCALL: 'Left call',
  MISSED: 'Missed call',
  DECLINED: 'Declined call',
  RINGING: 'Ringing...',
  UNAVAILABLE: 'Unavailable',
};

export default VideoCallMemberStatus;
