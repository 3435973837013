import React from 'react';

import VWRContextProvider from '../../../contexts/VirtualWaitingRoom/context';
import VirtualWaitingRoom from './VirtualWaitingRoom';

export default function VWRPage() {
  return (
    <VWRContextProvider>
      <VirtualWaitingRoom />
    </VWRContextProvider>
  );
}
