import React, { useEffect, useRef, useState } from 'react';
import ToolTip from 'rc-tooltip';
import BEM from '../../../bem';

import { InlineEditor } from '../../';
import { ReactComponent as CloseSidebarSvg } from '../../../images/search-cancel.svg';
import { useAMContext } from '../../../../contexts/AutomatedMessages';
import type { Workflow, WorkflowEvent } from '../../../../types';
import { WorkflowActivation, WorkflowClipboard, WorkflowEventsList } from './';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('AutomatedMessages');

type WorkflowSidebarProps = {
  workflow: Workflow | null;
  workflowEvents: WorkflowEvent[];
  disableDeleteButton: boolean;
};

type InlineEditorType = {
  focus: () => void;
};

export default function WorkflowSidebar({
  workflow,
  workflowEvents,
  disableDeleteButton,
}: WorkflowSidebarProps) {
  const {
    cancelNewWorkflow,
    createWorkflow,
    deleteWorkflow,
    isEditingWorkflowName,
    selectedWorkflow,
    selectWorkflow,
    setIsEditingWorkflowName,
    updateWorkflow,
    workflowError,
  } = useAMContext();
  const deleteWorkflowDisabled = isEditingWorkflowName || workflow?.isActive;
  const editorRef = useRef<InlineEditorType>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const deleteButtonRef = useRef<HTMLButtonElement>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  let deleteWorkflowFragment;

  const handleMouseEnter = () => {
    if (deleteWorkflowDisabled || disableDeleteButton) {
      setTooltipVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  const handleFocus = () => {
    if (deleteWorkflowDisabled || disableDeleteButton) {
      setTooltipVisible(true);
    }
  };

  const handleBlur = () => {
    setTooltipVisible(false);
  };

  const saveWorkflowName = (name: string) => {
    setIsEditingWorkflowName(false);
    if (workflow?.isPlaceholder) {
      createWorkflow(name.trim());
    } else if (workflow) {
      updateWorkflow({ id: workflow.id, label: name.trim() });
    }
  };

  const close = () => {
    selectedWorkflow?.isPlaceholder && cancelNewWorkflow(true);
    selectWorkflow(null);
  };

  const deleteCurrentWorkflow = () => {
    if (workflow) deleteWorkflow(workflow.id);
  };

  useEffect(() => {
    if (!workflow?.isPlaceholder) return;
    setIsEditingWorkflowName(true);
  }, [setIsEditingWorkflowName, workflow]);

  useEffect(() => {
    if (isEditingWorkflowName && editorRef.current) {
      editorRef.current.focus();
    }
  }, [isEditingWorkflowName]);

  const deleteWorkflowButton = (
    <button
      className={classes('delete-workflow', {
        isEnabled: !deleteWorkflowDisabled && !disableDeleteButton,
      })}
      onClick={() => !deleteWorkflowDisabled && deleteCurrentWorkflow()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      aria-describedby={
        (deleteWorkflowDisabled || disableDeleteButton) && tooltipVisible
          ? 'deleteWorkflowTooltip'
          : undefined
      }
      ref={deleteButtonRef}
    >
      Delete Workflow
    </button>
  );

  const tooltipContent = (
    <span id="deleteWorkflowTooltip">
      {deleteWorkflowDisabled
        ? 'Must remove workflow from all existing records and deactivate to delete.'
        : 'There must always be at least one workflow available.'}
    </span>
  );

  if (deleteWorkflowDisabled || disableDeleteButton) {
    deleteWorkflowFragment = (
      <ToolTip
        trigger={['hover', 'focus']}
        visible={tooltipVisible}
        placement="bottom"
        overlay={tooltipContent}
        overlayInnerStyle={{
          padding: '6px',
          width: '225px',
          minHeight: '10px',
          textAlign: 'center',
        }}
      >
        {deleteWorkflowButton}
      </ToolTip>
    );
  } else {
    deleteWorkflowFragment = deleteWorkflowButton;
  }

  return (
    <div className={classes('sidebar')}>
      {!isEditingWorkflowName && (
        <div className={classes('workflow-details')}>
          <button
            className={classes('workflow-name-existing')}
            onClick={() => setIsEditingWorkflowName(true)}
          >
            {workflow?.label}
          </button>
          <button onClick={close} className={classes('sidebar-workflow-close')}>
            <CloseSidebarSvg aria-disabled className={classes('sidebar-workflow-close-button')} />
          </button>
        </div>
      )}
      {isEditingWorkflowName && (
        <div className={classes('workflow-name')}>
          <div className={classes('sidebar-workflow-name')}>
            <InlineEditor
              classesName="AutomatedMessages"
              editorRef={editorRef}
              openedEditor
              placeholder="Workflow Name"
              save={saveWorkflowName}
              saveOnBlur
              listError={workflowError}
              value={workflow?.label || ''}
              onBlur={() => {
                setIsEditingWorkflowName && setIsEditingWorkflowName(false);
                workflow?.isPlaceholder && close();
              }}
              isAutomated
              insideRefs={[buttonRef]}
            />
          </div>
          <div
            ref={buttonRef}
            onClick={close}
            className={classes('sidebar-workflow-close', { workflowEdit: isEditingWorkflowName })}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
                close();
              }
            }}
          >
            <CloseSidebarSvg
              className={classes('sidebar-workflow-close-button', {
                workflowEdit: isEditingWorkflowName,
              })}
              aria-hidden
            />
          </div>
        </div>
      )}
      <div
        className={classes('workflow-information', {
          workflowNameSet: !isEditingWorkflowName,
        })}
      >
        <WorkflowActivation
          workflowDisabled={isEditingWorkflowName}
          workflowId={workflow?.id}
          workflowIsActive={workflow?.isActive}
        />
        <WorkflowEventsList
          isWorkflowActive={workflow?.isActive}
          workflowDisabled={isEditingWorkflowName}
          workflowEvents={workflowEvents}
        />
        {workflow?.id && <WorkflowClipboard workflow={workflow} />}
        {deleteWorkflowFragment}
      </div>
    </div>
  );
}
