import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FacilityModal } from '../DetailsModals/FacilityModal';
import TCClient from '../../../../client';
import plusIcon from '../../../images/plus.svg';
import { DotsIndicator } from '../../../../common/components';
import { OrganizationHierarchy } from '../../../shared-components/OrganizationHierarchy/OrganizationHierarchy';
import styles from './Locations.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

type LocationsData = {
  facilityId: string;
  facilityDisplayName: string;
  areas: Area[];
  units?: Units[];
};

type Units = {
  unitId: string;
  unitDisplayName: string;
  rooms: Array<{
    roomId: string;
    roomDisplayName: string;
    sections: Array<{
      sectionDisplayName: string;
      sectionId: string;
    }>;
  }>;
};

type Area = {
  areaId: string;
  areaDisplayName: string;
  rooms: Room[];
};

type Room = {
  roomId: string;
  roomDisplayName: string;
  sections: Section[];
};

type Section = {
  sectionId: string;
  sectionDisplayName: string;
};

export function LocationsTab() {
  const [locations, setLocations] = useState<LocationsData[]>([]);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [closedList, setClosedItems] = useState<{ [k: string]: boolean }>({});
  const dispatch = useAppDispatch();
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);
  const isLocationsDataDirty = useAppSelector((state) => state.multiOrg.isLocationsDataDirty);

  const isFacilityModalOpen = useAppSelector((state) => state.multiOrg.isFacilityModalOpen);

  const trackOpenParents = useCallback(
    (id: string, open: boolean) => {
      setClosedItems({ ...closedList, [id]: !open });
    },
    [closedList]
  );

  useEffect(() => {
    const getFacilities = async () => {
      setLoadingLocations(true);
      const locations = await TCClient.multiOrg.getOrganizationLocations(selectedOrganizationId);
      setLocations(locations);
      dispatch(actions.setLocationsDataDirty({ isLocationsDataDirty: false }));
      setLoadingLocations(false);
    };
    if (isLocationsDataDirty) {
      getFacilities();
    }
  }, [isLocationsDataDirty, dispatch, selectedOrganizationId]);

  if (loadingLocations)
    return (
      <div className={classNames(styles.loading)}>
        <DotsIndicator />
      </div>
    );

  return (
    <div className={classNames(styles.orgTable)}>
      {locations.map((facility) => {
        const { facilityId, facilityDisplayName, areas, units } = facility;
        if (closedList[facilityId] === undefined) {
          closedList[facilityId] = false;
        }

        return (
          <div className={classNames(styles.parentContainer)} key={facilityId}>
            {facilityId && (
              <OrganizationHierarchy
                key={facilityId}
                id={facilityId}
                name={facilityDisplayName}
                index={0}
                children={areas && areas.length > 0 && areas.some((area) => area.areaId !== null)}
                closed={!closedList[facilityId]}
                trackOpenParents={trackOpenParents}
                locationType={'Facility'}
              />
            )}
            {facility.areas &&
              closedList[facilityId] &&
              facility.areas.map((area: Area) => {
                const { areaId, areaDisplayName, rooms } = area;
                if (closedList[areaId] === undefined) {
                  closedList[areaId] = false;
                }

                return (
                  <div className={classNames(styles.parentContainer)} key={areaId}>
                    {areaId && (
                      <OrganizationHierarchy
                        key={areaId}
                        id={areaId}
                        name={areaDisplayName}
                        index={1}
                        children={rooms?.length > 0 && rooms.some((room) => room.roomId !== null)}
                        closed={!closedList[areaId]}
                        trackOpenParents={trackOpenParents}
                        locationType={'Floor'}
                      />
                    )}
                    {area.rooms &&
                      closedList[areaId] &&
                      area.rooms.map((room: Room) => {
                        const { roomId, roomDisplayName, sections } = room;

                        const unitName: { [k: string]: string } = {};

                        units &&
                          units.forEach((unit) => {
                            unit.rooms.forEach((room) => {
                              if (room.roomId === roomId)
                                unitName[room.roomId] = unit.unitDisplayName;
                            });
                          });

                        if (closedList[roomId] === undefined) {
                          closedList[roomId] = false;
                        }

                        return (
                          <div className={classNames(styles.parentContainer)} key={roomId}>
                            {roomId && roomDisplayName && (
                              <OrganizationHierarchy
                                key={roomId}
                                id={roomId}
                                name={roomDisplayName}
                                index={2}
                                children={
                                  sections?.length > 0 &&
                                  sections.some((section) => section.sectionId !== null)
                                }
                                closed={!closedList[roomId]}
                                trackOpenParents={trackOpenParents}
                                locationType={'Room'}
                                unitName={unitName[roomId]}
                              />
                            )}
                            {room.sections &&
                              closedList[roomId] &&
                              room.sections.map((section: Section) => {
                                const { sectionId, sectionDisplayName } = section;
                                if (closedList[sectionId] === undefined) {
                                  closedList[sectionId] = false;
                                }
                                return (
                                  <div
                                    className={classNames(styles.parentContainer)}
                                    key={sectionId}
                                  >
                                    {sectionId && (
                                      <OrganizationHierarchy
                                        key={sectionId}
                                        id={sectionId}
                                        name={sectionDisplayName}
                                        index={3}
                                        children={false}
                                        closed={!closedList[sectionId]}
                                        trackOpenParents={trackOpenParents}
                                        locationType={'Section'}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </div>
        );
      })}
      <div
        className={classNames(styles.addContainer)}
        onClick={() => {
          dispatch(
            actions.setFacilityModalOpen({
              isFacilityModalOpen: true,
            })
          );
        }}
      >
        <div className={classNames(styles.imgContainer)}>
          <img src={plusIcon} alt="add facility" className={classNames(styles.icon)} />
        </div>
        New Facility
      </div>
      <FacilityModal
        isOpen={isFacilityModalOpen}
        onClose={() => {
          dispatch(
            actions.setFacilityModalOpen({
              isFacilityModalOpen: false,
            })
          );
        }}
        mode={{ type: 'create' }}
      />
    </div>
  );
}
