import React from 'react';

import Fab from '../../Fab/Fab';
import { mobxInjectSelect } from '../../../../common/utils';
import { ReactComponent as AddMembersIcon } from '../../../../common/images/add-members.svg';

function AddMemberButton({ openModal }) {
  function addMemberModal() {
    openModal('addMemberToVideoCall');
  }

  return (
    <Fab
      dataQaId={'add-members-button'}
      onClick={addMemberModal}
      placement="bottom"
      title={'Invite Members'}
      backgroundColorOff="rgba(0, 0, 0, 0.7)"
      hoverColor="rgba(0, 0, 0, 0.5)"
      marginButton={'0 5px'}
    >
      <AddMembersIcon aria-hidden />
    </Fab>
  );
}

class AddMemberButtonContainer extends React.Component {
  render() {
    return <AddMemberButton {...this.props} />;
  }
}

export default mobxInjectSelect({
  modalStore: ['openModal'],
})(AddMemberButtonContainer);
