import React from 'react';
import { useSelector } from 'react-redux';
import { Tag } from '../../../../types';
import { ReactComponent as DropIcon } from '../../../images/pa-dropdown.svg';
import BEM from '../../../bem';
import { CollaborationCategories } from '../../../../models/enums';
import { selectColors } from '../../../../redux-stores/Collaboration/tags';
import TagItem from './TagItem';
const { NOTTAGGED } = CollaborationCategories;

const classes = BEM.with('CollaborationTags');

type TagListItemProps = {
  checkedTagsById?: { [id: string]: Tag };
  colorHex?: string;
  dropdown?: boolean;
  handleCheckTag?: (tagId: string, tag: Tag, isChecked: boolean) => void;
  handleSelect?: () => void;
  handleSelectTag?: (tag: Tag) => void;
  hasCheck?: boolean;
  hideCount?: boolean;
  isChecked?: boolean;
  isDropdownOpen?: boolean;
  name?: string;
  onClick?: (tag: Tag | undefined) => void;
  entityCount?: number;
  selected?: boolean;
  tag?: Tag;
  id?: string;
};

function TagListItem({
  checkedTagsById = {},
  colorHex = undefined,
  dropdown = false,
  handleCheckTag = () => {},
  handleSelect = () => {},
  handleSelectTag = () => {},
  hasCheck = false,
  hideCount = false,
  isChecked = false,
  isDropdownOpen = true,
  name = '',
  onClick = () => {},
  entityCount,
  selected = false,
  tag = undefined,
  id = '',
}: TagListItemProps) {
  const colors = useSelector(selectColors);

  let tagColorHex;
  if (!colorHex && tag) {
    const tagColor = colors.find((c) => c.colorId === tag.colorId);
    tagColorHex = tagColor?.colorValue?.replace('0x', '#');
  }

  let rightSideFragment;
  if (dropdown) {
    rightSideFragment = <DropIcon className={classes('chevron', { isOpen: isDropdownOpen })} />;
  } else if (!hideCount) {
    rightSideFragment = (
      <div className={classes('tag-item-count')} data-test-id={`tag item count ${name || id}`}>
        ({entityCount})
      </div>
    );
  }
  return (
    <div
      className={classes('item', { itemSelected: selected || isChecked, dropdown })}
      onClick={() => {
        onClick(tag);
      }}
    >
      <div className={classes('left-side-container')}>
        {hasCheck && (
          <div
            className={classes('check-container')}
            data-test-id={`tag item check ${id}`}
            onClick={() => handleCheckTag(tag?.id || id, tag || checkedTagsById[id], isChecked)}
          >
            <div className={classes('check-container-circle')}>
              <input type="checkbox" checked={!selected && isChecked} readOnly />
            </div>
          </div>
        )}
      </div>
      <div
        className={classes('tag-list-item-container')}
        data-test-id={`select tag ${name || id}`}
        onClick={() => {
          if (tag && !tag.id.includes(NOTTAGGED)) {
            handleSelectTag(tag);
          } else {
            handleSelect();
          }
        }}
      >
        {dropdown ? (
          <div className={classes('accordion-row')}>{name}</div>
        ) : (
          <TagItem
            colorHex={colorHex || tagColorHex}
            customStyles={'tag-list-item'}
            name={name}
            useType="action"
          />
        )}
        {rightSideFragment}
      </div>
    </div>
  );
}

export default TagListItem;
