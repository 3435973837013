import React, { useState } from 'react';

import { OrganizationSettings } from '../../../types';
import { ViewContainer } from '../../../shared-components/View/ViewContainer/ViewContainer';
import { Dropdown } from '../../../shared-components/Dropdown/Dropdown';
import { CtaButton } from '../../../shared-components/CtaButton/CtaButton';
import { SettingContainer } from '../../../shared-components/SettingContainer/SettingContainer';

import { TabProps } from './';
import { actions, thunk, useAppDispatch, useAppSelector } from 'redux-stores';

const { updateSettings } = actions;

type OtpSettings<
  T = Required<
    Pick<
      OrganizationSettings,
      | 'otpAutoUnlockHours'
      | 'otpAutoUnlockMinutes'
      | 'otpMaxLoginAttempts'
      | 'otpMaxPasscodeResends'
    >
  >
> = {
  [K in keyof T]: string;
};

export const OTP = ({ onClose }: TabProps) => {
  const dispatch = useAppDispatch();

  const {
    otpAutoUnlockHours = 0,
    otpAutoUnlockMinutes = 15,
    otpMaxLoginAttempts = 5,
    otpMaxPasscodeResends = 3,
  } = useAppSelector((state) => state.orgSettings.settings);

  const [otpSettings, setOtpSettings] = useState<OtpSettings>({
    otpAutoUnlockHours: `${otpAutoUnlockHours}`,
    otpAutoUnlockMinutes: `${otpAutoUnlockMinutes}`,
    otpMaxLoginAttempts: `${otpMaxLoginAttempts}`,
    otpMaxPasscodeResends: `${otpMaxPasscodeResends}`,
  });

  const saveSettings = async () => {
    dispatch(
      updateSettings({
        otpAutoUnlockHours: parseInt(otpSettings.otpAutoUnlockHours, 10),
        otpAutoUnlockMinutes: parseInt(otpSettings.otpAutoUnlockMinutes, 10),
        otpMaxLoginAttempts: parseInt(otpSettings.otpMaxLoginAttempts, 10),
        otpMaxPasscodeResends: parseInt(otpSettings.otpMaxPasscodeResends, 10),
      })
    );
    await thunk.saveSettings({ dispatch });
  };

  return (
    <ViewContainer header={'OTP Login Settings'} onClose={onClose}>
      <div>
        <div>
          <SettingContainer
            label={'Allowed login attempts'}
            subLabel={
              'Number of invalid attempts that users can try logging in before locking the account.'
            }
            dataTestId={'allowedLoginAttemptsDropdown'}
            settingControl={
              <Dropdown
                options={[
                  { label: '1', value: '1', dataTestId: 'allowedLoginAttempts-1' },
                  { label: '2', value: '2', dataTestId: 'allowedLoginAttempts-2' },
                  { label: '3', value: '3', dataTestId: 'allowedLoginAttempts-3' },
                  { label: '4', value: '4', dataTestId: 'allowedLoginAttempts-4' },
                  { label: '5', value: '5', dataTestId: 'allowedLoginAttempts-5' },
                ]}
                defaultValue={otpMaxLoginAttempts}
                onChange={(o) => setOtpSettings({ ...otpSettings, otpMaxLoginAttempts: o })}
              />
            }
          />
          <SettingContainer
            label={'Send passcode limit'}
            subLabel={'Number of times the users can generate the passcode for logging in.'}
            dataTestId={'sendPasscodeLimitDropdown'}
            settingControl={
              <Dropdown
                options={[
                  { label: '1', value: '1', dataTestId: 'sendPasscodeLimit-1' },
                  { label: '2', value: '2', dataTestId: 'sendPasscodeLimit-2' },
                  { label: '3', value: '3', dataTestId: 'sendPasscodeLimit-3' },
                  { label: '4', value: '4', dataTestId: 'sendPasscodeLimit-4' },
                  { label: '5', value: '5', dataTestId: 'sendPasscodeLimit-5' },
                ]}
                defaultValue={otpMaxPasscodeResends}
                onChange={(o) => setOtpSettings({ ...otpSettings, otpMaxPasscodeResends: o })}
              />
            }
          />
          <SettingContainer
            label={'Time to auto unlock'}
            subLabel={'Time after which the system will auto unlock locked users.'}
            settingControl={
              <div>
                <Dropdown
                  options={[
                    { label: '0 Hours', value: '0', dataTestId: 'timeToUnlockHours-0' },
                    { label: '1 Hour', value: '1', dataTestId: 'timeToUnlockHours-1' },
                    { label: '2 Hours', value: '2', dataTestId: 'timeToUnlockHours-2' },
                    { label: '3 Hours', value: '3', dataTestId: 'timeToUnlockHours-3' },
                    { label: '4 Hours', value: '4', dataTestId: 'timeToUnlockHours-4' },
                    { label: '5 Hours', value: '5', dataTestId: 'timeToUnlockHours-5' },
                    { label: '6 Hours', value: '6', dataTestId: 'timeToUnlockHours-6' },
                    { label: '7 Hours', value: '7', dataTestId: 'timeToUnlockHours-7' },
                    { label: '8 Hours', value: '8', dataTestId: 'timeToUnlockHours-8' },
                    { label: '9 Hours', value: '9', dataTestId: 'timeToUnlockHours-9' },
                    { label: '10 Hours', value: '10', dataTestId: 'timeToUnlockHours-10' },
                    { label: '11 Hours', value: '11', dataTestId: 'timeToUnlockHours-11' },
                    { label: '12 Hours', value: '12', dataTestId: 'timeToUnlockHours-12' },
                    { label: '13 Hours', value: '13', dataTestId: 'timeToUnlockHours-13' },
                    { label: '14 Hours', value: '14', dataTestId: 'timeToUnlockHours-14' },
                    { label: '15 Hours', value: '15', dataTestId: 'timeToUnlockHours-15' },
                    { label: '16 Hours', value: '16', dataTestId: 'timeToUnlockHours-16' },
                    { label: '17 Hours', value: '17', dataTestId: 'timeToUnlockHours-17' },
                    { label: '18 Hours', value: '18', dataTestId: 'timeToUnlockHours-18' },
                    { label: '19 Hours', value: '19', dataTestId: 'timeToUnlockHours-19' },
                    { label: '20 Hours', value: '20', dataTestId: 'timeToUnlockHours-20' },
                    { label: '21 Hours', value: '21', dataTestId: 'timeToUnlockHours-21' },
                    { label: '22 Hours', value: '22', dataTestId: 'timeToUnlockHours-22' },
                    { label: '23 Hours', value: '23', dataTestId: 'timeToUnlockHours-23' },
                    { label: '24 Hours', value: '24', dataTestId: 'timeToUnlockHours-24' },
                  ]}
                  defaultValue={otpAutoUnlockHours}
                  dataTestId={'timeToUnlockHoursDropdown'}
                  onChange={(o) => setOtpSettings({ ...otpSettings, otpAutoUnlockHours: o })}
                />
                <Dropdown
                  options={[
                    { label: '0 Minutes', value: '0', dataTestId: 'timeToUnlockMinutes-0' },
                    {
                      label: '15 Minutes',
                      value: '15',
                      dataTestId: 'timeToUnlockMinutes-15',
                    },
                    {
                      label: '30 Minutes',
                      value: '30',
                      dataTestId: 'timeToUnlockMinutes-30',
                    },
                    {
                      label: '45 Minutes',
                      value: '45',
                      dataTestId: 'timeToUnlockMinutes-45',
                    },
                  ]}
                  defaultValue={otpAutoUnlockMinutes}
                  dataTestId={'timeToUnlockMinutesDropdown'}
                  onChange={(o) => setOtpSettings({ ...otpSettings, otpAutoUnlockMinutes: o })}
                />
              </div>
            }
          />
        </div>
        <div>
          <CtaButton label={'Save Settings'} primary size={'small'} onClick={saveSettings} />
        </div>
      </div>
    </ViewContainer>
  );
};
