import React from 'react';
import { observer } from 'mobx-react';
import BEM from '../../bem';
import { ReactComponent as TagIconWithCirclSvg } from '../../images/tag-icon-with-circle.svg';

const classes = BEM.with('TagItem');

const TagItem = ({ name, color }) => {
  return (
    <div className={classes()}>
      <TagIconWithCirclSvg className={classes('image')} fill={color} />
      {name}
    </div>
  );
};

export default observer(TagItem);
