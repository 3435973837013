import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import BEM from '../../../bem';
import propTypes from '../../../propTypes';

const classes = BEM.with('MessageContent');

class MessageForwardedBodyHeader extends Component {
  static propTypes = {
    message: propTypes.message.isRequired,
  };

  render() {
    const { message } = this.props;
    const { isForwardedFromPatientNetwork, isOutgoing, senderRole } = message;

    return (
      <div
        className={classNames(
          classes('forwarded-from'),
          classes({ isForwardedFromPatientNetwork })
        )}
      >
        {isOutgoing && !senderRole ? 'You Forwarded:' : 'Forwarded:'}
      </div>
    );
  }
}

export default observer(MessageForwardedBodyHeader);
