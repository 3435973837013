import KeywordMessageBubble from './KeywordMessageBubble';
import BEM from 'common/bem';
import {
  KeywordSearchSecondLevelResult,
  KeywordSearchSecondLevelResultValue,
} from 'js-sdk/src/apis/SearchAPI';
const classes = BEM.with('KeywordSearchSideBar');

type KeywordSearchResultsDetailsProps = {
  results: KeywordSearchSecondLevelResult[];
  currentUserId: string;
  myRoleIds: string[];
};

const KeywordSearchResultsDetails = ({
  results,
  currentUserId,
  myRoleIds,
}: KeywordSearchResultsDetailsProps) => {
  const renderMessageResults = () => {
    return (
      <div className={classes('message-results')}>
        {results.map((result: KeywordSearchSecondLevelResult) => {
          if (result?.values) {
            return result.values.map((value: KeywordSearchSecondLevelResultValue) => {
              return (
                <KeywordMessageBubble
                  key={value.entity.message_id}
                  messageId={value.entity.message_id}
                  currentUserId={currentUserId}
                  senderToken={value.entity.sender_token}
                  myRoleIds={myRoleIds}
                  body={value.entity.body}
                  senderDisplayName={value.entity.sender_display_name}
                  createdTime={value.entity.created_time_server}
                  isGroup={value.entity.is_group}
                  highlight={value.entity.highlight}
                  isSecondLevel
                />
              );
            });
          }
          return null;
        })}
      </div>
    );
  };

  return <div className={classes('conversation-result-seeAll')}>{renderMessageResults()}</div>;
};

export default KeywordSearchResultsDetails;
