import { sdkApi } from '../api';
import { ReduxState } from 'redux-stores';
import TCClient from 'client';

const profileApi = sdkApi
  .injectEndpoints({
    endpoints: (build) => ({
      getPreferences: build.query<
        { dnd_text_templates: string[]; quick_replies: string[] },
        string
      >({
        queryFn: async (userId) => {
          try {
            const res = await TCClient.users.getPreferences(userId || undefined);
            return { data: res };
          } catch (error) {
            return { error };
          }
        },
      }),
      updatePreferences: build.mutation<
        object,
        { userId?: string; orgId?: string; dndTextTemplates?: string[] }
      >({
        queryFn: async ({ userId, orgId, dndTextTemplates }, api) => {
          const {
            session: { currentOrganizationId },
          } = api.getState() as ReduxState;
          try {
            const res = await TCClient.users.editPreferences({
              userId,
              dndTextTemplates,
              organizationId: orgId || currentOrganizationId,
            });

            return { data: res };
          } catch (error) {
            return { error };
          }
        },
      }),
    }),
    overrideExisting: true,
  })
  .enhanceEndpoints({
    addTagTypes: ['Preferences'],
    endpoints: {
      getPreferences: { providesTags: ['Preferences'] },
      updatePreferences: { invalidatesTags: ['Preferences'] },
    },
  });

export const {
  useGetPreferencesQuery,
  useUpdatePreferencesMutation,
  util: { updateQueryData: updateProfileQueryData },
} = profileApi;
