import React, { useEffect, useRef } from 'react';
import { Scrollbars } from 'tt-react-custom-scrollbars';

import { mobxInjectSelect } from '../../utils';
import BEM from '../../bem';
import { Message } from '../../../types';
import { AlertCard } from '../../../widgets/messenger/components/AlertCard/';
import { DotsIndicator } from '../../components';
import { YoureAllCaughtUp } from './index';

type AlertCardsViewProps = {};

const classes = BEM.with('AlertCardsView');

type MobxProps = {
  alertMessages: Message[];
  isFetchingAlertMessages: boolean;
  isGroupAlertsAllowed: boolean;
  selectedAlertsFilter: string;
  setAlertElement: (messageId: string, element: HTMLDivElement | null) => void;
  setAlertsFilter: (alertFilter: 'Alerts' | 'History') => void;
};

function AlertCardsView({
  alertMessages,
  isFetchingAlertMessages,
  isGroupAlertsAllowed,
  selectedAlertsFilter,
  setAlertElement,
  setAlertsFilter,
}: AlertCardsViewProps & MobxProps) {
  const scrollbarsRef = useRef<Scrollbars>(null);

  useEffect(() => {
    scrollbarsRef?.current?.scrollTop(0);
  }, [selectedAlertsFilter]);

  if (isFetchingAlertMessages) {
    return (
      <div className={classes()}>
        <div className={classes('loading-overlay')}>
          <div className={classes('dots-container')}>
            <DotsIndicator color={'#969696'} size={12} />
            <div className={classes('dots-text')}>Loading Alerts</div>
          </div>
        </div>
      </div>
    );
  }

  if (isGroupAlertsAllowed && selectedAlertsFilter === 'History' && alertMessages.length === 0) {
    return (
      <div className={classes()}>
        <span className={classes('empty-history-text')}>No alert history available</span>
      </div>
    );
  }

  if (selectedAlertsFilter === 'Alerts' && alertMessages.length === 0) {
    return <YoureAllCaughtUp />;
  }

  return (
    <div className={classes({ isGroupAlertsAllowed })}>
      <Scrollbars autoHide autoHideTimeout={750} ref={scrollbarsRef}>
        <div className={classes('content-container', { isGroupAlertsAllowed })}>
          {!isGroupAlertsAllowed && (
            <div className={classes('content-title')}>
              {selectedAlertsFilter} ({alertMessages.length})
            </div>
          )}
          {alertMessages.map((message) => (
            <div
              key={message.id}
              className={classes('card-container', { isGroupAlertsAllowed })}
              ref={(ref) => setAlertElement(message.id, ref)}
            >
              <AlertCard numLinesToClipContentAt={2} message={message} showConversationLink />
            </div>
          ))}
          {isGroupAlertsAllowed && selectedAlertsFilter === 'Alerts' && (
            <button
              className={classes('view-history-btn')}
              onClick={() => setAlertsFilter('History')}
            >
              View History
            </button>
          )}
        </div>
      </Scrollbars>
    </div>
  );
}

export default mobxInjectSelect<AlertCardsViewProps, MobxProps>({
  alertStore: ['alertMessages', 'isFetchingAlertMessages', 'setAlertElement'],
  rosterStore: ['selectedAlertsFilter', 'setAlertsFilter'],
  messengerStore: ['isGroupAlertsAllowed'],
})(AlertCardsView);
