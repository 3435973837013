import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { AppTypes } from '../../models/enums';
import Modal from './Modal';

const classes = BEM.with('DeleteBroadcastListModal');

const DeleteBroadcastListModal = ({
  closeModal,
  currentAppSelected,
  deleteBroadcastList,
  isOpen,
  openModal,
  options,
}) => {
  const [isInProgress, setIsInProgress] = useState(false);

  const deleteSelected = async () => {
    setIsInProgress(true);
    const { id, searchBroadcastLists } = options;

    try {
      await deleteBroadcastList(id);

      searchBroadcastLists();
      setIsInProgress(false);
      closeModal();
    } catch (err) {
      console.error(err);
      setIsInProgress(false);
      openModal('patientAdminFailure', {
        reopenModal: 'deleteBroadcastList',
        optionsReopenModal: { id },
        body: 'Unable to delete broadcast list. Please try again.',
      });
    }
  };

  return (
    <Modal
      bodyClass={classes('body')}
      closeClass={classes('close-button')}
      footerClass={classes('footer')}
      header={'Confirm Delete'}
      headerClass={classes('header')}
      isOpen={isOpen}
      size={'medium'}
      footerPrimaryActions={
        <button
          className={classes('delete-btn')}
          disabled={isInProgress}
          onClick={deleteSelected}
          type="button"
        >
          {isInProgress ? 'DELETING' : 'DELETE'}
        </button>
      }
      footerSecondaryActions={
        <button type="button" onClick={closeModal} className={classes('cancel-btn')}>
          CANCEL
        </button>
      }
      className={classes()}
      onRequestClose={closeModal}
    >
      <div className={classes('info')}>
        Removing broadcast list(s) will also delete all related{' '}
        {currentAppSelected === AppTypes.PATIENT_SETTINGS && 'scheduled messages and '}
        conversations. Are you sure you want to delete the broadcast list(s)?
      </div>
    </Modal>
  );
};

DeleteBroadcastListModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentAppSelected: PropTypes.string.isRequired,
  deleteBroadcastList: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  options: PropTypes.shape({
    id: PropTypes.string,
    searchBroadcastLists: PropTypes.func,
  }),
};

export default mobxInjectSelect({
  messengerStore: ['currentAppSelected'],
  modalStore: ['openModal'],
  patientAdminStore: ['deleteBroadcastList'],
})(DeleteBroadcastListModal);
