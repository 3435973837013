import React from 'react';

import styles from './ColorInput.module.css';

export interface ColorInputProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  dataTestId?: string;
}

export const ColorInput = ({ dataTestId, value, onChange }: ColorInputProps) => {
  return (
    <input
      type="color"
      value={value}
      data-test-id={dataTestId}
      className={styles.root}
      onChange={onChange}
    />
  );
};
