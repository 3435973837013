const MAX_AVATAR_FILE_SIZE_MB = 2;
const MAX_DISPLAY_FILE_SIZE_MB = 5;
const MAX_PREVIEW_SIZE_MB = 15;

const Images = {
  MAX_AVATAR_FILE_SIZE_BYTES: 1024 * 1024 * MAX_AVATAR_FILE_SIZE_MB,
  MAX_AVATAR_FILE_SIZE_MB,
  MAX_DISPLAY_SIZE_BYTES: 1024 * 1024 * MAX_DISPLAY_FILE_SIZE_MB,
  MAX_PREVIEW_SIZE_BYTES: 1024 * 1024 * MAX_PREVIEW_SIZE_MB,
};

export default Images;
