import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ModalPayload, sessionSlice } from 'redux-stores/session';

export type UISliceState = {
  accessibilityMode: boolean;
  messageBodyInputFocus: boolean;
  isMentionsModalOpen: boolean;
};

const { setModal } = sessionSlice.actions;

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    messageBodyInputFocus: false,
    accessibilityMode: false,
    isMentionsModalOpen: false,
  } as UISliceState,
  reducers: {
    setAccessibilityMode: (state, action: PayloadAction<boolean>) => {
      state.accessibilityMode = action.payload;
    },
    setMessageBodyInputFocus: (state, action: PayloadAction<boolean>) => {
      state.messageBodyInputFocus = action.payload;
    },
    setIsMentionsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isMentionsModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setModal, (state, action: PayloadAction<ModalPayload | undefined>) => {
      if (action.payload === undefined) {
        state.messageBodyInputFocus = true;
      }
    });
  },
});

export { uiSlice };
