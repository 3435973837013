import React from 'react';
import classNames from 'classnames';
import { mobxInjectSelect } from '../../common/utils';
import BEM from '../bem';
import { escalationStatusToReadableLabel } from '../utils/readableLabels';

const classes = BEM.with('Banner');

const Banner = ({ type, isGroupAlertsVCAllowed, dataTestId }) => {
  if (isGroupAlertsVCAllowed && type?.text) {
    return (
      <div className={classes()}>
        <span
          data-test-id={dataTestId}
          className={classNames(classes('banner'))}
          style={{ backgroundColor: `#${type.color.split('0x')[1]}` }}
        >
          {type.text}
        </span>
      </div>
    );
  }

  const statusText = escalationStatusToReadableLabel(type)
    ? escalationStatusToReadableLabel(type)
    : type;

  return (
    <div className={classes()}>
      <span data-test-id={dataTestId} className={classNames(classes('banner'), classes({ type }))}>
        {statusText}
      </span>
    </div>
  );
};

export default mobxInjectSelect({
  messengerStore: ['isGroupAlertsVCAllowed'],
})(Banner);
