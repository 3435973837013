import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../common/bem';
import mobxInjectSelect from '../../../common/utils/mobxInjectSelect';
import { ReactComponent as ShieldIcon } from '../../../common/images/shield-tc.svg';
import Favicon from './Favicon';

const classes = BEM.with('SessionExpired');

const CONNECTION_UNAVAILABLE = 'connection-unavailable';

class SessionExpired extends Component {
  static propTypes = {
    isIdpSessionExpired: PropTypes.bool.isRequired,
    // For some reason, eslint isn't detecting that the following props are being used:
    // eslint-disable-next-line
    setCurrentUserId: PropTypes.func.isRequired,
    // eslint-disable-next-line
    setSessionExpired: PropTypes.func.isRequired,
    setIdpSessionExpired: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
  };

  _isSignedOut = false;

  state = {
    signOutError: null,
  };

  componentDidMount() {
    this._signOut();
  }

  render() {
    const { signOutError } = this.state;
    let errorFragment, subtextFragment;

    if (signOutError === CONNECTION_UNAVAILABLE) {
      subtextFragment = "Please hit 'Refresh' below to log in.";
      errorFragment = (
        <div className={classes('offline-error')}>
          Please make sure you are connected to the network and try again.
        </div>
      );
    } else {
      subtextFragment = "Please hit 'Refresh' below to log in again.";
    }

    return (
      <div className={classes()}>
        <Favicon faviconSelector={this._faviconSelector} />
        <div className={classes('container')}>
          <div className={classes('content')}>
            <ShieldIcon />
            <div className={classes('title-text')}>Your session has expired.</div>
            <div className={classes('title-subtext')}>{subtextFragment}</div>
            <div className={classes('error-container')}>{errorFragment}</div>
          </div>
          <div className={classes('refresh-btn')} onClick={this._refresh}>
            Refresh
          </div>
        </div>
      </div>
    );
  }

  _faviconSelector = () => {
    return 'favicons/favicon.png';
  };

  _refresh = async () => {
    const { isIdpSessionExpired, setCurrentUserId, setIdpSessionExpired, setSessionExpired } =
      this.props;

    const signOutError = await this._signOut();

    if (signOutError && signOutError === CONNECTION_UNAVAILABLE) {
      this.setState({ signOutError });
    } else {
      setCurrentUserId(null);
      setSessionExpired(false);
    }

    if (isIdpSessionExpired) {
      setIdpSessionExpired(false);
    }
  };

  _signOut = async () => {
    const { signOut } = this.props;
    if (this._isSignedOut) return null;

    const signOutError = await signOut({
      ignoreErrors: false,
      returnErrors: true,
      wipeSession: false,
      idpEndSession: false,
    });

    if (!signOutError) {
      this._isSignedOut = true;
    }

    return signOutError;
  };
}

export default mobxInjectSelect({
  sessionStore: [
    'isIdpSessionExpired',
    'setCurrentUserId',
    'setIdpSessionExpired',
    'setSessionExpired',
    'signOut',
  ],
})(SessionExpired);
