import React from 'react';
import BEM from '../../../../bem';
import { Modal } from '../../..';
import { ReactComponent as PrioritySvg } from '../../../../images/priority-icon.svg';

const classes = BEM.with('PatientCsvFailModal');

export default function NewPatientCsvFailModal({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) {
  const imgHeader = {
    image: PrioritySvg,
    fill: '#db524b',
    imgClassHeader: classes('img'),
  };

  return (
    <Modal
      bodyClass={classes('body')}
      className={classes()}
      closeClass={classes('close-button')}
      imgHeader={imgHeader}
      isOpen={isOpen}
      onRequestClose={closeModal}
      size={'medium'}
    >
      <div className={classes('title-text', { generalCsvUploadError: true })}>
        Oops, something went wrong. Please try again or wait for the existing upload to finish
        processing.
      </div>
    </Modal>
  );
}
