import createMobxModel from './createMobxModel';

const UserRoles = createMobxModel({
  name: 'userRoles',

  fields: [
    'isAdmin',
    'isHelpDesk',
    'isPatientAdmin',
    'isProviderBroadcastListAdmin',
    'isRoleAdmin',
    'isTeamAdmin',
    'isVirtualWaitingRoomAdmin',
    'patientContextReporting',
    'patientDataAuditAdmin',
    'isScimAdmin',
    'patientDataReportAnalyticsEnabled',
    'reportingEnabled',
    'reportingEnabledOnAllOrgs',
    'tigerTouchPlusReportingEnabled',
  ],

  relations: {
    one: {
      organization: { type: 'organization' },
    },
  },
});

export default UserRoles;
