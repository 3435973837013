import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from '@tigerconnect/web-component-library';
import BEM from '../../../bem';
import TagItem from '../Tags/TagItem';
import { ReduxState } from '../../../../redux-stores';
import { EntityAvatar } from '../..';

const classes = BEM.with('CollaborationEntitiesItem');

type newEntity = {
  $entityType: string;
};

type RolesListItemProps = {
  entity: newEntity;
};

export default function NewEntityItem({ entity }: RolesListItemProps) {
  const selectedTag = useSelector((state: ReduxState) => state.tags.selectedTag);
  const entityType = entity?.$entityType;
  return (
    <div className={classes('')}>
      <div className={classes('role-details-container')}>
        <div className={classes('role-icon-container')}>
          {entityType === 'team' ? (
            <Avatar size="medium" type="team" />
          ) : (
            <EntityAvatar entity={entity} useWCL size="medium" />
          )}
        </div>
        <div className={classes('role-info')}>
          <div className={classes('role-name-unnamed')}>Unnamed</div>
          {selectedTag?.name && (
            <div className={classes('role-tag')} data-test-id={`selected tag ${selectedTag?.name}`}>
              <TagItem
                name={selectedTag?.name}
                colorHex={selectedTag?.color}
                dotColor
                useType="action"
              />
            </div>
          )}
        </div>
      </div>
      {entityType === 'role' && <div className={classes('no-owner')}>{'No one on duty'}</div>}
    </div>
  );
}
