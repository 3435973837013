import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import { ReactComponent as UndoSvg } from '../images/undo-icon.svg';
import { ReactComponent as CancelIconSvg } from '../images/close-icon.svg';

const classes = BEM.with('UndoAction');

class UndoAction extends Component {
  static propTypes = {
    removeButton: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    undoAction: PropTypes.func.isRequired,
  };

  render() {
    const { removeButton, text, undoAction } = this.props;
    return (
      <div className={classes()}>
        <div className={classes('button')} onClick={undoAction}>
          <UndoSvg className={classes('undo')} />
          Undo
        </div>
        <div className={classes('text')}>{text}</div>
        <div className={classes('cancel-btn')} onClick={removeButton}>
          <CancelIconSvg className={classes('cancel-icon')} />
        </div>
      </div>
    );
  }

  _undoRemoveDnd = () => {
    this.props.undoRemoveDnd();
  };

  _removeUndoButton = () => {
    this.props.setDndUndoButton(false);
  };
}

export default observer(UndoAction);
