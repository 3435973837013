import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import { mobxInjectSelect } from '../utils';
import { ReactComponent as LoginBg } from '../images/login-bg.svg';
import LockedSignIn from './SignInForm/LockedSignIn';
import { Buttons, FieldGroup, Form, SubmitButton, TextField } from './Form';
import StatusMessage from './StatusMessage';

const classes = BEM.with('SignInForm');

class SingleScreenSignInForm extends Component {
  static propTypes = {
    DevTools: PropTypes.func.isRequired,
    forgotPasswordServer: PropTypes.string,
    isSignInLoading: PropTypes.bool.isRequired,
    isStaticRender: PropTypes.bool,
    signInError: PropTypes.string,
    signInFormContainerClassName: PropTypes.string,
    usernameFormClassName: PropTypes.string,
  };

  componentDidUpdate() {
    this._focus();
  }

  render() {
    const {
      DevTools,
      isSignInLoading,
      isStaticRender,
      loginAttemptsLeft,
      signInError,
      signInFormContainerClassName,
      usernameFormClassName,
    } = this.props;
    const isWorking = isStaticRender || isSignInLoading;
    let innerFragment, errorAriaLabel, errMsg;

    if (loginAttemptsLeft === 0) {
      innerFragment = <LockedSignIn />;
    } else {
      const className = isStaticRender
        ? signInFormContainerClassName
        : classes('sign-in-container');
      const usernameTitleClassName =
        (isStaticRender && usernameFormClassName) ||
        classes('username-title', { singleScreen: true });

      if (signInError) {
        if (loginAttemptsLeft !== null && loginAttemptsLeft < 5) {
          errMsg = `Password does not match, ${loginAttemptsLeft} more attempts left`;
          errorAriaLabel = 'incorrect password';
        } else {
          errMsg =
            'Contact your helpdesk or supervisor to submit a request for access to TigerConnect.';
          errorAriaLabel = 'incorrect email';
        }
      }

      innerFragment = (
        <div id="tc-SignInForm__container" className={className}>
          <div className={classes('header')}>
            <div className={classes('bg')}>
              <LoginBg id="tc-SignInForm__loginBg" />
              <div id="tc-SignInForm__UsernameTitle" className={usernameTitleClassName}>
                <span className={classes('title-text')}>Sign in to TigerConnect</span>
              </div>
            </div>
          </div>

          <div
            id="tc-SignInForm__UsernameForm"
            className={isStaticRender ? usernameFormClassName : undefined}
          >
            <Form
              className={classes('form')}
              columns={2}
              isWorking={isWorking}
              onSubmit={this._signIn}
              theme="rows"
            >
              <div className={classes('error')}>
                {errMsg && (
                  <StatusMessage
                    ariaLabel={errorAriaLabel}
                    className={errorAriaLabel === 'incorrect email' && classes('accountError')}
                    type="error"
                  >
                    {errMsg}
                  </StatusMessage>
                )}
              </div>

              <FieldGroup className={classes('field-group')}>
                <TextField
                  ariaLabel="username"
                  className={signInError && classes('field-group-error')}
                  disabled={isStaticRender}
                  inputRef={this._setUsername}
                  name="username"
                  onFocus={this._onUsernameFocus}
                  placeholder="email, username, phone"
                />

                <TextField
                  ariaLabel="password"
                  className={signInError && classes('field-group-error')}
                  disabled={isWorking}
                  inputRef={this._setPassword}
                  name="password"
                  onFocus={this._onPasswordFocus}
                  placeholder="password"
                  type="password"
                  autocomplete="off"
                />
              </FieldGroup>

              <Buttons>
                <SubmitButton
                  ariaLabel="submit username"
                  className={classes('submit')}
                  disabled={isWorking}
                  isWorking={isWorking}
                >
                  {isWorking ? 'LOADING' : 'LOGIN'}
                </SubmitButton>
              </Buttons>

              <button
                aria-label="forgot password"
                className={classes('forgot-password')}
                onClick={this._onForgotPassword}
              >
                Forgot your password?
              </button>
            </Form>
          </div>
        </div>
      );
    }

    return (
      <div className={classes()}>
        {innerFragment}
        {!isStaticRender && <DevTools />}
      </div>
    );
  }

  _focus = () => {
    if (this.currInput) {
      this.currInput.focus();
    }
  };

  _onUsernameFocus = () => {
    this.currInput = this.username;
  };

  _onPasswordFocus = () => {
    this.currInput = this.password;
  };

  _onForgotPassword = () => {
    window.open(this.props.forgotPasswordServer, '_self');
  };

  _setPassword = (ref) => {
    this.password = ref;
  };

  _setUsername = (ref) => {
    this.username = ref;

    if (!this.currInput) {
      this.currInput = ref;
    }
  };

  _signIn = ({ username, password }) => {
    const { signIn } = this.props;
    signIn(username, password);
  };
}

export default mobxInjectSelect({
  messengerStore: ['isStaticRender'],
  sessionStore: [
    'forgotPasswordServer',
    'isSignInLoading',
    'loginAttemptsLeft',
    'signIn',
    'signInError',
  ],
  staticStore: ['DevTools', 'signInFormContainerClassName', 'usernameFormClassName'],
})(SingleScreenSignInForm);
