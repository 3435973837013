import { ServerValueEnum } from '../../utils/enum';

const EscalationChangeAction = new ServerValueEnum({
  ACKNOWLEDGED: { serverValue: 'escalation_acknowledged' },
  CANCELLED: { serverValue: 'escalation_cancelled' },
  INITIATED: { serverValue: 'escalation_initiated' },
  MEMBER_ADDED: { serverValue: 'escalation_member_added' },
  NO_RESPONSE: { serverValue: 'escalation_no_response' },
} as const);

export default EscalationChangeAction;
