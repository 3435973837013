import React from 'react';

import { CtaButton } from '../../../shared-components/CtaButton/CtaButton';
import { StandardOrgSettingInput } from '../../../shared-components/StandardSettingInput';
import { ViewContainer } from '../../../shared-components/View/ViewContainer/ViewContainer';

import { TabProps } from './';

export default function Billing({ onClose, onSave }: TabProps) {
  return (
    <ViewContainer
      {...{
        header: 'Billing',
        subHeader: 'Convert free organizations to paid organizations.',
        onClose,
      }}
    >
      <div>
        <div>
          <StandardOrgSettingInput
            options={[
              { label: 'Free', value: 'free' },
              { label: 'Paid', value: 'paid' },
            ]}
            settingName="paidStatus"
            type="dropdown"
          />
        </div>
        <div>
          <CtaButton primary label="Save" onClick={onSave} dataTestId={'saveBilling'} />
        </div>
      </div>
    </ViewContainer>
  );
}
