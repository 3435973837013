import { Organization, Entity } from '../../types';

export const groupByEntity = (
  currentOrganization: Organization,
  sortMap: Map<string, number>,
  results: Entity[]
) => {
  const grouped = results.reduce((all, current) => {
    const types = all.map((v) => v.entity_type);
    const entityType = current?.groupType === 'FORUM' ? 'forum' : current.$entityType;
    const index = types.indexOf(entityType);
    if (index !== -1) {
      all[index].values.push(current);
    } else {
      all.push({ entity_type: entityType, values: [current] });
    }
    return all;
  }, [] as { entity_type: Entity['$entityType']; values: Entity[] }[]);

  if (currentOrganization.isContacts && grouped.length > 1 && grouped[0].entity_type !== 'user') {
    const tmp = grouped[0];
    grouped[0] = grouped[1];
    grouped[1] = tmp;
  } else {
    grouped.sort((x, y) => {
      const xValue = sortMap.get(x.entity_type) || 0;
      const yValue = sortMap.get(y.entity_type) || 0;
      if (xValue < yValue) return -1;
      if (xValue > yValue) return 1;
      return 0;
    });
  }
  return grouped;
};

export const validateEntityOrder = (items: string[]) => {
  const expected = ['team', 'role', 'user', 'group', 'distributionList', 'forum'];
  let index = expected.indexOf(items[0]);
  for (let i = 1; i < items.length; i += 1) {
    index = expected.indexOf(items[i], index + 1);
    if (index === -1) return false;
  }
  return true;
};

export const updateEntitySortMap = ({
  currentOrganizationId,
  entitySortOrg,
  entitySortMap,
  isNewQuery,
  metadata,
}: {
  currentOrganizationId: string;
  entitySortOrg: string;
  entitySortMap: Map<string, number>;
  isNewQuery: boolean;
  metadata: {
    entityOrder?: string[];
  };
}) => {
  let updatedEntitySortOrg = entitySortOrg;
  let updatedEntitySortMap = new Map(entitySortMap);
  const entitySortUnique = Array.from(new Set(metadata.entityOrder));

  if (entitySortOrg === currentOrganizationId && !isNewQuery) {
    const lastIndex = entitySortMap.size;
    const sortOrder = entitySortUnique
      .filter((key) => !entitySortMap.has(key))
      .map<[string, number]>((key, i) => [key, i + lastIndex]);
    const entitySortArray = Array.from(entitySortMap.entries());
    updatedEntitySortMap = new Map([...entitySortArray, ...sortOrder]);
  } else {
    updatedEntitySortOrg = currentOrganizationId;
    const sortOrder = entitySortUnique.map<[string, number]>((key, i) => [key, i]);
    updatedEntitySortMap = new Map(sortOrder);
  }

  return { entitySortOrg: updatedEntitySortOrg, entitySortMap: updatedEntitySortMap };
};
