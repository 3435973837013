import React from 'react';
import classNames from 'classnames';
import styles from './Number.module.css';

export interface InputProps {
  /**
   * Optional callback for when user types into text field
   */
  onInputChange?: (e: string) => void;

  /**
   * Optional placeholder text
   */
  placeholder?: string;
  // value of the input
  value?: string;
  dataTestId?: string;
  disabled?: boolean;
}

export function Number({
  dataTestId,
  onInputChange,
  placeholder = '',
  value = '',
  disabled = false,
}: InputProps) {
  return (
    <div className={classNames(styles.numberRoot)}>
      <input
        className={styles.input}
        placeholder={placeholder}
        type={'number'}
        onChange={(e) => {
          onInputChange?.(e.target.value);
        }}
        value={value}
        min="-1"
        max="99"
        data-test-id={dataTestId}
        disabled={disabled}
      />
    </div>
  );
}
