import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import DotsIndicator from './DotsIndicator';
import { Modal } from './';

const classes = BEM.with('PatientDeleteModal');

class PatientDeleteModal extends Component {
  static propTypes = {
    actionInProgress: PropTypes.bool.isRequired,
    deletePatient: PropTypes.func.isRequired,
    hidePatientDeleteModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
  };

  render() {
    const { actionInProgress, isOpen, options } = this.props;
    const { entity } = options;
    const { displayName } = entity;
    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('closeButton')}
        headerClass={classes('deleteHeader')}
        header="Are you sure you want to delete this patient?"
        isOpen={isOpen}
        onRequestClose={this._onRequestClose}
        size={'variable'}
        footerClass={classes('forwardFooter')}
        footerPrimaryActions={
          <button
            className={classes('ok-btn', { progress: actionInProgress })}
            onClick={this._onDeletePatient}
            disabled={actionInProgress}
            type="button"
          >
            {actionInProgress ? (
              <div className={classes('dots-container')}>
                <DotsIndicator
                  className={classes('dots')}
                  size={10}
                  speed={0.5}
                  color={'#db524b'}
                />
              </div>
            ) : (
              'OK'
            )}
          </button>
        }
        footerSecondaryActions={
          <button
            className={classes('cancel-btn', { progress: actionInProgress })}
            disabled={actionInProgress}
            type="button"
            onClick={this._onRequestClose}
          >
            CANCEL
          </button>
        }
      >
        <div className={classes('message-container')}>
          <div className={classes('message')}>
            Deleting an active patient like
            <span className={classes('patient-name')}> {`${displayName}`} </span>
            would erase any conversations with this patient
          </div>
        </div>
      </Modal>
    );
  }

  _onRequestClose = () => {
    const { hidePatientDeleteModal } = this.props;

    hidePatientDeleteModal();
  };

  _onDeletePatient = () => {
    const { deletePatient, options } = this.props;
    const { entity, index } = options;

    deletePatient(entity.id, index);
  };
}

export default mobxInjectSelect({
  patientStore: ['actionInProgress', 'deletePatient', 'hidePatientDeleteModal'],
})(PatientDeleteModal);
