import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import BEM from 'common/bem';

const classes = BEM.with('ToolTip');

class ToolTip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };

    this.tipTimeout = undefined;
  }

  componentWillUnmount() {
    clearTimeout(this.tipTimeout);
  }

  openTip = () => {
    const { getManualOffset, waitTime } = this.props;

    let offset;
    if (getManualOffset) {
      offset = getManualOffset();
    }

    const time = waitTime !== null ? waitTime : 600;
    this.tipTimeout = setTimeout(() => {
      this.setState({ hover: true, offset });
    }, time);
  };

  closeTip = () => {
    clearTimeout(this.tipTimeout);
    this.setState({ hover: false });
  };

  render() {
    const {
      allowMultiline,
      children,
      disable,
      getManualOffset,
      hideArrow,
      isBroadcastList,
      isPatientFacing,
      location,
      text,
      textAlign,
    } = this.props;
    const { hover, offset } = this.state;
    return (
      <div
        className={classes()}
        onMouseEnter={this.openTip}
        onMouseLeave={this.closeTip}
        style={{ position: getManualOffset ? 'static' : 'relative' }}
      >
        {children}
        {hover && !disable && (
          <div
            className={classes('toolTipContainer', {
              bottom: location === 'bottom',
              left: location === 'left',
              right: location === 'right',
              top: location === 'top',
            })}
            style={offset}
          >
            <div
              className={classes('toolTip', {
                allowMultiline,
                patient: isPatientFacing,
                broadcastList: isBroadcastList,
              })}
            >
              {!hideArrow && (
                <div
                  className={classes('arrow', {
                    [`arrow-${location}`]: !!location,
                    patient: isPatientFacing,
                    broadcastList: isBroadcastList,
                  })}
                />
              )}
              <div className={classes('text', { allowMultiline })} style={{ textAlign }}>
                {text}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  disable: PropTypes.bool,
  getManualOffset: PropTypes.func,
  hideArrow: PropTypes.bool,
  isPatientFacing: PropTypes.bool,
  location: PropTypes.string,
  text: PropTypes.string.isRequired,
  waitTime: PropTypes.number,
};

ToolTip.defaultProps = {
  disable: false,
  hideArrow: false,
  location: 'bottom',
  waitTime: null,
};

export default observer(ToolTip);
