import React, { useState } from 'react';
import classNames from 'classnames';

import searchLoader from '../../images/loader_search.gif';
import styles from './CtaButton.module.css';

export interface ButtonProps {
  customStyles?: Record<string, string>;
  dataTestId?: string;
  /**
   * Optional disable
   */
  disabled?: boolean;
  /**
   * Optional button style
   */
  isInsertButton?: boolean;
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => Promise<void> | void;
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'large';
}

export const CtaButton: React.FC<ButtonProps> = ({
  customStyles = {},
  dataTestId,
  disabled = false,
  isInsertButton = false,
  label,
  onClick,
  primary = false,
  size = 'small',
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const shouldDisplayLoading = [
    'Save Settings',
    'Save User Permissions',
    'Delete Permission Group',
  ];
  const shouldDisplayNothing = ['Save Product Roles'];
  const currentStyle = { ...styles, ...customStyles };

  return (
    <div className={currentStyle.ctaButton}>
      {isLoading && shouldDisplayLoading.includes(label) ? (
        <div
          className={currentStyle.loadingContainer}
          style={(label === 'Save User Permissions' && { paddingLeft: '60px' }) || {}}
        >
          <img src={searchLoader} className={currentStyle.loadingImage} alt="" />
          <div>Saving...</div>
        </div>
      ) : (
        <button
          data-test-id={dataTestId}
          disabled={disabled || isLoading}
          className={classNames(
            isInsertButton ? currentStyle.insertRoot : currentStyle.root,
            primary
              ? currentStyle.red
              : isInsertButton
              ? currentStyle.lightGrey
              : currentStyle.grey,
            size === 'large'
              ? currentStyle.large
              : isInsertButton
              ? currentStyle.insertRoot
              : currentStyle.small,
            disabled ? currentStyle.disabled : currentStyle.root,
            isLoading && !shouldDisplayNothing.includes(label) ? currentStyle.isLoading : {}
          )}
          type="button"
          onClick={async () => {
            if (!disabled) {
              setIsLoading(true);
              await onClick?.();
              setIsLoading(false);
            }
          }}
          {...props}
        >
          <>{label}</>
        </button>
      )}
    </div>
  );
};
