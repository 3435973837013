import React from 'react';
import classNames from 'classnames';
import BEM from '../bem';
import { ReactComponent as activatedTeam } from '../images/default-avatar--activatedTeam.svg';
import { ReactComponent as distributionList } from '../images/default-avatar--distributionList.svg';
import { ReactComponent as distributionListPregen } from '../images/default-avatar--distributionListPregen.svg';
import { ReactComponent as escalation } from '../images/default-avatar--escalation.svg';
import { ReactComponent as forum } from '../images/default-avatar--forum.svg';
import { ReactComponent as group } from '../images/default-avatar--group.svg';
import { ReactComponent as groupNew } from '../images/default-avatar--groupNew.svg';
import { ReactComponent as multipleProviders } from '../images/default-avatar--multipleProviders.svg';
import { ReactComponent as multipleProvidersSmsOptedOut } from '../images/default-avatar--multipleProvidersSmsOptedOut.svg';
import { ReactComponent as patientCare } from '../images/default-avatar--patientCare.svg';
import { ReactComponent as patientMessaging } from '../images/default-avatar--patientMessaging.svg';
import { ReactComponent as rolesTransition } from '../images/default-avatar--roleTransition.svg';
import { ReactComponent as singleProvider } from '../images/default-avatar--singleProvider.svg';
import { ReactComponent as singleProviderSmsOptedOut } from '../images/default-avatar--singleProviderSmsOptedOut.svg';
import { ReactComponent as user } from '../images/default-avatar--user.svg';
import { ReactComponent as MuteIcon } from '../images/muted-icon.svg';
import BasicImage from './BasicImage';

const classes = BEM.with('AvatarImage');
const defaultAvatars: { [k: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  activatedTeam,
  distributionList,
  distributionListPregen,
  escalation,
  forum,
  group,
  groupNew,
  multipleProviders,
  multipleProvidersSmsOptedOut,
  patientCare,
  patientMessaging,
  rolesTransition,
  singleProvider,
  singleProviderSmsOptedOut,
  user,
};

type AvatarImageProps = {
  ariaLabel?: string;
  appendChildren?: React.ReactElement | null;
  avatar?: React.FC<{ className: string }>;
  avatarUrl?: string;
  basicImageClassModifier?: string;
  children: React.ReactElement | null;
  className?: string;
  displayName?: string;
  entityType: keyof typeof defaultAvatars | 'role' | 'careTeam';
  forceDefault?: boolean;
  inverse?: boolean;
  isDisabled?: boolean;
  isMuted?: boolean;
  isRolesTransitionFeatureFlagEnabled?: boolean;
  modifiers?: Record<string, unknown>;
  shouldUseMinWidth?: boolean;
  size?: string | number;
  squareAvatar?: boolean;
  style?: Record<string, unknown>;
  tagColor?: string;
  showPresenceIndicator?: boolean;
};

const ROLES_TRANSITION_AVATAR_URL_SUBSTRING = 'roles_transition';

export default function AvatarImage({
  appendChildren,
  ariaLabel,
  avatar: Avatar,
  avatarUrl,
  basicImageClassModifier = '',
  children = null,
  className = '',
  displayName,
  entityType,
  forceDefault,
  inverse,
  isDisabled,
  isMuted = false,
  isRolesTransitionFeatureFlagEnabled = false,
  modifiers,
  shouldUseMinWidth,
  showPresenceIndicator,
  size,
  squareAvatar,
  style: newStyle = {},
  tagColor,
  ...restProps
}: AvatarImageProps) {
  const isShowingDefault = forceDefault || !avatarUrl;
  const avatarClass = squareAvatar ? 'image-container-square' : 'image-container-circle';
  const isRoleTransitionGroup =
    isRolesTransitionFeatureFlagEnabled &&
    entityType === 'group' &&
    avatarUrl?.includes(ROLES_TRANSITION_AVATAR_URL_SUBSTRING);

  if ((!children || !children.props.children) && entityType !== 'role') {
    if (isShowingDefault || isRoleTransitionGroup) {
      let Avatar = defaultAvatars[entityType];

      if (isRoleTransitionGroup) {
        Avatar = defaultAvatars['rolesTransition'];
      } else if (isRolesTransitionFeatureFlagEnabled && entityType === 'group') {
        Avatar = defaultAvatars['groupNew'];
      }

      const shouldPad = entityType === 'forum';
      if (Avatar !== undefined) {
        children = <Avatar className={classes('image', { entityType, inverse, shouldPad })} />;
      }
    } else {
      children = (
        <BasicImage
          keyProp={avatarUrl}
          src={avatarUrl}
          alt={displayName}
          useCSSBG
          classModifier={basicImageClassModifier}
        />
      );
    }
  }

  const sizeName = typeof size === 'string' ? size : false;
  if (typeof size === 'number') {
    newStyle = { ...newStyle, [shouldUseMinWidth ? 'minWidth' : 'width']: size, height: size };
  }

  if (isDisabled) newStyle = { ...newStyle, opacity: 0.5 };

  let newInnerStyle: { [k: string]: unknown } = {};
  if (['role', 'team', 'activatedTeam'].includes(entityType as string)) {
    const avatarClass =
      size === 25 || size === 30 ? 'smallIcon' : size === 'large' ? 'largeIcon' : 'icon';
    if (tagColor === 'default' && entityType === 'role') {
      newInnerStyle = {
        backgroundColor: 'var(--neutral-100)',
        border: '1px solid var(--neutral-600)',
        height: '100%',
        width: '100%',
      };
    } else {
      newInnerStyle = { backgroundColor: tagColor };
    }

    if (Avatar) {
      children = (
        <div className={classes('container')}>
          <Avatar className={classes(avatarClass)} />
        </div>
      );
    }
  }

  return (
    <span
      aria-label={ariaLabel}
      className={classNames(
        className,
        classes({
          size: sizeName,
          [entityType]: true,
          default: isShowingDefault,
          ...modifiers,
        })
      )}
      style={newStyle}
      title={displayName}
      {...restProps}
    >
      <div
        className={classes(avatarClass, {
          entityType,
        })}
        style={newInnerStyle}
      >
        {children}
        {isMuted && (
          <span className={classes('mute', { avatarClass })}>
            <MuteIcon />
          </span>
        )}
      </div>
      {appendChildren}
    </span>
  );
}
