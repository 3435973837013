import { useCallback, useMemo, useState } from 'react';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import { BasicModal, RecipientSearchPicker } from '../';

import type { Conversation, Entity, Organization } from '../../../types';
import { actions, useAppDispatch } from '../../../redux-stores';
import { GROUP_MEMBER_SEARCH_TYPES } from 'models/enums/SearchTypes';
import { shouldAddMemberToConversation } from 'common/utils/shouldAddMemberToConversation';

const { setModal } = actions;

const classes = BEM.with('CollaborationModal');

type MobxProps = {
  currentUserId: string;
  currentConversation: Conversation;
  currentOrganization: Organization;
  isComposing: boolean;
  selectedRecipients: Array<Entity>;
};

type MentionMemberModalProps = {
  isOpen: boolean;
  options: {
    onClick: (users: Entity[]) => {};
  };
};

function MentionMemberModal({
  currentUserId,
  isOpen,
  options,
  currentConversation,
  currentOrganization,
  isComposing,
  selectedRecipients,
}: MentionMemberModalProps & MobxProps) {
  const { onClick } = options;
  const dispatch = useAppDispatch();

  const [selectedMembers, setSelectedMembers] = useState<Entity[]>([]);

  const getMembersNotInComposeRecipientPicker = useCallback(() => {
    return selectedMembers.filter((member) =>
      selectedRecipients.every((recipient) => {
        if (recipient.$entityType === 'team') {
          return recipient?.members?.every((m) => m.id !== member.id && member?.botUserId !== m.id);
        } else return member.id !== recipient.id && member?.botUserId !== recipient.id;
      })
    );
  }, [selectedMembers, selectedRecipients]);

  const membersToAdd = useMemo(
    () => (isComposing ? getMembersNotInComposeRecipientPicker() : []),
    [getMembersNotInComposeRecipientPicker, isComposing]
  );

  const handleSelectMembers = (selectedMember: Entity[]) => {
    setSelectedMembers(selectedMember);
  };

  const closeModal = () => {
    setSelectedMembers([]);
    dispatch(setModal(undefined));
  };

  const onSubmit = () => {
    onClick(selectedMembers);

    selectedMembers.forEach((member) => {
      if (shouldAddMemberToConversation(currentUserId, member, currentConversation)) {
        membersToAdd.push(member);
      }
    });

    if (membersToAdd.length > 0) {
      const serializedMembersToAdd = membersToAdd.map((member) => {
        return { ...member, members: [] };
      });

      dispatch(
        setModal({
          name: 'addMemberToConversation',
          data: {
            // Needed for serialize elements
            membersToAdd: JSON.parse(JSON.stringify(serializedMembersToAdd)),
          },
        })
      );
    } else {
      closeModal();
    }
  };

  return isOpen ? (
    <BasicModal
      ariaLabelCancelButton="Mentions Cancel"
      ariaLabelCloseButton="Mentions Modal Close"
      ariaLabelHeader="Mentions Header"
      ariaLabelSubmitButton="Mention Member"
      dangerButtonTheme="danger"
      headerText="Mentions"
      hasCloseButton={true}
      headerTextStyle="normal-header-text"
      isOpen={isOpen}
      onSubmit={onSubmit}
      submitDisabled={selectedMembers?.length === 0}
      onClose={closeModal}
      size="medium"
      submitText="MENTION"
      theme="normal"
    >
      <div data-test-id={'On Duty Body'} className={classes('')}>
        <div className={classes('selector-container')}>
          <RecipientSearchPicker
            autoFocus
            ariaLabelSelect="MentionProviders"
            placeholder="Choose someone to mention"
            className={classes('input')}
            isEntityInConversationCheck
            excludeTeams
            excludeBroadcastsWhenSenderIsRole
            onChange={handleSelectMembers}
            selected={selectedMembers}
            multi
            organization={currentOrganization}
            searchTypes={GROUP_MEMBER_SEARCH_TYPES}
            currentModalType="modal-mentions"
          />
        </div>
      </div>
    </BasicModal>
  ) : null;
}

export default mobxInjectSelect<MentionMemberModalProps, MobxProps>({
  sessionStore: ['currentUserId'],
  conversationStore: ['currentConversation'],
  messengerStore: ['currentOrganization'],
  composeMessageStore: ['isComposing', 'selectedRecipients'],
})(MentionMemberModal);
