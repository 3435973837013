import React from 'react';
import classNames from 'classnames';

import styles from './PageHeader.module.css';

export interface PageHeaderProps {
  /**
   * Header contents
   */
  label: string;
  /**
   * Sub header contents
   */
  onClick?: () => void;
  subLabel?: string;
  dataTestIdHeader?: string;
  dataTestIdSubHeader?: string;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  label,
  onClick,
  subLabel,
  dataTestIdHeader,
  dataTestIdSubHeader,
}) => {
  return (
    <>
      <span
        className={classNames(styles.root)}
        onClick={onClick}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
        data-test-id={dataTestIdHeader}
      >
        {label}
      </span>
      {subLabel && (
        <>
          <span className={classNames(styles.arrow)}>&nbsp; &#12297; &nbsp;</span>
          <span className={classNames(styles.root)} data-test-id={dataTestIdSubHeader}>
            {subLabel}
          </span>
        </>
      )}
    </>
  );
};
