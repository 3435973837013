import React from 'react';
import classNames from 'classnames';

import styles from './ViewHeader.module.css';

export interface ViewHeaderProps {
  /**
   * Customize styles for ViewHeaderProps
   */
  customStyles?: Record<string, string>;
  /**
   * Header contents
   */
  label: string;
}

export const ViewHeader: React.FC<ViewHeaderProps> = ({ customStyles = {}, label }) => {
  const currentStyles = { ...styles, ...customStyles };

  return <div className={classNames(currentStyles.viewHeader)}>{label}</div>;
};
