import React from 'react';
import { format, parseISO } from 'date-fns';

import { EntityDetail } from '@tigerconnect/web-component-library';
import classNames from 'classnames';
import BEM from '../../bem';
import { buildPatientLocation } from './../../utils';
import { MDYYYY_DATE_FORMAT } from 'common/constants';
import { PatientContextModel } from 'redux-stores/patientContext';
import { useAppSelector } from 'redux-stores';

const classes = BEM.with('PatientReferenceInfo');

type PatientReferenceInfoProps = {
  patientReferenceId?: string;
  showLocation?: boolean;
  patientReference?: PatientContextModel;
  type: 'pill' | 'card';
};

function PatientReferenceInfo({
  patientReference,
  patientReferenceId,
  showLocation = false,
  type,
}: PatientReferenceInfoProps) {
  const { patientReferences } = useAppSelector(({ patientContext: { patientReferences } }) => {
    return {
      patientReferences,
    };
  });
  const patientId = patientReferenceId || patientReference?.uuid;
  const patient = (patientId && patientReferences[patientId]) || patientReference;
  const { dob, firstName, lastName, status, mrn, gender, patientLocation } = patient || {};
  const formattedDob = dob && format(parseISO(dob), MDYYYY_DATE_FORMAT);
  const patientName = `${firstName} ${lastName}`;
  const patientDetail = [formattedDob, gender, mrn].filter((x) => x).join(' • ');
  const location = buildPatientLocation(patientLocation);

  if (type === 'pill') {
    return (
      <div className={classes('')}>
        {patientName && patientDetail ? (
          <div className={classes('patient-pill-container')}>
            <span className={classes('patient-name')}>{patientName}</span>
            <span className={classes('patient-details')}>{patientDetail}</span>
          </div>
        ) : (
          <div
            className={classNames(classes('patient-pill-container'), classes('patient-pill-error'))}
          >
            Patient Loading Error
          </div>
        )}
      </div>
    );
  } else if (type === 'card') {
    return (
      <div className={classes('')}>
        <EntityDetail
          name={patientName}
          options={{
            type: 'search results',
            details: {
              theme: 'patientReference',
              patientInformation: (
                <>
                  {status === 'discharged' && (
                    <>
                      <span className={classes('patient-discharged')}>DISCHARGED</span> •{' '}
                    </>
                  )}
                  {patientDetail}
                  {showLocation && location && (
                    <div className={classes('patient-location')}>Location: {location}</div>
                  )}
                </>
              ),
            },
          }}
          avatar={{ type: 'patientReference' }}
        />
      </div>
    );
  }
  return null;
}

export default PatientReferenceInfo;
