import { cloneDeep, isUndefined, isInteger, keyBy, omit } from 'lodash';
import type { Dispatch } from '@reduxjs/toolkit';
import TCClient from '../../../client';
import {
  CollabEntity,
  PagerNumber,
  Role,
  RoleMetadata,
  RoleEscalationPolicy,
} from '../../../types';
import {
  BatchRoleUpdateProps,
  CreateRoleProps,
  ManagePagerNumberProps,
  RoleUpdateProps,
  UpdateRoleOwnerProps,
} from '../../../types/Collaboration';
import { addEntity, formatRole, handleCreateUpdateError } from '../entities/thunk';
import { actions } from '../../index';
import { entityServerId } from '../selectors';

type RoleChangeEventData = {
  role: CollabEntity;
  type: 'OPT_IN' | 'OPT_OUT';
};
export const handleRoleChangeEvent = async (
  dispatch: Dispatch,
  organizationId: string,
  ...args: unknown[]
) => {
  const eventData = args[0] as RoleChangeEventData;
  const delay = isInteger(args[1]) ? (args[1] as number) : 3000;
  const { type, role } = eventData || {};
  const {
    id,
    members = [],
    organizationId: eventOrgId,
  } = cloneDeep(formatRole({ entity: role })) || {};
  if (!id || eventOrgId !== organizationId) return;
  dispatch(
    actions.reactToRoleChangeEvent({
      type,
      id,
      members,
    })
  );
  if (delay) await new Promise((resolve) => setTimeout(resolve, delay));
  dispatch(actions.refreshActiveRoles());
};

export const clearRolesSchedule = async (
  dispatch: Dispatch,
  organizationId: string
): Promise<boolean> => {
  try {
    await TCClient.roles.clearRolesScheduler(organizationId);
    return true;
  } catch (e) {
    handleCreateUpdateError(dispatch, e);
    return false;
  }
};

const handlePagerNumberChange = (
  dispatch: Dispatch,
  pagerNumber: string,
  selectedRole: Role,
  entitiesById: {
    [id: string]: CollabEntity;
  },
  remove?: boolean
) => {
  if (!selectedRole) return;

  let newPagers = selectedRole.orgPagers || [];
  if (remove) {
    newPagers = newPagers.filter((s) => s !== pagerNumber);
  } else {
    newPagers = [...newPagers, pagerNumber];
  }
  const newSelectedRole = { ...selectedRole, orgPagers: newPagers };
  if (entitiesById[selectedRole.id]) {
    dispatch(
      actions.updateEntities({
        entitiesById: { ...entitiesById, [selectedRole.id]: newSelectedRole },
      })
    );
  }
};

export const assignPagerNumber = async (
  dispatch: Dispatch,
  { pagerNumber, roleId, organizationId, selectedRole, entitiesById }: ManagePagerNumberProps
) => {
  await TCClient.twilio.assignPagerNumber(organizationId, pagerNumber, entityServerId(roleId));
  handlePagerNumberChange(dispatch, pagerNumber, selectedRole, entitiesById);
};

export const fetchIntegrationIds = async (dispatch: Dispatch, organizationId: string) => {
  const integrations = await TCClient.roles.findIntegrationIds(organizationId);
  dispatch(actions.setIntegrationIds(integrations.map(({ id }: { id: string }) => id)));
};

const formatRoleInputForServer = (roleUpdateProps: RoleUpdateProps) => {
  let escalationPolicy: RoleEscalationPolicy | RoleEscalationPolicy<string> | undefined =
    roleUpdateProps.escalationPolicy;
  if (escalationPolicy?.escalation_path?.length) {
    escalationPolicy = {
      ...escalationPolicy,
      escalation_path: escalationPolicy.escalation_path.map((ep) => ({
        ...ep,
        targets: ep.targets?.map((t) => t.token),
      })),
    };
  }

  return {
    ...roleUpdateProps,
    ...(!isUndefined(escalationPolicy) ? { escalationPolicy } : null),
  };
};

export const batchRoleUpdate = async ({
  ids,
  organizationId,
  entitiesById,
  entityUpdateProps,
}: BatchRoleUpdateProps): Promise<{ [id: string]: CollabEntity }> => {
  const {
    escalationPolicy,
    doesRoleTransitionExcludePrivateGroups,
    noOwnersMessage,
    openAssignment,
    ownerRequired,
    isRoleTransitionEnabled,
    tagColor,
    tagName,
    tagId,
  } = entityUpdateProps;

  for (const id of ids) {
    await TCClient.roles.update(id.split(':')[1], {
      ...formatRoleInputForServer(entityUpdateProps),
      organizationId,
    });
  }

  return keyBy(
    ids.map((id) => {
      const oldRole = entitiesById[id] as Role;
      return {
        ...oldRole,
        ...omit(entityUpdateProps, ['tagColor', 'tagName', 'tagId']),
        metadata: {
          ...(oldRole.metadata as RoleMetadata),
          ...(!isUndefined(escalationPolicy) ? { escalation_policy: escalationPolicy } : null),
          ...(!isUndefined(doesRoleTransitionExcludePrivateGroups)
            ? { exclude_private_group: doesRoleTransitionExcludePrivateGroups }
            : null),
          ...(!isUndefined(noOwnersMessage) ? { no_owners_message: noOwnersMessage } : null),
          ...(!isUndefined(openAssignment) ? { open_assignment: openAssignment } : null),
          ...(!isUndefined(ownerRequired) ? { owner_required: ownerRequired } : null),
          ...(!isUndefined(isRoleTransitionEnabled)
            ? { role_transition: isRoleTransitionEnabled }
            : null),
          ...(!isUndefined(tagColor) ? { tag_color: tagColor } : null),
          ...(!isUndefined(tagName) ? { tag_name: tagName } : null),
          ...(!isUndefined(tagId) ? { tag_id: tagId } : null),
        },
      };
    }),
    ({ id }: { id: string }) => id
  );
};

export const createRole = async (
  dispatch: Dispatch,
  {
    activeTab,
    checkedTagsById,
    organizationId,
    roleInput,
    selectedCategory,
    selectedTag,
    entitiesById,
    tagsById,
  }: CreateRoleProps
) => {
  dispatch(actions.setIsSavingEntity(true));
  try {
    const newRole = await TCClient.roles.create({
      ...roleInput,
      organizationId,
    });
    addEntity(
      dispatch,
      'role',
      activeTab,
      checkedTagsById,
      newRole,
      organizationId,
      entitiesById,
      selectedCategory,
      selectedTag,
      tagsById
    );
  } catch (e) {
    handleCreateUpdateError(dispatch, e);
  } finally {
    dispatch(actions.setIsSavingEntity(false));
  }
};

export const getAutoResponse = async (id: string) => {
  return await TCClient.groups.find(entityServerId(id), { bypassCache: true });
};

export const getPagerNumbers = async (
  dispatch: Dispatch,
  areaCode: string,
  organizationId: string
) => {
  try {
    dispatch(actions.setIsGettingPagerNumbers(true));
    const numbers: PagerNumber[] = await TCClient.twilio.getTwilioNumbers(organizationId, areaCode);
    dispatch(actions.setPagerNumbers(numbers));
    if (!numbers || !numbers.length) dispatch(actions.setAreaCodeError(true));
  } catch (e: unknown) {
    dispatch(actions.setAreaCodeError(true));
  } finally {
    dispatch(actions.setIsGettingPagerNumbers(false));
  }
};

export const removeAwayResponse = async (dispatch: Dispatch, id: string, orgid: string) => {
  dispatch(actions.setIsSavingEntity(true));
  await TCClient.roles.removeAwayResponse(entityServerId(id), orgid);
  dispatch(actions.setIsSavingEntity(false));
};

export const removePagerNumber = async (
  dispatch: Dispatch,
  { pagerNumber, roleId, organizationId, selectedRole, entitiesById }: ManagePagerNumberProps
) => {
  await TCClient.twilio.removePagerNumber(organizationId, pagerNumber, entityServerId(roleId));
  handlePagerNumberChange(dispatch, pagerNumber, selectedRole, entitiesById, true);
};

export const saveAwayResponse = async (
  dispatch: Dispatch,
  id: string,
  orgid: string,
  status: string
) => {
  dispatch(actions.setIsSavingEntity(true));
  await TCClient.roles.saveAwayResponse(entityServerId(id), orgid, status);
  dispatch(actions.setIsSavingEntity(false));
};

export const uploadScheduleUpload = async (
  dispatch: Dispatch,
  file: File,
  integrationId: string,
  timezone: string,
  organizationId: string
) => {
  try {
    const response = await TCClient.roles.uploadRolesSchedule({
      file,
      integrationId,
      organizationId,
      timezone,
    });
    dispatch(actions.setRoleScheduleUploadResponse(response));
  } catch (e) {
    handleCreateUpdateError(dispatch, e);
  }
};

export const updateRoleOwner = async (
  dispatch: Dispatch,
  { method, organizationId, selectedRole, user }: UpdateRoleOwnerProps
) => {
  const roleId = selectedRole.id;
  dispatch(actions.setRoleOwnershipLoading({ isLoading: true, id: roleId }));
  try {
    await TCClient.roles[method](entityServerId(roleId), organizationId, user.id || user.token);
    const oldRole = selectedRole as Role;
    const newRoleEntity = {
      ...oldRole,
      members: method === 'optOut' ? [] : [cloneDeep(user)],
      memberIds: method === 'optOut' ? [] : [user.id],
    };
    const newRole = formatRole({ entity: newRoleEntity });

    dispatch(actions.updateEntity(newRole));
  } catch (e) {
    handleCreateUpdateError(dispatch, e);
  } finally {
    dispatch(actions.setRoleOwnershipLoading({ isLoading: false, id: roleId }));
  }
};
