import React from 'react';
import BEM from '../../bem';
import { User } from '../../../types';

const classes = BEM.with('PriorityMessageHeader');

type PriorityMessageHeaderProps = {
  autoForwardToDisplayName: { displayName: string };
  message: {
    autoForwardedFrom: User;
    isAutoForwarded: boolean;
    isOutgoing: boolean;
  };
};

function PriorityMessageHeader({ autoForwardToDisplayName, message }: PriorityMessageHeaderProps) {
  const { isOutgoing, autoForwardedFrom, isAutoForwarded } = message;
  const direction = isOutgoing ? 'OUTGOING' : 'INCOMING';

  let autoForwardFragment;

  if (autoForwardToDisplayName) {
    autoForwardFragment = (
      <div className={classes('auto-forward')}>
        <div className={classes('auto-forward-text')}>
          Auto-forwarded to {autoForwardToDisplayName.displayName}
        </div>
      </div>
    );
  }

  if (isAutoForwarded && autoForwardedFrom) {
    autoForwardFragment = (
      <div className={classes('auto-forward')}>
        <div className={classes('auto-forward-text')}>
          Auto-forwarded from {autoForwardedFrom.displayName}
        </div>
      </div>
    );
  }

  return (
    <div className={classes({ direction, 'is-auto-forwarded': isAutoForwarded })}>
      <div className={classes('priority-text')}>
        PRIORITY MESSAGE
        {autoForwardFragment}
      </div>
    </div>
  );
}

export default PriorityMessageHeader;
