import React from 'react';

import TCClient from '../../client';

import { CtaButton } from '../shared-components/CtaButton/CtaButton';
import { ViewContainer } from '../shared-components/View/ViewContainer/ViewContainer';
import { UserData } from '../types';

import styles from '../pages/CustomerSupport/CustomerSupport.module.css';

export default function DeleteUser({
  accountData,
  afterDelete,
  onClose,
}: {
  accountData: UserData;
  afterDelete: () => void;
  onClose: () => void;
}) {
  async function handleDeleteUserClick() {
    const confirmation = await window.confirm('Are you sure you want to delete this user?');
    if (confirmation) {
      afterDelete();
      try {
        await TCClient.adminUsers.remoteWipe({
          orgId: accountData.organizationSettings[0].organizationId,
          userId: accountData.token,
        });
      } catch (e) {
        console.error(JSON.stringify(e));
      }

      await TCClient.adminUsers.accountDelete({
        userId: accountData.token,
      });
      alert('User successfully deleted.');
    }
  }

  return (
    <ViewContainer
      {...{
        header: 'Delete User',
        subHeader:
          'Permanently delete this user from the TigerConnect system. All user data will be erased FOREVER!',
        onClose,
      }}
    >
      <div className={styles.buttonContainer}>
        <CtaButton label={'Delete User'} onClick={handleDeleteUserClick} primary={true} />
      </div>
    </ViewContainer>
  );
}
