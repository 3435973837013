import React from 'react';
import PropTypes from 'prop-types';
import { MessageTemplateRepositories } from '../../../../models/enums/MessageTemplateRepositories';
import BEM from '../../../bem';
import { ReactComponent as DropDownSvg } from '../../../images/pa-dropdown.svg';
import { TemplatesRow } from './';
import { useAppSelector } from 'redux-stores';
import AccessibleList from 'common/components/AccessibleList';

const { PERSONAL } = MessageTemplateRepositories;

const classes = BEM.with('TemplatesTable');

const TemplatesTable = ({
  allowPatientDeliveryMethod,
  isAdmin,
  isLoadingMore,
  onClick,
  queryValue,
  repository,
  results,
  scrollContainerRef,
  selected,
  sortBy,
  sortOrder,
  toggleSelected,
  toggleSort,
}) => {
  const accessibilityMode = useAppSelector((state) => state.ui.accessibilityMode);
  let resultsFragment;

  const ariaSortString = (colName) => {
    return sortBy === colName ? (sortOrder === 'desc' ? 'descending' : 'ascending') : 'none';
  };

  if (results.length === 0) {
    if (isLoadingMore) {
      resultsFragment = <div />;
    } else if (queryValue) {
      resultsFragment = (
        <div className={classes('empty')}>No results found for &quot;{queryValue}&quot;</div>
      );
    } else {
      resultsFragment = (
        <div className={classes('empty')}>
          No message templates available.
          {(repository === 'personal' || isAdmin) && ' Click "Create Template" to get started.'}
        </div>
      );
    }
  } else {
    resultsFragment = results.map((template) => (
      <TemplatesRow
        allowPatientDeliveryMethod={allowPatientDeliveryMethod}
        {...template}
        createdBy={template.createdBy}
        isSmsCompatible={template.isSmsCompatible}
        isAdmin={isAdmin}
        isSelected={selected.includes(template.id)}
        key={template.id}
        onClick={onClick}
        toggleSelected={toggleSelected}
        updatedBy={template.updatedBy}
      />
    ));
  }

  return (
    <div className={classes()} role="table" aria-describedby="Message Templates table">
      <div className={classes('list')}>
        <div
          className={classes('list-header', {
            noCheckbox: !(repository === 'personal' || isAdmin),
          })}
          role="rowgroup"
        >
          {(repository === 'personal' || isAdmin) && (
            <div className={classes('list-item', { checkbox: true })} />
          )}
          <button
            className={classes('list-item', { three: true })}
            onClick={() => toggleSort('template_label')}
            aria-sort={ariaSortString('template_label')}
            role="columnheader"
          >
            Template Label
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'template_label',
              })}
              aria-hidden
            />
          </button>
          {allowPatientDeliveryMethod && (
            <button
              className={classes('list-item')}
              onClick={() => toggleSort('is_sms_compatible')}
              aria-sort={ariaSortString('is_sms_compatible')}
              role="columnheader"
            >
              <div> Delivery </div>
              <DropDownSvg
                className={classes('sort-arrow', {
                  isDesc: sortOrder === 'desc',
                  sortBy: sortBy === 'is_sms_compatible',
                })}
                aria-hidden
              />
            </button>
          )}
          {repository !== PERSONAL && (
            <button
              className={classes('list-item')}
              onClick={() => toggleSort('created_by_display_name')}
              aria-sort={ariaSortString('created_by_display_name')}
              role="columnheader"
            >
              Created by
              <DropDownSvg
                className={classes('sort-arrow', {
                  isDesc: sortOrder === 'desc',
                  sortBy: sortBy === 'created_by_display_name',
                })}
                aria-hidden
              />
            </button>
          )}
          <button
            className={classes('list-item')}
            onClick={() => toggleSort('created_on')}
            aria-sort={ariaSortString('created_on')}
            role="columnheader"
          >
            Created on
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'created_on',
              })}
              aria-hidden
            />
          </button>
          <button
            className={classes('list-item')}
            onClick={() => toggleSort('updated_on')}
            aria-sort={ariaSortString('updated_on')}
            role="columnheader"
          >
            Edited
            <DropDownSvg
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'updated_on',
              })}
              aria-hidden
            />
          </button>
        </div>
        <div className={classes('list-body')} ref={scrollContainerRef} id="Message Templates table">
          <AccessibleList
            focusableClasses={'.tc-TemplatesRow'}
            accessibilityMode={accessibilityMode}
            focusableChildrenClasses={'.tc-TemplatesRow__list-item-checkbox-default'}
            setStartElementOnChange={true}
            role="rowgroup"
          >
            {resultsFragment}
          </AccessibleList>
        </div>
      </div>
    </div>
  );
};

TemplatesTable.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  queryValue: PropTypes.string.isRequired,
  repository: PropTypes.string.isRequired,
  results: PropTypes.array.isRequired,
  scrollContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  selected: PropTypes.array.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  toggleSort: PropTypes.func.isRequired,
};

export default TemplatesTable;
