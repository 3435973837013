import React from 'react';
import { observer } from 'mobx-react';

import { AlertComponent } from '../../../../../src/types/';
import BEM from '../../../../common/bem';
import { GroupAvatar } from '../../../../common/components';

const classes = BEM.with('AlertViewConversation');

export type AlertViewConversationProps = {
  component?: AlertComponent;
  onClick?: () => void;
};
const mockGroup = { avatarUrl: '', groupType: 'GROUP' };

const AlertViewConversation = ({ component, onClick }: AlertViewConversationProps) => {
  return (
    <div className={classes()}>
      <div
        className={classes('container')}
        data-test-id={'viewConversation'}
        aria-label="view-conversation"
        onClick={onClick}
      >
        <GroupAvatar group={mockGroup} size={32} />{' '}
        <span className={classes('text')}>View Conversation</span>
      </div>
    </div>
  );
};

export default observer(AlertViewConversation);
