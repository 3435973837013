import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../../utils';
import BEM from '../../bem';
import { ReactComponent as ArrowRightSvg } from '../../images/arrow-right.svg';
import propTypes from '../../propTypes';
import { ContextMenu, MenuItemList, MenuItem } from '../ContextMenu';
import { Buttons, Form, SubmitButton } from '../Form';

const classes = BEM.with('LocationForm');

class LocationForm extends Component {
  static propTypes = {
    selectLocation: PropTypes.func.isRequired,
    locations: propTypes.arrayOrObservableArrayOf(PropTypes.object).isRequired,
  };

  state = {
    selectedLocation: this.props.locations[0],
    isWorking: false,
  };

  render() {
    const { locations } = this.props;
    const { isWorking, selectedLocation } = this.state;

    return (
      <Form className={classes()} columns={4} onSubmit={this.onSubmit} theme="rows">
        <div className={classes('location-header')}>
          <span className={classes('location-title-text')}>
            Please choose an organization's location
          </span>
          <span className={classes('location-title-subtext')}>
            You’re using organizations from multiple countries, please choose one before you
            continue.
          </span>
        </div>
        <div className={classes('location-picker-container')}>
          <span className={classes('location-picker-label')}>Location:</span>
          <ContextMenu
            event="click"
            position="topinnerleft"
            hideOnScroll={true}
            menu={
              <MenuItemList className={classes()}>
                {locations.map((location) => (
                  <MenuItem
                    className={classes('location-name')}
                    key={location.baseUrl}
                    onClick={this._setLocation(location)}
                  >
                    <span>{location.country}</span>
                  </MenuItem>
                ))}
              </MenuItemList>
            }
          >
            <div className={classes('location-picker')}>
              {selectedLocation && selectedLocation.country}
            </div>
          </ContextMenu>
        </div>
        <Buttons>
          <SubmitButton isWorking={isWorking} disabled={isWorking} className={classes('submit')}>
            {isWorking ? (
              'LOADING'
            ) : (
              <>
                CONTINUE
                <span className={classes('button-arrow')}>
                  <ArrowRightSvg />
                </span>
              </>
            )}
          </SubmitButton>
        </Buttons>
      </Form>
    );
  }

  _setLocation = (selectedLocation) => {
    return () => {
      this.setState({ selectedLocation });
    };
  };

  onSubmit = async () => {
    const { selectLocation } = this.props;
    this.setState({ isWorking: true });
    await selectLocation(this.state.selectedLocation);
  };
}

export default mobxInjectSelect({
  sessionStore: ['locations', 'selectLocation'],
})(LocationForm);
