import React, { useEffect, useState } from 'react';
import BEM from '../bem';

const classes = BEM.with('BasicImage');

const IMAGE_FADE_IN_TIME = 200;

type ImageProps = {
  alt: string | undefined;
  classModifier?: string;
  useCSSBG?: boolean;
  keyProp: string | undefined;
  src: string | undefined;
};

export default function BasicImage({
  alt,
  classModifier,
  useCSSBG,
  keyProp,
  src = '',
}: ImageProps) {
  const [showImage, setShowImage] = useState({});

  useEffect(() => {
    const image: HTMLImageElement = new Image();
    image.onload = handleImageLoaded(image);
    image.src = src;

    function handleImageLoaded(image: HTMLImageElement) {
      setShowImage(
        Object.assign(
          {},
          {
            opacity: 1,
          }
        )
      );
      return null;
    }
  }, [src]);

  const defaultStyle = {
    willChange: 'opacity',
    opacity: 0,
    transition: `opacity ${IMAGE_FADE_IN_TIME}ms cubic-bezier(0.22, 1, 0.36, 1)`,
    verticalAlign: 'top',
  };

  let imageComponent: JSX.Element;

  if (useCSSBG) {
    imageComponent = (
      <div
        key={keyProp}
        style={{
          ...defaultStyle,
          backgroundImage: `url(${src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: `100%`,
          ...showImage,
        }}
        className={classes('image', { classModifier })}
      ></div>
    );
  } else {
    imageComponent = (
      <img
        key={keyProp}
        style={{ ...defaultStyle, ...showImage }}
        className={classes('image', { classModifier })}
        src={src}
        alt={alt}
      />
    );
  }

  return (
    <div className={classes()}>
      <div className={classes('container')}>{imageComponent}</div>
    </div>
  );
}
