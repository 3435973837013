import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { ReactComponent as SideArrow } from '../../images/side-arrow.svg';
import propTypes from '../../propTypes';
import { mobxInjectSelect } from '../../utils';
import { MuteButton, RoleAvatar, Scrollbars, UserName } from '../';
import { ReactComponent as RolesActiveSvg } from '../../images/roles-saved-active.svg';
import { ReactComponent as RolesInactiveSvg } from '../../images/roles-saved-inactive.svg';

const classes = BEM.with('RoleInfoPane');

class RoleInfoPane extends Component {
  static propTypes = {
    currentConversation: propTypes.conversation.isRequired,
    disableSaveRoleButton: PropTypes.bool.isRequired,
    findRole: PropTypes.func.isRequired,
    openPrintMode: PropTypes.func.isRequired,
    removeSavedRole: PropTypes.func.isRequired,
    role: propTypes.role.isRequired,
    saveRole: PropTypes.func.isRequired,
    showLeaveGroupModal: PropTypes.func.isRequired,
  };

  _lastTagStyle = undefined;

  componentDidMount() {
    const { findRole, role } = this.props;
    findRole(role.id, role.organizationId);
  }

  render() {
    const { currentConversation, disableSaveRoleButton, openPrintMode, role, savedRoleIds } =
      this.props;

    const { isDeletable, isMuted } = currentConversation;
    const { tag } = role;
    const assignedRole = role.members && role.members.length > 0;
    const user = assignedRole ? role.members[0] : role.botUser.botRole;
    const isSavedRole = savedRoleIds.find((savedRoleId) => savedRoleId === role.id);
    const roleState = disableSaveRoleButton ? 'loading' : isSavedRole ? 'active' : 'inactive';
    const RoleIcon =
      roleState === 'loading' || roleState === 'inactive' ? RolesInactiveSvg : RolesActiveSvg;

    let onDutyStatus, tagFragment;

    if (!tag || tag.isDefault) {
      tagFragment = (
        <div className={classes('role-titles')}>
          <div aria-label="Info Pane Role Name" className={classes('role-name')}>
            {role.displayName}
          </div>
        </div>
      );
    } else {
      const { color } = tag;
      if (!this._lastTagStyle || this._lastTagStyle.backgroundColor !== color) {
        this._lastTagStyle = { backgroundColor: color };
      }
      tagFragment = (
        <div className={classes('role-titles')}>
          <div aria-label="Info Pane Role Name" className={classes('role-name')}>
            {role.displayName}
          </div>
          <span className={classes('tag-indicator')} style={this._lastTagStyle} />
          <span className={classes('tag-name')}>{tag.name}</span>
        </div>
      );
    }

    if (role.displayName === user.displayName) {
      onDutyStatus = (
        <div className={classes('user-details')}>
          <span aria-label="Info Pane Role Status" className={classes('no-on-duty')}>
            No one is on duty
          </span>
        </div>
      );
    } else {
      onDutyStatus = (
        <div aria-label="Info Pane Role Status" className={classes('user-details')}>
          <span className={classes('on-duty')}>On Duty: </span>
          <span className={classes('basic-details')}>
            <UserName user={user} />
          </span>
        </div>
      );
    }

    return (
      <div className={classes()}>
        <Scrollbars shouldScrollOverflow={true}>
          <div className={classes('main-container')}>
            <div className={classes('role-name-avatar')}>
              <div className={classes('role-avatar')}>
                <RoleAvatar
                  ariaLabel="Info Pane Avatar Image"
                  role={role}
                  size={'large'}
                  isMuted={isMuted}
                  showPresenceIndicator={true}
                  presenceStatus={role.members?.[0]?.presenceStatus}
                  userId={role.members?.[0]?.id}
                />
              </div>
              <div className={classes('role-names')}>
                {tagFragment}
                {onDutyStatus}
              </div>
            </div>
            <div className={classes('options')}>
              <div className={classes('title')}>OPTIONS</div>
              <MuteButton conversation={currentConversation} />
              <div className={classes('option-row')} onClick={openPrintMode}>
                <div
                  aria-label="Info Pane Print"
                  className={classes('option-header')}
                  id={'print-mode'}
                >
                  PRINT MODE
                </div>
                <div className={classes('side-arrow')}>
                  <SideArrow />
                </div>
              </div>
              <div
                aria-label="Info Pane Delete"
                className={classes('option-row', { disabled: !isDeletable })}
                onClick={this._showLeaveGroupModal}
              >
                <div
                  className={classes('option-header', {
                    disabled: !isDeletable,
                  })}
                >
                  DELETE CONVERSATION
                </div>
                <div className={classes('side-arrow')}>
                  <SideArrow />
                </div>
              </div>
              <div className={classes('option-row')} onClick={this._clickSaveRole}>
                <div className={classes('option-header')}>SAVE ROLE</div>
                <div className={classes('RoleSaveButton')}>
                  <RoleIcon
                    className={classNames(
                      classes('image', { disabled: disableSaveRoleButton }),
                      classes({ state: roleState })
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    );
  }

  _clickSaveRole = (e) => {
    e.stopPropagation();
    const { disableSaveRoleButton, removeSavedRole, role, saveRole, savedRoleIds } = this.props;

    if (disableSaveRoleButton) return;
    const isSavedRole = savedRoleIds.find((savedRoleId) => savedRoleId === role.id);
    const action = isSavedRole ? removeSavedRole : saveRole;
    action(role.botUserId, role.organizationId);
  };

  _showLeaveGroupModal = () => {
    const { currentConversation, showLeaveGroupModal } = this.props;
    const { counterParty, isDeletable } = currentConversation;

    if (isDeletable) {
      showLeaveGroupModal(counterParty);
    }
  };
}

export default mobxInjectSelect({
  conversationStore: ['currentConversation'],
  groupEditorStore: ['showLeaveGroupModal'],
  messengerStore: ['openPrintMode'],
  roleStore: ['disableSaveRoleButton', 'findRole', 'removeSavedRole', 'savedRoleIds', 'saveRole'],
})(RoleInfoPane);
