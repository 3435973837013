import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CollaborationCategories from '../../../models/enums/CollaborationCategories';
import { mobxInjectSelect } from '../../utils';
import propTypes from '../../propTypes';
import { GroupTypes } from '../../../types';
import { groupTypeReadableLabel } from '../../utils/readableLabels';
import { MuteButton } from '../';
import BEM from '../../bem';
import AccessibleList from '../AccessibleList';
import { ReactComponent as SideArrowSvg } from '../../images/side-arrow.svg';
import { ReactComponent as TeamSavedSvg } from '../../images/roles-saved-active.svg';
import { ReactComponent as TeamNotSavedSvg } from '../../images/roles-saved-inactive.svg';
import { PreserveGroupConversation } from './';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';
const { MYTEAMS } = CollaborationCategories;

const classes = BEM.with('GroupOptions');

class GroupOptions extends Component {
  static propTypes = {
    currentConversation: propTypes.conversation.isRequired,
    currentOrganization: PropTypes.shape({
      showTeams: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
    }),
    group: propTypes.group.isRequired,
    isUniversalTaggingAllowed: PropTypes.bool.isRequired,
    openCollaboration: PropTypes.func.isRequired,
    openPrintMode: PropTypes.func.isRequired,
    showLeaveGroupModal: PropTypes.func.isRequired,
    savedTeamIds: propTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
    selectTeam: PropTypes.func.isRequired,
    toggleSaveTeam: PropTypes.func.isRequired,
    isConversationDoNotExpireEnabled: PropTypes.bool.isRequired,
  };

  state = {
    accessibilityMode: false,
  };

  render() {
    let groupDetailsComponent;
    let thirdOptionComponent;
    const {
      currentConversation,
      currentOrganization,
      group,
      isUniversalTaggingAllowed,
      openPrintMode,
      savedTeamIds,
      toggleSaveTeam,
      isConversationDoNotExpireEnabled,
    } = this.props;
    const { isDeletable } = currentConversation;
    const { groupType, members, team, id } = group;
    const isSavedTeam = savedTeamIds.includes(team?.id);
    const isPatientServiceGroup = groupType === GroupTypes.GROUP && !!group.patientContextId;

    const shouldDisplayPreserveOption =
      currentConversation?.featureService !== 'group_alerts' &&
      groupType !== GroupTypes.FORUM &&
      !isPatientServiceGroup &&
      groupType !== GroupTypes.INTRA_TEAM;

    if (groupType === GroupTypes.PATIENT_MESSAGING) {
      const canLeaveGroup =
        members.filter((member) => !member.isPatient && !member.isPatientContact).length > 1;
      groupDetailsComponent = (
        <AccessibleList
          focusableClasses={['.tc-GroupOptions__option-row']}
          accessibilityMode={this.state.accessibilityMode}
        >
          <button className={classes('option-row')} onClick={openPrintMode}>
            <div className={classes('export-container')}>
              <div className={classes('option-header')}>EXPORT CONVERSATION</div>
              <div>
                <SideArrowSvg className={classes('side-arrow')} aria-hidden />
              </div>
            </div>
          </button>
          {canLeaveGroup && (
            <button
              className={classes('option-row', { disabled: !isDeletable })}
              onClick={this._openLeaveGroupModal}
            >
              <div className={classes('option-header', { disabled: !isDeletable })}>
                LEAVE {groupTypeReadableLabel(groupType).toUpperCase()}
              </div>
              <div>
                <SideArrowSvg className={classes('side-arrow')} aria-hidden />
              </div>
            </button>
          )}
        </AccessibleList>
      );
    } else {
      if (groupType === GroupTypes.INTRA_TEAM) {
        if (team && currentOrganization.showTeams) {
          thirdOptionComponent = (
            <>
              <button
                className={classes('option-row', { disabled: !isDeletable })}
                onClick={this._viewTeamProfile}
              >
                <div className={classes('option-header', { disabled: !isDeletable })}>
                  TEAM PROFILE
                </div>
                <div>
                  <SideArrowSvg className={classes('side-arrow')} aria-hidden />
                </div>
              </button>
              {isUniversalTaggingAllowed && (
                <button className={classes('option-row')} onClick={() => toggleSaveTeam(team?.id)}>
                  <div className={classes('option-header')}>SAVE TEAM</div>
                  <div className={classes('team-save-toggle')}>
                    {isSavedTeam ? <TeamSavedSvg aria-hidden /> : <TeamNotSavedSvg aria-hidden />}
                  </div>
                </button>
              )}
            </>
          );
        }
      } else {
        thirdOptionComponent = (
          <button
            aria-label="Info Pane Leave Group"
            className={classes('option-row', { disabled: !isDeletable })}
            id={'conv-details-leave'}
            onClick={this._openLeaveGroupModal}
          >
            <div className={classes('option-header', { disabled: !isDeletable })}>
              Leave {groupTypeReadableLabel(groupType)}
            </div>
            <div>
              <SideArrowSvg className={classes('side-arrow')} aria-hidden />
            </div>
          </button>
        );
      }
      groupDetailsComponent = (
        <div>
          <div className={classes('title')}>OPTIONS</div>
          <AccessibleList
            accessibilityMode={this.state.accessibilityMode}
            focusableClasses={[
              '.tc-GroupOptions__option-row',
              '.tc-MuteButton',
              '.tc-PreserveConversation',
            ]}
            focusableChildrenClasses={['Switch_module_accessibility']}
            setStartElementOnChange={true}
          >
            <MuteButton conversation={currentConversation} />
            {isConversationDoNotExpireEnabled && shouldDisplayPreserveOption && (
              <PreserveGroupConversation groupId={id} preserve={group.preserve} />
            )}
            <button id={'print-mode'} className={classes('option-row')} onClick={openPrintMode}>
              <div className={classes('option-header')}>Print Mode</div>
              <div>
                <SideArrowSvg className={classes('side-arrow')} aria-hidden />
              </div>
            </button>
            {thirdOptionComponent}
          </AccessibleList>
        </div>
      );
    }

    return (
      <div className={classes()}>
        {groupDetailsComponent}
        <ReduxEscapeHatch
          ref={(ref) => {
            this.reduxEscapeHatch = ref;
            if (
              this.reduxEscapeHatch &&
              this.reduxEscapeHatch?.accessibilityMode !== this.state.accessibilityMode
            ) {
              this.setState({ accessibilityMode: this.reduxEscapeHatch?.accessibilityMode });
            }
          }}
        />
      </div>
    );
  }

  _viewTeamProfile = () => {
    const { group, isUniversalTaggingAllowed, openCollaboration, selectTeam } = this.props;
    const { team } = group;

    if (team) {
      if (isUniversalTaggingAllowed) {
        openCollaboration(MYTEAMS, team.id);
      } else {
        selectTeam(team.id, { openTeamsTab: true });
      }
    }
  };

  _openLeaveGroupModal = () => {
    const { currentConversation, showLeaveGroupModal } = this.props;
    const { counterParty, isDeletable } = currentConversation;

    if (isDeletable) {
      showLeaveGroupModal(counterParty);
    }
  };
}

export default mobxInjectSelect({
  conversationStore: ['currentConversation'],
  groupEditorStore: ['showLeaveGroupModal'],
  messengerStore: [
    'currentOrganization',
    'isUniversalTaggingAllowed',
    'openCollaboration',
    'openPrintMode',
    'isConversationDoNotExpireEnabled',
  ],
  teamStore: ['savedTeamIds', 'selectTeam', 'toggleSaveTeam'],
})(GroupOptions);
