import { Message } from '../../../types';

export function calculateSingleOrGroupRecipientStatus({ message }: { message: Message }) {
  const { counterParty, counterPartyType, group, statusesPerRecipient } = message;
  const groupType = counterParty?.groupType;
  const isRoleP2P = counterPartyType === 'group' && groupType === 'ROLE_P2P';
  const singlePatientConversation =
    groupType === 'PATIENT_MESSAGING' && statusesPerRecipient.length === 1;
  let recipientStatus;

  if (isRoleP2P) {
    const { memberIds, p2pRecipient } = group;
    const { $entityType, botUser } = p2pRecipient || {};
    const p2pUser = $entityType === 'role' && botUser ? botUser : p2pRecipient;
    if (p2pUser) {
      let statusObject;

      statusObject = statusesPerRecipient.find(({ userId }) => userId === p2pUser.id);

      if (!statusObject) {
        statusObject = statusesPerRecipient.find(({ userId }) => memberIds?.includes(userId));
      }

      recipientStatus = statusObject?.status || 'NA';
    }
  } else if (counterPartyType === 'user' || singlePatientConversation) {
    recipientStatus = statusesPerRecipient.length > 0 ? statusesPerRecipient[0].status : 'NA';
  }

  return recipientStatus;
}
