import React, { useCallback, useState, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

import TCClient from '../../../../client';
import BEM from '../../../bem';
import { VWR, VWRStaff } from '../../../../types';
import { copyToClipboard, mobxInjectSelect } from '../../../../common/utils';
import { BasicModal, DotsIndicator, Scrollbars } from '../../';
import { ReactComponent as SuccessCheckMark } from '../../../images/green-fill-checkmark.svg';
import SourceIdVwr from '../../../../models/enums/SourceIdVwr';
import { VWRVisitReasonsModal } from './VisitReasonsModal';

import { RoomsDropDown } from './RoomDropDown';
import AccessPicker from './AccessPicker';

const classes = BEM.with('VirtualWaitingRoom');

type VirtualWaitingRoomProps = {};

type MobxProps = {
  currentOrganizationId: string;
  logPendoAnalytics: (p: { parentPathName: string; pathName: string }) => void;
  openModal: (modalType: string) => void;
  accessibilityMode: boolean;
};

const CREATE = 'CREATE' as const;
const EDIT = 'EDIT' as const;
const sourceId = SourceIdVwr.ADMIN;
type Mode = typeof CREATE | typeof EDIT;

const emptyRoom = {
  name: '',
  id: '',
  version: 0,
  staff: [],
  status: 'CLOSED',
  visitReasons: [],
} as VWR;

const VirtualWaitingRoom: React.FC<VirtualWaitingRoomProps & MobxProps> = ({
  currentOrganizationId,
  logPendoAnalytics,
  openModal,
  accessibilityMode,
}) => {
  const [roomList, setRoomList] = useState<VWR[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<VWR>();
  const [mode, setMode] = useState<Mode>(CREATE);
  const [draftRoom, setDraftRoom] = useState<VWR>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState<boolean>(true);
  const [saveIndicator, setSaveIndicator] = useState<boolean>(false);
  const [copyCheckMark, setCopyShowCheckMark] = useState<boolean>(false);
  const [shouldShowStaffErrorMessage, setShouldShowStaffErrorMessage] = useState(false);
  const [isReasonsModalOpen, setIsReasonsModalOpen] = useState(false);
  const draftStaff = draftRoom?.staff;
  const inputNameRef = useRef<HTMLInputElement | null>(null);

  const createNewWaitingRoom = useCallback(() => {
    setMode(CREATE);
    setDraftRoom(emptyRoom);
  }, []);

  useEffect(() => {
    if (draftStaff && draftStaff?.length > 0) {
      setShouldShowStaffErrorMessage(false);
    }
  }, [draftStaff]);

  const fetchRooms = useCallback(async () => {
    try {
      const rooms = (await TCClient.virtualWaitingRoom.findAll(currentOrganizationId, {
        showAll: true,
      })) as VWR[];
      setRoomList(rooms);
      if (rooms && rooms.length > 0) {
        setMode(EDIT);
      } else {
        createNewWaitingRoom();
      }
      return rooms;
    } catch (error) {
      console.log(error);
    }
  }, [currentOrganizationId, createNewWaitingRoom]);

  const assignSelectedRoom = async (selectedRoom: VWR) => {
    setDraftRoom(selectedRoom);
    setSelectedRoom(selectedRoom);
  };

  const onCloseModalAction = (room: VWR) => {
    assignSelectedRoom(room);
    setIsReasonsModalOpen(false);
  };

  const selectRoom = useCallback(
    async (room: VWR) => {
      setShouldShowStaffErrorMessage(false);
      try {
        const newRoom = (await TCClient.virtualWaitingRoom.find(
          room.id,
          currentOrganizationId
        )) as VWR;
        setDraftRoom(newRoom);
        setSelectedRoom(newRoom);
      } catch (error) {
        console.log(error);
      }
    },
    [currentOrganizationId]
  );

  useEffect(() => {
    const fetchRoomsData = async () => {
      const rooms = await fetchRooms();
      if (rooms && rooms.length > 0) await selectRoom(rooms[0]);
      setShowLoadingIndicator(false);
    };
    if (showLoadingIndicator) fetchRoomsData();
  }, [fetchRooms, showLoadingIndicator, selectRoom]);

  const createRoom = async () => {
    setSaveIndicator(true);
    try {
      const name = draftRoom?.name.trim();
      const room = await TCClient.virtualWaitingRoom.create(currentOrganizationId, {
        ...draftRoom,
        name,
      });
      await fetchRooms();
      assignSelectedRoom(room);
      setSaveIndicator(false);
    } catch (error) {
      console.log(error);
      if (openModal) openModal('failure');
      setSaveIndicator(false);
    }
  };

  const editRoom = async () => {
    setSaveIndicator(true);
    try {
      const name = draftRoom?.name.trim();
      const room = await TCClient.virtualWaitingRoom.update(
        selectedRoom?.id,
        currentOrganizationId,
        { ...draftRoom, name, sourceId }
      );
      await fetchRooms();
      assignSelectedRoom(room);
      setSaveIndicator(false);
    } catch (error) {
      console.log(error);
      if (openModal) openModal('failure');
      setSaveIndicator(false);
    }
  };

  const onCancel = () => {
    setShouldShowStaffErrorMessage(false);
    setDraftRoom(selectedRoom);
    setMode(EDIT);
  };

  const onSave = () => {
    if (draftRoom?.status && draftRoom.staff.length < 1) {
      setShouldShowStaffErrorMessage(true);
      return;
    }
    setShouldShowStaffErrorMessage(false);
    if (mode === CREATE) createRoom();
    if (mode === EDIT) editRoom();
  };

  const deleteWaitingRoom = async () => {
    setSaveIndicator(true);
    try {
      await TCClient.virtualWaitingRoom.delete(selectedRoom?.id, currentOrganizationId);
      const rooms = await fetchRooms();
      if (rooms && rooms.length > 0) await selectRoom(rooms[0]);
      closeDeleteModal();
      setSaveIndicator(false);
    } catch (error) {
      console.log(error);
      closeDeleteModal();
      if (openModal) openModal('failure');
      setSaveIndicator(false);
    }
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const copyUrl = () => {
    copyToClipboard(selectedRoom?.joinUrl || '');
    setCopyShowCheckMark(true);

    setTimeout(() => {
      setCopyShowCheckMark(false);
    }, 3000);
  };

  const isSaveDisabled = () => {
    if (mode === CREATE) {
      return draftRoom?.name.trim() === '';
    }
    const isDraftNameEmpty = draftRoom?.name.trim() === '';
    const isSameName = draftRoom?.name === selectedRoom?.name;
    const isSameStaff = isEqual(selectedRoom?.staff, draftRoom?.staff);
    return isDraftNameEmpty || (isSameName && isSameStaff);
  };

  const isCancelDisabled = () => {
    if (mode === CREATE) {
      return roomList.length === 0;
    }
    const isSameStaff = isEqual(selectedRoom?.staff, draftRoom?.staff);
    const isSameName = draftRoom?.name === selectedRoom?.name;
    return isSameStaff && isSameName;
  };

  useEffect(() => {
    const fetchRoomsData = async () => {
      const rooms = await fetchRooms();
      if (rooms && rooms.length > 0) await selectRoom(rooms[0]);
      setShowLoadingIndicator(false);
    };
    fetchRoomsData();
  }, [fetchRooms, selectRoom]);

  const selectedRoomId = selectedRoom?.id;
  useEffect(() => {
    const updateStaffList = ({
      roomId,
      staff,
      version,
    }: {
      roomId: string;
      staff: VWRStaff[];
      version: number;
    }) => {
      if (selectedRoomId === roomId && draftRoom) {
        setDraftRoom({ ...draftRoom, staff, version });
      }
    };

    TCClient.virtualWaitingRoom.on('vwr:staff:update', updateStaffList);
    return () => {
      TCClient.virtualWaitingRoom.off('vwr:staff:update', updateStaffList);
    };
  }, [draftRoom, selectedRoomId]);

  return (
    <div className={classes()}>
      <Scrollbars shouldScrollOverflow={true} autoHide={false}>
        <div className={classes('main-container')}>
          <div className={classes('header')}>
            <h3 className={classes('title')} id="patient-settings-heading">
              Virtual Waiting Room
            </h3>
            {mode === EDIT && (
              <button
                className={classes('create-button')}
                onClick={() => {
                  logPendoAnalytics({
                    parentPathName: 'Patient Settings',
                    pathName: 'Create New Waiting Room',
                  });
                  createNewWaitingRoom();
                  if (accessibilityMode && inputNameRef.current) inputNameRef?.current?.focus();
                }}
              >
                Create New Waiting Room
              </button>
            )}
          </div>
          {roomList && roomList.length > 0 && mode === EDIT && (
            <RoomsDropDown
              rooms={roomList}
              selectedWaitingRoom={selectedRoom}
              setSelectedRoom={selectRoom}
              reloadRoomsList={fetchRooms}
            />
          )}
          <span className={classes('item-title')}>Virtual Waiting Room Name</span>
          <input
            ref={inputNameRef}
            onChange={(e) => {
              const { value: name } = e.target;
              setDraftRoom((room) => {
                if (room) {
                  return { ...room, name };
                }
              });
            }}
            value={draftRoom?.name || ''}
            className={classes('input')}
            placeholder={'Type your virtual waiting room name here'}
          />
          <span className={classes('access-title')}>Virtual Waiting Room Access</span>
          <span className={classes('access-desc')}>
            Please select the users who will have access to this Virtual Waiting Room. Users with
            access will not only be able to navigate to this virtual waiting room, but will also
            receive notifications for all virtual waiting room activity.
          </span>
          {currentOrganizationId && (
            <AccessPicker draft={draftRoom || emptyRoom} setDraft={setDraftRoom} />
          )}
          {shouldShowStaffErrorMessage && (
            <span className={classes('staff-error-message')}>
              A virtual waiting room requires at least one staff member
            </span>
          )}
          {mode === EDIT && selectedRoom && (
            <>
              <span className={classes('item-title')}>Patient Reasons for Visit</span>
              <div className={classes('visit-reasons')}>
                <div className={classes('reasons-desc')}>
                  <span className={classes('desc')}>
                    Set customized reasons for visit that patients will select from when entering
                    the Virtual Waiting Room
                  </span>
                </div>
                <div className={classes('reasons-button')}>
                  <button
                    className={classes('copy-button')}
                    onClick={() => setIsReasonsModalOpen(true)}
                  >
                    EDIT
                  </button>
                </div>
              </div>
            </>
          )}
          {mode === EDIT && selectedRoom && selectedRoom.joinUrl && (
            <>
              <span className={classes('item-title')}>Virtual Waiting Room URL</span>
              <span className={classes('desc')}>
                Please share this link with your patients so they can enter your virtual waiting
                room.
              </span>
              <div className={classes('join-url-container')}>
                <div className={classes('join-url')} data-test-id={'vwr-join-url'}>
                  {selectedRoom?.joinUrl}
                </div>
                <div>
                  <button className={classes('copy-button')} onClick={copyUrl}>
                    {copyCheckMark ? (
                      <div className={classes('check-mark')}>
                        <SuccessCheckMark />
                      </div>
                    ) : (
                      'COPY'
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
          <div className={classes('edit-buttons')}>
            <button
              className={classes('cancel-button')}
              disabled={isCancelDisabled()}
              onClick={onCancel}
            >
              CANCEL
            </button>
            <button className={classes('save-button')} disabled={isSaveDisabled()} onClick={onSave}>
              {saveIndicator ? <DotsIndicator color={'#969696'} size={13} /> : 'SAVE'}
            </button>
          </div>
          {mode === EDIT && (
            <div className={classes('delete-section')}>
              <span className={classes('item-title')}>Delete Virtual Waiting Room</span>
              <span className={classes('desc')}>
                This will permanently delete this virtual waiting room.
              </span>
              <button className={classes('delete-button')} onClick={openDeleteModal}>
                DELETE
              </button>
            </div>
          )}
          <BasicModal
            ariaLabelBody={'Delete Virtual Waiting Room'}
            ariaLabelCancelButton={'Delete Virtual Waiting Room Cancel'}
            ariaLabelCloseButton={'Delete Virtual Waiting Room Close'}
            ariaLabelHeader={'Delete Virtual Waiting Room Header'}
            ariaLabelSubmitButton={'Delete Virtual Waiting Room Delete'}
            bodyText={`Are you sure you want to delete ${selectedRoom?.name}?`}
            headerText={'Are You Sure?'}
            isOpen={deleteModalOpen}
            onClose={closeDeleteModal}
            onSubmit={deleteWaitingRoom}
            submitText={'DELETE'}
            theme={'danger'}
          />
          {selectedRoom && (
            <VWRVisitReasonsModal
              currentOrganizationId={currentOrganizationId}
              isOpen={isReasonsModalOpen}
              onClose={(room) => onCloseModalAction(room)}
              openModal={openModal}
              room={selectedRoom}
            />
          )}
        </div>
      </Scrollbars>
      {showLoadingIndicator && (
        <div className={classes('loading')}>
          <DotsIndicator color={'#969696'} size={13} />
        </div>
      )}
    </div>
  );
};

export default mobxInjectSelect<VirtualWaitingRoomProps, MobxProps>({
  messengerStore: ['currentOrganizationId'],
  modalStore: ['openModal'],
  trackerStore: ['logPendoAnalytics'],
  sessionStore: ['accessibilityMode'],
})(VirtualWaitingRoom);
