import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from '@tigerconnect/web-component-library';
import throttle from 'lodash.throttle';
import { debounce } from 'lodash';

import TCClient from '../../../../client';
import BEM from '../../../bem';
import {
  Conversation,
  Entity,
  Group,
  Role,
  SearchQueryOptions,
  SearchResult,
  User,
} from '../../../../types';
import {
  Individual,
  Role as RoleEntity,
} from '../../../../widgets/messenger/components/SearchSidebar/SearchParityResults';

import Dropdown from '../../Dropdown';
import { ReduxState, useAppSelector } from '../../../../redux-stores';
import CollaborationSearch from '../CollaborationSearch';
import { mobxInjectSelect } from '../../../utils';
import SearchTypes from '../../../../models/enums/SearchTypes';
import { DotsIndicator } from '../../index';
import { groupByUserAndRoleEntities } from '../../../utils/groupByUserAndRoleEntities';
import { MODAL_HEIGHT } from 'common/components/Mentions/MentionMemberFloatingModal';

const LABEL_HEIGHT = 31;
const ENTITY_HEIGHT = 52;

const classes = BEM.with('CollaborationSelector');

type MobxProps = {
  currentConversation: Conversation;
  hideDnd: boolean;
  isUserDndAfEnabledForCurrentOrg: (user: User) => boolean;
  isPresenceFeatureFlagEnabled: boolean;
  isAvailabilityFeatureFlagEnabled: boolean;
  isComposing: boolean;
  selectedRecipients: Array<Entity>;
  syncGroupMembers: () => void;
  initializeGroupEditor: ({ group }: { group: Group }) => void;
};

type EntitiesSelectorProps = {
  allowRolesRecipients?: boolean;
  clearEntity: (e: React.MouseEvent) => void;
  currentUserId: string;
  selectEntity: (user: User | Role) => void;
  shouldDisplayOnDutyText?: boolean;
  toggleHandler: () => void;
  disableDndEntities?: boolean;
  isEntityInConversationCheck?: boolean;
  isMentionMemberFloatingModalOpen?: boolean;
  organizationId?: string;
  filter?: string;
  pressedKey?: string;
  setIsOpen?: (isOpen: boolean) => void;
  setHeight?: (height: number) => void;
};

type EntityType = User | Role;

function EntitiesSelector({
  allowRolesRecipients,
  clearEntity,
  currentUserId,
  currentConversation,
  hideDnd,
  isUserDndAfEnabledForCurrentOrg,
  isAvailabilityFeatureFlagEnabled,
  isPresenceFeatureFlagEnabled,
  organizationId,
  selectEntity,
  toggleHandler,
  disableDndEntities = true,
  isEntityInConversationCheck = false,
  isMentionMemberFloatingModalOpen = false,
  shouldDisplayOnDutyText = true,
  filter,
  pressedKey,
  setIsOpen,
  setHeight,
  isComposing,
  selectedRecipients,
  syncGroupMembers,
  initializeGroupEditor,
}: EntitiesSelectorProps & MobxProps) {
  const selectedOrgId = useAppSelector((state: ReduxState) => state.collab.selectedOrgId);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<EntityType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | Role | undefined>(undefined);
  const [selectedUserIndex, setSelectedUserIndex] = useState<number>(0);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const sortOrder = useRef<Map<string, number>>(
    new Map([
      ['role', 0],
      ['user', 1],
    ])
  );
  const continuation = useRef<string | undefined>('');
  const fetchEnd = useRef(false);
  const currentOrgId = organizationId ? organizationId : selectedOrgId;
  const loadingDotSize = isMentionMemberFloatingModalOpen ? 17 : 13;

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = event.target.value;
    setSearchValue(newVal);
    setIsLoading(false);
    continuation.current = '';
    fetchEnd.current = false;
    handleSearchQuery(newVal);
  };

  const clearSearch = () => {
    setSearchValue('');
    handleSearchQuery('');
  };

  const handleSearchQuery = useMemo(
    () =>
      debounce((val: string, scrolling?: boolean) => {
        const searchTypes = allowRolesRecipients
          ? [SearchTypes.INDIVIDUAL, SearchTypes.ROLE]
          : [SearchTypes.INDIVIDUAL];

        const searchEntities = async (val: string) => {
          const searchOptions: SearchQueryOptions = {
            version: 'SEARCH_PARITY',
            query: { name: val },
            organizationId: currentOrgId,
            types: searchTypes,
            sort: isPresenceFeatureFlagEnabled
              ? ['presenceStatus', 'displayName']
              : ['displayName'],
            ...(scrolling && continuation.current && { continuation: continuation.current }),
          };

          const {
            results,
            metadata: { continuation: nextContinuation },
          } = await TCClient.search.query<SearchResult>(searchOptions);

          continuation.current = nextContinuation;
          if (!nextContinuation) fetchEnd.current = true;

          const data = results
            .filter((r: SearchResult) => {
              if (!allowRolesRecipients) {
                return r.entityType !== 'role';
              } else {
                return r.entityType;
              }
            })
            .map((r: SearchResult) => r.entity as EntityType)
            .filter(
              (u: EntityType) =>
                ![
                  'ccd0b6da-e5c8-40fe-9708-828fa8fef035',
                  'e5ef47bd-6ab9-4ece-9b3e-aa477056d086',
                ].includes(u.id)
            );

          if (scrolling) {
            setResults((_results) => _results.concat(data));
            setIsLoading(false);
          } else {
            setResults(data);
            const groupedEntities = groupByUserAndRoleEntities(data, sortOrder);
            setIsLoading(false);
            setIsOpen &&
              setIsOpen(
                !(groupedEntities[0].values.length === 0 && groupedEntities[1].values.length === 0)
              );
          }
        };
        searchEntities(val);
      }, 500),
    [allowRolesRecipients, currentOrgId, isPresenceFeatureFlagEnabled, setIsOpen]
  );

  useEffect(() => {
    if (filter === '' && currentConversation?.counterPartyType === 'group') {
      const counterParty = currentConversation.counterParty;
      initializeGroupEditor({ group: counterParty });
      syncGroupMembers();
      setResults(currentConversation?.counterParty?.members || []);
      setIsOpen && setIsOpen(true);
      setIsLoading(false);
    } else {
      handleSearchQuery(filter || '');
    }
  }, [
    currentConversation,
    filter,
    handleSearchQuery,
    initializeGroupEditor,
    setIsOpen,
    syncGroupMembers,
  ]);

  const scrollTimeoutMS = 300;
  const handleScroll = throttle(({ target }: React.ChangeEvent<HTMLElement>) => {
    if (filter === '' && currentConversation?.counterPartyType === 'group') return;
    const { clientHeight, scrollHeight, scrollTop } = target;
    const scrollRatio = 0.8;
    if (
      clientHeight / (scrollHeight - scrollTop) > scrollRatio &&
      !fetchEnd.current &&
      !isLoading
    ) {
      setIsLoading(true);
      handleSearchQuery(searchValue, true);
    }
  }, scrollTimeoutMS);

  const groupedEntities = groupByUserAndRoleEntities(results, sortOrder);

  useEffect(() => {
    if (filter !== undefined) {
      const allUsers: Array<User | Role> = [];
      groupedEntities.forEach((entity) => entity.values.forEach((user) => allUsers.push(user)));
      const user = allUsers[selectedUserIndex];
      setSelectedUser(user);
      if (user) {
        const element = document.getElementById(allUsers[selectedUserIndex].id);
        if (selectedUserIndex === 0) {
          element?.scrollIntoView({ block: 'end' });
        } else if (isLoading) {
          element?.scrollIntoView({ block: 'start' });
        } else {
          element?.scrollIntoView({ block: 'nearest' });
        }
      }
    }
  }, [filter, groupedEntities, isLoading, selectedUserIndex]);

  useEffect(() => {
    const allUsers: Array<User | Role> = [];
    groupedEntities.forEach((entity) => entity.values.forEach((user) => allUsers.push(user)));
    setSelectedUser(allUsers[selectedUserIndex]);

    if (pressedKey === '') {
      setIsUserSelected(false);
      return;
    }

    if (!isUserSelected) {
      if (pressedKey === 'ArrowDown' || pressedKey === 'Tab') {
        const newIndex = selectedUserIndex + 1;
        if (newIndex < allUsers.length) {
          setSelectedUserIndex(newIndex);
        } else if (newIndex >= allUsers.length && !isLoading) {
          setSelectedUserIndex(0);
        }
      } else if (pressedKey === 'ArrowUp' || pressedKey === 'PrevTab') {
        const newIndex = selectedUserIndex - 1;
        if (newIndex < 0) {
          setSelectedUserIndex(allUsers.length - 1);
        } else {
          setSelectedUserIndex(newIndex);
        }
      } else if (pressedKey === 'Enter' && selectedUser) {
        selectEntity(selectedUser as User | Role);
      }
      setIsUserSelected(true);
    }
  }, [
    groupedEntities,
    isLoading,
    isUserSelected,
    pressedKey,
    selectEntity,
    selectedUser,
    selectedUserIndex,
  ]);

  useEffect(() => {
    if (!isLoading) {
      const innerGroupedEntities = groupByUserAndRoleEntities(results, sortOrder);
      const labels = innerGroupedEntities.filter((x) => x.values.length > 0).length * LABEL_HEIGHT;
      const entities =
        innerGroupedEntities.map((x) => x.values.length).reduce((m, x) => m + x) * ENTITY_HEIGHT;
      const height = Math.min(labels + entities, MODAL_HEIGHT);
      setHeight && setHeight(height || 0);
    }
  }, [isLoading, results, setHeight]);

  return (
    <div className={classes('', { isMentionMemberFloatingModalOpen })}>
      <Dropdown
        ariaLabel={'user-selector'}
        triggerHandler={toggleHandler}
        handleScroll={handleScroll}
      >
        <div>
          {!isMentionMemberFloatingModalOpen && (
            <div className={classes('search-container')}>
              <CollaborationSearch
                clearInputHandler={clearSearch}
                onChangeHandler={handleSearch}
                placeholder={'Search'}
                query={searchValue}
              />
            </div>
          )}
          {shouldDisplayOnDutyText && (
            <div
              key="noOneOnDuty"
              className={classes('list-option', { default: true })}
              onClick={(e: React.MouseEvent) => clearEntity(e)}
              data-test-id="clear user on duty"
            >
              -No one on duty-
            </div>
          )}
          {groupedEntities.map((entities, idx) => {
            if (entities.entity_type === 'role' && entities.values.length > 0) {
              return (
                <div key={idx} className={classes('sp-header')}>
                  <Card header="Roles" theme="provider" type="category">
                    {entities.values.map((role) => {
                      return (
                        <div
                          className={classes('list-option', {
                            selected: selectedUser?.id === role.id,
                          })}
                          id={role.id}
                          key={role.id + role.displayName}
                          data-test-id={`select user ${role.displayName}`}
                          onClick={() => {
                            selectEntity(role);
                          }}
                        >
                          <RoleEntity
                            currentUserId={currentUserId}
                            currentConversation={currentConversation}
                            role={
                              role as unknown as React.ComponentProps<typeof RoleEntity>['role']
                            }
                            isEntityInConversationCheck={isEntityInConversationCheck}
                            selectedRecipients={isComposing ? selectedRecipients : []}
                          />
                        </div>
                      );
                    })}
                  </Card>
                </div>
              );
            } else if (entities.entity_type === 'user' && entities.values.length > 0) {
              return (
                <div key={idx} className={classes('sp-header')}>
                  <Card header={'Individual'} theme="provider" type="category">
                    {entities.values.map((user: User) => (
                      <div
                        className={classes('list-option', {
                          disabled: !!user.dnd && disableDndEntities,
                          selected: selectedUser?.id === user.id,
                        })}
                        id={user.id}
                        key={user.id + user.displayName}
                        data-test-id={`select user ${user.displayName}`}
                        onClick={() => (!user.dnd || !disableDndEntities) && selectEntity(user)}
                      >
                        <Individual
                          currentUserId={currentUserId}
                          currentConversation={currentConversation}
                          hideDnd={hideDnd}
                          isPresenceFeatureFlagEnabled={isPresenceFeatureFlagEnabled}
                          isAvailabilityFeatureFlagEnabled={isAvailabilityFeatureFlagEnabled}
                          individual={
                            user as unknown as React.ComponentProps<typeof Individual>['individual']
                          }
                          isEntityInConversationCheck={isEntityInConversationCheck}
                          presenceSize="small"
                          showDndAfStatus={isUserDndAfEnabledForCurrentOrg(user)}
                          showPresenceIndicator={true}
                          selectedRecipients={isComposing ? selectedRecipients : []}
                        />
                      </div>
                    ))}
                  </Card>
                </div>
              );
            } else {
              return null;
            }
          })}
          {isLoading && (
            <div
              className={classes('loading', { isMentionMemberFloatingModalOpen })}
              id="entities-selector-loading"
            >
              <DotsIndicator size={loadingDotSize} speed={0.5} />
            </div>
          )}
        </div>
      </Dropdown>
    </div>
  );
}

export default mobxInjectSelect<EntitiesSelectorProps, MobxProps>({
  messengerStore: ['isPresenceFeatureFlagEnabled', 'isAvailabilityFeatureFlagEnabled'],
  userStore: ['hideDnd', 'isUserDndAfEnabledForCurrentOrg'],
  conversationStore: ['currentConversation'],
  composeMessageStore: ['isComposing', 'selectedRecipients'],
  groupEditorStore: ['syncGroupMembers', 'initializeGroupEditor'],
})(EntitiesSelector);
