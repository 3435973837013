import React, { useCallback, useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import TCClient from '../../../../../client';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { Toast } from '../../../../shared-components/Toast/Toast';
import { Checkbox } from '../../../../shared-components/Checkbox/Checkbox';
import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';
import { ProductRole } from '../../../../types';

import styles from './styles.module.css';
import { useAppSelector } from 'redux-stores';

type ProductRolesProps = {
  onClose: () => void;
};

type ProductRoleMap = {
  [k in ProductRole]?: boolean;
};

const initialRolesState: ProductRoleMap = {};

export const ProductRoles: React.FC<ProductRolesProps> = ({ onClose }) => {
  const selectedUser = useAppSelector((state) => state.admin.selectedUser);
  const selectedOrganization = useAppSelector((state) => state.admin.selectedOrganization);
  const [roles, setRoles] = useState<ProductRoleMap>(initialRolesState);
  const [diffRoles, setDiffRoles] = useState<ProductRoleMap>(initialRolesState);
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);

  const getRoles = useCallback(async () => {
    const { roles } = await TCClient.adminUsers.getUserProductRoles({
      organizationId: selectedOrganization.token,
      userId: selectedUser.id,
    });

    const [filterOutRole] = roles.filter((role) => role.product);

    const userRolesMap =
      filterOutRole.product.roles.reduce((m, v) => {
        return { ...m, [v]: true } || {};
      }, {} as Partial<ProductRoleMap>) || [];

    const productRoles = await TCClient.adminOrganizations.getAllProductRoles({
      orgId: selectedOrganization.token,
    });

    const mappedRoles = productRoles.reduce((m, v) => {
      return { ...m, [v]: userRolesMap[v] || false };
    }, initialRolesState);

    setRoles(mappedRoles);
    setDiffRoles(mappedRoles);
  }, [selectedOrganization.token, selectedUser.id]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const saveRoles = async () => {
    const actions = Object.keys(diffRoles).reduce((m, r) => {
      const role = r as keyof typeof roles;
      const init = diffRoles[role];
      const subs = roles[role];
      if (init === true && subs === false) {
        return { ...m, [role]: 'DEL' };
      }
      if (init === false && subs === true) {
        return { ...m, [role]: 'ADD' };
      }
      return m;
    }, {} as { [k in ProductRole]: 'ADD' | 'DEL' });

    await Promise.all(
      Object.keys(actions).map(async (role) => {
        await TCClient.adminUsers.updateUserProductRole({
          orgId: selectedOrganization.token,
          userId: selectedUser.id,
          product: role as ProductRole,
          action: actions[role as keyof typeof actions],
        });
      })
    );

    await getRoles();

    setIsSuccessToastOpen(true);
  };

  return (
    <ViewContainer
      header={'Product Roles'}
      subHeader={'Assign a product role to this user.'}
      onClose={onClose}
    >
      <div>
        <ul className={styles.userListContainer}>
          {Object.keys(roles).map((r) => (
            <li key={r}>
              <Checkbox
                checked={roles[r as keyof typeof roles]}
                onClick={(v) => {
                  setRoles({ ...roles, [r]: v });
                }}
                label={r.replaceAll('_', ' ')}
                multiple
              />
            </li>
          ))}
        </ul>
        <div className={styles.buttonWrapper}>
          <CtaButton
            customStyles={styles}
            disabled={isEqual(roles, diffRoles)}
            label={'Save Product Roles'}
            onClick={saveRoles}
            primary
            size={'small'}
          />
        </div>
        <Toast
          type={'SUCCESS'}
          open={isSuccessToastOpen}
          onClose={() => setIsSuccessToastOpen(false)}
          message={"User's Product Roles successfully updated."}
        />
      </div>
    </ViewContainer>
  );
};
