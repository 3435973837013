import React from 'react';
import styles from './DeleteButton.module.css';

export interface DeleteProps {
  /**
   * Optional identifier
   */
  id?: string;
  /**
   * Optional click handler
   */
  onDeleteClick?: (e: React.MouseEvent<HTMLSpanElement | HTMLParagraphElement>) => {};
}

export const DeleteButton: React.FC<DeleteProps> = ({ id, onDeleteClick }) => {
  const handleDeleteClick = (e: React.MouseEvent<HTMLSpanElement | HTMLParagraphElement>) => {
    onDeleteClick?.(e);
  };
  return <span className={styles.root} data-test-id={`delete ${id}`} onClick={handleDeleteClick} />;
};
