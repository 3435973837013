import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PatientInfo from './ConversationPane/PatientInfo';
import ToolTip from './ToolTip';
import BEM from 'common/bem';
import { GroupName } from 'common/components';
import { mobxInjectSelect } from 'common/utils';
import propTypes from 'common/propTypes';

const classes = BEM.with('PatientDetailCard');

class PatientDetailCard extends Component {
  static propTypes = {
    conversation: propTypes.conversation.isRequired,
    handleMrnOverflow: PropTypes.bool,
    isCenterName: PropTypes.bool,
    openPatientProfileModal: PropTypes.func.isRequired,
    showSmsOptedOutInfo: PropTypes.bool,
  };

  static defaultProps = {
    handleMrnOverflow: false,
    isCenterName: false,
    showSmsOptedOutInfo: false,
  };

  state = {
    isHovered: false,
  };

  render() {
    const { conversation, handleMrnOverflow, isCenterName, showSmsOptedOutInfo } = this.props;
    const { isHovered } = this.state;
    const { counterParty: group } = conversation;
    const { smsOptedOut } = group.patientDetails;

    return (
      <div className={classes()}>
        <div className={classes('group-name-container', { isCenterName })}>
          <GroupName
            className={classes('group-name', {
              smsOptedOut: !isHovered && smsOptedOut,
            })}
            group={group}
            ariaLabel={'Conversation Header Name'}
          />
        </div>
        <div
          className={classes('tooltip-container', {
            isHovered,
          })}
          onClick={this.showPatientProfile}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <ToolTip
            text="View Profile"
            className={classes('patient-network-tooltip')}
            isPatientFacing
          >
            <PatientInfo
              conversation={conversation}
              handleMrnOverflow={handleMrnOverflow}
              isCard
              isHovered={isHovered}
              isCenterName={isCenterName}
            />
          </ToolTip>
        </div>
        {showSmsOptedOutInfo && smsOptedOut && (
          <span className={classes('sms-opted-out-info')}>Opted out via SMS</span>
        )}
      </div>
    );
  }

  showPatientProfile = () => {
    const { conversation, openPatientProfileModal } = this.props;
    const { counterParty: group } = conversation;
    const { patientDetails } = group;
    if (patientDetails.id) {
      openPatientProfileModal(patientDetails.id);
    }
  };

  onMouseEnter = () => {
    this.setState({ isHovered: true });
  };
  onMouseLeave = () => {
    this.setState({ isHovered: false });
  };
}

export default mobxInjectSelect({
  patientStore: ['openPatientProfileModal'],
})(PatientDetailCard);
