import { useEffect } from 'react';
import styles from '../index.module.css';
import { userSettingsLabels } from './UserSettings';
import { renderSettings } from './RenderSettings';
import { UserSettings, UserOrganizationSettings, AdminStateSlice } from 'admin/types';
import TCClient from 'client';
import { hasOrgFeatureOrSettings } from 'admin/utils/renderSettingConditions';

type UserSettingsFormProps = {
  selectedOrganization: AdminStateSlice['selectedOrganization'];
  selectedUser: AdminStateSlice['selectedUser'];
  userSettings?: UserSettings;
  setUserSettings: (us: UserSettings) => void;
  setUserSettingsCopy: (us: UserSettings) => void;
  orgSettings?: UserOrganizationSettings;
  setOrgSettings: (os: UserOrganizationSettings) => void;
  featureFlags: Record<string, unknown>;
  urlParams: Record<string, string>;
};
export default function UserSettingsForm({
  selectedOrganization,
  selectedUser,
  featureFlags,
  urlParams,
  userSettings,
  setUserSettings,
  setUserSettingsCopy,
  orgSettings,
  setOrgSettings,
}: UserSettingsFormProps) {
  useEffect(() => {
    async function getOrganizationSettings() {
      const org_settings = await TCClient.adminUsers.fetchOrganizationSettings({
        organizationId: selectedOrganization.token,
      });
      setOrgSettings(org_settings);

      const user_settings = (await TCClient.adminUsers.getUserSettings({
        userId: selectedUser.id,
        organizationId: selectedOrganization.token,
      })) as UserSettings;
      setUserSettings(user_settings);
      setUserSettingsCopy(user_settings);
    }
    getOrganizationSettings();
  }, [
    selectedOrganization.token,
    selectedUser.id,
    setOrgSettings,
    setUserSettings,
    setUserSettingsCopy,
  ]);

  if (!orgSettings || !userSettings) {
    return null;
  }

  const { settings, patientEngagementSettings } = userSettingsLabels(
    userSettings,
    orgSettings,
    setUserSettings,
    featureFlags,
    urlParams
  );

  return (
    <div className={styles.userSettingsForm}>
      {settings.map((setting, index) => {
        return renderSettings(orgSettings, setting, index);
      })}

      {hasOrgFeatureOrSettings(orgSettings, ['featurePatientEngagement']) && (
        <h3 className={styles.patientEngagementTitle}>TigerConnect Patient Engagement</h3>
      )}

      {patientEngagementSettings.map((setting, index) => {
        return renderSettings(orgSettings, setting, index);
      })}
    </div>
  );
}
