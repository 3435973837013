export default function handleLocationChange({
  isIdpUser = false,
  url,
  wasPreviouslySignedIn = false,
}: {
  isIdpUser?: boolean;
  url?: string;
  wasPreviouslySignedIn?: boolean;
}) {
  if (wasPreviouslySignedIn && !isIdpUser) {
    if (url) {
      window.location.href = url;
    } else {
      window.location.reload();
    }
  }
}
