import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@tigerconnect/web-component-library';
import BEM from '../bem';
import propTypes from '../propTypes';
import { ReactComponent as defaultRoleSvg } from '../images/icon-role-default-tag.svg';
import { ReactComponent as whiteRoleSvg } from '../images/icon-role-white.svg';
import { ReactComponent as darkRoleSvg } from '../images/icon-role-dark.svg';
import AvatarImage from './AvatarImage';
import { PresenceIndicator } from './PresenceIndicator';
import { mobxInjectSelect } from 'common/utils';

const userAvatarClasses = BEM.with('UserAvatar');

function RoleAvatar({
  ariaLabel,
  displayName,
  indicatorSize,
  inverse,
  isAvailabilityFeatureFlagEnabled,
  isMuted,
  role,
  size,
  squareAvatar = true,
  useWCL = false,
  subscribeUser,
  unsubscribeUser,
  showPresenceIndicator,
  presenceStatus,
  userId,
}) {
  const { tag, metadata } = role;
  const roleHasUser = role?.members?.length > 0;

  useEffect(() => {
    if (isAvailabilityFeatureFlagEnabled && userId) {
      subscribeUser(userId);
    }

    return () => {
      if (isAvailabilityFeatureFlagEnabled && userId) {
        unsubscribeUser(userId);
      }
    };
  }, [subscribeUser, unsubscribeUser, userId, isAvailabilityFeatureFlagEnabled]);

  let roleAvatar, tagColor;
  if (!tag && metadata) {
    roleAvatar = whiteRoleSvg;
    tagColor = metadata.tag_color.replace('0x', '#');
  } else if (!tag || tag.isDefault === true) {
    roleAvatar = defaultRoleSvg;
    tagColor = 'default';
  } else {
    if (tag.color === '#000000' || tag.color === '#003B80') {
      roleAvatar = darkRoleSvg;
    } else {
      roleAvatar = whiteRoleSvg;
    }
    tagColor = tag.color;
  }

  if (useWCL)
    return (
      <Avatar
        type="role"
        size={size}
        bgColor={tagColor !== 'default' ? tagColor : undefined}
        shape="squircle"
      />
    );

  return (
    <AvatarImage
      ariaLabel={ariaLabel}
      avatar={roleAvatar}
      className={userAvatarClasses()}
      displayName={displayName}
      entityType="role"
      inverse={inverse}
      isMuted={isMuted}
      size={size}
      squareAvatar={squareAvatar}
      tagColor={tagColor}
      {...(isAvailabilityFeatureFlagEnabled &&
        showPresenceIndicator &&
        roleHasUser && {
          appendChildren: (
            <PresenceIndicator
              presence={presenceStatus}
              size={indicatorSize ? indicatorSize : 'MEDIUM'}
              userId={userId}
            />
          ),
        })}
    />
  );
}

RoleAvatar.propTypes = {
  ariaLabel: PropTypes.string,
  currentOrganizationId: PropTypes.string,
  indicatorSize: PropTypes.string,
  inverse: PropTypes.bool,
  isMuted: PropTypes.bool,
  role: propTypes.role,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  squareAvatar: PropTypes.bool,
  useWCL: PropTypes.bool,
  usePresence: PropTypes.bool,
  userId: PropTypes.string,
  presenceStatus: PropTypes.string,
  showPresenceIndicator: PropTypes.bool,
  isAvailabilityFeatureFlagEnabled: PropTypes.bool,
};

export default mobxInjectSelect({
  messengerStore: ['isAvailabilityFeatureFlagEnabled', 'currentOrganizationId'],
  presenceStore: ['subscribeUser', 'unsubscribeUser'],
})(RoleAvatar);
