import React from 'react';

import BEM from '../../../../bem';
import propTypes from '../../../../propTypes';

const classes = BEM.with('ScheduleMessageRecipient');

const Recipient = ({ recipient }) => {
  let nameFragment, secondaryFragment;

  if (recipient.$entityType === 'distributionList') {
    nameFragment = (
      <div className={classes('name', { distList: true })}>{recipient.name} (Broadcast)</div>
    );
    secondaryFragment = (
      <div className={classes('secondary', { distList: true })}>{recipient.memberCount} People</div>
    );
  } else if (recipient.$entityType === 'user' && !recipient.isPatientContact) {
    nameFragment = (
      <div className={classes('name', { patient: true })}>{recipient.displayName}</div>
    );
    secondaryFragment = recipient.patient && (
      <div className={classes('secondary', { patient: true })}>
        {recipient.patient.mrn} | {recipient.patient.dob} | {recipient.patient.gender}
      </div>
    );
  } else {
    nameFragment = (
      <div className={classes('name', { contact: true })}>
        {recipient.displayName} ({recipient.patientContact.relation})
      </div>
    );
    secondaryFragment = recipient.patient && (
      <div className={classes('secondary', { contact: true })}>
        <div>{recipient.patient.user.displayName}</div>
        <div>
          {recipient.patient.mrn} | {recipient.patient.dob} | {recipient.patient.gender}
        </div>
      </div>
    );
  }
  return (
    <div className={classes()}>
      {nameFragment}
      {secondaryFragment}
    </div>
  );
};

Recipient.propTypes = {
  recipient: propTypes.user.isRequired,
};

export default Recipient;
