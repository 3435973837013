import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../../bem';
import DotsIndicator from '../../../DotsIndicator';
import mobxInjectSelect from '../../../../utils/mobxInjectSelect';
import { Modal } from '../../..';
import { PatientAndContactFormItem } from '../';
import PatientAdminFormValidation from '../../../../../models/enums/PatientAdminFormValidation';

const { PATIENT, CONTACT } = PatientAdminFormValidation;
const classes = BEM.with('ManagePatientOrContactModal');

class ManagePatientOrContactModal extends Component {
  static propTypes = {
    actionInProgress: PropTypes.bool.isRequired,
    contact: PropTypes.object.isRequired,
    createContact: PropTypes.func.isRequired,
    editContact: PropTypes.func.isRequired,
    editPatient: PropTypes.func.isRequired,
    formErrorsString: PropTypes.string,
    hideManagePatientOrContactModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    validatePatientOrContactForm: PropTypes.func.isRequired,
  };

  render() {
    const { actionInProgress, contact, formErrorsString, isOpen, options, patient } = this.props;
    const {
      patient: { firstName, lastName },
      index,
      isPatient,
    } = options;

    const isAdding = !isPatient && !contact.contactId;
    const patientName = [firstName, lastName].join(' ');

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        headerClass={classes('header')}
        header={
          <React.Fragment>
            <p>
              {isAdding
                ? `Create a contact for ${patientName}`
                : `Edit ${patientName}${isPatient ? '' : "'s contact"}`}
            </p>
            <p className={classes('errors', { hasError: !!formErrorsString })}>
              {' '}
              {formErrorsString}{' '}
            </p>
          </React.Fragment>
        }
        onRequestClose={this._onRequestClose}
        size={'variable'}
        isOpen={isOpen}
        footerClass={classes('footer')}
        footerPrimaryActions={
          <button
            className={classes('save-btn', { progress: actionInProgress })}
            onClick={this._saveEntity}
            type="button"
          >
            {actionInProgress ? (
              <div className={classes('dots-container')}>
                <DotsIndicator className={classes('dots')} size={10} speed={0.5} color={'#fff'} />
              </div>
            ) : (
              'SAVE'
            )}
          </button>
        }
        footerSecondaryActions={
          <button
            className={classes('cancel-btn', { progress: actionInProgress })}
            onClick={this._onRequestClose}
            disabled={actionInProgress}
            type="button"
          >
            CANCEL
          </button>
        }
      >
        <div className={classes('patient-admin-container')}>
          <PatientAndContactFormItem
            entity={isPatient ? patient : contact}
            indexOfContact={index}
            isPatient={isPatient}
          />
        </div>
      </Modal>
    );
  }

  _saveEntity = () => {
    const { createContact, editContact, editPatient, options, validatePatientOrContactForm } =
      this.props;
    const { patient: patientParent, index, isPatient } = options;
    if (validatePatientOrContactForm(isPatient ? PATIENT : CONTACT)) {
      if (isPatient) {
        editPatient(patientParent);
      } else if (Number.isInteger(index)) {
        createContact(patientParent, index);
      } else {
        editContact(patientParent, index);
      }
    }
  };

  _onRequestClose = () => {
    const { hideManagePatientOrContactModal } = this.props;

    this.setState({ showError: false });
    hideManagePatientOrContactModal();
  };
}

export default mobxInjectSelect({
  patientStore: [
    'actionInProgress',
    'contact',
    'createContact',
    'editContact',
    'editPatient',
    'formErrorsString',
    'hideManagePatientOrContactModal',
    'patient',
    'validatePatientOrContactForm',
  ],
})(ManagePatientOrContactModal);
