import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from '../WebComponents';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';

const classes = BEM.with('About');

class About extends Component {
  static propTypes = {
    buildInfo: PropTypes.shape({
      version: PropTypes.string.isRequired,
    }).isRequired,
    desktopAppVersion: PropTypes.string.isRequired,
    isAutoUpdateEnabled: PropTypes.bool,
    isDesktopApp: PropTypes.bool.isRequired,
    setScrollbarsElement: PropTypes.func.isRequired,
  };

  render() {
    const {
      buildInfo: { version },
      desktopAppVersion,
      isAutoUpdateEnabled,
      isDesktopApp,
    } = this.props;

    return (
      <div className={classes()} ref={this._setScrollbarsElement}>
        <Card header="ABOUT">
          <div className={classes('info')}>
            <div className={classes('info-header')}>SUPPORT</div>
            <Button label="CONTACT" outline onClick={this._contactSupport} />
          </div>
          <div className={classes('info')}>
            <div className={classes('info-header')}>APP VERSION</div>
            <div className={classes('version')}>{version}</div>
          </div>
          {isDesktopApp && (
            <>
              <div className={classes('info')}>
                <div className={classes('info-header')}>DESKTOP VERSION</div>
                <div className={classes('version')}>{desktopAppVersion}</div>
              </div>
              <div className={classes('info')}>
                <div className={classes('info-header')}>AUTO UPDATE</div>
                <div className={classes('autoupdate')}>{isAutoUpdateEnabled ? 'On' : 'Off'}</div>
              </div>
            </>
          )}
        </Card>
      </div>
    );
  }

  _contactSupport = () => {
    window.open('https://tigerconnect.com/support/');
  };

  _setScrollbarsElement = (ref) => {
    this.props.setScrollbarsElement(ref, 'About');
  };
}

export default mobxInjectSelect({
  desktopAppStore: ['desktopAppVersion', 'isAutoUpdateEnabled', 'isDesktopApp'],
  profileStore: ['setScrollbarsElement'],
  messengerStore: ['buildInfo'],
})(About);
