import React from 'react';
import { DayPickerRangeController } from 'react-dates';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import BEM from '../../../bem';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('ScheduledMessagesDateRangePicker');

const DATE_FORMAT = 'YYYY-MM-DD';
const HISTORY_DEPTH_IN_DAYS = 7;
const QUEUE_DEPTH_IN_DAYS = 180;
const START_DATE = 'startDate';
const END_DATE = 'endDate';

class ScheduledMessagesDateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    let startDate = null;
    let endDate = null;
    if (props.range && props.range.scheduled_time) {
      startDate = moment(props.range.scheduled_time.from);
      endDate = moment(props.range.scheduled_time.to);
    }

    this.state = {
      editMode: {
        startDateInput: false,
        endDateInput: false,
      },
      focusedInput: props.range && props.range.scheduled_time ? null : START_DATE,
      startDate,
      endDate,
    };

    this.startDateInputRef = React.createRef();
    this.endDateInputRef = React.createRef();
  }

  componentDidMount() {
    document?.addEventListener('keydown', this._handleCalendarKeyPress);
  }

  componentWillUnmount() {
    document?.removeEventListener('keydown', this._handleCalendarKeyPress);
  }

  _handleCalendarKeyPress = (e) => {
    if (e.key === KEYMAP.ESCAPE) {
      this.props.setShowDatePicker(false);
    }
  };

  _onDatesChange = ({ startDate, endDate }) => {
    if (this.state.startDate && this.state.endDate) {
      this.setState({
        startDate: null,
        endDate: null,
        focusedInput: START_DATE,
      });
      return;
    }

    this.setState({
      startDate,
      endDate,
    });
  };

  _isOutsideRange = (day) => {
    const { category } = this.props;
    const dateLimit =
      category === 'history'
        ? moment().subtract(HISTORY_DEPTH_IN_DAYS, 'days')
        : moment().add(QUEUE_DEPTH_IN_DAYS, 'days');
    const isOutOfRange =
      category === 'history'
        ? day.isBefore(dateLimit) || day.isAfter(moment().endOf('day'))
        : day.isAfter(dateLimit) || day.isBefore(moment().startOf('day'));

    return isOutOfRange;
  };

  _clearDates = () => {
    this.setState({
      focusedInput: START_DATE,
      startDate: null,
      endDate: null,
    });
  };

  _applyDates = () => {
    const { startDate, endDate } = this.state;
    const { setRange } = this.props;
    if (endDate === null) return;

    const timeZone = moment.tz.guess() || 'America/Los_Angeles';
    const fromDate = moment.tz(startDate, timeZone).startOf('day').toISOString();
    const toDate = moment.tz(endDate, timeZone).endOf('day').toISOString();

    setRange({
      scheduled_time: {
        from: fromDate,
        to: toDate,
      },
    });
  };

  _getInputDate = (dateField) => {
    const momentDate = this.state[dateField];
    const isEditMode = this.state.editMode[`${dateField}Input`];

    if (!momentDate && !isEditMode) return '';

    return isEditMode ? this[`${dateField}InputRef`].current.value : momentDate.format(DATE_FORMAT);
  };

  _handleInputChange = (inputRef, inputStateName) => {
    this.setState({
      editMode: {
        ...this.state.editMode,
        [inputStateName]: true,
      },
    });
  };

  _getInputValue = (inputRef) => {
    return moment(inputRef.current.value, DATE_FORMAT);
  };

  _isInputValid = (inputRef, inputFieldName) => {
    const newValue = this._getInputValue(inputRef);
    if (!newValue.isValid()) return false;

    const isInRightOrder =
      inputFieldName === START_DATE
        ? this.state.endDate
          ? newValue.isBefore(this.state.endDate)
          : true
        : this.state.startDate
        ? newValue.isAfter(this.state.startDate)
        : false;

    return isInRightOrder && !this._isOutsideRange(newValue);
  };

  _updateInput = (inputRef, inputFieldName) => {
    this.setState({
      editMode: {
        startDateInput: false,
        endDateInput: false,
      },
      [inputFieldName]: this._isInputValid(inputRef, inputFieldName)
        ? this._getInputValue(inputRef)
        : this.state[inputFieldName],
    });
  };

  _handleKeyDown = (e, inputRef, inputFieldName) => {
    if (e.key === KEYMAP.ENTER) {
      this._updateInput(inputRef, inputFieldName);
    }
  };

  _onInputBlur = (inputRef, inputFieldName) => {
    this._updateInput(inputRef, inputFieldName);
  };

  _renderDayContents = (day) => {
    const { startDate, endDate } = this.state;
    const formattedDay = day.format('D');
    if (startDate && day.format('MM:DD:YYYY') === startDate.format('MM:DD:YYYY')) {
      return <div className={classes('start-date-selected')}>{formattedDay}</div>;
    }
    if (endDate && day.format('MM:DD:YYYY') === endDate.format('MM:DD:YYYY')) {
      return <div className={classes('end-date-selected')}>{formattedDay}</div>;
    }

    return formattedDay;
  };

  render() {
    const { category } = this.props;
    const { startDate, endDate, focusedInput } = this.state;

    return (
      <div className={classes()}>
        <p className={classes('header')}>Filter by Date Range</p>
        <DayPickerRangeController
          key={category}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={({ startDate, endDate }) => this._onDatesChange({ startDate, endDate })}
          renderDayContents={this._renderDayContents}
          focusedInput={focusedInput}
          hideKeyboardShortcutsPanel
          noBorder={true}
          onFocusChange={(focusedInput) => this.setState({ focusedInput })}
          numberOfMonths={1}
          isOutsideRange={this._isOutsideRange}
        />
        <div className={classes('inputs-container')}>
          <div className={classes('input-container')}>
            <label>Start</label>
            <input
              className={classes('input')}
              type={'text'}
              value={this._getInputDate(START_DATE)}
              ref={this.startDateInputRef}
              onKeyDown={(e) => this._handleKeyDown(e, this.startDateInputRef, START_DATE)}
              onBlur={() => this._onInputBlur(this.startDateInputRef, START_DATE)}
              onChange={() => this._handleInputChange(this.startDateInputRef, 'startDateInput')}
            />
          </div>
          <div className={classes('input-container')}>
            <label>End</label>
            <input
              className={classes('input')}
              type={'text'}
              value={this._getInputDate(END_DATE)}
              ref={this.endDateInputRef}
              onKeyDown={(e) => this._handleKeyDown(e, this.endDateInputRef, END_DATE)}
              onBlur={() => this._onInputBlur(this.endDateInputRef, END_DATE)}
              onChange={() => this._handleInputChange(this.endDateInputRef, 'endDateInput')}
            />
          </div>
        </div>
        <div className={classes('button-container')}>
          <button className={classes('button-clear')} onClick={this._clearDates}>
            Clear
          </button>
          <button
            className={classes('button-apply')}
            onClick={this._applyDates}
            onKeyDown={(e) => {
              if (e.key === 'Tab') {
                e.preventDefault();
                const calendarContainer = document.querySelector(
                  '.tc-ScheduledMessagesView__inner-container'
                );
                const firstButton = calendarContainer.querySelector('.DayPickerNavigation_button');
                firstButton.focus();
              }
            }}
          >
            Apply
          </button>
        </div>
      </div>
    );
  }
}

ScheduledMessagesDateRangePicker.propTypes = {
  category: PropTypes.string.isRequired,
  range: PropTypes.object,
  setRange: PropTypes.func.isRequired,
  setShowDatePicker: PropTypes.func,
};

export default ScheduledMessagesDateRangePicker;
