import React from 'react';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { User as FullUser } from '../../../admin/types';
import UserAvatarNoPresence from './UserAvatarNoPresence';
import UserAvatarPresence from './UserAvatarPresence';

type Role = {
  $entityType: string;
  description: string;
  displayName: string;
  id: string;
  memberIds: string[];
  organizationId: string;
  tagId: string;
};

export type User = Pick<
  FullUser,
  | '$notFound'
  | '$placeholder'
  | 'avatarUrl'
  | 'dnd'
  | 'firstName'
  | 'isDisabled'
  | 'isPatient'
  | 'isPatientContact'
  | 'isVisitor'
  | 'lastName'
  | 'patient'
  | 'patientContact'
  | 'presenceStatus'
  | 'removedFromOrg'
> &
  Partial<Pick<FullUser, 'id' | 'displayName'>> & {
    profileByOrganizationId?: Record<
      string,
      {
        autoForwardDisplayNames?: string[];
        autoForwardReceivers?: { displayName: string }[];
        autoForwardReceiverIds: string[];
        dndAutoForwardEntities: string[];
      }
    >;
  };

interface UserAvatarProps {
  avatarUrl?: string;
  className?: string;
  disableInitials?: boolean;
  displayName?: string;
  forceActiveIndicator?: boolean;
  indicatorSize?: string;
  inverse?: boolean;
  showDndAfStatus?: boolean;
  isMuted?: boolean;
  isSearch?: boolean;
  role?: Role;
  shouldUseMinWidth?: boolean;
  showPresenceIndicator?: boolean;
  showRoleIndicator?: boolean;
  size?: string | number;
  user?: User;
}

type MobxProps = {
  currentOrganizationId: string;
  isAvailabilityFeatureFlagEnabled: boolean;
  isExtendedAutoForwardOptionsEnabled: boolean;
  isPresenceFeatureFlagEnabled: boolean;
};

function UserAvatar({
  avatarUrl,
  className,
  currentOrganizationId,
  disableInitials,
  displayName,
  indicatorSize,
  inverse,
  isMuted = false,
  isAvailabilityFeatureFlagEnabled = false,
  isExtendedAutoForwardOptionsEnabled = false,
  isPresenceFeatureFlagEnabled = false,
  isSearch = false,
  role,
  shouldUseMinWidth,
  showDndAfStatus,
  showPresenceIndicator,
  showRoleIndicator,
  size = 32,
  user,
}: MobxProps & UserAvatarProps) {
  if (!user) return null;
  return (
    <>
      {isPresenceFeatureFlagEnabled || isAvailabilityFeatureFlagEnabled ? (
        <UserAvatarPresence
          avatarUrl={avatarUrl}
          className={className}
          currentOrganizationId={currentOrganizationId}
          disableInitials={disableInitials}
          displayName={displayName}
          indicatorSize={indicatorSize}
          isExtendedAutoForwardOptionsEnabled={isExtendedAutoForwardOptionsEnabled}
          inverse={inverse}
          isMuted={isMuted}
          isSearch={isSearch}
          role={role}
          shouldUseMinWidth={shouldUseMinWidth}
          showDndAfStatus={showDndAfStatus}
          showPresenceIndicator={showPresenceIndicator}
          showRoleIndicator={showRoleIndicator}
          size={size}
          user={user}
        />
      ) : (
        <UserAvatarNoPresence
          avatarUrl={avatarUrl}
          className={className}
          currentOrganizationId={currentOrganizationId}
          disableInitials={disableInitials}
          displayName={displayName}
          indicatorSize={indicatorSize}
          inverse={inverse}
          isExtendedAutoForwardOptionsEnabled={isExtendedAutoForwardOptionsEnabled}
          isMuted={isMuted}
          role={role}
          shouldUseMinWidth={shouldUseMinWidth}
          showDndAfStatus={showDndAfStatus}
          showPresenceIndicator={showPresenceIndicator}
          showRoleIndicator={showRoleIndicator}
          size={size}
          user={user}
        />
      )}
    </>
  );
}

export default mobxInjectSelect<UserAvatarProps, MobxProps>({
  messengerStore: [
    'currentOrganizationId',
    'isAvailabilityFeatureFlagEnabled',
    'isExtendedAutoForwardOptionsEnabled',
    'isPresenceFeatureFlagEnabled',
  ],
})(UserAvatar);
