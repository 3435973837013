import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions, thunk, useAppSelector, ReduxState } from 'redux-stores';
import type { LaunchData } from 'stores/LaunchStore';

const { setSelectedPatientReference } = actions;
const { searchPatientContextsThunk } = thunk;

export function useLaunchPCM(launchData: LaunchData, composeNewMessage: () => void) {
  const dispatch = useDispatch();

  const { patientReferenceSearchList, patientReferenceSearchLoading, selectedPatientReference } =
    useAppSelector((state: ReduxState) => state.patientContext);
  const orgId = useAppSelector((state: ReduxState) => state.session.currentOrganizationId);

  useEffect(() => {
    if (orgId && launchData && launchData.type === 'pcm') {
      dispatch(searchPatientContextsThunk({ query: launchData.data.mrn, from: 0 }));
    }
  }, [orgId, launchData, dispatch]);

  useEffect(() => {
    if (
      !patientReferenceSearchLoading &&
      patientReferenceSearchList &&
      patientReferenceSearchList.length > 0
    ) {
      const [patient] = patientReferenceSearchList;
      dispatch(setSelectedPatientReference(patient));
    }
  }, [patientReferenceSearchList, patientReferenceSearchLoading, dispatch]);

  useEffect(() => {
    if (selectedPatientReference) composeNewMessage();
  }, [selectedPatientReference, composeNewMessage]);

  return { loading: patientReferenceSearchLoading };
}
