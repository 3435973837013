import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { MessageTemplateRepositories } from '../../../../models/enums/MessageTemplateRepositories';
import BEM from '../../../bem';
import propTypes from '../../../propTypes';
import scheduledMessageFormats from '../../../utils/scheduledMessageFormats';
import { UserName } from '../../';

import { ReactComponent as CheckMarkSvg } from '../../../images/green-checkmark-full.svg';
import { KEYMAP } from 'common/constants';
const { PERSONAL } = MessageTemplateRepositories;
const { DATE_FORMAT } = scheduledMessageFormats;
const classes = BEM.with('TemplatesRow');

const TemplatesRow = ({
  allowPatientDeliveryMethod,
  createdBy,
  createdOn,
  isSmsCompatible = false,
  id,
  isAdmin,
  isSelected,
  onClick,
  repository,
  title,
  toggleSelected,
  updatedBy,
  updatedOn,
}) => (
  <div
    className={classes('', {
      selected: isSelected,
      noCheckbox: !(repository === 'personal' || isAdmin),
    })}
    data-test-id={`template-${title}-row`}
    onClick={() => onClick(id)}
    aria-label="Edit Template"
    role="row"
    onKeyDown={(e) => {
      if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
        onClick(id);
      }
    }}
  >
    {(repository === 'personal' || isAdmin) && (
      <div
        className={classes('list-item', {
          checkbox: true,
        })}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) e.stopPropagation();
        }}
        role="cell"
        aria-label="Select this item"
      >
        <button
          onClick={(e) => {
            e.stopPropagation();
            toggleSelected(id);
          }}
          className={classes('list-item-checkbox-default', {
            selected: isSelected,
          })}
          data-test-id={`template-${title}-checkbox`}
          aria-checked={isSelected}
          role="checkbox"
        >
          {isSelected && <CheckMarkSvg aria-hidden />}
        </button>
      </div>
    )}
    <div className={classes('list-item', { three: true })} role="cell">
      <div className={classes('template-title')} title={title}>
        {title}
      </div>
    </div>
    {allowPatientDeliveryMethod && (
      <div className={classes('list-item')} role="cell">
        {isSmsCompatible ? 'SMS' : 'Secure Message'}
      </div>
    )}
    {repository !== PERSONAL && (
      <div className={classes('list-item')} role="cell">
        <UserName user={createdBy} />
      </div>
    )}
    <div className={classes('list-item')} role="cell">
      {moment(createdOn).format(DATE_FORMAT)}
    </div>
    <div className={classes('list-item')} role="cell">
      <div>
        {moment(updatedOn).format(DATE_FORMAT)}
        {repository !== PERSONAL && (
          <div
            className={classes('list-item-updated-by', {
              selected: isSelected,
            })}
          >
            By <UserName user={updatedBy} />
          </div>
        )}
      </div>
    </div>
  </div>
);

TemplatesRow.propTypes = {
  createdBy: propTypes.user.isRequired,
  createdOn: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isSmsCompatible: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  updatedBy: propTypes.user.isRequired,
  updatedOn: PropTypes.string.isRequired,
};

export default TemplatesRow;
