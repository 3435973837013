import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PagerNumber, Role } from '../../../../types';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import BEM from '../../../bem';
import { MapStateToProps, reduxInjectSelect } from '../../../utils/reduxInjectSelect';
import { BasicModal, DotsIndicator } from '../..';

const { setAreaCodeError, setModal, setPagerError, setPagerNumbers } = actions;
const { assignPagerNumber, getPagerNumbers } = thunk;
const classes = BEM.with('CollaborationModal');

const reduxSelectors = {
  session: ['openModal'],
  collab: ['selectedOrgId'],
  entities: ['entitiesById', 'selectedEntity'],
  roles: ['isAreaCodeError', 'isGettingPagerNumbers', 'isPagerError', 'pagerNumbers'],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type PagerModalProps = {
  isOpen: boolean;
};

function PagerModal({
  entitiesById,
  isAreaCodeError,
  isGettingPagerNumbers,
  isPagerError,
  isOpen,
  pagerNumbers: numbers,
  selectedOrgId,
  selectedEntity,
}: ReduxProps & PagerModalProps) {
  const dispatch = useDispatch();
  const [draft, setDraft] = useState('');
  const [selectedNumber, setSelectedNumber] = useState<string | null>(null);
  const selectedRole = selectedEntity as Role;

  const closeModal = () => {
    dispatch(setModal(undefined));
    checkErrors();
    setDraft('');
    setSelectedNumber(null);
    dispatch(setPagerNumbers([]));
  };

  const checkErrors = useCallback(() => {
    isAreaCodeError && dispatch(setAreaCodeError(false));
    isPagerError && dispatch(setPagerError(false));
  }, [dispatch, isAreaCodeError, isPagerError]);

  const fetchPagerNumbers = () => {
    setSelectedNumber(null);
    if (draft.length === 3 && /^\d+$/.test(draft)) {
      getPagerNumbers(dispatch, draft, selectedOrgId);
      checkErrors();
    } else {
      dispatch(setPagerNumbers([]));
      dispatch(setAreaCodeError(true));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDraft(e.target.value);
  };

  const selectNumber = (number: string) => {
    setSelectedNumber(number);
  };

  const assignNumber = async () => {
    if (selectedNumber) {
      await assignPagerNumber(dispatch, {
        pagerNumber: selectedNumber,
        roleId: selectedRole.id,
        organizationId: selectedOrgId,
        selectedRole: selectedRole,
        entitiesById,
      });
      closeModal();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { keyCode } = e;
    if (keyCode === 13) {
      fetchPagerNumbers();
    }
  };

  return (
    <BasicModal
      ariaLabelBody={'TigerPage number Info'}
      ariaLabelCancelButton={'TigerPage number Cancel'}
      ariaLabelCloseButton={'TigerPage number Close'}
      ariaLabelHeader={'TigerPage number Header'}
      ariaLabelSubmitButton={'Confirm TigerPage number'}
      headerText={'Set a TigerPage number'}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={assignNumber}
      size={'medium'}
      theme={'normal'}
      submitDisabled={!selectedNumber || !selectedRole?.id}
      submitText={'ASSIGN'}
      hasCloseButton={true}
      useWCL
    >
      <div className={classes('')}>
        Create and assign a unique pager number for this role.
        <div className={classes('error-message-container')}>
          {(isAreaCodeError || isPagerError) && (
            <div className={classes('error-message')}>
              {isAreaCodeError && 'Area code not valid'}
              {isPagerError && 'Oops, something went wrong, please try again.'}
            </div>
          )}
        </div>
        <div className={classes('pager-container')}>
          <div className={classes('table-header')}>
            <span>Choose your preferred area code</span>
          </div>
          <div className={classes('input-container')}>
            <div className={classes('number')}>
              +1
              <input
                data-test-id="tiger page area code number"
                className={classes('input-number', {
                  error: isAreaCodeError,
                })}
                maxLength={3}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Area Code"
                type="text"
                value={draft}
              />
              {selectedNumber ? (
                <div className={classes('selected-number-preview')}>
                  {`-${selectedNumber.substring(5, 8)}-${selectedNumber.substring(8, 12)}`}
                </div>
              ) : (
                <div className={classes('end-number')}>-###-####</div>
              )}
            </div>
            <div
              data-test-id="find optional numbers"
              className={classes('find-button')}
              onClick={fetchPagerNumbers}
            >
              {isGettingPagerNumbers ? <DotsIndicator color="#fff" size={4} /> : 'Find'}
            </div>
          </div>
          {numbers.length > 0 && (
            <div className={classes('numbers-container')} data-test-id="pager numbers container">
              {numbers.map((number: PagerNumber, idx: number) => (
                <div
                  data-test-id={`select ${number.phone_number} as pager`}
                  className={classes('selected-preview-item-number', {
                    altBGNumbers: idx % 2 !== 0,
                    normalBGNumbers: !(idx % 2 !== 0),
                    selectedNumber: selectedNumber === number.phone_number,
                  })}
                  key={number.phone_number}
                  onClick={() => selectNumber(number.phone_number)}
                >
                  <div className={classes('selected-preview-number')}>{number.friendly_name}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </BasicModal>
  );
}

export default reduxInjectSelect<PagerModalProps, ReduxProps, ReduxState>(reduxSelectors)(
  PagerModal
);
