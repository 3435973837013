import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BEM from '../bem';

import { MessageItem } from './MessageItem';

const classes = BEM.with('PreviewMessages');

class PreviewMessages extends Component {
  static propTypes = {
    messages: PropTypes.array.isRequired,
  };

  render() {
    const { messages } = this.props;

    return (
      <div className={classes()}>
        {messages.map((message) => (
          <div className={classes('message-container')} key={message.sortNumber}>
            <div className={classes('message')}>
              <MessageItem
                isModal={true}
                message={message}
                showEscalation={false}
                standalone={true}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default observer(PreviewMessages);
