import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import TCClient from '../../../../client';
import { DotsIndicator } from '../../../../common/components';
import Modal from '../../../../common/components/Modal';
import { Input } from '../../../shared-components/Input/Input';
import Dropdown from '../../../../common/components/Dropdown';
import { ReactComponent as CloseIcon } from '../../../../common/images/modal-cancel.svg';
import { Unit } from '../../../types';

import styles from './styles.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  mode:
    | { type: 'create'; data: { parentAreaId: string } }
    | {
        type: 'edit' | 'delete';
        data: {
          roomName: string;
          roomId: string;
        };
      };
  openParent: (id: string, open: boolean) => void;
};

export const RoomModal = ({ isOpen, mode, onClose, openParent }: Props) => {
  const dispatch = useAppDispatch();
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);

  const isDeleteMode = mode.type === 'delete';
  const isEditMode = mode.type === 'edit';
  const isCreateMode = mode.type === 'create';

  const _roomName = isEditMode ? mode.data.roomName : '';
  const [roomName, setRoomName] = useState(_roomName);
  const [initialUnitName, setInitialUnitName] = useState('');
  const [unitName, setUnitName] = useState('');
  const [unitId, setUnitId] = useState('');
  const [units, setUnits] = useState<Unit[] | undefined>([]);
  const [unitDropdownOpen, setUnitDropdownOpen] = useState(false);

  const header = isEditMode ? `Edit Room` : isDeleteMode ? `Delete Room` : `Create new Room`;

  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const [buttonHasFocus, setButtonHasFocus] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleMouseEnter = () => setButtonHasFocus(true);
  const handleMouseLeave = () => setButtonHasFocus(false);

  let formHasChanged = false;
  if (isEditMode) {
    formHasChanged = mode.data.roomName !== roomName || initialUnitName !== unitName;
  } else {
    formHasChanged = roomName !== '';
  }

  useEffect(() => {
    let ref: HTMLButtonElement | null = null;

    buttonRef.current?.addEventListener('mouseenter', handleMouseEnter);
    buttonRef.current?.addEventListener('mouseleave', handleMouseLeave);

    ref = buttonRef.current;

    return () => {
      if (ref) {
        ref.removeEventListener('mouseenter', handleMouseEnter);
        ref.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  });

  useEffect(() => {
    const getUnits = async () => {
      const summary = await TCClient.multiOrg.getOrganizationLocations(selectedOrganizationId);
      if (isEditMode) {
        const facility = summary.find((facility) =>
          facility.areas?.find((area) =>
            area.rooms?.find((room) => room.roomId === mode.data.roomId)
          )
        );
        const units =
          facility?.units &&
          facility.units.map((unit) => {
            const { rooms, unitDisplayName, unitId } = unit;
            if (rooms)
              unit.rooms.forEach((room) => {
                if (room.roomId === mode.data.roomId) {
                  setUnitName(unit.unitDisplayName);
                  setInitialUnitName(unit.unitDisplayName);
                  setUnitId(unit.unitId);
                }
              });
            return {
              facilityId: facility.facilityId,
              facilityName: facility.facilityDisplayName,
              unitDisplayName,
              unitId,
            };
          });
        if (facility && facility.units) setUnits(units);
      }
    };
    getUnits();
  }, [isEditMode, mode.data, selectedOrganizationId]);

  const close = () => {
    setRoomName(_roomName);
    setUnitName('');
    setUnitId('');
    setError('');
    onClose();
  };

  return (
    <Modal
      bodyClass={styles.body}
      closeClass={isDeleteMode ? styles.close : ''}
      headerClass={isDeleteMode ? styles.header : ''}
      header={header}
      isOpen={isOpen}
      size={'medium'}
      onRequestClose={close}
    >
      <>
        {(isEditMode || isCreateMode) && (
          <div>
            <div className={styles.errorContainer}>
              {error.length > 0 && <div className={styles.error}>{error}</div>}
            </div>
            <div className={styles.inputContainer}>
              <span className={styles.inputLabel}>Room Name</span>
              <Input
                customCSS={styles.input}
                lengthLimit={100}
                onInputChange={(e) => setRoomName(e.target.value)}
                placeholder={`Type a room name...`}
                value={roomName}
              />
            </div>
          </div>
        )}
        {isDeleteMode && (
          <div className={styles.bodyText}>
            <p>
              Are you sure you want to remove{' '}
              <span className={styles.bold}>{mode.data.roomName}?</span>
            </p>
          </div>
        )}
        {isEditMode && (
          <div className={styles.inputContainer}>
            <span className={styles.inputLabel}>Unit</span>
            <div onClick={() => setUnitDropdownOpen(true)} className={styles.selectorContainer}>
              {unitName ? (
                <div className={styles.selectorItem}>
                  <span className={styles.selectorItemLabel}>{unitName}</span>
                  <button
                    className={styles.clearButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      setUnitName('');
                    }}
                  >
                    <CloseIcon className={styles.iconSecondary} />
                  </button>
                </div>
              ) : (
                <div className={styles.selectorItem}>-No unit selected-</div>
              )}
            </div>
            {unitDropdownOpen && (
              <div className={styles.dropdown}>
                <Dropdown
                  ariaLabel={'unit-selector'}
                  triggerHandler={() => setUnitDropdownOpen(false)}
                >
                  <div className={styles.listContainer}>
                    <div
                      key="noUnitSelected"
                      className={styles.listOption}
                      onClick={() => {
                        setUnitName('');
                        setUnitId('');
                        setUnitDropdownOpen(false);
                      }}
                      data-test-id="clear unit"
                    >
                      -No unit selected-
                    </div>
                    {units?.map((u) => (
                      <div
                        className={classNames(
                          styles.listOption,
                          unitName === u.unitDisplayName ? styles.selectedListOption : ''
                        )}
                        key={u.unitId}
                        data-test-id={`select unit ${u}`}
                        onClick={() => {
                          setUnitName(u.unitDisplayName);
                          setUnitId(u.unitId);
                          setUnitDropdownOpen(false);
                        }}
                      >
                        {u.unitDisplayName}
                      </div>
                    ))}
                  </div>
                </Dropdown>
              </div>
            )}
          </div>
        )}
        <div className={styles.footer}>
          <div className={styles.footerLeft}></div>
          <div className={styles.footerRight}>
            <button
              aria-label="Cancel"
              className={styles.cancelButton}
              onClick={close}
              type="button"
            >
              Cancel
            </button>
            <button
              ref={buttonRef}
              disabled={!isDeleteMode ? !formHasChanged : false}
              aria-label={isDeleteMode ? 'Delete' : 'Save'}
              className={isDeleteMode ? styles.deleteButton : styles.saveButton}
              onClick={async () => {
                try {
                  setIsSaving(true);
                  if (isEditMode) {
                    if (unitId && unitName) {
                      const { rooms } = await TCClient.multiOrg.getUnit({
                        orgId: selectedOrganizationId,
                        unitId: unitId,
                      });
                      let roomIds = rooms.map((r) => r.roomId);
                      if (!roomIds.includes(mode.data.roomId)) {
                        roomIds = roomIds.concat(mode.data.roomId);
                      }
                      await TCClient.multiOrg.updateUnit({
                        unitName: unitName,
                        unitId: unitId,
                        orgId: selectedOrganizationId,
                        roomIds: roomIds,
                      });
                    }
                    await TCClient.multiOrg.updateRoom({
                      id: mode.data.roomId,
                      orgId: selectedOrganizationId,
                      roomName: roomName,
                    });
                  } else if (isCreateMode) {
                    await TCClient.multiOrg.createRoom({
                      orgId: selectedOrganizationId,
                      parentAreaId: mode.data.parentAreaId,
                      roomName,
                    });
                    openParent(mode.data.parentAreaId, false);
                  } else {
                    await TCClient.multiOrg.deleteRoom({
                      id: mode.data.roomId,
                      orgId: selectedOrganizationId,
                    });
                  }
                  setIsSaving(false);
                  dispatch(actions.setLocationsDataDirty({ isLocationsDataDirty: true }));
                  setRoomName(roomName);
                  setUnitName('');
                  setUnitId('');
                  setError('');
                  close();
                } catch (e) {
                  if (
                    e.text &&
                    typeof e.text === 'string' &&
                    e.text.includes('Invalid input for name.')
                  ) {
                    setError(`This Room name is already in use`);
                  } else {
                    setError('Oops! Something went wrong, please try again');
                  }
                  setIsSaving(false);
                }
              }}
              type="button"
            >
              {isSaving ? (
                <DotsIndicator color={buttonHasFocus ? '#fff' : '#4580d8'} size={10} speed={0.5} />
              ) : isDeleteMode ? (
                'Delete'
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};
