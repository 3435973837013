import { UAParser } from 'ua-parser-js';

let parser = new UAParser();
let result = parser.getResult();

export const parseUserAgent = (uaString?: string) => {
  if (uaString) {
    parser = new UAParser(uaString);
    result = parser.getResult();
  }

  const {
    browser: { name: browserName = '', version: browserVersion = '' },
    device: { vendor: deviceName = '' },
    os: { name: osName = '', version: osVersion = '' },
  } = result;

  return { browserName, browserVersion, deviceName, osName, osVersion };
};
