import createMobxModel from './createMobxModel';

const PatientContact = createMobxModel({
  name: 'patientContact',

  fields: [
    'dob',
    'editVersion',
    'gender',
    'mrn',
    'organizationId',
    'phoneNumber',
    'relation',
    'smsOptedOut',
  ],

  relations: {
    one: {
      patient: { type: 'patient' },
      user: { type: 'user' },
    },
    many: {
      sharedConversations: { type: 'conversation' },
    },
  },
});

export default PatientContact;
