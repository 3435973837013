import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GROUP_MEMBER_SEARCH_TYPES } from '../../../models/enums/SearchTypes';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { RecipientSearchPicker, Scrollbars } from '../';
import Modal from '../Modal';
import MemberCard from './MemberCard';

const classes = BEM.with('AddCareTeamModal');

class AddCareTeamModal extends Component {
  static propTypes = {
    addedMembers: propTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
    closeModal: PropTypes.func.isRequired,
    currentConversation: propTypes.conversation,
    currentOrganization: propTypes.organization.isRequired,
    getUserById: PropTypes.func.isRequired,
    group: propTypes.group,
    initializeGroupEditor: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    prepareAddMember: PropTypes.func.isRequired,
    prepareRemoveMember: PropTypes.func.isRequired,
    resetGroupEditor: PropTypes.func.isRequired,
    saveMembers: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this._onAfterOpen();
  }

  render() {
    const { currentOrganization, getUserById, group, isOpen } = this.props;
    let { addedMembers } = this.props;

    if (!group || !group.memberIds) return null;

    addedMembers = addedMembers.map((memberId) => {
      const user = getUserById(memberId);

      return user.isRoleBot && user.botRole ? user.botRole : user;
    });

    const excludeIds = [...group.memberIds];
    let memberListFragment;
    if (addedMembers.length > 0) {
      const selectedMembersFragment = addedMembers.map((member) => (
        <MemberCard key={member.id} member={member} removeMember={this._removeMember} />
      ));

      excludeIds.push(...addedMembers.map(({ id }) => id));

      if (addedMembers.length > 2) {
        memberListFragment = (
          <div className={classes('member-list', { overflow: true })}>
            <Scrollbars autoHide={true}>{selectedMembersFragment}</Scrollbars>
          </div>
        );
      } else {
        memberListFragment = (
          <div className={classes('member-list')}>{selectedMembersFragment}</div>
        );
      }
    }

    return (
      <Modal
        bodyClass={classes('body', {
          membersSelected: addedMembers.length > 0,
        })}
        className={classes()}
        contentRef={this._setModal}
        footerClass={classes('footer')}
        footerPrimaryActions={
          <button className={classes('add-button')} onClick={this._addMembers} type="button">
            ADD
          </button>
        }
        footerSecondaryActions={
          <button className={classes('cancel-button')} onClick={this._closeModal} type="button">
            CANCEL
          </button>
        }
        hasCloseButton={true}
        header={'Add care team members for this patient'}
        headerClass={classes('header')}
        isOpen={isOpen}
        onAfterOpen={this._onAfterOpen}
        onRequestClose={this._closeModal}
      >
        <div className={classes('members-row')}>
          <span className={classes('row-header')}>Care team member:</span>
          <RecipientSearchPicker
            className="Add-care-team-modal"
            excludeIds={excludeIds}
            excludeIntraTeams={true}
            excludeTeams={true}
            multi
            shouldShowSelectedValues={false}
            onBlur={this._onBlur}
            onChange={this._onMemberSelected}
            organization={currentOrganization}
            placeholder={'- Add a care team member -'}
            openPlaceholder={'Search'}
            reposition={false}
            searchTypes={GROUP_MEMBER_SEARCH_TYPES}
            showCloseButton
            showLoadingSpinner={false}
            tabIndex={1}
          />
        </div>
        {memberListFragment}
      </Modal>
    );
  }

  _addMembers = async () => {
    const { saveMembers } = this.props;

    this._closeModal({ resetState: false });
    saveMembers();
  };

  _closeModal = ({ resetState = true } = {}) => {
    const { closeModal, resetGroupEditor } = this.props;

    if (resetState) {
      resetGroupEditor();
    }

    closeModal();
  };

  _onBlur = () => {
    this.modal.focus();
  };

  _onAfterOpen = () => {
    const { currentConversation, group: previousGroup, initializeGroupEditor } = this.props;

    const isGroup = !!currentConversation && currentConversation.counterPartyType === 'group';
    if (!isGroup) return;

    const currentGroup = currentConversation.counterParty;
    if (!previousGroup || previousGroup.id !== currentGroup.id) {
      initializeGroupEditor({ group: currentGroup });
    }
  };

  _onMemberSelected = (members) => {
    const { prepareAddMember } = this.props;
    if (!members) return;
    members.forEach((member) => {
      const memberId = member.$entityType === 'role' ? member.botUserId : member.id;

      prepareAddMember(memberId);
    });
  };

  _removeMember = (memberId) => {
    const { prepareRemoveMember } = this.props;
    memberId = memberId.replace('role:', '');

    prepareRemoveMember(memberId);
  };

  _setModal = (ref) => {
    this.modal = ref;
  };
}

export default mobxInjectSelect({
  conversationStore: ['currentConversation'],
  groupEditorStore: [
    'addedMembers',
    'group',
    'initializeGroupEditor',
    'prepareAddMember',
    'prepareRemoveMember',
    'resetGroupEditor',
    'saveMembers',
  ],
  messengerStore: ['currentOrganization'],
  userStore: ['getUserById'],
})(AddCareTeamModal);
