import React from 'react';
import { observer } from 'mobx-react';
import { userPresenceReadableLabel } from '../../utils/readableLabels';
import BEM from '../../bem';

const classes = BEM.with('PresenceIndicatorUnavailable');

type PresenceIndicatorUnavailableProps = {
  size: string;
};

const PresenceIndicatorUnavailable = ({ size = 'MEDIUM' }: PresenceIndicatorUnavailableProps) => {
  return (
    <div title={userPresenceReadableLabel('UNAVAILABLE')}>
      <svg
        className={classes({ size })}
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.75"
          y="0.75"
          width="6.5"
          height="6.5"
          rx="3.25"
          stroke="#B2B4B7"
          strokeWidth="1.5"
        />
      </svg>
    </div>
  );
};

export default observer(PresenceIndicatorUnavailable);
