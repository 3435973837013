// @ts-nocheck
export default function (store, { host }) {
  const Appointment = store.defineModel('appointment', {
    replaceKeys: {
      appointmentId: 'id',
    },

    transientAttrs: ['$deleted'],

    defaultValues: {},

    parseAttrs(attrs) {
      return attrs;
    },
  });

  return Appointment;
}
