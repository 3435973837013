import React from 'react';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import { ReactComponent as AllAlertsCompletedSvg } from '../../images/alert.svg';

const classes = BEM.with('YoureAllCaughtUp');

type YoureAllCaughtUpProps = {};

type MobxProps = {
  setAlertsFilter: (conversationItem: string) => void;
};

function YoureAllCaughtUp({ setAlertsFilter }: YoureAllCaughtUpProps & MobxProps) {
  return (
    <div className={classes()}>
      <div className={classes('all-caught-up-image')}>
        <AllAlertsCompletedSvg />
      </div>
      <div className={classes('all-caught-up-text')}>You're All Caught Up</div>
      <div
        className={classes('view-history')}
        onClick={() => {
          setAlertsFilter('History');
        }}
      >
        View History
      </div>
    </div>
  );
}

export default mobxInjectSelect<YoureAllCaughtUpProps, MobxProps>({
  rosterStore: ['setAlertsFilter'],
})(YoureAllCaughtUp);
