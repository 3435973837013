import React from 'react';

import classNames from 'classnames';
import styles from './PasswordToolTip.module.css';

const passwordRequirements = [
  {
    label: 'A lowercase',
    labelState: false,
  },
  {
    label: 'An uppercase',
    labelState: false,
  },
  {
    label: 'A number',
    labelState: false,
  },
  {
    label: 'A special character: e.g. ! @ # $ % ^ etc.',
    labelState: false,
  },
  {
    label: 'Minimum of 8 characters',
    labelState: false,
  },
];

export interface PasswordToolTipProps {
  value: string;
}

const MAX_LENGTH = 8;

export const PasswordToolTip: React.FC<PasswordToolTipProps> = ({ value }) => {
  const validatePassword = (value: string) => {
    passwordRequirements[0].labelState = /[a-z]/.test(value);

    passwordRequirements[1].labelState = /[A-Z]/.test(value);

    passwordRequirements[2].labelState = /\d/.test(value);

    passwordRequirements[3].labelState = /\W/.test(value);

    passwordRequirements[4].labelState = value.length >= MAX_LENGTH;

    return passwordRequirements;
  };

  return (
    <div className={styles.root}>
      <div className={styles.passwordToolTipList}>
        {validatePassword(value).map(({ label, labelState }) => (
          <span
            className={classNames(
              styles.passwordToolTipText,
              value === '' ? styles.default : !labelState ? styles.error : styles.pass
            )}
          >
            {label}
          </span>
        ))}
      </div>
    </div>
  );
};
