import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BEM from '../../bem';

const classes = BEM.with('MessageFormIndicator');

class MessageFormIndicator extends Component {
  static propTypes = {
    alwaysEscalate: PropTypes.bool,
    isEscalated: PropTypes.bool.isRequired,
    isPriority: PropTypes.bool.isRequired,
  };

  render() {
    const { alwaysEscalate, isEscalated, isPriority } = this.props;
    let indicatorFragment;

    if (alwaysEscalate) {
      indicatorFragment = (
        <div
          aria-label="Message Auto Escalation Indicator"
          className={classes('feature', { alwaysEscalate })}
        >
          Escalation Auto-Enabled by Admin
        </div>
      );
    } else if (isEscalated) {
      indicatorFragment = (
        <div
          aria-label="Message Escalation Indicator"
          className={classes('feature', { isEscalated })}
        >
          Escalation Enabled
        </div>
      );
    } else if (isPriority) {
      indicatorFragment = (
        <div aria-label="Message Priority Indicator" className={classes('feature', { isPriority })}>
          Priority Message: On
        </div>
      );
    } else {
      return null;
    }

    return <div className={classes()}>{indicatorFragment}</div>;
  }
}

export default observer(MessageFormIndicator);
