import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BEM from '../../../../common/bem';
import { ReactComponent as CloseButtonSvg } from '../../../../common/images/close-icon.svg';
import { EntityName, EntityAvatar } from '../../../../common/components';
import { PatientInfo } from '../ConversationPane';

const classes = BEM.with('ComposePatientConversationItem');

class ComposePatientConversationItem extends Component {
  static propTypes = {
    stopComposing: PropTypes.func,
  };

  _onMouseEnter = () => {
    this.setState({ isTileHovered: true });
  };

  _onMouseLeave = () => {
    this.setState({ isTileHovered: false });
  };

  render() {
    const { entity, stopComposing } = this.props;

    let relationFragment;
    if (entity.isPatient) {
      relationFragment = <span className={classes('patient-name')}>(Patient)</span>;
    } else if (entity.isPatientContact) {
      relationFragment = (
        <span className={classes('relation-name')}>({entity.patientContact.relation})</span>
      );
    }

    return (
      <div className={classes()}>
        <div className={classes('left-pane')}>
          <div className={classes('avatar')}>
            <EntityAvatar entity={entity} size="fit" />
          </div>
        </div>
        <div className={classes('details')}>
          <div className={classes('row')}>
            <span aria-label="Roster Tile Name" className={classes('display-name')}>
              <EntityName entity={entity} />
              {relationFragment}
            </span>
          </div>
          <button className={classes('close-button-container')} onClick={stopComposing}>
            <CloseButtonSvg className={classes('close-button')} aria-hidden />
          </button>
          <div className={classes('row')}>
            <div className={classes('patient-info')}>
              <PatientInfo entity={entity} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(ComposePatientConversationItem);
