import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { once } from 'ramda';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { Modal } from './';

const classes = BEM.with('DeleteConversationModal');

class MessageDeleteModal extends Component {
  static propTypes = {
    deleteMessagesLocally: PropTypes.func.isRequired,
    hideDeleteModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    options: PropTypes.shape({
      messages: PropTypes.array.isRequired,
    }),
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        ariaLabelCloseButton="Delete Message Close"
        ariaLabelHeader="Delete Message Header"
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('close-button')}
        headerClass={classes('header')}
        header="Delete Messages"
        isOpen={isOpen}
        onRequestClose={this._onRequestClose}
        size={'medium-small'}
        footerPrimaryActions={
          <button
            aria-label="Delete Message Delete"
            className={classes('delete-btn')}
            onClick={once(this._deleteMessage)}
            type="button"
          >
            DELETE
          </button>
        }
        footerSecondaryActions={
          <button
            aria-label="Delete Message Cancel"
            className={classes('cancel-btn')}
            onClick={this._onRequestClose}
            type="button"
          >
            CANCEL
          </button>
        }
      >
        <div className={classes('info')}>
          Messages will only be deleted from this device or browser.
        </div>
      </Modal>
    );
  }

  _deleteMessage = () => {
    const {
      deleteMessagesLocally,
      options: { messages },
    } = this.props;
    deleteMessagesLocally(messages);
    this._onRequestFinish();
  };

  _onRequestFinish = () => {
    const { hideMessageMultiSelect } = this.props;
    this._onRequestClose();
    hideMessageMultiSelect();
  };

  _onRequestClose = () => {
    const { closeModal } = this.props;
    closeModal();
  };
}

export default mobxInjectSelect({
  messageStore: ['deleteMessagesLocally'],
  messengerStore: ['hideMessageMultiSelect'],
})(MessageDeleteModal);
