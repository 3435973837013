import React, { Component } from 'react';
import classNames from 'classnames';
import BEM from '../bem';
import propTypes from '../propTypes';
import { messageSenderStatusToReadableLabel } from '../utils/readableLabels';
import { mobxInjectSelect } from 'common/utils';

const classes = BEM.with('MessageSenderStatus');

class MessageSenderStatus extends Component {
  static propTypes = {
    message: propTypes.message.isRequired,
  };

  render() {
    const {
      className,
      isExtendedAutoForwardOptionsEnabled,
      message: { senderStatus, senderError },
    } = this.props;
    const label = messageSenderStatusToReadableLabel(senderStatus);

    const renderLabel = isExtendedAutoForwardOptionsEnabled && senderError ? senderError : label;

    return (
      <span className={classNames(className, classes({ [senderStatus]: true }))} title={label}>
        {renderLabel}
      </span>
    );
  }
}

export default mobxInjectSelect({
  messengerStore: ['isExtendedAutoForwardOptionsEnabled'],
})(MessageSenderStatus);
