import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import propTypes from '../propTypes';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { MessageItem } from './MessageItem';

const classes = BEM.with('MessageGroup');

class MessageGroup extends Component {
  static propTypes = {
    isMultiSelect: PropTypes.bool.isRequired,
    messages: propTypes.messageArray.isRequired,
    precededByDateSeparator: PropTypes.bool.isRequired,
    precededByMessageGroup: PropTypes.bool,
    shouldTransition: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    precededByMessageGroup: false,
  };

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props, nextProps);
  }

  render() {
    const {
      isMultiSelect,
      messages,
      precededByDateSeparator,
      precededByMessageGroup,
      shouldTransition,
      deletedMessages,
    } = this.props;

    const messageItems = messages.map((message, idx) => {
      if (deletedMessages[message.id]) return null;

      return (
        <MessageItem
          key={message.id}
          message={message}
          precededByDateSeparator={idx === 0 && precededByDateSeparator}
          precededByOtherMessage={idx > 0}
          precededByMessageGroup={idx === 0 && precededByMessageGroup}
          shouldTransition={shouldTransition && idx === messages.length - 1}
        />
      );
    });

    return (
      <div className={classes()}>
        <div
          className={classes('message-list', {
            isMultiSelect,
            precededByMessageGroup,
          })}
        >
          {messageItems}
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  localStore: ['deletedMessages'],
})(MessageGroup);
