import * as errors from '../errors';
import BaseService from './BaseService';

export default class AppointmentsService extends BaseService {
  async create({
    appointmentDate,
    appointmentTime,
    appointmentTimezone,
    location,
    organizationId,
    patientId,
    provider,
    workflowId,
  }: {
    appointmentDate: string;
    appointmentTime: string;
    appointmentTimezone: string;
    location: string;
    organizationId: string;
    patientId: string;
    provider: string;
    workflowId: string;
  }) {
    this._validate({ organizationId, patientId, skipIdCheck: true });

    const response = await this.host.api.appointments.create({
      appointmentDate,
      appointmentTime,
      appointmentTimezone,
      patientId,
      location,
      organizationId,
      provider,
      workflowId,
    });

    const appointment = this.host.models.Appointment.inject(response);
    return appointment;
  }

  async delete(id: string, organizationId: string) {
    this._validate({ id, organizationId, skipDataCheck: true });

    await this.host.api.appointments.delete(id, organizationId);

    const appointment = this.host.models.Appointment.inject({
      $deleted: true,
      id,
    });
    return appointment;
  }

  async batchDelete(ids: string[], organizationId: string) {
    await this.host.api.appointments.batchDelete(ids, organizationId);

    const appointments = ids.map((id) =>
      this.host.models.Appointment.inject({
        $deleted: true,
        id,
      })
    );

    return appointments;
  }

  async findAll({ patientId, organizationId }: { patientId: string; organizationId: string }) {
    this._validate({ organizationId, patientId, skipIdCheck: true });

    const appointments = await this.host.api.appointments.findAll({ patientId, organizationId });

    return appointments.map((appointment: any) => {
      return this.host.models.Appointment.inject(appointment);
    });
  }

  getById(appointmentId: string) {
    return this.host.models.Appointment.get(appointmentId);
  }

  async update({
    appointmentDate,
    appointmentTime,
    appointmentTimezone,
    id,
    location,
    organizationId,
    provider,
    workflowId,
  }: {
    appointmentDate?: string;
    appointmentTime?: string;
    appointmentTimezone?: string;
    id: string;
    location?: string;
    organizationId: string;
    provider?: string;
    workflowId?: string;
  }) {
    this._validate({ id, organizationId, skipDataCheck: true });

    const response = await this.host.api.appointments.update({
      appointmentDate,
      appointmentTime,
      appointmentTimezone,
      id,
      location,
      organizationId,
      provider,
      workflowId,
    });

    const appointment = this.host.models.Appointment.inject(response);

    return appointment;
  }

  _validate({
    patientId,
    id,
    organizationId,
    skipDataCheck = false,
    skipIdCheck = false,
  }: {
    patientId?: string;
    id?: string;
    organizationId: string;
    skipDataCheck?: boolean;
    skipIdCheck?: boolean;
  }) {
    if (!skipIdCheck && !id) {
      throw new errors.ValidationError('id', 'required');
    }
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }
    if (!skipDataCheck) {
      if (!patientId) {
        throw new errors.ValidationError('patientId', 'required');
      }
    }
  }
}
