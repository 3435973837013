import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import FormFieldWrapper from './FormFieldWrapper';
import RangeInput from './RangeInput';

class RangeField extends Component {
  render() {
    const { caption, ...restProps } = this.props;

    return (
      <FormFieldWrapper {...restProps} labelCaption={caption} type="range">
        <RangeInput {...restProps} />
      </FormFieldWrapper>
    );
  }
}

export default withFormsy(RangeField);
