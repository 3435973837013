import React, { useCallback, useEffect, useState } from 'react';

import TCClient from '../../../../../client';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { Checkbox } from '../../../../shared-components/Checkbox/Checkbox';
import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';
import { Toast } from '../../../../shared-components/Toast/Toast';

import styles from './styles.module.css';
import { useAppSelector } from 'redux-stores';

type CustomDirectoriesProps = {
  onClose: () => void;
};

const initialGroupState = {};

export const CustomDirectories: React.FC<CustomDirectoriesProps> = ({ onClose }) => {
  const selectedOrganization = useAppSelector((state) => state.admin.selectedOrganization);
  const selectedUser = useAppSelector((state) => state.admin.selectedUser);
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);
  const [groups, setGroups] = useState<Record<string, boolean>>(initialGroupState);

  const getOrganizationSecurityGroups = useCallback(async () => {
    const securityGroups = await TCClient.adminUsers.getUserSecurityGroups({
      userId: selectedUser.id,
      orgId: selectedOrganization.token,
    });
    setGroups(securityGroups);
  }, [selectedOrganization.token, selectedUser.id]);

  useEffect(() => {
    getOrganizationSecurityGroups();
  }, [getOrganizationSecurityGroups]);

  const saveGroups = async () => {
    const _groups = Object.keys(groups).filter((g) => groups[g as keyof typeof groups] !== false);

    await TCClient.adminUsers.updateUserSecurityGroups({
      orgId: selectedOrganization.token,
      userId: selectedUser.id,
      groups: _groups,
    });

    await getOrganizationSecurityGroups();

    setIsSuccessToastOpen(true);
  };

  return (
    <ViewContainer
      header={'Custom Directories'}
      subHeader={
        'Identify which custom directories this user should be in. Note: These directories must already have been created (See Org Settings: Custom Directories)'
      }
      onClose={onClose}
    >
      <div>
        <ul>
          {Object.keys(groups).map((g) => (
            <li key={g}>
              <Checkbox
                checked={groups[g as keyof typeof groups]}
                onClick={(v) => {
                  setGroups({ ...groups, [g]: v });
                }}
                label={g}
                multiple
              />
            </li>
          ))}
        </ul>
        <div className={styles.buttonWrapper}>
          <CtaButton
            primary
            size={'small'}
            label={'Save Custom Directories'}
            onClick={saveGroups}
          />
        </div>
        <Toast
          type={'SUCCESS'}
          open={isSuccessToastOpen}
          onClose={() => setIsSuccessToastOpen(false)}
          message={'Custom Directories Saved.'}
        />
      </div>
    </ViewContainer>
  );
};
