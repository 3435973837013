export const patientGenders = ['M', 'A', 'F', 'N', 'O', 'U'];

export const patientRelations = [
  'Father',
  'Mother',
  'Spouse',
  'Brother',
  'Sister',
  'Daughter',
  'Son',
  'Granddaughter',
  'Grandson',
  'Grandfather',
  'Grandmother',
  'Foster Father',
  'Foster Mother',
  'Stepfather',
  'Stepmother',
  'Godfather',
  'Godmother',
  'Adoptive Father',
  'Adoptive Mother',
  'Father In-Law',
  'Mother In-Law',
  'Brother In-Law',
  'Sister In-Law',
  'Daughter In-Law',
  'Son In-Law',
  'Aunt',
  'Uncle',
  'Nephew',
  'Niece',
  'Friend',
  'Neighbor',
  'Relative',
  'Primary Care Physician',
  'Roommate',
  'Life Partner',
  'Significant Other',
  'Sponsor',
  'Babysitter',
  'Case Worker',
  'Social Worker',
  'Surrogate',
  'Financial Guarantor',
  'Employee',
  'Organ Donor',
  'Cadaver Donor',
  'Emergency Contact',
  'Caregiver',
  'Guardian',
  'Other',
  'Parent',
  'Child',
  'Grandchild',
  'Grandparent',
];
