import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';

import BEM from '../../../bem';
import { ReactComponent as CloseButtonSvg } from '../../../images/close-icon.svg';
import { AddPatientsFromList } from '.';
import { PatientResult, useGetPatientsQuery } from 'redux-stores/patientAdmin/patientsApi';
import { useRTKQueryInfiniteResults } from 'common/hooks/useRTKQueryInfiniteResults';
import DotsIndicator from 'common/components/DotsIndicator';
import AccessibleList from 'common/components/AccessibleList';
import { useAppSelector } from 'redux-stores';

const MIN_PATIENT_RESULTS = 20;

const classes = BEM.with('PatientSearchBox');

type PatientSearchBoxProps = {
  currentMemberIds: string[];
  pendingMemberIds: string[];
  togglePendingMembers: () => void;
  setSearchTriggered: (open: boolean) => void;
  searchTriggered: boolean;
  addMembersRef: RefObject<HTMLButtonElement>;
};

const PatientSearchBox = ({
  currentMemberIds,
  pendingMemberIds,
  togglePendingMembers,
  setSearchTriggered,
  searchTriggered,
  addMembersRef,
}: PatientSearchBoxProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  let searchResultFragment;
  const { accessibilityMode } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));
  const {
    results: patients,
    response,
    isFetching,
    loadNextPage,
  } = useRTKQueryInfiniteResults(
    useGetPatientsQuery,
    {
      args: { searchValue: searchQuery },
    },
    { ref: searchBoxRef }
  );
  const { continuation } = response?.metadata || {};

  const searchForPatients = ({ target: { value } } = { target: { value: '' } }) => {
    setSearchQuery(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      setSearchTriggered(false);
      addMembersRef.current?.focus();
    }
    if (event.key === 'Tab') {
      event.preventDefault();
    }
  };

  const membersMap = currentMemberIds.reduce((map: Record<string, boolean>, id) => {
    map[id] = true;
    return map;
  }, {}) as Record<string, boolean>;

  const filteredPatients = useMemo<PatientResult[] | undefined>(() => {
    return patients?.filter((patientUser) => {
      if (!membersMap[patientUser.entityId]) {
        return true;
      } else {
        const { contacts } = patientUser.entity.patient;

        if (contacts && contacts.length !== 0) {
          const currentMembersHasContacts = contacts.every(({ user: contactUser }) => {
            return !!membersMap[contactUser.id];
          });
          if (!currentMembersHasContacts) {
            return true;
          }
        }
      }
      return false;
    });
  }, [patients, membersMap]);

  if (filteredPatients && filteredPatients.length < MIN_PATIENT_RESULTS && continuation) {
    loadNextPage();
  }

  if (filteredPatients && filteredPatients.length > 0) {
    searchResultFragment = filteredPatients.map((patientDetail, idx) => (
      <AddPatientsFromList
        currentMembersMap={membersMap}
        isFirstEntry={idx === 0}
        key={patientDetail.entityId}
        patientDetails={patientDetail.entity}
        toggle={togglePendingMembers}
        pendingMemberIds={pendingMemberIds}
      />
    ));
  } else if (isFetching) {
    searchResultFragment = <div className={classes('no-search-results')}>Loading...</div>;
  } else {
    let noResultsText = 'No results found';
    if (searchQuery) {
      noResultsText += ` for "${searchQuery}"`;
    }

    searchResultFragment = <div className={classes('no-search-results')}>{noResultsText}</div>;
  }

  useEffect(() => {
    if (searchTriggered) {
      searchInputRef.current?.focus();
    }
  }, [searchTriggered]);

  return (
    <div className={classes()} ref={searchBoxRef} onKeyDown={handleKeyDown}>
      <AccessibleList
        accessibilityMode={accessibilityMode}
        focusableClasses={[
          '.tc-AddContactsFromList',
          '.tc-AddPatientsFromList',
          '.tc-PatientSearchBox__search-text',
        ]}
      >
        <div className={classes('search-container')}>
          <input
            className={classes('search-text')}
            aria-label="search bar"
            onChange={searchForPatients}
            placeholder="Search"
            type="text"
            ref={searchInputRef}
            value={searchQuery}
          />
          <button
            className={classes('close-container', {
              hide: searchQuery.length === 0,
            })}
            onClick={() => setSearchQuery('')}
          >
            <CloseButtonSvg aria-hidden />
          </button>
        </div>

        <div className={classes('search-results')}>{searchResultFragment}</div>
        {!!continuation && isFetching && (
          <div className={classes('search-results-loader')}>
            <DotsIndicator size={13} />
          </div>
        )}
      </AccessibleList>
    </div>
  );
};

export default PatientSearchBox;
