import EventEmitter from 'events';
import { action, computed, observable, runInAction } from 'mobx';
import queue from 'emitter-queue';
import { get, isEmpty, isEqual, mapValues, partial, pick, upperFirst, uniq } from 'lodash';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/switchMap';
import { validateYYYYMMDD } from '../common/utils/date';
import { DEFAULT_PATIENT_SEARCH_FIELDS } from '../common/utils/searchResults';
import {
  PatientAdminFormValidation,
  PatientCSVUploadTypes,
  PatientSidebarTabs,
} from '../models/enums/';

const { CONTACT, PATIENT, PATIENT_AND_CONTACT } = PatientAdminFormValidation;
const { APPOINTMENT_REMINDERS, BROADCAST } = PatientCSVUploadTypes;

export default class PatientStore {
  events = queue(new EventEmitter());

  defaultPatient = {
    currentEditVersion: '',
    dob: '',
    firstName: '',
    gender: 'M',
    lastName: '',
    mrn: '',
    patientId: '',
    phoneNumber: '',
  };

  defaultContact = {
    contactId: '',
    currentEditVersion: '',
    firstName: '',
    lastName: '',
    patientId: '',
    phoneNumber: '',
    relation: 'Father',
  };

  @observable actionInProgress = false;
  @observable continuation = '';
  @observable patient = Object.assign({}, this.defaultPatient);
  @observable contact = Object.assign({}, this.defaultContact);
  @observable csvErrors = {};
  @observable formErrors = {};
  @observable generalCsvUploadError = false;
  @observable input = '';
  @observable isCSVUploading = false;
  @observable isPatientSearchOpen = false;
  @observable isSearching = false;
  @observable isSendDisabled = false;
  @observable showRefreshPatientMessage = false;
  @observable sidebarTab = PatientSidebarTabs.MESSAGES;
  @observable totalPatients = null;
  @observable.shallow patientContactList = [];
  @observable.shallow patientList = [];

  constructor({ client, entityStore, params, stores }) {
    this.client = client;
    this.entityStore = entityStore;
    this.params = params;
    this.stores = stores;
  }

  _initializeSearcher = () => {
    this._searcher = Observable.fromEvent(this.events, 'search')
      .debounceTime(200)
      .switchMap(this._search)
      .subscribe(
        action(({ patients, continuation, failSearch = false, totalHits, firstHit } = {}) => {
          this.isSearching = false;
          if (!failSearch) {
            if (!patients || totalHits === 0) {
              this.patientList = [];
            } else {
              if (firstHit === 1) {
                this.patientList = patients;
              } else {
                this.patientList = [...this.patientList, ...patients];
              }
              this.continuation = continuation || '';
              this.totalPatients = totalHits;
            }
          }
        })
      );
  };

  _disposeSearcher = () => {
    if (this._searcher) {
      this._searcher.unsubscribe();
      this._searcher = null;
      this.isSearching = false;
    }
  };

  @computed get formErrorsString() {
    return upperFirst(uniq(Object.values(this.formErrors).filter((x) => x !== true)).join(', '));
  }

  @action('PatientStore.destroy') destroy = () => {
    this._disposeSearcher();
    this.input = '';
    this.patientList = [];
    this.isSearching = false;
    this.continuation = '';
    this.totalPatients = null;
  };

  @action('PatientStore.openUploadPatientsCsvModal')
  openUploadPatientsCsvModal = (type, listId) => {
    this.stores.modalStore.openModal('uploadPatientsCsv', { type, listId });
  };

  @action('PatientStore.hideUploadPatientsCsvModal')
  hideUploadPatientsCsvModal = () => {
    this.stores.modalStore.closeModal();
  };

  @action('PatientStore.hideCsvFailModal') hideCsvFailModal = () => {
    this.stores.modalStore.closeModal();
    this.stores.modalStore.openModal('uploadPatientsCsv');
  };

  @action('PatientStore.hideRefreshPatientMessage')
  hideRefreshPatientMessage = () => {
    this.showRefreshPatientMessage = false;
  };

  @action('PatientStore.showRefreshMessage') showRefreshMessage = () => {
    runInAction(() => {
      this.showRefreshPatientMessage = true;
    });
    setTimeout(() => {
      runInAction(() => {
        this.showRefreshPatientMessage = false;
      });
    }, 4000);
  };

  @action('PatientStore.setSidebarTab') setSidebarTab = (tab) => {
    const { messengerStore } = this.stores;
    if (messengerStore.isInfoPaneOpen) messengerStore.closeInfoPane();
    this.sidebarTab = tab;
  };

  @action('PatientStore.uploadCSV') uploadCSV = async (file, type, params = {}) => {
    const { messengerStore, modalStore } = this.stores;
    const { currentOrganizationId, isCsvPatientUploadFlagEnabled } = messengerStore;
    const { listId, timezone, systemConfigurationId } = params;
    const isBroadcastOrAAR = [APPOINTMENT_REMINDERS, BROADCAST].includes(type);
    this.hideUploadPatientsCsvModal();
    this.isCSVUploading = true;
    this.generalCsvUploadError = false;
    this.csvErrors = {};

    try {
      if (type === BROADCAST) {
        const email = this._checkIfUserHasAnEmail();
        if (!email) return;
        await this.client.patients.broadcastListCsvUpload(
          currentOrganizationId,
          file,
          listId,
          email
        );
      } else if (type === APPOINTMENT_REMINDERS) {
        const email = this._checkIfUserHasAnEmail();
        if (!email) return;
        await this.client.patients.appointmentReminderCsvUpload(
          currentOrganizationId,
          file,
          timezone,
          email
        );
      } else if (type === 'PBX_LINES') {
        const email = this._checkIfUserHasAnEmail();
        if (!email) return;
        await this.client.pbx.uploadPbxLinesViaCsv({
          currentOrganizationId,
          file,
          email,
          systemConfigurationId,
        });
      } else {
        const fileText = await this._readFileWithFileReader(file);
        if (isCsvPatientUploadFlagEnabled) {
          await this.client.patients.asyncCsvUpload(currentOrganizationId, fileText, file.name);
        } else {
          await this.client.patients.csvUpload(currentOrganizationId, fileText);
        }
      }
      runInAction(() => {
        this.isCSVUploading = false;
        if (isBroadcastOrAAR || type === 'PBX_LINES') {
          modalStore.openModal('csvSubmitted', { file, type });
        } else {
          if (isCsvPatientUploadFlagEnabled) {
            modalStore.openModal('patientsSubmittedCSV', { filename: file.name });
          } else {
            modalStore.openModal('patientSaveSuccess');
          }
        }
      });
    } catch (err) {
      runInAction(() => {
        this.isCSVUploading = false;
      });
      this.stores.modalStore.openModal('failure');
      if (isBroadcastOrAAR || type === 'PBX_LINES') {
        return;
      } else if (isCsvPatientUploadFlagEnabled) {
        this.stores.modalStore.openModal('newPatientCsvFail');
        return;
      }
      const { error, status } = err.response.body;
      const { message } = error;
      if (status === 'fail' && message.length > 0) {
        this.stores.modalStore.closeModal();
        if (typeof message !== 'object') {
          if (message === 'invalid_header_format') {
            this.csvErrors = [
              {
                row_number: 1,
                reason: 'Invalid header format',
              },
            ];
          } else {
            this.generalCsvUploadError = true;
          }
        } else {
          this.csvErrors = message;
        }
        this.stores.modalStore.openModal('patientCsvFail');
      }
    }
  };

  @action('PatientStore.pendingCsvUploads') getPendingCsvUploads = async () => {
    const { messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    const pendingCsvUploads = await this.client.patients.getPendingCsvUploads(
      currentOrganizationId
    );

    return pendingCsvUploads;
  };

  @action('PatientStore.openPatientCsvFailModal') openPatientCsvFailModal = () => {
    const { messengerStore, modalStore } = this.stores;
    const { isCsvPatientUploadFlagEnabled } = messengerStore;

    if (isCsvPatientUploadFlagEnabled) {
      modalStore.openModal('newPatientCsvFail');
    } else {
      modalStore.openModal('patientCsvFail');
    }
  };
  _checkIfUserHasAnEmail() {
    const { sessionStore } = this.stores;
    const { currentUser } = sessionStore;
    const emails = currentUser.emails.slice();
    if (!emails.length) {
      this.isCSVUploading = false;
      this.stores.modalStore.closeModal();
      this.stores.modalStore.openModal('patientBroadcastCsvFail', { failState: 'email' });
      return;
    }
    return emails[0].address;
  }

  _readFileWithFileReader(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result);
        },
        false
      );
      reader.addEventListener(
        'error',
        (e) => {
          reject(e);
        },
        false
      );
      reader.readAsText(file);
    });
  }

  @action('PatientStore.openCreatePatientModal')
  openCreatePatientModal = () => {
    this._clearPatient();
    this.stores.modalStore.openModal('createPatient');
  };

  @action('PatientStore.hideCreatePatientModal')
  hideCreatePatientModal = () => {
    this.stores.modalStore.closeModal();

    this.clearErrorValues();
    this.isSendDisabled = false;
    this.patientContactList = [];
  };

  @action('PatientStore.updatePatient') updatePatient = (field, value) => {
    this.patient[field] = value;
  };

  @action('PatientStore.updateEditContact') updateEditContact = (field, value) => {
    this.contact[field] = value;
  };

  @action('PatientStore.addContact') addContact = () => {
    const newContact = Object.assign({}, this.defaultContact);
    this.patientContactList = [...this.patientContactList, newContact];
  };

  @action('PatientStore.clearErrorValues') clearErrorValues = () => {
    this.formErrors = {};
  };

  @action('PatientStore.validatePatientOrContactForm')
  validatePatientOrContactForm = (type) => {
    this.clearErrorValues();
    const fields = ['firstName', 'lastName', 'phoneNumber'];

    if ([PATIENT, PATIENT_AND_CONTACT].includes(type)) {
      const validatePatientFn = partial(this._validateField, 'patient');
      fields.concat(['mrn', 'dob']).forEach(validatePatientFn);
    }
    if (type === PATIENT_AND_CONTACT) {
      this.patientContactList.forEach((_, i) => {
        fields.forEach((field) => this._validateField('patientContactList.' + i, field));
      });
    }
    if (type === CONTACT) {
      const validateContactFn = partial(this._validateField, 'contact');
      fields.forEach(validateContactFn);
    }

    return Object.values(this.formErrors).length === 0;
  };

  _validateField = (fieldPath, field) => {
    const path = fieldPath + '.' + field;
    const fieldVal = get(this, path, undefined);
    let error;

    if (!fieldVal) error = true;

    if (field === 'dob' && fieldVal && !validateYYYYMMDD(this._convertToYYYYMMDD(fieldVal))) {
      error = 'date of birth is incorrect';
    }

    if (field === 'phoneNumber' && fieldVal && !this.client.phone.isPhone(fieldVal)) {
      error = 'phone number format is incorrect';
    }

    if (error) this.formErrors[path] = error;
  };

  @action('PatientStore.addPatient') addPatient = async () => {
    const { messengerStore, modalStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    this.clearErrorValues();

    this.patient.organizationId = currentOrganizationId;
    this.patient.contacts = this.patientContactList.slice();

    if (this.validatePatientOrContactForm(PATIENT_AND_CONTACT)) {
      this.actionInProgress = true;
      const patient = {
        ...this.patient,
        dob: this._convertToYYYYMMDD(this.patient.dob),
      };

      try {
        const res = await this.client.patients.create(patient);

        this.hideCreatePatientModal();
        runInAction(() => {
          this.totalPatients = this.totalPatients + 1;
          this.patientList = [res, ...this.patientList];
        });
      } catch (err) {
        if (!this._canProcessKnownServerErrors(err, PATIENT_AND_CONTACT)) {
          console.error(err);
          modalStore.openModal('failure', {
            reopenModal: 'createPatient',
          });
        }
      } finally {
        runInAction(() => {
          this.actionInProgress = false;
        });
      }
    }
  };

  _formatContact = (patientContact) => {
    const fieldsToCompare = ['firstName', 'lastName', 'phoneNumber', 'relation'];
    const contactCopy = Object.assign({}, pick(patientContact, fieldsToCompare), {
      phoneNumber: this.client.phone.normalizePhone(patientContact.phoneNumber),
    });

    return mapValues(contactCopy, (val) => (typeof val === 'string' ? val.toLowerCase() : val));
  };

  @action('PatientStore.validateContact') validateContact = (index, field, value) => {
    this.patientContactList[index][field] = value;
    this.patientContactList = [...this.patientContactList];
    const newContact = this.patientContactList[index];

    const duplicateContacts = this.patientContactList.filter((patientContact) => {
      return isEqual(this._formatContact(patientContact), this._formatContact(newContact));
    });

    if (duplicateContacts.length > 1) {
      this.formErrors = {
        'contact.duplicate': 'Duplicate contact information entered',
      };
      this.isSendDisabled = true;
    } else {
      this.formErrors = {};
      this.isSendDisabled = false;
    }
  };

  @action('PatientStore.removeContact') removeContact = (index) => {
    this.patientContactList.splice(index, 1);
    this.patientContactList = [...this.patientContactList];
    this.isSendDisabled = false;
    this.clearErrorValues();
  };

  @action('PatientStore.clearSearchResults') clearSearchResults = () => {
    this.search({ text: '' });
  };

  @action('PatientStore.search') search = (props = {}) => {
    const { text = this.input } = props;

    this.stores.sessionStore.resetAutoLogout();

    if (this.input !== text) this.input = text;

    if (!this._searcher) this._initializeSearcher();
    runInAction(() => {
      this.isSearching = true;
    });
    this.events.queue('search', { ...props, text: text.trim() });
  };

  @action('PatientStore.showPatientSearch') showPatientSearch = () => {
    this.isPatientSearchOpen = true;
  };

  @action('PatientStore.hidePatientSearch') hidePatientSearch = () => {
    this.isPatientSearchOpen = false;
    this.clearSearchResults();
  };

  @action('PatientStore.showPatientDeleteModal') showPatientDeleteModal = (entity, index) => {
    const { modalStore } = this.stores;

    modalStore.openModal('patientDelete', { entity, index });
  };

  @action('PatientStore.showContactDeleteModal') showContactDeleteModal = (contact, index) => {
    const { modalStore } = this.stores;

    modalStore.openModal('contactDelete', { contact, index });
  };

  @action('PatientStore.hidePatientDeleteModal')
  hidePatientDeleteModal = () => {
    const { modalStore } = this.stores;

    modalStore.closeModal();
  };

  @action('PatientStore.showPatientEditModal') showPatientEditModal = (entity) => {
    const { modalStore } = this.stores;
    const { firstName, id: patientId, lastName, patient } = entity;
    const { editVersion: currentEditVersion, dob, gender, mrn, phoneNumber } = patient;

    this.patient = {
      dob: this._dobConverter(dob),
      currentEditVersion,
      firstName,
      gender,
      lastName,
      mrn,
      patientId,
      phoneNumber,
    };

    modalStore.openModal('managePatientOrContact', {
      patient: entity,
      isPatient: true,
    });
  };

  @action('PatientStore.hideManagePatientOrContactModal')
  hideManagePatientOrContactModal = () => {
    const { modalStore } = this.stores;

    modalStore.closeModal();
    this.clearErrorValues();
  };

  @action('PatientStore.hideAddContactModal') hideAddContactModal = () => {
    const { modalStore } = this.stores;

    modalStore.closeModal();
    this._clearContact();
  };

  @action('PatientStore.showEditContactModal') showEditContactModal = (entity, contact) => {
    const { modalStore } = this.stores;
    const { id: patientId } = entity;
    const {
      editVersion: currentEditVersion,
      phoneNumber,
      relation,
      user,
      userId: contactId,
    } = contact;
    const { firstName, lastName } = user;

    this.contact = {
      contactId,
      currentEditVersion,
      firstName,
      lastName,
      patientId,
      phoneNumber,
      relation,
    };

    modalStore.openModal('managePatientOrContact', {
      patient: entity,
      isPatient: false,
    });
  };

  @action('PatientStore.hideEditContactModal') hideEditContactModal = () => {
    const { modalStore } = this.stores;

    modalStore.closeModal();
    this._clearContact();
    this.clearErrorValues();
  };

  @action('PatientStore.showAddContactModal') showAddContactModal = (entity, index) => {
    const { modalStore } = this.stores;

    this._clearContact();
    this.contact.patientId = entity.id;
    modalStore.openModal('managePatientOrContact', {
      patient: entity,
      index,
      isPatient: false,
    });
  };

  _checkDuplicateContact = (patient, contact, contactIndex) => {
    const { modalStore } = this.stores;

    const duplicateContacts = patient.contacts.filter((patientContact) => {
      if (patientContact.userId === contact.contactId) return false;

      const existingContact = {
        firstName: patientContact.user.firstName,
        lastName: patientContact.user.lastName,
        relation: patientContact.relation,
        phoneNumber: patientContact.phoneNumber,
      };

      return isEqual(this._formatContact(existingContact), this._formatContact(contact));
    });

    if (duplicateContacts.length) {
      modalStore.openModal('duplicateContact', {
        contact,
        reopenModal: 'managePatientOrContact',
        optionsReopenModal: { patient, isPatient: false, contactIndex },
      });

      return true;
    }

    return false;
  };

  @action('PatientStore.createContact') createContact = async (patient, contactIndex) => {
    const { messengerStore, modalStore } = this.stores;
    const { currentOrganizationId } = messengerStore;
    const contact = this.contact;
    contact.organizationId = currentOrganizationId;

    if (this._checkDuplicateContact(patient.patient, contact, contactIndex)) return;

    try {
      this.actionInProgress = true;
      const sdkPatient = await this.client.patients.createContact(contact);
      this.entityStore.syncOne(sdkPatient);
      this.hideAddContactModal();
    } catch (err) {
      console.error(err);
      if (
        !this._canProcessKnownServerErrors(
          err,
          CONTACT,
          this.patientList[contactIndex],
          contactIndex
        )
      ) {
        modalStore.openModal('failure', {
          reopenModal: 'managePatientOrContact',
          optionsReopenModal: {
            patient: this.patientList[contactIndex],
            contactIndex,
            isPatient: false,
          },
        });
      }
    } finally {
      runInAction(() => {
        this.actionInProgress = false;
      });
    }
  };

  @action('PatientStore.patientEdited') patientEdited = () => {
    this.isPatientEdited = !this.isPatientEdited;
  };

  @action('PatientStore.editPatient') editPatient = async (entity) => {
    const { messengerStore, modalStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    this.clearErrorValues();

    if (this.validatePatientOrContactForm(PATIENT)) {
      this.actionInProgress = true;
      try {
        await this.client.patients.update({
          ...this.patient,
          organizationId: currentOrganizationId,
          dob: this._convertToYYYYMMDD(this.patient.dob),
          patientId: this._sanitizePatientId(this.patient.patientId),
        });
        this.hideManagePatientOrContactModal();
      } catch (err) {
        console.error(err);
        if (!this._canProcessKnownServerErrors(err, PATIENT)) {
          modalStore.openModal('failure', {
            reopenModal: 'managePatientOrContact',
            optionsReopenModal: { patient: entity, isPatient: true },
          });
        }
      } finally {
        runInAction(() => {
          this.actionInProgress = false;
        });
      }
    }
  };

  _sanitizePatientId = (patientId) => {
    const newPatientId = patientId.split(':');
    return newPatientId.length ? newPatientId[newPatientId.length - 1] : patientId;
  };

  @action('PatientStore.patientEdited') patientContactEdited = () => {
    this.isPatientContactEdited = !this.isPatientContactEdited;
  };

  @action('PatientStore.editContact') editContact = async (patient, contactIndex) => {
    const { messengerStore, modalStore } = this.stores;
    const { currentOrganizationId } = messengerStore;
    const contact = this.contact;

    this.clearErrorValues();

    for (let i = 0; i < patient.patient.contacts.length; i++) {
      if (patient.patient.contacts[i].userId === contact.contactId) {
        if (
          patient.patient.contacts[i].user.firstName === contact.firstName &&
          patient.patient.contacts[i].user.lastName === contact.lastName &&
          patient.patient.contacts[i].relation === contact.relation &&
          patient.patient.contacts[i].phoneNumber === contact.phoneNumber
        ) {
          this.hideManagePatientOrContactModal();
          return;
        }
      }
    }

    if (this._checkDuplicateContact(patient.patient, contact, contactIndex)) return;

    if (this.validatePatientOrContactForm(CONTACT)) {
      this.actionInProgress = true;
      contact.organizationId = currentOrganizationId;
      contact.patientId = this._sanitizePatientId(contact.patientId);

      try {
        await this.client.patients.updateContact(contact);
        this.hideManagePatientOrContactModal();
      } catch (err) {
        console.error(err);
        if (!this._canProcessKnownServerErrors(err, CONTACT, patient)) {
          modalStore.openModal('failure', {
            reopenModal: 'managePatientOrContact',
            optionsReopenModal: { patient, isPatient: false },
          });
        }
      } finally {
        runInAction(() => {
          this.actionInProgress = false;
        });
      }
    }
  };

  @action('PatientStore.openPatientProfileModal')
  openPatientProfileModal = async (entityId) => {
    const { conversationStore, messengerStore, modalStore } = this.stores;
    const { currentOrganizationId } = messengerStore;
    const localPatient = this.stores.entityStore.patient.getById(`patient:${entityId}`);

    let patient = localPatient;
    if (isEmpty(localPatient) || (localPatient.user && localPatient.user.$placeholder)) {
      const user = await this.stores.userStore.findUser(entityId, currentOrganizationId);
      patient = user.patient;
    }

    const conversations = await conversationStore.findAllGroupConversationsWithMembers(
      entityId,
      currentOrganizationId,
      { localOnly: true }
    );
    modalStore.openModal('patientProfile', { patient, conversations });
  };

  @action('PatientStore.deletePatient') deletePatient = async (entityId, index) => {
    const { modalStore, messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    this.actionInProgress = true;

    try {
      await this.client.patients.delete(entityId, currentOrganizationId);
      runInAction(() => {
        this.totalPatients = this.totalPatients - 1;
        this.patientList.splice(index, 1);
        modalStore.closeModal();
      });
    } catch (err) {
      console.error(err);
      modalStore.openModal('failure');
    } finally {
      runInAction(() => {
        this.actionInProgress = false;
      });
    }
  };

  @action('PatientStore.deleteContact') deleteContact = async (contactId, patientId) => {
    const { modalStore, messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    this.actionInProgress = true;

    try {
      await this.client.patients.deleteContact({
        contactId,
        organizationId: currentOrganizationId,
        patientId,
      });
      this.entityStore.syncOne(this.client.patients.getById(patientId));
      modalStore.closeModal();
    } catch (err) {
      console.error(err);
      modalStore.openModal('failure');
    } finally {
      runInAction(() => {
        this.actionInProgress = false;
      });
    }
  };

  @action('PatientStore.setCurrentPatientAdminPage')
  setCurrentPatientAdminPage = (page) => {
    this.currentPatientAdminPage = page;
  };

  _search = async ({ continuation = '', excludeIds, excludeSelf, text } = {}) => {
    const { messengerStore } = this.stores;

    const searchFields = DEFAULT_PATIENT_SEARCH_FIELDS;

    try {
      const { results, metadata } = await this.client.search.query({
        continuation,
        excludeIds,
        excludeSelf,
        organizationId: messengerStore.currentOrganizationId,
        query: { [searchFields]: text },
        sort: ['displayName'],
        types: ['patient_account'],
        version: 'SEARCH_PARITY',
      });

      const filterResults = results
        .filter(({ entity }) => entity.patient !== null)
        .map(({ entity }) => entity);

      const patients = this.entityStore.sync(filterResults);

      return {
        patients,
        continuation: metadata.continuation || '',
        firstHit: metadata.firstHit,
        totalHits: metadata.totalHits,
      };
    } catch (err) {
      console.error(err);
      return { failSearch: true };
    }
  };

  _dobConverter = (dob) => {
    const split = dob.split('/');
    const [month, day] = split;
    let [, , year] = split;
    if (year.length < 4) {
      year = '20' + year;
    }
    return [month, day, year].join('-');
  };

  _convertToYYYYMMDD = () => {
    const dobArr = this.patient.dob.split('-');
    if (dobArr.length < 3) {
      return null;
    }
    const [mm, dd, yyyy] = dobArr;
    return `${yyyy}-${mm}-${dd}`;
  };

  _clearPatient = () => {
    this.patient = Object.assign({}, this.defaultPatient);
  };

  _clearContact = () => {
    this.contact = Object.assign({}, this.defaultContact);
  };

  _canProcessKnownServerErrors = (serverError, type, patientOfContact = {}, contactIndex) => {
    const { modalStore } = this.stores;
    const errors = {};
    const invalidNumber = get(serverError, 'response.body.error.message.invalid_numbers');
    if (invalidNumber) {
      const paths = ['patient', 'patientContactList.0', 'patientContactList.1', 'contact'];
      const path = paths.find((p) => {
        return (
          this.client.phone.normalizePhone(get(this, `${p}.phoneNumber`, '')) === invalidNumber
        );
      });
      errors[`${path}.phoneNumber`] = 'invalid phone number';
    }

    if (serverError.message === 'patient already exists' && type === PATIENT_AND_CONTACT) {
      errors['patient.mrn'] = 'MRN already taken';
    }

    if (get(serverError, 'response.body.error.message') === 'duplicate contact') {
      modalStore.openModal('duplicateContact', {
        contact: this.contact,
        reopenModal: 'managePatientOrContact',
        optionsReopenModal: {
          patient: patientOfContact,
          isPatient: false,
          index: contactIndex,
        },
      });

      return true;
    }

    if (
      get(serverError, 'response.body.error.message') === 'patient is out of date' ||
      get(serverError, 'response.body.error.message') === 'patient contact is out of date'
    ) {
      const entityError =
        get(serverError, 'response.body.error.message') === 'patient is out of date'
          ? 'patient'
          : 'patient contact';
      modalStore.openModal('failureUpdate', { entityError });
      return true;
    }

    if (Object.values(errors).length) {
      runInAction(() => {
        this.formErrors = errors;
      });
      return true;
    }

    return false;
  };
}
