import React from 'react';

import TCClient from '../../../../../client';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';
import { AdminStateSlice } from '../../../../types';
import { reduxInjectSelect } from '../../../../../common/utils/reduxInjectSelect';
import { actions, ReduxState, useAppDispatch } from 'redux-stores';

type RemoveUserProps = {
  onClose: () => void;
};

const { setUsersPageView } = actions;

const RemoveUser: React.FC<RemoveUserProps & ReduxProps> = ({
  onClose,
  selectedUser,
  selectedOrganization,
}) => {
  const dispatch = useAppDispatch();
  const removeUser = async () => {
    const confirm = await window.confirm('Are you sure you want to permanently remove this user?');
    if (confirm) {
      const { token: orgId } = selectedOrganization;
      const { id: userId } = selectedUser;
      await TCClient.adminUsers.organizationLeave({ orgId, userId });
      dispatch(setUsersPageView({ selectedUsersPageView: 'users' }));
    }
  };
  return (
    <ViewContainer
      header={'Remove User'}
      subHeader={
        'Permanently remove this user from your organization. Changes will take effect immediately.'
      }
      onClose={onClose}
    >
      <div>
        <CtaButton
          label={'Remove User'}
          primary
          size={'small'}
          onClick={async () => {
            await removeUser();
          }}
        />
      </div>
    </ViewContainer>
  );
};

type ReduxProps = {
  selectedUser: AdminStateSlice['selectedUser'];
  selectedOrganization: AdminStateSlice['selectedOrganization'];
};

export default reduxInjectSelect<RemoveUserProps, ReduxProps, ReduxState>({
  admin: ['selectedUser', 'selectedOrganization'],
})(RemoveUser);
