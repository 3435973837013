import { useEffect, useState, useCallback } from 'react';

import BEM from '../bem';
import TCClient from '../../client';

import { ReactComponent as CloseButtonSvg } from '../images/close-icon.svg';
import { ReactComponent as MemberAdded } from '../images/green-checkmark.svg';
import { ReactComponent as MembersToBeAdded } from '../images/check-add-members.svg';

import { SearchQueryOptions, SearchResult, User, PBXTab, PBXLinesList } from '../../types';
import { SearchTypes } from '../../models/enums';
import EntityAvatar from './EntityAvatar';

const classes = BEM.with('AddUsersSearchBox');

type AddUsersSearchBoxProps = {
  category: PBXTab;
  organizationId: string;
  getAllPbxLinesInOrg: ({
    category,
    existingLines,
    pageNumber,
  }: {
    category: string;
    existingLines: PBXLinesList[];
    pageNumber: number;
  }) => void;
  setIsAddUsersSearchBoxOpen: (isAddUsersSearchBoxOpen: boolean) => void;
};

type MobxProps = {};

const AddUsersSearchBox = ({
  category,
  organizationId,
  setIsAddUsersSearchBoxOpen,
  getAllPbxLinesInOrg,
}: AddUsersSearchBoxProps & MobxProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [resultCount, setResultCount] = useState<number>();
  const [results, setResults] = useState<User[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const isRoleAssignee = category === 'Roles Lines';
  let searchResultFragment;

  const toggleSelected = (token: string) => {
    if (selected.includes(token)) {
      setSelected(selected.filter((id) => id !== token));
    } else {
      setSelected([...selected, token]);
    }
  };

  const assignUsersToLine = async () => {
    await TCClient.pbx.batchAssignUsersToNCLines({
      orgId: organizationId,
      targetIds: selected,
    });
    getAllPbxLinesInOrg({ category, existingLines: [], pageNumber: 1 });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = event.target.value;
    setSearchQuery(newVal);
    handleSearchQuery(newVal);
  };

  const handleSearchQuery = useCallback(
    (val: string) => {
      const searchTags = async (val: string) => {
        const searchOptions: SearchQueryOptions = {
          version: 'LEGACY',
          query: { name: val },
          organizationId: organizationId,
          types: [SearchTypes.USER],
          sort: ['firstName'],
          isNewAdminSearch: true,
          bool: {
            ...(!isRoleAssignee ? { must_not: { feature_service: 'role' } } : null),
            must: {
              'department,display_name,group_name,name,tag_names,title': val,
              ...(isRoleAssignee ? { feature_service: 'role' } : null),
            },
          },
        };

        const { results, metadata } = await TCClient.search.query<SearchResult>(searchOptions);
        setResultCount(metadata.totalHits);
        const data = results.map((r: SearchResult) => r.entity as User);
        setResults(data);
      };
      searchTags(val);
    },
    [organizationId, isRoleAssignee]
  );

  useEffect(() => {
    handleSearchQuery('');
  }, [handleSearchQuery]);

  useEffect(() => {
    handleSearchQuery(searchQuery);
  }, [handleSearchQuery, searchQuery]);

  if (results.length > 0) {
    searchResultFragment = results.map((user: User) => (
      <div
        className={classes('search-outer')}
        key={user.id}
        onClick={() => toggleSelected(user.id)}
      >
        <div className={classes('entity-svg')}>
          <EntityAvatar
            entity={user}
            indicatorSize={'SMALL'}
            showPresenceIndicator={true}
            size={25}
          />
        </div>
        <div className={classes('entity-info')}>
          <div className={classes('entity-name')}>{user.displayName}</div>
          <div className={classes('entity-details')}>
            {user.title && (
              <div className={classes('entity-title')} title={user.title}>
                {user.title}
              </div>
            )}
            {user.department && (
              <div className={classes('entity-department')}>| {user.department}</div>
            )}
          </div>
        </div>
        <div className={classes('check-mark', { isSelected: selected.includes(user.id) })}>
          {selected.includes(user.id) ? <MemberAdded /> : <MembersToBeAdded />}
        </div>
      </div>
    ));
  } else {
    let noResultsText = 'No results found';
    if (searchQuery) {
      noResultsText += ` for "${searchQuery}"`;
    }

    searchResultFragment = <div className={classes('no-search-results')}>{noResultsText}</div>;
  }

  return (
    <div className={classes()}>
      <div className={classes('search-container')}>
        <div className={classes('search-text')}>
          <input
            aria-label="search bar"
            onChange={handleSearch}
            placeholder="Search for users"
            type="text"
            value={searchQuery}
            autoFocus
          />
        </div>
        <div
          className={classes('close-container', {
            hide: searchQuery.length === 0,
          })}
          onClick={() => setSearchQuery('')}
        >
          <CloseButtonSvg />
        </div>
      </div>
      <div className={classes('individuals-tab')}>
        <div>Individuals</div>
        {selected.length > 0 && (
          <div className={classes('individuals-selection-tab')}>
            <div
              className={classes('clear-selection-button')}
              onClick={async () => {
                setSelected([]);
              }}
            >
              Clear Selection
            </div>
            <div
              className={classes('add-users-button')}
              onClick={async () => {
                setIsAddUsersSearchBoxOpen(false);
                assignUsersToLine();
              }}
            >
              Add {selected.length} users
            </div>
          </div>
        )}
      </div>
      <div className={classes('search-results')}>{searchResultFragment}</div>
      {resultCount ? (
        resultCount > 50 ? (
          <div className={classes('limited-results-tab')}> Please narrow down your search </div>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default AddUsersSearchBox;
