import TCClient from '../../../../../client';
import {
  PendingUsers,
  PendingUser,
  PendingUserTableRow,
  PendingUserTableRows,
} from '../../../../types';

function formatPendingUsers(serverPendingUsers: PendingUsers): PendingUserTableRows {
  return serverPendingUsers.map(
    ({ username, token, id, emailAddresses, lastName, firstName, organization }: PendingUser) => ({
      username,
      token,
      id,
      firstName,
      lastName,
      emailAddress: emailAddresses.emailAddress,
      organization,
    })
  );
}

export async function getPendingUsers({
  query,
  setPendingUsers,
  selectedOrganizationId,
}: {
  selectedOrganizationId: string;
  query?: string;
  setPendingUsers: (pendingUsers: PendingUserTableRows) => void;
}) {
  const pendingUsers: PendingUsers = await TCClient.adminUsers.getPendingUsers(
    selectedOrganizationId,
    query
  );
  setPendingUsers(formatPendingUsers(pendingUsers));
}

export async function addPendingUser({
  pendingUserToAdd,
  setPendingUsers,
  pendingUsers,
}: {
  pendingUserToAdd: PendingUserTableRow;
  setPendingUsers: (pendingUsers: PendingUserTableRows) => void;
  pendingUsers: PendingUserTableRows;
}) {
  await TCClient.adminUsers.addPendingUser(pendingUserToAdd);
  setPendingUsers(
    pendingUsers.filter(({ token }: PendingUserTableRow) => token !== pendingUserToAdd.token)
  );
}

export async function declineUser({
  userToDecline,
  setPendingUsers,
  toggleIsModalOpen,
  setUserToDecline,
  pendingUsers,
}: {
  userToDecline: PendingUserTableRow;
  setPendingUsers: (pendingUsers: PendingUserTableRows) => void;
  toggleIsModalOpen: () => void;
  setUserToDecline: (pendingUser: PendingUserTableRow | null) => void;
  pendingUsers: PendingUserTableRows;
}) {
  if (userToDecline === null) return;
  await TCClient.adminUsers.declinePendingUser(userToDecline);

  setPendingUsers(
    pendingUsers.filter(({ token }: PendingUserTableRow) => token !== userToDecline.token)
  );
  toggleIsModalOpen();
  setUserToDecline(null);
}

export async function addAllPendingUsers({
  setPendingUsers,
  pendingUsers,
  toggleIsModalOpen,
}: {
  pendingUsers: PendingUserTableRows;
  toggleIsModalOpen: () => void;
  setPendingUsers: (pendingUsers: PendingUserTableRows) => void;
}) {
  const promiseArr = pendingUsers.map((pendingUser: PendingUserTableRow) =>
    TCClient.adminUsers.addPendingUser(pendingUser)
  );
  await Promise.all(promiseArr);
  setPendingUsers([]);
  toggleIsModalOpen();
}

export async function declineAllPendingUsers({
  pendingUsers,
  setPendingUsers,
  toggleIsModalOpen,
}: {
  pendingUsers: PendingUserTableRows;
  toggleIsModalOpen: () => void;
  setPendingUsers: (pendingUsers: PendingUserTableRows) => void;
}) {
  const promiseArr = pendingUsers.map((pendingUser: PendingUserTableRow) =>
    TCClient.adminUsers.declinePendingUser(pendingUser)
  );
  const remoteWipeArr = pendingUsers.map((pendingUser: PendingUserTableRow) =>
    TCClient.adminUsers.remoteWipe({
      orgId: pendingUser.organization,
      userId: pendingUser.token,
    })
  );
  await Promise.all([...promiseArr, ...remoteWipeArr]);
  setPendingUsers([]);
  toggleIsModalOpen();
}
