import { action, observable } from 'mobx';
import { AlertGroupMetaTypes, AppTypes, FeatureServices } from '../models/enums';

export default class RosterStore {
  @observable filterUnreadCount = 0;
  @observable inactiveRosterUnreadCount = 0;
  @observable inactiveRosterUnreadPriorityCount = 0;
  @observable filterUnreadPriorityCount = 0;
  @observable defaultFilter = 'Inbox';
  @observable selectedFilter = 'Inbox';
  @observable suggestedFeature = 'Roles';
  @observable selectedAlertsFilter = 'Alerts';
  @observable selectedFilterBar = 'Left';

  constructor({ client, entityStore, stores }) {
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  @action('RosterStore.setFilter') setFilter = (filter) => {
    const { groupStore, messengerStore, teamStore } = this.stores;

    if (filter === 'Forums') {
      groupStore.refreshForums();
    } else if (filter === 'Teams') {
      teamStore.refreshTeamsByOrg();
    }

    this.selectedFilter = filter;
    messengerStore.closeInfoPane();
    messengerStore.currentAppSelected = AppTypes.MESSAGES;
  };

  @action('RosterStore.clearFilter') clearFilter = () => {
    this.setFilter(this.defaultFilter);
  };

  @action('RosterStore.setFilterUnreadCount') setFilterUnreadCount = (unreadCount) => {
    this.filterUnreadCount = unreadCount;
  };

  @action('RosterStore.setFilterUnreadPriorityCount')
  setFilterUnreadPriorityCount = (unreadCount) => {
    this.filterUnreadPriorityCount = unreadCount;
  };

  @action('RosterStore.setInactiveRosterUnreadCount')
  setInactiveRosterUnreadCount = (unreadCount) => {
    this.inactiveRosterUnreadCount = unreadCount;
  };

  @action('RosterStore.setInactiveRosterPriorityCount')
  setInactiveRosterUnreadPriorityCount = (unreadCount) => {
    this.inactiveRosterUnreadPriorityCount = unreadCount;
  };

  @action('RosterStore.resetUnreadCounts')
  resetUnreadCounts = () => {
    this.filterUnreadCount = 0;
    this.filterUnreadPriorityCount = 0;
    this.inactiveRosterUnreadCount = 0;
    this.inactiveRosterUnreadPriorityCount = 0;
    this.filterUnreadCount = 0;
  };

  @action('RosterStore.setFilterBar') setFilterBar = (filter) => {
    const { alertStore, messengerStore } = this.stores;

    messengerStore.closeInfoPane();

    if (filter === 'Alerts') {
      alertStore.fetchAlertMessagesForCurrentOrg();
      this.setAlertsFilter('Alerts');
    }

    this.selectedFilterBar = filter;
  };

  @action('RosterStore.setAlertsFilter') setAlertsFilter = (conversationTile) => {
    this.selectedAlertsFilter = conversationTile;
  };

  @action('RosterStore.setFilterBarByEntity') setFilterBarByEntity = (entity) => {
    if (entity.$entityType === 'message' && entity.group) entity = entity.group;
    const { $entityType: entityType, groupType } = entity;

    if (entityType === 'group' && groupType === 'PATIENT_CARE') {
      this.setFilterBar('Right');
    } else {
      this.setFilterBar('Left');
    }
  };

  @action('RosterStore.getUnselectedUnreadCounts') getUnselectedUnreadCounts = () => {
    const { conversationStore, messengerStore } = this.stores;
    const alertsUnreadCount = messengerStore.currentOrganization.unreadAlertsCount || 0;
    let patientCareUnreadCount = 0;
    let patientCareUnreadPriorityCount = 0;
    let rosterUnreadCount = 0;
    let rosterUnreadPriorityCount = 0;

    for (const conversation of conversationStore.conversations) {
      const { counterParty, unreadCount, unreadPriorityCount } = conversation;
      if (!counterParty) continue;

      const { groupType } = counterParty;
      if (groupType === 'PATIENT_CARE') {
        patientCareUnreadCount += unreadCount;
        patientCareUnreadPriorityCount += unreadPriorityCount;
      } else if (
        conversation.featureService !== FeatureServices.ALERTS &&
        conversation.featureService !== FeatureServices.ROLE_ALERTS
      ) {
        if (
          conversation.featureService === FeatureServices.GROUP_ALERTS &&
          conversation?.counterParty?.metadata?.meta_type === AlertGroupMetaTypes.WORKFLOW_GROUP
        ) {
          continue;
        }

        rosterUnreadCount += unreadCount;
        rosterUnreadPriorityCount += unreadPriorityCount;
      }
    }

    return {
      alertsUnreadCount,
      patientCareUnreadCount,
      patientCareUnreadPriorityCount,
      rosterUnreadCount,
      rosterUnreadPriorityCount,
    };
  };
}
