import React, { useRef } from 'react';
import { DeliveryMethod, DeliveryMethods } from '../../../../../models/enums/DeliveryMethods';
import BEM from '../../../../bem';
import { mobxInjectSelect } from '../../../../utils';
import { ContextMenu } from '../../../ContextMenu';
import { DeliveryMethodIcon } from '../../DeliveryMethodIcon';

import { ReactComponent as DropDownSvg } from '../../../../images/pa-dropdown.svg';

const { LINK, SMS } = DeliveryMethods;
const classes = BEM.with('TemplateForm');
const dmClasses = BEM.with('TemplateDeliveryMethod');

type TemplateDeliveryMethodProps = {
  messageDeliveryMethod: DeliveryMethod;
  setMessageDeliveryMethod: (deliveryMethod: DeliveryMethod) => void;
};

type MobxProps = {
  allowPatientDeliveryMethod?: boolean;
};

type DeliveryMethodSelectionOption = {
  copy: string;
  deliveryMethod: DeliveryMethod;
};

let options: DeliveryMethodSelectionOption[] = [
  {
    copy: 'Secure Browser Link: HIPAA-Compliant',
    deliveryMethod: LINK,
  },
  {
    copy: 'Unsecure SMS Text: Non-HIPAA Compliant',
    deliveryMethod: SMS,
  },
];

const TemplateDeliveryMethod: React.FC<TemplateDeliveryMethodProps & MobxProps> = ({
  allowPatientDeliveryMethod,
  messageDeliveryMethod,
  setMessageDeliveryMethod,
}) => {
  const deliveryMethodRef = useRef<HTMLDivElement>(null);
  const selectedOption = options.find((option) => messageDeliveryMethod === option.deliveryMethod);
  if (!allowPatientDeliveryMethod) {
    options = options.filter((option) => option.deliveryMethod !== SMS);
  }

  const selectedFragment = (
    <div className={classes('messageTypeDropdown')}>
      <div className={classes('messageKindDropdown')} ref={deliveryMethodRef}>
        <div className={classes('dropDownSvg')}>
          <DeliveryMethodIcon isSecure={messageDeliveryMethod === LINK} />
          <div className={classes('dropDownText')}>{selectedOption?.copy}</div>
        </div>
        <DropDownSvg className={classes('nav-item-dropdown')} />
      </div>
    </div>
  );

  let contextMenuFragment = selectedFragment;
  contextMenuFragment = (
    <ContextMenu
      className={classes('deliveryMethod')}
      event="click"
      offsetY={6}
      position="bottominnerleft"
      relativeTo={deliveryMethodRef.current}
      theme="vertical"
      value={messageDeliveryMethod}
      data-test-id={'template-delivery-input'}
      menu={
        <div className={dmClasses('')}>
          {options.map((option) => (
            <div
              className={dmClasses('menu-item')}
              key={option.deliveryMethod}
              onClick={() => setMessageDeliveryMethod(option.deliveryMethod)}
            >
              <DeliveryMethodIcon isSecure={option.deliveryMethod === LINK} />
              {option.copy}
            </div>
          ))}
        </div>
      }
    >
      {selectedFragment}
    </ContextMenu>
  );

  return (
    <div className={classes('formGroup')}>
      <label>Delivery</label>
      {contextMenuFragment}
    </div>
  );
};

export default mobxInjectSelect<TemplateDeliveryMethodProps, MobxProps>({
  patientAdminStore: ['allowPatientDeliveryMethod'],
})(TemplateDeliveryMethod);
