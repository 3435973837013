import React from 'react';
import BEM from '../../bem';
import { ReactComponent as PlusSvg } from '../../images/plus.svg';

const classes = BEM.with('GroupAvatarDropzone');

export default function AvatarUploadButton() {
  return (
    <div className={classes('upload-button')}>
      <span>
        <PlusSvg className={classes('upload-button-image')} />
      </span>
    </div>
  );
}
