import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';

import BEM from '../../../bem';

const classes = BEM.with('WrongPasswordModal');

class WrongPasswordModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        bodyClass={classes('body')}
        closeClass={classes('close-button')}
        headerClass={classes('header')}
        className={classes()}
        header="Error"
        isOpen={isOpen}
        onAfterOpen={this._onAfterOpen}
        onRequestClose={this._requestClose}
        size={'medium'}
        footerPrimaryActions={
          <button type="button" className={classes('ok-btn')} onClick={this._requestClose}>
            OK
          </button>
        }
      >
        <div className={classes('body-container')}>
          <div className={classes('description-container')}>
            <span>Your old password is incorrect, please try again</span>
          </div>
        </div>
      </Modal>
    );
  }

  _requestClose = () => {
    this.props.closeModal();
  };
}

export default WrongPasswordModal;
