import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { ReactComponent as PhoneIcon } from '../../images/phone-icon.svg';
import { ReactComponent as DownArrow } from '../../images/down-arrow.svg';
import { mobxInjectSelect } from '../../utils';
import { MobileQRLogin } from './';

const classes = BEM.with('MobileLogin');

class MobileLogin extends Component {
  static propTypes = {
    closeMobileQRLogin: PropTypes.func.isRequired,
    isMobileQRLoginOpen: PropTypes.bool.isRequired,
    generateQRImage: PropTypes.func.isRequired,
    openMobileQRLogin: PropTypes.func.isRequired,
  };

  render() {
    const { isMobileQRLoginOpen } = this.props;
    return (
      <div className={classes()}>
        <div className={classes('button')} onClick={this._mobileQRLogin}>
          <PhoneIcon className={classes('phone-icon')} />
          Mobile Login
          <div className={classes('hover-circle')}>
            <DownArrow className={classes('down-arrow')} />
          </div>
        </div>
        {isMobileQRLoginOpen && <MobileQRLogin />}
      </div>
    );
  }

  _mobileQRLogin = async () => {
    const { closeMobileQRLogin, isMobileQRLoginOpen, generateQRImage, openMobileQRLogin } =
      this.props;
    if (!isMobileQRLoginOpen) {
      generateQRImage();
      openMobileQRLogin();
    } else {
      closeMobileQRLogin();
    }
  };
}

export default mobxInjectSelect({
  userStore: ['closeMobileQRLogin', 'isMobileQRLoginOpen', 'generateQRImage', 'openMobileQRLogin'],
})(MobileLogin);
