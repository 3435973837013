import React, { useEffect, useRef, useState } from 'react';
import TCClient from '../../../../client';
import { DotsIndicator } from '../../../../common/components';
import Modal from '../../../../common/components/Modal';
import { Input } from '../../../shared-components/Input/Input';

import styles from './styles.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  mode:
    | {
        type: 'edit' | 'delete';
        data: { floorName: string; floorId: string };
      }
    | {
        type: 'create';
        data: { parentFacilityId: string };
      };
  openParent: (id: string, open: boolean) => void;
};

export const FloorModal = ({ isOpen, mode, onClose, openParent }: Props) => {
  const dispatch = useAppDispatch();
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);
  const isDeleteMode = mode.type === 'delete';
  const isEditMode = mode.type === 'edit';
  const isCreateMode = mode.type === 'create';
  const _floorName = isEditMode ? mode.data.floorName : '';
  const [floorName, setFloorName] = useState(_floorName);

  const header = isEditMode ? `Edit Floor` : isDeleteMode ? 'Delete Floor' : `Create new Floor`;

  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const [buttonHasFocus, setButtonHasFocus] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleMouseEnter = () => setButtonHasFocus(true);
  const handleMouseLeave = () => setButtonHasFocus(false);

  let formHasChanged = false;
  if (isEditMode) {
    formHasChanged = mode.data.floorName !== floorName;
  } else {
    formHasChanged = floorName !== '';
  }

  useEffect(() => {
    let ref: HTMLButtonElement | null = null;

    buttonRef.current?.addEventListener('mouseenter', handleMouseEnter);
    buttonRef.current?.addEventListener('mouseleave', handleMouseLeave);

    ref = buttonRef.current;

    return () => {
      if (ref) {
        ref.removeEventListener('mouseenter', handleMouseEnter);
        ref.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  });

  const close = () => {
    setFloorName(_floorName);
    setError('');
    onClose();
  };

  return (
    <Modal
      bodyClass={styles.body}
      closeClass={isDeleteMode ? styles.close : ''}
      headerClass={isDeleteMode ? styles.header : ''}
      header={header}
      isOpen={isOpen}
      size={'medium'}
      onRequestClose={close}
    >
      <>
        {(isEditMode || isCreateMode) && (
          <div>
            <div className={styles.errorContainer}>
              {error.length > 0 && <div className={styles.error}>{error}</div>}
            </div>
            <div className={styles.inputContainer}>
              <span className={styles.inputLabel}>Floor Name</span>
              <Input
                customCSS={styles.input}
                lengthLimit={100}
                onInputChange={(e) => setFloorName(e.target.value)}
                placeholder={`Type a floor name...`}
                value={floorName}
              />
            </div>
          </div>
        )}
        {isDeleteMode && (
          <div className={styles.bodyText}>
            <p>
              Are you sure you want to remove{' '}
              <span className={styles.bold}>{mode.data.floorName}?</span>
            </p>
          </div>
        )}
        <div className={styles.footer}>
          <div className={styles.footerLeft}></div>
          <div className={styles.footerRight}>
            <button
              aria-label="Cancel"
              className={styles.cancelButton}
              onClick={close}
              type="button"
            >
              Cancel
            </button>
            <button
              ref={buttonRef}
              disabled={!isDeleteMode ? !formHasChanged : false}
              aria-label={isDeleteMode ? 'Delete' : 'Save'}
              className={isDeleteMode ? styles.deleteButton : styles.saveButton}
              onClick={async () => {
                try {
                  setIsSaving(true);
                  if (isEditMode) {
                    await TCClient.multiOrg.updateArea({
                      id: mode.data.floorId,
                      orgId: selectedOrganizationId,
                      areaName: floorName,
                    });
                  } else if (isCreateMode) {
                    await TCClient.multiOrg.createArea({
                      orgId: selectedOrganizationId,
                      areaName: floorName,
                      parentFacilityId: mode.data.parentFacilityId,
                    });
                    openParent(mode.data.parentFacilityId, false);
                  } else {
                    await TCClient.multiOrg.deleteArea({
                      id: mode.data.floorId,
                      orgId: selectedOrganizationId,
                    });
                  }
                  setIsSaving(false);
                  dispatch(actions.setLocationsDataDirty({ isLocationsDataDirty: true }));
                  setFloorName(floorName);
                  setError('');
                  close();
                } catch (e) {
                  if (
                    e.text &&
                    typeof e.text === 'string' &&
                    e.text.includes('Invalid input for name.')
                  ) {
                    setError(`This Floor name is already in use`);
                  } else {
                    setError('Oops! Something went wrong, please try again');
                  }
                  setIsSaving(false);
                }
              }}
              type="button"
            >
              {isSaving ? (
                <DotsIndicator color={buttonHasFocus ? '#fff' : '#4580d8'} size={10} speed={0.5} />
              ) : isDeleteMode ? (
                'Delete'
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};
