import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { ReactComponent as MoreButtonSvg } from '../images/more-button.svg';
import { ContextMenu, MenuItemList, MenuItem } from './ContextMenu';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';

const classes = BEM.with('ConversationOptionMenu');

class ConversationOptionMenu extends Component {
  static propTypes = {
    conversation: propTypes.conversation.isRequired,
    isSelected: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    showLeaveGroupModal: PropTypes.func.isRequired,
    showMuteModal: PropTypes.func.isRequired,
    unmuteConversation: PropTypes.func.isRequired,
  };

  state = {
    isDeleteConversationModalOpen: false,
  };

  render() {
    const { conversation, isSelected } = this.props;
    const { counterParty, counterPartyType, isDeletable, isMuteable, isMuted } = conversation;
    const { groupType = 'GROUP', hasCurrentUser = false } = counterParty || {};

    if (!isMuteable && !isDeletable) return <div className={classes()} />;

    let deleteFragment;
    if (isDeletable) {
      if (counterPartyType === 'group') {
        if (groupType === 'ROLE_P2P') {
          deleteFragment = (
            <MenuItem onClick={this._showLeaveGroupModal} ariaLabel="Delete Role">
              Delete
            </MenuItem>
          );
        } else if (groupType === 'FORUM' && !hasCurrentUser) {
          deleteFragment = (
            <MenuItem onClick={this._showJoinForumModal} ariaLabel="Info Pane Join Group">
              Join
            </MenuItem>
          );
        } else if (groupType !== 'INTRA_TEAM') {
          deleteFragment = (
            <MenuItem onClick={this._showLeaveGroupModal} ariaLabel="Info Pane Leave Group">
              Leave
            </MenuItem>
          );
        }
      } else {
        deleteFragment = (
          <MenuItem onClick={this._showDeleteConversationModal} ariaLabel="Delete Conversation">
            Delete
          </MenuItem>
        );
      }
    }

    return (
      <div className={classes()}>
        <div className={classes('option-button')}>
          <ContextMenu
            event="click"
            position="bottomright"
            hideOnScroll={true}
            menu={
              <MenuItemList>
                {isMuteable && (
                  <MenuItem ariaLabel="Mute Modal Conversation" onClick={this._onMuteButtonClick}>
                    {isMuted ? 'Unmute' : 'Mute'}
                  </MenuItem>
                )}
                {deleteFragment}
              </MenuItemList>
            }
          >
            <MoreButtonSvg className={classes({ isSelected })} />
          </ContextMenu>
        </div>
        <ReduxEscapeHatch
          ref={(ref) => {
            this.reduxEscapeHatch = ref;
          }}
        />
      </div>
    );
  }

  _onMuteButtonClick = () => {
    const { conversation, showMuteModal, unmuteConversation } = this.props;
    if (conversation.isMuted) {
      unmuteConversation(conversation.id);
      this.reduxEscapeHatch.focusMessageBodyInput();
    } else {
      showMuteModal(conversation);
    }
  };

  _showDeleteConversationModal = () => {
    const { conversation, showDeleteConversationModal } = this.props;
    showDeleteConversationModal(conversation);
  };

  _showJoinForumModal = () => {
    const { conversation, openModal } = this.props;
    const { counterParty } = conversation;
    openModal('joinForum', { conversation, forum: counterParty });
  };

  _showLeaveGroupModal = () => {
    const { conversation, showLeaveGroupModal } = this.props;
    const { counterParty } = conversation;
    showLeaveGroupModal(counterParty);
  };
}

export default mobxInjectSelect({
  conversationStore: ['showDeleteConversationModal', 'showMuteModal', 'unmuteConversation'],
  groupEditorStore: ['showLeaveGroupModal'],
  modalStore: ['openModal'],
})(ConversationOptionMenu);
