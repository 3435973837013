import { useEffect, useRef, useState } from 'react';
import SearchTypes, {
  GROUP_MEMBER_SEARCH_TYPES,
  PROVIDER_SEARCH_TYPES,
} from '../../models/enums/SearchTypes';
import PerformanceKPITypes, { PerformanceKPIType } from '../../models/enums/PerformanceKPITypes';
import { FROZEN_EMPTY_ARRAY, mobxInjectSelect } from '../utils';
import type { Entity, Organization, User } from '../../types';

import RecipientSearchPicker from './RecipientSearchPicker';

type MobxProps = {
  hideExistingPatientConversations: () => void;
  selectedRecipients: Entity[];
  showExistingPatientConversations: (e: Entity) => void;
  promptToJoinForum: (e: Entity) => void;
  currentOrganization: Organization;
  isProviderNetwork?: boolean;
  clearSearchResults: () => void;
  closePickerMenu: () => void;
  setFilterBarByEntity: (e: Entity) => void;
  resetAutoLogout: () => void;
  performanceKpiEnd: (type: PerformanceKPIType) => void;
};

type NewMessageRecipientPickerProps = {
  className?: string;
  excludeBroadcastsWhenSenderIsRole?: boolean;
  selectedPatientReferenceId?: string;
  onChange?: (e: Entity[]) => void;
  onTab?: unknown;
  placeholder?: string;
  searchTypes?: string[];
  sender?: User;
  autoFocus?: boolean;
  openOnType?: boolean;
};

function NewMessageRecipientPicker(props: NewMessageRecipientPickerProps & MobxProps) {
  const {
    autoFocus,
    className,
    currentOrganization,
    excludeBroadcastsWhenSenderIsRole,
    isProviderNetwork,
    onTab,
    openOnType,
    performanceKpiEnd,
    placeholder,
    selectedPatientReferenceId,
    selectedRecipients,
    sender,
  } = props;
  const [searchTypes, setSearchTypes] = useState(props.searchTypes);
  const [selected, setSelected] = useState(selectedRecipients);
  const firstFocus = useRef(false);
  let isPatientOrContact;
  const excludeSenderId = [];

  useEffect(() => {
    setSelected(selectedRecipients);
  }, [selectedRecipients]);

  useEffect(
    function handleSearchTypes() {
      if (isProviderNetwork) {
        setSearchTypes(props.searchTypes);
      }
    },
    [props.searchTypes, setSearchTypes, isProviderNetwork]
  );

  if (sender) {
    excludeSenderId.push(sender.botRoleId ? sender.botRoleId : sender.id);
  }

  if (selected.length > 0) {
    const { isPatient, isPatientContact } = selected[0];
    isPatientOrContact = isPatient || isPatientContact;
  }

  function _onChange(selected = FROZEN_EMPTY_ARRAY as unknown as Entity[]) {
    const {
      hideExistingPatientConversations,
      onChange,
      promptToJoinForum,
      resetAutoLogout,
      setFilterBarByEntity,
      showExistingPatientConversations,
    } = props;
    let { searchTypes } = props;

    if (selected.length > 0) {
      const {
        $entityType: entityType,
        groupType,
        hasCurrentUser,
        isPatient,
        isPatientContact,
      } = selected[0];
      const patientOrContact = isPatient || isPatientContact;

      setFilterBarByEntity(selected[0]);

      if (patientOrContact) {
        searchTypes = [SearchTypes.USER];
      } else if (
        entityType === 'user' ||
        entityType === 'role' ||
        entityType === 'team' ||
        entityType === 'careTeam'
      ) {
        searchTypes = GROUP_MEMBER_SEARCH_TYPES;
      } else if (groupType === 'FORUM' && !hasCurrentUser) {
        promptToJoinForum(selected[0]);
        selected = FROZEN_EMPTY_ARRAY as unknown as Entity[];
      } else {
        searchTypes = FROZEN_EMPTY_ARRAY as unknown as string[];
      }

      if (patientOrContact && selected.length === 1) {
        showExistingPatientConversations(selected[0]);
      } else {
        hideExistingPatientConversations();
      }
    } else {
      hideExistingPatientConversations();
    }

    setSelected([...selected]);
    setSearchTypes([...(searchTypes || [])]);

    resetAutoLogout();
    if (onChange) onChange(selected);
  }

  function _onBlur() {
    if (selected.length === 0) {
      setSearchTypes(props.searchTypes);
    }
  }

  function _onFocus() {
    if (firstFocus.current) return;
    firstFocus.current = true;
    performanceKpiEnd(PerformanceKPITypes.COMPOSE);
  }

  return (
    <RecipientSearchPicker
      {...{
        className,
        excludeBroadcastsWhenSenderIsRole,
        onTab,
        placeholder,
        searchTypes,
        selected,
        sender,
      }}
      ariaLabelItem={'Conversation New Message Select User'}
      ariaLabelSelect={'Conversation New Message Add User'}
      enabledCapabilities={isPatientOrContact ? ['patient_network'] : undefined}
      selectedPatientReferenceId={selectedPatientReferenceId}
      excludeIds={excludeSenderId}
      excludeMyRoles={true}
      excludeRoles={isPatientOrContact}
      excludeTeams={!currentOrganization.showTeams}
      excludeIntraTeams={true}
      isAutoSized={true}
      isSingleRecipient={false}
      multi={true}
      onBlur={_onBlur}
      onChange={_onChange}
      onFocus={_onFocus}
      organization={currentOrganization}
      tabIndex={0}
      autoFocus={autoFocus}
      openOnType={openOnType}
    />
  );
}

NewMessageRecipientPicker.defaultProps = {
  placeholder: 'Type the name of a person or group',
  searchTypes: PROVIDER_SEARCH_TYPES,
};

export default mobxInjectSelect<NewMessageRecipientPickerProps, MobxProps>({
  composeMessageStore: [
    'hideExistingPatientConversations',
    'selectedRecipients',
    'showExistingPatientConversations',
  ],
  groupStore: ['promptToJoinForum'],
  messengerStore: ['currentOrganization'],
  networkStore: ['isProviderNetwork'],
  rosterStore: ['setFilterBarByEntity'],
  sessionStore: ['resetAutoLogout'],
  trackerStore: ['performanceKpiEnd'],
})(NewMessageRecipientPicker);
