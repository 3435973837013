import React, { useCallback } from 'react';

import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';
import { StandardOrgSettingInput } from '../../../../shared-components/StandardSettingInput';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';

import styles from '../../OrganizationSettings.module.css';
import { TabProps } from '../';
import { HttpsComponent } from './HttpsComponent';
import { LdapsComponent } from './LdapsComponent';
import { SamlComponent } from './SamlComponent';
import LoginWorkflowComponent from './LoginWorkflowComponent';
import { thunk, useAppDispatch, useAppSelector } from 'redux-stores';

const authOptions = [
  {
    label: 'HTTPS (basic)',
    value: 'basic',
  },
  {
    label: 'HTTPS (NTLM)',
    value: 'ntlm',
  },
  {
    label: 'LDAPS',
    value: 'ldap',
  },
  {
    label: 'SAML',
    value: 'saml',
  },
  {
    label: 'None',
    value: 'native',
  },
];

const AuthComponents = ({ onClose }: TabProps) => {
  const dispatch = useAppDispatch();
  const authSetting = useAppSelector((state) => state.orgSettings.settings.authType ?? 'native');

  const onSave = useCallback(
    () => thunk.saveSettings({ dispatch, location: 'authentication' }),
    [dispatch]
  );

  let childComponentFragment = <></>;

  switch (authSetting) {
    case 'saml':
      childComponentFragment = <SamlComponent />;
      break;
    case 'ldap':
      childComponentFragment = <LdapsComponent />;
      break;
    case 'native':
      childComponentFragment = <LoginWorkflowComponent isOTPLoginDisabled={true} />;
      break;
    case 'basic':
    case 'ntlm':
      childComponentFragment = <HttpsComponent />;
      break;
  }

  return (
    <ViewContainer
      {...{
        header: 'Authentication',
        subHeader:
          'Set up and manage this organization with external provisioning and authentication.',
        onClose,
      }}
    >
      <div className={styles.flexListWithDirection}>
        <div className={styles.flexListStyleWithPadding}>
          <StandardOrgSettingInput type="radio" options={authOptions} settingName="authType" />
        </div>
        {childComponentFragment}
        <div>
          <CtaButton primary label="Save Auth Settings" onClick={onSave} />
        </div>
      </div>
    </ViewContainer>
  );
};

export default AuthComponents;
