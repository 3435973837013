import React from 'react';
import { DotsIndicator } from '../../';
import BEM from '../../../bem';

const classes = BEM.with('ProcessingIndicator');

const ProcessingIndicator = () => (
  <div className={classes()}>
    <p className={classes('text')}>Uploading</p>
    <DotsIndicator color={'#ffffff'} size={13} />
  </div>
);
export default ProcessingIndicator;
