import BaseService from '../BaseService';

import {
  UserData,
  ServerUserSettings,
  UserSettings,
  UserInfo,
  ServerUserInfo,
  PendingUser,
  ServerPendingUser,
  UserOrganizationSettings,
  ServerDevice,
  ServerUserData,
} from './utils/types';

import {
  parseServerUserSettings,
  parseServerUserData,
  formatServerAccountSettings,
  formatServerUserInfo,
  parseServerAccountSearch,
  parseServerUserOrganizationSettings,
  parseServerDevices,
  parseServerPendingUsers,
} from './utils/userSettingsHelpers';

export class UsersService extends BaseService {
  async getAccountSettings({
    userId,
    organizationId,
  }: {
    userId?: string;
    organizationId?: string;
  } = {}): Promise<UserData> {
    const res = await this.host.api.adminUsers.getAccountSettings({
      userId,
      organizationId,
    });
    const userData = parseServerUserData(res);
    return userData;
  }

  async getAccountSettingRaw({
    userId,
    organizationId,
  }: {
    userId?: string;
    organizationId?: string;
  } = {}): Promise<ServerUserData> {
    const res = await this.host.api.adminUsers.getAccountSettings({
      userId,
      organizationId,
    });
    return res;
  }

  parseUserServerData = parseServerUserData;

  async setAccountSettings({
    userId,
    settings,
  }: {
    userId: string;
    settings: {
      set: Record<string, unknown>;
      del: Record<string, unknown>;
    };
  }) {
    return this.host.api.adminUsers.setAccountSettings({
      userId,
      settings,
    });
  }

  async unlinkPhoneNumber({
    userId,
    organizationId,
    phoneNumber,
  }: {
    userId: string;
    organizationId: string;
    phoneNumber: string;
  }) {
    return this.host.api.adminUsers.unlinkPhoneNumber({ id: userId, organizationId, phoneNumber });
  }

  async updateUserInfo({
    userInfoDiff,
    orgToken,
    userId,
  }: {
    userInfoDiff: Partial<UserInfo>;
    orgToken: string;
    userId: string;
  }) {
    const formattedUserInfo = formatServerUserInfo(userInfoDiff);

    await this.host.api.adminUsers.setAccountSettings({
      userId: userId,
      settings: {
        set: {
          ...(userInfoDiff.displayName && { display_name: userInfoDiff.displayName }),
          orgs: [{ organization_id: orgToken, settings: formattedUserInfo }],
        },
        del: {},
      },
    });
  }

  async updateUserSettings({
    changedUserSettings,
    orgToken,
    userId,
  }: {
    changedUserSettings?: UserSettings;
    orgToken: string;
    userId: string;
  }) {
    const PatientEngagementSettings = [
      'call_scheduler',
      'group_audio_call',
      'group_video_call',
      'mfa',
      'patient_context',
      'pbx_integration',
      'pf_video_call',
      'pf_voip',
      'pf_quick_add',
      'pf_group_audio_call',
      'pf_group_video_call',
      'pf_quick_call',
      'pf_quick_video',
      'patient_network',
      'pf_patient_list_access',
      'pf_broadcast',
      'pf_schedule_messages',
      'pf_virtual_waiting_room',
      'teams',
    ] as const;

    type PatientEngagementSetting = typeof PatientEngagementSettings[number];

    if (changedUserSettings) {
      const formattedUserSettings = formatServerAccountSettings(changedUserSettings);
      const patientEngagementSettings = PatientEngagementSettings.reduce((memo, k) => {
        if (formattedUserSettings[k] === undefined) return memo;
        return { ...memo, [k]: formattedUserSettings[k] };
      }, {});

      if (Object.keys(patientEngagementSettings).length > 0) {
        await this.postOrganization({
          orgId: orgToken,
          userId: userId,
          data: patientEngagementSettings,
        });
      }

      for (const key in formattedUserSettings) {
        if (
          formattedUserSettings[key as keyof typeof formattedUserSettings] === undefined ||
          PatientEngagementSettings.includes(key as PatientEngagementSetting)
        )
          delete formattedUserSettings[key as keyof typeof formattedUserSettings];
      }

      if (Object.keys(formattedUserSettings).length > 0) {
        await this.host.api.adminUsers.setAccountSettings({
          userId: userId,
          settings: {
            set: {
              orgs: [{ organization_id: orgToken, settings: formattedUserSettings }],
            },
            del: {},
          },
        });
      }
    }
  }

  async getUserSettings({
    userId,
    organizationId,
  }: {
    userId: string;
    organizationId: string;
  }): Promise<UserSettings> {
    const res = (await this.host.api.adminUsers.getUserSettings({ userId, organizationId })) as {
      settings: { value: unknown; name: keyof ServerUserSettings }[];
    };
    const settings = res.settings.reduce((m, v) => {
      return {
        ...m,
        [v.name]: v.value,
      };
    }, {} as ServerUserSettings);
    const user_settings = parseServerUserSettings(settings);
    return user_settings;
  }

  async accountSearch({
    searchInput,
    organizationId,
  }: {
    searchInput: string;
    organizationId: string;
  }) {
    const data = await this.host.api.adminUsers.accountSearch({ searchInput, organizationId });
    if (Array.isArray(data.entities.entity) && data.entities.entity[0]) {
      data.entities.entity = { ...data.entities.entity[0] };
    }
    return parseServerAccountSearch(data);
  }

  async createUser({
    organizationId,
    ...formData
  }: {
    organizationId: string;
    displayName: string;
    firstName: string;
    lastName: string;
    department: string;
    title: string;
    emailAddress: string;
    password: string;
    mobilePhone?: string;
  }) {
    return await this.host.api.adminUsers.createUser({
      formData,
      organizationId,
    });
  }

  async getPendingUsers(orgId: string, query?: string): Promise<PendingUser[]> {
    const res = (await this.host.api.adminUsers.getPendingUsers(
      orgId,
      query
    )) as ServerPendingUser[];
    return parseServerPendingUsers(res);
  }

  async addPendingUser({
    organization,
    emailAddress,
    username,
  }: {
    organization: string;
    emailAddress: string;
    username: string;
    token: string;
    id: string;
  }) {
    const settings = { email_address: emailAddress };
    await this.host.api.adminUsers.addPendingUser({
      organization_id: organization,
      username,
      settings,
    });
  }

  async declinePendingUser({ token, organization }: { token: string; organization: string }) {
    await this.host.api.adminUsers.declinePendingUser({
      token: token,
      organization_id: organization,
    });
    await this.remoteWipe({
      orgId: organization,
      userId: token,
    });
  }

  async fetchOrganizationSettings({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<UserOrganizationSettings> {
    const settings = await this.host.api.adminUsers.fetchOrganizationSettings({ organizationId });
    const orgSettings = parseServerUserOrganizationSettings(settings);
    return orgSettings;
  }

  async getUserSecurityGroups({ orgId, userId }: { orgId: string; userId: string }) {
    const { security_groups } =
      (await this.host.api.adminOrganizations.getOrganizationSecurityGroups({
        orgId,
      })) as { security_groups: string[] };

    const { securityGroups: allUserGroups } = (await this.host.api.adminUsers.getUserSecurityGroups(
      {
        orgId,
        userId,
      }
    )) as { securityGroups: { token: string; groups: string[] }[] };

    const userGroups = allUserGroups.find((g) => g.token === orgId)?.groups || [];

    const userGroupsMap = userGroups.reduce((m, v) => {
      return { ...m, [v]: true };
    }, {} as Record<string, boolean>);

    const groupsMap = security_groups.reduce((m, v) => {
      return { ...m, [v]: userGroupsMap[v as keyof typeof userGroupsMap] || false };
    }, {} as Record<string, boolean>);

    return groupsMap;
  }

  async updateUserSecurityGroups({
    orgId,
    userId,
    groups,
  }: {
    orgId: string;
    userId: string;
    groups: string[];
  }) {
    return await this.host.api.adminUsers.updateUserSecurityGroups({
      orgId,
      userId,
      groups,
    });
  }

  async getDevices({ orgId, userId }: { orgId: string; userId: string }) {
    const { resources } = (await this.host.api.adminUsers.getDevices({ orgId, userId })) as {
      resources: ServerDevice[];
    };
    const parsed = parseServerDevices(resources);
    return parsed;
  }

  async getUserPermissions({ orgId, userId }: { orgId: string; userId: string }) {
    const { roles } = await this.host.api.adminUsers.getUserPermissionRoles({
      orgId,
      userId,
    });
    const org = roles.find((org) => org.organization?.organization_id === orgId);
    const userPermissions = org?.organization?.roles || [];

    const userPermissionsMap = userPermissions.reduce((m, v) => {
      return { ...m, [v]: true };
    }, {} as Record<string, boolean>);

    const { roles: permissionRoles } =
      (await this.host.api.adminOrganizations.getAllPermissionRoles({
        orgId,
      })) as { roles: string[] };

    const mappedPermissions = permissionRoles.reduce((m, v) => {
      return { ...m, [v]: userPermissionsMap[v] || false };
    }, {} as Record<string, boolean>);

    return mappedPermissions;
  }

  async updateUserPermissionRoles<M extends Record<string, boolean>>({
    orgId,
    userId,
    permissions,
    diffPermissions,
  }: {
    orgId: string;
    userId: string;
    permissions: M;
    diffPermissions: M;
  }) {
    const actions = Object.keys(diffPermissions).reduce((m, p) => {
      const permission = p as keyof typeof permissions;
      const init = diffPermissions[permission];
      const subs = permissions[permission];
      if (init === true && subs === false) {
        return { ...m, [permission]: 'DEL' };
      }
      if (init === false && subs === true) {
        return { ...m, [permission]: 'ADD' };
      }
      return m;
    }, {});

    await Promise.all(
      Object.keys(actions).map(async (permission) => {
        await this.host.api.adminUsers.updateUserPermissionRole({
          orgId,
          userId,
          permission,
          action: actions[permission as keyof typeof actions],
        });
      })
    );
  }

  async deletePagerNumber({ pagerNumber, userId }: { pagerNumber: string; userId: string }) {
    const settings = {
      set: {},
      del: {
        pager_number: pagerNumber,
      },
    };

    await this.host.api.adminUsers.setAccountSettings({
      userId,
      settings,
    });

    await this.host.api.adminUsers.deletePagerNumber(pagerNumber);
  }

  async setIncomingNumber({
    number,
    special,
  }: {
    number: string;
    special: boolean;
  }): Promise<undefined> {
    await this.host.api.adminUsers.setIncomingNumber({
      number,
      special,
    });

    return undefined;
  }

  async updateUserPagerPriority({
    pager,
    value,
    userId,
  }: {
    pager: { number: unknown; name: string };
    value: boolean;
    userId: string;
  }): Promise<undefined> {
    const settings = {
      set: {
        pager_number: [
          {
            pager_number: pager.number,
            number: pager.number,
            label: pager.name,
            priority: value ? 0 : 1,
          },
        ],
      },
      del: {},
    };

    await this.host.api.adminUsers.setAccountSettings({
      userId,
      settings,
    });

    return undefined;
  }

  async getAvailablePagerNumbers({
    pagerNumber,
  }: {
    pagerNumber: string;
  }): Promise<{ name: string; number: string }[]> {
    const data = await this.host.api.adminUsers.getAvailablePagerNumbers(pagerNumber);
    return data.map((n) => ({ number: n.phone_number, name: n.friendly_name }));
  }

  async getUserPagers({
    userId,
    orgId,
  }: {
    userId: string;
    orgId: string;
  }): Promise<{ id: string; name: string; number: string; priority: boolean }[]> {
    const { pager_numbers } = await this.host.api.adminUsers.getUserPagers({ userId, orgId });

    const pagers = await Promise.all(
      pager_numbers.map(async (p) => {
        const data = await this.host.api.adminUsers.getPagerData({
          phoneNumber: p.pager_number,
        });

        return {
          id: p.pager_number,
          name: p.label,
          number: p.pager_number,
          priority: Boolean(JSON.parse(data as string).message_priority),
        };
      })
    );

    return pagers;
  }

  async getApiKeys({ orgId, userId }: { orgId: string; userId: string }) {
    return this.host.api.adminUsers.getApiKeys({ orgId, userId });
  }

  async deleteApiKey({ apiKey, orgId, userId }: { apiKey: string; orgId: string; userId: string }) {
    return this.host.api.adminUsers.deleteApiKey({ apiKey, orgId, userId });
  }

  async createApiKey({ orgId, userId }: { orgId: string; userId: string }) {
    return this.host.api.adminUsers.createApiKey({ orgId, userId });
  }

  async savePassword({
    orgId,
    password,
    userId,
  }: {
    orgId: string;
    password: string;
    userId: string;
  }) {
    return this.host.api.adminUsers.savePassword({ orgId, password, userId });
  }

  async resetPassword({ email }: { email: string }) {
    return this.host.api.adminUsers.resetPassword({ email });
  }

  async remoteWipe({
    orgId,
    resource,
    userId,
  }: {
    orgId: string;
    resource?: string;
    userId: string;
  }) {
    return this.host.api.adminUsers.remoteWipe({ orgId, resource, userId });
  }

  async accountDelete({ userId }: { userId: string }) {
    return await this.host.api.adminUsers.accountDelete({ userId });
  }

  async getUserProductRoles({
    userId,
    organizationId,
  }: {
    userId: string;
    organizationId: string;
  }) {
    return this.host.api.adminUsers.getUserProductRoles({ userId, organizationId });
  }

  async updateUserProductRole({
    userId,
    orgId,
    product,
    action,
  }: {
    userId: string;
    orgId: string;
    product: 'tt_admin' | 'tt_ldap' | 'tt_super_admin' | 'tt_reporting';
    action: 'DEL' | 'ADD';
  }) {
    return await this.host.api.adminUsers.updateUserProductRole({
      orgId,
      product,
      action,
      userId,
    });
  }

  async getUserPermissionRoles({ userId, orgId }: { userId: string; orgId: string }) {
    return this.host.api.adminUsers.getUserPermissionRoles({ userId, orgId });
  }

  async csvUploadUsers({ file, orgId }: { file: unknown; orgId: string }) {
    return this.host.api.adminUsers.csvUploadUsers({
      file,
      orgId,
    });
  }

  async getOrganization({ orgId, userId }: { orgId: string; userId: string }) {
    return this.host.api.adminUsers.getOrganization({ orgId, userId });
  }

  async postOrganization({
    data,
    orgId,
    userId,
  }: {
    data: Record<string, unknown>;
    orgId: string;
    userId: string;
  }) {
    return this.host.api.adminUsers.postOrganization({ data, orgId, userId });
  }

  async organizationLeave({ orgId, userId }: { orgId: string; userId: string }) {
    return this.host.api.adminUsers.organizationLeave({ orgId, userId });
  }

  async unlockUser({ userToken }: { userToken: string }) {
    return this.host.api.adminUsers.unlockUser({ userToken });
  }
}
