import React, { useEffect, useState } from 'react';
import { mobxInjectSelect } from '../../utils';
import BasicModal from '../BasicModal';
import { Response } from '../CustomResponseList/CustomResponseList';
import QuickReplyCustomResponseList from './QuickReplyCustomResponseList';

type QuickReplyModalContainerProps = {
  isOpen: boolean;
  closeModal: () => void;
};

type MobxProps = {
  editPreferences: (replies: Response[]) => Promise<void>;
  getPreferences: () => Promise<Response[]>;
};

function QuickReplyModalContainer(props: QuickReplyModalContainerProps & MobxProps) {
  const { isOpen, closeModal, editPreferences, getPreferences } = props;
  const [quickReplies, setQuickReplies] = useState<Response[]>([]);

  useEffect(() => {
    const getQuickReplies = async () => {
      const replies = await getPreferences();
      setQuickReplies(replies);
    };
    if (isOpen) {
      getQuickReplies();
    }
  }, [getPreferences, isOpen]);

  async function saveReplies() {
    await editPreferences(quickReplies);
    closeModal();
  }

  return (
    <BasicModal
      ariaLabelCancelButton="QuickReply Cancel"
      ariaLabelCloseButton="QuickReply Close"
      ariaLabelHeader="QuickReply Header"
      ariaLabelSubmitButton="QuickReply Submit"
      headerText="Quick Reply"
      onClose={closeModal}
      isOpen={isOpen}
      onSubmit={saveReplies}
      submitText="SAVE"
      hasCloseButton
      size={'large'}
      type="empty"
      useWCL
    >
      <div style={{ padding: '30px' }}>
        <QuickReplyCustomResponseList
          responses={quickReplies}
          setResponses={setQuickReplies}
          maxHeight={'400px'}
        />
      </div>
    </BasicModal>
  );
}

export default mobxInjectSelect<QuickReplyModalContainerProps, MobxProps>({
  messengerStore: ['getPreferences', 'editPreferences'],
})(QuickReplyModalContainer);
