import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Switch } from '../../WebComponents';
import { Role } from '../../../../types';
import { actions } from '../../../../redux-stores';
import BEM from '../../../bem';

const { setModal } = actions;
const classes = BEM.with('CollaborationEntityEditor');

type RoleEditorPagerProps = {
  role: Role;
};

export default function RoleEditorPager({ role }: RoleEditorPagerProps) {
  const dispatch = useDispatch();

  const requestChangePager = (e: React.MouseEvent) => {
    if (role.orgPagers && role.orgPagers.length > 0) {
      dispatch(setModal({ name: 'removePager' }));
    } else {
      dispatch(setModal({ name: 'pager' }));
    }
  };

  let prettyNumber = '';

  const hasPager = role.orgPagers && role.orgPagers.length > 0;
  if (hasPager) {
    const number = role.orgPagers?.[0]?.replace('+', '');
    prettyNumber = `(${number?.substring(1, 4)}) ${number?.substring(4, 7)}-${number?.substring(
      7,
      11
    )}`;
  }

  return (
    <div className={classes('card-container')}>
      <Card header="Tiger page">
        <div className={classes('switch-block')}>
          <div className={classes('switch-item')} onClick={requestChangePager}>
            {hasPager ? (
              <div className={classes('pager-number-container')}>
                <span>{prettyNumber}</span>
              </div>
            ) : (
              <span>Assign a unique pager number for this role</span>
            )}
            <Switch checked={!!hasPager} dataTestId="enablePager" />
          </div>
        </div>
      </Card>
    </div>
  );
}
