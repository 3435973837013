import React, { useEffect, useRef } from 'react';
import { Template } from '../../../types';
import BEM from '../../bem';
import { useInfiniteSearchResults } from '../../hooks';
import { ReactComponent as CheckSvg } from '../../images/selected-check.svg';
import { mobxInjectSelect } from '../../utils';
import { DotsIndicator } from '../';
import { MenuItem, MenuItemList } from '../ContextMenu';
import { KEYMAP } from 'common/constants';

type TemplateListProps = {
  repository: string;
  selectedTemplate: Template;
  setSelectedTemplate: (template: Template) => void;
  shouldFilterSms?: boolean;
  accessibilityMode?: boolean;
};

type MobxProps = {
  loadTemplates: () => void;
  allowPatientDeliveryMethod: boolean;
};

const classes = BEM.with('TemplateMenu');

const TemplateList = ({
  allowPatientDeliveryMethod,
  loadTemplates,
  repository,
  selectedTemplate,
  setSelectedTemplate,
  shouldFilterSms = false,
  accessibilityMode = false,
}: TemplateListProps & MobxProps) => {
  const { isLoading, results, resetSearch, scrollContainerRef, updateOptions } =
    useInfiniteSearchResults(loadTemplates, {
      sortBy: 'template_label',
      sortOrder: 'asc',
      ...(allowPatientDeliveryMethod ? (shouldFilterSms ? { isSmsCompatible: true } : null) : null),
    });

  const reloadSearch = useRef((options: Record<string, unknown>, refreshSearch = true) => {
    resetSearch();
    updateOptions(options, refreshSearch);
  });

  useEffect(() => {
    reloadSearch.current({ repository });
  }, [repository]);

  return (
    <div
      className={classes('menu-list-scroller')}
      ref={scrollContainerRef}
      role="tabpanel"
      id={`${repository}-templates-tabpanel`}
      aria-labelledby={`${repository}-templates-button`}
      onKeyDown={(e) => {
        if (!accessibilityMode) return;
        if (e.key === KEYMAP.TAB) {
          const activeEl = document.activeElement;
          let tabButton: Element | null = document.querySelector('.tc-MenuItemList__menu-item');
          if (!tabButton || activeEl === tabButton) {
            tabButton = document.querySelector('.tc-TemplateMenu__title-buttons');
          }
          tabButton && (tabButton as HTMLElement).focus();
        }
      }}
    >
      <MenuItemList className={classes('menu-list')} accessibilityMode={accessibilityMode}>
        {results.map((template: Template) => (
          <MenuItem
            ariaLabel={template.title}
            className={classes('menu-item', {
              selected: selectedTemplate.id === template.id,
            })}
            key={template.id}
            onClick={() => {
              setSelectedTemplate(template);
              if (accessibilityMode) {
                const parentButton = document.querySelector('.tc-MessageInputs__template-menu-btn');
                if (parentButton) {
                  (parentButton as HTMLElement).focus();
                }
              }
            }}
          >
            <div> {template.title} </div>
            {selectedTemplate.id === template.id && (
              <div>
                <CheckSvg />
              </div>
            )}
          </MenuItem>
        ))}
      </MenuItemList>
      <div className={classes('loading-more', { isLoadingMore: isLoading })}>
        <DotsIndicator color={'#969696'} size={18} />
      </div>
    </div>
  );
};

export default mobxInjectSelect<TemplateListProps, MobxProps>({
  patientAdminStore: ['loadTemplates', 'allowPatientDeliveryMethod'],
})(TemplateList);
