import React, { useState, useMemo, ChangeEvent } from 'react';
import { format } from 'date-fns';
import omit from 'lodash/omit';

import { mobxInjectSelect } from '../../../common/utils';
import TCClient from '../../../client';

import searchLoader from '../../images/loader_search.gif';
import { PageHeader } from '../../shared-components/PageHeader/PageHeader';
import { ActionBar } from '../../shared-components/ActionBar/ActionBar';
import { Table, RowsSelected } from '../../shared-components/Table/Table';
import { CtaButton } from '../../shared-components/CtaButton/CtaButton';
import avatarNoIconBG from '../../images/test_avatar_noicon.png';

import styles from './index.module.css';
import { MDYYYY_DATE_FORMAT } from 'common/constants';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';
import { Organization, UserSDK } from 'admin/types';
import {
  UserResult,
  useGetUsersQuery,
  useRemoveUserFromOrgMutation,
} from 'redux-stores/admin/usersApi';
import { useRTKQueryInfiniteResults } from 'common/hooks/useRTKQueryInfiniteResults';

const { setUsersPageView, viewUserPage } = actions;

const USER_COLUMNS = [
  {
    field: 'avatar',
    headerName: '',
  },
  {
    field: 'firstName',
    headerName: 'First Name',
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
  },
  {
    field: 'username',
    headerName: 'Username',
  },
  {
    field: 'phones',
    headerName: 'Mobile',
  },
  {
    field: 'formattedActivationTime',
    headerName: 'Activation',
  },
];

type UsersProps = {
  selectedOrganization: Organization;
  onScrollCallBackRef: React.RefObject<(e: React.UIEvent<HTMLElement>) => void>;
};

type MobxProps = {
  isProductSettingsAdmin: boolean;
};

function Users({
  isProductSettingsAdmin,
  selectedOrganization,
  onScrollCallBackRef,
}: UsersProps & MobxProps) {
  const dispatch = useAppDispatch();
  const authenticationType = useAppSelector((state) => state.orgSettings.settings.authType);

  const [inputValue, setInputValue] = useState('');
  const [usersSelected, setUsersSelected] = useState<RowsSelected>({});

  const { name, token } = selectedOrganization || {};

  const {
    results: users,
    response,
    isFetching,
  } = useRTKQueryInfiniteResults(
    useGetUsersQuery,
    {
      args: { orgId: token, searchValue: inputValue },
    },
    { customScrollFn: onScrollCallBackRef }
  );
  const { continuation, totalHits } = response?.metadata || {};

  const [removeUserFromOrg, removeUserFromOrgResult] = useRemoveUserFromOrgMutation();

  function navigateToAddUserForm() {
    dispatch(setUsersPageView({ selectedUsersPageView: 'add' }));
  }

  function navigateToImportUsersPage() {
    dispatch(setUsersPageView({ selectedUsersPageView: 'import' }));
  }

  function navigateToUserPage(user: UserSDK) {
    dispatch(viewUserPage(user));
  }

  const selectedUsersCount = Object.keys(usersSelected).length;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event?.target?.value);
  };

  async function removeSelectedUsers() {
    const didUserConfirm = await window.confirm(
      'Are you sure you want to Remove selected user(s)?'
    );
    if (didUserConfirm) {
      const userIds = Object.keys(usersSelected);
      try {
        await removeUserFromOrg({ userIds, orgId: token, inputValue }).unwrap();
        setUsersSelected({});
      } catch (error) {
        console.error('Could not remove user(s)', error);
      }
    }
  }

  async function sendWelcomeEmailToSelectedUsers() {
    const didUserConfirm = await window.confirm('Are you sure you want to Email selected user(s)?');
    if (didUserConfirm) {
      try {
        await TCClient.adminOrganizations.sendWelcomeEmail({
          userIds: Object.keys(usersSelected),
          organizationId: token,
        });
        setUsersSelected({});
      } catch (error) {
        console.error('Could not send welcome email', error);
      }
    }
  }

  const rowData = useMemo(() => {
    if (!users) return [];
    return users.map(({ entity, entityId }: UserResult) => {
      if (entity?.roles) {
        entity.roles = entity.roles.map((role) => {
          return {
            ...role,
            ...(role.members && { members: role.members.map((user) => omit(user, 'roles')) }),
          };
        });
      }

      if (Array.isArray(entity.phones)) {
        entity.phones = entity.phones[0];
      }
      entity.avatar = entity.avatarUrl || avatarNoIconBG;
      entity.association = entity.isExternal === '' ? 'Normal' : 'External';
      entity.id = entityId || entity.token;
      entity.formattedActivationTime = !entity.activationTime
        ? 'Not Activated'
        : format(new Date(entity.activationTime), MDYYYY_DATE_FORMAT);
      return entity;
    });
  }, [users]);

  const userCountText = isFetching
    ? 'Searching...'
    : totalHits !== 0
    ? `${totalHits} users`
    : 'No users found';

  if (
    (isProductSettingsAdmin || (authenticationType && authenticationType !== 'native')) &&
    USER_COLUMNS[4].field !== 'association'
  ) {
    USER_COLUMNS.splice(4, 0, {
      field: 'association',
      headerName: 'Association',
    });
  }

  return (
    <div className={styles.root}>
      <PageHeader
        label={'Users Directory '}
        subLabel={name}
        dataTestIdHeader={'usersDirectoryHeader'}
        dataTestIdSubHeader={'userOrgHeader'}
      />
      <ActionBar
        hasSearch={true}
        onInputChange={handleInputChange}
        searchValue={inputValue}
        dataTestId={'searchUser'}
        handleClearClick={() => {
          setUsersSelected({});
          setInputValue('');
        }}
      />
      <div className={styles.tableActionsContainer}>
        <div className={styles.buttonContainer}>
          {selectedUsersCount > 0 && (
            <>
              {removeUserFromOrgResult.isLoading ? (
                <div className={styles.removingUsers}>
                  <img src={searchLoader} className={styles.loadingImage} alt="" />
                  <div>Removing</div>
                </div>
              ) : (
                <CtaButton
                  label={`Remove Selected (${selectedUsersCount})`}
                  primary
                  onClick={removeSelectedUsers}
                  dataTestId="removeSelected"
                />
              )}
              <CtaButton
                label={`Send Welcome Email (${selectedUsersCount})`}
                primary
                onClick={sendWelcomeEmailToSelectedUsers}
                dataTestId="sendWelcomeEmail"
              />
              <div className={styles.pipe}>|</div>
            </>
          )}
          <CtaButton
            label={'Add User'}
            primary
            onClick={navigateToAddUserForm}
            dataTestId="addUser"
          />
          <CtaButton
            label={'Import Users'}
            primary
            onClick={navigateToImportUsersPage}
            dataTestId="importUsers"
          />
        </div>
        <div className={styles.userCount} data-test-id="userCount">
          {userCountText}
        </div>
      </div>
      {users && users.length > 0 && (
        <Table
          actionsOrder={[{ name: 'Edit', type: 'edit' }]}
          hasCheckbox={true}
          onRowClick={navigateToUserPage}
          onRowEdit={navigateToUserPage}
          onRowSelect={setUsersSelected}
          rowsSelected={usersSelected}
          columns={USER_COLUMNS}
          rows={rowData}
          isLoading={!!continuation && isFetching}
        />
      )}
    </div>
  );
}

export default mobxInjectSelect<UsersProps, MobxProps>({
  messengerStore: ['isProductSettingsAdmin'],
})(Users);
