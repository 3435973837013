import React, { Component } from 'react';
import PropTypes from 'prop-types';

import mobxInjectSelect from '../../../utils/mobxInjectSelect';
import BEM from '../../../bem';
import propTypes from '../../../propTypes';
import { isSingleEmoji } from '../../../utils';
import { MessageAttachmentList } from '..';

const classes = BEM.with('MessageContent');

class MessageNormalBody extends Component {
  static propTypes = {
    message: propTypes.message.isRequired,
    multiSelectable: PropTypes.bool.isRequired,
    richTextFormat: PropTypes.bool.isRequired,
  };

  render() {
    const { bodyText, message, multiSelectable, richTextFormat } = this.props;

    let styles;
    if (isSingleEmoji(message.body)) {
      styles = 'emoji-text';
    } else if (richTextFormat) {
      styles = 'code-container';
    } else {
      styles = 'text-container';
    }

    return (
      <div className={classes('body')}>
        <div className={classes(styles)} id={`${message.sortNumber}-body`}>
          {bodyText}
        </div>
        <MessageAttachmentList message={message} multiSelectable={multiSelectable} />
      </div>
    );
  }
}

export default mobxInjectSelect({
  localStore: ['richTextFormat'],
})(MessageNormalBody);
