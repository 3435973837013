import createMobxModel from './createMobxModel';

const UserProfile = createMobxModel({
  name: 'userProfile',

  fields: [
    'conversationId',
    'department',
    'dndAutoForwardEntities',
    'organizationId',
    'title',
    'userId',
  ],

  relations: {
    many: {
      autoForwardReceivers: { type: 'user' },
    },
  },
});

export default UserProfile;
