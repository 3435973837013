import React from 'react';
import { observer } from 'mobx-react';

import { AlertComponent } from '../../../../../src/types/';
import BEM from '../../../../common/bem';

const classes = BEM.with('AlertBoldTextView');

export type BoldTextViewProps = {
  component: AlertComponent;
  isV2?: boolean;
};

const BoldTextView = ({ component, isV2 = false }: BoldTextViewProps) => {
  const { value: text } = component;

  return <div className={classes('', { isV2 })}>{text}</div>;
};

export default observer(BoldTextView);
