import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../../../utils';
import Modal from '../../Modal';

import BEM from '../../../bem';

const classes = BEM.with('LogoutModal');

class LogoutModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    signOut: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        ariaLabelCloseButton="Logout Modal Close"
        ariaLabelHeader="Logout Modal Header"
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('close-button')}
        header="Logout"
        headerClass={classes('header')}
        isOpen={isOpen}
        onAfterOpen={this._onAfterOpen}
        onRequestClose={this._requestClose}
        size={'medium'}
        footerPrimaryActions={
          <button
            aria-label="Logout Modal Submit"
            type="button"
            className={classes('logout-btn')}
            onClick={this._onLogout}
          >
            LOGOUT
          </button>
        }
        footerSecondaryActions={
          <div>
            <button
              aria-label="Logout Modal Cancel"
              type="button"
              onClick={this._requestClose}
              className={classes('cancel-btn')}
            >
              CANCEL
            </button>
          </div>
        }
      >
        <div aria-label="Logout Modal Desc" className={classes('description-container')}>
          <span>You are about to log out of this device, are you sure?</span>
        </div>
      </Modal>
    );
  }

  _onLogout = () => {
    this.props.closeModal();
    this.props.signOut();
  };

  _requestClose = () => {
    this.props.closeModal();
  };
}

export default mobxInjectSelect({
  sessionStore: ['signOut'],
})(LogoutModal);
