import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { once } from 'lodash';
import BEM from '../../bem';
import Modal from '../Modal';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { ReactComponent as PhoneIcon } from '../../../widgets/messenger/images/provider-call-icon.svg';
import { ReactComponent as VideoCallIcon } from '../../images/icon-video.svg';
import { GroupAvatar, Spinner, TeamAvatar, UserAvatar } from '../';

const classes = BEM.with('CallActionModal');

function CallActionModal({
  checkAudioAndVideoDevices,
  isGroupVideoCallEnabled,
  isGroupVoiceCallEnabled,
  isOpen,
  isSettingUpCall,
  isVideoCallEnabled,
  isVoiceCallEnabled,
  openModal,
  startCall,
  options,
}) {
  const [buttonSelected, setButtonSelected] = useState('');
  const { entity = {}, videoTrack } = options;
  const { displayName, $entityType: entityType, groupType } = entity;
  const isGroup = entityType === 'group';
  const isIntraTeam = isGroup && groupType === 'INTRA_TEAM';
  const members = isGroup ? entity.members : [];
  const context = { type: entityType === 'user' ? 'account' : entityType, id: entity.id };

  const initiateVoiceCall = async () => {
    if (!isOpen || isSettingUpCall) return;
    setButtonSelected('voice');
    startCall({ recipientId: entity.id, members, isVideo: false, context });
  };

  const initiateVideoCall = async () => {
    if (!isOpen || isSettingUpCall) return;
    setButtonSelected('video');
    if (!videoTrack) {
      const { videoDevice } = await checkAudioAndVideoDevices();
      openModal('noCamera', { entity, videoDevice });
    } else {
      startCall({ recipientId: entity.id, members, isVideo: true, context });
    }
  };

  let avatarFragment;
  if (isIntraTeam) {
    avatarFragment = (
      <TeamAvatar displayName={displayName} fontSize={16} group={entity} size="fit" />
    );
  } else if (isGroup) {
    avatarFragment = <GroupAvatar group={entity} size="fit" />;
  } else {
    avatarFragment = <UserAvatar user={entity} size="fit" />;
  }

  return (
    <div className={classes('body-container')}>
      {avatarFragment}
      <div className={classes('title')}>Call {displayName}?</div>
      {(isGroup ? isGroupVoiceCallEnabled : isVoiceCallEnabled) && (
        <button
          className={classNames(classes('call-button'))}
          disabled={isSettingUpCall}
          onClick={once(initiateVoiceCall)}
        >
          {isSettingUpCall && buttonSelected === 'voice' && (
            <div className={classes('spinner')}>
              {' '}
              <Spinner />{' '}
            </div>
          )}
          {!isSettingUpCall && (isGroup ? isGroupVoiceCallEnabled : isVoiceCallEnabled) && (
            <div className={classes('call-button-container')}>
              <PhoneIcon className={classes('phone-icon')} />
              <div className={classes('call-button-text')}>
                {' '}
                Start {isGroup ? 'Group' : ''} Voice Call
              </div>
            </div>
          )}
        </button>
      )}
      {(isGroup ? isGroupVideoCallEnabled : isVideoCallEnabled) && (
        <button
          className={classNames(classes('call-button'))}
          disabled={isSettingUpCall}
          onClick={once(initiateVideoCall)}
        >
          {isSettingUpCall && buttonSelected === 'video' && (
            <div className={classes('spinner')}>
              {' '}
              <Spinner />{' '}
            </div>
          )}
          {!isSettingUpCall && (isGroup ? isGroupVideoCallEnabled : isVideoCallEnabled) && (
            <div className={classes('call-button-container')}>
              <VideoCallIcon className={classes('video-icon')} />
              <div className={classes('call-button-text')}>
                {' '}
                Start {isGroup ? 'Group' : ''} Video Call
              </div>
            </div>
          )}
        </button>
      )}
    </div>
  );
}

CallActionModal.propTypes = {
  calleePermissionsCheck: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isGroupVideoCallEnabled: PropTypes.bool.isRequired,
  isGroupVoiceCallEnabled: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSettingUpCall: PropTypes.bool.isRequired,
  isVideoCallEnabled: PropTypes.bool.isRequired,
  isVoiceCallEnabled: PropTypes.bool.isRequired,
  startCall: PropTypes.func.isRequired,
  options: PropTypes.shape({
    entity: PropTypes.shape({
      displayName: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

class CallActionModalContainer extends React.Component {
  render() {
    const { closeModal, isOpen } = this.props;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('close-button')}
        headerClass={classes('header')}
        isOpen={isOpen}
        onRequestClose={closeModal}
        size={'medium'}
      >
        <CallActionModal {...this.props} />
      </Modal>
    );
  }
}

export default mobxInjectSelect({
  callStore: [
    'calleePermissionsCheck',
    'checkAudioAndVideoDevices',
    'closeModal',
    'isGroupVideoCallEnabled',
    'isGroupVoiceCallEnabled',
    'isSettingUpCall',
    'isVideoCallEnabled',
    'isVoiceCallEnabled',
    'startCall',
  ],
  modalStore: ['openModal'],
})(CallActionModalContainer);
