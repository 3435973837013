import React, { useState, useRef } from 'react';

import TCClient from '../../../client';
import flattenKeys from '../../../common/utils/flattenKeys';

import { ActionBar } from '../../shared-components/ActionBar/ActionBar';
import { CtaButton } from '../../shared-components/CtaButton/CtaButton';
import { Input } from '../../shared-components/Input/Input';
import { PageHeader } from '../../shared-components/PageHeader/PageHeader';
import { Toast, ToastTypes } from '../../shared-components/Toast/Toast';
import DeleteUser from '../../shared-views/DeleteUser';
import Devices from '../../shared-views/Devices';
import { OrganizationData, AccountSearch } from '../../types';
import testAvatarNoIcon from '../../images/test_avatar_noicon.png';
import convertCamelCaseToSnakeCase from '../../utils/convertCamelCaseToSnakeCase';
import ForceLogout from './Customer/views/ForceLogout';
import SetPassword from './Customer/views/SetPassword';
import styles from './CustomerSupport.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

const {
  customerSupportSearchSearchInput,
  customerSupportSearchAccountData,
  customerSupportSearchOrganizationData,
  customerSupportSearchParseAccountData,
} = actions;

type TabButton = {
  label: string;
  onClick?: () => void;
};

const tabButtons = [
  { label: 'Set Password' },
  { label: 'Force Logout' },
  { label: 'Delete User' },
  { label: 'Devices' },
];

export default function Customers() {
  const selectedOrganization = useAppSelector((state) => state.admin.selectedOrganization);
  const searchInput = useAppSelector((state) => state.admin.customerSupportSearchSearchInput);
  const accountData = useAppSelector((state) => state.admin.customerSupportSearchAccountData);
  const organizationData = useAppSelector(
    (state) => state.admin.customerSupportSearchOrganizationData
  );
  const parseAccountData = useAppSelector(
    (state) => state.admin.customerSupportSearchParseAccountData
  );
  const searchInputBox = useRef<HTMLInputElement>(null);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>();
  const [error, setError] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const closeView = () => {
    setSelectedTab(null);
    setCurrentTabIndex(undefined);
  };

  async function handleSearchClick() {
    let organizationId, token;
    const isUserToken = searchInput.length === 36 && searchInput.split('-').length === 5;
    if (isUserToken) {
      token = searchInput;
    } else {
      const userData = (await TCClient.adminUsers.accountSearch({
        searchInput,
        organizationId: selectedOrganization.token,
      })) as AccountSearch;

      if (userData?.entities?.entity) {
        const { organizationSettings } = userData.entities.entity;
        organizationId = organizationSettings[0].organizationId;
        token = userData.entities.entity.token;
      }
    }

    if (!organizationId) organizationId = selectedOrganization.token;

    try {
      const accountSettings = await TCClient.adminUsers.getAccountSettingRaw({
        organizationId,
        userId: token,
      });

      if (!token || accountSettings.token !== token) {
        console.error('Could not search for customer', searchInput, error);
        setError(true);
        dispatch(customerSupportSearchAccountData(null));
        return;
      }
      dispatch(customerSupportSearchAccountData(accountSettings));
      dispatch(
        customerSupportSearchParseAccountData(
          TCClient.adminUsers.parseUserServerData(accountSettings)
        )
      );

      const organizationsSettings = await Promise.all(
        TCClient.adminUsers
          .parseUserServerData(accountSettings)
          ?.organizationSettings.map(({ organizationId }) => {
            return TCClient.adminOrganizations.fetchSettingsRaw({
              organizationId,
            }) as OrganizationData;
          })
      );
      dispatch(customerSupportSearchOrganizationData(organizationsSettings));
      setError(false);
    } catch (error) {
      console.error('Could not search for customer', searchInput, error);
      setError(true);
      dispatch(customerSupportSearchAccountData(null));
    }
  }

  function onTabSelection(tabButton: TabButton, index: number) {
    if (tabButton.label === selectedTab) {
      closeView();
    } else {
      setSelectedTab(tabButton.label);
      setCurrentTabIndex(index);
    }
  }

  function clearView() {
    closeView();
    dispatch(customerSupportSearchAccountData(null));
    dispatch(customerSupportSearchSearchInput(''));
    setError(false);
  }

  let flatAccount: Record<string, unknown> = {};
  let flatOrganizations: Record<string, unknown>[] = [];
  if (accountData && Object.keys(accountData).length > 0) {
    flatAccount = flattenKeys(accountData);
  }
  if (Object.keys(organizationData).length > 0) {
    flatOrganizations = organizationData.map((org) => flattenKeys(org));
  }

  const isSearchForCurrentUserId = accountData?.token === TCClient.currentUser.id;

  let avatarImageURL;
  if (accountData?.avatar) {
    avatarImageURL = accountData?.avatar;
  } else {
    avatarImageURL = testAvatarNoIcon;
  }

  return (
    <div className={styles.customerSupport}>
      <PageHeader label={'Customer Support'} />
      <ActionBar />
      <div className={styles.containerText}>Please enter an e-mail, username or phone number.</div>
      <div className={styles.inputContainer}>
        <Input
          customCSS={styles.input}
          inputRef={searchInputBox}
          onInputChange={(e) => {
            dispatch(customerSupportSearchSearchInput(e.target.value));
          }}
          shouldFocus={true}
          value={searchInput}
          dataTestId={'customerSupportSearch'}
          onEnter={handleSearchClick}
        />
        <CtaButton label={'Search'} onClick={handleSearchClick} primary={true} />
      </div>
      {accountData && parseAccountData && (
        <div>
          <div className={styles.userInfo}>
            <div>{accountData.display_name}</div>
            <div className={styles.imageCell}>
              <img src={avatarImageURL} alt={accountData.display_name || ''} />
            </div>
          </div>
          <ActionBar
            selectedTabIndex={currentTabIndex}
            setSelectedTabIndex={onTabSelection}
            tabButtons={
              isSearchForCurrentUserId
                ? tabButtons.filter((tab) => tab.label !== 'Delete User')
                : tabButtons
            }
          />
          {selectedTab === 'Set Password' && (
            <SetPassword accountData={parseAccountData} onClose={closeView} />
          )}
          {selectedTab === 'Force Logout' && (
            <ForceLogout accountData={parseAccountData} onClose={closeView} />
          )}
          {!isSearchForCurrentUserId && selectedTab === 'Delete User' && (
            <DeleteUser
              accountData={parseAccountData}
              afterDelete={clearView}
              onClose={closeView}
            />
          )}
          {selectedTab === 'Devices' && (
            <Devices accountData={parseAccountData} selectedTab={selectedTab} onClose={closeView} />
          )}
          <div className={styles.accountData}>
            {Object.keys(flatAccount).length > 0 &&
              Object.entries(flatAccount).map(([key, value]) => {
                if (!!value) {
                  key = convertCamelCaseToSnakeCase(key);
                  return <div className={styles.accountItem} key={key}>{`${key}: ${value}`}</div>;
                } else {
                  return null;
                }
              })}

            {flatOrganizations.length > 0 &&
              flatOrganizations.map((org) => {
                return (
                  <div className={styles.spacing} key={org.id as string}>
                    {Object.entries(org).map(([key, value]) => {
                      if (value !== undefined && value !== '' && value !== null) {
                        key = convertCamelCaseToSnakeCase(key);
                        return (
                          <div className={styles.accountItem} key={key}>{`${key}: ${value}`}</div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <Toast
        type={ToastTypes.FAILURE}
        message={'User with entered e-mail, username, or phone number not found.'}
        open={error}
        onClose={() => {
          setError(false);
        }}
      />
    </div>
  );
}
