import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import propTypes from '../propTypes';
import BEM from '../bem';
const classes = BEM.with('DistributionListName');

const DistributionListName = ({ ariaLabel, distributionList, className, ...restProps }) => {
  className = classNames(classes(), className);

  return (
    <span className={className} aria-label={ariaLabel} {...restProps}>
      {distributionList.name}
    </span>
  );
};

DistributionListName.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  distributionList: propTypes.distributionList.isRequired,
};

export default observer(DistributionListName);
