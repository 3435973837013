import React from 'react';
import { observer } from 'mobx-react';

import { AlertComponent } from '../../../../../src/types/';
import BEM from '../../../../common/bem';

const classes = BEM.with('AlertTextView');

export type AlertTextViewProps = {
  component: AlertComponent;
  numLinesToClipContentAt: number;
};

const COMMON_CLIPPING_STYLES = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const BASE_CLIPPING_STYLES = {
  ...COMMON_CLIPPING_STYLES,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
};

const AlertTextView = ({ numLinesToClipContentAt, component }: AlertTextViewProps) => {
  const { value: text } = component;
  let styles = {};

  if (numLinesToClipContentAt > 0) {
    styles = {
      ...BASE_CLIPPING_STYLES,
      lineClamp: `${numLinesToClipContentAt}`,
      WebkitLineClamp: `${numLinesToClipContentAt}`,
    };
  }

  return (
    <div className={classes()} style={styles} data-test-id={'alertText'} aria-label="alert-text">
      {text}
    </div>
  );
};

export default observer(AlertTextView);
