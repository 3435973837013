import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../common/bem';
import propTypes from '../../../common/propTypes';
import { mobxInjectSelect } from '../../../common/utils';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const classes = BEM.with('MessengerLoadingOverlay');

const spinnerTypes = {
  text: 'text',
  logo: 'logo',
};
const SWITCH_TO_TEXT_SPINNER_PERIOD = 6000;
let additionalTimer = null;

class MessengerLoadingOverlay extends Component {
  static propTypes = {
    activeProcessingEvents: propTypes.arrayOrObservableArrayOf(
      PropTypes.shape({
        actionType: PropTypes.string,
      })
    ),
    condensedReplays: PropTypes.bool.isRequired,
    conversationsLoaded: PropTypes.bool.isRequired,
    downloadedCount: PropTypes.number,
    downloadFinished: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSignOutLoading: PropTypes.bool.isRequired,
    offlineMessageCount: PropTypes.number,
    shouldIgnoreProcessingEvents: PropTypes.bool,
    startTime: PropTypes.number,
  };

  state = {
    spinnerType: spinnerTypes.text,
  };
  _timeout = null;

  static getDerivedStateFromProps(props, state) {
    if (
      additionalTimer &&
      Date.now() - additionalTimer > SWITCH_TO_TEXT_SPINNER_PERIOD &&
      state.spinnerType === spinnerTypes.logo
    ) {
      return {
        ...state,
        spinnerType: spinnerTypes.text,
      };
    }
    return state;
  }

  componentDidMount() {
    const { condensedReplays } = this.props;
    additionalTimer = Date.now();
    if (condensedReplays) {
      this.setState({
        spinnerType: spinnerTypes.logo,
      });
      this._timeout = setTimeout(() => {
        this.setState({
          spinnerType: spinnerTypes.text,
        });
        this._timeout = null;
      }, SWITCH_TO_TEXT_SPINNER_PERIOD);
    }
  }

  componentDidUpdate() {
    if (!this.props.isLoading && this._timeout !== null) {
      clearTimeout(this._timeout);
    }
  }

  componentWillUnmount() {
    if (this._timeout !== null) {
      clearTimeout(this._timeout);
    }
  }

  render() {
    const {
      activeProcessingEvents,
      condensedReplays,
      conversationsLoaded,
      downloadedCount,
      downloadFinished,
      isLoading,
      isSignOutLoading,
      offlineMessageCount,
      shouldIgnoreProcessingEvents,
      startTime,
    } = this.props;
    const { spinnerType } = this.state;
    let innerText;
    const isProcessing = activeProcessingEvents && activeProcessingEvents.length > 0;
    const showDownloading =
      !shouldIgnoreProcessingEvents &&
      !downloadFinished &&
      !isLoading &&
      startTime &&
      !condensedReplays;

    if (isSignOutLoading) return null;

    if (showDownloading) {
      innerText = `Downloading ${downloadedCount}/${offlineMessageCount} messages`;
    } else if (
      shouldIgnoreProcessingEvents ||
      (downloadFinished && !isLoading && !isProcessing && conversationsLoaded)
    ) {
      return null;
    } else if (spinnerType === spinnerTypes.logo) {
      return <LoadingSpinner loadingText={'Loading conversations...'} />;
    } else {
      innerText = 'Loading...';
    }

    return (
      <div className={classes()}>
        <div className={classes('message')}>{innerText}</div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  downloadedMessagesProgressStore: [
    'downloadedCount',
    'downloadFinished',
    'offlineMessageCount',
    'startTime',
  ],
  messengerStore: [
    'activeProcessingEvents',
    'condensedReplays',
    'conversationsLoaded',
    'shouldIgnoreProcessingEvents',
    'isLoading',
  ],
  sessionStore: ['isSignOutLoading'],
})(MessengerLoadingOverlay);
