import React, { useEffect, useState } from 'react';
import { Role, User } from '../../../../types';
import {
  actions,
  ReduxState,
  thunk,
  useAppSelector,
  useAppDispatch,
} from '../../../../redux-stores';
import BEM from '../../../bem';
import { mobxInjectSelect } from '../../../utils';
import { BasicModal } from '../..';
import UserItem from '../Entities/UserItem';
import EntitiesSelector from '../Entities/EntitiesSelector';

import { ReactComponent as CloseIcon } from '../../../images/modal-cancel.svg';
const { setModal } = actions;
const { updateRoleOwner } = thunk;
const classes = BEM.with('CollaborationModal');

type MobxProps = {
  currentUserId: string;
};

type OnDutyModalProps = {
  isOpen: boolean;
};

function OnDutyModal({ currentUserId, isOpen }: OnDutyModalProps & MobxProps) {
  const dispatch = useAppDispatch();
  const [isSkipping, setIsSkipping] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [user, setUser] = useState<User | Role | undefined>();
  const [initialUser, setInitialUser] = useState<User | undefined>();
  const openModal = useAppSelector((state: ReduxState) => state.session.openModal);
  const selectedOrgId = useAppSelector((state: ReduxState) => state.collab.selectedOrgId);
  const selectedRole = useAppSelector((state: ReduxState) => state.entities.selectedEntity) as Role;

  const isOptOutModal = openModal?.name === 'optOut';
  const isOwnerRequiredModal = openModal?.name === 'ownerRequired';
  const isOwnerRequired = selectedRole?.metadata?.owner_required;

  const closeModal = () => {
    dispatch(setModal(undefined));
  };

  useEffect(() => {
    if (openModal && ['ownerRequired', 'onDuty'].includes(openModal?.name) && selectedRole) {
      setUser(selectedRole.members?.[0]);
      setInitialUser(selectedRole.members?.[0]);
    } else if (openModal?.name === 'optOut') {
      setUser(undefined);
      setInitialUser(undefined);
    }
  }, [openModal, selectedRole]);

  const change = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    await updateRoleOwner(dispatch, {
      method: user ? 'optIn' : 'optOut',
      organizationId: selectedOrgId,
      selectedRole: selectedRole,
      user: (user as User) || (selectedRole?.members?.[0] as User),
    });
    setIsSubmitting(false);
    closeModal();
  };

  const clearUser = (e: React.MouseEvent) => {
    setUser(undefined);
    e.stopPropagation();
  };

  const selectUser = (user: User | Role) => {
    setUser(user);
    setSelectorOpen(false);
  };

  const skip = async () => {
    if (isSkipping) return;
    setIsSkipping(true);
    setUser(undefined);
    await updateRoleOwner(dispatch, {
      method: 'optOut',
      organizationId: selectedOrgId,
      selectedRole: selectedRole,
      user: selectedRole?.members?.[0] as User,
    });
    setIsSkipping(false);
    closeModal();
  };

  let body = null;
  if (isOptOutModal) body = 'Before you leave, would you like to assign someone to this role.';
  if (isOwnerRequiredModal) body = 'Assign someone to the role in order to continue';

  const changeDisabled =
    (isOptOutModal && !user) ||
    (isOwnerRequired && !user) ||
    (isOwnerRequiredModal && !user) ||
    (user && selectedRole?.members?.[0]?.token === user.id) ||
    initialUser?.id === user?.id;

  return (
    <BasicModal
      ariaLabelCloseButton={'On Duty Close'}
      ariaLabelHeader={'On Duty Header'}
      headerText={selectedRole?.displayName}
      hasCloseButton={true}
      isOpen={isOpen}
      size={'medium'}
      submitText={'CHANGE'}
      ariaLabelSubmitButton={'On Duty Submit'}
      onSubmit={change}
      submitDisabled={changeDisabled}
      dangerButtonTheme={'secondary'}
      onDangerSubmit={isOptOutModal && !isOwnerRequired ? skip : undefined}
      ariaLabelDangerButton={'skip role owner choose modal'}
      dangerText="SKIP"
      ariaLabelCancelButton="On Duty Cancel"
      onClose={closeModal}
      useWCL
    >
      <div data-test-id={'On Duty Body'} className={classes('')}>
        <div className={classes('body', { warning: isOwnerRequiredModal })}>{body}</div>
        <div className={classes('selector-container')}>
          <label htmlFor="userMenu" className={classes('label')}>
            On Duty
          </label>
          <div className={classes('selector')}>
            {selectorOpen ? (
              <EntitiesSelector
                clearEntity={clearUser}
                currentUserId={currentUserId}
                selectEntity={selectUser}
                toggleHandler={() => setSelectorOpen(!selectorOpen)}
              />
            ) : (
              <div
                data-test-id="open user selector"
                className={classes('selector-display-item-component')}
                onClick={() => setSelectorOpen(!selectorOpen)}
              >
                {user ? (
                  <div className={classes('selector-display-item')}>
                    <UserItem
                      avatarSize={33}
                      currentUserToken={currentUserId}
                      showTitleAndDepartment
                      maxWidth="180px"
                      user={user as User}
                    />
                    <button data-test-id="clear user" onClick={clearUser}>
                      <CloseIcon className={classes('icon-secondary')} />
                    </button>
                  </div>
                ) : (
                  <div className={classes('selector-display-item', { empty: true })}>
                    -Choose someone for this role-
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </BasicModal>
  );
}

export default mobxInjectSelect<OnDutyModalProps, MobxProps>({
  sessionStore: ['currentUserId'],
})(OnDutyModal);
