import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { once } from 'ramda';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import BasicModal from './BasicModal';

const classes = BEM.with('LeaveTeamModal');

class LeaveTeamModal extends Component {
  static propTypes = {
    leaveTeam: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    options: PropTypes.shape({ team: propTypes.team.isRequired }),
  };

  render() {
    const {
      options: { team },
      isOpen,
    } = this.props;

    return (
      <BasicModal
        ariaLabelCloseButton="Leave Team Close"
        ariaLabelHeader="Leave Team Header"
        headerText={`Leave ${team.displayName}?`}
        headerClass={classes('header')}
        isOpen={isOpen}
        size={'medium-small'}
        theme="danger"
        ariaLabelSubmitButton="Leave Team Button"
        onSubmit={once(this._leaveTeam)}
        submitText="YES"
        ariaLabelCancelButton="Leave Team Cancel"
        onClose={this._closeModal}
        cancelText="NO"
      >
        <div aria-label="Leave Team Info" className={classes('info')}>
          Leaving this team will remove you from all future activations.
        </div>
      </BasicModal>
    );
  }

  _closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  _leaveTeam = async () => {
    const {
      options: { team },
      leaveTeam,
    } = this.props;

    const status = await leaveTeam(team.id);
    if (status === 204) {
      this._closeModal();
    }
  };
}

export default mobxInjectSelect({
  teamStore: ['leaveTeam'],
})(LeaveTeamModal);
