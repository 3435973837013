import EventEmitter from 'events';
import { observable, action, runInAction } from 'mobx';
import queue from 'emitter-queue';
const NETWORK_SWITCH_TIMEOUT = 1500;

export default class NetworkStore {
  events = queue(new EventEmitter());
  @observable isNetworkLoading = false;
  @observable isProviderNetwork = true;

  constructor({ client, entityStore, stores }) {
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  mounted() {
    this.entityStore.events.on('afterInject', this._onAfterInject);
  }

  dispose() {
    this.entityStore.events.removeListener('afterInject', this._onAfterInject);
  }

  _onAfterInject = ({ $entityType, id, isPatientNetworkEnabled }) => {
    if ($entityType !== 'organization') return;

    const { currentOrganizationId } = this.stores.messengerStore;
    if (id !== currentOrganizationId) return;

    if (!isPatientNetworkEnabled && !this.isProviderNetwork) {
      this.switchNetwork();
    }
  };

  @action('NetworkStore.switchNetwork') switchNetwork = () => {
    const { composeMessageStore, messengerStore, teamStore, trackerStore } = this.stores;
    this.isProviderNetwork = !this.isProviderNetwork;
    this.isNetworkLoading = true;
    teamStore.unselectTeam();
    composeMessageStore.stopComposing();
    messengerStore.openMessages();
    const currentNetwork = this.isProviderNetwork ? 'Provider' : 'Patient';

    if (this.isProviderNetwork && this.stores.rosterStore.selectedFilter === 'Teams') {
      teamStore.refreshTeamsByOrg();
    }

    let parentPathName = 'Patient Network Messenger';
    if (currentNetwork === 'Provider') {
      parentPathName = 'Messages';
    }

    trackerStore.logPendoAnalytics({ parentPathName });

    setTimeout(() => {
      runInAction(() => {
        this.isNetworkLoading = false;
      });
    }, NETWORK_SWITCH_TIMEOUT);

    this.events.emit('switchNetwork', {
      networkType: currentNetwork,
    });
  };
}
