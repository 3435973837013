import React from 'react';
import classNames from 'classnames';

import { ViewHeader } from '../../shared-components/View/ViewHeader/ViewHeader';
import { ViewSubheader } from '../../shared-components/View/ViewSubheader/ViewSubheader';

import styles from './OrganizationSettingsListItem.module.css';

export interface OrganizationSettingListItemProps {
  label: string;
  description: string;
  control: JSX.Element;
}

export function OrganizationSettingListItem({
  label,
  description,
  control,
}: OrganizationSettingListItemProps) {
  return (
    <div className={classNames(styles.orgSettingsListItem)}>
      <div className={classNames(styles.textContent)}>
        <ViewHeader label={label} />
        <ViewSubheader label={description} />
      </div>
      <div className={classNames(styles.control)}>{control}</div>
    </div>
  );
}
