import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import BEM from '../../../../common/bem';
import propTypes from '../../../../common/propTypes';

const classes = BEM.with('RoleStatus');

class RoleStatus extends Component {
  static propTypes = {
    className: PropTypes.string,
    role: propTypes.role.isRequired,
  };

  _lastTagStyle = undefined;

  render() {
    const { className, role } = this.props;
    const { displayName, tag } = role;

    if (tag) {
      const { color } = tag;
      if (!this._lastTagStyle || this._lastTagStyle.backgroundColor !== color) {
        this._lastTagStyle = { backgroundColor: color };
      }
    }

    return (
      <div className={classNames(classes(), className)}>
        <span className={classes('role-name')}>{displayName}</span>
        {tag && <span className={classes('tag-indicator')} style={this._lastTagStyle} />}
      </div>
    );
  }
}

export default observer(RoleStatus);
