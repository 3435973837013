import React from 'react';
import PropTypes from 'prop-types';

import BEM from '../../../bem';
import { ReactComponent as MemberAdded } from '../../../images/green-checkmark.svg';
import { ReactComponent as MembersToBeAdded } from '../../../images/check-add-members.svg';
import { SmsOptedOut } from '../../';

const classes = BEM.with('AddContactsFromList');

const AddContactsFromList = ({ disabled, contactDetails, pendingMemberIds, toggle }) => {
  const isSelected =
    !contactDetails.smsOptedOut &&
    (disabled ||
      pendingMemberIds.some((pendingMemberId) => pendingMemberId === contactDetails.user.id));
  const name = contactDetails.user.displayName;
  const relation = contactDetails.relation;
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      !disabled && toggle();
    }
  };
  return (
    <div
      className={classes({ disabled })}
      onClick={() => !disabled && toggle()}
      onKeyDown={handleKeyDown}
    >
      <div className={classes('wrapper')}>
        <div className={classes('contact-info')}>
          <div className={classes('contact-details')}>
            <div className={classes('contact-gender')}>{relation}</div>
          </div>
          <div className={classes('contact-name')}>{name}</div>
          {contactDetails.smsOptedOut && <SmsOptedOut isContact tooltip />}
        </div>
        <div className={classes('check-mark', { isSelected })}>
          {isSelected ? <MemberAdded /> : <MembersToBeAdded />}
        </div>
      </div>
    </div>
  );
};

AddContactsFromList.propTypes = {
  disabled: PropTypes.bool,
  contactDetails: PropTypes.object.isRequired,
  pendingMemberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggle: PropTypes.func.isRequired,
};

AddContactsFromList.defaultProps = {
  disabled: false,
};

export default AddContactsFromList;
