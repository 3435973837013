import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../common/bem';
import propTypes from '../../common/propTypes';
import mobxInjectSelect from '../../common/utils/mobxInjectSelect';
import Images from '../../models/enums/Images';
import AttachmentPreview from './AttachmentPreview';

const classes = BEM.with('AttachmentPreviews');

class AttachmentPreviews extends Component {
  static propTypes = {
    attachmentDescriptors: propTypes.observableArray.isRequired,
    hasAttachments: PropTypes.bool.isRequired,
    scrollToBottomIfPresent: PropTypes.func.isRequired,
  };

  _hadAttachments = false;

  componentDidUpdate() {
    const { hasAttachments, scrollToBottomIfPresent } = this.props;
    if (!this._hadAttachments && hasAttachments) {
      scrollToBottomIfPresent();
    }

    this._hadAttachments = hasAttachments;
  }

  render() {
    const { attachmentDescriptors, hasAttachments } = this.props;
    if (!hasAttachments) return null;

    let imageSizeSum = 0;
    for (const { file } of attachmentDescriptors) {
      imageSizeSum += file.size;
    }

    const attachmentPreviews = attachmentDescriptors.map((attachmentDescriptor) => (
      <AttachmentPreview
        attachmentDescriptor={attachmentDescriptor}
        key={attachmentDescriptor.id}
        shouldShowImages={imageSizeSum <= Images.MAX_PREVIEW_SIZE_BYTES}
      />
    ));

    return (
      <div className={classes()}>
        <div className={classes('attachments-container')}>
          <div className={classes('attachments-text')}>Attachments</div>
          <div className={classes('attachment-preview-container')}>
            <div className={classes('attachment-preview-overflow-container')}>
              <div className={classes('attachments-holder')}>{attachmentPreviews}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  composeMessageStore: ['attachmentDescriptors', 'hasAttachments'],
  conversationStore: ['scrollToBottomIfPresent'],
})(AttachmentPreviews);
