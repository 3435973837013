import React, { useReducer, useState } from 'react';
import BEM from '../../bem';
import { mobxInjectSelect } from 'common/utils';

const classes = BEM.with('DebugUI');

const OBSERVABLE_FILTER = ['client', 'stores', 'entityStore', 'events'];
const DEFAULT_WATCHLIST = {
  sessionStore: ['currentUserId'],
  messengerStore: ['currentOrganizationId'],
  conversationStore: ['currentConversationId'],
};

function DynamicMobxComponent({ selectedList, removeObservable, ...observableList }) {
  function formatValue(key) {
    const value = observableList[key];
    try {
      return String(JSON.stringify(value, null, 2));
    } catch (e) {
      return String(value);
    }
  }

  return (
    <div className={classes('watchlistContainer')}>
      {Object.keys(selectedList).map((store) => {
        return (
          <div key={store}>
            <div className={classes('watchlistStore')}>{store}</div>
            {selectedList[store].map((observableKey, idx) => {
              return (
                <div
                  className={classes('watchlistObservableContainer')}
                  key={`${store}.${observableKey}`}
                >
                  <div className={classes('watchlistObservableKeyContainer')}>
                    <div className={classes('watchlistObservableKey')}>{observableKey}</div>
                    <div
                      className={classes('textButton')}
                      onClick={() => removeObservable(store, idx)}
                    >
                      remove
                    </div>
                  </div>
                  <div className={classes('watchlistObservableValue')}>
                    <div>{formatValue(observableKey)}</div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function DebugMobx({ getStorageValue, setStorageValue, removeStorageValue, toggleDebugUI }) {
  const [openStores, setOpenStores] = useState(false);
  const [openStore, setOpenStore] = useState({});
  const [selectedObservables, setSelectedObservables] = useState(
    getStorageValue('debugUIWatchlist') || { ...DEFAULT_WATCHLIST }
  );

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const DynamicMobx = mobxInjectSelect(selectedObservables)(DynamicMobxComponent);

  function handleOpenStore(store) {
    if (openStore[store]) {
      delete openStore[store];
    } else {
      openStore[store] = true;
    }
    setOpenStore({ ...openStore });
  }

  function addToObservedList(store, observable) {
    if (selectedObservables[store] && selectedObservables[store].includes(observable)) return;
    if (selectedObservables[store]) {
      selectedObservables[store].push(observable);
    } else {
      selectedObservables[store] = [observable];
    }
    setSelectedObservables({ ...selectedObservables });
    setStorageValue('debugUIWatchlist', selectedObservables);
  }

  function removeFromObservedList(store, idx) {
    if (!selectedObservables[store]) return;

    selectedObservables[store] = [
      ...selectedObservables[store].slice(0, idx),
      ...selectedObservables[store].slice(idx + 1),
    ];
    if (selectedObservables[store].length === 0) {
      delete selectedObservables[store];
    }
    setSelectedObservables({ ...selectedObservables });
    setStorageValue('debugUIWatchlist', selectedObservables);
  }

  function determineTriangleState(condition) {
    return condition ? <>&#9663;</> : <>&#9657;</>;
  }

  function resetWatchlist() {
    setSelectedObservables({ ...DEFAULT_WATCHLIST });
    removeStorageValue('debugUIWatchlist');
  }

  function refreshAvailableStores() {
    forceUpdate();
  }

  function renderStoreObservables(store, availableObservables) {
    return (
      openStore?.[store] && (
        <div style={{ margin: '5px 0 20px 15px' }}>
          {availableObservables.map((observable, idx) => (
            <div
              onClick={() => addToObservedList(store, observable)}
              key={idx}
              className={classes('observableListContainer')}
            >
              <span className={classes('observableListName')} title={observable}>
                {observable}
              </span>
              <span className={classes('observableListType')}>
                {global.mobxStores[store][observable]?.length
                  ? 'array'
                  : typeof global.mobxStores[store][observable]}
              </span>
            </div>
          ))}
        </div>
      )
    );
  }

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <span style={{ flex: 1 }}>Watchlist</span>
        <div>
          <span
            style={{ marginRight: '5px' }}
            className={classes('textButton')}
            onClick={resetWatchlist}
          >
            Reset
          </span>
          <span className={classes('textButton')} onClick={toggleDebugUI}>
            Close
          </span>
        </div>
      </div>
      <DynamicMobx selectedList={selectedObservables} removeObservable={removeFromObservedList} />

      <div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, marginBottom: '5px' }}>Available</div>
          <div className={classes('textButton')} onClick={refreshAvailableStores}>
            Refresh
          </div>
        </div>
        <div
          style={{ cursor: 'pointer', marginBottom: '3px' }}
          onClick={() => setOpenStores(!openStores)}
        >
          {determineTriangleState(openStores)} MobX Stores
        </div>
        {openStores && (
          <div>
            {Object.keys(global.mobxStores).map((store, idx) => {
              const availableObservables = Object.keys(global.mobxStores[store]).filter(
                (observable) =>
                  !OBSERVABLE_FILTER.includes(observable) &&
                  typeof global.mobxStores[store][observable] !== 'function'
              );

              if (availableObservables.length === 0) return null;

              return (
                <div key={idx} style={{ marginLeft: '15px', marginBottom: '3px' }}>
                  <div
                    style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                    onClick={() => handleOpenStore(store)}
                  >
                    {determineTriangleState(openStore?.[store])} {store}
                  </div>
                  {renderStoreObservables(store, availableObservables)}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default mobxInjectSelect({
  localStore: ['setStorageValue', 'removeStorageValue', 'getStorageValue'],
  sessionStore: ['toggleDebugUI'],
})(DebugMobx);
