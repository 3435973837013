import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import propTypes from '../propTypes';
import BEM from '../../common/bem';
import { UserName } from './';

const classes = BEM.with('TypersNames');

class TypersNames extends Component {
  static propTypes = {
    maxTypers: PropTypes.number.isRequired,
    typers: propTypes.userArray.isRequired,
  };

  render() {
    const { maxTypers, typers } = this.props;

    if (typers.length === 0) return null;
    if (typers.length > maxTypers) {
      return (
        <div key={typers[0].id} className={classes()}>
          Several people are typing
        </div>
      );
    }
    if (typers.length === 1)
      return (
        <div key={typers[0].id} className={classes()}>
          <UserName user={typers[0]} /> is typing
        </div>
      );
    if (typers.length === 2)
      return (
        <div key={typers[0].id} className={classes()}>
          <UserName user={typers[0]} /> and <UserName user={typers[1]} /> are typing
        </div>
      );
    if (typers.length >= 3)
      return (
        <div key={typers[0].id} className={classes()}>
          <UserName user={typers[0]} /> and <UserName user={typers[1]} /> and{' '}
          <UserName user={typers[2]} /> are typing
        </div>
      );
  }
}

export default observer(TypersNames);
