import { User } from 'common/components/UserAvatar/UserAvatar';

export function getUserInitials(user: User) {
  let initialsParts = [user?.firstName, user?.lastName].filter(Boolean);

  if (initialsParts.length === 0 && user?.displayName) {
    initialsParts = user.displayName.split(' ');
  }

  initialsParts = initialsParts.slice(0, 2);

  const initials = initialsParts
    .filter(Boolean)
    .map((s) => s?.[0])
    .join('')
    .toUpperCase();
  if (!initials) return null;

  return initials;
}
