import React from 'react';

import AutomatedMessagesContextProvider from '../../../../contexts/AutomatedMessages/context';
import AutomatedMessages from './AutomatedMessages';
import WorkflowActivation from './WorkflowActivation';
import WorkflowClipboard from './WorkflowClipboard';
import WorkflowEventsList from './WorkflowEventsList';
import WorkflowEventModal from './WorkflowEventModal';
import WorkflowItem from './WorkflowItem';
import WorkflowSidebar from './WorkflowSidebar';

export {
  WorkflowActivation,
  WorkflowClipboard,
  WorkflowEventsList,
  WorkflowEventModal,
  WorkflowItem,
  WorkflowSidebar,
};

export default function AutomatedMessagesPage() {
  return (
    <AutomatedMessagesContextProvider>
      <AutomatedMessages />
    </AutomatedMessagesContextProvider>
  );
}
