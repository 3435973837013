import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import { allowedSendersReasons } from '../utils';
import { Modal } from './';

const classes = BEM.with('AllowedSenderModal');

class AllowedSenderModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
  };

  render() {
    const {
      closeModal,
      isOpen,
      options: { reason },
    } = this.props;
    const { header, text } = allowedSendersReasons[reason];

    return (
      <Modal
        header={header}
        className={classes()}
        headerClass={classes('header')}
        isOpen={isOpen}
        onRequestClose={closeModal}
        footerSecondaryActions={
          <button type="button" onClick={closeModal} className={classes('continue-btn')}>
            Continue
          </button>
        }
      >
        <div className={classes('text')}>{text}</div>
      </Modal>
    );
  }
}

export default AllowedSenderModal;
