import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReduxState } from '../index';
import { PATIENT_REFERENCE_SEARCH_RESULT_PAGE_SIZE } from '.';
import TCClient from 'client';

export const searchPatientContextsThunk = createAsyncThunk(
  'patientContext/searchPatientContextsThunk',
  async (
    {
      query,
      from,
      pageSize,
      continuationValue,
    }: { query: string; from: number; pageSize?: number; continuationValue?: string },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as ReduxState;
    const api = thunkAPI.extra as typeof TCClient;

    if (state.patientContext.patientSearchView === 'All' || query !== '') {
      try {
        const { hits, totalHits } = await api.patientContext.searchAllPatients({
          orgId: state.session.currentOrganizationId,
          query,
          from,
          pageSize: pageSize || PATIENT_REFERENCE_SEARCH_RESULT_PAGE_SIZE,
        });

        return {
          query,
          hits,
          from,
          lastPage: totalHits < (pageSize || PATIENT_REFERENCE_SEARCH_RESULT_PAGE_SIZE),
        };
      } catch (e) {
        console.error('searchPatientContextsThunk', e);
        return undefined;
      }
    } else {
      try {
        const { hits, totalHits, continuation } = await api.patientContext.searchMyPatients({
          orgId: state.session.currentOrganizationId,
          pageSize: pageSize || PATIENT_REFERENCE_SEARCH_RESULT_PAGE_SIZE,
          continuationValue,
        });

        return {
          hits,
          lastPage: totalHits < (pageSize || PATIENT_REFERENCE_SEARCH_RESULT_PAGE_SIZE),
          continuation,
        };
      } catch (e) {
        console.error('searchPatientContextsThunk', e);
        return undefined;
      }
    }
  }
);

export const fetchPatientContextThunk = createAsyncThunk(
  'patientContext/fetchPatientContextThunk',
  async (patientContextId: string, thunkAPI) => {
    const state = thunkAPI.getState() as ReduxState;
    const api = thunkAPI.extra as typeof TCClient;

    try {
      const patient = await api.patientContext.getPatient({
        orgId: state.session.currentOrganizationId,
        patientContextId,
      });

      return patient;
    } catch (e) {
      throw new Error('fetchPatientContextThunk');
    }
  },
  {
    condition: (patientContextId, thunkAPI) => {
      const state = thunkAPI.getState() as ReduxState;
      const fetchStatus = state.patientContext.fetchPatientContextStatus[patientContextId];
      if (fetchStatus === 'pending') {
        return false;
      }
    },
  }
);

export const fetchPatientCareTeamThunk = createAsyncThunk(
  'patientContext/fetchPatientCareTeamThunk',
  async (
    {
      patientContextId,
      isRequestedFromCompose,
    }: {
      patientContextId: string;
      isRequestedFromCompose: boolean;
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as ReduxState;
    const api = thunkAPI.extra as typeof TCClient;
    const responseArray = [];
    let updatedContinuation = '';

    if (isRequestedFromCompose) {
      try {
        do {
          const response = await api.patientContext.getPatientCareTeamMembers({
            orgId: state.session.currentOrganizationId,
            patientContextId,
            tcAccountOnly: 1,
            continuationValue: updatedContinuation,
          });
          responseArray.push(...response.hits);
          updatedContinuation = response.continuation;
        } while (updatedContinuation !== null);
        return {
          careTeamMembers: responseArray,
          continuation: null,
          isRequestedFromCompose,
        };
      } catch (e) {
        throw new Error('fetchPatientCareTeamThunk');
      }
    } else {
      try {
        const response = await api.patientContext.getPatientCareTeamMembers({
          orgId: state.session.currentOrganizationId,
          patientContextId,
          tcAccountOnly: 0,
          continuationValue: state.patientContext.patientCareTeamModal.continuation,
        });
        responseArray.push(...response.hits);
        return {
          careTeamMembers: responseArray,
          continuation: response.continuation,
          isRequestedFromCompose,
        };
      } catch (e) {
        throw new Error('fetchPatientCareTeamThunk');
      }
    }
  }
);

export const handlePatientReferenceSSE = createAsyncThunk(
  'session/handlePatientReferenceSSE',
  async ({ patient_context_id }: { patient_context_id?: string }, thunkAPI) => {
    if (patient_context_id) {
      thunkAPI.dispatch(fetchPatientContextThunk(patient_context_id));
    }
  }
);
