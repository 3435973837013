import React from 'react';
import { mobxInjectSelect } from '../../utils';
import CustomResponseList, { Response } from '../CustomResponseList/CustomResponseList';

type QuickReplyCustomResponseListProps = {
  onEditButtonClick?: () => void;
  onResponseClick?: (text: string) => void;
  responses: Response[];
  setResponses?: (replies: Response[]) => void;
  maxHeight?: string;
};

type MobxProps = {
  isProviderNetwork: boolean;
};

const QUICK_REPLY_CHARACTER_LIMIT = 1024;

function QuickReplyCustomResponseList({
  isProviderNetwork,
  ...restProps
}: QuickReplyCustomResponseListProps & MobxProps) {
  return (
    <CustomResponseList
      responseLimit={isProviderNetwork ? 5 : 10}
      characterLimit={QUICK_REPLY_CHARACTER_LIMIT}
      {...restProps}
    />
  );
}

export default mobxInjectSelect<QuickReplyCustomResponseListProps, MobxProps>({
  networkStore: ['isProviderNetwork'],
})(QuickReplyCustomResponseList);
