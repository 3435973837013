import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import DistributionListName from './DistributionListName';
import GroupName from './GroupName';
import TeamName from './TeamName';
import UserName from './UserName';

const NAME_COMPONENT_BY_TYPE = {
  distributionList: DistributionListName,
  group: GroupName,
  role: UserName,
  team: TeamName,
  user: UserName,
};

class EntityName extends Component {
  static propTypes = {
    entity: PropTypes.shape({
      $entityType: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }),
  };

  render() {
    const { entity } = this.props;
    const { $entityType: entityType } = entity;

    const NameComponent = NAME_COMPONENT_BY_TYPE[entityType];
    const props = { [entityType]: entity };

    if (!NameComponent) {
      console.error('cannot render entity name for', { entity, entityType });
      return null;
    }

    return <NameComponent {...props} />;
  }
}

export default observer(EntityName);
