import React, { useEffect, useState } from 'react';
import { ActionBar } from '../../../shared-components/ActionBar/ActionBar';
import { CtaButton } from '../../../shared-components/CtaButton/CtaButton';
import { Input } from '../../../shared-components/Input/Input';
import { PageHeader } from '../../../shared-components/PageHeader/PageHeader';
import TCClient from '../../../../client';
import Dropdown from '../../../../common/components/Dropdown';
import { Toast, ToastTypes } from '../../../shared-components/Toast/Toast';
import testAvatarNoIcon from '../../../images/test_avatar_noicon.png';

import avatarRoom from '../../../images/avatar_room.png';

import styles from './Forum.module.css';

export interface ForumProps {
  selected: {
    id: string;
    name?: string;
    memberCount?: number;
    avatar?: string;
  };
  handleCancel: () => void;
  handleSuccess: () => void;
  organizationId: string;
}

type User = {
  $entityType: string;
  avatarUrl: string;
  token: string;
  displayName: string;
  id: string;
  isOriginal: boolean;
  status?: 'add' | 'del';
};

function Forum({
  selected: { id: forumId, name: initialForumName = '', avatar: forumAvatar },
  handleCancel,
  handleSuccess,
  organizationId,
}: ForumProps) {
  const [forumName, setForumName] = useState(initialForumName);
  const [addMemberSearch, setAddMemberSearch] = useState('');
  const [addMemberResults, setAddMemberResults] = useState<User[]>([]);
  const [membersList, setMembersList] = useState<User[]>([]);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastType, setToastType] = useState<keyof typeof ToastTypes>(ToastTypes.SUCCESS);
  const [toastMessage, setToastMessage] = useState('Forum successfully updated.');

  useEffect(() => {
    async function getMembers() {
      try {
        const results = await TCClient.groups.findAllMembers(forumId);
        setMembersList(
          results.map((member: User) => ({ ...member, isOriginal: true, id: member.token }))
        );
      } catch (e) {
        console.error(e);
      }
    }
    if (forumId) {
      getMembers();
    }
  }, [forumId]);

  useEffect(() => {
    setAddMemberResults([]);
    async function searchUsers() {
      try {
        const res = await TCClient.users.adminFindUsersToAddToForum(organizationId, {
          query: addMemberSearch,
        });
        const users = res.map((searchResult: { entity: string }) => searchResult.entity);
        setAddMemberResults(users);
      } catch (e) {
        console.error(e);
      }
    }
    if (addMemberSearch) {
      searchUsers();
    }
  }, [addMemberSearch, organizationId]);

  async function createForum() {
    const memberIds = membersList
      .filter((member) => member.status === 'add')
      .map((member) => member.id);

    try {
      const result = await TCClient.adminOrganizations.createForum({
        name: forumName,
        memberIds,
        organizationId,
      });
      if (result) {
        handleSuccess();
      } else {
        handleToastError('Forum was unable to be created. Please try again.');
      }
    } catch (e) {
      console.error(e);
      handleToastError('Forum was unable to be created. Please try again.');
    }
  }

  function handleToastSuccess(message: string) {
    setToastType(ToastTypes.SUCCESS);
    setToastMessage(message);
    setIsToastOpen(true);
  }

  function handleToastError(message: string) {
    setToastType(ToastTypes.FAILURE);
    setToastMessage(message);
    setIsToastOpen(true);
  }

  async function updateForum() {
    const memberIds = membersList
      .filter(
        (member) =>
          (member.isOriginal && member.status === 'del') ||
          (!member.isOriginal && member.status === 'add')
      )
      .map((entity) => ({
        token: entity.id,
        action: entity.status as 'add' | 'del',
      }));

    try {
      const result = await TCClient.adminOrganizations.updateForum({
        name: forumName,
        memberIds,
        organizationId,
        forumId,
      });
      if (result) {
        handleToastSuccess('Forum successfully updated.');
      } else {
        handleToastError('Forum was unable to be updated. Please try again.');
      }
    } catch (e) {
      console.error(e);
      handleToastError('Forum was unable to be updated. Please try again.');
    }
  }

  function addMemberToList(user: User) {
    if (!membersList) {
      setMembersList([{ ...user, status: 'add' }]);
    } else if (!membersList.some((member) => member.id === user.id)) {
      setMembersList(membersList.concat([{ ...user, status: 'add' }]));
    }
    setAddMemberSearch('');
  }

  function removeMemberFromList(user: User) {
    if (forumId) {
      setMembersList(
        membersList.map((value) =>
          value.token === user.token ? { ...value, status: 'del' } : value
        )
      );
    } else {
      setMembersList(membersList.filter((value) => value.id !== user.id));
    }
  }

  const displayMemberList = membersList.filter((member) => member.status !== 'del');

  const isDisabledButton = displayMemberList.length === 0 || !forumName;

  const createButtonFlow = () => {
    isDisabledButton
      ? !forumName && membersList
        ? window.alert('Please enter a name for the group.')
        : window.alert('You must select at least one other member to update a group.')
      : forumId
      ? updateForum()
      : createForum();
  };

  return (
    <div className={styles.forumContainer}>
      <div className={styles.forumAvatarContainer}>
        <img src={forumAvatar ? forumAvatar : avatarRoom} alt="forum avatar" />
      </div>
      <PageHeader label={'Create Forum'} subLabel={forumName} />
      <ActionBar />
      <div className={styles.forumContent}>
        <div className={styles.modifyForumForm}>
          <div className={styles.forumFormInputs}>
            <div className={styles.formRow}>
              <label>1. Forum Name</label>
              <Input
                customCSS={styles.formInput}
                placeholder={'Name your forum'}
                onInputChange={(e) => {
                  setForumName(e.currentTarget.value);
                }}
                value={forumName}
                dataTestId={'forumNameField'}
              />
            </div>
            <div className={styles.formRow}>
              <label>2. Add Members</label>
              <Input
                customCSS={styles.formInput}
                hasClearButton
                onInputChange={(e) => {
                  setAddMemberSearch(e.currentTarget.value);
                }}
                placeholder={'Search by name'}
                value={addMemberSearch}
                dataTestId={'searchForumMembers'}
                onClearClick={() => {
                  setAddMemberSearch('');
                }}
              />
              {addMemberResults.length > 0 && (
                <div className={styles.addMemberDropdownContainer}>
                  <Dropdown ariaLabel={'admin-forum-user-selector'} triggerHandler={() => {}}>
                    <>
                      {addMemberResults.map((user: User) => (
                        <>
                          <div
                            key={user.token}
                            className={styles.userRoleContainer}
                            onClick={() => {
                              addMemberToList(user);
                            }}
                          >
                            <img
                              className={styles.imageCell}
                              src={user.avatarUrl ? user.avatarUrl : testAvatarNoIcon}
                              alt={''}
                            />
                            <div key={user.token} className={styles.addMemberItem}>
                              {user.displayName}
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
          <div className={styles.forumFormActions}>
            <div
              className={styles.cancelButton}
              data-test-id={'cancelForumCreate'}
              onClick={handleCancel}
            >
              Cancel
            </div>
            <CtaButton
              primary={!isDisabledButton}
              size={'large'}
              onClick={() => {
                createButtonFlow();
              }}
              label={`${forumId ? 'Update' : 'Create'} Forum`}
            />
          </div>
        </div>
        <div className={styles.forumMembers}>
          <div className={styles.forumMembersList}>
            <span className={styles.membersText}>Members:</span> {displayMemberList.length}
            {displayMemberList.length > 0 && (
              <>
                {displayMemberList.map((member: User) => (
                  <div className={styles.forumMember} key={member.token}>
                    <img
                      className={styles.selectedImageCell}
                      src={member.avatarUrl ? member.avatarUrl : testAvatarNoIcon}
                      alt={''}
                    />
                    <div className={styles.memberName}>{member.displayName}</div>
                    <div
                      className={styles.removeMemberButton}
                      onClick={() => {
                        removeMemberFromList(member);
                      }}
                    >
                      x
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <Toast
        type={toastType}
        message={toastMessage}
        open={isToastOpen}
        onClose={() => {
          setIsToastOpen(false);
        }}
      />
    </div>
  );
}

export default Forum;
