import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SuccessCheckMark } from '../images/checkmark.svg';
import BEM from '../bem';
import { Modal } from './';

const classes = BEM.with('PatientForwardSuccessModal');
const FORWARD_SUCCESS_TIMEOUT = 1500;

class PatientForwardSuccessModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    options: PropTypes.object.isRequired,
  };

  render() {
    const { isOpen, options } = this.props;
    const { recipients = {} } = options;

    let forwardText;
    if (recipients.length > 1) {
      forwardText = 'Forwarded to multiple providers in the Provider Network';
    } else if (recipients.length === 1) {
      const { displayName } = recipients[0];
      forwardText = `Forwarded to ${displayName} in the Provider Network`;
    }

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        hasCloseButton={false}
        headerClass={classes('forwardSuccessHeader')}
        isOpen={isOpen}
        onAfterOpen={this._autoCloseModal}
        size={'variable'}
      >
        <div className={classes('check-mark')}>
          <SuccessCheckMark />
        </div>
        <div className={classes('message-container')}>{forwardText}</div>
      </Modal>
    );
  }

  _autoCloseModal = () => {
    const { closeModal } = this.props;
    setTimeout(() => {
      closeModal();
    }, FORWARD_SUCCESS_TIMEOUT);
  };
}

export default PatientForwardSuccessModal;
