import { Unit, Units } from '../types/MultiOrg';
import BaseService from './BaseService';

export default class MultiOrgService extends BaseService {
  async createFacility({ facilityName, orgId }: { facilityName: string; orgId: string }) {
    return this.host.api.multiOrg.createFacility({
      orgId,
      facilityName,
      tenantId: orgId,
    });
  }

  async createArea({
    areaName,
    orgId,
    parentFacilityId,
  }: {
    areaName: string;
    orgId: string;
    parentFacilityId: string;
  }) {
    return this.host.api.multiOrg.createArea({
      orgId,
      areaName,
      parentFacilityId,
    });
  }

  async createRoom({
    roomName,
    orgId,
    parentAreaId,
  }: {
    roomName: string;
    orgId: string;
    parentAreaId: string;
  }) {
    return this.host.api.multiOrg.createRoom({
      orgId,
      roomName,
      parentAreaId,
    });
  }

  async createSection({
    sectionName,
    orgId,
    parentRoomId,
  }: {
    sectionName: string;
    orgId: string;
    parentRoomId: string;
  }) {
    return this.host.api.multiOrg.createSection({
      orgId,
      sectionName,
      parentRoomId,
    });
  }

  async updateFacility({
    id,
    orgId,
    facilityName,
  }: {
    id: string;
    orgId: string;
    facilityName: string;
  }) {
    return this.host.api.multiOrg.updateFacility({
      id,
      orgId,
      facilityName,
    });
  }

  async updateArea({ id, orgId, areaName }: { id: string; orgId: string; areaName: string }) {
    return this.host.api.multiOrg.updateArea({
      id,
      orgId,
      areaName,
    });
  }

  async updateRoom({ id, orgId, roomName }: { roomName: string; id: string; orgId: string }) {
    return this.host.api.multiOrg.updateRoom({
      id,
      orgId,
      roomName,
    });
  }

  async updateSection({
    sectionName,
    id,
    orgId,
  }: {
    sectionName: string;
    id: string;
    orgId: string;
  }) {
    return this.host.api.multiOrg.updateSection({
      id,
      orgId,
      sectionName,
    });
  }

  async deleteFacility({ id, orgId }: { id: string; orgId: string }) {
    return this.host.api.multiOrg.deleteFacility({
      id,
      orgId,
    });
  }

  async deleteArea({ id, orgId }: { id: string; orgId: string }) {
    return this.host.api.multiOrg.deleteArea({
      id,
      orgId,
    });
  }

  async deleteRoom({ id, orgId }: { id: string; orgId: string }) {
    return this.host.api.multiOrg.deleteRoom({
      id,
      orgId,
    });
  }

  async deleteSection({ id, orgId }: { id: string; orgId: string }) {
    return this.host.api.multiOrg.deleteSection({
      id,
      orgId,
    });
  }

  async getOrganizationLocations(orgId: string) {
    return this.host.api.multiOrg.getOrganizationLocations(orgId);
  }

  async getUnassignedRooms({ orgId, facilityId }: { orgId: string; facilityId: string }) {
    return this.host.api.multiOrg.getUnassignedRooms(orgId, facilityId);
  }

  async createUnit({
    facilityId,
    orgId,
    unitName,
    roomIds,
  }: {
    facilityId: string;
    orgId: string;
    unitName: string;
    roomIds: string[];
  }) {
    return this.host.api.multiOrg.createUnit({ facilityId, orgId, unitName, roomIds });
  }

  async updateUnit({
    orgId,
    unitId,
    unitName,
    roomIds,
  }: {
    orgId: string;
    unitId: string;
    unitName: string;
    roomIds: string[];
  }) {
    return this.host.api.multiOrg.updateUnit({ orgId, unitId, unitName, roomIds });
  }

  async deleteUnit({ orgId, unitId }: { orgId: string; unitId: string }) {
    return this.host.api.multiOrg.deleteUnit({ orgId, unitId });
  }

  async findUnits({ orgId }: { orgId: string }): Promise<Units> {
    return this.host.api.multiOrg.findUnits({ orgId });
  }

  async getUnit({ orgId, unitId }: { orgId: string; unitId: string }): Promise<Unit> {
    return this.host.api.multiOrg.getUnit({ orgId, unitId });
  }
}
