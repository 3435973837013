import { ReactComponent as PriorityIcon } from '../../../common/images/priority-icon.svg';
import BEM from '../../../common/bem';
import mobxInjectSelect from 'common/utils/mobxInjectSelect';

const classes = BEM.with('ValidationError');

type ValidationErrorProps = {};

type MobxProps = {
  OCError: string;
};

function ValidationError({ OCError }: ValidationErrorProps & MobxProps) {
  return (
    <div className={classes()}>
      <div className={classes('container')}>
        <div className={classes('content')}>
          <PriorityIcon className={classes('header-icon')} />
          <div className={classes('title-text')}>Something went wrong.</div>
          <div className={classes('title-subtext')}>
            {'Please ensure that you have entered the correct information and try again.'}
          </div>
          <div className={classes('error-container')}>
            <div className={classes('error-message')}>{OCError}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default mobxInjectSelect<ValidationErrorProps, MobxProps>({
  operatorConsoleStore: ['OCError'],
})(ValidationError);
