import React from 'react';
import { observer } from 'mobx-react';
import BEM from '../../../common/bem';
import { MessageSubTypes } from '../../../models/enums/MessageSubTypes';

const classes = BEM.with('ScheduleMessageHeader');

function ScheduleMessageHeader({ message, headerText = 'SCHEDULED MESSAGE' }) {
  const { isOutgoing, subType } = message;
  const isVwrCallInvite = subType === MessageSubTypes.VIRTUAL_WAITING_ROOM_CALL_INVITE;

  const direction = isOutgoing && !isVwrCallInvite ? 'OUTGOING' : 'INCOMING';

  return (
    <div className={classes({ direction })}>
      <div className={classes('scheduled-text')}>{headerText}</div>
    </div>
  );
}

export default observer(ScheduleMessageHeader);
