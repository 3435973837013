import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider as MobxProvider } from 'mobx-react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '../redux-stores';
import DebugUI from '../common/components/DebugUI/DebugUI';
import Router from './Router';

class Root extends Component {
  static propTypes = {
    stores: PropTypes.object.isRequired,
  };

  render() {
    const { stores } = this.props;

    return (
      <MobxProvider {...stores}>
        <ReduxProvider store={store}>
          <Router />
          <DebugUI />
        </ReduxProvider>
      </MobxProvider>
    );
  }
}

export default Root;
