import React from 'react';
import { useDispatch } from 'react-redux';

import { Dropdown } from '../../shared-components/Dropdown/Dropdown';
import { switchOrganization } from '../../store/admin/thunk';

import styles from './index.module.css';
import { actions, useAppSelector } from 'redux-stores';

const { setUsersPageView } = actions;

export default function OrganizationsDropdown() {
  const dispatch = useDispatch();
  const { organizations, selectedOrganizationId, selectedPage } = useAppSelector((state) => ({
    organizations: state.admin.organizations,
    selectedOrganizationId: state.admin.selectedOrganizationId,
    selectedPage: state.admin.selectedPage,
  }));

  const setSelectedOrganization = (selectedOrganizationId: string) => {
    switchOrganization(dispatch, selectedOrganizationId);
    dispatch(setUsersPageView({ selectedUsersPageView: 'users' }));
  };

  return (
    <div className={styles.dropdownContainer}>
      {organizations && selectedPage !== 'Organizations' && selectedPage !== 'Customer Support' && (
        <Dropdown
          options={Object.values(organizations).map(({ token, name }) => ({
            label: name,
            value: token,
          }))}
          defaultValue={selectedOrganizationId}
          onChange={setSelectedOrganization}
        />
      )}
    </div>
  );
}
