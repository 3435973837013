import React from 'react';
import styles from './RadioInput.module.css';

export interface RadioInputProps {
  /**
   * Optional label
   */
  label?: string;
  /**
   * Mandatory name to be provided
   */
  name: string;
  /**
   * Optional selected value of Radio button group
   */
  selectedValue?: string;
  /**
   * Optional value Radio button
   */
  value?: string;
  /**
   * Optional click handler
   */
  dataTestId?: string;
  onRadioClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioInput: React.FC<RadioInputProps> = ({
  label,
  name,
  selectedValue,
  onRadioClick,
  value,
  dataTestId,
}) => {
  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    onRadioClick && onRadioClick(e);
  };

  const buttonValue = value ?? label;

  return (
    <div className={styles.radioInputContainer}>
      <input
        type="radio"
        name={name}
        id={`${name}-${label}`}
        value={buttonValue}
        checked={selectedValue === buttonValue}
        onChange={handleRadioClick}
      />
      <label htmlFor={`${name}-${label}`} className={styles.label}>
        <span data-test-id={dataTestId}></span>
        <p>{label}</p>
      </label>
    </div>
  );
};
