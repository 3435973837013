import createMobxModel from './createMobxModel';

const ProductRoles = createMobxModel({
  name: 'productRoles',

  fields: ['isAdmin', 'isAnalyticsAdmin', 'isLdapAdmin', 'isSuperAdmin'],

  relations: {
    one: {
      organization: { type: 'organization' },
    },
  },
});

export default ProductRoles;
