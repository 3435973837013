import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import propTypes from '../../../propTypes';
import { ReactComponent as DeleteButtonSvg } from '../../../images/patient-delete-button.svg';
import { ReactComponent as EditButtonSvg } from '../../../images/patient-edit-button.svg';
import { ReactComponent as PatientDataSvg } from '../../../images/patient-data.svg';
import mobxInjectSelect from '../../../utils/mobxInjectSelect';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('PatientAdminCardDetails');

class PatientAdminCardDetails extends Component {
  static propTypes = {
    allowAutomatedAppointmentReminders: PropTypes.bool,
    entity: propTypes.user.isRequired,
    index: PropTypes.number.isRequired,
    isPatientEdited: PropTypes.bool,
    logPendoAnalytics: PropTypes.func.isRequired,
    showPatientEditModal: PropTypes.func.isRequired,
    showPatientDeleteModal: PropTypes.func.isRequired,
    setSelectedPatientId: PropTypes.func.isRequired,
    isAccessibleFocused: PropTypes.bool,
  };

  state = {
    isHovered: false,
  };

  render() {
    const { allowAutomatedAppointmentReminders, entity, isAccessibleFocused, index } = this.props;
    const { isHovered } = this.state;
    const { patient } = entity;
    const { smsOptedOut } = patient;

    return (
      <div className={classes({ smsOptedOut })}>
        <div
          className={classes('patient-row', { smsOptedOut })}
          aria-label="patient-row"
          onMouseLeave={this.onMouseLeave}
          onMouseEnter={this.onMouseEnter}
        >
          {(isHovered || isAccessibleFocused) && (
            <div className={classes('option-button-container')} role="menu">
              <div
                role="button"
                tabIndex={0}
                className={classes('option-button')}
                aria-label="Edit patient"
                onClick={this._editPatient}
                onKeyDown={(e) => {
                  if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
                    this._editPatient();
                  }
                }}
              >
                <EditButtonSvg className={classes('edit-button-icon')} aria-hidden />
                <span className={classes('button-label')}>Edit</span>
              </div>
              <div
                role="button"
                tabIndex={0}
                className={classes('option-button-delete', {
                  allowAutomatedAppointmentReminders,
                })}
                aria-label="Delete patient"
                onClick={this._deletePatient}
                onKeyDown={(e) => {
                  if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
                    this._deletePatient();
                  }
                }}
              >
                <DeleteButtonSvg className={classes('delete-button-icon')} aria-hidden />
                <span className={classes('button-label')}>Delete</span>
              </div>
              {allowAutomatedAppointmentReminders && (
                <div
                  role="button"
                  tabIndex={0}
                  className={classes('option-button-about')}
                  aria-label="About patient"
                  onClick={this._allowAutomatedApptReminders}
                  onKeyDown={(e) => {
                    if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
                      this._allowAutomatedApptReminders();
                    }
                  }}
                >
                  <div className={classes('patient-data-icon')}>
                    <PatientDataSvg aria-hidden />
                  </div>
                  <span className={classes('button-label')}>Patient Data</span>
                </div>
              )}
            </div>
          )}
          <div className={classes('patient-row-left')}>
            <div className={classes('patient-name')}>
              <div className={classes('title')}>First</div>
              <div className={classes('name')} id={`${index}-patientFirstName`}>
                {entity.firstName}
              </div>
            </div>
            <div className={classes('patient-name')}>
              <div className={classes('title')}>Last</div>
              <div className={classes('name')} id={`${index}-patientLastName`}>
                {entity.lastName}
              </div>
            </div>
          </div>
          <div className={classes('patient-row-right')}>
            <div className={classes('patient-name-width')}>
              <div className={classes('title')}>Phone</div>
              <div className={classes('detail-phone')}>{patient.phoneNumber}</div>
              {smsOptedOut && (
                <div className={classes('sms-opted-out-info')}>Opted out via SMS</div>
              )}
            </div>
            <div className={classes('patient-mrn')}>
              <div className={classes('title')}>MRN</div>
              <div className={classes('detail-mrn')}>{patient.mrn}</div>
            </div>
            <div>
              <div className={classes('title')}>Gender</div>
              <div className={classes('detail-gender')}>{patient.gender}</div>
            </div>
            <div>
              <div className={classes('title')}>DOB</div>
              <div className={classes('detail-dob')}>{patient.dob}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _editPatient = () => {
    const { entity, isPatientEdited, showPatientEditModal } = this.props;

    document.activeElement.blur();
    showPatientEditModal(entity);
    if (isPatientEdited) this.forceUpdate();
  };

  _deletePatient = () => {
    const { showPatientDeleteModal, entity, index } = this.props;

    document.activeElement.blur();
    showPatientDeleteModal(entity, index);
  };

  _allowAutomatedApptReminders = () => {
    const { logPendoAnalytics, entity, setSelectedPatientId } = this.props;

    setSelectedPatientId(entity.id);
    logPendoAnalytics({
      parentPathName: 'Patient Settings',
      pathName: 'Patient Data',
    });
  };

  onMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovered: false });
  };
}

export default mobxInjectSelect({
  patientStore: ['isPatientEdited', 'showPatientDeleteModal', 'showPatientEditModal'],
  patientAdminStore: ['allowAutomatedAppointmentReminders'],
  trackerStore: ['logPendoAnalytics'],
})(PatientAdminCardDetails);
