import { ServerValueEnum } from '../../utils/enum';

const PatientCareCardUIType = new ServerValueEnum(
  {
    ACTION: { serverValue: 'ehr_action_item' },
    HEADER: { serverValue: 'ehr_header' },
    ITEM: { serverValue: 'table_item' },
    TEXT: { serverValue: 'ehr_text_view' },
  } as const,
  { defaultValue: 'NA' }
);

export default PatientCareCardUIType;
