import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@tigerconnect/web-component-library';
import { addMinutes, isToday, lightFormat, parseISO } from 'date-fns';
import BEM from '../../bem';
import { ReactComponent as DndStatusSvg } from '../../images/dnd-status.svg';
import { ReactComponent as StatusMessageSelector } from '../../images/status-message-selector.svg';
import { mobxInjectSelect } from '../../utils';
import { DotsIndicator } from '../';
import { AutoForwardItem } from './';
import { actions, useAppDispatch } from 'redux-stores';

const classes = BEM.with('NewProfileStatus');

function ProfileStatus({
  autoForwardEntitiesDisplayName,
  autoForwardOrganizations,
  dnd,
  dndExpireAt,
  dndText,
  inRole,
  loadingAutoForward,
  loadAutoForwardOrganizations,
  logPendoAnalytics,
  update,
}) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadAutoForwardOrganizations();
  }, [loadAutoForwardOrganizations]);

  useEffect(() => {
    setLoading(false);
  }, [dnd]);

  async function updateDnd(dnd, newDndExpireAt) {
    if (typeof dnd !== 'boolean') return;
    setLoading(true);
    await update({
      dnd,
      dndExpireAt: dnd ? newDndExpireAt : undefined,
      dndText,
    });
  }

  function showSelectedOption(minutes) {
    if (inRole) return;
    if (minutes === 'Custom') {
      dispatch(actions.setModal({ name: 'customDndTime' }));
      logPendoAnalytics({
        tracker: {
          name: 'Admin + Settings | Settings - Unavailable Custom',
        },
      });
      return;
    }

    let newDndExpireAt = undefined;
    if (typeof minutes === 'number') {
      logPendoAnalytics({
        tracker: {
          name: 'Admin + Settings | Settings - Unavailable Quick Set',
          props: { UnavailableMinutes: minutes },
        },
      });
      const dndDate = addMinutes(new Date(), minutes);
      newDndExpireAt = dndDate.toISOString();
    } else {
      logPendoAnalytics({
        tracker: {
          name: 'Admin + Settings | Settings - Unavailable Indefinite',
        },
      });
    }
    updateDnd(true, newDndExpireAt);
  }

  function buildDNDDateString() {
    if (!dndExpireAt) return 'Indefinite';
    let formatString = '';
    if (isToday(parseISO(dndExpireAt))) {
      formatString = 'h:mm a';
    } else {
      formatString = 'h:mm a MM/dd/yy';
    }

    return lightFormat(parseISO(dndExpireAt), formatString);
  }

  const dotsFragment = (
    <div className={classes('dots-container-text')}>
      <DotsIndicator className={classes('dots')} size={10} color={'#00a945'} />
    </div>
  );

  return (
    <div className={classes()}>
      <Card header={'MAKE ME UNAVAILABLE FOR'}>
        {loading ? (
          dotsFragment
        ) : dnd ? (
          <div className={classes('dnd-container')}>
            <div className={classes('selected-timer-container')}>
              <div className={classes('dnd-banner')}>
                <div className={classes('dnd-svg')}>
                  <DndStatusSvg className={classes('dnd-status-svg')} />
                </div>
                <div className={classes('dnd-text')} title={dndText || `You're Unavailable`}>
                  {dndText || `You're Unavailable`}
                </div>
              </div>
              <div className={classes('dnd-timer-info', { isTimerSet: true })}>
                <div className={classes('dnd-until-container')}>
                  <div className={classes('dnd-until-time')}>
                    Unavailable Until:{' '}
                    <span className={classes('dnd-end-time')}>{buildDNDDateString()}</span>
                  </div>
                  <div
                    className={classes('dnd-cancel')}
                    data-test-id={'dnd-cancel-button'}
                    onClick={() => {
                      updateDnd(false);
                      logPendoAnalytics({
                        tracker: {
                          name: 'Admin + Settings | Settings - Unavailable Cancel',
                          props: { UnavailableCancelLocation: 'Settings' },
                        },
                      });
                    }}
                  >
                    CANCEL
                  </div>
                </div>
                <div
                  className={classes('dnd-status-message-container')}
                  onClick={() => {
                    dispatch(actions.setModal({ name: 'customDndMessage' }));
                  }}
                  data-test-id={'open-customDndMessageModal'}
                >
                  <div className={classes('dnd-status-message')}>Select Unavailable Message</div>
                  <div className={classes('dnd-status-message-icon')}>
                    <StatusMessageSelector />
                  </div>
                </div>
              </div>
            </div>
            {autoForwardOrganizations && autoForwardOrganizations.length > 0 && (
              <div className={classes('auto-forward-info')}>
                <div className={classes('auto-forward-header')}>Auto-Forward</div>
                {loadingAutoForward ? (
                  <div className={classes('dots-container')}>
                    <DotsIndicator className={classes('dots')} size={10} color={'#00a945'} />
                  </div>
                ) : (
                  <ul className={classes('list')}>
                    {autoForwardOrganizations.map((organization) => (
                      <li key={organization.id}>
                        <AutoForwardItem
                          organization={organization}
                          autoForwardEntitiesDisplayName={autoForwardEntitiesDisplayName(
                            organization
                          )}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        ) : (
          <>
            <div className={classes('dnd-timer-info')}>
              <div className={classes('dnd-timer-container')}>
                <div className={classes('timers')}>
                  {[5, 10, 15, 30].map((time) => (
                    <div
                      className={classes('timer-option')}
                      key={time}
                      data-test-id={`${time}-dnd-timer-option`}
                      onClick={() => showSelectedOption(time)}
                    >
                      {time} min
                    </div>
                  ))}
                </div>
                <div className={classes('timers')}>
                  {[60, 240, 'Custom', 'Indefinite'].map((time) => (
                    <div
                      className={classes('timer-option')}
                      key={time}
                      data-test-id={`${time}-dnd-timer-option`}
                      onClick={() => showSelectedOption(time)}
                    >
                      {typeof time === 'string' ? `${time}` : `${time / 60} hr`}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {inRole && (
              <div className={classes('dnd-blocked-container')}>
                <div className={classes('dnd-blocked-bg')}></div>
                <div className={classes('dnd-blocked-content')}>
                  <div className={classes('dnd-blocked-text')}>
                    Not available while opted into a Role
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  );
}

ProfileStatus.propTypes = {
  dnd: PropTypes.bool,
  dndExpireAt: PropTypes.string,
  dndText: PropTypes.string,
  inRole: PropTypes.bool,
  isExtendedAutoForwardOptionsEnabled: PropTypes.bool,
  loadingAutoForward: PropTypes.bool.isRequired,
  loadAutoForwardOrganizations: PropTypes.func.isRequired,
  updateDnd: PropTypes.bool,
  update: PropTypes.func.isRequired,
};

export default mobxInjectSelect({
  trackerStore: ['logPendoAnalytics'],
  userStore: [
    'autoForwardOrganizations',
    'loadingAutoForward',
    'loadAutoForwardOrganizations',
    'update',
  ],
})(ProfileStatus);
