import React, { useEffect, useState } from 'react';
import { User } from '../../../types';
import BEM from '../../bem';
import mobxInjectSelect from '../../utils/mobxInjectSelect';

const classes = BEM.with('AutoForwardMessageHeader');

type AutoForwardMessageHeaderProps = {
  autoForwardToDisplayName: string;
  message: {
    autoForwardedFrom: User;
    availabilityDndAutoForwardedTo: {
      groupMembers: number;
      token: string;
      type: string;
    };
    isOutgoing: boolean;
    isAvailabilityDndAutoForwardedTo: boolean;
  };
};

type MobxProps = {
  currentOrganizationId: string;
  findRole: (roleId: string, orgId: string) => Promise<{ displayName: string }>;
  findTeam: (teamId: string) => Promise<{ displayName: string }>;
};

function AutoForwardMessageHeader({
  autoForwardToDisplayName,
  currentOrganizationId,
  findRole,
  findTeam,
  message,
}: AutoForwardMessageHeaderProps & MobxProps) {
  const {
    autoForwardedFrom,
    availabilityDndAutoForwardedTo,
    isOutgoing,
    isAvailabilityDndAutoForwardedTo,
  } = message;
  const direction = isOutgoing ? 'OUTGOING' : 'INCOMING';
  const [currentAutoForwardToDisplayName, setCurrentAutoForwardToDisplayName] =
    useState<string>('');

  useEffect(() => {
    const getAutoForwardEntityType = availabilityDndAutoForwardedTo?.type?.split('entity:')[1];
    const fetchTeam = async () => {
      const team = await findTeam(availabilityDndAutoForwardedTo?.token);
      setCurrentAutoForwardToDisplayName(team.displayName);
    };

    const fetchRole = async () => {
      const role = await findRole(availabilityDndAutoForwardedTo?.token, currentOrganizationId);
      setCurrentAutoForwardToDisplayName(role.displayName);
    };

    if (isAvailabilityDndAutoForwardedTo && !autoForwardToDisplayName) {
      if (getAutoForwardEntityType === 'team') {
        fetchTeam();
      } else if (getAutoForwardEntityType === 'role') {
        fetchRole();
      }
      if (!currentAutoForwardToDisplayName) {
        setCurrentAutoForwardToDisplayName('unknown');
      }
    } else {
      setCurrentAutoForwardToDisplayName(autoForwardToDisplayName);
    }
  }, [
    autoForwardToDisplayName,
    availabilityDndAutoForwardedTo,
    currentAutoForwardToDisplayName,
    currentOrganizationId,
    findRole,
    findTeam,
    isAvailabilityDndAutoForwardedTo,
  ]);

  return (
    <div className={classes({ direction })}>
      <div className={classes('auto-forward-text')}>
        {autoForwardedFrom
          ? `Auto-forwarded from ${autoForwardedFrom.displayName}`
          : `Auto-forwarded to ${currentAutoForwardToDisplayName}`}
      </div>
    </div>
  );
}

export default mobxInjectSelect<AutoForwardMessageHeaderProps, MobxProps>({
  messengerStore: ['currentOrganizationId'],
  roleStore: ['findRole'],
  teamStore: ['findTeam'],
})(AutoForwardMessageHeader);
