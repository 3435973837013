import type { Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { uniq, flatten, concat, compact } from 'lodash';
import TCClient from '../../../client';
import { AdminStateSlice, UserData } from '../../types';
import { actions, store, thunk } from 'redux-stores';

const CONSUMER_ORG = 'UpM28mnfW65vJkbqEATtKvxm';

export const initiateAdminSession = async (
  dispatch: Dispatch,
  initialOrgId: string,
  switchApp: (app: string) => void
) => {
  try {
    await loadOrganization(dispatch, initialOrgId, {
      initialLoad: true,
    });
  } catch (e) {
    switchApp('Messages');
    console.error('Error when trying to initiate admin session');
  }
};

export const switchOrganization = async (dispatch: Dispatch, selectedOrganizationId: string) => {
  try {
    await loadOrganization(dispatch, selectedOrganizationId);
  } catch (e) {
    console.error('Error when trying to fetch account settings');
  }
};

export const setToastSettings = async (
  toastSettings: Partial<AdminStateSlice['toastSettings']>
) => {
  store.dispatch(
    actions.setToastSettings({
      ...toastSettings,
      onClose: () => {
        toastSettings.onClose?.();
        setToastSettings({ open: false });
      },
    })
  );
};

export const fetchUserPermissions = async (userId: string) => {
  const {
    admin: { selectedOrganization },
  } = store.getState();
  const permissions = await TCClient.adminUsers.getUserPermissions({
    orgId: selectedOrganization.token,
    userId: userId,
  });
  return permissions;
};

const loadOrganization = async (
  dispatch: Dispatch,
  selectedOrganizationId: string,
  options?: {
    initialLoad?: boolean;
  }
) => {
  const settings = await TCClient.adminUsers.getAccountSettings({});

  if (options?.initialLoad) {
    const {
      admin: { organizations },
    } = store.getState();
    if (!organizations[selectedOrganizationId]) {
      const orgs = settings?.membership?.organization
        .filter(
          ({ admin, token }: { admin: boolean; token: string }) => token !== CONSUMER_ORG && admin
        )
        .sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name));

      dispatch(actions.setOrganizations({ organizations: orgs }));
    }
  }
  const selectedOrg = settings?.permissions?.organization?.find(
    (org) => org.organizationId === selectedOrganizationId
  );

  const permissions = compact(
    uniq(flatten(concat([selectedOrg?.permission], [settings?.permissions?.product?.permission])))
  );

  batch(() => {
    dispatch(actions.setPermissions({ permissions }));
    dispatch(actions.setSelectedOrganizationId({ selectedOrganizationId }));
    dispatch(actions.setSelectedPage({ selectedPage: 'Users' }));
    dispatch(actions.setAdminIsInitiated());
    dispatch(actions.customerSupportSearchOrganizationData([]));
    dispatch(actions.customerSupportSearchAccountData(null));
    dispatch(actions.customerSupportSearchParseAccountData({} as UserData));
    dispatch(actions.customerSupportSearchSearchInput(''));
  });

  await thunk.fetchSettings(dispatch);
};
