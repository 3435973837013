import { getIfUserIsNotInConversation } from 'common/utils/getIfUserIsNotInConversation';
import { Conversation, Entity, Role, User } from 'types';

export const shouldAddMemberToConversation = (
  currentUserId: string,
  member: User | Role | Entity,
  currentConversation?: Conversation
) => {
  const isUserNotInConversation = getIfUserIsNotInConversation(
    currentConversation,
    currentUserId,
    member?.botUser?.id || member?.id
  );

  const isCurrentConversationGroup =
    currentConversation?.counterPartyType?.toLowerCase() === 'group';

  return (
    isUserNotInConversation &&
    isCurrentConversationGroup &&
    currentConversation?.counterParty.groupType !== 'ROLE_P2P' &&
    currentConversation?.counterParty.groupType !== 'INTRA_TEAM' &&
    !(currentConversation?.counterParty.groupType === 'FORUM' && member?.$entityType === 'role')
  );
};
