import React, { useCallback, useEffect, useState } from 'react';

import { PatientReferenceSearchMode } from './PatientReferenceSearch';
import DotsIndicator from 'common/components/DotsIndicator';
import BEM from 'common/bem';
import { actions, thunk, useAppDispatch, useAppSelector } from 'redux-stores';
import {
  PatientContextModel,
  PATIENT_REFERENCE_SEARCH_RESULT_PAGE_SIZE,
  PATIENT_DIRECTORY_PAGE_SIZE,
} from 'redux-stores/patientContext';
import useDebounceVar from 'common/hooks/useDebounceVar';
import { useScrollListener } from 'common/hooks';

const classes = BEM.with('PatientReferenceSearchResults');

const { setPatientSearchList } = actions;
const { searchPatientContextsThunk } = thunk;

type PatientReferenceSearchResultsProps = {
  renderResult: (result: PatientContextModel, index: number) => React.ReactElement;
  pageSize?: number;
  inputValue?: string;
  scrollRef: React.RefObject<HTMLDivElement>;
  patientSearchView?: string;
  mode?: PatientReferenceSearchMode;
};

const PATIENT_SEARCH_DEBOUNCE_VAL = 300;
const LOAD_MORE_SCROLL_RATIO = 0.7;

function PatientReferenceSearchResults({
  renderResult,
  inputValue = '',
  scrollRef,
  patientSearchView = 'All',
  mode = 'Directory',
}: PatientReferenceSearchResultsProps) {
  const dispatch = useAppDispatch();
  const pageSize =
    mode === 'Directory' ? PATIENT_DIRECTORY_PAGE_SIZE : PATIENT_REFERENCE_SEARCH_RESULT_PAGE_SIZE;
  const {
    isPatientReferenceSearchOnLastPage,
    patientReferenceSearchContinuation,
    patientReferenceSearchList: results,
    patientReferenceSearchLoading,
  } = useAppSelector(
    ({
      patientContext: {
        isPatientReferenceSearchOnLastPage,
        patientReferenceSearchContinuation,
        patientReferenceSearchList,
        patientReferenceSearchLoading,
      },
    }) => {
      return {
        isPatientReferenceSearchOnLastPage,
        patientReferenceSearchContinuation,
        patientReferenceSearchList,
        patientReferenceSearchLoading,
      };
    }
  );
  const [query, setQuery] = useState(inputValue);
  const [isTyping, setIsTyping] = useState(false);
  const [from, setFrom] = useState(0);
  const debouncedQuery = useDebounceVar(query, PATIENT_SEARCH_DEBOUNCE_VAL);

  useEffect(
    function cleanUp() {
      return () => {
        dispatch(setPatientSearchList());
      };
    },
    [dispatch]
  );

  useEffect(
    function handleQueryProp() {
      if (inputValue !== query) {
        if (!isTyping) setIsTyping(true);
        setQuery(inputValue);
      }
    },
    [inputValue, isTyping, query]
  );

  useScrollListener(
    scrollRef.current,
    useCallback(
      ({ target }: React.ChangeEvent<HTMLElement>) => {
        const { clientHeight, scrollHeight, scrollTop } = target;
        if (patientReferenceSearchLoading || isPatientReferenceSearchOnLastPage) return;
        if (patientSearchView === 'My Patients') {
          if (
            patientReferenceSearchContinuation &&
            clientHeight / (scrollHeight - scrollTop) > LOAD_MORE_SCROLL_RATIO
          ) {
            dispatch(
              searchPatientContextsThunk({
                query: debouncedQuery,
                from,
                pageSize,
                continuationValue: patientReferenceSearchContinuation,
              })
            );
          }
        } else {
          if (clientHeight / (scrollHeight - scrollTop) > LOAD_MORE_SCROLL_RATIO) {
            setFrom(from + pageSize);
            dispatch(searchPatientContextsThunk({ query: debouncedQuery, from, pageSize }));
          }
        }
      },
      [
        dispatch,
        debouncedQuery,
        isPatientReferenceSearchOnLastPage,
        patientReferenceSearchLoading,
        from,
        pageSize,
        patientReferenceSearchContinuation,
        patientSearchView,
      ]
    )
  );

  const resetLocalState = useCallback(() => {
    dispatch(setPatientSearchList());
    setIsTyping(false);
    setFrom(0);
  }, [dispatch]);

  useEffect(() => {
    resetLocalState();
    dispatch(searchPatientContextsThunk({ query: debouncedQuery, from: 0, pageSize }));
  }, [dispatch, debouncedQuery, resetLocalState, pageSize, patientSearchView]);

  return (
    <div className={classes()}>
      <div className={classes('search-results-container')}>{renderSearchResults()}</div>
    </div>
  );

  function renderSearchResults() {
    if (isTyping || results === undefined) {
      return (
        <div className={classes('search-results-text-response')}>
          <DotsIndicator size={13} />
        </div>
      );
    } else if (results && results?.length > 0) {
      return (
        <>
          {results.map(renderResult)}
          {patientReferenceSearchLoading && (
            <div className={classes('search-results-page-loader')}>
              <DotsIndicator size={13} />
            </div>
          )}
        </>
      );
    } else if (results?.length === 0) {
      if (!patientReferenceSearchLoading) {
        if (debouncedQuery === '') {
          return null;
        } else if (debouncedQuery !== '') {
          return (
            <div className={classes('search-results-text-response')}>
              No Patient Reference with
              <br />
              &ldquo;{debouncedQuery}&rdquo; was found
            </div>
          );
        }
      }
    }
  }
}
export default PatientReferenceSearchResults;
