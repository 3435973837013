import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import propTypes from '../propTypes';
import { mobxInjectSelect } from '../utils';
import { Switch } from './WebComponents';

const classes = BEM.with('MuteButton');

class MuteButton extends Component {
  static propTypes = {
    conversation: propTypes.conversation.isRequired,
    showMuteModal: PropTypes.func.isRequired,
    unmuteConversation: PropTypes.func.isRequired,
  };

  render() {
    const {
      conversation: { isMuteable, isMuted },
    } = this.props;

    return (
      <div className={classes({ disabled: !isMuteable })}>
        <div className={classes('option-header', { disabled: !isMuteable })}>Mute</div>
        <div aria-label="Info Pane Mute" className={classes('option-button')}>
          <Switch
            disabled={!isMuteable}
            checked={isMuted}
            onSwitch={this._onMuteButtonClick}
            dataTestId="mute"
          />
        </div>
      </div>
    );
  }

  _onMuteButtonClick = () => {
    const { conversation, showMuteModal, unmuteConversation } = this.props;
    const { id: conversationId, isMuteable, isMuted } = conversation;
    if (!isMuteable) return;

    isMuted ? unmuteConversation(conversationId) : showMuteModal(conversation);
  };
}

export default mobxInjectSelect({
  conversationStore: ['showMuteModal', 'unmuteConversation'],
})(MuteButton);
