import React from 'react';

import { ReactComponent as TwoWayArrowsIcon } from '../../images/two-way-arrows.svg';
import { ReactComponent as OneWayArrowIcon } from '../../images/one-way-arrow.svg';

type DeliveryMethodIconProps = {
  circleColor?: string;
  color?: string;
  size?: number;
  isSecure?: boolean;
};

type IconStyle = {
  borderRadius?: string;
  backgroundColor?: string;
  height: string;
  width: string;
};

export const DeliveryMethodIcon: React.FC<DeliveryMethodIconProps> = ({
  circleColor,
  color,
  isSecure,
  size = 24,
}) => {
  const style: IconStyle = {
    height: `${size}px`,
    width: `${size}px`,
  };
  let iconColor = color || '#DF655F';
  let viewBox = '0 0 24 21';

  if (isSecure) {
    iconColor = color || '#4A657B';
    viewBox = '1 1 22 22';
  }

  if (circleColor) {
    style.backgroundColor = circleColor;
    style.borderRadius = `${size}px`;
  }

  return (
    <svg
      width="20"
      height="20"
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      {isSecure && (
        <>
          <rect x="7" y="10.3857" width="10" height="8.61446" rx="2" fill={iconColor} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5816 8.49963C8.5816 6.56684 10.1133 5 12.0027 5C13.8921 5 15.4237 6.56684 15.4237 8.49963V12.5377H8.5816V8.49963ZM9.63239 8.57664C9.63239 7.19607 10.6928 6.0769 12.0008 6.0769C13.3088 6.0769 14.3692 7.19607 14.3692 8.57664V10.3841H9.63239V8.57664Z"
            fill={iconColor}
          />
        </>
      )}
      {!isSecure && (
        <>
          <rect x="7" y="9.38574" width="10" height="8.61446" rx="2" fill={iconColor} />
          <path
            d="M15 6.41301V5.82603C15 4.26526 13.6569 3 12 3C10.3431 3 9 4.26526 9 5.82603V10H12"
            stroke={iconColor}
            strokeWidth="1.1"
          />
          <path
            d="M15 6.41301V5.82603C15 4.26526 13.6569 3 12 3C10.3431 3 9 4.26526 9 5.82603V10H12"
            stroke={iconColor}
            strokeWidth="1.1"
          />
          <path
            d="M7 11.3857C7 10.2812 7.89543 9.38574 9 9.38574H15C16.1046 9.38574 17 10.2812 17 11.3857V16.0002C17 17.1048 16.1046 18.0002 15 18.0002H9C7.89543 18.0002 7 17.1048 7 16.0002V11.3857Z"
            fill={iconColor}
          />
          <line
            x1="17.298"
            y1="6.27566"
            x2="18.8365"
            y2="5.76283"
            stroke={iconColor}
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="0.375"
            y1="-0.375"
            x2="1.99671"
            y2="-0.375"
            transform="matrix(0.948683 0.316228 0.316228 -0.948683 17.0609 8.25)"
            stroke={iconColor}
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="0.375"
            y1="-0.375"
            x2="1.99671"
            y2="-0.375"
            transform="matrix(1 0 0 -1 17 7.125)"
            stroke={iconColor}
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )}
    </svg>
  );
};

type DeliveryDirectionProps = {
  className?: string;
  shouldShowArrows?: boolean;
};

export const OneWayDelivery: React.FC<DeliveryDirectionProps> = ({
  className = '',
  shouldShowArrows = true,
}) => (
  <>
    <span className={className}>One-Way</span>
    {shouldShowArrows && <OneWayArrowIcon />}
  </>
);

export const TwoWayDelivery: React.FC<DeliveryDirectionProps> = ({
  className = '',
  shouldShowArrows = true,
}) => (
  <>
    <span className={className}>Two-Way</span>
    {shouldShowArrows && <TwoWayArrowsIcon />}
  </>
);

export default DeliveryMethodIcon;
