import React, { useState, RefObject, useEffect } from 'react';
import classNames from 'classnames';
import searchLoader from '../../images/loader_search.gif';
import styles from './Input.module.css';

export interface InputProps {
  /**
   * Boolean to display black X clear button
   */
  hasClearButton?: boolean;
  shouldFocus?: boolean;

  /**
   * Can set the loading indicator outside the scope of clicking on it
   */
  isLoading?: boolean;

  /**
   * Optional callback for when the black X button is clicked
   */
  onClearClick?: () => Promise<void> | void;

  /**
   * Optional onFocus when the user clicks on input field
   */
  onFocus?: () => Promise<void> | void;

  /**
   * Optional callback for when user types into text field
   */
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Optional placeholder text
   */
  placeholder?: string;
  /*
   * Value of the input
   */
  value?: string;
  customCSS?: string;
  inputRef?: RefObject<HTMLInputElement>;
  lengthLimit?: number;
  dataTestId?: string;
  disabled?: boolean;
  /*
   * Optional form-style submit handler on pressing enter
   */
  onEnter?: () => unknown | Promise<unknown>;
}

export const Input: React.FC<InputProps> = ({
  dataTestId,
  hasClearButton = false,
  isLoading = false,
  onClearClick,
  onFocus,
  shouldFocus = false,
  onInputChange,
  placeholder = '',
  value = '',
  customCSS = '',
  inputRef,
  lengthLimit,
  disabled = false,
  onEnter,
}) => {
  const [isClearing, setIsClearing] = useState(false);
  const handleClearButton = async () => {
    setIsClearing(true);
    onClearClick && (await onClearClick());
    setIsClearing(false);
  };

  useEffect(() => {
    if (shouldFocus && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [shouldFocus, inputRef]);

  return (
    <div className={classNames(styles.inputRoot, customCSS)}>
      <input
        className={classNames(styles.input, hasClearButton && styles.hasClearButton)}
        placeholder={placeholder}
        type={'text'}
        onChange={onInputChange}
        value={value}
        ref={inputRef}
        maxLength={lengthLimit}
        data-test-id={dataTestId}
        disabled={disabled}
        onFocus={onFocus}
        onKeyDown={async (e) => {
          if (e.key === 'Enter' && onEnter) {
            await onEnter();
          }
        }}
      />
      {hasClearButton &&
        (isLoading || isClearing ? (
          <div
            className={classNames(styles.buttonContainer, styles.loadingContainer)}
            data-test-id="cancelSearch"
          >
            <img src={searchLoader} className={styles.loadingImage} alt="" />
          </div>
        ) : (
          <div
            className={classNames(styles.clearButton, styles.buttonContainer)}
            onClick={handleClearButton}
            data-test-id="cancelSearch"
          />
        ))}
    </div>
  );
};
