import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import { ReactComponent as DragRejectedSvg } from '../../widgets/messenger/images/drag-rejected.svg';
import Modal from './Modal';

const classes = BEM.with('RejectedFileSizeModal');

class RejectedFileSizeModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
  };

  render() {
    const { closeModal, isOpen, options } = this.props;
    const { maxFileSize = '10MB' } = options;
    const imgHeader = {
      image: DragRejectedSvg,
      imgClassHeader: classes('img'),
    };

    return (
      <Modal
        bodyClass={classes('body')}
        closeClass={classes('close-button')}
        footerClass={classes('footer')}
        header={'File size is too big'}
        headerClass={classes('header')}
        imgHeader={imgHeader}
        isOpen={isOpen}
        size={'medium-small'}
        footerSecondaryActions={
          <button type="button" onClick={closeModal} className={classes('cancel-btn')}>
            Got it
          </button>
        }
        className={classes()}
        onRequestClose={closeModal}
      >
        <div className={classes('info')}>Please use a file size smaller than {maxFileSize}</div>
      </Modal>
    );
  }
}

export default RejectedFileSizeModal;
