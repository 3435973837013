import React, { useEffect, useState } from 'react';
import { Switch } from '@tigerconnect/web-component-library';
import { DeliveryMethods } from '../../../models/enums/DeliveryMethods';
import { mobxInjectSelect } from '../../utils';
import BEM from '../../bem';
import { ScheduleOption } from '../../../models/enums';
import { ReactComponent as RepeatSvg } from '../../images/repeat.svg';
import { ReactComponent as ScheduleMessageHeaderSvg } from '../../images/schedule-message-header-icon.svg';
import { ReactComponent as ScheduleMessageSvg } from '../../images/schedule-message-content-icon.svg';
import { ReactComponent as ScheduleRecipientSvg } from '../../images/schedule-recipient-icon.svg';
import { ReactComponent as PreferencesSvg } from '../../images/scheduled-message-preferences.svg';
import { ReactComponent as ProviderSenderSvg } from '../../images/schedule-message-provider-sender.svg';
import { ReactComponent as StatusSvg } from '../../images/scheduled-message-status.svg';
import { ReactComponent as ScheduleSvg } from '../../images/schedule-icon.svg';
import { Modal, Scrollbars } from '../';
import PatientBroadcastListSearchResultDetails from '../PatientBroadcastListSearchResultDetails';
import PatientSearchResultHeader from '../PatientSearchResultHeader';
import { DeliveryMethodIcon } from '../PatientSettings/DeliveryMethodIcon';
import { Status } from '../PatientSettings/ScheduledMessages/ScheduleMessage';
import { DeliveryMethod } from '../ScheduleMessageModal/DeliveryMethodScheduledMessageMenu';
import { MessageInputs, ScheduleInputs } from './';

const { LINK } = DeliveryMethods;
const classes = BEM.with('ScheduleMessageModal');

function EditScheduleMessageModal({
  allowPatientDeliveryMethod,
  closeModal,
  isBackButtonPressed,
  isOpen,
  openModal,
  options,
  resetScheduledMessageState,
  scheduledMessage,
  setIsBackButtonPressed,
  smsMessageLengthLimit,
  updateScheduledMessage,
}) {
  const {
    appointmentId,
    deliveryMethod,
    noReply,
    recipient,
    recipientType,
    repeating,
    scheduledTime,
    sender,
    senderDisplayName,
    status,
  } = options;
  const { onDelete } = scheduledMessage;
  const isAutomated = !!appointmentId;
  const isRepeatOn = !!repeating;
  const isSecure = deliveryMethod === LINK;
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [isTemplateChanged, setIsTemplateChanged] = useState(false);

  useEffect(() => {
    const {
      body,
      repeatEnabled,
      repeatSchedule,
      scheduleOption,
      selectedEndDate,
      selectedStartDate,
      selectedTime,
    } = scheduledMessage;

    if (
      body.trim().length === 0 ||
      scheduleOption === ScheduleOption.NOT_SELECTED ||
      (scheduleOption === ScheduleOption.SET_DATE_TIME && (!selectedStartDate || !selectedTime)) ||
      (repeatEnabled && (!repeatSchedule || !selectedEndDate)) ||
      (!isSecure && body.length > smsMessageLengthLimit)
    ) {
      setNextButtonDisabled(true);
    } else if (isBackButtonPressed) {
      setNextButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledMessage]);

  const _closeModal = () => {
    setNextButtonDisabled(true);
    resetScheduledMessageState();
    setIsBackButtonPressed(false);
    closeModal();
  };

  const _editScheduledMessage = () => {
    updateScheduledMessage('recipient', recipient);
    updateScheduledMessage('recipientType', recipientType);
    updateScheduledMessage('scheduledTime', scheduledTime);
    updateScheduledMessage('sender', sender);
    updateScheduledMessage('status', status);

    openModal('scheduleMessageReview', {
      isEditScheduleMessage: true,
      isTemplateChanged: isTemplateChanged,
      reopenModal: '',
      scheduledMessageView: true,
      setIsTemplateChanged: setIsTemplateChanged,
    });
  };

  const _deleteScheduledMessage = () => {
    openModal('deleteScheduledMessage', {
      selected: [scheduledMessage.id],
      onClose: () => {
        _closeModal();
        if (onDelete) {
          onDelete();
        }
      },
      onCancel: () => openModal('editScheduleMessage', options),
    });
  };

  return (
    <Modal
      bodyClass={classes('body')}
      className={classes()}
      hasCloseButton
      closeClass={classes('close-btn')}
      header={
        <div className={classes('header-content')}>
          <ScheduleMessageHeaderSvg />
          <p>Schedule Message</p>
        </div>
      }
      headerClass={classes('header')}
      isOpen={isOpen}
      onRequestClose={_closeModal}
      size="large"
      footerClass={classes('footer')}
      footerPrimaryActions={
        <button
          type="button"
          className={classes('primary-btn')}
          disabled={nextButtonDisabled}
          onClick={_editScheduledMessage}
        >
          NEXT
        </button>
      }
      footerSecondaryActions={
        <>
          <button className={classes('delete-btn')} type="button" onClick={_deleteScheduledMessage}>
            DELETE
          </button>
          <button className={classes('secondary-btn')} type="button" onClick={_closeModal}>
            CANCEL
          </button>
        </>
      }
      shouldSplitSecondaryFooterActions
    >
      <div className={classes('form-container')}>
        <Scrollbars shouldScrollOverflow={true}>
          <div className={classes('sections-container')}>
            <div className={classes('section')} style={{ alignItems: 'center' }}>
              <StatusSvg />
              <div style={{ marginLeft: '20px' }}>
                <Status isAutomated={isAutomated} status={'queued'} />
              </div>
            </div>
            <div className={classes('section')}>
              <DeliveryMethodIcon circleColor="#ECF1F3" isSecure={isSecure} size={31} />
              <div className={classes('section-container', { isDeliverySection: true })}>
                <DeliveryMethod
                  copy={`${
                    isSecure ? 'Secure Browser Link: ' : 'Unsecure SMS Text: Non-'
                  }HIPAA Compliant`}
                  deliveryMethod={deliveryMethod}
                  noReply={noReply}
                  shouldShowArrows={false}
                  shouldShowIcon={false}
                />
              </div>
            </div>
            {senderDisplayName && (
              <div className={classes('section')}>
                <ProviderSenderSvg />
                <div style={{ marginLeft: '20px' }}>
                  <div
                    style={{
                      color: '#4a657b',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    From
                  </div>
                  <div
                    style={{
                      color: '#35333c',
                      fontSize: '13px',
                      fontWeight: '500',
                    }}
                  >
                    {senderDisplayName}
                  </div>
                </div>
              </div>
            )}
            <div className={classes('section')}>
              <ScheduleRecipientSvg />
              <div
                style={{
                  marginLeft: '20px',
                  backgroundColor: '#ecf1f3',
                  padding: '8px 10px',
                  borderRadius: '6px',
                }}
              >
                {recipientType === 'distributionList' ? (
                  <PatientBroadcastListSearchResultDetails
                    context="SELECTED_ITEM"
                    entity={recipient}
                  />
                ) : (
                  <PatientSearchResultHeader
                    avatarSize={25}
                    entity={recipient}
                    isSingleRecipient={true}
                  />
                )}
              </div>
            </div>
            <div className={classes('section')}>
              <ScheduleSvg />
              <div className={classes('section-container')}>
                <div className={classes('section-header')}>
                  <div className={classes('schedule-header')}>
                    <p>Schedule</p>
                    {!isAutomated && (
                      <div className={classes('switch-container')}>
                        <RepeatSvg className={classes('repeat')} />
                        <span>Repeat</span>
                        <div className={classes('switch')}>
                          <Switch
                            disabled={true}
                            checked={isRepeatOn}
                            theme={'patient'}
                            dataTestId="repeatSchedule"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <ScheduleInputs
                  repeatEnabled={isRepeatOn}
                  isEditing
                  setNextButtonDisabled={setNextButtonDisabled}
                />
              </div>
            </div>
            <div className={classes('section')}>
              <ScheduleMessageSvg />
              <div className={classes('section-container')}>
                <div className={classes('section-header')}>Message</div>
                <MessageInputs
                  isEditing
                  messageDeliveryMethod={deliveryMethod}
                  setIsTemplateChanged={setIsTemplateChanged}
                  setNextButtonDisabled={setNextButtonDisabled}
                />
              </div>
            </div>
            {!allowPatientDeliveryMethod && recipientType === 'distributionList' && (
              <div className={classes('section')}>
                <PreferencesSvg />
                <div className={classes('section-content')}>
                  <div className={classes('section-title')}>Preferences</div>
                  <div
                    className={classes('section-container', {
                      noReply: true,
                    })}
                  >
                    <div
                      className={classes('section-header', {
                        noReply: true,
                      })}
                    >
                      No-reply
                    </div>
                    <div className={classes('switch')}>
                      <Switch
                        disabled={true}
                        checked={noReply}
                        theme={'patient'}
                        dataTestId="noReply"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </Modal>
  );
}

export default mobxInjectSelect({
  messengerStore: ['currentOrganization'],
  modalStore: ['openModal'],
  scheduleMessageStore: [
    'isBackButtonPressed',
    'resetScheduledMessageState',
    'scheduledMessage',
    'setIsBackButtonPressed',
    'smsMessageLengthLimit',
    'updateScheduledMessage',
  ],
  patientAdminStore: ['allowPatientDeliveryMethod'],
})(EditScheduleMessageModal);
