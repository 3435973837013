import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { mobxInjectSelect } from '../../utils';
import propTypes from '../../propTypes';
import BEM from '../../bem';
import Spinner from '../Spinner';

import iconPlay from '../../../widgets/messenger/images/iconPlay.png';
import RetryAttachment from './RetryAttachment';

const classes = BEM.with('VideoAttachment');

class VideoAttachment extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    attachment: propTypes.messageAttachment.isRequired,
    attachmentUrl: PropTypes.string,
    backupAction: PropTypes.func.isRequired,
    getDownloadState: PropTypes.func.isRequired,
    isMultiSelectMode: PropTypes.bool.isRequired,
    messageId: PropTypes.string.isRequired,
    multiSelectable: PropTypes.bool.isRequired,
    retryDownload: PropTypes.func.isRequired,
  };

  render() {
    const {
      action,
      attachment,
      attachmentUrl,
      backupAction,
      getDownloadState,
      isMultiSelectMode,
      messageId,
      multiSelectable,
      retryDownload,
    } = this.props;
    const { localPath } = attachment;
    const attachmentDownloadState = getDownloadState(messageId, attachment.id);
    const isLoading = attachmentDownloadState.isLoading;
    const url = isLoading ? null : attachmentUrl || localPath || attachmentDownloadState.url;
    const isErrored = !url && !isLoading;
    let loaderFragment, videoFragment;

    if (url) {
      videoFragment = (
        <div
          className={classes('attachment-container', {
            unselectable: isMultiSelectMode && !multiSelectable,
          })}
          aria-label={`Open Preview ${attachment.name}`}
          onClick={isMultiSelectMode ? () => {} : () => action(url)}
          data-test-id={`open-preview-${attachment.name}`}
        >
          <video className={classes('video')} alt={attachment.name} src={url}>
            <span
              aria-label={`Download Video Attachment ${attachment.name}`}
              onClick={isMultiSelectMode ? () => {} : backupAction}
            >
              {
                "Sorry, your browser doesn't support embedded videos. Click here to download the video instead."
              }
            </span>
          </video>
          <div className={classes('video-preview-overlay')} />
          <img className={classes('video-play-icon')} src={iconPlay} alt="play" />
        </div>
      );
    } else if (isErrored) {
      loaderFragment = <RetryAttachment retry={retryDownload} />;
    } else {
      loaderFragment = (
        <div className={classes('loading')}>
          <span className={classes('downloading-indicator')}>
            <Spinner />
          </span>
        </div>
      );
    }

    return (
      <div className={classes({ type: 'video' })}>
        {loaderFragment}
        {videoFragment}
      </div>
    );
  }
}

export default mobxInjectSelect({
  messageAttachmentStore: ['getDownloadState'],
})(VideoAttachment);
