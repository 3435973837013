import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import BEM from 'common/bem';
import { ConversationOptionMenu, EntityAvatar } from 'common/components';
import propTypes from 'common/propTypes';
import { attachments, getRecipientEntity, mobxInjectSelect } from 'common/utils';
import { AttachmentDragModal, ConversationItemDetails } from 'widgets/messenger/components/Sidebar';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';

const classes = BEM.with('ConversationItem');

class ConversationItem extends Component {
  static propTypes = {
    addAttachments: PropTypes.func.isRequired,
    clearNewMessagesIndicator: PropTypes.func.isRequired,
    clearNewMessagesOnConvClick: PropTypes.bool.isRequired,
    conversation: propTypes.conversation.isRequired,
    isPatientCare: PropTypes.bool.isRequired,
    isPinned: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    markAsReadOnRosterClick: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    rejectAttachments: PropTypes.func.isRequired,
    selectConversation: PropTypes.func.isRequired,
    selectTeam: PropTypes.func.isRequired,
  };

  ensureP2pUser() {
    const { conversation, findUser } = this.props;
    const { counterParty, counterPartyType, organizationId } = conversation;
    const { entity, entityType } = getRecipientEntity({
      entity: counterParty,
      entityType: counterPartyType,
    });

    if (entityType === 'user' && entity.$placeholder) {
      findUser(entity.id, organizationId);
    }
  }

  componentDidMount() {
    this.ensureP2pUser();
  }

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props, nextProps);
  }

  componentDidUpdate(prevProps) {
    const { conversation } = this.props;
    const { conversation: oldConversation } = prevProps;

    if (conversation.id !== oldConversation.id) this.ensureP2pUser();
  }

  render() {
    const { conversation, isPatientCare, isPinned, isSelected, rejectAttachments } = this.props;
    const { counterParty, counterPartyType } = conversation;
    const { entity, entityType } = getRecipientEntity({
      entity: counterParty,
      entityType: counterPartyType,
    });

    if (!entity || !entityType) return null;

    const isPatientCareSelected = isPatientCare && isSelected;
    const isMainRoster = !isPatientCare;
    const isMainRosterSelected = !isPatientCare && isSelected;
    const isMute = !!conversation.isMuted;

    return (
      <div
        className={classes({
          [entityType]: true,
          isMainRoster,
          isMainRosterSelected,
          isPatientCare,
        })}
        onClick={this._onClick}
        data-test-id={this._generateDataTestId()}
      >
        <Dropzone
          className={classes('contents', {
            isPatientCare,
            isPatientCareSelected,
          })}
          multiple={true}
          accept={attachments.allContentTypes.join(',')}
          disablePreview
          disableClick
          onDragEnter={this._onDragEnter}
          onDragLeave={this._onDragLeave}
          onDropAccepted={this._onDropAccepted}
          onDropRejected={rejectAttachments}
        >
          {({ isDragActive = false, isDragReject = false } = {}) => (
            <div className={classes('contents')}>
              {!isPatientCare && (
                <div className={classes('left-pane')}>
                  <ConversationOptionMenu conversation={conversation} isSelected={isSelected} />
                  <div className={classes('avatar')}>
                    <EntityAvatar
                      entity={entity}
                      entityType={entityType}
                      showPresenceIndicator={true}
                      size="fit"
                      isMuted={isMute}
                    />
                  </div>
                </div>
              )}
              <ConversationItemDetails
                conversation={conversation}
                isPinned={isPinned}
                isSelected={isSelected}
                isPatientCare={isPatientCare}
              />
              <AttachmentDragModal {...{ isDragActive, isDragReject }} />
              <ReduxEscapeHatch
                ref={(ref) => {
                  this.reduxEscapeHatch = ref;
                }}
              />
            </div>
          )}
        </Dropzone>
      </div>
    );
  }

  _generateDataTestId = () => {
    const { conversation } = this.props;
    const { counterParty } = conversation || {};
    const { p2pRecipient, p2pSenderType } = counterParty;
    const recipient = p2pRecipient || counterParty || {};

    const senderRoleName = p2pSenderType === 'role' ? counterParty.p2pSender.displayName : '';
    if (senderRoleName) return `${recipient.displayName} - ${senderRoleName}`;

    return recipient.displayName;
  };

  _onClick = async () => {
    const {
      clearNewMessagesIndicator,
      clearNewMessagesOnConvClick,
      conversation,
      currentConversation,
      isCommandEditorOpen,
      isSelected,
      markAsReadOnRosterClick,
      openModal,
      selectConversation,
      selectTeam,
      toggleCommandEditor,
    } = this.props;
    const { counterParty } = conversation;

    if (isCommandEditorOpen && currentConversation?.id !== conversation?.id) toggleCommandEditor();

    if (counterParty && counterParty.$entityType === 'group') {
      const { groupType, hasCurrentUser } = counterParty;

      if (groupType === 'FORUM' && !hasCurrentUser) {
        openModal('joinForum', { conversation, forum: counterParty });
        return;
      }
    } else if (counterParty && counterParty.$entityType === 'team') {
      selectTeam(counterParty.id, { conversationId: conversation.id });
      return;
    }

    if (markAsReadOnRosterClick) {
      if (clearNewMessagesOnConvClick && isSelected) {
        clearNewMessagesIndicator(conversation);
      }
    }

    await selectConversation(conversation, {
      markAsRead: markAsReadOnRosterClick,
    });
    this.reduxEscapeHatch?.focusMessageBodyInput();
  };

  _onDragEnter = () => {
    if (!this.timeout) {
      this.timeout = setTimeout(() => {
        this.timeout = null;
        this._onClick();
      }, 1000);
    }
  };

  _onDragLeave = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  };

  _onDropAccepted = (files) => {
    const { addAttachments } = this.props;
    this._onClick();
    addAttachments(files);
  };
}

export default mobxInjectSelect({
  composeMessageStore: [
    'addAttachments',
    'isCommandEditorOpen',
    'rejectAttachments',
    'toggleCommandEditor',
  ],
  conversationStore: ['clearNewMessagesIndicator', 'currentConversation', 'selectConversation'],
  localStore: ['clearNewMessagesOnConvClick', 'markAsReadOnRosterClick'],
  modalStore: ['openModal'],
  teamStore: ['selectTeam'],
  userStore: ['findUser'],
})(ConversationItem);
