import { observable } from 'mobx';
import createMobxModel from './createMobxModel';

const GroupMembersChange = createMobxModel({
  name: 'groupMembersChange',

  fields: ['action', 'actionTime'],

  objectFields: {
    actor: {},
    members: {
      clone(entityList) {
        const results = [];

        if (entityList) {
          for (const { displayName, id } of entityList) {
            results.push({ displayName, id });
          }
        }

        return observable.array(results);
      },
    },
  },
});

export default GroupMembersChange;
