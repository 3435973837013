import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BEM from '../../../bem';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { defaultNewTeam } from '../../../../redux-stores/Collaboration/teams';
import { CollabEntity, Tag, Team, User } from '../../../../types';
import { mobxInjectSelect } from '../../../utils';
import EntityEditorCreateButtons from '../Entities/EntityEditorCreateButtons';
import EntityEditorDescription from '../Entities/EntityEditorDescription';
import EntityEditorName from '../Entities/EntityEditorName';
import EntityEditorTag from '../Entities/EntityEditorTag';
import { DotsIndicator } from '../..';
import TeamEditorMembers from './TeamEditorMembers';
import TeamEditorToggleOptions from './TeamEditorToggleOptions';
import TeamEditorActions from './TeamEditorActions';

const { setModal, setEntityNameError } = actions;
const { createTeam, batchEntityUpdate } = thunk;
const classes = BEM.with('CollaborationEntityEditor');

const MAX_MEMBER_SPACES = 98;

type TeamEditorProps = {
  defaultTag?: Tag;
};

type MobxProps = {
  currentTeam: Team;
  currentUserId: string;
  refreshTeam: (
    id: string,
    { organizationId }: { organizationId: string }
  ) => Promise<CollabEntity>;
  setCurrentTeam: (id: string | null) => void;
  syncOne: (entity: CollabEntity) => CollabEntity;
  teamMembers: User[];
};

function TeamEditor({
  currentTeam: teamFromStore,
  currentUserId,
  defaultTag,
  refreshTeam,
  setCurrentTeam,
  syncOne,
  teamMembers,
}: MobxProps & TeamEditorProps) {
  const dispatch = useDispatch();
  const activeTab = useSelector((state: ReduxState) => state.entities.activeTab);
  const entityNameError = useSelector((state: ReduxState) => state.entities.entityNameError);
  const checkedTagsById = useSelector((state: ReduxState) => state.tags.checkedTagsById);
  const entitiesById = useSelector((state: ReduxState) => state.entities.entitiesById);
  const isTeamAdmin = useSelector((state: ReduxState) => state.collab.isTeamAdmin);
  const newEntityType = useSelector((state: ReduxState) => state.entities.newEntityType);
  const selectedEntity = useSelector((state: ReduxState) => state.entities.selectedEntity);
  const isSavingEntity = useSelector((state: ReduxState) => state.entities.isSavingEntity);
  const savedTeamIds = useSelector((state: ReduxState) => state.teams.savedTeamIds);
  const selectedCategory = useSelector((state: ReduxState) => state.tags.selectedCategory);
  const selectedOrgId = useSelector((state: ReduxState) => state.collab.selectedOrgId);
  const selectedTag = useSelector((state: ReduxState) => state.tags.selectedTag);
  const tagsById = useSelector((state: ReduxState) => state.tags.tagsById);
  const isNewTeam = newEntityType === 'team';
  const [newTeam, setNewTeam] = useState<Team>(isNewTeam ? defaultNewTeam : teamFromStore || {});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [memberSpaces, setMemberSpaces] = useState<number>(0);

  const memberSpacesExceeded = memberSpaces > MAX_MEMBER_SPACES;
  const canCreateTeam = memberSpaces > 0 && newTeam?.displayName && !memberSpacesExceeded;
  const isActualUserInTeam = newTeam?.memberIds && newTeam?.memberIds.includes(currentUserId);
  const canLeaveTeam =
    isActualUserInTeam && newTeam?.canMembersLeave && (teamMembers?.length || 0) > 1;
  const teamId = selectedEntity?.id;

  useEffect(() => {
    setCurrentTeam(null);
  }, [isNewTeam, setCurrentTeam]);

  useEffect(() => {
    if (!isNewTeam && teamFromStore) {
      setNewTeam(teamFromStore);
    } else {
      setNewTeam({
        ...defaultNewTeam,
        ...(defaultTag ? { tagId: defaultTag.id } : undefined),
      });
    }
  }, [teamFromStore, defaultTag, isNewTeam, selectedEntity]);

  useEffect(() => {
    if (!teamId) return;
    const chooseTeam = async () => {
      setIsLoading(true);
      await refreshTeam(teamId, { organizationId: selectedOrgId });
      setIsLoading(false);
      setCurrentTeam(teamId);
    };
    selectedEntity && setNewTeam(selectedEntity);
    chooseTeam();
  }, [teamId, selectedOrgId, refreshTeam, setCurrentTeam, selectedEntity]);

  const saveTeam = async (update: Partial<Team>, persist = !isNewTeam) => {
    let teamUpdate = { ...newTeam, ...update };
    setNewTeam(teamUpdate);
    if (!persist) return;
    if (!teamUpdate.displayName?.trim()) {
      dispatch(actions.setEntityNameErrorMessage('Team name cannot be empty.'));
      dispatch(setEntityNameError(true));
      return;
    }
    teamUpdate = { ...teamUpdate, displayName: teamUpdate?.displayName?.trim() };
    if (newTeam?.id) {
      await batchEntityUpdate(dispatch, {
        activeTab,
        checkedTagsById,
        entitiesById,
        ids: [newTeam?.id],
        organizationId: selectedOrgId,
        selectedEntity,
        tagsById,
        selectedCategory,
        selectedTag,
        entityUpdateProps: teamUpdate,
        syncOne,
      });
    } else {
      await createTeam(dispatch, {
        activeTab,
        checkedTagsById,
        entitiesById,
        organizationId: selectedOrgId,
        selectedCategory,
        selectedTag,
        tagsById,
        teamInput: teamUpdate,
      });
    }
  };

  const memberEditor = (
    <TeamEditorMembers
      canCreateTeam={canCreateTeam}
      teamMembers={teamMembers}
      team={newTeam}
      isNewTeam={isNewTeam}
      isTeamAdmin={isTeamAdmin}
      maxSpaces={MAX_MEMBER_SPACES}
      memberSpaces={memberSpaces}
      memberSpacesExceeded={memberSpacesExceeded}
      persistTeam={saveTeam}
      setMemberSpaces={setMemberSpaces}
    />
  );

  return (
    <div
      className={classes('')}
      style={{ padding: newEntityType ? '8px' : '0' }}
      data-test-id="team info pane"
    >
      <div className={classes('editor-header', { isNewTeam })}>
        <EntityEditorName
          entity={newTeam}
          updateEntity={saveTeam}
          isAdmin={isTeamAdmin}
          entityType={'team'}
          savedIds={savedTeamIds}
        />
        {isNewTeam && (
          <>
            {memberEditor}
            <EntityEditorCreateButtons
              type="team"
              createEnabled={entityNameError ? false : !!canCreateTeam}
              onCreate={() => saveTeam({}, true)}
            />
          </>
        )}
        {newTeam && !isNewTeam && (
          <TeamEditorActions
            isLoading={isSavingEntity || isLoading}
            isTeamAdmin={isTeamAdmin}
            maxTeamMembers={MAX_MEMBER_SPACES}
            memberSpaces={memberSpaces}
          />
        )}
      </div>
      <div className={classes('cards-container')}>
        {!isNewTeam && (
          <>
            <EntityEditorTag
              isAdmin={isTeamAdmin}
              tagColor={newTeam?.tag?.color || ''}
              tagName={newTeam?.tag?.name || ''}
            />
            <EntityEditorDescription
              key={`description_${newTeam?.id}`}
              entity={newTeam}
              isAdmin={isTeamAdmin}
              saveEntity={saveTeam}
            />
            {isTeamAdmin && (
              <TeamEditorToggleOptions
                isTeamAdmin={isTeamAdmin}
                team={newTeam}
                saveTeam={saveTeam}
              />
            )}
            {memberEditor}
            {canLeaveTeam && (
              <div className={classes('sub-container')}>
                <div
                  className={classes('leave-button')}
                  onClick={() => newTeam && dispatch(setModal({ name: 'leaveTeam' }))}
                >
                  Leave Team
                </div>
              </div>
            )}
            {isTeamAdmin && (
              <button
                className={classes('delete-button')}
                data-test-id="open delete team modal"
                onClick={() => dispatch(setModal({ name: 'deleteEntity' }))}
              >
                Delete Team
              </button>
            )}
          </>
        )}
        {(isSavingEntity || isLoading) && (
          <>
            <div className={classes('loading-overlay-container')} />
            <div className={classes('loading-container')}>
              <DotsIndicator color="#828282" size={7} marginRight={6} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default mobxInjectSelect<TeamEditorProps, MobxProps>({
  entityStore: ['syncOne'],
  sessionStore: ['currentUserId'],
  teamStore: ['currentTeam', 'refreshTeam', 'setCurrentTeam', 'teamMembers'],
})(TeamEditor);
