import React from 'react';

import { Switch } from '@tigerconnect/web-component-library';
import { User, VWR, VWRStaff } from '../../../../types';
import BEM from '../../../bem';
import { mobxInjectSelect } from '../../../utils';

import RecipientSearchPicker from '../../RecipientSearchPicker';

import { ReactComponent as DeleteSvg } from '../../../images/delete-cross.svg';

const classes = BEM.with('AccessPicker');

type AccessPickerProps = {
  draft: VWR;
  setDraft: React.Dispatch<React.SetStateAction<VWR | undefined>>;
};

type MobxProps = {
  clearSearchResults: () => void;
  currentOrganization: Record<string, unknown>;
};

const AccessPicker: React.FC<AccessPickerProps & MobxProps> = ({
  draft,
  setDraft,
  currentOrganization,
  clearSearchResults,
}) => {
  const onChange = (selected: User[]) => {
    clearSearchResults();

    const _staff = selected.map((s) => ({
      name: s.displayName,
      accountToken: s.id,
      notificationStatus: 'ON',
    })) as VWRStaff[];

    const staff = [...draft.staff, ..._staff];
    const _draft = { ...draft, staff };
    setDraft(_draft);
  };

  const handleToggleStatus = (s: VWRStaff) => {
    const index = draft.staff.findIndex((v) => v.accountToken === s.accountToken);
    if (index > -1) {
      const prevStaffItem = draft.staff[index];
      const notificationStatus =
        prevStaffItem.notificationStatus === 'OFF'
          ? 'ON'
          : ('OFF' as VWRStaff['notificationStatus']);
      const updatedStaffItem = { ...prevStaffItem, notificationStatus };
      const staff = [
        ...draft.staff.slice(0, index),
        updatedStaffItem,
        ...draft.staff.slice(index + 1),
      ];
      const _draft = { ...draft, staff };
      setDraft(_draft);
    }
  };

  const handleRemoveStaff = (s: VWRStaff) => {
    clearSearchResults();

    const index = draft.staff.findIndex((v) => v.accountToken === s.accountToken);
    if (index > -1) {
      const staff = [...draft.staff.slice(0, index), ...draft.staff.slice(index + 1)];
      const _draft = { ...draft, staff };
      setDraft(_draft);
    }
  };

  return (
    <div className={classes()}>
      <RecipientSearchPicker
        ariaLabelItem={'Virtual Waiting Room Select Provider'}
        ariaLabelSelect={'Virtual Waiting Room Add Provider'}
        className={'VWR-Provider'}
        enabledCapabilities={['pf_virtual_waiting_room']}
        excludeIds={draft?.staff?.map((s) => s.accountToken)}
        excludeRoles
        excludeSelf={false}
        hotkeyOptions={{ component: 'div', isFlex: false }}
        multi
        onChange={onChange}
        organization={currentOrganization}
        placeholder={'Add virtual waiting room staff here'}
        searchTypes={['user']}
        shouldShowSelectedValues={false}
        autoFocus={false}
      />
      {draft.staff.length > 0 && (
        <ul className={classes('staff-list')}>
          {draft.staff.map((s) => (
            <li className={classes('staff')} key={s.accountToken}>
              <div className={classes('name')}>
                <button
                  className={classes('remove-icon')}
                  onClick={() => handleRemoveStaff(s)}
                  aria-label="Remove staff"
                >
                  <DeleteSvg aria-hidden />
                </button>
                <div className={classes('name-text')}>{s.name || s.accountToken}</div>
              </div>
              <div className={classes('switch-container')}>
                <div className={classes('notification')}>Notification</div>
                <div>
                  <Switch
                    checked={s.notificationStatus === 'ON'}
                    theme={'patient'}
                    onSwitch={() => handleToggleStatus(s)}
                    dataTestId="notificationStatus"
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default mobxInjectSelect<AccessPickerProps, MobxProps>({
  recipientPickerStore: ['clearSearchResults'],
  messengerStore: ['currentOrganization'],
})(AccessPicker);
