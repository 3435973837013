import { useEffect } from 'react';

type RefNode = React.RefObject<Node>;

const useClickOutside = (refs: RefNode[], callback: (e: Event) => void): void => {
  useEffect(() => {
    const listener = (e: Event) => {
      const isOutside = (ref: RefNode) =>
        e.target instanceof Node &&
        ref.current instanceof Node &&
        !ref.current.isEqualNode(e.target) &&
        !ref.current.contains(e.target);

      const isOutsideAll = refs.filter((ref) => !isOutside(ref)).length === 0;

      if (isOutsideAll) {
        callback(e);
      }
    };

    document.addEventListener('click', listener, true);
    return () => document.removeEventListener('click', listener, true);
  }, [callback, refs]);
};

export default useClickOutside;
