import { createAsyncThunk } from '@reduxjs/toolkit';
import { handlePatientReferenceSSE } from 'redux-stores/patientContext/thunk';
import TCClient from 'client';
import { UpdateSSE } from 'types/SSE';

export const setupGlobalListeners = createAsyncThunk(
  'session/setupGlobalListeners',
  async (_vars: unknown, thunkAPI) => {
    thunkAPI.dispatch(listenToSSE(null));
  }
);

const listenToSSE = createAsyncThunk('session/listenToSSE', async (_vars: unknown, thunkAPI) => {
  const api = thunkAPI.extra as typeof TCClient;
  api.events.on('event', ({ type, data }: UpdateSSE) => {
    if (type === 'update') {
      if (data.patient_context_id && data.patient_context_updated) {
        thunkAPI.dispatch(handlePatientReferenceSSE(data));
      }
    }
  });
});
