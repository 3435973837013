import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { mobxInjectSelect } from '../../utils';
import propTypes from '../../propTypes';
import BEM from '../../bem';
import Spinner from '../Spinner';

import AttachmentFileHeader from './AttachmentFileHeader';
import RetryAttachment from './RetryAttachment';

const classes = BEM.with('AudioAttachment');

class AudioAttachment extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    attachment: propTypes.messageAttachment.isRequired,
    attachmentUrl: PropTypes.string,
    getDownloadState: PropTypes.func.isRequired,
    isMultiSelectMode: PropTypes.bool.isRequired,
    messageId: PropTypes.string.isRequired,
    multiSelectable: PropTypes.bool.isRequired,
    retryDownload: PropTypes.func.isRequired,
  };

  render() {
    const {
      action,
      attachment,
      attachmentUrl,
      getDownloadState,
      isMultiSelectMode,
      messageId,
      multiSelectable,
      retryDownload,
    } = this.props;
    const { localPath } = attachment;
    const attachmentDownloadState = getDownloadState(messageId, attachment.id);
    const isLoading = attachmentDownloadState.isLoading;
    const url = isLoading ? null : attachmentUrl || localPath || attachmentDownloadState.url;
    const isErrored = !url && !isLoading;
    let loaderFragment, audioFragment;

    if (url) {
      audioFragment = (
        <audio
          controls
          className={classes('audio')}
          data-test-id={attachment.name}
          src={url}
          preload="metadata"
        >
          <span
            aria-label={`audio-download-${attachment.name}`}
            onClick={isMultiSelectMode ? () => {} : action}
          >
            Your browser does not support the <code>audio</code> element. Click here to download the
            audio instead.
          </span>
        </audio>
      );
    } else if (isErrored) {
      loaderFragment = <RetryAttachment retry={retryDownload} isSmall />;
    } else {
      loaderFragment = (
        <div className={classes('loading')}>
          <span className={classes('downloading-indicator')}>
            <Spinner />
          </span>
        </div>
      );
    }

    return (
      <div className={classes({ type: 'audio' })}>
        <div className={classes('attachment-container')}>
          <div
            className={classes('attachment-metadata', {
              unselectable: isMultiSelectMode && !multiSelectable,
            })}
            onClick={isMultiSelectMode ? () => {} : action}
            data-test-id={attachment.name}
          >
            <AttachmentFileHeader attachment={attachment} messageId={messageId} />
          </div>
          {loaderFragment}
          {audioFragment}
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  messageAttachmentStore: ['getDownloadState'],
})(AudioAttachment);
