import React, { Component } from 'react';
import PropTypes from 'prop-types';
import propTypes from '../propTypes';
import { mobxInjectSelect } from '../utils';
import BEM from '../bem';
import { UndoSavedRole } from './Roles';
import { UndoDnd, UndoSavedTeam } from './';

const classes = BEM.with('UndoComponent');

class UndoComponent extends Component {
  static propTypes = {
    showDndUndoButton: PropTypes.bool.isRequired,
    showSavedRoleUndoButton: PropTypes.bool.isRequired,
    undoTeam: propTypes.team,
  };

  render() {
    const { showDndUndoButton, showSavedRoleUndoButton, undoTeam } = this.props;

    return (
      <div className={classes()}>
        <div className={classes('undo-container', { enable: showDndUndoButton })}>
          <UndoDnd />
        </div>
        <div
          className={classes('undo-container', {
            enable: showSavedRoleUndoButton,
          })}
        >
          <UndoSavedRole />
        </div>
        <div className={classes('undo-container', { enable: !!undoTeam })}>
          <UndoSavedTeam />
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  userStore: ['showDndUndoButton'],
  roleStore: ['showSavedRoleUndoButton'],
  teamStore: ['undoTeam'],
})(UndoComponent);
