import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../../utils';
import { ReactComponent as PriorityIconSvg } from '../../images/priority-icon.svg';
import BEM from '../../bem';

const classes = BEM.with('PriorityMessageButton');

class PriorityMessageButton extends Component {
  static propTypes = {
    alwaysEscalate: PropTypes.bool,
    isPriority: PropTypes.bool.isRequired,
    priority: PropTypes.string.isRequired,
    setPriority: PropTypes.func.isRequired,
    showPriorityModalIfShould: PropTypes.func.isRequired,
  };

  render() {
    const { alwaysEscalate, isPriority } = this.props;

    return (
      <div className={classes()}>
        <PriorityIconSvg
          aria-label="Message Priority Button"
          className={classes('image', { alwaysEscalate, isPriority })}
          onClick={this._onPriorityMessage}
        />
      </div>
    );
  }

  _onPriorityMessage = () => {
    const { alwaysEscalate, setPriority, showPriorityModalIfShould } = this.props;
    if (alwaysEscalate) return;
    const priority = this.props.priority === 'NORMAL' ? 'HIGH' : 'NORMAL';

    setPriority(priority);

    if (priority === 'HIGH') {
      showPriorityModalIfShould();
    }
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['priority', 'setPriority', 'showPriorityModalIfShould'],
})(PriorityMessageButton);
