import Enum from '../../utils/enum';

const MessageSenderStatus = new Enum([
  'SENDING',
  'SENT',
  'FAILED',
  'RETRYING',
  'NEW',
  'NA',
] as const);

export default MessageSenderStatus;
