import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import styles from './PersonaForm.module.css';
import { Input } from 'admin/shared-components/Input/Input';
import { CtaButton } from 'admin/shared-components/CtaButton/CtaButton';
import { PermissionMap, PersonaRowData, Persona, PersonaToast } from 'admin/types';
import { DotsIndicator } from 'common/components';
import { Checkbox } from 'admin/shared-components/Checkbox/Checkbox';
import { formatPermissionLabel } from 'admin/utils/parsePermissionsLabels';
import { mobxInjectSelect } from 'common/utils';
import { PersonaCreateFields, PermissionKey, PersonaUpdateFields } from 'js-sdk/src/types/Persona';
import { useAppSelector } from 'redux-stores';
import { MultiSelect } from 'admin/shared-components/MultiSelect/MultiSelect';

type MobxProps = {
  loadPermissions: (selectedOrganizationId: string) => Promise<PermissionKey[]>;
  loadTitles: (selectedOrganizationId: string) => Promise<string[]>;
  createPersona: (
    payload: PersonaCreateFields,
    selectedOrganizationId: string
  ) => Promise<Persona[]>;
  updatePersona: (
    payload: PersonaUpdateFields,
    personaId: string,
    selectedOrganizationId: string
  ) => Promise<Persona>;
};

type PersonaFormProps = {
  setShowPersonaForm: (shouldShow: boolean) => void;
  selectedPersona?: PersonaRowData;
  setSelectedPersona: (persona: PersonaRowData | undefined) => void;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  setToastMessageData: React.Dispatch<React.SetStateAction<PersonaToast | undefined>>;
};

type PersonaOption = {
  label: string;
  type: 'input' | 'dropdown';
  optionName: keyof Persona;
  listOptions?: { label: string; value: string }[];
  defaultValue?: string;
};

function PersonaForm({
  setShowPersonaForm,
  createPersona,
  selectedPersona,
  setSelectedPersona,
  loadPermissions,
  loadTitles,
  editMode,
  setEditMode,
  updatePersona,
  setToastMessageData,
}: PersonaFormProps & MobxProps) {
  const [personaName, setPersonaName] = useState(selectedPersona?.name);
  const [selectedTitles, setSelectedTitles] = useState(selectedPersona?.titles || []);
  const [description, setDescription] = useState(selectedPersona?.description || '');
  const [permissions, setPermissions] = useState<PermissionMap>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [titles, setTitles] = useState<string[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);

  const loadRequirements = useCallback(async () => {
    setLoading(true);
    try {
      const foundTitles = await loadTitles(selectedOrganizationId);
      const permissions = await loadPermissions(selectedOrganizationId);
      const personaPermissions = selectedPersona?.permissions;
      setPermissions(
        permissions.reduce(
          (pMap, permission) => ({
            ...pMap,
            [permission]: personaPermissions?.[permission] || false,
          }),
          {}
        )
      );
      setTitles(foundTitles);
      setSelectedTitles(selectedPersona?.titles || []);
    } finally {
      setLoading(false);
    }
  }, [loadPermissions, loadTitles, selectedOrganizationId, selectedPersona]);

  useEffect(() => {
    loadRequirements();
  }, [loadRequirements]);

  const personaInputs: PersonaOption[] = [
    { label: 'Name: ', type: 'input', optionName: 'name' },
    { label: 'Description: ', type: 'input', optionName: 'description' },
    {
      label: 'Title: ',
      optionName: 'titles',
      type: 'dropdown',
      listOptions: titles.map((title) => ({ label: title, value: title })),
    },
  ];

  const onEditSaveBtn = async () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      validateForm();
    }
  };
  const validateForm = async () => {
    const formErrors: Record<string, string> = {};
    if (!selectedTitles.length) formErrors.titles = 'Title is required';
    if (!personaName || !personaName.trim()) formErrors.name = 'Name is required';

    if (isEmpty(formErrors)) {
      await savePersona();
    } else {
      setErrors(formErrors);
    }
  };

  const savePersona = async () => {
    try {
      if (selectedPersona) {
        await updatePersona(
          {
            name: personaName || '',
            description: description || '',
            titles: selectedTitles,
            permissions,
          },
          selectedPersona.personaId,
          selectedOrganizationId
        );
        setSelectedPersona(undefined);
        setToastMessageData({ type: 'SUCCESS', message: 'Permission Group updated' });
      } else {
        await createPersona(
          {
            name: personaName || '',
            description: description || '',
            titles: selectedTitles,
            permissions,
          },
          selectedOrganizationId
        );
        setToastMessageData({ type: 'SUCCESS', message: 'Permission Group created' });
      }
      setShowPersonaForm(false);
      setErrors({});
    } catch (error) {
      let errorMessage = 'Response error please try again.';
      if (error?.response?.status === 409) {
        errorMessage = error?.response?.text;
      }
      setErrors((prev) => ({ ...prev, general: errorMessage }));
      setToastMessageData({ type: 'FAILURE', message: errorMessage });
    }
  };

  if (loading) {
    return (
      <div className={classNames(styles.loadingHolder)}>
        <DotsIndicator color="#0e0d0d" size={12} marginTop={30} marginRight={5} />
      </div>
    );
  }
  return (
    <div className={classNames(styles.personaForm)}>
      <div className={classNames(styles.personaHeader)}>
        {selectedPersona
          ? `${editMode ? 'Edit ' : ''}${selectedPersona.name}`
          : 'Create New Permission Group'}
      </div>
      {personaInputs.map(({ label, type, listOptions, optionName }) => (
        <div key={optionName} className={classNames(styles.personaRoot)}>
          <div className={classNames(styles.personaLabel)}>{label}</div>
          <div className={classNames(styles.personaChild)}>
            {optionName === 'name' && (
              <Input
                disabled={!editMode}
                value={personaName}
                onInputChange={(e) => setPersonaName(e.target.value)}
                dataTestId="newPersonaId"
              />
            )}
            {optionName === 'description' && (
              <Input
                disabled={!editMode}
                value={description}
                onInputChange={(e) => setDescription(e.target.value)}
                dataTestId="persona description"
              />
            )}
            {optionName === 'titles' && listOptions && (
              <MultiSelect
                dataTestId={'titleDropdown'}
                disabled={!editMode}
                onChange={setSelectedTitles}
                options={listOptions}
                placeholder={'Select titles...'}
                value={selectedTitles}
              />
            )}
          </div>
          <div className={classNames(styles.errorLabel)}>{errors[optionName]}</div>
        </div>
      ))}
      <hr className={classNames(styles.personaHr)} />
      <ul>
        {Object.keys(permissions).map((permission) => (
          <li key={permission}>
            <Checkbox
              disabled={!editMode}
              checked={permissions[permission as keyof PermissionMap]}
              onClick={(checked: boolean) =>
                setPermissions((prev) => ({ ...prev, [permission]: checked }))
              }
              multiple
              isLabelClickable={editMode}
              label={formatPermissionLabel(permission)}
            />
          </li>
        ))}
      </ul>
      <div className={classNames(styles.personaButtons)}>
        <CtaButton primary label={`${!editMode ? 'EDIT' : 'SAVE'}`} onClick={onEditSaveBtn} />
        <CtaButton primary={false} label="CANCEL" onClick={() => setShowPersonaForm(false)} />
      </div>
    </div>
  );
}

export default mobxInjectSelect<PersonaFormProps, MobxProps>({
  personaStore: ['createPersona', 'loadPermissions', 'loadTitles', 'updatePersona'],
})(PersonaForm);
