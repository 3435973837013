import React, { useState } from 'react';
import type { Colors } from '@tigerconnect/web-component-library';
import BEM from '../bem';
import { Button, Modal } from './WebComponents';
import { Modal as Modal2 } from './';

const classes = BEM.with('BasicModal');

type Theme = 'normal' | 'danger' | 'patient';

type BasicModalProps = {
  ariaLabelBody?: string;
  ariaLabelCancelButton: string;
  ariaLabelCloseButton: string;
  ariaLabelDangerButton?: string;
  ariaLabelHeader: string;
  ariaLabelSubmitButton?: string;
  bodyText?: string;
  buttonWidth?: number;
  cancelText?: string;
  children?: React.ReactNode;
  dangerText?: string;
  hasCloseButton?: boolean;
  headerText: string;
  headerTextStyle?: string;
  hideCancel?: boolean;
  isOpen: boolean;
  modalType?: string;
  onClose: () => Promise<void> | void;
  onDangerSubmit?: () => Promise<unknown> | void;
  onSubmit?: () => Promise<unknown> | void;
  submitDisabled?: boolean;
  submitText?: string;
  size?: 'large' | 'medium-large' | 'medium' | 'medium-small' | 'small' | 'image' | 'variable';
  theme?: Theme;
  useWCL?: boolean;
  dangerButtonTheme?: Colors;
  type?: 'empty' | 'dialog';
};

export default function BasicModal({
  ariaLabelBody = '',
  ariaLabelCancelButton,
  ariaLabelCloseButton,
  ariaLabelDangerButton,
  ariaLabelHeader,
  ariaLabelSubmitButton,
  bodyText,
  cancelText = 'CANCEL',
  children,
  dangerText = 'DELETE',
  hasCloseButton = false,
  hideCancel = false,
  headerText,
  headerTextStyle,
  isOpen,
  modalType = 'default',
  onClose,
  onDangerSubmit,
  onSubmit,
  submitDisabled = false,
  submitText,
  size = 'medium-small',
  theme = 'normal',
  dangerButtonTheme = 'danger',
  useWCL = false,
  type = 'dialog',
}: BasicModalProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  async function onCloseWrapper() {
    setIsSubmitting(false);
    await onClose();
  }

  async function onDeleteWrapper() {
    try {
      setIsSubmitting(true);
      await onSubmit?.();
    } finally {
      setIsSubmitting(false);
    }
  }

  return useWCL && size !== 'variable' && size !== 'image' ? (
    <Modal
      isOpen={isOpen}
      bodyDataTestId={ariaLabelBody}
      headerDataTestId={ariaLabelHeader}
      headerText={headerText}
      theme={theme === 'normal' ? 'secondary' : theme}
      submitText={submitText}
      submitDataTestId={ariaLabelSubmitButton}
      onClose={onCloseWrapper}
      size={size}
      hasCloseButton={hasCloseButton}
      type={type}
      closeButtonDataTestId={ariaLabelCloseButton}
      footerPrimaryActions={
        <>
          {!hideCancel && (
            <Button
              aria-label={ariaLabelCancelButton}
              data-test-id={ariaLabelCancelButton}
              onClick={onCloseWrapper}
              color="neutral"
              label={cancelText}
              outline
              alignment="right"
            />
          )}
          {submitText && ariaLabelSubmitButton && (
            <Button
              color={theme === 'normal' ? 'secondary' : theme}
              aria-label={ariaLabelSubmitButton}
              data-test-id={ariaLabelSubmitButton}
              disabled={isSubmitting || submitDisabled}
              onClick={onDeleteWrapper}
              label={submitText}
              outline={theme !== 'patient'}
            />
          )}
        </>
      }
      footerSecondaryActions={
        <>
          {onDangerSubmit && (
            <Button
              aria-label={ariaLabelDangerButton}
              data-test-id={ariaLabelDangerButton}
              onClick={onDangerSubmit}
              label={dangerText}
              color={dangerButtonTheme}
              outline
            />
          )}
        </>
      }
    >
      <div aria-label={ariaLabelBody} data-test-id={ariaLabelBody} className={classes('info')}>
        {children || bodyText}
      </div>
    </Modal>
  ) : (
    <Modal2
      ariaLabelCloseButton={ariaLabelCloseButton}
      ariaLabelHeader={ariaLabelHeader}
      bodyClass={classes(modalType === 'delete' ? 'body delete' : 'body')}
      closeClass={classes(`close-button-${theme === 'patient' ? 'normal' : theme}`)}
      header={headerText}
      headerClass={classes(`header-${theme === 'patient' ? 'normal' : theme}`)}
      headerTextStyle={classes(`${headerTextStyle}`)}
      hasCloseButton={hasCloseButton}
      isOpen={isOpen}
      size={size}
      footerPrimaryActions={
        <>
          {submitText && ariaLabelSubmitButton && (
            <Button
              color={theme === 'normal' ? 'secondary' : theme}
              aria-label={ariaLabelSubmitButton}
              data-test-id={ariaLabelSubmitButton}
              disabled={isSubmitting || submitDisabled}
              onClick={onDeleteWrapper}
              label={submitText}
              outline={theme !== 'patient'}
            />
          )}
        </>
      }
      footerSecondaryActions={
        <>
          {onDangerSubmit && (
            <Button
              aria-label={ariaLabelDangerButton}
              data-test-id={ariaLabelDangerButton}
              onClick={onDangerSubmit}
              label={dangerText}
              color="danger"
              outline
            />
          )}
          {!hideCancel && (
            <Button
              aria-label={ariaLabelCancelButton}
              data-test-id={ariaLabelCancelButton}
              onClick={onCloseWrapper}
              color="neutral"
              label={cancelText}
              outline
              alignment="right"
            />
          )}
        </>
      }
      className={classes()}
      onRequestClose={onCloseWrapper}
      shouldSplitSecondaryFooterActions={!!onDangerSubmit}
    >
      <div aria-label={ariaLabelBody} data-test-id={ariaLabelBody} className={classes('info')}>
        {children || bodyText}
      </div>
    </Modal2>
  );
}
