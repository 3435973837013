import Enum from '../../utils/enum';

const AllowedSendersReason = new Enum([
  'CONTACTS_MESSAGING_BLOCKED_BY_ADMIN',
  'CONVERSATION_NOT_FULLY_LOADED',
  'CONVERSATION_WITH_ROLE_YOU_ARE_CURRENTLY_IN',
  'COUNTER_PARTY_IS_TIGERPAGE',
  'COUNTER_PARTY_NOT_FULLY_LOADED',
  'COUNTER_PARTY_TYPE_NOT_SUPPORTED',
  'GROUP_HAS_NO_MEMBERS',
  'GROUP_MEMBERS_NOT_LOADED',
  'IS_ROLE_ASSIGNMENT_GROUP',
  'NO_COUNTER_PARTY',
  'ORGANIZATION_DOES_NOT_EXIST',
  'USER_IS_NOT_IN_THIS_ROLE_CONVERSATION',
  'USER_IS_NOT_MEMBER_OF_GROUP',
  'NONE',
] as const);

export default AllowedSendersReason;
