import React from 'react';
import { observer } from 'mobx-react';
import { userPresenceReadableLabel } from '../../utils/readableLabels';
import BEM from '../../bem';

const classes = BEM.with('PresenceIndicatorAvailable');

type PresenceIndicatorAvailableProps = {
  size: string;
};

const PresenceIndicatorAvailable = ({ size = 'MEDIUM' }: PresenceIndicatorAvailableProps) => {
  return (
    <div title={userPresenceReadableLabel('AVAILABLE')}>
      <svg
        className={classes({ size })}
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="8" height="8" rx="4" fill="#0E9E00" />
      </svg>
    </div>
  );
};

export default observer(PresenceIndicatorAvailable);
