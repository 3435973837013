const CollaborationTabs = {
  ALL: 'All',
  ROLES: 'Roles',
  TEAMS: 'Teams',
  ACTIVETEAMS: "Teams I'm in",
  ACTIVEROLES: 'On Duty',
  SAVED: 'Saved',
} as const;

export type CollaborationTab =
  | typeof CollaborationTabs.ALL
  | typeof CollaborationTabs.ROLES
  | typeof CollaborationTabs.TEAMS
  | typeof CollaborationTabs.ACTIVETEAMS
  | typeof CollaborationTabs.ACTIVEROLES
  | typeof CollaborationTabs.SAVED;

export default CollaborationTabs;
