import React, { useRef } from 'react';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import BEM from '../../bem';
import { ReactComponent as DropdownChevronSvg } from '../../images/dropdown-chevron.svg';
import { ReactComponent as CheckSvg } from '../../images/selected-check.svg';
import { ContextMenu, MenuItem, MenuItemList } from './';

const classes = BEM.with('BasicContextMenu');

type BasicContextMenuProps = {
  className?: string;
  hideArrow?: boolean;
  onChange: (val: string) => void;
  options: string[];
  placeholder?: string;
  optionRender?: (option: string) => JSX.Element;
  selected: string;
  accessibilityMode?: boolean;
  ariaDisabled?: boolean;
  dataTestId?: string;
};

export default function BasicContextMenu({
  className,
  hideArrow = false,
  onChange,
  options,
  placeholder,
  optionRender,
  selected,
  accessibilityMode,
  ariaDisabled,
  dataTestId,
}: BasicContextMenuProps) {
  const buttonRef = useRef(null);
  const selectedIdx = options.findIndex((option) => option === selected) ?? 0;

  const displayOption = (option: string) => {
    return optionRender ? optionRender(option) : <p>{capitalize(option)}</p>;
  };

  return (
    <ContextMenu
      event="click"
      offsetY={6}
      position="bottominnerleft"
      relativeTo={buttonRef.current}
      theme="vertical"
      accessibilityMode={accessibilityMode}
      ariaDisabled={ariaDisabled}
      menu={
        <MenuItemList
          className={classes()}
          accessibilityMode={accessibilityMode}
          selectedOptionIndex={selectedIdx}
        >
          {options.map((option) => {
            const isSelected = option === selected;

            return (
              <MenuItem
                className={classes('menu-item')}
                onClick={() => {
                  onChange(option);
                  if (accessibilityMode && buttonRef?.current) {
                    (buttonRef?.current as HTMLElement).focus();
                  }
                }}
                key={option}
              >
                {displayOption(option)}
                {isSelected && <CheckSvg className={classes('check')} />}
              </MenuItem>
            );
          })}
        </MenuItemList>
      }
    >
      <button
        className={classNames(classes('basic-btn'), className)}
        data-test-id={dataTestId || 'basic-menu-button'}
        ref={buttonRef}
        aria-disabled={ariaDisabled}
      >
        {selected ? displayOption(selected) : placeholder || ''}
        {!hideArrow && <DropdownChevronSvg />}
      </button>
    </ContextMenu>
  );
}
