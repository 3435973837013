import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BEM from '../../../../common/bem';
import { EntityAvatar } from '../../../../common/components';
import { ReactComponent as CloseButtonSvg } from '../../../../common/images/close-icon.svg';
import { ReactComponent as AvatarImageSvg } from '../../../../common/images/default-avatar--singleProvider.svg';
import getRecipientEntity from '../../../../common/utils/getRecipientEntity';

const AVATAR_PX = 52;
const classes = BEM.with('DummyPatientConversationItem');
const TILE_SPACE_PX = 20;
const TILE_HEIGHT = TILE_SPACE_PX + AVATAR_PX;

class DummyPatientConversationItem extends Component {
  static propTypes = {
    placeholderName: PropTypes.string,
    showBlankAvatar: PropTypes.bool,
    stopComposing: PropTypes.func,
  };

  static defaultProps = {
    placeholderName: 'New Message',
    showBlankAvatar: false,
    showRoleIndicator: true,
  };

  static getHeight() {
    return TILE_HEIGHT;
  }

  render() {
    const { entity: inEntity, placeholderName, showBlankAvatar, stopComposing } = this.props;
    const entity = inEntity ? getRecipientEntity({ entity: inEntity }).entity : null;

    const image = entity ? (
      <EntityAvatar entity={entity} size="fit" />
    ) : showBlankAvatar ? (
      <span className={classes('blank-avatar')} />
    ) : (
      <AvatarImageSvg className={classes('avatar-image')} />
    );

    const _handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        stopComposing();
      }
    };

    return (
      <div className={classes()}>
        <div className={classes('left-pane')}>
          <div
            className={classes('avatar-image-container', {
              entity: entity !== null,
            })}
          >
            {image}
          </div>
        </div>
        <div className={classes('details')}>
          <div className={classes('row')}>
            <div className={classes('text')}>{entity ? entity.displayName : placeholderName}</div>
          </div>
          <div className={classes('row')}>
            <div />
            {stopComposing && (
              <button
                className={classes('close-button-container')}
                onClick={stopComposing}
                onKeyDown={_handleKeyDown}
              >
                <CloseButtonSvg className={classes('close-button')} aria-hidden />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default observer(DummyPatientConversationItem);
