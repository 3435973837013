import React, { useState } from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';

import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';
import { Input } from '../../../../shared-components/Input/Input';
import { Table } from '../../../../shared-components/Table/Table';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import Modal from '../../../../shared-components/Modal/Modal';

import { PendingUserTableRow, PendingUserTableRows } from '../../../../types';

import { addPendingUser, getPendingUsers } from './thunks';

import styles from './PendingUsers.module.css';

type ModalProp = {
  onSuccess: () => void;
  body: string;
  doneButton: string;
  header: string;
};

export default function View({
  onClose,
  modalProps,
  pendingUsers,
  setPendingUsers,
  toggleIsModalOpen,
  isModalOpen,
  setUserToDecline,
  selectedOrganizationId,
}: {
  onClose: () => void;
  modalProps: { addAll: ModalProp; decline: ModalProp; declineAll: ModalProp };
  pendingUsers: PendingUserTableRows;
  setPendingUsers: (pendingUsers: PendingUserTableRows) => void;
  toggleIsModalOpen: () => void;
  isModalOpen: boolean;
  setUserToDecline: (pendingUser: PendingUserTableRow) => void;
  selectedOrganizationId: string;
}) {
  const [selectedModal, setSelectedModal] = useState<'addAll' | 'decline' | 'declineAll' | null>(
    null
  );
  const [searchValue, setSearchValue] = useState('');
  const pendingUsersCount = pendingUsers.length;
  const areTherePendingUsers = !!pendingUsersCount;
  const pendingUsersCountText = `${pendingUsersCount} user${pendingUsersCount > 1 ? 's' : ''}`;

  const debouncedSearch = debounce(async (query = '') => {
    await getPendingUsers({
      setPendingUsers,
      selectedOrganizationId,
      query,
    });
  }, 200);

  return (
    <ViewContainer
      {...{
        header: 'Pending User Requests',
        subHeader: 'These users are awaiting authorization into your organization.',
        onClose,
      }}
    >
      <div className={styles.pendingUsersContainer}>
        <div
          className={classNames(
            styles.pendingUsersActions,
            !areTherePendingUsers && styles.noPendingUsersActions
          )}
        >
          {areTherePendingUsers && (
            <div className={styles.pendingUsersButtons}>
              <CtaButton
                label="Add All"
                primary={true}
                onClick={() => {
                  setSelectedModal('addAll');
                  toggleIsModalOpen();
                }}
                dataTestId={'addAllPendingUsers'}
              />
              <span className={styles.pendingUsersButtonsSeparator}></span>
              <CtaButton
                label="Decline All"
                primary
                onClick={() => {
                  setSelectedModal('declineAll');
                  toggleIsModalOpen();
                }}
                dataTestId={'declineAllPendingUsers'}
              />
            </div>
          )}
          <div>
            <Input
              customCSS={styles.formInput}
              onInputChange={(e) => {
                const searchTerm = e.target.value;
                setSearchValue(searchTerm);

                debouncedSearch(searchTerm);
              }}
              value={searchValue}
              hasClearButton
              onClearClick={() => {
                setSearchValue('');
                debouncedSearch();
              }}
              dataTestId={'filterPendingUsers'}
            />
          </div>
        </div>
        <div className={styles.pendingUsersUserCount}>
          {areTherePendingUsers ? pendingUsersCountText : 'No users found'}
        </div>
        {areTherePendingUsers && (
          <div className={styles.pendingUsersTable}>
            <Table
              actionsOrder={[
                { name: 'Add', type: 'add' },
                { name: 'Delete', type: 'delete' },
              ]}
              columns={[
                { field: 'avatar', headerName: '' },
                { field: 'firstName', headerName: 'First Name' },
                { field: 'lastName', headerName: 'Last Name' },
                { field: 'emailAddress', headerName: 'Email' },
              ]}
              rows={pendingUsers}
              onRowAdd={(pendingUserToAdd) => {
                addPendingUser({ pendingUserToAdd, setPendingUsers, pendingUsers });
              }}
              onRowDelete={(userToDecline) => {
                setUserToDecline(userToDecline);
                setSelectedModal('decline');
                toggleIsModalOpen();
              }}
            />
          </div>
        )}
        {selectedModal !== null && (
          <Modal
            {...modalProps[selectedModal]}
            closeButton={'Cancel'}
            canClose
            isWarningButtonPresent={true}
            modalSize={'medium'}
            isOpen={isModalOpen}
            onClose={() => {
              toggleIsModalOpen();
              setSelectedModal(null);
            }}
          />
        )}
      </div>
    </ViewContainer>
  );
}
