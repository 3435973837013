import { useEffect, useState } from 'react';
import KeywordInputSearch from './KeywordInputSearch';
import KeywordSearchResultsDetails from './KeywordSearchResultsDetails';
import KeywordMessageBubble from './KeywordMessageBubble';
import { getStoreModel } from './helper';
import BEM from 'common/bem';
import mobxInjectSelect from 'common/utils/mobxInjectSelect';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';
import { useSearchQuery } from 'redux-stores/KeywordSearch/keywordSearchApi';
import { useRTKQueryInfiniteResults } from 'common/hooks/useRTKQueryInfiniteResults';
import { ReactComponent as ArrowBackIcon } from 'common/images/arrow_back.svg';
import { Conversation, User } from 'types';
import { DotsIndicator } from 'common/components';
import {
  KeywordSearchGeneralResult,
  KeywordSearchSecondLevelResult,
} from 'js-sdk/src/apis/SearchAPI';

const classes = BEM.with('KeywordSearchSideBar');

const { setKeywordSearchMode, setPreviousConversationId } = actions;

interface ConversationMetadata {
  group_id: string;
  peer_token: string;
  is_dl_list: boolean;
}

type MobxProps = {
  currentUserId: string;
  setCurrentConversationId: (id: string) => void;
  myRoleIds: string[];
  getEntityData: (entityType: string, id: string) => Conversation & User;
};

const KeywordSearchSideBar = ({
  currentUserId,
  setCurrentConversationId,
  myRoleIds,
  getEntityData,
}: MobxProps) => {
  const dispatch = useAppDispatch();
  const { currentOrganizationId, isKeywordSearchModeOn, previousConversationId } = useAppSelector(
    (state) => ({
      isKeywordSearchModeOn: state.keywordSearch.isKeywordSearchModeOn,
      previousConversationId: state.keywordSearch.previousConversationId,
      currentOrganizationId: state.session.currentOrganizationId,
    })
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [conversationId, setConversationId] = useState('');
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleSeeAllClick = (conversationId: string) => {
    setConversationId(conversationId);
  };

  useEffect(
    function cleanUp() {
      return () => {
        dispatch(setKeywordSearchMode(false));
        dispatch(setPreviousConversationId(''));
      };
    },
    [dispatch]
  );

  const queryArguments = {
    searchValue: searchQuery,
    orgId: currentOrganizationId,
    continuation: conversationId ? '0' : '',
    ...(conversationId && { conversationId }),
  };

  const { results, isFetching } = useRTKQueryInfiniteResults(useSearchQuery, {
    args: queryArguments,
  });

  useEffect(() => {
    if (!isFetching) {
      setIsTransitioning(false);
    }
  }, [isFetching]);

  function getConversationName(conversationMetadata: ConversationMetadata) {
    const { group_id, peer_token, is_dl_list } = conversationMetadata || {};
    const id = group_id || peer_token;

    if (!id) return 'Error: ID not found';

    const modelType = group_id ? 'group' : is_dl_list ? 'distributionList' : 'user';

    const entity = getStoreModel(modelType, id, getEntityData);

    return entity?.displayName || 'Error: Conversation name not found';
  }

  const isSecondLevelResult = (
    result: KeywordSearchGeneralResult | KeywordSearchSecondLevelResult
  ): result is KeywordSearchSecondLevelResult => {
    return 'entity_type' in result;
  };

  const refinedResults = (
    results: (KeywordSearchGeneralResult | KeywordSearchSecondLevelResult)[] | undefined
  ) => {
    if (!results) return { firstLevel: [], secondLevel: [] };
    const firstLevel = results.filter(
      (result): result is KeywordSearchGeneralResult => !isSecondLevelResult(result)
    );
    const secondLevel = results.filter(isSecondLevelResult);
    return { firstLevel, secondLevel };
  };

  const { firstLevel, secondLevel } = refinedResults(results);

  return (
    <div className={classes()}>
      <div className={classes('search-container')}>
        {conversationId && (
          <ArrowBackIcon
            className={classes('arrow-back')}
            onClick={() => {
              setIsTransitioning(true);
              setConversationId('');
            }}
          />
        )}
        <KeywordInputSearch query={searchQuery} setQuery={setSearchQuery} />
        <p
          className={classes('label')}
          onClick={() => {
            dispatch(setKeywordSearchMode(!isKeywordSearchModeOn));
            setCurrentConversationId(previousConversationId);
          }}
        >
          CANCEL
        </p>
      </div>
      <div className={classes('results-container')}>
        {isFetching || isTransitioning ? (
          <div className={classes('results-loader')}>
            <DotsIndicator className={classes('dots')} size={13} speed={0.5} />
          </div>
        ) : conversationId ? (
          <KeywordSearchResultsDetails
            results={secondLevel}
            currentUserId={currentUserId}
            myRoleIds={myRoleIds}
          />
        ) : (
          firstLevel?.map(({ entity }) => (
            <div className={classes('conversation-result')} key={entity?.conversation_id}>
              <div className={classes('conversation-header')}>
                <div className={classes('conversation-name')}>
                  {getConversationName(entity?.conversation_metadata)}
                </div>
                {entity?.total_hits_value > 2 && (
                  <div
                    className={classes('seeAll-label')}
                    onClick={() => handleSeeAllClick(entity?.conversation_id)}
                  >
                    See All
                  </div>
                )}
              </div>
              <div className={classes('message-results')}>
                {entity?.values.map(
                  ({
                    body,
                    created_time_server,
                    highlight,
                    sender_token,
                    is_group,
                    message_id,
                  }) => {
                    return (
                      <KeywordMessageBubble
                        key={message_id}
                        body={body}
                        createdTime={created_time_server}
                        isGroup={is_group}
                        currentUserId={currentUserId}
                        myRoleIds={myRoleIds}
                        senderToken={sender_token}
                        highlight={highlight}
                      />
                    );
                  }
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default mobxInjectSelect<null, MobxProps>({
  conversationStore: ['setCurrentConversationId'],
  roleStore: ['myRoleIds'],
  sessionStore: ['currentUserId'],
  entityStore: ['getEntityData'],
})(KeywordSearchSideBar);
