import { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { emojiMap } from '../../emoji/emojiMap';
import { ReactComponent as PlusIcon } from '../images/plus.svg';
import ReactionPicker from './ReactionPicker';
import styles from './MessageReactionSummary.module.css';
import {
  generateHexTone,
  getUserNames,
  GroupedReaction,
  groupReactionsByTone,
  Reaction,
  renderEmoji,
} from './utils/reactions';
import { mobxInjectSelect } from 'common/utils';
import { User } from 'types/index';
import { MaterialTooltip } from 'widgets/messenger/components/MaterialTooltip';

const DEBOUNCE_TIMEOUT_MS = 250;

type MessageReactionSummaryProps = {
  direction: 'OUTGOING' | 'INCOMING';
  isGroup: boolean;
  messageId: string;
  reactions: Reaction[];
};

type MobxProps = {
  addReaction: ({
    messageId,
    userId,
    reaction,
  }: {
    messageId: string;
    userId: string;
    reaction: string;
  }) => Promise<void>;
  currentOrganizationId: string;
  currentUserId: string;
  getStorageValue: <V>(key: string) => V;
  findUser: (userId: string, organizationId?: string, ignoreNotFound?: boolean) => Promise<User>;
  removeReaction: ({
    messageId,
    userId,
    reaction,
  }: {
    messageId: string;
    userId: string;
    reaction: string;
  }) => Promise<void>;
};

type UpdateAction = 1 | 0 | -1;
type PendingUpdate = {
  baseReaction: string;
  emojiUnicode: string;
  action: UpdateAction;
};

const MessageReactionSummary = ({
  addReaction,
  currentOrganizationId,
  currentUserId,
  direction,
  findUser,
  getStorageValue,
  isGroup,
  messageId,
  reactions,
  removeReaction,
}: MessageReactionSummaryProps & MobxProps) => {
  const [updates, setPendingUpdates] = useState<{ pending: { [key: string]: PendingUpdate } }>({
    pending: {},
  });
  const [reactionsBuffer, setReactionsBuffer] = useState<GroupedReaction[]>([]);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [userNames, setUserNames] = useState<Map<string, string>>(new Map([]));
  const directionStyle = styles[direction.toLowerCase()];
  const groupedReactions = groupReactionsByTone(reactions);
  const reactionDebounce = useRef(debounce(handleBulkUpdate, DEBOUNCE_TIMEOUT_MS));
  const getReactionsBufferStateCallback = useCallback(getReactionsBufferState, [reactions]);

  useEffect(() => {
    const userIds = reactions.reduce(
      (acc, reaction) => reaction.userIds.concat(acc),
      [] as string[]
    );

    async function fetchUser() {
      const names = await Promise.all(
        userIds.map(async (userId) => {
          const user = await findUser(userId, currentOrganizationId, true);
          if (!user) {
            return [userId, 'Removed User'];
          } else {
            return [userId, user.displayName];
          }
        })
      );

      setUserNames(new Map(names as [string, string][]));
    }

    fetchUser();
    setReactionsBuffer(getReactionsBufferStateCallback());
    setPendingUpdates({ pending: {} });
  }, [messageId, currentOrganizationId, findUser, reactions, getReactionsBufferStateCallback]);

  const AddReaction = (
    <li key={`add`} className={classnames(styles.item, directionStyle, styles.addItem)}>
      <ReactionPicker pickerOpen={pickerOpen} setPickerOpen={setPickerOpen} messageId={messageId}>
        <MaterialTooltip title={'Add reaction...'} placement={'top'} arrow>
          <div className={styles.addContainer} onClick={() => setPickerOpen(true)}>
            <span className={styles.add}>
              <PlusIcon fill={'var(--neutral-400)'} title="" />
            </span>
          </div>
        </MaterialTooltip>
      </ReactionPicker>
    </li>
  );

  function getReactionsBufferState(): GroupedReaction[] {
    const groupedReactions = groupReactionsByTone(reactions);

    if (groupedReactions.length) {
      return groupedReactions.map((r) => {
        const bufferedReaction: GroupedReaction = {
          baseReaction: `${r.baseReaction}`,
          fullReactions: r.fullReactions.map((fr) => {
            const bufferedFullReaction: Reaction = {
              emojiUnicode: `${fr.emojiUnicode}`,
              userIds: fr.userIds.concat([]),
            };

            return bufferedFullReaction;
          }),
          allUserIds: r.allUserIds.concat([]),
          totalCount: r.totalCount + 0,
        };

        return bufferedReaction;
      });
    }

    return [];
  }

  function renderReactionTooltip(reaction: GroupedReaction) {
    return (
      <>
        {reaction.fullReactions.map((r) => (
          <div className={styles.tooltip} key={r.emojiUnicode}>
            <div className={styles.emoji}>{renderEmoji(r.emojiUnicode)}</div>
            <div>
              <span>
                {emojiMap[r.emojiUnicode]?.fullName && `:${emojiMap[r.emojiUnicode]?.fullName}:`}
                <br /> reacted by
              </span>{' '}
              {getUserNames({
                currentUserId,
                userIds: r.userIds,
                userIdsMap: userNames,
              })}
            </div>
          </div>
        ))}
      </>
    );
  }

  function getToneModified(reaction: GroupedReaction): string {
    const tone = generateHexTone(getStorageValue);
    const baseReactionModifiers = emojiMap[reaction.baseReaction]?.['modifiers'];

    if (tone && baseReactionModifiers) {
      return baseReactionModifiers[tone];
    }

    return reaction.baseReaction;
  }

  function handleReaction(reaction: GroupedReaction) {
    const alreadyReacted = reaction.fullReactions.filter((r) => r.userIds.includes(currentUserId));

    if (alreadyReacted && alreadyReacted.length > 0) {
      alreadyReacted.map((r) =>
        removeReaction({
          messageId,
          userId: currentUserId,
          reaction: r.emojiUnicode,
        })
      );
    } else {
      const newReaction = getToneModified(reaction);

      addReaction({
        messageId,
        reaction: newReaction,
        userId: currentUserId,
      });
    }
  }

  function handleBulkUpdate(
    updates: { [key: string]: PendingUpdate },
    groupedReactions: GroupedReaction[]
  ) {
    for (const emoji in updates) {
      const update = updates[emoji];
      const groupedReaction = groupedReactions.find((r) => r.baseReaction === update.baseReaction);

      if (groupedReaction && update.action) {
        handleReaction(groupedReaction as GroupedReaction);
      }
    }
  }

  function queueUpdate(update: PendingUpdate): { [key: string]: PendingUpdate } {
    const existingUpdate = updates.pending[update.emojiUnicode];

    if (existingUpdate) {
      update.action += existingUpdate.action;
    }

    const newPendingUpdates = {
      pending: {
        ...updates.pending,
        [update.emojiUnicode]: update,
      },
    };

    setPendingUpdates(newPendingUpdates);

    return newPendingUpdates.pending;
  }

  function updateBuffer(update: PendingUpdate) {
    let newReactionsBuffer: GroupedReaction[] = [];
    const groupedReaction = reactionsBuffer.find((r) => r.baseReaction === update.baseReaction);

    if (!groupedReaction) return;

    if (update.action === 1) {
      const reaction = groupedReaction.fullReactions.find(
        (r) => r.emojiUnicode === update.emojiUnicode
      );

      if (!reaction) {
        groupedReaction.fullReactions.push({
          emojiUnicode: update.emojiUnicode,
          userIds: [currentUserId],
        });
      } else {
        reaction.userIds.push(currentUserId);
      }

      groupedReaction.allUserIds.push(currentUserId);
      groupedReaction.totalCount += update.action;

      newReactionsBuffer = reactionsBuffer.map((r) => {
        if (groupedReaction && r.baseReaction === update.baseReaction) {
          return groupedReaction;
        }

        return r;
      });
    }

    if (update.action === -1) {
      if (groupedReaction.totalCount + update.action === 0) {
        setReactionsBuffer(reactionsBuffer.filter((r) => r.baseReaction !== update.baseReaction));
        return;
      }

      newReactionsBuffer = reactionsBuffer.map((r) => {
        if (r.baseReaction === update.baseReaction) {
          const reaction = r.fullReactions.find((fr) => fr.emojiUnicode === update.emojiUnicode);

          if (reaction) {
            r.fullReactions = r.fullReactions
              .map((fr) => {
                if (fr.emojiUnicode === update.emojiUnicode) {
                  fr.userIds = fr.userIds.filter((id) => id !== currentUserId);
                }

                return fr;
              })
              .filter((fr) => fr.userIds.length);
          }

          r.allUserIds = r.allUserIds.filter((id) => id !== currentUserId);
          r.totalCount += update.action;
        }

        return r;
      });
    }

    setReactionsBuffer(newReactionsBuffer);
  }

  function handleReactionClick(reaction: GroupedReaction) {
    reactionDebounce.current.cancel();

    const alreadyReacted = reaction.fullReactions.filter((r) => r.userIds.includes(currentUserId));
    const action: UpdateAction = alreadyReacted && alreadyReacted.length ? -1 : 1;
    const update: PendingUpdate = {
      baseReaction: reaction.baseReaction,
      emojiUnicode: getToneModified(reaction),
      action,
    };

    updateBuffer(update);

    reactionDebounce.current(queueUpdate(update), groupedReactions);
  }

  function getReactionsState(): GroupedReaction[] {
    if (Object.keys(updates.pending).length) {
      return reactionsBuffer;
    }

    return groupedReactions;
  }

  return (
    <div className={classnames(styles.root, directionStyle, isGroup ? styles.group : '')}>
      <div className={classnames(styles.container, directionStyle)}>
        <ul className={classnames(styles.list, direction === 'OUTGOING' && styles.outgoing)}>
          {getReactionsState().length > 0 && (
            <>
              {direction === 'OUTGOING' && AddReaction}
              {getReactionsState().map((reaction) => (
                <li
                  key={reaction.baseReaction}
                  className={classnames(
                    styles.item,
                    directionStyle,
                    reaction.allUserIds.includes(currentUserId) ? styles.self : ''
                  )}
                  onClick={() => handleReactionClick(reaction)}
                >
                  <MaterialTooltip title={renderReactionTooltip(reaction)} placement={'top'} arrow>
                    <div className={styles.reaction}>
                      <div className={styles.emoji}>
                        {reaction.fullReactions.slice(0, 2).map((r, index) => (
                          <span key={index} className={styles.multiple}>
                            {renderEmoji(r.emojiUnicode)}
                          </span>
                        ))}
                      </div>
                      <span className={styles.count}>{reaction.totalCount}</span>
                    </div>
                  </MaterialTooltip>
                </li>
              ))}
              {direction === 'INCOMING' && AddReaction}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default mobxInjectSelect<MessageReactionSummaryProps, MobxProps>({
  localStore: ['getStorageValue'],
  messageStore: ['addReaction', 'removeReaction'],
  messengerStore: ['currentOrganizationId'],
  sessionStore: ['currentUserId'],
  userStore: ['findUser'],
})(MessageReactionSummary);
