import React from 'react';
import { useDispatch } from 'react-redux';
import { Card } from '../../WebComponents';
import { Role } from '../../../../types';
import BEM from '../../../bem';

import { ReactComponent as Arrow } from '../../../images/icon-arrow-right-gray.svg';
import { ReactComponent as AlwaysEscalateIcon } from '../../../images/icon-escalation-small.svg';

import { actions } from '../../../../redux-stores';

const { setModal } = actions;
const classes = BEM.with('CollaborationEntityEditor');

type RoleEditorEscalationProps = {
  role: Role;
};

export default function RoleEditorEscalation({ role }: RoleEditorEscalationProps) {
  const dispatch = useDispatch();
  const escalationPolicy = role.metadata?.escalation_policy;
  let hasEscalationPolicy = false;
  let targetOneName, targetTwoName;

  if (escalationPolicy?.escalation_path?.[0]?.targets) {
    targetOneName = escalationPolicy.escalation_path[0].targets[0]?.display_name;
    if (escalationPolicy.escalation_path.length === 2) {
      targetTwoName = escalationPolicy.escalation_path?.[1].targets?.[0]?.display_name;
    }
  }

  if (escalationPolicy?.escalation_path?.length) {
    hasEscalationPolicy = true;
  }

  return (
    <div className={classes('card-container')}>
      <Card header="Escalation">
        <div>
          <p>
            Allows you to pre-define an escalation path to help ensure a member of the team has this
            role covered.
          </p>
          <div className={classes('switch-block')}>
            <div
              className={classes('switch-item')}
              data-test-id="open escalation modal"
              onClick={() => dispatch(setModal({ name: 'escalation' }))}
            >
              {hasEscalationPolicy ? (
                <span className={classes('esc-on-text')}>ON</span>
              ) : (
                <span>OFF</span>
              )}
              <Arrow />
            </div>
          </div>
        </div>
        {hasEscalationPolicy && (
          <>
            <div className={classes('esc-on-container')}>
              <div className={classes('esc-on-title')}>Escalation Policy</div>
              <div className={classes('esc-on-row')}>
                <div className={classes('esc-on-target')}>
                  <div className={classes('esc-on-header')}>Original Recipient</div>
                  <div className={classes('esc-on-body')}>{role.displayName}</div>
                </div>
                <div className={classes('esc-on-time')}>
                  <div className={classes('esc-on-header')}>Time to Acknowledge</div>
                  <div className={classes('esc-on-body')}>
                    {escalationPolicy?.target_duration} mins
                  </div>
                </div>
              </div>
              <div className={classes('esc-on-row')}>
                <div className={classes('esc-on-target')}>
                  <div className={classes('esc-on-header')}>Backup 1</div>
                  <div className={classes('esc-on-body')}>{targetOneName}</div>
                </div>
                <div className={classes('esc-on-time')}>
                  <div className={classes('esc-on-header')} />
                  <div className={classes('esc-on-body')}>
                    {escalationPolicy?.escalation_path?.[0]?.duration} mins
                  </div>
                </div>
              </div>
              {(escalationPolicy?.escalation_path?.length || 0) >= 2 && (
                <div className={classes('esc-on-row')}>
                  <div className={classes('esc-on-target')}>
                    <div className={classes('esc-on-header')}>Backup 2</div>
                    <div className={classes('esc-on-body')}>{targetTwoName}</div>
                  </div>
                  <div className={classes('esc-on-time')}>
                    <div className={classes('esc-on-header')} />
                    <div className={classes('esc-on-body')}>
                      {escalationPolicy?.escalation_path?.[1].duration} mins
                    </div>
                  </div>
                </div>
              )}
            </div>
            {escalationPolicy?.always_escalate && (
              <div className={classes('always-escalate-on-container')}>
                <div className={classes('always-escalate-on')}>
                  <AlwaysEscalateIcon className={classes('always-escalate-on-icon')} />
                  <div>
                    Set to
                    <span className={classes('always-escalate-text')}> Always Escalate </span>
                    for this role
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  );
}
