import React, { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';
import classNames from 'classnames';
import { actions, ReduxState, useAppDispatch, useAppSelector } from '../../../redux-stores';
import EntitiesSelector from '../Collaboration/Entities/EntitiesSelector';
import { Conversation, Entity, Role, User } from '../../../types';
import style from './MentionMemberFloatingModal.module.css';
import { shouldAddMemberToConversation } from 'common/utils/shouldAddMemberToConversation';

const { setModal } = actions;

export const MODAL_HEIGHT = 210;

export type HandleKeyDown = {
  handleKeyDown: (key: string) => void;
  isOpen: boolean;
};

type MentionMemberSelectorProps = {
  currentUserId: string;
  currentConversation?: Conversation;
  isComposing: boolean;
  selectEntity: (entity: Role | User) => void;
  setMentionMemberFloatingModal: (isOpen: boolean) => void;
  selectedRecipients: Array<Entity>;
  filter?: string;
};

const MentionMemberFloatingModal = forwardRef(
  (
    {
      currentConversation,
      currentUserId,
      isComposing,
      selectEntity,
      setMentionMemberFloatingModal,
      selectedRecipients,
      filter,
    }: MentionMemberSelectorProps,
    ref: ForwardedRef<HandleKeyDown>
  ) => {
    const currentOrgId = useAppSelector((state: ReduxState) => state.session.currentOrganizationId);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [pressedKey, setPressedKey] = useState<string>('');
    const [height, setHeight] = useState<number>(0);
    const dispatch = useAppDispatch();

    const closeModal = () => {
      setMentionMemberFloatingModal(false);
    };

    const selectUser = (member: Role | User) => {
      setMentionMemberFloatingModal(false);
      selectEntity(member);

      const membersToAdd: Array<Role | User> = [];

      const getBotUserId = () => {
        if (member.$entityType === 'role') {
          const typedMember = member as Role;
          return typedMember.botUserId;
        }
      };

      const isMemberInSelectedRecipients = selectedRecipients.some(
        (recipient) =>
          recipient.id === member.id ||
          getBotUserId() === member.id ||
          recipient?.members?.some((m) => m.id === member.id || getBotUserId() === m.id)
      );

      if (isComposing && !isMemberInSelectedRecipients) {
        membersToAdd.push(member);
      }

      if (shouldAddMemberToConversation(currentUserId, member, currentConversation)) {
        membersToAdd.push(member);
      }

      if (membersToAdd.length > 0) {
        const serializedMembersToAdd = membersToAdd.map((member) => {
          return { ...member, members: [] };
        });

        dispatch(
          setModal({
            name: 'addMemberToConversation',
            data: {
              // Needed for serialize elements
              membersToAdd: JSON.parse(JSON.stringify(serializedMembersToAdd)),
            },
          })
        );
      } else {
        closeModal();
      }
    };

    const clearEntity = (e: React.MouseEvent) => {
      e.stopPropagation();
    };

    useImperativeHandle(ref, () => ({
      handleKeyDown(key: string) {
        setPressedKey(key);
      },
      isOpen,
    }));

    return (
      <div
        className={classNames(style.mentionMemberFloatingModal, {
          [style.hideMentionMemberFloatingModal]: !isOpen,
        })}
        style={{
          height: `${height}px`,
        }}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === 'Escape') {
            closeModal();
            setIsOpen(false);
          }
        }}
        id={'MentionMemberFloatingModal'}
      >
        <EntitiesSelector
          allowRolesRecipients={true}
          clearEntity={clearEntity}
          currentUserId={currentUserId}
          isEntityInConversationCheck={true}
          isMentionMemberFloatingModalOpen={true}
          organizationId={currentOrgId}
          selectEntity={selectUser}
          disableDndEntities={false}
          shouldDisplayOnDutyText={false}
          toggleHandler={() => setMentionMemberFloatingModal(false)}
          filter={filter}
          pressedKey={pressedKey}
          setIsOpen={setIsOpen}
          setHeight={setHeight}
        />
      </div>
    );
  }
);

export default MentionMemberFloatingModal;
