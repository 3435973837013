import React, { useState } from 'react';
import { ActionStatuses, VWR, VisitStatus, VisitStatuses } from '../../../types';
import BEM from '../../bem';
import TCClient from '../../../client';
import BasicModal from '../BasicModal';
import { useVWRContext, changeSelectedStatus } from '../../../contexts/VirtualWaitingRoom';
import { ReactComponent as DropdownChevronSvg } from '../../images/dropdown-chevron.svg';
import { ReactComponent as OptionIcon } from '../../images/icon-path.svg';
import OptionsMenu from './OptionsMenu';
import DropdownList from './DropdownList';
import VisitorCardList from './VisitorCardList';
import SideBarHeader from './SideBarHeader';

const classes = BEM.with('VirtualWaitingRoomSideBar');

type StatusOption = {
  name: typeof VisitStatuses[VisitStatus];
  id: VisitStatus;
  isOptionBolded?: boolean;
  optionIcon?: JSX.Element;
};

const statusOptions: StatusOption[] = [
  {
    name: 'Active Patients',
    id: 'INCOMPLETE',
    isOptionBolded: true,
  },
  {
    name: 'New',
    id: 'NEW',
    optionIcon: <OptionIcon style={{ marginRight: '5px' }} />,
  },
  {
    name: 'Intake',
    id: 'INTAKE',
    optionIcon: <OptionIcon style={{ marginRight: '5px' }} />,
  },
  {
    name: 'Ready',
    id: 'READY',
    optionIcon: <OptionIcon style={{ marginRight: '5px' }} />,
  },
  {
    name: 'Treating',
    id: 'TREATING',
    optionIcon: <OptionIcon style={{ marginRight: '5px' }} />,
  },
  {
    name: 'Follow-up',
    id: 'FOLLOW_UP',
    optionIcon: <OptionIcon style={{ marginRight: '5px' }} />,
  },
  {
    name: 'Completed',
    id: 'COMPLETED',
    isOptionBolded: true,
  },
];

const placeholderVWR: VWR = {
  id: '',
  name: '',
  staff: [],
  status: 'CLOSED',
  version: 0,
  visitReasons: [],
};

export default function SideBar() {
  const [isDangerModalOpen, setIsDangerModalOpen] = useState(false);
  const [isDisplayingOptions, setIsDisplayingOptions] = useState(false);
  const {
    dispatch,
    state: {
      loadedStatus,
      mobxProps,
      selectedRoom,
      selectedStatus,
      visitors: { count: visitorCount },
    },
  } = useVWRContext();

  async function clearCompletedVisits() {
    if (!selectedRoom) return;
    try {
      await TCClient.virtualWaitingRoom.clearAllCompletedVisits({
        roomId: selectedRoom?.id,
        organizationId: mobxProps.currentOrganizationId,
      });
      dispatch({ type: 'CLEAR_COMPLETED_VISITS' });
    } catch (e) {
      console.error(e);
    } finally {
      setIsDangerModalOpen(false);
    }
  }

  function toggleOptions() {
    if (loadedStatus === ActionStatuses.SUCCESS) {
      setIsDisplayingOptions(!isDisplayingOptions);
    }
  }

  return (
    <div className={classes()}>
      <SideBarHeader onOptionsButtonPress={toggleOptions} />
      {isDisplayingOptions ? (
        <OptionsMenu vwr={selectedRoom || placeholderVWR} closeOptions={toggleOptions} />
      ) : (
        loadedStatus === ActionStatuses.SUCCESS && (
          <>
            <div className={classes('status-dropdown-container')}>
              <DropdownList
                {...{
                  values: statusOptions,
                  setSelectedValue: ({ index }: { index: number }) => {
                    if (selectedRoom) {
                      changeSelectedStatus(
                        dispatch,
                        selectedRoom?.id,
                        statusOptions[index].id,
                        mobxProps
                      );
                    }
                  },
                  selectedValue: { id: selectedStatus },
                  primaryHeader: (
                    <span className={classes('selected-status')}>
                      {VisitStatuses[selectedStatus]} ({visitorCount})
                      <DropdownChevronSvg width={8} height={4} style={{ marginLeft: '5px' }} />
                    </span>
                  ),
                  width: 163,
                }}
              />
              {selectedStatus === 'COMPLETED' && (
                <button
                  className={classes('clear-all')}
                  disabled={visitorCount === 0}
                  onClick={() => setIsDangerModalOpen(!isDangerModalOpen)}
                >
                  Clear All
                </button>
              )}
            </div>
            <VisitorCardList
              roomState={selectedRoom?.status || 'CLOSED'}
              visitsFilterStatus={VisitStatuses[selectedStatus]}
            />
            <BasicModal
              ariaLabelBody={'Clear All Completed Patients info'}
              ariaLabelCancelButton={'Clear All Completed Patients Cancel'}
              ariaLabelCloseButton={'Clear All Completed Patients Close'}
              ariaLabelHeader={'Clear All Completed Patients Header'}
              ariaLabelSubmitButton={'Clear All Completed Patients Delete'}
              bodyText={
                'All patient visit(s) in the completed status will be removed for all staff members. Do you wish to continue?'
              }
              headerText={'Clear All Completed Patients'}
              isOpen={isDangerModalOpen}
              onClose={() => setIsDangerModalOpen(false)}
              onSubmit={clearCompletedVisits}
              submitText={'REMOVE'}
              size={'medium-large'}
              theme={'danger'}
            />
          </>
        )
      )}
    </div>
  );
}
