import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SuccessCheckMark } from '../images/checkmark.svg';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { Modal } from './';

const classes = BEM.with('PatientSaveSuccessModal');
const SAVE_SUCCESS_TIMEOUT = 1500;

class PatientSaveSuccessModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    showRefreshMessage: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        hasCloseButton={false}
        headerClass={classes('saveSuccessHeader')}
        isOpen={isOpen}
        onAfterOpen={this._autoCloseModal}
        size={'small'}
      >
        <div className={classes('check-mark')}>
          <SuccessCheckMark />
        </div>
        <div className={classes('message-container')}>Patients saved</div>
      </Modal>
    );
  }

  _autoCloseModal = () => {
    const { closeModal, showRefreshMessage } = this.props;
    setTimeout(() => {
      closeModal();
      showRefreshMessage();
    }, SAVE_SUCCESS_TIMEOUT);
  };
}

export default mobxInjectSelect({
  patientStore: ['showRefreshMessage'],
})(PatientSaveSuccessModal);
