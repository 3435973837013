import React, { Component } from 'react';
import BEM from '../../../bem';
import propTypes from '../../../propTypes';
import { mobxInjectSelect } from '../../../utils';
import { UserName } from '../..';
import { MessageSubTypes } from '../../../../models/enums/MessageSubTypes';

const classes = BEM.with('MessageContent');

class MessageHeader extends Component {
  static propTypes = {
    message: propTypes.message.isRequired,
  };

  render() {
    const { currentUserId, isRolesTransitionFeatureFlagEnabled, message } = this.props;
    const {
      counterParty,
      currentSenderRole,
      isForwarded,
      isOutgoing,
      senderId,
      sender,
      subType,
      sortNumber,
    } = message;
    let { senderRole } = message;
    let ariaLabel, nameFragment, role, titleFragment, user, group;

    const messageWasSentByOtherUser = senderId !== currentUserId;

    if (currentSenderRole) {
      senderRole = currentSenderRole;
    }

    const { $entityType, groupType } = counterParty || {};
    const isGroupConversation = $entityType === 'group' && groupType !== 'ROLE_P2P';
    const showUserName = isRolesTransitionFeatureFlagEnabled
      ? (senderRole || isGroupConversation) && messageWasSentByOtherUser
      : (senderRole || isGroupConversation) && !isOutgoing;
    const isPatientMessaging = groupType === 'PATIENT_MESSAGING';
    const isTeam = subType === MessageSubTypes.SYSTEM && message.group;
    const isVisitor = sender && sender.isVisitor;
    const shouldHideSenderDetails =
      subType === MessageSubTypes.SMS_OPT_OUT_NOTIFICATION ||
      subType === MessageSubTypes.SMS_OPT_IN_NOTIFICATION;

    if (
      (subType === MessageSubTypes.EMPTY_ROLE_NOTIFICATION ||
        subType === MessageSubTypes.WORKFLOW_AUTOMATION_NOTIFICATION) &&
      !isForwarded
    ) {
      nameFragment = 'Automated Message';
    } else if (isTeam) {
      group = message.group;
    } else if (subType === MessageSubTypes.ROLE_AWAY_NOTIFICATION) {
      role = senderRole;
      user = showUserName ? sender : null;
    } else if (showUserName || senderRole) {
      const labelName = senderRole ? senderRole.displayName : sender ? sender.displayName : '';
      ariaLabel = `Conversation Body ${labelName}`;
      role = senderRole;
      user = showUserName ? sender : null;
    }

    if (isVisitor) {
      nameFragment = (
        <UserName
          ariaLabel={ariaLabel}
          className={classes('user-name')}
          user={user}
          group={group}
          {...(sender &&
            sender.removedFromOrg && {
              onClick: null,
              className: classes('user-name-removed'),
            })}
        />
      );
    } else if (!shouldHideSenderDetails && (role || user || isTeam)) {
      nameFragment = (
        <UserName
          ariaLabel={ariaLabel}
          className={classes('user-name-link')}
          onClick={this._composeToSender}
          role={role}
          user={user}
          group={group}
          {...(sender &&
            sender.removedFromOrg && {
              onClick: null,
              className: classes('user-name-removed'),
            })}
        />
      );
    }

    if (!shouldHideSenderDetails && isPatientMessaging && user) {
      if (user.isPatient) {
        titleFragment = (
          <span className={classes('patient-contact-relation')} id={`${sortNumber}-relation`}>
            (Patient)
          </span>
        );
      } else if (user.patientContact) {
        const { relation } = user.patientContact;
        titleFragment = (
          <span
            className={classes('patient-contact-relation')}
            title={relation}
            id={`${sortNumber}-relation`}
          >
            ({relation})
          </span>
        );
      }
    }

    if (nameFragment) {
      return (
        <>
          <div
            className={classes('user-name', {
              isPatientMessaging,
            })}
          >
            {nameFragment}
            {titleFragment}
          </div>
          <div id={`${sortNumber}-name`} className="screen-reader-only">
            {nameFragment} said
          </div>
        </>
      );
    } else if (isOutgoing) {
      return (
        <div id={`${sortNumber}-name`} className="screen-reader-only">
          You said
        </div>
      );
    } else {
      const conversationName = document.getElementById('conversation-name')?.textContent;
      return conversationName ? (
        <div id={`${sortNumber}-name`} className="screen-reader-only">
          {conversationName} said
        </div>
      ) : null;
    }
  }

  _composeToSender = () => {
    const { composeToEntity, message, setIsComposeFromLink } = this.props;
    const { currentSenderRole, sender, subType } = message;
    if (subType === MessageSubTypes.SYSTEM) return;
    setIsComposeFromLink(true);
    composeToEntity(currentSenderRole || sender);
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['composeToEntity', 'setIsComposeFromLink'],
  sessionStore: ['currentUserId'],
  messengerStore: ['isRolesTransitionFeatureFlagEnabled'],
})(MessageHeader);
