import React from 'react';
import classNames from 'classnames';

import {
  SettingContainer,
  SettingContainerProps,
} from '../../shared-components/SettingContainer/SettingContainer';
import { StandardOrgSettingInput } from '../../shared-components/StandardSettingInput';
import { OrganizationSetting } from '../../types';

import { AuthorizeOrgSetting } from '../../shared-components/AuthorizeSetting';
import styles from './OrganizationSettings.module.css';

export function DesktopSettingsItemList() {
  const desktopOrgSettingsListItems: (SettingContainerProps & {
    dependsOnSettings?: (keyof OrganizationSetting)[];
  })[] = [
    {
      label: 'Desktop App Auto-update',
      subLabel: 'Allow users to automatically update new versions of the Desktop App',
      settingControl: (
        <StandardOrgSettingInput settingName="isDesktopAppAutoUpdateEnabled" type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['featurePriorityMessaging'],
      label: 'Desktop App PM Sound',
      subLabel: 'Desktop App will play a louder sound when a PM is received',
      settingControl: (
        <StandardOrgSettingInput settingName="isDesktopAppPmSoundEnabled" type="checkbox" />
      ),
    },
    {
      label: 'Extended Session (Desktop SSO customers only)',
      subLabel:
        'Enable this feature to allow your users to remain logged in for longer than 24 hours. Once enabled you will be able to set the amount of days a session is to remain active. Set the value to -1 to turn this feature off.',
      settingControl: (
        <>
          <StandardOrgSettingInput settingName="extendedSession" type="number" /> days
        </>
      ),
    },
  ];

  return (
    <>
      {desktopOrgSettingsListItems.map((settingProps) => (
        <AuthorizeOrgSetting
          key={settingProps.label}
          settingNames={settingProps.dependsOnSettings ? settingProps.dependsOnSettings : []}
        >
          <div key={settingProps.label} className={classNames(styles.individualSettingContainer)}>
            <SettingContainer {...settingProps} />
          </div>
        </AuthorizeOrgSetting>
      ))}
    </>
  );
}
