import React from 'react';
import { useSelector } from 'react-redux';
import { CollabEntity } from '../../../../types';
import BEM from '../../../bem';
import { ReduxState } from '../../../../redux-stores';
import { CollaborationCategories } from '../../../../models/enums';
import EntitiesItem from './EntitiesItem';
import NewEntityItem from './NewEntityItem';

const classes = BEM.with('CollaborationRoles');
const { MYROLES, MYTEAMS } = CollaborationCategories;

type EntitiesListItemProps = {
  hasCheck: boolean;
  hideNewEntityPlaceholder: boolean;
  isChecked?: boolean;
  selected: boolean;
  select: (token: string) => void;
  selectedCategory: string;
  toggle: (token: string, isChecked: boolean) => void;
  id: string;
  entity?: CollabEntity;
  newEntityType?: string;
};

const getEntity = (newEntityType: string) => ({ $entityType: newEntityType });

export default function EntitiesListItem({
  hasCheck,
  hideNewEntityPlaceholder,
  isChecked = false,
  entity,
  newEntityType,
  select,
  selectedCategory,
  selected,
  toggle,
  id,
}: EntitiesListItemProps) {
  const isRolesAdmin = useSelector((state: ReduxState) => state.collab.isRolesAdmin);
  const isTeamAdmin = useSelector((state: ReduxState) => state.collab.isTeamAdmin);
  const isEntityAdmin = isRolesAdmin || isTeamAdmin;
  const isSavedEntityCategory = selectedCategory === MYROLES || selectedCategory === MYTEAMS;
  const canCheck = hasCheck && !isSavedEntityCategory;
  return (
    <div
      className={classes('list-item', {
        selected,
        removeLeftSideContainer: !isEntityAdmin || isSavedEntityCategory,
      })}
      data-test-id={`entity ${entity?.displayName}`}
    >
      {isEntityAdmin && !isSavedEntityCategory && (
        <div
          className={classes('left-side-container')}
          onClick={() => canCheck && toggle(id, isChecked)}
        >
          {canCheck && (
            <div className={classes('check-container')}>
              <input type="checkbox" checked={isChecked} readOnly />
            </div>
          )}
        </div>
      )}

      <div className={classes('list-item-draggable')}>
        {newEntityType && !hideNewEntityPlaceholder ? (
          <NewEntityItem entity={getEntity(newEntityType)} />
        ) : entity ? (
          <EntitiesItem select={select} showOwner showTag showAction entity={entity} id={id} />
        ) : null}
      </div>
    </div>
  );
}
