export default class TargetNotSupportedError extends Error {
  static CODE = 'target-not-supported';

  constructor(methodName: string) {
    super(
      `method${methodName ? ` ${methodName}` : ''} not supported in target ${
        process.env.NODE_TARGET
      }`
    );
  }

  static raise(methodName: string) {
    throw new this(methodName);
  }
}
