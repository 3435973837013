export const CallStatuses = {
  INVITE: 'invite',
  COMPLETE: 'complete',
  EXPIRE: 'expire',
} as const;

export type CallStatus =
  | typeof CallStatuses.INVITE
  | typeof CallStatuses.COMPLETE
  | typeof CallStatuses.EXPIRE;
