import Linkify from 'tt-react-linkify';
import BEM from '../../bem';
import { MessageAttachmentList } from '../MessageItem';
import { PrintMessageDetails } from './';
import { Message } from 'types';

const classes = BEM.with('PrintMessageItem');

type PrintMessageItemProps = { message: Message };

const PrintMessageItem = ({ message }: PrintMessageItemProps) => {
  const { body, isForwarded, isOutgoing, originalSender, sender } = message;
  const direction = isOutgoing ? 'OUTGOING' : 'INCOMING';

  let bodyFragment: string | JSX.Element = body;
  if (isForwarded && originalSender) {
    bodyFragment = (
      <div className={classes('forwarded')}>
        Forwarded:
        <div className={classes('forwarded-body')}>
          <div className={classes('body-forward-name')}>{originalSender?.displayName}</div>
          <div className={classes('body-forward-text')}>{body}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes({ direction })}>
      <div className={classes('user-name')}>{sender?.displayName}</div>
      <div className={classes('body')}>
        <div className={classes('text-container')}>
          <Linkify
            inlineImages={true}
            properties={{ rel: ['noopener noreferrer'], target: '_blank' }}
          >
            {bodyFragment}
          </Linkify>
        </div>
        <MessageAttachmentList message={message} multiSelectable={false} printMode={true} />
      </div>
      <PrintMessageDetails message={message} />
    </div>
  );
};

export default PrintMessageItem;
