/* eslint @typescript-eslint/no-var-requires: 0 */
const { getGlobals } = require('./js-sdk/src/utils/globals');
const globals = getGlobals();

require('core-js/features/array/find');
require('core-js/features/array/from');
require('core-js/features/array/includes');
require('core-js/features/object/assign');
require('core-js/features/object/entries');
require('core-js/features/object/values');

require('core-js/features/global-this');

if (typeof Promise === 'undefined' || !('finally' in Promise.prototype)) {
  require('promise/lib/rejection-tracking').enable();
  globals.Promise = require('promise/lib/es6-extensions.js');
}

require('abortcontroller-polyfill/dist/abortcontroller-polyfill-only');
require('./js-sdk/src/utils/fetch-browser-polyfill');
