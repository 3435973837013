import React, { useRef, useState } from 'react';
import { Card } from '../../WebComponents';
import BEM from '../../../bem';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import { CollabEntity, SaveRoleProps } from '../../../../types';

const classes = BEM.with('CollaborationEntityEditor');

type EntityEditorDescriptionProps = {
  entity?: CollabEntity;
  isAdmin: boolean;
  saveEntity: (props: SaveRoleProps) => void;
};

export default function RoleEditorDescription({
  entity,
  isAdmin,
  saveEntity,
}: EntityEditorDescriptionProps) {
  const defaultDraft = entity?.description || '';
  const entityType = entity?.$entityType;
  const [draft, setDraft] = useState<string>(defaultDraft);
  const [focus, setFocus] = useState<boolean>(false);
  const [savedDraft, setSavedDraft] = useState<string>(defaultDraft);
  const descriptionRef = useRef(null);

  const descriptionOnKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 27) {
      resetDescription();
    }
  };

  const descriptionOnFocus = () => {
    setFocus(true);
  };

  const descriptionOnBlur = () => {
    setFocus(false);
  };

  const updateDraft = (value: string) => {
    setDraft(value);
  };

  const requestSaveEntity = (e: React.MouseEvent) => {
    e.preventDefault();
    saveEntity({ description: draft.trim(), emptyDescription: !draft.trim() });
    setSavedDraft(draft);
  };

  const resetDescription = () => {
    setDraft(savedDraft || '');
  };

  const emptyDescription = !(draft || focus);
  const form = (
    <form id="loginFormDescription">
      <textarea
        className={classes('text-area', {
          empty: emptyDescription,
          admin: isAdmin,
        })}
        disabled={!isAdmin}
        maxLength={256}
        onBlur={descriptionOnBlur}
        onChange={(e) => updateDraft(e.target.value)}
        onFocus={descriptionOnFocus}
        onKeyDown={descriptionOnKeyDown}
        placeholder={
          emptyDescription
            ? '-No Description-'
            : `${entityType === 'role' ? 'Role' : 'Team'} Description`
        }
        ref={descriptionRef}
        value={draft}
      />
    </form>
  );
  return (
    <div className={classes('')}>
      <div className={classes('card-container')}>
        <Card
          header={`${entityType === 'role' ? 'Role' : 'Team'} description`}
          headerRight={
            <div className={classes('title-text-area-container')}>
              {draft !== savedDraft && (
                <div className={classes('text-area-button-container')}>
                  <button
                    data-test-id={`cancel edit ${entityType} description`}
                    className={classes('cancel-button')}
                    onClick={resetDescription}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    data-test-id={`save ${entityType} description`}
                    className={classes('save-button')}
                    onClick={requestSaveEntity}
                    type="button"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          }
        >
          {isAdmin ? (
            <ToolTip text="Edit" textAlign={'center'} getManualOffset={() => {}}>
              {form}
            </ToolTip>
          ) : (
            <>{form}</>
          )}
        </Card>
      </div>
    </div>
  );
}
