const FETCH_CONTINUATION_RATIO = 0.8;

export const shouldFetchMore = (
  currentIndex: number,
  resultsLength: number,
  continuation: string | undefined,
  lastContinuation: string | undefined
): boolean => {
  return (
    !!continuation &&
    currentIndex >= Math.round(FETCH_CONTINUATION_RATIO * resultsLength) &&
    continuation !== lastContinuation
  );
};
