import React, { useCallback, useEffect, useState } from 'react';

import TCClient from '../../../../../client';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { Input } from '../../../../shared-components/Input/Input';
import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';
import { Checkbox } from '../../../../shared-components/Checkbox/Checkbox';
import { Dropdown } from '../../../../shared-components/Dropdown/Dropdown';
import { Table, TableColumn } from '../../../../shared-components/Table/Table';

import { Toast } from '../../../../shared-components/Toast/Toast';
import styles from './styles.module.css';
import { useAppSelector } from 'redux-stores';

type PagerProps = {
  onClose: () => void;
};

type PagerEntry = { id: string; name: string; number: string; priority: boolean };

export const Pager: React.FC<PagerProps> = ({ onClose }) => {
  const selectedOrganization = useAppSelector((state) => state.admin.selectedOrganization);
  const selectedUser = useAppSelector((state) => state.admin.selectedUser);
  const [pagers, setPagers] = useState<PagerEntry[]>([]);

  const columns: TableColumn[] = [
    { field: 'name', headerName: 'Pager Name' },
    { field: 'number', headerName: 'Pager Number' },
    {
      field: 'priority',
      headerName: 'Priority',
      renderer: (_, rowIndex, value) => {
        return (
          <Checkbox
            checked={value as boolean}
            onClick={async () => {
              const onOrOff = value ? 'OFF' : 'ON';
              const confirm = await window.confirm(
                `Are you sure you want to turn ${onOrOff} Priority Messaging for this number?`
              );

              if (confirm) {
                const pager = pagers[rowIndex];

                await TCClient.adminUsers.updateUserPagerPriority({
                  userId: selectedUser.id,
                  pager,
                  value: value as boolean,
                });

                pagers[rowIndex].priority = !value;

                setPagers([...pagers]);
              }
            }}
          />
        );
      },
    },
  ];

  const getPagers = useCallback(async () => {
    const _pagers = await TCClient.adminUsers.getUserPagers({
      orgId: selectedOrganization.token,
      userId: selectedUser.id,
    });
    setPagers(_pagers);
  }, [selectedOrganization.token, selectedUser.id]);

  useEffect(() => {
    getPagers();
  }, [getPagers]);

  const onRowDelete = async ({ number }: PagerEntry) => {
    const confirm = await window.confirm(
      'Are you sure you want to permanently delete this number?'
    );

    if (confirm) {
      await TCClient.adminUsers.deletePagerNumber({ userId: selectedUser.id, pagerNumber: number });
      await getPagers();
    }
  };

  const CreatePager = () => {
    const [status, setStatus] = useState<'get_numbers' | 'create_pager'>('get_numbers');
    const [pagerName, setPagerName] = useState('');
    const [pagerNumber, setPagerNumber] = useState('');
    const [selectedPagerIndex, setSelectedPagerIndex] = useState<number>(0);
    const [availableNumbers, setAvailableNumbers] = useState<{ label: string; value: string }[]>(
      []
    );
    const [error, setError] = useState('');
    const [warning, setWarning] = useState(false);

    const openEmptyQueryWarning = () => {
      setWarning(true);
    };

    const getAvailableNumbers = async () => {
      setError('');
      const numbers = await TCClient.adminUsers.getAvailablePagerNumbers({ pagerNumber });

      if (numbers.length === 0) {
        setError('There are no numbers available for this query. Please try again.');
      } else {
        setStatus('create_pager');
        const _numbers = numbers.map(({ name: label, number: value }) => ({
          label,
          value,
        }));
        setAvailableNumbers(_numbers);
      }
    };

    const savePager = async () => {
      if (!pagerName) {
        return setError('Enter a pager name');
      }

      const number = availableNumbers[selectedPagerIndex];
      const settings = {
        set: {
          pager_number: [
            {
              number: number.value.replace('+', ''),
              label: pagerName,
              priority: 0,
            },
          ],
        },
        del: {},
      };

      await TCClient.adminUsers.setAccountSettings({
        userId: selectedUser.id,
        settings,
      });

      await TCClient.adminUsers.setIncomingNumber({
        number: number.value,
        special: number.label === 'UNASSIGNED907',
      });

      await getPagers();

      setPagerName('');
      setPagerNumber('');
      setAvailableNumbers([]);
      setSelectedPagerIndex(0);

      setStatus('get_numbers');
    };

    const cancelSave = () => {
      setAvailableNumbers([]);
      setSelectedPagerIndex(0);
      setPagerName('');
      setPagerNumber('');
      setStatus('get_numbers');
    };

    return (
      <>
        <div className={styles.fields}>
          <Input
            customCSS={styles.field}
            placeholder={'Pager Name'}
            value={pagerName}
            onInputChange={(e) => setPagerName(e.target.value)}
          />
          {status === 'get_numbers' && (
            <>
              <Input
                customCSS={styles.field}
                placeholder={'Number Starts With'}
                value={pagerNumber}
                onInputChange={(e) => {
                  if (e.target.value.length <= 9) setPagerNumber(e.target.value);
                }}
              />
              <CtaButton
                label={'Get Numbers'}
                primary
                size={'small'}
                onClick={pagerNumber === '' ? openEmptyQueryWarning : getAvailableNumbers}
              />
            </>
          )}
          {status === 'create_pager' && (
            <>
              <div className={styles.dropdown}>
                <Dropdown
                  parentValue={
                    availableNumbers.length > 0 ? availableNumbers[0].label : 'Choose a number'
                  }
                  options={availableNumbers.slice(1)}
                  onChange={(_, i) => setSelectedPagerIndex(i)}
                />

                <div className={styles.chooseANumberText}>Choose a Number</div>
              </div>
              <CtaButton label={'Create Pager'} primary size={'small'} onClick={savePager} />
              <CtaButton label={'Cancel'} primary={false} size={'small'} onClick={cancelSave} />
            </>
          )}
        </div>
        {error && <span className={styles.error}>{error}</span>}
        <Toast
          message={'Please enter a valid query.'}
          type={'WARNING'}
          open={warning}
          onClose={() => {
            setWarning(false);
          }}
          autoHideDuration={1000}
        />
      </>
    );
  };

  return (
    <ViewContainer
      header={'Pager'}
      subHeader={'Create and assign a unique pager number for this user.'}
      onClose={onClose}
    >
      <div>
        {pagers.length > 0 && (
          <Table
            actionsOrder={[{ name: 'Delete', type: 'delete' }]}
            onRowDelete={onRowDelete}
            columns={columns}
            rows={pagers}
          />
        )}
        <CreatePager />
      </div>
    </ViewContainer>
  );
};
