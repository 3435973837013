import React, { useState } from 'react';
import BEM from '../../../bem';
import { DotsIndicator } from '../..';
import { ActionButton } from '../../../../types';

const classes = BEM.with('CollaborationEntityEditorActionButtons');

type EntityEditorActionButtonsProps = {
  isLoading: boolean;
  buttons: ActionButton[];
};

export default function EntityEditorActionButtons({
  buttons,
  isLoading,
}: EntityEditorActionButtonsProps) {
  const [loadingIndices, setLoadingIndices] = useState<{ [k: number]: boolean }>({});

  const actionClick = (button: ActionButton, index: number) => {
    if (loadingIndices[index]) return;
    const output = button.action();
    if (output && output?.then && typeof output?.then === 'function') {
      const outputAsPromise = output as Promise<void>;
      setLoadingIndices({ ...loadingIndices, [index]: true });
      outputAsPromise.then(() => setLoadingIndices({ ...loadingIndices, [index]: false }));
    }
  };

  return (
    <div className={classes('')}>
      {buttons
        .filter((button: ActionButton) => (button.isVisible ? button.isVisible() : true))
        .map((button: ActionButton, index: number) => (
          <div
            key={index}
            id={button.id}
            className={classes('button', {
              danger: button?.type === 'danger',
              disabled: button?.isDisabled && button?.isDisabled(),
            })}
            onClick={() => actionClick(button, index)}
          >
            {loadingIndices[index] && <DotsIndicator />}
            {!loadingIndices[index] && button.title}
          </div>
        ))}
      {isLoading && <div className={classes('loading-overlay-container')} />}
    </div>
  );
}
