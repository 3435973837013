import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Switch } from '../../WebComponents';
import { SaveRoleProps } from '../../../../types';
import BEM from '../../../bem';

import { actions } from '../../../../redux-stores';

const { setModal } = actions;
const classes = BEM.with('CollaborationEntityEditor');

type RoleEditorSwitchButtonsProps = {
  hasOwner?: boolean;
  openAssignment: boolean;
  ownerRequired: boolean;
  saveRole: (props: SaveRoleProps) => void;
};

export default function RoleEditorSwitchButtons({
  hasOwner,
  openAssignment,
  ownerRequired,
  saveRole,
}: RoleEditorSwitchButtonsProps) {
  const dispatch = useDispatch();

  const requestChangeOwnerRequired = () => {
    if (!ownerRequired && !hasOwner) {
      dispatch(setModal({ name: 'ownerRequired' }));
    } else {
      saveRole({ ownerRequired: !ownerRequired });
    }
  };

  return (
    <div className={classes('card-container')}>
      <Card header="Role assignment options">
        <div className={classes('switch-block')}>
          <div onClick={() => requestChangeOwnerRequired()} className={classes('switch-item')}>
            <span>Prevent empty assignment</span>
            <Switch
              checked={ownerRequired}
              onSwitch={requestChangeOwnerRequired}
              dataTestId="preventEmptyRole"
              ariaLabel="Toggle empty assignment prevention"
            />
          </div>
          <div
            onClick={() => saveRole({ openAssignment: !openAssignment })}
            className={classes('switch-item')}
          >
            <span>Controlled by Role Admins only</span>
            <Switch
              checked={!openAssignment}
              onSwitch={() => saveRole({ openAssignment: !openAssignment })}
              dataTestId="roleAdminOnly"
              ariaLabel="Toggle Role Admin only control"
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
