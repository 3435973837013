import React from 'react';
import { Button } from '@tigerconnect/web-component-library';
import BEM from '../../bem';

import ToolTip from '../../../widgets/messenger/components/ToolTip';
import { AppTypes } from '../../../models/enums';

const classes = BEM.with('RefreshButton');

type RefreshButtonProps = {
  currentAppSelected?: string;
  disabled?: boolean;
  refreshList: () => void;
  tooltipText: string;
};

type MobxProps = {};

const RefreshButton = ({
  currentAppSelected,
  disabled = false,
  refreshList,
  tooltipText,
}: MobxProps & RefreshButtonProps) => {
  const isBroadcastList = currentAppSelected === AppTypes.BROADCAST_LISTS;

  return (
    <ToolTip
      text={tooltipText || 'Click to refresh list'}
      isPatientFacing={!isBroadcastList}
      isBroadcastList={isBroadcastList}
      disable={disabled}
    >
      <div className={classes()} onClick={disabled ? () => {} : refreshList}>
        <Button
          icon="refresh"
          shape="circle"
          color={isBroadcastList ? 'secondary' : 'patient'}
          disabled={disabled}
        />
      </div>
    </ToolTip>
  );
};
export default RefreshButton;
