import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { ReactComponent as CheckSvg } from '../../images/selected-check.svg';
import { MenuItemList, MenuItem } from '../ContextMenu';
import { KEYMAP } from 'common/constants';

const REPEAT_SCHEDULES = ['Daily', 'Weekly', 'Bi-weekly', 'Monthly'];

const classes = BEM.with('RepeatScheduleContextMenu');

class RepeatScheduleContextMenu extends Component {
  static propTypes = {
    onRepeatScheduleSelected: PropTypes.func.isRequired,
    repeatSchedule: PropTypes.string,
    accessibilityMode: PropTypes.bool,
    buttonRef: PropTypes.any,
  };

  render() {
    const { repeatSchedule, onRepeatScheduleSelected, accessibilityMode, buttonRef } = this.props;
    const selectedIdx =
      REPEAT_SCHEDULES.findIndex((schedule) => !!(repeatSchedule && schedule === repeatSchedule)) ??
      0;

    return (
      <MenuItemList
        className={classes()}
        accessibilityMode={accessibilityMode}
        selectedOptionIndex={selectedIdx}
      >
        {REPEAT_SCHEDULES.map((schedule) => {
          const isSelected = !!(repeatSchedule && schedule === repeatSchedule);

          return (
            <MenuItem
              className={classes('menu-item', {
                selected: isSelected,
              })}
              key={schedule}
              onClick={() => onRepeatScheduleSelected(schedule)}
              onKeyDown={(e) => {
                if (e.key === KEYMAP.ESCAPE || e.key === KEYMAP.TAB) {
                  if (accessibilityMode) buttonRef?.focus();
                }
              }}
            >
              <p>{schedule}</p>
              {isSelected && <CheckSvg className={classes('check')} />}
            </MenuItem>
          );
        })}
      </MenuItemList>
    );
  }
}

export default observer(RepeatScheduleContextMenu);
