import React from 'react';

import BEM from '../../bem';
import DebugMobx from './DebugMobx';
import { mobxInjectSelect } from 'common/utils';

const classes = BEM.with('DebugUI');

type DebugUIProps = {};

type MobxProps = {
  showDebugUI: boolean;
};

function DebugUI({ showDebugUI }: DebugUIProps & MobxProps) {
  if (!showDebugUI) return null;
  return (
    <div className={classes()}>
      <DebugMobx />
    </div>
  );
}

export default mobxInjectSelect<DebugUIProps, MobxProps>({
  sessionStore: ['showDebugUI'],
})(DebugUI);
