import React from 'react';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import BEM from '../../../../../common/bem';
import { mobxInjectSelect, date } from '../../../../../common/utils';
import PfCallOrigins from '../../../../../models/enums/PfCallOrigins';
import { CallLog } from '../../../../../types';
import { ReactComponent as TimerSvg } from '../../../images/timer-icon.svg';
import { ReactComponent as ArrowIncomingSvg } from '../../../images/arrow-incoming-icon.svg';
import { ReactComponent as ArrowOutgoingSvg } from '../../../images/arrow-outgoing-icon.svg';
import { ReactComponent as ArrowMissedSvg } from '../../../images/broken-arrow-icon.svg';
import { KEYMAP } from 'common/constants';
import VideoCallButton from 'common/components/Call/VideoCallButton';

const { LOGS } = PfCallOrigins;
const classes = BEM.with('CallLog');

type CallLogEntryProps = {
  log: CallLog;
};

type MobxProps = {
  currentLog: CallLog;
  setCurrentLog: (log: CallLog) => void;
};

const callTypeAndDateTime = (log: CallLog) => {
  const Svg = log.isMissed ? ArrowMissedSvg : log.isOutgoing ? ArrowOutgoingSvg : ArrowIncomingSvg;
  const type = log.isMissed ? 'Missed' : log.isOutgoing ? 'Outgoing' : 'Incoming';
  const videoMobile = log.call.isVideo ? 'Video' : 'Mobile';
  return (
    <div className={classes('info-line', { isMissedCall: log.isMissed })}>
      <Svg />
      {`${type} ${videoMobile} ${moment(log.call.createdOn).format('MMM D, h:mmA')}`}
    </div>
  );
};

const groupDetails = (log: CallLog) => {
  const memberCount = get(log, 'target.entity.numMembers');
  return <div className={classes('call-members')}>{`${memberCount} Members`}</div>;
};

const patientRow = (log: CallLog) => {
  const {
    patientDetails,
    target: {
      entity: {
        metadata: { patientName },
      },
    },
  } = log;
  return (
    <div className={classes('patient-details')}>
      <div className={classes('name')}>{patientName}</div>
      {!isEmpty(patientDetails) && <div> {patientDetails.join(' | ')} </div>}
    </div>
  );
};

const CallLogEntry = ({ currentLog, log, setCurrentLog }: CallLogEntryProps & MobxProps) => {
  const {
    id,
    call,
    isContact,
    isGroup,
    isMissed,
    isOptedOut,
    isQuickCall,
    memberCount,
    target: { entity },
  } = log;
  const { members = [], metadata, displayName, token } = entity;
  const { patientContactId, patientId } = metadata;
  const callMembers = isGroup ? members : [token];
  const videoRecipient = isGroup ? (isContact ? patientContactId : patientId) : token;
  return (
    <div
      className={classes('call-log-entry', { isActive: id === get(currentLog, 'id') })}
      onClick={() => setCurrentLog(log)}
      onKeyDown={(e) => {
        if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
          setCurrentLog(log);
        }
      }}
    >
      <div className={classes('main')}>
        <div className={classes('info')}>
          <div className={classes('target')}>
            <div className={classes('name', { isOptedOut })}>{displayName}</div>
          </div>
          {callTypeAndDateTime(log)}
          {!isMissed && (
            <div className={classes('info-line')}>
              <TimerSvg /> {date.secondsToDurationFormat(call.duration)}
            </div>
          )}
        </div>
        {isOptedOut && <div className={classes('opt-out')}> Opted out via SMS </div>}
        {!isQuickCall && !isOptedOut && (
          <div className={classes('action')}>
            {videoRecipient && (
              <VideoCallButton
                isWhite={true}
                origin={LOGS}
                isInverseHover={true}
                maxMembers={4}
                entity={{
                  recipientId: videoRecipient,
                  recipientName: displayName,
                  members: callMembers.map((id: string) => ({ id })),
                }}
              />
            )}
          </div>
        )}
      </div>
      {isGroup && memberCount > 2 && groupDetails(log)}
      {isQuickCall ? (
        <div className={classes('qc-patient')}>Patient information not available</div>
      ) : (
        patientRow(log)
      )}
    </div>
  );
};

export default mobxInjectSelect<CallLogEntryProps, MobxProps>({
  callStore: ['currentLog', 'setCurrentLog'],
})(CallLogEntry);
