export default function parseUSPhoneNumber(number: string) {
  if (!number) return;
  if (number.length === 12) {
    const areaCode = number.substr(2, 3);
    const phoneNumber = number.substr(5, 3) + '-' + number.substr(8, 4);
    return '+1 (' + areaCode + ') ' + phoneNumber;
  } else if (number.length === 11) {
    const areaCode = number.substr(1, 3);
    const phoneNumber = number.substr(4, 3) + '-' + number.substr(7, 4);
    return '+1 (' + areaCode + ') ' + phoneNumber;
  } else {
    return number;
  }
}
