import React from 'react';
import classNames from 'classnames';

import styles from './ViewSubheader.module.css';

export interface ViewSubheaderProps {
  /**
   * Customize styles for ViewSubheaderProps
   */
  customStyles?: Record<string, string>;
  /**
   * Header contents
   */
  label: string;
}

export const ViewSubheader: React.FC<ViewSubheaderProps> = ({ customStyles = {}, label }) => {
  const currentStyles = { ...styles, ...customStyles };

  return <div className={classNames(currentStyles.root)}>{label}</div>;
};
