import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class PatientDetails extends Component {
  static propTypes = {
    className: PropTypes.string,
    patient: PropTypes.object.isRequired,
  };

  render() {
    const { className, patient } = this.props;
    const details = [];

    if (patient.MRN) {
      details.push('MRN ' + patient.MRN);
    }

    if (patient.DOB) {
      details.push('DOB ' + patient.DOB);
    }

    return <span className={classNames(className)}>{details.join(' | ')}</span>;
  }
}

export default PatientDetails;
