import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { pick } from 'ramda';
import shallowequal from 'shallowequal';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { FROZEN_EMPTY_OBJECT, email, phone } from '../../utils';
import RoleIndicator from '../Roles/RoleIndicator';
import { AvatarImage } from '..';
import { PresenceIndicator } from '../PresenceIndicator';
import { getUserInitials } from 'common/utils/getUserInitials';

const classes = BEM.with('AvatarImage');
const userAvatarClasses = BEM.with('UserAvatar');

const MODIFIERS_WITH_INITIALS = { initials: true };
const USER_PROPS = [
  '$notFound',
  '$placeholder',
  'avatarUrl',
  'displayName',
  'firstName',
  'lastName',
];

class UserAvatarNoPresence extends Component {
  static propTypes = {
    className: PropTypes.string,
    currentOrganizationId: PropTypes.string,
    indicatorSize: PropTypes.string,
    isExtendedAutoForwardOptionsEnabled: PropTypes.bool,
    isMuted: PropTypes.bool,
    shouldUseMinWidth: PropTypes.bool,
    showDndAfStatus: PropTypes.bool,
    showPresenceIndicator: PropTypes.bool,
    showRoleIndicator: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    user: propTypes.user.isRequired,
  };

  _initialsStyle = {};

  shouldComponentUpdate(nextProps) {
    return (
      USER_PROPS.some((prop) => this.userFields[prop] !== nextProps.user[prop]) ||
      this.props.isMuted !== nextProps.isMuted ||
      this.props.showDndAfStatus !== nextProps.showDndAfStatus
    );
  }

  render() {
    const {
      avatarUrl,
      className,
      currentOrganizationId,
      disableInitials = false,
      displayName,
      indicatorSize,
      isExtendedAutoForwardOptionsEnabled,
      isMuted = false,
      role,
      showDndAfStatus,
      showPresenceIndicator,
      showRoleIndicator = false,
      size,
      user,
      ...restProps
    } = this.props;
    const isDndAfEnabledForCurrentOrg = isExtendedAutoForwardOptionsEnabled
      ? user?.profileByOrganizationId &&
        user?.profileByOrganizationId[currentOrganizationId]?.dndAutoForwardEntities?.length > 0
      : user?.profileByOrganizationId &&
        user?.profileByOrganizationId[currentOrganizationId]?.autoForwardReceiverIds?.length > 0;

    const appendChildren = showRoleIndicator ? (
      <RoleIndicator role={role} />
    ) : showPresenceIndicator ? (
      <PresenceIndicator
        showDndAfStatus={showDndAfStatus || isDndAfEnabledForCurrentOrg}
        userId={user.id}
        dnd={user.dnd}
        size={indicatorSize}
      />
    ) : null;

    this.userFields = pick(USER_PROPS, user);

    let initials;
    let modifiers = FROZEN_EMPTY_OBJECT;
    let overrideChildren = null;
    const canShowInitials = !disableInitials && (typeof size !== 'number' || size > 20);

    if (
      canShowInitials &&
      !user.removedFromOrg &&
      !user.$placeholder &&
      !user.$notFound &&
      !user.avatarUrl &&
      user.displayName &&
      !phone.isPhone(user.displayName) &&
      !email.isEmail(user.displayName) &&
      (initials = getUserInitials(user))
    ) {
      modifiers = MODIFIERS_WITH_INITIALS;
      const newInitialsStyle = {};
      if (typeof size === 'number') {
        newInitialsStyle.fontSize = size < 50 ? Math.round(size * 0.47) : 24;
      }
      if (!shallowequal(this._initialsStyle, newInitialsStyle)) {
        this._initialsStyle = newInitialsStyle;
      }

      overrideChildren = (
        <span className={classes('initials')} style={this._initialsStyle}>
          {initials}
        </span>
      );
    }

    let entityType = 'user';

    const isPatientOrContact = user.isPatient || user.isPatientContact;
    if (isPatientOrContact) {
      entityType = (user.patientContact || user.patient).smsOptedOut
        ? 'singleProviderSmsOptedOut'
        : 'patientMessaging';
    }

    return (
      <AvatarImage
        {...restProps}
        appendChildren={appendChildren}
        avatarUrl={avatarUrl || user.avatarUrl}
        className={classNames(className, userAvatarClasses())}
        displayName={displayName || user.displayName}
        entityType={entityType}
        forceDefault={isPatientOrContact || user.removedFromOrg}
        isDisabled={user.isDisabled}
        isMuted={isMuted}
        modifiers={modifiers}
        size={size}
      >
        {overrideChildren}
      </AvatarImage>
    );
  }
}

export default observer(UserAvatarNoPresence);
