import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Switch } from '../../WebComponents';
import BEM from '../../../bem';
import { ReactComponent as RoleTransitionDisabledIcon } from '../../../images/role-transition-disabled-icon.svg';
import { actions } from '../../../../redux-stores';
import { Role, SaveRoleProps } from '../../../../types';

const { setModal } = actions;
const classes = BEM.with('CollaborationEntityEditor');

type RoleEditorHandoffProps = {
  excludePrivateGroup: boolean;
  isAdmin: boolean;
  role: Role;
  roleTransition: string;
  saveRole: (props: SaveRoleProps) => void;
};

export default function RoleEditorHandoff({
  excludePrivateGroup,
  isAdmin,
  roleTransition,
  role,
  saveRole,
}: RoleEditorHandoffProps) {
  const dispatch = useDispatch();
  const roleTransitionAll = roleTransition === 'all';
  const roleTransitionIndividual = roleTransition === 'individual';
  const isRoleTransitionEnabledForRole = role?.metadata?.role_transition;
  const isHandoffSwitchOn = roleTransitionIndividual && !isRoleTransitionEnabledForRole;

  const shouldDisplayPrivateGroupExclusionSwitch =
    roleTransitionAll || (roleTransitionIndividual && isRoleTransitionEnabledForRole);
  const isPrivateGroupExclusionSwitchOn = role?.metadata?.exclude_private_group === true;

  const roleHandoffToggle = () => {
    if (isAdmin) {
      dispatch(setModal({ name: 'rtuWarningScreen' }));
    }
  };

  const rolePrivateGroupExclusion = () => {
    saveRole({
      doesRoleTransitionExcludePrivateGroups: !excludePrivateGroup,
    });
  };

  const privateGroupExclusionToggler = (
    <div onClick={rolePrivateGroupExclusion} className={classes('switch-item')}>
      <span>Hand off private groups at shift change</span>
      <Switch dataTestId={'privateGroupExclusion'} checked={isPrivateGroupExclusionSwitchOn} />
    </div>
  );

  const allRolesHandoffDisabledDisplay = (
    <div
      className={classes('switch-item', { rolesHandoffIconIsDisplayed: roleTransition === 'all' })}
    >
      <RoleTransitionDisabledIcon className={classes('rtu-icon')} style={{ marginRight: 10 }} />
      <span>Conversation handoff at shift change disabled</span>
    </div>
  );

  const individuallyToggleableRoleHandoffDisplay = (
    <div onClick={roleHandoffToggle} className={classes('switch-item')}>
      <span style={{ marginRight: 2 }}>
        Hand off Role conversations to next Role owner at shift change
      </span>
      <Switch dataTestId={'individualRoleHandoff'} checked={isHandoffSwitchOn} />
    </div>
  );

  return (
    <div className={classes('card-container')}>
      <Card header="Role Handoff Options">
        <div className={classes('switch-block')}>
          {roleTransition === 'all' && allRolesHandoffDisabledDisplay}
          {roleTransition === 'individual' && individuallyToggleableRoleHandoffDisplay}
          {shouldDisplayPrivateGroupExclusionSwitch && privateGroupExclusionToggler}
        </div>
      </Card>
    </div>
  );
}
