import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { get } from 'lodash';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { MessageSubTypes } from '../../../models/enums/MessageSubTypes';
import { MessageContent } from './MessageContent';
import {
  AcknowledgeButton,
  AutoForwardMessageHeader,
  AutomatedMessageHeader,
  EscalationMessageHeader,
  MessageStatusFailedRetry,
  PatientNetworkMessageHeader,
  PriorityMessageHeader,
  ScheduleMessageHeader,
  RoleAutomatedMessageHeader,
  SmsAutomatedOptHeader,
} from './';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('MessageBubble');

class MessageBubble extends Component {
  static propTypes = {
    getEntityData: PropTypes.func,
    isDropdownActive: PropTypes.bool,
    isEscalated: PropTypes.bool,
    isModal: PropTypes.bool,
    message: propTypes.message.isRequired,
    multiSelectMode: PropTypes.bool.isRequired,
    multiSelectable: PropTypes.bool.isRequired,
    setMessageBubble: PropTypes.func,
    openMessageActionMenu: PropTypes.func,
  };

  render() {
    const { getEntityData, isDropdownActive, isEscalated, isModal, message, multiSelectable } =
      this.props;
    const {
      autoForwardedFrom,
      autoForwardedTo,
      availabilityDndAutoForwardedTo,
      isAutoForwarded,
      isAvailabilityDndAutoForwardedTo,
      isForwarded,
      isForwardedFromPatientNetwork,
      isOutgoing,
      priority,
      sender,
      senderStatus,
      subType,
      sortNumber,
    } = message;

    const isVwrCallInvite = subType === MessageSubTypes.VIRTUAL_WAITING_ROOM_CALL_INVITE;
    const isAutomatedMessage = subType === MessageSubTypes.AUTOMATED_MESSAGE;
    const isRoleAutomatedMessage =
      subType === MessageSubTypes.ROLE_AWAY_NOTIFICATION && !isForwarded;
    const isSystemMessage = subType === MessageSubTypes.SYSTEM;
    const direction = isOutgoing && !isSystemMessage && !isVwrCallInvite ? 'OUTGOING' : 'INCOMING';
    const isPatientOrContactMessage = sender && (sender.isPatient || sender.isPatientContact);
    const isSmsOptMessage =
      subType === MessageSubTypes.SMS_OPT_OUT_NOTIFICATION ||
      subType === MessageSubTypes.SMS_OPT_IN_NOTIFICATION;
    const isScheduleMessage = subType === MessageSubTypes.SCHEDULE_MESSAGE;
    const isVwrBotMessage = subType === MessageSubTypes.CHATBOT_MESSAGE;
    const isDndAutoForwardMessage = !!autoForwardedTo;
    const isAutoForwardedFromMessage = isAutoForwarded && !!autoForwardedFrom;
    const isAutoForwardMessage =
      isAvailabilityDndAutoForwardedTo || isDndAutoForwardMessage || isAutoForwardedFromMessage;
    const getAutoForwardEntityType = availabilityDndAutoForwardedTo?.type?.split('entity:')[1];
    const autoForwardToDisplayName =
      isAvailabilityDndAutoForwardedTo &&
      !!availabilityDndAutoForwardedTo?.groupMembers &&
      availabilityDndAutoForwardedTo?.groupMembers > 1
        ? 'Multiple Recipients'
        : isAvailabilityDndAutoForwardedTo
        ? getEntityData(getAutoForwardEntityType, availabilityDndAutoForwardedTo?.token)
            ?.displayName
        : autoForwardedTo?.displayName;

    const contentClassName = classNames(
      classes('content-status'),
      classes({
        isAutoForwardMessage,
        direction,
        isDropdownActive,
        isEscalated,
        isForwardedFromPatientNetwork,
        isPatientOrContactMessage,
        isRoleAutomatedMessage,
        isSmsOptMessage,
        priority,
        senderStatus,
      })
    );

    let acknowledgeFragment, headerFragment, retryFragment;

    if (priority === 'HIGH') {
      headerFragment = (
        <PriorityMessageHeader
          autoForwardToDisplayName={autoForwardToDisplayName}
          message={message}
        />
      );
    } else if (isEscalated) {
      headerFragment = <EscalationMessageHeader message={message} />;
      if (!isOutgoing) {
        acknowledgeFragment = <AcknowledgeButton message={message} />;
      }
    } else if (isForwardedFromPatientNetwork) {
      headerFragment = <PatientNetworkMessageHeader message={message} />;
    } else if (isAutomatedMessage) {
      headerFragment = <AutomatedMessageHeader message={message} />;
    } else if (isRoleAutomatedMessage) {
      headerFragment = <RoleAutomatedMessageHeader message={message} />;
    } else if (isAutoForwardMessage) {
      headerFragment = (
        <AutoForwardMessageHeader
          autoForwardToDisplayName={autoForwardToDisplayName}
          message={message}
        />
      );
    } else if (isSmsOptMessage) {
      headerFragment = <SmsAutomatedOptHeader message={message} />;
    } else if (isScheduleMessage) {
      headerFragment = <ScheduleMessageHeader message={message} />;
    } else if (isVwrBotMessage) {
      const title = get(message, 'metadata[0].payload.title', 'Thank you');
      headerFragment = <ScheduleMessageHeader message={message} headerText={title} />;
    } else if (isVwrCallInvite) {
      headerFragment = <ScheduleMessageHeader message={message} headerText={'UPDATE'} />;
    }

    if (senderStatus === 'FAILED' || senderStatus === 'RETRYING') {
      retryFragment = <MessageStatusFailedRetry message={message} />;
    }

    return (
      <div data-test-id="Message Bubble" className={classes()} onClick={this._onClick}>
        <div
          className={classes({ direction, forwarded: isForwarded, priority })}
          ref={this._setMessageBubble}
        >
          <div
            className={contentClassName}
            role="button"
            aria-labelledby={`${sortNumber}-relation ${sortNumber}-name ${sortNumber}-body ${sortNumber}-time ${sortNumber}-expiry ${sortNumber}-status`}
            onKeyDown={(e) => {
              if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
                e.preventDefault();
                e.stopPropagation();
                this.props.openMessageActionMenu?.(this.props.message);
              }
            }}
            aria-haspopup="menu"
            aria-disabled={false}
            aria-expanded={isDropdownActive}
          >
            {headerFragment}
            <MessageContent isModal={isModal} message={message} multiSelectable={multiSelectable} />
            {acknowledgeFragment}
          </div>
        </div>
        {retryFragment}
      </div>
    );
  }

  _onClick = (e) => {
    // Prevent ConversationMessages.onClick from firing; otherwise it prevents text from being selected
    const { multiSelectMode } = this.props;
    if (!multiSelectMode) e.stopPropagation();
  };

  _setMessageBubble = (ref) => {
    const { setMessageBubble } = this.props;
    setMessageBubble && setMessageBubble(ref);
  };
}

export default observer(MessageBubble);
