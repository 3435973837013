import React, { useEffect, useState } from 'react';
import { timeAgoFormatted } from 'time-left-ago';
import BEM from '../../bem';
import { Visitor, VisitStatuses, ReasonKey } from '../../../types';
import TCClient from '../../../client';
import { useVWRContext } from '../../../contexts/VirtualWaitingRoom';
import UnreadBadgeCount from '../UnreadBadgeCount';
import { Timestamp } from '../';

import { ReactComponent as ReasonDot } from '../../images/icon-reason-dot.svg';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('VisitorCard');

type VisitCardProps = {
  handleClick: () => void;
  isSelected: boolean;
  visitor: Visitor;
  visitsFilterStatus?: string;
  accessibilityMode?: boolean;
};

export default function VisitorCard({
  handleClick,
  isSelected,
  visitsFilterStatus,
  visitor,
  accessibilityMode = false,
}: VisitCardProps) {
  const {
    assignedStaffAccountId,
    conversation,
    reason,
    reasonKey,
    status,
    visitorFullName,
    visitorJoinedAt,
  } = visitor;
  const [assignmentName, setAssignmentName] = useState('');
  const {
    state: {
      mobxProps: { currentOrganizationId },
      selectedRoom,
    },
  } = useVWRContext();

  let visitReasons: undefined | { [k in ReasonKey]?: string };
  if (selectedRoom?.visitReasons) {
    visitReasons = selectedRoom.visitReasons.reduce((memo, k) => {
      return {
        ...memo,
        [k.reasonKey]: k.reasonValue,
      };
    }, {} as { [k in ReasonKey]?: string });
  }

  const unreadCount = conversation
    ? conversation.unreadCount + conversation.unreadPriorityCount
    : 0;

  useEffect(() => {
    const getAssignmentUser = async (userId: string) => {
      if (userId) {
        try {
          const user = await TCClient.users.find(userId, { organizationId: currentOrganizationId });
          if (user) setAssignmentName(user.displayName);
        } catch (e) {
          console.error(e);
        }
      }
    };
    getAssignmentUser(assignedStaffAccountId);
  }, [assignedStaffAccountId, currentOrganizationId]);

  const isNewStatus = status === 'NEW';
  const assignment = assignmentName || 'UNASSIGNED';
  const isUnassigned = !assignedStaffAccountId;
  const isCompletedStatusStyle =
    status === 'COMPLETED' && visitsFilterStatus !== VisitStatuses.COMPLETED;

  return (
    <div
      className={classes('', { isSelected, isCompletedStatusStyle })}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (accessibilityMode && (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE)) {
          e.preventDefault();
          e.stopPropagation();
          handleClick();
        }
      }}
    >
      <div className={classes('line')}>
        <div className={classes('status', { isNewStatus })}>{VisitStatuses[status]}</div>
        <div className={classes('name')}>{visitorFullName}</div>
        {conversation?.lastMessage && (
          <Timestamp
            value={conversation.lastMessage.createdAt}
            className={classes('last-message-timestamp')}
          />
        )}
      </div>
      <div className={classes('badge-count')}>
        {unreadCount > 0 && <UnreadBadgeCount count={unreadCount} />}
      </div>
      <div className={classes('line')}>
        <div className={classes('time')}>{timeAgoFormatted(visitorJoinedAt)}</div>
        {visitReasons && (
          <div className={classes('visit-type-container')}>
            <ReasonDot className={classes('reason-dot--' + reasonKey)} />
            <div className={classes('visit-type')}>{reason?.toUpperCase()}</div>
          </div>
        )}
        <div data-test-id={'vwr-assignment'} className={classes('assignment', { isUnassigned })}>
          {assignment}
        </div>
      </div>
    </div>
  );
}
