import React from 'react';
import { observer } from 'mobx-react';
import BEM from '../../../common/bem';
import { MessageSubTypes } from '../../../models/enums/MessageSubTypes';

const classes = BEM.with('SmsAutomatedOptHeader');

function SmsAutomatedOptHeader({ message }) {
  const { subType } = message;

  let text;
  if (subType === MessageSubTypes.SMS_OPT_OUT_NOTIFICATION) {
    text = 'Opted Out Via Sms';
  } else if (subType === MessageSubTypes.SMS_OPT_IN_NOTIFICATION) {
    text = 'Opted Back In';
  }

  return (
    <div className={classes()}>
      <div className={classes('text')}>{text}</div>
    </div>
  );
}

export default observer(SmsAutomatedOptHeader);
