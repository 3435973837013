import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import BEM from '../../../../common/bem';
import DotsIndicator from '../../../../common/components/DotsIndicator';
import MarkdownWrapper from '../../../../common/components/MarkdownWrapper';
import { FROZEN_EMPTY_ARRAY, attachmentType, mobxInjectSelect } from '../../../../common/utils';
import propTypes from '../../../../common/propTypes';
import {
  Banner,
  EntityName,
  MessageStatus,
  PatientDetails,
  Timestamp,
  TypingStatusIndicator,
  UnreadBadgeCount,
} from '../../../../common/components';
import { RoleStatus } from './';
import { PatientReferenceInfo } from 'common/components/PatientContext';

const classes = BEM.with('ConversationItemDetails');
const PADDING_PX = 11 + 6;
const BORDER_PX = 1;
const TILE_SPACE_PX = PADDING_PX + BORDER_PX;
const ROW_PX = 19;
const ADDITIONAL_PATIENT_PILL_HEIGHT = 25;

const buildSenderName = (sender, useDisplayName) => {
  if (!sender) return '';

  if (useDisplayName) {
    if (!sender.displayName) return '';
    return sender.displayName.split(' ')[0];
  }

  return sender.firstName || '';
};

class ConversationItemDetails extends Component {
  static propTypes = {
    conversation: propTypes.conversation.isRequired,
    currentRoles: propTypes.roleArray.isRequired,
    getTypersForCounterParty: PropTypes.func.isRequired,
    isGroupAlertsVCAllowed: PropTypes.bool.isRequired,
    isMentionsEnabled: PropTypes.bool,
    isPatientCare: PropTypes.bool,
    isPinned: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    richTextFormat: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isPatientCare: false,
  };

  static getHeight(props) {
    const { middleRows } = ConversationItemDetails.getTile(props);
    const addAdditionalPatientPillSpace = props.conversation.counterParty.patientContextId;
    if (addAdditionalPatientPillSpace) {
      return TILE_SPACE_PX + middleRows.length * ROW_PX + ADDITIONAL_PATIENT_PILL_HEIGHT;
    } else {
      return TILE_SPACE_PX + middleRows.length * ROW_PX;
    }
  }

  static getSenderRole({ counterParty, currentRoles }) {
    const { $entityType, groupType, memberIds, p2pSender } = counterParty;
    if ($entityType !== 'group' || currentRoles.length === 0) return null;
    if (groupType === 'ROLE_P2P')
      return p2pSender && p2pSender.$entityType === 'role' ? p2pSender : null;

    const applicableRoles = currentRoles.filter(
      (role) => memberIds && memberIds.includes(role.botUserId)
    );
    if (applicableRoles.length === 0) return null;

    return applicableRoles[0];
  }

  static getTagDetails({ p2pRecipient, p2pRecipientType, groupType, counterParty }) {
    const isP2pRole = p2pRecipient && p2pRecipientType === 'role';
    const tag = isP2pRole ? p2pRecipient.tag : counterParty.tag;
    const canShow = isP2pRole
      ? !tag?.isDefault
      : ['ACTIVATED_TEAM', 'INTRA_TEAM'].includes(groupType);

    return {
      tag,
      showTagName: tag && canShow,
    };
  }

  static getTile(props) {
    const {
      conversation,
      currentRoles,
      getTypersForCounterParty,
      hasGroupAlert,
      isDisplayNameEnabled,
      isMentionsEnabled,
      isPinned,
      isSelected,
      richTextFormat,
    } = props;
    const {
      counterParty,
      counterPartyType,
      inProgressEscalationCount,
      isMuted,
      lastMessage,
      unreadCount,
      unreadMentionMessages,
      unreadPriorityCount,
    } = conversation;
    const {
      escalationExecution,
      groupType,
      p2pRecipient,
      p2pRecipientType,
      p2pSenderType,
      memberCount,
      patientDetails,
      patientContextId,
      patientContextDetails,
      $entityType: entityType,
    } = counterParty;
    const isForum = groupType === 'FORUM';
    const isEscalationGroup = groupType === 'ESCALATION';
    const isPatientCareGroup = groupType === 'PATIENT_CARE';
    const isTeam = counterPartyType === 'team';

    const typers = getTypersForCounterParty(counterParty);
    const showTypers = typers && typers.length > 0;
    const senderRole = ConversationItemDetails.getSenderRole({
      counterParty,
      currentRoles,
    });
    const recipient = p2pRecipient || counterParty;
    const showLastMessage = !isTeam;
    const showLastMessageStatus = lastMessage && lastMessage.isOutgoing;
    const { tag, showTagName } = ConversationItemDetails.getTagDetails({
      p2pRecipient,
      p2pRecipientType,
      groupType,
      counterParty,
    });

    const banners = [];
    if (hasGroupAlert && counterParty?.alertMessage?.alertNotification?.tag_value) {
      banners.push({
        text: counterParty.alertMessage.alertNotification.tag_value,
        color: counterParty.alertMessage.alertNotification.tag_color,
      });
    }
    if (isForum) banners.push('forum');
    if (isPinned) banners.push('pinned');
    if (unreadPriorityCount > 0) banners.push('priority');
    if (isMentionsEnabled && unreadMentionMessages) banners.push('mentioned');
    if (isEscalationGroup && escalationExecution) banners.push(escalationExecution.status);
    if (inProgressEscalationCount > 0 && !isEscalationGroup) banners.push('Escalated');

    const middleRows = [];
    const bannerCount = banners.length;

    if (bannerCount > 0) {
      const bannerRows = [];
      let currentRow = [];

      for (let idx = 0; idx < bannerCount; idx++) {
        const type = banners[idx];
        if (idx > 0 && idx % 2 === 0) {
          bannerRows.push(currentRow);
          currentRow = [];
        }
        currentRow.push(
          <div key={type} className={classes('banner')}>
            <Banner type={type} />
          </div>
        );
      }
      bannerRows.push(currentRow);

      for (const bannerRow of bannerRows) {
        middleRows.push({
          className: classes('banner-container'),
          content: bannerRow,
        });
      }
    }

    middleRows.push({
      className: classes('header'),
      content: (
        <span aria-label="Roster Tile Name" className={classes('counter-party-name', { isForum })}>
          <EntityName entity={recipient} isMuted={isPatientCareGroup && isMuted} />
        </span>
      ),
    });

    if (showTagName) {
      middleRows.push({
        className: classes('tag', { isSelected }),
        content: <span className={classes('tag-name')}>{tag.name}</span>,
      });
    }

    if (isPatientCareGroup) {
      middleRows.push({
        className: classes('patient-details'),
        content: <PatientDetails patient={patientDetails} />,
      });
    }

    if (isTeam) {
      middleRows.push({
        className: classes('team-member-count'),
        content: <span>{pluralize('Member', counterParty.memberCount, true)}</span>,
      });
    }

    if (showTypers) {
      middleRows.push(
        groupType === 'GROUP'
          ? {
              className: classes('typers'),
              content: (
                <TypingStatusIndicator
                  counterParty={counterParty}
                  maxTypers={1}
                  size={8}
                  typers={typers}
                  color={'#828282'}
                />
              ),
            }
          : {
              className: classes('typing-dots'),
              content: <DotsIndicator color={'#828282'} size={10} speed={0.5} />,
            }
      );
    } else if (showLastMessage) {
      const {
        attachments = FROZEN_EMPTY_ARRAY,
        body,
        patientCareCard = FROZEN_EMPTY_ARRAY,
        sender,
      } = lastMessage || {};

      const senderName = buildSenderName(sender, isDisplayNameEnabled);
      const showLastMessageSender =
        entityType === 'group' &&
        p2pRecipientType !== 'role' &&
        p2pSenderType !== 'role' &&
        memberCount > 2;

      const lastMessageBody = showLastMessageSender && senderName ? `${senderName}: ${body}` : body;
      let lastMessageText =
        attachments && attachments.length > 0 && !body
          ? `Attachment: ${attachmentType(attachments[0].contentType)}`
          : patientCareCard.length > 0
          ? `Alert: ${patientCareCard[0].value}`
          : lastMessageBody;

      if (richTextFormat && lastMessageText) {
        lastMessageText = <MarkdownWrapper text={lastMessageText} />;
      }

      middleRows.push({
        className: classes('last-message-body'),
        content: (
          <div key="last-message-body" className={classes('last-message-body')}>
            {lastMessageText}
          </div>
        ),
      });
    }

    if (showLastMessageStatus) {
      middleRows.push({
        className: classes('last-message-status'),
        content: <MessageStatus isClickable={false} message={lastMessage} />,
      });
    } else if (!isPatientCareGroup) {
      middleRows.push({ className: classes('filler'), content: null });
    }

    const rightRows = [
      lastMessage && (
        <Timestamp value={lastMessage.createdAt} className={classes('last-message-timestamp')} />
      ),
      unreadCount > 0 && (
        <UnreadBadgeCount className={classes('unread-badge')} count={unreadCount} />
      ),
    ];

    while (rightRows.length < middleRows.length - 1) {
      rightRows.push(null);
    }

    if (senderRole) {
      rightRows.push(<RoleStatus className={classes('role-status')} role={senderRole} />);
    } else {
      rightRows.push(null);
    }

    if (entityType === 'group' && patientContextDetails) {
      middleRows.push({
        className: classes('patient-reference-pill'),
        content: (
          <PatientReferenceInfo
            patientReferenceId={patientContextId}
            patientReference={patientContextDetails}
            type={'pill'}
          />
        ),
      });
    }

    return { middleRows, rightRows, isForum };
  }

  render() {
    const {
      conversation,
      currentRoles,
      getTypersForCounterParty,
      isDisplayNameEnabled,
      isGroupAlertsVCAllowed,
      isMentionsEnabled,
      isPatientCare,
      isPinned,
      isSelected,
      richTextFormat,
    } = this.props;

    const hasGroupAlert = isGroupAlertsVCAllowed && conversation?.featureService === 'group_alerts';
    const { middleRows, rightRows, isForum } = ConversationItemDetails.getTile({
      conversation,
      currentRoles,
      getTypersForCounterParty,
      hasGroupAlert,
      isDisplayNameEnabled,
      isMentionsEnabled,
      isPinned,
      isSelected,
      richTextFormat,
    });

    const renderedRows = [];
    const rowCount = middleRows.length;
    const seen = {};

    for (let idx = 0; idx < rowCount; idx++) {
      const { className, content } = middleRows[idx];
      if (seen[className] === undefined) {
        seen[className] = 0;
      } else {
        seen[className]++;
      }
      const key = `${className}-${seen[className]}`;

      renderedRows.push(
        <div key={key} className={classes('row', { isForum })}>
          <div className={className}>{content}</div>
          {rightRows[idx]}
        </div>
      );
    }

    return <div className={classes({ isPatientCare, isForum })}>{renderedRows}</div>;
  }
}

const WrappedComponent = mobxInjectSelect({
  localStore: ['richTextFormat'],
  messengerStore: ['isDisplayNameEnabled', 'isMentionsEnabled', 'isGroupAlertsVCAllowed'],
  roleStore: ['currentRoles'],
  typingStatusStore: ['getTypersForCounterParty'],
})(ConversationItemDetails);

WrappedComponent.getHeight = ConversationItemDetails.getHeight;

export default WrappedComponent;
