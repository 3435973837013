import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../../utils';
import BEM from '../../bem';
import { Dropdown } from '../WebComponents';
import type { Response } from '../CustomResponseList/CustomResponseList';
import QuickReplyCustomResponseList from './QuickReplyCustomResponseList';

const classes = BEM.with('QuickReplyPortal');

enum KeyCode {
  ESCAPE = 'Escape',
}

type QuickReplyPortalProps = {
  isOpen: boolean;
  setMessage: (message: string) => void;
  togglePortal: (v: boolean) => void;
};

type MobxProps = {
  openModal: (m: string) => void;
  getPreferences: () => Promise<Response[]>;
};

const QuickReplyPortal = ({
  getPreferences,
  isOpen,
  openModal,
  setMessage,
  togglePortal,
}: QuickReplyPortalProps & MobxProps) => {
  const [quickReplies, setQuickReplies] = useState<Response[]>([]);

  useEffect(() => {
    const getQuickReplies = async () => {
      const replies = await getPreferences();
      setQuickReplies(replies);
    };
    isOpen ? getQuickReplies() : setQuickReplies([]);
  }, [isOpen, getPreferences]);

  const openEditor = () => {
    togglePortal(false);
    openModal('quickReply');
  };

  const selectMessage = (message: string) => {
    setMessage(message);
    togglePortal(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === KeyCode.ESCAPE) {
      togglePortal(false);
    }
  };

  return (
    <div className={classes()} onKeyDown={handleKeyDown}>
      {isOpen && (
        <Dropdown triggerHandler={() => togglePortal(!isOpen)}>
          <div className={classes('dropdown-container')}>
            <div className={classes('title')}>Quick Reply</div>
            <QuickReplyCustomResponseList
              responses={quickReplies}
              onResponseClick={selectMessage}
              onEditButtonClick={openEditor}
            />
          </div>
        </Dropdown>
      )}
    </div>
  );
};

QuickReplyPortal.propTypes = {
  getPreferences: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  togglePortal: PropTypes.func.isRequired,
};

export default mobxInjectSelect<QuickReplyPortalProps, MobxProps>({
  modalStore: ['openModal'],
  messengerStore: ['getPreferences'],
})(QuickReplyPortal);
