import { ServerValueEnum } from '../../utils/enum';

const PatientCareCardActionType = new ServerValueEnum(
  {
    ADD_MEMBERS: { serverValue: 'add_members' },
  } as const,
  { defaultValue: 'NA' }
);

export default PatientCareCardActionType;
