import { format } from 'date-fns';
import { isCurrentUser, renderBody, renderAvatar, getStoreModel } from './helper';
import BEM from 'common/bem';
import { User } from 'types';
import { ReactComponent as AttachmentIcon } from 'common/images/attach_file.svg';
import { ReactComponent as KeywordResultArrow } from 'common/images/keyword-result-arrow.svg';
import mobxInjectSelect from 'common/utils/mobxInjectSelect';

const classes = BEM.with('KeywordSearchSideBar');

type KeywordMessageBubbleMobXProps = {
  getEntityData: (entityType: string, id: string) => User;
  setCurrentConversationId: (id: string) => void;
};

interface KeywordMessageBubbleProps {
  body: string;
  conversationId?: string;
  createdTime: string;
  currentUserId: string;
  highlight: {
    body: string;
    attachment_name: string | null;
  };
  isGroup: boolean;
  isSecondLevel?: boolean;
  messageId?: string;
  myRoleIds: string[];
  senderDisplayName?: string;
  senderToken: string;
}

const KeywordMessageBubble = ({
  body,
  createdTime,
  currentUserId,
  getEntityData,
  highlight,
  isGroup,
  isSecondLevel,
  myRoleIds,
  senderDisplayName,
  senderToken,
}: KeywordMessageBubbleProps & KeywordMessageBubbleMobXProps) => {
  const isMe = isCurrentUser(currentUserId, senderToken, myRoleIds);
  const sender = getStoreModel('user', senderToken, getEntityData) as User;

  return (
    <div className={classes('message-container')}>
      {(!isSecondLevel || (isSecondLevel && isGroup)) && (
        <div className={classes('avatar-container')}>
          {!isMe && <div className={classes('avatar')}>{renderAvatar(sender)}</div>}
        </div>
      )}
      <div
        className={classes('message-bubble', {
          isCurrentUser: isMe,
        })}
      >
        {isGroup && !isMe && (
          <div className={classes('user-name')}>
            {senderDisplayName || sender?.displayName || 'Name not available'}
          </div>
        )}
        {renderBody(highlight.body, body)}
        {highlight.attachment_name && (
          <div className={classes('attachment-container')}>
            <AttachmentIcon />
            <div
              className={classes('attachment-name')}
              dangerouslySetInnerHTML={{ __html: highlight.attachment_name }}
            />
          </div>
        )}
        <div className={classes('message-created-time')}>
          {format(new Date(createdTime), 'M/d/yy h:mm a')}
        </div>
      </div>
      <div className={classes('arrow')}>
        <KeywordResultArrow />
      </div>
    </div>
  );
};

export default mobxInjectSelect<KeywordMessageBubbleProps, KeywordMessageBubbleMobXProps>({
  entityStore: ['getEntityData'],
})(KeywordMessageBubble);
