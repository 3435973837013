import React, { Component } from 'react';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';

import { ReactComponent as CloseSvg } from '../../images/down-arrow.svg';
import { ContextMenu } from '../ContextMenu';
import { LogoutButtonMenu } from './';

const classes = BEM.with('LogoutButton');
const MENU_OFFSET_Y = 13;
const MENU_OFFSET_X = -24;

class LogoutButton extends Component {
  render() {
    return (
      <div>
        <div className={classes()}>
          <div className={classes('text')} onClick={this._openLogoutModal}>
            Logout
          </div>
          <ContextMenu
            event="click"
            theme="vertical"
            position="bottomright"
            offsetY={MENU_OFFSET_Y}
            offsetX={MENU_OFFSET_X}
            menu={<LogoutButtonMenu />}
          >
            <div className={classes('svg-box')}>
              <div className={classes('svg')}>
                <CloseSvg />
              </div>
            </div>
          </ContextMenu>
        </div>
      </div>
    );
  }

  _openLogoutModal = () => {
    this.props.openModal('logoutModal');
  };
}

export default mobxInjectSelect({
  modalStore: ['openModal'],
})(LogoutButton);
