import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import TextareaAutoSize from 'tt-react-textarea-autosize';
import { DeliveryMethods } from '../../../models/enums/DeliveryMethods';
import ToolTip from '../../../widgets/messenger/components/ToolTip';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { attachments, mobxInjectSelect } from '../../utils/';
import { AttachmentIcon } from '../';

import { ReactComponent as AttachmentClipSvg } from '../../images/attachment-clip.svg';
import { ReactComponent as CloseButtonSvg } from '../../images/close-icon.svg';
import TemplateContextMenu from './TemplateContextMenu';

const { SMS } = DeliveryMethods;
const classes = BEM.with('MessageInputs');

class MessageInputs extends Component {
  static propTypes = {
    downloadTemplateFile: PropTypes.func.isRequired,
    isEditing: PropTypes.bool,
    loadTemplate: PropTypes.func.isRequired,
    messageDeliveryMethod: PropTypes.string,
    scheduledMessage: propTypes.scheduledMessageState.isRequired,
    setIsTemplateChanged: PropTypes.func,
    setNextButtonDisabled: PropTypes.func,
    smsMessageLengthLimit: PropTypes.number,
    updateScheduledMessage: PropTypes.func.isRequired,
    accessibilityMode: PropTypes.bool,
  };

  static defaultProps = {
    isEditing: false,
    setIsTemplateChanged: () => null,
  };

  state = {
    isAttachmentDownloadable: this.props.isEditing,
  };

  render() {
    const { isAttachmentDownloadable } = this.state;
    const { scheduledMessage, messageDeliveryMethod, smsMessageLengthLimit, accessibilityMode } =
      this.props;
    const { attachments: addedAttachments, body, selectedTemplate } = scheduledMessage;
    const characterLimitExceeded = body.length > smsMessageLengthLimit;
    let attachmentFragment, dropzoneRef;

    if (addedAttachments.length > 0) {
      const attachment = addedAttachments[0];
      const { name: fileName, type: fileType } = attachment;

      attachmentFragment = (
        <div className={classes('attachment-container')}>
          {isAttachmentDownloadable ? (
            <ToolTip text="Download File" isPatientFacing>
              {this.renderAttachment({ fileName, fileType })}
            </ToolTip>
          ) : (
            this.renderAttachment({ fileName, fileType })
          )}
          <button
            className={classes('reset-attachment-btn')}
            data-test-id={'reset-attachment-button'}
            onClick={this._resetAttachment}
          >
            <CloseButtonSvg />
          </button>
        </div>
      );
    } else if (messageDeliveryMethod !== SMS) {
      attachmentFragment = (
        <Dropzone
          accept={attachments.allContentTypes.join(',')}
          className={classes('attachment-btn-container')}
          disablePreview
          disableClick
          onDropAccepted={this._setAttachment}
          ref={(node) => {
            dropzoneRef = node;
          }}
        >
          {() => (
            <button
              className={classes('attachment-btn')}
              data-test-id={'add-attachment-button'}
              onClick={() => dropzoneRef.open()}
            >
              <AttachmentClipSvg />
              Add attachment
            </button>
          )}
        </Dropzone>
      );
    }

    return (
      <div className={classes()}>
        <TemplateContextMenu
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={this._setSelectedTemplate}
          shouldFilterSms={messageDeliveryMethod === SMS}
          accessibilityMode={accessibilityMode}
        />
        <div
          className={classes('message-section', {
            deliveryTypeSms: messageDeliveryMethod === SMS,
          })}
        >
          <TextareaAutoSize
            aria-label="Schedule Message"
            autoComplete="off"
            className={classes('message-input', {
              placeholder: !body,
              deliveryTypeSms: messageDeliveryMethod === SMS,
            })}
            data-test-id={'message-input'}
            name="body"
            onChange={this._setMessage}
            placeholder="Enter message here..."
            value={body}
          />
          {messageDeliveryMethod === SMS && (
            <div
              className={classes('messageLengthSubtext', {
                lengthExceeded: characterLimitExceeded,
              })}
            >
              {body.length} / {smsMessageLengthLimit}
            </div>
          )}
        </div>
        {attachmentFragment}
      </div>
    );
  }

  _downloadAttachmentFile = () => {
    const { isAttachmentDownloadable } = this.state;

    if (!isAttachmentDownloadable) return;

    const {
      downloadScheduledMessageFile,
      downloadTemplateFile,
      isEditing,
      scheduledMessage: { attachments, id, selectedTemplate },
    } = this.props;

    const fileName = attachments[0].name;
    if (isEditing) {
      downloadScheduledMessageFile({ scheduleId: id, fileName });
    } else {
      downloadTemplateFile({ templateId: selectedTemplate.id, fileName });
    }
  };

  renderAttachment = ({ fileType, fileName }) => {
    return (
      <div onClick={this._downloadAttachmentFile} className={classes('attachment-download')}>
        <AttachmentIcon fileType={fileType} className={classes('file-icon')} />
        {fileName}
      </div>
    );
  };

  _resetAttachment = () => {
    const { updateScheduledMessage } = this.props;

    this._updateActiveSaveButton();
    this.setState({ isAttachmentDownloadable: false });
    updateScheduledMessage('attachments', []);
  };

  _updateActiveSaveButton = () => {
    const { isEditing, setNextButtonDisabled } = this.props;

    if (isEditing) {
      setNextButtonDisabled(false);
    }
  };

  _setMessage = (e) => {
    const { updateScheduledMessage } = this.props;
    this._updateActiveSaveButton();
    updateScheduledMessage('body', e.target.value);
  };

  _setAttachment = ([file]) => {
    const { updateScheduledMessage } = this.props;
    this._updateActiveSaveButton();
    this.setState({ isAttachmentDownloadable: false });
    updateScheduledMessage('attachments', [file]);
  };

  _setSelectedTemplate = async (template) => {
    const { loadTemplate, noneSelectedTemplate, setIsTemplateChanged, updateScheduledMessage } =
      this.props;
    this._updateActiveSaveButton();
    updateScheduledMessage('selectedTemplate', template);

    if (template.id === noneSelectedTemplate.id) {
      updateScheduledMessage('body', '');
      updateScheduledMessage('attachments', []);
      return;
    }

    const loadedTemplate = await loadTemplate(template.id);
    updateScheduledMessage('body', loadedTemplate.body);
    setIsTemplateChanged(true);

    if (loadedTemplate.hasAttachment) {
      this.setState({ isAttachmentDownloadable: true });
      updateScheduledMessage('attachments', [
        {
          name: loadedTemplate.attachmentName,
          type: loadedTemplate.attachmentType,
        },
      ]);
    }
  };
}

export default mobxInjectSelect({
  patientAdminStore: ['loadTemplate'],
  scheduleMessageStore: [
    'downloadScheduledMessageFile',
    'downloadTemplateFile',
    'scheduledMessage',
    'updateScheduledMessage',
    'noneSelectedTemplate',
    'smsMessageLengthLimit',
  ],
  sessionStore: ['accessibilityMode'],
})(MessageInputs);
