import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Switch } from '../WebComponents';
import BEM from '../../bem';
import mobxInjectSelect from '../../utils/mobxInjectSelect';

const classes = BEM.with('ExpertSettings');

class ExpertSettings extends Component {
  static propTypes = {
    clearNewMessagesOnConvClick: PropTypes.bool.isRequired,
    markAsReadOnRosterClick: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    setClearNewMessagesOnConvClick: PropTypes.func.isRequired,
    setMarkAsReadOnRosterClick: PropTypes.func.isRequired,
    setScrollbarsElement: PropTypes.func.isRequired,
  };

  render() {
    const { clearNewMessagesOnConvClick, markAsReadOnRosterClick } = this.props;

    return (
      <div className={classes()} ref={this._setScrollbarsElement}>
        <Card header="EXPERT SETTINGS">
          <div className={classes('info')}>
            <div className={classes('info-header')}>
              MARK CONVERSATION AS READ WHEN CLICKING ROSTER TILE
            </div>
            <div className={classes('switch')}>
              <Switch
                checked={markAsReadOnRosterClick}
                onSwitch={this._toggleMarkAsReadOnRosterClick}
                dataTestId="markAsReadOnRosterClick"
              />
            </div>
          </div>
          <div className={classes('info')}>
            <div className={classes('info-header')}>
              CLEAR NEW MESSAGES INDICATOR WHEN CLICKING CONVERSATION
            </div>
            <div className={classes('switch')}>
              <Switch
                checked={clearNewMessagesOnConvClick}
                onSwitch={this._toggleClearNewMessagesOnConvClick}
                dataTestId="clearNewMessagesOnConvClick"
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }

  _setScrollbarsElement = (ref) => {
    this.props.setScrollbarsElement(ref, 'ExpertSettings');
  };

  _toggleMarkAsReadOnRosterClick = () => {
    const { openModal, markAsReadOnRosterClick, setMarkAsReadOnRosterClick } = this.props;
    setMarkAsReadOnRosterClick(!markAsReadOnRosterClick);
    openModal('success');
  };

  _toggleClearNewMessagesOnConvClick = () => {
    const { openModal, clearNewMessagesOnConvClick, setClearNewMessagesOnConvClick } = this.props;
    setClearNewMessagesOnConvClick(!clearNewMessagesOnConvClick);
    openModal('success');
  };
}

export default mobxInjectSelect({
  localStore: [
    'clearNewMessagesOnConvClick',
    'markAsReadOnRosterClick',
    'setClearNewMessagesOnConvClick',
    'setMarkAsReadOnRosterClick',
  ],
  modalStore: ['openModal'],
  profileStore: ['setScrollbarsElement'],
})(ExpertSettings);
