type PreserveGroupConversationProps = {
  clickable: boolean;
  onClick: () => void;
  children: JSX.Element;
  className: string;
  userName: string;
};

const GroupMemberEntity = ({
  clickable,
  onClick,
  children,
  userName,
  className,
}: PreserveGroupConversationProps) => {
  if (clickable)
    return (
      <button
        className={className}
        aria-label={`${userName} member - Press Enter or Space to send a message`}
        onClick={onClick}
      >
        {children}
      </button>
    );
  return (
    <div className={className} aria-label={`${userName} member`}>
      {children}
    </div>
  );
};

export default GroupMemberEntity;
