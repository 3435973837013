import { Message, Role } from '../../../types';

export function buildMessageRecipientList({
  currentGroupMessage,
  currentRoles,
}: {
  currentGroupMessage: Message;
  currentRoles: Role[];
}) {
  const myRoleBotIds = currentRoles.map(({ botUserId }) => botUserId);
  if (!currentGroupMessage) return [];

  const { group, statusesPerRecipient } = currentGroupMessage;
  if (!statusesPerRecipient || !group || !group.members) return [];
  const { members: groupMembers } = group;

  const userIdsAlsoInARole = [];
  for (const { botRole, isRoleBot } of groupMembers) {
    if (!(isRoleBot && botRole)) continue;

    const { memberIds } = botRole;
    if (!memberIds) continue;

    for (const memberId of memberIds) {
      userIdsAlsoInARole.push(memberId);
    }
  }

  const messageRecipients = [];

  for (const status of statusesPerRecipient) {
    const { userId, user } = status;
    if (myRoleBotIds.includes(userId) || !user) continue;
    if (!user.shouldDisplay) continue;
    if (userIdsAlsoInARole.includes(userId) || !groupMembers.includes(user)) continue;

    messageRecipients.push(status);
  }

  return messageRecipients;
}
