import React from 'react';
import classNames from 'classnames';
import BEM from '../../../bem';

import { ReactComponent as XIcon } from '../../../images/x.svg';

const classes = BEM.with('CollaborationTags');

type TagPillProps = {
  id: string;
  text: string;
  colorHex?: string;
  dismissHandler?: (id: string) => void;
};

function TagPill({ id, text, colorHex = undefined, dismissHandler = undefined }: TagPillProps) {
  return (
    <div
      className={classNames(classes('container'), classes('pill-container'))}
      data-test-id={`Pill ${id}`}
    >
      {colorHex && (
        <div className={classes('color-symbol')} style={{ backgroundColor: colorHex }} />
      )}
      <div className={classes('content')} data-test-id={`Pill ${text}`}>
        {text}
      </div>
      {dismissHandler && (
        <div
          className={classes('close-button')}
          onClick={() => dismissHandler(id)}
          data-test-id={`Close ${text}`}
        >
          <XIcon className={classes('close-pill-icon')} />
        </div>
      )}
    </div>
  );
}

export default TagPill;
