import React, { useCallback, useEffect, useState } from 'react';

import Tooltip from '../../../widgets/messenger/components/ToolTip';

import styles from './FormattingButtons.module.css';

type FormattingButtonsProps = {
  insertElementsAtCursor: (elms: (Element | ChildNode)[], cursorNode?: ChildNode) => void;
};

const FormattingButtonsContentEditable = ({ insertElementsAtCursor }: FormattingButtonsProps) => {
  const formatText = useCallback(
    (formatter: '*' | '**' | '```') => {
      const selObj = window.getSelection();
      const selRange = selObj?.getRangeAt(0);
      if (!selRange) return;
      const fragment = selRange.cloneContents();
      const left = document.createTextNode(formatter);
      const right = document.createTextNode(formatter);
      insertElementsAtCursor(
        [left, ...Array.from(fragment.childNodes), right],
        fragment.childNodes.length ? right : left
      );
    },
    [insertElementsAtCursor]
  );

  const formatItalic = useCallback(() => formatText('*'), [formatText]);
  const formatBold = useCallback(() => formatText('**'), [formatText]);
  const formatCode = useCallback(() => formatText('```'), [formatText]);

  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (e.metaKey) {
        if (e.key === 'b') {
          e.preventDefault();
          formatBold();
        } else if (e.key === 'i') {
          e.preventDefault();
          formatItalic();
        } else if (e.key === 'e') {
          e.preventDefault();
          formatCode();
        }
      }
    };
    window.addEventListener('keydown', keydown);
    return () => {
      window.removeEventListener('keydown', keydown);
    };
  }, [formatBold, formatCode, formatItalic, formatText]);

  const [showButtons, setShowButtons] = useState(false);

  return (
    <div className={`${styles.container} ${showButtons ? styles.expandedContainer : ''}`}>
      <Tooltip location="top" text="Show rich text formatting menu">
        <button
          onClick={() => setShowButtons(!showButtons)}
          className={`${styles.formattingIcon} ${showButtons ? styles.expandedFormattingIcon : ''}`}
        >
          Aa
        </button>
      </Tooltip>
      {showButtons && (
        <div className={styles.buttons}>
          <div>
            <Tooltip location="top" text="Add bold text (cmd + b)">
              <button onClick={formatBold} className={`${styles.icon} ${styles.bold}`}>
                B
              </button>
            </Tooltip>
          </div>
          <div>
            <Tooltip location="top" text="Add italic text (cmd + i)">
              <button onClick={formatItalic} className={`${styles.icon} ${styles.italic}`}>
                I
              </button>
            </Tooltip>
          </div>
          <div>
            <Tooltip location="top" text="Insert code (cmd + e)">
              <button onClick={formatCode} className={`${styles.icon} ${styles.code}`}>
                <span className={styles.codeLeft}>{'<'}</span>
                <span className={styles.codeRight}>{'>'}</span>
              </button>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormattingButtonsContentEditable;
