import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from '../WebComponents';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { mobxInjectSelect } from '../../utils';
import { DotsIndicator } from '../';
import { RemoveChangesButton } from './';

const classes = BEM.with('ProfileStatus');

class NewProviderStatus extends Component {
  static propTypes = {
    currentUser: propTypes.user,
    isAvailabilityFeatureFlagEnabled: PropTypes.bool,
    loadingUserStatus: PropTypes.bool.isRequired,
    scrollToElement: PropTypes.func.isRequired,
    setScrollbarsElement: PropTypes.func.isRequired,
    currentOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    update: PropTypes.func.isRequired,
  };

  state = {
    draftStatus: {
      value: this.props.currentUser.status,
      isDirty: false,
    },
  };

  render() {
    const { draftStatus } = this.state;
    const { loadingUserStatus } = this.props;

    const header = 'STATUS SETTINGS';

    const dotsFragment = (
      <div className={classes('dots-container-text')}>
        <DotsIndicator className={classes('dots')} size={10} color={'#00a945'} />
      </div>
    );

    const statusFragment = (
      <div className={classes('status-info')}>
        <div className={classes('sub-header')}>STATUS</div>
        <form className={classes('form')} onSubmit={this._submit('status')}>
          <input
            className={classes('input', { isDirty: draftStatus.isDirty })}
            onChange={this._onChange('draftStatus')}
            onFocus={this._onFocus}
            placeholder="Tell others what's going on"
            tabIndex={2}
            type="text"
            value={draftStatus.value}
            onKeyDown={this._onStatusEscape}
          />
        </form>
        {draftStatus.isDirty && (
          <div className={classes('buttons')}>
            <RemoveChangesButton removeChanges={this._removeChangesStatus} />
            <Button label="SAVE" outline onClick={this._submitStatus} />
          </div>
        )}
      </div>
    );

    return (
      <div className={classes()} ref={this._setScrollbarsElement}>
        <Card header={header}>{loadingUserStatus ? dotsFragment : statusFragment}</Card>
      </div>
    );
  }

  _submit = (field) => {
    return (e) => {
      e.preventDefault();
      if (field === 'status') this._submitStatus();
    };
  };

  _onChange = (field) => {
    return (e) => this._updateDraft(field, e.target.value, true);
  };

  _onFocus = () => {
    const { scrollToElement } = this.props;
    scrollToElement('StatusSettings', { offset: 25, shouldSpring: false });
  };

  _updateDraft = (field, value, isDirty) => {
    this.setState({ [field]: { value, isDirty } });
  };

  _submitStatus = async () => {
    const { draftStatus } = this.state;

    await this.props.update({
      status: draftStatus.value,
      loadingComponent: 'loadingUserStatus',
    });
    this._updateDraft('draftStatus', draftStatus.value, false);
  };

  _onStatusEscape = (e) => {
    if (e.key !== 'Escape') return;
    e.stopPropagation();
    const { draftStatus } = this.state;

    if (!draftStatus.isDirty) return;
    this._removeChangesStatus();
  };

  _removeChangesStatus = () => {
    this._updateDraft('draftStatus', this.props.currentUser.status, false);
  };

  _setScrollbarsElement = (ref) => {
    this.props.setScrollbarsElement(ref, 'StatusSettings');
  };
}

export default mobxInjectSelect({
  messengerStore: ['currentOrganization', 'isAvailabilityFeatureFlagEnabled'],
  profileStore: ['scrollToElement', 'setScrollbarsElement'],
  sessionStore: ['currentUser'],
  userStore: ['loadingUserStatus', 'update'],
})(NewProviderStatus);
