import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import VideoCallMemberStatus from '../../models/enums/VideoCallMemberStatus';
import BEM from '../bem';
import propTypes from '../propTypes';
import { mobxInjectSelect } from '../utils';
import { EntityAvatar } from './';

const classes = BEM.with('VideoCallMember');
const AVATAR_SIZE = 16;

class VideoCallMember extends Component {
  static propTypes = {
    canInvite: PropTypes.bool.isRequired,
    currentCall: PropTypes.object,
    currentUserId: PropTypes.string.isRequired,
    inviteMember: PropTypes.func.isRequired,
    member: propTypes.user,
  };

  static defaultProps = {
    member: {},
  };

  render() {
    const { canInvite, currentCall, currentUserId, member } = this.props;
    if (!member) return;

    const { displayName } = member;
    const status = get(currentCall, `membersStatuses.${member.id}`, 'Unknown');
    const inviteEligibleStatus = ['LEFTCALL', 'MISSED', 'DECLINED', 'NOTCONNECTED'].map(
      (s) => VideoCallMemberStatus[s]
    );

    let nameFragment;
    if (member.isPatient) {
      nameFragment = 'Patient';
    } else if (member.isPatientContact) {
      nameFragment = 'Contact';
    } else if (member.id === currentUserId) {
      nameFragment = 'You';
    }

    return (
      <div className={classes()}>
        <div className={classes('icon')}>
          <EntityAvatar
            entity={member.isRoleBot && member.botRole ? member.botRole : member}
            indicatorSize={'TINY'}
            showPresenceIndicator={true}
            size={AVATAR_SIZE}
          />
        </div>
        <div className={classes('name-status-container')}>
          <div className={classes('name-container')}>
            {`${displayName}${nameFragment ? ` (${nameFragment})` : ''}`}
          </div>
          <div
            className={classes('status', {
              connected: status === VideoCallMemberStatus.CONNECTED,
            })}
          >
            {status}
          </div>
        </div>
        {canInvite && inviteEligibleStatus.includes(status) && (
          <button className={classes('invite-button')} onClick={this.inviteMember}>
            Invite
          </button>
        )}
      </div>
    );
  }

  inviteMember = () => {
    const { inviteMember, member } = this.props;
    inviteMember(member);
  };
}

export default mobxInjectSelect({
  sessionStore: ['currentUserId'],
  callStore: ['currentCall', 'inviteMember'],
})(VideoCallMember);
