import React from 'react';
import { flatten, get } from 'lodash';
import BEM from '../../common/bem';
import { mobxInjectSelect } from '../../common/utils';
import { User } from '../../types';

const classes = BEM.with('PatientNetworkDisplayName');

type PatientNetworkDisplayNameProps = {
  descriptorClass?: string;
  users: User[];
};

type MobxProps = {
  currentUserId: string;
};

function displayUser(
  user: User,
  index: number,
  currentUserId: string,
  descriptorClass: string,
  length: number
) {
  const names = ['firstName', 'lastName'].map((name: string) => get(user, name));
  let descriptor = '',
    comma = '';
  if (user.id === currentUserId) descriptor = 'You';
  if (user.isPatient) descriptor = 'Patient';
  if (user.isPatientContact) descriptor = get(user, 'patientContact.relation');
  if (length > 1) names.pop();
  if (descriptor) descriptor = ` (${descriptor})`;
  if (index < length - 1) comma = ', ';
  return (
    <div key={user.id} className={classes('name')}>
      {names.join(' ')}
      {descriptor && <span className={descriptorClass || ''}>{descriptor}</span>}
      {comma}
    </div>
  );
}

function PatientNetworkDisplayName({
  currentUserId,
  descriptorClass = '',
  users,
}: PatientNetworkDisplayNameProps & MobxProps) {
  const entities = flatten(Array(users));
  return (
    <div className={classes()}>
      {entities.map((user: User, index: number) =>
        displayUser(user, index, currentUserId, descriptorClass, entities.length)
      )}
    </div>
  );
}

export default mobxInjectSelect<PatientNetworkDisplayNameProps, MobxProps>({
  sessionStore: ['currentUserId'],
})(PatientNetworkDisplayName);
