import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mobxInjectSelect from '../common/utils/mobxInjectSelect';
import { SignInForm } from '../common/components/SignInForm';
import LoadingSpinner from '../common/components/LoadingSpinner';
import SingleScreenSignInForm from '../common/components/SingleScreenSignInForm';
import AppSelector from '../widgets/messenger/components/AppSelector';
import SessionExpired from '../widgets/messenger/components/SessionExpired';
import handleLocationChange from './utils/handleLocationChange';

class Router extends Component {
  static propTypes = {
    enableClientRender: PropTypes.func.isRequired,
    externalLogoutUrl: PropTypes.string,
    isIdpSessionExpired: PropTypes.bool.isRequired,
    isIdpUser: PropTypes.bool.isRequired,
    isMessengerDisplayed: PropTypes.bool.isRequired,
    isSessionExpired: PropTypes.bool.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
  };

  _isHandlingLocationChange = false;
  _shouldHandleLocationChange = false;

  componentDidMount() {
    const { enableClientRender } = this.props;
    enableClientRender();
    window.addEventListener('beforeunload', this._beforeUnload);
  }

  componentDidUpdate() {
    const { isIdpUser, isSignedIn, externalLogoutUrl } = this.props;

    if (this._shouldHandleLocationChange && !this._isHandlingLocationChange) {
      this._isHandlingLocationChange = true;

      handleLocationChange({
        isIdpUser,
        url: externalLogoutUrl,
        wasPreviouslySignedIn: !isSignedIn && this._wasSignedIn,
      });
    }
  }

  render() {
    const {
      isIdpSessionExpired,
      isMessengerDisplayed,
      isSessionExpired,
      isSignedIn,
      isSingleScreenSignIn,
      isFHIRLaunchSession,
      didFHIRLaunchFail,
    } = this.props;
    this._wasSignedIn = this._wasSignedIn || isSignedIn;

    const isSignedOut = !isSessionExpired && !isSignedIn && this._wasSignedIn;
    this._shouldHandleLocationChange = isSignedOut;
    if (this._shouldHandleLocationChange) {
      return null;
    }

    if (isSessionExpired || isIdpSessionExpired) {
      return <SessionExpired />;
    } else if (
      isFHIRLaunchSession &&
      !didFHIRLaunchFail &&
      (!isSignedIn || !isMessengerDisplayed)
    ) {
      return <LoadingSpinner loadingText={'Logging you in to TigerConnect...'} />;
    } else if (isSignedIn && isMessengerDisplayed) {
      return <AppSelector />;
    } else if (isSingleScreenSignIn) {
      return <SingleScreenSignInForm />;
    } else {
      return <SignInForm />;
    }
  }

  _beforeUnload = (event) => {
    const { isMessengerDisplayed, isSignedIn } = this.props;
    if (!isMessengerDisplayed || !isSignedIn) return;

    const message = 'Are you sure you want to leave the page?\n\nYou may have unsaved data.';
    event.returnValue = message;
    return message;
  };
}

export default mobxInjectSelect({
  messengerStore: ['enableClientRender', 'isMessengerDisplayed'],
  sessionStore: [
    'externalLogoutUrl',
    'isIdpSessionExpired',
    'isIdpUser',
    'isSessionExpired',
    'isSignedIn',
    'isSingleScreenSignIn',
  ],
  launchStore: ['isFHIRLaunchSession', 'didFHIRLaunchFail'],
})(Router);
