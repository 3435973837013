import React, { useState } from 'react';

import { mobxInjectSelect, calculateDownloadSpeed } from '../utils';
import BEM from '../bem';

import logo from '../images/tc-logo.gif';
import darkLogo from '../images/tc-logo-dark.gif';

const LOGO_SIZE = 1742983;
const DARK_LOGO_SIZE = 328240;

const classes = BEM.with('LoadingSpinner');

type LoadingSpinnerProps = {
  loadingText: string;
};

type MobxProps = {
  setUsersInternetSpeed: (speed: number) => void;
  isDarkModeOn: boolean;
  hasSetUsersInternetSpeed: boolean;
  usersLoadingSpinnerCacheRand: number;
};
const LoadingSpinner = ({
  loadingText: propsLoadingText,
  setUsersInternetSpeed,
  isDarkModeOn,
  hasSetUsersInternetSpeed,
  usersLoadingSpinnerCacheRand,
}: LoadingSpinnerProps & MobxProps) => {
  const [imgStartTime] = useState(performance.now());
  const loadingText = propsLoadingText ? propsLoadingText : 'Loading...';
  const logoSrc = isDarkModeOn ? darkLogo : logo;
  const onImgLoad = () => {
    if (hasSetUsersInternetSpeed) return;
    const { speedMbps } = calculateDownloadSpeed({
      downloadSize: isDarkModeOn ? DARK_LOGO_SIZE : LOGO_SIZE,
      startTime: imgStartTime,
    });
    setUsersInternetSpeed(speedMbps);
  };

  return (
    <div className={classes()}>
      <div className={classes('container')}>
        <div className={classes('icon')}>
          <img
            src={logoSrc + `?rand=${usersLoadingSpinnerCacheRand}`}
            alt={loadingText}
            onLoad={onImgLoad}
          />
        </div>
        <div className={classes('text')}>{loadingText}</div>
      </div>
    </div>
  );
};

export default mobxInjectSelect<LoadingSpinnerProps, MobxProps>({
  messengerStore: ['isDarkModeOn'],
  trackerStore: [
    'setUsersInternetSpeed',
    'hasSetUsersInternetSpeed',
    'usersLoadingSpinnerCacheRand',
  ],
})(LoadingSpinner);
