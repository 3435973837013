import React from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import { ReactComponent as DropDownSvg } from '../../../images/pa-dropdown.svg';
import propTypes from '../../../propTypes';
import { PatientBroadcastListRowDetails } from './';
import AccessibleList from 'common/components/AccessibleList';
import { triggerButtonActionOnKeyPress } from 'common/utils/accessibility';
import { useAppSelector } from 'redux-stores';

const classes = BEM.with('PatientBroadcastListDetails');

const PatientBroadcastListDetails = ({
  groupedMembers,
  groupedPendingMembers,
  isLoading,
  queryValue,
  removePendingMember,
  resetPendingMembers,
  saveInProgress,
  savePendingMembers,
  scrollContainerRef,
  selectedMemberIds,
  sortBy,
  sortOrder,
  toggleSelectedMember,
  toggleSort,
  listHeaderRef,
}) => {
  let resultsFragment;
  const accessibilityMode = useAppSelector((state) => state.ui.accessibilityMode);

  if (groupedPendingMembers.length === 0 && groupedMembers.length === 0 && !isLoading) {
    if (queryValue) {
      resultsFragment = (
        <div className={classes('empty-broadcastList')}>
          No results found for &quot;{queryValue}&quot;
        </div>
      );
    } else {
      resultsFragment = <div className={classes('empty-broadcastList')}>No members available.</div>;
    }
  } else {
    resultsFragment = (
      <React.Fragment>
        <AccessibleList
          focusableClasses={['.tc-PatientBroadcastListRowDetails--selectable']}
          focusStart="first"
          accessibilityMode={accessibilityMode}
        >
          {groupedPendingMembers.length > 0 && (
            <div className={classes('pending-members')}>
              {groupedPendingMembers.map((members) => (
                <div className={classes('members-group')} key={members[0].id}>
                  {members.map((member) => (
                    <PatientBroadcastListRowDetails
                      isPending
                      isSingle={members.length === 1}
                      key={member.id}
                      member={member}
                      removePendingMember={removePendingMember}
                    />
                  ))}
                </div>
              ))}
              <div className={classes('save-cancel-buttons')}>
                <button className={classes('cancel-button')} onClick={resetPendingMembers}>
                  CANCEL
                </button>
                <button
                  className={classes('save-button')}
                  disabled={saveInProgress}
                  onClick={savePendingMembers}
                >
                  {saveInProgress ? 'SAVING' : 'SAVE'}
                </button>
              </div>
            </div>
          )}
          {groupedMembers.length > 0 && (
            <div className={classes('members')}>
              {groupedMembers.map((members) => (
                <div className={classes('members-group')} key={members[0].id}>
                  {members.map((member, idx) => (
                    <PatientBroadcastListRowDetails
                      isFirstEntry={idx === 0}
                      isSelected={selectedMemberIds.includes(member.id)}
                      key={member.id}
                      member={member}
                      isSingle={members.length === 1}
                      toggleSelectedMember={toggleSelectedMember}
                    />
                  ))}
                </div>
              ))}
            </div>
          )}
        </AccessibleList>
      </React.Fragment>
    );
  }

  return (
    <div className={classes()}>
      <div className={classes('list')}>
        <div className={classes('list-header')}>
          <div className={classes('list-item-checkbox')} />
          <div
            ref={listHeaderRef}
            tabIndex={0}
            role="button"
            className={classes('list-item-label')}
            onClick={() => toggleSort('patient_display_name')}
            onKeyDown={(event) =>
              triggerButtonActionOnKeyPress({
                event,
                action: () => toggleSort('patient_display_name'),
              })
            }
          >
            PATIENT/CONTACT
            <DropDownSvg
              aria-hidden={true}
              className={classes('sort-arrow', {
                isDesc: sortOrder === 'desc',
                sortBy: sortBy === 'patient_display_name',
              })}
            />
          </div>
          <div className={classes('list-item-mrn')}>MRN</div>
          <div className={classes('list-item-dob')}>DOB</div>
          <div className={classes('list-item-gender')}>GENDER</div>
          <div className={classes('list-item-added-by')}>ADDED BY</div>
          <div className={classes('list-item-date-added')}>DATE ADDED</div>
        </div>
        <div className={classes('lists-container')} ref={scrollContainerRef}>
          {resultsFragment}
        </div>
      </div>
    </div>
  );
};

PatientBroadcastListDetails.propTypes = {
  groupedMembers: PropTypes.arrayOf(propTypes.userArray).isRequired,
  groupedPendingMembers: PropTypes.arrayOf(propTypes.userArray).isRequired,
  isLoading: PropTypes.bool.isRequired,
  queryValue: PropTypes.string.isRequired,
  removePendingMember: PropTypes.func.isRequired,
  resetPendingMembers: PropTypes.func.isRequired,
  saveInProgress: PropTypes.bool.isRequired,
  savePendingMembers: PropTypes.func.isRequired,
  scrollContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  selectedMemberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  toggleSelectedMember: PropTypes.func.isRequired,
  toggleSort: PropTypes.func.isRequired,
};

export default PatientBroadcastListDetails;
