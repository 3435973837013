import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../common/bem';
import mobxInjectSelect from '../../../common/utils/mobxInjectSelect';

const classes = BEM.with('GeneralStatusBar');

class GeneralStatusBar extends Component {
  static propTypes = {
    generalStatus: PropTypes.string,
  };

  render() {
    const { generalStatus } = this.props;

    if (!generalStatus) return null;

    return (
      <div className={classes()}>
        <div className={classes('message')}>{generalStatus}</div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  messengerStore: ['generalStatus'],
})(GeneralStatusBar);
