import React, { useEffect, useRef } from 'react';
import Scrollbars from 'tt-react-custom-scrollbars';

import LeftNav from './LeftNav';
import MainView from './MainView';
import OrganizationsDropdown from './OrganizationsDropdown';
import ToastWrapper from './ToastWrapper';

import styles from './index.module.css';
import { DotsIndicator } from 'common/components';
import { mobxInjectSelect } from 'common/utils';
import { actions, thunk, useAppDispatch, useAppSelector } from 'redux-stores';

const { setUsersPageView } = actions;
const { initiateAdminSession } = thunk;

type AdminProps = {
  currentOrganizationId: string;
};

type MobxProps = {
  registerAdminNewLDUserKey: (orgId: string) => Promise<void>;
  switchApp: (app: string) => void;
};

function Admin({
  currentOrganizationId,
  registerAdminNewLDUserKey,
  switchApp,
}: AdminProps & MobxProps) {
  const dispatch = useAppDispatch();
  const isAdminInitiated = useAppSelector((state) => state.admin.isInitiated);
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);

  useEffect(() => {
    initiateAdminSession(dispatch, currentOrganizationId, switchApp);
  }, [currentOrganizationId, dispatch, switchApp]);

  useEffect(() => {
    return () => {
      dispatch(setUsersPageView({ selectedUsersPageView: 'users' }));
    };
  }, [dispatch]);

  useEffect(() => {
    registerAdminNewLDUserKey(selectedOrganizationId);
  }, [registerAdminNewLDUserKey, selectedOrganizationId]);

  const _onScrollCallBackRef = useRef<(e: React.UIEvent<HTMLElement>) => void>(null);

  function _onScroll(e: React.UIEvent<HTMLElement>) {
    _onScrollCallBackRef?.current?.(e);
  }

  return (
    <>
      {isAdminInitiated ? (
        <div className={styles.root}>
          <Scrollbars onScroll={_onScroll} autoHide autoHideTimeout={750}>
            <div className={styles.container}>
              <OrganizationsDropdown />
              <div className={styles.viewContainer}>
                <LeftNav />
                <MainView onScrollCallBackRef={_onScrollCallBackRef} />
              </div>
            </div>
          </Scrollbars>
          <ToastWrapper />
        </div>
      ) : (
        <div className={styles.loadingContainer}>
          <DotsIndicator />
        </div>
      )}
    </>
  );
}

export default mobxInjectSelect<AdminProps, MobxProps>({
  featureStore: ['registerAdminNewLDUserKey'],
  messengerStore: ['switchApp'],
})(Admin);
