import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { ReactComponent as EscalateSvg } from '../images/icon-escalation-outlined.svg';
import Modal from './Modal';

const classes = BEM.with('EscalationEnabledModal');
const IMG_HEADER = {
  image: EscalateSvg,
  fill: '#fff',
  imgClassHeader: classes('img'),
};

class EscalationEnabledModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  render() {
    const { closeModal, isOpen } = this.props;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        hasCloseButton={false}
        header={'Escalation Enabled'}
        headerClass={classes('header')}
        imgHeader={IMG_HEADER}
        isOpen={isOpen}
        onRequestClose={closeModal}
        size={'medium-small'}
      >
        <div className={classes('body-text')}>
          This message will be escalated according to the escalation policy.
        </div>
        <div
          aria-label="Escalation Modal Got It Button"
          onClick={closeModal}
          className={classes('button')}
        >
          GOT IT
        </div>
      </Modal>
    );
  }
}

export default mobxInjectSelect({
  modalStore: ['openModal'],
})(EscalationEnabledModal);
