import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Switch } from '../WebComponents';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { ReactComponent as DndIcon } from '../../images/dnd-icon.svg';
import { mobxInjectSelect } from '../../utils';
import { DotsIndicator } from '../';
import { AutoForwardItem, DndBlocker, RemoveChangesButton } from './';

const classes = BEM.with('ProfileStatus');

class ProfileStatus extends Component {
  static propTypes = {
    autoForwardEntitiesDisplayName: PropTypes.func,
    currentUser: propTypes.user,
    isPatientNetwork: PropTypes.bool,
    loadAutoForwardOrganizations: PropTypes.func.isRequired,
    loadingAutoForward: PropTypes.bool.isRequired,
    loadingUserDndText: PropTypes.bool.isRequired,
    loadingUserStatus: PropTypes.bool.isRequired,
    scrollToElement: PropTypes.func.isRequired,
    setScrollbarsElement: PropTypes.func.isRequired,
    currentOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
      networks: PropTypes.shape({
        patient: PropTypes.shape({
          dnd: PropTypes.bool.isRequired,
          dndText: PropTypes.string.isRequired,
        }),
      }),
    }),
    update: PropTypes.func.isRequired,
    setAwayMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isPatientNetwork: false,
  };

  _pendingDndStateChange = false;
  _currDndState = false;

  state = {
    draftDnd: !this.props.isPatientNetwork
      ? this.props.currentUser.dnd
      : this.props.currentOrganization.networks.patient.dnd,
    draftDndText: {
      value: !this.props.isPatientNetwork
        ? this.props.currentUser.dndText
        : this.props.currentOrganization.networks.patient.dndText,
      isDirty: false,
    },
    draftStatus: {
      value: this.props.currentUser.status,
      isDirty: false,
    },
  };

  componentDidMount() {
    this.props.loadAutoForwardOrganizations();
    this._setCurrDndState();
  }

  componentDidUpdate() {
    this._setCurrDndState();
  }

  render() {
    const { draftDndText, draftDnd, draftStatus } = this.state;
    const {
      autoForwardOrganizations,
      currentUser,
      loadingAutoForward,
      loadingUserDndText,
      loadingUserStatus,
      isPatientNetwork,
    } = this.props;

    const header = isPatientNetwork ? 'STATUS INFO' : 'STATUS SETTINGS';
    const title = isPatientNetwork ? 'Away Message' : 'Do Not Disturb';
    const subHeader = isPatientNetwork ? 'Message' : 'AUTO-REPLY';

    const inRole = currentUser.roles && currentUser.roles.length > 0;
    const disableSwitch = !isPatientNetwork ? inRole : false;

    if (this._pendingDndStateChange) {
      this._currDndState = draftDnd;
      this._pendingDndStateChange = false;
    }

    const dotsFragment = (
      <div className={classes('dots-container-text')}>
        <DotsIndicator className={classes('dots')} size={10} color={'#00a945'} />
      </div>
    );

    const statusFragment = (
      <div className={classes('status-info')}>
        <div className={classes('sub-header')}>STATUS</div>
        <form className={classes('form')} onSubmit={this._submit('status')}>
          <input
            className={classes('input', { isDirty: draftStatus.isDirty })}
            onChange={this._onChange('draftStatus')}
            onFocus={this._onFocus}
            placeholder="Tell others what's going on"
            tabIndex={2}
            type="text"
            value={draftStatus.value}
            onKeyDown={this._onStatusEscape}
          />
        </form>
        {draftStatus.isDirty && (
          <div className={classes('buttons')}>
            <RemoveChangesButton removeChanges={this._removeChangesStatus} />
            <Button label="SAVE" outline onClick={this._submitStatus} />
          </div>
        )}
      </div>
    );

    return (
      <div className={classes()} ref={this._setScrollbarsElement}>
        <Card header={header}>
          {loadingUserStatus ? dotsFragment : !isPatientNetwork && statusFragment}
          <div className={classes('dnd')}>
            <div className={classes('dnd-info')}>
              <DndIcon />
              <div className={classes('dnd-header')}>{title}</div>
              <div id={'dnd-toggle'}>
                <Switch
                  disabled={disableSwitch}
                  checked={this._currDndState}
                  onSwitch={this._onDndButtonClick}
                  dataTestId="setDnd"
                />
              </div>
            </div>
            {inRole && !isPatientNetwork && <DndBlocker />}
          </div>
          {this._currDndState ? (
            loadingUserDndText ? (
              <div className={classes('dots-container-text')}>
                <DotsIndicator className={classes('dots')} size={10} color={'#00a945'} />
              </div>
            ) : (
              <div className={classes('status-info')}>
                <div className={classes('sub-header')}>{subHeader}</div>
                <form className={classes('form')} onSubmit={this._submit('dndText')}>
                  <input
                    className={classes('input', {
                      isDirty: draftDndText.isDirty,
                    })}
                    handlers={this.dndHandlers}
                    onChange={this._onChange('draftDndText')}
                    onFocus={this._onFocus()}
                    placeholder="Enter your custom message here"
                    tabIndex={0}
                    type="text"
                    value={draftDndText.value}
                    onKeyDown={this._onDndEscape}
                  />
                </form>
                {draftDndText.isDirty && (
                  <div className={classes('buttons')}>
                    <RemoveChangesButton removeChanges={this._removeChangesDndText} />
                    <Button label="SAVE" outline onClick={this._submitDndText} />
                  </div>
                )}
              </div>
            )
          ) : null}
          {!isPatientNetwork &&
            this._currDndState &&
            autoForwardOrganizations &&
            autoForwardOrganizations.length > 0 && (
              <div className={classes('auto-forward-info')}>
                <div className={classes('auto-forward-header')}>Auto-Forward</div>
                {loadingAutoForward ? (
                  <div className={classes('dots-container')}>
                    <DotsIndicator className={classes('dots')} size={10} color={'#00a945'} />
                  </div>
                ) : (
                  <ul className={classes('list')}>
                    {autoForwardOrganizations.map(this._renderAutoForwardItem)}
                  </ul>
                )}
              </div>
            )}
        </Card>
      </div>
    );
  }

  _setCurrDndState = () => {
    const { isPatientNetwork, currentUser, currentOrganization } = this.props;

    this._currDndState = !isPatientNetwork
      ? currentUser.dnd
      : currentOrganization.networks.patient.dnd;
  };

  _submit = (field) => {
    return (e) => {
      e.preventDefault();
      if (field === 'dndText') this._submitDndText();
      if (field === 'status') this._submitStatus();
    };
  };

  _onChange = (field) => {
    return (e) => this._updateDraft(field, e.target.value, true);
  };

  _onFocus = () => {
    if (this.props.isPatientNetwork) return;
    const { scrollToElement } = this.props;
    scrollToElement('StatusSettings', { offset: 25, shouldSpring: false });
  };

  _updateDraft = (field, value, isDirty) => {
    this.setState({ [field]: { value, isDirty } });
  };

  _submitDndText = async () => {
    const { draftDndText } = this.state;
    const { currentOrganization, isPatientNetwork } = this.props;

    if (!isPatientNetwork) {
      await this.props.update({
        dndText: draftDndText.value,
        loadingComponent: 'loadingUserDndText',
      });
    } else {
      await this.props.setAwayMessage(currentOrganization.id, {
        dnd: true,
        dndText: draftDndText.value,
      });
    }
    this._updateDraft('draftDndText', draftDndText.value, false);
  };

  _onDndEscape = (e) => {
    if (e.key !== 'Escape') return;
    e.stopPropagation();
    const { draftDndText } = this.state;

    if (!draftDndText.isDirty) return;
    this._removeChangesDndText();
  };

  _removeChangesDndText = () => {
    this._updateDraft('draftDndText', this.props.currentUser.dndText, false);
  };

  _submitStatus = async () => {
    const { draftStatus } = this.state;

    await this.props.update({
      status: draftStatus.value,
      loadingComponent: 'loadingUserStatus',
    });
    this._updateDraft('draftStatus', draftStatus.value, false);
  };

  _onStatusEscape = (e) => {
    if (e.key !== 'Escape') return;
    e.stopPropagation();
    const { draftStatus } = this.state;

    if (!draftStatus.isDirty) return;
    this._removeChangesStatus();
  };

  _removeChangesStatus = () => {
    this._updateDraft('draftStatus', this.props.currentUser.status, false);
  };

  _onDndButtonClick = async () => {
    const { currentOrganization, currentUser, isPatientNetwork, setAwayMessage } = this.props;
    const { draftDndText } = this.state;
    const dnd = !isPatientNetwork ? currentUser.dnd : currentOrganization.networks.patient.dnd;

    this._pendingDndStateChange = true;
    this.setState({ draftDnd: !dnd });

    if (!dnd && !draftDndText.value) {
      const defaultDndText = "I'm sorry, I am unable to reply right now.";
      this._updateDraft('draftDndText', defaultDndText, false);
      if (!isPatientNetwork) {
        await this.props.update({
          dnd: !dnd,
          dndText: defaultDndText,
          loadingComponent: 'loadingUserDndText',
        });
      } else {
        await setAwayMessage(currentOrganization.id, {
          dnd: !dnd,
          dndText: defaultDndText,
        });
      }
    } else {
      if (!isPatientNetwork) {
        await this.props.update({ dnd: !dnd });
      } else {
        await setAwayMessage(currentOrganization.id, {
          dnd: !dnd,
          dndText: draftDndText.value,
        });
      }
    }
  };

  _renderAutoForwardItem = (organization) => {
    const { autoForwardEntitiesDisplayName } = this.props;
    return (
      <li key={organization.id}>
        <AutoForwardItem
          organization={organization}
          autoForwardEntitiesDisplayName={autoForwardEntitiesDisplayName(organization)}
        />
      </li>
    );
  };

  _setScrollbarsElement = (ref) => {
    this.props.setScrollbarsElement(ref, 'StatusSettings');
  };
}

export default mobxInjectSelect({
  messengerStore: ['currentOrganization'],
  profileStore: ['scrollToElement', 'setScrollbarsElement'],
  sessionStore: ['currentUser'],
  userStore: [
    'autoForwardOrganizations',
    'loadAutoForwardOrganizations',
    'loadingAutoForward',
    'loadingUserDndText',
    'loadingUserStatus',
    'update',
    'setAwayMessage',
  ],
})(ProfileStatus);
