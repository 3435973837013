import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from '../../WebComponents';
import BEM from '../../../bem';

import { Role, SaveRoleProps } from '../../../../types';

import ToolTip from '../../../../widgets/messenger/components/ToolTip';

import { ReduxState } from '../../../../redux-stores';

const classes = BEM.with('CollaborationEntityEditor');

type RoleEditorAutomatedMessageProps = {
  defaultMessage: string;
  role: Role;
  saveRole: (props: SaveRoleProps) => void;
};

export default function RoleEditorAutomatedMessage({
  defaultMessage,
  role,
  saveRole,
}: RoleEditorAutomatedMessageProps) {
  const defaultDraft = role.metadata?.no_owners_message || `${defaultMessage} ${role.displayName}`;
  const isRolesAdmin = useSelector((state: ReduxState) => state.collab.isRolesAdmin);
  const [draft, setDraft] = useState(defaultDraft);
  const [focus, setFocus] = useState(false);
  const [savedDraft, setSavedDraft] = useState(defaultDraft);
  const messageRef = useRef(null);

  useEffect(() => {
    updateDraft(role.metadata?.no_owners_message || `${defaultMessage} ${role.displayName}`);
  }, [defaultMessage, role.displayName, role.metadata]);

  const messageOnKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 27) {
      resetMessage();
    }
  };

  const messageOnFocus = () => {
    setFocus(true);
  };

  const messageOnBlur = () => {
    setFocus(false);
  };

  const updateDraft = (value: string) => {
    setDraft(value);
  };

  const requestSaveRole = (e: React.MouseEvent) => {
    e.preventDefault();

    const noOwnersMessage = !draft.trim() ? `${defaultMessage} ${role.displayName}` : draft.trim();
    saveRole({ noOwnersMessage });
    setDraft(noOwnersMessage);
    setSavedDraft(noOwnersMessage);
  };

  const resetMessage = () => {
    setDraft(savedDraft);
  };

  const emptyMessage = !(draft || focus);
  const form = (
    <form id="loginAutoMessage">
      <textarea
        className={classes('text-area', {
          empty: emptyMessage,
          admin: isRolesAdmin,
        })}
        disabled={!isRolesAdmin}
        maxLength={256}
        onBlur={messageOnBlur}
        onChange={(e) => updateDraft(e.target.value)}
        onFocus={messageOnFocus}
        onKeyDown={messageOnKeyDown}
        placeholder={emptyMessage ? '-No Message-' : 'Role automated message'}
        ref={messageRef}
        value={draft}
      />
    </form>
  );

  return (
    <div className={classes('card-container')}>
      <Card
        header="Unassigned role auto message"
        headerRight={
          <div className={classes('title-text-area-container')}>
            {draft !== savedDraft && (
              <div className={classes('text-area-button-container')}>
                <button
                  data-test-id="cancel edit role auto message"
                  className={classes('cancel-button')}
                  onClick={resetMessage}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  data-test-id="save role auto message"
                  className={classes('save-button')}
                  onClick={requestSaveRole}
                  type="button"
                >
                  Save
                </button>
              </div>
            )}
          </div>
        }
      >
        {isRolesAdmin ? (
          <ToolTip text="Edit" textAlign={'center'} getManualOffset={() => {}}>
            {form}
          </ToolTip>
        ) : (
          <>{form}</>
        )}
      </Card>
    </div>
  );
}
