import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../utils';
import BEM from '../bem';
import { Modal } from './';

const classes = BEM.with('DuplicateContactModal');

class DuplicateContactModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, options } = this.props;
    const { contact } = options;

    return (
      <Modal
        className={classes()}
        bodyClass={classes('body')}
        headerClass={classes('header')}
        header={'Duplicate Contact'}
        isOpen={isOpen}
        closeClass={classes('close-button')}
        onRequestClose={this._closeModal}
        footerPrimaryActions={
          <button type="button" className={classes('ok-btn')} onClick={this._closeModal}>
            OK
          </button>
        }
      >
        <div className={classes('text')}>
          The contact
          <span className={classes('contact-name')}>
            {' '}
            {`${contact.firstName + ' ' + contact.lastName}`}{' '}
          </span>{' '}
          already exists for this patient.
        </div>
      </Modal>
    );
  }

  _closeModal = () => {
    const { closeModal, openModal, options = {} } = this.props;
    const { reopenModal, optionsReopenModal = {} } = options;

    closeModal();
    if (reopenModal) openModal(reopenModal, optionsReopenModal);
  };
}

export default mobxInjectSelect({
  modalStore: ['openModal'],
})(DuplicateContactModal);
