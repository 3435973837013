import React from 'react';
import PropTypes from 'prop-types';

import BEM from '../../../../bem';

const classes = BEM.with('ScheduleMessageStatus');

const Status = ({ isAutomated, smsOptedOut, status }) => {
  let statusText, statusSlug;

  if (status === 'queued') {
    if (isAutomated) {
      statusText = 'automated';
      statusSlug = 'isAutomatedMessage';
    } else {
      statusText = statusSlug = 'scheduled';
    }
  } else if (status === 'sent_sms') {
    statusText = statusSlug = 'sent';
  } else {
    statusText = status;
    statusSlug = statusText.replace(/\s/g, '-');
  }

  return (
    <div className={classes('')}>
      {smsOptedOut && (
        <div className={classes('optedOutContainer')}>
          <b>Opted out</b>
          <br />
          via SMS
        </div>
      )}
      {!smsOptedOut && (
        <div className={classes('statusPill', { [statusSlug]: true })}>{statusText}</div>
      )}
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Status;
