const PatientAdminPages = {
  AUTOMATED_MESSAGES: 'automatedMessages',
  CUSTOM_LABELING: 'customLabeling',
  MESSAGE_TEMPLATES: 'messageTemplates',
  PATIENTS_LIST: 'patientsList',
  PATIENT_BROADCAST_LISTS: 'patientBroadcastLists',
  SCHEDULED_MESSAGES: 'scheduledMessages',
  VIRTUAL_WAITING_ROOM: 'virtualWaitingRoom',
} as const;

export type PatientAdminPage =
  | typeof PatientAdminPages.AUTOMATED_MESSAGES
  | typeof PatientAdminPages.CUSTOM_LABELING
  | typeof PatientAdminPages.MESSAGE_TEMPLATES
  | typeof PatientAdminPages.PATIENTS_LIST
  | typeof PatientAdminPages.PATIENT_BROADCAST_LISTS
  | typeof PatientAdminPages.SCHEDULED_MESSAGES
  | typeof PatientAdminPages.VIRTUAL_WAITING_ROOM;

export default PatientAdminPages;
