import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from '../images/cancel-icon.svg';
import BEM from '../bem';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('EscButton');

class EscButton extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    tabIndex: PropTypes.number,
  };

  static defaultProps = {
    tabIndex: 0,
  };

  render() {
    const { onClose, tabIndex } = this.props;

    return (
      <div className={classes()}>
        <div
          className={classes('button')}
          onClick={onClose}
          ref={this._setButton}
          tabIndex={tabIndex}
          role="button"
          aria-label="Escape"
          onKeyDown={(e) => {
            if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
              onClose();
            }
          }}
        >
          <Close />
          <div>ESC</div>
        </div>
      </div>
    );
  }

  _setButton = (ref) => {
    this.button = ref;
  };

  focus() {
    this.button && this.button.focus();
  }

  blur() {
    this.button && this.button.blur();
  }
}

export default observer(EscButton);
