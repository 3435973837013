import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import Modal from '../Modal';
import mobxInjectSelect from '../../utils/mobxInjectSelect';

const classes = BEM.with('CallModal');

class UnsupportedBrowserModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    currentCall: PropTypes.shape({
      callStatus: PropTypes.string,
      payload: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    declineVoipCall: PropTypes.func.isRequired,
  };

  render() {
    const { currentCall, isOpen, ...restProps } = this.props;

    const isMac = navigator.platform === 'MacIntel';

    let callStatus, headerFragment, name;
    if (currentCall) {
      callStatus = currentCall.callStatus;
      name = currentCall.payload.name;
    }
    if (callStatus === 'incoming') {
      headerFragment = (
        <div className={classes('title')}>
          Incoming call from <span className={classes('displayName')}>{name}</span>
        </div>
      );
    } else {
      headerFragment = <div className={classes('title')}> Browser Not Supported </div>;
    }

    return (
      <Modal
        bodyClass={classes('unsupported-body')}
        className={classes()}
        closeClass={classes('close-button')}
        headerClass={classes('header')}
        isOpen={isOpen}
        onRequestClose={this._closeModal}
        size={'medium'}
        footerSecondaryActions={
          <button className={classes('primary-btn')} onClick={this._closeModal} type="button">
            CLOSE
          </button>
        }
        {...restProps}
      >
        {headerFragment}
        This browser is not supported. Please start the call using
        <a
          href="https://www.google.com/chrome/"
          rel="noopener noreferrer"
          target="_blank"
          className={classes('browser')}
        >
          {' '}
          Chrome
        </a>
        {isMac && (
          <span>
            ,{' '}
            <a
              href="https://support.apple.com/downloads/safari"
              rel="noopener noreferrer"
              target="_blank"
              className={classes('browser')}
            >
              Safari
            </a>
            ,
          </span>
        )}
        <span> or </span>
        <a
          href="https://www.mozilla.org/en-US/firefox/new/"
          rel="noopener noreferrer"
          target="_blank"
          className={classes('browser')}
        >
          Firefox
        </a>
      </Modal>
    );
  }

  _closeModal = () => {
    const { closeModal, currentCall, declineVoipCall } = this.props;

    if (currentCall) {
      declineVoipCall();
    }
    closeModal();
  };
}

export default mobxInjectSelect({
  callStore: ['currentCall', 'declineVoipCall'],
})(UnsupportedBrowserModal);
