import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { MenuItem, MenuItemList } from './ContextMenu';
import Modal from './Modal';

const classes = BEM.with('MuteModal');
const MUTE_INTERVALS = [
  { durationInMinutes: 15, label: '15 Minutes' },
  { durationInMinutes: 1 * 60, label: '1 Hour' },
  { durationInMinutes: 8 * 60, label: '8 Hours' },
  { durationInMinutes: 24 * 60, label: '1 Day' },
  { durationInMinutes: 7 * 24 * 60, label: '1 Week' },
  { durationInMinutes: 7 * 24 * 60 * 52, label: '1 Year' },
];

class MuteModal extends Component {
  static propTypes = {
    currentMuteConversation: propTypes.conversation,
    hideMuteModal: PropTypes.func.isRequired,
    muteConversation: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const { currentMuteConversation: conversation } = this.props;
    let title = '';

    if (conversation) {
      title = 'Mute ' + conversation.counterParty.displayName;
    }

    return (
      <Modal
        ariaLabelCloseButton={'Mute Modal Close Button'}
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('close-button')}
        header={title}
        headerClass={classes('header')}
        isOpen={!!conversation}
        onRequestClose={this._onRequestClose}
        size={'medium-small'}
      >
        <MenuItemList>
          {MUTE_INTERVALS.map(({ durationInMinutes, label }) => (
            <MenuItem
              ariaLabel={`Mute Modal Time ${label}`}
              key={durationInMinutes}
              onClick={this._onMenuItemClick(durationInMinutes)}
            >
              {label}
            </MenuItem>
          ))}
        </MenuItemList>
      </Modal>
    );
  }

  _onRequestClose = () => {
    const { closeModal, hideMuteModal } = this.props;
    hideMuteModal();
    closeModal();
  };

  _onMenuItemClick = (durationInMinutes) => {
    return () => {
      const { closeModal, currentMuteConversation, hideMuteModal, muteConversation } = this.props;
      muteConversation(currentMuteConversation.id, durationInMinutes);
      hideMuteModal();
      closeModal();
    };
  };
}

export default mobxInjectSelect({
  conversationStore: ['currentMuteConversation', 'hideMuteModal', 'muteConversation'],
})(MuteModal);
