import React, { useEffect, useState } from 'react';

import useToggle from '../../../../utils/useToggle';

import { PendingUserTableRow, PendingUserTableRows } from '../../../../types';
import { TabProps } from '../';

import View from './View';
import { addAllPendingUsers, declineAllPendingUsers, declineUser, getPendingUsers } from './thunks';
import { useAppSelector } from 'redux-stores';

type ModalProp = {
  onSuccess: () => void;
  body: string;
  doneButton: string;
  header: string;
};

export default function PendingUsers({ onClose }: TabProps) {
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);

  const [pendingUsers, setPendingUsers] = useState<PendingUserTableRows>([]);
  const [userToDecline, setUserToDecline] = useState<PendingUserTableRow | null>(null);

  const [isModalOpen, toggleIsModalOpen] = useToggle(false);

  const pendingUsersCount = pendingUsers.length;
  const pendingUsersCountText = `${pendingUsersCount} user${pendingUsersCount > 1 ? 's' : ''}`;

  const modalProps: { addAll: ModalProp; decline: ModalProp; declineAll: ModalProp } = {
    decline: {
      onSuccess: () => {
        if (!userToDecline) return;
        declineUser({
          userToDecline,
          setPendingUsers,
          toggleIsModalOpen,
          setUserToDecline,
          pendingUsers,
        });
      },
      body: `Are you sure you want to decline access - ${userToDecline?.emailAddress} ?`,
      doneButton: 'Decline',
      header: 'Decline User',
    },
    addAll: {
      onSuccess: () => {
        addAllPendingUsers({ setPendingUsers, pendingUsers, toggleIsModalOpen });
      },
      body: `Are you sure you want to add ${
        pendingUsersCount > 1 ? 'all' : ''
      } ${pendingUsersCountText} displayed below ?`,
      doneButton: 'Add',
      header: 'Add all users',
    },
    declineAll: {
      onSuccess: () => {
        declineAllPendingUsers({ pendingUsers, setPendingUsers, toggleIsModalOpen });
      },
      body: `Are you sure you want to delete ${
        pendingUsersCount > 1 ? 'all' : ''
      } ${pendingUsersCountText} displayed below ?`,
      doneButton: 'Delete',
      header: 'Delete all users',
    },
  };

  useEffect(() => {
    getPendingUsers({
      setPendingUsers,
      selectedOrganizationId,
    });
  }, [selectedOrganizationId]);

  return (
    <View
      {...{
        onClose,
        modalProps,
        pendingUsers,
        setPendingUsers,
        toggleIsModalOpen,
        isModalOpen,
        setUserToDecline,
        selectedOrganizationId,
      }}
    />
  );
}
