import React, { Component } from 'react';
import classNames from 'classnames';
import BEM from '../../bem';

const classes = BEM.with('FormButtons');

class Buttons extends Component {
  render() {
    const { children, className } = this.props;

    return <div className={classNames(classes(), className)}>{children}</div>;
  }
}

export default Buttons;
