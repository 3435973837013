import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActionTexts, TeamMemberStatus } from '../../../models/enums/';
import BEM from '../../../common/bem';
import propTypes from '../../../common/propTypes';
import {
  DotsIndicator,
  EntityAvatar,
  TeamAvatar,
  TeamName,
  Scrollbars,
  UserName,
  UserTitleDepartment,
} from '../../../common/components';
import { mobxInjectSelect } from '../../../common/utils';

const classes = BEM.with('TeamProfile');

class TeamProfile extends Component {
  static propTypes = {
    actionButtonOnClick: PropTypes.func.isRequired,
    currentOrganizationId: PropTypes.string.isRequired,
    currentTeam: propTypes.team.isRequired,
    currentUser: propTypes.user.isRequired,
    getAdminRolesInOrganization: PropTypes.func.isRequired,
    maxTeamMembers: PropTypes.number.isRequired,
    openEditTeamModal: PropTypes.func.isRequired,
    refreshTeam: PropTypes.func.isRequired,
    requestToJoin: PropTypes.func.isRequired,
  };

  _isLoading = true;
  _lastTeamId = null;

  constructor(props) {
    super(props);

    this.STATUS_TO_CLICK_HANDLER = {
      [TeamMemberStatus.DECLINED]: this._resetDecline,
      [TeamMemberStatus.MEMBER]: this._openLeaveTeamModal,
      [TeamMemberStatus.PENDING]: null,
      [TeamMemberStatus.REQUEST]: this._requestToJoin,
    };
  }

  componentDidMount() {
    this._loadTeam();
  }

  componentDidUpdate() {
    const { currentTeam } = this.props;
    if (!currentTeam) {
      return;
    }

    if (this._lastTeamId !== currentTeam.id) {
      this._loadTeam();
    }
  }

  _loadTeam = async () => {
    const { currentTeam: team } = this.props;
    if (!team) return;

    this._lastTeamId = team ? team.id : null;
    if (!this._isLoading) {
      this._isLoading = true;
      this.forceUpdate();
    }

    await this.props.refreshTeam(team.id);

    if (this._lastTeamId === team.id) {
      this._isLoading = false;
      this.forceUpdate();
    }
  };

  state = {
    dropShadow: false,
  };

  render() {
    const { actionButtonOnClick, currentTeam: team, getAdminRolesInOrganization } = this.props;
    const { dropShadow } = this.state;
    const { description = '', hasCurrentUserOrRole, members = [] } = team;
    const { isTeamAdmin = false } = getAdminRolesInOrganization();
    const actionText = hasCurrentUserOrRole ? ActionTexts.message : ActionTexts.activate;
    const isLoading = this._isLoading;

    return (
      <div className={classes()}>
        <div className={classes('team-card')}>
          <div className={classes('team-container')}>
            <Scrollbars onUpdate={this._onUpdateScroll}>
              <div className={classes('team-details')}>
                <div className={classes('team-header')}>
                  <TeamAvatar
                    className={classes('team-avatar')}
                    displayName={team.displayName}
                    size={30}
                  />
                  <TeamName className={classes('team-name')} team={team} />
                  {isTeamAdmin && (
                    <div className={classes('edit-button')} onClick={this.editTeam}>
                      Edit
                    </div>
                  )}
                </div>
                {description.length > 0 && (
                  <div className={classes('team-description')}>
                    <div className={classes('description-label')}>Description</div>
                    {team.description}
                  </div>
                )}
                {members.length > 0 && (
                  <div className={classes('team-members')}>
                    <div className={classes('label-container')}>
                      <span className={classes('members-label')}>Members</span>
                      {this._renderActionableLabel()}
                    </div>
                    {members.map((member) => (
                      <div className={classes('user-container')} key={member.id}>
                        <EntityAvatar
                          entity={member.isRoleBot && member.botRole ? member.botRole : member}
                          size={30}
                        />
                        <span className={classes('user-details')}>
                          <UserName
                            className={classes('user-name')}
                            includeRoleOwner
                            includeRoleTag
                            user={member}
                          />
                          <UserTitleDepartment className={classes('user-title')} user={member} />
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Scrollbars>
          </div>
          <div className={classes('button-container', { dropShadow })}>
            <button
              className={classes('action-button', { isLoading })}
              disabled={isLoading}
              onClick={actionButtonOnClick}
            >
              {isLoading ? <DotsIndicator size={10} speed={0.5} color={'#fff'} /> : actionText}
            </button>
          </div>
        </div>
        <div className={classes('delete-team-container')}>
          {isTeamAdmin && (
            <div className={classes('delete-team-text')} onClick={this._openDeleteModal}>
              Delete Team
            </div>
          )}
        </div>
      </div>
    );
  }

  _renderActionableLabel = () => {
    const {
      currentTeam: team,
      currentUser,
      getAdminRolesInOrganization,
      maxTeamMembers,
    } = this.props;
    const {
      canMembersLeave,
      canRequestToJoin,
      hasCurrentUserOrRole,
      hasCurrentUserBeenDeclined,
      memberCount,
    } = team;
    const { isTeamAdmin = false } = getAdminRolesInOrganization();
    const isRequestPending = this._isRequestPending();

    let declinedFragment;
    let label;
    let status;
    if (!hasCurrentUserOrRole) {
      if (isRequestPending) {
        label = 'Request pending...';
        status = TeamMemberStatus.PENDING;
      } else if (hasCurrentUserBeenDeclined) {
        label = 'Request Denied';
        status = TeamMemberStatus.DECLINED;

        declinedFragment = (
          <div className={classes('reset-decline-container')}>
            <div className={classes('reset-decline-x')}>x</div>
          </div>
        );
      } else if (!isTeamAdmin && canRequestToJoin && memberCount < maxTeamMembers) {
        label = `Request to join as ${currentUser.displayName}`;
        status = TeamMemberStatus.REQUEST;
      }
    } else {
      if (canMembersLeave && team.members.length > 2) {
        label = 'Leave Team';
        status = TeamMemberStatus.MEMBER;
      }
    }

    if (label === undefined) return null;

    return (
      <span
        className={classes('team-membership-status', { status })}
        onClick={this.STATUS_TO_CLICK_HANDLER[status]}
      >
        {label}
        {declinedFragment}
      </span>
    );
  };

  _resetDecline = () => {
    const { currentTeam: team, resetDecline } = this.props;
    const { hasCurrentUserBeenDeclined } = team;

    if (hasCurrentUserBeenDeclined) {
      resetDecline();
    }
  };

  _openLeaveTeamModal = () => {
    const { currentTeam: team, openModal } = this.props;
    openModal('oldLeaveTeam', { team });
  };

  _requestToJoin = () => {
    const { currentTeam: team, currentOrganizationId, currentUser, requestToJoin } = this.props;
    const { hasCurrentUserOrRole, id: teamId } = team;

    if (!hasCurrentUserOrRole) {
      const requestedById = currentUser.id;
      requestToJoin(teamId, {
        organizationId: currentOrganizationId,
        requestedById,
      });
    }
  };

  editTeam = () => {
    const { currentTeam, openEditTeamModal } = this.props;
    openEditTeamModal(currentTeam);
  };

  _onUpdateScroll = (values) => {
    const { clientHeight, scrollHeight } = values;
    const dropShadow = scrollHeight > clientHeight;

    if (this.state.dropShadow !== dropShadow) {
      this.setState({ dropShadow });
    }
  };

  _isRequestPending = () => {
    const { currentTeam: team } = this.props;
    return team.hasCurrentUserPending;
  };

  _openDeleteModal = () => {
    const { currentTeam: team, openModal } = this.props;
    openModal('deleteTeam', { team });
  };
}

export default mobxInjectSelect({
  createTeamStore: ['maxTeamMembers', 'openEditTeamModal'],
  messengerStore: ['currentOrganizationId', 'getAdminRolesInOrganization'],
  modalStore: ['openModal'],
  sessionStore: ['currentUser'],
  teamStore: [
    'actionButtonOnClick',
    'activateTeam',
    'currentTeam',
    'refreshTeam',
    'requestPending',
    'requestToJoin',
    'resetDecline',
  ],
})(TeamProfile);
