import React from 'react';

import CustomerSupport from '../CustomerSupport';
import Organizations from '../Organizations';
import OrgSettings from '../OrgSettings/OrganizationSettings';
import Forums from '../Forums';
import Locations from '../Multiorg/Locations';
import UsersRouter from '../Users';

import styles from './MainView.module.css';
import { useAppSelector } from 'redux-stores';

export default function MainView({
  onScrollCallBackRef,
}: {
  onScrollCallBackRef: React.RefObject<(e: React.UIEvent<HTMLElement>) => void>;
}) {
  const selectedPage = useAppSelector((state) => state.admin.selectedPage);
  let page;
  switch (selectedPage) {
    case 'Customer Support':
      page = <CustomerSupport />;
      break;
    case 'Organizations':
      page = <Organizations />;
      break;
    case 'Org Settings':
      page = <OrgSettings />;
      break;
    case 'Forums':
      page = <Forums />;
      break;
    case 'Locations':
      page = <Locations />;
      break;
    case 'Users':
    default:
      page = <UsersRouter onScrollCallBackRef={onScrollCallBackRef} />;
  }

  return <div className={styles.root}>{page}</div>;
}
