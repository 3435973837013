export const DeliveryDirections = {
  BEFORE: 'before',
  AFTER: 'after',
} as const;

export const DeliveryMethods = {
  LINK: 'link',
  SMS: 'sms',
} as const;

export const EventDeliveryMethods = {
  ...DeliveryMethods,
  ONE_WAY_LINK: 'one_way_link',
} as const;

export type DeliveryMethod = typeof DeliveryMethods[keyof typeof DeliveryMethods];
export type EventDeliveryMethod = typeof EventDeliveryMethods[keyof typeof EventDeliveryMethods];
