import React, { useRef } from 'react';
import { Template } from '../../../types';
import BEM from '../../bem';
import { ContextMenu } from '../ContextMenu';

import { ReactComponent as DropdownChevronSvg } from '../../images/dropdown-chevron.svg';
import { ReactComponent as MessageTemplateSvg } from '../../images/message-template.svg';
import TemplateMenu from './TemplateMenu';

const classes = BEM.with('MessageInputs');

type TemplateContextMenuProps = {
  allowDynamicTemplates?: boolean;
  disableCustom?: boolean;
  selectedTemplate: Template;
  setSelectedTemplate: (template: Template) => void;
  shouldFilterSms?: boolean;
  accessibilityMode?: boolean;
  ariaDisabled?: boolean;
};

const TemplateContextMenu: React.FC<TemplateContextMenuProps> = ({
  allowDynamicTemplates,
  disableCustom,
  selectedTemplate,
  setSelectedTemplate,
  shouldFilterSms,
  accessibilityMode = false,
  ariaDisabled = false,
}) => {
  const templateMenuButtonRef = useRef(null);

  return (
    <ContextMenu
      event="click"
      offsetY={6}
      position="bottominnerleft"
      relativeTo={templateMenuButtonRef.current}
      theme="vertical"
      accessibilityMode={accessibilityMode}
      ariaDisabled={ariaDisabled}
      menu={
        <TemplateMenu
          disableCustom={!!disableCustom}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          shouldFilterSms={shouldFilterSms}
          allowDynamicTemplates={allowDynamicTemplates}
          accessibilityMode={accessibilityMode}
        />
      }
    >
      <button
        className={classes('template-menu-btn')}
        data-test-id={'message-template-button'}
        ref={templateMenuButtonRef}
        aria-label={`Template: ${selectedTemplate.title}`}
        aria-disabled={ariaDisabled}
      >
        <div className={classes('template-menu-label')}>
          <MessageTemplateSvg aria-hidden />
          Template: {selectedTemplate.title}
        </div>
        <DropdownChevronSvg />
      </button>
    </ContextMenu>
  );
};

export default TemplateContextMenu;
