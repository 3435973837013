import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import BEM from '../../../bem';

import propTypes from '../../../propTypes';
import { ReactComponent as CheckMarkSvg } from '../../../images/green-checkmark-full.svg';
import { ReactComponent as BroadcastSvg } from '../../../images/broadcast.svg';
import { ReactComponent as BroadcastPregenSvg } from '../../../images/broadcast-pregen.svg';
import { ReactComponent as BroadcastAdSecurityGroupSvg } from '../../../images/broadcast-adSecurityGroup.svg';
import scheduledMessageFormats from '../../../utils/scheduledMessageFormats';
import { UserName } from '../../';
import { AppTypes } from '../../../../models/enums';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('PatientBroadcastRow');

const { DATE_FORMAT } = scheduledMessageFormats;

const PatientBroadcastRow = ({
  adminOnly,
  currentAppSelected,
  createdBy,
  createdOn,
  displayName,
  editBroadcastList,
  isAdSync,
  id,
  isAdmin,
  isPregen,
  isSelected,
  toggleSelected,
  updatedByDisplayName,
  updatedOn,
  memberCount,
}) => (
  <div
    className={classes('', { selected: isSelected, noCheckbox: !isAdmin })}
    data-test-id={`broadcast-${displayName}-row`}
    onClick={() => editBroadcastList(id)}
    aria-label="Edit Broadcast List"
    role="row"
    onKeyDown={(e) => {
      if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
        editBroadcastList(id);
      }
    }}
  >
    {isAdmin && (
      <div
        className={classes('list-item', { checkbox: true })}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) e.stopPropagation();
        }}
        role="cell"
        aria-label="Select this item"
      >
        {!isPregen && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              toggleSelected(id);
            }}
            className={classes('list-item-checkbox-default', {
              selected: isSelected,
            })}
            aria-checked={isSelected}
            role="checkbox"
          >
            {isSelected && <CheckMarkSvg aria-hidden />}
          </button>
        )}
      </div>
    )}
    <div className={classes('list-item', { three: true })} role="cell">
      {isPregen ? (
        <BroadcastPregenSvg className={classes('broadcast-list-svg')} aria-hidden />
      ) : isAdSync ? (
        <BroadcastAdSecurityGroupSvg className={classes('broadcast-list-svg')} aria-hidden />
      ) : (
        <BroadcastSvg className={classes('broadcast-list-svg')} aria-hidden />
      )}
      <span className={classes('list-item-display-name')} title={displayName}>
        {displayName}
      </span>
    </div>
    <div className={classes('list-item')} role="cell">
      {isPregen ? '' : memberCount}
    </div>
    <div className={classes('list-item')} role="cell">
      {createdBy ? <UserName user={createdBy} /> : ''}
    </div>
    <div className={classes('list-item')} role="cell">
      {createdOn ? moment(createdOn).format(DATE_FORMAT) : ''}
    </div>
    <div className={classes('list-item')} role="cell">
      <div>
        <div className={classes('list-item-updated-on')}>
          {updatedOn ? moment(updatedOn).format(DATE_FORMAT) : ''}
        </div>
        <div className={classes('list-item-updated-by')}>
          {updatedByDisplayName && `By ${updatedByDisplayName}`}
        </div>
      </div>
    </div>
    {(currentAppSelected === AppTypes.BROADCAST_LISTS ||
      (currentAppSelected === AppTypes.PATIENT_SETTINGS && isAdmin)) && (
      <div className={classes('list-item')} role="cell">
        {adminOnly
          ? currentAppSelected === AppTypes.BROADCAST_LISTS
            ? 'Limited'
            : 'Admin'
          : 'Public'}
      </div>
    )}
  </div>
);

PatientBroadcastRow.propTypes = {
  createdBy: propTypes.user,
  createdOn: PropTypes.string,
  currentAppSelected: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  editBroadcastList: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  updatedOn: PropTypes.string,
};

export default PatientBroadcastRow;
