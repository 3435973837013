import React, { Component } from 'react';
import { observer } from 'mobx-react';
import BEM from '../../bem';

const classes = BEM.with('DndBlocker');

class DndBlocker extends Component {
  render() {
    return (
      <div className={classes()}>
        <div className={classes('blocker-container')}>
          <div className={classes('bg')} />
          <div className={classes('details-container')}>
            <div className={classes('details')}>Not available while opted into a Role</div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(DndBlocker);
