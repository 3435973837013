import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Editor as TinyMCEEditor } from 'tinymce';
import { Editor } from '@tinymce/tinymce-react';

import TCClient from '../../../../client';
import insertAtCaret from '../../../utils/insertAtCaret';

import { RadioInput } from '../../../shared-components/RadioInput/RadioInput';
import { CtaButton } from '../../../shared-components/CtaButton/CtaButton';
import InputCount from '../../../shared-components/InputCount/InputCount';
import Modal from '../../../shared-components/Modal/Modal';
import { Toast } from '../../../shared-components/Toast/Toast';
import { ViewContainer } from '../../../shared-components/View/ViewContainer/ViewContainer';

import styles from './WelcomeEmail.module.css';

import { TabProps } from './';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

const MAX_MESSAGE_LENGTH = 9000;

export default function WelcomeEmail({ onClose, onSave }: TabProps) {
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);
  const dispatch = useAppDispatch();

  const editorRef = useRef<TinyMCEEditor | null>(null);
  const subjectInputRef = useRef<HTMLInputElement>(null);
  const [subjectBuffer, setSubjectBuffer] = useState('');
  const [fromBuffer, setFromBuffer] = useState('');
  const [headerBuffer, setHeaderBuffer] = useState('');
  const [adminNameBuffer, setAdminNameBuffer] = useState('');
  const [messageBuffer, setMessageBuffer] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [logoBuffer, setLogoBuffer] = useState('');
  const [unsentCount, setUnsentCount] = useState(0);
  const [autoSendWelcomeEmailRadioValue, setAutoSendWelcomeEmailRadioValue] = useState<
    'Manual' | 'Automatic'
  >('Manual');
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);
  const [optionalMessageLength, setOptionalMessageLength] = useState(0);

  const [toastType, setToastType] = useState<'SUCCESS' | 'FAILURE'>('FAILURE');
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastDuration, setToastDuration] = useState<number | undefined>(undefined);

  const getWelcomeEmailUnsentCount = useCallback(async () => {
    const { count } = await TCClient.adminOrganizations.getWelcomeEmailUnsentCount({
      organizationId: selectedOrganizationId,
    });
    setUnsentCount(count);
  }, [selectedOrganizationId]);

  useEffect(() => {
    async function getWelcomeEmailSettings() {
      const {
        autoSendWelcomeEmail,
        welcomeEmailLogo,
        welcomeEmailSettings: { from, optionalHeader, optionalMessage, optionalName, subject },
      } = await TCClient.adminOrganizations.getWelcomeEmailSettings({
        organizationId: selectedOrganizationId,
      });
      setAutoSendWelcomeEmailRadioValue(autoSendWelcomeEmail ? 'Automatic' : 'Manual');
      setLogoUrl(welcomeEmailLogo);
      setFromBuffer(from);
      setSubjectBuffer(subject);
      setHeaderBuffer(optionalHeader);
      setMessageBuffer(optionalMessage);
      setOptionalMessageLength(optionalMessage.length);
      setAdminNameBuffer(optionalName);
      await getWelcomeEmailUnsentCount();
    }

    getWelcomeEmailSettings();
  }, [getWelcomeEmailUnsentCount, selectedOrganizationId]);

  const handleSaveSettings = async () => {
    await saveLocalSettings();
    onSave();
  };

  const saveLocalSettings = useCallback(async () => {
    if (editorRef.current) {
      try {
        await TCClient.adminOrganizations.saveWelcomeEmailSettings({
          organizationId: selectedOrganizationId,
          from: fromBuffer,
          subject: subjectBuffer,
          header: headerBuffer,
          message: editorRef.current.getContent(),
          name: adminNameBuffer,
          logo: logoBuffer,
          sendTrigger: autoSendWelcomeEmailRadioValue,
        });
      } catch (e) {
        showToast('Unable to save settings', { type: 'FAILURE' });
      }
    }
  }, [
    adminNameBuffer,
    autoSendWelcomeEmailRadioValue,
    fromBuffer,
    headerBuffer,
    logoBuffer,
    selectedOrganizationId,
    subjectBuffer,
  ]);

  useEffect(() => {
    dispatch(actions.setOrgSettingsTabSaveFunc(saveLocalSettings));
    return () => {
      dispatch(actions.setOrgSettingsTabSaveFunc(() => {}));
    };
  }, [dispatch, saveLocalSettings]);

  function handleLogoInput(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogoBuffer(e.target?.result?.toString() || '');
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  function handleWelcomeEmailRadioInput(e: React.ChangeEvent<HTMLInputElement>) {
    setAutoSendWelcomeEmailRadioValue(e.target.value as 'Manual' | 'Automatic');
  }

  function showToast(message = '', options?: { duration?: number; type?: 'SUCCESS' | 'FAILURE' }) {
    setToastDuration(options?.duration);
    setToastType(options?.type || 'FAILURE');
    setToastMessage(message);
    setIsToastOpen(true);
  }

  async function handleSendEmails() {
    try {
      const res = await TCClient.adminOrganizations.sendWelcomeEmail({
        organizationId: selectedOrganizationId,
        sendUnsent: true,
      });
      setIsSendEmailModalOpen(false);
      if (res.status === 200) {
        showToast('Emails are being sent.', { type: 'SUCCESS' });
        await getWelcomeEmailUnsentCount();
      }
    } catch (e) {
      setIsSendEmailModalOpen(false);
      showToast('Something went wrong.');
    }
  }

  function handleInsertOrgName() {
    if (!subjectInputRef.current) return;
    const str = insertAtCaret(subjectInputRef.current, '<org_name>');
    if (str.length > 150) return;
    setSubjectBuffer(str);
  }

  function handleEditorEvents(_event: unknown, editor: TinyMCEEditor) {
    editor.on('keyup', function (_e: unknown) {
      setOptionalMessageLength(editorRef.current?.getContent?.().length || 0);
    });
  }

  function getLimitColor() {
    const ratio = optionalMessageLength / MAX_MESSAGE_LENGTH;
    if (ratio > 0.75) return { color: '#FF0000' };
    else if (ratio > 0.5) return { color: '#FFA500' };
    return { color: '#008000' };
  }

  if (optionalMessageLength > MAX_MESSAGE_LENGTH) {
    showToast(
      "You have reached the optional message's character limit, please delete some text before trying to enter more.",
      { duration: 3000 }
    );
  }

  return (
    <ViewContainer
      {...{
        header: 'Welcome Email',
        subHeader: '',
        onClose,
      }}
    >
      <div className={styles.welcomeEmail}>
        <div className={styles.section}>
          <div className={styles.header}>Send Welcome Emails:</div>
          <div className={styles.welcomeEmailRadioContainer}>
            <RadioInput
              label={'Manual'}
              selectedValue={autoSendWelcomeEmailRadioValue}
              name={'welcomeEmail'}
              onRadioClick={handleWelcomeEmailRadioInput}
              dataTestId={'emailSendManual'}
            />
            <RadioInput
              label={'Automatic'}
              selectedValue={autoSendWelcomeEmailRadioValue}
              name={'welcomeEmail'}
              onRadioClick={handleWelcomeEmailRadioInput}
              dataTestId={'emailSendAutomatic'}
            />
          </div>
          <div className={styles.welcomeEmailMissedNotice}>
            {`${unsentCount} user${unsentCount === 1 ? '' : 's'} in your organization ${
              unsentCount === 1 ? 'has' : 'have'
            } not been sent a welcome email.`}
          </div>
          <CtaButton
            primary
            label={'Send Welcome Email'}
            onClick={() => setIsSendEmailModalOpen(true)}
            disabled={unsentCount === 0}
            dataTestId={'sendWelcomeEmail button'}
          />
        </div>
        <hr />
        <div className={styles.header}>Welcome Email Template</div>
        <div className={styles.subheader}>
          Customize various aspects of the welcome email sent to your organization.
        </div>

        <div className={styles.templateRow}>
          <div className={styles.subjectColumn}>
            <div className={styles.inputCountContainer}>
              <InputCount
                lengthLimit={150}
                labelText={'Subject Line:'}
                value={subjectBuffer}
                onInputChange={(e) => setSubjectBuffer(e.target.value)}
                inputRef={subjectInputRef}
                dataTestId={'emailSubjectLine'}
              />
            </div>
            <CtaButton isInsertButton label={'Insert <org_name>'} onClick={handleInsertOrgName} />
          </div>
          <div className={styles.logoColumn}>
            <label>Logo:</label>
            <input type="file" onChange={handleLogoInput} />
            <img src={logoBuffer || logoUrl} alt="welcome email logo" />
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.inputCountContainer}>
            <InputCount
              lengthLimit={50}
              labelText={'From Field:'}
              value={fromBuffer}
              onInputChange={(e) => setFromBuffer(e.target.value)}
              dataTestId={'emailFromField'}
            />
          </div>
        </div>
        <div className={styles.section}>
          <hr />
          <div className={styles.header}>Optional Message From Admin</div>
          <div className={styles.inputCountContainer}>
            <InputCount
              lengthLimit={50}
              labelText={'Header:'}
              value={headerBuffer}
              onInputChange={(e) => setHeaderBuffer(e.target.value)}
              dataTestId={'emailHeader'}
            />
          </div>
          <label>
            Message:{' '}
            <span
              style={{ ...getLimitColor(), fontSize: '11px' }}
            >{`${optionalMessageLength}/${MAX_MESSAGE_LENGTH}`}</span>
          </label>
          <div className={styles.editorContainer} data-test-id="emailMessage">
            <Editor
              tinymceScriptSrc={process.env.PUBLIC_URL + 'libs/tinymce/tinymce.min.js'}
              onInit={(_, editor) => (editorRef.current = editor)}
              initialValue={messageBuffer}
              init={{
                setup: function (editor) {
                  editor.ui.registry.addButton('customFirstNameButton', {
                    text: 'First Name',
                    onAction: function () {
                      editor.insertContent('&lt;first_name&gt;');
                    },
                  });
                  editor.ui.registry.addButton('customLastNameButton', {
                    text: 'Last Name',
                    onAction: function () {
                      editor.insertContent('&lt;last_name&gt;');
                    },
                  });
                  editor.ui.registry.addButton('customEmailAddressButton', {
                    text: 'Email Address',
                    onAction: function () {
                      editor.insertContent('&lt;email_address&gt;');
                    },
                  });
                  editor.ui.registry.addButton('customUsernameButton', {
                    text: 'Username',
                    onAction: function () {
                      editor.insertContent('&lt;user_name&gt;');
                    },
                  });
                  editor.ui.registry.addButton('customOrgNameButton', {
                    text: 'Org Name',
                    onAction: function () {
                      editor.insertContent('&lt;org_name&gt;');
                    },
                  });
                },
                min_height: 350,
                menubar: false,
                plugins: ['advlist hr autolink link paste image lists'],
                toolbar: [
                  'bold italic underline strikethrough | alignleft aligncenter alignright | subscript superscript | formatselect fontsizeselect',
                  'bullist numlist | outdent indent blockquote | undo redo | link unlink image | hr removeformat',
                  'customFirstNameButton customLastNameButton customEmailAddressButton customUsernameButton customOrgNameButton',
                ],
                resize: true,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
              onSelectionChange={handleEditorEvents}
            />
          </div>
          <div className={styles.inputCountContainer}>
            <InputCount
              lengthLimit={50}
              labelText={'Admin Name:'}
              value={adminNameBuffer}
              onInputChange={(e) => setAdminNameBuffer(e.target.value)}
              dataTestId={'emailAdminName'}
            />
          </div>
          <div className={styles.saveSettingsButtonContainer}>
            <CtaButton primary label={'Save Welcome Email Settings'} onClick={handleSaveSettings} />
          </div>
        </div>

        <Modal
          body={
            'Are you sure you want to send an email to all new users, are you sure you want to continue?'
          }
          isOpen={isSendEmailModalOpen}
          closeButton={'Cancel'}
          canClose
          doneButton={'Send'}
          header={'Welcome new users'}
          isWarningButtonPresent={true}
          modalSize={'medium'}
          onClose={() => setIsSendEmailModalOpen(false)}
          onSuccess={handleSendEmails}
        />
        <Toast
          type={toastType}
          open={isToastOpen}
          onClose={() => setIsToastOpen(false)}
          message={toastMessage}
          autoHideDuration={toastDuration}
        />
      </div>
    </ViewContainer>
  );
}
