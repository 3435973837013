import createMobxModel from './createMobxModel';

const MessageStatusPerRecipient = createMobxModel({
  name: 'messageStatusPerRecipient',

  fields: ['createdAt', 'reaction', 'reactionTimestamp', 'status'],

  relations: {
    one: {
      distributionList: { type: 'distributionList' },
      message: { type: 'message' },
      user: { type: 'user' },
    },
  },
});

export default MessageStatusPerRecipient;
