import Modal from 'admin/shared-components/Modal/Modal';
import { OrganizationSetting } from 'admin/types';
import { actions, useAppDispatch } from 'redux-stores';

type Props = {
  isOpen: boolean;
  options: {
    updatedSetting: OrganizationSetting;
  };
};

export default function SecurityRiskModal({ isOpen, options }: Props) {
  const { updatedSetting } = options;
  const dispatch = useAppDispatch();

  function handleSubmit() {
    dispatch(actions.updateSettings(updatedSetting));
    dispatch(actions.setModal());
  }

  function handleClose() {
    dispatch(actions.setModal());
  }

  function renderSettingText() {
    const settingName = Object.keys(updatedSetting)[0];
    switch (settingName) {
      case 'isPatientAccessCodeEnabled':
        return 'Access Code';
      case 'isPatientDateOfBirthEnabled':
        return 'Date of Birth';
    }
    return '';
  }

  return (
    <Modal
      isOpen={isOpen}
      closeButton="Cancel"
      onSuccess={handleSubmit}
      onClose={handleClose}
      canClose
      header={`Disable ${renderSettingText()}`}
      doneButton="I Understand"
      isWarningButtonPresent={true}
      modalSize="small"
      body={`It is highly recommended that you don’t turn off the ${renderSettingText()} setting. By turning off this setting you are exposing your organization to more security risks.`}
    />
  );
}
