import React, { Component } from 'react';
import { observer } from 'mobx-react';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { EscalationBanner } from '../';

const classes = BEM.with('EscalationMessageHeader');

class EscalationMessageHeader extends Component {
  static propTypes = {
    message: propTypes.message,
  };

  render() {
    const { message } = this.props;
    const { escalationExecution, isOutgoing, senderStatus, shouldEscalate } = message;
    const { displayId, status } = escalationExecution || {};
    let bannerFragment, escalationTextFragment;
    const direction = isOutgoing ? 'OUTGOING' : 'INCOMING';

    if (senderStatus === 'FAILED') {
      escalationTextFragment = <div className={classes('escalation-text')}>ESCALATED MESSAGE</div>;
    } else if (shouldEscalate && !displayId) {
      escalationTextFragment = <div className={classes('escalation-text-fade')} />;
      bannerFragment = <div className={classes('banner-fade')} />;
    } else {
      escalationTextFragment = (
        <div className={classes('escalation-text')}>ESCALATION {displayId}</div>
      );
      bannerFragment = (
        <div className={classes('banner')}>
          <EscalationBanner status={status} />
        </div>
      );
    }

    return (
      <div className={classes({ direction, senderStatus })}>
        <div className={classes('escalation-header')}>
          {escalationTextFragment}
          {bannerFragment}
        </div>
      </div>
    );
  }
}

export default observer(EscalationMessageHeader);
