import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pick, uniq } from 'lodash';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { CollabEntity, Tag } from '../../../../types';
import BEM from '../../../bem';
import { MapStateToProps, reduxInjectSelect } from '../../../utils/reduxInjectSelect';
import { BasicModal } from '../..';
import TagSelector from '../Tags/TagSelector';
import TagItem from '../Tags/TagItem';
import { entityTagId, standardTagId } from '../../../../redux-stores/Collaboration/selectors';

const { setModal } = actions;
const { batchEntityUpdate, fetchTag } = thunk;
const classes = BEM.with('CollaborationModal');

const reduxSelectors = {
  session: ['openModal'],
  collab: ['isRolesAdmin', 'isTeamAdmin', 'selectedOrgId'],
  entities: ['activeTab', 'checkedEntityIds', 'entitiesById', 'selectedEntity'],
  tags: ['checkedTagsById', 'selectedCategory', 'selectedTag', 'tagsById'],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type TagSelectorModalProps = { isOpen: boolean };

const emptyTag = { color: '', entityCount: 0, name: '', id: '' };

function TagSelectorModal({
  activeTab,
  checkedEntityIds,
  checkedTagsById,
  isRolesAdmin,
  isTeamAdmin,
  openModal,
  isOpen,
  entitiesById,
  selectedCategory,
  selectedOrgId,
  selectedEntity,
  selectedTag,
  tagsById,
}: ReduxProps & TagSelectorModalProps) {
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [tag, setTag] = useState<Tag>(emptyTag);
  const [entities, setEntities] = useState<CollabEntity[]>([]);
  const [entityIds, setEntityIds] = useState<string[]>([]);
  const [relevantTagIds, setRelevantTagIds] = useState<string[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!openModal || !['tagSelector', 'tagSelectorEditor'].includes(openModal?.name)) return;
    if (relevantTagIds.length === 1) {
      const relevantTagId = relevantTagIds[0];
      const relevantTag = relevantTagId && tagsById[relevantTagId];
      if (relevantTag) {
        setTag(relevantTag);
      } else {
        const id = relevantTagId.split(':')[1];
        dispatch(fetchTag({ id }));
      }
    } else {
      setTag(emptyTag);
    }
  }, [openModal, tagsById, entities, relevantTagIds, dispatch]);

  useEffect(() => {
    const isSelectedEntity = openModal?.name === 'tagSelectorEditor' && selectedEntity;
    const entityIds = isSelectedEntity && selectedEntity ? [selectedEntity.id] : checkedEntityIds;
    const entities =
      isSelectedEntity && selectedEntity
        ? [selectedEntity]
        : (Object.values(pick(entitiesById, entityIds)) as CollabEntity[]);
    const relevantTags = uniq(
      entities
        .filter((entity) => entityTagId(entity) && entity.organizationId)
        .map(
          (entity) => standardTagId(entityTagId(entity), entity.organizationId as string) as string
        )
    );
    setEntities(entities);
    setEntityIds(entityIds);
    setRelevantTagIds(relevantTags);
  }, [checkedEntityIds, entitiesById, openModal, selectedEntity]);

  const closeModal = () => {
    dispatch(setModal(undefined));
  };

  const updateTags = async () => {
    await batchEntityUpdate(dispatch, {
      activeTab,
      ids: entityIds,
      checkedTagsById,
      organizationId: selectedOrgId,
      entitiesById,
      entityUpdateProps: {
        tagColor: tag.color,
        tagName: tag.name,
        tagId: tag.id.split(':')[1] || '',
      },
      selectedCategory,
      selectedEntity,
      selectedTag,
      tagsById,
    });
  };

  const selectTag = (tag: Tag) => {
    setTag(tag);
    setSelectorOpen(false);
  };

  const renderTagPreview = () => {
    const colorHex = tag?.color?.replace('0x', '#');
    const name = tag?.name;

    if (openModal?.name === 'tagSelectorEditor' || tag?.id) {
      return (
        <TagItem
          colorHex={!name ? 'transparent' : colorHex}
          isAdmin={isRolesAdmin || isTeamAdmin}
          name={name || '-Not Tagged-'}
          noColor={!name}
        />
      );
    } else if (relevantTagIds.length > 1) {
      return (
        <TagItem
          colorHex="transparent"
          isAdmin={isRolesAdmin || isTeamAdmin}
          name="-Multiple Tags-"
          noColor
        />
      );
    }
    return (
      <TagItem
        colorHex="transparent"
        isAdmin={isRolesAdmin || isTeamAdmin}
        name="No tags in selected items"
        noColor
      />
    );
  };

  return (
    <BasicModal
      ariaLabelBody={'Tag Selector Info'}
      ariaLabelCancelButton={'Tag Selector Cancel'}
      ariaLabelCloseButton={'Tag SelectorClose'}
      ariaLabelHeader={'Tag Selector Header'}
      ariaLabelSubmitButton={'Confirm Tag Selection'}
      headerText={
        entities.length === 1 ? entities[0].displayName || '' : `${entities.length} items selected`
      }
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={updateTags}
      size={'medium'}
      theme={'normal'}
      submitText={'CHANGE'}
      useWCL
    >
      <div className={classes('')}>
        <label htmlFor="tagMenu" className={classes('label')}>
          Choose a tag
        </label>
        <div className={classes('selector')}>
          {selectorOpen ? (
            <TagSelector
              selectTag={selectTag}
              toggleHandler={() => setSelectorOpen(!selectorOpen)}
            />
          ) : (
            <div
              data-test-id="toggle tag selector"
              className={classes('tag-color-button')}
              id="tagMenu"
              onClick={() => setSelectorOpen(!selectorOpen)}
            >
              {renderTagPreview()}
            </div>
          )}
        </div>
      </div>
    </BasicModal>
  );
}

export default reduxInjectSelect<TagSelectorModalProps, ReduxProps, ReduxState>(reduxSelectors)(
  TagSelectorModal
);
