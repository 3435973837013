import React, { SetStateAction } from 'react';

import { User, UserData, UserInfo, UserSettings } from '../../../types';

import { SettingText } from '../../../shared-components/SettingText/SettingText';
import { Input } from '../../../shared-components/Input/Input';
import { Checkbox } from '../../../shared-components/Checkbox/Checkbox';
import avatarNoIconBG from '../../../images/test_avatar_noicon_bg.png';
import { ReactComponent as XIcon } from '../../../../common/images/x-gray-icon.svg';

import styles from './index.module.css';

type UserInfoFormProps = {
  isProductSettingsAdmin: boolean;
  isPersonaBasedManagementAllowed: boolean;
  selectedUser: User;
  setUnlinkPhoneNumber: React.Dispatch<SetStateAction<string>>;
  setUserInfo: (userInfo: UserInfo) => void;
  userInfo: UserInfo;
  userSettings?: UserSettings;
  setUserSettings: (userSettings: UserSettings) => void;
  userData: UserData;
};

export default function UserInfoForm({
  isProductSettingsAdmin,
  isPersonaBasedManagementAllowed,
  setUnlinkPhoneNumber,
  selectedUser,
  setUserInfo,
  setUserSettings,
  userInfo,
  userData,
  userSettings,
}: UserInfoFormProps) {
  return (
    <div className={styles.userSettings}>
      <div className={styles.userInfo}>
        <div className={styles.avatar}>
          <img
            alt={'avatar'}
            src={selectedUser?.avatarUrl || avatarNoIconBG}
            width={140}
            height={140}
          />
        </div>
        <div>
          <div className={styles.field}>
            <SettingText label={'Display Name'} size={'large'} />
            <Input
              value={userInfo.displayName}
              onInputChange={(e) => {
                setUserInfo({ ...userInfo, displayName: e.target.value });
              }}
            />
          </div>
          <div className={styles.field}>
            <SettingText label={'Title'} size={'large'} />
            <Input
              value={userInfo.title}
              onInputChange={(e) => {
                setUserInfo({ ...userInfo, title: e.target.value });
              }}
            />
          </div>
          <div className={styles.field}>
            <SettingText label={'Department'} size={'large'} />
            <Input
              value={userInfo.department}
              onInputChange={(e) => {
                setUserInfo({ ...userInfo, department: e.target.value });
              }}
            />
          </div>
          {userData.username !== null && (
            <div className={styles.field}>
              <SettingText label={'Username'} size={'large'} />
              <div className={styles.settingReadInfo}>{userData.username}</div>
            </div>
          )}
          <div className={styles.field}>
            <SettingText label={'Email Address'} size={'large'} />
            <div className={styles.settingReadInfo}>
              {userData?.emailAddresses[0]?.emailAddress}
            </div>
          </div>
          {isProductSettingsAdmin && (
            <div className={styles.field}>
              <SettingText label={'Association'} size={'large'} />
              <div className={styles.settingReadInfo}>{selectedUser.association}</div>
            </div>
          )}
          {isPersonaBasedManagementAllowed && (
            <PersonaFragment userSettings={userSettings} setUserSettings={setUserSettings} />
          )}
          {userData.phoneNumbers &&
            userData.phoneNumbers.length > 0 &&
            userData.phoneNumbers[0].phoneNumber[0].$t && (
              <div className={styles.phoneNumberFields}>
                <SettingText label={'Phone Numbers'} size={'large'} />
                <div className={styles.phoneNumbers}>
                  {userData.phoneNumbers.map((currentNumber) => {
                    return currentNumber.phoneNumber.map((phoneNumberData) => {
                      return (
                        <div className={styles.phoneNumber} key={styles.phoneNumber}>
                          <div className={styles.number}>{phoneNumberData.$t}</div>
                          <div
                            className={styles.deleteIcon}
                            onClick={async () => {
                              const confirm = await window.confirm(
                                'Are you sure you want to delete this phone?'
                              );
                              if (confirm) {
                                setUnlinkPhoneNumber(phoneNumberData.$t);
                              }
                            }}
                          >
                            <XIcon />
                          </div>
                        </div>
                      );
                    });
                  })}
                </div>
              </div>
            )}
          {selectedUser?.pagers && selectedUser?.pagers.length > 0 && (
            <div className={styles.field}>
              <SettingText label={'Pager Number'} size={'large'} alignment={'top'} />
              <div className={styles.settingReadInfo}>
                {selectedUser?.pagers.map((currentPager) => {
                  return (
                    <div className={styles.pagerNumber} key={currentPager}>
                      {currentPager}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function PersonaFragment({
  setUserSettings,
  userSettings,
}: {
  setUserSettings: (us: UserSettings) => void;
  userSettings?: UserSettings;
}) {
  return userSettings ? (
    <>
      <div className={styles.field}>
        <SettingText label={'Permission Groups'} size={'large'} />
        <div className={styles.settingReadInfo}>
          {userSettings?.personaName || 'No Permission Group Assigned'}
        </div>
      </div>
      <div className={styles.field}>
        <SettingText label={'Exclude'} size={'large'}>
          <div className={styles.tooltip}>
            ?
            <span className={styles.tooltipText}>
              When a user is excluded from Personas, their user permissions MUST be managed
              individually
            </span>
          </div>
        </SettingText>
        <div className={styles.checkBoxContainer}>
          <Checkbox
            checked={userSettings?.personaMappingExclusion}
            onClick={() => {
              setUserSettings({
                ...userSettings,
                personaMappingExclusion: !userSettings.personaMappingExclusion,
              });
            }}
          />
        </div>
      </div>
    </>
  ) : null;
}
