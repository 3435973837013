import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BEM from '../../../bem';
import passwordValidation from '../../../utils/passwordValidation';

const classes = BEM.with('ValidateNewPassword');

class ValidateNewPassword extends Component {
  componentDidMount() {
    this._testValue();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this._testValue();
    }
  }

  static propTypes = {
    focus: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  state = {
    digits: false,
    min: false,
    symbols: false,
    uppercase: false,
  };

  render() {
    const { focus } = this.props;
    const { digits, min, symbols, uppercase } = this.state;

    return (
      <div className={classes()}>
        <div className={classes('list', { focus: focus.new })}>
          <ul>
            <li className={classes('list-item', { valid: min })}>Minimum of 8 characters</li>
            <li className={classes('list-item', { valid: uppercase })}>A capital letter</li>
            <li className={classes('list-item', { valid: digits })}>A number</li>
            <li className={classes('list-item', { valid: symbols })}>A special characters</li>
          </ul>
        </div>
      </div>
    );
  }

  _testValue = () => {
    const validationSchema = passwordValidation(this.props.value, true);
    this.setState(validationSchema);
  };
}

export default observer(ValidateNewPassword);
