import React, { useEffect, useState } from 'react';
import { last } from 'lodash';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Avatar } from '@tigerconnect/web-component-library';
import { Button, Switch } from '../../../components/WebComponents';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { entityServerId } from '../../../../redux-stores/Collaboration/selectors';
import { Role, RoleEscalationPolicy } from '../../../../types';
import BEM from '../../../bem';
import { MapStateToProps, reduxInjectSelect } from '../../../utils/reduxInjectSelect';
import { Modal } from '../..';

import { ReactComponent as DownArrow } from '../../../images/icon-dropdown-black.svg';
import { ReactComponent as CloseIcon } from '../../../images/close.svg';
import { ReactComponent as GreenCheckBox } from '../../../images/on.svg';
import BackupSelector from './BackupSelector';
import TimeSelector from './TimeSelector';

const { setModal } = actions;
const { batchEntityUpdate } = thunk;
const classes = BEM.with('CollaborationEscalationModal');

const reduxSelectors = {
  session: ['openModal'],
  collab: ['selectedOrgId'],
  entities: ['activeTab', 'entitiesById', 'selectedEntity'],
  tags: ['checkedTagsById', 'selectedCategory', 'selectedTag', 'tagsById'],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type EscalationModalProps = {
  isOpen: boolean;
};

function EscalationModal({
  activeTab,
  checkedTagsById,
  openModal,
  entitiesById,
  selectedCategory,
  selectedOrgId,
  isOpen,
  selectedEntity,
  selectedTag,
  tagsById,
}: ReduxProps & EscalationModalProps) {
  const dispatch = useDispatch();
  const [backupTwo, setBackupTwo] = useState(false);
  const [escalationOn, setEscalationOn] = useState(false);
  const [ogRecipientTimeSelectorOpen, setOgRecipientTimeSelectorOpen] = useState(false);
  const [backupOneTimeSelectorOpen, setBackupOneTimeSelectorOpen] = useState(false);
  const [backupTwoTimeSelectorOpen, setBackupTwoTimeSelectorOpen] = useState(false);
  const [backupOneSelectorOpen, setBackupOneSelectorOpen] = useState(false);
  const [backupTwoSelectorOpen, setBackupTwoSelectorOpen] = useState(false);
  const [ogRecipientTime, setOgRecipientTime] = useState<number | undefined>();
  const [backupOneName, setBackupOneName] = useState('');
  const [backupOneToken, setBackupOneToken] = useState('');
  const [backupOneTime, setBackupOneTime] = useState<number | undefined>();
  const [backupTwoName, setBackupTwoName] = useState('');
  const [backupTwoToken, setBackupTwoToken] = useState('');
  const [backupTwoTime, setBackupTwoTime] = useState<number | undefined>();
  const [alwaysEscalate, setAlwaysEscalate] = useState(false);
  const [saveReady, setSaveReady] = useState(false);
  const selectedRole = selectedEntity as Role;

  useEffect(() => {
    if (openModal?.name !== 'escalation') return;
    const policy = (selectedRole?.metadata?.escalation_policy || {}) as RoleEscalationPolicy;
    const { always_escalate, escalation_path, target_duration } = policy;
    setEscalationOn(!!selectedRole?.metadata?.escalation_policy);
    setBackupTwo((escalation_path && escalation_path.length === 2) || false);
    setOgRecipientTime(target_duration);
    setBackupOneName(escalation_path?.[0]?.targets?.[0]?.display_name || '');
    setBackupOneToken(escalation_path?.[0]?.targets?.[0]?.token || '');
    setBackupOneTime(escalation_path?.[0]?.duration);
    setBackupTwoName(escalation_path?.[1]?.targets?.[0]?.display_name || '');
    setBackupTwoToken(escalation_path?.[1]?.targets?.[0]?.token || '');
    setBackupTwoTime(escalation_path?.[1]?.duration);
    setAlwaysEscalate(!!always_escalate);
  }, [selectedRole, openModal]);

  const closeModal = () => {
    dispatch(setModal(undefined));
    handleClearForm();
  };

  useEffect(() => {
    if (!escalationOn && selectedRole?.metadata?.escalation_policy) {
      setSaveReady(true);
      return;
    }
    if (!ogRecipientTime || !backupOneToken || !backupOneTime) {
      setSaveReady(false);
      return;
    }
    if (backupTwo && (!backupTwoToken || !backupTwoTime)) {
      setSaveReady(false);
      return;
    }

    setSaveReady(true);
  }, [
    backupOneToken,
    backupOneTime,
    backupTwo,
    backupTwoToken,
    backupTwoTime,
    escalationOn,
    ogRecipientTime,
    selectedRole,
  ]);

  const handleSaveEscalationPolicy = () => {
    const escalationPath = [
      {
        targets: [{ display_name: backupOneName, token: backupOneToken }],
        duration: backupOneTime || 0,
      },
    ];

    if (backupTwo) {
      escalationPath.push({
        targets: [{ display_name: backupTwoName, token: backupTwoToken }],
        duration: backupTwoTime || 0,
      });
    }

    const payload: { [k: string]: RoleEscalationPolicy } = {
      escalationPolicy: {
        always_escalate: alwaysEscalate,
        escalation_path: escalationPath,
        target: entityServerId(selectedRole.id),
        target_duration: ogRecipientTime,
      },
    };

    if (!escalationOn && selectedRole?.metadata?.escalation_policy) {
      payload.escalationPolicy = {};
    }

    batchEntityUpdate(dispatch, {
      activeTab,
      ids: [selectedRole.id],
      checkedTagsById,
      organizationId: selectedOrgId,
      entitiesById,
      entityUpdateProps: payload,
      selectedCategory,
      selectedEntity: selectedRole,
      selectedTag,
      tagsById,
    });
    closeModal();
  };

  const handleSaveBackupTime = (selector: number, time: number) => {
    setOgRecipientTime(selector === 1 ? time : ogRecipientTime);
    setBackupOneTime(selector === 2 ? time : backupOneTime);
    setBackupTwoTime(selector === 3 ? time : backupTwoTime);
  };

  const handleSaveTarget = (selector: number, name: string, token: string) => {
    const parsedToken = last(token.split(':')) as string;
    setBackupOneName(selector === 1 ? name : backupOneName);
    setBackupOneToken(selector === 1 ? parsedToken : backupOneToken);
    setBackupTwoToken(selector === 2 ? parsedToken : backupTwoToken);
    setBackupTwoName(selector === 2 ? name : backupTwoName);
    setBackupOneSelectorOpen(false);
    setBackupTwoSelectorOpen(false);
  };

  const handleClearForm = () => {
    setOgRecipientTimeSelectorOpen(false);
    setBackupOneTimeSelectorOpen(false);
    setBackupTwoTimeSelectorOpen(false);
    setBackupOneSelectorOpen(false);
    setBackupTwoSelectorOpen(false);
    setOgRecipientTime(undefined);
    setBackupOneTime(undefined);
    setBackupTwoTime(undefined);
    setBackupOneToken('');
    setBackupTwoToken('');
    setBackupOneName('');
    setBackupTwoName('');
    setBackupTwo(false);
    setAlwaysEscalate(false);
  };

  const formatBackupInputTime = (time: number | undefined) => {
    if (time) {
      return `${time} Min${time === 1 ? '' : 's'}`;
    }
    return '';
  };

  const backgroundColor = selectedRole?.metadata?.tag_color.replace('0x', '#') || '#4570a9';

  return (
    <Modal
      ariaLabelCloseButton={'On Duty Close'}
      ariaLabelHeader={'On Duty Header'}
      bodyClass={classes('body')}
      closeClass={classes(`close-button-normal`)}
      header={''}
      headerClass={classes('header')}
      isOpen={isOpen}
      overlayClassName={classes('')}
      size={'medium'}
      className={classes('modal')}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
    >
      <div className={classes('escalation-content')}>
        <div className={classes('close-escalation-button')} onClick={() => closeModal()}>
          <CloseIcon className={classes('close-icon')} />
        </div>
        <div className={classes('curr-role-container', { closed: !escalationOn })}>
          <div className={classes('curr-role-icon')}>
            <Avatar size="large" shape="squircle" type="role" bgColor={backgroundColor} />
          </div>
          <div className={classes('curr-role-names')}>
            <div className={classes('curr-role-display-name')}>{selectedRole?.displayName}</div>
            {selectedRole?.metadata?.tag_name && (
              <div className={classes('curr-role-tag-name')}>
                {selectedRole?.metadata?.tag_name}
              </div>
            )}
          </div>
        </div>
        <div className={classes('sub-container')}>
          <div className={classes('title')}>Escalation</div>
          <div>
            <div className={classes('sub-container-content')}>
              <div className={classes('switch-item')}>
                <div className={classes('switch-text')}>
                  You can pre-define an escalation path to help ensure a member of the team has this
                  role covered.
                </div>
                <div
                  onClick={() => {
                    setEscalationOn(!escalationOn);
                  }}
                >
                  <Switch checked={escalationOn} dataTestId="openEscalation" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {escalationOn && (
          <>
            <div className={classnames(classes('sub-container'), classes('sub-container'))}>
              <div className={classes('title')}>
                ESCALATION POLICY
                <div
                  className={classes('clear-form-button')}
                  data-test-id="clear escalation form"
                  onClick={handleClearForm}
                >
                  clear
                </div>
              </div>
              <div className={classes('sub-container-content')}>
                <div className={classes('esc-pol-row')}>
                  <div className={classes('progress-col')}>
                    <div className={classes('start-symbol')} />
                  </div>
                  <div className={classes('name-col')}>
                    <label htmlFor="ogRecipient">Original Recipient</label>
                    <div className={classes('og-recipient-name')}>{selectedRole?.displayName}</div>
                  </div>
                  <div className={classes('time-col')}>
                    <label htmlFor="originalRecTime">Time To Acknowledge</label>
                    <div>
                      <DownArrow className={classes('down-arrow')} />
                      <input
                        type="text"
                        id="originalRecTime"
                        placeholder="Select Time"
                        readOnly
                        value={formatBackupInputTime(ogRecipientTime)}
                        className={classes('esc-pol-input')}
                        onClick={() => setOgRecipientTimeSelectorOpen(true)}
                      />
                    </div>
                    {ogRecipientTimeSelectorOpen && (
                      <TimeSelector
                        toggleHandler={() => setOgRecipientTimeSelectorOpen(false)}
                        saveHandler={handleSaveBackupTime}
                        selector={1}
                      />
                    )}
                  </div>
                </div>

                <div className={classes('esc-pol-row')}>
                  <div className={classes('progress-col')}>
                    <div className={classes('mid-symbol')} />
                  </div>
                  <div className={classes('name-col')}>
                    <label htmlFor="backupOne">Backup 1</label>
                    <div
                      className={classes('esc-pol-backup')}
                      onClick={() => setBackupOneSelectorOpen(true)}
                    >
                      {backupOneName && (
                        <div
                          className={classes('cancel-circle')}
                          onClick={() => handleSaveTarget(1, '', '')}
                        >
                          <CloseIcon className={classes('cancel-icon')} />
                        </div>
                      )}
                      <input
                        type="text"
                        id="backupOne"
                        value={backupOneName}
                        readOnly
                        placeholder="Select Member"
                        className={classes('esc-pol-input')}
                      />
                    </div>
                    {backupOneSelectorOpen && (
                      <BackupSelector
                        selector={1}
                        toggleHandler={() => {
                          setBackupOneSelectorOpen(false);
                        }}
                        saveHandler={handleSaveTarget}
                        ogToken={selectedRole.id}
                        backupOneToken={backupOneToken}
                        backupTwoToken={backupTwoToken}
                      />
                    )}
                  </div>

                  <div className={classes('time-col')}>
                    <label htmlFor="backupOneTime">Time To Acknowledge</label>
                    <div>
                      <DownArrow className={classes('down-arrow')} />
                      <input
                        type="text"
                        id="backupOneTime"
                        placeholder="Select Time"
                        value={formatBackupInputTime(backupOneTime)}
                        readOnly
                        className={classes('esc-pol-input')}
                        onClick={() => setBackupOneTimeSelectorOpen(true)}
                      />
                    </div>
                    {backupOneTimeSelectorOpen && (
                      <TimeSelector
                        toggleHandler={() => setBackupOneTimeSelectorOpen(false)}
                        saveHandler={handleSaveBackupTime}
                        selector={2}
                      />
                    )}
                  </div>
                </div>

                <div className={classes('esc-pol-row')}>
                  <div className={classes('progress-col')}>
                    <div
                      className={classes('mid-symbol', {
                        inactive: !backupTwo,
                      })}
                    />
                  </div>
                  {backupTwo ? (
                    <>
                      <div className={classes('name-col')}>
                        <label htmlFor="backupTwo">Backup 2 (Optional)</label>
                        <div
                          className={classes('esc-pol-backup')}
                          onClick={() => setBackupTwoSelectorOpen(true)}
                        >
                          {backupTwoName && (
                            <div
                              className={classes('cancel-circle')}
                              onClick={() => handleSaveTarget(2, '', '')}
                            >
                              <CloseIcon className={classes('cancel-icon')} />
                            </div>
                          )}
                          <input
                            type="text"
                            id="backupTwo"
                            value={backupTwoName}
                            placeholder="Select Member"
                            readOnly
                            className={classes('esc-pol-input')}
                          />
                        </div>
                        {backupTwoSelectorOpen && (
                          <BackupSelector
                            selector={2}
                            toggleHandler={() => {
                              setBackupTwoSelectorOpen(false);
                            }}
                            saveHandler={handleSaveTarget}
                            ogToken={selectedRole.id}
                            backupOneToken={backupOneToken}
                            backupTwoToken={backupTwoToken}
                          />
                        )}
                      </div>
                      <div className={classes('time-col')}>
                        <label htmlFor="backupTwoTime">Time To Acknowledge</label>
                        <div>
                          <DownArrow className={classes('down-arrow')} />
                          <input
                            type="text"
                            id="backupTwoTime"
                            readOnly
                            placeholder="Select Time"
                            value={formatBackupInputTime(backupTwoTime)}
                            className={classes('esc-pol-input')}
                            onClick={() => setBackupTwoTimeSelectorOpen(true)}
                          />
                        </div>
                        {backupTwoTimeSelectorOpen && (
                          <TimeSelector
                            toggleHandler={() => setBackupTwoTimeSelectorOpen(false)}
                            saveHandler={handleSaveBackupTime}
                            selector={3}
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <div
                      className={classes('optional-backup-button')}
                      data-test-id="add backup 2 button"
                      onClick={() => setBackupTwo(true)}
                    >
                      Add Backup 2 (Optional)
                    </div>
                  )}
                </div>

                <div className={classes('esc-pol-row')}>
                  <div className={classes('progress-col')}>
                    <div className={classes('end-symbol')} />
                  </div>
                  <div className={classes('escalation-ends-text')}>
                    <label htmlFor="escalationEnds">Escalation Ends</label>
                  </div>
                </div>
              </div>
            </div>

            <div className={classnames(classes('sub-container'), classes('sub-container'))}>
              <div className={classes('title')}>POLICY OPTION</div>
              <div className={classes('sub-container-content')}>
                <div className={classes('policy-option')}>
                  <div className={classes('green-checkbox-container')}>
                    {alwaysEscalate && <GreenCheckBox className={classes('green-checkbox')} />}
                    <input
                      id="alwaysEscalate"
                      className={classes('custom-checkbox')}
                      type="checkbox"
                      checked={alwaysEscalate}
                      data-test-id="always escalate checkbox"
                      onChange={() => setAlwaysEscalate(!alwaysEscalate)}
                    />{' '}
                    <label
                      htmlFor="alwaysEscalate"
                      className={classes('green-label', { checked: alwaysEscalate })}
                    >
                      Always Escalate for This Role
                    </label>
                  </div>
                  <p>
                    All messages sent to this role will always be escalated. Please note the
                    message-sender is the default party to determine which message should be
                    escalated, enabling this option overrides the default behavior.
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        <div className={classes('actions')}>
          <Button
            dataTestId="escalation form cancel button"
            onClick={closeModal}
            label="CANCEL"
            color="neutral"
            outline
            alignment="right"
          />
          {saveReady && (
            <Button
              onClick={handleSaveEscalationPolicy}
              dataTestId="save role escalation policy"
              label="SAVE"
              outline
            />
          )}
        </div>
      </div>
    </Modal>
  );
}

export default reduxInjectSelect<EscalationModalProps, ReduxProps, ReduxState>(reduxSelectors)(
  EscalationModal
);
