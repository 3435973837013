import React, { useCallback } from 'react';
import { isEmpty, uniq } from 'lodash';
import { Call } from '../../../types';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import RoundButton from '../RoundButton';
import { ReactComponent as IconVideoSvg } from '../../images/icon-video.svg';

type VideoCallButtonProps = {
  disabled?: boolean;
  entity: {
    members?: { id: string }[];
    recipientId: string;
    recipientName: string;
  };
  isInverseHover?: boolean;
  isSmall?: boolean;
  isWhite?: boolean;
  maxMembers?: number;
  origin: string;
};

type MobxProps = {
  currentCall: Call;
  currentUserId: string;
  videoCallSetUp: (...args: unknown[]) => Promise<void>;
};

function VideoCallButton({
  currentCall,
  currentUserId,
  disabled = false,
  entity,
  isInverseHover = false,
  isSmall = false,
  isWhite = false,
  maxMembers,
  origin,
  videoCallSetUp,
}: VideoCallButtonProps & MobxProps) {
  const { recipientName, members = [] } = entity;
  let tooManyMembers = false;
  if (maxMembers) {
    const callMembers = uniq(members.map((m) => m.id).concat([currentUserId]));
    tooManyMembers = callMembers.length > maxMembers;
  }
  const isDisabled = disabled || !isEmpty(currentCall) || tooManyMembers;
  const onClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const setupCall = async () => {
        await videoCallSetUp(entity, origin);
      };
      setupCall();
    },
    [entity, origin, videoCallSetUp]
  );

  return (
    <RoundButton
      color="#4A657B"
      dataTestId={'call-button'}
      disabled={isDisabled}
      img={IconVideoSvg}
      isInverseHover={isInverseHover}
      isSmall={isSmall}
      isWhite={isWhite}
      onClick={onClick}
      title={
        isDisabled
          ? ''
          : `${members && members.length > 2 ? 'Group video' : 'Video'} call with ${recipientName}`
      }
    />
  );
}

export default mobxInjectSelect<VideoCallButtonProps, MobxProps>({
  callStore: ['currentCall', 'videoCallSetUp'],
  sessionStore: ['currentUserId'],
})(VideoCallButton);
