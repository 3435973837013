import React from 'react';
import { reduxInjectSelect, MapStateToProps } from '../../../utils/reduxInjectSelect';
import { ReduxState } from '../../../../redux-stores';
import BEM from '../../../bem';
import RoleEditor from '../RoleEditor/RoleEditor';
import TeamEditor from '../TeamEditor/TeamEditor';
import { Role } from '../../../../types';

const classes = BEM.with('CollaborationRoles');

const reduxSelectors = {
  entities: ['newEntityType', 'selectedEntity'],
  tags: ['checkedTagsById', 'selectedTag', 'tagsById'],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type EntityContainerProps = {};

function EntityContainer({
  checkedTagsById,
  newEntityType,
  selectedEntity,
  selectedTag,
}: ReduxProps & EntityContainerProps) {
  const checkTagFirstEntity =
    Object.keys(checkedTagsById).length === 1 ? Object.values(checkedTagsById)[0] : undefined;
  const defaultTag = selectedTag?.name ? selectedTag : checkTagFirstEntity;
  const selectedEntityType = selectedEntity?.$entityType;

  return (
    <div
      className={classes('right-container', {
        open: !!(selectedEntity?.id || newEntityType),
      })}
    >
      {(newEntityType === 'role' || (selectedEntityType === 'role' && !newEntityType)) && (
        <RoleEditor defaultTag={defaultTag} role={selectedEntity as Role} />
      )}
      {(newEntityType === 'team' || (selectedEntityType === 'team' && !newEntityType)) && (
        <TeamEditor defaultTag={defaultTag} />
      )}
    </div>
  );
}

export default reduxInjectSelect<EntityContainerProps, ReduxProps, ReduxState>(reduxSelectors)(
  EntityContainer
);
