import { useState } from 'react';
import * as Sentry from '@sentry/react';

import BEM from '../../../common/bem';
import { ReactComponent as PriorityIcon } from '../../../common/images/priority-icon.svg';

const classes = BEM.with('ErrorBoundary');

const CONNECTION_UNAVAILABLE = 'connection-unavailable';

type SentryFallbackRenderProps = Parameters<Sentry.FallbackRender>[0];

type ErrorBoundaryFallbackProps = {
  relayError: () => void;
  setCurrentUserId: (id: unknown) => void;
  signOut: (req: {
    ignoreErrors: boolean;
    returnErrors: boolean;
    wipeSession: boolean;
  }) => Promise<string>;
} & SentryFallbackRenderProps;

export const ErrorBoundaryFallback = (props: ErrorBoundaryFallbackProps) => {
  const [signOutError, setSignOutError] = useState<string>();
  let errorFragment;

  if (signOutError === CONNECTION_UNAVAILABLE) {
    errorFragment = (
      <div className={classes('offline-error')}>
        Please make sure you are connected to the network and try again.
      </div>
    );
  }

  const _signOut = async () => {
    const { setCurrentUserId, signOut } = props;

    const error = await signOut({
      ignoreErrors: false,
      returnErrors: true,
      wipeSession: false,
    });

    if (error) {
      if (error === CONNECTION_UNAVAILABLE) {
        setSignOutError(error);
      } else {
        setCurrentUserId(null);
      }
    }
  };

  return (
    <div className={classes()}>
      <div className={classes('container')}>
        <div className={classes('content')}>
          <PriorityIcon className={classes('header-icon')} />
          <div className={classes('title-text')}>Something went wrong.</div>
          <div className={classes('title-subtext')}>
            {"We've been notified of this issue."}
            <br />
            {"Please hit 'Refresh' below to try again."}
          </div>
          <div className={classes('error-container')}>{errorFragment}</div>
        </div>
        <div className={classes('refresh-btn')} onClick={_signOut}>
          Refresh
        </div>
      </div>
    </div>
  );
};
