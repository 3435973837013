import EventEmitter from 'events';
import { action, observable, runInAction } from 'mobx';
import queue from 'emitter-queue';

const ONLINE_HIDE_DELAY = 3000;
const UNREACHABLE_SHOW_DELAY = 4000;

export default class NetworkStatusStore {
  @observable networkStatus = 'OFFLINE';
  @observable messageVisible = false;
  _sawFirstOnline = false;
  _onlineHideTimeout = null;
  _unreachableShowTimeout = null;
  ignoreNetworkStatusChange = false;

  events = queue(new EventEmitter());

  constructor({ client, stores }) {
    this.client = client;
    this.stores = stores;
  }

  mounted() {
    runInAction(() => {
      this.setNetworkStatus(this.client.networkStatus);
    });

    this.client.on('networkStatus:change', this._handleNetworkStatusChange);
  }

  dispose() {
    this.client.removeListener('networkStatus:change', this._handleNetworkStatusChange);
    clearTimeout(this._onlineHideTimeout);
    clearTimeout(this._unreachableShowTimeout);
    this._onlineHideTimeout = null;
    this._unreachableShowTimeout = null;
  }

  @action('NetworkStatusStore.setNetworkStatus') setNetworkStatus(networkStatus) {
    this.networkStatus = networkStatus;
  }

  @action('NetworkStatusStore.setMessageVisible') setMessageVisible(messageVisible) {
    this.messageVisible = messageVisible;

    if (this.messageVisible) {
      this.events.emit('networkStatusBannerVisible', {
        networkStatus: this.networkStatus,
      });
    }
  }

  _handleNetworkStatusChange = (options) => {
    const { networkStatus } = options;

    if (this.ignoreNetworkStatusChange || !this.client.isSignedIn) return;
    if (!this._sawFirstOnline && networkStatus !== 'ONLINE') return;

    clearTimeout(this._onlineHideTimeout);
    this._onlineHideTimeout = null;

    if (networkStatus === 'ONLINE') {
      this._handleOnline(options);
    } else if (networkStatus === 'UNREACHABLE') {
      this._handleUnreachable(options);
    } else if (networkStatus === 'CONNECTING') {
      this._handleConnecting(options);
    } else if (networkStatus === 'OFFLINE') {
      this._handleOffline(options);
    }
  };

  _handleConnecting = ({ networkStatus, previousStatus }) => {
    this.setNetworkStatus(networkStatus);

    if (!this._unreachableShowTimeout && previousStatus !== 'ONLINE') {
      this.setMessageVisible(true);
    }
  };

  _handleOnline = ({ networkStatus }) => {
    this.setNetworkStatus(networkStatus);

    if (this.messageVisible && this._unreachableShowTimeout === null) {
      this._onlineHideTimeout = setTimeout(() => {
        this._onlineHideTimeout = null;
        this.setMessageVisible(false);
      }, ONLINE_HIDE_DELAY);
    }

    clearTimeout(this._unreachableShowTimeout);

    this._sawFirstOnline = true;
    this._unreachableShowTimeout = null;
  };

  _handleUnreachable = () => {
    this.setNetworkStatus('CONNECTING');

    if (!this.messageVisible && this._unreachableShowTimeout === null) {
      this._unreachableShowTimeout = setTimeout(() => {
        this._unreachableShowTimeout = null;
        this.setNetworkStatus('UNREACHABLE');
        this.setMessageVisible(true);
      }, UNREACHABLE_SHOW_DELAY);
    }
  };

  _handleOffline = ({ networkStatus }) => {
    this.setNetworkStatus(networkStatus);

    clearTimeout(this._unreachableShowTimeout);

    this._unreachableShowTimeout = null;
    this.setMessageVisible(true);
  };
}
