import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppTypes } from '../../models/enums';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { ReactComponent as SettingsIcon } from '../images/settings-icon.svg';
import { ContextMenu, MenuItemList, MenuItem } from './ContextMenu';
import { UserAvatar, UserName } from './';

const classes = BEM.with('UserInfoHeader');
const MENU_OFFSET_X = 0;
const MENU_OFFSET_Y = -5;
const ORG_SELECTOR_APPS = [AppTypes.COLLABORATION, AppTypes.ROLES, AppTypes.ANALYTICS];

class UserInfoHeader extends Component {
  static propTypes = {
    currentAppSelected: PropTypes.string.isRequired,
    currentOrganization: propTypes.organization,
    currentUser: propTypes.user,
    isExtendedAutoForwardOptionsEnabled: PropTypes.bool,
    isProductRolesAdmin: PropTypes.func.isRequired,
    isProviderNetwork: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    openProfile: PropTypes.func.isRequired,
    openOrgSelectModal: PropTypes.func.isRequired,
    openPatientUserSettings: PropTypes.func.isRequired,
  };

  state = {
    userDetails: null,
  };

  render() {
    const {
      currentAppSelected,
      currentOrganization,
      currentUser,
      isExtendedAutoForwardOptionsEnabled,
      isProviderNetwork,
      isProductRolesAdmin,
      isUniversalTaggingAllowed,
      openAdminOrgSelectModal,
      openOrgSelectModal,
      openPatientUserSettings,
    } = this.props;
    const { userDetails } = this.state;
    const displayText = { displayName: 'User Settings' };
    if (!currentUser) return null;

    const showDndAfStatus = isExtendedAutoForwardOptionsEnabled
      ? !!currentOrganization?.autoForwardEntitiesIds
      : !!currentOrganization?.autoForwardReceiver;

    return (
      <div className={classes()}>
        <div className={classes('user-details-wrapper')}>
          {ORG_SELECTOR_APPS.includes(currentAppSelected) && isProductRolesAdmin() && (
            <div
              className={classes('open-org-selector')}
              onClick={
                isUniversalTaggingAllowed
                  ? currentAppSelected === AppTypes.ANALYTICS
                    ? openOrgSelectModal
                    : openAdminOrgSelectModal
                  : openOrgSelectModal
              }
              aria-label="Org Selector"
            >
              Orgs +
            </div>
          )}
          {isProviderNetwork ? (
            <ContextMenu
              event="click"
              theme="vertical"
              position="bottominnerleft"
              onShow={this._onShow}
              onHide={this._onHide}
              offsetX={MENU_OFFSET_X}
              offsetY={MENU_OFFSET_Y}
              relativeTo={userDetails}
              menu={
                <MenuItemList>
                  <MenuItem onClick={this._openProfileEditor} ariaLabel="Profile Settings">
                    <span>Profile Settings</span>
                  </MenuItem>
                  <MenuItem onClick={this._openLogoutModal} ariaLabel="Logout">
                    <span>Logout</span>
                  </MenuItem>
                </MenuItemList>
              }
            >
              <div
                className={classes('user-details')}
                ref={this._setUserDetails}
                aria-label="User Detail Menu"
              >
                <div className={classes('user-details-text')} data-test-id={'user-info-header'}>
                  <UserName className={classes('user-name')} user={currentUser} />
                  <div className={classes('user-status')}>{currentUser.status}</div>
                </div>
                <UserAvatar
                  className={classes('avatar')}
                  showDndAfStatus={showDndAfStatus}
                  showPresenceIndicator={true}
                  size={38}
                  user={{ ...currentUser }}
                />
              </div>
            </ContextMenu>
          ) : (
            <button
              aria-label="Patient User Settings"
              className={classes('user-settings-container')}
              ref={this._setUserDetails}
              onClick={openPatientUserSettings}
            >
              <UserName className={classes('user-name')} user={displayText} />
              <div className={classes('icon-container')}>
                <SettingsIcon aria-hidden />
              </div>
            </button>
          )}
        </div>
      </div>
    );
  }

  _openProfileEditor = (e) => {
    const { openProfile } = this.props;
    e.preventDefault();
    openProfile();
  };

  _openLogoutModal = () => {
    this.props.openModal('logoutModal');
  };

  _setUserDetails = (userDetails) => {
    this.setState({ userDetails });
  };
}

export default mobxInjectSelect({
  messengerStore: [
    'currentAppSelected',
    'currentOrganization',
    'isExtendedAutoForwardOptionsEnabled',
    'isProductRolesAdmin',
    'isUniversalTaggingAllowed',
    'openAdminOrgSelectModal',
    'openProfile',
    'openPatientUserSettings',
  ],
  modalStore: ['openModal'],
  networkStore: ['isProviderNetwork'],
  reactAdminStore: ['openOrgSelectModal'],
  sessionStore: ['currentUser'],
})(UserInfoHeader);
