import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../';
import { ReactComponent as OptInSvg } from '../../../images/role-opt-in-icon.svg';

import BEM from '../../../bem';

const classes = BEM.with('GeneralRoleModal');

class AssignedRoleModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
  };

  render() {
    const { isOpen, options } = this.props;

    const imgHeader = {
      image: OptInSvg,
      imgClassHeader: classes('img'),
    };

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        header={"You've been opted into a role"}
        headerClass={classes('header')}
        imgHeader={imgHeader}
        isOpen={isOpen}
        onRequestClose={this._requestClose}
        size={'medium'}
        footerPrimaryActions={
          <button type="button" className={classes('submit-btn')} onClick={this._requestClose}>
            GOT IT
          </button>
        }
      >
        <div className={classes('body-container')}>
          <div className={classes('row')}>
            {"You're now on duty for"}
            <div className={classes('display-name')}>{` ${options.event.role.displayName}, `}</div>
          </div>
          <div className={classes('row')}>{'designated by the administrator.'}</div>
        </div>
      </Modal>
    );
  }

  _requestClose = () => {
    this.props.closeModal();
  };
}

export default AssignedRoleModal;
