import React, { Component } from 'react';
import classNames from 'classnames';
import BEM from '../../bem';

const classes = BEM.with('FormFieldGroupSeparator');

class FieldGroupSeparator extends Component {
  render() {
    const { className } = this.props;

    return <div className={classNames(classes(), className)} />;
  }
}

export default FieldGroupSeparator;
