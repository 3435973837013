const MessageMetadataNamespaces = {
  ALERT: 'tigertext:message:alert',
  CONVERSATION_MENTIONS: 'tigertext:message:mentions',
  PATIENT_CARE: 'tigertext:message:patient_care',
  PATIENT_MESSAGING: 'tigertext:message:patient_messaging',
  ROLE_OWNER: 'tigertext:message:role:owner',
  ROLE_SENDER: 'tigertext:message:role:sender',
  VWR_CALL: 'tigertext:message:vwr:call',
} as const;

export type MessageMetadataNamespace =
  typeof MessageMetadataNamespaces[keyof typeof MessageMetadataNamespaces];

export default MessageMetadataNamespaces;
