import React, { useCallback, useEffect, useRef } from 'react';

import { CtaButton } from '../../shared-components/CtaButton/CtaButton';

import { AuthorizeOrgSetting } from '../../shared-components/AuthorizeSetting';
import mobxInjectSelect from '../../../common/utils/mobxInjectSelect';
import { BaseOrganizationSettingsItemList } from './BaseOrganizationSettingsItemList';
import { DesktopSettingsItemList } from './DesktopSettingsItemList';
import Tabs from './Tabs';
import { TigerConnectPatientEngagementSettingsItemList } from './TigerConnectPatientEngagementSettingsItemList';

import styles from './OrganizationSettings.module.css';
import OrgName from './OrgName';
import { thunk, useAppDispatch, useAppSelector } from 'redux-stores';

type OrganizationSettingsProps = {};

type MobxProps = {
  logPendoAnalytics: (p: { tracker: { name: string } }) => void;
};

function OrganizationSettings({ logPendoAnalytics }: OrganizationSettingsProps & MobxProps) {
  const dispatch = useAppDispatch();
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);
  const orgHeaderRef = useRef<HTMLDivElement>(null);
  const orgSettingsNavRef = useRef<HTMLDivElement>(null);

  const onSave = useCallback(
    () => thunk.saveSettings({ dispatch, logPendoAnalytics }),
    [dispatch, logPendoAnalytics]
  );

  useEffect(() => {
    const onScroll = () => {
      if (orgHeaderRef.current && orgSettingsNavRef.current) {
        const headerBounds = orgHeaderRef.current.getBoundingClientRect();
        const navBounds = orgSettingsNavRef.current.getBoundingClientRect();
        if (headerBounds.bottom > navBounds.top) {
          orgHeaderRef.current.setAttribute('sticky', 'sticky');
        } else {
          orgHeaderRef.current.removeAttribute('sticky');
        }
      }
    };

    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    thunk.fetchSettings(dispatch);
  }, [dispatch, selectedOrganizationId]);

  return (
    <div className={styles.orgSettingsContainer}>
      <header className={styles.header} ref={orgHeaderRef}>
        <OrgName />
        <div className={styles.actionButtons}>
          <CtaButton label="Cancel" dataTestId={'cancelSaveSettings'} />
          <CtaButton primary label="Save Settings" dataTestId={'saveSettings'} onClick={onSave} />
        </div>
      </header>
      <nav className={styles.navigationBar} ref={orgSettingsNavRef}>
        <Tabs onSave={onSave} />
      </nav>
      <section className={styles.settingsList}>
        <BaseOrganizationSettingsItemList />
        <AuthorizeOrgSetting settingNames={['featurePatientEngagement']}>
          <>
            <h2 className={styles.sectionDividingText}>TigerConnect Patient Engagement</h2>
            <TigerConnectPatientEngagementSettingsItemList />
          </>
        </AuthorizeOrgSetting>
        <h2 className={styles.sectionDividingText}>Desktop Settings</h2>
        <DesktopSettingsItemList />
      </section>
    </div>
  );
}

export default mobxInjectSelect<OrganizationSettingsProps, MobxProps>({
  trackerStore: ['logPendoAnalytics'],
})(OrganizationSettings);
