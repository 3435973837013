import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BEM from '../bem';

import ToolTip from '../../widgets/messenger/components/ToolTip';
import DotsIndicator from './DotsIndicator';

const classes = BEM.with('LongPressButton');

class LongPressButton extends Component {
  static propTypes = {
    getManualOffset: PropTypes.func,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isOn: PropTypes.bool,
    isOnText: PropTypes.string,
    isOffText: PropTypes.string,
    onCompletion: PropTypes.func,
    progress: PropTypes.number,
    setProgress: PropTypes.func,
    toolTipText: PropTypes.string,
  };

  state = {
    progress: this.props.progress || 0,
  };

  componentDidUpdate() {
    const { progress, onCompletion } = this.props;
    if (progress === 100) {
      setTimeout(() => {
        this.props.progress >= 100 && onCompletion();
        this.resetProgress();
      }, 250);
    }
  }

  setIntervalFunction(condition, progressInc) {
    if (condition) {
      this.setState({ progress: this.state.progress + progressInc });
      this.props.setProgress(this.state.progress + progressInc);
    } else {
      clearInterval(this.optFn);
    }
  }

  handleMouseDown = () => {
    clearInterval(this.optFn);
    this.optFn = setInterval(() => {
      this.setIntervalFunction(this.state.progress < 100, 5);
    }, 60);
  };

  handleMouseUp = () => {
    clearInterval(this.optFn);
    if (this.state.progress !== 100) {
      this.optFn = setInterval(() => {
        this.setIntervalFunction(this.state.progress > 0, -5);
      }, 60);
    }
  };

  resetProgress = () => {
    clearInterval(this.optFn);
    this.setState({ progress: 0 });
    this.props.setProgress(0);
  };

  render() {
    const {
      getManualOffset = () => ({}),
      isDisabled,
      isLoading,
      isOn,
      isOnText,
      isOffText,
      toolTipText,
    } = this.props;
    return (
      <div className={classes('')} aria-label={`long-press-button`}>
        <ToolTip
          getManualOffset={getManualOffset}
          hideArrow={!!isDisabled}
          waitTime={0}
          location="bottom"
          text={toolTipText}
        >
          <button
            aria-label={`long press button`}
            className={classes('button', {
              disabled: isDisabled,
              isLoading: isLoading,
            })}
            disabled={isDisabled}
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onMouseLeave={this.handleMouseUp}
          >
            {isLoading ? <DotsIndicator color="#ffffff" size={6} /> : isOn ? isOnText : isOffText}
          </button>
        </ToolTip>
      </div>
    );
  }
}

export default observer(LongPressButton);
