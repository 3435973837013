import React from 'react';
import BEM from '../../../../bem';
import { Modal } from '../../..';
import { PatientCSVUploadTypes } from '../../../../../models/enums';

const classes = BEM.with('CSVSubmittedModal');
const { APPOINTMENT_REMINDERS, BROADCAST, PATIENT } = PatientCSVUploadTypes;

type CSVSubmittedModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  options: { file: File; type: string };
};

const headerText: { [k: string]: string } = {
  [APPOINTMENT_REMINDERS]: 'Appointments Submitted',
  [BROADCAST]: 'Patient List Submitted',
  [PATIENT]: 'Patients Submitted',
};

export default function CSVSubmittedModal({ closeModal, isOpen, options }: CSVSubmittedModalProps) {
  const { file, type } = options;

  return (
    <Modal
      className={classes()}
      closeClass={classes('close-button')}
      footerClass={classes('footer')}
      footerPrimaryActions={
        <button className={classes('ok-btn')} onClick={closeModal} type="button">
          OK
        </button>
      }
      header={headerText[type]}
      headerClass={classes('header')}
      isOpen={isOpen}
      onRequestClose={closeModal}
      size={'medium'}
    >
      <div className={classes('container')}>
        <p className="word-break">{`Your upload of ${file.name} has been received.`}</p>
        <br></br>
        <p className="word-break">
          Processing time may vary depending on the file size. You will receive an email
          confirmation when complete.
        </p>
        <p className="word-break">Exiting this page will not cancel the upload.</p>
      </div>
    </Modal>
  );
}
