import React from 'react';
import classNames from 'classnames';

import styles from './ColoredText.module.css';

export interface ColoredTextProps {
  text: string;
  color: 'red' | 'grey' | 'green' | 'yellow';
}

export const ColoredText = ({ text, color }: ColoredTextProps) => {
  return <span className={classNames(styles.root, styles[color])}>{text}</span>;
};
