import createMobxModel from './createMobxModel';
import { FeatureServices } from './enums/';

export const Group = createMobxModel({
  name: 'group',

  fields: [
    'avatarUrl',
    'conversationId',
    'createdAt',
    'description',
    'displayName',
    'expireAt',
    'groupType',
    'hasCurrentUser',
    'isPublic',
    'memberCount',
    'metadata',
    'patientContextId',
    'name',
    'preserve',
    'replayHistory',
  ],

  objectFields: {
    alertNotification: {},
    patientDetails: {},
    patientContextDetails: {},
    conversation: {
      clone(conversation, { entityStore }) {
        if (conversation) {
          if (conversation.featureService === FeatureServices.GROUP_ALERTS) {
            let mobxConversation = entityStore.conversation.getById(conversation.id);
            if (!mobxConversation) {
              mobxConversation = entityStore.syncOne(conversation);
            }

            return mobxConversation;
          }

          return entityStore.conversation.getById(conversation.id);
        } else {
          return null;
        }
      },
    },
  },

  relations: {
    one: {
      alertMessage: { type: 'message' },
      createdByUser: { type: 'user' },
      escalationExecution: { type: 'escalationExecution' },
      organization: { type: 'organization' },
      p2pRecipient: { type: ['role', 'user'] },
      p2pSender: { type: ['role', 'user'] },
      tag: { type: 'tag' },
      team: { type: 'team' },
    },
    many: {
      members: { type: ['user', 'team'] },
    },
  },
});

export default Group;
