import React, { Component } from 'react';
import { observer } from 'mobx-react';
import BEM from '../../../../common/bem';
import { DistributionListAvatar, DistributionListName } from '../../../../common/components';
import propTypes from '../../../../common/propTypes';

const classes = BEM.with('ConversationHeader');

class DistributionListConversationHeader extends Component {
  static propTypes = {
    conversation: propTypes.conversation.isRequired,
  };

  render() {
    const {
      conversation: { counterParty: distributionList },
    } = this.props;

    return (
      <div className={classes({ distributionList: true })} title={distributionList.displayName}>
        <DistributionListAvatar distributionList={distributionList} size="fit" />
        <span className={classes('group-details')}>
          <span className={classes('group-name')}>
            <DistributionListName
              ariaLabel={'Conversation Header Name'}
              distributionList={distributionList}
            />
          </span>
        </span>
      </div>
    );
  }
}

export default observer(DistributionListConversationHeader);
