import React, { Component } from 'react';
import { observer } from 'mobx-react';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { ReactComponent as RoleIconSvg } from '../../images/role-icon-small.svg';
import { ReactComponent as BorderCircleSvg } from '../../images/border-circle-21.svg';

const classes = BEM.with('RoleIndicator');

class RoleIndicator extends Component {
  static propTypes = {
    role: propTypes.role,
  };

  _lastCircleStyle = undefined;

  render() {
    const { role } = this.props;
    const { tag } = role;

    if (tag && !tag.isDefault) {
      const { color } = tag;
      if (!this._lastCircleStyle || this._lastCircleStyle.backgroundColor !== color) {
        this._lastCircleStyle = { backgroundColor: color };
      }
    } else {
      this._lastCircleStyle = undefined;
    }

    return (
      <div className={classes()}>
        <div className={classes('container')}>
          <BorderCircleSvg className={classes('border-circle')} />
          <span className={classes('circle')} style={this._lastCircleStyle}>
            <RoleIconSvg className={classes('icon')} />
          </span>
        </div>
      </div>
    );
  }
}

export default observer(RoleIndicator);
