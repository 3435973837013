import React from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';

import { mobxInjectSelect } from '../../utils';
import { ReactComponent as MemberAdded } from '../../images/green-checkmark.svg';
import { ReactComponent as MembersToBeAdded } from '../../images/check-add-members.svg';
import {
  Individual,
  Role,
} from '../../../widgets/messenger/components/SearchSidebar/SearchParityResults';

const classes = BEM.with('ProviderSearchBoxResult');

const ProviderSearchBoxResult = ({
  currentOrganization,
  currentUserId,
  entityType,
  hideDnd,
  isUserDndAfEnabledForCurrentOrg,
  isAvailabilityFeatureFlagEnabled,
  isFirstEntry,
  isPresenceFeatureFlagEnabled,
  pendingMemberIds,
  toggle,
  user,
}) => {
  const isSelected = pendingMemberIds.some(
    (pendingMemberId) => pendingMemberId === user.id || pendingMemberId === user.botUserId
  );

  const userDndAfStatusForCurrentOrg = isUserDndAfEnabledForCurrentOrg(user);

  return (
    <div>
      <div className={classes()} onClick={() => toggle(user)}>
        <div className={classes('wrapper', { isFirstEntry, isSearchParity: true })}>
          <>
            {entityType === 'user' && (
              <Individual
                currentOrganization={currentOrganization}
                currentUserId={currentUserId}
                hideDnd={hideDnd}
                individual={user}
                isAvailabilityFeatureFlagEnabled={isAvailabilityFeatureFlagEnabled}
                isPresenceFeatureFlagEnabled={isPresenceFeatureFlagEnabled}
                presenceSize={'small'}
                showDndAfStatus={userDndAfStatusForCurrentOrg}
                showPresenceIndicator={true}
                typeOfUser={'composeUser'}
              />
            )}
            {entityType === 'role' && <Role role={user} />}
          </>
          <div className={classes('check-mark', { isSelected })}>
            {isSelected ? <MemberAdded /> : <MembersToBeAdded />}
          </div>
        </div>
      </div>
    </div>
  );
};

ProviderSearchBoxResult.propTypes = {
  isFirstEntry: PropTypes.bool,
  pendingMemberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggle: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

ProviderSearchBoxResult.defaultProps = {
  isFirstEntry: false,
};

export default mobxInjectSelect({
  messengerStore: [
    'currentOrganization',
    'isPresenceFeatureFlagEnabled',
    'isAvailabilityFeatureFlagEnabled',
  ],
  sessionStore: ['currentUserId'],
  userStore: ['hideDnd', 'isUserDndAfEnabledForCurrentOrg'],
})(ProviderSearchBoxResult);
