import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux-stores';
import { Color } from '../../../../types';
import BEM from '../../../bem';
import Dropdown from '../../Dropdown';
import CollaborationSearch from '../CollaborationSearch';
import TagItem from './TagItem';

const classes = BEM.with('CollaborationSelector');

type TagSelectorProps = {
  selectColor: (color?: Color) => void;
  toggleHandler: () => void;
};

export default function TagColorSelector({ selectColor, toggleHandler }: TagSelectorProps) {
  const colors = useSelector((state: ReduxState) => state.tags.colors);
  const [searchValue, setSearchValue] = useState('');
  const [filteredColors, setFilteredColors] = useState<Color[]>(colors);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  useEffect(() => {
    setFilteredColors(
      colors.filter((color: Color) =>
        color.colorName.toUpperCase().includes(searchValue.toUpperCase())
      )
    );
  }, [colors, searchValue]);

  return (
    <div className={classes('')}>
      <Dropdown ariaLabel={'Tag Selector Dropdown'} triggerHandler={toggleHandler}>
        <>
          <div className={classes('search-container')}>
            <CollaborationSearch
              placeholder="Search"
              query={searchValue}
              onChangeHandler={handleSearch}
              clearInputHandler={clearSearch}
            />
          </div>
          {!searchValue && (
            <div
              className={classes('list-option', { default: true })}
              data-test-id="select no tag color"
              onClick={() => selectColor()}
            >
              <TagItem
                colorHex="#fff"
                hasBorder
                name="-No tag color selected-"
                noIcon
                isAdmin={true}
              />
            </div>
          )}
          {!filteredColors.length && (
            <div className={classes('no-search-results-found')}>No results found</div>
          )}
          <div id="tagColors">
            {filteredColors.map((color) => (
              <div
                className={classes('list-option')}
                key={color.colorId}
                data-test-id={`select tag color ${color.colorName}`}
                onClick={() => selectColor(color)}
              >
                <TagItem
                  colorHex={color.colorValue.replace('0x', '#')}
                  name={color.colorName}
                  isAdmin={true}
                  noIcon
                />
              </div>
            ))}
          </div>
        </>
      </Dropdown>
    </div>
  );
}
