import React, { ReactElement } from 'react';
import { mobxInjectSelect } from '../../common/utils';
import { OrganizationSetting } from '../types';
import { useAppSelector } from 'redux-stores';

type AuthorizeOrgSettingComponentProps = {
  children: ReactElement;
  doesRequireProductAdmin?: boolean;
  doesRequireProductSuperAdmin?: boolean;
  featureNames?: string[];
  settingNames?: (keyof OrganizationSetting | [keyof OrganizationSetting, string])[];
};

type MobxProps = {
  isProductSettingsAdmin: boolean;
  isProductSuperAdmin: boolean;
  adminFeatureFlags: Record<string, unknown>;
  getURLParams: () => Record<string, string>;
};

const AuthorizeOrgSettingComponent = ({
  children,
  doesRequireProductAdmin,
  doesRequireProductSuperAdmin,
  adminFeatureFlags,
  featureNames,
  getURLParams,
  isProductSettingsAdmin,
  isProductSuperAdmin,
  settingNames,
}: AuthorizeOrgSettingComponentProps & MobxProps) => {
  const urlParams = getURLParams();
  let isAuthorized = true;

  const dependentSettings = useAppSelector((state) => {
    return settingNames?.length
      ? settingNames.map((setting) => {
          if (typeof setting === 'string') {
            return state.orgSettings.settings[setting];
          } else {
            const [key, expected] = setting;
            const actual = state.orgSettings.settings[key];
            return [actual, expected];
          }
        })
      : [];
  });

  dependentSettings.forEach((setting) => {
    if (Array.isArray(setting)) {
      const [actual, expected] = setting;
      if (actual !== expected) isAuthorized = false;
    } else if (!setting || setting === 'off') isAuthorized = false;
  });

  if (isAuthorized && featureNames?.length) {
    let hasAtLeastOneFeature = false;
    featureNames.forEach((feature) => {
      if (urlParams[feature] === 'true' || adminFeatureFlags[feature]) {
        hasAtLeastOneFeature = true;
      }
    });

    if (!hasAtLeastOneFeature) {
      isAuthorized = false;
    }
  }

  if (isAuthorized && doesRequireProductAdmin) {
    isAuthorized = isProductSettingsAdmin;
  }

  if (isAuthorized && doesRequireProductSuperAdmin) {
    isAuthorized = isProductSuperAdmin;
  }

  return isAuthorized ? children : <></>;
};

export const AuthorizeOrgSetting = mobxInjectSelect<AuthorizeOrgSettingComponentProps, MobxProps>({
  featureStore: ['adminFeatureFlags', 'getURLParams'],
  messengerStore: ['isProductSettingsAdmin', 'isProductSuperAdmin'],
})(AuthorizeOrgSettingComponent);
