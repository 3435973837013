import React, { useState } from 'react';
import classNames from 'classnames';
import { ActionBar, TabButton } from '../../../shared-components/ActionBar/ActionBar';

import styles from './LocationTabs.module.css';
import { actions, useAppDispatch } from 'redux-stores';

const tabButtons = [
  {
    label: 'Locations',
  },
  {
    label: 'Units',
  },
];

export function LocationsTabs() {
  const dispatch = useAppDispatch();
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const onTabSelection = (tabButton: TabButton, index: number) => {
    dispatch(actions.setSelectedLocationButton({ selectedLocationButton: tabButton.label }));
    if (tabButton.label === 'Locations') {
      dispatch(actions.setLocationsDataDirty({ isLocationsDataDirty: true }));
    }
    if (tabButton.label === 'Units') {
      dispatch(actions.setIsUnitsDataDirty({ isUnitsDataDirty: true }));
    }
    setCurrentTabIndex(index);
  };

  return (
    <div className={classNames(styles.locationsTab)}>
      <ActionBar
        customStyles={styles}
        selectedTabIndex={currentTabIndex}
        setSelectedTabIndex={onTabSelection}
        tabButtons={tabButtons}
      />
    </div>
  );
}
