import { useEffect, useState } from 'react';
import classnames from 'classnames';

import Modal from '../../../../common/components/Modal';
import TCClient from '../../../../client';
import { Checkbox } from '../../../shared-components/Checkbox/Checkbox';

import styles from './AssignRoomsModal.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

type AssignUnitsModalProps = {
  facilityId: string;
  isOpen: boolean;
  unitId: string;
  unitName: string;
  onClose: () => void;
};

type AssignRoomMap = {
  [k: string]: boolean;
};

type Room = {
  roomId: string;
  roomName: string;
  parentAreaName?: string;
};

const initialAssignRoomState: AssignRoomMap = {};

function AssignRoomsModal({
  facilityId,
  isOpen,
  onClose,
  unitId,
  unitName,
}: AssignUnitsModalProps) {
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);
  const dispatch = useAppDispatch();
  const [assignRoom, setAssignRoom] = useState<AssignRoomMap>(initialAssignRoomState);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [saveRoomIds, setSaveRoomIds] = useState<string[]>([]);
  const [isLoadingRooms, setIsLoadingRooms] = useState<boolean>(false);
  const modalHeader = `${unitName} Unit ` + (saveRoomIds.length > 1 ? 'Rooms' : 'Room');

  const close = () => {
    setSaveRoomIds([]);
    onClose();
  };

  const save = async () => {
    await TCClient.multiOrg.updateUnit({
      orgId: selectedOrganizationId,
      unitId,
      unitName,
      roomIds: saveRoomIds,
    });
    dispatch(actions.setIsUnitsDataDirty({ isUnitsDataDirty: true }));
    close();
  };

  useEffect(() => {
    const getRooms = async () => {
      setIsLoadingRooms(true);
      const getUnit = await TCClient.multiOrg.getUnit({
        orgId: selectedOrganizationId,
        unitId,
      });

      const getAssignedRooms = getUnit.rooms;
      if (getAssignedRooms) {
        const map = getAssignedRooms.reduce((memo, v) => {
          return { ...memo, [v.roomId]: true };
        }, {} as { [k: string]: boolean });
        setAssignRoom(map);
        setSaveRoomIds(Object.keys(map));
      }

      const getUnassignedRooms = await TCClient.multiOrg.getUnassignedRooms({
        orgId: selectedOrganizationId,
        facilityId: facilityId,
      });

      const sortedUnassignedRooms = getUnassignedRooms.sort(
        (a, b) =>
          parseInt(a.parentAreaName.split(' ')[1]) - parseInt(b.parentAreaName.split(' ')[1])
      );

      const sortedAssignedRooms = getAssignedRooms.sort(
        (a, b) => parseInt(a.roomName.split(' ')[1]) - parseInt(b.roomName.split(' ')[1])
      );

      setRooms([...sortedUnassignedRooms, ...sortedAssignedRooms]);
      setIsLoadingRooms(false);
    };
    getRooms();
  }, [facilityId, selectedOrganizationId, unitId]);

  return (
    <Modal
      bodyClass={styles.body}
      headerClass={styles.header}
      header={modalHeader}
      isOpen={isOpen}
      size={'medium'}
      onRequestClose={close}
      closeClass={styles.close}
    >
      <div className={rooms.length ? styles.unitContainer : styles.unitContainerEmpty}>
        {isLoadingRooms ? (
          <div className={styles.noRoomsAvailable}>Loading...</div>
        ) : rooms.length ? (
          rooms.map(({ parentAreaName = '', roomId = '', roomName }, index) => {
            const currentUnitItemStyles = { marginTop: index > 0 ? '2px' : '0px' };
            const assignRoomsBool = assignRoom[roomId as keyof typeof assignRoom];
            const additionalUnitItemStyles = assignRoomsBool
              ? { backgroundColor: '#dff7ff' }
              : {
                  backgroundColor: index % 2 === 1 ? '#ffffff' : '#fbfcfc',
                };

            return (
              <div
                key={roomId}
                className={styles.unitItems}
                style={{ ...currentUnitItemStyles, ...additionalUnitItemStyles }}
              >
                <Checkbox
                  checked={assignRoomsBool}
                  customStyles={{ accentColor: '#076abc' }}
                  onClick={(v) => {
                    setAssignRoom({ ...assignRoom, [roomId]: v });
                    if (v) {
                      setSaveRoomIds(saveRoomIds.concat(roomId));
                    } else {
                      const currentSaveRoomIds = saveRoomIds.filter(
                        (currentRoomId) => currentRoomId !== roomId
                      );
                      setSaveRoomIds(currentSaveRoomIds);
                    }
                  }}
                />
                {parentAreaName && <div className={styles.parentAreaName}>{parentAreaName} :</div>}
                <div style={!parentAreaName ? { paddingLeft: '5px' } : {}}>{roomName}</div>
              </div>
            );
          })
        ) : (
          <div className={styles.noRoomsAvailable}>No Rooms available</div>
        )}
      </div>
      <div className={styles.buttons}>
        <button className={classnames(styles.button, styles.cancel)} onClick={close}>
          Cancel
        </button>
        <button
          className={classnames(styles.button, styles.save)}
          disabled={isLoadingRooms || !rooms.length}
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  );
}

export default AssignRoomsModal;
