import React from 'react';
import { createSelector } from '@reduxjs/toolkit';
import type { ParametricSelector } from '@reduxjs/toolkit';
import { get, last, zipObject } from 'lodash';
import { useSelector } from 'react-redux';

type SelectorType = ParametricSelector<{ [k: string]: unknown }, unknown, unknown>;

type StateSlice<State> = {
  [K in keyof State]?: readonly (keyof State[K])[];
};

type SliceKeys<T extends readonly unknown[] | undefined> = T extends readonly (infer V)[]
  ? V
  : never;

type SelectProps<State, Slice extends StateSlice<State>> = {
  [K in keyof Slice]: K extends keyof State
    ? Pick<State[K], SliceKeys<Slice[K]> extends keyof State[K] ? SliceKeys<Slice[K]> : never>
    : never;
}[keyof Slice];

type UnionToIntersection<T> = (T extends unknown ? (x: T) => unknown : never) extends (
  // generic infer is a false positive
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  x: infer R
) => unknown
  ? R
  : never;

export type MapStateToProps<State, Slice extends StateSlice<State>> = UnionToIntersection<
  SelectProps<State, Slice>
>;

export function reduxInjectSelect<
  PropTypes,
  ReduxStateProps,
  State extends Record<string, unknown>
>(selectors: {
  [k in keyof State]?: readonly (keyof State[k])[];
}) {
  type CombinedProps = PropTypes & ReduxStateProps;
  return (PassedComponent: React.FC<CombinedProps> | React.ComponentType<CombinedProps>) => {
    return (props: PropTypes) => {
      const propsWithoutStores = {} as { [k in keyof PropTypes]: React.ReactNode };
      const fields: string[] = [];

      Object.keys(selectors).forEach((key) => {
        selectors[key as keyof typeof selectors]?.forEach((field) =>
          fields.push(`${key}.${String(field)}`)
        );
      });
      const selectorFns: SelectorType[] = fields.map(
        (field) => ((state: { [k: string]: unknown }) => get(state, field)) as SelectorType
      );

      const customSelector = createSelector(selectorFns, (...args) => {
        return zipObject(fields.map((field) => last(field.split('.'))) as string[], args);
      }) as (...res: unknown[]) => unknown;

      const reduxProps = useSelector((state) => customSelector(state)) as {
        [k in keyof ReduxStateProps]: React.ReactNode;
      };

      for (const [key, value] of Object.entries(props as {})) {
        if (!Object.keys(reduxProps).includes(key)) {
          propsWithoutStores[key as keyof PropTypes] = value;
        }
      }

      return <PassedComponent {...propsWithoutStores} {...(reduxProps as CombinedProps)} />;
    };
  };
}

export default reduxInjectSelect;
