export default class NotSignedInError extends Error {
  static CODE = 'not-signed-in';

  code: string;

  constructor(...args: (string | undefined)[]) {
    super(...args);
    this.code = NotSignedInError.CODE;
  }
}
