import { ServerValueEnum } from '../../utils/enum';

const PatientCareCardType = new ServerValueEnum(
  {
    ADMIT: { serverValue: 'admit' },
    DISCHARGE: { serverValue: 'discharge' },
    LAB: { serverValue: 'lab' },
    TRANSFER: { serverValue: 'transfer' },
  } as const,
  { defaultValue: 'NA' }
);

export default PatientCareCardType;
