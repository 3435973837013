import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { partial } from 'lodash';
import BEM from '../../../bem';
import { ReactComponent as PatientSvg } from '../../../images/default-avatar--patientCare.svg';
import { ReactComponent as RemoveIcon } from '../../../images/remove-icon.svg';
import mobxInjectSelect from '../../../utils/mobxInjectSelect';
import { patientGenders, patientRelations } from '../../../utils/patients';

const classes = BEM.with('PatientAndContactFormItem');

class EntityInput extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
  };

  render() {
    return (
      <div className={classes('entity-input')}>
        <div className={classes('title')}> {this.props.title} </div>
        {this.props.children}
      </div>
    );
  }
}

class PatientAndContactFormItem extends Component {
  static propTypes = {
    actionInProgress: PropTypes.bool.isRequired,
    entity: PropTypes.object.isRequired,
    formErrors: PropTypes.object,
    indexOfContact: PropTypes.number,
    isPatient: PropTypes.bool.isRequired,
    removeContact: PropTypes.func.isRequired,
    validateContact: PropTypes.func.isRequired,
    updateEditContact: PropTypes.func.isRequired,
    updatePatient: PropTypes.func.isRequired,
  };

  render() {
    const { actionInProgress, entity, isPatient } = this.props;
    const { dob, firstName, gender, lastName, mrn, patientId, phoneNumber, relation } = entity;

    return (
      <div className={classes()}>
        {(isPatient || this._isUpdatingUnsavedContact()) && (
          <div className={classes('icon-col')}>
            <button
              aria-label={!isPatient && 'Remove Contact'}
              className={classNames(
                classes('icon-container'),
                classes((isPatient ? 'patient' : 'contact') + '-icon-container')
              )}
              onClick={this._removeContact}
            >
              {isPatient ? (
                <PatientSvg className={classes('patient-icon')} aria-hidden />
              ) : (
                <RemoveIcon className={classes('remove-icon')} aria-hidden />
              )}
            </button>
          </div>
        )}
        <div className={classes('entity-row-left')}>
          <EntityInput title={isPatient ? 'First' : ''}>
            <input
              className={classes('input', {
                hasError: this._fieldHasError('firstName'),
                contactName: !isPatient,
              })}
              disabled={actionInProgress}
              maxLength={300}
              onChange={partial(this._updateEntity, 'firstName')}
              placeholder="First Name"
              type="text"
              value={firstName}
            />
          </EntityInput>
          <EntityInput title={isPatient ? 'Last' : ''}>
            <input
              className={classes('input', {
                hasError: this._fieldHasError('lastName'),
                contactName: !isPatient,
              })}
              disabled={actionInProgress}
              maxLength={300}
              onChange={partial(this._updateEntity, 'lastName')}
              placeholder="Last Name"
              type="text"
              value={lastName}
            />
          </EntityInput>
        </div>
        <div className={classes('entity-row-right')}>
          <EntityInput title="Phone">
            <input
              className={classes('input', {
                hasError: this._fieldHasError('phoneNumber'),
                isNumber: true,
              })}
              disabled={actionInProgress}
              maxLength={300}
              onChange={partial(this._updateEntity, 'phoneNumber')}
              placeholder="000-000-0000"
              type="text"
              value={phoneNumber}
            />
          </EntityInput>
          {isPatient && (
            <EntityInput title="MRN">
              <input
                className={classNames(
                  classes('input-mrn'),
                  classes('input', {
                    hasError: this._fieldHasError('mrn'),
                    isNumber: true,
                  })
                )}
                disabled={patientId || actionInProgress}
                maxLength={300}
                onChange={partial(this._updateEntity, 'mrn')}
                placeholder="000000"
                type="text"
                value={mrn}
              />
            </EntityInput>
          )}
          {isPatient && (
            <EntityInput title="Gender">
              <select
                name="gender"
                disabled={actionInProgress}
                className={classNames(
                  classes('input', { isNumber: true }),
                  classes('input-gender')
                )}
                onChange={partial(this._updateEntity, 'gender')}
                value={gender}
              >
                {patientGenders.map((gender) => (
                  <option key={gender} value={gender}>
                    {gender}
                  </option>
                ))}
              </select>
            </EntityInput>
          )}
          {isPatient && (
            <EntityInput title="DOB">
              <input
                className={classNames(
                  classes('input', {
                    hasError: this._fieldHasError('dob'),
                    isNumber: true,
                  }),
                  classes('input-dob')
                )}
                disabled={actionInProgress}
                maxLength={300}
                onChange={partial(this._updateEntity, 'dob')}
                placeholder="MM - DD - YYYY"
                type="text"
                value={dob}
              />
            </EntityInput>
          )}
          {!isPatient && (
            <EntityInput title="Relation">
              <select
                name="relation"
                disabled={actionInProgress}
                className={classNames(
                  classes('input', { isNumber: true }),
                  classes('input-relation')
                )}
                onChange={partial(this._updateEntity, 'relation')}
                value={relation}
              >
                {patientRelations.map((relation) => (
                  <option key={relation} value={relation}>
                    {relation}
                  </option>
                ))}
              </select>
            </EntityInput>
          )}
        </div>
      </div>
    );
  }

  _fieldHasError = (field) => {
    const { formErrors, indexOfContact, isPatient } = this.props;

    const path = isPatient
      ? 'patient'
      : this._isUpdatingUnsavedContact()
      ? 'patientContactList.' + indexOfContact
      : 'contact';

    return !!formErrors[path + '.' + field];
  };

  _isUpdatingUnsavedContact = () => {
    const { entity, indexOfContact, isPatient } = this.props;
    return !isPatient && Number.isInteger(indexOfContact) && !entity.patientId;
  };

  _updateEntity = (field, e) => {
    const { indexOfContact, isPatient, validateContact, updateEditContact, updatePatient } =
      this.props;

    if (isPatient) {
      updatePatient(field, e.target.value);
    } else if (this._isUpdatingUnsavedContact()) {
      validateContact(indexOfContact, field, e.target.value);
    } else {
      updateEditContact(field, e.target.value);
    }
  };

  _removeContact = () => {
    const { indexOfContact, isPatient, removeContact } = this.props;
    if (!isPatient && Number.isInteger(indexOfContact)) {
      removeContact(indexOfContact);
    }
  };
}

export default mobxInjectSelect({
  patientStore: [
    'actionInProgress',
    'editContact',
    'editPatient',
    'formErrors',
    'patient',
    'patientContactList',
    'removeContact',
    'validateContact',
    'updateEditContact',
    'updatePatient',
  ],
})(PatientAndContactFormItem);
