import React from 'react';

import BEM from '../../../bem';
import DropdownList from '../../VirtualWaitingRoom/DropdownList';
import { ReactComponent as DownArrowSvg } from '../../../images/down-arrow.svg';
import { VWR } from '../../../../types';

const classes = BEM.with('VirtualWaitingRoom');

type RoomsDropDownProps = {
  rooms: VWR[];
  selectedWaitingRoom?: VWR;
  setSelectedRoom: (room: VWR) => void;
  reloadRoomsList: (room?: VWR) => void;
};

export const RoomsDropDown: React.FC<RoomsDropDownProps> = ({
  rooms,
  selectedWaitingRoom,
  setSelectedRoom,
  reloadRoomsList,
}) => {
  const handleSelector = () => {
    reloadRoomsList(selectedWaitingRoom);
  };

  return (
    <div className={classes('dropdown-container')}>
      <DropdownList
        width={217}
        shouldScroll
        shouldShowSearch
        values={rooms}
        selectedValue={{ id: selectedWaitingRoom?.id }}
        setSelectedValue={({ id }) => {
          const room = rooms.find((r) => r.id === id);
          if (room) {
            setSelectedRoom(room);
          }
        }}
        primaryHeader={
          <div
            title={selectedWaitingRoom?.name}
            className={classes('dropdown-selector')}
            aria-label="waiting room selector"
          >
            <div className={classes('dropdown-header')} onClick={handleSelector}>
              <span className={classes('header-name')} aria-label="selected waiting room name">
                {selectedWaitingRoom?.name}
              </span>
              <span className={classes('down-arrow-container')}>
                <DownArrowSvg className={classes('down-arrow')} />
              </span>
            </div>
          </div>
        }
      />
    </div>
  );
};
