import React from 'react';

import { AuthorizeOrgSetting } from '../../shared-components/AuthorizeSetting';
import {
  SettingContainer,
  SettingContainerProps,
} from '../../shared-components/SettingContainer/SettingContainer';
import { StandardOrgSettingInput } from '../../shared-components/StandardSettingInput';
import { OrganizationSetting } from '../../types';
import {
  DESKTOP_AND_WEB_LOGOUT_CONTROL_OPTIONS,
  ESCALATION_EXPIRATION_OPTIONS,
  FAST_DEPLOY_CONTROL_OPTIONS,
  FAST_DEPLOY_LOGIN_OPTIONS,
  PINLOCK_TTL_CONTROL_OPTIONS,
} from '../../utils/commonControlOptions';
import { parseLoginType } from '../../../js-sdk/src/services/Admin/utils';
import { C2COrgSettingsControls } from './C2COrgSettingControls';

import styles from './OrganizationSettings.module.css';
import { useAppSelector } from 'redux-stores';

export function BaseOrganizationSettingsItemList() {
  const authenticationType = useAppSelector((state) => state.orgSettings.settings.authType);
  const loginWorkflow = useAppSelector((state) => state.orgSettings.settings.orgLoginWorkflow);
  const orgSettingsListItems: (SettingContainerProps & {
    dependsOnFeatures?: string[];
    dependsOnSettings?: (keyof OrganizationSetting)[];
  })[] = [
    {
      label: 'Message Lifespan',
      subLabel: 'Sent messages will be deleted after this time limit.',
      settingControl: <StandardOrgSettingInput settingName={'messageLifespan'} type="slider" />,
    },
    {
      dependsOnFeatures: ['allowAlertLifespan', 'ALLOW_ALERT_LIFESPAN'],
      label: 'Alert Lifespan',
      subLabel: 'Alerts will be deleted after this time limit.',
      settingControl: <StandardOrgSettingInput settingName={'alertLifespan'} type="slider" />,
    },
    {
      dependsOnFeatures: ['allowConversationExpiration', 'ALLOW_CONVERSATION_EXPIRATION'],
      label: 'Conversation Expiration',
      subLabel: 'Conversations will be removed from the inbox after the last message expires.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isConversationExpirationEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnFeatures: ['allowDoNotExpire', 'ALLOW_DO_NOT_EXPIRE'],
      label: 'Preserve Group Conversation',
      subLabel:
        'Users can specify which group conversations should not be included in the cleanup process for stale groups and expired conversations.',
      settingControl: (
        <StandardOrgSettingInput
          settingName={'isPreserveGroupConversationEnabled'}
          type="checkbox"
        />
      ),
    },
    {
      label: 'Delete on Read',
      subLabel: 'All messages will be deleted one minute after opening.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isDeleteOnReadEnabled'} type="checkbox" />
      ),
    },
    {
      label: 'Desktop App / Web Logout',
      subLabel:
        'Users will be automatically logged out of the Desktop App / Web Console when this time limit of inactivity is met.',
      settingControl: (
        <StandardOrgSettingInput
          options={DESKTOP_AND_WEB_LOGOUT_CONTROL_OPTIONS}
          settingName="autoLogout"
          type="dropdown"
        />
      ),
    },
    {
      label: 'PIN Lock (mobile)',
      subLabel:
        'Users are required to enter their PIN code on the mobile application after this time limit.',
      settingControl: (
        <StandardOrgSettingInput
          options={PINLOCK_TTL_CONTROL_OPTIONS}
          settingName={'pinLock'}
          type="dropdown"
        />
      ),
    },
    {
      label: 'Repeat Notifications',
      subLabel:
        'Users will receive a new alert sound every 2 minutes, up to 20 minutes, until the message is viewed.',
      settingControl: (
        <StandardOrgSettingInput settingName={'areRepeatNotificationsEnabled'} type="checkbox" />
      ),
    },
    {
      label: 'Fast Deploy',
      subLabel: 'Users not logged in will receive TigerConnect messages via email and SMS.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isFastDeployEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['isFastDeployEnabled'],
      label: 'Fast Deploy session length',
      subLabel:
        'The amount of time that the web session/conversation is viewable to the recipient.',
      settingControl: (
        <StandardOrgSettingInput
          options={FAST_DEPLOY_CONTROL_OPTIONS}
          settingName="fastDeploySessionLength"
          type="dropdown"
        />
      ),
    },
    {
      dependsOnSettings: ['isFastDeployEnabled'],
      label: 'Fast Deploy Login Value',
      subLabel: 'Select the value type that represents how your users will login to TigerConnect.',
      settingControl: (
        <StandardOrgSettingInput
          options={FAST_DEPLOY_LOGIN_OPTIONS}
          settingName="fastDeployLoginValue"
          type="dropdown"
        />
      ),
    },
    {
      label: 'Message Forwarding',
      subLabel: 'Enable users to forward TigerConnect messages to others within the organization.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isMessageForwardingEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['featureDeliveryEscalation'],
      label: 'Delivery Escalations',
      subLabel: 'Enable SMS delivery if TigerConnect messages are undeliverable after 5 minutes.',
      settingControl: (
        <StandardOrgSettingInput settingName={'areDeliveryEscalationsEnabled'} type="checkbox" />
      ),
    },
    {
      label: 'Forums',
      subLabel: 'Enable your users to create and join secure chat forums.',
      settingControl: <StandardOrgSettingInput settingName={'areForumsEnabled'} type="checkbox" />,
    },
    {
      dependsOnSettings: ['featureDndAutoForward'],
      label: 'Auto Forward',
      subLabel: 'Allow users to automatically forward messages to other users when on DND.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isAutoForwardEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['featurePriorityMessaging'],
      label: 'Priority Messaging',
      subLabel: 'Allow users to send high priority messages.',
      settingControl: (
        <StandardOrgSettingInput settingName={'priorityMessagingSetting'} type="dropdown" />
      ),
    },
    {
      dependsOnFeatures: ['allowMentions', 'CONVERSATION_MENTIONS'],
      label: '@Mentions',
      subLabel:
        'Allow users to mention users and roles to draw attention to specific conversations.',
      settingControl: <StandardOrgSettingInput settingName={'isMentionsEnabled'} type="checkbox" />,
    },
    {
      label: 'Emoji Reactions',
      subLabel: 'Enable users to respond to messages using emoji reactions.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isEmojiReactionsEnabled'} type="checkbox" />
      ),
    },
    {
      label: 'Skip SMS verification in Mobile',
      subLabel: 'Allow users to skip SMS verification process when registering on a mobile device.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isSkipSmsVerificationEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnFeatures: ['allowExternalBrowser', 'EXTERNAL_BROWSER'],
      label: 'Message Links: External Browser',
      subLabel: 'Users are forced to use an external browser when opening links.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isOpenLinksExternallyEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnFeatures: ['allowDisableLink', 'DISABLE_LINK'],
      label: 'Message Links: Disable Preview Link',
      subLabel: 'Disables link previews across all platforms.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isDisableLinkEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['featureRoles'],
      label: 'Roles',
      subLabel:
        'Enable this feature to allow role creation and the ability for users to message a role.',
      settingControl: <StandardOrgSettingInput settingName={'areRolesEnabled'} type="checkbox" />,
    },
    {
      dependsOnSettings: ['featureRolesEscalation', 'areRolesEnabled'],
      label: 'Roles Escalation',
      subLabel:
        'Enable this feature to assign escalation rules for each role, allowing for messages to be escalated to backups when necessary.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isRolesEscalationEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['isRolesEscalationEnabled'],
      label: 'Escalation Settings',
      subLabel: 'Set expiration time for group escalation message.',
      settingControl: (
        <StandardOrgSettingInput
          options={ESCALATION_EXPIRATION_OPTIONS}
          settingName="rolesEscalationMessageExpiration"
          type="dropdown"
        />
      ),
    },
    {
      dependsOnSettings: ['featureRolesScheduleUpload', 'areRolesEnabled'],
      label: 'Roles Schedule Upload Tool',
      subLabel:
        'A utility that allows you to manually upload an on-call schedule to the Roles platform.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isRolesScheduleUploadEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['featureRolesCalendarView', 'areRolesEnabled'],
      label: 'Roles Calendar Views',
      subLabel: 'Allow users to have an expanded view of scheduled roles via a calendar interface.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isRolesCalendarViewEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['featureInsights'],
      label: 'Insights',
      subLabel: 'Enable this feature to let users see more detailed reports.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isTigerInsightsEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['featureClickToCall'],
      label: 'Click-2-Call Number Mask',
      subLabel:
        "Choose between using a randomly generated or custom telephone number for your organization's masked caller ID telephone calls.",
      settingControl: <C2COrgSettingsControls />,
    },
    {
      dependsOnSettings: ['featureVoipCalling'],
      label: 'VoIP Audio Calling',
      subLabel: 'Allow users to make and receive VoIP audio calls.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isVoipCallingEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['featureGroupAudioCalling'],
      label: 'Group Audio Calling',
      subLabel: 'Allow users to make and receive group audio calls.',
      settingControl: (
        <StandardOrgSettingInput settingName={'groupAudioCallingSetting'} type="dropdown" />
      ),
    },
    {
      dependsOnSettings: ['featureVideoCalling'],
      label: 'Video Calling',
      subLabel: 'Allow users to make and receive video calls.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isVideoCallingEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['featureGroupVideoCalling'],
      label: 'Group Video Calling',
      subLabel: 'Allow users to make and receive group video calls.',
      settingControl: (
        <StandardOrgSettingInput settingName={'groupVideoCallingSetting'} type="dropdown" />
      ),
    },
    {
      dependsOnFeatures: ['allowOTP'],
      label: 'Allow users to configure One-Time Password (OTP) as a backup login',
      subLabel:
        'Enabling this feature will allow users to configure the "OTP Login" feature under their account settings. TigerConnect recommends customers using their own authentication solution to turn on this feature as a way for users to configure an alternate way to login to TigerConnect when the customer\'s authentication solution goes down. To switch to using username & OTP as a login method for all users, please contact client care.',
      settingControl: (
        <StandardOrgSettingInput
          settingName={'otpAllowConfigure'}
          type="checkbox"
          disabled={
            ['native', 'saml'].includes(authenticationType) ||
            parseLoginType(loginWorkflow) === 'Username & OTP'
          }
        />
      ),
    },
    {
      dependsOnSettings: ['featurePhotoVideoSharingDisabled'],
      label: 'Disable sharing photos/videos from device',
      subLabel:
        'This setting will disable users from being to share photos/videos from their devices camera gallery on TigerConnect.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isPhotoVideoSharingDisabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['featureAttachmentSharingDisabled'],
      label: 'Disable sharing attachments from device',
      subLabel:
        'This setting will disable users from being to share attachments from their device and cloud storage on TigerConnect.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isAttachmentSharingDisabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['featureTeams'],
      label: 'Teams',
      subLabel: 'Allow users to create and activate teams.',
      settingControl: <StandardOrgSettingInput settingName={'teamsSetting'} type="dropdown" />,
    },
    {
      dependsOnFeatures: ['allowPbxIntegration', 'pbx'],
      dependsOnSettings: ['featureEnterprisePbx'],
      label: 'Enterprise PBX Integration',
      subLabel: 'Allows SIP integration with enterprise PBX telephony systems.',
      settingControl: (
        <StandardOrgSettingInput settingName={'enterprisePbxSetting'} type="dropdown" />
      ),
    },
    {
      dependsOnSettings: ['featureTigerSchedule'],
      label: 'TigerConnect Physician Scheduling',
      subLabel: 'Allow users to access TigerConnect Physician Scheduling applications',
      settingControl: (
        <StandardOrgSettingInput settingName={'tigerScheduleSetting'} type="dropdown" />
      ),
    },
    {
      dependsOnFeatures: ['allowAdSync', 'allow-ad-sync'],
      label: 'Active Directory Sync for Provider Broadcast Lists',
      subLabel: 'Automatically sync security group membership with provider broadcast lists.',
      settingControl: (
        <StandardOrgSettingInput settingName={'isBroadcastListAdSyncEnabled'} type="checkbox" />
      ),
    },
    {
      dependsOnSettings: ['featurePatientContext'],
      label: 'Patient Reference',
      subLabel: 'Allow users to start a group message about a specific patient',
      settingControl: (
        <StandardOrgSettingInput settingName={'patientContextSetting'} type="dropdown" />
      ),
    },
    {
      dependsOnFeatures: ['allowScimAdminSetting', 'ALLOW_SCIM_ADMIN_SETTING'],
      label: 'SCIM Integration',
      subLabel:
        'Allows admins to select users that can manage SCIM user provisioning settings for their organization.',
      settingControl: (
        <StandardOrgSettingInput settingName={'scimIntegrationSetting'} type="checkbox" />
      ),
    },
    {
      dependsOnFeatures: ['allowPersonaAdmin'],
      label: 'Title-Based Access Controls',
      subLabel:
        'Organize user permissions based on job titles that can be applied to multiple users - managed in the "Permission Groups" tab.',
      settingControl: (
        <StandardOrgSettingInput settingName={'personaManagement'} type="checkboxBasedOnDropdown" />
      ),
    },
  ];

  return (
    <>
      {orgSettingsListItems.map((settingProps) => (
        <AuthorizeOrgSetting
          key={settingProps.label}
          featureNames={settingProps.dependsOnFeatures ? settingProps.dependsOnFeatures : []}
          settingNames={settingProps.dependsOnSettings ? settingProps.dependsOnSettings : []}
        >
          <div key={settingProps.label} className={styles.individualSettingContainer}>
            <SettingContainer {...settingProps} />
          </div>
        </AuthorizeOrgSetting>
      ))}
    </>
  );
}
