import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import propTypes from '../propTypes';
import { highlightText, mobxInjectSelect } from '../utils/';
import BEM from '../bem';

const classes = BEM.with('UserTitleDepartment');

class UserTitleDepartment extends Component {
  static propTypes = {
    className: PropTypes.string,
    currentOrganizationId: PropTypes.string.isRequired,
    organizationId: PropTypes.string,
    searchWords: PropTypes.arrayOf(PropTypes.string),
    user: propTypes.user.isRequired,
  };

  render() {
    const { className, currentOrganizationId, searchWords, user } = this.props;
    const { organizationId = currentOrganizationId } = this.props;
    const profile = user.profileByOrganizationId[organizationId];
    if (!profile) return null;

    let titleAndDepartment = [];
    if (profile.title) titleAndDepartment.push(profile.title);
    if (profile.department) titleAndDepartment.push(profile.department);
    titleAndDepartment = titleAndDepartment.filter(Boolean).join(', ');

    if (searchWords && searchWords.length > 0) {
      titleAndDepartment = highlightText(titleAndDepartment, searchWords, classes);
    }

    return <span className={classNames(classes(), className)}>{titleAndDepartment}</span>;
  }
}

export default mobxInjectSelect({
  messengerStore: ['currentOrganizationId'],
})(UserTitleDepartment);
