import React from 'react';

import { ReduxState } from '../../../../redux-stores';
import { MapStateToProps, reduxInjectSelect } from '../../../utils/reduxInjectSelect';
import BEM from '../../../bem';

import EntityContainer from './EntityContainer';
import EntityErrorBoundary from './EntityErrorBoundary';

import EntitiesList from './EntitiesList';
import EntitiesHeader from './EntitiesHeader';

const classes = BEM.with('CollaborationRoles');

const reduxSelectors = {
  entities: [
    'entitiesById',
    'entitiesSearchQuery',
    'isLoadingEntities',
    'newEntityType',
    'selectedEntity',
  ],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type EntitiesProps = {};

function Entities({
  isLoadingEntities: loading,
  entitiesById,
  entitiesSearchQuery: searchQuery,
  newEntityType,
  selectedEntity,
}: ReduxProps & EntitiesProps) {
  const results = Object.values(entitiesById);

  const showEmptyBG = !loading && !newEntityType && results.length === 0 && !searchQuery;
  const isInfoPaneOpen = selectedEntity?.id || newEntityType;

  return (
    <div className={classes('')}>
      <div
        className={classes('roles-container', {
          empty: showEmptyBG,
          infoPaneOpen: !!isInfoPaneOpen,
        })}
      >
        <EntitiesHeader />
        <EntitiesList />
      </div>
      <EntityErrorBoundary>
        <EntityContainer />
      </EntityErrorBoundary>
    </div>
  );
}

export default reduxInjectSelect<EntitiesProps, ReduxProps, ReduxState>(reduxSelectors)(Entities);
