import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RoleAvatar, UserName } from '../../../../common/components';
import propTypes from '../../../../common/propTypes';
import BEM from '../../../../common/bem';
import { mobxInjectSelect } from 'common/utils';

const classes = BEM.with('ConversationHeader');

class RoleConversationHeader extends Component {
  static propTypes = {
    role: propTypes.role.isRequired,
    user: propTypes.user.isRequired,
    currentOrganizationId: PropTypes.string.isRequired,
  };

  _lastTagStyle = undefined;

  componentDidMount() {
    this.fetchRole();
  }

  componentDidUpdate(prevProps) {
    const { role } = this.props;
    if (prevProps.role.id !== role.id) this.fetchRole();
  }

  fetchRole() {
    const { currentOrganizationId, role, findRole } = this.props;
    findRole(role.id, role.organizationId || currentOrganizationId);
  }

  render() {
    const { user, role } = this.props;
    const { tag } = role;
    let onDutyStatus, tagFragment;

    if (role.displayName === user.displayName) {
      onDutyStatus = (
        <span className={classes('user-details')}>
          <span className={classes('no-on-duty')}>No one is on duty</span>
        </span>
      );
    } else {
      onDutyStatus = (
        <span className={classes('user-details')}>
          <span className={classes('on-duty')}>On Duty:</span>
          <span className={classes('basic-details')}>
            <UserName ariaLabel={'Conversation Header Name'} user={user} />
          </span>
          <span className={classes('extra-details')}>
            {user.status && (
              <span aria-label={'Conversation User Status'} className={classes('user-status')}>
                {user.status}
              </span>
            )}
            {user.title && <span className={classes('user-title')}>{user.title}</span>}
          </span>
        </span>
      );
    }

    if (!user.displayName) {
      onDutyStatus = (
        <span className={classes('user-details')}>
          <span className={classes('loader')}>Loading...</span>
        </span>
      );
    }

    if (!tag || tag.isDefault) {
      tagFragment = (
        <div className={classes('role-titles')}>
          <span className={classes('role-name')}>{role.displayName}</span>
        </div>
      );
    } else {
      const { color } = tag;
      if (!this._lastTagStyle || this._lastTagStyle.backgroundColor !== color) {
        this._lastTagStyle = { backgroundColor: color };
      }
      tagFragment = (
        <div className={classes('role-titles')}>
          <span className={classes('role-name')}>{role.displayName}</span>
          <span className={classes('tag-indicator')} style={this._lastTagStyle} />
          <span className={classes('tag-name')}>{tag.displayName}</span>
        </div>
      );
    }

    return (
      <div className={user.status && user.title && classes({ addedMargin: true })}>
        <div className={classes({ role: true })} title={user.displayName}>
          {tagFragment}
        </div>
        <div className={classes({ role: true })} title={user.displayName}>
          <RoleAvatar
            user={user}
            role={role}
            size="fit"
            showPresenceIndicator={true}
            presenceStatus={user.presenceStatus}
            userId={user.id}
          />
          {onDutyStatus}
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  messengerStore: ['currentOrganizationId'],
  roleStore: ['findRole'],
})(RoleConversationHeader);
