import React, { useCallback, useEffect, useState } from 'react';

import TCClient from '../../../../../client';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { Table } from '../../../../shared-components/Table/Table';
import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';
import { AdminStateSlice } from '../../../../types';
import { reduxInjectSelect } from '../../../../../common/utils/reduxInjectSelect';

import styles from './styles.module.css';
import { ReduxState } from 'redux-stores';

type ApiProps = {
  onClose: () => void;
};

type KeySecret = { id: string; key: string; secret: string };

const columns = [
  { field: 'key', headerName: 'Key' },
  { field: 'secret', headerName: 'Secret' },
];

const Api: React.FC<ApiProps & ReduxProps> = ({
  onClose,
  selectedUser,
  selectedOrganizationId,
}) => {
  const [keys, setKeys] = useState<KeySecret[]>([]);

  const getKeys = useCallback(async () => {
    const res = await TCClient.adminUsers.getApiKeys({
      orgId: selectedOrganizationId,
      userId: selectedUser.id,
    });
    const _keys = res.map((v) => ({ ...v, id: v.key }));
    setKeys(_keys);
  }, [selectedOrganizationId, selectedUser.id]);

  useEffect(() => {
    getKeys();
  }, [getKeys]);

  return (
    <ViewContainer
      header={'API'}
      subHeader={'Generate a new, unique API key (and secret code) for this user.'}
      onClose={onClose}
    >
      <div>
        {keys.length > 0 && (
          <Table
            actionsOrder={[{ name: 'Delete', type: 'delete' }]}
            onRowDelete={async ({ key: apiKey }) => {
              await TCClient.adminUsers.deleteApiKey({
                apiKey,
                orgId: selectedOrganizationId,
                userId: selectedUser.id,
              });
              await getKeys();
            }}
            columns={columns}
            rows={keys}
          />
        )}
        <div className={styles.buttonWrapper}>
          <CtaButton
            primary
            size={'small'}
            label={'Generate New API Key'}
            onClick={async () => {
              await TCClient.adminUsers.createApiKey({
                orgId: selectedOrganizationId,
                userId: selectedUser.id,
              });
              await getKeys();
            }}
          />
        </div>
      </div>
    </ViewContainer>
  );
};

type ReduxProps = {
  selectedUser: AdminStateSlice['selectedUser'];
  selectedOrganizationId: AdminStateSlice['selectedOrganizationId'];
};

export default reduxInjectSelect<ApiProps, ReduxProps, ReduxState>({
  admin: ['selectedUser', 'selectedOrganizationId'],
})(Api);
