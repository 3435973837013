import React, { Component } from 'react';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { MessageSubTypes } from '../../models/enums/MessageSubTypes';
import { MenuItemList, MenuItem } from './ContextMenu';

const classes = BEM.with('MessageContextMenu');

type EscalationTarget = {
  display_name: string;
  type: 'user' | 'role';
  id: string;
};

type EscalationExecution = {
  additionalTargets: { level: number; target: EscalationTarget }[];
  carbonCopyMessage: unknown;
  currentStep: number;
  group: { conversation: unknown };
  path: { targets: { id: string }[] }[];
  status: unknown;
  targetId: string;
};

type Message = {
  canRecall: unknown;
  conversation: unknown;
  counterParty?: { groupType: unknown };
  escalationExecution: EscalationExecution;
  id: string;
  priority: unknown;
  senderId: unknown;
  senderOrganization: { allowPriorityMessage: unknown; isForwardAllowed: unknown };
  senderStatus: unknown;
  subType: unknown;
};

type MessageContextMenuProps = {
  message: Message;
  messageRef: React.RefObject<Node>;
  sideEffect: (name: string) => void;
  accessibilityMode?: boolean;
  selectMessageRef: HTMLElement;
};

type MobxProps = {
  senderId: string;
  markConversationAsRead: (conversation: unknown) => void;
  setScrollbarsElement: (ref: React.RefObject<Node>, element: string) => void;
  currentConversation?: { featureService?: string };
  acknowledgeEscalation: (esclation: EscalationExecution) => void;
  escalateMessage: (id: string) => void;
  isRolesTransitionFeatureFlagEnabled: boolean;
  selectEscalationGroupConversation: (esclation: EscalationExecution) => void;
  skipEscalation: (esclation: EscalationExecution) => void;
  showCancelEscalationModal: (esclation: EscalationExecution) => void;
  showRemoveEscalationGroupModal: (esclation: EscalationExecution) => void;
  showEscalationPolicyInfoPane: (esclation: EscalationExecution) => void;
  canEscalate: unknown;
  resend: (id: string, { senderId }: { senderId: string }) => void;
  resendAsPriority: (id: string, { senderId }: { senderId: string }) => void;
  selectMessage: (message: MessageContextMenuProps['message']) => void;
  showMessageInfoModal: (id: string) => void;
  showMessageMultiSelect: (type: string) => void;
  isProviderNetwork: unknown;
  currentRoleBotUserIds: string[];
  currentUserId: string;
};

class MessageContextMenu extends Component<MessageContextMenuProps & MobxProps> {
  render() {
    const {
      canEscalate,
      currentRoleBotUserIds,
      currentUserId,
      isProviderNetwork,
      isRolesTransitionFeatureFlagEnabled,
      message,
      currentConversation,
      accessibilityMode,
    } = this.props;
    let { canRecall } = message;
    const { counterParty, escalationExecution, senderOrganization, senderStatus, subType } =
      message;

    const menuItems = [];

    if (senderStatus === 'SENDING') {
      menuItems.push(
        <MenuItem
          key="messagedetails"
          className={classes('messageDetails')}
          onClick={this._viewMessageDetails}
        >
          View Message Details
        </MenuItem>
      );

      return (
        <MenuItemList accessibilityMode={accessibilityMode} className={classes()}>
          {menuItems}
        </MenuItemList>
      );
    }

    const { groupType } = counterParty || {};
    let { allowPriorityMessage, isForwardAllowed } = senderOrganization;
    const isFailed = senderStatus === 'FAILED';
    const isRetrying = senderStatus === 'RETRYING';
    const isNormalPriority = message.priority === 'NORMAL';
    const isSystemMessage = subType === MessageSubTypes.SYSTEM;
    const isSendingUser = message.senderId === currentUserId;
    let isDeleteAllowed = !isSendingUser;
    const isVWRConversation = currentConversation?.featureService === 'vwr';

    let canAcknowledge = false,
      canSkip = false,
      canCancelEscalation = false,
      canRemoveEscalation = false;
    let hasCarbonCopyMessage = false,
      hasEscalationGroup = false,
      isEscalationGroup = false;
    if (!isProviderNetwork) {
      allowPriorityMessage = false;
      isForwardAllowed = false;
    }
    if (escalationExecution) {
      const { currentStep, group: escalationGroup, path, status, targetId } = escalationExecution;
      const canAcknowledgeIds = [targetId];
      let canSkipIds = [targetId];
      let lastLevel = 0;

      if (path) {
        for (const step of path) {
          lastLevel++;
          const { targets } = step;
          const entityIds = [];
          for (const { id: targetId } of targets) {
            entityIds.push(targetId);
            canAcknowledgeIds.push(targetId);
          }
          if (lastLevel === currentStep) canSkipIds = entityIds;
        }
      }

      if (isRolesTransitionFeatureFlagEnabled) {
        const { additionalTargets } = escalationExecution;

        if (additionalTargets) {
          for (const { level, target } of additionalTargets) {
            if (level >= currentStep) {
              canSkipIds.push(target.id);
              canAcknowledgeIds.push(target.id);
            }
          }
        }
      }

      const isActive = status === 'IN_PROGRESS';
      canSkip =
        isActive &&
        currentStep < lastLevel &&
        !isSystemMessage &&
        (canSkipIds.includes(currentUserId) ||
          canSkipIds.some((id) => currentRoleBotUserIds.includes(id)));
      canAcknowledge =
        isActive &&
        !isSendingUser &&
        !isSystemMessage &&
        (canAcknowledgeIds.includes(currentUserId) ||
          canAcknowledgeIds.some((id) => currentRoleBotUserIds.includes(id)));
      hasCarbonCopyMessage = !!escalationExecution.carbonCopyMessage;
      hasEscalationGroup = !!(escalationGroup && escalationGroup.conversation);
      canRecall = status === 'CANCELLED' && !hasEscalationGroup && canRecall;
      isDeleteAllowed = !hasEscalationGroup && isDeleteAllowed;
      canRemoveEscalation = status === 'CANCELLED' && hasEscalationGroup && isSendingUser;
      canCancelEscalation = isActive && isSendingUser;
      isEscalationGroup = groupType === 'ESCALATION';
    }

    if (isSystemMessage) {
      isForwardAllowed = false;
      isDeleteAllowed = false;
      canRecall = false;
      canRemoveEscalation = false;
      canCancelEscalation = false;
    }

    if (isVWRConversation) {
      isForwardAllowed = false;
      canRecall = false;
      allowPriorityMessage = false;
      isDeleteAllowed = false;
    }

    if (!isFailed && !isRetrying) {
      if (!isProviderNetwork && !isVWRConversation) {
        menuItems.push(
          <MenuItem
            key="forwardtoprovidermessage"
            className={classes('forwardToProviderMessage')}
            onClick={this._showMultiSelect('forward')}
          >
            Forward to Provider
          </MenuItem>
        );
      }

      if (canAcknowledge) {
        const acknowledgeText = hasEscalationGroup
          ? 'Acknowledge Message'
          : 'Acknowledge Escalation';
        menuItems.push(
          <MenuItem
            key="acknowledgemessage"
            className={classes('acknowledgeEscalation')}
            onClick={this._onAcknowledgeEscalation}
          >
            {acknowledgeText}
          </MenuItem>
        );
        if (canSkip) {
          menuItems.push(
            <MenuItem
              key="escalateahead"
              className={classes('escalateAhead')}
              onClick={this._skipEscalation}
            >
              Escalate Ahead
            </MenuItem>
          );
        }
      }
      if (escalationExecution && hasEscalationGroup && hasCarbonCopyMessage && !isSystemMessage) {
        menuItems.push(
          <MenuItem
            key="viewescalation"
            className={classes('viewEscalation')}
            onClick={this._viewEscalationDetails}
          >
            View Escalation Details
          </MenuItem>
        );
      }
      if (hasEscalationGroup && !isEscalationGroup && !isSystemMessage) {
        menuItems.push(
          <MenuItem
            key="gotoescalation"
            className={classes('goToEscalation')}
            onClick={this._goToEscalation}
          >
            Go to Escalation Group
          </MenuItem>
        );
      }
      if (isForwardAllowed) {
        menuItems.push(
          <MenuItem
            key="forward"
            className={classes('forward')}
            onClick={this._showMultiSelect('forward')}
          >
            Forward
          </MenuItem>
        );
      }

      if (isDeleteAllowed) {
        menuItems.push(
          <MenuItem
            key="delete"
            className={classes('delete')}
            onClick={this._showMultiSelect('delete')}
          >
            Delete
          </MenuItem>
        );
      }

      if (isSendingUser && !escalationExecution && !isSystemMessage) {
        menuItems.push(
          <MenuItem key="resend" className={classes('resend')} onClick={this._resendMessage}>
            Resend
          </MenuItem>
        );
        if (allowPriorityMessage && isNormalPriority) {
          menuItems.push(
            <MenuItem
              key="resendaspriority"
              className={classes('resendAsPriority')}
              onClick={this._resendMessageAsPriority}
            >
              Resend as Priority
            </MenuItem>
          );
        }
      }

      if (canEscalate && isNormalPriority && !isSystemMessage) {
        menuItems.push(
          <MenuItem key="escalate" className={classes('escalate')} onClick={this._escalateMessage}>
            Escalate Message
          </MenuItem>
        );
      }
    }

    menuItems.push(
      <MenuItem
        key="messagedetails"
        className={classes('messageDetails')}
        onClick={this._viewMessageDetails}
      >
        View Message Details
      </MenuItem>
    );

    if (canRemoveEscalation) {
      menuItems.push(
        <MenuItem
          key="removeescalation"
          className={classes('removeEscalation')}
          onClick={this._removeEscalation}
        >
          Remove Escalation
        </MenuItem>
      );
    }
    if (canCancelEscalation) {
      menuItems.push(
        <MenuItem
          key="cancelescalation"
          className={classes('cancelEscalation')}
          onClick={this._cancelEscalation}
        >
          Cancel Escalation
        </MenuItem>
      );
    }

    if (canRecall) {
      menuItems.push(
        <MenuItem
          key="recall"
          className={classes('recall')}
          onClick={this._showMultiSelect('recall')}
        >
          Recall
        </MenuItem>
      );
    }

    return (
      <MenuItemList accessibilityMode={accessibilityMode} className={classes()}>
        {menuItems}
      </MenuItemList>
    );
  }

  _viewMessageDetails = () => {
    const { message, showMessageInfoModal } = this.props;
    showMessageInfoModal(message.id);
    this._finishMenuItem();
  };

  _showMultiSelect = (type: string) => {
    return () => {
      const {
        message,
        selectMessage,
        showMessageMultiSelect,
        accessibilityMode,
        selectMessageRef,
      } = this.props;
      this._setScrollbarsElement();
      showMessageMultiSelect(type);
      selectMessage(message);
      this._finishMenuItem();
      setTimeout(() => {
        if (accessibilityMode && selectMessageRef) {
          selectMessageRef.focus();
        }
      }, 0);
    };
  };

  _setScrollbarsElement = () => {
    const { messageRef, setScrollbarsElement } = this.props;
    setScrollbarsElement(messageRef, 'firstMessageSelected');
  };

  _resendMessage = () => {
    const { message, resend, senderId } = this.props;
    resend(message.id, { senderId });
    this._finishMenuItem('resend');
  };

  _resendMessageAsPriority = () => {
    const { message, resendAsPriority, senderId } = this.props;
    resendAsPriority(message.id, { senderId });
    this._finishMenuItem('resend');
  };

  _escalateMessage = () => {
    const { escalateMessage, message } = this.props;
    escalateMessage(message.id);
    this._finishMenuItem();
  };

  _viewEscalationDetails = () => {
    const { message, showEscalationPolicyInfoPane } = this.props;
    const { escalationExecution } = message || {};
    if (escalationExecution) showEscalationPolicyInfoPane(escalationExecution);
    this._finishMenuItem();
  };

  _goToEscalation = () => {
    const { message, selectEscalationGroupConversation } = this.props;
    const { escalationExecution } = message || {};
    if (escalationExecution) selectEscalationGroupConversation(escalationExecution);
    this._finishMenuItem();
  };

  _onAcknowledgeEscalation = () => {
    const { acknowledgeEscalation, message } = this.props;
    const { escalationExecution } = message || {};
    if (escalationExecution) acknowledgeEscalation(escalationExecution);
    this._finishMenuItem();
  };

  _cancelEscalation = () => {
    const { message, showCancelEscalationModal } = this.props;
    const { escalationExecution } = message || {};
    if (escalationExecution) showCancelEscalationModal(escalationExecution);
    this._finishMenuItem();
  };

  _skipEscalation = () => {
    const { message, skipEscalation } = this.props;
    const { escalationExecution } = message || {};
    if (escalationExecution) skipEscalation(escalationExecution);
    this._finishMenuItem();
  };

  _removeEscalation = () => {
    const { message, showRemoveEscalationGroupModal } = this.props;
    const { escalationExecution } = message || {};
    if (escalationExecution) showRemoveEscalationGroupModal(escalationExecution);
    this._finishMenuItem();
  };

  _finishMenuItem = (name?: string) => {
    const { message, markConversationAsRead, sideEffect } = this.props;
    const { conversation } = message;
    if (conversation) markConversationAsRead(conversation);
    sideEffect(name || '');
  };
}

export default mobxInjectSelect<MessageContextMenuProps, MobxProps>({
  composeMessageStore: ['senderId'],
  conversationStore: ['markConversationAsRead', 'setScrollbarsElement', 'currentConversation'],
  escalationStore: [
    'acknowledgeEscalation',
    'escalateMessage',
    'selectEscalationGroupConversation',
    'skipEscalation',
    'showCancelEscalationModal',
    'showRemoveEscalationGroupModal',
    'showEscalationPolicyInfoPane',
  ],
  messageStore: [
    'canEscalate',
    'resend',
    'resendAsPriority',
    'selectMessage',
    'showMessageInfoModal',
  ],
  messengerStore: ['isRolesTransitionFeatureFlagEnabled', 'showMessageMultiSelect'],
  networkStore: ['isProviderNetwork'],
  roleStore: ['currentRoleBotUserIds'],
  sessionStore: ['currentUserId'],
})(MessageContextMenu);
