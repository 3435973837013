import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BEM from '../bem';
import { mobxInjectSelect } from '../utils';
import { ReactComponent as AcceptedSvg } from '../images/request-accepted.svg';
import { ReactComponent as DeniedSvg } from '../images/request-denied.svg';
import DotsIndicator from './DotsIndicator';

import RoleAvatar from './RoleAvatar';
import UserAvatar from './UserAvatar/UserAvatar';

const classes = BEM.with('RequestToJoinTeam');

class RequestToJoinTeam extends Component {
  static propTypes = {
    _getRequestState: PropTypes.func.isRequired,
    acceptRequestToJoin: PropTypes.func.isRequired,
    declineRequestToJoin: PropTypes.func.isRequired,
    message: PropTypes.shape({
      createdAt: PropTypes.object,
      currentSenderRole: PropTypes.string,
      sender: PropTypes.object,
    }).isRequired,
  };

  render() {
    const { _getRequestState, message } = this.props;
    const { createdAt, currentSenderRole, sender, teamRequest } = message;
    if (!sender) return null;

    const { status } = teamRequest || {};
    const role = currentSenderRole ? currentSenderRole : sender.isRoleBot ? sender.botRole : null;
    const isLoadingTeamRequest = !(teamRequest && teamRequest.id && teamRequest.teamId);
    const isPendingRequest =
      teamRequest && teamRequest.id ? _getRequestState(teamRequest.id).pending : false;

    const avatar = role ? (
      <RoleAvatar
        ariaLabel={'Conversation Avatar ' + role.displayName}
        user={sender}
        role={role}
        size={30}
      />
    ) : (
      <UserAvatar
        ariaLabel={'Conversation Avatar ' + sender.displayName}
        user={sender}
        size="fit"
      />
    );
    const avatarFragment = <div className={classes('user-avatar-wrapper')}>{avatar}</div>;

    return (
      <div className={classes()}>
        <div className={classes('requests')}>
          {avatarFragment}
          <div className={classes('requester-info')}>
            <div className={classes('requester')}> {sender.displayName} </div>
            {sender.title && <div className={classes('requester-title')}> {sender.title} </div>}
          </div>
        </div>
        <div className={classes('request-text')}>Sent a request to join the team.</div>
        <div aria-label="Message Bubble Time" className={classes('message-time')}>
          {moment(createdAt).format('h:mm A')}
        </div>
        {status !== 'accepted' && status !== 'declined' && (
          <div className={classes('request-buttons')}>
            <button
              className={classes('deny-request', {
                isLoading: isLoadingTeamRequest,
              })}
              disabled={isLoadingTeamRequest || isPendingRequest}
              onClick={this._denyRequest}
              type="button"
            >
              {isLoadingTeamRequest || isPendingRequest ? (
                <DotsIndicator size={10} speed={0.5} color={'#fff'} />
              ) : (
                'Decline'
              )}
            </button>
            <button
              className={classes('accept-request', {
                isLoading: isLoadingTeamRequest,
              })}
              disabled={isLoadingTeamRequest || isPendingRequest}
              onClick={this._acceptRequest}
              type="button"
            >
              {isLoadingTeamRequest || isPendingRequest ? (
                <DotsIndicator size={10} speed={0.5} color={'#fff'} />
              ) : (
                'Accept'
              )}
            </button>
          </div>
        )}
        <div className={classes('request-final')}>
          {status === 'accepted' && (
            <Fragment>
              <AcceptedSvg />
              <div className={classes('request-accepted')}>Accepted</div>
            </Fragment>
          )}
          {status === 'declined' && (
            <Fragment>
              <DeniedSvg />
              <div className={classes('request-denied')}>Declined</div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }

  _acceptRequest = () => {
    const { acceptRequestToJoin, message } = this.props;
    const { teamRequest = {} } = message;

    acceptRequestToJoin(teamRequest.id, teamRequest.teamId);
  };

  _denyRequest = () => {
    const { declineRequestToJoin, message } = this.props;
    const { teamRequest = {} } = message;

    declineRequestToJoin(teamRequest.id, teamRequest.teamId);
  };
}

export default mobxInjectSelect({
  teamStore: ['_getRequestState', 'acceptRequestToJoin', 'declineRequestToJoin'],
})(RequestToJoinTeam);
