import React, { useEffect, useContext, useReducer } from 'react';
import TCClient from '../../client';
import mobxInjectSelect from '../../common/utils/mobxInjectSelect';
import reducer from './reducer';
import { AppDispatch } from './actions';

export type AppState = {
  virtualWaitingRoom: {
    totalNewVisitsCount: number;
    newVisitorCounts: {
      total: number;
      [key: string]: number;
    };
  };
};

type AppContextShape = {
  state: AppState;
  dispatch: AppDispatch;
};

const AppContext = React.createContext<AppContextShape>({} as AppContextShape);
export const useAppContext = () => useContext(AppContext);

const INITIAL_STATE: AppState = {
  virtualWaitingRoom: {
    totalNewVisitsCount: 0,
    newVisitorCounts: {
      total: 0,
    },
  },
};

export type MobxProps = {
  currentOrganizationId: string;
};

const AppContextProvider: React.FC<MobxProps> = ({ children, currentOrganizationId }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const value = { state, dispatch };

  useEffect(() => {
    const incrementTotalNewVisitorsCount = ({
      roomId,
      organizationId,
    }: {
      organizationId: string;
      roomId: string;
    }) => {
      if (organizationId !== currentOrganizationId) return;
      dispatch({
        type: 'VWR_INCREASE_NEW_VISITS_COUNT',
        payload: roomId,
      });
    };
    const decrementTotalNewVisitorsCount = ({ roomId }: { roomId: string }) => {
      dispatch({
        type: 'VWR_DECREASE_NEW_VISITS_COUNT',
        payload: roomId,
      });
    };

    const updateBlueDotIndicator = ({
      organizationId,
      previousStatus,
      status,
      roomId,
    }: {
      visitId: string;
      status: string;
      previousStatus: string;
      organizationId: string;
      roomId: string;
    }) => {
      if (organizationId !== currentOrganizationId) return;
      if (previousStatus === 'NEW') {
        decrementTotalNewVisitorsCount({ roomId });
      } else if (status === 'NEW') {
        incrementTotalNewVisitorsCount({ organizationId: organizationId, roomId: roomId });
      }
    };

    TCClient.virtualWaitingRoom.on('vwr:visitor:add', incrementTotalNewVisitorsCount);
    TCClient.virtualWaitingRoom.on('vwr:status:update', updateBlueDotIndicator);

    return () => {
      TCClient.virtualWaitingRoom.off('vwr:visitor:add', incrementTotalNewVisitorsCount);
      TCClient.virtualWaitingRoom.off('vwr:status:update', updateBlueDotIndicator);
    };
  }, [state.virtualWaitingRoom.newVisitorCounts.total, currentOrganizationId]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default mobxInjectSelect<{}, MobxProps>({
  messengerStore: ['currentOrganizationId'],
})(AppContextProvider);
