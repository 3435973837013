import React, { useEffect, useState } from 'react';
import { mobxInjectSelect } from '../../../../common/utils';
import { UserAvatar, UserName } from '../../../../common/components';
import CallButton from '../../../../common/components/Call/CallButton';
import BEM from '../../../../common/bem';
import PfCallOrigins from '../../../../models/enums/PfCallOrigins';
import { Organization } from '../../../../types';

const classes = BEM.with('ConversationHeader');

type UserConversationHeaderProps = {
  isVideoCallEnabled: boolean;
  isVoiceCallEnabled: boolean;
  organizationId: string;
  user: {
    profileByOrganizationId: Record<
      string,
      {
        autoForwardDisplayNames: string[];
        autoForwardReceivers: { displayName: string }[];
        autoForwardReceiverIds: string[];
        dndAutoForwardEntities: string[];
      }
    >;
    displayName: string;
    status: string;
    members: { id: string }[];
    recipientId: string;
    recipientName: string;
  };
};

type MobxProps = {
  counterPartyProfile: { displayName: string; id: string };
  currentOrganization: Organization;
  findUser: (id: string) => Promise<{ displayName: string }>;
  getAutoForwardEntitiesIdsByOrg: (org: Organization) => { displayName: string }[];
  isExtendedAutoForwardOptionsEnabled: boolean;
};

const UserConversationHeader: React.FC<UserConversationHeaderProps & MobxProps> = ({
  counterPartyProfile,
  currentOrganization,
  findUser,
  getAutoForwardEntitiesIdsByOrg,
  isExtendedAutoForwardOptionsEnabled,
  isVideoCallEnabled,
  isVoiceCallEnabled,
  user,
}) => {
  const [autoForwardDisplayName, setAutoForwardDisplayName] = useState(
    counterPartyProfile?.displayName
  );
  const [latestCounterPartyId, setLatestCounterPartyId] = useState(counterPartyProfile?.id);

  useEffect(() => {
    async function updateCounterParty() {
      const id = counterPartyProfile?.id;
      if (counterPartyProfile && id) {
        try {
          await findUser(counterPartyProfile.id);
        } catch (error) {
          console.error(error);
        }
      }
      if (latestCounterPartyId === id) {
        setAutoForwardDisplayName(counterPartyProfile?.displayName);
      }
    }
    setLatestCounterPartyId(counterPartyProfile?.id);
    updateCounterParty();
  }, [
    counterPartyProfile,
    currentOrganization,
    findUser,
    getAutoForwardEntitiesIdsByOrg,
    isExtendedAutoForwardOptionsEnabled,
    latestCounterPartyId,
  ]);

  return (
    <div className={classes()}>
      <div className={classes({ user: true })} title={user.displayName}>
        <UserAvatar user={user} size="fit" showPresenceIndicator={true} />
        <span className={classes('user-details')}>
          <span className={classes('basic-details')}>
            <div id="conversation-name">
              <UserName user={user} />
            </div>
            {(isVideoCallEnabled || isVoiceCallEnabled) && (
              <span className={classes('phone-icon')}>
                <CallButton entity={user} origin={PfCallOrigins.CONVERSATION} />
              </span>
            )}
          </span>
          {user.status && (
            <span className={classes('user-status')} aria-label={'Conversation User Status'}>
              {user.status}
            </span>
          )}
        </span>
      </div>
      {autoForwardDisplayName && !isExtendedAutoForwardOptionsEnabled && (
        <div className={classes('auto-forward-details')}>
          Auto-forwarding to{' '}
          <span className={classes('display-name')}>{autoForwardDisplayName}</span> at the moment
        </div>
      )}
    </div>
  );
};

export default mobxInjectSelect<UserConversationHeaderProps, MobxProps>({
  conversationStore: ['counterPartyProfile'],
  messengerStore: ['currentOrganization', 'isExtendedAutoForwardOptionsEnabled'],
  userStore: ['findUser', 'getAutoForwardEntitiesIdsByOrg'],
})(UserConversationHeader);
