import createMobxModel from './createMobxModel';

const Tag = createMobxModel({
  name: 'tag',

  fields: ['color', 'displayName', 'isDefault', 'name', 'tagId'],

  relations: {
    one: {
      organization: { type: 'organization' },
    },
    many: {
      roles: { type: 'role' },
      teams: { type: 'team' },
    },
  },
});

export default Tag;
