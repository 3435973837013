export default class ConnectionUnavailableError extends Error {
  static CODE = 'connection-unavailable';

  code: string;

  constructor() {
    super('No internet connection');
    this.code = ConnectionUnavailableError.CODE;
  }
}
