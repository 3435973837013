import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Checkbox } from '../../../shared-components/Checkbox/Checkbox';
import { CtaButton } from '../../../shared-components/CtaButton/CtaButton';
import { Input } from '../../../shared-components/Input/Input';
import { Table } from '../../../shared-components/Table/Table';
import { ViewContainer } from '../../../shared-components/View/ViewContainer/ViewContainer';
import styles from '../OrganizationSettings.module.css';

import { TabProps } from './';
import { actions, thunk, useAppDispatch, useAppSelector } from 'redux-stores';

export default function CustomDirectories({ onClose }: TabProps) {
  const dispatch = useAppDispatch();
  const [customDirectories, setCustomDirectories] = useState<string[]>([]);
  const [newCustomDirectory, setNewCustomDirectory] = useState('');
  const isCustomDirectoriesEnabled = useAppSelector(
    (state) => state.orgSettings.settings.isCustomDirectoriesEnabled
  );

  useEffect(() => {
    const init = async () => {
      const directories = await thunk.fetchDirectories(dispatch);
      setCustomDirectories(directories);
    };

    init();
  }, [dispatch]);

  return (
    <ViewContainer
      {...{
        header: 'Custom Directories',
        subHeader:
          'Create multiple, customized directories for your organization. Note: You must add search groups before you can enable them.',
        onClose,
      }}
    >
      <div>
        <div className={styles.customDirectoryRowContainer}>
          <span className={styles.customDirectoryEnableText}>Enable:</span>
          <Checkbox
            customStyles={{ marginRight: '15px' }}
            dataTestId="enableCD checkbox"
            disabled={customDirectories.length === 0}
            checked={isCustomDirectoriesEnabled}
            onClick={() => {
              dispatch(
                actions.updateSettings({
                  isCustomDirectoriesEnabled: !isCustomDirectoriesEnabled,
                })
              );
            }}
          />
          <CtaButton
            dataTestId="Save Custom Directory Setting"
            primary
            label="Save"
            onClick={() => thunk.saveSettings({ dispatch })}
          />
        </div>
        {customDirectories.length > 0 && (
          <Table
            actionsOrder={[{ name: 'Delete', type: 'delete' }]}
            columns={[{ field: 'name', headerName: 'Name' }]}
            rows={customDirectories.map((name) => ({ id: name, name }))}
            onRowDelete={async ({ name }) => {
              try {
                await thunk.deleteDirectory(dispatch, name);
                setCustomDirectories(customDirectories.filter((cd) => cd !== name));
                dispatch(
                  actions.updateSettings({
                    customDirectories: customDirectories.filter((cd) => cd !== name),
                  })
                );
              } catch (error) {
                console.error('Could not delete Custom Directory', name, error);
              }
            }}
          />
        )}
        <div
          className={classNames(
            styles.customDirectoryRowContainer,
            styles.customDirectoryAddContainer
          )}
        >
          <div className={styles.inputEntry}>
            <Input
              dataTestId="cdInputField"
              onInputChange={(ev) => setNewCustomDirectory(ev.target.value)}
              value={newCustomDirectory}
            />
          </div>
          <CtaButton
            dataTestId="Create Custom Directory"
            primary
            label="Add"
            onClick={async () => {
              try {
                await thunk.createDirectory(dispatch, newCustomDirectory);
                setCustomDirectories([...customDirectories, newCustomDirectory]);
                dispatch(
                  actions.updateSettings({
                    customDirectories: [...customDirectories, newCustomDirectory],
                  })
                );
                setNewCustomDirectory('');
              } catch (error) {
                console.error('Could not create Custom Directory', newCustomDirectory, error);
              }
            }}
          />
        </div>
      </div>
    </ViewContainer>
  );
}
