import React from 'react';
import classNames from 'classnames';

import styles from './SettingText.module.css';

export interface SettingTextProps {
  /**
   * Setting contents
   */
  label: string;
  /**
   * How large should the button be?
   */
  size: 'small' | 'large';
  /**
   * what alignment the text is
   */
  alignment?: 'top' | 'bottom';

  children?: React.ReactNode;
}

export const SettingText: React.FC<SettingTextProps> = ({ label, size, alignment, children }) => {
  return (
    <div
      className={classNames(
        styles.root,
        size === 'large' ? styles.large : styles.small,
        alignment === 'top' ? styles.top : alignment === 'bottom' ? styles.bottom : ''
      )}
    >
      {label}
      {children}
    </div>
  );
};
