import React, { Component } from 'react';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import BEM from '../bem';

const classes = BEM.with('BuildInfo');

class BuildInfo extends Component {
  render() {
    const { buildInfo } = this.props;
    const lines = [
      { data: 'version', title: 'Version' },
      { data: 'branch', title: 'Branch' },
      { data: 'authorDate', title: 'Date' },
    ];

    return (
      <div className={classes()}>
        <div className={classes('container')}>
          <div className={classes('header')}>
            <p className={classes('header-line')}>Build Info</p>
          </div>
          <div className={classes('body')}>
            {lines.map(({ data, title }) => (
              <p key={data} className={classes('body-line')}>
                <span className={classes('body-line-title')}>{title}:&nbsp;</span>
                <span className={classes('body-line-value')}>{buildInfo[data].trim()}</span>
              </p>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  messengerStore: ['buildInfo'],
})(BuildInfo);
