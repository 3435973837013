import React from 'react';
import { observer } from 'mobx-react';
import BEM from '../../bem';

const classes = BEM.with('PresenceIndicatorDnd');

type PresenceIndicatorDndProps = {
  size: string;
};

const PresenceIndicatorDnd = ({ size = 'MEDIUM' }: PresenceIndicatorDndProps) => {
  return (
    <svg
      className={classes({ size })}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="4" fill="#D92A25" />
      <rect x="4" y="7" width="8" height="2" fill="white" />
    </svg>
  );
};

export default observer(PresenceIndicatorDnd);
