import React, { useEffect, useRef, useState } from 'react';
import ToolTip from '../../../widgets/messenger/components/ToolTip';
import useClickOutside from '../../../common/hooks/useClickOutside';
import styles from './ActionBarMenuOption.module.css';

export type menuOptionButtons = {
  buttonName: string;
  disabled?: boolean;
  onClick?: () => void;
  toolTip?: string;
};

export type ActionBarDropButtonProps = {
  customStyles?: Record<string, string>;
  showDropDown?: boolean;
  onClose: () => void;
  /**
   * Array of dropdown buttons
   */
  menuOptionButtons: menuOptionButtons[];
};

export default function ActionBarMenuOption({
  customStyles = {},
  showDropDown: _showDropDown = false,
  menuOptionButtons,
  onClose,
}: ActionBarDropButtonProps) {
  const [showDropDown, setShowDropDown] = useState<boolean>(true);
  const [selectedButtonName, setSelectedButtonName] = useState(menuOptionButtons[0]?.buttonName);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const currentStyle = { ...styles, ...customStyles };

  const button = (name: string, disabled?: boolean, onClick?: () => void) => {
    const buttonClick = () => {
      setSelectedButtonName(name);
      if (onClick) {
        onClick();
      }
    };
    return (
      <div
        id={selectedButtonName}
        onClick={(e) => {
          if (disabled) e.stopPropagation();
          else buttonClick();
        }}
        style={{
          color: disabled ? 'grey' : 'black',
          fontFamily: 'Inter',
          fontWeight: 500,
          padding: !showDropDown ? '0' : '3px 0 0 5px',
          textAlign: 'left',
          fontSize: '12px',
          fontStyle: 'normal',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        {name}
      </div>
    );
  };

  useClickOutside([containerRef], (e) => {
    e.stopPropagation();
    setShowDropDown(false);
    onClose();
  });

  useEffect(() => {
    setShowDropDown(_showDropDown);
  }, [_showDropDown]);

  return (
    <div id="parent" className={currentStyle.parent}>
      {showDropDown && (
        <div
          ref={containerRef}
          className={currentStyle.dropDown}
          style={customStyles}
          onClick={() => {
            if (!showDropDown) setShowDropDown(true);
          }}
        >
          {menuOptionButtons.map((dropDownButton) => {
            const _button = button(
              dropDownButton.buttonName,
              dropDownButton?.disabled || false,
              () => {
                setShowDropDown(false);
                onClose();
                dropDownButton.onClick && dropDownButton.onClick();
              }
            );
            return (
              <div
                key={dropDownButton.buttonName}
                className={dropDownButton.disabled ? currentStyle.disabledItem : currentStyle.item}
                ref={buttonRef}
                style={{
                  borderRadius: 4,
                  height: 25,
                  textAlign: 'center',
                }}
              >
                {dropDownButton.toolTip ? (
                  <ToolTip text={dropDownButton.toolTip} textAlign={'center'}>
                    {_button}
                  </ToolTip>
                ) : (
                  _button
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
