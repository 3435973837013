import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Images from '../../../models/enums/Images';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { mobxInjectSelect } from '../../utils';
import { AvatarDropzone, EscButton, UserName } from '../';
import { MobileLogin } from './';

const classes = BEM.with('ProfileHeader');

class ProfileHeader extends Component {
  static propTypes = {
    closeProfile: PropTypes.func.isRequired,
    currentUser: propTypes.user.isRequired,
    openModal: PropTypes.func.isRequired,
    organizations: propTypes.organizationArray.isRequired,
    update: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.escButton && this.escButton.focus();
  }

  render() {
    const { closeProfile, currentUser, organizations } = this.props;
    const allowQrCode = organizations.some(
      ({ isContacts, pendingAdminApproval }) => !isContacts && !pendingAdminApproval
    );

    return (
      <div className={classes()}>
        <div className={classes('right-buttons')}>
          {allowQrCode && <MobileLogin />}
          <EscButton onClose={closeProfile} ref={this._setEscButton} />
        </div>
        <div className={classes('user-details')}>
          <AvatarDropzone entity={currentUser} onDropAccepted={this._onAvatarSelected} size={77} />
          <UserName className={classes('user-name')} user={currentUser} />
        </div>
      </div>
    );
  }

  _setEscButton = (ref) => {
    this.escButton = ref;
  };

  _onAvatarSelected = async ([file]) => {
    const { openModal, update } = this.props;
    if (!file) return;

    if (file.size > Images.MAX_AVATAR_FILE_SIZE_BYTES) {
      openModal('rejectedFileSize', {
        maxFileSize: `${Images.MAX_AVATAR_FILE_SIZE_MB}MB`,
      });
    } else {
      await update({ avatarFile: file });
    }
  };
}

export default mobxInjectSelect({
  messengerStore: ['closeProfile', 'organizations'],
  modalStore: ['openModal'],
  sessionStore: ['currentUser'],
  userStore: ['update'],
})(ProfileHeader);
