import React, { useState } from 'react';

import TCClient from '../../../../../client';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';
import { Toast } from '../../../../shared-components/Toast/Toast';

type UnlockUserProps = {
  isLocked?: boolean;
  onClose: () => void;
  userToken: string;
};

export const UnlockUser = ({ isLocked = false, onClose, userToken }: UnlockUserProps) => {
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);
  const [isFailureToastOpen, setIsFailureToastOpen] = useState(false);
  return (
    <>
      <ViewContainer
        header={'Unlock User'}
        subHeader={
          'Use this to unlock the user after too many invalid authentication code attempts.'
        }
        onClose={onClose}
      >
        <div>
          <CtaButton
            primary={isLocked}
            disabled={!isLocked}
            size={'small'}
            label={'Unlock User'}
            onClick={async () => {
              try {
                await TCClient.adminUsers.unlockUser({ userToken });
                setIsSuccessToastOpen(true);
              } catch {
                setIsFailureToastOpen(true);
              }
            }}
          />
        </div>
      </ViewContainer>
      <Toast
        type="SUCCESS"
        message="This user's account has been unlocked."
        open={isSuccessToastOpen}
        onClose={() => {
          setIsSuccessToastOpen(false);
        }}
      />
      <Toast
        type="FAILURE"
        message="Error unlocking user."
        open={isFailureToastOpen}
        onClose={() => {
          setIsFailureToastOpen(false);
        }}
      />
    </>
  );
};
