const PfCallOrigins = {
  BANG: 'Bang',
  CONVERSATION_DETAILS: 'Conversation Details Screen',
  CONVERSATION: 'Conversation Screen',
  LOGS: 'Call Logs',
  PATIENT_DETAILS: 'Patient Details Screen',
  PROFILE: 'Patient Profile Screen',
  SEARCH: 'Search',
  VWR_CONVERSATION: 'VWR Conversation Screen',
} as const;

export default PfCallOrigins;
