import { useEffect, useRef, useState } from 'react';
import TCClient from '../../../../client';
import { DotsIndicator } from '../../../../common/components';
import Modal from '../../../../common/components/Modal';
import { Input } from '../../../shared-components/Input/Input';

import styles from './styles.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  mode:
    | { type: 'create'; data: { facilityDisplayName: string; facilityId: string } }
    | {
        type: 'edit';
        data: { facilityDisplayName: string; facilityId: string; unitName: string; unitId: string };
      };
};

export const UnitModal = ({ isOpen, onClose, mode }: Props) => {
  const dispatch = useAppDispatch();
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);

  const _unitName = mode.type === 'edit' ? mode.data.unitName : '';
  const [unitName, setUnitName] = useState(_unitName);

  const header =
    mode.type === 'edit' ? `Edit Unit` : `New Unit in ${mode.data.facilityDisplayName}`;

  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const [buttonHasFocus, setButtonHasFocus] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleMouseEnter = () => setButtonHasFocus(true);
  const handleMouseLeave = () => setButtonHasFocus(false);

  let formHasChanged = false;
  if (mode.type === 'edit') {
    formHasChanged = mode.data.unitName !== unitName || mode.data.unitName !== unitName;
  } else {
    formHasChanged = unitName !== '';
  }

  useEffect(() => {
    let ref: HTMLButtonElement | null = null;

    buttonRef.current?.addEventListener('mouseenter', handleMouseEnter);
    buttonRef.current?.addEventListener('mouseleave', handleMouseLeave);

    ref = buttonRef.current;

    return () => {
      if (ref) {
        ref.removeEventListener('mouseenter', handleMouseEnter);
        ref.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  });

  const close = () => {
    setUnitName(_unitName);
    setError('');
    onClose();
  };

  const saveUnit = async () => {
    try {
      setIsSaving(true);
      if (mode.type === 'edit') {
        const { rooms } = await TCClient.multiOrg.getUnit({
          orgId: selectedOrganizationId,
          unitId: mode.data.unitId,
        });
        const roomIds = rooms.map((r) => r.roomId);
        await TCClient.multiOrg.updateUnit({
          unitId: mode.data.unitId,
          unitName,
          orgId: selectedOrganizationId,
          roomIds,
        });
      } else {
        await TCClient.multiOrg.createUnit({
          orgId: selectedOrganizationId,
          facilityId: mode.data.facilityId,
          unitName,
          roomIds: [],
        });
      }
      dispatch(actions.setIsUnitsDataDirty({ isUnitsDataDirty: true }));
      setIsSaving(false);
      close();
    } catch (e) {
      if (e.text && typeof e.text === 'string' && e.text.includes('Invalid input for name.')) {
        setError(`This Unit name is already in use`);
      } else {
        setError('Oops! Something went wrong, please try again');
      }
      setIsSaving(false);
    }
  };

  return (
    <Modal
      bodyClass={styles.body}
      header={header}
      isOpen={isOpen}
      size={'medium'}
      onRequestClose={close}
    >
      <>
        <div>
          <div className={styles.errorContainer}>
            {error.length > 0 && <div className={styles.error}>{error}</div>}
          </div>
          <div className={styles.inputContainer}>
            <span className={styles.inputLabel}>Unit Name</span>
            <Input
              customCSS={styles.input}
              lengthLimit={100}
              onInputChange={(e) => setUnitName(e.target.value)}
              placeholder={`Type a unit name...`}
              value={unitName}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footerLeft}></div>
          <div className={styles.footerRight}>
            <button
              aria-label="Cancel"
              className={styles.cancelButton}
              onClick={close}
              type="button"
            >
              Cancel
            </button>
            <button
              ref={buttonRef}
              disabled={!formHasChanged}
              aria-label="Save"
              className={styles.saveButton}
              onClick={async () => {
                await saveUnit();
              }}
              type="button"
            >
              {isSaving && (
                <DotsIndicator color={buttonHasFocus ? '#fff' : '#4580d8'} size={10} speed={0.5} />
              )}
              {!isSaving && 'Save'}
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};
