import createMobxModel from './createMobxModel';

const MessageTemplate = createMobxModel({
  name: 'messageTemplate',

  fields: [
    'attachmentName',
    'attachmentSize',
    'attachmentType',
    'body',
    'createdOn',
    'hasAttachment',
    'isSmsCompatible',
    'network',
    'repository',
    'title',
    'updatedOn',
  ],

  relations: {
    one: {
      createdBy: { type: 'user' },
      organization: { type: 'organization' },
      updatedBy: { type: 'user' },
    },
  },
});

export default MessageTemplate;
