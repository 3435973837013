import React from 'react';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { Switch } from '../WebComponents';
import BEM from '../../bem';

const classes = BEM.with('PreserveConversation');

type PreserveGroupConversationProps = {
  groupId: string;
  preserve: boolean;
};

type MobxProps = {
  updateGroup: (groupId: string, data: { preserve: boolean }) => void;
};

const PreserveGroupConversation = ({
  groupId,
  updateGroup,
  preserve,
}: MobxProps & PreserveGroupConversationProps) => {
  const onSwitchChange = () => {
    const updatedPreserveValue = !preserve;
    updateGroup(groupId, { preserve: updatedPreserveValue });
  };

  return (
    <div aria-label="Info Pane Preserve Conversation" className={classes()}>
      <div className={classes('option-header')}>Preserve Group Conversation</div>
      <Switch checked={preserve} onSwitch={() => onSwitchChange()} />
    </div>
  );
};

export default mobxInjectSelect<PreserveGroupConversationProps, MobxProps>({
  groupStore: ['updateGroup'],
})(PreserveGroupConversation);
