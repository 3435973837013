import TCClient from '../../client';

export const getProviderBroadcastLists = async ({
  continuation,
  query,
  sortBy,
  sortOrder,
  organizationId,
}: {
  continuation: string;
  query: string;
  sortBy: string;
  sortOrder: string;
  organizationId: string;
}) => {
  const { metadata, results } = await TCClient.distributionLists.search({
    continuation,
    network: 'provider',
    organizationId,
    query,
    sortBy,
    sortOrder,
  });

  return {
    metadata,
    results,
  };
};

export const createProviderBroadcastList = async ({
  adminOnly,
  isAdSync = false,
  members = [],
  name,
  securityGroupValue,
  currentOrganizationId,
}: {
  adminOnly: boolean;
  isAdSync?: boolean;
  members?: string[];
  name: string;
  securityGroupValue?: string;
  currentOrganizationId: string;
}) => {
  const broadcastList = await TCClient.distributionLists.create({
    ...(securityGroupValue === undefined ? null : { securityGroup: securityGroupValue }),
    adminOnly,
    members,
    name,
    network: 'provider',
    isAdSync,
    organizationId: currentOrganizationId,
  });

  return broadcastList;
};

export const updateProviderBroadcastList = async ({
  adminOnly,
  currentOrganizationId,
  id,
  name,
  securityGroupValue,
}: {
  adminOnly: boolean;
  currentOrganizationId: string;
  id: string;
  name?: string;
  securityGroupValue?: string;
}) => {
  const broadcastList = await TCClient.distributionLists.update({
    ...(securityGroupValue === undefined ? null : { adSecurityGroup: securityGroupValue }),
    adminOnly,
    id,
    ...(name ? { name } : null),
    organizationId: currentOrganizationId,
  });

  return broadcastList;
};

export const loadProviderBroadcastListMembers = async ({
  continuation,
  currentOrganizationId,
  id,
  query,
  sortBy,
  sortOrder,
}: {
  continuation: string;
  currentOrganizationId: string;
  id: string;
  query: string;
  sortBy: Array<string>;
  sortOrder: Array<string>;
}) => {
  const { metadata, results } = await TCClient.distributionLists.findMembers(id, {
    continuation,
    network: 'provider',
    organizationId: currentOrganizationId,
    query,
    sortBy,
    sortOrder,
  });

  return {
    metadata,
    results,
  };
};

export const loadProviderBroadcastListSharedMembers = async ({
  continuation,
  currentOrganizationId,
  id,
  query,
  sortBy,
  sortOrder,
}: {
  continuation: string;
  currentOrganizationId: string;
  id: string;
  query: string;
  sortBy: Array<string>;
  sortOrder: Array<string>;
}) => {
  const { metadata, results } = await TCClient.distributionLists.findSharedMembers(id, {
    continuation,
    network: 'provider',
    organizationId: currentOrganizationId,
    query,
    sortBy,
    sortOrder,
  });

  return {
    metadata,
    results,
  };
};

export const addSharedMembers = async ({
  id,
  members,
  organizationId,
}: {
  id: string;
  members: Array<string>;
  organizationId: string;
}) => {
  await TCClient.distributionLists.addSharedMembers({
    id,
    members,
    organizationId,
  });
};

export const removeSharedMembers = async ({
  id,
  members,
  organizationId,
}: {
  id: string;
  members: Array<string>;
  organizationId: string;
}) => {
  await TCClient.distributionLists.removeSharedMembers({
    id,
    members,
    organizationId,
  });
};
