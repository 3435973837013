import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../../common/bem';
import propTypes from '../../../../common/propTypes';
import { mobxInjectSelect } from '../../../../common/utils';

const classes = BEM.with('NewItemButton');

class NewItemButton extends Component {
  static propTypes = {
    composeFromNewGroup: PropTypes.func.isRequired,
    currentOrganization: propTypes.organization,
    openNewGroupModal: PropTypes.func.isRequired,
    selectedFilter: PropTypes.string.isRequired,
    selectedFilterBar: PropTypes.string.isRequired,
  };

  render() {
    const { currentOrganization, selectedFilter, selectedFilterBar } = this.props;
    if (!currentOrganization) return null;

    const { forumsEnabled } = currentOrganization;
    if (selectedFilter === 'Forums' && !forumsEnabled) return null;

    if (selectedFilter !== 'Groups' && selectedFilter !== 'Forums') return null;
    if (selectedFilterBar === 'Right') return null;

    const type = selectedFilter.slice(0, selectedFilter.length - 1);
    return (
      <div className={classes()} onClick={this._openModal}>
        <div className={classes('text')}>{`NEW ${type}`}</div>
      </div>
    );
  }

  _openModal = () => {
    const { openNewGroupModal, composeFromNewGroup, selectedFilter } = this.props;
    if (selectedFilter === 'Groups') {
      composeFromNewGroup();
    } else {
      const type = selectedFilter.slice(0, selectedFilter.length - 1);
      openNewGroupModal({ type });
    }
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['composeFromNewGroup'],
  createGroupStore: ['openNewGroupModal'],
  messengerStore: ['currentOrganization'],
  rosterStore: ['selectedFilter', 'selectedFilterBar'],
})(NewItemButton);
