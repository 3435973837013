import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Role, User } from '../../../../types';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { mobxInjectSelect } from '../../../utils';
import { BasicModal } from '../..';

const { setModal } = actions;
const { updateRoleOwner } = thunk;

type OptInModalProps = {
  isOpen: boolean;
};

type MobxProps = {
  currentUser: User;
};

function OptInModal({ currentUser, isOpen }: OptInModalProps & MobxProps) {
  const dispatch = useDispatch();
  const selectedOrgId = useSelector((state: ReduxState) => state.collab.selectedOrgId);
  const selectedRole = useSelector((state: ReduxState) => state.entities.selectedEntity) as Role;

  const closeModal = () => {
    dispatch(setModal(undefined));
  };

  const optIn = async () => {
    await updateRoleOwner(dispatch, {
      method: 'optIn',
      organizationId: selectedOrgId,
      selectedRole: selectedRole,
      user: currentUser,
    });
    closeModal();
  };

  return (
    <BasicModal
      ariaLabelBody={'Opt In Info'}
      ariaLabelCancelButton={'Opt In Cancel'}
      ariaLabelCloseButton={'Opt InClose'}
      ariaLabelHeader={'Opt In Header'}
      ariaLabelSubmitButton={'Confirm Opt In'}
      bodyText={`This will remove ${selectedRole?.members?.[0]?.displayName} from the role. Are you sure you want to continue?`}
      headerText={'You are about to take over a role'}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={optIn}
      size={'medium'}
      theme={'normal'}
      submitText={'CONTINUE'}
      hasCloseButton={true}
      useWCL
    />
  );
}

export default mobxInjectSelect<OptInModalProps, MobxProps>({
  sessionStore: ['currentUser'],
})(OptInModal);
