import React from 'react';

import { AdminStateSlice } from '../../types';
import hasProductPermission from '../../utils/hasProductPermission';

import { mobxInjectSelect } from '../../../common/utils';
import LeftNavItem from './LeftNavItem';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

const { setUsersPageView, setForumsPageView } = actions;

type LeftNavProps = {};

type MobxProps = {
  allowMTOrg: boolean;
};

function LeftNav({ allowMTOrg }: LeftNavProps & MobxProps) {
  const dispatch = useAppDispatch();
  const { areForumsEnabled, permissions } = useAppSelector((state) => ({
    permissions: state.admin.permissions,
    areForumsEnabled: state.orgSettings.settings?.areForumsEnabled,
  }));

  const leftNavItems: {
    backgroundPosition: string;
    label: AdminStateSlice['selectedPage'];
    width: number;
    height: number;
    onClick?: () => void;
    dataTestId: string;
    visible?: boolean;
  }[] = [
    {
      backgroundPosition: '0 -1795px',
      label: 'Users',
      width: 32,
      height: 32,
      dataTestId: 'userDirectoryTab',
      onClick: () => {
        dispatch(setUsersPageView({ selectedUsersPageView: 'users' }));
      },
      visible: hasProductPermission('user_settings', permissions),
    },
    {
      backgroundPosition: '0 -1827px',
      label: 'Org Settings',
      width: 33,
      height: 31,
      dataTestId: 'orgSettingsTab',
      visible: hasProductPermission('org_settings', permissions),
    },
    {
      backgroundPosition: '0 -1885px',
      label: 'Forums',
      width: 33,
      height: 33,
      dataTestId: 'forumsTab',
      onClick: () => {
        dispatch(setForumsPageView({ selectedForumsPageView: 'forums' }));
      },
      visible: Boolean(hasProductPermission('public_groups', permissions) && areForumsEnabled),
    },
    {
      backgroundPosition: '0 -1919px',
      label: 'Organizations',
      width: 33,
      height: 36,
      dataTestId: 'organizationsTab',
      visible: hasProductPermission('create_org', permissions),
    },
    {
      backgroundPosition: '0 -1955px',
      label: 'Customer Support',
      width: 33,
      height: 28,
      dataTestId: 'customerSupportTab',
      visible: hasProductPermission('search_users', permissions),
    },
    {
      backgroundPosition: '0',
      label: 'Locations',
      width: 33,
      height: 35,
      dataTestId: 'locationTab',
      visible: allowMTOrg && hasProductPermission('user_settings', permissions),
    },
  ];
  return (
    <div style={{ width: '95px', height: '100%' }}>
      {leftNavItems.map(
        ({ backgroundPosition, label, onClick, width, height, dataTestId, visible }, key) => (
          <div key={key}>
            <LeftNavItem
              {...{
                backgroundPosition,
                label,
                onClick,
                width,
                height,
                dataTestId,
                visible,
              }}
            />
          </div>
        )
      )}
    </div>
  );
}

export default mobxInjectSelect<LeftNavProps, MobxProps>({
  messengerStore: ['allowMTOrg'],
})(LeftNav);
