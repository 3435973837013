import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import * as patientContextThunk from './thunk';

export const PATIENT_REFERENCE_SEARCH_RESULT_PAGE_SIZE = 10;
export const PATIENT_DIRECTORY_PAGE_SIZE = 50;

type PatientCareTeamMember = {
  uuid: string;
  avatar: string | null;
  firstName: string;
  lastName: string;
  title: string;
  rolName: string[];
  organization: string;
  accountToken: string | null;
};

type PatientListType = 'All' | 'My Patients';

type PatientCareTeam = {
  hits: PatientCareTeamMember[];
  continuation: string | null;
};

export type PatientContextSliceState = {
  isPatientContextEnabled: boolean;
  isCareTeamAllowed: boolean;
  selectedPatientReference?: PatientContextModel;
  selectedPatientReferenceDetail?: PatientContextModel;
  patientSearchView: PatientListType;
  patientReferenceSearchList?: Array<PatientContextModel>;
  patientReferenceSearchLoading: boolean;
  patientCareTeamSearchLoading: boolean;
  patientReferences: Record<string, PatientContextModel>;
  fetchPatientContextStatus: Record<
    string,
    'fulfilled' | 'pending' | 'timeout' | 'rejected' | undefined
  >;
  patientCareTeamModal: PatientCareTeam;
  patientCareTeamCompose: PatientCareTeam;
  isPatientReferenceSearchOnLastPage: boolean;
  patientReferenceSearchContinuation: string;
  showSearchStartText: boolean;
};

export type PatientContextModel = {
  firstName?: string;
  lastName?: string;
  mrn?: string;
  organization?: string;
  dob?: string;
  phoneNumber?: string;
  gender?: string;
  email?: Array<string>;
  uuid?: string;
  accountToken?: string;
  status?: 'admitted' | 'discharged' | null;
  patientLocation?: {
    bed?: string;
    building?: string;
    facility?: string;
    floor?: string;
    locationStatus?: string;
    locationType?: string;
    organization?: string;
    personalLocationType?: string;
    pointOfCare?: string;
    room?: string;
  };
};

export type PatientLocation = {
  bed?: string;
  building?: string;
  facility?: string;
  floor?: string;
  locationStatus?: string;
  locationType?: string;
  organization?: string;
  personalLocationType?: string;
  pointOfCare?: string;
  room?: string;
};

export type PatientContextSearchResults = {
  from: number;
  hits: Array<PatientContextModel>;
  lastPage: boolean;
};

const patientContextSlice = createSlice({
  name: 'patientContext',
  initialState: {
    isPatientReferenceSearchOnLastPage: false,
    patientReferenceSearchContinuation: '',
    isPatientContextEnabled: false,
    isCareTeamAllowed: false,
    selectedPatientReference: undefined,
    selectedPatientReferenceDetail: undefined,
    patientCareTeamModal: {
      hits: [],
      continuation: null,
    } as PatientCareTeam,
    patientCareTeamCompose: {
      hits: [],
      continuation: null,
    } as PatientCareTeam,
    patientReferenceSearchList: undefined,
    patientReferenceSearchLoading: false,
    patientCareTeamSearchLoading: false,
    fetchPatientContextStatus: {},
    patientReferences: {},
    patientSearchView: 'All',
    showSearchStartText: false,
  } as PatientContextSliceState,
  reducers: {
    setIsPatientContextEnabled: (state, action: PayloadAction<boolean>) => {
      state.isPatientContextEnabled = action.payload;
    },
    setIsCareTeamAllowed: (state, action: PayloadAction<boolean>) => {
      state.isCareTeamAllowed = action.payload;
    },
    setPatientSearchView: (state, action: PayloadAction<PatientListType>) => {
      if (state.patientSearchView === action.payload) return;
      state.showSearchStartText = false;
      state.patientSearchView = action.payload;
    },
    setSelectedPatientReference: (
      state,
      action: PayloadAction<PatientContextModel | undefined>
    ) => {
      state.selectedPatientReference = action.payload;
    },
    setSelectedPatientReferenceDetail: (
      state,
      action: PayloadAction<PatientContextModel | undefined>
    ) => {
      state.selectedPatientReferenceDetail = action.payload;
    },
    setPatientSearchList: (
      state,
      action: PayloadAction<Array<PatientContextModel> | undefined>
    ) => {
      state.patientReferenceSearchList = action.payload;
      state.patientReferenceSearchLoading = false;
      if (action.payload === undefined) {
        state.isPatientReferenceSearchOnLastPage = false;
        state.patientReferenceSearchContinuation = '';
      }
    },
    setPatientReferences: (state, action) => {
      if (!action.payload?.uuid) return;
      if (state.patientReferences[action.payload.uuid]) {
        delete state.patientReferences[action.payload.uuid];
      }
      state.patientReferences[action.payload.uuid] = action.payload;
    },
    setPatientContextStatus: (state, action) => {
      state.fetchPatientContextStatus[action.payload.id] = action.payload.status;
    },
    setShowSearchStartText: (state, action) => {
      state.showSearchStartText = action.payload;
    },
    setPatientCareTeam: (state, action: PayloadAction<PatientCareTeam>) => {
      state.patientCareTeamModal = {
        hits: action.payload.hits,
        continuation: action.payload.continuation,
      };
      state.patientCareTeamSearchLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(patientContextThunk.searchPatientContextsThunk.fulfilled, (state, action) => {
      if (action.payload === undefined) return;
      if (
        state.patientReferenceSearchList === undefined ||
        (action.payload.continuation === undefined && action.payload.from === 0)
      ) {
        state.patientReferenceSearchList = [];
      }

      state.patientReferenceSearchList = state.patientReferenceSearchList.concat(
        action.payload.hits
      );
      state.patientReferenceSearchContinuation = action.payload.continuation;
      state.isPatientReferenceSearchOnLastPage = action.payload.lastPage;
      state.patientReferenceSearchLoading = false;

      if (state.patientSearchView === 'All' && action.payload.query === '') {
        state.showSearchStartText = true;
      } else if (
        state.patientSearchView === 'My Patients' &&
        state.patientReferenceSearchList.length === 0
      ) {
        state.showSearchStartText = true;
      } else {
        state.showSearchStartText = false;
      }
    });
    builder.addCase(patientContextThunk.searchPatientContextsThunk.pending, (state, action) => {
      state.showSearchStartText = false;
      state.patientReferenceSearchLoading = true;
    });
    builder.addCase(patientContextThunk.fetchPatientContextThunk.pending, (state, action) => {
      if (action.meta.arg) {
        state.fetchPatientContextStatus[action.meta.arg] = 'pending';
      }
    });
    builder.addCase(patientContextThunk.fetchPatientContextThunk.rejected, (state, action) => {
      if (action.meta.arg) {
        state.fetchPatientContextStatus[action.meta.arg] = 'rejected';
      }
    });
    builder.addCase(patientContextThunk.fetchPatientContextThunk.fulfilled, (state, action) => {
      if (action.meta.arg) {
        state.fetchPatientContextStatus[action.meta.arg] = 'fulfilled';
      }
      if (!action.payload?.uuid) return;
      if (state.patientReferences[action.payload?.uuid]) {
        delete state.patientReferences[action.payload?.uuid];
      }
      state.patientReferences[action.payload?.uuid] = action.payload;
    });
    builder.addCase(patientContextThunk.fetchPatientCareTeamThunk.pending, (state, action) => {
      state.patientCareTeamSearchLoading = true;
    });
    builder.addCase(patientContextThunk.fetchPatientCareTeamThunk.fulfilled, (state, action) => {
      if (action.payload.isRequestedFromCompose) {
        state.patientCareTeamCompose = {
          hits: action.payload.careTeamMembers,
          continuation: action.payload.continuation,
        };
      } else {
        state.patientCareTeamModal = {
          hits: [...state.patientCareTeamModal.hits, ...action.payload.careTeamMembers],
          continuation: action.payload.continuation,
        };
      }
      state.patientCareTeamSearchLoading = false;
    });
  },
});

export { patientContextThunk, patientContextSlice };
