import React, { useState } from 'react';

import TCClient from '../../../../../client';
import { Toast } from '../../../../shared-components/Toast/Toast';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';

type ResetPasswordProps = {
  email: string;
  onClose: () => void;
};

export const ResetPassword: React.FC<ResetPasswordProps> = ({ email, onClose }) => {
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);
  const [isFailureToastOpen, setIsFailureToastOpen] = useState(false);
  const requestPasswordReset = async () => {
    try {
      await TCClient.adminUsers.resetPassword({ email });
      setIsSuccessToastOpen(true);
    } catch {
      setIsFailureToastOpen(true);
    }
  };
  return (
    <ViewContainer
      subHeader={'Trigger a Reset Password email to be sent to this user.'}
      header={'Change Password'}
      onClose={onClose}
    >
      <div>
        <CtaButton
          label={'Reset Password'}
          primary
          size={'small'}
          onClick={async () => {
            await requestPasswordReset();
          }}
        />
        <Toast
          type="SUCCESS"
          message="A reset password email has been sent to this user."
          open={isSuccessToastOpen}
          onClose={() => {
            setIsSuccessToastOpen(false);
          }}
        />
        <Toast
          type="FAILURE"
          message="There was an error with the request."
          open={isFailureToastOpen}
          onClose={() => {
            setIsFailureToastOpen(false);
          }}
        />
      </div>
    </ViewContainer>
  );
};
