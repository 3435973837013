import React, { useState } from 'react';
import classNames from 'classnames';
import TCClient from '../../../../client';
import { RadioInput } from './../../../shared-components/RadioInput/RadioInput';
import { Dropdown } from './../../../shared-components/Dropdown/Dropdown';
import { Input } from './../../../shared-components/Input/Input';
import { Toast } from './../../../shared-components/Toast/Toast';
import { CtaButton } from './../../../shared-components/CtaButton/CtaButton';

import styles from './OrganizationForm.module.css';

type OrganizationsFormProps = {
  isCreateMode: boolean;
  isSuccessToastOpen: boolean;
  setIsCreateMode: (isCreateMode: boolean) => void;
  setIsSuccessToastOpen: (isSuccessToastOpen: boolean) => void;
};

export default function OrganizationForm({
  isCreateMode,
  isSuccessToastOpen,
  setIsCreateMode,
  setIsSuccessToastOpen,
}: OrganizationsFormProps) {
  const [orgName, setOrgName] = useState('');
  const [domainName, setDomainName] = useState('');
  const [orgTypeValue, setOrgTypeValue] = useState('Select');
  const [billingTypeValue, setBillingTypeValue] = useState('active');
  const [isFailureToastOpen, setIsFailureToastOpen] = useState(false);
  const [hasAutoSendWelcomeEmail, setHasAutoSendWelcomeEmail] = useState(false);
  const [error, setError] = useState('');

  const options = [
    { label: 'Org Name', type: 'input' },
    {
      label: 'Org Type',
      type: 'dropdown',
      listOptions: [
        { label: 'Select', value: 'Select' },
        { label: 'Sales Demo', value: 'DEMO' },
        { label: 'QA (testing)', value: 'QA' },
        { label: 'Customer (Production)', value: 'CUSTOMER' },
      ],
    },
    { label: 'Domain', type: 'input' },
    {
      label: 'Billing',
      type: 'dropdown',
      listOptions: [
        { label: 'Paid', value: 'active' },
        { label: 'Free', value: 'inactive' },
      ],
    },
    {
      label: 'Welcome Email',
      type: 'radio button',
      buttons: [{ title: 'Manual' }, { title: 'Automatic' }],
    },
  ];

  const validateValues = async () => {
    if (orgName.trim() === '') {
      setError('Invalid Org Name');
      setIsFailureToastOpen(true);
    } else if (orgTypeValue === 'Select') {
      setIsFailureToastOpen(true);
      setError('Invalid Org Type');
    } else {
      await createNewOrg();
    }
  };

  async function createNewOrg() {
    const domainArray = [];
    try {
      const res = await TCClient.adminOrganizations.createOrganization({
        hasAutoSendWelcomeEmail,
        billing: billingTypeValue,
        join: false,
        name: orgName,
        orgType: orgTypeValue,
      });
      if (domainName.length > 0) {
        domainArray.push(domainName);
        await TCClient.adminOrganizations.addEmailDomain({
          orgId: res.organizationId,
          domainNames: domainArray,
        });
      }
      setIsSuccessToastOpen(true);
      setIsCreateMode(false);
      setError('');
    } catch (err) {
      console.error(err);
      setError('Org creation failed');
      setIsFailureToastOpen(true);
    }
  }

  return (
    <div className={classNames(styles.orgForm)}>
      <Toast
        message={error}
        type={'FAILURE'}
        open={isFailureToastOpen}
        onClose={() => {
          setIsFailureToastOpen(false);
        }}
        autoHideDuration={2000}
      />
      <div className={classNames(styles.orgHeader)}> Create Organization </div>
      {options.map(({ label, type, listOptions, buttons }) => (
        <div className={classNames(styles.orgRoot)}>
          <div className={classNames(styles.orgLabel)}>{label}</div>
          <div className={classNames(styles.orgChild)}>
            {type === 'input' && (
              <Input
                value={label === 'Domain' ? domainName : orgName}
                onInputChange={(e) => {
                  if (label === 'Domain') setDomainName(e.target.value);
                  else setOrgName(e.target.value);
                }}
                dataTestId={label === 'Domain' ? 'newOrgDomainField' : 'newOrgNameField'}
              />
            )}
            {type === 'radio button' &&
              buttons?.map(({ title }) => (
                <div className={classNames(styles.orgRadio)}>
                  <RadioInput
                    label={title}
                    name={'autoSendWelcomeEmail'}
                    selectedValue={hasAutoSendWelcomeEmail ? 'Automatic' : 'Manual'}
                    onRadioClick={(e) => {
                      setHasAutoSendWelcomeEmail(e?.target?.value !== 'Manual');
                    }}
                  />
                </div>
              ))}
            {type === 'dropdown' && listOptions && (
              <Dropdown
                options={listOptions}
                onChange={(e) => {
                  if (label === 'Org Type') setOrgTypeValue(e);
                  else setBillingTypeValue(e);
                }}
                dataTestId={label === 'Org Type' ? 'newOrgTypeDropdown' : 'newOrgBillingDropdown'}
              />
            )}
          </div>
        </div>
      ))}
      <hr className={classNames(styles.orgHr)} />
      <div className={classNames(styles.orgButtons)}>
        <div className={classNames(styles.orgCreate)}>
          <CtaButton primary={true} label={'CREATE'} onClick={validateValues} />
        </div>
        <div className={classNames(styles.orgCancel)}>
          <CtaButton primary={false} label={'CANCEL'} onClick={() => setIsCreateMode(false)} />
        </div>
      </div>
    </div>
  );
}
