import React, { useCallback, useEffect, useRef, useState } from 'react';
import { omit } from 'lodash';
import BEM from '../../bem';
import TCClient from '../../../client';
import { mobxInjectSelect } from '../../utils';
import { DotsIndicator, ScrollingList } from '../';
import { Organization, SearchResult, Tag } from '../../../types';
import useClickOutside from '../../hooks/useClickOutside';
import { ReactComponent as CloseButtonIcon } from '../../images/close-icon.svg';
import ToolTip from '../../../widgets/messenger/components/ToolTip';
import { CollaborationCategories } from '../../../models/enums';
import { notTaggedTag } from '../../../redux-stores/Collaboration/tags';
import TagListItem from './Tags/TagListItem';

const { NOTTAGGED } = CollaborationCategories;

const classes = BEM.with('TagFilter');

type TagFilterProps = {
  onChange: (selectedTags: Tag[]) => void;
  onClear: () => void;
  onOutsideClick?: () => void;
  savedTags: Tag[];
  selectedTags: Tag[];
};

type MobxProps = {
  currentOrganization: Organization;
};

const LINE_ITEM_HEIGHT = 32;

function TagFilter({
  currentOrganization,
  onChange,
  onClear,
  onOutsideClick,
  savedTags,
  selectedTags,
}: TagFilterProps & MobxProps) {
  const tagListRef = useRef<ScrollingList | null>(null);
  const popUpRef = useRef(null);
  const [continuation, setContinuation] = useState<string | undefined>(undefined);
  const [tagCount, setTagCount] = useState(0);
  const [tags, setTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const savedIds = savedTags.map((t) => t.id);

  const fetchPageOfTags = useCallback(
    async (currentTags: Tag[], continuation?: string) => {
      setIsLoading(true);
      const { results, metadata } = await TCClient.search.query<Tag & SearchResult>({
        version: 'SEARCH_PARITY',
        organizationId: currentOrganization.id,
        ...(continuation ? { continuation } : null),
        returnFields: ['color', 'color_value', 'name', 'token'],
        types: ['tag'],
        sort: ['name'],
        query: { name: '' },
      });
      setContinuation(metadata.continuation);
      setTagCount(metadata.totalHits);
      setTags([...currentTags, ...(results.map((r) => omit(r.entity, 'toPlainObject')) as Tag[])]);
      setIsLoading(false);
    },
    [currentOrganization.id]
  );

  useEffect(() => {
    fetchPageOfTags([]);
  }, [fetchPageOfTags]);

  useClickOutside([popUpRef], () => {
    onOutsideClick && onOutsideClick();
  });

  const tagClicked = useCallback(
    (tag: Tag | undefined) => {
      if (!tag) return;
      const isSelected = selectedTags.map((s) => s.id).includes(tag?.id);
      if (isSelected) {
        onChange(selectedTags.filter((x) => x.id !== tag.id));
      } else {
        onChange([...selectedTags, tag]);
      }
    },
    [onChange, selectedTags]
  );

  const getRowHeight = useCallback(() => LINE_ITEM_HEIGHT, []);

  const rowRenderer = useCallback(
    ({
      key,
      index,
      style,
    }: {
      key: string;
      index: number;
      style?: Record<string, unknown>;
      isVisible: boolean;
      isScrolling: boolean;
    }) => {
      const indexOffset = savedTags.length + (savedTags.length ? 2 : 0);
      const headerText = !index ? 'Saved' : index === savedTags.length + 1 ? 'General' : '';
      const sectionHeader = indexOffset ? headerText : '';
      const tagIndex = index - indexOffset;
      const tagList = [notTaggedTag, ...tags.filter((t: Tag) => !savedIds.includes(t.id))];
      const tag = tagIndex >= 0 ? tagList[tagIndex] : savedTags[index - 1];

      if (continuation && !isLoading && tagIndex >= tagList.length - 1) {
        fetchPageOfTags(tags, continuation);
      }

      let content;
      if (!sectionHeader && tag) {
        if (tag.id === notTaggedTag.id) {
          content = (
            <TagListItem
              hasCheck={false}
              hideCount={true}
              colorHex="#99a4a5"
              onClick={tagClicked}
              selected={selectedTags.map((s) => s.id).includes(tag?.id)}
              tag={tag}
              name={NOTTAGGED}
              id={tag?.id}
            />
          );
        } else {
          content = (
            <TagListItem
              hasCheck={false}
              hideCount={true}
              selected={selectedTags.map((s) => s.id).includes(tag?.id)}
              onClick={tagClicked}
              tag={tag}
              name={tag?.name}
              id={tag?.id}
            />
          );
        }
      }

      return (
        <div style={style} key={key}>
          {sectionHeader && <div className={classes('section-header')}>{sectionHeader}</div>}
          {content}
          {!sectionHeader && !tag && isLoading && (
            <div className={classes('loading')}>
              <DotsIndicator size={7} />
            </div>
          )}
        </div>
      );
    },
    [continuation, fetchPageOfTags, isLoading, savedIds, savedTags, selectedTags, tagClicked, tags]
  );

  const rowCount = tagCount + (savedTags.length ? 2 : 0) + 1;
  const height = Math.min(rowCount * LINE_ITEM_HEIGHT, 174);

  return (
    <div className={classes('')}>
      <div className={classes('inner-container')} ref={popUpRef}>
        <div className={classes('title')}>
          <div className={classes('text')}>Filter by Tags</div>
          {selectedTags.length > 0 && (
            <ToolTip text="Remove Filter" textAlign={'center'} location={'bottom'}>
              <button onClick={onClear}>
                <CloseButtonIcon className={classes('icon')} />
              </button>
            </ToolTip>
          )}
        </div>
        <div style={{ height: `${height}px`, position: 'relative' }}>
          <ScrollingList
            rowCount={rowCount + 3}
            ref={tagListRef}
            rowRenderer={rowRenderer}
            rowHeight={getRowHeight}
          />
        </div>
      </div>
    </div>
  );
}

export default mobxInjectSelect<TagFilterProps, MobxProps>({
  messengerStore: ['currentOrganization'],
})(TagFilter);
