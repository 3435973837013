import BEM from 'common/bem';
import { User } from 'types';
import UserAvatarNoPresence from 'common/components/UserAvatar/UserAvatarNoPresence';

const classes = BEM.with('KeywordSearchSideBar');

export function isCurrentUser(currentUserId: string, senderId: string, myRoleIds: string[]) {
  return currentUserId === senderId || myRoleIds?.includes(senderId);
}

export function renderBody(highlightBody: string, entityBody: string) {
  const isValid = (value: string) => value && value !== 'null' && value.trim() !== '';

  const truncate = (value: string, maxLength: number) =>
    value.length > maxLength ? value.slice(0, maxLength) + '...' : value;

  const content = isValid(highlightBody)
    ? highlightBody
    : isValid(entityBody)
    ? truncate(entityBody, 60)
    : null;

  if (!content) return null;

  return <div className={classes('message')} dangerouslySetInnerHTML={{ __html: content }} />;
}

export function renderAvatar(sender: User) {
  return sender ? <UserAvatarNoPresence user={sender} size={34} /> : null;
}

export function getStoreModel(
  entityType: string,
  id: string,
  getEntityData: (entityType: string, id: string) => User
) {
  try {
    const model = getEntityData(entityType, id);
    return model;
  } catch (e) {
    return null;
  }
}
