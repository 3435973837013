import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounce, noop } from 'lodash';
import BEM from '../../bem';

import { ReactComponent as ClearSearchIcon } from '../../images/modal-cancel.svg';

const classes = BEM.with('CollaborationSearch');

const DEBOUNCE_TIMEOUT = 300;

type CollaborationSearchProps = {
  clearInputHandler?: () => void;
  actionFn?: (query: string) => void;
  onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  query: string;
};

function CollaborationSearch({
  clearInputHandler,
  actionFn = noop,
  onChangeHandler,
  placeholder,
  query,
}: CollaborationSearchProps) {
  const dispatch = useDispatch();
  const [localQuery, setLocalQuery] = useState(query);
  const searchRef = useRef<HTMLInputElement>(null);
  const dispatchDebounced = useRef(debounce(dispatch, DEBOUNCE_TIMEOUT));

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setLocalQuery(query);
  }, [query]);

  const clear = () => {
    if (clearInputHandler) {
      clearInputHandler();
      return;
    }
    dispatch(actionFn(''));
  };

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newQuery = e.target.value;
      setLocalQuery(newQuery);
      dispatchDebounced.current(actionFn(newQuery));
    },
    [actionFn]
  );

  return (
    <div className={classes('')}>
      {localQuery && <ClearSearchIcon className={classes('clear-button')} onClick={clear} />}
      <input
        className={classes('input')}
        ref={searchRef}
        onChange={onChangeHandler || onChange}
        placeholder={placeholder}
        type="text"
        value={localQuery}
        data-test-id="search bar"
      />
    </div>
  );
}

export default CollaborationSearch;
