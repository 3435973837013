import React from 'react';
import { useDispatch } from 'react-redux';
import { Card } from '../../WebComponents';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import BEM from '../../../bem';
import TagItem from '../Tags/TagItem';

import { actions } from '../../../../redux-stores';

const { setModal } = actions;
const classes = BEM.with('CollaborationEntityEditor');

type EntityEditorTagProps = {
  isAdmin: boolean;
  tagColor: string;
  tagName: string;
};

export default function EntityEditorTag({ isAdmin, tagColor, tagName }: EntityEditorTagProps) {
  const dispatch = useDispatch();

  const tagDetails = (
    <div
      className={classes('tag-editor', {
        admin: isAdmin,
      })}
      onClick={() => {
        isAdmin && dispatch(setModal({ name: 'tagSelectorEditor' }));
      }}
      data-test-id="open tag selector"
    >
      {!(tagName && tagColor) ? (
        <div className={classes('tag-empty')}>-Not tagged-</div>
      ) : (
        <div className={classes('tag')}>
          <TagItem
            colorHex={tagColor.replace('0x', '#')}
            name={tagName}
            isAdmin={isAdmin}
            useType="adminAction"
          />
        </div>
      )}
    </div>
  );

  return (
    <div className={classes('card-container')}>
      <Card header="Tag">
        {isAdmin ? (
          <ToolTip text="Switch Tag" textAlign={'center'} getManualOffset={() => {}}>
            {tagDetails}
          </ToolTip>
        ) : (
          <div>{tagDetails}</div>
        )}
      </Card>
    </div>
  );
}
