import React, { useEffect } from 'react';

import BEM from '../../bem';
import PatientReferenceSearch from './PatientReferenceSearch';
import { PatientContextModel } from 'redux-stores/patientContext';
import { ReactComponent as PatientSvg } from 'common/images/patient-icon.svg';
import { actions, useAppDispatch } from 'redux-stores';

const { setModal, setSelectedPatientReferenceDetail, setPatientSearchView } = actions;

const classes = BEM.with('PatientDirectory');

type PatientDirectoryProps = {};

function PatientDirectory(props: PatientDirectoryProps) {
  const dispatch = useAppDispatch();

  useEffect(
    function cleanUp() {
      return () => {
        dispatch(setSelectedPatientReferenceDetail());
        dispatch(setPatientSearchView('All'));
      };
    },
    [dispatch]
  );

  return (
    <div className={classes()}>
      <div className={classes('header-container')}>
        <PatientSvg /> <div className={classes('header-text')}>Patients</div>
      </div>
      <PatientReferenceSearch mode="Directory" selectResultFn={selectPatientReference} />
    </div>
  );

  function selectPatientReference(result?: PatientContextModel) {
    dispatch(setSelectedPatientReferenceDetail(result));
    dispatch(setModal({ name: 'patientReferenceDetails' }));
  }
}

export default PatientDirectory;
