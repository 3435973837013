import React, { Component } from 'react';
import PropTypes from 'prop-types';
import propTypes from '../propTypes';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { ReactComponent as CloseButton } from '../images/close-button.svg';
import { ExistingPatientConversationCard } from './';
import AccessibleList from 'common/components/AccessibleList';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';

const classes = BEM.with('ExistingPatientConversations');
const MAX_CONVERSATIONS_TO_SHOW = 3;

const KEYMAP = {
  ENTER: 'Enter',
  SPACE: ' ',
};

class ExistingPatientConversations extends Component {
  static propTypes = {
    existingPatientConversations: propTypes.conversationArray.isRequired,
    existingPatientConversationsTarget: propTypes.counterParty.isRequired,
    hideExistingPatientConversations: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }
  state = {
    accessibilityMode: false,
  };

  componentDidMount() {
    this.focusHandler = () => {
      const firstEl = this.containerRef?.current.getElementsByClassName(
        'tc-ExistingPatientConversations__close-button'
      )[0];
      if (firstEl) {
        firstEl.tabIndex = 0;
        this.containerRef.current.tabIndex = -1;
        firstEl.focus();
      }
    };
    this.containerRef?.current.addEventListener('focus', this.focusHandler);
  }

  componentWillUnmount() {
    this.containerRef?.current.removeEventListener('focus', this.focusHandler);
  }

  render() {
    const { existingPatientConversationsTarget: targetRecipient } = this.props;

    return (
      <div className={classes()} tabIndex={0} ref={this.containerRef}>
        <div className={classes('patient-conversation-text')}>
          We found more conversations with this {targetRecipient.isPatient ? 'patient' : 'contact'}.
          <CloseButton
            role="button"
            className={classes('close-button')}
            aria-label="Close"
            onClick={this._closeMenu}
            onKeyDown={(e) => {
              if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
                this._closeMenu();
              }
            }}
            tabIndex={0}
          />
        </div>
        <ReduxEscapeHatch
          ref={(ref) => {
            this.reduxEscapeHatch = ref;
            if (
              this.reduxEscapeHatch &&
              this.reduxEscapeHatch?.accessibilityMode !== this.state.accessibilityMode
            ) {
              this.setState({ accessibilityMode: this.reduxEscapeHatch?.accessibilityMode });
            }
          }}
        />
        <AccessibleList
          className={classes('patient-conversation-cards')}
          focusableClasses={['.tc-ExistingPatientConversationCard']}
          direction="horizontal"
          focusStart="first"
          accessibilityMode={this.state.accessibilityMode}
        >
          {this._updateRows()}
        </AccessibleList>
      </div>
    );
  }

  _updateRows = () => {
    let { existingPatientConversations } = this.props;
    existingPatientConversations = existingPatientConversations.slice(0, MAX_CONVERSATIONS_TO_SHOW);

    const conversationList = [];
    for (const conversation of existingPatientConversations) {
      const { id } = conversation;

      conversationList.push(
        <ExistingPatientConversationCard conversation={conversation} key={id} />
      );
    }

    return conversationList;
  };

  _closeMenu = () => {
    const { hideExistingPatientConversations } = this.props;
    hideExistingPatientConversations();
  };
}

export default mobxInjectSelect({
  composeMessageStore: [
    'existingPatientConversations',
    'existingPatientConversationsTarget',
    'hideExistingPatientConversations',
  ],
})(ExistingPatientConversations);
