import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import BEM from '../bem';
const classes = BEM.with('EscalationBanner');

const EscalationBanner = ({ status }) => {
  let statusText = '';
  if (status === 'IN_PROGRESS') statusText = 'In Progress';
  if (status === 'CANCELLED') statusText = 'Cancelled';
  if (status === 'ACKNOWLEDGED') statusText = 'Acknowledged';
  if (status === 'NO_RESPONSE') statusText = 'No Response';

  return (
    <div className={classes()}>
      <span className={classNames(classes('escalation-banner'), classes({ status }))}>
        {statusText}
      </span>
    </div>
  );
};

export default observer(EscalationBanner);
