import React from 'react';
import classNames from 'classnames';
import { ReactComponent as PlusIcon } from '../../../common/images/plus.svg';
import ToolTip from '../../../widgets/messenger/components/ToolTip';

type ActionButtonProps = {
  customStyles: Record<string, string>;
  onClick: () => void;
  textAlign: string;
  toolTipText: string;
};

export function ActionButton({ customStyles, onClick, textAlign, toolTipText }: ActionButtonProps) {
  const button = (
    <button className={classNames(customStyles.plusIcon)} onClick={onClick}>
      <PlusIcon className={classNames(customStyles.plusIconAvatar)} />
    </button>
  );
  return (
    <div className={classNames(customStyles.button)}>
      {toolTipText ? (
        <ToolTip text={toolTipText} textAlign={textAlign}>
          {button}
        </ToolTip>
      ) : (
        button
      )}
    </div>
  );
}
