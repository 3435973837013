import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BEM from '../../../bem';
import { ReduxState, actions } from '../../../../redux-stores';
import { Color } from '../../../../types';

import { ReactComponent as XIcon } from '../../../images/x.svg';
import TagPill from './TagPill';

const { saveFilter } = actions;
const classes = BEM.with('CollaborationTags');

function TagFilterColorPills() {
  const dispatch = useDispatch();
  const filteredColors = useSelector((state: ReduxState) => state.tags.filteredColors);

  const removeFilter = (colorID = '') => {
    let payload: Color[] = [];

    if (colorID) {
      payload = filteredColors.filter((color: Color) => color.colorId !== colorID);
    }

    dispatch(saveFilter(payload));
    return payload;
  };

  return (
    <div
      className={classes('tag-filter-color-pills-container')}
      data-test-id="tag-filter-color-pills"
    >
      <div
        className={classes('close-all-pills-button')}
        data-test-id="remove all color filters"
        onClick={() => removeFilter()}
      >
        <XIcon className={classes('close-all-pills-icon')} />
      </div>
      {filteredColors.map((color: Color) => (
        <TagPill
          key={color.colorId}
          id={color.colorId}
          text={color.colorName}
          colorHex={color.colorValue.replace('0x', '#')}
          dismissHandler={removeFilter}
        />
      ))}
    </div>
  );
}

export default TagFilterColorPills;
