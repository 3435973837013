import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { DotsIndicator } from '../../';
import { actions, ReduxState } from '../../../../redux-stores';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import BEM from '../../../bem';

const { selectEntity } = actions;
const classes = BEM.with('CollaborationEntityEditorCreateButtons');

type EntityEditorCreateButtonsProps = {
  createButtonText?: string;
  createEnabled: boolean;
  onCancel?: () => void;
  onCreate: () => void;
  type?: string;
};

export default function EntityEditorCreateButtons({
  createButtonText = 'Create',
  createEnabled,
  onCancel,
  onCreate,
  type,
}: EntityEditorCreateButtonsProps) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: ReduxState) => state.entities.isSavingEntity);

  const close = () => {
    dispatch(selectEntity(undefined));
  };

  const callAction = () => {
    if (createEnabled) {
      onCreate();
    }
  };

  return (
    <div className={classes('')}>
      <ToolTip text="Cancel" textAlign={'center'}>
        <button
          data-test-id="cancel"
          className={classes('actions-button')}
          onClick={onCancel || close}
          type="button"
        >
          Cancel
        </button>
      </ToolTip>
      <ToolTip text={createButtonText} textAlign={'center'} disable={!createEnabled}>
        <button
          data-test-id={`${createButtonText.toLowerCase()}${capitalize(type)}Button`}
          className={classes('actions-button', { isCreate: true })}
          onClick={() => callAction()}
          type="button"
          disabled={!createEnabled}
        >
          {!isLoading && createButtonText}
          {isLoading && (
            <div className={classes('loading-holder')}>
              <DotsIndicator color="#fff" size={12} marginTop={30} marginRight={5} />
            </div>
          )}
        </button>
      </ToolTip>
    </div>
  );
}
