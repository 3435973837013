import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { intersection, keyBy, pick, sortBy } from 'lodash';
import { ReduxState } from '../..';
import { CollaborationCategories } from '../../../models/enums';
import { CollaborationCategory } from '../../../models/enums/CollaborationCategories';
import { Color, Tag } from '../../../types';
import { CollabTagsState, SaveTagsProps } from '../../../types/Collaboration';
import * as tagsThunk from './thunk';

const { ALL, MYROLES, NONE, NOTTAGGED } = CollaborationCategories;

const tagsSlice = createSlice({
  name: 'tags',
  initialState: {
    colors: [],
    checkedTagsById: {},
    filteredColors: [],
    filteredTags: [],
    hasTags: false,
    isLoadingTags: false,
    isTagSearchOpen: false,
    savedTags: [],
    selectedCategory: ALL,
    tagsById: {},
    tagModalError: false,
    tagSearchQuery: '',
  } as CollabTagsState,
  reducers: {
    clearUserSelections: (state, action: PayloadAction<string | undefined>) => {
      state.checkedTagsById = {};
      state.filteredColors = [];
      state.filteredTags = [];
      state.selectedCategory = action.payload ? MYROLES : ALL;
      state.selectedTag = undefined;
      state.tagBeingEdited = undefined;
      state.isTagSearchOpen = false;
      state.tagSearchQuery = '';
    },

    saveColors: (state, action: PayloadAction<Color[]>) => {
      state.colors = action.payload;
    },

    saveFilter: (state, action: PayloadAction<Color[]>) => {
      state.filteredColors = action.payload;
    },

    saveTags: (state, action: PayloadAction<SaveTagsProps>) => {
      const { continuation, deleting, dontPropagate, isNewTag, replace, tagsById } = action.payload;
      const existingTags = state.tagContinuation && !replace ? state.tagsById : {};
      const savedTagIds = state.savedTags.map((t: Tag) => t.id);
      const checkedTagIds = Object.keys(state.checkedTagsById);
      const currentTagIdsViaSearch = state.tagSearchQuery ? Object.keys(state.tagsById) : [];

      if (
        (intersection(checkedTagIds, Object.keys(tagsById)).length && !dontPropagate) ||
        deleting
      ) {
        const oldCheckedTags = deleting ? {} : state.checkedTagsById;
        state.checkedTagsById = pick({ ...oldCheckedTags, ...tagsById }, checkedTagIds);
      }

      if (
        state.selectedTag?.id &&
        intersection([state.selectedTag?.id], Object.keys(tagsById)) &&
        !dontPropagate &&
        !isNewTag
      ) {
        state.selectedTag = tagsById[state.selectedTag.id];
      }

      state.tagContinuation = continuation;
      state.hasTags = !!Object.keys(tagsById).length;
      state.tagsById = { ...existingTags, ...tagsById };

      const oldSavedTags = deleting ? {} : keyBy(state.savedTags, 'id');
      const savedTags = pick({ ...oldSavedTags, ...state.tagsById }, savedTagIds);
      state.savedTags = sortBy(Object.values(savedTags), (t: Tag) => t.name.toLocaleLowerCase());

      if (currentTagIdsViaSearch.length) {
        state.tagsById = pick(state.tagsById, currentTagIdsViaSearch);
      }

      if (
        !state.selectedTag &&
        !Object.keys(state.checkedTagsById).length &&
        !state.selectedCategory &&
        !isNewTag
      ) {
        state.selectedCategory = ALL;
      }
    },

    selectTag: (state, action: PayloadAction<Tag | undefined>) => {
      state.checkedTagsById = {};
      state.selectedTag = action.payload;
      state.selectedCategory = '';
    },

    selectTagCategory: (state, action: PayloadAction<CollaborationCategory>) => {
      state.checkedTagsById = {};
      state.selectedTag = undefined;
      state.selectedCategory = action.payload;
    },

    setTagBeingEdited: (state, action: PayloadAction<{ tag?: Tag }>) => {
      state.tagModalError = false;
      state.tagBeingEdited = action.payload.tag;
    },

    setCheckedTags: (state, action: PayloadAction<{ [tagId: string]: Tag }>) => {
      state.checkedTagsById = action.payload;
      state.selectedTag = undefined;
      state.selectedCategory = Object.keys(action.payload).length ? NONE : ALL;
    },

    setFilteredTags: (state, action: PayloadAction<Tag[]>) => {
      state.filteredTags = action.payload;
    },

    setSavedTags: (state, action: PayloadAction<Tag[]>) => {
      state.savedTags = sortBy(action.payload || [], (t) => t.name.toLocaleLowerCase());
    },

    setTagLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.isLoadingTags = action.payload;
    },

    setTagModalError: (state, action: PayloadAction<boolean>) => {
      state.tagModalError = action.payload;
    },

    setTagSearchQuery: (state, action: PayloadAction<string>) => {
      state.tagSearchQuery = action.payload;
    },

    setIsTagSearchOpen: (state, action: PayloadAction<boolean>) => {
      state.isTagSearchOpen = action.payload;
    },
  },
});

const notTaggedTag: Tag = {
  id: `${NOTTAGGED}:${NOTTAGGED}`,
  name: 'Not Tagged',
  entityCount: 0,
};

export { notTaggedTag, tagsThunk, tagsSlice };

export const selectCheckedTagsById = (state: ReduxState) => state.tags.checkedTagsById;
export const selectCheckedTagsIds = createSelector([selectCheckedTagsById], (checkedTagsById) =>
  Object.keys(checkedTagsById)
);

export const selectTagsById = (state: ReduxState) => state.tags.tagsById;
export const selectTagsByIdAlphabetical = createSelector([selectTagsById], (tagsById) =>
  sortBy(Object.values(tagsById), (t: Tag) => t.name.toLocaleLowerCase())
);

export const selectColors = (state: ReduxState) => state.tags.colors;
