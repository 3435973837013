import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { ReactComponent as ShieldTC } from '../../images/shield-tc.svg';
import mobxInjectSelect from '../../utils/mobxInjectSelect';

const classes = BEM.with('LockedSignIn');

const CONNECTION_UNAVAILABLE = 'connection-unavailable';

class LockedSignIn extends Component {
  static propTypes = {
    checkLogin: PropTypes.func.isRequired,
    signInUsername: PropTypes.string,
  };

  state = {
    checkLoginError: null,
  };

  render() {
    const { checkLoginError } = this.state;
    let errorFragment;

    if (checkLoginError === CONNECTION_UNAVAILABLE) {
      errorFragment = (
        <div className={classes('offline-error')}>
          Please make sure you are connected to the network and try again.
        </div>
      );
    }

    return (
      <div className={classes()} id="tc-locked">
        <div className={classes('content')}>
          <ShieldTC />
          <div className={classes('title-text')}>For Your Protection</div>
          <div className={classes('title-subtext')}>
            Your account has been temporarily locked for 10 minutes. Please try again later or
            contact customer service for additional help.
          </div>
          <div className={classes('error-container')}>{errorFragment}</div>
        </div>
        <div className={classes('refresh-btn')} onClick={this._refreshPage}>
          Refresh
        </div>
      </div>
    );
  }

  _refreshPage = async () => {
    const { checkLogin, signInUsername } = this.props;
    if (!signInUsername) {
      window.location.reload();
    }

    const checkLoginError = await checkLogin(signInUsername, {
      checkOnly: true,
      returnErrors: true,
    });

    if (checkLoginError && checkLoginError === CONNECTION_UNAVAILABLE) {
      this.setState({ checkLoginError });
    } else {
      window.location.reload();
    }
  };
}

export default mobxInjectSelect({
  sessionStore: ['checkLogin', 'signInUsername'],
})(LockedSignIn);
