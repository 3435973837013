import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import { ContextMenu, MenuItemList, MenuItem } from '../ContextMenu';
import { ReactComponent as AppleSvg } from '../../images/apple.svg';
import { ReactComponent as AndroidSvg } from '../../images/android.svg';

const classes = BEM.with('DownloadApp');

const MENU_OFFSET_Y = 15;

class DownloadApp extends Component {
  static propTypes = {
    disableAndroidAppLink: PropTypes.bool.isRequired,
  };

  state = {
    isAppListOpen: false,
  };

  render() {
    const { disableAndroidAppLink } = this.props;

    return (
      <div className={classes()}>
        <ContextMenu
          event="click"
          theme="vertical"
          position="bottom"
          offsetY={MENU_OFFSET_Y}
          onShow={this._onShow}
          onHide={this._onHide}
          menu={
            <MenuItemList className={classes('app-list')}>
              <MenuItem onClick={this._onIosDownload}>
                <AppleSvg className={classes('app-svg')} height={14} width={12} />
                <span>iOS Download</span>
              </MenuItem>
              {!disableAndroidAppLink && (
                <MenuItem onClick={this._onAndroidDownload}>
                  <AndroidSvg className={classes('app-svg')} height={14} width={12} />
                  <span>Android Download</span>
                </MenuItem>
              )}
            </MenuItemList>
          }
        >
          <div className={classes('text', { 'is-open': this.state.isAppListOpen })}>
            Download the app
          </div>
        </ContextMenu>
      </div>
    );
  }

  _onIosDownload = () => {
    window.open(
      'https://itunes.apple.com/us/app/tigertext-secure-messenger/id355832697?mt=8&utm_source=web_console&utm_medium=menu_cta&utm_campaign=Web+Drive+to+Mobile'
    );
  };

  _onAndroidDownload = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.tigertext&utm_source=web_console&utm_medium=menu_cta&utm_campaign=Web+Drive+to+Mobile'
    );
  };

  _onShow = () => {
    this.setState({ isAppListOpen: true });
  };

  _onHide = () => {
    this.setState({ isAppListOpen: false });
  };
}

export default mobxInjectSelect({
  desktopAppStore: ['disableAndroidAppLink'],
})(DownloadApp);
