import React, { useEffect, useRef, useState } from 'react';

import { Card } from '../WebComponents';
import { ReactComponent as SearchCancelSvg } from '../../images/patient-reference-search-cancel.svg';
import PatientReferenceSearchResults from './PatientReferenceSearchResults';
import PatientReferenceInfo from './PatientReferenceInfo';
import BEM from 'common/bem';
import { mobxInjectSelect } from 'common/utils';
import { ReactComponent as SearchSvg } from 'common/images/search.svg';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';
import { PatientContextModel } from 'redux-stores/patientContext';

const classes = BEM.with('PatientReferenceSearch');

const { setPatientSearchView } = actions;

type PatientReferenceSearchProps = {
  selectResultFn: (patientContext?: PatientContextModel) => void;
  mode?: PatientReferenceSearchMode;
};

export type PatientReferenceSearchMode = 'Directory' | 'Dropdown';

type MobxProps = {
  showCareTeamPatientProfile: boolean;
};

function PatientReferenceSearch({
  showCareTeamPatientProfile,
  selectResultFn,
  mode = 'Dropdown',
}: PatientReferenceSearchProps & MobxProps) {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState('');
  const [firstEnter, setFirstEnter] = useState<boolean>(true);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { patientSearchView, showSearchStartText } = useAppSelector(
    ({ patientContext: { patientSearchView, showSearchStartText } }) => ({
      patientSearchView,
      showSearchStartText,
    })
  );

  const activeElement = document.activeElement as HTMLElement;

  useEffect(() => {
    if (firstEnter && activeElement !== searchInputRef.current) {
      searchInputRef.current?.focus();
    }
    if (!firstEnter && activeElement === searchInputRef.current) {
      setFirstEnter(false);
    }
  }, [activeElement, firstEnter]);

  useEffect(function cleanup() {
    return () => {
      setQuery('');
      setFirstEnter(true);
    };
  }, []);

  function handleSearchInput(e: React.ChangeEvent<HTMLInputElement>) {
    setQuery(e.target.value);
  }

  function renderSearchComponent() {
    return (
      <div
        ref={scrollContainerRef}
        className={classes('search-results', { isEmpty: query === '' })}
      >
        {renderStartSearchText()}
        <PatientReferenceSearchResults
          renderResult={renderRow}
          inputValue={query}
          scrollRef={scrollContainerRef}
          mode={mode}
        />
      </div>
    );
  }

  return (
    <div className={classes({ mode })}>
      <div className={classes('search-input-container')}>
        {mode === 'Directory' && (
          <div className={classes('search-input-icon')}>
            <SearchSvg />
          </div>
        )}
        <div className={classes('search-input')}>
          <input
            className={classes('search-input-value')}
            placeholder="Enter Patient's Name or MRN"
            value={query}
            onChange={handleSearchInput}
            ref={searchInputRef}
          />
          {query.length > 0 && (
            <SearchCancelSvg className={classes('search-cancel')} onClick={() => setQuery('')} />
          )}
        </div>
      </div>
      {!showCareTeamPatientProfile ? (
        mode === 'Dropdown' ? (
          <Card header="Patient References" type="category" theme="provider">
            {renderSearchComponent()}
          </Card>
        ) : (
          renderSearchComponent()
        )
      ) : (
        <>
          {query === '' && (
            <div className={classes('search-results-category')}>
              <div
                className={classes('search-category', {
                  selected: patientSearchView === 'All',
                })}
                onClick={() => dispatch(setPatientSearchView('All'))}
              >
                All
              </div>
              <div
                className={classes('search-category', {
                  selected: patientSearchView === 'My Patients',
                })}
                onClick={() => dispatch(setPatientSearchView('My Patients'))}
              >
                My Patients
              </div>
            </div>
          )}

          <div
            ref={scrollContainerRef}
            className={classes('search-results', {
              isEmpty: patientSearchView === 'All' && query === '',
            })}
          >
            {renderStartSearchText()}
            <PatientReferenceSearchResults
              renderResult={renderRow}
              inputValue={query}
              scrollRef={scrollContainerRef}
              patientSearchView={patientSearchView}
              mode={mode}
            />
          </div>
        </>
      )}
    </div>
  );

  function renderStartSearchText() {
    if (showSearchStartText && query === '') {
      return (
        <div
          className={classes('search-start-container', {
            allowCareTeamAssignments: showCareTeamPatientProfile,
            isMyPatientsTab: patientSearchView === 'My Patients',
          })}
        >
          <div className={classes('search-start-icon')}>
            <SearchSvg />
          </div>
          <div>Start typing to search all patients in your EHR</div>
        </div>
      );
    }
  }

  function renderRow(result: PatientContextModel, index: number) {
    return (
      <div
        key={`${result.mrn}_${index}`}
        data-test-id={`patientReferenceSearchResult${result.firstName}${result.lastName}`}
        className={classes('search-result-row')}
        onClick={() => selectResultFn?.(result)}
      >
        <PatientReferenceInfo patientReference={result} type={'card'} showLocation />
      </div>
    );
  }
}

export default mobxInjectSelect<PatientReferenceSearchProps, MobxProps>({
  messengerStore: ['showCareTeamPatientProfile'],
})(PatientReferenceSearch);
