import styles from './SCIM.module.css';
import { Attribute } from 'types';

interface AttributeLabelProps {
  name: string;
  description: string;
}

function AttributeLabel({ name, description }: AttributeLabelProps) {
  return (
    <div className={styles.attributeRow} key={name}>
      <div className={styles.attributeName}>{name}:</div>
      <div>{description}</div>
    </div>
  );
}

export default function AttributeRow({ attribute }: { attribute: Attribute }) {
  const hasSubAttributes =
    Array.isArray(attribute.subAttributes) && attribute.subAttributes.length > 0;
  return !hasSubAttributes ? (
    <div>{attribute.description}</div>
  ) : (
    <div>
      <div>{attribute.description}</div>
      <div className={styles.subAttributeWrapper}>
        <div className={styles.subAttributeTitle}>Sub-Attributes</div>
        {attribute.subAttributes.map((subAttribute) => (
          <AttributeLabel
            key={subAttribute.name}
            name={subAttribute.name}
            description={subAttribute.description}
          />
        ))}
      </div>
    </div>
  );
}
