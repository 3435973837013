import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@tigerconnect/web-component-library';
import { CollabEntity, Group, Role, Team } from '../../../../types';
import { mobxInjectSelect } from '../../../utils';
import BEM from '../../../bem';
import TagItem from '../Tags/TagItem';

import { ReactComponent as SavedRoleIcon } from '../../../images/bookmark.svg';
import { EntityAvatar } from '../..';

import { ReduxState, actions } from '../../../../redux-stores';
import { useIsHovered } from '../../../hooks/useIsHovered';
import UserItem from './UserItem';

const classes = BEM.with('CollaborationEntitiesItem');

const { setNewEntityType } = actions;

type EntitiesItemProps = {
  entity: CollabEntity;
  select?: (id: string) => void;
  showAction?: boolean;
  showOwner?: boolean;
  showTag?: boolean;
  id: string;
};

type MobxProps = {
  composeNewMessage: () => void;
  composeToEntityV2: (entity: CollabEntity) => void;
  currentUserId: string;
  findTeam: (id: string) => Promise<Team>;
  openMessages: () => void;
  selectConversationWithEntity: (props: { group: Group; markAsRead: boolean }) => void;
  setConversationOrigin: (id: string) => void;
  setSelectedRecipients: (entiies: [CollabEntity]) => void;
};

function EntitiesItem({
  composeNewMessage,
  composeToEntityV2,
  currentUserId,
  entity,
  findTeam,
  openMessages,
  select = () => {},
  setConversationOrigin,
  selectConversationWithEntity,
  setSelectedRecipients,
  showAction = false,
  showOwner = false,
  showTag = false,
  id,
}: EntitiesItemProps & MobxProps) {
  const dispatch = useDispatch();
  const [actionButtonId, setActionButtonId] = useState('');
  const divRef = useRef(null);
  const isHovered = useIsHovered(divRef);
  const [actionButtonText, setActionButtonText] = useState('');
  const entitiesById = useSelector((state: ReduxState) => state.entities.entitiesById);
  const savedRoleIds = useSelector((state: ReduxState) => state.roles.savedRoleIds);
  const savedTeamIds = useSelector((state: ReduxState) => state.teams.savedTeamIds);
  const owner = entity?.members?.[0];
  const isRole = entity?.$entityType === 'role';
  const isTeam = entity?.$entityType === 'team';
  const isOptedInRole = isRole && currentUserId === owner?.id;
  const isBookmarked =
    entity?.$entityType === 'team' ? savedTeamIds.includes(id) : savedRoleIds.includes(id);

  const selectEntity = (id: string) => {
    select(id);
    dispatch(setNewEntityType(undefined));
  };

  useEffect(() => {
    const { $entityType = '' } = entity || {};
    let newActionText = '';
    if (!isOptedInRole && isRole) {
      newActionText = 'Message Role';
      setActionButtonId('message-role');
    } else if ($entityType === 'team') {
      newActionText = entity?.hasCurrentUserOrRole ? 'Message Team' : 'Activate';
      setActionButtonId(newActionText === 'Message Team' ? 'message-team' : 'activate');
    }
    setActionButtonText(newActionText);
  }, [currentUserId, entity, isOptedInRole, isRole]);

  const onActionButtonClick = useCallback(
    async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();

      if (actionButtonText.includes('Message Role')) {
        composeToEntityV2(entitiesById[id]);
      } else if (actionButtonText.includes('Message Team')) {
        const team = await findTeam(id);
        const { group } = team;
        if (!group) return;
        selectConversationWithEntity({
          group,
          markAsRead: false,
        });
      } else if (actionButtonText === 'Activate') {
        openMessages();
        setConversationOrigin('Team Card');
        setSelectedRecipients([entitiesById[id]]);
        composeNewMessage();
      }
    },
    [
      actionButtonText,
      composeNewMessage,
      composeToEntityV2,
      entitiesById,
      findTeam,
      id,
      openMessages,
      selectConversationWithEntity,
      setConversationOrigin,
      setSelectedRecipients,
    ]
  );

  let tagColor, tagName;

  if (entity?.$entityType === 'role') {
    const roleEntity = entity as Role;
    tagColor = roleEntity?.metadata?.tag_color;
    tagName = roleEntity?.metadata?.tag_name;
  } else if (entity?.$entityType === 'team') {
    const teamEntity = entity as Team;
    tagColor = teamEntity?.tag?.color;
    tagName = teamEntity?.tag?.name;
  }

  return (
    <div
      data-test-id={`select ${entity?.$entityType} ${id}`}
      className={classes('')}
      ref={divRef}
      onClick={() => selectEntity(id)}
    >
      <div
        className={classes('role-details-container')}
        data-test-id={
          showOwner && owner?.displayName ? `${entity?.$entityType} owner ${owner.displayName}` : ''
        }
      >
        <div className={classes('role-icon-container')}>
          {entity?.$entityType === 'team' ? (
            <Avatar size="medium" type="team" bgColor={tagColor} />
          ) : (
            <EntityAvatar entity={entity} useWCL size="medium" />
          )}
        </div>
        <div className={classes('role-info')}>
          <div className={classes('role-name')}>
            <div
              className={classes('role-name-text')}
              data-test-id={`${entity?.$entityType} name ${entity?.displayName}`}
            >
              {entity?.displayName}
            </div>
            <div>{isBookmarked ? <SavedRoleIcon className={classes('bookmark-icon')} /> : ''}</div>
          </div>
          {showTag && tagColor && tagName && (
            <div
              className={classes('role-tag')}
              data-test-id={`${entity?.$entityType} tag ${tagName}`}
            >
              <TagItem
                colorHex={tagColor.replace('0x', '#')}
                name={tagName}
                dotColor
                useType="action"
              />
            </div>
          )}
        </div>
      </div>
      {showOwner && ((isTeam && entity?.hasCurrentUserOrRole) || isRole) && (
        <UserItem
          avatarSize={26}
          currentUserToken={currentUserId}
          entity={entity}
          isEntityDetails
          isHovered={isHovered}
          maxWidth="180px"
          notOnDuty={!owner && isRole}
          reverseItems
          user={owner}
        />
      )}
      {actionButtonText && showAction && isHovered && (
        <div
          className={classes('message-entities-button')}
          id={actionButtonId}
          onClick={onActionButtonClick}
        >
          {actionButtonText}
        </div>
      )}
    </div>
  );
}

export default mobxInjectSelect<EntitiesItemProps, MobxProps>({
  composeMessageStore: [
    'composeNewMessage',
    'composeToEntityV2',
    'setConversationOrigin',
    'setSelectedRecipients',
  ],
  conversationStore: ['selectConversationWithEntity'],
  messengerStore: ['openMessages'],
  sessionStore: ['currentUserId'],
  teamStore: ['findTeam'],
})(EntitiesItem);
