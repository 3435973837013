import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MessageStatus from '../MessageStatus';
import propTypes from '../../propTypes';
import timeLeft from '../../utils/timeLeft';
import BEM from '../../../common/bem';
import { MessageSubTypes } from '../../../models/enums/MessageSubTypes';
const classes = BEM.with('MessageDetails');

class MessageDetails extends Component {
  static propTypes = {
    isModal: PropTypes.bool,
    isPatientMessageForward: PropTypes.bool,
    isProviderNetwork: PropTypes.bool,
    message: propTypes.message.isRequired,
  };

  static defaultProps = {
    isModal: false,
    isPatientMessageForward: false,
    isProviderNetwork: true,
  };

  render() {
    const { isModal, isPatientMessageForward, isProviderNetwork, message } = this.props;
    const { createdAt, isOutgoing, expiresAt, senderStatus, subType, sortNumber } = message;

    const isVwrCallInvite = subType === MessageSubTypes.VIRTUAL_WAITING_ROOM_CALL_INVITE;
    const messageExpiry = timeLeft(expiresAt);
    const messageTime = moment(createdAt).format('h:mm A');

    return (
      <div className={classes()}>
        <div
          aria-label="Message Bubble Time"
          className={classes('message-time', { isPatientMessageForward })}
        >
          {messageTime}
        </div>
        <div id={`${sortNumber}-time`} className="screen-reader-only">
          Message sent at {messageTime}.
        </div>
        {isProviderNetwork && !isVwrCallInvite && (
          <>
            <div
              aria-label="Message Bubble Time Left"
              className={classes('message-time', { 'time-left': true })}
            >
              {messageExpiry}
            </div>
            <div id={`${sortNumber}-expiry`} className="screen-reader-only">
              Message expires in {messageExpiry.replace('left', '').trim()}.
            </div>
          </>
        )}
        {isOutgoing && senderStatus !== 'RETRYING' && !isVwrCallInvite ? (
          <div className={classes('message-status')} id={`${sortNumber}-status`}>
            <span className="screen-reader-only">Message status is </span>
            <MessageStatus isClickable={!isModal} message={message} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default observer(MessageDetails);
