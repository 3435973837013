import React, { useState } from 'react';
import ToolTip from 'rc-tooltip';
import BEM from '../../../bem';

import { copyToClipboard } from '../../../../common/utils';
import { ReactComponent as Clipboard } from '../../../images/workflow-clipboard.svg';
import type { Workflow } from '../../../../types';

const classes = BEM.with('AutomatedMessages');

type WorkflowClipboardProps = {
  workflow: Workflow | null;
};

const WorkflowClipboard: React.FC<WorkflowClipboardProps> = ({ workflow }) => {
  const [tooltipText, setTooltipText] = useState('Copy to clipboard');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTextStateCopied, setTooltipTextStateCopied] = useState(false);

  const copyIdToClipboard = async () => {
    if (workflow) {
      const workflowIdCopy = workflow.id;
      copyToClipboard(workflowIdCopy);
      setTooltipText('Copied');
      setTooltipTextStateCopied(true);
    }
  };

  const onFocusHandler = () => {
    setTooltipVisible(true);
    if (!tooltipTextStateCopied) {
      setTooltipText('Copy to clipboard');
    }
  };

  const onBlurHandler = () => {
    setTooltipVisible(false);
    setTooltipTextStateCopied(false);
    setTooltipText('Copy to clipboard');
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      copyIdToClipboard();
    }
  };

  const onClickHandler = () => {
    copyIdToClipboard();
  };

  return (
    <div className={classes('workflow-id')}>
      <div className={classes('workflow-id-title')}>WORKFLOW ID</div>
      <ToolTip
        placement="bottom"
        visible={tooltipVisible}
        overlay={<span>{tooltipText}</span>}
        overlayInnerStyle={{
          padding: '6px',
          width: '115px',
          minHeight: '10px',
          textAlign: 'center',
        }}
      >
        <button
          className={classes('workflow-id-icon', { isSelected: tooltipTextStateCopied })}
          onClick={onClickHandler}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onKeyDown={onKeyDownHandler}
          onMouseEnter={onFocusHandler}
          onMouseLeave={onBlurHandler}
          aria-label="Copy Workflow ID to Clipboard"
        >
          <Clipboard className={classes('clipboard-button')} aria-hidden />
          <div className={classes('workflow-id-number')} id="workflowId">
            {workflow?.id}
          </div>
        </button>
      </ToolTip>
    </div>
  );
};

export default WorkflowClipboard;
