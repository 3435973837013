import {
  OrganizationSettings,
  OrganizationWideSettingOption,
  RequestServerOrganizationSettings,
  ResponseServerOrganizationSettings,
  ServerBool,
} from './types';
import {
  formatPaidStatus,
  formatLoginType,
  parseArchiveNetwork,
  parseAuthType,
  parseC2CNumberMaskType,
  parseFastDeployValue,
  parseOrganizationWideSettingOption,
  parsePaidStatus,
  parseLoginType,
  parsePatientEngagementValue,
  parsePinLock,
  parseIfDefined,
} from './';

type BooleanRequestServerOrganizationSettings = {
  [K in keyof RequestServerOrganizationSettings]: RequestServerOrganizationSettings[K] extends boolean
    ? '0' | '1'
    : K;
};

export function formatServerOrganizationSettings(
  settings: OrganizationSettings,
  configureForOrganizationSet = false
): RequestServerOrganizationSettings {
  const serverSettings: RequestServerOrganizationSettings = {
    alert_lifespan: settings.alertLifespan,
    allow_looker: settings.isTigerInsightsEnabled,
    allow_public_groups: settings.areForumsEnabled,
    allow_role_schedule_upload: settings.isRolesScheduleUploadEnabled,
    allow_voip: settings.isVoipCallingEnabled,
    alternative_ma_routing: settings.isFastDeployEnabled,
    autologout_time: settings.autoLogout,
    broadcast_list_ad_sync: settings.isBroadcastListAdSyncEnabled,
    calendar: settings.isRolesCalendarViewEnabled,
    call_scheduler: settings.tigerScheduleSetting,
    click_to_call_areacode: settings.click2CallNumberMaskAreaCode,
    click_to_call_phone_type: settings.click2CallNumberMaskType,
    click_to_call_proxy_number: settings.click2CallNumberMaskProxyNumber,
    click_to_call_status: settings.click2CallNumberMask,
    conversation_expiration: settings.isConversationExpirationEnabled,
    delivery_escalation: settings.areDeliveryEscalationsEnabled,
    disable_sms_validation: settings.isSkipSmsVerificationEnabled,
    dnd_auto_forward: settings.isAutoForwardEnabled,
    dor: settings.isDeleteOnReadEnabled,
    emoji_reactions: settings.isEmojiReactionsEnabled,
    preserve_group_conversation: settings.isPreserveGroupConversationEnabled,
    fast_deploy_login_value: settings.fastDeployLoginValue,
    forward: settings.isMessageForwardingEnabled,
    group_audio_call: settings.groupAudioCallingSetting,
    group_video_call: settings.groupVideoCallingSetting,
    ma_session_duration: settings.fastDeploySessionLength,
    mentions: settings.isMentionsEnabled,
    name: settings.name,
    no_attachment: settings.isAttachmentSharingDisabled,
    no_link_preview: settings.isDisableLinkEnabled,
    no_photo: settings.isPhotoVideoSharingDisabled,
    open_links_externally: settings.isOpenLinksExternallyEnabled,
    org_type: settings.orgType,
    otp_allow_configure: settings.otpAllowConfigure,
    patient_context: settings.patientContextSetting,
    pbx_integration: settings.enterprisePbxSetting,
    persona_management: settings.personaManagement,
    pin_lock: settings.pinLock,
    prevent_pending: settings.preventPendingUsers,
    priority_messaging: settings.priorityMessagingSetting,
    repeat_push: settings.areRepeatNotificationsEnabled,
    role_escalation_destruction_ttl: settings.rolesEscalationMessageExpiration,
    role_service: settings.areRolesEnabled,
    roles_escalation: settings.isRolesEscalationEnabled,
    scim_integration: parseIfDefined<boolean, OrganizationWideSettingOption>(
      settings.scimIntegrationSetting,
      (x) => (x ? 'individual' : 'off')
    ),
    teams: settings.teamsSetting,
    ttl: settings.messageLifespan,
    video_call: settings.isVideoCallingEnabled,
    patient_network: settings.patientEngagementSetting,
    patient_conversation_ttl: settings.patientConversationLifespan,
    pf_skip_access_code: parseIfDefined<boolean, boolean>(
      settings.isPatientAccessCodeEnabled,
      (x) => !x
    ),
    pf_skip_dob: parseIfDefined<boolean, boolean>(settings.isPatientDateOfBirthEnabled, (x) => !x),
    pf_no_photo: settings.isPatientPhotoVideoSharingDisabled,
    pf_no_attachment: settings.isPatientAttachmentSharingDisabled,
    pf_quick_add: settings.patientQuickAddSetting,
    pf_quick_call: settings.patientQuickCallSetting,
    pf_quick_video: settings.patientQuickCallVideoSetting,
    pf_voip: settings.patientVoiceCallingSetting,
    pf_group_audio_call: settings.patientGroupVoiceCallingSetting,
    pf_video_call: settings.patientVideoCallingSetting,
    pf_video_call_link_ttl: settings.patientVideoCallingLinkLifespan,
    pf_group_video_call: settings.patientGroupVideoCallingSetting,
    pf_schedule_messages: settings.patientScheduledMessagesSetting,
    pf_patient_list_access: settings.patientListAccessSetting,
    pf_enable_sms_schedule_message: settings.isPatientSmsTextEnabled,
    pf_auto_appointment_reminder: parseIfDefined<boolean, OrganizationWideSettingOption>(
      settings.isPatientAppointmentRemindersEnabled,
      (x) => (x ? 'all' : 'off')
    ),
    pf_broadcast: settings.patientBroadcastingSetting,
    pf_virtual_waiting_room: settings.patientVirtualWaitingRoomSetting,
    // Desktop Settings
    auto_update: settings.isDesktopAppAutoUpdateEnabled,
    desktop_priority_messaging: settings.isDesktopAppPmSoundEnabled,
    sso_web_session_duration: settings.extendedSession,
    // Tabbed Settings
    billing: formatPaidStatus(settings.paidStatus),
    org_login_workflow: formatLoginType(settings.orgLoginWorkflow),
    search_sg_limited: settings.isCustomDirectoriesEnabled,
    ...(!configureForOrganizationSet && { security_groups: settings.customDirectories }),
    // Premium Features
    hide_alt_ma_msg_url: settings.featureHideFastDeployUrl,
    feature_hide_caller_number: settings.featureHidePersonalMobile,
    hide_delivery_escalation_msg_url: settings.featureHideDeliveryEscalationUrl,
    feature_block_personal: settings.featureRestrictContactsOrg,
    feature_calendar: settings.featureRolesCalendarView,
    feature_call_scheduler: settings.featureTigerSchedule,
    feature_click_to_call: settings.featureClickToCall,
    feature_customized_ma: settings.featureCustomFastDeploy,
    feature_delivery_escalation: settings.featureDeliveryEscalation,
    feature_dnd_auto_forward: settings.featureDndAutoForward,
    feature_group_audio_call: settings.featureGroupAudioCalling,
    feature_group_video_call: settings.featureGroupVideoCalling,
    feature_looker: settings.featureInsights,
    feature_no_attachment: settings.featureAttachmentSharingDisabled,
    feature_no_photo: settings.featurePhotoVideoSharingDisabled,
    feature_patient_context: settings.featurePatientContext,
    feature_pf_patient_list_access: settings.featurePatientListAccess,
    feature_patient_network: settings.featurePatientEngagement,
    feature_pbx_integration: settings.featureEnterprisePbx,
    feature_pf_auto_appointment_reminder: settings.featurePatientAppointmentReminders,
    feature_pf_broadcast: settings.featurePatientBroadcasting,
    feature_pf_enable_sms_schedule_message: settings.featurePatientSmsText,
    feature_pf_group_video_call: settings.featurePatientGroupVideoCalling,
    feature_pf_no_attachment: settings.featurePatientAttachmentSharingDisabled,
    feature_pf_no_photo: settings.featurePatientPhotoVideoSharingDisabled,
    feature_pf_quick_add: settings.featurePatientQuickAdd,
    feature_pf_quick_call: settings.featurePatientQuickCall,
    feature_pf_quick_video: settings.featurePatientQuickCallVideo,
    feature_pf_schedule_messages: settings.featurePatientScheduledMessages,
    feature_pf_video_call: settings.featurePatientVideoCalling,
    feature_pf_virtual_waiting_room: settings.featurePatientVirtualWaitingRoom,
    feature_priority_messaging: settings.featurePriorityMessaging,
    feature_role_schedule_upload: settings.featureRolesScheduleUpload,
    feature_role_service: settings.featureRoles,
    feature_roles_escalation: settings.featureRolesEscalation,
    feature_scim_integration: settings.featureScimIntegration,
    feature_teams: settings.featureTeams,
    feature_transaction: settings.featureTransaction,
    feature_video_call: settings.featureVideoCalling,
    feature_voip: settings.featureVoipCalling,
    // MFA Settings
    mfa: settings.mfaSetting,
    mfa_auto_unlock_hours: settings.mfaAutoUnlockHours,
    mfa_auto_unlock_minutes: settings.mfaAutoUnlockMinutes,
    mfa_max_login_attempts: settings.mfaMaxLoginAttempts,
    mfa_max_resend_passcode: settings.mfaMaxPasscodeResends,
    // OTP Settings
    otp_auto_unlock_hours: settings.otpAutoUnlockHours,
    otp_auto_unlock_minutes: settings.otpAutoUnlockMinutes,
    otp_max_login_attempts: settings.otpMaxLoginAttempts,
    otp_max_resend_passcode: settings.otpMaxPasscodeResends,
    // Customized FD Settings
    customized_ma: parseIfDefined<boolean, ServerBool>(settings.isCustomFastDeployEnabled, (x) =>
      x ? '1' : '0'
    ),
    // Auth
    auth_type: settings.authType,
    // Archiving
    archive_msg: settings.isArchivingEnabled,
    secure_smtp: settings.isArchivingSecure,
    archive_inbox: settings.archiveInbox,
    archive_msg_body: settings.shouldArchiveBody,
    archive_network: settings.archiveNetwork,
    smtp_server: settings.archiveSmtpServer,
    smtp_user: settings.archiveSmtpUser,
    smtp_pass: settings.archiveSmtpPass,
  };

  if (settings.featurePatientEngagement && settings.patientEngagementSetting !== 'individual') {
    serverSettings.patient_network = 'all';
  }

  // Customized FD
  if (settings.isCustomFastDeployEnabled) {
    Object.assign(serverSettings, {
      customized_ma_apple_store_link: settings.customFastDeployAppleStoreLink,
      customized_ma_google_store_link: settings.customFastDeployGoogleStoreLink,
      customized_ma_header_color: settings.customFastDeployHeaderColor,
      customized_ma_header_text_color: settings.customFastDeployTextColor,
      customized_ma_logo: settings.customFastDeployLogo,
      customized_ma_sms_text: settings.customFastDeploySMSText,
    });
  }

  // Auth
  if (settings.authType !== 'native' && !configureForOrganizationSet) {
    let authObject: Record<string, unknown> = {
      auth_url: settings.authUrl,
      auth_use_username: settings.authUsernameLogin,
      auth_check_cert: settings.authCertVerify,
      auth_ldap_base: settings.authLdapBase,
      auth_ldap_encryption: settings.authLdapEncryption,
      auth_ldap_host: settings.authLdapHost,
      auth_ldap_port: settings.authLdapPort,
      auth_ldap_search_dn: settings.authLdapSearchDn,
      auth_ldap_search_first: settings.authLdapSearchFirst,
      auth_ldap_search_pw: settings.authLdapSearchPass,
      auth_mod_pass_status: !Array.isArray(settings.authAcceptedStatusCodes)
        ? settings.authAcceptedStatusCodes
            ?.split(',')
            .map((code) => parseInt(code))
            .filter((code) => code >= 100 && code <= 1000)
        : settings.authAcceptedStatusCodes,
      auth_mod_prefix: settings.authPrefix,
      auth_mod_subs: settings.authSubstitutions
        ? Object.entries(settings.authSubstitutions)?.reduce((acc, [key, value]) => {
            if (acc) acc += ';';
            return `${acc}${key},${value}`;
          }, '')
        : undefined,
      auth_mod_suffix: settings.authSuffix,
    };
    switch (settings.authType) {
      case 'saml':
        authObject = {
          auth_saml_assertion_user_id: settings.authSamlAssertionId,
          auth_saml_tigertext_user_id: settings.authSamlTcId,
          auth_saml_idp_metadata: settings.authSamlIspMetadataUrl,
          auth_saml_session_ttl: settings.authSamlTtl,
          auth_saml_trusted_fingerprint: settings.authSamlIspFingerprint,
        };
        break;
      case 'ldap':
        delete authObject.auth_url;
        delete authObject.auth_mod_pass_status;
        break;
      case 'ntlm':
      case 'basic':
        delete authObject.auth_ldap_base;
        delete authObject.auth_ldap_encryption;
        delete authObject.auth_ldap_host;
        delete authObject.auth_ldap_port;
        delete authObject.auth_ldap_search_dn;
        delete authObject.auth_ldap_search_first;
        delete authObject.auth_ldap_search_pw;
        break;
    }
    Object.assign(serverSettings, authObject);
  }

  return serverSettings;
}

/**
 * Establishes the relationships between fields that are necessary when saving
 * organization settings. Each key represents an org setting and each value
 * represents the list of org settings necessary for saving that setting.
 *
 * This should only be used in cases where the POST request body requires
 * more than the changed field be included in the body for the change to persist
 *
 * These relationships could be a bug on the platform side that we have client
 * workarounds for until a fix is available.
 */
export const requiredFieldRelationships: {
  [Property in keyof Partial<OrganizationSettings>]: Array<keyof OrganizationSettings>;
} = {
  archiveNetwork: ['isArchivingEnabled'],
};

/**
 * Parses an unknown payload into a usable local initial state value given the response of an organization settings call
 * @param serverResponse The raw value taken from a network request to retrieve an organization's settings
 */
export function parseServerOrganizationSettings(
  serverResponse: ResponseServerOrganizationSettings
): OrganizationSettings {
  const formattedSettings: OrganizationSettings = {
    alertLifespan: Number(serverResponse.alert_lifespan),
    areDeliveryEscalationsEnabled: Boolean(serverResponse.delivery_escalation),
    areForumsEnabled: Boolean(serverResponse.allow_public_groups),
    areRepeatNotificationsEnabled: Boolean(serverResponse.repeat_push),
    areRolesEnabled: Boolean(serverResponse.role_service),
    autoLogout: Number(serverResponse.autologout_time),
    click2CallNumberMask: parseOrganizationWideSettingOption(serverResponse.click_to_call_status),
    click2CallNumberMaskAreaCode: String(serverResponse.click_to_call_areacode),
    click2CallNumberMaskProxyNumber: String(serverResponse.click_to_call_proxy_number),
    click2CallNumberMaskType: parseC2CNumberMaskType(serverResponse.click_to_call_phone_type),
    emailDomains: serverResponse.email_domains,
    enterprisePbxSetting: parseOrganizationWideSettingOption(serverResponse.pbx_integration),
    fastDeployLoginValue: parseFastDeployValue(serverResponse.fast_deploy_login_value),
    fastDeploySessionLength: Number(serverResponse.ma_session_duration),
    groupAudioCallingSetting: parseOrganizationWideSettingOption(serverResponse.group_audio_call),
    groupVideoCallingSetting: parseOrganizationWideSettingOption(serverResponse.group_video_call),
    id: serverResponse.id,
    isAttachmentSharingDisabled: Boolean(serverResponse.no_attachment),
    isAutoForwardEnabled: Boolean(serverResponse.dnd_auto_forward),
    isBroadcastListAdSyncEnabled: Boolean(serverResponse.broadcast_list_ad_sync),
    isConversationExpirationEnabled: Boolean(serverResponse.conversation_expiration),
    isDeleteOnReadEnabled: Boolean(serverResponse.dor),
    isDisableLinkEnabled: Boolean(serverResponse.no_link_preview),
    isPreserveGroupConversationEnabled: Boolean(serverResponse.preserve_group_conversation),
    isEmojiReactionsEnabled: Boolean(serverResponse.emoji_reactions),
    isFastDeployEnabled: Boolean(serverResponse.alternative_ma_routing),
    isMentionsEnabled: Boolean(serverResponse.mentions),
    isMessageForwardingEnabled: Boolean(serverResponse.allow_msg_forward),
    isOpenLinksExternallyEnabled: Boolean(serverResponse.open_links_externally),
    isPhotoVideoSharingDisabled: Boolean(serverResponse.no_photo),
    isRolesCalendarViewEnabled: Boolean(serverResponse.calendar),
    isRolesEscalationEnabled: Boolean(serverResponse.roles_escalation),
    isRolesScheduleUploadEnabled: Boolean(serverResponse.allow_role_schedule_upload),
    isSkipSmsVerificationEnabled: Boolean(serverResponse.disable_sms_validation),
    scimIntegrationSetting: serverResponse.scim_integration === 'individual',
    isTigerInsightsEnabled: Boolean(serverResponse.allow_looker),
    isVideoCallingEnabled: Boolean(serverResponse.video_call),
    isVoipCallingEnabled: Boolean(serverResponse.voip),
    messageLifespan: Number(serverResponse.ttl),
    name: serverResponse.name,
    orgType: serverResponse.org_type,
    otpAllowConfigure: Boolean(serverResponse.otp_allow_configure),
    patientContextSetting: parseOrganizationWideSettingOption(serverResponse.patient_context),
    personaManagement: parseOrganizationWideSettingOption(serverResponse.persona_management),
    pinLock: parsePinLock(serverResponse.pin_lock),
    preventPendingUsers: serverResponse.prevent_pending,
    priorityMessagingSetting: parseOrganizationWideSettingOption(serverResponse.priority_messaging),
    rolesEscalationMessageExpiration: Number(serverResponse.role_escalation_destruction_ttl),
    teamsSetting: parseOrganizationWideSettingOption(serverResponse.teams),
    tigerScheduleSetting: parseOrganizationWideSettingOption(serverResponse.call_scheduler),
    token: serverResponse.id,
    // TigerConnect Patient Engagement Settings
    patientEngagementSetting: parsePatientEngagementValue(serverResponse.patient_network),

    patientConversationLifespan: Number(serverResponse.patient_conversation_ttl),
    isPatientAccessCodeEnabled: Boolean(!serverResponse.pf_skip_access_code),
    isPatientDateOfBirthEnabled: Boolean(!serverResponse.pf_skip_dob),
    isPatientPhotoVideoSharingDisabled: Boolean(serverResponse.pf_no_photo),
    isPatientAttachmentSharingDisabled: Boolean(serverResponse.pf_no_attachment),
    patientQuickAddSetting: parseOrganizationWideSettingOption(serverResponse.pf_quick_add),
    patientQuickCallSetting: parseOrganizationWideSettingOption(serverResponse.pf_quick_call),
    patientQuickCallVideoSetting: parseOrganizationWideSettingOption(serverResponse.pf_quick_video),
    patientVoiceCallingSetting: parseOrganizationWideSettingOption(serverResponse.pf_voip),
    patientGroupVoiceCallingSetting: parseOrganizationWideSettingOption(
      serverResponse.pf_group_audio_call
    ),
    patientVideoCallingSetting: parseOrganizationWideSettingOption(serverResponse.pf_video_call),
    patientVideoCallingLinkLifespan: Number(serverResponse.pf_video_call_link_ttl),
    patientGroupVideoCallingSetting: parseOrganizationWideSettingOption(
      serverResponse.pf_group_video_call
    ),
    patientListAccessSetting: parseOrganizationWideSettingOption(
      serverResponse.pf_patient_list_access
    ),
    patientScheduledMessagesSetting: parseOrganizationWideSettingOption(
      serverResponse.pf_schedule_messages
    ),
    isPatientSmsTextEnabled: Boolean(serverResponse.pf_enable_sms_schedule_message),
    isPatientAppointmentRemindersEnabled: serverResponse.pf_auto_appointment_reminder === 'all',
    patientBroadcastingSetting: parseOrganizationWideSettingOption(serverResponse.pf_broadcast),
    patientVirtualWaitingRoomSetting: parseOrganizationWideSettingOption(
      serverResponse.pf_virtual_waiting_room
    ),
    // Desktop Settings
    isDesktopAppAutoUpdateEnabled: Boolean(serverResponse.auto_update),
    isDesktopAppPmSoundEnabled: Boolean(serverResponse.desktop_priority_messaging),
    extendedSession: Number(serverResponse.sso_web_session_duration),
    // Tabbed Settings
    paidStatus: parsePaidStatus(serverResponse.billing),
    serverOrgLoginWorkflow: parseLoginType(serverResponse.org_login_workflow),
    orgLoginWorkflow: parseLoginType(serverResponse.org_login_workflow),
    isCustomDirectoriesEnabled: Boolean(serverResponse.search_sg_limited),
    customDirectories: serverResponse.security_groups,
    // Premium Features
    featureInsights: Boolean(serverResponse.feature_looker),
    featureTransaction: Boolean(serverResponse.feature_transaction),
    featureDeliveryEscalation: Boolean(serverResponse.feature_delivery_escalation),
    featureRestrictContactsOrg: Boolean(serverResponse.feature_block_personal),
    featureHidePersonalMobile: Boolean(serverResponse.feature_hide_caller_number),
    featureCustomFastDeploy: Boolean(serverResponse.feature_customized_ma),
    featureDndAutoForward: Boolean(serverResponse.feature_dnd_auto_forward),
    featurePriorityMessaging: Boolean(serverResponse.feature_priority_messaging),
    featureRoles: Boolean(serverResponse.feature_role_service),
    featureRolesEscalation: Boolean(serverResponse.feature_roles_escalation),
    featureRolesScheduleUpload: Boolean(serverResponse.feature_role_schedule_upload),
    featureClickToCall: Boolean(serverResponse.feature_click_to_call),
    featureVoipCalling: Boolean(serverResponse.feature_voip),
    featureGroupAudioCalling: Boolean(serverResponse.feature_group_audio_call),
    featureVideoCalling: Boolean(serverResponse.feature_video_call),
    featureGroupVideoCalling: Boolean(serverResponse.feature_group_video_call),
    featureRolesCalendarView: Boolean(serverResponse.feature_calendar),
    featureHideFastDeployUrl: Boolean(serverResponse.hide_alt_ma_msg_url),
    featureHideDeliveryEscalationUrl: Boolean(serverResponse.hide_delivery_escalation_msg_url),
    featurePhotoVideoSharingDisabled: Boolean(serverResponse.feature_no_photo),
    featureAttachmentSharingDisabled: Boolean(serverResponse.feature_no_attachment),
    featureTigerSchedule: Boolean(serverResponse.feature_call_scheduler),
    featureTeams: Boolean(serverResponse.feature_teams),
    featureEnterprisePbx: Boolean(serverResponse.feature_pbx_integration),
    featureScimIntegration: Boolean(serverResponse.feature_scim_integration),
    featurePatientContext: Boolean(serverResponse.feature_patient_context),
    featurePatientEngagement: Boolean(serverResponse.feature_patient_network),
    featurePatientPhotoVideoSharingDisabled: Boolean(serverResponse.feature_pf_no_photo),
    featurePatientAttachmentSharingDisabled: Boolean(serverResponse.feature_pf_no_attachment),
    featurePatientQuickAdd: Boolean(serverResponse.feature_pf_quick_add),
    featurePatientQuickCall: Boolean(serverResponse.feature_pf_quick_call),
    featurePatientQuickCallVideo: Boolean(serverResponse.feature_pf_quick_video),
    featurePatientVideoCalling: Boolean(serverResponse.feature_pf_video_call),
    featurePatientGroupVideoCalling: Boolean(serverResponse.feature_pf_group_video_call),
    featurePatientScheduledMessages: Boolean(serverResponse.feature_pf_schedule_messages),
    featurePatientListAccess: Boolean(serverResponse.feature_pf_patient_list_access),
    featurePatientSmsText: Boolean(serverResponse.feature_pf_enable_sms_schedule_message),
    featurePatientAppointmentReminders: Boolean(
      serverResponse.feature_pf_auto_appointment_reminder
    ),
    featurePatientVirtualWaitingRoom: Boolean(serverResponse.feature_pf_virtual_waiting_room),
    featurePatientBroadcasting: Boolean(serverResponse.feature_pf_broadcast),
    // MFA Settings
    mfaSetting: parseOrganizationWideSettingOption(serverResponse.mfa),
    mfaAutoUnlockHours: serverResponse.mfa_auto_unlock_hours,
    mfaAutoUnlockMinutes: serverResponse.mfa_auto_unlock_minutes,
    mfaMaxLoginAttempts: serverResponse.mfa_max_login_attempts,
    mfaMaxPasscodeResends: serverResponse.mfa_max_resend_passcode,
    // OTP Settings
    otpAutoUnlockHours: serverResponse.otp_auto_unlock_hours,
    otpAutoUnlockMinutes: serverResponse.otp_auto_unlock_minutes,
    otpMaxLoginAttempts: serverResponse.otp_max_login_attempts,
    otpMaxPasscodeResends: serverResponse.otp_max_resend_passcode,
    // Customized FD Settings
    isCustomFastDeployEnabled: typeof serverResponse.customized_ma === 'object',
    // Auth
    authType: parseAuthType(serverResponse.auth_type),
    authUrl: serverResponse.auth_url,
    authUsernameLogin: Boolean(serverResponse.auth_use_username),
    authCertVerify: Boolean(serverResponse.auth_check_cert),
    authAcceptedStatusCodes: serverResponse.auth_mod_pass_status,
    authPrefix: serverResponse.auth_mod_prefix,
    authSubstitutions: serverResponse.auth_mod_subs,
    authSuffix: serverResponse.auth_mod_suffix,
    authLdapBase: serverResponse.auth_ldap_base,
    authLdapEncryption: serverResponse.auth_ldap_encryption,
    authLdapHost: serverResponse.auth_ldap_host,
    authLdapPort: serverResponse.auth_ldap_port,
    authLdapSearchDn: serverResponse.auth_ldap_search_dn,
    authLdapSearchFirst: serverResponse.auth_ldap_search_first,
    authLdapSearchPass: serverResponse.auth_ldap_search_pw,
    authSamlAssertionId: serverResponse.auth_saml_assertion_user_id,
    authSamlTcId: serverResponse.auth_saml_tigertext_user_id || 'email',
    authSamlIspMetadataUrl: serverResponse.auth_saml_idp_metadata,
    authSamlTtl: serverResponse.auth_saml_session_ttl,
    authSamlIspFingerprint: serverResponse.auth_saml_trusted_fingerprint,
    authSamlUrl: serverResponse.auth_saml_auth_url,
    authSamlConsumeUrl: serverResponse.auth_saml_consume_url,
    authSamlMetadataUrl: serverResponse.auth_saml_metadata_url,
    authSamlLogoutUrl: serverResponse.auth_saml_logout_url,
    // Archive
    isArchivingEnabled: serverResponse.archive_msg,
    isArchivingSecure: serverResponse.secure_smtp,
    archiveInbox: serverResponse.archive_inbox,
    shouldArchiveBody: serverResponse.archive_msg_body,
    archiveNetwork: parseArchiveNetwork({
      allowPatient: serverResponse.allow_patient_facing_archiving,
      allowProvider: serverResponse.allow_provider_archiving,
    }),
    archiveSmtpServer: serverResponse.smtp_server,
    archiveSmtpUser: serverResponse.smtp_user,
    archiveSmtpPass: serverResponse.smtp_pass,
  };

  // Customized FD
  if (typeof serverResponse.customized_ma === 'object') {
    Object.assign(formattedSettings, {
      customFastDeployAppleStoreLink: serverResponse.customized_ma.customized_ma_apple_store_link,
      customFastDeployGoogleStoreLink: serverResponse.customized_ma.customized_ma_google_store_link,
      customFastDeployHeaderColor: serverResponse.customized_ma.customized_ma_header_color,
      customFastDeployTextColor: serverResponse.customized_ma.customized_ma_header_text_color,
      customFastDeployLogo: serverResponse.customized_ma.customized_ma_logo,
      customFastDeploySMSText: serverResponse.customized_ma.customized_ma_sms_text,
    });
  }

  return formattedSettings;
}

export function formatServerOrganizationSettingsWithBoolean(
  settings: OrganizationSettings
): BooleanRequestServerOrganizationSettings {
  const currentServerSettings = formatServerOrganizationSettings(settings, true);

  const formatBooleanRequestServerOrganizationSettings = Object.keys(currentServerSettings).reduce(
    (obj, key) => {
      if (typeof currentServerSettings[key as keyof typeof currentServerSettings] === 'boolean') {
        return {
          ...obj,
          [key]: currentServerSettings[key as keyof typeof currentServerSettings] ? '1' : '0',
        };
      }
      return obj;
    },
    {} as BooleanRequestServerOrganizationSettings
  );

  return Object.assign(currentServerSettings, formatBooleanRequestServerOrganizationSettings, {
    pin: {
      cascade: '0',
      time: String(settings.pinLock),
      required: settings.pinLock !== -1 ? '1' : '0',
    },
  });
}
