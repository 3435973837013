import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import FormFieldWrapper from './FormFieldWrapper';
import TextInput from './TextInput';

class TextField extends Component {
  render() {
    const { type = 'text', ...restProps } = this.props;

    return (
      <FormFieldWrapper {...restProps} type="textual">
        <TextInput {...restProps} type={type} />
      </FormFieldWrapper>
    );
  }
}

export default withFormsy(TextField);
