import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LocationStateSlice } from '../../../../types';

const initialState: LocationStateSlice = {
  selectedLocationButton: 'Locations',
  isAssignUnitModal: false,
  isLocationsDataDirty: true,
  isUnitsDataDirty: true,
  isFacilityModalOpen: false,
};

export const locationSlice = createSlice({
  name: 'locationsDirectory',
  initialState,
  reducers: {
    setFacilityModalOpen: (state, action: PayloadAction<{ isFacilityModalOpen: boolean }>) => {
      state.isFacilityModalOpen = action.payload.isFacilityModalOpen;
    },
    setSelectedLocationButton: (
      state,
      action: PayloadAction<{ selectedLocationButton: string }>
    ) => {
      state.selectedLocationButton = action.payload.selectedLocationButton;
    },
    setLocationsDataDirty: (state, action: PayloadAction<{ isLocationsDataDirty: boolean }>) => {
      state.isLocationsDataDirty = action.payload.isLocationsDataDirty;
    },
    setIsUnitsDataDirty: (state, action: PayloadAction<{ isUnitsDataDirty: boolean }>) => {
      state.isUnitsDataDirty = action.payload.isUnitsDataDirty;
    },
  },
});
