import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import Modal from '../Modal';
import mobxInjectSelect from '../../utils/mobxInjectSelect';

const classes = BEM.with('CallModal');

class JoinVoipCallErrorModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, closeModal, ...restProps } = this.props;

    const titleText = 'Unable to join call';
    const bodyText = 'Please contact the caller and try again';

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('close-button')}
        footerClass={classes('footer')}
        headerClass={classes('header')}
        isOpen={isOpen}
        onRequestClose={closeModal}
        size={'medium'}
        {...restProps}
        footerPrimaryActions={
          <button className={classes('primary-btn')} onClick={closeModal} type="button">
            OK
          </button>
        }
      >
        <div className={classes('title')}>{titleText}</div>
        {bodyText}
      </Modal>
    );
  }
}

export default mobxInjectSelect({
  callStore: ['closeModal'],
})(JoinVoipCallErrorModal);
