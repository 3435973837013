import { useEffect, useRef } from 'react';
import throttle from 'lodash.throttle';

const DEFAULT_THROTTLE_TIMEOUT = 300;
type DebouncedFunc = ReturnType<typeof throttle>;

export function useScrollListener(
  ref: HTMLElement | null,
  scrollHandler: (e: React.ChangeEvent<HTMLElement>) => void,
  throttleTimeout = DEFAULT_THROTTLE_TIMEOUT
) {
  const throttledHandler = useRef<DebouncedFunc | null>(null);

  useEffect(() => {
    const currentRef = ref;
    throttledHandler.current = throttle(scrollHandler, throttleTimeout);

    if (throttledHandler.current && currentRef) {
      currentRef.addEventListener('scroll', throttledHandler.current);
    }

    return () => {
      if (throttledHandler.current && currentRef) {
        currentRef.removeEventListener('scroll', throttledHandler.current);
        throttledHandler.current.cancel();
      }
    };
  }, [ref, scrollHandler, throttleTimeout]);
}
