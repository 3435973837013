import React from 'react';
import { Workflow } from '../../../../types';
import BEM from '../../../bem';

const classes = BEM.with('WorkflowItem');

type WorkflowItemProps = Workflow;

export default function WorkflowItem({ isActive, label, eventsCount }: WorkflowItemProps) {
  return (
    <div className={classes()}>
      <div className={classes('status-row')}>
        <div className={classes('status', { isActive })}>{isActive ? 'Active' : 'Inactive'}</div>
      </div>
      <div className={classes('content-row')}>
        <div className={classes('label')} title={label}>
          {label}
        </div>
        <div className={classes('num-events')}>
          {eventsCount} rule{eventsCount === 0 || eventsCount > 1 ? 's' : ''}
        </div>
      </div>
    </div>
  );
}
