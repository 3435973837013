import { useState } from 'react';

import { TabProps } from './../';
import PersonaTable from './PersonaTable';
import PersonaForm from './PersonaForm';
import { Toast } from 'admin/shared-components/Toast/Toast';
import { ViewContainer } from 'admin/shared-components/View/ViewContainer/ViewContainer';
import { PersonaRowData, PersonaToast } from 'admin/types';

const PersonaTab = ({ onClose }: TabProps) => {
  const [showPersonaForm, setShowPersonaForm] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [toastMessageData, setToastMessageData] = useState<PersonaToast | undefined>();
  const [selectedPersona, setSelectedPersona] = useState<PersonaRowData | undefined>();
  return (
    <ViewContainer
      {...{
        header: 'Permission Groups',
        subHeader: '',
        onClose,
      }}
    >
      <>
        <Toast
          message={toastMessageData?.message || ''}
          type={toastMessageData?.type || 'SUCCESS'}
          open={!!toastMessageData}
          onClose={() => setToastMessageData(undefined)}
          autoHideDuration={2000}
        />
        {!showPersonaForm ? (
          <PersonaTable
            setEditMode={setEditMode}
            showPersonaForm={showPersonaForm}
            setShowPersonaForm={setShowPersonaForm}
            setSelectedPersona={setSelectedPersona}
            setToastMessageData={setToastMessageData}
          />
        ) : (
          <PersonaForm
            editMode={editMode}
            setEditMode={setEditMode}
            setShowPersonaForm={setShowPersonaForm}
            selectedPersona={selectedPersona}
            setSelectedPersona={setSelectedPersona}
            setToastMessageData={setToastMessageData}
          />
        )}
      </>
    </ViewContainer>
  );
};

export default PersonaTab;
