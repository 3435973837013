import React from 'react';
import classNames from 'classnames';

import styles from './LeftNavIcon.module.css';

export default function LeftNavIcon({
  children,
  dataTestId,
  onClick,
  label,
  isSelected,
}: {
  children: JSX.Element[];
  dataTestId?: string;
  onClick: (label: string) => void;
  label: string;
  isSelected: boolean;
}) {
  return (
    <div
      onClick={() => {
        onClick(label);
      }}
      className={classNames(styles.root, isSelected && styles.selected)}
      data-test-id={dataTestId}
    >
      {children}
    </div>
  );
}
