import { action, observable } from 'mobx';

export default class OrganizationSelectorStore {
  @observable isOpenSelector = false;

  @action('OrganizationSelectorStore.openSelector') openSelector = () => {
    this.isOpenSelector = true;
  };

  @action('OrganizationSelectorStore.closeSelector') closeSelector = () => {
    this.isOpenSelector = false;
  };
}
