import React from 'react';
import { observer } from 'mobx-react';
import BEM from '../../../common/bem';

const classes = BEM.with('PatientNetworkMessageHeader');

function PatientNetworkMessageHeader({ message }) {
  const { isOutgoing } = message;
  const direction = isOutgoing ? 'OUTGOING' : 'INCOMING';

  return (
    <div className={classes({ direction })}>
      <div className={classes('text')}>From Patient Network</div>
    </div>
  );
}

export default observer(PatientNetworkMessageHeader);
