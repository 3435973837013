import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import DragModal from './DragModal';

const NullDropzone = ({ children, className, innerClassName, disabled = false }) => (
  <Dropzone
    className={className}
    multiple={false}
    accept={'nothing'}
    disablePreview
    disableClick
    disabled={disabled}
  >
    {(args) => {
      const { isDragActive } = args;
      const isDragReject = isDragActive || args.isDragReject;
      return (
        <div className={innerClassName}>
          <DragModal {...{ isDragActive, isDragReject }} />
          {children}
        </div>
      );
    }}
  </Dropzone>
);

NullDropzone.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  innerClassName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default NullDropzone;
