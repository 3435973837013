import React from 'react';
import classNames from 'classnames';

import { SettingText } from '../SettingText/SettingText';

import styles from './SettingContainer.module.css';

export interface SettingContainerProps {
  /**
   * Setting contents label
   */
  label: string;
  /**
   * Setting contents subLabel
   */
  subLabel?: string;
  dataTestId?: string;
  /**
   * The setting control to slot in
   */
  settingControl?: React.ReactNode;
}

export const SettingContainer: React.FC<SettingContainerProps> = ({
  dataTestId,
  label,
  settingControl,
  subLabel,
}) => {
  return (
    <div className={classNames(styles.root)}>
      <div>
        <SettingText size={'large'} label={label} />
        {subLabel && <SettingText size={'small'} label={subLabel} />}
      </div>
      {settingControl && (
        <div className={classNames(styles.action)} data-test-id={dataTestId}>
          {settingControl}
        </div>
      )}
    </div>
  );
};
