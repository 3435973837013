import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import useClickOutside from '../../../common/hooks/useClickOutside';
import { FacilityModal } from '../../pages/Multiorg/DetailsModals/FacilityModal';
import { FloorModal } from '../../pages/Multiorg/DetailsModals/FloorModal';
import { RoomModal } from '../../pages/Multiorg/DetailsModals/RoomModal';
import { SectionModal } from '../../pages/Multiorg/DetailsModals/SectionModal';
import ToolTip from '../../../widgets/messenger/components/ToolTip';
import ActionBarMenuOption from '../ActionBarMenuOption/ActionBarMenuOption';
import { ReactComponent as MoreButton } from '../../../common/images/more-button.svg';
import closeArrow from '../../images/side_arrow.svg';
import plusIcon from '../../images/plus.svg';
import openArrow from '../../images/down_arrow.svg';
import singleIcon from '../../images/single_line.svg';
import styles from './OrganizationHierarchy.module.css';

const indexNames = ['first', 'second', 'third', 'forth', 'fifth'] as const;
const locationNames = ['Facility', 'Floor', 'Room', 'Section'] as const;

interface OrganizationHierarchyProps {
  children: boolean;
  closed: boolean;
  id: string;
  index: number;
  name: string;
  trackOpenParents: (id: string, open: boolean) => void;
  locationType: 'Facility' | 'Floor' | 'Room' | 'Section';
  unitName?: string;
}
export const OrganizationHierarchy: React.FC<OrganizationHierarchyProps> = ({
  children,
  closed,
  id,
  index,
  name,
  trackOpenParents,
  locationType,
  unitName,
}) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [resourceModalIsOpen, setResourceModalIsOpen] = useState(false);
  const [resourceModalToOpen, setResourceModalToOpen] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside([containerRef], () => {
    setDisplayDropdown(false);
  });

  const closeChildren = () => {
    trackOpenParents(id, !closed);
  };

  const openParent = (id: string, closed: boolean) => {
    trackOpenParents(id, closed);
  };

  const resourceModal = (location: string) => {
    setResourceModalIsOpen(true);
    setResourceModalToOpen(location);
  };

  const closeResourceModal = () => {
    setResourceModalIsOpen(false);
  };

  const openDetailsContainer = () => {
    setDisplayDropdown(!displayDropdown);
  };

  const baseProps = { isOpen: resourceModalIsOpen, onClose: closeResourceModal, openParent };

  const hierarchyFragment = (
    <div className={classNames(styles.hierarchyContainer)}>
      <div className={classNames(styles.container, styles[indexNames[index]])}>
        {locationType !== 'Section' && (
          <div className={classNames(styles.toolTipContainer)}>
            <div
              className={classNames(styles.plusContainer)}
              onClick={(e) => {
                e.stopPropagation();
                resourceModal('create');
              }}
            >
              <ToolTip text={`New ${locationNames[index + 1]}`} textAlign={'center'}>
                <div className={classNames(styles.newLocationTooltip)}>
                  <img src={plusIcon} alt="" className={classNames(styles.plusIcon)} />
                </div>
              </ToolTip>
            </div>
          </div>
        )}
        {index > 0 && <img src={singleIcon} alt="" className={classNames(styles.line)} />}
        <div
          className={classNames(styles.hoverContainer, displayDropdown && styles.displayDropdown)}
        >
          {locationType !== 'Section' && (
            <div
              className={classNames(styles.iconContainer, children && styles.iconContainerChildren)}
              onClick={(e) => {
                e.stopPropagation();
                closeChildren();
              }}
            >
              {children && (
                <img
                  src={!closed ? openArrow : closeArrow}
                  className={classNames(styles.icon)}
                  alt=""
                />
              )}
            </div>
          )}
          <div
            className={classNames(
              styles.nameContainer,
              !children && locationType === 'Facility' && styles.noDropdown
            )}
          >
            <div className={classNames(styles.title)}>{name}</div>
            <div className={classNames(styles.subtitle)}>{locationType}</div>
          </div>
          <div className={classNames(styles.rightSideContainer)}>
            {unitName && <div className={classNames(styles.unitName)}>{unitName}</div>}
            <div
              className={classNames(styles.optionsContainer)}
              onClick={openDetailsContainer}
              ref={containerRef}
            >
              <ActionBarMenuOption
                onClose={() => setDisplayDropdown(false)}
                customStyles={{
                  display: 'inline-block',
                  position: 'relative',
                  width: '70px',
                  marginRight: '10px',
                }}
                showDropDown={displayDropdown}
                menuOptionButtons={[
                  {
                    buttonName: 'Edit',
                    onClick: () => resourceModal('edit'),
                  },
                  {
                    buttonName: 'Delete',
                    disabled: children,
                    onClick: () => resourceModal('delete'),
                    ...(children && { toolTip: 'Remove nested items to delete' }),
                  },
                ]}
              />
              {!displayDropdown ? (
                <ToolTip text={'Details'} textAlign={'center'}>
                  <MoreButton />
                </ToolTip>
              ) : (
                <MoreButton />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.root)}>
      {hierarchyFragment}
      {locationType === 'Facility' &&
        (resourceModalToOpen === 'edit' || resourceModalToOpen === 'delete') && (
          <FacilityModal
            {...baseProps}
            mode={{ type: resourceModalToOpen, data: { facilityName: name, facilityId: id } }}
          />
        )}
      {locationType === 'Facility' && resourceModalToOpen === 'create' && (
        <FloorModal {...baseProps} mode={{ type: 'create', data: { parentFacilityId: id } }} />
      )}
      {locationType === 'Floor' &&
        (resourceModalToOpen === 'delete' || resourceModalToOpen === 'edit') && (
          <FloorModal
            {...baseProps}
            mode={{ type: resourceModalToOpen, data: { floorName: name, floorId: id } }}
          />
        )}
      {locationType === 'Floor' && resourceModalToOpen === 'create' && (
        <RoomModal {...baseProps} mode={{ type: 'create', data: { parentAreaId: id } }} />
      )}
      {locationType === 'Room' &&
        (resourceModalToOpen === 'delete' || resourceModalToOpen === 'edit') && (
          <RoomModal
            {...baseProps}
            mode={{
              type: resourceModalToOpen,
              data: { roomName: name, roomId: id },
            }}
          />
        )}
      {locationType === 'Room' && resourceModalToOpen === 'create' && (
        <SectionModal {...baseProps} mode={{ type: 'create', data: { parentRoomId: id } }} />
      )}
      {locationType === 'Section' &&
        (resourceModalToOpen === 'delete' || resourceModalToOpen === 'edit') && (
          <SectionModal
            {...baseProps}
            mode={{ type: resourceModalToOpen, data: { sectionName: name, parentRoomId: id } }}
          />
        )}
    </div>
  );
};
