import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../../bem';
import mobxInjectSelect from '../../../../utils/mobxInjectSelect';
import { Modal } from '../../..';
import { ReactComponent as PrioritySvg } from '../../../../images/priority-icon.svg';

const classes = BEM.with('PatientCsvFailModal');

class PatientCsvFailModal extends Component {
  static propTypes = {
    csvErrors: PropTypes.object.isRequired,
    generalCsvUploadError: PropTypes.bool.isRequired,
    hideCsvFailModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  render() {
    const { hideCsvFailModal, csvErrors, generalCsvUploadError, isOpen } = this.props;
    const imgHeader = {
      image: PrioritySvg,
      fill: '#db524b',
      imgClassHeader: classes('img'),
    };

    const singleError = csvErrors.length === 1;
    const errorMessage = `There ${singleError ? 'is' : 'are'} ${csvErrors.length} error${
      singleError ? ' ' : 's'
    } in your patient .csv`;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('close-button')}
        imgHeader={imgHeader}
        isOpen={isOpen}
        onRequestClose={hideCsvFailModal}
        size={'medium'}
      >
        <div className={classes('title-text', { generalCsvUploadError })}>
          Patient upload failed
        </div>
        {!generalCsvUploadError && (
          <div>
            <div className={classes('sub-text')}>{errorMessage}</div>
            <div className={classes('error-container')}>
              <div className={classes('error-header')}>
                <div className={classes('record-num')}> # </div>
                <div className={classes('reason')}> Errors </div>
              </div>
              {csvErrors.map(this.errorRow)}
            </div>
          </div>
        )}
      </Modal>
    );
  }

  errorRow(errorItem, index) {
    return (
      <div className={classes('error-row')} key={index}>
        <div className={classes('record-num')}> {errorItem.row_number} </div>
        <div className={classes('reason')}> {errorItem.reason} </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  patientStore: ['csvErrors', 'generalCsvUploadError', 'hideCsvFailModal'],
})(PatientCsvFailModal);
