import React from 'react';
import { Button } from '@tigerconnect/web-component-library';
import BEM from 'common/bem';
import { getRecipientEntity, mobxInjectSelect } from 'common/utils';
import { actions, useAppDispatch } from 'redux-stores';

const { setMessageBodyInputFocus } = actions;

const classes = BEM.with('InfoButton');

function InfoButton(props) {
  const { currentConversation, isInfoPaneOpen, isProviderNetwork } = props;

  const dispatch = useAppDispatch();

  if (currentConversation) {
    const { counterPartyType } = currentConversation;
    if (counterPartyType === 'distributionList') return null;
  }

  const _onClick = () => {
    const {
      closeInfoPane,
      currentConversation,
      isInfoPaneOpen,
      showEscalationPolicyInfoPane,
      showGroupEditor,
      showRoleConversationInfo,
      showUserConversationInfoPane,
    } = props;
    const { counterParty, counterPartyType } = currentConversation;
    const { entity, entityType } = getRecipientEntity({
      entity: counterParty,
      entityType: counterPartyType,
    });

    if (isInfoPaneOpen) {
      closeInfoPane();
      dispatch(setMessageBodyInputFocus(true));
      return;
    }

    switch (entityType) {
      case 'group':
        const { groupType } = entity;

        if (groupType === 'ESCALATION') {
          const { escalationExecution } = entity;
          showEscalationPolicyInfoPane(escalationExecution);
        } else {
          showGroupEditor({ group: entity });
        }

        break;
      case 'user':
        showUserConversationInfoPane({
          conversation: currentConversation,
          user: entity,
        });
        break;
      case 'role':
        showRoleConversationInfo({ role: entity });
        break;
      default:
    }
  };

  return (
    <div className={classes()}>
      <Button
        aria-label="Toggle Conversation Information Aside"
        aria-controls="infoPane"
        aria-expanded={isInfoPaneOpen}
        onClick={_onClick}
        shape="circle"
        icon="details"
        color={isProviderNetwork ? 'neutral' : 'patient'}
        active={isInfoPaneOpen}
      />
    </div>
  );
}

export default mobxInjectSelect({
  conversationStore: ['currentConversation', 'showUserConversationInfoPane'],
  escalationStore: ['showEscalationPolicyInfoPane'],
  groupEditorStore: ['showGroupEditor'],
  messengerStore: ['closeInfoPane', 'isInfoPaneOpen'],
  networkStore: ['isProviderNetwork'],
  roleStore: ['showRoleConversationInfo'],
})(InfoButton);
