import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { noop } from 'node-noop';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import UserAvatar from '../UserAvatar/UserAvatar';
import AccessibleList from '../AccessibleList';
import { KEYMAP } from 'common/constants';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';

const classes = BEM.with('RoleSelector');

class RoleSelector extends Component {
  static propTypes = {
    allowedSenders: propTypes.userOrRoleArray.isRequired,
    composeEntity: PropTypes.object,
    sender: propTypes.user.isRequired,
    setLastSenderId: PropTypes.func,
    setSenderId: PropTypes.func.isRequired,
    showSendAs: PropTypes.bool,
  };

  static defaultProps = {
    setLastSenderId: noop,
    showSendAs: true,
  };

  _styleCache = {};
  menuContentsRef = React.createRef();
  selectedTileRef = React.createRef();

  state = {
    isOpen: false,
    accessibilityMode: false,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate() {
    if (this.state.isOpen && this.state.accessibilityMode) {
      this.focusCurrentListItem();
    }
  }

  handleKeyDown = (e) => {
    if (e.key === KEYMAP.ESCAPE && this.state.isOpen) {
      e.stopPropagation();
      this.setState({ isOpen: false });
    }
  };

  focusCurrentListItem() {
    if (this.state.isOpen && this.state.accessibilityMode) {
      const currentItem = this.menuContentsRef.current.querySelector(
        '.tc-RoleSelector__menu-contents-line--is-current'
      );
      if (currentItem) {
        currentItem.focus();
      }
    }
  }

  render() {
    const { allowedSenders, composeEntity, sender, showSendAs } = this.props;
    const senderSelectable = allowedSenders.length > 1;
    const isOpen = senderSelectable && this.state.isOpen;
    const senderIsRole = sender.isRoleBot;
    const senderTag = senderIsRole && sender.botRole && sender.botRole.tag;
    const senderTagColor = senderIsRole
      ? senderTag
        ? senderTag.color
        : 'var(--neutral-600)'
      : null;
    let senderTagStyle;
    let disableSenderOption;
    let isSenderTagColorDark = true;

    if (senderTagColor) {
      if (!this._styleCache[senderTagColor]) {
        this._styleCache[senderTagColor] = { backgroundColor: senderTagColor };
      }
      senderTagStyle = this._styleCache[senderTagColor];
    }

    if (composeEntity && composeEntity.$entityType === 'role') {
      disableSenderOption = composeEntity.botUserId;
    }

    if (senderTagColor && senderIsRole) {
      if (
        senderTagStyle.backgroundColor === '#003B80' ||
        senderTagStyle.backgroundColor === '#000000'
      ) {
        isSenderTagColorDark = true;
      } else {
        isSenderTagColorDark = false;
      }
    }

    return (
      <div className={classes()}>
        <div className={classes('menu', { isOpen })} style={showSendAs ? { left: '76px' } : {}}>
          {showSendAs && <div className={classes('menu-title')}>Sending as:</div>}
          <AccessibleList
            className={classes('menu-contents')}
            accessibilityMode={this.state.accessibilityMode}
            focusableClasses={['.tc-RoleSelector__menu-contents-line']}
            direction="vertical"
            loop={false}
            focusStart={'last'}
            ref={this.menuContentsRef}
          >
            {allowedSenders.map((item) => {
              const isRole = item.$entityType === 'role';
              const user = isRole ? item.botUser : item;
              const { displayName } = item;
              const { id } = user;
              const tag = isRole && item.tag;
              const tagColor = isRole ? (tag ? tag.color : 'var(--neutral-600)') : null;
              const senderDisabled = id === disableSenderOption;
              const ariaLabel = `Message Role Selector ${displayName}`;
              let tagStyle;

              if (tagColor) {
                if (!this._styleCache[tagColor]) {
                  this._styleCache[tagColor] = { backgroundColor: tagColor };
                }
                tagStyle = this._styleCache[tagColor];
              }

              return (
                <li
                  aria-label={ariaLabel}
                  className={classes('menu-contents-line', {
                    'is-disabled': senderDisabled,
                    'is-current': sender.id === id,
                  })}
                  key={id}
                  onClick={!senderDisabled ? this._onClickMenuItem({ id, isRole }) : noop}
                  onKeyDown={(e) => this.handleMenuItemKeyDown(e, { id, isRole, senderDisabled })}
                >
                  {isRole ? (
                    <span className={classes('role-dot')} style={tagStyle}>
                      &nbsp;
                    </span>
                  ) : (
                    <UserAvatar user={user} size={17} />
                  )}
                  <div className={classes('menu-name')}>{displayName}</div>
                </li>
              );
            })}
          </AccessibleList>
          <ReduxEscapeHatch
            ref={(ref) => {
              this.reduxEscapeHatch = ref;
              if (
                this.reduxEscapeHatch &&
                this.reduxEscapeHatch?.accessibilityMode !== this.state.accessibilityMode
              ) {
                this.setState({ accessibilityMode: this.reduxEscapeHatch?.accessibilityMode });
              }
            }}
          />
        </div>

        {showSendAs && (
          <div className={classes('send-as-container')}>
            <span className={classes('send-as')}>Sending as:</span>
          </div>
        )}

        <div className={classes('selected-tile-container')}>
          <button
            aria-label="Message Role Selected"
            ref={this.selectedTileRef}
            className={classes('selected-tile', {
              'is-clickable': senderSelectable,
              'is-role-dark': isSenderTagColorDark,
              'is-role-light': !isSenderTagColorDark,
            })}
            style={senderTagStyle}
            onClick={this._onClickSelectedTile}
          >
            {sender.displayName}
          </button>
        </div>
      </div>
    );
  }

  close = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  };

  _onClickMenuItem = ({ id, isRole }) => {
    const { setSenderId, setLastSenderId } = this.props;

    return () => {
      if (isRole) setLastSenderId(id);
      setSenderId(id);
      this.setState({ isOpen: false });
    };
  };

  _onClickSelectedTile = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleMenuItemKeyDown = (e, { id, isRole, senderDisabled }) => {
    if ((e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) && !senderDisabled) {
      e.preventDefault();
      this._onClickMenuItem({ id, isRole })();
    } else if (e.key === KEYMAP.ESCAPE) {
      e.stopPropagation();
      this.setState({ isOpen: false });
    }
    if (this.selectedTileRef.current) {
      this.selectedTileRef.current.focus();
    }
  };
}

export default observer(RoleSelector);
