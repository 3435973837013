import { useCallback, useEffect, useState } from 'react';
import TCClient from 'client';
import type { Entity } from 'types';
import type { CallResponse as Provider, LaunchData } from 'stores/LaunchStore';
import { useAppSelector, ReduxState } from 'redux-stores';

export function useLaunchCall(
  launchData: LaunchData,
  videoCallSetUp: (entity: Entity, origin: string) => unknown
) {
  const [loading, setLoading] = useState(false);

  const organizationId = useAppSelector((state: ReduxState) => state.session.currentOrganizationId);

  const callProvider = useCallback(
    async ({ firstName, lastName }: Provider, organizationId: string) => {
      setLoading(true);
      const { results } = await TCClient.search.query<{ entity: Entity }>({
        version: 'SEARCH_PARITY',
        query: { 'displayName,name': `${firstName} ${lastName}` },
        organizationId,
        types: ['user'],
        sort: ['displayName'],
      });
      if (results.length === 1) {
        const [{ entity: provider }] = results;
        videoCallSetUp(provider, 'Conversation');
      }
      setLoading(false);
    },
    [videoCallSetUp]
  );

  useEffect(() => {
    if (organizationId && launchData && launchData.type === 'call') {
      callProvider(launchData.data, organizationId);
    }
  }, [callProvider, launchData, organizationId]);

  return { loading };
}
