import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BEM from '../../../../common/bem';
import propTypes from '../../../../common/propTypes';
import { date } from '../../../../common/utils';

const classes = BEM.with('PatientInfo');
const OVERFLOW_MRN_LENGTH = 7;

class PatientInfo extends Component {
  static propTypes = {
    conversation: propTypes.conversation,
    handleMrnOverflow: PropTypes.bool,
    isCard: PropTypes.bool,
    isCenterName: PropTypes.bool,
    isHovered: PropTypes.bool,
    showName: PropTypes.bool,
    isInsideCard: PropTypes.bool,
  };

  static defaultProps = {
    conversation: null,
    handleMrnOverflow: false,
    isCard: false,
    isCenterName: false,
    showName: false,
  };

  render() {
    const {
      conversation,
      handleMrnOverflow,
      isCard,
      isCenterName,
      isHovered,
      entity,
      log,
      isInsideCard,
    } = this.props;
    let formattedDob;
    let patientGender;
    let patientMRN;
    let patientName;
    if (conversation) {
      const { patientDetails } = conversation.counterParty;
      formattedDob = date.formatDob(patientDetails.dob);
      patientGender = patientDetails.gender;
      patientMRN = patientDetails.mrn;
      patientName = patientDetails.name;
    } else if (entity) {
      const { patient } = entity;
      formattedDob = patient.dob;
      patientGender = patient.gender;
      patientMRN = patient.mrn;
      patientName = entity.patient.user.displayName;
    } else if (log) {
      const {
        target: {
          entity: { metadata },
        },
      } = log;
      formattedDob = date.formatDob(metadata.patientDob);
      patientGender = metadata.patientGender;
      patientMRN = metadata.patientMrn;
      patientName = metadata.patientName;
    }

    let infoContainer;
    const accountForOverflow = handleMrnOverflow && patientMRN.length > OVERFLOW_MRN_LENGTH;

    if (accountForOverflow) {
      infoContainer = (
        <div className={classes('patient-details', { isCenterName: true })}>
          <div> {`MRN ${patientMRN}`}</div>
          <div> {`DOB ${formattedDob} | ${patientGender}`}</div>
        </div>
      );
    } else {
      infoContainer = (
        <div className={classes('patient-details', { isCenterName })}>
          {`MRN ${patientMRN} | DOB ${formattedDob} | ${patientGender}`}
        </div>
      );
    }

    return (
      <button
        className={classes({ 'card-style': isCard, 'is-hovered': isHovered })}
        tabIndex={isInsideCard ? -1 : 0}
      >
        <div className={classes('patient-name', { isCenterName })}>{patientName}</div>
        {infoContainer}
      </button>
    );
  }
}

export default observer(PatientInfo);
