import TCClient from '../../client';
import { AppDispatch } from './actions';
type NewVisitorsCounts = { [key: string]: number; total: number };
export async function setTotalNewVisitsCount(dispatch: AppDispatch, currentOrganizationId: string) {
  try {
    const roomsNewVisitCounts: NewVisitorsCounts = await TCClient.virtualWaitingRoom.getStats(
      currentOrganizationId
    );
    const total = Object.values(roomsNewVisitCounts).reduce((t, value) => t + value, 0);
    roomsNewVisitCounts.total = total;
    dispatch({ type: 'VWR_SET_NEW_VISITS_COUNT', payload: roomsNewVisitCounts });
  } catch (err) {
    console.error(err);
  }
}
