import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import VideoCallButton from '../Call/VideoCallButton';
import RoundButton from '../RoundButton';
import { ReactComponent as MessageBubbleSvg } from '../../images/message-bubble.svg';
import PfCallOrigins from './../../../models/enums/PfCallOrigins';

const classes = BEM.with('PatientContactInfo');

class PatientContactInfo extends Component {
  static propTypes = {
    className: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    composeToEntity: PropTypes.func.isRequired,
    contact: PropTypes.object.isRequired,
    isPFVideoCallEnabled: PropTypes.bool,
  };

  render() {
    const { contact, className, isPFVideoCallEnabled } = this.props;
    const { smsOptedOut, user: contactBot } = contact;
    const contactDisplayName = contactBot.displayName;

    return (
      <div className={classNames(className, classes())}>
        <div className={classes('section')}>
          <div className={classes('section-header')}>{contact.relation}</div>
          <span className={classes('relation', { smsOptedOut })}>{contactDisplayName}</span>
        </div>
        <div className={classes('section')}>
          <div className={classes('phone-holder')}>
            <div className={classes('phone-number')}>
              <div className={classes('section-header')}>Phone</div>
              {contact.phoneNumber}
              {smsOptedOut && (
                <div className={classes('sms-opted-out-info')}>Opted out via SMS</div>
              )}
            </div>
            {!smsOptedOut && (
              <div className={classes('phone-actions')}>
                {isPFVideoCallEnabled && (
                  <div className={classes('phone-action')}>
                    <VideoCallButton
                      origin={PfCallOrigins.PROFILE}
                      isSmall={true}
                      entity={{
                        recipientName: contactDisplayName,
                        recipientId: contactBot.id,
                      }}
                    />
                  </div>
                )}
                <div className={classes('phone-action')}>
                  {' '}
                  <RoundButton
                    color="#4a657b"
                    onClick={this._onMessagePatientContact}
                    img={MessageBubbleSvg}
                    isSmall={true}
                  />{' '}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  _onMessagePatientContact = () => {
    const { closeModal, contact, composeToEntity } = this.props;
    const { user: contactBot } = contact;

    composeToEntity(contactBot, { origin: PfCallOrigins.PROFILE });
    closeModal();
  };
}

export default mobxInjectSelect({
  callStore: ['isPFVideoCallEnabled'],
  composeMessageStore: ['composeToEntity'],
  modalStore: ['closeModal'],
})(PatientContactInfo);
