import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';

const classes = BEM.with('MessageRecipientStatus');

class GroupMessageRecipientStatus extends Component {
  static propTypes = {
    currentRoleBotUserIds: propTypes.arrayOrObservableArrayOf(PropTypes.string.isRequired)
      .isRequired,
    currentUserId: PropTypes.string.isRequired,
    group: propTypes.group.isRequired,
    isClickable: PropTypes.bool.isRequired,
    isRolesTransitionFeatureFlagEnabled: PropTypes.bool.isRequired,
    message: propTypes.message.isRequired,
    showGroupMessageStatusModal: PropTypes.func.isRequired,
    statusesPerRecipient: PropTypes.arrayOf(
      PropTypes.shape({
        status: propTypes.messageRecipientStatus.isRequired,
        user: propTypes.user.isRequired,
      })
    ),
  };

  render() {
    const { className, group, isClickable, statusesPerRecipient } = this.props;

    const ignoredUsers = this._getIgnoredUsers();
    let readCount = 0;
    let sentCount = 0;

    if (statusesPerRecipient && group && group.memberIds) {
      const { memberIds: groupMemberIds } = group;
      for (const { status, userId } of statusesPerRecipient) {
        if (ignoredUsers.includes(userId) || !groupMemberIds.includes(userId)) continue;
        sentCount++;
        if (status === 'READ') readCount++;
      }
    }

    const readBySomeone = readCount > 0;
    const isSent = readCount === 0;

    return (
      <span
        className={classNames(
          className,
          classes({
            group: true,
            NEW: isSent,
            READ: readBySomeone,
            isClickable,
          })
        )}
        onClick={isClickable ? this._onGroupMessageStatusRequested : undefined}
        data-test-id="message-status"
      >
        {readCount === 0 ? 'Sent' : `${readCount}/${sentCount} Read`}
      </span>
    );
  }

  _getIgnoredUsers = () => {
    const {
      currentRoleBotUserIds,
      currentUserId,
      isRolesTransitionFeatureFlagEnabled,
      group,
      statusesPerRecipient,
    } = this.props;
    const ignoredUsers = isRolesTransitionFeatureFlagEnabled
      ? [...currentRoleBotUserIds]
      : [currentUserId, ...currentRoleBotUserIds];

    for (const { user } of statusesPerRecipient) {
      if (!user.shouldDisplay) {
        ignoredUsers.push(user.id);
      } else {
        if (isRolesTransitionFeatureFlagEnabled) {
          if (!(user.isRoleBot && user.botRole && group.memberIds.includes(user.id))) continue;
        } else {
          if (!(user.isRoleBot && user.botRole)) continue;
        }

        const { memberIds } = user.botRole;
        if (!memberIds) continue;

        for (const memberId of memberIds) {
          ignoredUsers.push(memberId);
        }
      }
    }

    return ignoredUsers;
  };

  _onGroupMessageStatusRequested = () => {
    const { showGroupMessageStatusModal, message } = this.props;
    showGroupMessageStatusModal(message.id);
  };
}

export default mobxInjectSelect({
  messageStore: ['showGroupMessageStatusModal'],
  roleStore: ['currentRoleBotUserIds'],
  sessionStore: ['currentUserId'],
  messengerStore: ['isRolesTransitionFeatureFlagEnabled'],
})(GroupMessageRecipientStatus);
