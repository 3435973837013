import { sdkApi } from '../api';
import { SearchTypes } from 'models/enums';
import TCClient from 'client';
import { SearchArgType, SearchResults } from 'types/api';
import { UserSDK } from 'admin/types';

export type UserResult = {
  entity: UserSDK;
  entityId: string;
};

const adminUsersApi = sdkApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<SearchResults<UserResult>, SearchArgType>({
      queryFn: async ({ searchValue, orgId, continuation }, _queryApi) => {
        const res = await TCClient.search.query<UserResult>({
          version: 'LEGACY',
          query: { name: '' },
          organizationId: orgId,
          types: [SearchTypes.USER],
          sort: ['firstName'],
          isNewAdminSearch: true,
          bool: { must: { any: searchValue }, must_not: { feature_service: 'role' } },
          continuation: continuation || '',
        });
        return { data: res };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { searchValue, orgId } = queryArgs;
        return { searchValue, orgId };
      },
      merge: (currentCache, newItems) => {
        if (newItems.metadata.firstHit === 1) {
          currentCache.results = newItems.results;
          currentCache.metadata = newItems.metadata;
        } else {
          currentCache.results.push(...newItems.results);
          currentCache.metadata = newItems.metadata;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return true;
      },
    }),
    removeUserFromOrg: build.mutation<
      string[],
      { userIds: string[]; orgId: string; inputValue: string }
    >({
      queryFn: async ({ userIds, orgId }, _queryApi, _extraOptions, _baseQuery) => {
        const values = await Promise.allSettled(
          userIds.map(
            async (userId: string): Promise<{ type: string }> =>
              (await TCClient.adminUsers.organizationLeave({
                orgId,
                userId,
              })) as Promise<{ type: string }>
          )
        );
        const finalIds = values
          .map((res, idx) => {
            if (res.status === 'rejected' || res.value.type === 'error') return '';
            return userIds[idx];
          })
          .filter((id) => id);
        return { data: finalIds };
      },
      async onQueryStarted({ orgId, inputValue }, { dispatch, queryFulfilled }) {
        try {
          const { data: userIds } = await queryFulfilled;
          if (userIds.length === 0) return;
          dispatch(
            adminUsersApi.util.updateQueryData(
              'getUsers',
              { orgId, searchValue: inputValue },
              (usersCache) => {
                const { results } = usersCache;
                Object.assign(usersCache, {
                  results: [...results.filter((result) => !userIds.includes(result.entityId))],
                });
              }
            )
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetUsersQuery, useRemoveUserFromOrgMutation } = adminUsersApi;
