import React from 'react';
import { trim } from 'lodash';
import { useSelector } from 'react-redux';
import BEM from '../../../bem';
import { ReactComponent as ClearSearchIcon } from '../../../images/modal-cancel.svg';
import EntityAvatar from '../../EntityAvatar';
import { Role, User } from '../../../../types';
import { ReduxState } from '../../../../redux-stores';

const classes = BEM.with('CollaborationTeamEditorMembers');

type TeamMembersResultsProps = {
  editor: string | undefined;
  isNewTeam: boolean;
  member: User | Role;
  removeMember: (user: User) => void;
};

export default function TeamEditorMemberLineItem({
  editor,
  isNewTeam,
  member,
  removeMember,
}: TeamMembersResultsProps) {
  const isLoading = useSelector((state: ReduxState) => state.entities.isSavingEntity);
  const removeMemberCall = () => {
    !isLoading && removeMember(member as User);
  };

  let details, tag, role, onDuty;

  if (member?.$entityType === 'role') {
    role = member as Role;
  } else {
    const { title, department, botRole } = (member as User) || {};
    if (botRole) role = botRole;
    details = [title, department]
      .filter((v: string | undefined) => v)
      .map((v: string | undefined) => trim(v))
      .join(', ');
  }

  if (role) {
    onDuty = role.members?.[0];
    details = onDuty?.displayName || 'No one on duty';
    tag = role.tag;
  }

  return (
    <div
      className={classes('list-item', {
        isNewTeamOrEditorSection: editor === 'membersEditor' || isNewTeam,
      })}
    >
      <div className={classes('entity-svg', { 'not-tagged-role': role && !tag })}>
        <EntityAvatar
          entity={role || member}
          indicatorSize={'SMALL'}
          showPresenceIndicator={true}
          size={role ? 'medium' : 32}
          useWCL={!!role}
        />
      </div>
      <div className={classes('entity-info')}>
        <div className={classes('entity-name')}>{member?.displayName}</div>
        <div className={classes('entity-sub-info', { small: !!(details && tag) })}>
          {tag && <div className={classes('entity-tag')}> {tag?.name} </div>}
          {details && (
            <div className={classes('entity-details', { red: role && !onDuty })}>{details}</div>
          )}
        </div>
      </div>
      {(editor === 'membersEditor' || isNewTeam) && (
        <div className={classes('actions', { isLoading })}>
          <ClearSearchIcon
            className={classes('actions-remove')}
            data-test-id={`name ${member?.displayName}`}
            onClick={removeMemberCall}
          />
        </div>
      )}
    </div>
  );
}
