import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import propTypes from '../propTypes';
import { calculateSingleOrGroupRecipientStatus } from './utils/calculateSingleOrGroupRecipientStatus';
import { GroupMessageRecipientStatus, SingleRecipientMessageRecipientStatus } from './';

class MessageRecipientStatus extends Component {
  static propTypes = {
    ensureRecipientStatus: PropTypes.func.isRequired,
    findRecipientStatus: PropTypes.func.isRequired,
    isClickable: PropTypes.bool,
    isRolesTransitionFeatureFlagEnabled: PropTypes.bool.isRequired,
    message: propTypes.message.isRequired,
  };

  componentDidMount() {
    const { ensureRecipientStatus, findRecipientStatus, message } = this.props;
    const { counterPartyType, id, shouldEnsureRecipientStatus } = message;

    if (
      counterPartyType === 'group' &&
      message.isGroupReplay &&
      !message.isGroupReplayStatusesFetched
    ) {
      findRecipientStatus(id, { includeUsers: false });
    } else if (shouldEnsureRecipientStatus) {
      ensureRecipientStatus(id, { includeUsers: false });
    }
  }

  componentDidUpdate() {
    const { ensureRecipientStatus, message } = this.props;
    const { id, shouldEnsureRecipientStatus } = message;

    if (shouldEnsureRecipientStatus) {
      ensureRecipientStatus(id, { includeUsers: false });
    }
  }

  render() {
    const { message, isRolesTransitionFeatureFlagEnabled } = this.props;
    const { counterParty, counterPartyType, group, statusesPerRecipient } = message;
    let { isClickable } = this.props;

    isClickable =
      !!isClickable &&
      [
        'GROUP',
        'ESCALATION',
        'PATIENT_CARE',
        'PATIENT_MESSAGING',
        'ACTIVATED_TEAM',
        'INTRA_TEAM',
      ].includes(counterParty?.groupType);

    let recipientStatus;

    if (isRolesTransitionFeatureFlagEnabled) {
      recipientStatus = calculateSingleOrGroupRecipientStatus(this.props);
    } else {
      const groupType = counterParty && counterParty.groupType;
      const isRoleP2P = counterPartyType === 'group' && groupType === 'ROLE_P2P';
      const singlePatientConversation =
        groupType === 'PATIENT_MESSAGING' && statusesPerRecipient.length === 1;

      if (isRoleP2P) {
        const { p2pRecipient } = group;
        const { $entityType, botUser } = p2pRecipient || {};
        const p2pUser = $entityType === 'role' && botUser ? botUser : p2pRecipient;
        if (p2pUser) {
          const statusObject = statusesPerRecipient.find(({ user }) => user === p2pUser);
          recipientStatus = statusObject ? statusObject.status : 'NA';
        }
      } else if (counterPartyType === 'user' || singlePatientConversation) {
        recipientStatus = statusesPerRecipient.length > 0 ? statusesPerRecipient[0].status : 'NA';
      }
    }

    if (recipientStatus) {
      return <SingleRecipientMessageRecipientStatus recipientStatus={recipientStatus} />;
    } else if (counterPartyType === 'group') {
      return (
        <GroupMessageRecipientStatus
          group={group}
          isClickable={isClickable}
          message={message}
          statusesPerRecipient={statusesPerRecipient}
        />
      );
    } else {
      return null;
    }
  }
}

export default mobxInjectSelect({
  messageStore: ['ensureRecipientStatus', 'findRecipientStatus'],
  messengerStore: ['isRolesTransitionFeatureFlagEnabled'],
})(MessageRecipientStatus);
