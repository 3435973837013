import React, { useEffect, useState } from 'react';

import { OrganizationSettings } from '../../../types';
import { ViewContainer } from '../../../shared-components/View/ViewContainer/ViewContainer';
import { Dropdown } from '../../../shared-components/Dropdown/Dropdown';
import { CtaButton } from '../../../shared-components/CtaButton/CtaButton';
import { SettingContainer } from '../../../shared-components/SettingContainer/SettingContainer';

import { TabProps } from './';
import { actions, thunk, useAppDispatch, useAppSelector } from 'redux-stores';

type MfaSetting = NonNullable<OrganizationSettings['mfaSetting']>;

type MfaSettings<
  T = Required<
    Pick<
      OrganizationSettings,
      | 'mfaAutoUnlockHours'
      | 'mfaAutoUnlockMinutes'
      | 'mfaMaxLoginAttempts'
      | 'mfaMaxPasscodeResends'
    >
  >
> = {
  [K in keyof T]: string;
} & { mfaSetting: MfaSetting };

export const MFA = ({ onClose }: TabProps) => {
  const dispatch = useAppDispatch();

  const {
    mfaSetting = 'off',
    mfaAutoUnlockHours = 1,
    mfaAutoUnlockMinutes = 0,
    mfaMaxLoginAttempts = 5,
    mfaMaxPasscodeResends = 3,
  } = useAppSelector((state) => state.orgSettings.settings);

  const [mfaSettings, setMfaSettings] = useState<MfaSettings>({
    mfaSetting,
    mfaAutoUnlockHours: `${mfaAutoUnlockHours}`,
    mfaAutoUnlockMinutes: `${mfaAutoUnlockMinutes}`,
    mfaMaxLoginAttempts: `${mfaMaxLoginAttempts}`,
    mfaMaxPasscodeResends: `${mfaMaxPasscodeResends}`,
  });

  useEffect(() => {
    dispatch(
      actions.updateSettings({
        mfaSetting: mfaSettings.mfaSetting,
        mfaAutoUnlockHours: parseInt(mfaSettings.mfaAutoUnlockHours, 10),
        mfaAutoUnlockMinutes: parseInt(mfaSettings.mfaAutoUnlockMinutes, 10),
        mfaMaxLoginAttempts: parseInt(mfaSettings.mfaMaxLoginAttempts, 10),
        mfaMaxPasscodeResends: parseInt(mfaSettings.mfaMaxPasscodeResends, 10),
      })
    );
  }, [dispatch, mfaSettings]);

  const saveSettings = async () => {
    await thunk.saveSettings({ dispatch });
  };

  return (
    <ViewContainer header={'Multi Factor Authentication Settings'} onClose={onClose}>
      <div>
        <SettingContainer
          label={'MFA Authentication'}
          subLabel={'Enable this feature to activate passcode verification step during login.'}
          settingControl={
            <Dropdown<MfaSetting>
              options={[
                { label: 'Off', value: 'off' },
                { label: 'Organization', value: 'all' },
                { label: 'Individual', value: 'individual' },
              ]}
              onChange={(s) => setMfaSettings({ ...mfaSettings, mfaSetting: s })}
              defaultValue={mfaSettings.mfaSetting}
            />
          }
        />
        {mfaSettings.mfaSetting !== 'off' && (
          <div>
            <SettingContainer
              label={'Allowed login attempts'}
              subLabel={
                'Number of invalid attempts that users can try logging in before locking the account.'
              }
              settingControl={
                <Dropdown
                  options={[
                    { label: '1', value: '1' },
                    { label: '2', value: '2' },
                    { label: '3', value: '3' },
                    { label: '4', value: '4' },
                    { label: '5', value: '5' },
                  ]}
                  defaultValue={mfaMaxLoginAttempts}
                  onChange={(o) => setMfaSettings({ ...mfaSettings, mfaMaxLoginAttempts: o })}
                />
              }
            />
            <SettingContainer
              label={'Send passcode limit'}
              subLabel={'Number of times the users can generate the passcode for logging in.'}
              settingControl={
                <Dropdown
                  options={[
                    { label: '1', value: '1' },
                    { label: '2', value: '2' },
                    { label: '3', value: '3' },
                    { label: '4', value: '4' },
                    { label: '5', value: '5' },
                  ]}
                  defaultValue={mfaMaxPasscodeResends}
                  onChange={(o) => setMfaSettings({ ...mfaSettings, mfaMaxPasscodeResends: o })}
                />
              }
            />
            <SettingContainer
              label={'Time to auto unlock'}
              subLabel={'Time after which the system will auto unlock locked users.'}
              settingControl={
                <div>
                  <Dropdown
                    options={[
                      { label: '0 Hours', value: '0' },
                      { label: '1 Hour', value: '1' },
                      { label: '2 Hours', value: '2' },
                      { label: '3 Hours', value: '3' },
                      { label: '4 Hours', value: '4' },
                      { label: '5 Hours', value: '5' },
                      { label: '6 Hours', value: '6' },
                      { label: '7 Hours', value: '7' },
                      { label: '8 Hours', value: '8' },
                      { label: '9 Hours', value: '9' },
                      { label: '10 Hours', value: '10' },
                      { label: '11 Hours', value: '11' },
                      { label: '12 Hours', value: '12' },
                      { label: '13 Hours', value: '13' },
                      { label: '14 Hours', value: '14' },
                      { label: '15 Hours', value: '15' },
                      { label: '16 Hours', value: '16' },
                      { label: '17 Hours', value: '17' },
                      { label: '18 Hours', value: '18' },
                      { label: '19 Hours', value: '19' },
                      { label: '20 Hours', value: '20' },
                      { label: '21 Hours', value: '21' },
                      { label: '22 Hours', value: '22' },
                      { label: '23 Hours', value: '23' },
                      { label: '24 Hours', value: '24' },
                    ]}
                    defaultValue={mfaAutoUnlockHours}
                    onChange={(o) => setMfaSettings({ ...mfaSettings, mfaAutoUnlockHours: o })}
                  />
                  <Dropdown
                    options={[
                      { label: '0 Minutes', value: '0' },
                      { label: '15 Minutes', value: '15' },
                      { label: '30 Minutes', value: '30' },
                      { label: '45 Minutes', value: '45' },
                    ]}
                    defaultValue={mfaAutoUnlockMinutes}
                    onChange={(o) => setMfaSettings({ ...mfaSettings, mfaAutoUnlockMinutes: o })}
                  />
                </div>
              }
            />
          </div>
        )}
        <div>
          <CtaButton label={'Save Settings'} primary size={'small'} onClick={saveSettings} />
        </div>
      </div>
    </ViewContainer>
  );
};
