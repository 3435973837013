import React, { useCallback, useEffect, useState } from 'react';

import TCClient from '../../client';

import { CtaButton } from '../shared-components/CtaButton/CtaButton';
import { Table, TableRow } from '../shared-components/Table/Table';
import { ViewContainer } from '../shared-components/View/ViewContainer/ViewContainer';
import { UserData, Device } from '../types';
import { SET_TOAST_DURATION_IN_MILLISECONDS } from '../utils/setToastDuration';

import styles from '../pages/CustomerSupport/CustomerSupport.module.css';
import { thunk, useAppSelector } from 'redux-stores';

export default function Devices({
  accountData,
  onClose,
  selectedTab,
}: {
  accountData: UserData;
  onClose: () => void;
  selectedTab: string;
}) {
  const [devices, setDevices] = useState<Device[]>([]);
  const selectedOrganization = useAppSelector((state) => state.admin.selectedOrganization);

  const clearResource = async () => {
    try {
      await getDevices();
      thunk.setToastSettings({
        duration: SET_TOAST_DURATION_IN_MILLISECONDS(3),
        open: true,
        message: 'Web resource successfully cleared',
        type: 'SUCCESS',
      });
    } catch (_e) {
      thunk.setToastSettings({
        duration: SET_TOAST_DURATION_IN_MILLISECONDS(3),
        open: true,
        message: 'An error occurred when clearing the web resource',
        type: 'FAILURE',
      });
    }
  };

  const getDevices = useCallback(async () => {
    const userDevices = await TCClient.adminUsers.getDevices({
      orgId: selectedOrganization?.token,
      userId: accountData.token,
    });
    setDevices(userDevices);
  }, [accountData.token, selectedOrganization.token]);

  async function wipeDevice(id: string) {
    const confirmation = await window.confirm(
      'Are you sure you want to permanently wipe this device?'
    );
    if (confirmation) {
      await TCClient.adminUsers.remoteWipe({
        orgId: selectedOrganization?.token,
        resource: id,
        userId: accountData.token,
      });
      getDevices();
    }
  }

  function formatTime(date_time: string) {
    if (date_time === '') {
      return date_time;
    }
    const temp_time = date_time.split('T');
    let temp_hour: string[] = [];
    if (temp_time[1]) {
      temp_hour = temp_time[1].split(':');
    }
    const newTime = temp_time[0] + ' ' + temp_hour[0] + ':' + temp_hour[1];
    return newTime;
  }

  useEffect(() => {
    if (selectedTab === 'Devices') {
      getDevices();
    }
  }, [accountData, getDevices, selectedTab]);

  const columns = [
    { field: 'type', headerName: 'Type' },
    { field: 'id', headerName: 'Id' },
    { field: 'lastLogin', headerName: 'Last Login GMT' },
    { field: 'version', headerName: 'Version' },
  ];
  let rows: Array<TableRow> = [];
  if (devices.length > 0) {
    rows = devices
      .filter((item: Device) => item.id)
      .map((item: Device) => {
        return {
          type: item.deviceType,
          id: item.id,
          lastLogin: formatTime(item.lastLoginTime),
          version: item.productVersion,
        };
      });
  }

  return (
    <ViewContainer
      {...{
        header: 'Devices',
        subHeader: 'View a list of devices this user has used to log into TigerConnect.',
        onClose,
      }}
    >
      <div>
        {rows.length > 0 && (
          <div>
            <Table
              actionsOrder={[{ name: 'Wipe', type: 'delete' }]}
              columns={columns}
              rows={rows}
              onRowDelete={(row) => wipeDevice(row.id)}
            />
          </div>
        )}
        <div className={styles.buttonContainer}>
          <CtaButton label={'Clear Possible CN Resource'} onClick={clearResource} primary={true} />
        </div>
      </div>
    </ViewContainer>
  );
}
