import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { AttachmentPreviews, ConversationMessages, TypingStatusIndicator } from './';

const classes = BEM.with('ExistingConversation');

class ExistingConversation extends Component {
  static propTypes = {
    clearNewMessagesIndicator: PropTypes.func.isRequired,
    clearNewMessagesOnConvClick: PropTypes.bool.isRequired,
    conversation: propTypes.conversation.isRequired,
    currentMessageMultiSelectOption: PropTypes.string.isRequired,
    findConversation: PropTypes.func.isRequired,
    getTypersForCounterParty: PropTypes.func.isRequired,
    hasAttachments: PropTypes.bool.isRequired,
    markConversationAsRead: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { conversation, findConversation } = this.props;
    const shouldEnsure = conversation.entitiesEnsured
      ? { ensureEntities: null }
      : { ensureEntities: { calculateRoleOwnership: true } };
    findConversation(conversation.id, shouldEnsure);
  }

  render() {
    const {
      conversation,
      currentMessageMultiSelectOption,
      getTypersForCounterParty,
      hasAttachments,
    } = this.props;
    const { counterParty } = conversation;
    const inMessageMultiSelectMode = currentMessageMultiSelectOption !== '';
    const typers = getTypersForCounterParty(counterParty);
    const shouldDisplayTypingIndicator = !inMessageMultiSelectMode && typers.length > 0;

    return (
      <div className={classes()} onClick={this._markAsReadByClick}>
        <ConversationMessages
          counterParty={counterParty}
          firstUnreadMessage={conversation.firstUnreadMessage}
          key={conversation.id}
          lastMessage={conversation.lastMessage}
          markConversationAsRead={this._markAsRead}
          messages={conversation.timeline}
        />
        {shouldDisplayTypingIndicator && (
          <div className={classes('typers', { hasAttachments })}>
            <TypingStatusIndicator
              counterParty={counterParty}
              maxTypers={3}
              size={6}
              typers={typers}
            />
          </div>
        )}
        <div className={classes('header-fade-element')} />
        <AttachmentPreviews />
      </div>
    );
  }

  _markAsReadByClick = async () => {
    const { clearNewMessagesIndicator, clearNewMessagesOnConvClick, conversation } = this.props;
    await this._markAsRead();
    if (clearNewMessagesOnConvClick) clearNewMessagesIndicator(conversation);
  };

  _markAsRead = async (options) => {
    const { conversation, markConversationAsRead } = this.props;
    await markConversationAsRead(conversation, options);
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['hasAttachments'],
  conversationStore: ['clearNewMessagesIndicator', 'findConversation', 'markConversationAsRead'],
  localStore: ['clearNewMessagesOnConvClick'],
  messengerStore: ['currentMessageMultiSelectOption'],
  typingStatusStore: ['getTypersForCounterParty'],
})(ExistingConversation);
