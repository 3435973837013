import Enum from '../../utils/enum';

const MessageType = new Enum([
  'AUTO_FORWARD',
  'CALL_CHANGE',
  'ESCALATION_EXECUTION_CHANGE',
  'GROUP_MEMBERSHIP_CHANGE',
  'HIGHER_CONTINUATION',
  'LOWER_CONTINUATION',
  'REACTION',
  'USER_SENT',
] as const);

export default MessageType;
