import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { once } from 'ramda';
import classNames from 'classnames';
import {
  SEARCH_PARITY_AUTOFORWARD_SEARCH_TYPES,
  SEARCH_PARITY_EXTENDED_AUTOFORWARD_SEARCH_TYPES,
} from '../../../../models/enums/SearchTypes';
import BEM from '../../../bem';
import { FROZEN_EMPTY_ARRAY, mobxInjectSelect } from '../../../utils';
import { Modal, RecipientSearchPicker } from '../..';

const classes = BEM.with('AutoForwardModal');

class AutoForwardModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    currentUserId: PropTypes.string.isRequired,
    getAutoForwardEntitiesIdsByOrg: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    resetAutoLogout: PropTypes.func.isRequired,
    setAutoForward: PropTypes.func.isRequired,
  };

  state = {
    assignUser: FROZEN_EMPTY_ARRAY,
    shouldAssignUsersBackToZero: false,
  };

  render() {
    const { currentUserId, getAutoForwardEntitiesIdsByOrg, isOpen, options } = this.props;
    const { assignUser, shouldAssignUsersBackToZero } = this.state;
    const { organization } = options;
    const isExtendedAutoForwardOptionsEnabled = organization.dndAutoForwardEntities;

    const autoForwardEntitiesRecipients = getAutoForwardEntitiesIdsByOrg(options.organization);

    const shouldShowAtLeastOneOtherRecipientErrorMessage =
      assignUser.length === 1 && assignUser[0].id === currentUserId;

    const isSaveButtonDisabled =
      assignUser.length === 0 || shouldShowAtLeastOneOtherRecipientErrorMessage;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        header="Auto Forward"
        isOpen={isOpen}
        onRequestClose={this._requestClose}
        size={'medium'}
        footerPrimaryActions={
          <button
            type="button"
            className={classes('save-btn')}
            disabled={isSaveButtonDisabled}
            onClick={once(this._onSave)}
          >
            SAVE
          </button>
        }
        footerSecondaryActions={
          <div>
            <button type="button" onClick={this._requestClose} className={classes('cancel-btn')}>
              CANCEL
            </button>
          </div>
        }
      >
        <div className={classes('body-container')}>
          <div className={classes('description-container')}>
            <span>
              Auto-forward while in Do Not Disturb mode will route all messages to the contact of
              your choosing.
            </span>
          </div>
          <div className={classes('error-message-container')}>
            {shouldShowAtLeastOneOtherRecipientErrorMessage && (
              <span>You must select at least one other recipient.</span>
            )}
          </div>

          <div className={classes('members-container')}>
            {isExtendedAutoForwardOptionsEnabled ? (
              <RecipientSearchPicker
                className={classNames(
                  'Auto-forward-modal',
                  classes('extended-auto-forward-options')
                )}
                excludeSelf={false}
                multi={true}
                onChange={this._onAssignUserChange}
                organization={organization}
                placeholder="Choose a contact"
                searchTypes={SEARCH_PARITY_EXTENDED_AUTOFORWARD_SEARCH_TYPES}
                selected={
                  isExtendedAutoForwardOptionsEnabled &&
                  assignUser.length === 0 &&
                  !shouldAssignUsersBackToZero
                    ? autoForwardEntitiesRecipients
                    : assignUser
                }
                tabIndex={1}
              />
            ) : (
              <RecipientSearchPicker
                className={'Auto-forward-modal'}
                excludeRoles
                excludeTeams={true}
                multi={true}
                onChange={this._onAssignUserChange}
                organization={organization}
                placeholder="Choose a contact"
                searchTypes={SEARCH_PARITY_AUTOFORWARD_SEARCH_TYPES}
                selected={assignUser}
                tabIndex={1}
              />
            )}
          </div>
        </div>
      </Modal>
    );
  }

  _onSave = () => {
    const { options, setAutoForward } = this.props;
    const { assignUser } = this.state;
    let entitiesIds = [{ account: [], team: [], role: [] }];
    const { organization } = options;
    const isExtendedAutoForwardOptionsEnabled = organization.dndAutoForwardEntities;

    if (isExtendedAutoForwardOptionsEnabled) {
      entitiesIds = assignUser.reduce(
        (acc, user) => {
          if (user.$entityType === 'role') {
            acc.role.push(user.id.split(':')[1]);
          } else if (user.$entityType === 'team') {
            acc.team.push(user.id);
          } else {
            acc.account.push(user.id);
          }
          return acc;
        },
        { account: [], team: [], role: [] }
      );
    }

    setAutoForward(organization, assignUser[0].id, entitiesIds);
    this._requestClose();
  };

  _onAssignUserChange = (values) => {
    const { getAutoForwardEntitiesIdsByOrg, options, resetAutoLogout } = this.props;
    let assignUser = [];
    const { organization } = options;
    const isExtendedAutoForwardOptionsEnabled = organization.dndAutoForwardEntities;

    if (isExtendedAutoForwardOptionsEnabled) {
      assignUser.concat(...getAutoForwardEntitiesIdsByOrg(options?.organization));
      this.setState({
        shouldAssignUsersBackToZero:
          values.length === 0 && assignUser.length === getAutoForwardEntitiesIdsByOrg.length,
      });
      assignUser.push(...values);
      if (assignUser.length === 99) return;
    } else {
      assignUser = values.length === 0 ? FROZEN_EMPTY_ARRAY : [values[values.length - 1]];
    }

    resetAutoLogout();
    this.setState({ assignUser });
  };

  _requestClose = () => {
    const { options } = this.props;
    const { organization } = options;
    const isExtendedAutoForwardOptionsEnabled = organization.dndAutoForwardEntities;
    const { closeModal } = this.props;

    if (!isExtendedAutoForwardOptionsEnabled) this.setState({ assignUser: FROZEN_EMPTY_ARRAY });
    closeModal();
  };
}

export default mobxInjectSelect({
  sessionStore: ['currentUserId', 'resetAutoLogout'],
  userStore: ['getAutoForwardEntitiesIdsByOrg', 'setAutoForward'],
})(AutoForwardModal);
