import React from 'react';
import { observer } from 'mobx-react';

import CareTeamAvatar from './CareTeamAvatar';
import DistributionListAvatar from './DistributionListAvatar';
import GroupAvatar from './GroupAvatar';
import RoleAvatar from './RoleAvatar';
import TeamAvatar from './TeamAvatar';
import UserAvatar from './UserAvatar/UserAvatar';
import { ReactElementDict, User } from 'types';

const AVATAR_COMPONENT_BY_TYPE: ReactElementDict = {
  distributionList: DistributionListAvatar,
  group: GroupAvatar,
  role: RoleAvatar,
  team: TeamAvatar,
  user: UserAvatar,
  careTeam: CareTeamAvatar,
};

interface EntityAvatarProps {
  entity: { $entityType: string; members?: Array<object>; [key: string]: unknown };
  size?: string | number;
  useWCL?: boolean;
  [key: string]: unknown;
}

function EntityAvatar(props: EntityAvatarProps) {
  const _normalizeEntityProps = () => {
    const { entity, ...restProps } = props;
    const { $entityType: entityType } = entity;

    const newProps = { ...restProps, [entityType]: entity };
    if (entityType === 'role') {
      const roleUser = (
        entity?.members && entity.members.length === 1 ? entity.members[0] : entity?.botUser
      ) as User;
      newProps.userId = roleUser?.id;
      newProps.presenceStatus = roleUser?.presenceStatus;

      return { newProps, entityType };
    }

    if (entityType !== 'user' && entityType !== 'role') {
      const { ...otherProps } = newProps;
      if (entityType === 'team' || (entityType === 'group' && entity.groupType === 'INTRA_TEAM')) {
        otherProps.displayName = entity.displayName;
        return { newProps: otherProps, entityType: 'team' };
      }

      return { newProps: otherProps, entityType };
    }

    return { newProps, entityType };
  };

  const { newProps, entityType } = _normalizeEntityProps();
  const AvatarComponent = AVATAR_COMPONENT_BY_TYPE[entityType];
  if (!AvatarComponent) {
    const { entity } = props;
    console.error('cannot render entity avatar for', { entity, entityType });
    return null;
  }

  return <AvatarComponent {...newProps} entityType={entityType} />;
}

export default observer(EntityAvatar);
