import { Conversation, Entity } from 'types';

const getGroupMembers = (
  isCurrentConversationGroup: boolean | undefined,
  isEntityInConversationCheck: boolean | undefined,
  currentConversation: Conversation | undefined
) => {
  if (isCurrentConversationGroup && isEntityInConversationCheck) {
    return (currentConversation?.counterParty.memberIds as string[]) ?? ([] as string[]);
  } else if (!isCurrentConversationGroup && isEntityInConversationCheck) {
    return [currentConversation?.counterParty?.id] as string[];
  } else return [];
};

const checkIfUserIsAGroupMember = (membersIds: string[], userId: string) => {
  return membersIds.some((item) => item === userId);
};

const checkIfUserIsATeamMember = (selectedTeamMembers: Entity[] | undefined, userId: string) => {
  return selectedTeamMembers?.some((selectedMember) => {
    const memberType = selectedMember?.$entityType;

    if (memberType === 'team') {
      return selectedMember.members?.some((innerMember) => innerMember.id === userId);
    } else {
      const memberId = selectedMember?.botUserId || selectedMember?.id;

      return memberId === userId;
    }
  });
};

export const getNotInConversationLabel = (
  currentConversation: Conversation | undefined,
  isEntityInConversationCheck: boolean | undefined,
  userId: string,
  selectedRecipients?: Entity[]
) => {
  const isCurrentConversationGroup =
    currentConversation?.counterPartyType?.toLowerCase() === 'group';

  const groupMembers = getGroupMembers(
    isCurrentConversationGroup,
    isEntityInConversationCheck,
    currentConversation
  );

  const isTeamMember = checkIfUserIsATeamMember(selectedRecipients, userId);

  const isGroupMember = checkIfUserIsAGroupMember(groupMembers, userId);

  if (isGroupMember && !isCurrentConversationGroup) {
    return '';
  }

  if (isCurrentConversationGroup && !isGroupMember) {
    return 'Not in conversation';
  }

  if (!isCurrentConversationGroup && !isTeamMember) {
    return 'Not in conversation';
  }

  return '';
};
