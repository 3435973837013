import React from 'react';
import { Card } from '../../WebComponents';
import { Role } from '../../../../types';
import BEM from '../../../bem';
import { ReactComponent as RoleTransitionDisabledIcon } from '../../../images/role-transition-disabled-icon.svg';
import { ReactComponent as RoleTransitionEnabledIcon } from '../../../images/role-transition-enabled-icon.svg';
const classes = BEM.with('CollaborationEntityEditor');

type RoleEditorDetailsProps = {
  role: Role;
  roleTransition: string;
};

export default function RoleEditorDetails({ role, roleTransition }: RoleEditorDetailsProps) {
  const isRoleTransitionEnabledForRole = role?.metadata?.role_transition;
  const isHandoffEnabledForAllRoles = roleTransition === 'all';
  const shouldDisplayPrivateGroupExclusionText =
    (roleTransition === 'all' ||
      (roleTransition === 'individual' && isRoleTransitionEnabledForRole)) &&
    role?.metadata?.exclude_private_group;

  const [roleTransitionText, RoleTransitionIcon] =
    isHandoffEnabledForAllRoles || isRoleTransitionEnabledForRole
      ? ['Conversation handoff at shift change disabled', RoleTransitionDisabledIcon]
      : ['Conversation handoff at shift change enabled', RoleTransitionEnabledIcon];

  const detailsText = shouldDisplayPrivateGroupExclusionText
    ? `${roleTransitionText}. Private groups excluded and will be handed off`
    : roleTransitionText;

  return (
    <div className={classes('card-container')}>
      <Card header="Role Details">
        <div className={classes('switch-block')}>
          <div className={classes('switch-item', { rolesHandoffIconIsDisplayed: true })}>
            <RoleTransitionIcon style={{ marginRight: 15 }} />
            <span>{detailsText}</span>
          </div>
        </div>
      </Card>
    </div>
  );
}
