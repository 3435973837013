import React, { useState } from 'react';
import BEM from '../../../../common/bem';
import { mobxInjectSelect } from '../../../../common/utils';
import PatientSidebarTabs from '../../../../models/enums/PatientSidebarTabs';
import { AccessibleList } from 'common/components';
import { KEYMAP } from 'common/constants';
import { useAppSelector } from 'redux-stores';
import { UISliceState } from 'redux-stores/ui';

const classes = BEM.with('PatientSidebarTabPicker');

type PatientSidebarTabPickerProps = {};

type MobxProps = {
  isSearching: boolean;
  setSidebarTab: (tab: string) => void;
  sidebarTab: string;
};

function PatientSidebarTabPicker({
  isSearching,
  setSidebarTab,
  sidebarTab,
}: PatientSidebarTabPickerProps & MobxProps) {
  const { accessibilityMode } = useAppSelector(({ ui }: { ui: UISliceState }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const getTabAriaControls = (index: number) => {
    switch (index) {
      case 0:
        return 'patient-conversation-list';
      case 1:
        return 'call-log';
      default:
        return 'unknown';
    }
  };

  if (isSearching) {
    return <></>;
  }

  return (
    <div className={classes()} role="tablist" id="patient-sidebar-tab-picker-container">
      <AccessibleList
        accessibilityMode={accessibilityMode}
        className={classes('accessibility')}
        direction="horizontal"
        focusableClasses={['.tc-PatientSidebarTabPicker__tab']}
        loop={true}
      >
        {Object.values(PatientSidebarTabs).map((tab: string, index: number) => (
          <button
            key={tab}
            className={classes('tab', {
              isActive: sidebarTab === tab,
            })}
            onClick={() => {
              setSidebarTab(tab);
              setActiveTabIndex(index);
            }}
            onKeyDown={(e) => {
              if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
                setSidebarTab(tab);
                setActiveTabIndex(index);
              }
            }}
            role="tab"
            aria-selected={activeTabIndex === index}
            aria-controls={getTabAriaControls(index)}
            id={`patient-sidebar-tab-picker-${index + 1}`}
          >
            {tab.toUpperCase()}
          </button>
        ))}
      </AccessibleList>
    </div>
  );
}

export default mobxInjectSelect<PatientSidebarTabPickerProps, MobxProps>({
  patientStore: ['setSidebarTab', 'sidebarTab'],
  messengerStore: ['isSearching'],
})(PatientSidebarTabPicker);
