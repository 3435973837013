import React, { useEffect, useState } from 'react';

import {
  AddMemberToConversationModal,
  MentionMemberModal,
} from '../../../common/components/Mentions';
import mobxInjectSelect from '../../../common/utils/mobxInjectSelect';
import {
  AddMemberToVideoCall,
  AlertDetailsModal,
  AllowedSenderModal,
  AlwaysEscalateModal,
  CancelEscalationModal,
  CreateAppointmentModal,
  CreateSuccessModal,
  CreateOrEditCustomDirectoriesModal,
  DeleteAppointmentModal,
  DeleteBroadcastList,
  DeleteBroadcastListModal,
  DeleteConversationModal,
  DeleteTeamModal,
  DeleteTemplateModal,
  DeleteScheduledMessageModal,
  DuplicateContactModal,
  EscalationEnabledModal,
  FailureModal,
  GoModal,
  GroupMessageStatusModal,
  JoinForumModal,
  LeaveTeamModal as OldLeaveTeamModal,
  MessageDeleteModal,
  MessageForwardModal,
  MessageInfoModal,
  MessageRecallModal,
  MuteModal,
  NewGroupModal,
  PatientDeleteModal,
  PatientForwardSuccessModal,
  PatientSaveSuccessModal,
  PatientsSubmittedCSVModal,
  PriorityMessageModal,
  RejectedFileFormatModal,
  RejectedFileSizeModal,
  RemoveEscalationGroupModal,
  RemovePatientBroadcastListMember,
  RemoveRoleOrUserLineModal,
  SuccessModal,
  SupportedFileTypesModal,
  SukiModal,
  PatientAdminFailureModal,
  VisitorCallModal,
} from 'common/components';
import AddADSyncModal from 'common/components/BroadcastListSettings/AddADSyncModal';
import {
  AssignedRoleModal,
  RemovedFromRoleModal,
  TookOverRoleModal,
} from 'common/components/Roles/Modals';
import {
  ClearRolesSchedulerModal,
  DeleteEntityModal,
  DeleteTagModal,
  ErrorModal,
  EscalationModal,
  OnDutyModal,
  OptInModal,
  PagerModal,
  RemovePagerModal,
  RemoveTagModal,
  RoleEditorHandoffWarningScreenModal,
  RolesSchedulerModal,
  TagModal,
  TagSelectorModal,
  LeaveTeamModal,
} from 'common/components/Collaboration/Modals';
import {
  CallActionModal,
  CalleeBusyModal,
  ConfirmCallModal,
  EnableBrowserPermissionsModal,
  JoinVoipCallErrorModal,
  NoCameraConnectionModal,
  UnsupportedBrowserModal,
  CalleePermissionsModal,
  CalleeCapabilitiesModal,
  CalleeDndEnabledModal,
} from 'common/components/Call';
import {
  ScheduleMessageReviewModal,
  EditScheduleMessageModal,
  ScheduleMessageModal,
} from 'common/components/ScheduleMessageModal';
import { PatientBroadcastCsvFailModal } from 'common/components/PatientSettings/PatientBroadcastLists';
import { QuickReplyModal } from 'common/components/MessageForm';
import { MessageAttachmentModal } from 'common/components/MessageItem';
import { LeaveGroupModal } from 'common/components/GroupEditor';
import {
  ContactDeleteModal,
  CreatePatientModal,
  FailureUpdateModal,
  ManagePatientOrContactModal,
  NewPatientCsvModal,
  PatientCsvFailModal,
  UploadPBXLinesModal,
  CSVSubmittedModal,
  UploadPatientsCsvModal,
} from 'common/components/PatientSettings/PatientsList/Modals';
import {
  AutoForwardModal,
  CustomDndMessageModal,
  CustomDndTimeModal,
  ForceLogoutModal,
  LogoutModal,
  WrongPasswordModal,
} from 'common/components/Profile/Modals';
import {
  PatientReferenceSelectModal,
  PatientReferenceDetailsModal,
} from 'common/components/PatientContext';
import { AddCareTeamModal } from 'common/components/AddCareTeamModal';
import { EulaModal } from 'common/components/EulaModal';
import { PatientProfileModal } from 'common/components/PatientProfileModal';
import SecurityRiskModal from 'admin/pages/OrgSettings/Modals/SecurityRiskModal';
import OrgSettingEnabledModal from 'admin/pages/OrgSettings/Modals/OrgSettingEnabledModal';

import { ReactElementDict } from 'types';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

const { setMessageBodyInputFocus, setModal } = actions;

const COMPONENT_BY_TYPE: ReactElementDict = {
  addADSync: AddADSyncModal,
  addCareTeamModal: AddCareTeamModal,
  addMemberToVideoCall: AddMemberToVideoCall,
  alertDetails: AlertDetailsModal,
  allowedSenderModal: AllowedSenderModal,
  alwaysEscalate: AlwaysEscalateModal,
  assignedRoleModal: AssignedRoleModal,
  autoForwardModal: AutoForwardModal,
  callAction: CallActionModal,
  calleeBusy: CalleeBusyModal,
  calleeCapabilities: CalleeCapabilitiesModal,
  calleeDndEnabled: CalleeDndEnabledModal,
  calleePermissions: CalleePermissionsModal,
  callUnsupported: UnsupportedBrowserModal,
  cancelEscalation: CancelEscalationModal,
  clearScheduler: ClearRolesSchedulerModal,
  confirmCall: ConfirmCallModal,
  contactDelete: ContactDeleteModal,
  createAppointment: CreateAppointmentModal,
  createPatient: CreatePatientModal,
  createSuccessModal: CreateSuccessModal,
  createOrEditCustomDirectories: CreateOrEditCustomDirectoriesModal,
  csvSubmitted: CSVSubmittedModal,
  customDndTime: CustomDndTimeModal,
  customDndMessage: CustomDndMessageModal,
  deleteAppointment: DeleteAppointmentModal,
  deleteEntity: DeleteEntityModal,
  deleteTeam: DeleteTeamModal,
  deleteTag: DeleteTagModal,
  deleteConversation: DeleteConversationModal,
  deleteSelectedBroadcastListItems: DeleteBroadcastList,
  deleteBroadcastList: DeleteBroadcastListModal,
  deleteScheduledMessage: DeleteScheduledMessageModal,
  deleteTemplate: DeleteTemplateModal,
  duplicateContact: DuplicateContactModal,
  editScheduleMessage: EditScheduleMessageModal,
  enableBrowserPermissions: EnableBrowserPermissionsModal,
  escalation: EscalationModal,
  escalationEnabled: EscalationEnabledModal,
  uploadPBXLines: UploadPBXLinesModal,
  error: ErrorModal,
  eula: EulaModal,
  failure: FailureModal,
  failureUpdate: FailureUpdateModal,
  forceLogoutModal: ForceLogoutModal,
  go: GoModal,
  groupMessageStatus: GroupMessageStatusModal,
  joinForum: JoinForumModal,
  joinVoipCallErrorModal: JoinVoipCallErrorModal,
  leaveGroup: LeaveGroupModal,
  oldLeaveTeam: OldLeaveTeamModal,
  leaveTeam: LeaveTeamModal,
  logoutModal: LogoutModal,
  managePatientOrContact: ManagePatientOrContactModal,
  mentionMember: MentionMemberModal,
  addMemberToConversation: AddMemberToConversationModal,
  messageAttachment: MessageAttachmentModal,
  messageDelete: MessageDeleteModal,
  messageForward: MessageForwardModal,
  messageInfo: MessageInfoModal,
  messageRecall: MessageRecallModal,
  mute: MuteModal,
  newGroup: NewGroupModal,
  newPatientCsvFail: NewPatientCsvModal,
  noCamera: NoCameraConnectionModal,
  onDuty: OnDutyModal,
  optIn: OptInModal,
  optOut: OnDutyModal,
  ownerRequired: OnDutyModal,
  patientAdminFailure: PatientAdminFailureModal,
  patientBroadcastCsvFail: PatientBroadcastCsvFailModal,
  patientCsvFail: PatientCsvFailModal,
  patientDelete: PatientDeleteModal,
  patientForwardSuccess: PatientForwardSuccessModal,
  patientProfile: PatientProfileModal,
  patientReferenceSelect: PatientReferenceSelectModal,
  patientReferenceDetails: PatientReferenceDetailsModal,
  patientSaveSuccess: PatientSaveSuccessModal,
  patientsSubmittedCSV: PatientsSubmittedCSVModal,
  patientAccessCode: SecurityRiskModal,
  orgSettingEnabled: OrgSettingEnabledModal,
  patientDOB: SecurityRiskModal,
  pager: PagerModal,
  priorityMessage: PriorityMessageModal,
  quickReply: QuickReplyModal,
  rejectedFileFormat: RejectedFileFormatModal,
  rejectedFileSize: RejectedFileSizeModal,
  removePager: RemovePagerModal,
  removedFromRoleModal: RemovedFromRoleModal,
  removeEscalationGroup: RemoveEscalationGroupModal,
  removePatientBroadcastListMember: RemovePatientBroadcastListMember,
  removeRoleOrUserLine: RemoveRoleOrUserLineModal,
  removeTag: RemoveTagModal,
  roleScheduler: RolesSchedulerModal,
  rtuWarningScreen: RoleEditorHandoffWarningScreenModal,
  scheduleMessage: ScheduleMessageModal,
  scheduleMessageReview: ScheduleMessageReviewModal,
  success: SuccessModal,
  supportedFileTypes: SupportedFileTypesModal,
  sukiModal: SukiModal,
  tag: TagModal,
  tagSelector: TagSelectorModal,
  tagSelectorEditor: TagSelectorModal,
  tookOverRoleModal: TookOverRoleModal,
  uploadPatientsCsv: UploadPatientsCsvModal,
  visitorCall: VisitorCallModal,
  wrongPasswordModal: WrongPasswordModal,
};

type MobxProps = {
  closeModal: () => void;
  currentModalType: string;
  isModalOpen: boolean;
  options: { [key: string]: unknown };
};

type RootModalProps = {};

function RootModal({
  closeModal,
  currentModalType,
  isModalOpen,
  options,
}: RootModalProps & MobxProps) {
  const dispatch = useAppDispatch();
  const openModal = useAppSelector(({ session }) => session.openModal);
  const isReduxModalOpen = useAppSelector(({ session }) => session.isModalOpen);
  const [isOpen, setIsOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState('');
  const [_options, setOptions] = useState({});

  useEffect(() => {
    if (isModalOpen && currentModalType) {
      setCurrentModal(currentModalType);
      setOptions(options);
      setIsOpen(true);
    } else if (isReduxModalOpen && openModal) {
      setCurrentModal(openModal?.name);
      setOptions(openModal?.data || {});
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [currentModalType, isModalOpen, isReduxModalOpen, openModal, options]);
  const Modal = COMPONENT_BY_TYPE[currentModal];

  function _closeModal() {
    closeModal();
    dispatch(setModal(undefined));
    dispatch(setMessageBodyInputFocus(true));
  }

  return Modal ? (
    <div style={{ zIndex: 2 }}>
      <Modal closeModal={_closeModal} isOpen={isOpen} options={_options} />
    </div>
  ) : null;
}

export default mobxInjectSelect<RootModalProps, MobxProps>({
  modalStore: ['closeModal', 'currentModalType', 'isModalOpen', 'options'],
})(RootModal);
