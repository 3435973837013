import { emojiMap } from '../../../emoji/emojiMap';

export type ToneIndex = '1' | '2' | '3' | '4' | '5' | '6';
type SkinToneHex = '1f3fb' | '1f3fc' | '1f3fd' | '1f3fe' | '1f3ff';

const toneMap: Record<ToneIndex, SkinToneHex | null> = {
  '6': '1f3ff',
  '5': '1f3fe',
  '4': '1f3fd',
  '3': '1f3fc',
  '2': '1f3fb',
  '1': null,
};

export const buildQuickActionThumbsUp = (
  getStorageValue: (keyValue: string) => ToneIndex | null
) => {
  const THUMB_CODE_POINT = '1f44d';

  const savedToneIndex = getStorageValue('emoji-mart.skin') || '1';
  const skinToneHex = toneMap[savedToneIndex];

  const thumbsUp = [THUMB_CODE_POINT, skinToneHex].filter((p): p is string => p !== null).join('-');

  return thumbsUp;
};

export const generateHexTone = (getStorageValue: (keyValue: string) => ToneIndex | null) => {
  const savedToneIndex = getStorageValue('emoji-mart.skin') || '1';
  const skinToneHex = toneMap[savedToneIndex];
  if (skinToneHex === null) return null;
  return skinToneHex;
};

export const getUserNames = ({
  currentUserId,
  userIds,
  userIdsMap,
}: {
  currentUserId: string;
  userIds: string[];
  userIdsMap: Map<string, string>;
}) => {
  let names: string[] = [];
  let reactedBySelf = false;

  for (const userId of userIds) {
    if (userId === currentUserId) {
      reactedBySelf = true;
      continue;
    }

    const name = userIdsMap.get(userId);
    if (name) names.push(name);
  }

  if (reactedBySelf) {
    names = ['You', ...names];
  }

  return names.join(', ');
};

export type Reaction = { emojiUnicode: string; userIds: string[] };

export type GroupedReaction = {
  baseReaction: string;
  fullReactions: Reaction[];
  allUserIds: string[];
  totalCount: number;
};

export const groupReactionsByTone = (reactions: Reaction[]): GroupedReaction[] => {
  const mapped = reactions.reduce((mappedReactions, reaction) => {
    const reactionUnicode = reaction.emojiUnicode;
    const reactionBase = emojiMap[reactionUnicode]?.['baseHex'] || reactionUnicode;

    if (reactionBase in mappedReactions) {
      const { baseReaction, fullReactions, allUserIds, totalCount } = mappedReactions[reactionBase];

      const userIds = reaction.userIds.slice();
      const _allUserIds = allUserIds.slice();
      const uniqueUserIds = Array.from(new Set([..._allUserIds, ...userIds]));

      mappedReactions[reactionBase] = {
        baseReaction,
        fullReactions: fullReactions.concat(reaction),
        allUserIds: uniqueUserIds,
        totalCount: totalCount + reaction.userIds.length,
      };
    } else {
      const userIds = reaction.userIds.slice();
      const uniqueUserIds = Array.from(new Set(userIds));

      mappedReactions[reactionBase] = {
        baseReaction: reactionBase,
        fullReactions: [reaction],
        allUserIds: uniqueUserIds,
        totalCount: reaction.userIds.length,
      };
    }
    return mappedReactions;
  }, {} as { [emoji: string]: GroupedReaction });

  const grouped = Object.values(mapped);

  grouped.sort((a, b) => b.totalCount - a.totalCount);

  return grouped;
};

export const renderEmoji = (e: string) => {
  const hexValues = e.split('-');
  try {
    const codePoints = hexValues.map((codePoint) => parseInt(`0x${codePoint}`, 16));
    return String.fromCodePoint(...codePoints);
  } catch (err) {
    console.error('Error parsing emoji', err);
  }
};
