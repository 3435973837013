import { last } from 'lodash';
import { CollabEntity, Role, Team } from '../../types';

export const roleMember = (role: Role) => role?.members?.[0];

export const entityTagId = (entity: CollabEntity) => {
  if (entity?.$entityType === 'role') {
    return (entity as Role)?.metadata?.tag_id || undefined;
  } else if (entity?.$entityType === 'team') {
    return (entity as Team)?.tag?.id || undefined;
  }
};

export const standardTagId = (id: string | undefined, orgId: string) => {
  return id ? `${orgId}:${last(id.split(':'))}` : id;
};

export const entityServerId = (id: string) => {
  if (!id || !id.includes(':')) return id;
  return id.split(':')[1];
};
