import React, { Component } from 'react';
import classNames from 'classnames';
import BEM from '../../bem';

const classes = BEM.with('FormField');

class FormFieldWrapper extends Component {
  render() {
    const {
      children,
      className,
      errorMessage,
      hidden,
      invisible,
      isFormSubmitted,
      isPristine,
      isValid,
      label,
      labelCaption,
      required,
      showError,
      showRequired,
      type,
    } = this.props;

    return (
      <div
        className={classNames(
          classes({
            'form-submitted': isFormSubmitted,
            [isValid ? 'valid' : 'invalid']: true,
            error: showError,
            hidden,
            invisible,
            required: showRequired,
            type,
          }),
          className
        )}
      >
        {label ? (
          <label className={classes('label')}>
            <span className={classes('label-text')}>
              {label}
              {required && ' '}
              {required && <span className={classes('required-indicator')}>*</span>}
              {labelCaption && <span className={classes('label-caption')}>{labelCaption}</span>}
            </span>
            {children}
          </label>
        ) : (
          children
        )}

        {(!isPristine || isFormSubmitted) && errorMessage && (
          <span className={classes('error-message')}>{errorMessage}</span>
        )}
      </div>
    );
  }
}

export default FormFieldWrapper;
