import React, { Component } from 'react';
import propTypes from '../../../../common/propTypes';
import ExistingConversation from '../../../../common/components/ExistingConversation';
import mobxInjectSelect from '../../../../common/utils/mobxInjectSelect';

class CurrentConversation extends Component {
  static propTypes = {
    currentConversation: propTypes.conversation,
  };

  render() {
    const { currentConversation } = this.props;

    return <ExistingConversation conversation={currentConversation} key={currentConversation.id} />;
  }
}

export default mobxInjectSelect({
  conversationStore: ['currentConversation'],
})(CurrentConversation);
