import React, { useEffect, useState } from 'react';
import { MessageTemplateRepository } from '../../../../models/enums/MessageTemplateRepositories';

import { TemplateForm, TemplatesView, TemplateView } from './';

const ACTIONS = {
  CREATE: 'create',
  EDIT: 'edit',
  SEARCH: 'search',
  VIEW: 'view',
};

type MessageTemplatesProps = {
  isAdmin: boolean;
  repository: MessageTemplateRepository;
  logPendoAnalytics: (p: { parentPathName: string; pathName: string }) => void;
  setCurrentAdminPageRefresher: (fn: () => void) => void;
};

const MessageTemplates: React.FC<MessageTemplatesProps> = ({
  isAdmin,
  logPendoAnalytics,
  repository,
  setCurrentAdminPageRefresher,
}) => {
  const [action, setAction] = useState(ACTIONS.SEARCH);
  const [id, setId] = useState<string>('');

  useEffect(() => {
    setAction(ACTIONS.SEARCH);
    setId('');
  }, [repository]);

  const createTemplate = () => {
    setId('');
    logPendoAnalytics({
      parentPathName: 'Patient Settings',
      pathName: `Create ${repository} Message Template`,
    });
    setAction(ACTIONS.CREATE);
  };

  const editTemplate = (id: string) => {
    setId(id);
    setAction(ACTIONS.EDIT);
  };

  const viewTemplate = (id: string) => {
    setId(id);
    setAction(ACTIONS.VIEW);
  };

  const searchTemplates = () => {
    setId('');
    setAction(ACTIONS.SEARCH);
  };

  const setMessageTemplateAdminPageRefresher = (fn: () => void) => {
    setCurrentAdminPageRefresher(() => {
      searchTemplates();
      fn();
    });
  };

  switch (action) {
    case ACTIONS.CREATE:
    case ACTIONS.EDIT:
      return (
        <TemplateForm
          id={id}
          isAdmin={isAdmin}
          repository={repository}
          searchTemplates={searchTemplates}
        />
      );
    case ACTIONS.SEARCH:
      return (
        <TemplatesView
          repository={repository}
          createTemplate={createTemplate}
          editTemplate={editTemplate}
          isAdmin={isAdmin}
          viewTemplate={viewTemplate}
          setMessageTemplateAdminPageRefresher={setMessageTemplateAdminPageRefresher}
        />
      );
    case ACTIONS.VIEW:
      return <TemplateView id={id} repository={repository} searchTemplates={searchTemplates} />;
    default:
      return <></>;
  }
};

export default MessageTemplates;
