import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../WebComponents';
import { Modal } from '../..';
import BEM from '../../../bem';
import { actions, ReduxState } from '../../../../redux-stores';
import { MapStateToProps, reduxInjectSelect } from '../../../utils/reduxInjectSelect';
import { BatchEntityUpdateProps } from '../../../../types/Collaboration';
import { Role } from '../../../../types';
import { batchEntityUpdate } from '../../../../redux-stores/Collaboration/entities/thunk';

const { setModal } = actions;
const classes = BEM.with('CollaborationModal');
const BasicModalClasses = BEM.with('BasicModal');

const reduxSelectors = {
  session: ['openModal'],
  collab: ['selectedOrgId'],
  entities: ['activeTab', 'entitiesById', 'selectedEntity'],
  tags: ['checkedTagsById', 'selectedCategory', 'selectedTag', 'tagsById'],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type RoleEditorHandoffWarningScreenModalProps = {
  isOpen: boolean;
};

function RoleEditorHandoffWarningScreenModal({
  activeTab,
  checkedTagsById,
  entitiesById,
  isOpen,
  selectedCategory,
  selectedEntity,
  selectedOrgId,
  selectedTag,
  tagsById,
}: ReduxProps & RoleEditorHandoffWarningScreenModalProps) {
  const dispatch = useDispatch();
  const selectedRole = selectedEntity as Role;

  const closeModal = () => {
    dispatch(setModal(undefined));
  };

  const updateRoleTransition = async () => {
    const batchUpdatePayload: BatchEntityUpdateProps = {
      activeTab,
      ids: [selectedRole.id],
      checkedTagsById,
      organizationId: selectedOrgId,
      entitiesById,
      entityUpdateProps: {
        isRoleTransitionEnabled: !selectedRole?.metadata?.role_transition,
        doesRoleTransitionExcludePrivateGroups: false,
      },
      selectedCategory,
      selectedEntity,
      selectedTag,
      tagsById,
    };

    await batchEntityUpdate(dispatch, batchUpdatePayload);

    closeModal();
  };

  return (
    <Modal
      ariaLabelCloseButton={'Role Transition Warning Screen Close'}
      ariaLabelHeader={'Role Transition Warning Screen Header'}
      bodyClass={BasicModalClasses('body')}
      closeClass={BasicModalClasses('close-button-normal')}
      header={'Change Role Transition Setting?'}
      headerClass={BasicModalClasses('header-normal', { rtuWarningScreen: true })}
      hasCloseButton={true}
      isOpen={isOpen}
      size={'medium'}
      footerPrimaryActions={
        <Button
          dataTestId={'Role Transition Warning Screen Submit'}
          onClick={updateRoleTransition}
          label="CONTINUE"
          outline
        />
      }
      footerSecondaryActions={
        <Button
          dataTestId={'Role Transition Warning Screen Cancel'}
          onClick={closeModal}
          outline
          color="neutral"
          label="CANCEL"
          alignment="right"
        />
      }
      className={BasicModalClasses('')}
      onRequestClose={closeModal}
    >
      <div data-test-id={'Role Transition Warning Screen Body'} className={classes('')}>
        <div className={classes('body')}>
          {'Changing this setting will affect the handoff of messages and conversations between Role owners. ' +
            'Are you sure you want to update this setting?'}
        </div>
      </div>
    </Modal>
  );
}

export default reduxInjectSelect<RoleEditorHandoffWarningScreenModalProps, ReduxProps, ReduxState>(
  reduxSelectors
)(RoleEditorHandoffWarningScreenModal);
