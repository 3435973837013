import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { last, times } from 'lodash';
import { mobxInjectSelect } from '../../../utils';
import BEM from '../../../bem';
import { Tag } from '../../../../types';
import InlineModal from '../Modals/InlineModal';
import { DotsIndicator, ScrollingList } from '../..';

import { actions, ReduxState, thunk } from '../../../../redux-stores';

import { CollaborationCategories } from '../../../../models/enums';
import { CollaborationCategory } from '../../../../models/enums/CollaborationCategories';
import { shouldFetchMore } from '../../../../redux-stores/Collaboration/util';
import {
  notTaggedTag,
  selectCheckedTagsById,
  selectCheckedTagsIds,
  selectTagsByIdAlphabetical,
} from '../../../../redux-stores/Collaboration/tags';
import { selectMyTeamsCount } from '../../../../redux-stores/Collaboration/teams';
import { selectMyRolesCount } from '../../../../redux-stores/Collaboration/roles';
import TagListItem from './TagListItem';

const { setModal, selectTag, selectTagCategory, setCheckedTags } = actions;
const { fetchSavedTags, fetchTagsThunk } = thunk;
const { ALL, MYROLES, MYTEAMS, NOTTAGGED } = CollaborationCategories;

const classes = BEM.with('CollaborationTags');
const modalClasses = BEM.with('CollaborationInlineModal');

type TagsListProps = {};

type MobxProps = {
  collabSelection: string;
  currentUser: { id: string; organizationIds?: Array<string> };
};

type RowType = { id: string; type?: string; first?: number };

function TagsList({ collabSelection, currentUser }: TagsListProps & MobxProps) {
  const dispatch = useDispatch();
  const tagListRef = useRef<ScrollingList | null>(null);
  const lastFetchedContinuation = useRef<string | undefined>(undefined);

  const hasFetchedTotals = useSelector((state: ReduxState) => state.entities.hasFetchedTotals);
  const taggedEntityCount = useSelector((state: ReduxState) => state.entities.taggedEntityCount);
  const untaggedEntityCount = useSelector(
    (state: ReduxState) => state.entities.untaggedEntityCount
  );

  const isRolesAdmin = useSelector((state: ReduxState) => state.collab.isRolesAdmin);
  const isTeamAdmin = useSelector((state: ReduxState) => state.collab.isTeamAdmin);
  const isRolesEnabled = useSelector((state: ReduxState) => state.collab.isRolesEnabled);
  const isTeamsEnabled = useSelector((state: ReduxState) => state.collab.isTeamsEnabled);
  const selectedOrgId = useSelector((state: ReduxState) => state.collab.selectedOrgId);

  const filteredColors = useSelector((state: ReduxState) => state.tags.filteredColors);
  const isLoadingTags = useSelector((state: ReduxState) => state.tags.isLoadingTags);
  const checkedTagsById = useSelector(selectCheckedTagsById);
  const checkedTagsIds = useSelector(selectCheckedTagsIds);
  const savedTags = useSelector((state: ReduxState) => state.tags.savedTags);
  const selectedCategory = useSelector((state: ReduxState) => state.tags.selectedCategory);
  const selectedTag = useSelector((state: ReduxState) => state.tags.selectedTag);
  const tagContinuation = useSelector((state: ReduxState) => state.tags.tagContinuation);
  const tagSearchQuery = useSelector((state: ReduxState) => state.tags.tagSearchQuery);
  const tags = useSelector(selectTagsByIdAlphabetical);

  const myTeamsCount = useSelector(selectMyTeamsCount);
  const myRolesCount = useSelector(selectMyRolesCount);

  const [isSavedOpen, setIsSavedOpen] = useState(true);
  const [isGeneralOpen, setIsGeneralOpen] = useState(true);
  const [rowTypes, setRowTypes] = useState<RowType[]>([]);

  useEffect(() => {
    selectedOrgId && fetchSavedTags(dispatch, selectedOrgId, isRolesEnabled, isTeamsEnabled);
  }, [dispatch, isRolesEnabled, isTeamsEnabled, selectedOrgId]);

  useEffect(() => {
    if (!selectedOrgId) return;
    lastFetchedContinuation.current = undefined;
    dispatch(fetchTagsThunk({}));
  }, [dispatch, filteredColors, isRolesEnabled, isTeamsEnabled, selectedOrgId, tagSearchQuery]);

  useEffect(() => {
    if (tagListRef.current) {
      tagListRef.current.recomputeRowHeights(1);
    }
  }, [isSavedOpen, isGeneralOpen]);

  useEffect(() => {
    const isSearchFilter = tagSearchQuery || filteredColors.length > 0;
    const hasSavedData =
      (myRolesCount && isRolesEnabled) || (myTeamsCount && isTeamsEnabled) || savedTags.length > 0;
    const showMyRoles = (collabSelection === MYROLES && !hasFetchedTotals) || myRolesCount;
    const savedOpenNoFilter = isSavedOpen && !isSearchFilter;
    const organizationIdList = currentUser?.organizationIds;
    const organizationHasCurrentUser = organizationIdList?.includes(selectedOrgId);

    const items: RowType[] = [
      ...(!isSearchFilter && hasSavedData && organizationHasCurrentUser
        ? [{ id: 'saved', type: 'accordion' }]
        : []),
      ...(showMyRoles && savedOpenNoFilter && isRolesEnabled && organizationHasCurrentUser
        ? [{ id: MYROLES }]
        : []),
      ...(myTeamsCount && savedOpenNoFilter && isTeamsEnabled && organizationHasCurrentUser
        ? [{ id: MYTEAMS }]
        : []),
      ...(savedOpenNoFilter ? savedTags.map(() => ({ id: 'savedTag' })) : []),
      ...(!isSearchFilter && hasSavedData && organizationHasCurrentUser
        ? [{ id: 'general', type: 'accordion' }]
        : []),
      ...(isGeneralOpen && !isSearchFilter ? [{ id: ALL }] : []),
      ...(isGeneralOpen && !isSearchFilter ? [{ id: NOTTAGGED }] : []),
      ...((isGeneralOpen || isSearchFilter) && (isRolesEnabled || isTeamsEnabled)
        ? tags.map((x) => ({ id: 'tag' }))
        : []),
    ].reduce((memo: RowType[], item: RowType, index: number) => {
      const lastItem = last(memo) || { id: '' };
      const isFirst = lastItem.id !== item.id;
      return [...memo, { ...item, first: isFirst ? index : lastItem.first }];
    }, []);
    setRowTypes(items);
    tagListRef.current?.recomputeRowHeights();
  }, [
    selectedOrgId,
    filteredColors.length,
    isGeneralOpen,
    isSavedOpen,
    myRolesCount,
    myTeamsCount,
    savedTags,
    tagSearchQuery,
    tags,
    hasFetchedTotals,
    collabSelection,
    isRolesEnabled,
    isTeamsEnabled,
    currentUser,
  ]);

  const getRowHeight = ({ index }: { index: number }) => {
    const rowType = rowTypes[index] || {};
    return rowType.type === 'accordion' ? 30 : 50;
  };

  const handleSelectTag = useCallback(
    (tag: Tag) => {
      dispatch(selectTag(tag));
    },
    [dispatch]
  );

  const handleCheckTag = useCallback(
    (tagId: string, tag: Tag, isChecked) => {
      const newCheckedTags = { ...checkedTagsById };
      if (isChecked) {
        delete newCheckedTags[tagId];
      } else {
        newCheckedTags[tagId] = tag;
      }
      dispatch(setCheckedTags(newCheckedTags));
    },
    [checkedTagsById, dispatch]
  );

  const handleSelectCategory = useCallback(
    (category: CollaborationCategory) => {
      dispatch(selectTagCategory(category));
    },
    [dispatch]
  );

  const rowRenderer = ({
    key,
    index,
    style,
  }: {
    key: string;
    index: number;
    style?: Record<string, unknown>;
  }) => {
    if (shouldFetchMore(index, rowTypes.length, tagContinuation, lastFetchedContinuation.current)) {
      lastFetchedContinuation.current = tagContinuation;
      dispatch(
        fetchTagsThunk({
          continuation: tagContinuation,
        })
      );
    }

    const rowType = rowTypes[index] || {};
    let content;
    let tag: Tag | undefined;

    if (rowType.id === 'saved') {
      content = (
        <TagListItem
          colorHex="#99a4a5"
          dropdown={true}
          isDropdownOpen={isSavedOpen}
          onClick={() => setIsSavedOpen(!isSavedOpen)}
          name="SAVED"
        />
      );
    } else if (rowType.id === MYROLES) {
      content = (
        <TagListItem
          handleSelect={() => {
            handleSelectCategory(MYROLES);
          }}
          name="My Roles"
          entityCount={myRolesCount}
          selected={selectedCategory === MYROLES}
        />
      );
    } else if (rowType.id === MYTEAMS) {
      content = (
        <TagListItem
          handleSelect={() => {
            handleSelectCategory(MYTEAMS);
          }}
          name="My Teams"
          entityCount={myTeamsCount}
          selected={selectedCategory === MYTEAMS}
        />
      );
    } else if (rowType.id === 'savedTag') {
      const savedTagIndex = index - (rowType.first || 0);
      tag = savedTags[savedTagIndex];
      content = (
        <TagListItem
          checkedTagsById={checkedTagsById}
          handleCheckTag={handleCheckTag}
          handleSelectTag={handleSelectTag}
          hasCheck
          isChecked={tag && !!checkedTagsIds.find((id) => id === tag?.id)}
          entityCount={tag?.entityCount || 0}
          selected={selectedTag?.id === tag?.id}
          tag={tag}
          name={tag?.name}
          id={tag?.id}
        />
      );
    } else if (rowType.id === 'general') {
      content = (
        <TagListItem
          colorHex="#99a4a5"
          dropdown={true}
          isDropdownOpen={isGeneralOpen}
          onClick={() => setIsGeneralOpen(!isGeneralOpen)}
          name="GENERAL"
        />
      );
    } else if (rowType.id === ALL) {
      content = (
        <TagListItem
          handleSelect={() => {
            handleSelectCategory(ALL);
          }}
          name={ALL}
          entityCount={taggedEntityCount + untaggedEntityCount}
          selected={selectedCategory === ALL}
        />
      );
    } else if (rowType.id === NOTTAGGED) {
      tag = notTaggedTag;
      content = (
        <TagListItem
          handleSelect={() => {
            handleSelectCategory(NOTTAGGED);
          }}
          hasCheck
          isChecked={tag && !!checkedTagsIds.find((id) => id === tag?.id)}
          checkedTagsById={checkedTagsById}
          handleCheckTag={handleCheckTag}
          name={NOTTAGGED}
          entityCount={untaggedEntityCount}
          selected={selectedCategory === NOTTAGGED}
          tag={tag}
          id={tag.id}
        />
      );
    } else if (rowType.id === 'tag') {
      const tagIndex = index - (rowType.first || 0);
      tag = tags[tagIndex];
      content = (
        <TagListItem
          checkedTagsById={checkedTagsById}
          handleCheckTag={handleCheckTag}
          handleSelectTag={handleSelectTag}
          hasCheck
          isChecked={tag && !!checkedTagsIds.find((id) => id === tag?.id)}
          entityCount={tag?.entityCount || 0}
          selected={selectedTag?.id === tag?.id}
          tag={tag}
          name={tag?.name}
          id={tag?.id}
        />
      );
    }

    return (
      <div key={key} style={style}>
        {content}
      </div>
    );
  };

  const isFilterOrSearchActive = tagSearchQuery || filteredColors.length > 0;
  const showInlineModal = !isFilterOrSearchActive && !isLoadingTags && !tags.length;
  const emptyFilter = isFilterOrSearchActive && !tags.length;

  let listLength = 0;
  listLength = rowTypes.length;
  if (tagContinuation) {
    listLength += 1;
  }
  const getInlineListHeight = () => {
    return times(listLength).reduce((memo, index) => memo + getRowHeight({ index }), 0) + 'px';
  };

  return (
    <div
      className={classes('tags-list-container', { listGrow: tags.length })}
      data-test-id="tags list container"
    >
      {!isLoadingTags && !emptyFilter && (
        <div style={{ height: showInlineModal ? getInlineListHeight() : '100%' }}>
          <ScrollingList
            rowCount={listLength + 2}
            ref={tagListRef}
            rowRenderer={rowRenderer}
            rowHeight={getRowHeight}
          />
        </div>
      )}
      {isLoadingTags && (
        <div className={classes('loading-holder')}>
          <DotsIndicator color="#828282" size={12} marginTop={20} marginRight={5} />
        </div>
      )}
      {!isLoadingTags && isFilterOrSearchActive && !tags.length && (
        <div className={classes('no-results')}>
          {filteredColors.length > 0
            ? 'No results found'
            : `No results for "${tagSearchQuery}" found`}
        </div>
      )}
      {showInlineModal && (
        <div className={classnames(classes('tags-list-modal-container'), classes('empty-bg'))}>
          <InlineModal id="new-tag" isTag>
            <div className={modalClasses('inline-header')}>TAGGING</div>
            <div className={modalClasses('body-text')}>
              Currently there are no tags in this organization.
            </div>
            <button
              className={modalClasses('button')}
              data-test-id="create new tag inline"
              onClick={() => dispatch(setModal({ name: 'tag' }))}
            >
              {(isRolesAdmin || isTeamAdmin) && 'Create a new tag'}
            </button>
          </InlineModal>
        </div>
      )}
    </div>
  );
}

export default mobxInjectSelect<TagsListProps, MobxProps>({
  messengerStore: ['collabSelection'],
  sessionStore: ['currentUser'],
})(TagsList);
