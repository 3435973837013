import React, { useEffect, useState, useRef } from 'react';
import BEM from '../../../bem';
import { mobxInjectSelect } from '../../../utils';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import PatientCSVUploadTypes from '../../../../models/enums/PatientCSVUploadTypes';

import RefreshButton from '../../RefreshButton';
import { ReactComponent as CancelButtonSvg } from '../../../images/patient-search-cancel.svg';
import { ReactComponent as PatientSvg } from '../../../images/default-avatar--patientCare.svg';
import { ReactComponent as PlusButtonSvg } from '../../../images/plus-lean.svg';
import { ReactComponent as SearchButtonSvg } from '../../../images/search.svg';
import { ReactComponent as UploadButtonSvg } from '../../../images/upload.svg';
import { ProcessingIndicator, PatientAdminList, PatientData, PatientSettingsSearchBar } from '.';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('PatientsList');
const { PATIENT, APPOINTMENT_REMINDERS } = PatientCSVUploadTypes;

type MobxProps = {
  allowAutomatedAppointmentReminders: boolean;
  clearAppointmentData: () => void;
  destroy: () => void;
  isCsvPatientUploadFlagEnabled: boolean;
  hidePatientSearch: () => void;
  hideRefreshPatientMessage: () => void;
  isCSVUploading: boolean;
  isSearching: boolean;
  isPatientSearchOpen: boolean;
  search: () => void;
  openCreatePatientModal: () => void;
  openUploadPatientsCsvModal: (origin?: string, listId?: string) => void;
  showPatientSearch: () => void;
  showRefreshPatientMessage: boolean;
  totalPatients: number;
};

type PatientsListProps = {
  setCurrentAdminPageRefresher: (fn: () => void) => void;
};

const PatientsList: React.FC<PatientsListProps & MobxProps> = ({
  allowAutomatedAppointmentReminders,
  clearAppointmentData,
  destroy,
  hidePatientSearch,
  hideRefreshPatientMessage,
  isCsvPatientUploadFlagEnabled,
  isCSVUploading,
  isSearching,
  isPatientSearchOpen,
  search,
  openCreatePatientModal,
  openUploadPatientsCsvModal,
  setCurrentAdminPageRefresher,
  showPatientSearch,
  showRefreshPatientMessage,
  totalPatients,
}) => {
  let searchIcon, patientSearchBar, patientSettingsBodyFragment;
  const [selectedPatientId, setSelectedPatientId] = useState('');
  const [showUploadMenu, setShowUploadMenu] = useState(false);
  const uploadButtonRef = useRef<HTMLButtonElement>(null);
  const searchButtonRef = useRef<HTMLButtonElement>(null);

  const createPatient = () => {
    openCreatePatientModal();
  };

  const _refreshPatientList = () => {
    destroy();
    search();
    setSelectedPatientId('');
  };

  const clickUploadButton = () => {
    if (allowAutomatedAppointmentReminders) setShowUploadMenu(!showUploadMenu);
    else {
      openUploadPatientsCsvModal(PATIENT);
    }
  };

  const _hidePatientSearch = () => {
    destroy();
    hidePatientSearch();
  };

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === KEYMAP.ESCAPE) {
        if (isPatientSearchOpen) {
          _hidePatientSearch();
          if (searchButtonRef.current) {
            searchButtonRef.current.focus();
          }
        } else if (showUploadMenu) {
          setShowUploadMenu(false);
          if (uploadButtonRef.current) {
            uploadButtonRef.current.focus();
          }
        }
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  });

  const handleCancelSearchKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === KEYMAP.ESCAPE) {
      _hidePatientSearch();
      if (searchButtonRef.current) {
        searchButtonRef.current.focus();
      }
    }
  };

  const handleUploadKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === KEYMAP.ESCAPE) {
      setShowUploadMenu(false);
      if (uploadButtonRef.current) {
        uploadButtonRef.current.focus();
      }
    }
  };

  const _showPatientSearch = () => {
    showPatientSearch();
  };

  const selectPatientId = (id: string) => {
    clearAppointmentData();
    setSelectedPatientId(id);
  };

  setCurrentAdminPageRefresher(_refreshPatientList.bind(this));

  useEffect(() => {
    if (!allowAutomatedAppointmentReminders || isSearching) {
      setSelectedPatientId('');
    }
  }, [allowAutomatedAppointmentReminders, isSearching]);

  if (isPatientSearchOpen) {
    searchIcon = (
      <button
        className={classes('bg-icon')}
        data-test-id={'cancelSearch'}
        aria-label="Cancel Search"
        onKeyDown={handleCancelSearchKeyDown}
        onClick={_hidePatientSearch}
      >
        <CancelButtonSvg className={classes('button-icon-cancel')} aria-hidden />
      </button>
    );
    patientSearchBar = <PatientSettingsSearchBar hidePatientSearch={_hidePatientSearch} />;
  } else {
    searchIcon = (
      <button
        ref={searchButtonRef}
        className={classes('bg-icon')}
        data-test-id={'openSearch'}
        aria-label="open search"
        onClick={_showPatientSearch}
      >
        <SearchButtonSvg className={classes('button-icon-fill')} aria-hidden />
      </button>
    );
  }

  if (selectedPatientId.length === 0) {
    patientSettingsBodyFragment = (
      <div className={classes()}>
        <div className={classes('patient-admin-wrapper')}>
          <div className={classes('patient-admin-header')}>
            <div className={classes('patient-admin-title')}>
              <h3 id="patient-settings-heading">PATIENT LIST</h3>
              <RefreshButton refreshList={_refreshPatientList} tooltipText="Refresh Patients" />
              {showRefreshPatientMessage && !isCsvPatientUploadFlagEnabled && (
                <div className={classes('refresh-message')}>
                  <div className={classes('arrow')} />
                  <div className={classes('refresh-content')}>
                    <div className={classes('content')}>Refresh to see imported patients</div>
                    <div className={classes('close-button')} onClick={hideRefreshPatientMessage}>
                      x
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={classes('patient-admin-icons')}>
              <ToolTip text="Search Patients" className={classes('tooltip')} isPatientFacing>
                {searchIcon}
              </ToolTip>
              <ToolTip
                disable={showUploadMenu}
                text="Upload Patients"
                className={classes('tooltip')}
                isPatientFacing
              >
                <button
                  ref={uploadButtonRef}
                  className={classes('bg-icon', { open: showUploadMenu })}
                  data-test-id={'uploadPatients'}
                  aria-label="upload patients"
                  onClick={clickUploadButton}
                  onKeyDown={handleUploadKeyDown}
                >
                  <UploadButtonSvg className={classes('button-icon-path')} aria-hidden />
                </button>
              </ToolTip>
              {showUploadMenu && (
                <div className={classes('upload-menu-outer-container')}>
                  <div className={classes('upload-menu-inner-container')}>
                    <button
                      className={classes('upload-menu-option')}
                      onClick={() => {
                        setShowUploadMenu(false);
                        openUploadPatientsCsvModal(PATIENT);
                      }}
                    >
                      Upload Patients
                    </button>
                    <button
                      className={classes('upload-menu-option')}
                      onClick={() => {
                        setShowUploadMenu(false);
                        openUploadPatientsCsvModal(APPOINTMENT_REMINDERS);
                      }}
                      aria-label="Upload Appointments"
                    >
                      Upload Appointments
                    </button>
                  </div>
                </div>
              )}
              <ToolTip text="Add Patient" className={classes('tooltip')} isPatientFacing>
                <button
                  className={classes('bg-icon')}
                  data-test-id={'createPatient'}
                  aria-label="create patient"
                  onClick={createPatient}
                >
                  <PlusButtonSvg className={classes('button-icon-path')} aria-hidden />
                </button>
              </ToolTip>
            </div>
          </div>
          <div className={classes('patient-count-container')}>
            <div className={classes('avatar-container')}>
              <PatientSvg aria-hidden className={classes('patient-icon')} />
            </div>
            <div className={classes('patient-count-info')}>
              <div className={classes('patient-count-header')}>Patient List</div>
              <div className={classes('patient-count')}>
                {Number.isInteger(totalPatients)
                  ? `${totalPatients} Patient${totalPatients === 1 ? '' : 's'}`
                  : 'Loading...'}
              </div>
            </div>
          </div>
          {patientSearchBar}
          <PatientAdminList setSelectedPatientId={selectPatientId} />
          {isCSVUploading && <ProcessingIndicator />}
        </div>
      </div>
    );
  } else {
    patientSettingsBodyFragment = (
      <div className={classes()}>
        <PatientData
          selectedPatientId={selectedPatientId}
          closePatientData={() => {
            selectPatientId('');
          }}
        />
      </div>
    );
  }

  return patientSettingsBodyFragment;
};

export default mobxInjectSelect<PatientsListProps, MobxProps>({
  appointmentsStore: ['clearAppointmentData'],
  patientAdminStore: ['allowAutomatedAppointmentReminders'],
  patientStore: [
    'destroy',
    'hidePatientSearch',
    'hideRefreshPatientMessage',
    'isCSVUploading',
    'isSearching',
    'isPatientSearchOpen',
    'search',
    'openCreatePatientModal',
    'openUploadPatientsCsvModal',
    'showPatientSearch',
    'showRefreshPatientMessage',
    'totalPatients',
  ],
  messengerStore: ['isCsvPatientUploadFlagEnabled'],
})(PatientsList);
