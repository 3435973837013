import styles from '../OrganizationSettings.module.css';
import { PBXTab, PBXLinesList } from '../../../../types';
import UserRoleAssigneeRow from './UserRoleAssigneeRow';

type UserRoleAssigneeTableProps = {
  category: PBXTab;
  results?: PBXLinesList[];
  selected: string[];
  setSelected: (selected: string[]) => void;
  organizationId: string;
  toggleSelected: (id: string) => void;
  setPbxLinesList: (pbxLinesList: PBXLinesList[]) => void;
};

export default function UserRoleAssigneeTable({
  category,
  results,
  setPbxLinesList,
  selected,
  setSelected,
  toggleSelected,
  organizationId,
}: UserRoleAssigneeTableProps) {
  return (
    <div className={styles.assigneeRows}>
      {results &&
        results.map((result) => (
          <UserRoleAssigneeRow
            category={category}
            result={result}
            key={result.id}
            targetId={result.targetId}
            selected={selected}
            setSelected={setSelected}
            pbxLinesList={results}
            toggleSelected={toggleSelected}
            setPbxLinesList={setPbxLinesList}
            organizationId={organizationId}
          />
        ))}
    </div>
  );
}
