import React from 'react';
import BEM from '../../bem';
import Modal from '../Modal';
import mobxInjectSelect from '../../utils/mobxInjectSelect';

const classes = BEM.with('CallModal');

function NoCameraConnectionModal({
  closeModal,
  isOpen,
  joinVoipCall,
  options,
  setIncomingCallActionsEnabled,
  startCall,
}) {
  const { entity, isIncomingCall, isProviderNetwork, roomName, videoDevice } = options;
  const { $entityType: entityType, id, recipientId } = entity;
  const isGroup = entityType === 'group';
  const members = isGroup || !isProviderNetwork ? entity.members : [];
  const bodyClass = !videoDevice ? 'body' : 'body-no-margin';

  const _closeModal = () => {
    closeModal();
    setIncomingCallActionsEnabled(true);
  };

  const _startOrJoinVideoCall = async () => {
    if (isIncomingCall) {
      joinVoipCall({ shouldAnswerWithCamOn: false, roomName });
      setIncomingCallActionsEnabled(false);
      _closeModal();
    } else {
      startCall({
        recipientId: recipientId || id,
        members,
        context: { type: entityType === 'user' ? 'account' : entityType, id: entity.id },
      });
    }
  };

  return (
    <Modal
      bodyClass={classes(bodyClass)}
      className={classes()}
      closeClass={classes('close-button')}
      footerClass={classes('footer')}
      headerClass={classes('header')}
      isOpen={isOpen}
      onRequestClose={_closeModal}
      size={'medium'}
      footerPrimaryActions={
        <button className={classes('primary-btn')} onClick={_startOrJoinVideoCall} type="button">
          CONTINUE
        </button>
      }
      footerSecondaryActions={
        <button className={classes('secondary-btn')} onClick={_closeModal} type="button">
          CANCEL
        </button>
      }
    >
      <div className={classes('title')}> Unable to connect to camera </div>
      Continue video call with voice only?
      {videoDevice && (
        <div className={classes('enable-text')}>
          {' '}
          You need to enable access from your browser to enable video.{' '}
        </div>
      )}
    </Modal>
  );
}

export default mobxInjectSelect({
  callStore: ['closeModal', 'joinVoipCall', 'startCall', 'setIncomingCallActionsEnabled'],
})(NoCameraConnectionModal);
