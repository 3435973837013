import React from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../../../../utils';
import { Modal } from '../../../';
import PrioritySvg from '../../../../images/priority-icon.svg';

import BEM from '../../../../bem';

const classes = BEM.with('FailureUpdateModal');

const FailureUpdateModal = ({ closeModal, destroy, isOpen, search, options }) => {
  const { entityError } = options;
  const imgHeader = {
    image: PrioritySvg,
    fill: '#db524b',
    imgClassHeader: classes('img'),
  };

  const _refreshPatient = () => {
    destroy();
    search();
    closeModal();
  };

  return (
    <Modal
      header={'Oh no.'}
      className={classes()}
      headerClass={classes('header')}
      hasCloseButton={false}
      imgHeader={imgHeader}
      isOpen={isOpen}
      size={'small-medium'}
    >
      <div className={classes('text')}>
        You cannot save because the {entityError} information is out of sync. Please refresh to
        continue.
      </div>
      <div onClick={_refreshPatient} className={classes('refresh-btn')}>
        REFRESH
      </div>
    </Modal>
  );
};

FailureUpdateModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
};

export default mobxInjectSelect({
  patientStore: ['destroy', 'search'],
})(FailureUpdateModal);
