import { useEffect, useState } from 'react';

export function useLocalAudioTrack(audioTrack) {
  const [track, setTrack] = useState(audioTrack);

  const getLocalAudioTrack = () => {
    setTrack(audioTrack);
    return audioTrack;
  };

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalAudioTrack];
}

export function useLocalVideoTrack({ createdVideoTrack }) {
  const [track, setTrack] = useState(createdVideoTrack);

  const getLocalVideoTrack = () => {
    setTrack(createdVideoTrack);
    return createdVideoTrack;
  };

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalVideoTrack];
}

export function useLocalTrackToggle(shouldAnswerWithCamOn) {
  const [isLocalAudioToggledOff, setIsLocalAudioToggledOff] = useState(null);
  const [isLocalVideoToggledOff, setIsLocalVideoToggledOff] = useState(!shouldAnswerWithCamOn);
  const toggleLocalTrack = (trackKind) => {
    if (trackKind === 'audio') {
      setIsLocalAudioToggledOff(!isLocalAudioToggledOff);
    } else {
      setIsLocalVideoToggledOff(!isLocalVideoToggledOff);
    }
  };

  return { isLocalAudioToggledOff, isLocalVideoToggledOff, toggleLocalTrack };
}

export default function useLocalTracks({
  createdAudioTrack,
  createdVideoTrack,
  shouldAnswerWithCamOn,
}) {
  const [audioTrack, getLocalAudioTrack] = useLocalAudioTrack(createdAudioTrack);
  const [videoTrack, getLocalVideoTrack] = useLocalVideoTrack({
    createdVideoTrack,
    shouldAnswerWithCamOn,
  });
  const localTrackToggling = useLocalTrackToggle(shouldAnswerWithCamOn);

  const localTracks = [audioTrack, videoTrack].filter((track) => track !== undefined);

  return {
    localTracks,
    getLocalAudioTrack,
    getLocalVideoTrack,
    localTrackToggling,
  };
}
