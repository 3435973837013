import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../../common/bem';
import { EntityAvatar, GroupAvatar } from '../../../../common/components';
import { ReactComponent as AvatarImageSvg } from '../../../../common/images/dummy-avatar.svg';
import mobxInjectSelect from '../../../../common/utils/mobxInjectSelect';
import { ReactComponent as ActivatedTeamSvg } from '../../../../common/images/default-avatar--unTaggedActivatedTeam.svg';

const classes = BEM.with('DummyConversationAvatar');

class DummyConversationAvatar extends Component {
  static propTypes = {
    showBlankAvatar: PropTypes.bool.isRequired,
    entity: PropTypes.object,
    isTeamActivation: PropTypes.bool.isRequired,
    selectedRecipients: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };

  render() {
    const {
      entity: entityProp,
      isTeamActivation,
      selectedRecipients,
      showBlankAvatar,
    } = this.props;

    const entity = isTeamActivation ? selectedRecipients[0] : entityProp;

    const image =
      isTeamActivation && !entity.tag ? (
        <ActivatedTeamSvg className={classes('avatar-image')} />
      ) : isTeamActivation ? (
        <GroupAvatar
          group={{ ...entity, groupType: 'ACTIVATED_TEAM', tag: entity.tag }}
          size="fit"
        />
      ) : entity ? (
        <EntityAvatar entity={entity} size="fit" showPresenceIndicator={true} />
      ) : showBlankAvatar ? (
        <span className={classes('blank-avatar')} />
      ) : (
        <AvatarImageSvg className={classes('avatar-image')} />
      );

    return (
      <div className={classes()}>
        <div
          className={classes('avatar-image-container', {
            entity: entity !== null,
          })}
        >
          {image}
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  composeMessageStore: ['isTeamActivation', 'selectedRecipients'],
})(DummyConversationAvatar);
