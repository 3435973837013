import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HotKeys } from 'react-hotkeys';
import HotKeyActions from '../../../models/enums/HotKeyActions';
import { GROUP_MEMBER_SEARCH_TYPES } from '../../../models/enums/SearchTypes';
import BEM from '../../bem';
import AccessibleList from '../AccessibleList';
import { ReactComponent as AddButtonSvg } from '../../images/add-button.svg';
import propTypes from '../../propTypes';
import { FROZEN_EMPTY_ARRAY, mobxInjectSelect } from '../../utils';
import { DotsIndicator, RecipientSearchPicker } from '../';
import { GroupMember } from './';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';

const classes = BEM.with('GroupMembers');

class GroupMembers extends Component {
  static propTypes = {
    currentConversation: propTypes.conversation.isRequired,
    group: propTypes.group.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isMembersDirty: PropTypes.bool.isRequired,
    isProviderNetwork: PropTypes.bool.isRequired,
    memberCount: PropTypes.number.isRequired,
    members: propTypes.userArray.isRequired,
    prepareAddMember: PropTypes.func.isRequired,
    resetAutoLogout: PropTypes.func.isRequired,
    resetGroupEditor: PropTypes.func.isRequired,
    returnFocus: PropTypes.func.isRequired,
    saveInProgress: PropTypes.bool.isRequired,
    saveMembers: PropTypes.func.isRequired,
    scrollBarOffset: PropTypes.number,
  };

  state = {
    selectedMembers: FROZEN_EMPTY_ARRAY,
    showRecipientPicker: false,
    accessibilityMode: false,
  };

  constructor(...args) {
    super(...args);

    this.handlers = {
      [HotKeyActions.ESCAPE]: this._escapeRecipientPicker,
    };
  }

  render() {
    const {
      currentConversation,
      fixedButtons,
      group,
      isLoading,
      isMembersDirty,
      isProviderNetwork,
      memberCount,
      members,
      saveInProgress,
      scrollBarOffset,
      setGroupMembers,
      isNoSearchResultsOnEmptyQueryEnabled,
    } = this.props;
    const { selectedMembers, showRecipientPicker } = this.state;
    const { organization } = currentConversation;
    const { groupType } = group;
    let buttonsFragment, membersFragment, recipientPickerFragment;

    const excludeIds = [];
    const excludeRoles = groupType === 'FORUM' || groupType === 'PATIENT_MESSAGING';

    for (const member of members) {
      excludeIds.push(member.id);

      const { botRole } = member;
      if (botRole && botRole.members.length > 0) {
        excludeIds.push(botRole.members[0].id);
      }
    }

    if (showRecipientPicker) {
      recipientPickerFragment = (
        <HotKeys className={classes('recipient-picker-container')} handlers={this.handlers}>
          <RecipientSearchPicker
            className={'Edit-group'}
            enabledCapabilities={isProviderNetwork ? undefined : ['patient_network']}
            excludeIds={excludeIds}
            excludeRoles={excludeRoles}
            excludeTeams={
              groupType === 'FORUM' ||
              groupType === 'PATIENT_MESSAGING' ||
              groupType === 'PATIENT_CARE'
            }
            excludeIntraTeams={true}
            multi
            shouldShowSelectedValues={false}
            onChange={this._onMemberChange}
            organization={organization}
            placeholder="Search for members"
            reposition={false}
            searchTypes={GROUP_MEMBER_SEARCH_TYPES}
            selected={selectedMembers}
            tabIndex={0}
            openOnType={isNoSearchResultsOnEmptyQueryEnabled}
          />
        </HotKeys>
      );
    }

    if (saveInProgress) {
      membersFragment = (
        <div className={classes('dots-container')}>
          <DotsIndicator className={classes('dots')} size={20} speed={0.5} color={'#db524b'} />
        </div>
      );
    } else {
      membersFragment = (
        <div className={classes('list-container')}>
          <ReduxEscapeHatch
            ref={(ref) => {
              this.reduxEscapeHatch = ref;
              if (
                this.reduxEscapeHatch &&
                this.reduxEscapeHatch?.accessibilityMode !== this.state.accessibilityMode
              ) {
                this.setState({ accessibilityMode: this.reduxEscapeHatch?.accessibilityMode });
              }
            }}
          />
          <AccessibleList
            className={classes('list')}
            accessibilityMode={this.state.accessibilityMode}
            focusableClasses={'.tc-GroupMembers__details'}
            focusableChildrenClasses={'.tc-GroupMembers__actions'}
            setStartElementOnChange={true}
          >
            {members
              .filter(({ shouldDisplay }) => shouldDisplay)
              .map((user) => (
                <GroupMember key={user.id} user={user} />
              ))}
          </AccessibleList>
        </div>
      );
    }

    if (isMembersDirty && !saveInProgress) {
      buttonsFragment = (
        <div className={classes('buttons-container', { fixedButtons })}>
          {fixedButtons && <div className={classes('padding')}></div>}
          <div
            className={classes('buttons', {
              fixedButtons,
              scrollBarOffset: !!scrollBarOffset && fixedButtons,
            })}
          >
            <button className={classes('cancel-button')} onClick={this._cancel}>
              Cancel
            </button>
            <button
              className={classes('save-button')}
              disabled={saveInProgress}
              onClick={this._save}
            >
              Save
            </button>
          </div>
        </div>
      );
    } else {
      buttonsFragment = <div className={classes('footer')} />;
    }

    return (
      <div className={classes({ fixedButtons })} ref={setGroupMembers}>
        <div className={classes('header')}>
          <div>
            {groupType === 'PATIENT_CARE' ? 'CARE TEAM MEMBERS' : 'MEMBERS'}
            {groupType === 'PATIENT_MESSAGING' && <span> ({memberCount}) </span>}
          </div>
          {groupType !== 'INTRA_TEAM' && (
            <button
              className={classes('add-button', { active: showRecipientPicker })}
              onClick={this._openRecipientPicker}
              aria-label="Open recipient picker"
            >
              <AddButtonSvg aria-hidden />
            </button>
          )}
        </div>
        {recipientPickerFragment}
        {membersFragment}
        {isLoading && !saveInProgress && (
          <div className={classes('loading')}>
            <DotsIndicator size={20} speed={0.5} color={'#db524b'} />
          </div>
        )}
        {buttonsFragment}
      </div>
    );
  }

  _escapeRecipientPicker = (e) => {
    e.preventDefault();
    const { returnFocus } = this.props;

    this.setState({ showRecipientPicker: false });
    returnFocus();
  };

  _openRecipientPicker = () => {
    this.setState({ showRecipientPicker: true });
  };

  _cancel = () => {
    const { resetGroupEditor } = this.props;
    this.setState({ showRecipientPicker: false });
    resetGroupEditor();
  };

  _save = () => {
    const { saveMembers } = this.props;
    this.setState({ showRecipientPicker: false });
    saveMembers();
  };

  _onMemberChange = (members) => {
    const { group, prepareAddMember, resetAutoLogout } = this.props;

    this.setState({ selected: members || FROZEN_EMPTY_ARRAY });
    resetAutoLogout();

    if (members) {
      members.forEach((member) => {
        if (member.$entityType === 'team') {
          member.memberIds.forEach((memberId) => {
            if (group.memberIds.includes(memberId)) return;
            prepareAddMember(memberId);
          });
        } else {
          prepareAddMember(member.$entityType === 'role' ? member.botUserId : member.id);
        }
      });
    }
  };
}

export default mobxInjectSelect({
  conversationStore: ['currentConversation'],
  groupEditorStore: [
    'resetGroupEditor',
    'group',
    'isMembersDirty',
    'members',
    'memberCount',
    'prepareAddMember',
    'saveInProgress',
    'saveMembers',
  ],
  networkStore: ['isProviderNetwork'],
  sessionStore: ['resetAutoLogout'],
  messengerStore: ['isNoSearchResultsOnEmptyQueryEnabled'],
})(GroupMembers);
