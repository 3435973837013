import createMobxModel from './createMobxModel';

const ScheduledMessage = createMobxModel({
  name: 'scheduledMessage',

  fields: [
    'appointmentId',
    'attachmentName',
    'attachmentSize',
    'attachmentType',
    'body',
    'createdOn',
    'deliveryMethod',
    'endDate',
    'hasAttachment',
    'network',
    'noReply',
    'processedCount',
    'remainingCount',
    'repeating',
    'scheduledTime',
    'scheduleMessageCategory',
    'smsOptedOut',
    'startDate',
    'startTime',
    'status',
    'templateLabel',
    'timezoneName',
    'updatedOn',
  ],

  relations: {
    one: {
      createdBy: { type: 'user' },
      organization: { type: 'organization' },
      recipient: { type: ['distributionList', 'user'] },
      sender: { type: 'user' },
      template: { type: 'messageTemplate' },
      updatedBy: { type: 'user' },
    },
  },
});

export default ScheduledMessage;
