import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { ReactComponent as PrioritySvg } from '../images/priority-icon.svg';
import Modal from './Modal';

const classes = BEM.with('PriorityMessageModal');

class PriorityMessageModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const { closeModal, isOpen } = this.props;
    const imgHeader = {
      image: PrioritySvg,
      fill: '#fff',
      imgClassHeader: classes('img'),
    };
    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        hasCloseButton={false}
        header={'You are about to send a Priority Message'}
        headerClass={classes('header')}
        imgHeader={imgHeader}
        isOpen={isOpen}
        onRequestClose={closeModal}
        size={'medium-small'}
      >
        <div className={classes('priority-message-body')}>
          The recipient will receive a distinctive alert and multiple notifications to emphasize the
          urgency of the message.
        </div>
        <div
          aria-label={'Conversation Priority Modal Close'}
          onClick={closeModal}
          className={classes('priority-message-close-btn')}
        >
          GOT IT
        </div>
      </Modal>
    );
  }
}

export default mobxInjectSelect({
  modalStore: ['openModal'],
})(PriorityMessageModal);
