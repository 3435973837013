import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import PatientInfo from '../../../widgets/messenger/components/ConversationPane/PatientInfo';
import PatientConversationItem from '../../../widgets/messenger/components/Sidebar/PatientConversationItem';
import PatientConversationItemDetails from '../../../widgets/messenger/components/Sidebar/PatientConversationItemDetails';
import VideoCallButton from '../Call/VideoCallButton';
import RoundButton from '../RoundButton';
import { AvatarImage, Modal, Scrollbars, UserAvatar } from '../';
import { ReactComponent as MessageBubbleSvg } from '../../images/message-bubble.svg';
import PfCallOrigins from './../../../models/enums/PfCallOrigins';
import { PatientContactInfo } from './';

const classes = BEM.with('PatientProfileModal');

class PatientProfileModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    composeToEntity: PropTypes.func.isRequired,
    isPFVideoCallEnabled: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    options: PropTypes.shape({
      patient: PropTypes.shape({
        user: PropTypes.object,
        userId: PropTypes.string,
        smsOptedOut: PropTypes.bool,
        phoneNumber: PropTypes.string,
        contacts: PropTypes.array,
      }),
      conversations: PropTypes.array,
    }).isRequired,
  };

  static defaultProps = {
    options: {
      patient: {
        user: {},
        contacts: [],
      },
      conversations: [],
    },
  };

  render() {
    const { isPFVideoCallEnabled, isOpen, options } = this.props;
    const { conversations, patient } = options;
    const {
      user: patientBot = {},
      userId: patientId,
      smsOptedOut,
      phoneNumber,
      contacts = [],
    } = patient || {};
    const patientDisplayName = patientBot.displayName;

    let avatarFragment;
    if (smsOptedOut) {
      avatarFragment = (
        <AvatarImage
          className={classes()}
          displayName={patientDisplayName}
          entityType="singleProviderSmsOptedOut"
          forceDefault
          size={65}
        />
      );
    } else {
      avatarFragment = <UserAvatar size={65} user={patientBot} />;
    }

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        hasCloseButton={true}
        header={"Patient's Profile"}
        headerClass={classes('header')}
        isOpen={isOpen}
        onRequestClose={this._closeModal}
        size={'medium'}
      >
        <div className={classes('container')}>
          <Scrollbars autoHide={true} shouldScrollOverflow={true}>
            <div className={classes('info-container')}>
              <div className={classes('patient-header')}>
                {avatarFragment}
                <div className={classes('patient-info')}>
                  <div className={classes('patient-details')}>
                    <div
                      className={classes('patient-name', { smsOptedOut })}
                      title={patientDisplayName}
                    >
                      {patientDisplayName} (Patient)
                    </div>
                  </div>
                  <PatientInfo entity={patientBot} isCard isCenterName isInsideCard />
                </div>
              </div>
              <div className={classes('patient-actions')}>
                {!smsOptedOut && isPFVideoCallEnabled && (
                  <div className={classes('patient-action')}>
                    <VideoCallButton
                      origin={PfCallOrigins.PROFILE}
                      entity={{
                        recipientId: patientId,
                        recipientName: patientDisplayName,
                      }}
                    />
                  </div>
                )}
                {!smsOptedOut && (
                  <div className={classes('patient-action')}>
                    <RoundButton
                      className={classes('patient-action')}
                      color="#4a657b"
                      onClick={this._onMessagePatient}
                      img={MessageBubbleSvg}
                    />
                  </div>
                )}
              </div>
              <div className={classes('section')}>
                <div className={classes('section-header')}>Phone</div>
                <div className={classes('phone-number')}>{phoneNumber}</div>
                {smsOptedOut && (
                  <div className={classes('sms-opted-out-info')}>Opted out via SMS</div>
                )}
              </div>
              {contacts.length > 0 && (
                <div className={classes('section')}>
                  <div className={classes('section-header')}>Contacts</div>
                  {contacts.map((contact) => (
                    <PatientContactInfo
                      className={classes('patient-contact')}
                      key={contact.id}
                      contact={contact}
                    />
                  ))}
                </div>
              )}
            </div>
            {conversations.length > 0 && (
              <div className={classes('conversations-container')}>
                <div className={classes('conversations-header')}>Shared Conversations:</div>
                {conversations.map(this._getConversationTile)}
              </div>
            )}
          </Scrollbars>
        </div>
      </Modal>
    );
  }

  _closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  _getConversationTile = (conversation) => {
    const height = PatientConversationItemDetails.getHeight({
      conversation,
      currentRoles: [],
      getTypersForCounterParty: () => [],
      isPinned: false,
      isSelected: false,
    });

    return (
      <div
        className={classes('conversation-item-container')}
        onClick={this._closeModal}
        key={conversation.id}
        style={{ height }}
      >
        <PatientConversationItem
          conversation={conversation}
          isPinned={false}
          isSelected={false}
          isInsideCard={true}
          allowFocus={true}
          closeModal={this._closeModal}
        />
      </div>
    );
  };

  _onMessagePatient = () => {
    const { closeModal, composeToEntity, options } = this.props;
    const { patient } = options;
    const { user: patientBot } = patient || {};

    composeToEntity(patientBot, { origin: PfCallOrigins.PROFILE });
    closeModal();
  };
}

export default mobxInjectSelect({
  callStore: ['isPFVideoCallEnabled'],
  composeMessageStore: ['composeToEntity'],
})(PatientProfileModal);
