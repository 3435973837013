import React from 'react';
import iconFilePdf from '../../../widgets/messenger/images/iconFilePdf.png';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import { MessageAttachmentPropTypes } from '../../../types/';
import Spinner from '../Spinner';
import { IS_SAFARI } from '../../utils/browsers';
import AttachmentFileHeader from './AttachmentFileHeader';
import RetryAttachment from './RetryAttachment';

const classes = BEM.with('PdfAttachment');

type PdfAttachmentProps = {
  action: (url: string) => void;
  attachment: MessageAttachmentPropTypes;
  attachmentUrl: string;
  backupAction: () => void;
  isLoading: boolean;
  isMultiSelectMode: boolean;
  messageId: string;
  multiSelectable: boolean;
  printMode?: boolean;
  retryDownload: () => void;
};

type MobxProps = {
  isDesktopApp: boolean;
  supportsInlinePdf: boolean;
};

const PdfAttachment = ({
  action,
  attachment,
  attachmentUrl,
  backupAction,
  isDesktopApp,
  isLoading,
  isMultiSelectMode,
  messageId,
  multiSelectable,
  printMode,
  retryDownload,
  supportsInlinePdf,
}: PdfAttachmentProps & MobxProps) => {
  const { localPath } = attachment;
  const url = isLoading ? null : attachmentUrl || localPath;
  const isErrored = !url && !isLoading;
  let loaderFragment, pdfFragment;

  if ((isDesktopApp && !supportsInlinePdf) || IS_SAFARI || printMode) {
    return (
      <div className={classes({ type: 'document' })}>
        <div
          aria-label={`document-download-${attachment.name}`}
          className={classes('attachment-container')}
          onClick={isMultiSelectMode ? () => {} : backupAction}
        >
          <AttachmentFileHeader messageId={messageId} attachment={attachment} />
        </div>
      </div>
    );
  }

  if (url) {
    pdfFragment = (
      <div
        className={classes('attachment-container', {
          unselectable: isMultiSelectMode && !multiSelectable,
        })}
      >
        <iframe
          className={classes('pdf')}
          name={attachment.name}
          src={`${url}#view=FitBV&toolbar=0`}
          title={`pdfAttachmentIframe-${attachment.name}`}
        />
        <div
          aria-label={`open-preview-${attachment.name}`}
          className={classes('pdf-preview-overlay')}
          data-test-id={attachment.name}
          onClick={isMultiSelectMode ? () => {} : () => action(url)}
        />
        <img className={classes('pdf-icon')} src={iconFilePdf} alt="pdf" />
      </div>
    );
  } else if (isErrored) {
    loaderFragment = <RetryAttachment retry={retryDownload} />;
  } else {
    loaderFragment = (
      <div className={classes('loading')}>
        <span className={classes('downloading-indicator')}>
          <Spinner />
        </span>
      </div>
    );
  }

  return (
    <div className={classes({ type: 'pdf' })}>
      {loaderFragment}
      {pdfFragment}
    </div>
  );
};

export default mobxInjectSelect<PdfAttachmentProps, MobxProps>({
  desktopAppStore: ['isDesktopApp', 'supportsInlinePdf'],
})(PdfAttachment);
