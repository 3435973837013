import createMobxModel from './createMobxModel';

const Team = createMobxModel({
  name: 'team',

  fields: [
    'canMembersLeave',
    'canRequestToJoin',
    'description',
    'displayName',
    'groupId',
    'hasCurrentUserOrRole',
    'hasCurrentUserPending',
    'hasCurrentUserBeenDeclined',
    'memberCount',
    'memberIds',
  ],

  relations: {
    one: {
      group: { type: 'group' },
      tag: { type: 'tag' },
    },
    many: {
      members: { type: 'user' },
    },
  },
});

export default Team;
