import React from 'react';

import { StandardOrgSettingInput } from '../../../../shared-components/StandardSettingInput';

import { SettingContainer } from '../../../../shared-components/SettingContainer/SettingContainer';
import { mobxInjectSelect } from '../../../../../common/utils';
import { useAppSelector } from 'redux-stores';

type LoginWorkflowComponentProps = {
  isOTPLoginDisabled?: boolean;
};

type MobxProps = {
  featureFlags: Record<string, unknown>;
  getURLParams: () => Record<string, string>;
};

function LoginWorkflowComponent({
  isOTPLoginDisabled = false,
  featureFlags,
  getURLParams,
}: LoginWorkflowComponentProps & MobxProps) {
  const urlParams = getURLParams();
  const loginUsingOTPEnabled = useAppSelector(
    (state) => state.orgSettings.settings.otpAllowConfigure
  );
  const currentAuthType = useAppSelector((state) => state.orgSettings.settings.authType);
  const disableOTPFromDropdown = isOTPLoginDisabled || !loginUsingOTPEnabled;
  const disableDropdown = currentAuthType === 'saml' || currentAuthType === 'native';

  return (
    <div>
      {(urlParams.allowOTP || featureFlags?.allowOTP === true) && (
        <SettingContainer
          label={'Login Workflow'}
          subLabel={
            'Select a login experience for this authentication - Username and OTP requires the user to have previously logged in and setup their OTP'
          }
          settingControl={
            <StandardOrgSettingInput
              options={[
                { label: 'Username & Password', value: 'Username & Password' },
                {
                  label: 'Username & OTP',
                  value: 'Username & OTP',
                  disabled: disableOTPFromDropdown,
                },
              ]}
              settingName="orgLoginWorkflow"
              type="dropdown"
              disabled={disableDropdown}
            />
          }
        />
      )}
    </div>
  );
}

export default mobxInjectSelect<LoginWorkflowComponentProps, MobxProps>({
  featureStore: ['featureFlags', 'getURLParams'],
})(LoginWorkflowComponent);
