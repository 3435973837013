import { ServerValueEnum } from '../../utils/enum';

const MessagePriority = new ServerValueEnum(
  {
    LOW: { serverValue: -1 },
    NORMAL: { serverValue: 0 },
    HIGH: { serverValue: 1 },
  } as const,
  { defaultValue: 'NORMAL' }
);

export default MessagePriority;
