import React from 'react';
import AvatarImage from '../AvatarImage';
import BEM from '../../bem';

const classes = BEM.with('GroupAvatarDropzone');

export default function AvatarPreview({ avatarPreviewUrl }) {
  return (
    <AvatarImage
      className={classes('avatar-img')}
      avatarUrl={avatarPreviewUrl}
      entityType={'Group'}
      size="large"
    />
  );
}
