import React, { Component } from 'react';
import PropTypes from 'prop-types';

import propTypes from '../../propTypes';
import { mobxInjectSelect } from '../../utils';
import ImageAttachment from './ImageAttachment';
import VideoAttachment from './VideoAttachment';
import PdfAttachment from './PdfAttachment';
import AudioAttachment from './AudioAttachment';
import DocumentAttachment from './DocumentAttachment';

class MessageAttachment extends Component {
  static propTypes = {
    attachment: propTypes.messageAttachment.isRequired,
    currentMessageMultiSelectOption: PropTypes.string.isRequired,
    downloadAttachment: PropTypes.func.isRequired,
    downloadAttachmentUrl: PropTypes.func.isRequired,
    findAttachment: PropTypes.func.isRequired,
    getDownloadState: PropTypes.func.isRequired,
    isInlineImage: PropTypes.func.isRequired,
    isInlineVideo: PropTypes.func.isRequired,
    message: propTypes.message.isRequired,
    multiSelectable: PropTypes.bool.isRequired,
    openMessageAttachmentModal: PropTypes.func.isRequired,
  };

  state = {
    attachmentUrl: null,
  };

  componentDidMount() {
    this._downloadAttachmentUrl();
  }

  render() {
    const {
      attachment,
      currentMessageMultiSelectOption,
      getDownloadState,
      isInlineAudio,
      isInlineImage,
      isInlinePDF,
      isInlineVideo,
      message,
      multiSelectable,
      printMode,
    } = this.props;

    const attachmentDownloadState = getDownloadState(message.id, attachment.id);
    const attachmentProps = {
      attachment,
      attachmentUrl: this.state.attachmentUrl || attachmentDownloadState.url,
      isLoading: attachmentDownloadState.isLoading,
      isMultiSelectMode: !!currentMessageMultiSelectOption,
      message,
      messageId: message.id,
      multiSelectable,
    };

    if (isInlineImage(attachment)) {
      return (
        <ImageAttachment
          {...attachmentProps}
          action={this._openMessageAttachmentModal}
          retryDownload={this._downloadAttachmentUrl}
        />
      );
    } else if (isInlineVideo(attachment)) {
      return (
        <VideoAttachment
          {...attachmentProps}
          action={this._openMessageAttachmentModal}
          backupAction={this._downloadAttachment}
          retryDownload={this._downloadAttachmentUrl}
        />
      );
    } else if (isInlinePDF(attachment)) {
      return (
        <PdfAttachment
          {...attachmentProps}
          action={this._openMessageAttachmentModal}
          backupAction={this._downloadAttachment}
          retryDownload={this._downloadAttachmentUrl}
          printMode={printMode}
        />
      );
    } else if (isInlineAudio(attachment)) {
      return (
        <AudioAttachment
          {...attachmentProps}
          action={this._downloadAttachment}
          retryDownload={this._downloadAttachmentUrl}
        />
      );
    }
    return <DocumentAttachment {...attachmentProps} action={this._downloadAttachment} />;
  }

  _downloadAttachmentUrl = async () => {
    const {
      attachment,
      downloadAttachmentUrl,
      findAttachment,
      isInlineAudio,
      isInlineImage,
      isInlinePDF,
      isInlineVideo,
      message,
    } = this.props;
    const messageId = message.id;

    if (
      isInlineImage(attachment) ||
      isInlineVideo(attachment) ||
      isInlinePDF(attachment) ||
      isInlineAudio(attachment)
    ) {
      const attachmentUrl = await downloadAttachmentUrl(messageId, attachment.id);
      this.setState({ attachmentUrl });
    } else {
      await findAttachment(messageId, attachment.id);
    }
  };

  _openMessageAttachmentModal = (url) => {
    const { attachment, openMessageAttachmentModal, message } = this.props;
    const messageId = message.id;

    openMessageAttachmentModal({
      attachmentId: attachment.id,
      messageId,
      name: attachment.name,
      url,
      contentType: attachment.contentType,
      size: attachment.size,
    });
  };

  _downloadAttachment = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { attachment, downloadAttachment, message } = this.props;
    downloadAttachment(message.id, attachment.id);
  };
}

export default mobxInjectSelect({
  messageAttachmentStore: [
    'downloadAttachment',
    'downloadAttachmentUrl',
    'findAttachment',
    'getDownloadState',
    'isInlineAudio',
    'isInlineImage',
    'isInlinePDF',
    'isInlineVideo',
    'openMessageAttachmentModal',
  ],
  messengerStore: ['currentMessageMultiSelectOption'],
})(MessageAttachment);
