import { keyBy, omit } from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { Dispatch } from '@reduxjs/toolkit';
import TCClient from '../../../client';
import { SearchResult, Tag, SearchQueryOptions } from '../../../types';
import { SaveTagProps, ToggleSaveTagProps } from '../../../types/Collaboration';
import { actions, ReduxState } from '../../index';
import { entityServerId, standardTagId } from '../selectors';
import { fetchTotals } from '../entities/thunk';

type SaveTagEventData = {
  action: 'add' | 'del';
  id: string;
  organizationId: string;
  type: string;
};
export const handleSaveTagEvent = async (
  dispatch: Dispatch,
  organizationId: string,
  isRolesEnabled: boolean,
  isTeamsEnabled: boolean,
  ...args: unknown[]
) => {
  const { id, organizationId: eventOrgId } = args[0] as SaveTagEventData;
  if (!id || eventOrgId !== organizationId) return;
  fetchSavedTags(dispatch, organizationId, isRolesEnabled, isTeamsEnabled);
};

export const deleteTags = async (
  dispatch: Dispatch,
  ids: string[],
  tagsById: { [id: string]: Tag },
  organizationId: string
) => {
  await TCClient.tags.deleteTags(ids, organizationId);
  const idsToOmit = ids.map((i) => standardTagId(i, organizationId) as string);

  await new Promise((resolve) => setTimeout(resolve, 4000));
  await fetchTotals(dispatch, organizationId);

  dispatch(
    actions.saveTags({ tagsById: omit(tagsById, idsToOmit), replace: true, deleting: true })
  );
};

const formatTag = (tag: Tag, isRolesEnabled: boolean, isTeamsEnabled: boolean) => {
  const roleCount = isRolesEnabled ? tag.roleCount || 0 : 0;
  const teamCount = isTeamsEnabled ? tag.teamCount || 0 : 0;
  return omit({ ...tag, entityCount: roleCount + teamCount }, 'toPlainObject');
};

export const fetchTagsThunk = createAsyncThunk(
  'tags/fetchTagsStatus',
  async (options: { continuation?: string | undefined }, thunkAPI) => {
    const state = thunkAPI.getState() as ReduxState;
    const api = thunkAPI.extra as typeof TCClient;

    !options?.continuation && thunkAPI.dispatch(actions.setTagLoadingStatus(true));
    const colors = state.tags.filteredColors.map((c) => c.colorId);
    const searchOptions: SearchQueryOptions = {
      version: 'SEARCH_PARITY',
      query: {
        name: state.tags.tagSearchQuery || '',
      },
      ...(colors.length ? { colors } : null),
      continuation: options?.continuation,
      organizationId: state.collab.selectedOrgId,
      returnFields: ['color', 'color_value', 'name', 'role_count', 'team_count', 'token'],
      types: ['tag'],
      sort: ['name'],
    };
    const { results, metadata } = await api.search.query<SearchResult>(searchOptions);
    thunkAPI.dispatch(
      actions.saveTags({
        tagsById: keyBy(
          results.map((r: SearchResult) => {
            return formatTag(
              r.entity as Tag,
              state.collab.isRolesEnabled,
              state.collab.isTeamsEnabled
            );
          }),
          (r) => r.id
        ),
        replace: !options?.continuation,
        continuation: metadata?.continuation,
        dontPropagate: true,
      })
    );
    thunkAPI.dispatch(actions.setTagLoadingStatus(false));
  }
);

export const loadColors = async (dispatch: Dispatch, organizationId: string) => {
  const colors = await TCClient.tags.findColors(organizationId);
  dispatch(actions.saveColors(colors));
};

export const saveTag = async (dispatch: Dispatch, options: SaveTagProps) => {
  const { color, id, name, organizationId, tagsById } = options;
  const colorId = color.colorId;
  let tag: Tag;
  try {
    if (!id) {
      const newTagId = await TCClient.tags.createTag({ color: colorId, name, organizationId });
      tag = {
        color: color.colorValue.replace('0x', '#'),
        colorId: color.colorId,
        name,
        entityCount: 0,
        id: standardTagId(newTagId, organizationId) as string,
      };
    } else {
      await TCClient.tags.updateTag(id.split(':')[1], {
        color: colorId,
        name,
        organizationId,
      });
      const oldTag = tagsById[id] as Tag;
      tag = {
        ...oldTag,
        color: color.colorValue.replace('0x', '#'),
        colorId: color.colorId,
        name,
      };
    }

    dispatch(actions.setModal(undefined));
    dispatch(actions.saveTags({ tagsById: { ...tagsById, [tag.id]: tag }, isNewTag: !id }));
    !id && dispatch(actions.selectTag(tag));
    return true;
  } catch (e) {
    dispatch(actions.setTagModalError(true));
    return false;
  }
};

export const fetchTag = createAsyncThunk(
  'tags/fetchTag',
  async (options: { id: string }, thunkAPI) => {
    const { id } = options;
    const state = thunkAPI.getState() as ReduxState;
    const tagsById = state.tags.tagsById;
    const tag = await TCClient.tags.findTag(id, state.collab.selectedOrgId, {
      ignoreNotFound: true,
    });
    const formattedTag = formatTag(tag, state.collab.isRolesEnabled, state.collab.isTeamsEnabled);

    thunkAPI.dispatch(
      actions.saveTags({
        tagsById: { ...tagsById, [formattedTag.id]: formattedTag },
        isNewTag: false,
      })
    );
  }
);

export const fetchSavedTags = async (
  dispatch: Dispatch,
  organizationId: string,
  isRolesEnabled: boolean,
  isTeamsEnabled: boolean
) => {
  const tags = await TCClient.tags.findSavedTags(organizationId, { ignoreNotFound: true });
  tags &&
    dispatch(
      actions.setSavedTags(tags.map((t: Tag) => formatTag(t, isRolesEnabled, isTeamsEnabled)))
    );
};

export const toggleSaveTag = async (
  dispatch: Dispatch,
  { organizationId, savedTags, tag }: ToggleSaveTagProps
) => {
  let newSavedTags = savedTags;
  const savedTagIds = savedTags.map((t) => t.id);

  if (savedTagIds.includes(tag.id)) {
    await TCClient.tags.removeSavedTag(entityServerId(tag.id), organizationId);
    newSavedTags = newSavedTags.filter((x) => x.id !== tag.id);
  } else {
    await TCClient.tags.saveTag(entityServerId(tag.id), organizationId);
    newSavedTags = [...newSavedTags, tag];
  }

  dispatch(actions.setSavedTags(newSavedTags));
};
