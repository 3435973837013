import React from 'react';
import classNames from 'classnames';

import BEM from 'common/bem';
import { TeamAvatar, GroupAvatar, GroupName, PatientDetails } from 'common/components';
import CallButton from 'common/components/Call/CallButton';
import PfCallOrigins from 'models/enums/PfCallOrigins';
import propTypes from 'common/propTypes';
import { escalationStatusLine } from 'common/utils';
import { getGroupTag } from 'common/utils/getGroupTag';
import { PatientReferenceCard } from 'common/components/PatientContext';

const classes = BEM.with('ConversationHeader');

const PROVIDER_NETWORK_MEMBER_LIMIT = 10;
function GroupConversationHeader({
  conversation: { counterParty: group },
  isGroupVideoCallEnabled,
  isGroupVoiceCallEnabled,
}) {
  const { escalationExecution, groupType, memberCount, patientDetails, patientContextId } = group;
  const tag = getGroupTag(group);
  const isEscalated = !!escalationExecution;
  const isPatientCare = groupType === 'PATIENT_CARE';
  const isIntraTeam = groupType === 'INTRA_TEAM';
  const canMakeCall =
    (isGroupVideoCallEnabled || isGroupVoiceCallEnabled) &&
    groupType !== 'FORUM' &&
    memberCount <= PROVIDER_NETWORK_MEMBER_LIMIT;
  const memberNames = !group.members
    ? ''
    : ' with:' +
      group.members
        .filter(Boolean /*TODO members should never contain null*/)
        .map((u) => `\n- ${u.displayName}`)
        .join();

  const title = `${isEscalated ? 'Escalation Group' : 'Group conversation'}${memberNames}`;

  let escalationDetailsFragment;
  if (isEscalated) {
    const { status } = escalationExecution;
    const statusLine = escalationStatusLine(escalationExecution);

    escalationDetailsFragment = (
      <div className={classNames(classes('escalation-details'), classes({ status }))}>
        {statusLine}
      </div>
    );
  }

  let patientDetailsFragment;
  if (isPatientCare) {
    patientDetailsFragment = (
      <PatientDetails patient={patientDetails} className={classNames(classes('patient-details'))} />
    );
  }

  let groupAvatarFragment;
  if (isIntraTeam) {
    groupAvatarFragment = <TeamAvatar displayName={group.displayName} group={group} size="fit" />;
  } else {
    groupAvatarFragment = <GroupAvatar group={group} size="fit" />;
  }

  let tagFragment;
  if (tag) {
    tagFragment = (
      <div className={classes('tag-details')}>
        <div className={classes('tag-indicator')} style={{ backgroundColor: tag.color }} />
        <div className={classes('tag-name')}>{tag.name}</div>
      </div>
    );
  }

  return (
    <div className={classes()}>
      <div className={classes({ group: true, escalated: isEscalated })} title={title}>
        {groupAvatarFragment}
        <span className={classes('group-details', { isEscalated })}>
          <span
            className={classes('group-name', {
              isPatientCare,
              canMakeCallIsDisabled: !canMakeCall,
            })}
          >
            <GroupName group={group} ariaLabel={'Conversation Header Name'} />
            {tagFragment}
            {canMakeCall && (
              <span className={classes('phone-icon', { isPatientCare })} id={'conv-call'}>
                <CallButton entity={group} origin={PfCallOrigins.CONVERSATION} />
              </span>
            )}
          </span>
          {patientDetailsFragment}
          {escalationDetailsFragment}
        </span>
      </div>
      {patientContextId && (
        <div className={classes('group-patient-reference-card')}>
          <PatientReferenceCard patientContextId={patientContextId} key={group.id} />
        </div>
      )}
    </div>
  );
}

GroupConversationHeader.propTypes = {
  conversation: propTypes.conversation.isRequired,
};

export default GroupConversationHeader;
