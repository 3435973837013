import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../utils';
import { ReactComponent as PrioritySvg } from '../images/priority-icon.svg';

import BEM from '../bem';
import { Modal } from './';

const classes = BEM.with('FailureModal');

class FailureModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    failureModalErrorMessage: PropTypes.string,
    failedPhoneNumbersList: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  render() {
    const { failureModalErrorMessage, failedPhoneNumbersList, isOpen } = this.props;
    const imgHeader = {
      image: PrioritySvg,
      fill: '#db524b',
      imgClassHeader: classes('img'),
    };

    return (
      <Modal
        header={'Oh no.'}
        className={classes()}
        headerClass={classes('header')}
        closeClass={classes('close-button')}
        imgHeader={imgHeader}
        isOpen={isOpen}
        onRequestClose={this._closeModal}
        size={'small'}
        ariaLabelCloseButton="Close"
        role="alertdialog"
        ariaLabelledBy="dialog-message"
      >
        <div className={classes('text')} id="dialog-message">
          {failureModalErrorMessage}
        </div>
        {failedPhoneNumbersList.length > 0 &&
          failedPhoneNumbersList.map((phoneNumber) => {
            return <div className={classes('phoneNumberText')}>{phoneNumber}</div>;
          })}
        <div onClick={this._closeModal} className={classes('ok-btn-container')}>
          <button onClick={this._closeModal} className={classes('ok-btn')}>
            OK
          </button>
        </div>
      </Modal>
    );
  }

  _closeModal = () => {
    const { closeModal, openModal, options = {} } = this.props;
    const { reopenModal, optionsReopenModal = {} } = options;
    closeModal();

    if (reopenModal) openModal(reopenModal, optionsReopenModal);
  };
}

export default mobxInjectSelect({
  messageStore: ['failedPhoneNumbersList', 'failureModalErrorMessage'],
  modalStore: ['openModal'],
})(FailureModal);
