import createMobxModel from './createMobxModel';

export const TeamRequest = createMobxModel({
  name: 'teamRequest',

  fields: ['status'],

  relations: {
    one: {
      createdBy: { type: 'user' },
      team: { type: 'team' },
    },
  },
});

export default TeamRequest;
