import React, { Component } from 'react';
import PropTypes from 'prop-types';
import propTypes from 'common/propTypes';
import { mobxInjectSelect } from 'common/utils';
import BEM from 'common/bem';

import { UnreadBadgeCount } from 'common/components';
import { MenuItem, MenuItemList } from 'common/components/ContextMenu';

const classes = BEM.with('RosterFilterMenu');

const ALL_FILTER_OPTIONS = ['Inbox', 'Teams', 'Groups', 'Forums'];

class RosterFilterMenu extends Component {
  static propTypes = {
    currentOrganization: propTypes.organization,
    filterUnreadCount: PropTypes.number.isRequired,
    filterUnreadPriorityCount: PropTypes.number.isRequired,
    inactiveRosterUnreadCount: PropTypes.number.isRequired,
    inactiveRosterUnreadPriorityCount: PropTypes.number.isRequired,
    isUniversalTaggingAllowed: PropTypes.bool.isRequired,
    selectedFilter: PropTypes.string.isRequired,
    setFilter: PropTypes.func.isRequired,
  };

  render() {
    const {
      currentOrganization,
      filterUnreadPriorityCount,
      inactiveRosterUnreadCount,
      inactiveRosterUnreadPriorityCount,
      selectedFilter,
      setFilter,
    } = this.props;
    let { filterUnreadCount } = this.props;
    const rosterFilterOptions = ALL_FILTER_OPTIONS.filter(this._removeDisabledOptions);
    const showAlerts = !!currentOrganization && currentOrganization.showAlerts;
    const showPatientCare = !!currentOrganization && currentOrganization.showPatientCare;

    return (
      <MenuItemList className={classes()} selected={selectedFilter}>
        {rosterFilterOptions.map((option) => {
          let showUnreadCount = option === 'Inbox' && filterUnreadCount > 0;
          let showPriorityButton = option === 'Inbox' && filterUnreadPriorityCount > 0;

          if ((showAlerts || showPatientCare) && option === 'Inbox' && selectedFilter !== 'Inbox') {
            showUnreadCount = showUnreadCount || inactiveRosterUnreadCount > 0;
            showPriorityButton = showPriorityButton || inactiveRosterUnreadPriorityCount > 0;
            filterUnreadCount = filterUnreadCount + inactiveRosterUnreadCount;

            if (showAlerts) {
              filterUnreadCount += currentOrganization?.unreadAlertsCount || 0;
            }
          }

          return (
            <MenuItem
              className={classes('list-item')}
              key={option}
              onClick={() => setFilter(option)}
              isSelected={option === selectedFilter}
              ariaLabel={`Roster ${option}`}
            >
              <div className={classes('filter-text')}>{option}</div>
              {(showUnreadCount || showPriorityButton) && (
                <UnreadBadgeCount
                  className={classes('unread-count')}
                  count={filterUnreadCount}
                  priority={showPriorityButton}
                  usePriorityButton={true}
                />
              )}
            </MenuItem>
          );
        })}
      </MenuItemList>
    );
  }

  _removeDisabledOptions = (option) => {
    const { currentOrganization, isUniversalTaggingAllowed } = this.props;
    const { forumsEnabled = false, showTeams = false } = currentOrganization || {};

    if (
      (!forumsEnabled && option === 'Forums') ||
      ((!showTeams || isUniversalTaggingAllowed) && option === 'Teams')
    ) {
      return false;
    }

    return true;
  };
}

export default mobxInjectSelect({
  messengerStore: ['currentOrganization', 'isUniversalTaggingAllowed'],
  rosterStore: [
    'filterUnreadCount',
    'filterUnreadPriorityCount',
    'inactiveRosterUnreadCount',
    'inactiveRosterUnreadPriorityCount',
    'selectedFilter',
    'setFilter',
  ],
})(RosterFilterMenu);
