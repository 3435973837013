import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Time from 'tt-react-time';

const START_OF_YEAR = moment().startOf('year');

export default class Timestamp extends Component {
  static propTypes = {
    format: PropTypes.string,
    hasTimeInDateFormat: PropTypes.bool,
    titleFormat: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  };

  state = {
    now: new Date(),
  };

  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value;
  }

  render() {
    let { format, titleFormat } = this.props;
    const { value, hasTimeInDateFormat, ...restProps } = this.props;
    const { now } = this.state;

    if (!format) {
      const m = moment(value);
      const diffDays = m.diff(now, 'days');

      if (diffDays === 0) {
        format = 'h:mm A';
      } else if (diffDays > 1 && diffDays < 7) {
        format = 'dddd';
      } else if (!m.isBefore(START_OF_YEAR)) {
        format = 'MMM D';
        if (hasTimeInDateFormat) format += ', h:mm A';
      } else {
        format = 'M/DD/YY';
        if (hasTimeInDateFormat) format += ', h:mm A';
      }
      titleFormat = format;
    }

    return (
      <Time value={value} format={format} titleFormat={titleFormat || format} {...restProps} />
    );
  }
}
