import createMobxModel from './createMobxModel';

const Patient = createMobxModel({
  name: 'patient',

  fields: ['dob', 'editVersion', 'gender', 'mrn', 'phoneNumber', 'smsOptedOut'],

  relations: {
    one: {
      user: { type: 'user' },
    },
    many: {
      contacts: { type: 'patientContact' },
      sharedConversations: { type: 'conversation' },
    },
  },
});

export default Patient;
