import { useCallback, useEffect, useState } from 'react';

type RefNode = React.RefObject<Node>;

export const useIsHovered = (ref: RefNode) => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  useEffect(() => {
    const currentRef = ref.current;
    currentRef?.addEventListener('mouseenter', onMouseEnter);
    currentRef?.addEventListener('mouseleave', onMouseLeave);
    return () => {
      currentRef?.removeEventListener('mouseenter', onMouseEnter);
      currentRef?.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [onMouseEnter, onMouseLeave, ref]);

  return isHovered;
};
