import React, { Component } from 'react';
import classNames from 'classnames';
import BEM from '../../bem';
import Spinner from '../Spinner';

const classes = BEM.with('FormButton');

class Button extends Component {
  render() {
    const {
      ariaLabel,
      children,
      className,
      disabled,
      hasLoader = true,
      isWorking,
      type,
      'data-test-id': dataTestId = '',
    } = this.props;

    return (
      <button
        type={type}
        className={classNames(classes({ type }), className)}
        autoComplete="off"
        disabled={disabled ? 'disabled' : false}
        aria-label={ariaLabel}
        data-test-id={dataTestId}
      >
        <span className={classes('content')}>{children}</span>
        {isWorking && hasLoader && (
          <span className={classes('loader')}>
            <Spinner />
          </span>
        )}
      </button>
    );
  }
}

export default Button;
