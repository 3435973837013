import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { noop } from 'node-noop';
import BEM from '../../bem';
import overloadEvent from '../../utils/overloadEvent';
import AccessibleList from 'common/components/AccessibleList';

const classes = BEM.with('MenuItemList');

class MenuItemList extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    position: PropTypes.oneOf([
      'bottom',
      'left',
      'right',
      'top',
      'topinnerleft',
      'bottomright',
      'bottominnerleft',
      'bottomleft',
    ]),
    theme: PropTypes.string,
    title: PropTypes.string,
    accessibilityMode: PropTypes.bool,
    selectedOptionIndex: PropTypes.number,
  };

  static defaultProps = {
    onClick: noop,
    position: 'top',
    theme: 'horizontal',
    selectedOptionIndex: 0,
  };

  componentDidMount() {
    if (this.div && this.props.accessibilityMode) {
      const index = this.props.selectedOptionIndex >= 0 ? this.props.selectedOptionIndex : 0;
      this.div.getElementsByClassName('tc-MenuItemList__menu-item')[index]?.focus();
    }
  }

  _setRef = (ref) => {
    this.div = ref;
  };

  render() {
    const { children, className, onClick, position, theme, title, accessibilityMode } = this.props;

    return (
      <div className={classNames(className, classes({ position, theme }))}>
        <div className={classes('list-container')}>
          <AccessibleList
            className={classes('list')}
            focusableClasses={['.tc-MenuItemList__menu-item']}
            ref={this._setRef}
            direction="vertical"
            loop={false}
            accessibilityMode={accessibilityMode}
            focusStart={'first'}
            role={'menu'}
          >
            {title && <li className={classes('title-item', { title: true })}>{title}</li>}
            {React.Children.toArray(children)
              .filter(Boolean)
              .map((menuItem, i) => {
                const { className, 'data-selectable': dataSelectable } = menuItem.props;

                if (dataSelectable !== undefined && !dataSelectable) {
                  return React.cloneElement(menuItem, {
                    onClick: (e) => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                    },
                  });
                }

                return (
                  <li
                    className={classes('list-item')}
                    key={menuItem.key || `item-${i}`}
                    tabIndex={-1}
                  >
                    {React.cloneElement(menuItem, {
                      className: classNames('menu-item', className),
                      onClick: overloadEvent(menuItem, 'onClick', onClick),
                    })}
                  </li>
                );
              })}
          </AccessibleList>
        </div>
      </div>
    );
  }
}

export default observer(MenuItemList);
