import EventEmitter from 'events';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoginErrors, SignInStep } from '../../../models/enums';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import { Buttons, FieldGroup, Form, SubmitButton, TextField } from '../Form';

const classes = BEM.with('SignInForm');

class UsernameForm extends Component {
  static propTypes = {
    checkLoginError: PropTypes.string,
    accountsConnectedWithSamePhoneNumberErrMessage: PropTypes.string,
    events: PropTypes.instanceOf(EventEmitter).isRequired,
    isCheckLoginLoading: PropTypes.bool,
    isStaticRender: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    signInStep: PropTypes.string.isRequired,
    usernameFormClassName: PropTypes.string,
  };

  componentDidMount() {
    const { events } = this.props;
    events.on('setSignInStep', this._resetFormMaybe);
    this._focus();
  }

  componentDidUpdate() {
    this._focus();
  }

  componentWillUnmount() {
    const { events } = this.props;
    events.removeListener('setSignInStep', this._resetFormMaybe);
  }

  render() {
    const {
      checkLoginError,
      accountsConnectedWithSamePhoneNumberErrMessage,
      isCheckLoginLoading,
      isStaticRender,
      onSubmit,
      prefilledUserName,
      signInStep,
      usernameFormClassName,
      isFHIRLaunchSession,
      didFHIRLaunchFail,
    } = this.props;

    const isWorking = isStaticRender || isCheckLoginLoading;
    let className, errorAriaLabel, errMsg;

    if (isStaticRender) {
      className = usernameFormClassName;
    } else {
      className = signInStep === SignInStep.USERNAME ? undefined : classes('hidden');
    }

    if (checkLoginError === LoginErrors.MAGIC_LOGIN_FAILED) {
      errorAriaLabel = 'unknown error';
      errMsg = 'Oh no. Something went wrong, please try again.';
    } else if (checkLoginError === LoginErrors.ACCOUNTS_CONNECTED_WITH_SAME_PHONE_NUMBER) {
      errorAriaLabel = 'accounts connected with one phone number';
      errMsg = accountsConnectedWithSamePhoneNumberErrMessage;
    } else if (isFHIRLaunchSession && didFHIRLaunchFail) {
      errorAriaLabel = 'fhir launch session failed';
      errMsg = 'We had trouble logging you in automatically, please enter your credentials.';
    } else {
      errorAriaLabel = 'incorrect email';
      errMsg =
        'Contact your helpdesk or supervisor to submit a request for access to TigerConnect.';
    }

    const isAccountError = [
      'incorrect email',
      'accounts connected with one phone number',
      'fhir launch session failed',
    ].includes(errorAriaLabel);

    return (
      <div id="tc-SignInForm__UsernameForm" className={className}>
        <Form
          className={classes('form')}
          columns={2}
          isWorking={isWorking}
          onSubmit={onSubmit}
          ref={this._setForm}
          theme="rows"
        >
          <div className={classes('error')}>
            {(checkLoginError || (isFHIRLaunchSession && didFHIRLaunchFail)) && (
              <div
                aria-label={errorAriaLabel}
                className={isAccountError ? classes('accountError') : ''}
                data-test-id="incorrect-email-error"
              >
                {errMsg}
              </div>
            )}
          </div>

          <FieldGroup className={classes('field-group')}>
            <TextField
              ariaLabel="username"
              className={checkLoginError && classes('field-group-error')}
              data-test-id={'username-input'}
              disabled={isStaticRender}
              inputRef={this._setInput}
              name="username"
              value={prefilledUserName}
              placeholder="Email or Username"
            />
          </FieldGroup>

          <Buttons>
            <SubmitButton
              ariaLabel="submit username"
              className={classes('submit')}
              data-test-id={'continue-button'}
              disabled={isWorking}
              isWorking={isWorking}
            >
              {isWorking ? 'LOADING' : 'LOGIN'}
            </SubmitButton>
          </Buttons>
        </Form>
      </div>
    );
  }

  _resetFormMaybe = (signInStep) => {
    if (signInStep === SignInStep.USERNAME) {
      this.form.reset();
    }
  };

  _setForm = (ref) => {
    this.form = ref;
  };

  _setInput = (ref) => {
    this.input = ref;
  };

  _focus = () => {
    this.input.focus();
  };
}

export default mobxInjectSelect({
  messengerStore: ['isStaticRender'],
  sessionStore: [
    'accountsConnectedWithSamePhoneNumberErrMessage',
    'events',
    'checkLoginError',
    'isCheckLoginLoading',
    'isSignInLoading',
    'prefilledUserName',
    'signInStep',
  ],
  staticStore: ['usernameFormClassName'],
  launchStore: ['isFHIRLaunchSession', 'didFHIRLaunchFail'],
})(UsernameForm);
