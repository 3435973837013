import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ReactComponent as CancelIcon } from '../../images/cancel-icon.svg';
import { ReactComponent as CancelIconHovered } from '../../images/cancel-icon-hover.svg';
import BEM from '../../bem';

const classes = BEM.with('RemoveChangesButton');

class RemoveChangesButton extends Component {
  static propTypes = {
    removeChanges: PropTypes.func.isRequired,
  };

  state = {
    hovered: false,
  };

  render() {
    const { removeChanges } = this.props;
    const { hovered } = this.state;

    return (
      <button
        className={classes()}
        onMouseOver={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        onClick={removeChanges}
        aria-label="Remove changes"
      >
        {hovered ? <CancelIconHovered aria-hidden="true" /> : <CancelIcon aria-hidden="true" />}
      </button>
    );
  }
}

export default observer(RemoveChangesButton);
