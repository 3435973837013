import React, { useState } from 'react';

import TCClient from '../../../client';
import { useVWRContext } from '../../../contexts/VirtualWaitingRoom';
import BEM from '../../bem';
import Modal from '../Modal';
import DotsIndicator from '../DotsIndicator';
import RecipientSearchPicker from '../RecipientSearchPicker';
import { mobxInjectSelect } from '../../utils';
import { User } from '../../../types';

const classes = BEM.with('InformProviderModal');

type InformProviderModalProps = {
  isOpen: boolean;
  onClose: ({ informSuccess }: { informSuccess: boolean }) => void;
};

type MobxProps = {
  currentOrganization: Record<string, unknown>;
};

const InformProviderModal: React.FC<InformProviderModalProps & MobxProps> = ({
  currentOrganization,
  isOpen,
  onClose,
}) => {
  const {
    dispatch,
    state: {
      mobxProps: { currentOrganizationId },
      selectedRoom,
      visitors: { selectedVisitor },
    },
  } = useVWRContext();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedProvider, setSelectedProvider] = useState<User>();

  const onChange = (selected?: User) => {
    if (selected) {
      setSelectedProvider(selected);
    }
  };

  const handleSubmitWrapper = async () => {
    if (selectedProvider && selectedVisitor && selectedRoom) {
      try {
        setIsSubmitting(true);

        const data = {
          accountToken: selectedProvider.id,
          roomId: selectedRoom?.id,
          visitId: selectedVisitor?.id,
          visitorName: selectedVisitor?.visitorFullName,
          vwrConversationId: selectedVisitor?.conversation.id,
        };

        const { vwrCallId } = await TCClient.virtualWaitingRoom.informProvider(
          currentOrganizationId,
          data
        );

        const updatedVisitor = {
          ...selectedVisitor,
          activeCall: { id: vwrCallId },
        };

        dispatch({ type: 'UPDATE_SELECTED_VISITOR', payload: updatedVisitor });

        onCloseWrapper({ informSuccess: true });
      } catch {
        console.error('Failed to invite provider');
        onCloseWrapper({ informSuccess: false });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const onCloseWrapper = ({ informSuccess }: { informSuccess: boolean }) => {
    setSelectedProvider(undefined);
    onClose({ informSuccess });
  };

  return (
    <Modal
      hasCloseButton={false}
      ariaLabelCloseButton={'Cancel Invite'}
      ariaLabelHeader={'Inform Provider Header'}
      bodyClass={classes('body')}
      header={'Send Invite'}
      headerClass={classes('header')}
      isOpen={isOpen}
      size={'medium-large'}
      footerClass={classes('footer')}
      footerPrimaryActions={
        <button
          type="button"
          aria-label={'Confirm Provider to Inform'}
          className={classes('invite-btn', { isSubmitting })}
          disabled={isSubmitting}
          onClick={handleSubmitWrapper}
        >
          {isSubmitting ? <DotsIndicator color={'#ffffff'} size={13} /> : 'INVITE'}
        </button>
      }
      footerSecondaryActions={
        <button
          type="button"
          aria-label={'Cancel Invite'}
          className={classes('cancel-btn')}
          onClick={() => onCloseWrapper({ informSuccess: false })}
        >
          CANCEL
        </button>
      }
      className={classes()}
      onRequestClose={() => onCloseWrapper({ informSuccess: false })}
    >
      <div>
        <RecipientSearchPicker
          ariaLabelItem={'Virtual Waiting Room Select Provider'}
          ariaLabelSelect={'Virtual Waiting Room Add Provider'}
          autoFocus
          className="inform-provider"
          excludeRoles
          hotkeyOptions={{ component: 'div', isFlex: false }}
          multi={false}
          onChange={onChange}
          onFocus={() => setSelectedProvider(undefined)}
          organization={currentOrganization}
          placeholder={'Select a provider'}
          searchTypes={['user']}
          selected={selectedProvider}
          shouldAutoBlur
          shouldShowSelectedValues={true}
          tabIndex={1}
        />
      </div>
    </Modal>
  );
};

export default mobxInjectSelect<InformProviderModalProps, MobxProps>({
  messengerStore: ['currentOrganization'],
})(InformProviderModal);
