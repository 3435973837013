import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { ReactComponent as MessageBubbleSvg } from '../../images/message-bubble.svg';

const classes = BEM.with('MessageButton');

class MessageButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    const { className } = this.props;

    return (
      <button className={classNames(className, classes())} onClick={this._onClick}>
        <MessageBubbleSvg />
        Message
      </button>
    );
  }

  _onClick = () => {
    const { onClick } = this.props;
    if (onClick) onClick();
  };
}

export default observer(MessageButton);
