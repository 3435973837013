import React, { Component } from 'react';
import { SpringSystem } from 'rebound';
import { Scrollbars } from './';

export default class SpringScrollbars extends Component {
  componentDidMount() {
    this.springSystem = new SpringSystem();
    this.spring = this.springSystem.createSpring();
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate });
  }

  componentWillUnmount() {
    this.springSystem.deregisterSpring(this.spring);
    this.springSystem.removeAllListeners();
    this.springSystem = undefined;
    this.spring.destroy();
    this.spring = undefined;
  }

  _setScrollbars = (ref) => {
    this.scrollbars = ref;
  };

  getScrollTop() {
    return this.scrollbars.getScrollTop();
  }

  getScrollHeight() {
    return this.scrollbars.getScrollHeight();
  }

  getHeight() {
    return this.scrollbars.getHeight();
  }

  scrollTop(top, { shouldSpring = true } = {}) {
    const { scrollbars } = this.scrollbars;
    if (shouldSpring) {
      const scrollTop = scrollbars.getScrollTop();
      this.spring.setCurrentValue(scrollTop).setAtRest();
      this.spring.setEndValue(top);
    } else {
      scrollbars.scrollTop(top);
    }
  }

  handleSpringUpdate = (spring) => {
    const { scrollbars } = this.scrollbars;
    const val = spring.getCurrentValue();
    scrollbars.scrollTop(val);
  };

  render() {
    return <Scrollbars {...this.props} ref={this._setScrollbars} />;
  }
}
