import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { once } from 'ramda';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import Modal from './Modal';

const classes = BEM.with('DeleteConversationModal');

class DeleteConversationModal extends Component {
  static propTypes = {
    currentDeleteConversation: propTypes.conversation,
    hideDeleteConversationModal: PropTypes.func.isRequired,
    removeConversation: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const { currentDeleteConversation } = this.props;
    const { counterParty } = currentDeleteConversation || {};
    const displayName = counterParty ? counterParty.displayName : null;

    return (
      <Modal
        ariaLabelCloseButton="Delete Conversation Close"
        ariaLabelHeader="Delete Conversation Header"
        bodyClass={classes('body')}
        closeClass={classes('close-button')}
        header="Delete Conversation"
        headerClass={classes('header')}
        isOpen={!!currentDeleteConversation}
        size={'medium-small'}
        footerPrimaryActions={
          <button
            aria-label="Delete Conversation Delete"
            className={classes('delete-btn')}
            onClick={once(this._removeConversation)}
            type="button"
          >
            DELETE
          </button>
        }
        footerSecondaryActions={
          <button
            aria-label="Delete Conversation Cancel"
            className={classes('cancel-btn')}
            onClick={this._closeModal}
            type="button"
          >
            CANCEL
          </button>
        }
        className={classes()}
        onRequestClose={this._closeModal}
      >
        <div aria-label="Delete Conversation Info" className={classes('info')}>
          Are you sure you want to delete your conversation with {displayName}?
        </div>
      </Modal>
    );
  }

  _closeModal = () => {
    const { closeModal, hideDeleteConversationModal } = this.props;
    hideDeleteConversationModal();
    closeModal();
  };

  _removeConversation = () => {
    const { currentDeleteConversation, removeConversation } = this.props;
    this._closeModal();
    removeConversation(currentDeleteConversation);
  };
}

export default mobxInjectSelect({
  conversationStore: [
    'currentDeleteConversation',
    'hideDeleteConversationModal',
    'removeConversation',
  ],
})(DeleteConversationModal);
