import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { PrintMessageItem } from './';

const classes = BEM.with('PrintMessageList');

class PrintMessageList extends Component {
  static propTypes = {
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        body: PropTypes.string,
      })
    ).isRequired,
  };

  render() {
    const { messages } = this.props;

    return (
      <div className={classes()}>
        {messages.map((message) => (
          <PrintMessageItem key={message.id} message={message} />
        ))}
        <span className={classes('brand')}>TigerConnect</span>
      </div>
    );
  }
}

export default observer(PrintMessageList);
