import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import LogoutButton from './LogoutButton';

const classes = BEM.with('ProfileMenu');

class ProfileMenu extends Component {
  static propTypes = {
    expertMode: PropTypes.bool.isRequired,
    isCallingAvailable: PropTypes.func.isRequired,
    scrollToElement: PropTypes.func.isRequired,
  };

  render() {
    const { isCallingAvailable, expertMode } = this.props;

    return (
      <div className={classes()}>
        <div className={classes('item-list')}>
          <div className={classes('item')}>
            <span onClick={this._scrollToElement('ProfileInfo')}>PROFILE INFO</span>
          </div>
          <div className={classes('item')}>
            <span onClick={this._scrollToElement('StatusSettings')}>STATUS SETTINGS</span>
          </div>
          <div className={classes('item')}>
            <span onClick={this._scrollToElement('AccountSettings')}>ACCOUNT SETTINGS</span>
          </div>
          <div className={classes('item')}>
            <span onClick={this._scrollToElement('DASettings')}>DESKTOP APP SETTINGS</span>
          </div>
          {isCallingAvailable && (
            <div className={classes('item')}>
              <span onClick={this._scrollToElement('CallingPreferences')}>CALLING PREFERENCES</span>
            </div>
          )}
          {expertMode ? (
            <div className={classes('item')}>
              <span onClick={this._scrollToElement('ExpertSettings')}>EXPERT SETTINGS</span>
            </div>
          ) : null}
          <div className={classes('item')}>
            <span onClick={this._scrollToElement('About')}>ABOUT</span>
          </div>
          <LogoutButton />
        </div>
      </div>
    );
  }

  _scrollToElement = (element) => {
    const { scrollToElement } = this.props;
    return () => scrollToElement(element);
  };
}

export default mobxInjectSelect({
  callStore: ['isCallingAvailable'],
  localStore: ['expertMode'],
  profileStore: ['scrollToElement'],
})(ProfileMenu);
