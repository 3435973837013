import React from 'react';
import clsx from 'clsx';
import styles from './Checkbox.module.css';

export interface CheckboxProps {
  /**
   * Optional click handler
   */
  onClick?: (selectedOption: boolean) => void;
  checked?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  customStyles?: Record<string, string>;
  label?: string;
  multiple?: boolean;
  isLabelClickable?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  onClick,
  checked = false,
  dataTestId,
  disabled = false,
  customStyles = {},
  label,
  multiple = false,
  isLabelClickable = false,
}) => {
  return (
    <div
      className={clsx(styles.root, { [styles.checkboxMargin]: multiple })}
      onClick={() => (isLabelClickable ? onClick?.(!checked) : undefined)}
    >
      <input
        id={label}
        disabled={disabled}
        style={customStyles}
        type="checkbox"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          return isLabelClickable ? undefined : onClick && onClick(e.target.checked);
        }}
        checked={checked}
        data-test-id={dataTestId}
      />
      {label && <span className={styles.label}>{label}</span>}
    </div>
  );
};
