import React from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import Modal from './Modal';

const classes = BEM.with('DeleteBroadcastList');

const DeleteBroadcastList = ({
  closeModal,
  deleteBroadcastList,
  deleteBroadcastLists,
  isOpen,
  options,
}) => {
  const { selected, onClose } = options;

  const deleteSelected = async (selected) => {
    if (selected.length === 1) {
      await deleteBroadcastList(selected[0]);
    } else {
      await deleteBroadcastLists(selected);
    }
    if (onClose) {
      onClose();
    }
    closeModal();
  };

  return (
    <Modal
      bodyClass={classes('body')}
      closeClass={classes('close-button')}
      footerClass={classes('footer')}
      header={'Confirm Delete'}
      headerClass={classes('header')}
      isOpen={isOpen}
      size={'medium'}
      footerPrimaryActions={
        <button
          type="button"
          className={classes('delete-btn')}
          onClick={() => deleteSelected(selected)}
        >
          DELETE {selected.length > 1 && `(${selected.length})`}
        </button>
      }
      footerSecondaryActions={
        <button type="button" onClick={closeModal} className={classes('cancel-btn')}>
          CANCEL
        </button>
      }
      className={classes()}
      onRequestClose={closeModal}
    >
      <div className={classes('info')}>
        Are you sure you want to delete the Broadcast List
        {selected.length > 1 && 's'}?
      </div>
    </Modal>
  );
};

DeleteBroadcastList.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteBroadcastList: PropTypes.func.isRequired,
  deleteBroadcastLists: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
};

export default mobxInjectSelect({
  patientAdminStore: ['deleteBroadcastLists', 'deleteBroadcastList'],
})(DeleteBroadcastList);
