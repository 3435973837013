import React, { useRef, useState } from 'react';

import { ColoredText } from '../../../shared-components/ColoredText/ColoredText';
import { CtaButton } from '../../../shared-components/CtaButton/CtaButton';
import { Input } from '../../../shared-components/Input/Input';
import { ColorInput } from '../../../shared-components/ColorInput/ColorInput';
import { ViewContainer } from '../../../shared-components/View/ViewContainer/ViewContainer';
import insertAtCaret from '../../../utils/insertAtCaret';
import { FileInput } from '../../../shared-components/FileInput/FileInput';

import styles from '../OrganizationSettings.module.css';

import { TabProps } from './';
import { actions, thunk, useAppDispatch, useAppSelector } from 'redux-stores';

export default function CustomizedFD({ onClose }: TabProps) {
  const dispatch = useAppDispatch();
  const {
    isCustomFastDeployEnabled,
    customFastDeployAppleStoreLink,
    customFastDeployGoogleStoreLink,
    customFastDeployHeaderColor,
    customFastDeployTextColor,
    customFastDeployLogo,
    customFastDeploySMSText = '',
  } = useAppSelector((state) => ({
    isCustomFastDeployEnabled: state.orgSettings.settings.isCustomFastDeployEnabled,
    customFastDeployAppleStoreLink: state.orgSettings.settings.customFastDeployAppleStoreLink,
    customFastDeployGoogleStoreLink: state.orgSettings.settings.customFastDeployGoogleStoreLink,
    customFastDeployHeaderColor: state.orgSettings.settings.customFastDeployHeaderColor,
    customFastDeployTextColor: state.orgSettings.settings.customFastDeployTextColor,
    customFastDeployLogo: state.orgSettings.settings.customFastDeployLogo,
    customFastDeploySMSText: state.orgSettings.settings.customFastDeploySMSText,
  }));
  const [smsAndEmailSubjectInput, setSmsAndEmailSubjectInput] = useState(customFastDeploySMSText);
  const [headerTextColor, setHeaderTextColor] = useState(customFastDeployTextColor);
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState(customFastDeployHeaderColor);
  const [appleStoreLinkBuffer, setAppleStoreLinkBuffer] = useState(customFastDeployAppleStoreLink);
  const [googleStoreLinkBuffer, setGoogleStoreLinkBuffer] = useState(
    customFastDeployGoogleStoreLink
  );
  const [logoBuffer, setLogoBuffer] = useState(customFastDeployLogo);
  const subjectInputRef = useRef<HTMLInputElement | null>(null);

  function handleInsertName(insertTextType: string) {
    if (subjectInputRef?.current) {
      const str = insertAtCaret(subjectInputRef.current, insertTextType);
      if (str.length > 100) return;
      setSmsAndEmailSubjectInput(str);
    }
  }

  async function handleCustomFastDeployEnablement() {
    dispatch(
      actions.updateSettings({
        isCustomFastDeployEnabled: !isCustomFastDeployEnabled,
        customFastDeployLogo: undefined,
      })
    );
    await thunk.saveSettings({ dispatch });
    await thunk.fetchSettings(dispatch);
  }

  async function handleSave() {
    dispatch(
      actions.updateSettings({
        isCustomFastDeployEnabled: true,
        customFastDeployAppleStoreLink: appleStoreLinkBuffer,
        customFastDeployGoogleStoreLink: googleStoreLinkBuffer,
        customFastDeployHeaderColor: headerBackgroundColor,
        customFastDeployTextColor: headerTextColor,
        customFastDeployLogo: logoBuffer?.replace(/.+?base64,/, ''),
        customFastDeploySMSText: smsAndEmailSubjectInput,
      })
    );
    await thunk.saveSettings({ dispatch });
  }

  function handleLogoFileInput(files: FileList) {
    const reader = new FileReader();
    reader.onload = (file) => {
      setLogoBuffer(file.target?.result?.toString());
    };
    reader.readAsDataURL(files[0]);
  }

  return (
    <ViewContainer
      {...{
        header: 'Customized Fast Deploy',
        onClose,
      }}
    >
      <div>
        <div className={styles.subHeaderMissing}>
          <CtaButton
            primary
            size={'large'}
            label={`${isCustomFastDeployEnabled ? 'Disable' : 'Enable'} Customized FD`}
            onClick={handleCustomFastDeployEnablement}
            dataTestId="enable-or-disable-custom-fd"
          />
        </div>
        {isCustomFastDeployEnabled && (
          <>
            <div className={styles.flexList}>
              <div>
                <div>
                  <div className={styles.listHeader}>
                    SMS and Email Subject Text Format
                    <div className={styles.orgTypeLabel}>
                      <ColoredText
                        text={`${smsAndEmailSubjectInput?.length}/100`}
                        color={
                          smsAndEmailSubjectInput?.length > 49 &&
                          smsAndEmailSubjectInput?.length < 75
                            ? 'yellow'
                            : smsAndEmailSubjectInput?.length > 74 &&
                              smsAndEmailSubjectInput?.length < 101
                            ? 'red'
                            : 'green'
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.linkText}>
                    <Input
                      value={smsAndEmailSubjectInput === 'null' ? '' : smsAndEmailSubjectInput}
                      onInputChange={(e) => setSmsAndEmailSubjectInput(e.target.value)}
                      inputRef={subjectInputRef}
                      lengthLimit={100}
                      dataTestId="smsEmailFormat"
                    />
                  </div>
                  <div className={styles.flexListStyle}>
                    <CtaButton
                      isInsertButton={true}
                      label="Insert <Sender Name>"
                      dataTestId="insert-sender-name-to-sms-and-email-input"
                      onClick={() => handleInsertName('{{sender}}')}
                    />
                    <CtaButton
                      isInsertButton={true}
                      label="Insert <Organization Name>"
                      dataTestId="insert-org-name-to-sms-and-email-input"
                      onClick={() => handleInsertName('{{org_name}}')}
                    />
                  </div>
                </div>
                <div>
                  <div className={styles.listHeader}> Apple App Store Link </div>
                  <div className={styles.linkText}>
                    <Input
                      value={appleStoreLinkBuffer}
                      onInputChange={(e) => setAppleStoreLinkBuffer(e.target.value)}
                      dataTestId="appleAppLink"
                    />{' '}
                  </div>
                </div>
                <div>
                  <div className={styles.listHeader}> Google Play Store Link</div>
                  <div className={styles.linkText}>
                    <Input
                      value={googleStoreLinkBuffer}
                      onInputChange={(e) => setGoogleStoreLinkBuffer(e.target.value)}
                      dataTestId="googlePlayLink"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.flexListWithDirectionAndMargin}>
                <div>
                  <div className={styles.listHeader}> Header Background Color </div>
                  <div className={styles.listContentBackground}>
                    <div className={styles.listContent}>
                      <ColorInput
                        value={headerBackgroundColor}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setHeaderBackgroundColor(e.target.value)
                        }
                        dataTestId="backgroundColor"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.marginsContainer}>
                  <div className={styles.listHeader}> Header Text Color </div>
                  <div className={styles.listContentBackground}>
                    <div className={styles.listContent}>
                      <ColorInput
                        value={headerTextColor}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setHeaderTextColor(e.target.value)
                        }
                        dataTestId="textColor"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.listHeader}> Logo </div>
                  <div className={styles.fileFont}>
                    <FileInput onInputChange={handleLogoFileInput} />
                    {logoBuffer && <img src={logoBuffer} height="100" alt="custom fd logo" />}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.marginsContainer}>
              <CtaButton
                primary
                size={'large'}
                label="Save"
                onClick={handleSave}
                dataTestId="custom-fd-save-button"
              />
            </div>
          </>
        )}
      </div>
    </ViewContainer>
  );
}
