import { observable } from 'mobx';
import createMobxModel from './createMobxModel';

const User = createMobxModel({
  name: 'user',

  fields: [
    '$placeholder',
    'avatarUrl',
    'contactsMessagingBlocked',
    'deleteOnRead',
    'department',
    'displayName',
    'distinguishedName',
    'dnd',
    'dndAutoForwardEntities',
    'dndText',
    'dndExpireAt',
    'emails',
    'extAuth',
    'firstName',
    'incomingCallNotification',
    'incomingCallSound',
    'isAlertsUser',
    'isPatient',
    'isPatientContact',
    'isRoleBot',
    'isVisitor',
    'lastName',
    'messageAnyoneRecipient',
    'mfaEnabled',
    'organizationIds',
    'patientBroadcastListDetails',
    'persistUsername',
    'presenceStatus',
    'removedFromOrg',
    'shouldDisplay',
    'status',
    'timeToLive',
    'title',
    'username',
  ],

  objectFields: {
    profileByOrganizationId: {
      clone(entity, { entityStore }) {
        const result = {};
        for (const [organizationId, inEntry] of Object.entries(entity)) {
          const {
            autoForwardReceivers,
            conversationId,
            department,
            dndAutoForwardEntities,
            title,
            userId,
          } = inEntry;
          const id = `${userId}:${organizationId}`;
          result[organizationId] = entityStore.userProfile._sync({
            $entityType: 'userProfile',
            autoForwardReceivers,
            conversationId,
            department,
            dndAutoForwardEntities,
            id,
            organizationId,
            title,
            userId,
          });
        }
        return observable.object(result);
      },
    },
  },

  relations: {
    one: {
      botRole: { type: 'role' },
      patient: { type: 'patient' },
      patientContact: { type: 'patientContact' },
    },
    many: {
      roles: { type: 'role' },
    },
  },
});

export default User;
