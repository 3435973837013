import React, { useState } from 'react';

import { RadioInput } from '../../../../shared-components/RadioInput/RadioInput';
import { StandardOrgSettingInput } from '../../../../shared-components/StandardSettingInput';
import styles from '../../OrganizationSettings.module.css';
import LoginWorkflowComponent from './LoginWorkflowComponent';
import { actions, useAppDispatch } from 'redux-stores';

export const SamlComponent = () => {
  const dispatch = useAppDispatch();
  const [isCustomClaim, setIsCustomClaim] = useState(false);

  return (
    <div>
      <LoginWorkflowComponent isOTPLoginDisabled={true} />
      <div className={styles.labelStyle}>
        <div className={styles.flexListStyleWithPadding}>
          <div className={styles.mainLabelStyle}>ISP Metadata URL</div>
          <div className={styles.inputBoxStyle}>
            <StandardOrgSettingInput settingName="authSamlIspMetadataUrl" type="input" />
          </div>
        </div>
        <div className={styles.flexListStyleWithPadding}>
          <div className={styles.mainLabelStyle}>ISP Certificate Fingerprint</div>
          <div className={styles.inputBoxStyle}>
            <StandardOrgSettingInput settingName="authSamlIspFingerprint" type="input" />
          </div>
        </div>
        <div className={styles.flexListStyleWithPadding}>
          <div className={styles.labelStyle}>
            <div className={styles.mainLabelStyle}>Default Session Length</div>
            <div className={styles.sideLabelStyle}>
              Note: This is an optional field and only applicable if one is not provided by the ISP
            </div>
          </div>
          <div className={styles.inputBoxStyle}>
            <StandardOrgSettingInput settingName="authSamlTtl" type="input" />
          </div>
          <div className={styles.helpText}>Must be greater than 60</div>
        </div>
        <div className={styles.flexListStyleWithPadding}>
          <div className={styles.mainLabelStyle}>Assertion User ID</div>
          <RadioInput
            label={'Name ID'}
            name={'isCustomClaim'}
            onRadioClick={() => {
              setIsCustomClaim(false);
              dispatch(actions.updateSettings({ authSamlAssertionId: 'name_id' }));
            }}
            selectedValue={`${isCustomClaim}`}
            value={'false'}
          />
          <RadioInput
            label={'Custom Claim'}
            name={'isCustomClaim'}
            onRadioClick={() => {
              setIsCustomClaim(true);
              dispatch(actions.updateSettings({ authSamlAssertionId: '' }));
            }}
            selectedValue={`${isCustomClaim}`}
            value={'true'}
          />
          {isCustomClaim && (
            <div className={styles.inputBoxStyle}>
              <StandardOrgSettingInput settingName="authSamlAssertionId" type="input" />
            </div>
          )}
        </div>
        <div className={styles.flexListStyleWithPadding}>
          <div className={styles.mainLabelStyle}>TigerConnect ID</div>
          <StandardOrgSettingInput
            settingName="authSamlTcId"
            type="radio"
            options={[
              { label: 'Email Address', value: 'email' },
              { label: 'Username', value: 'username' },
              { label: 'Phone', value: 'phone' },
            ]}
          />
        </div>
        <div className={styles.flexListStyleWithPadding}>
          <div className={styles.mainLabelStyle}>Auth URL</div>
          <StandardOrgSettingInput settingName="authSamlUrl" type="input" disabled />
        </div>
        <div className={styles.flexListStyleWithPadding}>
          <div className={styles.mainLabelStyle}>Metadata URL</div>
          <StandardOrgSettingInput settingName="authSamlMetadataUrl" type="input" disabled />
        </div>
        <div className={styles.flexListStyleWithPadding}>
          <div className={styles.mainLabelStyle}>Consume URL</div>
          <StandardOrgSettingInput settingName="authSamlConsumeUrl" type="input" disabled />
        </div>
        <div className={styles.flexListStyleWithPadding}>
          <div className={styles.mainLabelStyle}>Deauth URL</div>
          <StandardOrgSettingInput settingName="authSamlLogoutUrl" type="input" disabled />
        </div>
      </div>
    </div>
  );
};
