export const CALL_CONTINUES = 'CALL_CONTINUES' as const;
export const ENDED_BY_CALLER = 'ENDED_BY_CALLER' as const;
export const ENDED_BY_USER = 'ENDED_BY_USER' as const;
export const RECIPIENT_BUSY = 'RECIPIENT_BUSY' as const;
export const TOO_FEW_PARTICIPANTS = 'TOO_FEW_PARTICIPANTS' as const;
export const VWR_CALL_CANCELLED = 'VWR_CALL_CANCELLED' as const;

export type EndCallStateType =
  | typeof CALL_CONTINUES
  | typeof ENDED_BY_CALLER
  | typeof ENDED_BY_USER
  | typeof RECIPIENT_BUSY
  | typeof TOO_FEW_PARTICIPANTS
  | typeof VWR_CALL_CANCELLED;

const EndCallState: { [k: string]: EndCallStateType } = {
  CALL_CONTINUES,
  ENDED_BY_CALLER,
  ENDED_BY_USER,
  RECIPIENT_BUSY,
  TOO_FEW_PARTICIPANTS,
  VWR_CALL_CANCELLED,
};

export default EndCallState;
