import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import { mobxInjectSelect } from '../../../utils';

import { ReactComponent as CancelButtonSvg } from '../../../images/patient-search-cancel.svg';

const classes = BEM.with('PatientSettingsSearchBar');

class PatientSettingsSearchBar extends Component {
  static propTypes = {
    clearSearchResults: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    input: PropTypes.string.isRequired,
    destroy: PropTypes.func.isRequired,
    hidePatientSearch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    document.addEventListener('keydown', this._handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleKeyDown);
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      this._clearSearchResults();
      this.props.hidePatientSearch();
    }
  };

  render() {
    const { input } = this.props;

    let cancelButton;
    if (input && input.length > 0) {
      cancelButton = (
        <button
          className={classes('search-icon-container')}
          onClick={this._clearSearchResults}
          aria-label="Clear search results"
        >
          <CancelButtonSvg className={classes('button-icon-cancel')} aria-hidden />
        </button>
      );
    }

    return (
      <div className={classes()}>
        <div className={classes('search-container')}>
          <input
            className={classes('input')}
            ref={this._setInput}
            onChange={this._search}
            onKeyDown={this._handleKeyDown}
            placeholder={'Search Patients'}
            type="text"
            value={input}
            aria-label="search bar"
            autoFocus
          />
          {cancelButton}
        </div>
      </div>
    );
  }

  _setInput = (ref) => {
    this.input = ref;
  };

  _search = (e) => {
    const { search } = this.props;

    search({ text: e.target.value });
  };

  _clearSearchResults = () => {
    const { clearSearchResults, destroy } = this.props;

    destroy();
    clearSearchResults();
    this.input.focus();
  };
}

export default mobxInjectSelect({
  patientStore: ['clearSearchResults', 'destroy', 'input', 'search'],
})(PatientSettingsSearchBar);
