import React, { useEffect } from 'react';

import DotsIndicator from '../DotsIndicator';
import PatientReferenceInfo from './PatientReferenceInfo';
import ToolTip from 'widgets/messenger/components/ToolTip';
import BEM from 'common/bem';
import { ReactComponent as CancelIcon } from 'common/images/patient-reference-gray-cancel.svg';
import { ReactComponent as ExclamationIcon } from 'common/images/exclamation.svg';
import { PatientContextModel } from 'redux-stores/patientContext';
import { actions, thunk, useAppDispatch, useAppSelector } from 'redux-stores';

const classes = BEM.with('PatientReferenceCard');

const {
  setModal,
  setSelectedPatientReference,
  setSelectedPatientReferenceDetail,
  setPatientContextStatus,
} = actions;
const { fetchPatientContextThunk } = thunk;

const FETCH_PATIENT_CONTEXT_TIMEOUT = 15000;
let fetchPatientContextStatusTimeout: number;

function PatientReferenceCard({
  patient,
  patientContextId = '',
  type = 'detail',
  hasCancel,
}: {
  patient?: PatientContextModel;
  patientContextId?: string;
  type?: string;
  hasCancel?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { fetchPatientContextStatus, patientReferences } = useAppSelector(
    ({ patientContext: { fetchPatientContextStatus, patientReferences } }) => {
      return {
        patientReferences,
        fetchPatientContextStatus,
      };
    }
  );

  useEffect(
    function getPatientContext() {
      if (!!patientContextId) {
        dispatch(fetchPatientContextThunk(patientContextId));
      }
    },
    [dispatch, patientContextId]
  );

  const fetchStatus = fetchPatientContextStatus[patientContextId];
  const fetchFailed = patientContextId && (fetchStatus === 'rejected' || fetchStatus === 'timeout');

  function renderPatientContext() {
    clearTimeout(fetchPatientContextStatusTimeout);

    if (patient || patientReferences[patientContextId]) {
      const patientToShow = patient || patientReferences[patientContextId];

      return (
        <ToolTip text="Patient Details" textAlign={'center'} disable={fetchFailed}>
          <div className={classes('patient-detail')} onClick={() => handleOpenModal(patientToShow)}>
            <PatientReferenceInfo patientReference={patientToShow} type={'card'} showLocation />
          </div>
        </ToolTip>
      );
    } else if (fetchStatus === 'pending') {
      fetchPatientContextStatusTimeout = setTimeout(() => {
        dispatch(setPatientContextStatus({ id: patientContextId, status: 'timeout' }));
      }, FETCH_PATIENT_CONTEXT_TIMEOUT);
      return (
        <div className={classes('loading-container')}>
          <DotsIndicator size={13} />
        </div>
      );
    } else {
      return (
        <>
          <ExclamationIcon />
          <div className={classes('error-text')}>
            <div className={classes('error-header')}>Patient Loading Error</div>
            <div className={classes('error-subline')}>
              {type === 'editor' ? (
                <>Please reload patient info</>
              ) : (
                <>Please load the patient info before continuing</>
              )}
            </div>
          </div>
          <div className={classes('retry-button-container')}>
            <button
              type="button"
              onClick={() => {
                dispatch(fetchPatientContextThunk(patientContextId));
              }}
              className={classes('retry-button')}
            >
              Retry
            </button>
          </div>
        </>
      );
    }
  }

  return (
    <div className={classes({ [type]: true, failed: fetchFailed })}>
      <div className={classes('container')}>
        {type === 'editor' && (
          <>
            {fetchFailed ? (
              <div className={classes('header')}>Patient Reference Error</div>
            ) : (
              <div className={classes('header')}>Patient Reference</div>
            )}
          </>
        )}
        <div className={classes('patient-detail-container')}>
          {renderPatientContext()}
          {hasCancel && (
            <div className={classes('cancel-icon-container')}>
              <CancelIcon onClick={removePatientReference} />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  function handleOpenModal(patient: PatientContextModel) {
    dispatch(setSelectedPatientReferenceDetail(patient));
    dispatch(setModal({ name: 'patientReferenceDetails' }));
  }

  function removePatientReference() {
    dispatch(setSelectedPatientReference(undefined));
  }
}

export default PatientReferenceCard;
