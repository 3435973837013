import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BEM from '../../../bem';

const classes = BEM.with('ValidateReEnterNewPassword');

class ValidateReEnterNewPassword extends Component {
  static propTypes = {
    focus: PropTypes.object.isRequired,
  };

  render() {
    const { focus } = this.props;

    return (
      <div className={classes()}>
        <div className={classes('text', { focus: focus.reEnterNew })}>Password must match</div>
      </div>
    );
  }
}

export default observer(ValidateReEnterNewPassword);
