import type { Dispatch } from '@reduxjs/toolkit';
import { isInteger, keyBy, isUndefined, omit } from 'lodash';
import TCClient from '../../../client';
import { CollaborationTabs } from '../../../models/enums';
import { CollabEntity, Team } from '../../../types';
import { BatchTeamUpdateProps, CreateTeamProps } from '../../../types/Collaboration';
import { addEntity, formatEntity, handleCreateUpdateError } from '../entities/thunk';
import { actions } from '../../index';
import { entityServerId } from '../selectors';

type FriendsEventData = {
  action: string;
  metadata: { team: { id: string; members: string[]; organization_id: string } };
  organizationId: string;
};
export const handleFriendsEvent = async (
  dispatch: Dispatch,
  organizationId: string,
  ...args: unknown[]
) => {
  const eventData = args[0] as FriendsEventData;
  const delay = isInteger(args[1]) ? (args[1] as number) : 3000;
  const { action, metadata, organizationId: eventOrgId } = eventData || {};
  const { id, members: memberIds, organization_id: teamOrgId } = metadata?.team || {};
  const orgId = eventOrgId || teamOrgId;
  if (!id || orgId !== organizationId || !['add', 'del'].includes(action)) return;
  if (delay) await new Promise((resolve) => setTimeout(resolve, delay));
  dispatch(actions.reactToFriendsEvent({ action, id, memberIds }));
  if (delay) await new Promise((resolve) => setTimeout(resolve, delay));
  dispatch(actions.refreshActiveTeams());
};

export const createTeam = async (
  dispatch: Dispatch,
  {
    activeTab,
    checkedTagsById,
    entitiesById,
    organizationId,
    selectedCategory,
    selectedTag,
    tagsById,
    teamInput,
  }: CreateTeamProps
) => {
  const { tagId } = teamInput;
  dispatch(actions.setIsSavingEntity(true));
  try {
    const newTeam = await TCClient.teams.create({
      ...teamInput,
      ...(tagId ? { tagId: entityServerId(tagId) } : null),
      organizationId,
    });

    addEntity(
      dispatch,
      'team',
      activeTab,
      checkedTagsById,
      newTeam,
      organizationId,
      entitiesById,
      selectedCategory,
      selectedTag,
      tagsById
    );
  } catch (err) {
    handleCreateUpdateError(dispatch, err);
  } finally {
    dispatch(actions.setIsSavingEntity(false));
  }
};

export const batchTeamUpdate = async ({
  dispatch,
  ids,
  organizationId,
  entitiesById,
  entityUpdateProps,
  syncOne,
}: BatchTeamUpdateProps): Promise<{ [id: string]: CollabEntity }> => {
  const newTeams: Team[] = [];
  const { tagId } = entityUpdateProps;

  for (const id of ids) {
    const entity = await TCClient.teams.update({
      ...entitiesById[id],
      ...entityUpdateProps,
      ...(!isUndefined(tagId) ? { tagId: entityServerId(tagId) } : null),
      id,
      organizationId,
    });

    syncOne && syncOne(entity);
    newTeams.push(formatEntity({ entity }));
  }

  return keyBy(newTeams, (team) => team.id);
};

export const leaveTeam = async (
  dispatch: Dispatch,
  {
    activeTab,
    entitiesById,
    organizationId,
    syncOne,
    teamId,
  }: {
    activeTab: string;
    entitiesById: { [k: string]: CollabEntity };
    organizationId: string;
    syncOne: (entity: CollabEntity) => CollabEntity;
    teamId: string;
  }
) => {
  try {
    await TCClient.teams.leave(teamId, { organizationId });
    const team = await TCClient.teams.refresh(teamId, { organizationId });
    syncOne(team);
    const teamClone = formatEntity({ entity: team });
    if (activeTab === CollaborationTabs.ACTIVETEAMS) {
      dispatch(actions.updateEntities({ entitiesById: omit(entitiesById, [team.id]) }));
    } else if (entitiesById[teamId]) {
      dispatch(actions.updateEntities({ entitiesById: { ...entitiesById, [teamId]: teamClone } }));
    }
    dispatch(actions.selectEntity(teamClone));
  } catch (err) {
    handleCreateUpdateError(dispatch, err);
  }
};
