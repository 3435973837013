import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Organization } from '../../../types';
import { CollabSliceState } from '../../../types/Collaboration';

const collabSlice = createSlice({
  name: 'collab',
  initialState: {
    isAdminOrgSelectorOpen: false,
    isRolesAdmin: false,
    isRolesEnabled: false,
    isTeamAdmin: false,
    isTeamsEnabled: false,
    loadRefresh: false,
    selectedOrgId: '',
    selectedOrgName: '',
  } as CollabSliceState,
  reducers: {
    setAdminStatus: (
      state,
      action: PayloadAction<{ isRolesAdmin: boolean; isTeamAdmin: boolean }>
    ) => {
      state.isRolesAdmin = action.payload.isRolesAdmin;
      state.isTeamAdmin = action.payload.isTeamAdmin;
    },

    setCurrentOrganization: (state, action: PayloadAction<Organization>) => {
      state.currentOrganization = action.payload;
    },

    setFeatureEnablementStatus: (
      state,
      action: PayloadAction<{ rolesEnabled: boolean; showTeams: boolean }>
    ) => {
      state.isRolesEnabled = action.payload.rolesEnabled;
      state.isTeamsEnabled = action.payload.showTeams;
    },

    setIsAdminOrgSelectorOpen: (state, action: PayloadAction<boolean>) => {
      state.isAdminOrgSelectorOpen = action.payload;
    },

    setSelectedOrganization: (state, action: PayloadAction<{ id: string; name: string }>) => {
      state.selectedOrgId = action.payload.id;
      state.selectedOrgName = action.payload.name;
    },
  },
});

export { collabSlice };
