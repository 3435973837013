import React, { Component } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import timeLeft from '../../utils/timeLeft';

const classes = BEM.with('PrintMessageDetails');

class MessageDetails extends Component {
  static propTypes = {
    message: propTypes.message.isRequired,
  };

  render() {
    const { message } = this.props;
    const { createdAt, expiresAt } = message;

    return (
      <div className={classes()}>
        <div className={classes('message-time', { 'time-left': true })}>{timeLeft(expiresAt)}</div>
        <div className={classes('message-time')}>
          Sent: {moment(createdAt).format('M/DD/YY h:mmA')}
        </div>
      </div>
    );
  }
}

export default observer(MessageDetails);
