import { Conversation } from 'types';

const getGroupMembers = (
  isCurrentConversationGroup: boolean | undefined,
  currentConversation: Conversation | undefined,
  currentUserId: string | undefined
) => {
  if (isCurrentConversationGroup) {
    return (currentConversation?.counterParty.memberIds as string[]) ?? ([] as string[]);
  } else {
    return [currentConversation?.counterParty.id, currentUserId] as string[];
  }
};

const checkIfUserIsAGroupMember = (membersIds: string[], userId: string) => {
  return membersIds.some((item) => item === userId);
};

export const getIfUserIsNotInConversation = (
  currentConversation: Conversation | undefined,
  currentUserId: string | undefined,
  userId: string
) => {
  const isCurrentConversationGroup =
    currentConversation?.counterPartyType?.toLowerCase() === 'group';

  const groupMembers = getGroupMembers(
    isCurrentConversationGroup,
    currentConversation,
    currentUserId
  );

  const isGroupMember = checkIfUserIsAGroupMember(groupMembers, userId);

  return !isGroupMember && currentConversation?.counterParty?.groupType !== 'ROLE_P2P';
};
