import React, { useRef, useCallback } from 'react';
import BEM from '../../../../bem';
import AttachmentIcon from '../../../AttachmentIcon';

import ToolTip from '../../../../../widgets/messenger/components/ToolTip';
import { ReactComponent as AttachmentPin } from '../../../../images/attachment_pin.svg';
import { ReactComponent as CloseAttachmentIcon } from '../../../../images/icon-close-gray.svg';
import type { Attachment } from './';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('TemplateForm');

type DownloadAttachmentProps = {
  attachment: Attachment;
  downloadFile: (fileName: string) => void;
};

type TemplateAttachmentProps = {
  attachment: Attachment | null;
  downloadFile: (fileName: string) => void;
  isAttachmentFromTemplate: boolean;
  isSecureText: boolean;
  setAttachment: (newAttachment: Attachment | null) => void;
  setIsAttachmentFromTemplate: (status: boolean) => void;
};

const TemplateAttachment: React.FC<TemplateAttachmentProps> = ({
  attachment,
  downloadFile,
  isAttachmentFromTemplate,
  isSecureText,
  setAttachment,
  setIsAttachmentFromTemplate,
}) => {
  let attachmentFragment = null;
  let secureTextFragment = null;
  const attachmentRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setAttachment({
        name: files[0].name,
        type: files[0].type,
      });
    } else {
      setAttachment(null);
    }
  };

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === KEYMAP.ENTER || event.key === KEYMAP.SPACE) {
      event.preventDefault();
      attachmentRef.current?.click();
    }
  }, []);

  if (isSecureText) {
    secureTextFragment = (
      <div
        className={classes('formGroup', {
          attachment: true,
          shouldHide: !!attachment,
        })}
      >
        <label className={classes('attachment-pin')} tabIndex={0} onKeyDown={handleKeyDown}>
          <AttachmentPin aria-hidden />
          <span className={classes('attachment-label')}>Add Attachment</span>
          <input
            ref={attachmentRef}
            onChange={handleFileInputChange}
            type="file"
            name="attachment"
            style={{ display: 'none' }}
          />
        </label>
      </div>
    );
  }
  if (attachment) {
    attachmentFragment = (
      <div
        className={classes('formGroup', {
          attachmentPreview: true,
          shouldHide: !attachment,
        })}
      >
        {isAttachmentFromTemplate ? (
          <ToolTip text="Download File" className={classes('attachment-tooltip')} isPatientFacing>
            <DownloadAttachment downloadFile={downloadFile} attachment={attachment} />
          </ToolTip>
        ) : (
          <DownloadAttachment downloadFile={downloadFile} attachment={attachment} />
        )}
        <button
          className={classes('removeAttachment')}
          onClick={() => {
            if (attachmentRef.current) {
              attachmentRef.current.value = '';
            }
            setAttachment(null);
            setIsAttachmentFromTemplate(false);
          }}
          data-test-id={'template-remove-attachment'}
          aria-label="Remove attachment"
          id="template-remove-attachment"
        >
          <CloseAttachmentIcon aria-hidden />
        </button>
      </div>
    );
  }

  return (
    <>
      {secureTextFragment}
      {attachmentFragment}
    </>
  );
};

const DownloadAttachment: React.FC<DownloadAttachmentProps> = ({ attachment, downloadFile }) => (
  <div className={classes('attachment-container')} onClick={() => downloadFile(attachment.name)}>
    {attachment && (
      <AttachmentIcon fileType={attachment.type} className={classes('attachment-symbol')} />
    )}
    <span className={classes('attachment-file-name')}>{attachment && attachment.name}</span>
  </div>
);

export default TemplateAttachment;
