import { useState } from 'react';
import classnames from 'classnames';

import mobxInjectSelect from '../utils/mobxInjectSelect';
import { ReactComponent as PlusIcon } from '../images/plus.svg';

import { buildQuickActionThumbsUp, renderEmoji } from './utils/reactions';

import ReactionPicker from './ReactionPicker';
import styles from './ReactionOptionBar.module.css';
import { MaterialTooltip } from 'widgets/messenger/components/MaterialTooltip';

type MessageOptionBarProps = {
  children: React.ReactNode;
  direction: 'INCOMING' | 'OUTGOING';
  messageId: string;
  setPickerOpen: (v: boolean) => void;
};

type MobxProps = {
  addReaction: ({
    messageId,
    reaction,
    userId,
  }: {
    messageId: string;
    reaction: string;
    userId: string;
  }) => Promise<void>;
  currentUserId: string;
  getStorageValue: <V>(key: string) => V;
};

const MessageOptionBar = ({
  addReaction,
  children,
  direction,
  messageId,
  currentUserId,
  getStorageValue,
}: MessageOptionBarProps & MobxProps) => {
  const __direction = direction.toLowerCase();
  const [pickerOpen, setPickerOpen] = useState(false);

  const thumbsUp = buildQuickActionThumbsUp(getStorageValue);

  return (
    <div className={styles.root}>
      <div className={classnames(styles.buttonContainer, styles[__direction])}>
        {children}
        <MaterialTooltip title={'+1'} placement={'top'} arrow>
          <button
            className={styles.defaultButton}
            onClick={async (e) => {
              e.stopPropagation();
              await addReaction({
                messageId,
                reaction: thumbsUp,
                userId: currentUserId,
              });
            }}
          >
            {renderEmoji(thumbsUp)}
          </button>
        </MaterialTooltip>
        <ReactionPicker pickerOpen={pickerOpen} setPickerOpen={setPickerOpen} messageId={messageId}>
          <MaterialTooltip title={'Choose a reaction'} placement={'top'} arrow>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setPickerOpen(true);
              }}
              className={styles.addContainer}
            >
              <span className={styles.add}>
                <PlusIcon fill={'var(--neutral-400)'} title="" />
              </span>
            </div>
          </MaterialTooltip>
        </ReactionPicker>
      </div>
    </div>
  );
};

export default mobxInjectSelect<MessageOptionBarProps, MobxProps>({
  messageStore: ['addReaction'],
  sessionStore: ['currentUserId'],
  localStore: ['getStorageValue'],
})(MessageOptionBar);
