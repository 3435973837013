import uuid from 'uuid';
import { createStores } from './stores';
import client from './client';
import { params } from './params';

function setup() {
  const {
    code,
    country,
    key,
    magic_login,
    organizationId,
    password,
    secret,
    udid = uuid.v4(),
    userId,
  } = params;

  const stores = createStores({ client, params });
  const { desktopAppStore, messengerStore, sessionStore, trackerStore } = stores;
  const { isDesktopApp, signInPreviousUser } = desktopAppStore;
  const { setCurrentOrganizationId } = messengerStore;
  const {
    authenticate,
    checkLogin,
    signIn,
    signInUsername,
    signInWithJwtToken,
    signInWithMagicToken,
  } = sessionStore;
  const { trackInsecureSignIn } = trackerStore;

  if (organizationId) {
    setCurrentOrganizationId(organizationId);
  }

  if (userId && password) {
    trackInsecureSignIn('signIn');
    signIn(userId, password, { udid });
  } else if (key) {
    trackInsecureSignIn('authenticate');
    authenticate(key, secret);
  } else if (magic_login) {
    signInWithMagicToken(magic_login);
  } else if (userId && country) {
    checkLogin(userId, { country });
  } else if (code) {
    signInWithJwtToken(code);
  } else if (isDesktopApp) {
    signInPreviousUser();
  } else if (signInUsername) {
    checkLogin(signInUsername, { fromReload: true });
  }

  return stores;
}

const stores = setup();

export default stores;
