import React from 'react';
import BEM from '../../../../common/bem';
import { GroupAvatar, RoundButton } from '../../../../common/components';
import VideoCallButton from '../../../../common/components/Call/VideoCallButton';
import { mobxInjectSelect } from '../../../../common/utils';
import { ReactComponent as MessageSvg } from '../../../../common/images/message-bubble.svg';
import PfCallOrigins from '../../../../models/enums/PfCallOrigins';
import { User, CallLog } from '../../../../types';
import PatientInfo from '../ConversationPane/PatientInfo';

const classes = BEM.with('CallLogViewMemberInfo');
const OPT_OUT_MESSAGE = 'Opted out via SMS';
const { LOGS } = PfCallOrigins;

type CallLogViewMemberInfoProps = {
  patientAndContacts: User[];
};

type MobxProps = {
  currentLog: CallLog;
  composeToEntity: (arg: unknown) => void;
  isPFVideoCallEnabled: boolean;
  openPatientProfileModal: (modal: string) => void;
};

function CallLogViewMemberInfo({
  currentLog,
  composeToEntity,
  isPFVideoCallEnabled,
  openPatientProfileModal,
  patientAndContacts,
}: CallLogViewMemberInfoProps & MobxProps) {
  const {
    target: { entity },
    isContact,
    isGroup,
    isOptedOut,
  } = currentLog;
  const { avatar, displayName, members = [], metadata, token } = entity;
  const { patientContactId, patientId } = metadata;
  const callPatientId = patientId ? patientId : token;
  const canVideoCall = isPFVideoCallEnabled && !isOptedOut;
  const canMessage = patientAndContacts.length === 1 && !isOptedOut;
  const callMembers = isGroup ? members : [token];
  const videoRecipient = isGroup ? (isContact ? patientContactId : callPatientId) : token;
  return (
    <div className={classes()}>
      <div className={classes('content')}>
        <div className={classes('avatar-holder')}>
          <GroupAvatar avatarUrl={avatar} group={entity} size="fit" />
        </div>
        <div className={classes('member-title', { isOptedOut })}>{displayName}</div>
        <div className={classes('patient-info')}>
          <PatientInfo
            isCard
            showName
            isCenterName
            className={classes('patient-info')}
            log={currentLog}
            isInsideCard
          />
        </div>
        {isOptedOut && <div className={classes('opt-out')}> {OPT_OUT_MESSAGE} </div>}
        <div className={classes('patient-actions')}>
          {canVideoCall && (
            <div className={classes('patient-action')}>
              <VideoCallButton
                origin={LOGS}
                maxMembers={4}
                entity={{
                  recipientId: videoRecipient,
                  recipientName: displayName,
                  members: callMembers.map((id: string) => ({ id })),
                }}
              />
            </div>
          )}
          {canMessage && (
            <div className={classes('patient-action')}>
              <RoundButton
                color="#4a657b"
                onClick={() => composeToEntity(patientAndContacts[0])}
                img={MessageSvg}
              />
            </div>
          )}
        </div>
      </div>
      <button
        onClick={() => openPatientProfileModal(callPatientId)}
        className={classes('profile-link')}
      >
        Show Patient Profile
      </button>
    </div>
  );
}

export default mobxInjectSelect<CallLogViewMemberInfoProps, MobxProps>({
  callStore: ['currentLog', 'isPFVideoCallEnabled'],
  composeMessageStore: ['composeToEntity'],
  patientStore: ['openPatientProfileModal'],
})(CallLogViewMemberInfo);
