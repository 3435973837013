import React, { useCallback } from 'react';
import PfCallOrigins from '../../models/enums/PfCallOrigins';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { CallChangeBang, MissedCallBang } from './';

type CallChangeLogProps = {
  message: {
    body: string;
    conversation: { counterPartyId: string };
    counterPartyType: string;
    senderId: string;
    counterPartyId: string;
    group: { members: Record<string, unknown>[] };
  };
};

type MobxProps = {
  currentUserId: string;
  isGroupVideoCallEnabled: boolean;
  isGroupVoiceCallEnabled: boolean;
  isPFGroupVideoCallEnabled: boolean;
  isPFGroupVoiceCallEnabled: boolean;
  isProviderNetwork: boolean;
  videoCallSetUp: (
    entity: {
      $entityType: string;
      recipientId: string;
      isVideo: boolean;
      members: Record<string, unknown>[];
      context: Record<string, unknown>;
    },
    type: 'Bang'
  ) => void;
};

function CallChangeLog({
  currentUserId,
  isGroupVideoCallEnabled,
  isGroupVoiceCallEnabled,
  isPFGroupVideoCallEnabled,
  isPFGroupVoiceCallEnabled,
  isProviderNetwork,
  message,
  videoCallSetUp,
}: CallChangeLogProps & MobxProps) {
  const { body, conversation, counterPartyType, senderId } = message;
  const isVideoCall = body ? body.includes('Video') : false;
  const isGroup = counterPartyType && counterPartyType === 'group';
  const isCaller = senderId === currentUserId;

  const callAgain = useCallback(() => {
    let members: Record<string, unknown>[], recipientId;
    if (isGroup) {
      recipientId = message.counterPartyId;
      members = message.group.members;
    } else {
      recipientId = isCaller ? conversation.counterPartyId : senderId;
      members = [];
    }
    const context = { type: isGroup ? 'group' : 'account', id: conversation.counterPartyId };
    videoCallSetUp(
      {
        $entityType: isGroup ? 'group' : 'user',
        recipientId,
        isVideo: isVideoCall,
        members,
        context,
      },
      PfCallOrigins.BANG
    );
  }, [
    conversation.counterPartyId,
    isCaller,
    isGroup,
    isVideoCall,
    message.counterPartyId,
    message.group,
    senderId,
    videoCallSetUp,
  ]);

  if (body && (body.startsWith('Missed Call') || body.startsWith('Missed Video Call'))) {
    return (
      <MissedCallBang
        body={body}
        callAgain={callAgain}
        isGroup={isGroup}
        isProviderNetwork={isProviderNetwork}
        isGroupVideoCallEnabled={isGroupVideoCallEnabled}
        isGroupVoiceCallEnabled={isGroupVoiceCallEnabled}
        isPFGroupVideoCallEnabled={isPFGroupVideoCallEnabled}
        isPFGroupVoiceCallEnabled={isPFGroupVoiceCallEnabled}
        isVideoCall={isVideoCall}
      />
    );
  } else {
    return (
      <CallChangeBang
        body={body}
        callAgain={callAgain}
        isCaller={isCaller}
        isGroup={isGroup}
        isProviderNetwork={isProviderNetwork}
        isGroupVideoCallEnabled={isGroupVideoCallEnabled}
        isGroupVoiceCallEnabled={isGroupVoiceCallEnabled}
        isPFGroupVideoCallEnabled={isPFGroupVideoCallEnabled}
        isPFGroupVoiceCallEnabled={isPFGroupVoiceCallEnabled}
        isVideoCall={isVideoCall}
      />
    );
  }
}

export default mobxInjectSelect<CallChangeLogProps, MobxProps>({
  callStore: [
    'isGroupVideoCallEnabled',
    'isGroupVoiceCallEnabled',
    'isPFGroupVideoCallEnabled',
    'isPFGroupVoiceCallEnabled',
    'videoCallSetUp',
  ],
  sessionStore: ['currentUserId'],
  networkStore: ['isProviderNetwork'],
})(CallChangeLog);
