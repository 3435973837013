import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NetworkStatusMessages } from '../../../models/enums';
import BEM from '../../../common/bem';
import mobxInjectSelect from '../../../common/utils/mobxInjectSelect';

const classes = BEM.with('NetworkStatusBar');

class NetworkStatusBar extends Component {
  static propTypes = {
    messageVisible: PropTypes.bool,
    networkStatus: PropTypes.string.isRequired,
  };

  render() {
    const { messageVisible, networkStatus } = this.props;
    if (!messageVisible || !networkStatus) return null;

    const text = NetworkStatusMessages[networkStatus];
    if (!text) return null;

    return (
      <div className={classes({ status: networkStatus })}>
        <div className={classes('message')}>{text}</div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  networkStatusStore: ['messageVisible', 'networkStatus'],
})(NetworkStatusBar);
