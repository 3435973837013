import React from 'react';
import { observer } from 'mobx-react';

import { AlertComponent } from '../../../../../src/types/';
import BEM from '../../../../common/bem';
import { ReactComponent as EscalatedIconSvg } from '../../../../common/images/icon-escalated.svg';

const classes = BEM.with('AlertEscalateHeader');

export type EscalateHeaderProps = {
  component: AlertComponent;
};

const EscalateHeader = ({ component }: EscalateHeaderProps) => {
  const { value: text } = component;
  let { bg_color: backgroundColor, value_color: color } = component;

  if (backgroundColor) backgroundColor = backgroundColor.replace('0x', '#');
  if (color) color = color.replace('0x', '#');

  return (
    <div
      className={classes()}
      style={{ backgroundColor, color }}
      data-test-id={'escalateHeader'}
      aria-label="escalate-header"
    >
      <EscalatedIconSvg />
      <div>{text}</div>
    </div>
  );
};

export default observer(EscalateHeader);
