import React from 'react';
import Admin from './pages/Admin';

export default function AdminProvider({
  currentOrganizationId,
}: {
  currentOrganizationId: string;
}) {
  return <Admin currentOrganizationId={currentOrganizationId} />;
}
