import { UserOrganizationSettings } from 'admin/types';

export const hasOrgFeatureOrSettings = (
  orgSettings: UserOrganizationSettings,
  settings: Array<keyof UserOrganizationSettings>
): boolean => {
  return settings.every((setting) => orgSettings[setting]);
};

export const isIndividual = (
  orgSettings: UserOrganizationSettings,
  setting: keyof UserOrganizationSettings
): boolean => {
  return orgSettings[setting] === 'individual';
};

export const isNotOff = (
  orgSettings: UserOrganizationSettings,
  setting: keyof UserOrganizationSettings
): boolean => {
  return orgSettings[setting] !== 'off';
};
