import React from 'react';

import Fab from '../../Fab/Fab';
import { ReactComponent as CallEndIcon } from '../../../images/call-end.svg';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { mobxInjectSelect } from '../../../../common/utils';

function EndCallButton({ endVoipCall }) {
  const { room } = useVideoContext();

  function disconnect() {
    room.disconnect();
    endVoipCall();
  }

  return (
    <Fab
      dataTestId={'end-call-button'}
      isDisabled={false}
      isEnabled
      title={'End Call'}
      onClick={disconnect}
      backgroundColorOff={'#D92A25'}
      backgroundColorOn={'#D92A25'}
      hoverColor={'#BC2521'}
    >
      <CallEndIcon aria-hidden />
    </Fab>
  );
}

class EndCallButtonContainer extends React.Component {
  render() {
    return <EndCallButton {...this.props} />;
  }
}

export default mobxInjectSelect({
  callStore: ['currentCall', 'endVoipCall'],
})(EndCallButtonContainer);
