import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from '../../WebComponents';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import BEM from '../../../bem';
import { MapStateToProps, reduxInjectSelect } from '../../../utils/reduxInjectSelect';
import { Role, User } from '../../../../types';
import { LongPressButton } from '../../';
import UserItem from '../Entities/UserItem';

import { actions, ReduxState, thunk } from '../../../../redux-stores';

const { setModal } = actions;
const { updateRoleOwner } = thunk;
const classes = BEM.with('CollaborationEntityEditor');

type RoleEditorOnDutyProps = {
  currentRoles: Role[];
  currentUser: User;
  role: Role;
};

const reduxSelectors = {
  collab: ['isRolesAdmin', 'selectedOrgId'],
  roles: ['roleOwnershipLoadingTokens'],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

function RoleEditorOnDuty({
  currentRoles,
  currentUser,
  isRolesAdmin,
  role,
  roleOwnershipLoadingTokens,
  selectedOrgId,
}: ReduxProps & RoleEditorOnDutyProps) {
  const dispatch = useDispatch();
  const [optProgress, setOptProgress] = useState<number>(0);

  const onCompletion = () => {
    if (role?.members?.[0]?.id === currentUser.id) {
      if (role?.metadata?.owner_required) {
        dispatch(setModal({ name: 'ownerRequired' }));
      } else {
        dispatch(setModal({ name: 'optOut' }));
      }
    } else if (role?.members?.length) {
      dispatch(setModal({ name: 'optIn' }));
    } else {
      updateRoleOwner(dispatch, {
        method: 'optIn',
        organizationId: selectedOrgId,
        selectedRole: role,
        user: currentUser,
      });
    }
  };

  const isLoading = roleOwnershipLoadingTokens.includes(role.id);
  const modalType = role?.metadata?.owner_required ? 'ownerRequired' : 'onDuty';

  const action = role?.members?.[0]?.token === currentUser.id ? 'out' : 'in';

  const isDnd = currentUser.dnd && action === 'in';
  const isOpenAssignment = role?.metadata?.open_assignment;
  const disabledByAdmin = !(isOpenAssignment || isRolesAdmin);
  const isDisabled = isDnd || disabledByAdmin;
  const isButtonOn = !!currentRoles.find((r) => r.id === role.id);
  let tooltip = `Hold to Opt ${isButtonOn ? 'out' : 'in'}`;
  if (isDnd) tooltip = 'Opt in not allowed on DND';
  if (disabledByAdmin) tooltip = `Opt ${isButtonOn ? 'out' : 'in'} disabled by Admin`;

  const ownerDetails = (
    <div
      data-test-id="open role selector"
      onClick={() => isRolesAdmin && dispatch(setModal({ name: modalType }))}
    >
      {role.members?.length ? (
        <div className={classes('owner')}>
          <UserItem
            entity={role}
            avatarSize={33}
            currentUserToken={currentUser.id}
            showTitleAndDepartment
            isEntityDetails
            maxWidth="180px"
            user={role.members?.[0]}
          />
        </div>
      ) : (
        <div className={classes('empty-on-duty')}>-No one on duty-</div>
      )}
    </div>
  );

  return (
    <div className={classes('card-container')}>
      <Card header="On duty">
        <div className={classes('on-duty-options')}>
          <div className={classes('on-duty-progress')}>
            <progress className={classes('progress-bar')} max="100" value={optProgress} />
          </div>
          {isLoading ? (
            <div className={classes('loading-on-duty')}>Loading...</div>
          ) : (
            <div
              className={classes('owner-container', {
                admin: isRolesAdmin,
              })}
            >
              {isRolesAdmin ? (
                <ToolTip
                  text="Change"
                  textAlign={'center'}
                  getManualOffset={() => ({ left: '40%' })}
                >
                  {ownerDetails}
                </ToolTip>
              ) : (
                <>{ownerDetails}</>
              )}
            </div>
          )}
          <LongPressButton
            isDisabled={isDisabled}
            isLoading={isLoading}
            isOn={isButtonOn}
            isOnText={'Opt Out'}
            isOffText={'Opt In'}
            progress={optProgress}
            setProgress={setOptProgress}
            toolTipText={tooltip}
            onCompletion={onCompletion}
            getManualOffset={() => ({ left: isDisabled ? 'auto' : '50%' })}
          />
        </div>
      </Card>
    </div>
  );
}

export default reduxInjectSelect<RoleEditorOnDutyProps, ReduxProps, ReduxState>(reduxSelectors)(
  RoleEditorOnDuty
);
