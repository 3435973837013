import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PatientArrows } from '../../images/patients-arrows.svg';
import { ReactComponent as ProviderArrows } from '../../images/provider-arrows.svg';
import propTypes from '../../../../common/propTypes';
import { UnreadBadgeCount } from '../../../../common/components';
import { mobxInjectSelect } from '../../../../common/utils';
import BEM from '../../../../common/bem';
import { AppTypes } from '../../../../models/enums';
import { ReactComponent as DownArrowSvg } from '../../images/down-arrow.svg';

const classes = BEM.with('SidebarOrganizationSelector');

class SidebarOrganizationSelector extends Component {
  static propTypes = {
    closeSelector: PropTypes.func.isRequired,
    conversationsLoaded: PropTypes.bool.isRequired,
    currentOrganization: propTypes.organization,
    openSelector: PropTypes.func.isRequired,
    organizations: propTypes.organizationArray,
    otherOrganizationsAlertsUnreadCount: PropTypes.number.isRequired,
    otherOrganizationsUnreadCount: PropTypes.number.isRequired,
    otherOrganizationsUnreadPriorityCount: PropTypes.number.isRequired,
    otherOrganizationsUnreadVWRCount: PropTypes.number.isRequired,
    switchNetwork: PropTypes.func.isRequired,
    totalUnreadAlertsCount: PropTypes.number.isRequired,
    totalUnreadCount: PropTypes.number.isRequired,
    totalUnreadVWRCount: PropTypes.number.isRequired,
  };

  state = {
    switchText: this.props.isProviderNetwork ? 'Providers' : 'Patients',
  };

  componentWillUnmount() {
    this.props.closeSelector();
  }

  render() {
    const {
      conversationsLoaded,
      currentAppSelected,
      currentOrganization,
      isProviderNetwork,
      openSelector,
      organizations = [],
      otherOrganizationsAlertsUnreadCount,
      otherOrganizationsUnreadCount,
      otherOrganizationsUnreadPriorityCount,
      otherOrganizationsUnreadVWRCount,
      totalUnreadAlertsCount,
      totalUnreadCount,
      totalUnreadVWRCount,
    } = this.props;
    const { switchText } = this.state;
    const classNetwork = isProviderNetwork ? 'provider' : 'patient';
    const SvgComponent = isProviderNetwork ? ProviderArrows : PatientArrows;
    const clickable = organizations.length > 0 && !!currentOrganization;
    const showPatientNetwork = !!currentOrganization && currentOrganization.isPatientNetworkEnabled;
    const getUnreadMessageLabel = (count) =>
      count > 0 ? ` with ${count} unread message${count > 1 ? 's' : ''}` : '';
    let enableButtonFragment, unreadOrArrowFragment;
    let orgName = 'Loading...';
    let priority = false;

    if (currentOrganization) {
      if (clickable) orgName = currentOrganization.displayName;
      priority = otherOrganizationsUnreadPriorityCount > 0;

      const unreadCount =
        currentAppSelected === AppTypes.MESSAGES || currentAppSelected === AppTypes.VIRTUAL_WAITING
          ? otherOrganizationsAlertsUnreadCount +
            otherOrganizationsUnreadCount +
            (otherOrganizationsUnreadVWRCount ? otherOrganizationsUnreadVWRCount : 0)
          : totalUnreadAlertsCount +
            totalUnreadCount +
            (totalUnreadVWRCount ? totalUnreadVWRCount : 0);

      if (unreadCount > 0 && conversationsLoaded) {
        unreadOrArrowFragment = (
          <span>
            <UnreadBadgeCount count={unreadCount} priority={priority} />
          </span>
        );
      } else if (organizations.length > 1) {
        unreadOrArrowFragment = (
          <span className={classes('image-container')}>
            <DownArrowSvg className={classes('image')} />
          </span>
        );
      }

      if (showPatientNetwork) {
        let patientUnreadOrArrowFragment;
        const { patient, provider } = currentOrganization.networks;
        if (isProviderNetwork && patient.unreadCount > 0) {
          patientUnreadOrArrowFragment = (
            <UnreadBadgeCount className={classes('unread-badge')} count={patient.unreadCount} />
          );
        } else if (!isProviderNetwork && provider.unreadCount > 0) {
          patientUnreadOrArrowFragment = (
            <UnreadBadgeCount className={classes('unread-badge')} count={provider.unreadCount} />
          );
        } else {
          patientUnreadOrArrowFragment = <SvgComponent className={classes('button-arrows')} />;
        }
        const switchNetworkLabelBtn = `Switch to ${isProviderNetwork ? 'Patients' : 'Providers'}`;
        enableButtonFragment = (
          <button
            className={classes(`${classNetwork}-button`)}
            aria-label={
              switchNetworkLabelBtn +
              getUnreadMessageLabel(!isProviderNetwork ? provider.unreadCount : patient.unreadCount)
            }
            onClick={this.switchNetwork}
            onFocus={this.changeButtonLabel(switchNetworkLabelBtn)}
            onBlur={this.changeButtonLabel(isProviderNetwork ? 'Providers' : 'Patients')}
            onMouseEnter={this.changeButtonLabel(switchNetworkLabelBtn)}
            onMouseLeave={this.changeButtonLabel(isProviderNetwork ? 'Providers' : 'Patients')}
          >
            <div className={classes(`${classNetwork}-button-text`)} aria-hidden>
              {switchText}
            </div>
            {patientUnreadOrArrowFragment}
          </button>
        );
      }
    }

    return (
      <div className={classes()}>
        <div className={classes('selector-container')}>
          <div title={orgName} className={classes('selected-org')}>
            <button
              className={classes('selected-hover', { priority })}
              onClick={clickable ? openSelector : undefined}
              id="org-selector"
              aria-label="Change Organization"
            >
              <span className={classes('name')}>{orgName}</span>
              {unreadOrArrowFragment}
            </button>
            {enableButtonFragment}
          </div>
        </div>
      </div>
    );
  }

  switchNetwork = () => {
    this.props.switchNetwork();
  };

  changeButtonLabel = (text) => {
    return () => {
      this.setState({ switchText: text });
    };
  };
}

export default mobxInjectSelect({
  messengerStore: [
    'conversationsLoaded',
    'currentAppSelected',
    'currentOrganization',
    'organizations',
    'otherOrganizationsAlertsUnreadCount',
    'otherOrganizationsUnreadCount',
    'otherOrganizationsUnreadPriorityCount',
    'otherOrganizationsUnreadVWRCount',
    'totalUnreadAlertsCount',
    'totalUnreadCount',
    'totalUnreadVWRCount',
  ],
  networkStore: ['isProviderNetwork', 'switchNetwork'],
  organizationSelectorStore: ['closeSelector', 'openSelector'],
})(SidebarOrganizationSelector);
