import React, { useState } from 'react';
import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';
import { Input } from '../../../../shared-components/Input/Input';

import styles from '../../OrganizationSettings.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

export default function AuthSubstituionsForm() {
  const dispatch = useAppDispatch();
  const authSubstitutions = useAppSelector(
    (state) => state.orgSettings.settings.authSubstitutions || {}
  );
  const [searchFor, setSearchFor] = useState('');
  const [replace, setReplace] = useState('');

  return (
    <div className={styles.flexListStyleWithPadding}>
      <div className={styles.mainLabelStyle}>Username Search/Replace</div>
      <div className={styles.inputBoxStyleWithMargin}>
        <Input value={searchFor} onInputChange={({ target: { value } }) => setSearchFor(value)} />
      </div>
      <div className={styles.inputBoxStyleWithMargin}>
        <Input value={replace} onInputChange={({ target: { value } }) => setReplace(value)} />
      </div>
      <CtaButton
        primary
        label="Add"
        disabled={!(searchFor && replace)}
        onClick={() => {
          dispatch(
            actions.updateSettings({
              authSubstitutions: { ...authSubstitutions, [searchFor]: replace },
            })
          );
          setSearchFor('');
          setReplace('');
        }}
      />
    </div>
  );
}
