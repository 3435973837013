import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../../utils';
import propTypes from '../../propTypes';
import { CollaborationCategories } from '../../../models/enums';
import BEM from '../../bem';
import { ReactComponent as RoleBannerSvg } from '../../images/role-banner-icon.svg';

const classes = BEM.with('RoleBanner');

class RoleBanner extends Component {
  static propTypes = {
    currentRoles: propTypes.roleArray.isRequired,
    goToMyRoles: PropTypes.func.isRequired,
    isUniversalTaggingAllowed: PropTypes.bool,
    openCollaboration: PropTypes.func.isRequired,
  };

  render() {
    const { currentRoles, goToMyRoles, isUniversalTaggingAllowed, openCollaboration } = this.props;
    const rolesNames = currentRoles.map((role) => role.displayName);

    return (
      <div className={classes()}>
        <div
          className={classes('details', { enable: currentRoles.length > 0 })}
          onClick={() =>
            isUniversalTaggingAllowed
              ? openCollaboration(CollaborationCategories.MYROLES)
              : goToMyRoles()
          }
        >
          <RoleBannerSvg className={classes('icon')} />
          <div className={classes('header')}>{"You're On Duty:"}</div>
          <div className={classes('role-names')}>{rolesNames.join(', ')}</div>
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  messengerStore: ['goToMyRoles', 'isUniversalTaggingAllowed', 'openCollaboration'],
  roleStore: ['currentRoles'],
})(RoleBanner);
