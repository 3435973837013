import ValidationError from '../errors/ValidationError';

const Validator = {
  isNotEmpty(_name: unknown, value: unknown) {
    return !!value;
  },

  notEmpty(name: string, value: unknown, message: string) {
    if (!Validator.isNotEmpty(name, value)) {
      if (!message) message = `expected ${name} to be provided`;
      Validator.raise(name, undefined, message);
    }
  },

  isOneOf(_name: string, value: string, values: string[]) {
    return values.includes(value);
  },

  oneOf(name: string, value: string, values: string[], message: string) {
    if (!Validator.isOneOf(name, value, values)) {
      if (!message) message = `expected ${value} to be one of (${values.join(', ')})`;
      Validator.raise(name, undefined, message);
    }
  },

  raise(name: string, code = 'invalid', message: string) {
    throw new ValidationError(name, code, message);
  },
};

export default Validator;
