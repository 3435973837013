import { range } from 'lodash';

export const TTL_CONTROL_OPTIONS = [
  { label: '5 minutes', value: 5 },
  { label: '10 minutes', value: 10 },
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '1 hour', value: 60 },
  { label: '2 hours', value: 120 },
  { label: '3 hours', value: 180 },
  { label: '4 hours', value: 240 },
  { label: '5 hours', value: 300 },
  { label: '6 hours', value: 360 },
  { label: '7 hours', value: 420 },
  { label: '8 hours', value: 480 },
  { label: '9 hours', value: 540 },
  { label: '10 hours', value: 600 },
  { label: '11 hours', value: 660 },
  { label: '12 hours', value: 720 },
  { label: '1 day', value: 1440 },
  { label: '2 days', value: 2880 },
  { label: '4 days', value: 5760 },
  { label: '5 days', value: 7200 },
  { label: '10 days', value: 14400 },
  { label: '20 days', value: 28800 },
  { label: '30 days', value: 43200 },
];

export const ALL_OFF_INDV = [
  { label: 'Off', value: 'off' },
  { label: 'Organization', value: 'all' },
  { label: 'Individual', value: 'individual' },
];

export const PINLOCK_TTL_CONTROL_OPTIONS = [
  { value: '-1', label: 'Not required' },
  { value: '0', label: 'Required immediately' },
  { value: '1', label: 'After 1 minute' },
  { value: '5', label: 'After 5 minutes' },
  { value: '15', label: 'After 15 minutes' },
  { value: '60', label: 'After 1 hour' },
  { value: '240', label: 'After 4 hours' },
];

export const DESKTOP_AND_WEB_LOGOUT_CONTROL_OPTIONS = [
  { label: '10 minutes', value: 10 },
  { label: '20 minutes', value: 20 },
  { label: '1 hour', value: 60 },
  { label: '4 hours', value: 240 },
  { label: '24 hours', value: 1440 },
];

export const FAST_DEPLOY_CONTROL_OPTIONS = [
  { label: '1 minute', value: 1 },
  { label: '5 minutes', value: 5 },
  { label: '20 minutes', value: 20 },
  { label: '1 hour', value: 60 },
  { label: '4 hours', value: 240 },
  { label: '24 hours', value: 1440 },
];

export const FAST_DEPLOY_LOGIN_OPTIONS = [
  { label: 'Email Address', value: 'email' },
  { label: 'Username', value: 'username' },
];

export const ESCALATION_EXPIRATION_OPTIONS = range(50).map((num) => ({
  label: num === 0 ? 'Immediately' : num === 1 ? '1 Hour (Default)' : `${num} Hours`,
  value: num,
}));

export const CLICK_2_CALL_OPTIONS = [
  { label: 'Off', value: 'off' },
  { label: 'Organization', value: 'all' },
  { label: 'Individual', value: 'individual' },
];

export const CLICK_2_CALL_MASK_TYPE_OPTIONS = [
  { label: 'Random Number', value: 'twilio' },
  { label: 'Custom Number', value: 'custom' },
];

export const PATIENT_ENGAGEMENT_OPTIONS = [
  { label: 'Organization', value: 'all' },
  { label: 'Individual', value: 'individual' },
];

export const PATIENT_CONV_TTL = range(100).map((num) => ({
  label: `${num + 1} days`,
  value: num + 1,
}));

export const PATIENT_VIDEO_LINK_TTL = [{ label: 'Unlimited', value: 0 }].concat(
  range(30).map((num) => ({
    label: `${num + 1} minutes`,
    value: (num + 1) * 60,
  }))
);
