import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ToolTip from '../ToolTip';
import PatientDetailCard from '../PatientDetailCard';
import generateExpirationTime from '../../../../common/utils/generateExpirationTime';
import mobxInjectSelect from 'common/utils/mobxInjectSelect';
import BEM from 'common/bem';
import propTypes from 'common/propTypes';
import GroupAvatar from 'common/components/GroupAvatar';
import VideoCallButton from 'common/components/Call/VideoCallButton';
import PfCallOrigins from 'models/enums/PfCallOrigins';

const classes = BEM.with('PatientConversationHeader');

const HEADER_AVATAR_PADDING = 100;

function PatientConversationHeader({
  conversation,
  ensureUsers,
  infoPaneRef,
  isInfoPaneOpen,
  isPFGroupVideoCallEnabled,
  isPFVideoCallEnabled,
  members,
}) {
  const [membersList, setMembersList] = useState([]);
  const [overlap, setOverlap] = useState(false);
  const { counterParty: group } = conversation;
  const { expireAt, escalationExecution, memberCount, patientDetails } = group;
  const { smsOptedOut } = patientDetails;
  const isEscalated = !!escalationExecution;
  const cardContainerRef = useRef();
  const detailsContainerRef = useRef();
  const headerContainerRef = useRef();

  let expirationFragment;

  if (expireAt !== undefined) {
    const expireInDays = generateExpirationTime(expireAt);

    expirationFragment = (
      <div className={classes('expiration-detail')}>Expires in {expireInDays}</div>
    );
  }

  const updateOverlapDiv = () => {
    const isValidDetailsWidth = !isNaN(get(detailsContainerRef.current, 'detailsWidth'));
    if (
      isValidDetailsWidth &&
      get(cardContainerRef.current, 'cardWidth') &&
      headerContainerRef.current
    ) {
      const divWidth =
        get(detailsContainerRef.current, 'detailsWidth') +
        get(cardContainerRef.current, 'cardWidth') +
        HEADER_AVATAR_PADDING;
      const overlap = get(headerContainerRef.current, 'scrollWidth') < divWidth;
      setOverlap(overlap);
    }
  };

  useEffect(() => {
    detailsContainerRef.current.detailsWidth = get(detailsContainerRef.current, 'scrollWidth');
    cardContainerRef.current.cardWidth = get(cardContainerRef.current, 'scrollWidth');
    updateOverlapDiv();
    window.addEventListener('resize', updateOverlapDiv);
    infoPaneRef.addEventListener('transitionend', updateOverlapDiv);
    return () => {
      window.removeEventListener('resize', updateOverlapDiv);
      infoPaneRef.removeEventListener('transitionend', updateOverlapDiv);
    };
  }, [conversation, members, infoPaneRef]);

  useEffect(() => {
    if (!isInfoPaneOpen) {
      updateOverlapDiv();
    }
  }, [isInfoPaneOpen]);

  useEffect(() => {
    const formatMemberNames = (members) => {
      return members
        .filter((u) => u.firstName)
        .map((u) => `${u.firstName}`)
        .join(', ');
    };

    const fetchUsers = async () => {
      const users = await ensureUsers(conversation.counterParty.memberIds);
      setMembersList(formatMemberNames(users));
    };

    fetchUsers();
  }, [conversation.counterParty.memberIds, ensureUsers]);

  return (
    <div
      className={classes({
        escalated: isEscalated,
        smsOptedOut,
      })}
      ref={headerContainerRef}
    >
      <div className={classes('avatar-container')}>
        <GroupAvatar group={group} size="fit" />
      </div>

      <div className={classes('patient-detail-card-container')} ref={cardContainerRef}>
        <PatientDetailCard conversation={conversation} showSmsOptedOutInfo />
      </div>
      {((isPFVideoCallEnabled && memberCount === 2) ||
        (isPFVideoCallEnabled && isPFGroupVideoCallEnabled)) &&
        group &&
        group.patientDetails &&
        !group.patientDetails.smsOptedOut && (
          <div className={classes('actions-container')}>
            <ToolTip
              text="Group call max 4 participants"
              disable={memberCount <= 4}
              isPatientFacing={true}
            >
              <VideoCallButton
                disabled={memberCount > 4}
                origin={PfCallOrigins.CONVERSATION}
                isSmall={true}
                entity={{
                  recipientId: group.patientDetails.isPatientContact
                    ? group.metadata.patient_contact_id
                    : group.patientDetails.id,
                  recipientName: group.displayName,
                  members: group.members,
                }}
              />
            </ToolTip>
          </div>
        )}

      <div
        className={classes('conversation-details', {
          infoPaneOpen: isInfoPaneOpen,
          hasMemberList: memberCount > 2,
        })}
        ref={detailsContainerRef}
      >
        {!overlap && (
          <div>
            {expirationFragment}
            {memberCount > 2 && (
              <div className={classes('members-detail')}>Members: {membersList}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

PatientConversationHeader.propType = {
  conversation: propTypes.conversation.isRequired,
  isPFGroupVideoCallEnabled: PropTypes.bool.isRequired,
  isPFVideoCallEnabled: PropTypes.bool.isRequired,
  members: propTypes.array,
};

export default mobxInjectSelect({
  callStore: ['isPFGroupVideoCallEnabled', 'isPFVideoCallEnabled'],
  messengerStore: ['infoPaneRef'],
  userStore: ['ensureUsers'],
})(PatientConversationHeader);
