import React, { RefObject } from 'react';
import classNames from 'classnames';

import styles from './FileInput.module.css';

type FileInputProps = {
  /**
   * Optional callback for when user types into text field
   */
  onInputChange?: (files: FileList) => void;
  /*
   * Value of the input
   */
  inputRef?: RefObject<HTMLInputElement>;
  dataTestId?: string;
  disabled?: boolean;
  isMultiple?: boolean;
};

export function FileInput({
  dataTestId,
  onInputChange,
  inputRef,
  disabled = false,
  isMultiple = false,
}: FileInputProps) {
  function handleLogoInput(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length > 0) {
      onInputChange?.(event.target.files);
    }
  }

  return (
    <div className={classNames(styles.inputRoot)}>
      <input
        className={styles.input}
        type={'file'}
        onChange={handleLogoInput}
        ref={inputRef}
        data-test-id={dataTestId}
        disabled={disabled}
        multiple={isMultiple}
      />
    </div>
  );
}
