import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { ContextMenu, MenuItemList, MenuItem } from '../ContextMenu';

const classes = BEM.with('GroupAvatarDropzone');

export default class AvatarEditMenu extends Component {
  static propTypes = {
    avatarHoverTarget: propTypes.ref.isRequired,
    changeAvatar: PropTypes.func.isRequired,
    isAvatarMenuOpen: PropTypes.bool.isRequired,
    removeAvatar: PropTypes.func.isRequired,
    setAvatarMenuState: PropTypes.func.isRequired,
  };

  render() {
    const { removeAvatar, changeAvatar, isAvatarMenuOpen, setAvatarMenuState, avatarHoverTarget } =
      this.props;

    return (
      <ContextMenu
        event="click"
        onShow={() => setAvatarMenuState(true)}
        onHide={() => setAvatarMenuState(false)}
        offsetX={24}
        offsetY={122}
        relativeTo={avatarHoverTarget}
        menu={
          <MenuItemList>
            <MenuItem onClick={changeAvatar} ariaLabel="Upload Avatar">
              Upload
            </MenuItem>
            <MenuItem onClick={removeAvatar} ariaLabel="Remove Avatar">
              Remove
            </MenuItem>
          </MenuItemList>
        }
      >
        <div className={classes('avatar-hovered')}>
          <span
            className={classes('avatar-hovered-text', {
              'avatar-menu-open': isAvatarMenuOpen,
            })}
          >
            Edit
          </span>
        </div>
      </ContextMenu>
    );
  }
}
