import React from 'react';
import classNames from 'classnames';

import { AuthorizeOrgSetting } from '../../shared-components/AuthorizeSetting';
import {
  SettingContainer,
  SettingContainerProps,
} from '../../shared-components/SettingContainer/SettingContainer';
import { StandardOrgSettingInput } from '../../shared-components/StandardSettingInput';
import { OrganizationSetting } from '../../types';
import {
  PATIENT_CONV_TTL,
  PATIENT_ENGAGEMENT_OPTIONS,
  PATIENT_VIDEO_LINK_TTL,
} from '../../utils/commonControlOptions';

import styles from './OrganizationSettings.module.css';

export function TigerConnectPatientEngagementSettingsItemList() {
  const tigerConnectSettingsListItems: (SettingContainerProps & {
    dependsOnFeatures?: string[];
    dependsOnSettings?: (keyof OrganizationSetting | [keyof OrganizationSetting, string])[];
  })[] = [
    {
      label: 'TigerConnect Patient Engagement',
      subLabel:
        'Enable this feature to allow your users to communicate with patients. You can choose to enable this feature for your entire organization or enable this feature to individual users.',
      settingControl: (
        <StandardOrgSettingInput
          options={PATIENT_ENGAGEMENT_OPTIONS}
          type="dropdown"
          settingName="patientEngagementSetting"
        />
      ),
    },
    {
      label: 'Conversation Lifespan',
      subLabel: 'Conversations with patients will be deleted after this time limit',
      settingControl: (
        <StandardOrgSettingInput
          options={PATIENT_CONV_TTL}
          type="slider"
          settingName="patientConversationLifespan"
        />
      ),
    },
    {
      label: 'Access Code Setting',
      subLabel:
        'This setting controls the requirement for patients and contacts to enter an access code for authorization to conversations.',
      settingControl: (
        <StandardOrgSettingInput type="checkbox" settingName="isPatientAccessCodeEnabled" />
      ),
    },
    {
      label: 'Date of Birth Setting',
      subLabel:
        'This setting controls the requirement for patients to enter a date of birth for authorization to conversations.',
      settingControl: (
        <StandardOrgSettingInput type="checkbox" settingName="isPatientDateOfBirthEnabled" />
      ),
    },
    {
      dependsOnSettings: ['featurePatientPhotoVideoSharingDisabled'],
      label: 'Disable sharing photos/video from device - TigerConnect Patient Engagement Only',
      subLabel:
        "This setting will disable users from sharing photos/videos from their device's camera gallery on TigerConnect.",
      settingControl: (
        <StandardOrgSettingInput type="checkbox" settingName="isPatientPhotoVideoSharingDisabled" />
      ),
    },
    {
      dependsOnSettings: ['featurePatientAttachmentSharingDisabled'],
      label: 'Disable sharing attachments from device - TigerConnect Patient Engagement Only',
      subLabel:
        'This setting will disable users from sharing attachments from their device and cloud storage on TigerConnect.',
      settingControl: (
        <StandardOrgSettingInput type="checkbox" settingName="isPatientAttachmentSharingDisabled" />
      ),
    },
    {
      dependsOnSettings: ['featurePatientQuickAdd'],
      label: 'Patient Quick Add',
      subLabel: 'Allow users to quickly add a patient from their mobile application.',
      settingControl: (
        <StandardOrgSettingInput type="dropdown" settingName="patientQuickAddSetting" />
      ),
    },
    {
      dependsOnSettings: ['featurePatientQuickCall', ['patientEngagementSetting', 'all']],
      label: 'Patient Quick Call',
      subLabel:
        'Allow users to quickly dial any telephone number using masked cellular calling from their mobile application.',
      settingControl: (
        <StandardOrgSettingInput type="dropdown" settingName="patientQuickCallSetting" />
      ),
    },
    {
      dependsOnSettings: ['featurePatientQuickCallVideo'],
      label: 'Patient Quick Call Video',
      subLabel:
        'Allow users to quickly make video calls to patients from their mobile application.',
      settingControl: (
        <StandardOrgSettingInput type="dropdown" settingName="patientQuickCallVideoSetting" />
      ),
    },
    {
      dependsOnFeatures: ['pfVoiceCall'],
      dependsOnSettings: ['patientVoiceCallingSetting'],
      label: 'Voice Calling',
      subLabel:
        'Allow providers to make voice calls to patients. Patients cannot call providers directly.',
      settingControl: (
        <StandardOrgSettingInput type="dropdown" settingName="patientVoiceCallingSetting" />
      ),
    },
    {
      dependsOnFeatures: ['pfGroupVoiceCall'],
      dependsOnSettings: ['patientGroupVoiceCallingSetting'],
      label: 'Group Voice Calling',
      subLabel: 'Allow users to initiate a group voice call with patients.',
      settingControl: (
        <StandardOrgSettingInput type="dropdown" settingName="patientGroupVoiceCallingSetting" />
      ),
    },
    {
      dependsOnSettings: ['featurePatientVideoCalling'],
      label: 'Video Calling',
      subLabel:
        'Allow providers to make video calls to patients. Patients cannot call providers directly.',
      settingControl: (
        <StandardOrgSettingInput type="dropdown" settingName="patientVideoCallingSetting" />
      ),
    },
    {
      dependsOnSettings: ['patientVideoCallingSetting'],
      label: 'Video Calling Link Lifespan',
      subLabel:
        'This setting controls the duration of the timer expiration for the TigerTouch video SMS link.',
      settingControl: (
        <StandardOrgSettingInput
          options={PATIENT_VIDEO_LINK_TTL}
          type="slider"
          settingName="patientVideoCallingLinkLifespan"
        />
      ),
    },
    {
      dependsOnSettings: ['featurePatientGroupVideoCalling'],
      label: 'Group Video Calling',
      subLabel: 'Allow users to initiate a group video call with patients.',
      settingControl: (
        <StandardOrgSettingInput type="dropdown" settingName="patientGroupVideoCallingSetting" />
      ),
    },
    {
      dependsOnSettings: ['featurePatientListAccess'],
      label: 'Patient List Access',
      subLabel: 'Allow providers to access and update patient information in the Patient List',
      settingControl: (
        <StandardOrgSettingInput type="dropdown" settingName={'patientListAccessSetting'} />
      ),
    },
    {
      dependsOnSettings: ['featurePatientScheduledMessages'],
      label: 'Schedule Messages',
      subLabel:
        'Allow providers to schedule messages to patients. Needs TigerConnect Patient Engagement enabled for use.',
      settingControl: (
        <StandardOrgSettingInput type="dropdown" settingName="patientScheduledMessagesSetting" />
      ),
    },
    {
      dependsOnFeatures: ['allowAar', 'aar'],
      dependsOnSettings: ['featurePatientAppointmentReminders', 'patientScheduledMessagesSetting'],
      label: 'Automated Appointment Reminders',
      subLabel:
        'Allow providers to create appointments and automatically generate reminders. Needs Schedule Messages to be enabled for use.',
      settingControl: (
        <StandardOrgSettingInput
          type="checkbox"
          settingName="isPatientAppointmentRemindersEnabled"
        />
      ),
    },
    {
      dependsOnSettings: ['featurePatientBroadcasting'],
      label: 'Patient Broadcasting',
      subLabel:
        'Allow providers to broadcast messages to patients. Needs TigerConnect Patient Engagement enabled for use.',
      settingControl: (
        <StandardOrgSettingInput type="dropdown" settingName="patientBroadcastingSetting" />
      ),
    },
    {
      dependsOnSettings: ['featurePatientVirtualWaitingRoom'],
      label: 'Virtual Waiting Room',
      subLabel:
        'Route your patients to the virtual front door of your organization. A dashboard is provided to you to help guide patients to their appropriate services.',
      settingControl: (
        <StandardOrgSettingInput type="dropdown" settingName="patientVirtualWaitingRoomSetting" />
      ),
    },
  ];

  return (
    <>
      {tigerConnectSettingsListItems.map((settingProps) => (
        <AuthorizeOrgSetting
          key={settingProps.label}
          featureNames={settingProps.dependsOnFeatures ? settingProps.dependsOnFeatures : []}
          settingNames={settingProps.dependsOnSettings ? settingProps.dependsOnSettings : []}
        >
          <div key={settingProps.label} className={classNames(styles.individualSettingContainer)}>
            <SettingContainer {...settingProps} />
          </div>
        </AuthorizeOrgSetting>
      ))}
    </>
  );
}
