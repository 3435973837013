import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SearchQueryOptions, SearchResult, Tag } from '../../../../types';
import TCClient from '../../../../client';
import { ReduxState } from '../../../../redux-stores';
import BEM from '../../../bem';
import Dropdown from '../../Dropdown';
import CollaborationSearch from '../CollaborationSearch';
import TagItem from './TagItem';

const classes = BEM.with('CollaborationSelector');

type TagSelectorProps = {
  selectTag: (tag: Tag) => void;
  toggleHandler: () => void;
};

function TagSelector({ selectTag, toggleHandler }: TagSelectorProps) {
  const isRolesAdmin = useSelector((state: ReduxState) => state.collab.isRolesAdmin);
  const isTeamAdmin = useSelector((state: ReduxState) => state.collab.isTeamAdmin);
  const selectedOrgId = useSelector((state: ReduxState) => state.collab.selectedOrgId);
  const [results, setResults] = useState<Tag[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = event.target.value;
    setSearchValue(newVal);
    handleSearchQuery(newVal);
  };

  const clearSearch = () => {
    setSearchValue('');
    handleSearchQuery('');
  };

  const handleSearchQuery = useCallback(
    (val: string) => {
      const searchTags = async (val: string) => {
        const searchOptions: SearchQueryOptions = {
          version: 'SEARCH_PARITY',
          query: { name: val },
          organizationId: selectedOrgId,
          returnFields: ['color', 'color_value', 'name', 'token'],
          types: ['tag'],
          sort: ['name'],
        };
        const { results } = await TCClient.search.query<SearchResult>(searchOptions);
        const data = results.map((r: SearchResult) => r.entity as Tag);
        setResults(data);
      };
      searchTags(val);
    },
    [selectedOrgId]
  );

  useEffect(() => {
    handleSearchQuery('');
  }, [handleSearchQuery]);

  return (
    <div className={classes('', { large: true })}>
      <Dropdown ariaLabel={'tag-selector'} triggerHandler={toggleHandler}>
        <div>
          <div className={classes('search-container')}>
            <CollaborationSearch
              clearInputHandler={clearSearch}
              onChangeHandler={handleSearch}
              placeholder={'Search'}
              query={searchValue}
            />
          </div>
          <div
            className={classes('list-option')}
            onClick={() => selectTag({ name: '', id: '', color: '', colorId: '', entityCount: 0 })}
          >
            <TagItem
              isAdmin={isRolesAdmin || isTeamAdmin}
              name="-Not Tagged-"
              colorHex="transparent"
              noIcon
              hasBorder
            />
          </div>
          {results.map((tag: Tag) => (
            <div className={classes('list-option')} onClick={() => selectTag(tag)} key={tag.id}>
              <TagItem
                isAdmin={isRolesAdmin || isTeamAdmin}
                name={tag.name}
                colorHex={tag.color?.replace('0x', '#')}
              />
            </div>
          ))}
          {results.length === 50 && (
            <div className={classes('list-option')}>Please narrow down your search</div>
          )}
        </div>
      </Dropdown>
    </div>
  );
}

export default TagSelector;
