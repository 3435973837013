import React, { useEffect } from 'react';
import {
  DeliveryMethod as DeliveryMethodType,
  DeliveryMethods,
} from '../../../models/enums/DeliveryMethods';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import {
  DeliveryMethodIcon,
  OneWayDelivery,
  TwoWayDelivery,
} from '../PatientSettings/DeliveryMethodIcon';
import AccessibleList from '../AccessibleList';

const { LINK, SMS } = DeliveryMethods;
const classes = BEM.with('DeliveryMethodScheduledMessageMenu');
const menuItemClasses = BEM.with('DeliveryMethodScheduledMessageMenuItem');

type DeliveryMethodScheduledMessageMenuProps = {
  handleClick?: (deliveryMethod: DeliveryMethodType, noReply: boolean) => void;
  accessibilityMode?: boolean;
};

type MobxProps = {
  allowPatientDeliveryMethod?: boolean;
};

type DeliveryMethodProps = {
  copy: string;
  deliveryMethod: DeliveryMethodType;
  noReply: boolean;
  shouldShowArrows?: boolean;
  shouldShowIcon?: boolean;
} & DeliveryMethodScheduledMessageMenuProps;

const DeliveryMethodScheduledMessageMenu = ({
  handleClick,
  allowPatientDeliveryMethod,
  accessibilityMode,
}: DeliveryMethodScheduledMessageMenuProps & MobxProps) => {
  useEffect(() => {
    if (accessibilityMode) {
      (
        document.querySelector(
          '.tc-DeliveryMethodScheduledMessageMenuItem--clickable'
        ) as HTMLElement
      ).focus();
    }
  }, [accessibilityMode]);

  return (
    <AccessibleList
      className={classes()}
      focusableClasses={['.tc-DeliveryMethodScheduledMessageMenuItem--clickable']}
      focusStart={'first'}
      direction="vertical"
      loop={false}
      accessibilityMode={accessibilityMode}
    >
      <DeliveryMethod
        copy="Secure Browser Link: HIPAA-Compliant"
        deliveryMethod={LINK}
        noReply={false}
        handleClick={handleClick}
      />
      <DeliveryMethod
        copy="Secure Browser Link: HIPAA-Compliant"
        deliveryMethod={LINK}
        noReply={true}
        handleClick={handleClick}
      />
      {allowPatientDeliveryMethod && (
        <DeliveryMethod
          copy="Unsecure SMS Text: Non-HIPAA Compliant"
          deliveryMethod={SMS}
          noReply={true}
          handleClick={handleClick}
        />
      )}
    </AccessibleList>
  );
};

export const DeliveryMethod: React.FC<DeliveryMethodProps> = ({
  copy,
  deliveryMethod,
  noReply,
  handleClick,
  shouldShowArrows = true,
  shouldShowIcon = true,
}) => (
  <button
    onClick={() => handleClick && handleClick(deliveryMethod, noReply)}
    tabIndex={-1}
    className={menuItemClasses('', { clickable: !!handleClick })}
    onKeyDown={(e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
      }
      if (e.key === ' ' || e.key === 'Enter') {
        e.stopPropagation();
      }
    }}
  >
    {shouldShowIcon && <DeliveryMethodIcon isSecure={deliveryMethod === LINK} />}
    {copy}
    {!noReply && (
      <TwoWayDelivery className={menuItemClasses('arrows')} shouldShowArrows={shouldShowArrows} />
    )}
    {noReply && (
      <OneWayDelivery className={menuItemClasses('arrows')} shouldShowArrows={shouldShowArrows} />
    )}
  </button>
);

export default mobxInjectSelect<DeliveryMethodScheduledMessageMenuProps, MobxProps>({
  patientAdminStore: ['allowPatientDeliveryMethod'],
})(DeliveryMethodScheduledMessageMenu);
