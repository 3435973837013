import React, { useEffect, useState, useRef } from 'react';
import throttle from 'lodash.throttle';
import classNames from 'classnames';

import { Scrollbars } from 'tt-react-custom-scrollbars';
import useClickOutside from '../../hooks/useClickOutside';
import BEM from '../../bem';

import { ReactComponent as CheckmarkSvg } from '../../images/icon-dropdown-selector-copy.svg';
import { ReactComponent as SearchCancel } from '../../images/search-cancel.svg';
import UnreadBadgeCount from '../UnreadBadgeCount';

import { useAppSelector } from 'redux-stores';
import AccessibleList from 'common/components/AccessibleList';
const CHECKMARK_WIDTH = 10;
const PADDING_WIDTH = 10;

type ValueType = {
  name: string;
  id: string;
  optionIcon?: JSX.Element;
  isOptionBolded?: boolean;
  isMiniBadgeShown?: boolean;
  unreadMessages?: number;
};

type IndexedValueType = {
  index: number;
} & ValueType;

type DropdownListProps = {
  centeredItemSubText?: React.ReactElement;
  disabled?: boolean;
  handleDropdownClick?: () => void;
  primaryHeader?: JSX.Element;
  selectedValue: { id?: string };
  setSelectedValue: (arg0: IndexedValueType) => void;
  shouldScroll?: boolean;
  scrollHeight?: number;
  shouldShowSearch?: boolean;
  shouldShowCheckMark?: boolean;
  values: ValueType[];
  width: number;
};

const classes = BEM.with('DropdownList');

export default function DropdownList({
  centeredItemSubText,
  disabled = false,
  handleDropdownClick = () => {},
  primaryHeader,
  selectedValue,
  setSelectedValue,
  shouldScroll = false,
  scrollHeight = 400,
  shouldShowSearch = false,
  shouldShowCheckMark = true,
  values = [],
  width,
}: DropdownListProps) {
  const [isDropdownOpen, toggleDropdownOpen] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const clearSearchButtonRef = useRef<HTMLButtonElement>(null);
  const selectedRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState<JSX.Element[]>([]);

  const { accessibilityMode } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));
  useEffect(() => {
    let valuesToMap = values;

    if (shouldShowSearch && search) {
      const filtered = values.filter((v) => v.name.toLowerCase().includes(search.toLowerCase()));
      valuesToMap = filtered;
    }

    setDropdownOptions(
      valuesToMap.map(
        (
          {
            name,
            id,
            optionIcon,
            isOptionBolded = false,
            isMiniBadgeShown = false,
            unreadMessages = -1,
          }: ValueType,
          index: number
        ) => {
          const isSelected = id === selectedValue?.id;
          const maxWidth = isSelected
            ? width - (shouldShowCheckMark ? CHECKMARK_WIDTH : 0) - PADDING_WIDTH
            : width - PADDING_WIDTH;
          return (
            <div
              data-test-id={`dropdown-option-${index}`}
              className={classes('dropdown-option-container', { isSelected, isOptionBolded })}
              onClick={() => {
                setSelectedValue({ id, name, index });
                toggleDropdownOpen(false);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault();
                  setSelectedValue({ id, name, index });
                  toggleDropdownOpen(false);
                  if (accessibilityMode) buttonRef?.current?.focus();
                }
              }}
              role="button"
              key={id}
              style={{ maxWidth: `${maxWidth}px` }}
              ref={isSelected ? selectedRef : undefined}
            >
              {isMiniBadgeShown && <div className={classes('mini-badge')} />}
              {optionIcon}
              <p className={classes('dropdown-option-item')}>{name}</p>
              {unreadMessages > 0 && <UnreadBadgeCount count={unreadMessages} />}
              {shouldShowCheckMark && isSelected && (
                <CheckmarkSvg className={classes('checkmark-svg')} />
              )}
            </div>
          );
        }
      )
    );
  }, [
    shouldShowSearch,
    values,
    search,
    selectedValue,
    setSelectedValue,
    width,
    shouldShowCheckMark,
    accessibilityMode,
  ]);

  useEffect(() => {
    if (!isDropdownOpen) {
      setSearch('');
    }
  }, [isDropdownOpen]);

  useClickOutside([headerRef, buttonRef], () => {
    toggleDropdownOpen(false);
  });

  const THROTTLE_WAIT = 500;
  const handleSearch = throttle(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    THROTTLE_WAIT
  );
  const searchInput = (
    <div className={classes('search-container')}>
      <input
        className={classes('search')}
        placeholder="Search"
        value={search}
        onChange={handleSearch}
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            if (accessibilityMode && clearSearchButtonRef?.current && search.length) {
              e.preventDefault();
              clearSearchButtonRef?.current?.focus();
            }
          }
        }}
      />
      {search.length > 0 && (
        <button
          className={classes('search-icon-container')}
          onClick={(e) => {
            e.nativeEvent.stopImmediatePropagation();
            setSearch('');
            accessibilityMode && inputRef?.current?.focus();
          }}
          ref={clearSearchButtonRef}
          onKeyDown={(e) => {
            if (e.key === 'Tab') {
              if (e.shiftKey) {
                e.preventDefault();
                inputRef.current?.focus();
              }
            }
          }}
        >
          <SearchCancel className={classes('search-icon')} aria-hidden />
        </button>
      )}
    </div>
  );

  const noResults = (
    <div
      className={classNames(
        classes('dropdown-option-container'),
        classes({ isNoResultOption: true })
      )}
    >
      <p
        className={classNames(classes('dropdown-option-item'), classes({ isNoResultOption: true }))}
      >
        No results
      </p>
    </div>
  );

  const options = dropdownOptions.length ? dropdownOptions : [noResults];

  const disableHorizontalScroll = (props: { [k: string]: unknown }) => <div {...props} />;
  const scrollable = (
    <Scrollbars
      autoHeight
      autoHeightMax={scrollHeight}
      className={classes('scroll')}
      renderTrackHorizontal={disableHorizontalScroll}
    >
      {options}
    </Scrollbars>
  );

  const _onClickDropDown = async () => {
    if (!isDropdownOpen && handleDropdownClick) await handleDropdownClick();
    toggleDropdownOpen(!isDropdownOpen);
    if (accessibilityMode) {
      selectedRef.current?.setAttribute('tabIndex', '0');
      selectedRef.current?.focus();
    }
  };
  return (
    <div className={classes()} style={{ width: `${width}px` }}>
      <button
        className={classes('dropdown-button', { disabled })}
        disabled={disabled}
        onClick={async () => {
          _onClickDropDown();
        }}
        onKeyDown={async (e) => {
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
            _onClickDropDown();
          }
          if (e.key === 'Escape') {
            toggleDropdownOpen(false);
          }
        }}
        ref={buttonRef}
      >
        {primaryHeader}
        {centeredItemSubText}
      </button>

      {isDropdownOpen && (
        <AccessibleList
          accessibilityMode={accessibilityMode}
          focusableClasses={[
            '.tc-DropdownList__dropdown-option-container',
            '.tc-DropdownList__search',
          ]}
          setStartElementOnChange={true}
        >
          <div
            className={classes('dropdown-container')}
            ref={headerRef}
            onKeyDown={(event) => {
              if (event.key === 'Escape') {
                toggleDropdownOpen(false);
                buttonRef.current?.focus();
              }
              if (event.key === 'Tab') {
                event.preventDefault();
              }
            }}
          >
            {shouldShowSearch && searchInput}
            {shouldScroll ? scrollable : options}
          </div>
          <></>
        </AccessibleList>
      )}
    </div>
  );
}
