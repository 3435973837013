import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import propTypes from '../../../propTypes';
import { isSingleEmoji, mobxInjectSelect } from '../../../utils';
import { MessageSubTypes } from '../../../../models/enums/MessageSubTypes';
import { MessageAttachmentList } from '..';
import { UserName } from '../..';

const classes = BEM.with('MessageContent');

class MessageForwardBody extends Component {
  static propTypes = {
    currentUserId: PropTypes.string.isRequired,
    message: propTypes.message.isRequired,
    multiSelectable: PropTypes.bool.isRequired,
  };

  render() {
    const { bodyText, currentUserId, message, multiSelectable } = this.props;
    const { body, isForwardedFromPatientNetwork, originalSender, originalSenderRole, subType } =
      message;
    let forwardedDisplayNameFragment;
    let forwardedRoleAutomatedFragment;
    let role, user;

    if (subType === MessageSubTypes.ROLE_AWAY_NOTIFICATION) {
      role = originalSenderRole;
      user = originalSender;
      forwardedRoleAutomatedFragment = (
        <div className={classes('automated-message')}>Roles Automated Message</div>
      );
    } else if (
      subType === MessageSubTypes.EMPTY_ROLE_NOTIFICATION ||
      subType === MessageSubTypes.WORKFLOW_AUTOMATION_NOTIFICATION
    ) {
      forwardedDisplayNameFragment = 'Automated Message';
    } else if (originalSenderRole && originalSenderRole.displayName) {
      role = originalSenderRole;
    } else {
      user = originalSender;
    }

    if (role || user) {
      if (isForwardedFromPatientNetwork) {
        forwardedDisplayNameFragment = (
          <div className={classes('user-name')}>
            <UserName className={classes('user-name-patient')} user={user} />
            {user.isPatient && (
              <span
                className={classes('patient-name', {
                  isForwardedFromPatientNetwork,
                })}
              >
                (Patient)
              </span>
            )}
            {user.isPatientContact && (
              <span
                className={classes('patient-contact-relation', {
                  isForwardedFromPatientNetwork,
                })}
                title={user.relation}
              >
                ({user.relation})
              </span>
            )}
          </div>
        );
      } else {
        forwardedDisplayNameFragment = (
          <div className={classes('user-name')}>
            <UserName
              className={user && currentUserId !== user.id ? classes('user-name-link') : null}
              onClick={this._composeToOriginalSender}
              role={role}
              user={user}
            />
          </div>
        );
      }
    }

    return (
      <div className={classNames(classes('body'), classes({ 'body-type': 'forwarded' }))}>
        <div className={classes('body-forward-border')} />
        <div className={classes('body-forward-details')}>
          <div className={classes('body-forward-name')}>
            {forwardedRoleAutomatedFragment}
            {forwardedDisplayNameFragment}
          </div>
          <div className={classes('body-forward-text')}>
            <div className={classes(isSingleEmoji(body) ? 'emoji-text' : 'text-container')}>
              {bodyText}
            </div>
            {<MessageAttachmentList message={message} multiSelectable={multiSelectable} />}
          </div>
        </div>
      </div>
    );
  }

  _composeToOriginalSender = () => {
    const { currentUserId, composeToEntity, message } = this.props;
    const { originalSender, originalSenderRole } = message;

    if (originalSender && originalSender.id === currentUserId) return;
    composeToEntity(originalSenderRole || originalSender);
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['composeToEntity'],
  sessionStore: ['currentUserId'],
})(MessageForwardBody);
