import { useEffect, useState, useCallback } from 'react';

import BEM from '../bem';
import TCClient from '../../client';

import { ReactComponent as CloseButtonSvg } from '../images/close-icon.svg';

import { SearchQueryOptions, SearchResult, User, PBXTab, PBXLinesList } from '../../types';
import SearchTypes from '../../models/enums/SearchTypes';
import EntityAvatar from './EntityAvatar';

const classes = BEM.with('AssigneeSearchBox');

type AssigneeSearchBoxProps = {
  category: PBXTab;
  id: string;
  organizationId: string;
  pbxLinesList: PBXLinesList[];
  setIsAssigneeSearchBoxOpen: (isAssigneeSearchBoxOpen: boolean) => void;
  setPbxAssignee: (pbxAssignee: string) => void;
  setPbxLinesList: (pbxLinesList: PBXLinesList[]) => void;
  targetType: 'User' | 'Role' | 'NurseCall';
};

const AssigneeSearchBox = ({
  category,
  id,
  organizationId,
  pbxLinesList,
  setIsAssigneeSearchBoxOpen,
  setPbxAssignee,
  setPbxLinesList,
  targetType,
}: AssigneeSearchBoxProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [resultCount, setResultCount] = useState<number>();
  const [results, setResults] = useState<User[]>([]);
  const isRoleAssignee = category === 'Roles Lines';
  let searchResultFragment;

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = event.target.value;
    setSearchQuery(newVal);
    handleSearchQuery(newVal);
  };

  const handleSearchQuery = useCallback(
    (val: string) => {
      const searchTags = async (val: string) => {
        const searchOptions: SearchQueryOptions = {
          version: 'LEGACY',
          query: { name: val },
          organizationId: organizationId,
          types: [SearchTypes.USER],
          sort: ['firstName'],
          isNewAdminSearch: true,
          bool: {
            ...(!isRoleAssignee ? { must_not: { feature_service: 'role' } } : null),
            must: {
              'department,display_name,group_name,name,tag_names,title': val,
              ...(isRoleAssignee ? { feature_service: 'role' } : null),
            },
          },
        };

        const { results, metadata } = await TCClient.search.query<SearchResult>(searchOptions);
        setResultCount(metadata.totalHits);
        const data = results.map((r: SearchResult) => r.entity as User);
        setResults(data);
      };
      searchTags(val);
    },
    [organizationId, isRoleAssignee]
  );

  const setCurrentLinePbxAssignee = async (userId: string) => {
    await TCClient.pbx.assignPbxLine({
      pbxLineId: id,
      orgId: organizationId,
      targetId: userId,
      targetType,
    });
    setPbxAssignee(userId);
    const newPbxListContent = pbxLinesList.map((line: PBXLinesList) => {
      if (id === line.id) return { ...line, targetId: userId };
      return line;
    });
    setPbxLinesList([...newPbxListContent]);
  };

  useEffect(() => {
    handleSearchQuery('');
  }, [handleSearchQuery]);

  useEffect(() => {
    handleSearchQuery(searchQuery);
  }, [handleSearchQuery, searchQuery]);

  if (results.length > 0) {
    searchResultFragment = results.map((user: User) => (
      <div
        className={classes('search-outer')}
        key={user.id}
        onClick={async () => {
          setIsAssigneeSearchBoxOpen(false);
          setCurrentLinePbxAssignee(user.$entityType === 'user' ? user.id : user.id.substring(5));
        }}
      >
        <div className={classes('entity-svg')}>
          <EntityAvatar
            entity={user}
            indicatorSize={'SMALL'}
            showPresenceIndicator={true}
            size={25}
          />
        </div>
        <div className={classes('entity-info')}>
          <div className={classes('entity-name')}>{user.displayName}</div>
          <div className={classes('entity-details')}>
            {user.title && (
              <div className={classes('entity-title')} title={user.title}>
                {user.title}
              </div>
            )}
            {user.department && (
              <div className={classes('entity-department')}>| {user.department}</div>
            )}
          </div>
        </div>
      </div>
    ));
  } else {
    let noResultsText = 'No results found';
    if (searchQuery) {
      noResultsText += ` for "${searchQuery}"`;
    }

    searchResultFragment = <div className={classes('no-search-results')}>{noResultsText}</div>;
  }

  return (
    <div className={classes()}>
      <div className={classes('search-container')}>
        <div className={classes('search-text')}>
          <input
            aria-label="search bar"
            onChange={handleSearch}
            placeholder="Enter Name"
            type="text"
            value={searchQuery}
            autoFocus
          />
        </div>
        <div
          className={classes('close-container', {
            hide: searchQuery.length === 0,
          })}
          onClick={() => setSearchQuery('')}
        >
          <CloseButtonSvg />
        </div>
      </div>
      <div className={classes('individuals-tab')}> Individuals </div>
      <div className={classes('search-results')}>{searchResultFragment}</div>
      {resultCount && resultCount > 50 && (
        <div className={classes('limited-results-tab')}> Please narrow down your search </div>
      )}
    </div>
  );
};

export default AssigneeSearchBox;
