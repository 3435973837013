import React from 'react';
import { UnitsTab } from './Units';
import { LocationsTab } from './Locations';
import { useAppSelector } from 'redux-stores';

export function DirectoryContainer() {
  const selectedLocationButton = useAppSelector((state) => state.multiOrg.selectedLocationButton);

  return <div>{selectedLocationButton === 'Units' ? <UnitsTab /> : <LocationsTab />}</div>;
}
