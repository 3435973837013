import React, { useEffect, useState } from 'react';
import { trim } from 'lodash';
import { CollabEntity, User } from '../../../../types';
import { mobxInjectSelect } from '../../../utils';
import BEM from '../../../bem';
import UserAvatar from '../../UserAvatar/UserAvatar';

import { ReactComponent as DndIcon } from '../../../images/dnd-icon.svg';
import { ReactComponent as CheckIcon } from '../../../images/checkmark.svg';

const classes = BEM.with('CollaborationUserItem');

type UserItemProps = {
  avatarSize?: number;
  currentUserToken: string;
  entity?: CollabEntity;
  fontSize?: number;
  isEntityDetails?: boolean;
  isHovered?: boolean;
  isSearch?: boolean;
  maxWidth?: string;
  notOnDuty?: boolean;
  reverseItems?: boolean;
  showDnd?: boolean;
  showTitleAndDepartment?: boolean;
  user?: User;
};

type MobxProps = {
  findUser: (id: string) => User;
  getUserById: (id: string) => User;
};

function UserItem({
  currentUserToken,
  entity,
  findUser,
  getUserById,
  isEntityDetails = false,
  isHovered = false,
  isSearch = false,
  maxWidth = '100%',
  notOnDuty = false,
  reverseItems = false,
  showDnd = false,
  showTitleAndDepartment,
  user,
}: UserItemProps & MobxProps) {
  let fragment, isMemberAvatar;
  const { title, department } = user || {};
  const onDutyTileAndDepartment = [title, department]
    .filter((v: string | undefined) => v)
    .map((v: string | undefined) => trim(v))
    .join(', ');

  const entityType = entity?.$entityType;
  const onDuty = isEntityDetails && currentUserToken === user?.id && entityType === 'role';
  const [mobxUser, setMobxUser] = useState<User | undefined>(user);

  useEffect(() => {
    const getUser = async () => {
      if (user) {
        await findUser(user.id);
        const mobxUserStore = getUserById(user.id);
        setMobxUser(mobxUserStore);
      }
    };
    getUser();
  }, [findUser, getUserById, user]);

  if (entityType === 'team' && !isHovered)
    isMemberAvatar = (
      <div className={classes('team-check-icon')}>
        <CheckIcon />
      </div>
    );

  if (entityType !== 'team' && mobxUser && (onDuty || !isHovered))
    isMemberAvatar = (
      <UserAvatar
        className={onDuty ? classes('on-duty') : ''}
        indicatorSize={'SMALL'}
        isSearch={isSearch}
        showPresenceIndicator={true}
        size={32}
        user={mobxUser}
      />
    );

  if (onDuty) {
    fragment = <div className={classes('current-user-on-duty')}>{"You're on duty"}</div>;
  } else if (isEntityDetails && entityType === 'team' && !isHovered) {
    fragment = <div className={classes('current-user-in-team')}>{"You're in this team"}</div>;
  } else if (!isHovered) {
    fragment = (
      <div className={classes('user-details')}>
        <div className={classes('user-name-status')}>
          <div className={classes('user-name')} style={{ maxWidth }}>
            {mobxUser?.displayName}
          </div>
          {showDnd && mobxUser?.dnd && (
            <div className={classes('user-status')}>
              {'On do not disturb'}
              <DndIcon className={classes('dnd-icon')} />
            </div>
          )}
        </div>
        {showTitleAndDepartment && (
          <div className={classes('user-title')}>{onDutyTileAndDepartment}</div>
        )}
      </div>
    );
  }

  return (
    <div className={classes('', { showDnd })}>
      <div
        className={classes('user-item', {
          reverse: reverseItems,
        })}
        data-test-id="user item"
      >
        {notOnDuty && !isHovered ? (
          <div className={classes('no-one-on-duty')}>{'No one on duty'}</div>
        ) : (
          <>
            {isMemberAvatar}
            {fragment}
          </>
        )}
      </div>
    </div>
  );
}

export default mobxInjectSelect<UserItemProps, MobxProps>({
  userStore: ['findUser', 'getUserById'],
})(UserItem);
