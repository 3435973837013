import React, { useEffect, useRef, useState } from 'react';
import { Template } from '../../../types';
import BEM from '../../bem';
import {
  MessageTemplateRepositories,
  MessageTemplateRepository,
} from '../../../models/enums/MessageTemplateRepositories';
import { mobxInjectSelect } from '../../utils/';
import { MenuItem, MenuItemList } from '../ContextMenu';
import { ReactComponent as CheckSvg } from '../../images/selected-check.svg';
import AccessibleList from '../AccessibleList';
import TemplateList from './TemplateList';
import { KEYMAP } from 'common/constants';

type TemplateMenuProps = {
  allowDynamicTemplates?: boolean;
  disableCustom?: boolean;
  selectedTemplate: Template;
  setSelectedTemplate: (template: Template) => void;
  shouldFilterSms?: boolean;
  accessibilityMode?: boolean;
};

type MobxProps = {
  noneSelectedTemplate: Template;
};

const { GENERAL, PERSONAL, DYNAMIC } = MessageTemplateRepositories;
const classes = BEM.with('TemplateMenu');

const TemplateMenu: React.FC<TemplateMenuProps & MobxProps> = ({
  allowDynamicTemplates,
  disableCustom,
  noneSelectedTemplate,
  selectedTemplate,
  setSelectedTemplate,
  shouldFilterSms,
  accessibilityMode,
}) => {
  const [repository, setRepository] = useState<MessageTemplateRepository>(PERSONAL);
  const isGeneralRepository = repository === GENERAL;
  const isPersonalRepository = repository === PERSONAL;
  const isDynamicRepository = repository === DYNAMIC;
  const personalButtonRef = useRef<HTMLButtonElement | null>(null);
  const generalButtonRef = useRef<HTMLButtonElement | null>(null);
  const dynamicButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (selectedTemplate?.repository) {
      setRepository(selectedTemplate.repository);
      if (accessibilityMode) {
        if (!disableCustom) {
          const customButton = document.querySelectorAll('.tc-MenuItemList__menu-item')[0];
          customButton && (customButton as HTMLElement).focus();
          return;
        }
        if (selectedTemplate?.repository === PERSONAL) personalButtonRef?.current?.focus();
        if (selectedTemplate?.repository === GENERAL) generalButtonRef?.current?.focus();
        if (selectedTemplate?.repository === DYNAMIC) dynamicButtonRef?.current?.focus();
      }
    }
  }, [accessibilityMode, disableCustom, selectedTemplate]);

  return (
    <div
      className={classes('', { disableCustom })}
      onKeyDown={(e) => {
        if (accessibilityMode) {
          if (e.key === KEYMAP.TAB) {
            if (e.shiftKey) {
              e.preventDefault();
            }
            const ul = document.querySelector('.tc-MenuItemList__list');
            if (ul?.childElementCount === 0) {
              e.preventDefault();
            }
            const templateList = document.querySelector('[id*="templates-tabpanel"]');
            const tabButton = templateList?.querySelector('.tc-MenuItemList__menu-item');
            tabButton?.setAttribute('tabindex', '0');
          }
          if (e.key === KEYMAP.ESCAPE) {
            const parentButton = document.querySelector('.tc-MessageInputs__template-menu-btn');
            parentButton && (parentButton as HTMLElement).focus();
          }
        }
      }}
    >
      {!disableCustom && (
        <MenuItemList className={classes('menu-list')} accessibilityMode={accessibilityMode}>
          <MenuItem
            ariaLabel={noneSelectedTemplate.title}
            className={classes('menu-item', {
              selected: selectedTemplate.id === noneSelectedTemplate.id,
            })}
            onClick={() => {
              setSelectedTemplate(noneSelectedTemplate);
              if (accessibilityMode) {
                const parentButton = document.querySelector('.tc-MessageInputs__template-menu-btn');
                if (parentButton) {
                  (parentButton as HTMLElement).focus();
                }
              }
            }}
            preventDefault={false}
          >
            <div> {noneSelectedTemplate.title} </div>
            {selectedTemplate.id === noneSelectedTemplate.id && (
              <div>
                <CheckSvg />
              </div>
            )}
          </MenuItem>
        </MenuItemList>
      )}
      <AccessibleList
        className={classes('title-sect')}
        direction="horizontal"
        focusableClasses={['.tc-TemplateMenu__title-buttons']}
        accessibilityMode={accessibilityMode}
        role="tablist"
      >
        <div data-selectable={false} aria-hidden>
          <button
            ref={personalButtonRef}
            onClick={(event) => {
              event.nativeEvent.stopImmediatePropagation();
              setRepository(PERSONAL);
            }}
            className={classes('title-buttons', { isPersonalRepository })}
            data-test-id={'personal-templates-button'}
            id={`${PERSONAL}-templates-button`}
            role="tab"
            aria-controls={`${PERSONAL}-templates-tabpanel`}
            aria-selected={isPersonalRepository}
          >
            PERSONAL TEMPLATES
          </button>
        </div>
        <div data-selectable={false} aria-hidden>
          <button
            ref={generalButtonRef}
            onClick={(event) => {
              event.nativeEvent.stopImmediatePropagation();
              setRepository(GENERAL);
            }}
            className={classes('title-buttons', { isGeneralRepository })}
            data-test-id={'general-templates-button'}
            id={`${GENERAL}-templates-button`}
            role="tab"
            aria-controls={`${GENERAL}-templates-tabpanel`}
            aria-selected={isGeneralRepository}
          >
            GENERAL TEMPLATES
          </button>
        </div>
        {allowDynamicTemplates && (
          <div data-selectable={false} aria-hidden>
            <button
              ref={dynamicButtonRef}
              onClick={(event) => {
                event.nativeEvent.stopImmediatePropagation();
                setRepository(DYNAMIC);
              }}
              className={classes('title-buttons', { isDynamicRepository })}
              data-test-id={'dynamic-templates-button'}
              id={`${DYNAMIC}-templates-button`}
              role="tab"
              aria-controls={`${DYNAMIC}-templates-tabpanel`}
              aria-selected={isDynamicRepository}
            >
              DYNAMIC TEMPLATES
            </button>
          </div>
        )}
      </AccessibleList>
      <TemplateList
        repository={repository}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        shouldFilterSms={shouldFilterSms}
        accessibilityMode={accessibilityMode}
      />
    </div>
  );
};

export default mobxInjectSelect<TemplateMenuProps, MobxProps>({
  scheduleMessageStore: ['noneSelectedTemplate'],
})(TemplateMenu);
