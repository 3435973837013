import React from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import { ReactComponent as CancelIcon } from '../../../images/cancel.svg';

const classes = BEM.with('CollaborationInlineModal');

const InlineModal = ({ id, children, customStyle, dismissHandler, isTag }) => (
  <div className={classes('')} data-test-id={`inline-modal-${id}`}>
    <div className={classes('inline-modal', { isTag })}>
      {dismissHandler && (
        <div className={classes('dismiss-button-container')}>
          <div
            className={classes('dismiss-button')}
            onClick={dismissHandler}
            data-test-id="inline modal close"
          >
            <CancelIcon className={classes('cancel-icon')} />
          </div>
        </div>
      )}
      {children}
    </div>
  </div>
);

InlineModal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  customStyle: PropTypes.object,
  dismissHandler: PropTypes.func,
  isTag: PropTypes.bool,
};

InlineModal.defaultProps = {
  customStyle: {},
  dismissHandler: undefined,
  isTag: false,
};

export default InlineModal;
