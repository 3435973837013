import sorted from 'sorted-array-functions';

const has = Object.prototype.hasOwnProperty;
const NOT_PROVIDED = Symbol('NOT_PROVIDED');

type Options<T> = { previousValue: T };

export default class SortedArray<T = unknown> {
  cmp: sorted.CompareFn<T>;

  constructor(cmp: sorted.CompareFn) {
    this.cmp = cmp;
  }

  add(array: T[], element: T) {
    const idx = sorted.gt(array, element, this.cmp);
    if (idx !== -1) {
      array.splice(idx, 0, element);
    } else {
      array.push(element);
    }
  }

  indexOf(array: T[], element: T, options?: Options<T>) {
    const previousValue =
      options && has.call(options, 'previousValue') ? options.previousValue : NOT_PROVIDED;
    const len = array.length;
    const previousIsEquivalent =
      previousValue === NOT_PROVIDED || this.cmp(element, previousValue) === 0;

    if (previousIsEquivalent) {
      let idx = sorted.gt(array, element, this.cmp);
      idx = idx === -1 ? len - 1 : idx - 1;

      if (idx !== -1) {
        while (idx > 0 && this.cmp(array[idx - 1], element) === 0) {
          idx--;
        }

        while (idx < len && this.cmp(array[idx], element) === 0) {
          if (array[idx] === element) {
            return idx;
          }
          idx++;
        }
      }
    } else {
      let idx = 0;
      while (idx < len) {
        if (array[idx] === element) return idx;
        idx++;
      }
    }

    return -1;
  }

  remove(array: T[], element: T, options?: Options<T>) {
    const idx = this.indexOf(array, element, options);
    if (idx !== -1) {
      array.splice(idx, 1);
      return true;
    } else {
      return false;
    }
  }

  removeAll(array: T[], element: T, options?: Options<T>) {
    const previousValue =
      options && has.call(options, 'previousValue') ? options.previousValue : NOT_PROVIDED;
    let idx = this.indexOf(array, element, options);
    let removedAny = false;

    if (idx !== -1) {
      const previousIsEquivalent =
        previousValue === NOT_PROVIDED || this.cmp(element, previousValue) === 0;

      while (idx < array.length && (!previousIsEquivalent || this.cmp(array[idx], element) === 0)) {
        if (array[idx] === element) {
          array.splice(idx, 1);
          removedAny = true;
        } else {
          idx++;
        }
      }
    }

    return removedAny;
  }

  update(array: T[], element: T, options?: Options<T>) {
    const previousValue =
      options && has.call(options, 'previousValue') ? options.previousValue : NOT_PROVIDED;
    const previousIsEquivalent =
      previousValue === NOT_PROVIDED || this.cmp(element, previousValue) === 0;
    let idx = this.indexOf(array, element, options);

    if (idx !== -1) {
      if (previousIsEquivalent) {
        return false;
      }
      array.splice(idx, 1);
    }

    idx = sorted.gt(array, element, this.cmp);

    if (idx !== -1) {
      array.splice(idx, 0, element);
    } else {
      array.push(element);
    }

    return true;
  }
}
