import React, { useEffect, useState } from 'react';
import { get, partition } from 'lodash';
import BEM from '../../../../common/bem';
import { DotsIndicator } from '../../../../common/components';
import { mobxInjectSelect } from '../../../../common/utils';
import { CallLog, User } from '../../../../types';
import { CallLogViewDetails, CallLogViewMemberInfo } from './';

const classes = BEM.with('CallLogView');

type CallLogViewProps = {};

type MobxProps = {
  currentLog: CallLog;
  getUserInfo: (id: string) => Promise<User>;
  logContinuation: string;
};

function CallLogView({ currentLog, getUserInfo, logContinuation }: CallLogViewProps & MobxProps) {
  const [patientAndContacts, setPatientAndContacts] = useState<User[]>([]);
  const [providers, setProviders] = useState<User[]>([]);

  useEffect(() => {
    const fetchMembers = async () => {
      const memberIds = get(currentLog, 'target.entity.members', []);
      if (currentLog && !currentLog.isGroup) {
        memberIds.push(get(currentLog, 'target.entity.token'));
        memberIds.push(get(currentLog, 'caller.entity.token'));
      }
      const members: User[] = await Promise.all(memberIds.map(getUserInfo));
      const [patients, docs] = partition(members, (m: User) => m.isPatient || m.isPatientContact);
      setPatientAndContacts(patients);
      setProviders(docs);
    };
    fetchMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLog]);

  return (
    <div className={classes()}>
      <div className={classes('container', { isEmpty: !currentLog })}>
        {currentLog ? (
          <div>
            {!currentLog.isQuickCall && (
              <CallLogViewMemberInfo patientAndContacts={patientAndContacts} />
            )}
            {<CallLogViewDetails patientAndContacts={patientAndContacts} providers={providers} />}
          </div>
        ) : (
          <div className={classes('empty-message')}>
            {logContinuation ? `You have no recent calls` : <DotsIndicator />}
          </div>
        )}
      </div>
    </div>
  );
}

export default mobxInjectSelect<CallLogViewProps, MobxProps>({
  callStore: ['currentLog', 'getUserInfo', 'logContinuation'],
})(CallLogView);
