import React from 'react';
import ProviderBLProvider from '../../../contexts/BroadcastListSettings/context';
import ProviderBroadcastListsView from './ProviderBroadcastListsView';

export default function BroadcastListPage() {
  return (
    <ProviderBLProvider>
      <ProviderBroadcastListsView />
    </ProviderBLProvider>
  );
}
