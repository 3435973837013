export default class RequiresBasicAuthError extends Error {
  static CODE = 'requires-basic-auth';

  code: string;

  constructor(...args: (string | undefined)[]) {
    super(...args);
    this.code = RequiresBasicAuthError.CODE;
  }
}
