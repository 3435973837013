import React, { Component, Fragment } from 'react';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';

import { ReactComponent as CustomLabelingSvg } from '../../images/pa-custom-labeling.svg';
import { ReactComponent as DropDownSvg } from '../../images/pa-dropdown.svg';
import { ReactComponent as MessageTemplatesSvg } from '../../images/pa-message-templates.svg';
import { ReactComponent as PatientBroadcastListsSvg } from '../../images/pa-patient-broadcast-lists.svg';
import { ReactComponent as PatientsListSvg } from '../../images/pa-patients-list.svg';
import { ReactComponent as AutomatedMessagesSvg } from '../../images/automated-messages.svg';
import { ReactComponent as ScheduledMessagesSvg } from '../../images/pa-scheduled-messages.svg';
import { ReactComponent as VirtualWaitingRoomSvg } from '../../images/virtual-waiting-room-setting.svg';
import AccessibleList from '../AccessibleList';
import { PatientsList } from './PatientsList';
import { ScheduledMessages } from './ScheduledMessages';
import { MessageTemplates } from './MessageTemplates';
import { CustomLabeling } from './CustomLabeling';
import VirtualWaitingRoom from './VirtualWaitingRoom';
import PatientBroadcastLists from './PatientBroadcastLists';
import AutomatedMessages from './AutomatedMessages';
import ReduxEscapeHatch, { ReduxEscapeHatchRef } from 'common/components/ReduxEscapeHatch';
import {
  MessageTemplateRepositories,
  MessageTemplateRepository,
  MessageTemplateRepositoryLabels,
} from 'models/enums/MessageTemplateRepositories';
import PatientAdminPages, { PatientAdminPage } from 'models/enums/PatientAdminPages';

const { ALL, AUTOMATED, GENERAL, PERSONAL, DYNAMIC } = MessageTemplateRepositories;

const {
  AUTOMATED_MESSAGES,
  CUSTOM_LABELING,
  MESSAGE_TEMPLATES,
  PATIENT_BROADCAST_LISTS,
  PATIENTS_LIST,
  SCHEDULED_MESSAGES,
  VIRTUAL_WAITING_ROOM,
} = PatientAdminPages;

const classes = BEM.with('PatientSettings');

type PatientSettingsProps = {};

type MobxProps = {
  allowAutomatedAppointmentReminders: boolean;
  allowPatientBroadcastLists: boolean;
  allowPatientScheduledMessages: boolean;
  allowPatientListAccess: boolean;
  allowVirtualWaitingRoomSettings: boolean;
  currentPatientAdminPage: PatientAdminPage;
  setCurrentPatientAdminPage: (p: PatientAdminPage, messageTemplate?: string) => void;
  currentOrganizationId: string;
  getAdminRolesInOrganization: (id: string) => { isPatientAdmin: boolean };
  logPendoAnalytics: (p: { parentPathName: string; pathName: string }) => void;
};

type ScheduledMessagesFeed = 'personal' | 'all' | 'automated';

type PatientSettingsState = {
  scheduledMessagesExpanded: boolean;
  scheduledMessagesFeed: ScheduledMessagesFeed;
  templateRepository: MessageTemplateRepository;
  templatesExpanded: boolean;
  accessibilityMode: boolean;
};

class PatientSettings extends Component<PatientSettingsProps & MobxProps, PatientSettingsState> {
  constructor(props: PatientSettingsProps & MobxProps) {
    super(props);
    this.state = {
      scheduledMessagesExpanded: false,
      scheduledMessagesFeed: 'personal',
      templateRepository: PERSONAL,
      templatesExpanded: false,
      accessibilityMode: false,
    };
  }

  componentDidUpdate() {
    const { allowAutomatedAppointmentReminders, currentPatientAdminPage } = this.props;

    if (
      currentPatientAdminPage === MESSAGE_TEMPLATES &&
      this.state.templateRepository === DYNAMIC &&
      !allowAutomatedAppointmentReminders
    ) {
      this.setState({
        templateRepository: PERSONAL,
      });
    } else if (
      currentPatientAdminPage === SCHEDULED_MESSAGES &&
      this.state.scheduledMessagesFeed === 'automated' &&
      !allowAutomatedAppointmentReminders
    ) {
      this.setState({
        scheduledMessagesFeed: 'personal',
      });
    }
  }

  currentAdminPageRefresher: null | (() => void) = null;
  private reduxEscapeHatch: ReduxEscapeHatchRef | null = null;
  setCurrentAdminPageRefresher = (fn: () => void) => {
    this.currentAdminPageRefresher = fn;
  };

  refreshCurrentAdminPage = () => {
    if (this.currentAdminPageRefresher) {
      this.currentAdminPageRefresher();
    }
  };

  render() {
    const {
      scheduledMessagesExpanded,
      scheduledMessagesFeed,
      templateRepository,
      templatesExpanded,
    } = this.state;
    const {
      allowAutomatedAppointmentReminders,
      allowPatientBroadcastLists,
      allowPatientScheduledMessages,
      allowPatientListAccess,
      allowVirtualWaitingRoomSettings,
      currentPatientAdminPage,
      currentOrganizationId,
      getAdminRolesInOrganization,
      logPendoAnalytics,
      setCurrentPatientAdminPage,
    } = this.props;

    const { isPatientAdmin } = getAdminRolesInOrganization(currentOrganizationId);

    const selectTabAndRefresh = (p: PatientAdminPage, subTab?: string) => {
      setCurrentPatientAdminPage(p, subTab);
      this.refreshCurrentAdminPage();
    };

    let adminPage: React.FC | React.ReactElement = <></>;
    this.setCurrentAdminPageRefresher(() => {});
    if (currentPatientAdminPage === PATIENTS_LIST) {
      adminPage = (
        <PatientsList setCurrentAdminPageRefresher={this.setCurrentAdminPageRefresher.bind(this)} />
      );
    } else if (currentPatientAdminPage === AUTOMATED_MESSAGES) {
      adminPage = <AutomatedMessages />;
    } else if (currentPatientAdminPage === SCHEDULED_MESSAGES) {
      adminPage = (
        <ScheduledMessages
          feed={scheduledMessagesFeed}
          isAdmin={isPatientAdmin}
          setCurrentAdminPageRefresher={this.setCurrentAdminPageRefresher.bind(this)}
        />
      );
    } else if (currentPatientAdminPage === PATIENT_BROADCAST_LISTS) {
      adminPage = (
        <PatientBroadcastLists
          isAdmin={isPatientAdmin}
          setCurrentAdminPageRefresher={this.setCurrentAdminPageRefresher.bind(this)}
        />
      );
    } else if (currentPatientAdminPage === MESSAGE_TEMPLATES) {
      adminPage = (
        <MessageTemplates
          repository={templateRepository}
          isAdmin={isPatientAdmin}
          logPendoAnalytics={logPendoAnalytics}
          setCurrentAdminPageRefresher={this.setCurrentAdminPageRefresher.bind(this)}
        />
      );
    } else if (currentPatientAdminPage === CUSTOM_LABELING && isPatientAdmin) {
      adminPage = <CustomLabeling />;
    } else if (currentPatientAdminPage === VIRTUAL_WAITING_ROOM) {
      adminPage = <VirtualWaitingRoom />;
    }

    return (
      <div className={classes()}>
        <nav role="navigation" aria-labelledby="nav-title" className={classes('nav')}>
          <AccessibleList
            direction={'vertical'}
            focusableClasses={['.tc-PatientSettings__nav-item']}
            focusStart={'first'}
            loop={false}
            accessibilityMode={this.state.accessibilityMode}
          >
            <h2 className={classes('nav-title')} id="nav-title">
              Patient Settings
            </h2>
            <div className={classes('nav-items')}>
              {(isPatientAdmin || allowPatientListAccess) && (
                <button
                  className={classes('nav-item', {
                    active: currentPatientAdminPage === PATIENTS_LIST,
                  })}
                  onClick={() => {
                    selectTabAndRefresh(PATIENTS_LIST);
                  }}
                  data-test-id={'patients-list'}
                >
                  <PatientsListSvg aria-hidden className={classes('nav-item-icon')} /> Patients List
                </button>
              )}
              {isPatientAdmin && allowAutomatedAppointmentReminders && (
                <button
                  className={classes('nav-item', {
                    active: currentPatientAdminPage === AUTOMATED_MESSAGES,
                  })}
                  onClick={() => {
                    selectTabAndRefresh(AUTOMATED_MESSAGES);
                  }}
                  data-test-id={'automated-messages'}
                >
                  <AutomatedMessagesSvg aria-hidden className={classes('nav-item-icon')} />
                  Automated Messages
                </button>
              )}
              {allowPatientScheduledMessages && (
                <Fragment>
                  <button
                    className={classes('nav-item', {
                      active:
                        !isPatientAdmin &&
                        currentPatientAdminPage === SCHEDULED_MESSAGES &&
                        scheduledMessagesFeed === 'personal',
                    })}
                    onClick={(e) => {
                      this._openScheduledMessagesTab(e);
                      this.refreshCurrentAdminPage();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'ArrowLeft') {
                        this._showScheduleMessagesTab(e, false);
                      }
                      if (e.key === 'ArrowRight') {
                        this._showScheduleMessagesTab(e);
                      }
                    }}
                    onFocus={(e) => {
                      this._showScheduleMessagesTab(e);
                    }}
                    data-test-id={'scheduled-messages'}
                  >
                    <ScheduledMessagesSvg aria-hidden /> Scheduled Messages{' '}
                    {isPatientAdmin && (
                      <DropDownSvg
                        aria-hidden
                        className={classes('nav-item-dropdown', {
                          expanded: scheduledMessagesExpanded,
                        })}
                        onClick={this._toggleScheduledMessagesTab}
                      />
                    )}
                  </button>
                  {scheduledMessagesExpanded && (
                    <div>
                      <button
                        tabIndex={-1}
                        className={classes('nav-item', {
                          active:
                            currentPatientAdminPage === SCHEDULED_MESSAGES &&
                            scheduledMessagesFeed === 'personal',
                          menu: true,
                          scheduledMessagesExpanded,
                        })}
                        onClick={() => {
                          this.setState(
                            {
                              scheduledMessagesFeed: 'personal',
                            },
                            () => {
                              selectTabAndRefresh(
                                SCHEDULED_MESSAGES,
                                MessageTemplateRepositoryLabels[PERSONAL]
                              );
                            }
                          );
                        }}
                        data-test-id={'scheduled-messages-personal'}
                      >
                        Personal
                      </button>
                      <button
                        tabIndex={-1}
                        className={classes('nav-item', {
                          active:
                            currentPatientAdminPage === SCHEDULED_MESSAGES &&
                            scheduledMessagesFeed === 'all',
                          menu: true,
                          scheduledMessagesExpanded,
                        })}
                        onClick={() => {
                          this.setState(
                            {
                              scheduledMessagesFeed: 'all',
                            },
                            () => {
                              selectTabAndRefresh(
                                SCHEDULED_MESSAGES,
                                MessageTemplateRepositoryLabels[ALL]
                              );
                            }
                          );
                        }}
                        data-test-id={'scheduled-messages-admin'}
                      >
                        Admin
                      </button>
                      {isPatientAdmin && allowAutomatedAppointmentReminders && (
                        <button
                          tabIndex={-1}
                          className={classes('nav-item', {
                            active:
                              currentPatientAdminPage === SCHEDULED_MESSAGES &&
                              scheduledMessagesFeed === 'automated',
                            menu: true,
                            scheduledMessagesExpanded,
                          })}
                          onClick={() => {
                            this.setState(
                              {
                                scheduledMessagesFeed: 'automated',
                              },
                              () => {
                                selectTabAndRefresh(
                                  SCHEDULED_MESSAGES,
                                  MessageTemplateRepositoryLabels[AUTOMATED]
                                );
                              }
                            );
                          }}
                          data-test-id={'scheduled-messages-automated'}
                        >
                          Automated
                        </button>
                      )}
                    </div>
                  )}
                </Fragment>
              )}
              {allowPatientBroadcastLists && (
                <button
                  className={classes('nav-item', {
                    active: currentPatientAdminPage === PATIENT_BROADCAST_LISTS,
                  })}
                  onClick={() => {
                    selectTabAndRefresh(PATIENT_BROADCAST_LISTS);
                  }}
                  data-test-id={'patient-broadcast-lists'}
                >
                  <PatientBroadcastListsSvg aria-hidden className={classes('nav-item-icon')} />
                  Patient Broadcast Lists
                </button>
              )}
              {allowPatientScheduledMessages && (
                <Fragment>
                  <button
                    className={classes('nav-item')}
                    onClick={(e) => {
                      this._openMessageTemplatesTab(e);
                      this.refreshCurrentAdminPage();
                    }}
                    onFocus={(e) => {
                      this._showMessageTemplateTab(e);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'ArrowLeft') {
                        this._showMessageTemplateTab(e, false);
                      }
                      if (e.key === 'ArrowRight') {
                        this._showMessageTemplateTab(e);
                      }
                    }}
                    data-test-id={'message-templates'}
                  >
                    <MessageTemplatesSvg aria-hidden className={classes('nav-item-icon')} /> Message
                    Templates
                    <DropDownSvg
                      aria-hidden
                      className={classes('nav-item-dropdown', {
                        expanded: templatesExpanded,
                      })}
                      onClick={this._toggleMessageTemplatesTab}
                    />
                  </button>
                  {templatesExpanded && (
                    <div>
                      <button
                        tabIndex={-1}
                        className={classes('nav-item', {
                          active:
                            currentPatientAdminPage === MESSAGE_TEMPLATES &&
                            templateRepository === PERSONAL,
                          menu: true,
                          templatesExpanded,
                        })}
                        onClick={() => {
                          this.setState(
                            {
                              templateRepository: PERSONAL,
                            },
                            () => {
                              selectTabAndRefresh(
                                MESSAGE_TEMPLATES,
                                MessageTemplateRepositoryLabels[PERSONAL]
                              );
                            }
                          );
                        }}
                        data-test-id={'message-templates-personal'}
                      >
                        Personal
                      </button>
                      <button
                        tabIndex={-1}
                        className={classes('nav-item', {
                          active:
                            currentPatientAdminPage === MESSAGE_TEMPLATES &&
                            templateRepository === GENERAL,
                          menu: true,
                          templatesExpanded,
                        })}
                        onClick={() => {
                          this.setState(
                            {
                              templateRepository: GENERAL,
                            },
                            () => {
                              selectTabAndRefresh(
                                MESSAGE_TEMPLATES,
                                MessageTemplateRepositoryLabels[GENERAL]
                              );
                            }
                          );
                        }}
                        data-test-id={'message-templates-general'}
                      >
                        General
                      </button>
                      {allowAutomatedAppointmentReminders && (
                        <button
                          tabIndex={-1}
                          className={classes('nav-item', {
                            active:
                              currentPatientAdminPage === MESSAGE_TEMPLATES &&
                              templateRepository === DYNAMIC,
                            menu: true,
                            templatesExpanded,
                          })}
                          onClick={() => {
                            this.setState(
                              {
                                templateRepository: DYNAMIC,
                              },
                              () => {
                                selectTabAndRefresh(
                                  MESSAGE_TEMPLATES,
                                  MessageTemplateRepositoryLabels[DYNAMIC]
                                );
                              }
                            );
                          }}
                          data-test-id={'message-templates-dynamic'}
                        >
                          Dynamic
                        </button>
                      )}
                    </div>
                  )}
                </Fragment>
              )}
              {isPatientAdmin && (
                <button
                  className={classes('nav-item', {
                    active: currentPatientAdminPage === CUSTOM_LABELING,
                  })}
                  onClick={() => setCurrentPatientAdminPage(CUSTOM_LABELING)}
                  data-test-id={'custom-labeling'}
                >
                  <CustomLabelingSvg aria-hidden /> Custom Labeling
                </button>
              )}
              {allowVirtualWaitingRoomSettings && (
                <button
                  className={classes('nav-item', {
                    active: currentPatientAdminPage === VIRTUAL_WAITING_ROOM,
                  })}
                  onClick={() => setCurrentPatientAdminPage(VIRTUAL_WAITING_ROOM)}
                  data-test-id={'virtual-waiting-room'}
                >
                  <VirtualWaitingRoomSvg aria-hidden /> Virtual Waiting Room
                </button>
              )}
            </div>
          </AccessibleList>
        </nav>
        <ReduxEscapeHatch
          ref={(ref) => {
            this.reduxEscapeHatch = ref as ReduxEscapeHatchRef;
            if (
              this.reduxEscapeHatch &&
              this.reduxEscapeHatch?.accessibilityMode !== this.state.accessibilityMode
            ) {
              this.setState({
                accessibilityMode: this.reduxEscapeHatch?.accessibilityMode,
              });
            }
          }}
        />
        <main role="main" aria-labelledby="patient-settings-heading" className={classes('body')}>
          {adminPage}
        </main>
      </div>
    );
  }

  _showMessageTemplateTab = (event: React.FocusEvent | React.KeyboardEvent, status = true) => {
    event.stopPropagation();
    this.setState({ templatesExpanded: status });
  };

  _showScheduleMessagesTab = (event: React.FocusEvent | React.KeyboardEvent, status = true) => {
    event.stopPropagation();
    this.setState({
      scheduledMessagesExpanded: status,
    });
  };

  _openMessageTemplatesTab = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
    const { currentPatientAdminPage, setCurrentPatientAdminPage } = this.props;
    this.setState({ templatesExpanded: true });

    if (currentPatientAdminPage !== MESSAGE_TEMPLATES) {
      this.setState({ templateRepository: PERSONAL });
      setCurrentPatientAdminPage(MESSAGE_TEMPLATES);
    }
  };

  _toggleMessageTemplatesTab = (event: React.MouseEvent) => {
    event.stopPropagation();
    const { templatesExpanded } = this.state;
    const { currentPatientAdminPage, setCurrentPatientAdminPage } = this.props;
    const isExpanding = !templatesExpanded;
    this.setState({ templatesExpanded: isExpanding });

    if (isExpanding && currentPatientAdminPage !== MESSAGE_TEMPLATES) {
      this.setState({ templateRepository: PERSONAL });
      setCurrentPatientAdminPage(MESSAGE_TEMPLATES);
    }
  };

  _openScheduledMessagesTab = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
    const {
      currentOrganizationId,
      currentPatientAdminPage,
      getAdminRolesInOrganization,
      setCurrentPatientAdminPage,
    } = this.props;
    const { isPatientAdmin } = getAdminRolesInOrganization(currentOrganizationId);
    if (!isPatientAdmin) {
      this.setState({
        scheduledMessagesExpanded: false,
        scheduledMessagesFeed: 'personal',
      });
      setCurrentPatientAdminPage(SCHEDULED_MESSAGES);
      return;
    }

    this.setState({ scheduledMessagesExpanded: true });

    if (currentPatientAdminPage !== SCHEDULED_MESSAGES) {
      this.setState({ scheduledMessagesFeed: 'personal' });
      setCurrentPatientAdminPage(SCHEDULED_MESSAGES);
    }
  };

  _toggleScheduledMessagesTab = (event: React.MouseEvent) => {
    event.stopPropagation();
    const {
      currentOrganizationId,
      currentPatientAdminPage,
      getAdminRolesInOrganization,
      setCurrentPatientAdminPage,
    } = this.props;
    const { isPatientAdmin } = getAdminRolesInOrganization(currentOrganizationId);
    if (!isPatientAdmin) {
      this.setState({
        scheduledMessagesExpanded: false,
        scheduledMessagesFeed: 'personal',
      });
      setCurrentPatientAdminPage(SCHEDULED_MESSAGES);
      return;
    }

    const { scheduledMessagesExpanded } = this.state;
    const isExpanding = !scheduledMessagesExpanded;
    this.setState({ scheduledMessagesExpanded: isExpanding });

    if (isExpanding && currentPatientAdminPage !== SCHEDULED_MESSAGES) {
      this.setState({ scheduledMessagesFeed: 'personal' });
      setCurrentPatientAdminPage(SCHEDULED_MESSAGES);
    }
  };
}

export default mobxInjectSelect<PatientSettingsProps, MobxProps>({
  messengerStore: ['currentOrganizationId', 'getAdminRolesInOrganization'],
  patientAdminStore: [
    'allowAutomatedAppointmentReminders',
    'allowPatientBroadcastLists',
    'allowPatientScheduledMessages',
    'allowPatientListAccess',
    'allowVirtualWaitingRoomSettings',
    'currentPatientAdminPage',
    'setCurrentPatientAdminPage',
  ],
  trackerStore: ['logPendoAnalytics'],
})(PatientSettings);
