import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Organization, Role, RoleMetadata, SaveRoleProps, Tag, User } from '../../../../types';
import BEM from '../../../bem';
import { mobxInjectSelect } from '../../../utils';
import { DotsIndicator } from '../..';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { defaultNewRole } from '../../../../redux-stores/Collaboration/roles';
import EntityEditorActionButtons from '../Entities/EntityEditorActionButtons';
import EntityEditorCreateButtons from '../Entities/EntityEditorCreateButtons';
import EntityEditorDescription from '../Entities/EntityEditorDescription';
import EntityEditorName from '../Entities/EntityEditorName';
import EntityEditorTag from '../Entities/EntityEditorTag';
import RoleEditorAutomatedMessage from './RoleEditorAutomatedMessage';
import RoleEditorEscalation from './RoleEditorEscalation';
import RoleEditorOnDuty from './RoleEditorOnDuty';
import RoleEditorPager from './RoleEditorPager';
import RoleEditorSwitchButtons from './RoleEditorSwitchButtons';
import RoleEditorAutoResponse from './RoleEditorAutoResponse';
import RoleEditorHandoff from './RoleEditorHandoff';
import RoleEditorDetails from './RoleEditorDetails';

const { setModal, setEntityNameError } = actions;
const { batchEntityUpdate, createRole } = thunk;
const classes = BEM.with('CollaborationEntityEditor');

const DEFAULT_MESSAGE = 'There is currently no one on duty for';

type MobxProps = {
  composeToEntityV2: (entity: Role) => void;
  currentOrganization: Organization;
  currentRoles: Role[];
  currentUser: User;
};

type RoleEditorProps = {
  defaultTag?: Tag;
  role?: Role;
};

function RoleEditor({
  composeToEntityV2,
  currentOrganization,
  currentRoles,
  currentUser,
  defaultTag,
  role: roleProp,
}: RoleEditorProps & MobxProps) {
  const dispatch = useDispatch();
  const activeTab = useSelector((state: ReduxState) => state.entities.activeTab);
  const entityNameError = useSelector((state: ReduxState) => state.entities.entityNameError);
  const checkedTagsById = useSelector((state: ReduxState) => state.tags.checkedTagsById);
  const isRolesAdmin = useSelector((state: ReduxState) => state.collab.isRolesAdmin);
  const isSavingRole = useSelector((state: ReduxState) => state.entities.isSavingEntity);
  const selectedOrgId = useSelector((state: ReduxState) => state.collab.selectedOrgId);
  const selectedRole = useSelector((state: ReduxState) => state.entities.selectedEntity) as Role;
  const entitiesById = useSelector((state: ReduxState) => state.entities.entitiesById);
  const newEntityType = useSelector((state: ReduxState) => state.entities.newEntityType);
  const savedRoleIds = useSelector((state: ReduxState) => state.roles.savedRoleIds);
  const tagsById = useSelector((state: ReduxState) => state.tags.tagsById);
  const selectedCategory = useSelector((state: ReduxState) => state.tags.selectedCategory);
  const selectedTag = useSelector((state: ReduxState) => state.tags.selectedTag);

  const [role, setRole] = useState<Role>(defaultNewRole);

  const isNewRole = newEntityType === 'role';
  const { canEscalateMessages: canEscalate, roleTransition } = currentOrganization || {};
  const editorDiv = useRef<HTMLDivElement>(null);
  const { id: roleId } = roleProp || {};
  const { metadata } = role || {};
  const isCurrentUserRoleOwner = role?.members?.map((m) => m.id).includes(currentUser.id);

  const isRolesTransitionEnabledForOrg =
    roleTransition === 'all' || roleTransition === 'individual';

  useEffect(() => {
    if (isNewRole && defaultTag) {
      setRole({
        ...defaultNewRole,
        metadata: {
          ...(defaultNewRole.metadata as RoleMetadata),
          tag_name: defaultTag?.name || '',
          tag_hex: defaultTag?.color,
          tag_id: defaultTag?.id.split(':')[1] || '',
          tag_color: defaultTag?.color?.replace('#', '0x') || '',
        },
      });
    } else if (!isNewRole && roleProp) {
      setRole(roleProp);
    } else {
      setRole(defaultNewRole);
    }
  }, [defaultTag, isNewRole, roleProp]);

  useEffect(() => {
    if (editorDiv.current) editorDiv.current.scrollTop = 0;
  }, [roleId]);

  const saveRole = (
    {
      description,
      displayName,
      doesRoleTransitionExcludePrivateGroups = role?.metadata?.exclude_private_group,
      emptyDescription,
      noOwnersMessage,
      isRoleTransitionEnabled = role?.metadata?.role_transition,
      openAssignment = role?.metadata?.open_assignment,
      ownerRequired = role?.metadata?.owner_required,
      tagColor,
      tagName,
      tagId,
    }: SaveRoleProps,
    persist = !isNewRole
  ) => {
    let roleNoOwnerMessage;
    if (noOwnersMessage) {
      roleNoOwnerMessage = noOwnersMessage;
    } else if (role?.metadata?.no_owners_message) {
      roleNoOwnerMessage = role?.metadata.no_owners_message;
    } else {
      roleNoOwnerMessage = `${DEFAULT_MESSAGE} ${displayName || role?.displayName}`;
    }
    const updateRoleName = displayName?.trim() || role?.displayName.trim();
    const newRole = {
      description: emptyDescription ? '' : description || role?.description,
      displayName: updateRoleName,
      doesRoleTransitionExcludePrivateGroups,
      escalationPolicy: role?.metadata?.escalation_policy || {},
      name: updateRoleName,
      noOwnersMessage: roleNoOwnerMessage,
      openAssignment,
      isRoleTransitionEnabled,
      ownerRequired,
      owners: role?.members?.length ? [role?.members?.[0].id] : undefined,
      replayHistory: role?.metadata?.replay_history,
      role_transition: role?.metadata?.role_transition,
      tagColor: tagColor || role?.metadata?.tag_color,
      tagName: tagName || role?.metadata?.tag_name,
      tagId: tagId || role?.metadata?.tag_id,
      id: role?.id,
    };

    if (roleTransition) {
      if (roleTransition === 'all') {
        if (doesRoleTransitionExcludePrivateGroups === true) {
          newRole.isRoleTransitionEnabled = true;
        } else if (doesRoleTransitionExcludePrivateGroups === false) {
          newRole.isRoleTransitionEnabled = isRoleTransitionEnabled;
        } else {
          newRole.isRoleTransitionEnabled = false;
        }
      } else if (roleTransition === 'individual') {
        if (!newRole.id) {
          newRole.isRoleTransitionEnabled = false;
        } else {
          newRole.isRoleTransitionEnabled = isRoleTransitionEnabled;
        }
        newRole.doesRoleTransitionExcludePrivateGroups = doesRoleTransitionExcludePrivateGroups;
      }
      newRole.role_transition = newRole.isRoleTransitionEnabled;
    }

    if (tagColor === '' && tagName === '' && tagId === '') {
      newRole.tagColor = '';
      newRole.tagName = '';
      newRole.tagId = '';
    }

    if (!persist) {
      setRole({ ...role, displayName: displayName || '' });
      return;
    }

    if (!role.displayName?.trim()) {
      dispatch(setEntityNameError(true));
      dispatch(actions.setEntityNameErrorMessage('Role name cannot be empty.'));
      return;
    }

    if (!isSavingRole) {
      if (newRole.id) {
        batchEntityUpdate(dispatch, {
          activeTab,
          checkedTagsById,
          ids: [newRole.id],
          organizationId: selectedOrgId,
          entitiesById,
          entityUpdateProps: newRole,
          selectedCategory,
          selectedEntity: selectedRole,
          selectedTag,
          tagsById,
        });
      } else {
        createRole(dispatch, {
          activeTab,
          checkedTagsById,
          organizationId: selectedOrgId,
          roleInput: newRole,
          selectedCategory,
          selectedTag,
          entitiesById,
          tagsById,
        });
      }
    }
  };

  if (!roleProp?.id && !isNewRole) {
    return <div />;
  }

  const actionButtons = [
    {
      title: 'Message Role',
      id: 'message-role',
      isVisible: () => !isCurrentUserRoleOwner && !isNewRole,
      action: () => role && composeToEntityV2(role),
    },
  ];

  return (
    <div
      className={classes('')}
      style={{ padding: isNewRole ? '8px' : '0' }}
      ref={editorDiv}
      data-test-id="role info pane"
    >
      <div className={classes('editor-header', { isNewRole })}>
        <EntityEditorName
          entity={role}
          updateEntity={saveRole}
          isAdmin={isRolesAdmin}
          entityType={'role'}
          savedIds={savedRoleIds}
        />
        {isNewRole && (
          <EntityEditorCreateButtons
            type="role"
            onCreate={() => saveRole({}, true)}
            createEnabled={entityNameError ? false : !!role.displayName}
          />
        )}
        <EntityEditorActionButtons buttons={actionButtons} isLoading={isSavingRole} />
        {isCurrentUserRoleOwner && <RoleEditorAutoResponse role={role} />}
      </div>
      <div className={classes('cards-container')}>
        {!isNewRole && (
          <>
            <EntityEditorTag
              isAdmin={isRolesAdmin}
              tagColor={metadata?.tag_color || ''}
              tagName={metadata?.tag_name || ''}
            />
            <RoleEditorOnDuty currentRoles={currentRoles} currentUser={currentUser} role={role} />
            {isRolesTransitionEnabledForOrg && !isRolesAdmin && (
              <RoleEditorDetails role={role} roleTransition={roleTransition || ''} />
            )}
            <EntityEditorDescription
              key={`description_${role?.id}`}
              entity={role}
              isAdmin={isRolesAdmin}
              saveEntity={saveRole}
            />
            {isRolesAdmin && role && (
              <>
                <RoleEditorAutomatedMessage
                  defaultMessage={DEFAULT_MESSAGE}
                  key={`automatedMessage_${role?.id}`}
                  role={role}
                  saveRole={saveRole}
                />
                <RoleEditorSwitchButtons
                  hasOwner={role?.members && role.members.length > 0}
                  openAssignment={!!metadata?.open_assignment}
                  ownerRequired={!!metadata?.owner_required}
                  saveRole={saveRole}
                />
                {isRolesTransitionEnabledForOrg && (
                  <RoleEditorHandoff
                    excludePrivateGroup={!!metadata?.exclude_private_group}
                    isAdmin={isRolesAdmin}
                    role={role}
                    roleTransition={roleTransition || ''}
                    saveRole={saveRole}
                  />
                )}
                <RoleEditorPager role={role} />
                {canEscalate && <RoleEditorEscalation role={role} />}
                <button
                  className={classes('delete-button')}
                  data-test-id="open delete role modal"
                  onClick={() => dispatch(setModal({ name: 'deleteEntity' }))}
                >
                  Delete Role
                </button>
              </>
            )}
          </>
        )}
        {isSavingRole && (
          <>
            <div className={classes('loading-overlay-container')} />
            {isSavingRole && (
              <div className={classes('loading-container')}>
                <DotsIndicator color="#828282" size={7} marginRight={6} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default mobxInjectSelect<RoleEditorProps, MobxProps>({
  composeMessageStore: ['composeToEntityV2'],
  messengerStore: ['currentOrganization'],
  roleStore: ['currentRoles'],
  sessionStore: ['currentUser'],
})(RoleEditor);
