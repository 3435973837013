import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import propTypes from '../propTypes';
import BEM from '../bem';
const classes = BEM.with('TeamName');

const TeamName = ({ ariaLabel, className, team, ...restProps }) => {
  className = classNames(classes(), className);

  return (
    <span className={className} aria-label={ariaLabel} title={team.displayName} {...restProps}>
      {team.displayName}
    </span>
  );
};

TeamName.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  team: propTypes.team.isRequired,
};

export default observer(TeamName);
