import BaseService from './BaseService';

export default class PbxService extends BaseService {
  async assignPbxLine({
    pbxLineId,
    orgId,
    targetId,
    targetType,
  }: {
    pbxLineId: string;
    orgId: string;
    targetId: string;
    targetType: string;
  }) {
    return this.host.api.pbx.assignPbxLine({
      pbxLineId,
      orgId,
      targetId,
      targetType,
    });
  }

  async retrieveNurseCallStatus({ orgId }: { orgId: string }) {
    return this.host.api.pbx.retrieveNurseCallStatus({
      orgId,
    });
  }

  async batchUnAssignPbxLines({
    lineType,
    orgId,
    pbxLineIds,
    targetIds,
  }: {
    lineType: string;
    orgId: string;
    pbxLineIds: string[];
    targetIds?: string[];
  }) {
    return this.host.api.pbx.batchUnAssignPbxLines({
      lineType,
      orgId,
      pbxLineIds,
      targetIds,
    });
  }

  async getPbxLineDetails({ orgId, pbxLineId }: { orgId: string; pbxLineId: string }) {
    return this.host.api.pbx.getPbxLineDetails({
      pbxLineId,
      orgId,
    });
  }

  async unAssignPbxLine({
    lineType,
    orgId,
    pbxLineId,
    targetIds,
  }: {
    lineType: string;
    orgId: string;
    pbxLineId: string;
    targetIds?: string[];
  }) {
    return this.host.api.pbx.unAssignPbxLine({
      lineType,
      orgId,
      pbxLineId,
      targetIds,
    });
  }

  async batchAssignUsersToNCLines({ targetIds, orgId }: { targetIds: string[]; orgId: string }) {
    return this.host.api.pbx.batchAssignUsersToNCLines({
      targetIds,
      orgId,
    });
  }

  async getPbxLines({
    page,
    orgId,
    size,
    targetType,
  }: {
    orgId: string;
    page: number;
    size: number;
    targetType: 'User' | 'Role' | 'NurseCall';
  }) {
    return this.host.api.pbx.getPbxLines({
      page,
      orgId,
      size,
      targetType,
    });
  }

  async uploadPbxLinesViaCsv({
    currentOrganizationId,
    email,
    file,
    systemConfigurationId,
  }: {
    currentOrganizationId: string;
    email: string;
    file: File;
    systemConfigurationId: string;
  }) {
    const headers = this.httpClient.getAuthHeaders();
    headers['TT-X-Organization-Key'] = currentOrganizationId;

    return this.host.api.pbx.uploadPbxLinesViaCsv({
      systemConfigurationId,
      email,
      headers,
      file,
    });
  }

  async getPbxSystems({
    currentOrganizationId,
    containNurseCall,
  }: {
    currentOrganizationId: string;
    containNurseCall: boolean;
  }) {
    return this.host.api.pbx.getPbxSystems({ currentOrganizationId, containNurseCall });
  }
}
