import React, { useEffect, useRef, useState } from 'react';

import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import type { SerializedError } from '@reduxjs/toolkit';
import { SDKQueryErrorType, SearchArgType, SearchResults } from 'types/api';
import { useAppSelector } from 'redux-stores';

export type RTKQueryResponse<Result> = {
  data?: SearchResults<Result>;
  error?: SDKQueryErrorType | SerializedError;
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
};

type RTKQueryHook<Result> = (
  args: SearchArgType,
  { skip }: { skip: boolean }
) => RTKQueryResponse<Result>;

type Options = {
  ref?: React.RefObject<unknown>;
  customScrollFn?: React.MutableRefObject<
    ((e: React.UIEvent<HTMLElement>) => void) | ((e: React.ChangeEvent<HTMLElement>) => void) | null
  >;
};

const CUSTOM_SCROLLFN_RATIO = 0.5;
const CUSTOM_SCROLLFN_THROTTLE = 20;
const DEFAULT_DEBOUNCEFN_TIME = 300;

export const useRTKQueryInfiniteResults = <Result>(
  RTKQueryHook: RTKQueryHook<Result>,
  { args }: { args: SearchArgType },
  options?: Options
) => {
  const currentOrganizationId = useAppSelector((state) => state.session.currentOrganizationId);
  const { ref, customScrollFn } = options || {};
  const [currentSearchValue, setCurrentSearchValue] = useState('');
  const [currentContinuation, setCurrentContinuation] = useState('');
  const [shouldSkipQuery, setShouldSkipQuery] = useState(false);

  const prevContinuationRef = useRef('');

  const handleScrollFn = useRef(
    throttle((event) => {
      if (!event.currentTarget) return;
      const { clientHeight, scrollHeight, scrollTop } = event.currentTarget;
      if (clientHeight / (scrollHeight - scrollTop) > CUSTOM_SCROLLFN_RATIO) {
        setCurrentContinuation(prevContinuationRef.current);
      }
    }, CUSTOM_SCROLLFN_THROTTLE)
  );
  if (customScrollFn) customScrollFn.current = handleScrollFn.current;

  useEffect(
    function addScrollListenerIfRefPassedIn() {
      const element = ref?.current as HTMLElement;
      const handleScroll = handleScrollFn.current;

      if (element) {
        element.addEventListener('scroll', handleScroll);
      }
      return () => {
        if (element) {
          element.removeEventListener('scroll', handleScroll);
        }
      };
    },
    [ref]
  );

  const debounceInputFn = useRef(
    debounce(() => {
      setShouldSkipQuery(false);
    }, DEFAULT_DEBOUNCEFN_TIME)
  );

  if (args.searchValue !== currentSearchValue) {
    prevContinuationRef.current = '';
    setCurrentSearchValue(args.searchValue as string);
    setCurrentContinuation('');
    setShouldSkipQuery(true);

    debounceInputFn.current();
  }

  function loadNextPage() {
    if (prevContinuationRef.current !== currentContinuation) {
      setCurrentContinuation(prevContinuationRef.current);
    }
  }

  const { data, error, isFetching } = RTKQueryHook(
    {
      searchValue: currentSearchValue,
      orgId: args.orgId || currentOrganizationId,
      continuation: !!currentContinuation ? currentContinuation : undefined,
      conversationId: args.conversationId,
    },
    { skip: shouldSkipQuery }
  );

  const { results, metadata } = data || {};
  if (metadata?.continuation) {
    prevContinuationRef.current = metadata.continuation;
  }

  return {
    results,
    response: data,
    error,
    isFetching,
    loadNextPage,
  };
};
