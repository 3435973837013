import React from 'react';

import { AdminStateSlice } from '../../types';

import spriteIcons from '../../images/sprite_icons.png';
import spriteIconsDark from '../../images/sprite_icons_dark.png';
import hospitalIcon from '../../../common/images/hospital.svg';
import { mobxInjectSelect } from '../../../common/utils';
import LeftNavIcon from './LeftNavIcon';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

const { setSelectedPage, setViewToShow } = actions;

type LeftNavItemProps = {
  backgroundPosition: string;
  dataTestId?: string;
  height: number;
  label: AdminStateSlice['selectedPage'];
  onClick?: () => void;
  visible?: boolean;
  width: number;
};

type MobxProps = {
  logPendoAnalytics: (p: { parentPathName: string; pathName: string }) => void;
  isDarkModeOn: boolean;
};

function LeftNavItem({
  label,
  width,
  height,
  backgroundPosition,
  dataTestId,
  logPendoAnalytics,
  isDarkModeOn,
  onClick,
  visible = true,
}: LeftNavItemProps & MobxProps) {
  const selectedPage = useAppSelector((state) => state.admin.selectedPage);
  const dispatch = useAppDispatch();
  const isSelected = selectedPage === label;
  const opacity = isSelected ? '.7' : '.3';
  const background =
    label === 'Locations'
      ? `url(${hospitalIcon}) no-repeat left center`
      : isDarkModeOn
      ? `url(${spriteIconsDark}) no-repeat left center`
      : `url(${spriteIcons}) no-repeat left center`;

  if (!visible) return null;

  return (
    <LeftNavIcon
      onClick={() => {
        dispatch(setSelectedPage({ selectedPage: label }));
        dispatch(setViewToShow({ viewToShow: '' }));
        dispatch(actions.setSelectedLocationButton({ selectedLocationButton: 'Locations' }));
        dispatch(actions.setLocationsDataDirty({ isLocationsDataDirty: true }));
        logPendoAnalytics({ parentPathName: 'Settings', pathName: `${label}` });
        onClick?.();
      }}
      label={label}
      isSelected={isSelected}
      dataTestId={dataTestId}
    >
      <div
        style={{
          background,
          backgroundPosition,
          width: `${width}px`,
          height: `${height}px`,
          opacity,
          color: 'var(--neutral-1000)',
        }}
      />
      <div
        style={{
          color: 'var(--neutral-1000)',
          opacity,
          fontFamily: 'Open Sans,sans-serif',
          fontSize: '10px',
          width: '80px',
          textAlign: 'center',
          marginTop: '3px',
        }}
      >
        {label}
      </div>
    </LeftNavIcon>
  );
}

export default mobxInjectSelect<LeftNavItemProps, MobxProps>({
  trackerStore: ['logPendoAnalytics'],
  messengerStore: ['isDarkModeOn'],
})(LeftNavItem);
