import React from 'react';
import { Team } from '../../types';
import { mobxInjectSelect } from '../utils';
import { UndoAction } from '.';

type UndoSavedTeamProps = {};

type MobxProps = {
  setUndoTeam: (id?: string) => {};
  undoTeam: Team;
  undoUnsave: () => {};
};

function UndoSavedTeam({ setUndoTeam, undoTeam, undoUnsave }: UndoSavedTeamProps & MobxProps) {
  if (!undoTeam) return <div />;

  return (
    <UndoAction
      removeButton={() => setUndoTeam(undefined)}
      text={`Removed from favorites: ${undoTeam.displayName}`}
      undoAction={undoUnsave}
    />
  );
}

export default mobxInjectSelect<UndoSavedTeamProps, MobxProps>({
  teamStore: ['setUndoTeam', 'undoUnsave', 'undoTeam'],
})(UndoSavedTeam);
