import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../../../utils';
import Modal from '../../Modal';

import BEM from '../../../bem';

const classes = BEM.with('ForceLogoutModal');

class ForceLogoutModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    signOutAllDevices: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('close-button')}
        header="Logout"
        headerClass={classes('header')}
        isOpen={isOpen}
        onAfterOpen={this._onAfterOpen}
        onRequestClose={this._requestClose}
        size={'medium'}
        footerPrimaryActions={
          <button type="button" className={classes('logout-btn')} onClick={this._onLogout}>
            LOGOUT OF ALL DEVICES
          </button>
        }
        footerSecondaryActions={
          <div>
            <button type="button" onClick={this._requestClose} className={classes('cancel-btn')}>
              CANCEL
            </button>
          </div>
        }
      >
        <div className={classes('description-container')}>
          <span>
            You are about to log out of all devices currently using your account, are you sure you
            want to proceed?
          </span>
        </div>
      </Modal>
    );
  }

  _onLogout = () => {
    this.props.closeModal();
    this.props.signOutAllDevices();
  };

  _requestClose = () => {
    this.props.closeModal();
  };
}

export default mobxInjectSelect({
  sessionStore: ['signOutAllDevices'],
})(ForceLogoutModal);
