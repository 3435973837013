import qs from 'qs';

export const params: Record<string, string> = {};

export function refreshParams() {
  for (const prop of Object.keys(params)) {
    delete params[prop];
  }
  const newParams = qs.parse(window.location.search.slice(1));
  Object.assign(params, newParams);

  return params;
}

refreshParams();
