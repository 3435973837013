import { action, observable, runInAction } from 'mobx';
import { downloadFile } from '../common/utils';
import { SearchTypes } from '../models/enums';
import { Organization, QueryReponse, Role, TCClient } from '../types';

type Stores = {
  messengerStore: { currentOrganization: Organization };
  modalStore: { openModal: (modalType: string) => void };
};

export default class CollaborationStore {
  client: TCClient;
  stores: Stores;
  @observable collabReloadKey = 0;
  @observable openModal: string | undefined = undefined;

  constructor({ client, stores }: { client: TCClient; stores: Stores }) {
    this.client = client;
    this.stores = stores;
  }

  mounted() {}

  @action('CollaborationStore.exportRolesScheduleUpload') exportRolesScheduleUpload = async () => {
    const { modalStore } = this.stores;
    const currentOrganization = this.stores.messengerStore.currentOrganization;
    try {
      const response = await this.client.roles.findRolesSchedulerCsv(currentOrganization.id);
      downloadFile(`Schedule-Upload-${currentOrganization.name}.csv`, 'text/csv', response);
    } catch (e) {
      runInAction(() => {
        modalStore.openModal('failure');
      });
    }
  };

  @action('CollaborationStore.exportRolesDetails') exportRolesDetails = async () => {
    const { modalStore, messengerStore } = this.stores;
    const currentOrganization = messengerStore.currentOrganization;
    let continuation: string | undefined = '';
    let output = 'Name,Token\r\n';

    try {
      while (continuation !== undefined) {
        const response: QueryReponse = await this.client.search.query({
          continuation,
          query: { name: '' },
          organizationId: currentOrganization.id,
          types: [SearchTypes.USER],
          isNewAdminSearch: true,
          bool: { must: { feature_service: 'role' } },
          version: 'LEGACY',
        });

        continuation = response.metadata.continuation;
        output += response.results
          .map(({ entity }: { entity: Role }) => `${entity.displayName},${entity.botUserId}\r\n`)
          .join('');
      }

      downloadFile(`Roles-${currentOrganization.name}.csv`, 'text/csv', output);
    } catch (e) {
      runInAction(() => {
        modalStore.openModal('failure');
      });
    }
  };

  @action('CollaborationStore.reloadCollaboration') reloadCollaboration = () => {
    this.collabReloadKey++;
  };
}
