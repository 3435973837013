import NetworkStatusMessages from '../../../models/enums/NetworkStatusMessages';
import { mobxInjectSelect } from 'common/utils';
import { DotsIndicator } from 'common/components';
import BEM from 'common/bem';

const classes = BEM.with('OptInBanner');
type OptInBannerProps = {};
type MobxProps = {
  optinRoleNames: string[];
  isOptingIntoRole: boolean;
  hideOptinBanner: () => void;
  messageVisible: boolean;
  networkStatus?: keyof typeof NetworkStatusMessages;
};
function OptInBanner({
  optinRoleNames,
  isOptingIntoRole,
  hideOptinBanner,
  messageVisible,
  networkStatus,
}: OptInBannerProps & MobxProps) {
  const commaSeparatedRoleNames = optinRoleNames.join(', ');
  if (
    !isOptingIntoRole ||
    (messageVisible && networkStatus && NetworkStatusMessages[networkStatus])
  ) {
    return null;
  }
  return (
    <div title={commaSeparatedRoleNames} className={classes('')}>
      <DotsIndicator size={12} color={'#8798A4'} />
      <div className={classes('opt-in-text')}>Opting into {commaSeparatedRoleNames}</div>
      <div className={classes('hide-button')} onClick={hideOptinBanner}>
        Hide
      </div>
    </div>
  );
}

export default mobxInjectSelect<OptInBannerProps, MobxProps>({
  networkStatusStore: ['messageVisible', 'networkStatus'],
  roleStore: ['optinRoleNames', 'isOptingIntoRole', 'hideOptinBanner'],
})(OptInBanner);
