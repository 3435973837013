import React from 'react';
import classNames from 'classnames';
import BEM from '../../bem';
import { KEYMAP } from 'common/constants';
const classes = BEM.with('MenuItemList');

type MenuItemProps = {
  onClick?: (event: React.SyntheticEvent) => void;
  className?: string;
  isSelected?: boolean;
  ariaLabel?: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  preventDefault?: boolean;
};

const MenuItem: React.FC<MenuItemProps> = ({
  onClick,
  className = '',
  children,
  isSelected = false,
  ariaLabel = '',
  onKeyDown,
  preventDefault = true,
}) => {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(e);
      }}
      onKeyDown={(e) => {
        if (e.key === KEYMAP.TAB) {
          preventDefault && e.preventDefault();
        }
        if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
          e.stopPropagation();
        }
        onKeyDown?.(e);
      }}
      className={classNames(classes(className, { isSelected }), className)}
      aria-label={ariaLabel}
    >
      <span>{children}</span>
    </button>
  );
};

export default MenuItem;
