import { MutableRefObject } from 'react';
import { Role, User } from '../../types';

type UserAndRoleTypes = (
  | { entity_type: 'user'; values: User[] }
  | { entity_type: 'role'; values: Role[] }
)[];

export const groupByUserAndRoleEntities = (
  data: (User | Role)[],
  sortOrder: MutableRefObject<Map<string, number>>
): UserAndRoleTypes => {
  const mapped = data.reduce(
    (all, current) => {
      const key = current.$entityType as 'user' | 'role';
      const isRoleBot = (current as User).isRoleBot;
      if (key === 'role' || isRoleBot) all.role.values.push(current as Role);
      else if (key === 'user' && !isRoleBot) all.user.values.push(current as User);
      return all;
    },
    {
      user: { entity_type: 'user', values: [] },
      role: { entity_type: 'role', values: [] },
    } as {
      user: { entity_type: 'user'; values: User[] };
      role: { entity_type: 'role'; values: Role[] };
    }
  );
  const groups = Object.values(mapped);
  if (sortOrder.current.size > 0) {
    groups.sort((x, y) => {
      const xValue = sortOrder.current.get(x.entity_type) || 0;
      const yValue = sortOrder.current.get(y.entity_type) || 0;
      if (xValue < yValue) return -1;
      if (xValue > yValue) return 1;
      return 0;
    });
  }
  return groups;
};
