import React from 'react';
import { Card, Switch } from '../../../components/WebComponents';
import BEM from '../../../bem';
import { TeamUpdateProps, Team } from '../../../../types';
const classes = BEM.with('CollaborationEntityEditor');

type TeamEditorToggleOptionsProps = {
  isTeamAdmin: boolean;
  team: Team | undefined;
  saveTeam: (props: TeamUpdateProps) => void;
};

export default function TeamEditorToggleOptions({
  isTeamAdmin,
  team,
  saveTeam,
}: TeamEditorToggleOptionsProps) {
  const canRequestToJoin = team?.canRequestToJoin || false;
  const canMembersLeave = team?.canMembersLeave || false;

  const updateTeamToggle = async (canRequestToJoin: boolean, canMembersLeave: boolean) => {
    saveTeam({
      canRequestToJoin: canRequestToJoin,
      canMembersLeave: canMembersLeave,
    });
  };

  return (
    <div className={classes('card-container')}>
      <Card header="TEAM OPTIONS">
        <div className={classes('switch-block')}>
          <div className={classes('switch-item')}>
            <span>Anyone can request to join</span>
            <Switch
              checked={canRequestToJoin}
              disabled={!isTeamAdmin}
              dataTestId="joinRequest"
              onSwitch={() => {
                updateTeamToggle(!canRequestToJoin, canMembersLeave);
              }}
            />
          </div>
          <div className={classes('switch-item')}>
            <span>Allow members to leave this team</span>
            <Switch
              checked={canMembersLeave}
              disabled={!isTeamAdmin}
              dataTestId="leaveTeam"
              onSwitch={() => {
                updateTeamToggle(canRequestToJoin, !canMembersLeave);
              }}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
