import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { highlightText } from '../utils';

class TagIndicator extends Component {
  static propTypes = {
    classes: PropTypes.func.isRequired,
    tag: PropTypes.shape({
      color: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }).isRequired,
    searchWords: PropTypes.arrayOf(PropTypes.string),
  };

  render() {
    const { classes, tag, searchWords } = this.props;
    let tagName = tag.displayName;

    if (searchWords && searchWords.length > 0) {
      tagName = highlightText(tagName, searchWords, classes);
    }

    return (
      <div className={classes('role-tag-details')}>
        <span className={classes('tag-indicator')} style={{ backgroundColor: tag.color }} />
        <span className={classes('tag-name')}>{tagName}</span>
      </div>
    );
  }
}

export default observer(TagIndicator);
