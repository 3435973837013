import { observable } from 'mobx';
import createMobxModel from './createMobxModel';

const EscalationExecution = createMobxModel({
  name: 'escalationExecution',

  fields: [
    'actionUserName',
    'actionTime',
    'additionalTargets',
    'currentStep',
    'displayId',
    'expiresAt',
    'status',
    'targetMinutesToAcknowledge',
  ],

  objectFields: {
    path: {
      clone(entityList) {
        const results = [];

        if (entityList) {
          for (const { minutesToAcknowledge, targets } of entityList) {
            const targetGroup = { minutesToAcknowledge };
            Object.defineProperty(targetGroup, 'targets', {
              enumerable: true,
              get() {
                return targets;
              },
            });
            results.push(targetGroup);
          }
        }

        return observable.array(results);
      },
    },
  },

  relations: {
    one: {
      carbonCopyMessage: { type: 'message' },
      group: { type: 'group' },
      originalMessage: { type: 'message' },
      target: { type: 'user' },
    },
  },
});

export default EscalationExecution;
