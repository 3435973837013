export const filterOutCorruptedMentionsData = (
  payload: {
    entity_id?: string;
    entity_type?: string;
    start: number;
    end: number;
  }[],
  body: string
) => {
  return payload.filter(
    (metadata) =>
      metadata.entity_type &&
      metadata.entity_id &&
      metadata.start < body.length &&
      metadata.end <= body.length &&
      metadata.end > metadata.start
  );
};
