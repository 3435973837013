import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BEM from '../../../bem';
import { Tag } from '../../../../types';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import { actions, ReduxState } from '../../../../redux-stores';
import { CollaborationCategories } from '../../../../models/enums';
import TagItem from './TagItem';
const { NOTTAGGED } = CollaborationCategories;

const { setModal, setTagBeingEdited } = actions;
const classes = BEM.with('CollaborationRoles');

type SelectedTagsProps = {
  checkedTagsById: { [id: string]: Tag };
};

export default function SelectedTags({ checkedTagsById }: SelectedTagsProps) {
  const dispatch = useDispatch();
  const isRolesAdmin = useSelector((state: ReduxState) => state.collab.isRolesAdmin);
  const isTeamAdmin = useSelector((state: ReduxState) => state.collab.isTeamAdmin);

  const openTagModal = (tag?: Tag) => {
    dispatch(setModal({ name: 'tag' }));
    dispatch(setTagBeingEdited({ tag }));
  };

  const isSingleTag = Object.keys(checkedTagsById).length === 1;
  let checkedTagsElements = null;
  if (isRolesAdmin || isTeamAdmin) {
    checkedTagsElements = Object.values(checkedTagsById).map((tag) => {
      if (tag.id.includes(NOTTAGGED)) {
        return (
          <div className={classes('selected-tag')} key={tag.id}>
            <TagItem
              name={NOTTAGGED}
              customStyles={'tag-selected-name'}
              colorHex="none"
              useType="display"
            />
          </div>
        );
      }
      return (
        <ToolTip text="Edit Tag" textAlign={'center'} key={tag.id}>
          <div className={classes('selected-tag')} onClick={() => openTagModal(tag)}>
            <TagItem
              colorHex={tag?.color}
              name={tag?.name || ''}
              isAdmin={true}
              customStyles={'tag-selected-name-with-hover'}
              isOverflow={isSingleTag}
            />
          </div>
        </ToolTip>
      );
    });
  } else {
    checkedTagsElements = Object.values(checkedTagsById).map((tag) => {
      return (
        <div className={classes('selected-tag')} key={tag.id}>
          <TagItem
            colorHex={tag?.color}
            name={tag?.name || ''}
            customStyles={'tag-selected-name'}
            isOverflow={isSingleTag}
            useType="display"
          />
        </div>
      );
    });
  }

  return (
    <div
      className={classes('selected-tag-container', {
        single: isSingleTag,
      })}
      data-test-id="Selected Tags"
    >
      {checkedTagsElements}
    </div>
  );
}
