import React, { useCallback, useEffect, useRef } from 'react';
import BEM from '../bem';

import { useScrollListener } from '../hooks/useScrollListener';

const classes = BEM.with('Dropdown');

type DropdownProps = {
  ariaLabel: string;
  children: React.ReactElement;
  handleScroll?: (e: React.ChangeEvent<HTMLElement>) => void;
  triggerHandler: (e: MouseEvent) => void;
  resize?: string;
  triggerRef?: React.RefObject<HTMLElement>;
};

export default function Dropdown({
  ariaLabel,
  children,
  handleScroll,
  triggerHandler,
  resize,
  triggerRef,
}: DropdownProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const closeDropdown = useCallback(
    (e) => {
      const { target } = e;
      if (
        (containerRef.current && containerRef.current.contains(target)) ||
        (triggerRef && triggerRef.current && triggerRef.current.contains(target))
      ) {
        return;
      }
      triggerHandler(e);
    },
    [triggerHandler, triggerRef]
  );

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown, false);
    return () => {
      document.removeEventListener('mousedown', closeDropdown, false);
    };
  }, [closeDropdown, triggerHandler]);

  useScrollListener(containerRef.current, handleScroll || (() => {}));

  return (
    <div
      className={classes('', { resize: resize })}
      ref={containerRef}
      aria-label={'select from dropdown'}
      data-test-id={`dropdown-${ariaLabel}`}
    >
      {children}
    </div>
  );
}
