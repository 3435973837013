import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Images from '../../models/enums/Images';
import { GROUP_MEMBER_SEARCH_TYPES } from '../../models/enums/SearchTypes';
import BEM from '../bem';
import propTypes from '../propTypes';
import { mobxInjectSelect } from '../utils';
import RoleSelector from './Roles/RoleSelector';
import { DotsIndicator, Modal, RecipientSearchPicker } from './';
import { GroupAvatarDropzone } from 'common/components/GroupAvatarDropzone';

const classes = BEM.with('NewGroupModal');

class NewGroupModal extends Component {
  static propTypes = {
    allowedSenders: propTypes.userOrRoleArray.isRequired,
    avatarPreviewUrl: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    createGroup: PropTypes.func.isRequired,
    createInProgress: PropTypes.bool.isRequired,
    currentOrganization: propTypes.organization.isRequired,
    currentConversationId: PropTypes.string,
    fetchSetters: PropTypes.func.isRequired,
    forumDescription: PropTypes.string.isRequired,
    forumMembers: propTypes.counterPartyArray.isRequired,
    forumName: PropTypes.string,
    getUserById: PropTypes.func.isRequired,
    groupSenderId: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    isTeamActivation: PropTypes.bool,
    newGroupName: PropTypes.string,
    newGroupNamePlaceholder: PropTypes.string,
    openModal: PropTypes.func.isRequired,
    options: PropTypes.object,
    selectedRecipients: propTypes.counterPartyArray.isRequired,
    senderId: PropTypes.string,
    setAvatar: PropTypes.func.isRequired,
    stopComposing: PropTypes.func.isRequired,
  };

  state = {
    isAvatarHovered: false,
  };

  componentDidMount() {
    const { currentUserId, fetchSetters, options } = this.props;
    const { setSenderId } = fetchSetters(options);
    setSenderId(currentUserId);
  }

  render() {
    const {
      allowedSenders,
      avatarPreviewUrl,
      createInProgress,
      currentConversationId,
      currentOrganization,
      fetchSetters,
      forumDescription,
      forumMembers,
      forumName,
      getUserById,
      groupSenderId,
      isOpen,
      isTeamActivation,
      newGroupName,
      newGroupNamePlaceholder,
      options,
      selectedRecipients,
      senderId,
    } = this.props;
    const { setName, setSenderId } = fetchSetters(options);
    const { isAvatarHovered } = this.state;
    const groupType = options.type;
    const isForum = groupType === 'Forum';
    const members = isForum ? forumMembers : selectedRecipients;
    const name = isForum ? forumName : newGroupName;
    const placeholder = isForum ? undefined : newGroupNamePlaceholder;
    const disableCreateButton = isTeamActivation
      ? !newGroupName && !newGroupNamePlaceholder
      : members.length < 1 || (!placeholder && !name);
    const modalSenderId = !isForum && !currentConversationId ? senderId : groupSenderId;
    const sender = modalSenderId && getUserById(modalSenderId);

    const excludeSenderId = [];

    if (sender) {
      excludeSenderId.push(sender.botRoleId ? sender.botRoleId : sender.id);
    }

    return (
      <Modal
        ariaLabelCloseButton={`New ${groupType} Modal Close`}
        ariaLabelHeader={`New ${groupType} Title`}
        bodyClass={classes('body', { isForum })}
        className={classes()}
        header={`Create a ${groupType}`}
        isOpen={isOpen}
        onRequestClose={this._closeModal}
        shouldReturnFocusAfterClose={true}
        size={'medium-large'}
        footerPrimaryActions={
          <button
            aria-label={`New ${groupType} Save`}
            className={classes('save-btn', { progress: createInProgress })}
            disabled={disableCreateButton}
            onClick={this._onSubmit}
            type="button"
          >
            {createInProgress ? (
              <div className={classes('dots-container')}>
                <DotsIndicator className={classes('dots')} size={10} speed={0.5} color={'#fff'} />
              </div>
            ) : isForum ? (
              'CREATE'
            ) : (
              'SAVE'
            )}
          </button>
        }
        footerSecondaryActions={
          <button
            aria-label={`New ${groupType} Cancel`}
            className={classes('cancel-btn')}
            disabled={createInProgress}
            onClick={this._closeModal}
            type="button"
          >
            CANCEL
          </button>
        }
      >
        {groupType === 'Forum' && (
          <div className={classes('warning')}>
            <div className={classes('first-row')}>You are creating a forum.</div>
            <div className={classes('second-row')}>
              Messages posted in the forum are public to everyone in your organization.
            </div>
          </div>
        )}
        <div className={classes('name-row')}>
          <div className={classes('row-header')}>NAME</div>
          <input
            aria-label={`New ${groupType} Name`}
            className={classes('input')}
            maxLength={100}
            onChange={(e) => setName(e.target.value)}
            placeholder={placeholder}
            type="text"
            value={name}
          />
        </div>

        {isForum && (
          <div className={classes('description-row')}>
            <div className={classes('row-header')}>DESCRIPTION</div>
            <input
              className={classes('input')}
              maxLength={300}
              onChange={this._onDescriptionChange}
              placeholder="What's this forum about?"
              type="text"
              value={forumDescription}
            />
          </div>
        )}

        <div className={classes('avatar-row')}>
          <div aria-label={`New ${groupType} Avatar Button`} className={classes('avatar-header')}>
            <GroupAvatarDropzone
              avatarPreviewUrl={avatarPreviewUrl}
              isAvatarHovered={isAvatarHovered}
              onAvatarSelected={this._onAvatarSelected}
              removeAvatar={this._removeAvatar}
              setAvatarHovered={this._setAvatarHovered}
            />
          </div>
          <div className={classes('avatar-text')}>
            <div className={classes('avatar-text-header')}>COVER</div>
            <div
              aria-label={`New ${groupType} Avatar Desc`}
              className={classes('avatar-text-body')}
            >
              {`Upload a photo for this ${groupType}. This will only be visible to ${groupType} members.`}
            </div>
          </div>
        </div>

        <div className={classes('members-row')}>
          <span className={classes('row-header')}>MEMBERS</span>
          <RecipientSearchPicker
            ariaLabelSelect={`New ${groupType} Add User`}
            ariaLabelItem={`New ${groupType} Select User`}
            className={classNames('New-group-modal', classes('members-picker'))}
            excludeIds={excludeSenderId}
            isAutoSized={true}
            multi={true}
            onChange={this._onMembersChange}
            organization={currentOrganization}
            placeholder="Invite member"
            searchTypes={GROUP_MEMBER_SEARCH_TYPES}
            selected={members}
            sender={sender}
            tabIndex={1}
            excludeIntraTeams={true}
            excludeRoles={isForum}
            excludeTeams={isForum}
          />
        </div>

        {!isForum && senderId && allowedSenders.length > 1 && (
          <div className={classes('create-as-row')}>
            <span className={classes('create-as-header')}>{`Create ${groupType} as:`}</span>
            <RoleSelector
              allowedSenders={allowedSenders}
              ref={this._setRoleSelector}
              sender={sender || (senderId && getUserById(senderId))}
              setSenderId={setSenderId}
              showSendAs={false}
            />
          </div>
        )}
      </Modal>
    );
  }

  _closeModal = () => {
    const { closeModal, fetchSetters, isTeamActivation, options, selectedRecipients } = this.props;
    const { setName } = fetchSetters(options);
    closeModal();

    if (selectedRecipients.length < 2 && !isTeamActivation) {
      setName('');
    }
  };

  _onDescriptionChange = (e) => {
    const { fetchSetters, options } = this.props;
    const { setDescription } = fetchSetters(options);

    setDescription(e.target.value);
    this.roleSelector && this.roleSelector.close();
  };

  _setAvatarHovered = (isAvatarHovered) => {
    this.setState({ isAvatarHovered });
  };

  _onAvatarSelected = ([file]) => {
    const { openModal, setAvatar } = this.props;
    if (!file) return;

    if (file.size > Images.MAX_AVATAR_FILE_SIZE_BYTES) {
      openModal('rejectedFileSize', {
        maxFileSize: `${Images.MAX_AVATAR_FILE_SIZE_MB}MB`,
      });
    } else {
      setAvatar(file);
    }
  };

  _removeAvatar = () => {
    const { setAvatar } = this.props;
    setAvatar(null);
  };

  _onMembersChange = (members) => {
    const { fetchSetters, options } = this.props;
    const { setMembers } = fetchSetters(options);

    setMembers(members);
    if (this.roleSelector) this.roleSelector.close();
  };

  _setRoleSelector = (ref) => {
    this.roleSelector = ref;
  };

  _onSubmit = async () => {
    const { closeModal, createGroup, options, stopComposing } = this.props;
    const isPublic = options.type === 'Forum';

    const succeeded = await createGroup(isPublic);
    if (succeeded) {
      closeModal();
      stopComposing();
    }
  };
}

export default mobxInjectSelect({
  composeMessageStore: [
    'isTeamActivation',
    'newGroupName',
    'newGroupNamePlaceholder',
    'stopComposing',
    'selectedRecipients',
    'senderId',
    'setSenderId',
  ],
  createGroupStore: [
    'allowedSenders',
    'avatarPreviewUrl',
    'createGroup',
    'createInProgress',
    'forumDescription',
    'forumMembers',
    'fetchSetters',
    'forumName',
    'placeholder',
    'setAvatar',
    'groupSenderId',
  ],
  conversationStore: ['currentConversationId'],
  messengerStore: ['currentOrganization'],
  modalStore: ['openModal'],
  sessionStore: ['currentUserId'],
  userStore: ['getUserById'],
})(NewGroupModal);
