const CollaborationCategories = {
  ALL: 'All',
  MYROLES: 'My Roles',
  MYTEAMS: 'My Teams',
  NONE: '',
  NOTTAGGED: 'Not Tagged',
} as const;

export type CollaborationCategory =
  | typeof CollaborationCategories.ALL
  | typeof CollaborationCategories.MYROLES
  | typeof CollaborationCategories.MYTEAMS
  | typeof CollaborationCategories.NONE
  | typeof CollaborationCategories.NOTTAGGED;

export default CollaborationCategories;
