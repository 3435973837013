export const getNodeAtPosition = (childNodes: NodeListOf<ChildNode>, atPosition: number) => {
  let startingIndex = 0;
  let newIndex = 0;
  for (let index = 0; index < childNodes.length; index++) {
    const currentNodeValue = childNodes[index].textContent;
    startingIndex = newIndex;
    newIndex += currentNodeValue?.length || 0;

    if (startingIndex === 0 && newIndex === 0 && atPosition === 0) {
      return { node: childNodes[0], startingIndex: 0, index: 0 };
    }

    if (atPosition >= startingIndex && atPosition <= newIndex - 1) {
      return { node: childNodes[index], startingIndex, index };
    }
  }
  return { node: undefined, startingIndex: -1, index: -1 };
};
