import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CollabEntity, Team } from '../../../../types';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { mobxInjectSelect } from '../../../utils';
import { BasicModal } from '../..';

const { setModal } = actions;
const { leaveTeam } = thunk;

type LeaveTeamModalProps = { isOpen: boolean };

type MobxProps = {
  syncOne: (entity: CollabEntity) => CollabEntity;
};

function LeaveTeamModal({ isOpen, syncOne }: LeaveTeamModalProps & MobxProps) {
  const dispatch = useDispatch();
  const activeTab = useSelector((state: ReduxState) => state.entities.activeTab);
  const entitiesById = useSelector((state: ReduxState) => state.entities.entitiesById);
  const selectedOrgId = useSelector((state: ReduxState) => state.collab.selectedOrgId);
  const selectedTeam = useSelector((state: ReduxState) => state.entities.selectedEntity) as Team;

  const closeModal = () => {
    dispatch(setModal(undefined));
  };

  const leave = async () => {
    await leaveTeam(dispatch, {
      activeTab,
      entitiesById,
      organizationId: selectedOrgId,
      syncOne,
      teamId: selectedTeam.id,
    });
    closeModal();
  };

  return (
    <BasicModal
      ariaLabelBody={'Leave Team Body'}
      ariaLabelCancelButton={'Leave Team Cancel'}
      ariaLabelCloseButton={'Leave Team Close'}
      ariaLabelHeader={'Leave Team Header'}
      ariaLabelSubmitButton={'Confirm Leave Team'}
      bodyText={`Leaving this team will remove you from all future activations.`}
      cancelText={'NO'}
      headerText={`Leave ${selectedTeam?.displayName}?`}
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={leave}
      size={'medium'}
      theme={'danger'}
      submitText={'YES'}
      hasCloseButton={true}
      useWCL
    />
  );
}

export default mobxInjectSelect<LeaveTeamModalProps, MobxProps>({
  entityStore: ['syncOne'],
})(LeaveTeamModal);
