import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import BEM from '../../../bem';
import propTypes from '../../../propTypes';

const classes = BEM.with('MessageContent');

class PatientConversationDetails extends Component {
  static propTypes = {
    message: propTypes.message.isRequired,
  };

  render() {
    const { message } = this.props;
    const { isForwardedFromPatientNetwork, patientDetails, originalGroupName } = message;
    const { contactName, isPatientContact, patientName, relation, mrn } = patientDetails;
    const conversationName = isPatientContact ? contactName : patientName;

    if (!(conversationName && mrn)) return null;

    return (
      <div className={classes('patient-conversation-container')}>
        <div className={classes('from-conversation-header')}>From Conversation:</div>
        <div className={classes('user-name')}>
          {originalGroupName ? (
            <Fragment>
              <div className={classes('user-name-patient')} title={originalGroupName}>
                {originalGroupName}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className={classes('user-name-patient')}>{conversationName}</div>
              {!isPatientContact && (
                <span
                  className={classes('patient-name', {
                    isForwardedFromPatientNetwork,
                  })}
                >
                  (Patient)
                </span>
              )}
              {isPatientContact && (
                <span
                  className={classes('patient-contact-relation', {
                    isForwardedFromPatientNetwork,
                  })}
                  title={relation}
                >
                  ({relation})
                </span>
              )}
            </Fragment>
          )}
        </div>
        <div className={classes('patient-details')}>
          <div className={classes('patient-name')}>{patientName}</div>
          <div className={classes('patient-mrn')}>MRN {mrn}</div>
        </div>
      </div>
    );
  }
}

export default observer(PatientConversationDetails);
