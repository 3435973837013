import type { Dispatch } from '@reduxjs/toolkit';
import TCClient from '../../../../client';

import { OrganizationSettings } from '../../../types';
import { SET_TOAST_DURATION_IN_MILLISECONDS } from '../../../utils/setToastDuration';
import { actions, store, thunk } from 'redux-stores';
import { AuthSaml } from 'js-sdk/src/services/Admin/utils/types';

export const findSettings = async (organizationId: string) => {
  const settings = (await TCClient.adminOrganizations.fetchSettings({
    organizationId: organizationId,
  })) as OrganizationSettings;
  return settings;
};

export const fetchSettings = async (dispatch: Dispatch) => {
  const {
    admin: { selectedOrganizationId },
  } = store.getState();

  const settings = await findSettings(selectedOrganizationId);
  dispatch(actions.updateSettings(settings));
};

export const saveSettings = async ({
  dispatch,
  location = 'root',
  logPendoAnalytics,
}: {
  logPendoAnalytics?: (p: { tracker: { name: string } }) => void;
  location?: string;
  dispatch: Dispatch;
}) => {
  const {
    admin: { orgSettingsTabSaveFunc },
  } = store.getState();

  try {
    const {
      admin: { selectedOrganizationId },
      orgSettings: { settings },
    } = store.getState();

    if (settings.authType === 'saml' && location === 'authentication') {
      const samlPayload = {
        organization_id: selectedOrganizationId,
        auth: {
          auth_type: settings.authType,
          saml_idp_metadata: settings.authSamlIspMetadataUrl,
          saml_trusted_fingerprint: settings.authSamlIspFingerprint,
          auth_saml_assertion_user_id: settings.authSamlAssertionId,
          auth_saml_tigertext_user_id: settings.authSamlTcId,
          saml_session_ttl: settings.authSamlTtl,
        } as AuthSaml,
      };
      await TCClient.adminOrganizations.saveAuthSettings({
        data: samlPayload,
      });
    } else {
      await TCClient.adminOrganizations.saveSettings({
        organizationId: selectedOrganizationId,
        settings,
        logPendoAnalytics,
      });
    }

    if (orgSettingsTabSaveFunc) {
      await orgSettingsTabSaveFunc();
    }

    await fetchSettings(dispatch);

    thunk.setToastSettings({
      duration: SET_TOAST_DURATION_IN_MILLISECONDS(3),
      open: true,
      message: 'Organization settings have been saved.',
      type: 'SUCCESS',
    });
  } catch (_e) {
    thunk.setToastSettings({
      duration: SET_TOAST_DURATION_IN_MILLISECONDS(3),
      open: true,
      message: "An error occurred when saving the organization's settings",
      type: 'FAILURE',
    });
  }
};

export const deleteOrganization = async (dispatch: Dispatch) => {
  const {
    admin: { selectedOrganizationId },
  } = store.getState();

  await TCClient.adminOrganizations.deleteOrganization({
    organizationId: selectedOrganizationId,
  });

  dispatch(actions.removeOrganization({ organizationId: selectedOrganizationId }));
};

export const createDirectory = async (_dispatch: Dispatch, directory: string) => {
  const {
    admin: { selectedOrganizationId },
  } = store.getState();

  return TCClient.adminOrganizations.createCustomDirectories({
    organizationId: selectedOrganizationId,
    directories: [directory],
  });
};

export const deleteDirectory = async (_dispatch: Dispatch, directory: string) => {
  const {
    admin: { selectedOrganizationId },
  } = store.getState();

  return TCClient.adminOrganizations.deleteCustomDirectories({
    organizationId: selectedOrganizationId,
    directories: [directory],
  });
};

export const fetchDirectories = async (_dispatch?: Dispatch) => {
  const {
    admin: { selectedOrganizationId },
  } = store.getState();
  const directories = await TCClient.adminOrganizations.fetchCustomDirectories({
    organizationId: selectedOrganizationId,
  });

  return directories;
};

export const findUnits = async (_dispatch?: Dispatch) => {
  const {
    admin: { selectedOrganizationId },
  } = store.getState();

  return await TCClient.multiOrg.findUnits({
    orgId: selectedOrganizationId,
  });
};
