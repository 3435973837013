import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { ReactComponent as RemoveButton } from '../../images/close-icon.svg';
import propTypes from '../../propTypes';
import { EntityAvatar, UserTitleDepartment } from '../';

const classes = BEM.with('AddCareTeamModal');

class MemberCard extends Component {
  static propTypes = {
    removeMember: PropTypes.func.isRequired,
    member: propTypes.user.isRequired,
  };

  render() {
    const { member } = this.props;
    const { $entityType: entityType, displayName } = member;

    let memberInfoFragment;
    if (entityType === 'role') {
      const { tag } = member;

      if (tag && !tag.isDefault) {
        memberInfoFragment = <div className={classes('member-info')}>{tag.displayName}</div>;
      }
    } else {
      memberInfoFragment = <UserTitleDepartment className={classes('member-info')} user={member} />;
    }

    return (
      <div className={classes('member-card')}>
        <EntityAvatar
          entity={member}
          indicatorSize={'SMALL'}
          showPresenceIndicator={true}
          size={33}
        />
        <div className={classes('member-details')}>
          <div className={classes('member-name')}>{displayName}</div>
          {memberInfoFragment}
        </div>
        <RemoveButton className={classes('remove-button')} onClick={this._removeMember} />
      </div>
    );
  }

  _removeMember = () => {
    const { member, removeMember } = this.props;

    if (removeMember) removeMember(member.id);
  };
}

export default observer(MemberCard);
