import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import { mobxInjectSelect } from '../../../utils';
import propTypes from '../../../propTypes';
import { MessageDetails } from '..';
import { MessageSubTypes } from '../../../../models/enums/MessageSubTypes';
import { ReactComponent as DndSvg } from '../../../images/dnd-icon.svg';
import {
  MessageBody,
  MessageForwardedBodyHeader,
  MessageHeader,
  MessageModalHeader,
  PatientConversationDetails,
} from './';

const classes = BEM.with('MessageContent');

class MessageContent extends Component {
  static propTypes = {
    composeToContacts: PropTypes.func.isRequired,
    currentModalType: PropTypes.string,
    isModal: PropTypes.bool,
    isProviderNetwork: PropTypes.bool.isRequired,
    message: propTypes.message.isRequired,
    multiSelectable: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isModal: false,
  };

  render() {
    const {
      composeToContacts,
      currentModalType,
      isModal,
      isModalOpen,
      isProviderNetwork,
      message,
      multiSelectable,
    } = this.props;
    const { currentSenderRole, isForwarded, isForwardedFromPatientNetwork, isOutgoing, subType } =
      message;
    let { senderRole } = message;

    const isVwrCallInvite = subType === MessageSubTypes.VIRTUAL_WAITING_ROOM_CALL_INVITE;
    const direction = isOutgoing && !isVwrCallInvite ? 'OUTGOING' : 'INCOMING';
    const isPatientMessageForward = isModalOpen && currentModalType === 'patientMessageForward';
    let awayFragment, forwardedFragment, headerFragment, patientConversationFragment, tagFragment;

    if (currentSenderRole) {
      senderRole = currentSenderRole;
    }

    if (isModal) {
      headerFragment = <MessageModalHeader message={message} />;
    } else {
      if (isForwarded) {
        forwardedFragment = <MessageForwardedBodyHeader message={message} />;
      }
      if (senderRole) {
        const { tag } = senderRole;
        if (tag) {
          tagFragment = <div className={classes('role-tag')}>{tag.displayName}</div>;
        }
      }
      if (
        subType === 'CHATBOT_MESSAGE' ||
        subType === MessageSubTypes.VIRTUAL_WAITING_ROOM_CALL_INVITE
      ) {
        headerFragment = null;
      } else {
        headerFragment = <MessageHeader message={message} />;
      }
    }

    if (isForwardedFromPatientNetwork) {
      patientConversationFragment = <PatientConversationDetails message={message} />;
    }

    if (subType === MessageSubTypes.USER_AWAY_NOTIFICATION && !isProviderNetwork) {
      awayFragment = (
        <div className={classes('away')}>
          {' '}
          <DndSvg /> AWAY MESSAGE:
        </div>
      );
    }

    return (
      <div className={classes({ direction })}>
        {headerFragment}
        {tagFragment}
        {forwardedFragment}
        {awayFragment}
        <MessageBody
          composeToContacts={composeToContacts}
          isModal={isModal}
          message={message}
          multiSelectable={multiSelectable}
        />
        {patientConversationFragment}
        <MessageDetails
          isModal={isModal}
          isPatientMessageForward={isPatientMessageForward}
          isProviderNetwork={isProviderNetwork}
          message={message}
        />
      </div>
    );
  }
}

export default mobxInjectSelect({
  composeMessageStore: ['composeToContacts'],
  networkStore: ['isProviderNetwork'],
  modalStore: ['currentModalType', 'isModalOpen'],
})(MessageContent);
