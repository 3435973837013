import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SignInStep } from '../../../models/enums';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import { ReactComponent as LoginBg } from '../../images/tc-icon.svg';
import TigerConnectLogoMain from '../../images/tigerconnect-logo-main.png';
import DotsIndicator from '../DotsIndicator';
import { IS_IE11 } from '../../utils/browsers';
import LocationForm from './LocationForm';
import LockedSignIn from './LockedSignIn';
import PasswordForm from './PasswordForm';
import PasswordTitle from './PasswordTitle';
import UsernameTitle from './UsernameTitle';
import UsernameForm from './UsernameForm';
import WarnIEUsersUnsupportedBrowserForm from './WarnIEUsersUnsupportedBrowserForm';

const classes = BEM.with('SignInForm');

function InnerSignInFragment({
  allowServerSelection,
  checkLogin,
  isStaticRender,
  isWarnIEUsersFeatureFlagEnabled,
  loginAttemptsLeft,
  processingMagicToken,
  signIn,
  signInFormContainerClassName,
  signInStep,
}) {
  const [shouldDisplayIEWarning, setShouldDisplayIEWarning] = useState(true);
  const [isTabPressed, setIsTabPressed] = useState(false);
  const isUserUsingIE = IS_IE11 && shouldDisplayIEWarning && isWarnIEUsersFeatureFlagEnabled;
  const titleText = isUserUsingIE ? 'Unsupported Browser' : 'Sign in to TigerConnect';

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        setIsTabPressed(true);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (loginAttemptsLeft === 0) {
    return <LockedSignIn />;
  } else if (allowServerSelection) {
    return (
      <div className={classes('location-container-outer')}>
        <div className={classes('tc-svg')}>
          <LoginBg id="tc-SignInForm__loginBg" />{' '}
        </div>
        <div className={classes('location-container')}>
          <LocationForm />
        </div>
      </div>
    );
  } else {
    let className;
    className = isStaticRender
      ? signInFormContainerClassName
      : (className = classes('sign-in-container', {
          hidden: processingMagicToken,
          a11y: isTabPressed,
        }));

    return (
      <div id="tc-SignInForm__container" className={className}>
        <div className={classes('header')}>
          <div className={classes('tc-svg')}>
            {isUserUsingIE ? (
              <img height={40} src={TigerConnectLogoMain} alt={''} />
            ) : (
              <LoginBg id="tc-SignInForm__loginBg" />
            )}
          </div>
          {(isStaticRender || signInStep === SignInStep.USERNAME || isUserUsingIE) && (
            <div
              className={classes('sign-in-title-text', {
                isUserUsingIE,
              })}
              data-test-id={'sign-in-title-text'}
            >
              <UsernameTitle bodyText={titleText} />
            </div>
          )}
          {(isStaticRender || signInStep === SignInStep.PASSWORD) && !isUserUsingIE && (
            <PasswordTitle />
          )}
        </div>

        {isUserUsingIE && (
          <div>
            <WarnIEUsersUnsupportedBrowserForm
              onSubmit={() => {
                setShouldDisplayIEWarning(false);
              }}
            />
          </div>
        )}
        {!isUserUsingIE && (
          <div>
            <UsernameForm onSubmit={checkLogin} />
            <PasswordForm onSubmit={signIn} />
          </div>
        )}
      </div>
    );
  }
}

function SignInForm({
  allowServerSelection,
  autoCheckLogin,
  checkLogin,
  DevTools,
  isStaticRender,
  isWarnIEUsersFeatureFlagEnabled,
  loginAttemptsLeft,
  processingMagicToken,
  signIn,
  signInFormContainerClassName,
  signInFormLoadingIndicatorClassName,
  signInStep,
  signInUsername,
}) {
  const loadingSpinnerClassName = isStaticRender
    ? signInFormLoadingIndicatorClassName
    : classes('loading-indicator', { hidden: !processingMagicToken });

  const _checkLogin = ({ username }) => {
    checkLogin(username);
  };

  const _signIn = ({ password }) => {
    signIn(signInUsername, password);
  };

  useEffect(() => {
    autoCheckLogin();
  }, [autoCheckLogin]);

  return (
    <div className={classes()}>
      <span id="tc-SignInForm__loading-indicator" className={loadingSpinnerClassName}>
        <DotsIndicator />
      </span>
      <InnerSignInFragment
        {...{
          allowServerSelection,
          checkLogin: _checkLogin,
          isStaticRender,
          isWarnIEUsersFeatureFlagEnabled,
          loginAttemptsLeft,
          processingMagicToken,
          signIn: _signIn,
          signInFormContainerClassName,
          signInStep,
        }}
      />
      {!isStaticRender && <DevTools />}
    </div>
  );
}

SignInForm.propTypes = {
  DevTools: PropTypes.func.isRequired,
  allowServerSelection: PropTypes.bool,
  checkLogin: PropTypes.func.isRequired,
  isStaticRender: PropTypes.bool,
  isWarnIEUsersFeatureFlagEnabled: PropTypes.bool,
  loginAttemptsLeft: PropTypes.number,
  processingMagicToken: PropTypes.bool,
  signIn: PropTypes.func.isRequired,
  signInFormContainerClassName: PropTypes.string,
  signInFormLoadingIndicatorClassName: PropTypes.string,
  signInStep: PropTypes.string.isRequired,
  signInUsername: PropTypes.string,
};

export default mobxInjectSelect({
  messengerStore: ['isStaticRender', 'isWarnIEUsersFeatureFlagEnabled'],
  sessionStore: [
    'allowServerSelection',
    'autoCheckLogin',
    'checkLogin',
    'loginAttemptsLeft',
    'processingMagicToken',
    'signIn',
    'signInStep',
    'signInUsername',
  ],
  staticStore: ['DevTools', 'signInFormContainerClassName', 'signInFormLoadingIndicatorClassName'],
})(SignInForm);
