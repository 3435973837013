import { useEffect } from 'react';
import { Picker } from 'emoji-mart';
import type { BaseEmoji } from 'emoji-mart';
import { PositioningPortal } from '@codastic/react-positioning-portal';

import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';

const PickerClasses = BEM.with('EmojiPickerPortal');

type ReactionPickerProps = {
  pickerOpen: boolean;
  setPickerOpen: (v: boolean) => void;
  messageId: string;
  children: JSX.Element;
};

type MobxProps = {
  addReaction: ({
    messageId,
    reaction,
    userId,
  }: {
    messageId: string;
    reaction: string;
    userId: string;
  }) => Promise<void>;
  currentUserId: string;
};

const ReactionPicker = ({
  addReaction,
  children,
  currentUserId,
  pickerOpen,
  setPickerOpen,
  messageId,
}: ReactionPickerProps & MobxProps) => {
  useEffect(() => {
    if (pickerOpen) {
      const input = document.querySelector('input[id^=emoji-mart-search]') as HTMLInputElement;
      if (input) input.focus();
    }
  }, [pickerOpen]);

  return (
    <PositioningPortal
      isOpen={pickerOpen}
      onOpen={() => setPickerOpen(true)}
      onShouldClose={() => setPickerOpen(false)}
      portalContent={
        <div
          className={PickerClasses()}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Picker
            autoFocus
            emoji="point_up"
            native
            onClick={async (emoji: BaseEmoji, event) => {
              event.stopPropagation();
              setPickerOpen(false);
              await addReaction({
                messageId,
                reaction: `${emoji.unified}`,
                userId: currentUserId,
              });
            }}
            set="twitter"
            showPreview={false}
            title="Pick your emoji…"
          />
        </div>
      }
    >
      {children}
    </PositioningPortal>
  );
};

export default mobxInjectSelect<ReactionPickerProps, MobxProps>({
  messageStore: ['addReaction'],
  sessionStore: ['currentUserId'],
})(ReactionPicker);
