const SearchTypes = {
  CARE_TEAM: 'careTeam',
  DISTRIBUTION_LIST: 'distributionList',
  GROUP: 'group',
  FORUM: 'forum',
  PATIENT: 'patient_account',
  PATIENT_DISTRIBUTION_LIST: 'patientDistributionList',
  USER: 'user',
  TAG: 'tag',
  TEAM: 'team',
  INDIVIDUAL: 'individual',
  ROLE: 'role',
  MESSAGE: 'message',
};

export const ALL_SEARCH_TYPES = [
  SearchTypes.USER,
  SearchTypes.GROUP,
  SearchTypes.FORUM,
  SearchTypes.PATIENT,
  SearchTypes.PATIENT_DISTRIBUTION_LIST,
  SearchTypes.CARE_TEAM,
  SearchTypes.DISTRIBUTION_LIST,
  SearchTypes.TAG,
  SearchTypes.TEAM,
  SearchTypes.INDIVIDUAL,
  SearchTypes.ROLE,
];

export const PROVIDER_SEARCH_TYPES = [
  SearchTypes.USER,
  SearchTypes.GROUP,
  SearchTypes.FORUM,
  SearchTypes.CARE_TEAM,
  SearchTypes.DISTRIBUTION_LIST,
  SearchTypes.TAG,
  SearchTypes.TEAM,
];

export const PATIENT_SEARCH_TYPES = [SearchTypes.PATIENT, SearchTypes.PATIENT_DISTRIBUTION_LIST];

export const AUTOFORWARD_SEARCH_TYPES = [SearchTypes.USER];
export const SEARCH_PARITY_AUTOFORWARD_SEARCH_TYPES = [SearchTypes.INDIVIDUAL];
export const SEARCH_PARITY_EXTENDED_AUTOFORWARD_SEARCH_TYPES = [
  SearchTypes.INDIVIDUAL,
  SearchTypes.ROLE,
  SearchTypes.TEAM,
];

export const GROUP_MEMBER_SEARCH_TYPES = [
  SearchTypes.USER,
  SearchTypes.TAG,
  SearchTypes.TEAM,
  SearchTypes.CARE_TEAM,
];

export const ROLE_ASSIGNEE_SEARCH_TYPES = [SearchTypes.USER];

export const TEAMS_SEARCH_TYPES = [SearchTypes.USER];

export const BROADCAST_LIST_SEARCH_TYPES = [SearchTypes.USER];

export default SearchTypes;
