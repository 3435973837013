import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../common/bem';
import { UnreadBadgeCount } from '../../../common/components';
import propTypes from '../../../common/propTypes';
import { mobxInjectSelect } from '../../../common/utils';
import AccessibleList from '../../../common/components/AccessibleList';
import { KEYMAP } from '../../../common/constants';
import { AppTypes } from '../../../models/enums';
import { useAppContext } from '../../../contexts/App';
import { setTotalNewVisitsCount } from '../../../contexts/App/thunk';
import { ReactComponent as SideIconAnalyticsSvg } from '../images/side-icon-Analytics.svg';
import { ReactComponent as SideIconBroadcastListSvg } from '../images/side-icon-Broadcast-List.svg';
import { ReactComponent as SideIconCollaboration } from '../images/side-icon-Collaboration.svg';
import { ReactComponent as SideIconMessengerNSSvg } from '../images/side-icon-Messenger-not-selected.svg';
import { ReactComponent as SideIconMessengerSvg } from '../images/side-icon-Messenger.svg';
import { ReactComponent as SideIconPatientSvg } from '../images/side-icon-PatientSettings.svg';
import { ReactComponent as SideIconRolesSvg } from '../images/side-icon-Roles.svg';
import { ReactComponent as SideIconSettingsSvg } from '../images/side-icon-Settings.svg';
import { ReactComponent as SideIconSettingTeams } from '../images/side-icon-Teams.svg';
import { ReactComponent as SideIconVirtualWaitingSvg } from '../images/side-icon-VirtualWaiting.svg';
import { useAppSelector, actions, useAppDispatch } from 'redux-stores';

const { setKeywordSearchMode } = actions;

const classes = BEM.with('AppSelectorButtons');

const icons = {
  Analytics: SideIconAnalyticsSvg,
  BroadcastLists: SideIconBroadcastListSvg,
  Collaboration: SideIconCollaboration,
  Messenger: SideIconMessengerSvg,
  'Messenger-not-selected': SideIconMessengerNSSvg,
  PatientSettings: SideIconPatientSvg,
  Roles: SideIconRolesSvg,
  Settings: SideIconSettingsSvg,
  VirtualWaiting: SideIconVirtualWaitingSvg,
  PatientDirectory: SideIconPatientSvg,
};

class AppSelectorButtons extends Component {
  static propTypes = {
    allowVirtualWaitingRoomSettings: PropTypes.bool.isRequired,
    currentAppSelected: PropTypes.string.isRequired,
    currentOrganization: propTypes.organization,
    currentOrganizationId: PropTypes.string,
    getAdminRolesInOrganization: PropTypes.func.isRequired,
    accessibilityMode: PropTypes.bool.isRequired,
    isBroadcastListSettingsAllowed: PropTypes.bool,
    isPatientContextAllowed: PropTypes.bool,
    switchApp: PropTypes.func.isRequired,
    totalUnreadCount: PropTypes.number.isRequired,
    newMessageAnnouncement: PropTypes.object,
    totalUnreadVWRCount: PropTypes.number.isRequired,
  };

  render() {
    const {
      allowPatientBroadcastLists,
      allowPatientScheduledMessages,
      allowPatientListAccess,
      allowVirtualWaitingRoomSettings,
      currentOrganization,
      currentOrganizationId,
      getAdminRolesInOrganization,
      isBroadcastListSettingsAllowed,
      isPatientContextAllowed,
      isUniversalTaggingAllowed,
      isVWREnabledForCurrentOrganization,
    } = this.props;
    const {
      isAdmin = false,
      isAnalyticsAdmin = false,
      isPatientAdmin = false,
      isTTPlusReportingAdmin = false,
      isPatientDataReportAnalyticsAdmin = false,
    } = getAdminRolesInOrganization(currentOrganizationId);
    const {
      canAdminRoles = false,
      rolesEnabled = false,
      showTeams = false,
    } = currentOrganization || {};

    const isRolesEnabled = canAdminRoles && rolesEnabled;
    if (isUniversalTaggingAllowed) {
      icons.Collaboration =
        isRolesEnabled && showTeams
          ? SideIconCollaboration
          : isRolesEnabled
          ? SideIconRolesSvg
          : showTeams
          ? SideIconSettingTeams
          : null;
    }
    const buttonsArray = [
      'Messenger',
      isRolesEnabled && !isUniversalTaggingAllowed ? 'Roles' : null,
      (isRolesEnabled || showTeams) && isUniversalTaggingAllowed ? 'Collaboration' : null,
      isAnalyticsAdmin || isTTPlusReportingAdmin || isPatientDataReportAnalyticsAdmin
        ? 'Analytics'
        : null,
      isAdmin ? 'Settings' : null,
      isPatientAdmin ||
      allowPatientScheduledMessages ||
      allowPatientListAccess ||
      allowPatientBroadcastLists ||
      allowVirtualWaitingRoomSettings
        ? 'Patient Settings'
        : null,
      isVWREnabledForCurrentOrganization ? 'Virtual Waiting' : null,
      isPatientContextAllowed ? 'Patient Directory' : null,
      isBroadcastListSettingsAllowed ? 'Broadcast Lists' : null,
    ].filter(Boolean);
    return (
      <nav role="navigation" aria-labelledby="app-navigation-heading" className={classes()}>
        <h2 id="app-navigation-heading" className="screen-reader-only">
          App Navigation
        </h2>
        <div aria-orientation="vertical">
          <AccessibleList
            accessibilityMode={this.props.accessibilityMode}
            focusableClasses={['.tc-AppSelectorButtons__button']}
            loop
            setStartElementOnChange
          >
            {buttonsArray.map(this._renderButton)}
          </AccessibleList>
        </div>
      </nav>
    );
  }

  _renderButton = (button, idx) => {
    const {
      currentAppSelected,
      currentOrganization,
      currentOrganizationId,
      getAdminRolesInOrganization,
      isBroadcastListAdmin,
      isVwrBlueDotActive,
      totalUnreadAlertsCount,
      totalUnreadCount,
      newMessageAnnouncement,
      totalUnreadVWRCount,
    } = this.props;
    const {
      canAdminRoles = false,
      rolesEnabled = false,
      showTeams = false,
    } = currentOrganization || {};
    const { isRolesAdmin = false, isTeamAdmin = false } =
      getAdminRolesInOrganization(currentOrganizationId);
    const isRolesEnabled = canAdminRoles && rolesEnabled;
    const normalizedButtonName = button.replace(' ', '');
    const selected =
      (currentAppSelected === AppTypes.MESSAGES && normalizedButtonName === 'Messenger') ||
      (currentAppSelected === AppTypes.COLLABORATION && normalizedButtonName === 'Collaboration')
        ? true
        : currentAppSelected === normalizedButtonName;

    const Avatar =
      !selected && normalizedButtonName === 'Messenger'
        ? icons[`${normalizedButtonName}-not-selected`]
        : icons[normalizedButtonName];

    const isMiniBadgeShown = button === 'Virtual Waiting' && isVwrBlueDotActive;

    let adminFragment, unreadCountFragment;
    if (
      (button === 'Roles' && isRolesAdmin) ||
      (button === 'Broadcast Lists' && isBroadcastListAdmin) ||
      (button === 'Collaboration' &&
        ((isRolesAdmin && isRolesEnabled) || (isTeamAdmin && showTeams)))
    ) {
      adminFragment = (
        <div className={classes('location')}>
          <div className={classes('container')}>
            <span className={classes('circle')}>
              <SideIconSettingsSvg className={classes('small-icon', { selected })} />
            </span>
          </div>
        </div>
      );
    }

    if (button === 'Messenger' && (totalUnreadAlertsCount > 0 || totalUnreadCount > 0)) {
      unreadCountFragment = (
        <div className={classes('unread-count')}>
          <UnreadBadgeCount
            className={classes('unread-text')}
            count={totalUnreadAlertsCount + totalUnreadCount}
            priority={false}
          />
          <div
            role="alert"
            aria-live="assertive"
            id="messageAnnouncer"
            key={newMessageAnnouncement?.key}
            className="screen-reader-only"
          >
            {newMessageAnnouncement?.text}
          </div>
        </div>
      );
    }

    if (button === 'Virtual Waiting' && totalUnreadVWRCount > 0) {
      unreadCountFragment = (
        <div className={`${classes('unread-count')}`}>
          <UnreadBadgeCount
            className={classes('unread-text')}
            count={totalUnreadVWRCount}
            priority={false}
          />
        </div>
      );
    }

    if (button === 'Collaboration') {
      button =
        isRolesEnabled && showTeams
          ? (button = 'Roles & Teams')
          : isRolesEnabled
          ? 'Roles'
          : showTeams
          ? 'Teams'
          : null;
    }

    const onKeyDown = (e, fn) => {
      if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
        fn();
      }
    };

    return (
      <button
        aria-label={`${button}`}
        className={classes('button')}
        key={button}
        onClick={this._onClick(button)}
        onKeyDown={(event) => onKeyDown(event, this._onClick(button))}
        tabIndex={idx === 0 ? 0 : -1}
      >
        <div className={classes({ button: normalizedButtonName })}>
          <div className={classes('bg-icon', { selected })}>
            {isMiniBadgeShown && <div className={classes('mini-badge')} />}
            {unreadCountFragment}
            <Avatar
              className={classes('icon', {
                teamsEnabled: showTeams && !isRolesEnabled,
              })}
            />
            {adminFragment}
          </div>
          <div className={classes('text')}>{button}</div>
        </div>
      </button>
    );
  };

  _onClick = (option) => {
    return () => {
      const { switchApp, closeKeywordSearchFn, isKeywordInboxSearchFeatureFlagEnabled } =
        this.props;
      if (option === 'Messenger') {
        option = 'Messages';
        if (isKeywordInboxSearchFeatureFlagEnabled) {
          closeKeywordSearchFn();
        }
      }
      switchApp(option);
    };
  };
}

function AppSelectorButtonFC(props) {
  const { currentOrganizationId, isVWREnabledForCurrentOrganization } = props;
  const { state, dispatch } = useAppContext();
  const reduxDispatch = useAppDispatch();

  const { accessibilityMode } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));

  useEffect(() => {
    if (currentOrganizationId && isVWREnabledForCurrentOrganization) {
      setTotalNewVisitsCount(dispatch, currentOrganizationId);
    }
  }, [currentOrganizationId, dispatch, isVWREnabledForCurrentOrganization]);

  return (
    <AppSelectorButtons
      {...props}
      closeKeywordSearchFn={() => reduxDispatch(setKeywordSearchMode(false))}
      accessibilityMode={accessibilityMode}
      isVwrBlueDotActive={state.virtualWaitingRoom.newVisitorCounts.total > 0}
    />
  );
}

export default mobxInjectSelect({
  messengerStore: [
    'currentAppSelected',
    'currentOrganization',
    'currentOrganizationId',
    'getAdminRolesInOrganization',
    'isBroadcastListAdmin',
    'isBroadcastListSettingsAllowed',
    'isKeywordInboxSearchFeatureFlagEnabled',
    'isPatientContextAllowed',
    'isUniversalTaggingAllowed',
    'isVWREnabledForCurrentOrganization',
    'switchApp',
    'totalUnreadAlertsCount',
    'totalUnreadCount',
    'totalUnreadVWRCount',
    'newMessageAnnouncement',
  ],
  patientAdminStore: [
    'allowPatientScheduledMessages',
    'allowPatientListAccess',
    'allowPatientBroadcastLists',
    'allowVirtualWaitingRoomSettings',
  ],
})(AppSelectorButtonFC);
