import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../../utils';
import BEM from '../../bem';

const classes = BEM.with('Admin');

class ReactAdmin extends Component {
  static propTypes = {
    currentOrganizationId: PropTypes.string.isRequired,
    destroyIframe: PropTypes.func.isRequired,
    getIframe: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this._getIframe();
  }

  componentDidUpdate(prevProps) {
    const { destroyIframe } = this.props;
    if (prevProps.view !== this.props.view) {
      destroyIframe();
      this._getIframe();
    }
  }

  _getIframe() {
    const { currentOrganizationId, getIframe, view } = this.props;
    getIframe(this.iframeParent, currentOrganizationId, view);
  }

  componentWillUnmount() {
    const { destroyIframe } = this.props;
    destroyIframe();
  }

  render() {
    return <div className={classes()} ref={this._setIframeParent} />;
  }

  _setIframeParent = (ref) => {
    this.iframeParent = ref;
  };
}

export default mobxInjectSelect({
  messengerStore: ['currentOrganizationId'],
  reactAdminStore: ['destroyIframe', 'getIframe'],
})(ReactAdmin);
