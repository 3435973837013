import { observable } from 'mobx';
import createMobxModel from './createMobxModel';

const EscalationPolicy = createMobxModel({
  name: 'escalationPolicy',

  fields: ['alwaysEscalate', 'minutesToExpire', 'targetMinutesToAcknowledge'],

  objectFields: {
    path: {
      clone(entityList) {
        const results = [];

        if (entityList) {
          for (const { minutesToAcknowledge, targets } of entityList) {
            const targetGroup = { minutesToAcknowledge };
            Object.defineProperty(targetGroup, 'targets', {
              enumerable: true,
              get() {
                return targets;
              },
            });
            results.push(targetGroup);
          }
        }

        return observable.array(results);
      },
    },
  },

  relations: {
    one: {
      target: { type: 'user' },
    },
  },
});

export default EscalationPolicy;
