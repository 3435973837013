import React from 'react';
import BEM from '../../../bem';
import Dropdown from '../../Dropdown';

const selectorClasses = BEM.with('CollaborationSelector');

type TimeSelectorProps = {
  saveHandler: (selector: number, num: number) => void;
  selector: number;
  toggleHandler: () => void;
};

export default function TimeSelector({ saveHandler, selector, toggleHandler }: TimeSelectorProps) {
  const timeArr = [];
  for (let idx = 0; idx < 60; idx += 1) {
    timeArr[idx] = idx + 1;
  }

  return (
    <div className={selectorClasses('', { coverInput: true, large: true })}>
      <Dropdown ariaLabel={'select-time-to-acknowledge'} triggerHandler={toggleHandler}>
        <>
          {timeArr.map((num) => (
            <div
              className={selectorClasses('list-option')}
              onClick={() => {
                saveHandler(selector, num);
                toggleHandler();
              }}
              key={num}
            >
              {`${num} Min${num > 1 ? 's' : ''}`}
            </div>
          ))}
        </>
      </Dropdown>
    </div>
  );
}
