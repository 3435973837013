export type EntitySkeleton = {
  accountStatus: string;
  id: string;
  $entityType: string;
  profileByOrganizationId: Record<string, Record<string, string>>;
} & Record<string, string>;

export default class SearchResult {
  id: string;
  entity: EntitySkeleton;
  entityId: string;
  entityType: string;
  metadata?: Record<string, unknown>;
  organizationId: string;
  conversationId?: string;
  constructor(
    entity: EntitySkeleton,
    organizationId: string,
    conversationId: string | undefined,
    metadata?: Record<string, unknown>
  ) {
    this.id = `searchResult:${organizationId}:${entity.id}`;
    this.entity = entity;
    this.entityId = entity.id;
    this.entityType = entity.$entityType;
    this.metadata = metadata;
    this.organizationId = organizationId;
    this.conversationId = conversationId;
  }

  static is(o: unknown) {
    return o instanceof this;
  }
}
