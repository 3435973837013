import EventEmitter from 'events';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SignInStep } from '../../../models/enums';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import { Buttons, FieldGroup, Form, SubmitButton, TextField } from '../Form';

const classes = BEM.with('SignInForm');

class PasswordForm extends Component {
  static propTypes = {
    events: PropTypes.instanceOf(EventEmitter).isRequired,
    forgotPasswordServer: PropTypes.string,
    isSignInLoading: PropTypes.bool,
    isStaticRender: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    passwordFormClassName: PropTypes.string,
    signInError: PropTypes.string,
    signInStep: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { events } = this.props;
    events.on('setSignInStep', this._resetFormMaybe);
    this._focus();
  }

  componentDidUpdate() {
    this._focus();
  }

  componentWillUnmount() {
    const { events } = this.props;
    events.removeListener('setSignInStep', this._resetFormMaybe);
  }

  render() {
    const {
      isSignInLoading,
      isStaticRender,
      loginAttemptsLeft,
      onSubmit,
      passwordFormClassName,
      serverLocation,
      signInError,
      signInStep,
    } = this.props;
    const isWorking = isStaticRender || isSignInLoading;
    let className;
    if (isStaticRender) {
      className = passwordFormClassName;
    } else {
      className = signInStep === SignInStep.PASSWORD ? undefined : classes('hidden');
    }

    let infoFragment;
    if (isStaticRender || (!signInError && serverLocation)) {
      infoFragment = (
        <div id="tc-SignInForm__country" className={classes('country')}>
          {isStaticRender ? '' : `Server: ${serverLocation}`}
        </div>
      );
    } else {
      infoFragment = (
        <div className={classes('error')}>
          {signInError && (
            <div
              aria-label="incorrect password"
              data-test-id={'incorrect-password-error'}
              className={classes('passwordError')}
            >
              Password does not match, {loginAttemptsLeft} more attempts left
            </div>
          )}
        </div>
      );
    }

    return (
      <div id="tc-SignInForm__PasswordForm" className={className}>
        <Form
          className={classes('form')}
          columns={2}
          isWorking={isWorking}
          onSubmit={onSubmit}
          ref={this._setForm}
          theme="rows"
        >
          {infoFragment}

          <FieldGroup className={classes('field-group')}>
            <TextField
              ariaLabel="password"
              className={signInError && classes('field-group-error')}
              disabled={isWorking}
              data-test-id={'password-input'}
              inputRef={this._setInput}
              name="password"
              placeholder="Password"
              type="password"
              autocomplete="off"
            />
          </FieldGroup>

          <Buttons>
            <SubmitButton
              ariaLabel="submit password"
              className={classes('submit')}
              disabled={isWorking}
              data-test-id={'login-button'}
              isWorking={isWorking}
            >
              {isWorking ? 'LOADING' : 'LOGIN'}
            </SubmitButton>
          </Buttons>
          <button
            aria-label="forgot password"
            className={classes('forgot-password')}
            data-test-id={'forgot-password-button'}
            onClick={this._onForgotPassword}
          >
            Forgot your password?
          </button>
        </Form>
      </div>
    );
  }

  _resetFormMaybe = (signInStep) => {
    if (signInStep === SignInStep.USERNAME) {
      this.form.reset();
    }
  };

  _setForm = (ref) => {
    this.form = ref;
  };

  _setInput = (ref) => {
    this.input = ref;
  };

  _focus = () => {
    this.input.focus();
  };

  _onForgotPassword = () => {
    window.open(this.props.forgotPasswordServer, '_self');
  };
}

export default mobxInjectSelect({
  messengerStore: ['isStaticRender'],
  sessionStore: [
    'events',
    'forgotPasswordServer',
    'isSignInLoading',
    'loginAttemptsLeft',
    'serverLocation',
    'signInError',
    'signInStep',
  ],
  staticStore: ['passwordFormClassName'],
})(PasswordForm);
