import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { mobxInjectSelect } from '../../utils';
import propTypes from '../../propTypes';
import BEM from '../../bem';
import { AttachmentIcon, Spinner } from '../';

const classes = BEM.with('AttachmentFileHeader');

const MAX_FILENAME_LENGTH = 65;

class AttachmentFileHeader extends Component {
  static propTypes = {
    attachment: propTypes.messageAttachment.isRequired,
    getDownloadState: PropTypes.func.isRequired,
    messageId: PropTypes.string.isRequired,
  };

  render() {
    const { attachment, getDownloadState, messageId } = this.props;
    const attachmentDownloadState = getDownloadState(messageId, attachment.id);

    return (
      <div className={classes()}>
        <div className={classes('icon-container')}>
          {attachmentDownloadState.isDownloading ? (
            <div className={classes('downloading-indicator')}>
              <Spinner />
            </div>
          ) : (
            this._renderDocumentIcon()
          )}
        </div>

        <div className={classes('document-name')}>{this.handleFileName()}</div>
      </div>
    );
  }

  handleFileName = () => {
    const {
      attachment: { name },
    } = this.props;
    if (!name) return 'Attachment';
    if (name.length < MAX_FILENAME_LENGTH) return name;

    const fileNameArr = name.split('.');
    const fileType = fileNameArr.pop();
    const fileName = fileNameArr.join('.');

    return `${fileName.slice(0, MAX_FILENAME_LENGTH)}...${fileType}`;
  };

  _renderDocumentIcon() {
    const {
      attachment: { contentType },
    } = this.props;

    return <AttachmentIcon fileType={contentType} className={classes('document-icon')} />;
  }
}

export default mobxInjectSelect({
  messageAttachmentStore: ['getDownloadState'],
})(AttachmentFileHeader);
