import React, { Component } from 'react';
import Button from './Button';

class SubmitButton extends Component {
  render() {
    return <Button type="submit" {...this.props} />;
  }
}

export default SubmitButton;
