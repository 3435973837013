import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import mobxInjectSelect from '../../utils/mobxInjectSelect';

const classes = BEM.with('MessageStatusFailedRetry');

class MessageStatusFailedRetry extends Component {
  static propTypes = {
    message: propTypes.message.isRequired,
    retrySend: PropTypes.func.isRequired,
    recallMessages: PropTypes.func.isRequired,
  };

  render() {
    const { message } = this.props;
    const { senderStatus } = message;

    return (
      <div className={classes()}>
        <button
          className={classNames(classes('retry-button'), classes({ senderStatus }))}
          onClick={this._retrySend}
          disabled={senderStatus === 'RETRYING'}
        >
          {senderStatus === 'FAILED' ? 'Resend' : senderStatus === 'RETRYING' ? 'Sending...' : null}
        </button>
        <button
          className={classNames(classes('delete-button'), classes({ senderStatus }))}
          onClick={this._recallMessage}
          disabled={senderStatus === 'RETRYING'}
        >
          {'Delete'}
        </button>
      </div>
    );
  }

  _recallMessage = () => {
    const { message, recallMessages } = this.props;
    recallMessages([message]);
  };

  _retrySend = () => {
    const { message, retrySend } = this.props;
    retrySend(message.id);
  };
}

export default mobxInjectSelect({
  messageStore: ['recallMessages', 'retrySend'],
})(MessageStatusFailedRetry);
