import { closeReasons, CloseReason } from '../../models/enums/CloseReason';
import { E1_START_CONNECTION, E2_CONNECTION_OPENED } from './WebSocketClient';
export type OnErrorClient = (error: Error) => void;

export type WebSocketError =
  | 'AuthFailure'
  | 'CommandMissing'
  | 'ConnectionClosed'
  | 'CouldNotEstablishConnection'
  | 'CouldNotInstantiateClient'
  | 'InvalidAuthCommand'
  | 'InvalidPayload'
  | 'UnknownCommand'
  | 'UnknownEvent'
  | 'OpenTimedOut';

export type OnErroWebSocket = ({
  code,
  hasSignaledError,
  detail,
  reason,
  type,
}: {
  code?: unknown;
  hasSignaledError?: boolean;
  detail?: string;
  reason?: string;
  type: WebSocketError;
}) => void;

export type OnMessage = (
  data: { data: string },
  opts: { isFirstMessage: boolean; command?: string }
) => void;

export type OnOpen = () => void;

class WebSocketRequest {
  hasForcedClose: boolean;
  hasSignaledClose: boolean;
  hasSignaledError: boolean;
  hasSignaledMessage: boolean;
  hasSignaledOpen: boolean;
  _onError?: OnErroWebSocket;
  _onMessage?: OnMessage;
  _onOpen?: OnOpen;
  url: string;
  socket: WebSocket;

  constructor({
    onError,
    onMessage,
    onOpen,
    url,
  }: {
    onError?: OnErroWebSocket;
    onMessage?: OnMessage;
    onOpen?: OnOpen;
    url: string;
  }) {
    this.hasForcedClose = false;
    this.hasSignaledClose = false;
    this.hasSignaledError = false;
    this.hasSignaledMessage = false;
    this.hasSignaledOpen = false;
    this._onError = onError;
    this._onMessage = onMessage;
    this._onOpen = onOpen;
    this.url = url;

    this.socket = new WebSocket(url);

    performance.mark(E1_START_CONNECTION);

    this.socket.addEventListener('close', (event) => this.onClose(event));
    this.socket.addEventListener('error', (event) => this.onError(event));
    this.socket.addEventListener('message', (event) => this.onMessage(event));
    this.socket.addEventListener('open', () => this.onOpen());
  }

  onOpen() {
    performance.mark(E2_CONNECTION_OPENED);

    if (this.hasSignaledOpen) return;
    this.hasSignaledOpen = true;

    if (this._onOpen) this._onOpen();
  }

  onError(_: Event) {
    if (this.hasSignaledError) return;
    this.hasSignaledError = true;
  }

  onClose(event: CloseEvent) {
    if (this.hasSignaledClose) return;
    this.close();
    this.hasSignaledClose = true;

    let type: WebSocketError;

    if (!event.wasClean) {
      type = 'OpenTimedOut';
    } else if (this.hasSignaledMessage) {
      type = 'ConnectionClosed';
    } else {
      type = 'CouldNotEstablishConnection';
    }

    const { code, reason } = event;

    if (this._onError)
      this._onError({
        code,
        hasSignaledError: this.hasSignaledError,
        reason,
        type,
      });
  }

  onMessage(event: { data: string }) {
    const isFirstMessage = !this.hasSignaledMessage;
    if (!this.hasSignaledMessage) this.hasSignaledMessage = true;

    if (this._onMessage) this._onMessage(event, { isFirstMessage });
  }

  close(code?: number, reason?: CloseReason) {
    if (this.hasSignaledClose || this.hasForcedClose) return;
    this.hasForcedClose = true;
    if (reason && closeReasons.includes(reason)) this.hasSignaledClose = true;
    return this.socket.close(code, reason);
  }

  send(message: string) {
    return this.socket.send(message);
  }
}

export default WebSocketRequest;
