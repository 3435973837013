import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { once } from 'ramda';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { BasicModal } from './';

class CancelEscalationModal extends Component {
  static propTypes = {
    cancelEscalation: PropTypes.func.isRequired,
    currentCancelEscalation: propTypes.escalationExecution,
    hideCancelEscalationModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const { currentCancelEscalation } = this.props;

    return (
      <BasicModal
        ariaLabelBody={'Are you sure you want to cancel this escalation?'}
        ariaLabelCancelButton={'Cancel'}
        ariaLabelCloseButton={'Close'}
        ariaLabelHeader={'Cancel Escalation'}
        ariaLabelSubmitButton={'Yes'}
        hasCloseButton={true}
        headerText={'Cancel Escalation'}
        isOpen={!!currentCancelEscalation}
        onClose={this._closeModal}
        onSubmit={once(this._cancelEscalation)}
        size={'medium'}
        theme={'danger'}
        submitText={'YES'}
        cancelText={'NO'}
      >
        <div>Are you sure you want to cancel this escalation?</div>
      </BasicModal>
    );
  }

  _closeModal = () => {
    const { closeModal, hideCancelEscalationModal } = this.props;
    hideCancelEscalationModal();
    closeModal();
  };

  _cancelEscalation = () => {
    const { cancelEscalation, currentCancelEscalation } = this.props;
    if (currentCancelEscalation) cancelEscalation(currentCancelEscalation);
    this._closeModal();
  };
}

export default mobxInjectSelect({
  escalationStore: ['cancelEscalation', 'currentCancelEscalation', 'hideCancelEscalationModal'],
})(CancelEscalationModal);
