type SavedEventType = {
  action: string;
  entity: { id: string; type: string };
  organization_id: string;
};
const formatSavedEvent = (data: SavedEventType) => {
  const {
    action,
    entity: { id, type },
    organization_id: organizationId,
  } = data;
  return { action, id, organizationId, type };
};

export default formatSavedEvent;
