import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import BEM from '../../../bem';
import propTypes from '../../../propTypes';
import { mobxInjectSelect } from '../../../utils';
import scheduledMessageFormats from '../../../utils/scheduledMessageFormats';
import { UserName } from '../../';
import { ReactComponent as CheckMarkSvg } from '../../../images/green-checkmark-full.svg';
import { ReactComponent as RepeatSvg } from '../../../images/repeat.svg';
import { Recipient, Status } from './ScheduleMessage';
const { SERVER_VALUE_TO_REPEAT_SCHEDULE, NONE_SELECTED_TEMPLATE } = scheduledMessageFormats;
const classes = BEM.with('ScheduledMessagesRow');

const ScheduledMessagesRow = ({
  index,
  bulkUpdateScheduledMessage,
  category,
  feed,
  isSelected,
  message,
  openEditScheduleMessageModal,
  openModal,
  recipient,
  reloadSearch,
  sender,
  toggleSelected,
  updatedBy,
}) => {
  const isQueueCategory = category === 'queue';
  const isHistoryCategory = category === 'history';

  const onItemClick = () => {
    if (message.smsOptedOut) {
      if (isQueueCategory) toggleSelected(message.id);
      return;
    }
    if (isQueueCategory && feed !== 'automated') {
      openEditScheduleMessageModal(message.id, { onDelete: reloadSearch });
      return;
    }

    const update = {
      ...message,
      recipient,
      repeatEnabled: message.repeating,
      repeatSchedule: SERVER_VALUE_TO_REPEAT_SCHEDULE[message.repeating],
      scheduledTime: moment.tz(message.scheduledTime, message.timezoneName),
      selectedEndDate: moment.tz(message.scheduledTime, message.timezoneName),
      selectedTemplate: NONE_SELECTED_TEMPLATE,
      sender: sender.displayName,
      timezone: message.timezoneName,
    };
    if (message.templateLabel) {
      update.selectedTemplate = {
        id: message.templateId,
        title: message.templateLabel,
      };
    }
    bulkUpdateScheduledMessage(update);

    openModal('scheduleMessageReview', {
      scheduledMessageView: true,
      reopenModal: '',
      id: message.id,
      category,
    });
  };

  return (
    <div
      className={classes('', {
        selected: isSelected,
        noCheckbox: !isQueueCategory,
        smsOptedOut: message.smsOptedOut,
      })}
      data-test-id={`scheduled-message-${index}-row`}
      onClick={onItemClick}
      aria-label="Edit Scheduled Message"
      role="row"
      onKeyDown={(e) => {
        if (e.key === ' ' || e.key === 'Enter') {
          onItemClick();
        }
      }}
    >
      {isQueueCategory && (
        <div
          className={classes('list-item', {
            checkbox: true,
          })}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') e.stopPropagation();
          }}
          role="cell"
          aria-label="Select this item"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              toggleSelected(message.id);
            }}
            className={classes('list-item-checkbox-default', {
              selected: isSelected,
            })}
            aria-checked={isSelected}
            role="checkbox"
          >
            {isSelected && <CheckMarkSvg aria-hidden />}
          </button>
        </div>
      )}
      <div className={classes('list-item')} role="cell">
        <Status
          status={message.status}
          isAutomated={!!message.appointmentId}
          smsOptedOut={message.smsOptedOut}
        />
      </div>
      {feed !== 'personal' && (
        <div
          className={classes('list-item', {
            three: true,
          })}
          role="cell"
        >
          <UserName user={sender} />
        </div>
      )}
      <div className={classes('list-item', { four: true })} role="cell">
        <Recipient recipient={recipient} />
      </div>
      <div className={classes('list-item', { four: true })} role="cell">
        <div
          className={classes('template-label')}
          title={message.templateLabel || 'No Template (Custom Message)'}
        >
          {message.templateLabel || (
            <div>
              No Template
              <br />
              (Custom Message)
            </div>
          )}
        </div>
      </div>
      <div className={classes('list-item')} role="cell">
        <div>
          {moment.tz(message.scheduledTime, message.timezoneName).format('MM/DD/YYYY')}
          <br />
          {moment.tz(message.scheduledTime, message.timezoneName).format('h:mm A z')}
          {message.repeating && (
            <div className={classes('repeating')}>
              <div className={classes('repeating-svg')}>
                <RepeatSvg aria-hidden />
              </div>
              <div>
                {message.repeating} {message.processedCount || 0}/
                {message.remainingCount + message.processedCount}
              </div>
            </div>
          )}
        </div>
      </div>
      {isHistoryCategory && (
        <div className={classes('list-item')} role="cell">
          {moment(message.createdOn).format('MM/DD/YYYY')}
          <br />
          {moment(message.createdOn).format('h:mm A z')}
        </div>
      )}
      <div
        className={classes('list-item', {
          updateDetails: true,
        })}
        role="cell"
      >
        <div
          className={classes('list-item-updated-on', {
            feedTypeAll: feed === 'all',
            feedTypePersonal: feed === 'personal',
          })}
        >
          {moment(message.updatedOn).format('MM/DD/YYYY')}
          <div className={classes('list-item-updated-by')}>
            By <UserName user={updatedBy} />
          </div>
        </div>
      </div>
    </div>
  );
};

ScheduledMessagesRow.propTypes = {
  index: PropTypes.number,
  bulkUpdateScheduledMessage: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  feed: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  message: PropTypes.object.isRequired,
  openEditScheduleMessageModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  recipient: propTypes.user.isRequired,
  sender: propTypes.user.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  updatedBy: propTypes.user.isRequired,
};

export default mobxInjectSelect({
  modalStore: ['openModal'],
  scheduleMessageStore: [
    'bulkUpdateScheduledMessage',
    'openEditScheduleMessageModal',
    'scheduledMessage',
  ],
})(ScheduledMessagesRow);
