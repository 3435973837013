import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import BEM from '../../../../common/bem';
import { ReactComponent as DndStatusSvg } from '../../../../common/images/dnd-status.svg';
import type { Entity } from '../../../../types';

const classes = BEM.with('DndBanner');

export const DndText = ({ dndText }: { dndText: string }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const overflow = ref.current.scrollHeight > ref.current.clientHeight;
      setIsOverflowing(overflow);
    }
    setIsExpanded(false);
  }, [dndText]);

  return (
    <div
      ref={ref}
      onClick={() => (isOverflowing || isExpanded) && setIsExpanded(!isExpanded)}
      className={classes('dnd-text', { isExpanded, isOverflowing })}
      title={dndText}
    >
      {dndText}
    </div>
  );
};

type DndBannerProps = {
  counterParty?: { dnd?: boolean; $entityType: string; dndText?: string };
  counterPartyProfile?: { displayName: string; id: string };
  currentOrganizationId?: string;
  hasCurrentConversation?: boolean;
  getAutoForwardEntityDisplayName?: (entities: string[]) => string;
  isComposing?: boolean;
  isExtendedAutoForwardOptionsEnabled?: boolean;
  isRecipientPickerOpen?: boolean;
  selectedRecipients?: Entity[];
};

export const DndBanner = observer(
  ({
    counterParty,
    counterPartyProfile,
    currentOrganizationId,
    getAutoForwardEntityDisplayName,
    hasCurrentConversation,
    isComposing,
    isExtendedAutoForwardOptionsEnabled,
    isRecipientPickerOpen,
    selectedRecipients,
  }: DndBannerProps) => {
    let showDndBanner = false;
    let dndText = 'Is Unavailable';
    let autoForwardDisplayName = '';

    if ((isComposing || isRecipientPickerOpen) && selectedRecipients?.length === 1) {
      if (selectedRecipients[0].dnd && selectedRecipients[0].$entityType === 'user') {
        showDndBanner = true;
        dndText = selectedRecipients[0].dndText || 'Is Unavailable';

        if (selectedRecipients[0].profileByOrganizationId && currentOrganizationId) {
          const profile = selectedRecipients[0].profileByOrganizationId?.[currentOrganizationId];
          if (isExtendedAutoForwardOptionsEnabled) {
            autoForwardDisplayName =
              getAutoForwardEntityDisplayName?.(profile?.dndAutoForwardEntities || []) || '';
          } else {
            autoForwardDisplayName = profile?.autoForwardReceivers?.[0]?.displayName || '';
          }
        }
      }
    } else if (
      hasCurrentConversation &&
      counterParty?.$entityType === 'user' &&
      counterParty?.dnd
    ) {
      showDndBanner = true;
      dndText = counterParty.dndText || 'Is Unavailable';
      autoForwardDisplayName = counterPartyProfile?.displayName || '';
    }

    if (!showDndBanner) return null;

    return (
      <div className={classes()}>
        <div className={classes('dnd-status')}>
          <div className={classes('dnd-status-information')}>
            <div className={classes('dnd-status-svg')}>
              <DndStatusSvg />
            </div>
            <DndText dndText={dndText} />
          </div>
          {autoForwardDisplayName && (
            <div className={classes('dnd-auto-forward')}>
              Auto-forwarding to:
              <span className={classes('dnd-auto-forward-name')}>{autoForwardDisplayName}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
);
