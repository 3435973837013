import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { mobxInjectSelect } from '../../utils';
import propTypes from '../../propTypes';
import Spinner from '../Spinner';
import BEM from '../../bem';

import RetryAttachment from './RetryAttachment';

const classes = BEM.with('ImageAttachment');

class ImageAttachment extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    attachment: propTypes.messageAttachment.isRequired,
    getDownloadState: PropTypes.func.isRequired,
    isMultiSelectMode: PropTypes.bool.isRequired,
    message: propTypes.message.isRequired,
    messageId: PropTypes.string.isRequired,
    multiSelectable: PropTypes.bool.isRequired,
    setImageLoaded: PropTypes.func.isRequired,
  };

  state = {
    isDownloading: true,
  };

  render() {
    const {
      action,
      attachment,
      getDownloadState,
      isMultiSelectMode,
      messageId,
      multiSelectable,
      retryDownload,
      attachmentUrl,
    } = this.props;
    const { localPath } = attachment;
    const { isDownloading } = this.state;
    const attachmentDownloadState = getDownloadState(messageId, attachment.id);
    const isLoading = attachmentDownloadState.isLoading;
    const url = isLoading ? null : attachmentUrl || localPath || attachmentDownloadState.url;
    const isErrored = !url && !attachmentDownloadState.isLoading;
    let loaderFragment, imageFragment;

    if (url) {
      imageFragment = (
        <img
          alt={attachment.name}
          className={classes('image', {
            isDownloading,
            unselectable: isMultiSelectMode && !multiSelectable,
          })}
          onClick={isMultiSelectMode ? () => {} : () => action(url)}
          onLoad={this._imageLoaded}
          src={url}
          data-test-id={attachment.name}
        />
      );
    } else if (isErrored) {
      loaderFragment = <RetryAttachment retry={retryDownload} />;
    } else if (isLoading || isDownloading) {
      loaderFragment = (
        <div className={classes('loading')}>
          <span className={classes('downloading-indicator')}>
            <Spinner />
          </span>
        </div>
      );
    }

    return (
      <div className={classes({ type: 'image' })}>
        {loaderFragment}
        {imageFragment}
      </div>
    );
  }

  _imageLoaded = () => {
    const { message, setImageLoaded } = this.props;

    this.setState({ isDownloading: false });
    setImageLoaded(message.id);
  };
}

export default mobxInjectSelect({
  conversationStore: ['setImageLoaded'],
  messageAttachmentStore: ['getDownloadState'],
})(ImageAttachment);
