import React, { useEffect, useState } from 'react';

import { CtaButton } from '../../../shared-components/CtaButton/CtaButton';
import { Input } from '../../../shared-components/Input/Input';
import Modal from '../../../shared-components/Modal/Modal';
import { SettingContainer } from '../../../shared-components/SettingContainer/SettingContainer';
import { StandardOrgSettingInput } from '../../../shared-components/StandardSettingInput';
import { Table } from '../../../shared-components/Table/Table';
import { ViewContainer } from '../../../shared-components/View/ViewContainer/ViewContainer';
import styles from '../OrganizationSettings.module.css';
import { OrganizationSettingListItem } from '../OrganizationSettingsListItem';
import TCClient from '../../../../client';
import { SET_TOAST_DURATION_IN_MILLISECONDS } from '../../../utils/setToastDuration';

import { TabProps } from './';
import { actions, thunk, useAppDispatch, useAppSelector } from 'redux-stores';

export default function OrgDetails({ onClose }: TabProps) {
  const dispatch = useAppDispatch();
  const { name, selectedOrganizationId } = useAppSelector((state) => ({
    name: state.orgSettings.settings.name,
    selectedOrganizationId: state.admin.selectedOrganizationId,
  }));

  useEffect(() => {
    async function fetchEmailDomains() {
      try {
        const { emailDomains } = await TCClient.api.adminOrganizations.getEmailDomains(
          selectedOrganizationId
        );
        setDomainBuffer(emailDomains);
      } catch (e) {
        console.error(e);
      }
    }
    fetchEmailDomains();
  }, [selectedOrganizationId]);

  const [isEditOrgNameOpen, setIsEditOrgNameOpen] = useState(false);
  const [orgNameInputBuffer, setOrgNameInputBuffer] = useState(name);
  const [domainNameInputBuffer, setDomainNameInputBuffer] = useState('');
  const [domainBuffer, setDomainBuffer] = useState<string[]>([]);
  const [isDeleteDomainModalOpen, setIsDeleteDomainModalOpen] = useState(false);
  const [domainToDelete, setDomainToDelete] = useState('');

  function saveOrgName() {
    dispatch(actions.updateSettings({ name: orgNameInputBuffer }));
    thunk.saveSettings({ dispatch });
  }

  async function addEmailDomain(domainName: string) {
    try {
      await TCClient.api.adminOrganizations.addEmailDomain({
        domainNames: [domainName],
        organizationId: selectedOrganizationId,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function removeEmailDomain(domainName: string) {
    try {
      await TCClient.api.adminOrganizations.deleteEmailDomain({
        domainNames: [domainName],
        organizationId: selectedOrganizationId,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function addDomain() {
    if (domainNameInputBuffer.length === 0) {
      return;
    }
    if (domainBuffer.includes(domainNameInputBuffer)) {
      thunk.setToastSettings({
        duration: SET_TOAST_DURATION_IN_MILLISECONDS(3),
        open: true,
        message: `${domainNameInputBuffer} already exists.`,
        type: 'FAILURE',
      });
      return;
    }
    await addEmailDomain(domainNameInputBuffer);
    setDomainBuffer([...domainBuffer, domainNameInputBuffer]);
    setDomainNameInputBuffer('');
  }

  async function removeDomain() {
    await removeEmailDomain(domainToDelete);
    setDomainToDelete('');
    setIsDeleteDomainModalOpen(false);
    setDomainBuffer([...domainBuffer.filter((domain) => domain !== domainToDelete)]);
  }

  return (
    <ViewContainer
      {...{
        header: 'Org Details',
        subHeader: "Change the organization's name. Create and associate domains with this org.",
        onClose,
      }}
    >
      <div>
        {isEditOrgNameOpen ? (
          <div className={styles.orgSettingsActionContainer}>
            <div>
              <Input
                value={orgNameInputBuffer}
                onInputChange={(e) => {
                  setOrgNameInputBuffer(e.target.value);
                }}
                dataTestId={'org-name-input'}
              />
            </div>
            <div className={styles.orgTypeLabel}>
              <CtaButton
                primary
                label="Update Org Name"
                onClick={saveOrgName}
                dataTestId={'update-org-name-button'}
              />
            </div>
            <div
              className={styles.closeButton}
              data-test-id={'close-org-name-edit'}
              onClick={() => setIsEditOrgNameOpen(false)}
            />
          </div>
        ) : (
          <div className={styles.orgSettingsActionContainer}>
            <div className={styles.orgNameDetails}>{name}</div>
            <div
              className={styles.editIcon}
              data-test-id={'open-org-name-edit'}
              onClick={() => setIsEditOrgNameOpen(true)}
            />
          </div>
        )}
        {domainBuffer.length > 0 && (
          <div>
            <SettingContainer
              label={"Prevent 'Pending Users':"}
              subLabel={
                'Users will be notified to contact their administrator if they try to register.'
              }
              settingControl={
                <StandardOrgSettingInput
                  options={[
                    { label: 'On', value: true },
                    { label: 'Off', value: false },
                  ]}
                  settingName={'preventPendingUsers'}
                  type={'radio'}
                />
              }
            />
          </div>
        )}
        {domainBuffer.length > 0 && (
          <Table
            actionsOrder={[{ name: 'Delete', type: 'delete' }]}
            columns={[{ field: 'domains', headerName: 'Domains' }]}
            rows={domainBuffer.map((domain) => ({ id: domain, domains: domain }))}
            onRowDelete={(row) => {
              setDomainToDelete(row.domains);
              setIsDeleteDomainModalOpen(true);
            }}
          />
        )}
        <div className={styles.orgSettingsActionContainer}>
          <div className={styles.addDomainContainer}>
            <div>
              <Input
                customCSS={styles.input}
                value={domainNameInputBuffer}
                onInputChange={(e) => {
                  setDomainNameInputBuffer(e.target.value);
                }}
                dataTestId={'add-domain-name-input'}
              />
            </div>
            <div className={styles.orgTypeLabel}>
              <CtaButton
                primary
                label="Add Domain"
                dataTestId={'add-domain-button'}
                onClick={addDomain}
              />
            </div>
          </div>
          <div>
            <OrganizationSettingListItem
              label={'Org Type'}
              description={''}
              control={
                <StandardOrgSettingInput
                  options={[
                    { label: 'Sales Demo', value: 'DEMO' },
                    { label: 'QA (testing)', value: 'QA' },
                    { label: 'Customer (Production)', value: 'CUSTOMER' },
                  ]}
                  settingName={'orgType'}
                  type={'dropdown'}
                />
              }
            />
          </div>
          <Modal
            body={`Are you sure you want to delete the domain - ${domainToDelete}`}
            isOpen={isDeleteDomainModalOpen}
            closeButton={'Cancel'}
            canClose
            doneButton={'Delete'}
            header={'Delete Domain'}
            isWarningButtonPresent={true}
            modalSize={'medium'}
            onClose={() => setIsDeleteDomainModalOpen(false)}
            onSuccess={() => removeDomain()}
          />
        </div>
      </div>
    </ViewContainer>
  );
}
