import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import Modal from '../Modal';
import mobxInjectSelect from '../../utils/mobxInjectSelect';

const classes = BEM.with('CallModal');

class EnableBrowserPermissionsModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    currentCall: PropTypes.shape({
      callStatus: PropTypes.string,
      payload: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    endVoipCall: PropTypes.func.isRequired,
    isVideoInactive: PropTypes.bool.isRequired,
    options: PropTypes.shape({
      isVideo: PropTypes.bool.isRequired,
    }).isRequired,
    room: PropTypes.object,
    setIncomingCallActionsEnabled: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, isVideoInactive, options, ...restProps } = this.props;
    const { audioDevice, isVideo, videoDevice } = options;
    const enableVideo = isVideo && !isVideoInactive && !videoDevice;
    const headerText = !audioDevice
      ? `Unable to connect to ${!videoDevice ? 'camera and microphone' : 'microphone'}`
      : `Enable microphone ${enableVideo ? 'and camera ' : ''}permissions`;
    const bodyText = !audioDevice
      ? `Please check the connection${!videoDevice ? 's' : ''} and try again.`
      : 'You need to allow access from your browser to start the call.';
    return (
      <Modal
        bodyClass={classes('unsupported-body')}
        className={classes()}
        closeClass={classes('close-button')}
        headerClass={classes('header')}
        isOpen={isOpen}
        onRequestClose={this._closeModal}
        size={'medium'}
        footerSecondaryActions={
          <button className={classes('primary-btn')} onClick={this._closeModal} type="button">
            OK
          </button>
        }
        {...restProps}
      >
        <div className={classes('title')}>{headerText}</div>
        {bodyText}
      </Modal>
    );
  }

  _closeModal = async () => {
    const { closeModal, currentCall, endVoipCall, room, setIncomingCallActionsEnabled } =
      this.props;

    if (room) room.disconnect();
    if (currentCall) await endVoipCall();
    setIncomingCallActionsEnabled(true);
    closeModal();
  };
}

export default mobxInjectSelect({
  callStore: [
    'closeModal',
    'currentCall',
    'endVoipCall',
    'isVideoInactive',
    'room',
    'setIncomingCallActionsEnabled',
  ],
})(EnableBrowserPermissionsModal);
