import React, { useEffect, useState } from 'react';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import TCClient from '../../client';
import Modal from './Modal';

const classes = BEM.with('CreateOrEditCustomDirectoriesModal');

type CreateOrEditCustomDirectoriesModalProps = {
  options: {
    id: string;
    orgSecurityGroups: string[];
  };
};

type SecurityGroupType = {
  name: string;
};

type MobxProps = {
  closeModal: () => void;
  currentOrganizationId: string;
  isOpen: boolean;
};

const CreateOrEditCustomDirectoriesModal = ({
  closeModal,
  currentOrganizationId,
  isOpen,
  options: { id: entityId, orgSecurityGroups },
}: MobxProps & CreateOrEditCustomDirectoriesModalProps) => {
  const [entitySecurityGroups, setEntitySecurityGroups] = useState<string[]>([]);

  useEffect(() => {
    const getEntityCustomDirectories = async () => {
      try {
        const res = await TCClient.adminOrganizations.getEntityCustomDirectories({
          orgId: currentOrganizationId,
          entityId,
        });
        if (res) {
          if (Array.isArray(res)) {
            const groups = res.map((securityGroup: SecurityGroupType) => {
              return securityGroup.name;
            });
            setEntitySecurityGroups(groups);
          } else {
            setEntitySecurityGroups([res.name]);
          }
        } else {
          setEntitySecurityGroups([]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getEntityCustomDirectories();
  }, [entityId, currentOrganizationId, isOpen]);

  const saveCustomDirectories = async () => {
    try {
      await TCClient.adminOrganizations.updateEntityCustomDirectories({
        orgId: currentOrganizationId,
        entityId,
        entitySecurityGroups,
      });
    } catch (err) {
      console.log(err);
    }
    closeModal();
  };

  const addOrDeleteEntityGroup = (orgSecurityGroup: string) => {
    const updatedEntitySecurityGroups: string[] = [];
    const isOrgSecGroupPartOfEntitySecGroup = entitySecurityGroups?.includes(orgSecurityGroup);
    if (isOrgSecGroupPartOfEntitySecGroup) {
      updatedEntitySecurityGroups.push(
        ...entitySecurityGroups.filter(
          (entitySecurityGroup) => entitySecurityGroup !== orgSecurityGroup
        )
      );
    } else {
      updatedEntitySecurityGroups.push(...entitySecurityGroups, orgSecurityGroup);
    }
    setEntitySecurityGroups(updatedEntitySecurityGroups);
  };

  return (
    <Modal
      bodyClass={classes('body')}
      closeClass={classes('close-button')}
      footerClass={classes('footer')}
      header={'Custom Directories'}
      headerClass={classes('header')}
      isOpen={isOpen}
      size={'large'}
      className={classes()}
      onRequestClose={closeModal}
      footerPrimaryActions={
        <button
          aria-label="Save"
          className={classes('save-btn-container')}
          type="button"
          onClick={saveCustomDirectories}
        >
          <div className={classes('save-btn')}>Save</div>
        </button>
      }
    >
      <div className={classes('label')}>
        Identify which custom directories this Broadcast List should be in. Note: These directories
        must already have been created (See Org Settings: Custom Directories)
      </div>
      <div className={classes('info')}>
        <div className={classes('checkbox-container')}>
          {orgSecurityGroups.map((orgSecurityGroup) => (
            <div className={classes('custom-directory-item')} key={orgSecurityGroup}>
              <input
                type="checkbox"
                className={classes('checkbox')}
                checked={entitySecurityGroups?.includes(orgSecurityGroup)}
                onChange={() => addOrDeleteEntityGroup(orgSecurityGroup)}
              />
              <span className={classes('custom-directory-label')}>{orgSecurityGroup}</span>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default mobxInjectSelect<CreateOrEditCustomDirectoriesModalProps, MobxProps>({
  messengerStore: ['currentOrganizationId'],
})(CreateOrEditCustomDirectoriesModal);
