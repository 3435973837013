import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from 'common/bem';
import { EscalationPolicyInfoPane, UserConversationInfoPane } from 'common/components/';
import { GroupEditor } from 'common/components/GroupEditor';
import RoleInfoPane from 'common/components/Roles/RoleInfoPane';
import mobxInjectSelect from 'common/utils/mobxInjectSelect';

const classes = BEM.with('InfoPane');

const COMPONENT_BY_TYPE = {
  EscalationPolicyInfo: EscalationPolicyInfoPane,
  GroupEditor: GroupEditor,
  RoleConversationInfo: RoleInfoPane,
  RoleInfo: RoleInfoPane,
  UserConversationInfo: UserConversationInfoPane,
};

class InfoPane extends Component {
  static propTypes = {
    closeInfoPane: PropTypes.func.isRequired,
    infoPane: PropTypes.shape({
      button: PropTypes.string,
      type: PropTypes.oneOf(Object.keys(COMPONENT_BY_TYPE)).isRequired,
    }),
    isInfoPaneOpen: PropTypes.bool.isRequired,
  };

  componentDidUpdate() {
    const { isInfoPaneOpen } = this.props;
    if (this.focusEntity && isInfoPaneOpen) this.focusEntity.focus();
  }

  render() {
    const { infoPane, isInfoPaneOpen } = this.props;
    let pane;

    if (infoPane) {
      const { type, ...componentProps } = infoPane;
      const Component = COMPONENT_BY_TYPE[type];

      pane = <Component {...componentProps} />;
    }

    return (
      <aside aria-labelledby="conversation-info-heading" className={classes()}>
        <h2 id="conversation-info-heading" className="screen-reader-only">
          Conversation Information
        </h2>
        <div
          id="infoPane"
          className={classes('main', { isInfoPaneOpen })}
          ref={this._setFocusEntity}
          tabIndex={isInfoPaneOpen ? 0 : -1}
        >
          {pane}
        </div>
      </aside>
    );
  }

  _setFocusEntity = (ref) => {
    this.focusEntity = ref;
    this.props.setInfoPaneRef(ref);
  };
}

export default mobxInjectSelect({
  messengerStore: ['closeInfoPane', 'infoPane', 'isInfoPaneOpen', 'setInfoPaneRef'],
})(InfoPane);
