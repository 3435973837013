import React, { useEffect } from 'react';
import {
  EventDeliveryMethod,
  EventDeliveryMethods,
} from '../../../../models/enums/DeliveryMethods';
import BEM from '../../../bem';
import { mobxInjectSelect } from '../../../utils';
import { DeliveryMethodIcon, OneWayDelivery, TwoWayDelivery } from '../DeliveryMethodIcon';
import AccessibleList from 'common/components/AccessibleList';

const { LINK, ONE_WAY_LINK, SMS } = EventDeliveryMethods;
const classes = BEM.with('DeliveryMethodScheduledMessageMenu');
const menuItemClasses = BEM.with('DeliveryMethodScheduledMessageMenuItem');

type DeliveryMethodMenuProps = {
  handleClick?: (deliveryMethod: EventDeliveryMethod) => void;
  accessibilityMode?: boolean;
};

type DeliveryMethodProps = {
  copy: string;
  deliveryMethod: EventDeliveryMethod;
  shouldShowArrows?: boolean;
  shouldShowCircle?: boolean;
  shouldShowIcon?: boolean;
} & DeliveryMethodMenuProps;

type MobxProps = {
  allowPatientDeliveryMethod?: boolean;
};

export const DeliveryMethodMenu = ({
  handleClick,
  allowPatientDeliveryMethod,
  accessibilityMode,
}: DeliveryMethodMenuProps & MobxProps) => {
  useEffect(() => {
    if (accessibilityMode) {
      (
        document.querySelector(
          '.tc-DeliveryMethodScheduledMessageMenuItem--clickable'
        ) as HTMLElement
      ).focus();
    }
  }, [accessibilityMode]);

  return (
    <AccessibleList
      className={classes()}
      focusableClasses={['.tc-DeliveryMethodScheduledMessageMenuItem--clickable']}
      focusStart={'first'}
      direction="vertical"
      loop={false}
      accessibilityMode={accessibilityMode}
    >
      <DeliveryMethod
        copy="Secure Browser Link: HIPAA-Compliant"
        deliveryMethod={LINK}
        handleClick={handleClick}
      />
      <DeliveryMethod
        copy="Secure Browser Link: HIPAA-Compliant"
        deliveryMethod={ONE_WAY_LINK}
        handleClick={handleClick}
      />
      {allowPatientDeliveryMethod && (
        <DeliveryMethod
          copy="Unsecure SMS Text: Non-HIPAA Compliant"
          deliveryMethod={SMS}
          handleClick={handleClick}
        />
      )}
    </AccessibleList>
  );
};

export const DeliveryMethod: React.FC<DeliveryMethodProps> = ({
  copy,
  deliveryMethod,
  handleClick,
  shouldShowArrows = true,
  shouldShowCircle = false,
  shouldShowIcon = true,
}) => (
  <button
    onClick={() => handleClick && handleClick(deliveryMethod)}
    tabIndex={-1}
    className={menuItemClasses('', { clickable: !!handleClick })}
    onKeyDown={(e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
      }
      if (e.key === ' ' || e.key === 'Enter') {
        e.stopPropagation();
      }
    }}
  >
    {shouldShowIcon && (
      <DeliveryMethodIcon
        circleColor={shouldShowCircle ? 'var(--neutral-200)' : ''}
        isSecure={deliveryMethod === LINK || deliveryMethod === ONE_WAY_LINK}
      />
    )}
    {copy}
    {deliveryMethod === LINK && (
      <TwoWayDelivery className={menuItemClasses('arrows')} shouldShowArrows={shouldShowArrows} />
    )}
    {(deliveryMethod === ONE_WAY_LINK || deliveryMethod === SMS) && (
      <OneWayDelivery className={menuItemClasses('arrows')} shouldShowArrows={shouldShowArrows} />
    )}
  </button>
);

export default mobxInjectSelect<DeliveryMethodMenuProps, MobxProps>({
  patientAdminStore: ['allowPatientDeliveryMethod'],
})(DeliveryMethodMenu);
