import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { once } from 'lodash';
import BEM from '../../bem';
import Modal from '../Modal';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { Spinner } from '../';
import { ReactComponent as IconVideoSvg } from '../../images/icon-video.svg';

const classes = BEM.with('CallModal');

class ConfirmCallModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isSettingUpCall: PropTypes.bool.isRequired,
    startCall: PropTypes.func.isRequired,
    options: PropTypes.shape({
      entity: PropTypes.shape({
        recipientId: PropTypes.string.isRequired,
        recipientName: PropTypes.string.isRequired,
      }).isRequired,
    }),
  };

  render() {
    const {
      closeModal,
      isOpen,
      isSettingUpCall,
      options: { entity = {} },
      ...restProps
    } = this.props;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('close-button')}
        headerClass={classes('header')}
        isOpen={isOpen}
        onRequestClose={closeModal}
        size={'medium'}
        {...restProps}
      >
        <div className={classes('title')}>
          Start a {entity.members?.length > 2 ? 'group' : ''} call with {entity.recipientName}
        </div>
        <button
          className={classes('call-button')}
          data-test-id={'confirm-call-button'}
          disabled={isSettingUpCall}
          onClick={once(this._startVideoCall)}
        >
          {isSettingUpCall && (
            <div className={classes('spinner')}>
              {' '}
              <Spinner />{' '}
            </div>
          )}
          {!isSettingUpCall && <IconVideoSvg height={25} width={25} fill={'#4A657B'} />}
          {!isSettingUpCall && <div className={classes('call-button-text')}> Start Video Call</div>}
        </button>
      </Modal>
    );
  }

  _startVideoCall = () => {
    if (!this.props.isOpen || this.props.isSettingUpCall) return;
    const {
      options: { entity = {} },
      startCall,
    } = this.props;

    entity.context = {
      type: entity.$entityType === 'user' ? 'account' : entity.$entityType,
      id: entity.id,
    };

    startCall(entity);
  };
}

export default mobxInjectSelect({
  callStore: ['closeModal', 'isSettingUpCall', 'startCall'],
})(ConfirmCallModal);
