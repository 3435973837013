import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Scrollbars } from 'tt-react-custom-scrollbars';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { AlertCard } from '../../widgets/messenger/components/AlertCard';
import { Group, Message, User } from '../../types';
import { GroupMessageRecipient, Modal } from './';

const classes = BEM.with('AlertDetailsModal');

type AlertDetailsModalProps = {
  isOpen: boolean;
  options: {
    message: Message & { counterParty: User | Group; alertRecipientIds: string[] };
  };
};

type MobxProps = {
  closeModal: () => void;
  ensureRecipientStatus: (
    messageId: string,
    { includeUsers }?: { includeUsers?: boolean }
  ) => Promise<void>;
  isGroupAlertsAllowed: boolean;
  selectedFilterBar: string;
};

function WrappedAlertDetailsModal({
  closeModal,
  ensureRecipientStatus,
  isGroupAlertsAllowed,
  isOpen,
  options: { message },
  selectedFilterBar,
}: AlertDetailsModalProps & MobxProps) {
  const { statusesPerRecipient, alertRecipientIds } = message;

  return (
    <Modal
      bodyClass={classes('body')}
      className={classes()}
      header="Alert Details"
      isOpen={isOpen}
      onRequestClose={closeModal}
      size={'variable'}
    >
      <Scrollbars autoHeight autoHide autoHeightMax="calc(100vh - 110px)">
        <div className={classes('message-container')}>
          <AlertCard
            isExpanded
            message={message}
            showConversationLink={selectedFilterBar === 'Alerts'}
          />
        </div>
        {isGroupAlertsAllowed && message?.featureService === 'group_alerts' && (
          <div className={classes('recipients-outer')}>
            <div className={classes('recipients-inner')}>
              {statusesPerRecipient
                .filter((recipient) => alertRecipientIds?.includes(recipient?.userId))
                .map((status, idx) => (
                  <GroupMessageRecipient
                    isFirst={idx === 0}
                    key={status.userId}
                    showReaction
                    status={status}
                  />
                ))}
            </div>
          </div>
        )}
      </Scrollbars>
    </Modal>
  );
}

const ObservedAlertDetailsModal = observer(WrappedAlertDetailsModal);

function AlertDetailsModal({
  closeModal,
  ensureRecipientStatus,
  isGroupAlertsAllowed,
  isOpen,
  options: { message },
  selectedFilterBar,
}: AlertDetailsModalProps & MobxProps) {
  useEffect(() => {
    if (message.shouldEnsureRecipientStatus) {
      ensureRecipientStatus(message.id, { includeUsers: true });
    }
  }, [ensureRecipientStatus, message.id, message.shouldEnsureRecipientStatus]);

  return (
    <ObservedAlertDetailsModal
      closeModal={closeModal}
      ensureRecipientStatus={ensureRecipientStatus}
      isGroupAlertsAllowed={isGroupAlertsAllowed}
      isOpen={isOpen}
      options={{ message }}
      selectedFilterBar={selectedFilterBar}
    />
  );
}

export default mobxInjectSelect<AlertDetailsModalProps, MobxProps>({
  messageStore: ['ensureRecipientStatus'],
  messengerStore: ['isGroupAlertsAllowed'],
  rosterStore: ['selectedFilterBar'],
})(AlertDetailsModal);
