import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BEM from '../../bem';
import { ReactComponent as AttachmentSvg } from '../../images/attachment.svg';

const classes = BEM.with('AttachmentButton');

class AttachmentButton extends Component {
  static propTypes = {
    hasAttachments: PropTypes.bool.isRequired,
    onAttachmentButton: PropTypes.func.isRequired,
  };

  render() {
    const { onAttachmentButton, hasAttachments } = this.props;

    return (
      <div className={classes()}>
        <button
          aria-label="Message Attachment Button"
          className={classes('attachment-button-container')}
          onClick={onAttachmentButton}
        >
          <AttachmentSvg
            className={classes('image', { 'has-attachment': hasAttachments })}
            fill={'#dadada'}
            aria-hidden
          />
        </button>
      </div>
    );
  }
}

export default observer(AttachmentButton);
