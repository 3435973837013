import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { once } from 'ramda';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import BasicModal from './BasicModal';

const classes = BEM.with('DeleteTeamModal');

class DeleteTeamModal extends Component {
  static propTypes = {
    deleteTeam: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    options: PropTypes.shape({ team: propTypes.team.isRequired }),
  };

  render() {
    const {
      options: { team },
      isOpen,
    } = this.props;

    return (
      <BasicModal
        ariaLabelCloseButton="Delete Team Close"
        ariaLabelHeader="Delete Team Header"
        closeClass={classes('close-button')}
        header="Delete Team"
        headerClass={classes('header')}
        isOpen={isOpen}
        size={'medium-small'}
        submitText="DELETE"
        ariaLabelSubmitButton="Delete Team Delete"
        hasCloseButton
        onSubmit={once(this._deleteTeam)}
        theme="danger"
        ariaLabelCancelButton="Delete Team Cancel"
        onClose={this._closeModal}
      >
        <div aria-label="Delete Team Info" className={classes('info')}>
          Are you sure you want to delete the <span>{team.displayName}</span> team?
        </div>
      </BasicModal>
    );
  }

  _closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  _deleteTeam = async () => {
    const {
      options: { team },
      deleteTeam,
    } = this.props;
    const status = await deleteTeam(team.id);

    if (status === 204) {
      this._closeModal();
    }
  };
}

export default mobxInjectSelect({
  teamStore: ['deleteTeam'],
})(DeleteTeamModal);
