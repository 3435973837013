import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../../utils';
import { ReactComponent as EscalationIconSvg } from '../../images/icon-escalation.svg';
import BEM from '../../bem';

const classes = BEM.with('EscalationButton');

class EscalationButton extends Component {
  static propTypes = {
    alwaysEscalate: PropTypes.bool,
    isEscalated: PropTypes.bool.isRequired,
    setEscalated: PropTypes.func.isRequired,
    showEscalationModalIfShould: PropTypes.func.isRequired,
  };

  render() {
    const { alwaysEscalate, isEscalated } = this.props;

    return (
      <div className={classes()}>
        <EscalationIconSvg
          aria-label="Message Escalation Button"
          className={classes('image', { alwaysEscalate, isEscalated })}
          onClick={this._onEscalation}
        />
      </div>
    );
  }

  _onEscalation = () => {
    let { isEscalated } = this.props;
    const { alwaysEscalate, openModal, setEscalated, showEscalationModalIfShould } = this.props;

    if (isEscalated && alwaysEscalate) {
      openModal('alwaysEscalate');
      return;
    }

    isEscalated = !isEscalated;

    setEscalated(isEscalated);

    if (isEscalated) {
      showEscalationModalIfShould();
    }
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['isEscalated', 'setEscalated', 'showEscalationModalIfShould'],
  modalStore: ['openModal'],
})(EscalationButton);
