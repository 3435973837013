export type TemplateVariable = {
  description: string;
  display: string;
  example: string;
  id: string;
  pii?: boolean;
  source: string;
};

export const templateVariables: TemplateVariable[] = [
  {
    description: 'First Name of Patient',
    display: 'PatientFN',
    example: 'Jeff',
    id: '{{PatientFN}}',
    pii: true,
    source: 'TC',
  },
  {
    description: 'Last Name of Patient',
    display: 'PatientLN',
    example: 'Cook',
    id: '{{PatientLN}}',
    pii: true,
    source: 'TC',
  },
  {
    description: 'Appointment Date in Day and Month',
    display: 'ApptMonthDay',
    example: 'March 3rd',
    id: '{{ApptMonthDay}}',
    source: 'appointment',
  },
  {
    description: 'Appointment Date in Day, Month and Year',
    display: 'ApptMonthDayYear',
    example: 'March 3rd, 2020',
    id: '{{ApptMonthDayYear}}',
    source: 'appointment',
  },
  {
    description: 'Appointment Date in Day, Month, and Year full format',
    display: 'ApptDDMMYYYY',
    example: '03/03/2020',
    id: '{{ApptDDMMYYYY}}',
    source: 'appointment',
  },
  {
    description: 'Appointment Date in Day and Month',
    display: 'ApptMMDD',
    example: '10/03',
    id: '{{ApptMMDD}}',
    source: 'appointment',
  },
  {
    description: 'Appointment Time',
    display: 'ApptTime',
    example: '10:00 p.m.',
    id: '{{ApptTime}}',
    source: 'appointment',
  },
  {
    description: 'The Day of the Appointment',
    display: 'ApptDayFull',
    example: 'Monday',
    id: '{{ApptDayFull}}',
    source: 'appointment',
  },
  {
    description: 'The Day of the Appointment',
    display: 'ApptDayShort',
    example: 'Mon',
    id: '{{ApptDayShort}}',
    source: 'appointment',
  },
  {
    description: 'Appointment Time Zone',
    display: 'ApptTimeZone',
    example: 'PST',
    id: '{{ApptTimeZone}}',
    source: 'appointment',
  },
  {
    description: 'Org, Department, Facility, Clinic, Etc.',
    display: 'Location',
    example: 'Santa Monica Allergy',
    id: '{{Location}}',
    source: 'appointment',
  },
  {
    description: 'Name of provider',
    display: 'Provider',
    example: 'UCLA Health',
    id: '{{Provider}}',
    source: 'appointment',
  },
];
