import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../utils';

import BEM from '../bem';
import { ReactComponent as AdminPropertyFailedSvg } from '../images/admin-property-failed.svg';
import { Modal } from './';

const classes = BEM.with('PatientAdminFailureModal');
const IMG_HEADER = {
  image: AdminPropertyFailedSvg,
  fill: '#db524b',
  imgClassHeader: classes('img'),
};
const PATIENT_ADMIN_OPERATION_FAILURE_TIMEOUT = 3000;

class PatientAdminFailureModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    openEditScheduleMessageModal: PropTypes.func,
  };

  render() {
    const { isOpen, options = {} } = this.props;
    const { body } = options;

    return (
      <Modal
        header={'Something went wrong.'}
        className={classes()}
        bodyClass={classes('body')}
        headerClass={classes('header')}
        hasCloseButton={false}
        imgHeader={IMG_HEADER}
        isOpen={isOpen}
        onAfterOpen={this._autoCloseModal}
        size={'medium-small'}
      >
        <div className={classes('text')}>{body}</div>
      </Modal>
    );
  }

  _autoCloseModal = () => {
    const { closeModal, options = {} } = this.props;
    const { onClose, isEditScheduledMessage, messageId, reopenModal } = options;
    setTimeout(() => {
      closeModal();
      if (isEditScheduledMessage) this._reopenEditScheduleMessageModal(messageId);
      else if (reopenModal) this._reopenModal();
      if (onClose) onClose();
    }, PATIENT_ADMIN_OPERATION_FAILURE_TIMEOUT);
  };

  _reopenEditScheduleMessageModal = (messageId) => {
    const { openEditScheduleMessageModal } = this.props;
    openEditScheduleMessageModal(messageId);
  };

  _reopenModal = () => {
    const { openModal, options = {} } = this.props;
    const { reopenModal, optionsReopenModal = {} } = options;
    if (reopenModal) openModal(reopenModal, optionsReopenModal);
  };
}

export default mobxInjectSelect({
  modalStore: ['openModal'],
  scheduleMessageStore: ['openEditScheduleMessageModal'],
})(PatientAdminFailureModal);
