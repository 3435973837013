import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import Modal from '../Modal';
import { EulaContent } from './';

const classes = BEM.with('EulaModal');

class EulaModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    signOut: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { eulas } = this.props.options;

    this.state = {
      eulas,
    };
  }

  render() {
    const { isOpen } = this.props;
    const eula = this.state.eulas[0];

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        hasCloseButton={false}
        header="TigerConnect End User Agreement"
        headerClass={classes('header')}
        isOpen={isOpen}
        size={'large'}
        footerPrimaryActions={
          <button type="button" className={classes('accept-btn')} onClick={this._onAccept}>
            I AGREE
          </button>
        }
        footerSecondaryActions={
          <div>
            <button type="button" onClick={this._onLogout} className={classes('logout-btn')}>
              LOGOUT
            </button>
          </div>
        }
      >
        <EulaContent src={eula.url} />
      </Modal>
    );
  }

  _onAccept = () => {
    const { eulas } = this.state;

    eulas[0].organizationIds.forEach((organizationId) =>
      this.props.setEulaAccepted(organizationId, true)
    );

    const remainingEulas = eulas.slice(1);
    if (remainingEulas.length > 0) {
      this.setState({ eulas: remainingEulas });
      return;
    }

    this.props.closeModal();
  };

  _onLogout = () => {
    this.props.closeModal();
    this.props.signOut();
  };
}

export default mobxInjectSelect({
  sessionStore: ['signOut'],
  userStore: ['setEulaAccepted'],
})(EulaModal);
