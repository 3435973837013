import { useCallback, useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import TCClient from '../../../../../client';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { Toast } from '../../../../shared-components/Toast/Toast';
import { Checkbox } from '../../../../shared-components/Checkbox/Checkbox';
import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';

import styles from './styles.module.css';
import { useAppSelector } from 'redux-stores';
import { formatPermissionLabel } from 'admin/utils/parsePermissionsLabels';

type UserPermissionsProps = {
  onClose: () => void;
  isPersonaMappingExcluded?: boolean;
  isPersonaBasedManagementAllowed?: boolean;
};

type PermissionMap = {
  [k: string]: boolean;
};

const initialPermissionState: PermissionMap = {};

export const UserPermissions = ({
  onClose,
  isPersonaMappingExcluded = true,
  isPersonaBasedManagementAllowed = false,
}: UserPermissionsProps) => {
  const selectedUser = useAppSelector((state) => state.admin.selectedUser);
  const selectedOrganization = useAppSelector((state) => state.admin.selectedOrganization);
  const [permissions, setPermissions] = useState<PermissionMap>(initialPermissionState);
  const [diffPermissions, setDiffPermissions] = useState<PermissionMap>(initialPermissionState);
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);

  const getPermissions = useCallback(async () => {
    const _permissions = await TCClient.adminUsers.getUserPermissions({
      orgId: selectedOrganization.token,
      userId: selectedUser.id,
    });

    const orgSettings = await TCClient.adminOrganizations.fetchSettings({
      organizationId: selectedOrganization.token,
    });
    const { featureCustomFastDeploy } = orgSettings;

    if (!featureCustomFastDeploy) {
      delete _permissions['customer'];
    }

    setPermissions(_permissions);
    setDiffPermissions(_permissions);
  }, [selectedOrganization.token, selectedUser.id]);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  const saveRoles = async () => {
    await TCClient.adminUsers.updateUserPermissionRoles({
      orgId: selectedOrganization.token,
      userId: selectedUser.id,
      diffPermissions,
      permissions,
    });

    await getPermissions();

    setIsSuccessToastOpen(true);
  };

  return (
    <ViewContainer
      header={'User Permissions'}
      subHeader={'Assign permissions to this user.'}
      onClose={onClose}
    >
      <div>
        <ul>
          {Object.keys(permissions).map((permission) => (
            <li key={permission}>
              <Checkbox
                disabled={isPersonaBasedManagementAllowed ? !isPersonaMappingExcluded : false}
                checked={permissions[permission as keyof typeof permissions]}
                onClick={(v) => {
                  setPermissions({ ...permissions, [permission]: v });
                }}
                multiple
                label={formatPermissionLabel(permission)}
              />
            </li>
          ))}
        </ul>
        <div className={styles.buttonWrapper}>
          <CtaButton
            customStyles={styles}
            disabled={isEqual(permissions, diffPermissions)}
            label={'Save User Permissions'}
            onClick={saveRoles}
            primary
            size={'small'}
          />
        </div>
        <Toast
          type={'SUCCESS'}
          open={isSuccessToastOpen}
          onClose={() => setIsSuccessToastOpen(false)}
          message={"User's Permissions successfully updated."}
        />
      </div>
    </ViewContainer>
  );
};
