import { ServerValueEnum } from '../../utils/enum';

const MessageSubType = new ServerValueEnum(
  {
    ALERTS: { serverValue: 'alerts' },
    AUTOMATED_MESSAGE: { serverValue: 'appointment' },
    CHATBOT_MESSAGE: { serverValue: 'CHATBOT_MESSAGE' },
    EMPTY_ROLE_NOTIFICATION: { serverValue: 'empty_role_notification' },
    WORKFLOW_AUTOMATION_NOTIFICATION: { serverValue: 'workflow_automation_notification' },
    NA: { key: 'NA' },
    ROLE_ALERTS: { serverValue: 'role_alerts' },
    ROLE_AWAY_NOTIFICATION: { serverValue: 'role_away_notification' },
    SCHEDULE_MESSAGE: { serverValue: 'schedule_message' },
    SMS_OPT_IN_NOTIFICATION: { serverValue: 'sms_optin_notification' },
    SMS_OPT_OUT_NOTIFICATION: { serverValue: 'sms_optout_notification' },
    SYSTEM: { serverValue: 'system' },
    TEAM_REQUEST: { serverValue: 'team_request' },
    USER_AWAY_NOTIFICATION: { serverValue: 'user_away_notification' },
    VIRTUAL_WAITING_ROOM_CALL_INVITE: { serverValue: 'vwr_call' },
  } as const,
  { defaultValue: 'NA' }
);

export default MessageSubType;
