import React, { useEffect, useRef, useState, useCallback } from 'react';
import { compact } from 'lodash';
import PropTypes from 'prop-types';
import useClickOutside from '../hooks/useClickOutside';
import BEM from '../bem';

import { ReactComponent as ArrowReturnSvg } from '../images/arrow-return.svg';
import { ReactComponent as EditPencilIconSvg } from '../images/edit-pencil.svg';
import { KEYMAP } from 'common/constants';

const InlineEditor = ({
  classesName,
  insideRefs = [],
  editorRef,
  isAutomated = false,
  listError,
  onBlur,
  openedEditor,
  placeholder,
  save,
  saveOnBlur,
  value: valueFromProps,
}) => {
  const [showEditor, setShowEditor] = useState(!!openedEditor);
  const [value, setValue] = useState(valueFromProps);
  const [inputBoxFocus, setInputBoxFocus] = useState(true);
  const classes = BEM.with(classesName || 'InlineEditor');
  const inputRef = useRef();
  const nameEditorRef = useRef();
  const inputContainerRef = useRef();
  let valueFragment;

  const saveNewValue = useCallback(
    async (newValue) => {
      if (!isAutomated && (!newValue || newValue === valueFromProps) && newValue.length > 1) {
        setShowEditor(false);
        return;
      }

      setValue(newValue);
      const isSuccess = await save(newValue);

      if (isSuccess) {
        setShowEditor(false);
      }
    },
    [isAutomated, save, valueFromProps]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newValue = (e.target['editorValue'].value || '').trim();
    await saveNewValue(newValue);
  };

  const handleOutsideClick = useCallback(async () => {
    if (saveOnBlur) {
      if (isAutomated ? editorRef.current.value === '' : inputRef.current.value === '') return;
      await saveNewValue(isAutomated ? editorRef.current.value : inputRef.current.value);
      return;
    }

    if (
      (isAutomated ? editorRef.current === '' : inputRef.current === '') ||
      value === '' ||
      listError !== ''
    ) {
      setInputBoxFocus(true);
    } else {
      setInputBoxFocus(false);
      onBlur && onBlur();
    }
  }, [listError, onBlur, saveNewValue, saveOnBlur, value, inputRef, isAutomated, editorRef]);

  useClickOutside(
    compact([
      inputContainerRef,
      !isAutomated && (!showEditor || !inputBoxFocus) && nameEditorRef,
      ...insideRefs,
    ]),
    handleOutsideClick
  );

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      if (isAutomated) {
        if (editorRef.current) editorRef.current.blur();
      } else if (inputRef.current) {
        inputRef.current.blur();
      }
      handleOutsideClick();
      showEditor(false);
    }
  };

  const handleKeyDownEdit = (e) => {
    if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
      e.preventDefault();
      setShowEditor(true);
    }
  };

  useEffect(() => {
    setValue(valueFromProps);
    if (isAutomated && editorRef.current) {
      editorRef.current.value = valueFromProps;
    } else if (inputRef.current) {
      inputRef.current.value = valueFromProps;
    }
  }, [valueFromProps, inputRef, isAutomated, editorRef]);

  useEffect(() => {
    if (((isAutomated ? editorRef.current : inputRef.current) && !inputBoxFocus) || showEditor) {
      if (isAutomated) {
        editorRef.current.focus();
      } else {
        inputRef.current.focus();
      }
    }
  }, [inputBoxFocus, inputRef, isAutomated, editorRef, showEditor]);

  if (!showEditor || !inputBoxFocus) {
    valueFragment = (
      <div
        className={classes('inline-editor')}
        onClick={() => {
          setShowEditor(true);
          setInputBoxFocus(true);
        }}
        ref={nameEditorRef}
        onKeyDown={handleKeyDownEdit}
        tabIndex={0}
      >
        <div>{value}</div>
        {!isAutomated && <EditPencilIconSvg className={classes('edit-icon')} aria-hidden />}
      </div>
    );
  } else {
    valueFragment = (
      <div className={classes('inline-editor', { editing: true })} ref={inputContainerRef}>
        <form className={classes('input-container')} onSubmit={handleSubmit}>
          <input
            data-test-id={'input-editor'}
            defaultValue={value}
            name="editorValue"
            placeholder={placeholder}
            ref={isAutomated ? editorRef : inputRef}
            type="text"
            className={classes('input-editor', { isAutomated })}
            onKeyDown={handleKeyDown}
            maxLength={isAutomated ? 100 : undefined}
          />
          <button type="submit" data-test-id={'submit-input'} className={classes('submit-input')}>
            Save <ArrowReturnSvg aria-hidden />
          </button>
        </form>
      </div>
    );
  }

  return valueFragment;
};

InlineEditor.propTypes = {
  classesName: PropTypes.string,
  insideRefs: PropTypes.arrayOf(PropTypes.object),
  isAutomated: PropTypes.bool,
  listError: PropTypes.string,
  onBlur: PropTypes.func,
  openedEditor: PropTypes.bool,
  placeholder: PropTypes.string,
  save: PropTypes.func.isRequired,
  saveOnBlur: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

export default InlineEditor;
