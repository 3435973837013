import { observable, action, runInAction } from 'mobx';
import { SetupScimParam, Stores, TCClient } from '../types';

type Params = {
  scimStatus: number;
  scimBaseUrl: string;
};

export default class ScimStore {
  client: TCClient;
  stores: Stores;
  params: Params;

  @observable scimStatus = 'pending';
  @observable scimBaseUrl = '';
  @observable hasAdmin = false;
  @observable suffix = null;
  @observable prefix = null;

  constructor({ client, stores, params }: { client: TCClient; params: Params; stores: Stores }) {
    this.client = client;
    this.stores = stores;
    this.params = params;
  }

  mounted() {}

  @action('ScimStore.setupScim') setupScim = async (params: SetupScimParam) => {
    try {
      const { token } = await this.client.scim.setupScim(params);
      return token;
    } catch (e) {
      console.error('Setup SCIM error: ', JSON.stringify(e));
      return null;
    }
  };

  @action('ScimStore.loadBearerToken') loadBearerToken = async (orgId: string) => {
    try {
      const response = await this.client.scim.findBearerToken(orgId);
      return response.token;
    } catch (e) {
      console.error('Loading bearer token error: ', JSON.stringify(e));
      return null;
    }
  };

  @action('ScimStore.loadConfiguration') loadConfiguration = async (orgId: string) => {
    try {
      const settings = await this.client.scim.findSettings(orgId);
      runInAction(() => {
        this.scimBaseUrl = settings.baseUrl ?? '';
        this.scimStatus = settings.status;
        this.hasAdmin = settings.hasAdmin;
      });
    } catch (e) {
      runInAction(() => {
        this.scimStatus = 'error';
      });
      console.error('Loading SCIM configuration error: ', JSON.stringify(e));
    }
  };
}
