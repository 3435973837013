export default class PermissionDeniedError extends Error {
  static CODE = 'permission-denied';

  action: unknown;
  code: unknown;
  id: unknown;
  resource: unknown;
  body: unknown;

  constructor(
    resource: { name: string } | string,
    id: unknown,
    action: unknown,
    message = `can't perform action '${action}' on ${
      (typeof resource !== 'string' && resource.name) || resource
    } #${id}`,
    body: unknown
  ) {
    super(message);
    this.code = PermissionDeniedError.CODE;
    this.resource = resource;
    this.action = action;
    this.id = id;
    this.body = body;
  }
}
