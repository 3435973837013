import { observable, action, autorun } from 'mobx';
import { validator } from 'common/utils';
import { EventMessage, TCClient } from 'types';

type Params = {
  number: string;
  last_name: string;
  first_name: string;
  email: string;
  user_name: string;
  operatorConsoleFlow: string;
};

type Stores = {
  sessionStore: {
    authValues: { basicToken: string; bearerToken: string };
  };
  networkStore: { switchNetwork: () => Promise<void> };
  messengerStore: { currentOrganizationId: string };
  conversationStore: {
    currentConversation: unknown;
    currentConversationId: string;
  };
};

const CANARY_ENVS = ['prod-a', 'prod-c'];
const PROD_API_ENVS = ['prod', 'production', ...CANARY_ENVS];
const UAT_API_ENV = 'uat';

const OC_URLS = {
  PROD: 'https://api.tigerconnect.com/v1/operator-console/operations/integrate-landing',
  UAT: 'https://api.uat.tigerconnect.com/v1/operator-console/operations/integrate-landing',
  DEV: 'https://api.env7.tigerconnect.com/v1/operator-console/operations/integrate-landing',
};

export interface OperatorConsoleResponse {
  groupId: string;
  patientId: string;
  welcomeMessage: string;
}

export default class OperatorConsoleStore {
  client: TCClient;
  stores: Stores;
  params: Params;

  @observable patientGroupToken = '';
  @observable operatorConsoleFlow = false;
  @observable allowInjectOCMessage = false;
  @observable OCError = '';

  body = '';

  constructor({ client, params, stores }: { client: TCClient; params: Params; stores: Stores }) {
    this.client = client;
    this.params = params;
    this.stores = stores;
  }

  mounted() {
    if (this.params.operatorConsoleFlow === 'true') {
      this.client.on('messages:offline:start', () => {
        this.client.removeListener('message', this._handleNewMessage);
      });

      this.client.on('messages:offline:stop', () => {
        this.client.on('message', this._handleNewMessage);
      });

      autorun(() => {
        const conversation = this.stores.conversationStore.currentConversation as {
          counterPartyId: string;
        };

        if (this.allowInjectOCMessage && this.patientGroupToken === conversation?.counterPartyId) {
          this.injectMessage();
        }
      });

      this.operatorConsoleFlow = true;
      this.allowInjectOCMessage = true;
    }
  }

  _handleNewMessage = (message: EventMessage) => {
    if (document.hasFocus()) return;

    const displayName = message.counterParty?.displayName;
    const title = `${displayName || 'Someone'} sent you a new message`;

    window.parent.postMessage(
      {
        id: 'tigerConnect',
        message: title,
      },
      '*'
    );
  };

  _getOperatorConsoleUrl = () => {
    const { apiEnv } = this.client.config;

    if (PROD_API_ENVS.includes(apiEnv)) {
      return OC_URLS.PROD;
    } else if (apiEnv === UAT_API_ENV) {
      return OC_URLS.UAT;
    }

    return OC_URLS.DEV;
  };

  async _fetchOperatorConsole(bearerToken: string, basicToken: string, organizationId: string) {
    const { number, last_name, first_name, email, user_name } = this.params;

    try {
      validator('First name', first_name).isRequired();
      validator('organization Id', organizationId).isRequired();
      validator('Authorization', bearerToken).isRequired();
      validator('Authorization', basicToken).isRequired();
      validator('Username', user_name).isRequired().isEmail();
      validator('Email', email).isRequired().isEmail();
      validator('Phone', number).isRequired().isPhone();

      const url = this._getOperatorConsoleUrl();

      const requestBody = {
        number,
        organizationId,
        userName: user_name,
        email,
        firstName: first_name,
        lastName: last_name,
      };

      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Xmpp-UserToken': basicToken,
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
      });

      if (!response.ok) {
        throw new Error('An error occurred while processing your request. Please try again later.');
      }

      const result = (await response.json()) as OperatorConsoleResponse;
      const patientGroupToken = result.groupId;

      this.body = result.welcomeMessage;

      return patientGroupToken;
    } catch (error) {
      this.setError(error.message);
    }
  }

  @action('OperatorConsoleStore.setError') setError = (error: string) => {
    this.OCError = error;
  };

  @action('OperatorConsoleStore.injectMessage') injectMessage = () => {
    const conversationId = this.stores.conversationStore.currentConversationId;
    if (conversationId) {
      this.client.messages.injectToConversation(conversationId, this.body);
      this.setAllowInjectMessage(false);
    }
  };

  @action('OperatorConsoleStore.setOperatorConsoleFlow') setOperatorConsoleFlow = (
    isEnabled: boolean
  ) => {
    this.operatorConsoleFlow = isEnabled;
  };

  @action('OperatorConsoleStore.setAllowInjectMessage') setAllowInjectMessage = (
    isAllowed: boolean
  ) => {
    this.allowInjectOCMessage = isAllowed;
  };

  @action('OperatorConsoleStore.setPatientGroupToken') setPatientGroupToken = (token: string) => {
    this.patientGroupToken = token;
  };

  @action('OperatorConsoleStore.operatorConsole') operatorConsole = async () => {
    if (this.operatorConsoleFlow && !this.patientGroupToken) {
      const { bearerToken, basicToken } = this.stores.sessionStore.authValues;
      const organizationId = this.stores.messengerStore.currentOrganizationId;

      const patientGroupToken = await this._fetchOperatorConsole(
        bearerToken,
        basicToken,
        organizationId
      );

      if (patientGroupToken) {
        this.stores.networkStore.switchNetwork();
        this.setPatientGroupToken(patientGroupToken);
      }

      this.setOperatorConsoleFlow(false);
    }
  };
}
