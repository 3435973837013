import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { isUndefined, uniq } from 'lodash';
import { ReduxState } from '../..';
import { Team } from '../../../types';
import { CollabTeamsState, ReactToFriendsEventProps } from '../../../types/Collaboration';
import { entitiesSlice } from '../entities';
import * as teamsThunk from './thunk';

const { reactToFriendsEvent } = entitiesSlice.actions;

const teamsSlice = createSlice({
  name: 'teams',
  initialState: {
    activeTeamIds: [],
    savedTeamIds: [],
  } as CollabTeamsState,
  reducers: {
    setMyTeams: (
      state,
      action: PayloadAction<{ activeTeamIds?: string[]; savedTeamIds?: string[] }>
    ) => {
      const { activeTeamIds, savedTeamIds } = action.payload;
      if (!isUndefined(savedTeamIds)) state.savedTeamIds = savedTeamIds;
      if (!isUndefined(activeTeamIds)) state.activeTeamIds = activeTeamIds;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      reactToFriendsEvent,
      (state, action: PayloadAction<ReactToFriendsEventProps>) => {
        const { action: friendAction, id } = action.payload;
        if (friendAction === 'add') {
          state.activeTeamIds = [...state.activeTeamIds, id];
        } else {
          state.activeTeamIds = state.activeTeamIds.filter((i) => i !== id);
        }
      }
    );
  },
});

export const defaultNewTeam: Team = {
  $entityType: 'team',
  canMembersLeave: false,
  canRequestToJoin: false,
  description: '',
  id: '',
  displayName: '',
  members: [],
  name: '',
};

export { teamsThunk, teamsSlice };

export const selectActiveTeamIds = (state: ReduxState) => state.teams.activeTeamIds;
export const selectSavedTeamIds = (state: ReduxState) => state.teams.savedTeamIds;
export const selectMyTeamsCount = createSelector(
  [selectActiveTeamIds, selectSavedTeamIds],
  (activeTeamIds, savedTeamIds) => uniq([...activeTeamIds, ...savedTeamIds]).length
);
