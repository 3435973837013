import React, { useState, useEffect } from 'react';

import mobxInjectSelect from '../../utils/mobxInjectSelect';
import BEM from '../../bem';
import { updateProviderBroadcastList } from '../../../contexts/BroadcastListSettings';
import { Modal } from '..';

const classes = BEM.with('AddADSyncModal');

type AddADSyncModalProps = {
  isOpen: boolean;
  options: {
    adminOnly: boolean;
    securityGroupName: string;
    currentOrganizationId: string;
    canSaveList: (saveList: boolean) => void;
    resetForm: (id: string) => void;
    setIsSaving: (isSaving: boolean) => void;
    setListError: (listError: string) => void;
    listId: string;
    setCurrentListId: (currentListId: string) => void;
    setBroadcastListDetails: ({
      adminOnly,
      createdBy,
      createdOn,
      displayName,
      memberCount,
      securityGroupName,
    }: {
      adminOnly: string;
      createdBy: string;
      createdOn: string;
      displayName: string;
      memberCount: string;
      securityGroupName: string;
    }) => void;
  };
};

type MobxProps = {
  closeModal: () => void;
  openModal: (
    modalType: string,
    options: {
      body: string;
    }
  ) => void;
};

function AddADSyncModal({
  closeModal,
  isOpen,
  options,
  openModal,
}: MobxProps & AddADSyncModalProps) {
  const { securityGroupName } = options;
  const [securityGroupTitle, setSecurityGroupTitle] = useState('');
  const [isSecurityGroupTitleChanged, setIsSecurityGroupTitleChanged] = useState(false);
  const [duplicateSecurityGroup, setDuplicateSecurityGroup] = useState('');
  const { canSaveList } = options;
  const isSecurityGroupTitleInvalid =
    securityGroupTitle.trim().length < 1 || securityGroupTitle.trim().length > 64;

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecurityGroupTitle(event.currentTarget.value);
    setIsSecurityGroupTitleChanged(true);
  };

  useEffect(() => {
    if (!isOpen) return;
    setSecurityGroupTitle(securityGroupName || '');
  }, [isOpen, securityGroupName]);

  const closeCurrentModal = () => {
    closeModal();
    setDuplicateSecurityGroup('');
    setSecurityGroupTitle(securityGroupName ? securityGroupName : '');
    setIsSecurityGroupTitleChanged(false);
  };

  const cancelSecurityGroupEntry = () => {
    closeCurrentModal();
    canSaveList(false);
  };

  useEffect(() => {
    setSecurityGroupTitle(securityGroupName ? securityGroupName : '');
  }, [securityGroupName]);

  const saveSecurityGroup = async () => {
    const {
      adminOnly,
      currentOrganizationId,
      canSaveList,
      listId,
      resetForm,
      setBroadcastListDetails,
      setCurrentListId,
      setIsSaving,
      setListError,
    } = options;
    try {
      setIsSaving(true);
      const list = await updateProviderBroadcastList({
        adminOnly,
        id: listId,
        currentOrganizationId,
        securityGroupValue: securityGroupTitle,
      });
      resetForm(list.id);
      setCurrentListId(list.id);
      setListError('');
      setBroadcastListDetails({
        createdOn: list.createdOn,
        displayName: list.displayName,
        memberCount: list.memberCount,
        adminOnly: list.adminOnly,
        createdBy: list.createdBy.displayName,
        securityGroupName: list.securityGroupName,
      });
      closeCurrentModal();
      setSecurityGroupTitle(list.securityGroupName);
      canSaveList(true);
    } catch (err) {
      if (err.response) {
        const { error, status } = err.response.body || {};
        const { message } = error;
        if (message.ad_security_group_list_exist) {
          setDuplicateSecurityGroup('AD Security group already exists.');
        } else if (status === 'fail' && message === 'name_duplicated') {
          setListError('Broadcast list must have a unique label.');
          closeModal();
          setDuplicateSecurityGroup('');
          canSaveList(false);
        }
      } else if (err.message === 'name must be less than 60 alphanumeric characters') {
        setListError('Broadcast list be use between 1 and 60 alphanumeric and special characters.');
        closeCurrentModal();
        canSaveList(false);
      } else {
        console.error(err);
        openModal('patientAdminFailure', {
          body: 'Unable to create broadcast list. Please try again.',
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      bodyClass={classes('body')}
      className={classes()}
      closeClass={classes('close-button')}
      header={'Create Directory Sync'}
      headerClass={classes('header')}
      isOpen={isOpen}
      size={'medium-small'}
      onRequestClose={cancelSecurityGroupEntry}
      footerPrimaryActions={
        <button
          aria-label="Save AD Sync"
          className={classes('done-btn', {
            isDisabled: !isSecurityGroupTitleChanged || isSecurityGroupTitleInvalid,
          })}
          type="button"
          onClick={() => {
            if (!isSecurityGroupTitleInvalid) saveSecurityGroup();
          }}
          disabled={!isSecurityGroupTitleChanged || isSecurityGroupTitleInvalid}
        >
          DONE
        </button>
      }
      footerSecondaryActions={
        <button
          aria-label="Cancel AD Sync"
          className={classes('cancel-btn')}
          onClick={cancelSecurityGroupEntry}
          type="button"
        >
          CANCEL
        </button>
      }
    >
      <div className={classes('input-container')}>
        <input
          type="text"
          placeholder="Enter Security Group"
          value={securityGroupTitle}
          onChange={handleInput}
        />
        {duplicateSecurityGroup !== '' && (
          <div className={classes('error-container')}>
            A broadcast list using this security group already exists.
          </div>
        )}
        {securityGroupTitle.length > 64 && (
          <div className={classes('error-container')}>
            Security groups have a max limit of 64 characters.
          </div>
        )}
      </div>
    </Modal>
  );
}

export default mobxInjectSelect<AddADSyncModalProps, MobxProps>({
  modalStore: ['openModal'],
})(AddADSyncModal);
