import React, { Component } from 'react';
import { observer } from 'mobx-react';
import BEM from '../../../../common/bem';
import { ReactComponent as DragAcceptedSvg } from '../../images/drag-accepted.svg';

const classes = BEM.with('DragModal');

class DragModal extends Component {
  render() {
    const { isDragActive } = this.props;
    const dragState = isDragActive ? 'drag-accepted' : null;

    return (
      dragState && (
        <div className={classes()}>
          <div className={classes('overlay-white')} />
          <div className={classes('overlay', { [dragState]: true })}>
            <div className={classes('modal')}>
              <DragAcceptedSvg className={classes('image')} />
              <p className={classes('caption', { [dragState]: true })}>{'Drop to Upload'}</p>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default observer(DragModal);
