import React from 'react';

import TCClient from '../../../../../client';

import { CtaButton } from '../../../../shared-components/CtaButton/CtaButton';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { UserData } from '../../../../types';

import styles from '../../CustomerSupport.module.css';

export default function ForceLogout({
  accountData,
  onClose,
}: {
  accountData: UserData;
  onClose: () => void;
}) {
  async function handleForceLogoutClick() {
    await TCClient.adminUsers.remoteWipe({
      orgId: accountData.organizationSettings[0].organizationId,
      userId: accountData.token,
    });
    alert('Force logout was successful.');
  }

  return (
    <ViewContainer
      {...{
        header: 'Force Logout',
        subHeader: 'Force logout this user from all logged in devices, both mobile and web.',
        onClose,
      }}
    >
      <div className={styles.buttonContainer}>
        <CtaButton label={'Force  Logout'} onClick={handleForceLogoutClick} primary={true} />
      </div>
    </ViewContainer>
  );
}
