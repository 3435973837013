import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { intersection, xor } from 'lodash';
import omit from 'lodash/omit';
import { ReduxState } from '../..';
import { CollaborationCategories, CollaborationTabs } from '../../../models/enums';
import { CollaborationTab } from '../../../models/enums/CollaborationTabs';
import { CollabEntity, Tag } from '../../../types';
import {
  CollabEntitiesState,
  ReactToFriendsEventProps,
  ReactToRoleChangeEventProps,
  TotalsProps,
  UpdateEntitiesProps,
} from '../../../types/Collaboration';
import { tagsSlice } from '../tags';
import * as entitiesThunk from './thunk';

const RESPONSIVE_THRESHOLD = 1240;
const { clearUserSelections, selectTag, selectTagCategory, setCheckedTags } = tagsSlice.actions;

const defaultTab: { [k: string]: CollaborationTab } = {
  [CollaborationCategories.MYROLES]: CollaborationTabs.ACTIVEROLES,
  [CollaborationCategories.MYTEAMS]: CollaborationTabs.ACTIVETEAMS,
};

const entitiesSlice = createSlice({
  name: 'entities',
  initialState: {
    activeTab: CollaborationTabs.ALL,
    checkedEntityIds: [],
    entitiesById: {},
    entityResultsCount: 0,
    entitiesSearchQuery: '',
    entityNameError: false,
    hasFetchedTotals: false,
    isEntitySearchOpen: false,
    isLoadingEntities: false,
    isSavingEntity: false,
    entityNameErrorMsg: '',
    taggedEntityCount: 0,
    searchKey: 0,
    untaggedEntityCount: 0,
  } as CollabEntitiesState,
  reducers: {
    addEntity: (state, action: PayloadAction<CollabEntity>) => {
      const entity = action.payload;
      state.entitiesById = { ...state.entitiesById, [entity.id]: entity };
    },

    reactToRoleChangeEvent: (state, action: PayloadAction<ReactToRoleChangeEventProps>) => {
      const { id, members } = action.payload;
      const { entitiesById, selectedEntity } = state;
      const currentItem = entitiesById[id] || (selectedEntity?.id === id && selectedEntity);
      const newItem = currentItem && {
        ...currentItem,
        members,
        memberIds: members.map(({ id }) => id),
      };
      if (state.activeTab !== CollaborationTabs.ACTIVEROLES && state.entitiesById[id]) {
        state.entitiesById = { ...state.entitiesById, [id]: newItem };
      }
      if (state.selectedEntity?.id === id) state.selectedEntity = newItem;
    },

    refreshActiveRoles: (state) => {
      if (state.activeTab === CollaborationTabs.ACTIVEROLES) {
        state.entityContinuation = undefined;
        state.searchKey++;
      }
    },

    reactToFriendsEvent: (state, action: PayloadAction<ReactToFriendsEventProps>) => {
      const { action: friendAction, id, memberIds } = action.payload;
      const { entitiesById, selectedEntity } = state;
      const currentItem = entitiesById[id] || (selectedEntity?.id === id && selectedEntity);
      const newItem = currentItem && {
        ...currentItem,
        memberIds,
        hasCurrentUserOrRole: friendAction === 'add',
      };
      if (state.activeTab !== CollaborationTabs.ACTIVETEAMS && state.entitiesById[id]) {
        state.entitiesById = { ...state.entitiesById, [id]: newItem };
      }
      if (state.selectedEntity?.id === id) state.selectedEntity = newItem;
    },

    refreshActiveTeams: (state) => {
      if (state.activeTab === CollaborationTabs.ACTIVETEAMS) {
        state.entityContinuation = undefined;
        state.searchKey++;
      }
    },

    removeEntity: (state, action: PayloadAction<string>) => {
      state.entitiesById = omit(state.entitiesById, [action.payload]);
    },

    setActiveTab: (state, action: PayloadAction<CollaborationTab>) => {
      state.activeTab = action.payload;
    },

    setIsEntitySearchOpen: (state, action: PayloadAction<boolean>) => {
      state.isEntitySearchOpen = action.payload;
    },

    setIsSavingEntity: (state, action: PayloadAction<boolean>) => {
      state.isSavingEntity = action.payload;
    },

    setNewEntityType: (state, action: PayloadAction<string | undefined>) => {
      state.newEntityType = action.payload;
      state.entityNameError = false;
      if (action.payload) state.selectedEntity = undefined;
    },

    selectEntity: (state, action: PayloadAction<CollabEntity | undefined>) => {
      state.selectedEntity = action.payload;
      state.entityNameError = false;
      state.newEntityType = undefined;
    },

    setCheckedEntities: (state, action: PayloadAction<string[]>) => {
      state.checkedEntityIds = action.payload;
    },

    setEntityLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.isLoadingEntities = action.payload;
    },

    setEntityNameError: (state, action: PayloadAction<boolean>) => {
      state.entityNameError = action.payload;
    },

    setEntityNameErrorMessage: (state, action: PayloadAction<string>) => {
      state.entityNameErrorMsg = action.payload;
    },

    setEntitiesSearchQuery: (state, action: PayloadAction<string>) => {
      state.isLoadingEntities = true;
      state.entitiesSearchQuery = action.payload;
    },

    setTotals: (state, action: PayloadAction<TotalsProps>) => {
      const { tagged, untagged } = action.payload;
      state.taggedEntityCount = tagged;
      state.untaggedEntityCount = untagged;
      state.hasFetchedTotals = true;
    },

    updateEntities: (state, action: PayloadAction<UpdateEntitiesProps>) => {
      const {
        shouldAppend,
        continuation,
        entitiesById,
        entityResultsCount,
        entityResultsCountDiff,
        taggedEntityCountDiff,
        untaggedEntityCountDiff,
      } = action.payload;

      if (entityResultsCount) state.entityResultsCount = entityResultsCount;

      const existingEntities = shouldAppend ? state.entitiesById : {};
      state.entityContinuation = continuation;
      if (entityResultsCountDiff) state.entityResultsCount += entityResultsCountDiff;
      if (taggedEntityCountDiff) state.taggedEntityCount += taggedEntityCountDiff;
      if (untaggedEntityCountDiff) state.untaggedEntityCount += untaggedEntityCountDiff;
      state.entitiesById = { ...existingEntities, ...entitiesById };
      state.checkedEntityIds = intersection(
        state.checkedEntityIds,
        Object.keys(state.entitiesById)
      );
      if (state.selectedEntity && state.entitiesById[state.selectedEntity.id])
        state.selectedEntity = state.entitiesById[state.selectedEntity.id];
    },

    updateEntity: (state, action: PayloadAction<CollabEntity>) => {
      const newUpdatedEntity = action.payload;
      const id = newUpdatedEntity.id;
      if (state.entitiesById[id]) {
        state.entitiesById = { ...state.entitiesById, [id]: newUpdatedEntity };
      }
      if (state.selectedEntity?.id === id) state.selectedEntity = newUpdatedEntity;
    },

    updateSavedEntityIds: (state, action: PayloadAction<string[]>) => {
      const existingIds = Object.keys(state.entitiesById);
      if (state.activeTab === 'Saved' && xor(action.payload, existingIds).length) {
        state.searchKey++;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearUserSelections, (state, action: PayloadAction<string | undefined>) => {
        state.activeTab = action.payload ? CollaborationTabs.ACTIVEROLES : CollaborationTabs.ALL;
        state.newEntityType = undefined;
        state.selectedEntity = undefined;
        state.entitiesSearchQuery = '';
        state.isEntitySearchOpen = false;
      })
      .addCase(selectTag, (state, action: PayloadAction<Tag | undefined>) => {
        state.activeTab = CollaborationTabs.ALL;
      })
      .addCase(selectTagCategory, (state, action: PayloadAction<string>) => {
        state.activeTab = defaultTab[action.payload] || CollaborationTabs.ALL;
        state.entityContinuation = undefined;
        state.isLoadingEntities = true;
      })
      .addCase(setCheckedTags, (state, action: PayloadAction<{ [tagId: string]: Tag }>) => {
        state.activeTab = CollaborationTabs.ALL;
      })
      .addMatcher(
        (action) => {
          return [
            'tags/clearUserSelections',
            'tags/selectTag',
            'tags/selectTagCategory',
            'tags/setCheckedTags',
          ].includes(action.type);
        },
        (state, action) => {
          state.checkedEntityIds = [];
          if (state.newEntityType) {
            state.newEntityType = undefined;
          }
          if (window.innerWidth <= RESPONSIVE_THRESHOLD) {
            state.selectedEntity = undefined;
          }
        }
      );
  },
});

export { entitiesThunk, entitiesSlice };

export const selectSelectedEntity = (state: ReduxState) => state.entities.selectedEntity;
export const selectNewEntityType = (state: ReduxState) => state.entities.newEntityType;
