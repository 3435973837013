import React from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';

export default function Participant({ participant, disableAudio, index }) {
  return (
    <ParticipantInfo participant={participant} index={index}>
      <ParticipantTracks participant={participant} disableAudio={disableAudio} />
    </ParticipantInfo>
  );
}
