import React, { useCallback } from 'react';

import { CtaButton } from '../../../shared-components/CtaButton/CtaButton';
import { StandardOrgSettingInput } from '../../../shared-components/StandardSettingInput';
import { ViewContainer } from '../../../shared-components/View/ViewContainer/ViewContainer';

import styles from '../OrganizationSettings.module.css';
import { TabProps } from './';
import { thunk, useAppDispatch, useAppSelector } from 'redux-stores';
import { SET_TOAST_DURATION_IN_MILLISECONDS } from 'admin/utils/setToastDuration';

export default function Archiving({ onClose }: TabProps) {
  const dispatch = useAppDispatch();
  const { archiveInbox, isArchivingEnabled } = useAppSelector((state) => ({
    archiveInbox: state.orgSettings.settings.archiveInbox,
    isArchivingEnabled: state.orgSettings.settings.isArchivingEnabled,
  }));
  const onSave = useCallback(() => {
    if (isArchivingEnabled && !archiveInbox) {
      thunk.setToastSettings({
        duration: SET_TOAST_DURATION_IN_MILLISECONDS(3),
        open: true,
        message: 'Please fill out all required fields to enable archiving.',
        type: 'FAILURE',
      });
    } else {
      thunk.saveSettings({ dispatch });
    }
  }, [archiveInbox, dispatch, isArchivingEnabled]);

  return (
    <ViewContainer
      {...{
        header: 'Archiving',
        subHeader: 'Set up and manage this organization with message archiving.',
        onClose,
      }}
    >
      <>
        <div className={styles.flexListStyleWithPadding}>
          <StandardOrgSettingInput
            type="radio"
            settingName="isArchivingEnabled"
            options={[
              { label: 'No', value: false },
              { label: 'Yes', value: true },
            ]}
          />
        </div>
        {isArchivingEnabled && (
          <>
            <div className={styles.flexListStyleWithPadding}>
              <div className={styles.labelStyle}>
                <div className={styles.mainLabelStyle}>SMTP email address*</div>
                <div className={styles.sideLabelStyle}>
                  Send message receipts to the specified mailbox.
                </div>
              </div>
              <div className={styles.inputBoxStyle}>
                <StandardOrgSettingInput type="input" settingName="archiveInbox" />
              </div>
            </div>
            <div className={styles.flexListStyleWithPadding}>
              <div className={styles.mainLabelStyle}>SMTP Server</div>
              <div className={styles.inputBoxStyle}>
                <StandardOrgSettingInput type="input" settingName="archiveSmtpServer" />
              </div>
            </div>
            <div className={styles.flexListStyleWithPadding}>
              <div className={styles.mainLabelStyle}>SMTP User</div>
              <div className={styles.inputBoxStyle}>
                <StandardOrgSettingInput type="input" settingName="archiveSmtpUser" />
              </div>
            </div>
            <div className={styles.flexListStyleWithPadding}>
              <div className={styles.mainLabelStyle}>SMTP Pass</div>
              <div className={styles.inputBoxStyle}>
                <StandardOrgSettingInput type="input" settingName="archiveSmtpPass" />
              </div>
            </div>
            <div className={styles.flexListStyleWithPadding}>
              <div className={styles.mainLabelStyle}> Secure SMTP </div>
              <StandardOrgSettingInput
                type="radio"
                settingName="isArchivingSecure"
                options={[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true },
                ]}
              />
            </div>
            <div className={styles.flexListStyleWithPadding}>
              <div className={styles.mainLabelStyle}> Archive Message Body* </div>
              <StandardOrgSettingInput
                type="radio"
                settingName="shouldArchiveBody"
                options={[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true },
                ]}
              />
            </div>
            <div>
              <div className={styles.labelStyle}>
                <div className={styles.mainLabelStyle}>Archiving Network</div>
                <div className={styles.sideLabelStyle}>
                  Please select the network you want to store messages from in the archive.
                </div>
              </div>
              <StandardOrgSettingInput
                type="dropdown"
                settingName="archiveNetwork"
                options={[
                  { label: 'Provider Network', value: 'provider' },
                  { label: 'Patient Network', value: 'patient' },
                  { label: 'Both Networks', value: 'all' },
                ]}
              />
            </div>
            <div>
              <CtaButton primary label="Save" onClick={onSave} />
            </div>
          </>
        )}
      </>
    </ViewContainer>
  );
}
