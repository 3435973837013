import React from 'react';

import BEM from '../../../bem';

import TagsHeader from './TagsHeader';
import TagsList from './TagsList';

const classes = BEM.with('CollaborationTags');

export default function Tags() {
  return (
    <div className={classes()}>
      <TagsHeader />
      <TagsList />
    </div>
  );
}
