import React, { RefObject } from 'react';
import { Input } from '../Input/Input';
import styles from './InputCount.module.css';

export interface InputCountProps {
  hasClearButton?: boolean;
  isLoading?: boolean;
  onClearClick?: () => void;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
  customCSS?: string;
  lengthLimit: number;
  labelText: string;
  inputRef?: RefObject<HTMLInputElement>;
  dataTestId?: string;
}

export default function InputCount({
  lengthLimit,
  labelText,
  value,
  ...inputProps
}: InputCountProps) {
  function getLimitColor() {
    const ratio = value.length / lengthLimit;
    if (ratio > 0.75) return { color: '#FF0000' };
    else if (ratio > 0.5) return { color: '#FFA500' };
    return { color: '#008000' };
  }

  return (
    <div className={styles.inputCount}>
      <label className={styles.label}>
        {labelText}{' '}
        <span
          style={{ ...getLimitColor(), fontSize: '11px' }}
        >{`${value.length}/${lengthLimit}`}</span>
      </label>
      <Input {...inputProps} value={value} lengthLimit={lengthLimit} />
    </div>
  );
}
