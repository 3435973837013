import { KEYMAP } from 'common/constants';

type KeyActionProps = {
  event: React.KeyboardEvent;
  action: () => void;
};

export const triggerButtonActionOnKeyPress = ({ event, action }: KeyActionProps) => {
  if (event.key === KEYMAP.ENTER || event.key === KEYMAP.SPACE) {
    action();
  }
};
