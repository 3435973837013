import React from 'react';
import BasicModal from './BasicModal';

export default function PatientsSubmittedCSVModal({
  isOpen,
  closeModal,
  options: { filename },
}: {
  isOpen: boolean;
  closeModal: () => void;
  options: {
    filename: string;
  };
}) {
  return (
    <BasicModal
      ariaLabelBody={'Patients Submitted Info'}
      ariaLabelCancelButton={'Patients Submitted Cancel'}
      ariaLabelCloseButton={'Patients Submitted Close'}
      ariaLabelHeader={'Patients Submitted Header'}
      ariaLabelSubmitButton={'Patients Submitted Invite'}
      headerText={'Patients Submitted'}
      hideCancel={true}
      isOpen={isOpen}
      hasCloseButton={true}
      onClose={closeModal}
      onSubmit={closeModal}
      size={'medium'}
      submitText={'OK'}
      theme={'patient'}
    >
      <div>
        <p className="word-break">{`Your upload of ${filename} has been received.`}</p>
        <br />
        <p className="word-break">
          Processing time may vary depending on file size. You will receive an email confirmation
          when complete.
        </p>
        <p className="word-break">Exiting this page will not cancel the upload.</p>
      </div>
    </BasicModal>
  );
}
