import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BEM from '../bem';
import { mobxInjectSelect } from '../utils';
import { ReactComponent as ForumAvatarSvg } from '../images/default-avatar--forum.svg';
import { Banner, Modal } from './';

const classes = BEM.with('JoinForumModal');

const MAX_FORUM_DESCRIPTION_LENGTH = 155;

class JoinForumModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    joinForum: PropTypes.func.isRequired,
    selectConversation: PropTypes.func.isRequired,
    stopSearching: PropTypes.func.isRequired,
  };

  render() {
    const {
      closeModal,
      isOpen,
      options: { forum },
    } = this.props;
    const { avatarUrl, createdByUser, displayName, memberCount } = forum;
    let { description } = forum;

    let avatarFragment;
    if (!avatarUrl) {
      avatarFragment = <ForumAvatarSvg className={classes('image', { default: true })} />;
    } else {
      avatarFragment = (
        <div>
          <img src={avatarUrl} alt={displayName} className={classes('image')} />
          <div className={classes('overlay')} />
          <div className={classes('banner')}>
            <Banner type="forum" />
          </div>
        </div>
      );
    }

    const username =
      createdByUser && !createdByUser.$placeholder ? createdByUser.displayName : null;
    const createdBy = username ? `by ${username}` : '';
    const createdInfo = `Created on ${moment(forum.createdAt).format('MMM DD, YYYY')} ${createdBy}`;

    if (description.length > MAX_FORUM_DESCRIPTION_LENGTH) {
      description = `${description.slice(0, MAX_FORUM_DESCRIPTION_LENGTH)}...`;
    }

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        hasCloseButton={true}
        header={displayName}
        isOpen={isOpen}
        onRequestClose={closeModal}
        size={'medium-large'}
        footerPrimaryActions={
          <button type="button" className={classes('join-btn')} onClick={this._onJoin}>
            JOIN
          </button>
        }
        footerSecondaryActions={
          <button className={classes('cancel-btn')} type="button" onClick={closeModal}>
            CANCEL
          </button>
        }
      >
        <div className={classes('forum-container')}>
          <div className={classes('image-container')}>{avatarFragment}</div>
          <div className={classes('forum-details')}>
            <div className={classes('description')}>
              <span>DESCRIPTION</span>
              {description || 'N/A'}
            </div>
            <div>
              <span>MEMBERS</span>
              {memberCount}
            </div>
            <div>{createdInfo}</div>
          </div>
        </div>
      </Modal>
    );
  }

  _onJoin = () => {
    const {
      closeModal,
      joinForum,
      options: { conversation, forum },
      selectConversation,
      stopSearching,
    } = this.props;

    joinForum(forum.id);
    selectConversation(conversation);
    stopSearching();
    closeModal();
  };
}

export default mobxInjectSelect({
  conversationStore: ['selectConversation'],
  groupStore: ['joinForum'],
  messengerStore: ['stopSearching'],
})(JoinForumModal);
