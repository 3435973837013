import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../../utils';
import propTypes from '../../propTypes';
import { UndoAction } from '../';

class UndoSavedRole extends Component {
  static propTypes = {
    setUndoButton: PropTypes.func.isRequired,
    undoRemoveSavedRole: PropTypes.func.isRequired,
    undoRole: propTypes.role,
  };

  render() {
    const { undoRole } = this.props;

    if (!undoRole) return <div />;

    return (
      <UndoAction
        removeButton={this._removeUndoButton}
        text={`Removed from favorites: ${undoRole.displayName}`}
        undoAction={this._undoSaveRole}
      />
    );
  }

  _undoSaveRole = () => {
    const { undoRemoveSavedRole, undoRole } = this.props;
    undoRemoveSavedRole(undoRole.botUserId, undoRole.organizationId);
  };

  _removeUndoButton = () => {
    this.props.setUndoButton(false);
  };
}

export default mobxInjectSelect({
  roleStore: ['setUndoButton', 'undoRemoveSavedRole', 'undoRole'],
})(UndoSavedRole);
