import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import BEM from '../bem';
import propTypes from '../propTypes';
import { mobxInjectSelect } from '../utils';
import { MessageSubTypes } from '../../models/enums/MessageSubTypes';
import AutoForwardBang from './Bangs/AutoForwardBang';
import {
  AlertReactionBang,
  CallChangeLog,
  MessageGroup,
  MessageGroupEscalationChangeLog,
  MessageGroupMembershipChangeLog,
  RequestToJoinTeam,
  Spinner,
  Timestamp,
  VirtualWaitingRoomBangText,
  VirtualWaitingRoomCallCard,
} from './';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';
import AccessibleList from 'common/components/AccessibleList';
import { PatientCareCard } from 'common/components/PatientCareCard';

const classes = BEM.with('GroupedMessagesList');

const MS_IN_DAY_WINDOW = 86400000 * 2;
const NEW_MESSAGE_INDICATOR = 'NewMessageIndicator';

class GroupedMessagesList extends Component {
  static propTypes = {
    enableTransition: PropTypes.bool.isRequired,
    firstUnreadMessage: propTypes.message,
    hasMessageFormIndicator: PropTypes.bool.isRequired,
    isGroupAlertsVCAllowed: PropTypes.bool.isRequired,
    messages: propTypes.messageArray.isRequired,
    setScrollbarsElement: PropTypes.func.isRequired,
    showLoaderBottom: PropTypes.bool.isRequired,
    showLoaderTop: PropTypes.bool.isRequired,
    currentMessageMultiSelectOption: PropTypes.string.isRequired,
  };

  _lastMessages = null;
  _shouldTransition = false;

  state = {
    accessibilityMode: false,
  };

  render() {
    const {
      enableTransition,
      firstUnreadMessage,
      hasMessageFormIndicator,
      messages,
      showLoaderBottom,
      showLoaderTop,
      currentMessageMultiSelectOption,
    } = this.props;

    const isMultiSelectMode = currentMessageMultiSelectOption !== '';
    let loaderFragmentTop, loaderFragmentBottom;

    if (!shallowequal(messages, this._lastMessages)) {
      this._lastMessages = messages.slice();
      this._shouldTransition = enableTransition;
    } else {
      this._shouldTransition = false;
    }

    if (showLoaderBottom) {
      loaderFragmentBottom = (
        <div className={classes('message-loader-bottom')}>
          <span>Loading messages...</span>
          <span className={classes('spinner')}>
            <Spinner />
          </span>
        </div>
      );
    }

    if (showLoaderTop) {
      loaderFragmentTop = (
        <div className={classes('message-loader-top')}>
          <span>Loading messages...</span>
          <span className={classes('spinner')}>
            <Spinner />
          </span>
        </div>
      );
    }

    const messageGroups = this._calculateMessageGroups({
      firstUnreadMessage,
      messages,
    });

    const renderedGroups = [];
    for (const messageGroup of messageGroups) {
      const renderedGroup = this._renderMessageGroup(messageGroup);
      if (renderedGroup) renderedGroups.push(renderedGroup);
    }

    this.focusableClasses = isMultiSelectMode
      ? ['.tc-MessageItem--multiSelect']
      : [
          '.tc-MessageBubble__content-status',
          '.tc-GroupedMessagesList__date-separator',
          '.tc-CallChangeLog__missed-call',
          '.tc-MessageGroupMembershipChangeLog',
          '.tc-GroupedMessagesList__new-messages-box',
          '.VWR-bang-text',
          '.tc-AlertReactionBang',
        ];

    this.focusableChildrenClasses = isMultiSelectMode
      ? undefined
      : [
          '.tc-UserName',
          '.tc-MessageDropdown__show-onhover-actions-button',
          '.tc-CallChangeLog__call-again',
        ];

    return (
      <div className={classes({ hasMessageFormIndicator })} role="application">
        <ReduxEscapeHatch
          ref={(ref) => {
            this.reduxEscapeHatch = ref;
            if (
              this.reduxEscapeHatch &&
              this.reduxEscapeHatch?.accessibilityMode !== this.state.accessibilityMode
            ) {
              this.setState({ accessibilityMode: this.reduxEscapeHatch?.accessibilityMode });
            }
          }}
        />
        <AccessibleList
          className={classes('list')}
          ref={this._setRef}
          focusableClasses={this.focusableClasses}
          focusStart={'last'}
          accessibilityMode={this.state.accessibilityMode}
          focusableChildrenClasses={this.focusableChildrenClasses}
          role="feed"
          ariaLabel="Conversation"
          setStartElementOnChange
        >
          {loaderFragmentTop}
          {renderedGroups}
          {loaderFragmentBottom}
        </AccessibleList>
      </div>
    );
  }

  _setRef = (ref) => {
    this.div = ref;
  };

  _calculateMessageGroups({ firstUnreadMessage, messages }) {
    const groups = [];
    if (messages.length === 0) return groups;

    let lastMessage = null;
    let currentGroup;

    for (const message of messages) {
      const isPatientCareCard = !!message.patientCareCard && message.patientCareCard.length > 0;
      const onSameDay =
        lastMessage &&
        lastMessage.createdAt &&
        message.createdAt &&
        lastMessage.createdAt.getDay() === message.createdAt.getDay() &&
        message.createdAt.getTime() - lastMessage.createdAt.getTime() < MS_IN_DAY_WINDOW;
      const isLastMessageBySameUser =
        lastMessage &&
        lastMessage.messageType === 'USER_SENT' &&
        message.messageType === 'USER_SENT' &&
        lastMessage.senderId === message.senderId &&
        lastMessage.currentSenderRoleId === message.currentSenderRoleId;
      const needsDateSeparator = !onSameDay;
      const needsNewMessageIndicator = firstUnreadMessage === message;
      const isAfterVwrCallInvite =
        currentGroup &&
        currentGroup.messages &&
        currentGroup.messages.length &&
        currentGroup.messages[0].subType === MessageSubTypes.VIRTUAL_WAITING_ROOM_CALL_INVITE;
      const isVwrCallInvite =
        message.subType === MessageSubTypes.VIRTUAL_WAITING_ROOM_CALL_INVITE &&
        message?.conversation?.featureService !== 'vwr';
      const needsNewGroup =
        needsDateSeparator ||
        needsNewMessageIndicator ||
        isPatientCareCard ||
        !isLastMessageBySameUser ||
        isVwrCallInvite ||
        isAfterVwrCallInvite;
      const requestToJoin = message.subType === 'TEAM_REQUEST';

      if (needsDateSeparator) {
        const { createdAt: date, id } = message;
        groups.push({
          date,
          id: `DATE_SEPARATOR-${id}`,
          showMargin: !!lastMessage,
          type: 'DATE_SEPARATOR',
        });
      }

      if (needsNewMessageIndicator) {
        groups.push({
          id: 'NEW_MESSAGES',
          precededByDateSeparator: needsDateSeparator,
          type: 'NEW_MESSAGES',
        });
      }

      if (requestToJoin) {
        const { id } = message;
        const type = 'TEAM_REQUEST';
        currentGroup = {
          id,
          messages: [],
          type,
        };
        groups.push(currentGroup);
      } else if (isVwrCallInvite) {
        const { id } = message;
        const type = 'VWR_CALL';
        currentGroup = {
          id,
          messages: [],
          type,
        };
        groups.push(currentGroup);
      } else if (needsNewGroup) {
        const { id, isOutgoing } = message;
        let { messageType: type } = message;

        if (isPatientCareCard) {
          type = 'PATIENT_CARE_CARD';
        }

        currentGroup = {
          id,
          isOutgoing,
          messages: [],
          precededByDateSeparator: needsDateSeparator,
          precededByMessageGroup:
            lastMessage &&
            lastMessage.messageType === 'USER_SENT' &&
            !needsDateSeparator &&
            !isLastMessageBySameUser,
          type,
        };
        groups.push(currentGroup);
      }

      currentGroup.messages.push(message);
      lastMessage = message;
    }

    if (currentGroup) {
      currentGroup.isLast = true;
    }

    return groups;
  }

  _renderMessageGroup = (messageGroup) => {
    if (messageGroup.length === 0) return null;
    const { currentMessageMultiSelectOption, isGroupAlertsVCAllowed } = this.props;
    const isMultiSelect = !!currentMessageMultiSelectOption;
    const { type } = messageGroup;
    let messageGroupFragment;

    if (type === 'USER_SENT') {
      const { isLast, precededByMessageGroup, messages, precededByDateSeparator } = messageGroup;

      messageGroupFragment = (
        <MessageGroup
          precededByMessageGroup={precededByMessageGroup}
          isMultiSelect={isMultiSelect}
          messages={messages}
          precededByDateSeparator={precededByDateSeparator}
          shouldTransition={!!isLast && this._shouldTransition}
        />
      );
    } else if (type === 'GROUP_MEMBERSHIP_CHANGE') {
      const { messages, precededByDateSeparator } = messageGroup;
      messageGroupFragment = (
        <MessageGroupMembershipChangeLog
          isMultiSelect={isMultiSelect}
          message={messages[0]}
          precededByDateSeparator={precededByDateSeparator}
        />
      );
    } else if (type === 'AUTO_FORWARD') {
      const { messages, precededByDateSeparator } = messageGroup;
      messageGroupFragment = (
        <AutoForwardBang message={messages[0]} precededByDateSeparator={precededByDateSeparator} />
      );
    } else if (type === 'ESCALATION_EXECUTION_CHANGE') {
      const { messages, precededByDateSeparator } = messageGroup;
      messageGroupFragment = (
        <MessageGroupEscalationChangeLog
          message={messages[0]}
          precededByDateSeparator={precededByDateSeparator}
        />
      );
    } else if (type === 'DATE_SEPARATOR') {
      const { date, showMargin } = messageGroup;
      messageGroupFragment = (
        <div
          className={classes('date-separator', { showMargin, isMultiSelect })}
          onClick={this._onTimestampClick}
          aria-label="Conversation Date Separator"
        >
          <Timestamp format="dddd, MMM D" value={date} />
        </div>
      );
    } else if (type === 'NEW_MESSAGES') {
      const { precededByDateSeparator } = messageGroup;
      messageGroupFragment = (
        <div
          className={classes('new-messages', { precededByDateSeparator })}
          ref={this._setScrollbarsElement}
        >
          <div className={classes('new-messages-box')}>NEW MESSAGES</div>
        </div>
      );
    } else if (type === 'PATIENT_CARE_CARD') {
      const { messages } = messageGroup;
      messageGroupFragment = <PatientCareCard message={messages[0]} />;
    } else if (type === 'TEAM_REQUEST') {
      const { messages } = messageGroup;
      messageGroupFragment = <RequestToJoinTeam message={messages[0]} />;
    } else if (type === 'VWR_CALL') {
      const { messages } = messageGroup;
      messageGroupFragment = (
        <VirtualWaitingRoomCallCard
          message={messages[0]}
          status={messages[0]?.vwrCallInvite?.status}
        />
      );
    } else if (type === 'CALL_CHANGE') {
      const { messages } = messageGroup;
      messageGroupFragment = <CallChangeLog message={messages[0]} />;
    } else if (type === 'VISIT_ASSIGNMENT' || type === 'VISIT_STATUS_UPDATE') {
      const { messages } = messageGroup;
      messageGroupFragment = <VirtualWaitingRoomBangText text={messages[0].body} />;
    } else if (type === 'CALL_PROVIDER_NOTIFIED') {
      const { messages } = messageGroup;
      const { body, createdAt } = messages[0];
      messageGroupFragment = (
        <VirtualWaitingRoomBangText text={body} createdAt={createdAt} bodyStyle={'normal'} />
      );
    } else if (type === 'CALL_PROVIDER_ENDED') {
      const { messages } = messageGroup;
      const { body, createdAt } = messages[0];
      messageGroupFragment = (
        <VirtualWaitingRoomBangText text={body} createdAt={createdAt} bodyStyle={'alert'} />
      );
    } else if (
      type === 'CALL_STAFF_CANCELLED' ||
      type === 'CALL_PROVIDER_COMPLETED' ||
      type === 'CALL_LINK_EXPIRED'
    ) {
      const { messages } = messageGroup;
      const { body, createdAt } = messages[0];
      messageGroupFragment = <VirtualWaitingRoomBangText text={body} createdAt={createdAt} />;
    } else if (type === 'REACTION' && isGroupAlertsVCAllowed) {
      const { messages, precededByDateSeparator } = messageGroup;

      messageGroupFragment = (
        <AlertReactionBang
          message={messages[0]}
          precededByDateSeparator={precededByDateSeparator}
        />
      );
    } else {
      return null;
    }

    return (
      <li key={messageGroup.id} className={classes('list-item')}>
        {messageGroupFragment}
      </li>
    );
  };

  _onTimestampClick = (e) => {
    // Prevent ConversationMessages.onClick from firing; otherwise it prevents text from being selected
    e.stopPropagation();
  };

  _setScrollbarsElement = (ref) => {
    const { setScrollbarsElement } = this.props;
    setScrollbarsElement(ref, NEW_MESSAGE_INDICATOR);
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['hasMessageFormIndicator'],
  conversationStore: ['setScrollbarsElement'],
  messengerStore: ['currentMessageMultiSelectOption', 'isGroupAlertsVCAllowed'],
})(GroupedMessagesList);
