import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { Avatar } from '@tigerconnect/web-component-library';
import BEM from '../../../bem';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import { CollabEntity } from '../../../../types';
import { EntityAvatar } from '../../';

import { ReactComponent as ClearSearchIcon } from '../../../images/modal-cancel.svg';
import { ReactComponent as CloseButtonIcon } from '../../../images/close-icon.svg';
import { ReactComponent as SavedEntityIcon } from '../../../images/saved-role-icon.svg';
import { ReactComponent as ArrowReturnSvg } from '../../../images/arrow-return.svg';
import useClickOutside from '../../../hooks/useClickOutside';

const { selectEntity, setEntityNameError, setNewEntityType } = actions;
const { toggleSaveEntity } = thunk;
const classes = BEM.with('CollaborationEntityEditorName');

type EntityEditorNameProps = {
  entity: CollabEntity;
  entityType: string;
  isAdmin: boolean;
  savedIds: string[];
  updateEntity: (values: { [k: string]: unknown }) => void;
};

export default function EntityEditorName({
  entity,
  entityType,
  isAdmin,
  savedIds,
  updateEntity,
}: EntityEditorNameProps) {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const activeTab = useSelector((state: ReduxState) => state.entities.activeTab);
  const entityNameError = useSelector((state: ReduxState) => state.entities.entityNameError);
  const entityNameErrorMsg = useSelector((state: ReduxState) => state.entities.entityNameErrorMsg);
  const newEntityType = useSelector((state: ReduxState) => state.entities.newEntityType);
  const selectedCategory = useSelector((state: ReduxState) => state.tags.selectedCategory);
  const selectedOrgId = useSelector((state: ReduxState) => state.collab.selectedOrgId);
  const [focus, setFocus] = useState<boolean>(false);
  const [draft, setDraft] = useState<string>('');
  const isSaved = entity && savedIds.includes(entity?.id);

  const close = () => {
    dispatch(setNewEntityType(undefined));
    dispatch(selectEntity(undefined));
    dispatch(setEntityNameError(false));
  };

  useEffect(() => {
    setDraft(entity?.displayName || '');
  }, [entity]);

  const updateName = (newName: string) => {
    setDraft(newName);
    dispatch(setEntityNameError(false));
    newEntityType && updateEntity({ displayName: newName });
  };

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    !newEntityType && updateEntity({ displayName: draft.trim() });
  };

  useClickOutside([inputRef], () => {
    setFocus(false);
  });

  const toggleBookmark = () => {
    if (entity?.id) {
      toggleSaveEntity(dispatch, {
        activeTab,
        savedIds,
        selectedCategory,
        entity,
        organizationId: selectedOrgId,
        entityType,
      });
    }
  };

  let header = <>{entityType.toUpperCase()}</>;

  if (newEntityType) {
    header = (
      <>
        <div className={classes('title')}>Create a new {entityType}</div>
        <ToolTip text={`Close ${capitalize(entityType)}`} textAlign={'center'} hideArrow={true}>
          <button data-test-id={`close ${entityType}`} onClick={close} type="button">
            <ClearSearchIcon className={classes('icon-secondary')} />
          </button>
        </ToolTip>
      </>
    );
  }

  return (
    <div
      className={classes('', {
        overlayLayout: true,
      })}
    >
      <div className={classes('header-container', { isNewEntity: !!newEntityType })}>{header}</div>
      <div className={classes('name-input-container', { entityNameError })}>
        {!newEntityType && entity.$entityType && (
          <>
            {entity?.$entityType === 'team' ? (
              <Avatar size="medium" type="team" bgColor={entity.tag?.color} />
            ) : (
              <EntityAvatar entity={entity} useWCL size="medium" />
            )}
          </>
        )}
        <ToolTip
          text="Change"
          textAlign={'center'}
          location={'bottom'}
          disable={!!newEntityType || focus || !isAdmin}
        >
          <form
            className={classes('input-name-form', {
              entityNameError,
              editable: !!newEntityType || focus,
              current: !newEntityType,
              allowedToEdit: !!newEntityType || isAdmin,
            })}
            ref={inputRef}
            onFocus={() => isAdmin && setFocus(true)}
            onSubmit={handleSubmit}
          >
            <input
              data-test-id={`${capitalize(entityType)} name`}
              name="name-field"
              className={classes('input-name')}
              maxLength={100}
              onChange={(e) => updateName(e.target.value)}
              tabIndex={1}
              type="text"
              value={draft}
              disabled={!isAdmin}
              placeholder={`${capitalize(entityType)} name`}
            />
            {entity?.displayName !== draft && !newEntityType && focus && (
              <button
                type="submit"
                data-test-id={'submit-input'}
                className={classes('submit-input')}
                onClick={() => handleSubmit()}
              >
                Save <ArrowReturnSvg />
              </button>
            )}
          </form>
        </ToolTip>
        {!newEntityType && (
          <div className={classes('name-actions')}>
            <ToolTip
              text={`${isSaved ? 'Unsave' : 'Save'} ${capitalize(entityType)}`}
              textAlign={'center'}
              getManualOffset={() => ({
                left: '20%',
              })}
            >
              <SavedEntityIcon
                onClick={toggleBookmark}
                className={classes('gold-icon', {
                  active: isSaved,
                })}
              />
            </ToolTip>
            <ToolTip text="Close" textAlign={'center'} location={'left'} hideArrow={true}>
              <button onClick={close}>
                <CloseButtonIcon className={classes('icon')} />
              </button>
            </ToolTip>
          </div>
        )}
      </div>
      {entityNameError && <div className={classes('error-message')}>{entityNameErrorMsg}</div>}
    </div>
  );
}
