import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BEM from '../bem';
const classes = BEM.with('StatusMessage');

export default function StatusMessage({ ariaLabel, type, className, children, ...rest }) {
  return (
    <div aria-label={ariaLabel} className={classNames(className, classes({ type }))} {...rest}>
      {children}
    </div>
  );
}

StatusMessage.propTypes = {
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
};
