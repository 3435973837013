import React from 'react';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import AudioPreview from './AudioPreview';

export default function LocalVideoPreview() {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find((track) => track.name === 'camera');

  return videoTrack ? <VideoTrack track={videoTrack} isLocal /> : <AudioPreview />;
}
