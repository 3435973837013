import React from 'react';
import { observer } from 'mobx-react';
import BEM from '../../../common/bem';

const classes = BEM.with('RoleAutomatedMessageHeader');

function RoleAutomatedMessageHeader({ message }) {
  const { isOutgoing } = message;
  const direction = isOutgoing ? 'OUTGOING' : 'INCOMING';

  return (
    <div className={classes({ direction })}>
      <div className={classes('text')}>ROLE AUTOMATED MESSAGE</div>
    </div>
  );
}

export default observer(RoleAutomatedMessageHeader);
