import React from 'react';
import { ReactComponent as VWRIcon } from '../../images/icon-vwr-blue.svg';
import BEM from '../../bem';

const classes = BEM.with('VirtualWaitingRoomEmptyRoom');

export default function EmptyRoom() {
  return (
    <div className={classes()}>
      <div className={classes('content')}>
        <div className={classes('primary')}>
          <VWRIcon />
          <p className={classes('primary-text')}>Virtual Waiting Room</p>
        </div>
        <p>Join a waiting room to communicate with patients.</p>
        <p>Contact your organization administrator to learn more.</p>
      </div>
    </div>
  );
}
