import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import { highlightText, mobxInjectSelect } from '../utils';
import VideoCallButton from './Call/VideoCallButton';
import PfCallOrigins from './../../models/enums/PfCallOrigins';
import { EntityAvatar, SmsOptedOut } from './';

const classes = BEM.with('PatientSearchResultDetails');
const AVATAR_SIZE = 25;

class PatientSearchResultDetails extends Component {
  static propTypes = {
    entity: PropTypes.shape({
      $entityType: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      groupType: PropTypes.string,
      patientDetails: PropTypes.object,
    }).isRequired,
    input: PropTypes.string.isRequired,
    isAddFamilyAndContacts: PropTypes.bool.isRequired,
    isSearching: PropTypes.bool.isRequired,
    isPFVideoCallEnabled: PropTypes.bool.isRequired,
  };

  render() {
    const {
      entity,
      input,
      isAddFamilyAndContacts,
      isComposing,
      isSearching,
      isPFVideoCallEnabled,
    } = this.props;
    const searchWords = input.split(',');
    const { displayName, patient } = entity;
    const patientDob = patient && patient.dob;
    const patientGender = patient && patient.gender;
    const patientMrn = patient && patient.mrn;
    const smsOptedOut = patient && patient.smsOptedOut;
    const disableSelection = smsOptedOut && (isComposing || !patient.sharedConversations.length);
    let displayNameFragment;

    if (!isComposing) {
      displayNameFragment = (
        <div className={classes('patient-details', { smsOptedOut })}>
          <span>{'MRN '}</span>
          <span>{highlightText(patientMrn, searchWords, classes)}</span>
          <span>{` | DOB ${patientDob} | ${patientGender}`}</span>
        </div>
      );
    } else {
      displayNameFragment = (
        <div className={classes('patient-details', { smsOptedOut })}>
          <span>Patient</span>
          <span className={classes('patient-mrn')}>
            &bull;<span className={classes('patient-mrn')}>{'MRN '}</span>
          </span>
          <span>{highlightText(patientMrn, searchWords, classes)}</span>
        </div>
      );
    }

    return (
      <div className={classes()}>
        <div className={classes('wrapper', { smsOptedOut, isAddFamilyAndContacts })}>
          <div
            className={classes('wrapper-inside', {
              smsOptedOut,
              disableSelection,
            })}
          >
            <div className={classes('avatar', { isAddFamilyAndContacts })}>
              <EntityAvatar
                className={classes('avatar-class', { smsOptedOut })}
                entity={entity}
                size={AVATAR_SIZE}
              />
            </div>
            <div className={classes('details', { isAddFamilyAndContacts })}>
              <div className={classes('details-container')}>
                <div
                  className={classes('name', {
                    smsOptedOut,
                    isAddFamilyAndContacts,
                  })}
                >
                  {highlightText(displayName, searchWords, classes)}
                </div>
                {displayNameFragment}
              </div>
              <div className={classes('opt-out-container', { isAddFamilyAndContacts })}>
                {smsOptedOut && <SmsOptedOut tooltip={!isAddFamilyAndContacts} />}
              </div>
            </div>
            {isPFVideoCallEnabled && !smsOptedOut && isSearching && (
              <div className={classes('call')}>
                <VideoCallButton
                  entity={{
                    recipientId: entity.id,
                    recipientName: entity.displayName,
                  }}
                  isSmall={true}
                  origin={PfCallOrigins.SEARCH}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  callStore: ['isPFVideoCallEnabled'],
  composeMessageStore: ['isAddFamilyAndContacts', 'isComposing'],
  messengerStore: ['isSearching'],
})(PatientSearchResultDetails);
