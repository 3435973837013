import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import propTypes from '../../../propTypes';
import { ReactComponent as DeleteButtonSvg } from '../../../images/patient-delete-button.svg';
import { ReactComponent as EditButtonSvg } from '../../../images/patient-edit-button.svg';
import mobxInjectSelect from '../../../utils/mobxInjectSelect';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('PatientAdminCardContactDetails');

class PatientAdminCardContactDetails extends Component {
  static propTypes = {
    contact: propTypes.user,
    entity: propTypes.user.isRequired,
    index: PropTypes.number.isRequired,
    isPatientContactEdited: PropTypes.bool,
    showEditContactModal: PropTypes.func.isRequired,
  };

  state = {
    isHovered: false,
    isAccessibleFocused: false,
  };

  constructor(props) {
    super(props);
    this.hoverRef = React.createRef();
  }

  focusInHandler = () => {
    if (this.state.isAccessibleFocused) return;
    this.setState({ isAccessibleFocused: true });
    this.hoverRef?.current?.setAttribute('aria-expanded', 'true');
  };

  focusOutHandler = () => {
    if (!this.state.isAccessibleFocused) return;
    setTimeout(() => {
      if (
        this.hoverRef?.current?.getAttribute('tabindex') === '-1' ||
        !this.hoverRef?.current?.contains(document.activeElement)
      ) {
        this.setState({ isAccessibleFocused: false });
        this.hoverRef?.current?.setAttribute('aria-expanded', 'false');
      }
    }, 0);
  };

  componentDidMount() {
    this.hoverRef?.current?.addEventListener('focusin', this.focusInHandler);
    this.hoverRef?.current?.addEventListener('focusout', this.focusOutHandler);
  }

  componentWillUnmount() {
    this.hoverRef?.current?.removeEventListener('focusin', this.focusInHandler);
    this.hoverRef?.current?.removeEventListener('focusout', this.focusOutHandler);
  }

  render() {
    const { contact } = this.props;
    const { smsOptedOut } = contact;
    const { isHovered, isAccessibleFocused } = this.state;

    return (
      <div
        className={classes({ smsOptedOut })}
        role="button"
        aria-label={`Patient ${contact.relation}. ${contact.user.firstName} ${contact.user.lastName}`}
        aria-haspopup="menu"
        ref={this.hoverRef}
        onKeyDown={(e) => {
          if (isAccessibleFocused && (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER)) {
            e.preventDefault();
            e.stopPropagation();
            const menuButtons = this.hoverRef?.current?.querySelectorAll(
              '.tc-PatientAdminCardContactDetails__option-button'
            );
            menuButtons?.[0]?.focus();
          }
        }}
      >
        <div
          className={classes('contact-row', { smsOptedOut })}
          onMouseLeave={this.onMouseLeave}
          onMouseEnter={this.onMouseEnter}
        >
          {(isHovered || isAccessibleFocused) && (
            <div className={classes('option-button-container')} role="menu">
              <div
                role="button"
                tabIndex={0}
                className={classes('option-button', { isEditing: true })}
                aria-label="Edit contact"
                onClick={this._editContact}
                onKeyDown={(e) => {
                  if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
                    this._editContact();
                  }
                }}
              >
                <EditButtonSvg className={classes('edit-button-icon')} aria-hidden />
                <span className={classes('button-label')}>Edit</span>
              </div>
              <div
                role="button"
                tabIndex={0}
                className={classes('option-button', { isDeleting: true })}
                aria-label="Delete contact"
                onClick={this._deleteContact}
                onKeyDown={(e) => {
                  if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
                    this._deleteContact();
                  }
                }}
              >
                <DeleteButtonSvg className={classes('delete-button-icon')} aria-hidden />
                <span className={classes('button-label')}>Delete</span>
              </div>
            </div>
          )}
          <div className={classes('contact-row-left')}>
            <div className={classes('name')}>{contact.user.firstName}</div>
            <div className={classes('name')}>{contact.user.lastName}</div>
          </div>
          <div className={classes('contact-row-right')}>
            <div>
              <div className={classes('title')}>Phone</div>
              <div className={classes('detail-phone')}>{contact.phoneNumber}</div>
              {smsOptedOut && (
                <div className={classes('sms-opted-out-info')}>Opted out via SMS</div>
              )}
            </div>
            <div>
              <div className={classes('title')}>Relation</div>
              <div className={classes('detail-relation')}>{contact.relation}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _editContact = () => {
    const { contact, entity, isPatientContactEdited, showEditContactModal } = this.props;

    document.activeElement.blur();
    showEditContactModal(entity, contact);
    if (isPatientContactEdited) this.forceUpdate();
  };

  _deleteContact = () => {
    const { contact, index, showContactDeleteModal } = this.props;

    document.activeElement.blur();
    showContactDeleteModal(contact, index);
  };

  onMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovered: false });
  };
}

export default mobxInjectSelect({
  patientStore: [
    'isPatientContactEdited',
    'showContactDeleteModal',
    'showPatientDeleteModal',
    'showEditContactModal',
  ],
})(PatientAdminCardContactDetails);
