import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { once } from 'ramda';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import Modal from './Modal';

const classes = BEM.with('RemoveEscalationGroupModal');

class RemoveEscalationGroupModal extends Component {
  static propTypes = {
    currentRemoveEscalation: propTypes.escalationExecution,
    hideRemoveEscalationGroupModal: PropTypes.func.isRequired,
    removeEscalationGroup: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const { currentRemoveEscalation } = this.props;
    const { displayId = null } = currentRemoveEscalation || {};

    return (
      <Modal
        bodyClass={classes('body')}
        closeClass={classes('close-button')}
        header="Remove Escalation Group Now?"
        headerClass={classes('header')}
        isOpen={!!currentRemoveEscalation}
        size={'medium'}
        footerPrimaryActions={
          <button
            className={classes('remove-btn')}
            onClick={once(this._removeEscalationGroup)}
            type="button"
          >
            REMOVE
          </button>
        }
        footerSecondaryActions={
          <button className={classes('keep-btn')} onClick={this._closeModal} type="button">
            KEEP
          </button>
        }
        className={classes()}
        onRequestClose={this._closeModal}
      >
        <div>Would you like to remove Escalation Group {displayId} now?</div>
        <div>Otherwise, it will be removed automatically when it expires.</div>
      </Modal>
    );
  }

  _closeModal = () => {
    const { closeModal, hideRemoveEscalationGroupModal } = this.props;
    hideRemoveEscalationGroupModal();
    closeModal();
  };

  _removeEscalationGroup = () => {
    const { currentRemoveEscalation, removeEscalationGroup } = this.props;
    if (currentRemoveEscalation) removeEscalationGroup(currentRemoveEscalation);
    this._closeModal();
  };
}

export default mobxInjectSelect({
  escalationStore: [
    'currentRemoveEscalation',
    'hideRemoveEscalationGroupModal',
    'removeEscalationGroup',
  ],
})(RemoveEscalationGroupModal);
