import EventEmitter from 'events';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { action, computed, observable, runInAction } from 'mobx';
import queue from 'emitter-queue';
import { FROZEN_EMPTY_ARRAY } from '../common/utils';

export default class RoleStore {
  @observable disableSaveRoleButton = false;
  @observable showSavedRoleUndoButton = false;
  @observable undoRole = null;
  @observable savedRoleIds = [];
  @observable optinRoleNames = [];
  @observable isOptingIntoRole = false;

  events = queue(new EventEmitter());

  constructor({ client, entityStore, stores }) {
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;

    this.client.roles.on(
      'change',
      action((event) => {
        if (event.target.id !== event.triggeredBy.id) {
          this._handleEventInitiatedByOtherUser(event);
        }
      })
    );

    this.client.roles.on(
      'saved',
      action((event) => {
        const { action, id, organizationId } = event;
        if (organizationId !== this.stores.messengerStore.currentOrganizationId) return;
        const roleId = `role:${id}`;
        if (action === 'del') {
          this.savedRoleIds = this.savedRoleIds.filter((rid) => rid !== roleId);
        } else {
          this.savedRoleIds = uniq([...this.savedRoleIds, roleId]);
        }
      })
    );

    this.client.roles.on(
      'optin:start',
      action((event) => {
        const { roleStore } = this.stores;
        const role = roleStore.getRoleById(`role:${event.role_id}`);
        if (!role) {
          console.error(`Unable to retrieve Role: ${event.role_id} on start`);
          return;
        }
        const roleName = role.displayName;
        this.optinRoleNames = [...this.optinRoleNames, roleName];
        this.isOptingIntoRole = true;
      })
    );

    this.client.roles.on(
      'optin:stop',
      action((event) => {
        const { roleStore } = this.stores;
        const role = roleStore.getRoleById(`role:${event.role_id}`);
        if (!role) {
          console.error(`Unable to retrieve Role: ${event.role_id} on stop`);
          return;
        }
        const roleName = role.displayName;
        this.optinRoleNames = this.optinRoleNames.filter((name) => roleName !== name);
        if (this.optinRoleNames.length === 0) {
          this.hideOptinBanner();
        }
      })
    );
  }

  @computed get currentRoles() {
    const { messengerStore, sessionStore } = this.stores;
    const { currentOrganizationId } = messengerStore;
    const { currentUser } = sessionStore;
    const allRoles = currentUser ? currentUser.roles : FROZEN_EMPTY_ARRAY;
    const currentOrgRoles = allRoles.filter(
      (role) => role.organizationId === currentOrganizationId
    );
    const uniqueRolesInCurrentOrg = uniqBy(currentOrgRoles, (obj) => obj.id);

    return uniqueRolesInCurrentOrg;
  }

  @computed get currentRoleBotUserIds() {
    return this.currentRoles.map(({ botUserId }) => botUserId);
  }

  @computed get hasRoles() {
    return this.currentRoles.length > 0;
  }

  @computed get currentRolesIds() {
    return this.currentRoles.map((role) => role.id);
  }

  @computed get myRoleIds() {
    return this.client.currentUser.roles.map((role) => role.id);
  }

  _handleEventInitiatedByOtherUser = (event) => {
    const { role, type } = event;
    const members = role && role.members;

    if (type === 'OPT_IN') {
      this.stores.modalStore.openModal('assignedRoleModal', { event });
    } else {
      if (members && members.length > 0) {
        this.stores.modalStore.openModal('tookOverRoleModal', { event });
      } else {
        this.stores.modalStore.openModal('removedFromRoleModal', { event });
      }
    }
  };

  @action('RoleStore.findRole') findRole = async (roleId, organizationId, options) => {
    const role = await this.client.roles.find(roleId, organizationId, options);
    return this.entityStore.syncOne(role);
  };

  @action('RoleStore.findAllRoles') findAllRoles = async () => {
    const roles = await this.client.roles.findAll();
    return this.entityStore.sync(roles);
  };

  @action('RoleStore.getRoleById') getRoleById = (roleId: string) => {
    if (!roleId.includes('role:')) {
      roleId = `role:${roleId}`;
    }
    return this.entityStore.role.getById(roleId);
  };

  @action('RoleStore.findSavedRoleIds') findSavedRoleIds = async (organizationId) => {
    const savedRoles = await this.client.roles.findSavedRoles(organizationId);
    const savedRolesFromStore = this.entityStore.sync(savedRoles);
    return savedRolesFromStore.map((role) => role.id);
  };

  @action('RoleStore.loadSavedRoleIds') loadSavedRoleIds = async (organizationId) => {
    const { organizationStore } = this.stores;
    const { canAdminRoles = false } = await organizationStore.find(organizationId);
    runInAction(() => (this.savedRoleIds = []));
    if (!canAdminRoles) return;
    const savedRoleIds = await this.findSavedRoleIds(organizationId);
    runInAction(() => {
      this.savedRoleIds = savedRoleIds;
    });
  };

  @action('RoleStore.saveRole') saveRole = async (roleId, organizationId) => {
    this.disableSaveRoleButton = true;

    await this.client.roles.saveRole(roleId, organizationId);
    runInAction(() => {
      this.savedRoleIds = [...this.savedRoleIds, `role:${roleId}`];
      this.disableSaveRoleButton = false;
    });
  };

  @action('RoleStore.removeSavedRole') removeSavedRole = async (roleId, organizationId) => {
    this.disableSaveRoleButton = true;

    await this.client.roles.removeSavedRole(roleId, organizationId);
    const undoRole = await this.findRole(roleId, organizationId);
    runInAction(() => {
      this.savedRoleIds = this.savedRoleIds.filter((id) => id !== undoRole.id);
      this.undoRole = undoRole;
      this.disableSaveRoleButton = false;
      this.setUndoButton(true);

      clearTimeout(this._undoButtonTimeout);
      this._undoButtonTimeout = setTimeout(() => {
        this.setUndoButton(false);
      }, 7000);
    });
  };

  @action('RoleStore.undoRemoveSavedRole') undoRemoveSavedRole = (roleId, organizationId) => {
    this.saveRole(roleId, organizationId);
    this.setUndoButton(false);
  };

  @action('RoleStore.setUndoButton') setUndoButton = (value) => {
    this.showSavedRoleUndoButton = value;
  };

  @action('RoleStore.showRoleDetails') showRoleDetails = async ({ role }) => {
    const { messengerStore } = this.stores;
    messengerStore.showInfoPane({ button: 'close', role, type: 'RoleInfo' });
  };

  @action('RoleStore.showRoleConversationInfo')
  showRoleConversationInfo = async ({ role }) => {
    const { messengerStore } = this.stores;
    messengerStore.showInfoPane({
      button: 'dots',
      role,
      type: 'RoleConversationInfo',
    });
  };

  @action('RoleStore.hideOptinBanner') hideOptinBanner = () => {
    this.isOptingIntoRole = false;
    this.optinRoleNames = [];
  };
}
