import React from 'react';
import BEM from '../../../bem';
import { ReactComponent as PrioritySvg } from '../../../images/priority-icon.svg';
import { Modal } from '../..';

const classes = BEM.with('PatientBroadcastCsvFailModal');

type PatientBroadcastCsvFailModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  options: { failState: string };
};

export default function PatientBroadcastCsvFailModal({
  closeModal,
  isOpen,
  options,
}: PatientBroadcastCsvFailModalProps) {
  const { failState } = options;

  const imgHeader = {
    image: PrioritySvg,
    fill: '#db524b',
    imgClassHeader: classes('img'),
  };

  let text;
  if (failState === 'email') {
    text =
      'Account does not have a valid email address. Please contact your helpdesk or supervisor to update your account information.';
  } else if (failState === 'csv') {
    text =
      'Oops, something went wrong. Please try again or wait for the existing upload to finish processing.';
  }

  return (
    <Modal
      className={classes()}
      headerClass={classes('header')}
      closeClass={classes('close-button')}
      imgHeader={imgHeader}
      isOpen={isOpen}
      onRequestClose={closeModal}
      size={'medium'}
    >
      <div className={classes('text')}>{text}</div>
    </Modal>
  );
}
