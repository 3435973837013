import React, { useEffect, useState } from 'react';
import { mobxInjectSelect } from '../../../../common/utils';

import { ActionBar, TabButton } from '../../../shared-components/ActionBar/ActionBar';

import Archiving from './Archiving';
import AuthenticationComponents from './AuthComponents';
import Billing from './Billing';
import CustomDirectories from './CustomDirectories';
import CustomizedFD from './CustomizedFD';
import Delete from './Delete';
import SCIM from './SCIM';
import OrgDetails from './OrgDetails';
import PendingUsers from './PendingUsers';
import Premium from './Premium';
import UserPermissions from './UserPermissions';
import WelcomeEmail from './WelcomeEmail';
import { MFA } from './MFA';
import { OTP } from './OTP';
import PBX from './PBX';
import PersonaTab from './Persona';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

const { setViewToShow } = actions;

type MobxProps = {
  allowMTOrg: boolean;
  allowOneTimePasswordLogin: boolean;
  allowPBXRoles: boolean;
  allowScimAdminSetting: boolean;
  isScimAdmin: boolean;
  isMfaEnabled: boolean;
  isProductSettingsAdmin: boolean;
  isProductSuperAdmin: boolean;
  removeOrgTabsBaseOnProductRoles: () => string[];
  isPersonaAdminFeatureFlagEnabled: boolean;
};

export type TabProps = {
  onClose: () => void;
  onSave: () => void;
};

type TabsProps = {
  onSave: () => void;
};

const OrgSettingsTabs = {
  Authentication: AuthenticationComponents,
  Archiving: Archiving,
  'Custom Directories': CustomDirectories,
  Billing: Billing,
  Delete: Delete,
  'User Permissions': UserPermissions,
  'Org Details': OrgDetails,
  Premium: Premium,
  SCIM: SCIM,
  'Permission Groups': PersonaTab,
  'Pending Users': PendingUsers,
  'Welcome Email': WelcomeEmail,
  'Customized FD': CustomizedFD,
  'MFA Settings': MFA,
  'OTP Login': OTP,
  PBX: PBX,
} as const;

export type OrgSettingsTab = keyof typeof OrgSettingsTabs;

const OrgProductRolesAdminTabs: OrgSettingsTab[] = [
  'Authentication',
  'Archiving',
  'Custom Directories',
  'Billing',
  'Delete',
  'User Permissions',
  'Org Details',
  'Premium',
  'SCIM',
  'Permission Groups',
  'Pending Users',
  'Welcome Email',
  'Customized FD',
  'MFA Settings',
  'OTP Login',
  'PBX',
];

const OrgUserPermissionAdminTabs = [
  'User Permissions',
  'Pending Users',
  'SCIM',
  'Permission Groups',
  'Customized FD',
  'Welcome Email',
  'MFA Settings',
  'PBX',
];

const Tabs = ({
  allowMTOrg,
  allowOneTimePasswordLogin,
  allowPBXRoles,
  allowScimAdminSetting,
  removeOrgTabsBaseOnProductRoles,
  isScimAdmin,
  isProductSettingsAdmin,
  isProductSuperAdmin,
  isPersonaAdminFeatureFlagEnabled,
  isMfaEnabled,
  onSave,
}: TabsProps & MobxProps) => {
  const dispatch = useAppDispatch();
  const {
    emailDomains = [],
    featureCustomFastDeploy,
    preventPendingUsers,
    isLoginWorkflowOTP,
    scimIntegrationSetting,
    personaManagement,
  } = useAppSelector((state) => ({
    featureCustomFastDeploy: state.orgSettings.settings.featureCustomFastDeploy,
    preventPendingUsers: state.orgSettings.settings.preventPendingUsers,
    emailDomains: state.orgSettings.settings.emailDomains,
    isLoginWorkflowOTP: state.orgSettings.settings.otpAllowConfigure,
    scimIntegrationSetting: state.orgSettings.settings.scimIntegrationSetting,
    personaManagement: state.orgSettings.settings.personaManagement,
  }));
  const viewToShow = useAppSelector((state) => state.admin.viewToShow);
  const [authorizedTabs, setAuthorizedTabs] = useState<TabButton<OrgSettingsTab>[]>([]);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>();
  const ttAdmin = isProductSettingsAdmin || isProductSuperAdmin;
  const scimIntegrationEnabled = scimIntegrationSetting && (isScimAdmin || isProductSuperAdmin);
  const personaManagementSettingEnabled = personaManagement !== 'off';
  const CurrentTab = viewToShow === '' ? null : OrgSettingsTabs[viewToShow];
  let renderTab = <></>;
  useEffect(() => {
    const removeTabs = [
      preventPendingUsers && 'Pending Users',
      !featureCustomFastDeploy && 'Customized FD',
      (!allowOneTimePasswordLogin || !isLoginWorkflowOTP) && 'OTP Login',
      !isMfaEnabled && 'MFA Settings',
      !allowPBXRoles && 'PBX',
      (!isPersonaAdminFeatureFlagEnabled || !personaManagementSettingEnabled) &&
        'Permission Groups',
      (!allowScimAdminSetting || !scimIntegrationEnabled) && 'SCIM',
    ];

    const mergeTabs = removeTabs.concat(removeOrgTabsBaseOnProductRoles());

    setAuthorizedTabs(
      OrgProductRolesAdminTabs.map((tab) => ({
        label: tab,
      })).filter((tab) => !mergeTabs.includes(tab?.label))
    );
  }, [
    allowMTOrg,
    allowPBXRoles,
    allowScimAdminSetting,
    preventPendingUsers,
    isPersonaAdminFeatureFlagEnabled,
    personaManagementSettingEnabled,
    featureCustomFastDeploy,
    isLoginWorkflowOTP,
    emailDomains.length,
    removeOrgTabsBaseOnProductRoles,
    allowOneTimePasswordLogin,
    isMfaEnabled,
    scimIntegrationSetting,
    isScimAdmin,
    scimIntegrationEnabled,
  ]);

  function clearTab() {
    dispatch(setViewToShow({ viewToShow: '' }));
    setCurrentTabIndex(undefined);
  }

  function onTabSelection(tabButton: TabButton<OrgSettingsTab>, index: number) {
    if (tabButton.label === viewToShow) {
      clearTab();
    } else {
      dispatch(setViewToShow({ viewToShow: tabButton.label }));
      setCurrentTabIndex(index);
    }
  }

  if (CurrentTab) {
    renderTab = <CurrentTab onSave={onSave} onClose={clearTab} />;
  }

  return (
    <>
      <ActionBar
        hasSearch={false}
        tabButtons={
          ttAdmin
            ? authorizedTabs
            : authorizedTabs.filter((tab) => OrgUserPermissionAdminTabs.includes(tab.label))
        }
        setSelectedTabIndex={onTabSelection}
        selectedTabIndex={currentTabIndex}
      />
      {renderTab}
    </>
  );
};

export default mobxInjectSelect<TabsProps, MobxProps>({
  messengerStore: [
    'allowMTOrg',
    'allowPBXRoles',
    'allowOneTimePasswordLogin',
    'allowScimAdminSetting',
    'isScimAdmin',
    'isMfaEnabled',
    'isPersonaAdminFeatureFlagEnabled',
    'isProductSettingsAdmin',
    'isProductSuperAdmin',
    'removeOrgTabsBaseOnProductRoles',
  ],
})(Tabs);
