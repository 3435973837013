import React, { Component } from 'react';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import { PatientCSVUploadTypes } from '../../../../../models/enums';
import BEM from '../../../../bem';
import mobxInjectSelect from '../../../../utils/mobxInjectSelect';
import { ReactComponent as DropdownChevronSvg } from '../../../../images/dropdown-chevron.svg';
import { ReactComponent as UploadButtonSvg } from '../../../../images/upload.svg';
import { generateValidTimezones } from '../../../../utils';
import { Modal } from '../../..';
import TimezoneContextMenu from '../../../ScheduleMessageModal/TimezoneContextMenu';
import { ContextMenu } from '../../../ContextMenu';
import { KEYMAP } from 'common/constants';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';

const classes = BEM.with('UploadPatientsCsvModal');

const { APPOINTMENT_REMINDERS, BROADCAST, PATIENT } = PatientCSVUploadTypes;
const { origin: windowOrigin, pathname: windowPath } = window.location;
const pathname = windowOrigin + windowPath.replace('index.html', '') + 'media/';

const templateUrl = {
  [APPOINTMENT_REMINDERS]: 'automated_appointments_template.csv',
  [BROADCAST]: 'patient_broadcast_template.csv',
  [PATIENT]: 'patients_template.csv',
};

const entityName = {
  [APPOINTMENT_REMINDERS]: 'appointments',
  [BROADCAST]: 'patient broadcast list',
  [PATIENT]: 'patients',
};

const entityUploadName = {
  [APPOINTMENT_REMINDERS]: 'appointments',
  [BROADCAST]: 'patient broadcast list',
  [PATIENT]: 'patient list',
};

const uploadDescription = {
  [APPOINTMENT_REMINDERS]: 'Add appointments from a .csv file',
  [BROADCAST]: 'Add patients to this broadcast list from a .csv file',
  [PATIENT]: 'Add patients from a .csv file',
};

class UploadPatientsCsvModal extends Component {
  static propTypes = {
    determineCoarseTimezone: PropTypes.func.isRequired,
    hideUploadPatientsCsvModal: PropTypes.func.isRequired,
    uploadCSV: PropTypes.func.isRequired,
    options: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.timezoneMenuButtonRef = React.createRef();
  }

  state = {
    error: null,
    file: '',
    accessibilityMode: false,
  };

  componentDidMount() {
    this.props.determineCoarseTimezone().then(({ name }) => {
      this.setState({ selectedTimezone: name, timezones: generateValidTimezones() });
    });
  }

  render() {
    const { isOpen, options } = this.props;
    const { error, selectedTimezone, timezones = [] } = this.state;
    const { type = PATIENT } = options;
    const name = entityName[type];
    const uploadName = entityUploadName[type];
    const timezoneObject = timezones.find(({ timezone }) => timezone === selectedTimezone);

    const handleKeyPress = (e) => {
      const enterOrSpace = e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE;
      if (enterOrSpace) {
        e.preventDefault();
        this._onSelectFile();
      }
    };

    return (
      <Modal
        bodyClass={classes('body', { appointmentReminders: type === APPOINTMENT_REMINDERS })}
        className={classes()}
        headerClass={classes('header')}
        header={`Upload ${startCase(name)}`}
        onRequestClose={this._onRequestClose}
        size={'medium-large'}
        isOpen={isOpen}
      >
        <div className={classes('main-container')}>
          <div className={classes('error')}>
            {error && <div className={classes('error-message')}>{error}</div>}
          </div>
          <div
            className={classes('upload-button-container')}
            onClick={this._onSelectFile}
            aria-label="Upload appointments"
            tabIndex={0}
            role="button"
            onKeyPress={handleKeyPress}
          >
            <input
              className={classes('upload-button-input')}
              type="file"
              ref={this._setFileHandler}
              onChange={this._onFileChange}
            />
            <div className={classes('column-one')}>
              <div className={classes('first-row')}>Upload {startCase(name)}</div>
              <div className={classes('second-row')}>{uploadDescription[type]}</div>
            </div>
            <div className={classes('column-two')}>
              <UploadButtonSvg className={classes('button-icon-path')} aria-hidden />
            </div>
          </div>
          <div className={classes('upload-text')}>
            <span id="downloadable-template-link">{"Don't have a CSV ready? Use"}</span>
            <a
              className={classes('upload-text-link')}
              download
              id="template-link"
              href={pathname + templateUrl[type]}
              rel="noopener noreferrer"
              target="_blank"
              tabIndex="0"
              aria-labelledby="downloadable-template-link template-link"
            >
              this template
            </a>
            <span>to upload your {uploadName}</span>
          </div>
          {type === APPOINTMENT_REMINDERS && timezoneObject && (
            <div className={classes('timezone-settings')}>
              <div className={classes('first-row')}>Time zone</div>
              <ContextMenu
                event="click"
                offsetY={6}
                position="bottominnerleft"
                relativeTo={this.timezoneMenuButtonRef.current}
                theme="csv-upload"
                accessibilityMode={this.state.accessibilityMode}
                menu={
                  <TimezoneContextMenu
                    onTimezoneSelected={(timezone) => {
                      this._setTimezone(timezone);
                      this._handleContextMenuClose();
                    }}
                    selectedTimezone={selectedTimezone}
                    accessibilityMode={this.state.accessibilityMode}
                  />
                }
              >
                <button
                  className={classes('timezone-menu-btn')}
                  data-test-id={'timezone-menu-button'}
                  ref={this.timezoneMenuButtonRef}
                  aria-label={`Selected Timezone ${timezoneObject.abbr} - ${timezoneObject.name}`}
                >
                  {`${timezoneObject.abbr} - ${timezoneObject.name}`}
                  <DropdownChevronSvg aria-hidden />
                </button>
              </ContextMenu>
              <ReduxEscapeHatch
                ref={(ref) => {
                  this.reduxEscapeHatch = ref;
                  if (
                    this.reduxEscapeHatch &&
                    this.reduxEscapeHatch?.accessibilityMode !== this.state.accessibilityMode
                  ) {
                    this.setState({ accessibilityMode: this.reduxEscapeHatch?.accessibilityMode });
                  }
                }}
              />
            </div>
          )}
        </div>
      </Modal>
    );
  }

  _setTimezone = (tz) => {
    this.setState({ selectedTimezone: tz });
  };

  _onRequestClose = () => {
    const { hideUploadPatientsCsvModal } = this.props;

    this.setState({
      error: null,
      file: '',
    });
    hideUploadPatientsCsvModal();
  };

  _onSelectFile = () => {
    this.state.fileHandler.click();
  };

  _setFileHandler = (element) => {
    this.setState({
      fileHandler: element,
    });
  };

  _onFileChange = async ({ target: { files } }) => {
    const { options, uploadCSV } = this.props;
    const { listId, type } = options;
    const { selectedTimezone: timezone } = this.state;
    let { file } = this.state;
    let error = null;
    const newFile = files.length ? files[0] : null;
    const ext = newFile && newFile.name.split('.').pop().toLowerCase();

    if (newFile) {
      file = newFile;
      if (!(newFile.type === 'text/csv' || ext === 'csv')) {
        error = newFile && 'Invalid file type, please select a .csv file';
        file = null;
      }
    }

    this.setState({
      error,
      file,
    });

    if (newFile && error === null) {
      await uploadCSV(newFile, type, { listId, timezone });
    }
  };

  _handleContextMenuClose = () => {
    if (this.timezoneMenuButtonRef.current) {
      this.timezoneMenuButtonRef.current.focus();
    }
  };
}

export default mobxInjectSelect({
  patientAdminStore: ['determineCoarseTimezone'],
  patientStore: ['hideUploadPatientsCsvModal', 'uploadCSV'],
})(UploadPatientsCsvModal);
