import React from 'react';
import { observer } from 'mobx-react';

import { AlertComponent } from '../../../../../src/types/';
import BEM from '../../../../common/bem';

const classes = BEM.with('AlertRoleInfo');

export type AlertRoleInfoProps = {
  component: AlertComponent;
};

const AlertRoleInfo = ({ component }: AlertRoleInfoProps) => {
  const { value: text } = component;
  let { value_color: color } = component;

  if (color) color = color.replace('0x', '#');

  return (
    <div className={classes()} style={{ color }}>
      {text}
    </div>
  );
};

export default observer(AlertRoleInfo);
