import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BEM from '../bem';
import { ReactComponent as PriorityButtonSvg } from '../images/priority-icon.svg';

const buttonClasses = BEM.with('UnreadBadgeCount-Button');
const textClasses = BEM.with('UnreadBadgeCount-Text');

class UnreadBadgeCount extends Component {
  static propTypes = {
    className: PropTypes.string,
    count: PropTypes.number.isRequired,
    priority: PropTypes.bool,
    usePriorityButton: PropTypes.bool,
  };

  static defaultProps = {
    priority: false,
    usePriorityButton: false,
  };

  render() {
    const { className, count, priority, usePriorityButton } = this.props;

    if (usePriorityButton && priority)
      return (
        <div className={classNames(buttonClasses(), className)}>
          <PriorityButtonSvg className={buttonClasses('priority-button')} />
        </div>
      );

    return (
      <div
        className={classNames(
          textClasses({
            priority,
            double: count > 9,
            triple: count > 99,
          }),
          className
        )}
      >
        <span className={textClasses('count')}>{count > 999 ? '1K+' : count}</span>
      </div>
    );
  }
}

export default observer(UnreadBadgeCount);
