import React from 'react';
import { Modal } from '@tigerconnect/web-component-library';

import BEM from 'common/bem';

const classes = BEM.with('SukiModal');

type SukiModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function SukiModal({ isOpen, closeModal }: SukiModalProps) {
  function onCloseWrapper() {
    closeModal();
  }

  return (
    <Modal
      isOpen={isOpen}
      bodyDataTestId={'suki embed'}
      headerDataTestId={'suki header'}
      headerText={'Suki'}
      theme={'primary'}
      onClose={onCloseWrapper}
      size={'medium-large'}
      hasCloseButton={true}
      type={'empty'}
      closeButtonDataTestId={'close suki'}
    >
      <div className={classes()}>Suki embed goes here</div>
    </Modal>
  );
}
