import * as errors from '../errors';
import BaseService from './BaseService';

export default class TwilioService extends BaseService {
  async getTwilioNumbers(organizationId: string, areaCode: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!areaCode) throw new errors.ValidationError('areaCode', 'required');

    const res = await this.host.api.twilio.getTwilioNumbers(organizationId, areaCode);
    return res;
  }

  async assignPagerNumber(organizationId: string, number: string, roleToken: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!number) throw new errors.ValidationError('number', 'required');
    if (!roleToken) throw new errors.ValidationError('roleToken', 'required');

    await this.host.api.twilio.assignPagerNumber(organizationId, number, roleToken);
  }

  async removePagerNumber(organizationId: string, number: string, roleToken: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!number) throw new errors.ValidationError('number', 'required');
    if (!roleToken) throw new errors.ValidationError('roleToken', 'required');

    await this.host.api.twilio.removePagerNumber(organizationId, number, roleToken);
  }
}
