import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { mobxInjectSelect } from '../../utils';
import { EntityAvatar, UserName, UserTitleDepartment } from '../';
import { ReactComponent as CloseRoundSvg } from '../../images/close-round-icon.svg';
import Entity from './GroupMemberEntity';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('GroupMembers');
const AVATAR_SIZE = 37;

function GroupMember({
  composeToEntityV2,
  currentUser,
  group,
  highlightedMembers,
  isMembersDirty,
  isProviderNetwork,
  members,
  prepareRemoveMember,
  user,
}) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { groupType } = group;
  const isHighlighted = highlightedMembers.includes(user.id);
  const isCurrentUser = user.id === currentUser.id;
  let isRemovable =
    !isCurrentUser &&
    !(user.isPatientContact || user.isPatient) &&
    groupType !== 'FORUM' &&
    groupType !== 'INTRA_TEAM';
  let patientTitleFragment;
  let smsOptedOut = false;

  if (groupType === 'PATIENT_MESSAGING') {
    const patientAndContactCount = members.filter(
      (member) => member.isPatient || member.isPatientContact
    ).length;
    if (patientAndContactCount > 1 && (user.isPatientContact || user.isPatient)) {
      isRemovable = true;
    }

    let patientTitle;
    if (user.isPatient) {
      patientTitle = 'Patient';
      smsOptedOut = user.patient.smsOptedOut;
    } else if (user.isPatientContact) {
      patientTitle = user.patientContact.relation || 'Patient Contact';
      smsOptedOut = user.patientContact.smsOptedOut;
    }
    patientTitleFragment = <div className={classes('patient-title')}>{patientTitle}</div>;
  }

  const isMemberClickable =
    isProviderNetwork &&
    !isCurrentUser &&
    !isMembersDirty &&
    !currentUser.roleIds.includes(user?.botRoleId);

  function _handleMemberClick(entity) {
    if (!isMemberClickable) return;
    return () => composeToEntityV2(entity);
  }

  function _prepareRemoveMember(userId) {
    return (e) => {
      e.stopPropagation();
      prepareRemoveMember(userId);
    };
  }

  function _handleKeyDown(e) {
    if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
      _prepareRemoveMember(user.id)(e);
    }
  }

  return (
    <li
      className={classNames(classes('list-item', { highlight: isHighlighted }))}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <Entity
        className={classes('details', { highlight: isHighlighted })}
        clickable={isMemberClickable}
        onClick={_handleMemberClick(user)}
        userName={user.displayName}
      >
        <EntityAvatar
          entity={user.isRoleBot && user.botRole ? user.botRole : user}
          size={AVATAR_SIZE}
          showPresenceIndicator={true}
        />
        <span className={classes('user-details')}>
          <UserName
            className={classes('user-name', {
              smsOptedOut,
              underLine: isMouseOver && isMemberClickable,
            })}
            includeRoleOwner
            isCurrentUser={isCurrentUser}
            user={user}
          />
          {patientTitleFragment}
          <UserTitleDepartment className={classes('user-title')} user={user} />
        </span>
        {isRemovable && (
          <span
            aria-label="Remove member"
            role="button"
            className={classes('actions', { highlight: isHighlighted })}
            onClick={_prepareRemoveMember(user.id)}
            onKeyDown={_handleKeyDown}
          >
            <CloseRoundSvg className={classes('actions-remove')} aria-hidden />
          </span>
        )}
      </Entity>
    </li>
  );
}

GroupMember.propTypes = {
  group: propTypes.group.isRequired,
  highlightedMembers: propTypes.arrayOrObservableArrayOf(PropTypes.string),
  members: propTypes.arrayOrObservableArrayOf(PropTypes.object),
  prepareRemoveMember: PropTypes.func.isRequired,
  user: propTypes.user.isRequired,
  composeToEntityV2: PropTypes.func.isRequired,
  isMembersDirty: PropTypes.bool.isRequired,
};

export default mobxInjectSelect({
  composeMessageStore: ['composeToEntityV2'],
  groupEditorStore: [
    'group',
    'highlightedMembers',
    'members',
    'prepareRemoveMember',
    'isMembersDirty',
  ],
  networkStore: ['isProviderNetwork'],
  sessionStore: ['currentUser'],
})(GroupMember);
