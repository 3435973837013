import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import PatientConversationItemDetails from './PatientConversationItemDetails';
import AttachmentDragModal from './AttachmentDragModal';
import BEM from 'common/bem';
import { EntityAvatar } from 'common/components';
import propTypes from 'common/propTypes';
import { attachments, getRecipientEntity, mobxInjectSelect } from 'common/utils';
const classes = BEM.with('PatientConversationItem');

class PatientConversationItem extends Component {
  static propTypes = {
    addAttachments: PropTypes.func.isRequired,
    clearNewMessagesIndicator: PropTypes.func.isRequired,
    clearNewMessagesOnConvClick: PropTypes.bool.isRequired,
    conversation: propTypes.conversation.isRequired,
    isPinned: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isAccessibilityEnabled: PropTypes.bool.isRequired,
    markAsReadOnRosterClick: PropTypes.bool.isRequired,
    rejectAttachments: PropTypes.func.isRequired,
    selectConversation: PropTypes.func.isRequired,
    isInsideCard: PropTypes.bool,
    allowFocus: PropTypes.bool,
    closeModal: PropTypes.func,
  };

  state = {
    isTileHovered: false,
  };

  componentDidMount() {
    const { conversation, findUser } = this.props;
    const { counterParty, counterPartyType, organizationId } = conversation;
    const { entity, entityType } = getRecipientEntity({
      entity: counterParty,
      entityType: counterPartyType,
    });

    if (entityType === 'user' && entity.$placeholder) {
      findUser(entity.id, organizationId);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
  }

  render() {
    const {
      conversation,
      isAccessibilityEnabled,
      isPinned,
      isSelected,
      rejectAttachments,
      isInsideCard,
      allowFocus = false,
    } = this.props;
    const { isTileHovered } = this.state;
    const { counterParty, counterPartyType } = conversation;
    const { entity, entityType } = getRecipientEntity({
      entity: counterParty,
      entityType: counterPartyType,
    });
    if (!entity || !entityType) return null;
    const isGroupConversation = entity.memberCount > 2;
    const ariaDescription = `${
      isGroupConversation ? 'Group conversation' : 'Conversation'
    } with patient ${entity.patientDetails?.name}`;
    return (
      <div
        className={classes({
          [entityType]: true,
          isMainRosterSelected: isSelected,
          muted: conversation.isMuted,
        })}
        aria-label={ariaDescription}
        tabIndex={allowFocus ? 0 : -1}
        role="button"
        onKeyDown={this._handleKeyDown}
        onClick={this._onClick}
        onMouseEnter={this._onMouseEnter}
        onMouseLeave={this._onMouseLeave}
      >
        <Dropzone
          className={classes('contents')}
          multiple={true}
          accept={attachments.allContentTypes.join(',')}
          disablePreview
          disableClick
          onDragEnter={this._onDragEnter}
          onDragLeave={this._onDragLeave}
          onDropAccepted={this._onDropAccepted}
          onDropRejected={rejectAttachments}
          disabled={isAccessibilityEnabled}
        >
          {({ isDragActive = false, isDragReject = false } = {}) => (
            <div className={classes('contents-patient')}>
              <div className={classes('left-pane')}>
                <div className={classes('avatar')}>
                  <EntityAvatar
                    entity={entity}
                    entityType={entityType}
                    isMuted={conversation.isMuted}
                    size="fit"
                  />
                </div>
              </div>
              <PatientConversationItemDetails
                conversation={conversation}
                isPinned={isPinned}
                isSelected={isSelected}
                isTileHovered={isTileHovered}
                isInsideCard={isInsideCard}
              />
              <AttachmentDragModal {...{ isDragActive, isDragReject }} />
            </div>
          )}
        </Dropzone>
      </div>
    );
  }

  _onClick = async () => {
    const {
      clearNewMessagesIndicator,
      clearNewMessagesOnConvClick,
      conversation,
      hidePatientFilteredConversations,
      isSelected,
      markAsReadOnRosterClick,
      selectConversation,
    } = this.props;

    if (markAsReadOnRosterClick) {
      if (clearNewMessagesOnConvClick && isSelected) {
        clearNewMessagesIndicator(conversation);
      }
    }

    hidePatientFilteredConversations({ continueSearch: false });

    await selectConversation(conversation, {
      markAsRead: markAsReadOnRosterClick,
    });
  };

  _handleKeyDown = (e) => {
    const { closeModal, allowFocus } = this.props;
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      this._onClick();
      if (allowFocus) closeModal();
    }
  };

  _onMouseEnter = () => {
    this.setState({ isTileHovered: true });
  };

  _onMouseLeave = () => {
    this.setState({ isTileHovered: false });
  };

  _onDragEnter = () => {
    if (!this.timeout) {
      this.timeout = setTimeout(() => {
        this.timeout = null;
        this._onClick();
      }, 1000);
    }
  };

  _onDragLeave = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  };

  _onDropAccepted = (files) => {
    const { addAttachments } = this.props;
    this._onClick();
    addAttachments(files);
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['addAttachments', 'rejectAttachments'],
  conversationStore: [
    'clearNewMessagesIndicator',
    'hidePatientFilteredConversations',
    'selectConversation',
  ],
  localStore: ['clearNewMessagesOnConvClick', 'markAsReadOnRosterClick'],
  modalStore: ['openModal'],
  userStore: ['findUser'],
  messengerStore: ['isAccessibilityEnabled'],
})(PatientConversationItem);
